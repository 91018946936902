import styled from 'styled-components';

// material ui
import { Container } from '@material-ui/core';


// Styled resetPassword components ....
export const ResetPasswordContainer = styled(Container).attrs({ component: 'main'})`
    margin-top: 125px;
`;

export const ResetPasswordLogoContainer = styled.div`
    display: flex;
    padding: 16px 16px 0 16px;
`;

export const ResetPasswordLogo = styled.div`
    margin: 0 auto;
`;

export const H3 = styled.h3`
    margin-bottom: 0;
`;