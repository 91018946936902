import React from 'react';
import {
  canAccessSettings,
  canAccessHelp,
  canAccessActionList,
  canAccessPayments,
  canAccessEmployers,
  canAccessContacts,
  canAccessClaims,
  canAccessReports,
  isClientPortalUser,
} from '../../dtos/auth';
// Icons
import IconSettings from '@material-ui/icons/Settings';
import IconCompanies from '@material-ui/icons/Business';
import IconContacts from '@material-ui/icons/Contacts';
import IconLaunchpad from '@material-ui/icons/Home';
import IconWorkOrders from '@material-ui/icons/BusinessCenter';
import PieChartIcon from '@material-ui/icons/PieChart';
import FlagIcon from '@material-ui/icons/Flag';
import IconAssignment from '@material-ui/icons/Assignment';
import IconAssessment from '@material-ui/icons/Assessment';

import MenuNavItems from './menuNavItems';
import { NavList, NavListHeader } from './menu.styles';
import { MenuNavItemProps } from './menuNavItem';

export interface MenuNavProps {
  isCollapsed: boolean;
}

const MenuNav = (props: MenuNavProps) => {
  const { isCollapsed } = props;

  let items: MenuNavItemProps[] = [];

  if (canAccessActionList())
    items.push({
      name: 'Action List',
      link: '/actions',
      Icon: IconLaunchpad,
    });

  if (canAccessPayments())
    items.push({
      name: 'Batch Payments',
      link: '/batchPayments',
      Icon: IconAssignment,
    });

  if (canAccessEmployers())
    items.push({
      name: 'Employers',
      link: '/employers',
      Icon: IconCompanies,
    });

  if (canAccessContacts())
    items.push({
      name: 'Contacts',
      link: '/contacts',
      Icon: IconContacts,
    });

  if (canAccessClaims())
    items.push({
      name: 'Claims',
      link: '/claims',
      Icon: IconWorkOrders,
    });

  if (isClientPortalUser()) {
    items.push({
      name: 'Dashboard',
      link: '/dashboard',
      Icon: IconAssessment,
    });
  }

  if (canAccessReports())
    items.push({
      name: 'Reports',
      link: '/reports',
      Icon: PieChartIcon,
    });

  if (canAccessSettings())
    items.push({
      name: 'Settings',
      Icon: IconSettings,
      items: [
        {
          name: 'System',
          link: '/settings/system',
        },
        {
          name: 'Codes',
          link: '/settings/codes',
        },
        {
          name: 'Email Templates',
          link: '/settings/emailTemplates',
        },
        {
          name: 'Health System',
          link: '/settings/healthSystems',
        },
      ],
    });

  if (canAccessHelp())
    items.push({
      name: 'Help',
      link: '/help',
      Icon: FlagIcon,
    });

  return (
    <div data-cy="sidebar_container" data-iscollapsed={isCollapsed}>
      <NavList disablePadding>
        {!isCollapsed && <NavListHeader disableSticky={true}></NavListHeader>}

        <MenuNavItems isCollapsed={isCollapsed} items={items} />
      </NavList>
    </div>
  );
};

export default MenuNav;
