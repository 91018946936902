import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors, } from 'formik';

// material ui
import { Grid, TextField, FormControlLabel } from '@material-ui/core';
import { ClientQuestionnaireDto } from '../../dto';
import { MHARadio } from '../../../../common/styles/styles';
import { radioButtonBooleanOptions } from '../../constants/options';


interface PartTimeComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
}

const PartTimeComponent: React.FC<PartTimeComponentProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation
  }) => {

  return (
    <React.Fragment>
      <Grid item xs={12}>On average, how many hours does the claimant work each week?</Grid>
      <Grid item xs={4}>
        <TextField
          id="hoursWorked"
          size="small"
          margin="none"
          value={values.hoursWorked || ''}
          //label="Job Title"
          name="hoursWorked"
          multiline
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.hoursWorked)}
          helperText={errors.hoursWorked && errors.hoursWorked}
          data-cy="clientQuestionnaire_hoursWorked"
          aria-label="Number of hours worked each week"
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={12}>Is the claimant working all available hours?</Grid>
      <Grid container item xs={12} direction="row">
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="workingAllHours"
              name="workingAllHours"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('workingAllHours', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? 1);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              //add errors for validation
              checked={o.status === values.workingAllHours}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_workingAllHours" />
          </Grid>
        ))}
      </Grid>
      {values.workingAllHours !== undefined && values.workingAllHours === false ?
        <>
          <Grid item xs={12}>Did the claimant refuse any offers of work for specific days or hours?</Grid>
          <Grid item xs={4}>
            {radioButtonBooleanOptions.map((o, index) => (
              <Grid item xs={3} key={o.name + index}>
                <FormControlLabel
                  id="offersRefused"
                  name="offersRefused"
                  onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                    setFieldValue('offersRefused', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? 1);
                    setShouldBlockNavigation(true);
                  }}
                  onBlur={handleBlur}
                  //add errors for validation
                  checked={o.status === values.offersRefused}
                  value={o.name}
                  control={<MHARadio
                    size="small" />} label={o.name} data-cy="clientQuestionnaire_workingAllHours" />
              </Grid>
            ))}
          </Grid>
          <br />
          <Grid item xs={12}>Did the claimant give a reason for refusing any offered work, or indicate a reason for not being available to accept the offered work?</Grid>
          <Grid item xs={4}>
            <TextField
              id="reasonForRefusal"
              size="small"
              margin="none"
              value={values.reasonForRefusal || ''}
              //label="Job Title"
              name="reasonForRefusal"
              multiline
              onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
              onBlur={handleBlur}
              error={Boolean(errors.reasonForRefusal)}
              helperText={errors.reasonForRefusal && errors.reasonForRefusal}
              data-cy="clientQuestionnaire_reasonForRefusal"
              aria-label="Number of hours worked each week"
              fullWidth
            />
          </Grid>
          <br />
        </>
        : null}
      <br />
    </React.Fragment>
  )
}

export default PartTimeComponent;
