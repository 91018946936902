import { createAsyncAction, createAction } from 'typesafe-actions';
import { HealthSystemDto } from './healthSystems.dtos';
import { RateDto, RateWrapperDto } from '../../../common/dtos/billing';

export const getHealthSystemsActions = createAsyncAction(
  'GET_HEALTH_SYSTEMS_REQUEST',
  'GET_HEALTH_SYSTEMS_SUCCESS',
  'GET_HEALTH_SYSTEMS_ERROR',
)<undefined, HealthSystemDto[], string>();

export const updateHealthSystemsActions = createAsyncAction(
  'UPDATE_HEALTH_SYSTEMS_REQUEST',
  'UPDATE_HEALTH_SYSTEMS_SUCCESS',
  'UPDATE_HEALTH_SYSTEMS_ERROR',
)<HealthSystemDto, HealthSystemDto, string>();

export const createHealthSystemsActions = createAsyncAction(
  'CREATE_HEALTH_SYSTEMS_REQUEST',
  'CREATE_HEALTH_SYSTEMS_SUCCESS',
  'CREATE_HEALTH_SYSTEMS_ERROR',
)<HealthSystemDto, HealthSystemDto, string>();

export const getHealthSystemsByIdActions = createAsyncAction(
  'GET_HEALTH_SYSTEMS_BY_ID_REQUEST',
  'GET_HEALTH_SYSTEMS_BY_ID_SUCCESS',
  'GET_HEALTH_SYSTEMS_BY_ID_ERROR',
)<string, HealthSystemDto, string>();



export const getHealthSystemRateActions = createAsyncAction(
    'GET_HEALTH_SYSTEM_RATE_REQUEST',
    'GET_HEALTH_SYSTEM_RATE_SUCCESS',
    'GET_HEALTH_SYSTEM_RATE_ERROR',

)<RateWrapperDto, RateWrapperDto, string>();


export const updateHealthSystemRateActions = createAsyncAction(
    'UPDATE_HEALTH_SYSTEM_RATE_REQUEST',
    'UPDATE_HEALTH_SYSTEM_RATE_SUCCESS',
    'UPDATE_HEALTH_SYSTEM_RATE_ERROR',
)<RateWrapperDto, RateDto, string>();

export const createHealthSystemRateActions = createAsyncAction(
    'CREATE_HEALTH_SYSTEM_RATE_REQUEST',
    'CREATE_HEALTH_SYSTEM_RATE_SUCCESS',
    'CREATE_HEALTH_SYSTEM_RATE_ERROR',
)<RateWrapperDto, RateDto, string>();


export const healthSystemListShouldRefresh = createAction('REFRESH_HEALTH_SYSTEM_LIST')<boolean>();
export const healthSystemShouldRefresh = createAction('REFRESH_HEALTH_SYSTEM_ID')<string>();
//export const healthSystemImportHistoryShouldRefresh = createAction('REFRESH_HEALTH_SYSTEM_IMPORT_HISTORY')<boolean>();