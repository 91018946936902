import React from 'react';
import { FormikHandlers, FormikErrors, getIn, FormikHelpers } from 'formik';
// material ui
import { Grid, TextField, MenuItem, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import phoneNumberField from '../../../common/components/phoneNumberField';
import extensionField from '../../../common/components/extensionField';
import { ContactDto } from '../contacts.dtos';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { PhoneDto } from '../../../common/dtos/phone';
import { TextSelect } from '../contacts.styles';


interface ContactPhoneProps extends React.HTMLProps<React.ReactNode> {
  phone: PhoneDto;
  errors: FormikErrors<ContactDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  index: number;
  setShouldBlockNavigation: (o: boolean) => void;
  phones: PhoneDto[];
  setFieldValue: FormikHelpers<ContactDto>["setFieldValue"];
  isReadOnly: boolean;
}

const ContactPhone: React.FC<ContactPhoneProps> = (
  {
    phone,
    errors,
    handleChange,
    handleBlur,
    index,
    setShouldBlockNavigation,
    setFieldValue,
    phones,
    isReadOnly
  }) => {

  const handlePrimaryPhones = () => {
    for (let i = 0; i < phones.length; i++) {
      if (i !== index) {
        setFieldValue(`phones[${i}].isPrimary`, false);
      }
    }
  }

  return (
    <React.Fragment key={'phoneFragment' + index}>
      <Grid item sm={12} md={6} lg={3} key={'phone_contactTypeId' + index}>
        <TextSelect
          name={`phones[${index}].contactTypeId`}
          value={phone.contactTypeId || 1}
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          disabled={isReadOnly}

          select
          fullWidth
          data-cy="template_phoneTypeDropDown"
        >
          <MenuItem value={0} disabled>Phone Type</MenuItem>
          <MenuItem value={2}>Home Phone</MenuItem>
          <MenuItem value={1}>Mobile Phone</MenuItem>
          <MenuItem value={3}>Work Phone</MenuItem>
        </TextSelect>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} key={'phone_phoneNumber' + index}>
        <TextField
          key={'phoneNumber_field' + index}
          id={`phones[${index}].phoneNumber`}
          size="small"
          margin="none"
          value={phone.phoneNumber || ''}
          label="Phone Number"
          disabled={isReadOnly}
          name={`phones[${index}].phoneNumber`}
          onChange={e => { handleChange(e); }}
          onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
          error={Boolean(getIn(errors, `phones[${index}].phoneNumber`))}
          helperText={getIn(errors, `phones[${index}].phoneNumber`) && getIn(errors, `phones[${index}].phoneNumber`)}
          data-cy={`contact_phone_${index}_phoneNumberInput`}
          fullWidth
          InputProps={{ inputComponent: (phoneNumberField as any) }}
        />
      </Grid>
      <Grid item sm={12} md={6} lg={3} key={'phone_extension' + index}>
        <TextField
          key={'extension_field' + index}
          id={`phones[${index}].extension`}
          size="small"
          margin="none"
          value={phone.extension || ''}
          label="Extension"
          disabled={isReadOnly}
          name={`phones[${index}].extension`}
          onChange={e => { handleChange(e); }}
          onBlur={handleBlur}
          error={Boolean(getIn(errors, `phones[${index}].extension`))}
          helperText={getIn(errors, `phones[${index}].extension`) && getIn(errors, `phones[${index}].extension`)}
          data-cy={`contact_phone_${index}_phoneExtensionInput`}
          fullWidth
          InputProps={{ inputComponent: (extensionField as any) }}
        />
      </Grid>
      <Grid item xs={2} sm={10} hidden={phones.length === 1} />
      <Grid item xs={10} sm={2} hidden={phones.length === 1}>
        <FormControlLabel
          id={`phones[${index}].isPrimary`}
          name={`phones[${index}].isPrimary`}
          onChange={(e) => { handleChange(e); handlePrimaryPhones(); }}
          onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
          value={phone.isPrimary}
          checked={phone.isPrimary}
          disabled={isReadOnly}
          control={<Checkbox disabled={isReadOnly}
            icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} size="small"
            data-cy={`employer_phone_${index}_phonePrimaryInput`}
          />}
          label={<Typography variant="subtitle2">Primary</Typography>}
        />
      </Grid>
    </React.Fragment>
  )
}

export default ContactPhone;
