import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, FieldArray } from 'formik';
import { getType } from 'typesafe-actions';
import { useParams, Prompt, useHistory } from 'react-router-dom';
import { Grid, Checkbox, FormControlLabel, Typography, Card, CardContent, TextField, Link } from '@material-ui/core';
import Label from '../../../../common/components/label';
import { rootSelector } from '../../../../common/selectors/selectors';
import { createHealthSystemsActions, updateHealthSystemsActions, getHealthSystemsByIdActions } from '../healthSystems.actions';
import { HealthSystemDto, HealthSystemInit, healthGroupValidationSchema, HealthSystemGroupinit, healthSystemValidationSchema } from '../healthSystems.dtos';
import BottomActionFormBar from '../../../../common/components/bottomActionFormBar';
import { isReadOnly } from '../../../../common/dtos/auth';
import EditHealthGroupDialog from './editHealthGroupDialog';
import HealthGroupWarningDialog from './healthGroupWarningDialog';
import { Checkbox1, StyledTextField } from '../../../../common/styles/styles';
import { AddButton, HealthSystemCheckbox } from '../healthSystems.styles';
import { FeatureTypeEnum } from '../../../../common/enums/featureTypeEnum';


const HealthSystemDetailsSettings: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { healthSystemId } = useParams<{ healthSystemId: string }>();
  const healthSystem = rootSelector(state => state.healthSystems.getHealthSystemsById);

  const [dto, setDto] = React.useState<HealthSystemDto>(HealthSystemInit);
  const [dtoToSave, setDtoToSave] = React.useState<HealthSystemDto | undefined>();
  const [selectedHealthGroupIndex, setSelectedHealthGroupIndex] = React.useState<number>(0);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [dialogWarningOpen, setDialogWarningOpen] = React.useState<boolean>(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = React.useState(false);
  const [readOnlyStatus, setReadOnlyStatus] = React.useState(false);


  React.useEffect(() => {
    let s = isReadOnly(FeatureTypeEnum.Settings);
    setReadOnlyStatus(s);
  }, [readOnlyStatus]);

  React.useEffect(() => {
    if (healthSystemId !== undefined && healthSystemId !== 'new')
      dispatch({ type: getType(getHealthSystemsByIdActions.request), payload: healthSystemId });
  }, [dispatch, healthSystemId]);

  React.useEffect(() => {
    setDto(healthSystem);
    setDtoToSave(undefined);
  }, [healthSystem]);


  const onDialogOpen = (index: number) => {
    setSelectedHealthGroupIndex(index);
    setDialogOpen(true);
  };
  const onDialogClose = () => setDialogOpen(false);

  const onDialogWarningOpen = () => setDialogWarningOpen(true);
  const onDialogWarningClose = () => setDialogWarningOpen(false);

  const handleCancel = () => {
    history.push("/settings/healthSystems");
  }

  const handleSave = (values: (HealthSystemDto | undefined)) => {
    let newDto = values === undefined ? dtoToSave : values;
    if (newDto !== undefined) {
      dispatch({
        type: newDto.healthSystemId ?
          getType(updateHealthSystemsActions.request) :
          getType(createHealthSystemsActions.request),
        payload: newDto
      });
    }
  }


  return <>
    <Card>
      <CardContent>
        <Formik initialValues={dto}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={healthSystemValidationSchema} 
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => { setSubmitting(false); }, 700);
            setDtoToSave(values);

            if (values.groups === undefined || values.groups.length === 0)
              onDialogWarningOpen();
            else
              handleSave(values);
            
          }}
          enableReinitialize={true}
        >
          {(props) => {
            const { values, touched, errors, handleBlur, handleChange, handleSubmit, isSubmitting, setFieldValue } = props;
            return (
              <form onSubmit={handleSubmit} data-cy="healthSystem_details_addUserNameForm">
                {dialogOpen &&
                  <EditHealthGroupDialog
                  open={dialogOpen}
                  onClose={onDialogClose}
                  setFieldValue={setFieldValue}
                  item={values}
                  healthGroupIndex={selectedHealthGroupIndex}
                  setShouldBlockNavigation={setShouldBlockNavigation}
                  />}
                {dialogWarningOpen &&
                  <HealthGroupWarningDialog
                    open={dialogWarningOpen}
                    onCancel={onDialogWarningClose}
                    handleAgree={handleSave}
                  />}
                <Grid container data-cy="healthSystems_details_Container">
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      style={{ paddingRight: 10 }}
                      id="healthSystemName"
                      fullWidth
                      disabled={readOnlyStatus}
                      data-cy="healthSystems_details_name"
                      margin="none" size="small"
                      value={(values.healthSystemName || '')}
                      label="Health System Name"
                      name="healthSystemName"
                      onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
                      onBlur={handleBlur}
                      helperText={(errors.healthSystemName && touched.healthSystemName) && errors.healthSystemName}
                    />

                  </Grid>

                  <Grid item xs={1} sm={1}>
                    <Checkbox1
                      onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
                      onBlur={handleBlur}
                      name="isActive"
                      id="isActive"
                      disabled={readOnlyStatus}
                      value={values.isActive}
                      checked={values.isActive}
                      control={<Checkbox disabled={readOnlyStatus}
                        size="small" data-cy="healthSystem_SetActiveStatus" />}
                      label={<Typography variant="subtitle2">Active</Typography>} />
                  </Grid>
                  <Grid item xs={7}></Grid>
                </Grid>

                <br />

                <FieldArray name="groups" render={arrayHelpers => (
                  <>
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <Grid item xs={12}><Typography variant="subtitle1">Groups</Typography>
                        </Grid>
                        <br />
                        <Grid item xs={12} hidden={readOnlyStatus} data-cy="healthSystems_groupName_form_grid">

                          <Formik initialValues={HealthSystemGroupinit}
                            validateOnChange={false}
                            validateOnBlur={false}
                            validationSchema={healthGroupValidationSchema}
                            onSubmit={(values, { resetForm }) => {
                              setTimeout(() => { resetForm(JSON.parse(JSON.stringify(HealthSystemGroupinit))); }, 500);
                              arrayHelpers.push(values);
                              setShouldBlockNavigation(true);
                            }}
                            enableReinitialize={true}
                          >
                            {(props) => {
                              const { values, touched, errors, handleChange, handleSubmit } = props;
                              return (
                                <>
                                  <StyledTextField
                                    size="small"
                                    margin="none"
                                    data-cy="healthSystems_details_addNewGroup"
                                    value={values.name}
                                    label="Enter Group Name"
                                    id="name"
                                    name="name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={readOnlyStatus}
                                    error={Boolean(errors.name)}
                                    helperText={(errors.name && touched.name) && errors.name}
                                    />

                                  <AddButton
                                    onClick={() => handleSubmit()}
                                    data-cy="healthSystem_details_addNewGroupButton"
                                    hidden={readOnlyStatus}
                                    variant="contained" color="primary">Add </AddButton>
                                </>);
                            }}
                          </Formik>

                        </Grid>
                      </Grid>

                    </Grid>
                  </>
                )} />

                <br />
                <br />

                <Grid container>
                  {values.groups.map((o, index) => (
                    <Grid item xs={12} sm={6} md={3} key={o.name + index}>
                      <FormControlLabel
                        name={`groups[${index}].isActive`}
                        checked={values.groups[index].isActive} 
                        value={values.groups[index].isActive}
                        onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
                        onBlur={handleBlur}
                        disabled={readOnlyStatus}
                        control={<HealthSystemCheckbox size="small" />}
                        label={<Link
                          onBlur={handleBlur}
                          onClick={(e: React.SyntheticEvent) => { e.preventDefault(); if (!readOnlyStatus) onDialogOpen(index); }}>
                          {o.name ?? '-No name on file-'}
                        </Link>}
                        data-cy="settings_HealthSystemGroups"
                      />
                    </Grid>
                  ))}

                </Grid>

                <br />
                <br />
                <br />

                <Grid item xs={12}><Typography variant="subtitle1">Employers</Typography></Grid>

                <br /><br />

                <Grid container spacing={2}>
                  {values.groups?.map((g, index) => (
                    <Grid item xs={12} sm={6} md={3} key={g.name + index}>
                      <Typography variant="subtitle1">{g.name}</Typography>

                      <Grid container>
                        {g?.isActive && g?.employers?.map((e, index) => (
                          <Grid item xs={12} key={e + index}>
                            <Label> {e}</Label>
                          </Grid>
                        ))}
                      </Grid>
                      <br />
                    </Grid>

                  ))}
                </Grid>

                <Prompt
                  when={shouldBlockNavigation}
                  message='You have unsaved edits, are you sure you want to leave?'
                />

                <BottomActionFormBar hidden={readOnlyStatus} cancelClickHandler={handleCancel} submitting={isSubmitting} featureType={FeatureTypeEnum.Settings} />
                <br />
                <br />
              </form>);
          }}
        </Formik>

      </CardContent>
    </Card>


  </>


}
export default HealthSystemDetailsSettings;