 
/* Autogenerated by Typewriter do NOT edit
   Dtos.tst is the generating template */


export interface IUisSiWitnessInformationOccurrence  { 
    
    id?: number;
    witnessName?: string;
    witnessJobTitle?: string; 
} 

export class IUisSiWitnessInformationOccurrence_PropertyAttributes {
    static readonly id_Attributes = {
      required: { value: true }, name: "Id", 
      };
       static readonly witnessName_Attributes = {
      stringLength: { maxLength: 60, }, required: { value: false }, name: "WitnessName", 
      };
       static readonly witnessJobTitle_Attributes = {
      stringLength: { maxLength: 30, }, required: { value: false }, name: "WitnessJobTitle", 
      };
      
}

