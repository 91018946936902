import { AxiosError } from 'axios';
import { ApiError } from './apiResponse';
import apiClient from './apiClient';
import { showNotification } from '../actionCreators/dispatchNotification';
import {
  EmailTemplateDto,
  EmailTemplateRequestWrapper,
} from './../dtos/emailTemplateDto';
// endpoints
const emailTemplateUrl = 'email-templates';
const downloadUrl = 'download/documents/witness-information?inState=';
const downloadDocUrl = 'download/documents/';

export const getEmailTemplatesApi = async () => {
  return await apiClient()
    .get(`${emailTemplateUrl}`)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, 'error');
        }
      } else {
        showNotification(
          'An error occurred while retrieving email templates.',
          'error'
        );
      }
      throw err;
    });
};

export const getEmailTemplateApi = async (
  request: EmailTemplateRequestWrapper
) => {
  var url =
    request.request.claimId === undefined
      ? `${emailTemplateUrl}/${request.request.id}`
      : `${emailTemplateUrl}/${request.request.id}?claimId=${
          request.request.claimId
        }${
          request.request.documentId !== undefined
            ? '&documentId=' + request.request.documentId
            : ''
        }`;
  return await apiClient()
    .get(url)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, 'error');
        }
      } else {
        showNotification(
          'An error occurred while retrieving email template.',
          'error'
        );
      }
      throw err;
    });
};

export const updateEmailTemplateApi = async (request: EmailTemplateDto) => {
  //if (request.supportingDocuments?.length !== 0 && request.supportingDocuments !== undefined) {
  const data = new FormData();
  request.supportingDocuments?.forEach((f) =>
    f.fileUpload !== undefined ? data.append(f.fileName, f.fileUpload) : null
  );
  data.append('model', JSON.stringify(request));

  return await apiClient()
    .put(`${emailTemplateUrl}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    .then((res) => {
      showNotification('Email Template Updated', 'success');
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, 'error');
        }
      } else {
        showNotification(
          'An error occurred while updating a Email Template.',
          'error'
        );
      }
      throw err;
    });
};

export const createEmailTemplateApi = async (request: EmailTemplateDto) => {
  const data = new FormData();
  request.supportingDocuments?.forEach((f) => {
    if (f.fileUpload !== undefined) data.append(f.fileName, f.fileUpload);
  });
  data.append('model', JSON.stringify(request));
  return await apiClient()
    .post(`${emailTemplateUrl}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((res) => {
      showNotification('Email Template Created', 'success');
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, 'error');
        }
      } else {
        showNotification(
          'An error occurred while creating a Email Template.',
          'error'
        );
      }
      throw err;
    });
};

export const downloadWitnessInfo = async (request: boolean) => {
  return await apiClient()
    .get(`${downloadUrl}${encodeURIComponent(request)}`, {
      responseType: 'blob',
    })
    .then((res) => {
      var filename = 'witness_information' + new Date().toLocaleDateString();

      let blob = new Blob([res.data], { type: 'application/pdf' });
      let downloadUrl = window.URL.createObjectURL(blob);
      let link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      setTimeout(function () {
        URL.revokeObjectURL(downloadUrl);
      }, 100);
      return true;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, 'error');
        }
      } else {
        showNotification(
          'An error occurred while retrieving email template.',
          'error'
        );
      }
      throw err;
    });
};

export const downloadActionListDocument = async (request: string) => {
  if (request.length === 0) return false;
  return await apiClient()
    .get(`${downloadDocUrl}${encodeURIComponent(request)}`, {
      responseType: 'blob',
    })
    .then((res) => {
      let blob = new Blob([res.data], { type: 'application/pdf' });
      let downloadUrl = window.URL.createObjectURL(blob);
      let link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', request);
      document.body.appendChild(link);
      link.click();

      setTimeout(function () {
        URL.revokeObjectURL(downloadDocUrl);
      }, 100);
      return true;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) {
          showNotification(`${request} not found.`, 'error');
          //showNotification(axiosError.response.data.error.message, 'error');
        }
      } else {
        showNotification(
          'An error occurred while retrieving action list document.',
          'error'
        );
      }
      throw err;
    });
};

export const downloadSupportingDocument = async (request: string) => {
  if (request.length === 0) return false;
  return await apiClient()
    .get(`${downloadDocUrl}${request}`, {
      responseType: 'blob',
    })
    .then((res) => {
      let blob = new Blob([res.data], { type: 'application/pdf' });
      let downloadUrl = window.URL.createObjectURL(blob);
      let link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', request);
      document.body.appendChild(link);
      link.click();

      setTimeout(function () {
        URL.revokeObjectURL(downloadDocUrl);
      }, 100);
      return true;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, 'error');
        }
      } else {
        showNotification(
          'An error occurred while retrieving supporting document.',
          'error'
        );
      }
      throw err;
    });
};
