export interface FileUploadDto {
  fileUpload: File;
  fileName: string;
}


export interface EmailTemplateRequestDto {
  id?: string;
  claimId?: string;
  documentId?: string;
}


export interface EmailTemplateDto {
  id?: string; 
  isActive: boolean; 
  label: string; 
  state?: string; 
  stateId?: number;
  emailTemplateTypeId: number;
  emailTemplateUserTypeId: number; 
  subject: string;  
  body: string;
  supportingDocuments?: FileUploadDto[];
  shouldDownloadActionListDocument: boolean;
  shouldSendSecureLink: boolean;
}


export interface EmailTemplateRequestWrapper {
  fileName?: string;
  request: EmailTemplateRequestDto;
}


export const emailTemplateInit: EmailTemplateDto = {
  isActive: true,
  label: '',
  state: '',
  subject: '',
  body: '',
  emailTemplateTypeId: 0,
  emailTemplateUserTypeId: 0,
  shouldDownloadActionListDocument: false,
  shouldSendSecureLink: false,
};

export const emailTemplateWrapperInit: EmailTemplateRequestWrapper = {
  request: emailTemplateInit
}