export interface CompanyCustomerSettingsDto {
  settingId?: string, //($uuid)
  settingTypeId: number,// SettingTypesinteger($int32)
  settings: CompanySettingsDto[],
  isActive: boolean
}

export interface CompanySettingsDto {
  setting: string,
  isActive: boolean,
  id?: string,
}

export const companySettingsInit: CompanySettingsDto = { setting: '', isActive: true, id: undefined };
export const companyCustomerSettingsInit: CompanyCustomerSettingsDto[] = [
  {
    "settingId": '',
    "settingTypeId": 1,
    "settings": [
    ],
    "isActive": true
  },
  {
    "settingId": '',
    "settingTypeId": 2,
    "settings": [
    ],
    "isActive": true
  },
  {
    "settingId": '',
    "settingTypeId": 3,
    "settings": [
    ],
    "isActive": true
  },
]

export const setting1init: CompanyCustomerSettingsDto = {

  settingId: '', //($uuid)
  settingTypeId: 1,// SettingTypesinteger($int32)
  settings: [],
  isActive: true
};

export const setting2init: CompanyCustomerSettingsDto = {

  settingId: '', //($uuid)
  settingTypeId: 2,// SettingTypesinteger($int32)
  settings: [],
  isActive: true
};

export const setting3init: CompanyCustomerSettingsDto = {

  settingId: '', //($uuid)
  settingTypeId: 3,// SettingTypesinteger($int32)
  settings: [],
  isActive: true
};