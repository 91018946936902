


import { ISelectOption } from "../dtos/SelectOptionDto";
enum RemunerationTypeCodes {
        Item1 = 0,
        Item2 = 1,
        Item3 = 2,
        Item4 = 3,
        Item6 = 4,
        Item7 = 5,
        Item8 = 6,
        Item9 = 7,
        Item10 = 8,
        Item11 = 9,
        Item12 = 10,
        Item13 = 11,
        Item14 = 12,
        Item15 = 13,
         
}

export class RemunerationTypeCodes_class {

public static enum = RemunerationTypeCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['Item1']: 0,
    ['Item2']: 1,
    ['Item3']: 2,
    ['Item4']: 3,
    ['Item6']: 4,
    ['Item7']: 5,
    ['Item8']: 6,
    ['Item9']: 7,
    ['Item10']: 8,
    ['Item11']: 9,
    ['Item12']: 10,
    ['Item13']: 11,
    ['Item14']: 12,
    ['Item15']: 13,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "1- Severance",
    [1]: "2- Separation",
    [2]: "3- Vacation",
    [3]: "4- Holiday/Floating Holiday",
    [4]: "6- Profit Sharing",
    [5]: "7- Bonus Pay",
    [6]: "8- Wages in Lieu of Notice",
    [7]: "9- Back Pay Award",
    [8]: "10- Residual Payments",
    [9]: "11- Commissions",
    [10]: "12- Sick",
    [11]: "13- Disability",
    [12]: "14- Not Listed Above",
    [13]: "15- Supplemental Pay",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "1- Severance"},
  {value: 1, text: "2- Separation"},
  {value: 2, text: "3- Vacation"},
  {value: 3, text: "4- Holiday/Floating Holiday"},
  {value: 4, text: "6- Profit Sharing"},
  {value: 5, text: "7- Bonus Pay"},
  {value: 6, text: "8- Wages in Lieu of Notice"},
  {value: 7, text: "9- Back Pay Award"},
  {value: 8, text: "10- Residual Payments"},
  {value: 9, text: "11- Commissions"},
  {value: 10, text: "12- Sick"},
  {value: 11, text: "13- Disability"},
  {value: 12, text: "14- Not Listed Above"},
  {value: 13, text: "15- Supplemental Pay"},
  ]
}

