import React from 'react';
import { Prompt } from 'react-router';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  Dialog, DialogContent, DialogTitle, Grid, TextField, DialogActions,
  MenuItem
} from '@material-ui/core';
import { getType } from 'typesafe-actions';
import moment from 'moment';
import { KeyboardDatePicker, } from '@material-ui/pickers';
import { rootSelector } from '../../../common/selectors/selectors';
import { updateEligibilityEventActions, createEligibilityEventActions } from '../actions'
import { ClaimDto, EligibilityEventDto, EligibilityEventInit, ClaimInit } from '../claims.dtos'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { EligibilityCodeDto } from '../../settings/codes/codes.dtos';
import { getFullName } from '../../../common/dtos/auth';
import { PaddedTextField, StyledCardContent, ButtonGrid, StyledSubmitButton, StyledCancelButton } from '../../../common/styles/styles';
import { DialogInputLabel } from '../claims.styles';
import { requiredError } from '../../../common/constants/strings';


interface Props {
  open: boolean;
  onCancel: () => void;
  existingEligibilityEvent?: EligibilityEventDto;
  isClientUser: boolean;
  isReadOnly: boolean;
  byb: Date | undefined;
  bye: Date | undefined;
}

const EligibilityEventDialog: React.FC<Props> = ({
  open,
  onCancel,
  existingEligibilityEvent,
  isClientUser,
  isReadOnly,
  byb,
  bye
}) => {

  const [dto, setDto] = React.useState(EligibilityEventInit as EligibilityEventDto)
  const [claim, setClaim] = React.useState(ClaimInit as ClaimDto)

  const dispatch = useDispatch();

  const systemClaim = rootSelector(state => state.claims.claim);
  const eligibilityCodes = rootSelector(state => state.codes.eligibilityCodes);


  React.useEffect(() => {
    setClaim(systemClaim);
  }, [systemClaim]);

  React.useEffect(() => {
    if (existingEligibilityEvent !== undefined) {
      setDto(existingEligibilityEvent);
    }
  }, [existingEligibilityEvent]);


  const cancelDialog = () => onCancel();


  const handleIneligibleStartDateChange = (startDate: MaterialUiPickersDate | Date | null | undefined | string, endDate: Date | undefined, setFieldValue: FormikHelpers<EligibilityEventDto>["setFieldValue"]) => {
    if (startDate !== undefined && startDate !== null && ((startDate as moment.Moment).isValid())) {
      if (endDate === null || endDate === undefined) {
        setFieldValue('coverageStartDate', startDate)
      }
      else if (endDate && startDate && moment(startDate).format('yyyy-MM-DD') !== '0001-01-01') {
        if (moment(startDate).format('yyyy-MM-DD') > moment(endDate).format('yyyy-MM-DD')) {
          setFieldValue('ineligibleEndDate', startDate);
        }
      }
    }
  }

  const handleIneligibleEndDateChange = (endDate: MaterialUiPickersDate | Date | null | undefined | string, startDate: Date | undefined, setFieldValue: FormikHelpers<EligibilityEventDto>["setFieldValue"]) => {
    if (endDate !== undefined && endDate !== null && ((endDate as moment.Moment).isValid())) {
      if (startDate === null || startDate === undefined) {
        setFieldValue('coverageStartDate', endDate)
      }
      else if (startDate && endDate && moment(endDate).format('yyyy-MM-DD') !== '0001-01-01') {

        if (moment(endDate).format('yyyy-MM-DD') < moment(startDate).format('yyyy-MM-DD')) {
          setFieldValue('ineligibleStartDate', endDate);
        }
      }
    }

  }


  return <Formik
    initialValues={dto}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={Yup.object().shape({
      eligibilityCodeId: Yup.string().max(200, 'Must be 200 characters or less').required(requiredError).notOneOf(['00000000-0000-0000-0000-000000000000'], requiredError).nullable(),
      ineligibleStartDate: Yup.date().nullable(),
      ineligibleEndDate: Yup.date().nullable(),
      caseNumber: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
      comments: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
    })
    }
    onSubmit={(values, { setSubmitting, resetForm }) => {
      setTimeout(() => {
        setSubmitting(false);
        resetForm();
        onCancel();
      }, 700);

      let request = values;
        request.claimId = claim.id;
        request.createdBy = getFullName();


      dispatch({
        type: values.id ?
          getType(updateEligibilityEventActions.request) :
          getType(createEligibilityEventActions.request),
        payload: request
      });
      
    }}
    enableReinitialize={true}
  >
    {(props) => {
      const {
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched
      } = props;
      return (
        <Dialog
          open={open}
          data-cy="eligibility_event_dialog"
        >
          <DialogTitle>Add Event - Eligibility</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <StyledCardContent>
                <Grid container alignContent="center" item xs={12}>


                  <Grid item xs={5}>
                    <DialogInputLabel>Eligibility Issue</DialogInputLabel>
                    <TextField
                      id="eligibilityCodeId"
                      name="eligibilityCodeId"
                      onChange={handleChange}
                      value={values.eligibilityCodeId ?? ''}
                      disabled={isClientUser || isReadOnly}

                      select
                      fullWidth
                      data-cy="eligibility_event_codeId"
                      onBlur={handleBlur}
                      error={Boolean(errors.eligibilityCodeId)}
                      helperText={errors.eligibilityCodeId}>
                      <MenuItem value={''} disabled>Please select an item</MenuItem>
                      {eligibilityCodes.map((option, index) => (
                        <MenuItem key={option.name + index} value={option.id}>{option.name} - {option.description}</MenuItem>
                      ))}
                    </TextField>

                  </Grid>
                  <Grid item xs={7}></Grid>

                  <Grid item xs={5}>
                    <DialogInputLabel>Start Date of Ineligibile Period</DialogInputLabel>

                    <KeyboardDatePicker
                      margin="normal"
                      id="ineligibleStartDate"
                      name="ineligibleStartDate"
                      disabled={isClientUser || isReadOnly}
                      format="MM/DD/yyyy"
                      fullWidth
                      data-cy="appellate_event_ineligibleStartDate"
                      value={values.ineligibleStartDate}
                      onChange={(date: MaterialUiPickersDate) => { setFieldValue('ineligibleStartDate', date); handleIneligibleStartDateChange(date, values.ineligibleEndDate, setFieldValue); }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5}>
                    <DialogInputLabel>BYB</DialogInputLabel>

                    <KeyboardDatePicker
                      margin="normal"
                      id="byb"
                      name="byb"
                      disabled={true}
                      format="MM/DD/yyyy"
                      fullWidth
                      value={byb}
                      onChange={(date: MaterialUiPickersDate) => { }}
                      KeyboardButtonProps={{
                        'aria-label': 'view byb date',
                      }}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <DialogInputLabel>End Date of Ineligibile Period</DialogInputLabel>
                    <KeyboardDatePicker
                      margin="normal"
                      id="ineligibleEndDate"
                      name="ineligibleEndDate"
                      disabled={isClientUser || isReadOnly}
                      format="MM/DD/yyyy"
                      fullWidth
                      data-cy="appellate_event_ineligibleEndDate"
                      value={values.ineligibleEndDate}
                      onChange={(date: MaterialUiPickersDate) => { setFieldValue('ineligibleEndDate', date); handleIneligibleEndDateChange(date, values.ineligibleStartDate, setFieldValue); }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5}>
                    <DialogInputLabel>BYE</DialogInputLabel>

                    <KeyboardDatePicker
                      margin="normal"
                      id="bye"
                      name="bye"
                      disabled={true}
                      format="MM/DD/yyyy"
                      fullWidth
                      value={bye}
                      onChange={(date: MaterialUiPickersDate) => { }}
                      KeyboardButtonProps={{
                        'aria-label': 'view bye date',
                      }}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <DialogInputLabel>Case #</DialogInputLabel>

                    <PaddedTextField
                      id="caseNumber"
                      size="small"
                      margin="none"
                      disabled={isClientUser || isReadOnly}
                      value={values.caseNumber || ''}
                      name="caseNumber"
                      onChange={e => { handleChange(e) }}
                      onBlur={handleBlur}
                      error={(Boolean(errors.caseNumber && touched.caseNumber))}
                      helperText={(Boolean(errors.caseNumber) && touched.caseNumber) && errors.caseNumber}
                      data-cy="eligibility_event_caseNumber"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={7}></Grid>

                  <Grid item xs={5}>
                    <DialogInputLabel>Comments</DialogInputLabel>
                    <PaddedTextField
                      id="comments"
                      size="small"
                      margin="none"
                      multiline
                      disabled={isClientUser || isReadOnly}

                      value={values.comments || ''}
                      name="comments"
                      onChange={e => { handleChange(e) }}
                      onBlur={handleBlur}
                      error={(Boolean(errors.comments && touched.comments))}
                      helperText={(Boolean(errors.comments) && touched.comments) && errors.comments}
                      data-cy="eligibility_event_comments"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={7}></Grid>
                </Grid>
              </StyledCardContent>

              <DialogActions>
                <ButtonGrid container>
                  <StyledSubmitButton
                    type="button"
                    color="primary"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting || isClientUser || isReadOnly}
                    data-cy="eligibility_event_submitButton"
                  >
                    Save
                  </StyledSubmitButton>

                  <StyledCancelButton
                    data-cy="eligibility_event_cancelButton"
                    color="secondary"
                    onClick={cancelDialog}
                  >
                    Cancel
                  </StyledCancelButton>
                </ButtonGrid>
              </DialogActions>
            </form>
          </DialogContent>
          <Prompt
            // when={shouldBlockNavigation}
            message='You have unsaved edits, are you sure you want to leave?'
          />
        </Dialog>
      );
    }}
  </Formik>
}

export default EligibilityEventDialog;
