import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { BatchPaymentsDto, ListBatchPaymentDto, MiWamDto } from './dtos';
import { getBatchPaymentsListActions, getBatchPaymentActions, getClaimPaymentsActions, getMiWamDataActions } from './actions';

const batchPaymentsReducer = combineReducers({
  list: createReducer([] as ListBatchPaymentDto[])
    .handleAction([ getBatchPaymentsListActions.success, ], (state, action) => action.payload),

  batch: createReducer({ isComplete: false, reportedWageTypeId: 0, batchPaymentTypeId: 0, charges: [] } as BatchPaymentsDto)
    .handleAction([ getBatchPaymentActions.success ], (state, action) => action.payload),

  claimPayments: createReducer([] as BatchPaymentsDto[])
    .handleAction([ getClaimPaymentsActions.success, ], (state, action) => action.payload),

  miWamData: createReducer([] as MiWamDto[])
    .handleAction([ getMiWamDataActions.success, ], (state, action) => action.payload),
});


export default batchPaymentsReducer;