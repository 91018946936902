import React from "react";
import {
  claimantProfileReportRequestValidationSchema,
  ClaimantProfileReportParametersDto,
} from "../dtos";
import { FormikHelpers, Formik } from "formik";
import SearchIcon from "@material-ui/icons/Search";
import {
  Grid,
  Link,
  Typography,
  Button,
  Card,
  CardContent,
  InputAdornment,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import {
  PaddedTextField,
  TableCard,
  ListCardContent,
  CustomColorTypography,
} from "../../../common/styles/styles";
import { useDispatch } from "react-redux";
import { rootSelector } from "../../../common/selectors/selectors";
import { claimantReportActions } from "../../../common/actions/claimant";
import {
  ClaimantReportDto,
  ClaimantReportRequest,
} from "../../../common/dtos/claimantReportDto";
import { getType } from "typesafe-actions";
import moment from "moment";
import { ClaimStatusEnum_class } from "../../../common/enums/claimStatusEnum";
import theme from "../../../common/themes/theme";

interface Props extends React.HTMLProps<React.ReactNode> {
  setFieldValue: FormikHelpers<ClaimantProfileReportParametersDto>["setFieldValue"];
  submit: (e?: React.FormEvent<HTMLFormElement>) => void;
}

const ClaimantProfileReportParameters: React.FC<Props> = ({
  setFieldValue,
  submit,
}) => {
  const dispatch = useDispatch();

  const systemReportClaimants = rootSelector(
    (state) => state.claimants.claimantReportList
  );

  const [claimants, setClaimants] = React.useState<ClaimantReportDto[]>([]);
  const [sortByDescending, setSortByDescending] =
    React.useState<boolean>(false);
  const [isClosing, setIsClosing] = React.useState<boolean>(false);
  const [resultsCount, setResultsCount] = React.useState<number>(-1);

  React.useEffect(
    () => setClaimants(systemReportClaimants),
    [systemReportClaimants]
  );

  const handleSelectClaiment = (claimant: ClaimantReportDto) => {
    setFieldValue("claimId", claimant.claimId);
    setFieldValue("claimantName", claimant.claimantName);
    setFieldValue("displayParameter", claimant.display);
    setIsClosing(true);
    dispatch({ type: getType(claimantReportActions.success), payload: [] });
    setTimeout(() => {
      submit();
    }, 700);
    setResultsCount(-1);
  };

  return (
    <>
      <Formik
        initialValues={
          {
            display: "ssn",
          } as ClaimantReportRequest
        }
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={claimantProfileReportRequestValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            setResultsCount(resultsCount + 1);
          }, 700);
          dispatch({
            type: getType(claimantReportActions.request),
            payload: values,
          });
        }}
        enableReinitialize={true}
      >
        {(props) => {
          const {
            values,
            errors,
            handleChange,
            handleSubmit,
            handleBlur,
            handleReset,
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={2}>
                  <PaddedTextField
                    id="ssn"
                    name="ssn"
                    size="small"
                    margin="none"
                    label="SSN"
                    value={values.ssn || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.ssn)}
                    helperText={errors.ssn && errors.ssn}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          <IconButton
                            edge="start"
                            title="Search SSN"
                            aria-label="Search SSN Icon"
                            color="secondary"
                            type="submit"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <PaddedTextField
                    id="suta"
                    name="suta"
                    size="small"
                    margin="none"
                    label="SUTA"
                    value={values.suta || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.suta)}
                    helperText={errors.suta && errors.suta}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          <IconButton
                            edge="start"
                            title="Search SUTA"
                            aria-label="Search SUTA Icon"
                            color="secondary"
                            type="submit"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <PaddedTextField
                    id="searchString"
                    name="searchString"
                    size="small"
                    margin="none"
                    label="Claimant Name"
                    value={values.searchString || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.searchString)}
                    helperText={errors.searchString && errors.searchString}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          <IconButton
                            edge="start"
                            title="Search"
                            aria-label="Search Icon"
                            color="secondary"
                            type="submit"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <PaddedTextField
                    id="employeeId"
                    name="employeeId"
                    size="small"
                    margin="none"
                    label="Employee ID"
                    value={values.employeeId || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.employeeId)}
                    helperText={errors.employeeId && errors.employeeId}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          <IconButton
                            edge="start"
                            title="Search"
                            aria-label="Search Icon"
                            color="secondary"
                            type="submit"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <FormLabel id="display-radio-buttons-group">
                      Display
                    </FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="display-radio-buttons-group"
                      name="display"
                      value={values.display}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="ssn"
                        control={<Radio />}
                        label="Last 4 SSN"
                      />

                      <FormControlLabel
                        value="employeeId"
                        control={<Radio />}
                        label="Employee ID"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  spacing={2}
                  alignContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={4}>
                    {/*<FormControlLabel*/}
                    {/*  id="sortDueDateToggle"*/}
                    {/*  name="sortDueDateToggle"*/}
                    {/*  label={<Typography variant="subtitle2">Date Created</Typography>}*/}
                    {/*  value={sortByDescending}*/}
                    {/*  checked={sortByDescending}*/}
                    {/*  onChange={() => setSortByDescending(!sortByDescending)} aria-label="Due Date Sort Toggle"*/}
                    {/*  labelPlacement="start"*/}
                    {/*  control={<Checkbox checkedIcon={<ImportExportIconBlue />}*/}
                    {/*    icon={<ImportExportIconNotSelected />}*/}
                    {/*    size="small" />}*/}
                    {/*/>*/}
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button
                      title="CLEAR FILTERS BUTTON"
                      color="secondary"
                      onClick={() => {
                        handleReset();
                        setTimeout(() => {
                          handleSubmit();
                        }, 700);
                      }}
                    >
                      <Typography color="primary" variant="subtitle2">
                        CLEAR FILTERS
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    {/*<Button title="FILTER BUTTON" variant={false ? "outlined" : "text"} color="secondary" onClick={() => { handleSubmit(); }} data-cy="actionItems_filter">*/}
                    {/*  <Typography color="primary" variant="subtitle2">FILTER</Typography>*/}
                    {/*</Button>*/}
                  </Grid>
                </Grid>

                {claimants.length === 0 ? (
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                        >
                          {(values.searchString === undefined &&
                            values.suta === undefined &&
                            values.ssn === undefined) ||
                          resultsCount < 0 ? (
                            <Typography variant="subtitle1" color="secondary">
                              Please search to find claimant records
                            </Typography>
                          ) : (
                            <Typography variant="subtitle1" color="secondary">
                              {isClosing ? "" : "No Records Found"}
                            </Typography>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                ) : (
                  claimants
                    .sort((a, b) => {
                      return sortByDescending
                        ? moment(a.createdDate).diff(moment(b.createdDate))
                        : moment(b.createdDate).diff(moment(a.createdDate));
                    })
                    .map((o, index) => (
                      <Grid item xs={12} key={"documentsList_item_" + index}>
                        <TableCard raised>
                          <ListCardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={10}>
                                <Typography
                                  variant="subtitle1"
                                  color="secondary"
                                >
                                  {o.label}
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Link
                                  component="button"
                                  onClick={() =>
                                    handleSelectClaiment({
                                      ...o,
                                      display: values.display,
                                    })
                                  }
                                >
                                  <CustomColorTypography
                                    variant="body2"
                                    color="inherit"
                                    ccolor={theme.secondaryColors.customTeal}
                                  >
                                    GENERATE REPORT
                                  </CustomColorTypography>
                                </Link>
                              </Grid>

                              <Grid item xs={12} sm={12} md={6} lg={2}>
                                <Typography variant="body2" color="textPrimary">
                                  Claim Start Date
                                </Typography>
                                <Typography variant="body2" color="secondary">
                                  {o.byb === undefined
                                    ? "None on File"
                                    : moment.utc(o.byb).format("MM/DD/yyyy") ||
                                      "None on File"}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={12} md={6} lg={2}>
                                <Typography variant="body2" color="textPrimary">
                                  Employer Contact
                                </Typography>
                                <Typography variant="body2" color="secondary">
                                  {o.employerContactFullName === undefined ||
                                  o.employerContactFullName.length === 0
                                    ? "None on File"
                                    : o.employerContactFullName}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={12} md={6} lg={2}>
                                <Typography variant="body2" color="textPrimary">
                                  State Code
                                </Typography>
                                <Typography variant="body2" color="secondary">
                                  {o.stateCode === undefined ||
                                  o.stateCode.length === 0
                                    ? "None on File"
                                    : o.stateCode}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={12} md={6} lg={2}>
                                <Typography variant="body2" color="textPrimary">
                                  SUTA
                                </Typography>
                                <Typography variant="body2" color="secondary">
                                  {o.suta === undefined || o.suta.length === 0
                                    ? "None on File"
                                    : o.suta}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={12} md={6} lg={2}>
                                <Typography variant="body2" color="textPrimary">
                                  SSN
                                </Typography>
                                <Typography variant="body2" color="secondary">
                                  {o.ssnLastFour === undefined ||
                                  o.ssnLastFour.length === 0
                                    ? "None on File"
                                    : o.ssnLastFour}
                                </Typography>
                              </Grid>

                              <Grid item xs={12} sm={12} md={6} lg={2}>
                                <Typography variant="body2" color="textPrimary">
                                  Claim Status
                                </Typography>
                                <Typography variant="body2" color="secondary">
                                  {ClaimStatusEnum_class.enumByNumber[
                                    o.claimStatusId
                                  ] || "None on File"}
                                </Typography>
                              </Grid>
                            </Grid>
                          </ListCardContent>
                        </TableCard>
                      </Grid>
                    ))
                )}
              </Grid>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default ClaimantProfileReportParameters;
