import React from "react";
import {
  FormikHandlers,
  FormikTouched,
  FormikErrors,
  FormikHelpers,
  getIn,
  ArrayHelpers,
  FieldArray,
} from "formik";

// material ui
import {
  Grid,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

import SsnTextField from "../../../common/components/ssnTextField";
import { ClaimDto, ClaimantPaymentInit } from "../claims.dtos";
import AddBoxRoundedIcon from "@material-ui/icons/AddBoxRounded";

import moment from "moment";
import MoneyField from "../../../common/components/numberFields";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { isAuthorized } from "../../../common/dtos/auth";
import {
  IndentGrid,
  PaddedTextField,
  TMGrid,
  FormIcon,
} from "../../../common/styles/styles";
import { SelectionTF } from "../claims.styles";

interface ClaimantProps extends React.HTMLProps<React.ReactNode> {
  values: ClaimDto;
  errors: FormikErrors<ClaimDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setShouldBlockNavigation: (o: boolean) => void;
  setFieldValue: FormikHelpers<ClaimDto>["setFieldValue"];
  separationPayTypeOptions: { name: string; id: number }[];
  isQuickEdit: boolean;
  handleClaimantFirstNameUpdates: (ClaimantFirstName: string) => void;
  handleClaimantLastNameUpdates: (ClaimantLastName: string) => void;
  isClientUser: boolean;
  isReadOnly: boolean;
}

const jobStatusOptions = [
  { name: "", id: 0 },
  { name: "Full-time", id: 1 },
  { name: "Part-time", id: 2 },
  { name: "On-call/Per diem", id: 3 },
  { name: "Seasonal", id: 4 },
  { name: "Temporary", id: 5 },
];

const ClaimantDetails: React.FC<ClaimantProps> = ({
  values,
  errors,
  handleChange,
  isReadOnly,
  isClientUser,
  handleBlur,
  setShouldBlockNavigation,
  setFieldValue,
  separationPayTypeOptions,
  isQuickEdit,
  handleClaimantFirstNameUpdates,
  handleClaimantLastNameUpdates,
}) => {
  const [isMasked, setIsMasked] = React.useState(!isAuthorized());

  const handleSSNMaskToggle = () => {
    setIsMasked(!isMasked);
  };

  const handleFirstDayWorkedDateChange = (
    startDate: MaterialUiPickersDate | Date | null | undefined | string,
    endDate: Date | undefined
  ) => {
    if (
      startDate !== undefined &&
      startDate !== null &&
      (startDate as moment.Moment).isValid()
    ) {
      if (endDate === null || endDate === undefined) {
        setFieldValue("dateLastWorked", startDate);
      } else if (
        endDate &&
        startDate &&
        moment(startDate).format("yyyy-MM-DD") !== "0001-01-01"
      ) {
        if (
          moment(startDate).format("yyyy-MM-DD") >
          moment(endDate).format("yyyy-MM-DD")
        ) {
          setFieldValue("dateLastWorked", startDate);
        }
      }
    }
  };

  const handleLastDayWorkedDateChange = (
    endDate: MaterialUiPickersDate | Date | null | undefined | string,
    startDate: Date | undefined
  ) => {
    if (
      endDate !== undefined &&
      endDate !== null &&
      (endDate as moment.Moment).isValid()
    ) {
      if (startDate === null || startDate === undefined) {
        setFieldValue("dateFirstWorked", endDate);
      } else if (
        startDate &&
        endDate &&
        moment(endDate).format("yyyy-MM-DD") !== "0001-01-01"
      ) {
        if (
          moment(endDate).format("yyyy-MM-DD") <
          moment(startDate).format("yyyy-MM-DD")
        ) {
          setFieldValue("dateFirstWorked", endDate);
        }
      }
    }
  };

  const handleGetMaskedSSNValue = (): string => {
    let v = values.claimantSsn ?? "";
    if (v.replace("-", "").length > 3) {
      return "***-**-" + v.replace("-", "").slice(-4);
    } else {
      return "***-**-";
    }
  };

  return (
    <IndentGrid
      container
      item
      xs={12}
      spacing={2}
      data-cy="claimant_details_container"
    >
      <Grid item xs={12} sm={5}>
        <PaddedTextField
          id="claimantFirstName"
          size="small"
          margin="none"
          value={values.claimantFirstName || ""}
          label="First Name"
          disabled={isClientUser || isReadOnly}
          name="claimantFirstName"
          onChange={(e) => {
            handleChange(e);
            handleClaimantFirstNameUpdates(e.target.value);
          }}
          onBlur={(e) => {
            handleBlur(e);
            setShouldBlockNavigation(true);
          }}
          error={Boolean(getIn(errors, "claimantFirstName"))}
          helperText={
            getIn(errors, "claimantFirstName") &&
            getIn(errors, "claimantFirstName")
          }
          data-cy="claim_claimant_firstName"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <PaddedTextField
          id="claimantMiddleInitial"
          size="small"
          margin="none"
          disabled={isClientUser || isReadOnly}
          value={values.claimantMiddleInitial || ""}
          label="MI"
          name="claimantMiddleInitial"
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={(e) => {
            handleBlur(e);
            setShouldBlockNavigation(true);
          }}
          error={Boolean(getIn(errors, "claimantMiddleInitial"))}
          helperText={
            getIn(errors, "claimantMiddleInitial") &&
            getIn(errors, "claimantMiddleInitial")
          }
          data-cy="claim_claimant_middleInitial"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={5}>
        <PaddedTextField
          id="claimantLastName"
          size="small"
          margin="none"
          disabled={isClientUser || isReadOnly}
          value={values.claimantLastName || ""}
          label="Last Name"
          name="claimantLastName"
          onChange={(e) => {
            handleChange(e);
            handleClaimantLastNameUpdates(e.target.value);
          }}
          onBlur={(e) => {
            handleBlur(e);
            setShouldBlockNavigation(true);
          }}
          error={Boolean(getIn(errors, "claimantLastName"))}
          helperText={
            getIn(errors, "claimantLastName") &&
            getIn(errors, "claimantLastName")
          }
          data-cy="claim_claimant_lastName"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        {!isMasked ? (
          <PaddedTextField
            id="claimantSsn"
            size="small"
            margin="none"
            value={values.claimantSsn || ""}
            label={"SSN"}
            name="claimantSsn"
            disabled={isClientUser || isReadOnly}
            InputLabelProps={{ required: true }}
            onChange={(e) => {
              handleChange(e);
              setShouldBlockNavigation(true);
            }}
            onBlur={(e) => {
              handleBlur(e);
              setShouldBlockNavigation(true);
            }}
            error={errors.claimantSsn !== undefined}
            helperText={errors.claimantSsn && errors.claimantSsn}
            data-cy="claim_claimant_Ssn"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  role="viewPasswordToggle"
                  onClick={(e) => {
                    handleSSNMaskToggle();
                  }}
                >
                  {!isAuthorized() ? null : isMasked ? (
                    <IconButton
                      tabIndex={-1}
                      aria-label="View SSN"
                      title="View SSN"
                    >
                      <VisibilityIcon color="primary" />
                    </IconButton>
                  ) : (
                    <IconButton title="Hide SSN" aria-label="Hide SSN">
                      <VisibilityOffIcon color="primary" />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
              inputComponent: SsnTextField as any,
            }}
          />
        ) : (
          <PaddedTextField
            id="claimantSsn"
            size="small"
            margin="none"
            value={handleGetMaskedSSNValue() || ""}
            label={"SSN"}
            disabled
            InputLabelProps={{ required: true }}
            name="claimantSsn"
            onBlur={(e) => {
              handleBlur(e);
              setShouldBlockNavigation(true);
            }}
            error={errors.claimantSsn !== undefined}
            helperText={errors.claimantSsn && errors.claimantSsn}
            data-cy="claim_claimant_SsnPostMask"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  role="viewPasswordToggle"
                  onClick={(e) => {
                    handleSSNMaskToggle();
                  }}
                >
                  {!isAuthorized() ? null : isMasked ? (
                    <IconButton aria-label="View SSN" title="View SSN">
                      <VisibilityIcon color="primary" />
                    </IconButton>
                  ) : (
                    <IconButton
                      tabIndex={-1}
                      title="Hide SSN"
                      aria-label="Hide SSN"
                    >
                      <VisibilityOffIcon color="primary" />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        )}
      </Grid>
      {!isQuickEdit ? (
        <>
          <Grid item xs={12}>
            <PaddedTextField
              id="claimantJobTitle"
              size="small"
              margin="none"
              value={values.claimantJobTitle || ""}
              label="Job Title"
              disabled={isClientUser || isReadOnly}
              name="claimantJobTitle"
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={(e) => {
                handleBlur(e);
                setShouldBlockNavigation(true);
              }}
              error={Boolean(getIn(errors, "claimantJobTitle"))}
              helperText={
                getIn(errors, "claimantJobTitle") &&
                getIn(errors, "claimantJobTitle")
              }
              data-cy="claim_claimant_jobTitle"
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="claimantJobStatus"
              size="small"
              margin="none"
              disabled={isClientUser || isReadOnly}
              select
              value={values.claimantJobStatus || ""}
              label="Job Status"
              name="claimantJobStatus"
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={(e) => {
                handleBlur(e);
                setShouldBlockNavigation(true);
              }}
              error={Boolean(errors.claimantJobStatus)}
              helperText={
                Boolean(errors.claimantJobStatus) && errors.claimantJobStatus
              }
              data-cy="claim_claimant_claimantJobStatus"
              fullWidth
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
            >
              {jobStatusOptions
                .filter((item) => item.name !== "")
                .map((option, index) => (
                  <MenuItem key={option.name + index} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <FieldArray
            name="claimantPaymentList"
            render={(arrayHelpers) => (
              <TMGrid item xs={12} container justifyContent="flex-end">
                <div hidden={isReadOnly}>
                  <FormIcon
                    title="Add to Payments List"
                    color="secondary"
                    onClick={() => {
                      arrayHelpers.push(ClaimantPaymentInit);
                    }}
                    data-cy="employer_details_addBusinessUnit"
                    disabled={isClientUser || isReadOnly}
                  >
                    <AddBoxRoundedIcon />
                  </FormIcon>
                </div>
              </TMGrid>
            )}
          />
          {values.claimantPaymentList &&
            values.claimantPaymentList.map((o, index) => (
              <React.Fragment key={"ClaimantPayments_" + index}>
                <Grid item xs={12} sm={12} md={12} lg={isQuickEdit ? 6 : 4}>
                  <SelectionTF
                    id={`claimantPaymentList[${index}].separationPayId`}
                    size="small"
                    margin="none"
                    disabled={isClientUser || isReadOnly}
                    select
                    value={o.separationPayId || ""}
                    label="Separation Pay"
                    name={`claimantPaymentList[${index}].separationPayId`}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      setShouldBlockNavigation(true);
                    }}
                    error={Boolean(
                      getIn(
                        errors,
                        `claimantPaymentList[${index}].separationPayId`
                      )
                    )}
                    helperText={
                      getIn(
                        errors,
                        `claimantPaymentList[${index}].separationPayId`
                      ) &&
                      getIn(
                        errors,
                        `claimantPaymentList[${index}].separationPayId`
                      )
                    }
                    data-cy="claim_claimant_separationPayType"
                    fullWidth
                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                  >
                    {separationPayTypeOptions
                      .filter((item) => item.name !== "")
                      .map((option, index) => (
                        <MenuItem key={option.name + index} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </SelectionTF>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={isQuickEdit ? 6 : 4}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="datePaid"
                    name={`claimantPaymentList[${index}].datePaid`}
                    label="Date Paid"
                    fullWidth
                    disabled={isClientUser || isReadOnly}
                    format="MM/DD/yyyy"
                    value={o.datePaid || null}
                    data-cy="claim_claimant_datePaid"
                    onChange={(date: MaterialUiPickersDate) =>
                      setFieldValue(
                        `claimantPaymentList[${index}].datePaid`,
                        date
                      )
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={isQuickEdit ? 12 : 4}>
                  <SelectionTF
                    id="amountPaid"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    size="small"
                    margin="none"
                    disabled={isClientUser || isReadOnly}
                    value={o.amountPaid || ""}
                    label="Amount Paid"
                    name={`claimantPaymentList[${index}].amountPaid`}
                    fullWidth
                    onBlur={(e) => {
                      handleBlur(e);
                      setShouldBlockNavigation(true);
                    }}
                    error={Boolean(
                      getIn(errors, `claimantPaymentList[${index}].amountPaid`)
                    )}
                    InputProps={{ inputComponent: MoneyField as any }}
                    data-cy="claim_claimant_amountPaid"
                  />
                </Grid>
              </React.Fragment>
            ))}

          <Grid item xs={12} sm={12} md={12} lg={isQuickEdit ? 6 : 4}>
            <KeyboardDatePicker
              margin="normal"
              id="dateFirstWorked"
              name="dateFirstWorked"
              label="First Day Worked"
              disabled={isClientUser || isReadOnly}
              format="MM/DD/yyyy"
              value={values.dateFirstWorked || null}
              data-cy="claim_claimant_dateFirstWorked"
              fullWidth
              onChange={(date: MaterialUiPickersDate) => {
                setFieldValue("dateFirstWorked", date);
                handleFirstDayWorkedDateChange(date, values.dateLastWorked);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={isQuickEdit ? 6 : 4}>
            <KeyboardDatePicker
              margin="normal"
              id="dateLastWorked"
              name="dateLastWorked"
              label="Last Day Worked"
              disabled={isClientUser || isReadOnly}
              format="MM/DD/yyyy"
              value={values.dateLastWorked || null}
              data-cy="claim_claimant_dateLastWorked"
              fullWidth
              onChange={(date: MaterialUiPickersDate) => {
                setFieldValue("dateLastWorked", date);
                handleLastDayWorkedDateChange(
                  moment(date),
                  values.dateFirstWorked
                );
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={isQuickEdit ? 6 : 4}>
            <KeyboardDatePicker
              margin="normal"
              id="separationDate"
              name="separationDate"
              label="Separation Date"
              format="MM/DD/yyyy"
              value={values.separationDate || null}
              data-cy="claim_claimant_separationDate"
              fullWidth
              disabled={isClientUser || isReadOnly}
              onChange={(date: MaterialUiPickersDate) =>
                setFieldValue("separationDate", date)
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>

          <Grid item md={12} lg={isQuickEdit ? 6 : 4}>
            <KeyboardDatePicker
              data-cy="basePeriodStartDate"
              disabled={isClientUser || isReadOnly}
              format="MM/DD/yyyy"
              fullWidth
              id="basePeriodStartDate"
              KeyboardButtonProps={{ "aria-label": "change date" }}
              label="Base Period Start"
              margin="normal"
              name="basePeriodStartDate"
              onChange={(date: MaterialUiPickersDate) =>
                setFieldValue("basePeriodStartDate", date)
              }
              value={values.basePeriodStartDate || null}
            />
          </Grid>

          <Grid item md={12} lg={isQuickEdit ? 6 : 4}>
            <KeyboardDatePicker
              data-cy="basePeriodEndDate"
              disabled={isClientUser || isReadOnly}
              format="MM/DD/yyyy"
              fullWidth
              id="basePeriodEndDate"
              KeyboardButtonProps={{ "aria-label": "change date" }}
              label="Base Period End"
              margin="normal"
              name="basePeriodEndDate"
              onChange={(date: MaterialUiPickersDate) =>
                setFieldValue("basePeriodEndDate", date)
              }
              value={values.basePeriodEndDate || null}
            />
          </Grid>
        </>
      ) : null}
    </IndentGrid>
  );
};

export default ClaimantDetails;
