import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete';
import { Formik } from 'formik';
import * as React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { isAuthorized } from '../../../common/dtos/auth';
import * as Styles from '../../styles/styles';

// Styles

const StyledTextField = styled(TextField)`

         .label.Mui-focused {
            color: white;
        }
       .MuiInputBase-root {
            color: white;
        }
        .MuiFormLabel-root {
            color: white;
        }
        .MuiInput-underline:before {
            border-bottom-color: white;
        }
        .MuiInput-underline:after {
            border-bottom-color: white;
        }
        .MuiOutlinedInput-root {
            &.fieldset: {
                border-color: white;
                font-size: 3px;
                font-weight: bold;
                font-color: white;
                
            },
            &:hover fieldset {
                border-color: white;
            },
            &.Mui-focused fieldset {
                border-color: white;
            },
        };
        width: 150px;
        padding: 5px;
        margin-top: 2px;

`;

const ContactSearch: React.FC = () => {
  //const [contactsList, setContactsList] = React.useState([] as ContactDto[]);
  //const [contactSearchOptions, setContactSearchOptions] = React.useState([] as string[]);
  //const dispatch = useDispatch();

  //const systemContacts = rootSelector(state => state.contacts.contactsList);

  //React.useEffect(() => {
  //  dispatch({ type: getType(getContactsActions.request) });
  //}, [dispatch])

  //React.useEffect(() => {
  //  setContactsList(systemContacts as ContactDto[]);
  //}, [systemContacts])

  //React.useEffect(() => {
  //  let searchOptions: string[] = [];
  //  contactsList.forEach((item: ContactDto) => {
  //    searchOptions.push(item.fullName!);
  //    if (item.primaryCompanyName !== undefined && item.primaryCompanyName !== '') { searchOptions.push(item.primaryCompanyName); }
  //    if (item.primaryPhoneNumber !== undefined && item.primaryPhoneNumber !== '') { searchOptions.push(item.primaryPhoneNumber); }
  //    if (item.primaryEmailAddress !== undefined && item.primaryEmailAddress !== '') { searchOptions.push(item.primaryEmailAddress); }
  //  });
  //  let newArray = Array.from(new Set(searchOptions.sort((a, b) => a.localeCompare(b))));
  //  setContactSearchOptions(newArray);
  //}, [contactsList]);

  return (
    <Formik
      initialValues={{ search: '' }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={Yup.object({
        search: Yup.string().max(200, 'Must be 200 characters or less'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          setSubmitting(false);
        }, 700);

        if (isAuthorized() && values.search !== '') {
          window.location.assign('/contacts?search=' + values.search);
        }
      }}
      enableReinitialize={true}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        } = props;
        return (
          <form noValidate onSubmit={handleSubmit}>
            <Styles.AutocompleteSearch
              aria-label='HeaderContacts Search Autocomplete'
              id='headerContactSearch'
              options={[]}
              ListboxProps={{ style: { width: 300 } }}
              autoComplete
              includeInputInList
              freeSolo
              onInputChange={(_e: any, v: string | null) => {
                setFieldValue('search', v ?? '');
                handleChange(_e);
              }}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <StyledTextField
                  {...params}
                  aria-label='Header Contacts Search Field'
                  variant='standard'
                  id='search'
                  size='small'
                  margin='none'
                  placeholder='Contacts'
                  value={values.search}
                  name='search'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(errors.search)}
                  helperText={errors.search && touched.search && errors.search}
                  data-cy='contact_search'
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment
                        aria-label='Header Contacts Search Icon'
                        position='end'
                      >
                        {' '}
                        <IconButton
                          aria-label='Header Claims Search Icon'
                          onClick={() => handleSubmit()}
                        >
                          <SearchIcon htmlColor='#ffffff' fontSize='medium' />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default ContactSearch;
