import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { requestAssistanceApi } from '../api/requestAssistance';
import { requestAssistanceActions } from '../actions/requestAssistance.actions';
//import { RequestAssistanceDto } from '../dtos/requestAssistance';

function* handleRequestAssistance(request: ReturnType<typeof requestAssistanceActions.request>) {
  yield call(requestAssistanceApi, request.payload);
    //put({ type: getType(requestAssistanceActions.request), payload: request.payload });

}

export function* watchAssistanceRequest() {
    try {
        yield takeLatest(getType(requestAssistanceActions.request), handleRequestAssistance);
    }
    catch (err) {
    }
}