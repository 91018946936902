import React from 'react';
import { FormikHandlers, FormikErrors, FormikHelpers, FieldArray, getIn } from 'formik';
// material ui
import { Grid, CardContent, Typography, } from '@material-ui/core';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { NavCard, NavOptionsGrid } from '../../documents.styles';
import { Icon, SidesPagination } from '../../../../common/styles/styles';
import { ISi, ISi_PropertyAttributes } from '../../../../common/dtos/SiDto';
import SiSelectOptions from '../siFieldComponents/SiSelectOptions';
import { DischargeReasonCodes_class } from '../../../../common/enums/DischargeReasonCodes';
import IndicatorTypeField from '../siFieldComponents/IndicatorTypeField';
import SiTextField from '../siFieldComponents/SiTextField';
import { DischargeExplanationCodes_class } from '../../../../common/enums/DischargeExplanationCodes';
import SiDateField from '../siFieldComponents/SiDateField';
import SiTextAreaField from '../siFieldComponents/SiTextAreaField';
import { IUisSiPriorIncidentOccurrence, IUisSiPriorIncidentOccurrence_PropertyAttributes } from '../../../../common/dtos/UisSiPriorIncidentOccurrenceDto';
import {
  isActCommittedViolatedLaw, isAdverseEffectOnEmployer, isAltercationOnCompanyPropertyInd, isAltercationOnCompanyTimeInd, isAskedToTestInd, isClaimantAdmissionInd,
  isClaimantAvoidAltercationInd, isClaimantStartAltercationInd, isDamagesReportedBy, isDollarValue, isEmployerDamage, isInadequatePerfReasonInd, isJobChangeInd, isMetPastExpections,
  isReasonForFinalAbsence, isTestPerformedInd, isValueAttachedToActInd, isWereThereWitnessesInd, isWhatConditionsChanged, isWhatWasStolen, isWhyNotPerformed, isWhyRequestedToTest,
  opDollarValue, opChainOfCustodyInd, opSecondTestSplitSampleGiven, opSecondTestSplitSampleResults, opTesterCompanyName, opTesterName, opWhoPerformedTest, opTestResults, isPriorIncidentInd, isUisSiWitnessInformationOccurrences
} from '../../../../utils';
import { TestRequestedReasonCodes_class } from '../../../../common/enums/TestRequestedReasonCodes';
import { Tester_class } from '../../../../common/enums/Tester';
import IndicatorTypeWithUnknownField from '../siFieldComponents/IndicatorTypeWithUnknownField';
import MoneyField from '../../../../common/components/numberFields';
import { IUisSiWitnessInformationOccurrence_PropertyAttributes } from '../../../../common/dtos/UisSiWitnessInformationOccurrenceDto';
import IndicatorTypesWithRefusedUnknownField from '../siFieldComponents/IndicatorTypesWithRefusedUnknownField';
import IndicatorTypesWithUnknownDiscloseField from '../siFieldComponents/IndicatorTypesWithUnknownDiscloseField';
import Label from '../../../../common/components/label';


interface Props extends React.HTMLProps<React.ReactNode> {
  values: ISi,
  errors: FormikErrors<ISi>,
  handleChange: FormikHandlers["handleChange"],
  handleBlur: FormikHandlers["handleBlur"],
  setFieldValue: FormikHelpers<ISi>["setFieldValue"],
  isReadOnly: boolean,
}


const SiDischarge: React.FC<Props> = (
  {
    values,
    errors,
    handleChange,
    isReadOnly,
    handleBlur,
    setFieldValue,
  }) => {
  const [incidentPageNumber, setIncidentPageNumber] = React.useState(1);

  React.useEffect(() => {
    if (!!values.uisSiPriorIncidentOccurrences && values.uisSiPriorIncidentOccurrences.length !== 0)
      setIncidentPageNumber(1);
  }, []);

  React.useEffect(() => {
    if (!isInadequatePerfReasonInd(values) && values.inadequatePerfReasonInd !== undefined) setFieldValue('inadequatePerfReasonInd', undefined);
    if (!isMetPastExpections(values) && values.metPastExpections !== undefined) setFieldValue('metPastExpections', undefined);
    if (!isJobChangeInd(values) && values.jobChangeInd !== undefined) setFieldValue('jobChangeInd', undefined);
    if (!isWhatConditionsChanged(values) && values.whatConditionsChanged !== undefined) setFieldValue('whatConditionsChanged', undefined);
    if (!isReasonForFinalAbsence(values) && values.reasonForFinalAbsence !== undefined) setFieldValue('reasonForFinalAbsence', undefined);
    if (!isAskedToTestInd(values) && values.askedToTestInd !== undefined) setFieldValue('askedToTestInd', undefined);
    if (!isTestPerformedInd(values) && values.testPerformedInd !== undefined) setFieldValue('testPerformedInd', undefined);
    if (!isWhyNotPerformed(values) && values.whyNotPerformed !== undefined) setFieldValue('whyNotPerformed', undefined);
    if (!isWhyRequestedToTest(values) && values.whyRequestedToTest !== undefined) setFieldValue('whyRequestedToTest', undefined);
    if (!opWhoPerformedTest(values) && values.whoPerformedTest !== undefined) setFieldValue('whoPerformedTest', undefined);
    if (!opTesterName(values) && values.testerName !== undefined) setFieldValue('testerName', undefined);
    if (!opTesterCompanyName(values) && values.testerCompanyName !== undefined) setFieldValue('testerCompanyName', undefined);
    if (!opTestResults(values) && values.testResults !== undefined) setFieldValue('testResults', undefined);
    if (!opChainOfCustodyInd(values) && values.chainOfCustodyInd !== undefined) setFieldValue('chainOfCustodyInd', undefined);
    if (!opSecondTestSplitSampleGiven(values) && values.secondTestSplitSampleGiven !== undefined) setFieldValue('secondTestSplitSampleGiven', undefined);
    if (!opSecondTestSplitSampleResults(values) && values.secondTestSplitSampleResults !== undefined) setFieldValue('secondTestSplitSampleResults', undefined);
    if (!isWhatWasStolen(values) && values.whatWasStolen !== undefined) setFieldValue('whatWasStolen', undefined);
    if (!isValueAttachedToActInd(values) && values.valueAttachedToActInd !== undefined) setFieldValue('valueAttachedToActInd', undefined);
    if (!(isDollarValue(values) || opDollarValue(values)) && values.dollarValue !== undefined) setFieldValue('dollarValue', undefined);
    if (!isAdverseEffectOnEmployer(values) && values.adverseEffectOnEmployer !== undefined) setFieldValue('adverseEffectOnEmployer', undefined);
    if (!isClaimantAdmissionInd(values) && values.claimantAdmissionInd !== undefined) setFieldValue('claimantAdmissionInd', undefined);
    if (!isEmployerDamage(values) && values.employerDamage !== undefined) setFieldValue('employerDamage', undefined);
    if (!isDamagesReportedBy(values) && values.damagesReportedBy !== undefined) setFieldValue('damagesReportedBy', undefined);
    if (!isActCommittedViolatedLaw(values) && values.actCommittedViolatedLaw !== undefined) setFieldValue('actCommittedViolatedLaw', undefined);
    if (!isAdverseEffectOnEmployer(values) && values.adverseEffectOnEmployer !== undefined) setFieldValue('adverseEffectOnEmployer', undefined);
    if (!isAltercationOnCompanyPropertyInd(values) && values.altercationOnCompanyPropertyInd !== undefined) setFieldValue('altercationOnCompanyPropertyInd', undefined);
    if (!isAltercationOnCompanyTimeInd(values) && values.altercationOnCompanyTimeInd !== undefined) setFieldValue('altercationOnCompanyTimeInd', undefined);
    if (!isClaimantStartAltercationInd(values) && values.claimantStartAltercationInd !== undefined) setFieldValue('claimantStartAltercationInd', undefined);
    if (!isClaimantAvoidAltercationInd(values) && values.claimantAvoidAltercationInd !== undefined) setFieldValue('claimantAvoidAltercationInd', undefined);
    if (!isWereThereWitnessesInd(values) && values.wereThereWitnessesInd !== undefined) setFieldValue('wereThereWitnessesInd', undefined);
    if (!(values.wereThereWitnessesInd === 0) && values.uisSiWitnessInformationOccurrences&& values.uisSiWitnessInformationOccurrences[0]?.witnessName !== undefined) setFieldValue('uisSiWitnessInformationOccurrences[0].witnessName', undefined);
    if (!(values.wereThereWitnessesInd === 0) && values.uisSiWitnessInformationOccurrences && values.uisSiWitnessInformationOccurrences[0]?.witnessJobTitle !== undefined) setFieldValue('uisSiWitnessInformationOccurrences[0].witnessJobTitle', undefined);
    if (!isUisSiWitnessInformationOccurrences(values) && (values.uisSiWitnessInformationOccurrences || []).length !== 0) setFieldValue('uisSiWitnessInformationOccurrences', []);
    if (!isPriorIncidentInd(values) && values.priorIncidentInd !== undefined) setFieldValue('priorIncidentInd', undefined);
  }, [values]);

  const handleGetErrors = () => {
    let errorIndexList = [];
    for (let i = 0; i < (getIn(errors, `uisSiPriorIncidentOccurrences`) ?? []).length; i++) {
      if (Boolean(getIn(errors, `uisSiPriorIncidentOccurrences[${i}]`))) {
        errorIndexList.push(i);
      }
    }
    return errorIndexList;
  };

  const handleDischargeReasonCodeChange = (v: any | null) => {
    let C = DischargeReasonCodes_class.enum;
    if (!!v && [C.Item2, C.Item3, C.Item4, C.Item5, C.Item7, C.Item9, C.Item10, C.Item11, C.Item12, C.Item13].findIndex(f => f === v.value) !== -1) {
      setFieldValue('violateCompanyPolicyInd', 0);
      handleViolateCompanyPolicyIndChange({value: 0});
    }
    else setFieldValue('violateCompanyPolicyInd', undefined);
  };

  const handleViolateCompanyPolicyIndChange = (v: any | null) => {
    if (!!v && v.value === 0) {
      setFieldValue('dischargePolicyAwareInd', 0);
      setFieldValue('dischargePolicyAwareExplanationCode', DischargeExplanationCodes_class.enum.B);
    }
  };

  return (
    <FieldArray name={`uisSiPriorIncidentOccurrences`} render={arrayHelpers => (
      <Grid container spacing={2} data-cy="sidesResponse_Discharge_container">
        <NavOptionsGrid item xs={6}>
          <NavCard>
            <CardContent>
              <Grid container item xs={12} spacing={2}>
                <SiSelectOptions
                  label={"Why was the claimant facing discharge or suspension?"}
                  name={`dischargeReasonCode`}
                  value={values.dischargeReasonCode}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  options={DischargeReasonCodes_class.enumAsSelectOptions}
                  callBack={handleDischargeReasonCodeChange}
                />


                {/*Conditional Questions*/}
                {isInadequatePerfReasonInd(values) &&
                  <IndicatorTypeField
                    label={"Was the inadequate performance due to carelessness or negligence?"}
                    name={`inadequatePerfReasonInd`}
                    value={values.inadequatePerfReasonInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isMetPastExpections(values) &&
                  <IndicatorTypeField
                    label={"Was the claimant able to meet the employers performance expectations in the past?"}
                    name={`metPastExpections`}
                    value={values.metPastExpections}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isJobChangeInd(values) &&
                  <IndicatorTypeField
                    label={"Did anything change about the job?"}
                    name={`jobChangeInd`}
                    value={values.jobChangeInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isWhatConditionsChanged(values) &&
                  <SiTextAreaField
                    label={"Explain what changed about the job."}
                    name={`whatConditionsChanged`}
                    value={values.whatConditionsChanged}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.whatConditionsChanged_Attributes.stringLength.maxLength}
                  />
                }
                {isReasonForFinalAbsence(values) &&
                  <SiTextAreaField
                    label={"What was the reason the claimant gave for the final absence/tardiness?"}
                    name={`reasonForFinalAbsence`}
                    value={values.reasonForFinalAbsence}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.reasonForFinalAbsence_Attributes.stringLength.maxLength}
                  />
                }
                {isAskedToTestInd(values) &&
                  <IndicatorTypeField
                    label={"Was the claimant asked to submit to a test?"}
                    name={`askedToTestInd`}
                    value={values.askedToTestInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }
                {isTestPerformedInd(values) &&
                  <IndicatorTypeField
                    label={"Was a test performed?"}
                    name={`testPerformedInd`}
                    value={values.testPerformedInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }
                {isWhyNotPerformed(values) &&
                  <SiTextAreaField
                    label={"Why was a test not performed?"}
                    name={`whyNotPerformed`}
                    value={values.whyNotPerformed}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.whyNotPerformed_Attributes.stringLength.maxLength}
                  /> 
                }
                {isWhyRequestedToTest(values) &&
                  <SiSelectOptions
                    label={"Why was the test requested?"}
                    name={`whyRequestedToTest`}
                    value={values.whyRequestedToTest}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    options={TestRequestedReasonCodes_class.enumAsSelectOptions}
                  /> 
                }
                {opWhoPerformedTest(values) &&
                  <SiSelectOptions
                    label={"Who performed the test?"}
                    name={`whoPerformedTest`}
                    value={values.whoPerformedTest}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    options={Tester_class.enumAsSelectOptions}
                  /> 
                }
                {opTesterName(values) &&
                  <SiTextField
                    label={"First and Last Name of the person who performed the test."}
                    name={`testerName`}
                    value={values.testerName}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.testerName_Attributes.stringLength.maxLength}
                  /> 
                }
                {opTesterCompanyName(values) &&
                  <SiTextField
                    label={"Name of the company where the drug /alcohol test was performed"}
                    name={`testerCompanyName`}
                    value={values.testerCompanyName}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.testerCompanyName_Attributes.stringLength.maxLength}
                  />
                }
                {opTestResults(values) &&
                  <SiTextAreaField
                    label={"What were the results of the test?"}
                    name={`testResults`}
                    value={values.testResults}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.testResults_Attributes.stringLength.maxLength}
                  /> 
                }
                {opChainOfCustodyInd(values) &&
                  <IndicatorTypeField
                    label={"Do you have a copy of the chain of custody (documentation of how the testing and results were handled)?"}
                    name={`chainOfCustodyInd`}
                    value={values.chainOfCustodyInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }
                {opSecondTestSplitSampleGiven(values) &&
                  <IndicatorTypesWithRefusedUnknownField
                    label={"Was a second test, or split sample test offered/given?"}
                    name={`secondTestSplitSampleGiven`}
                    value={values.secondTestSplitSampleGiven}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }
                {opSecondTestSplitSampleResults(values) &&
                  <SiTextAreaField
                    label={"What were the results?"}
                    name={`secondTestSplitSampleResults`}
                    value={values.secondTestSplitSampleResults}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.secondTestSplitSampleResults_Attributes.stringLength.maxLength}
                  />
                }
                {isWhatWasStolen(values) &&
                  <SiTextAreaField
                    label={"What did the claimant steal?"}
                    name={`whatWasStolen`}
                    value={values.whatWasStolen}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.whatWasStolen_Attributes.stringLength.maxLength}
                  /> 
                }
                {isValueAttachedToActInd(values) &&
                  <IndicatorTypeWithUnknownField
                    label={"Was there a value attached to the act?"}
                    name={`valueAttachedToActInd`}
                    value={values.valueAttachedToActInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }
                {(isDollarValue(values) || opDollarValue(values)) &&
                  <SiTextField
                    label={isDollarValue(values) ? "What is the dollar value of the theft?" : "What was the value of the damage?"}
                    name={`dollarValue`}
                    value={values.dollarValue?.toString()}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputprops={{ inputComponent: MoneyField as any }}
                    max={17}
                  /> 
                }
                {/*{isAdverseEffectOnEmployer(values) &&*/}
                {/*  <SiTextAreaField*/}
                {/*    label={"How did the act adversely affect the employer?"}*/}
                {/*    name={`adverseEffectOnEmployer`}*/}
                {/*    value={values.adverseEffectOnEmployer}*/}
                {/*  errors={errors}*/}
                {/*  setFieldValue={setFieldValue}*/}
                {/*    handleChange={handleChange}*/}
                {/*    handleBlur={handleBlur}*/}
                {/*    isReadOnly={isReadOnly}*/}
                {/*    max={ISi_PropertyAttributes.adverseEffectOnEmployer_Attributes.stringLength.maxLength}*/}
                {/*  /> */}
                {/*}*/}
                {isClaimantAdmissionInd(values) &&
                  <IndicatorTypeField
                    label={values.dischargeReasonCode === DischargeReasonCodes_class.enum.Item10 ? "Did the claimant admit to the theft?" : "Did the claimant admit to causing the damage?"}
                    name={`claimantAdmissionInd`}
                    value={values.claimantAdmissionInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }
                {isEmployerDamage(values) &&
                  <SiTextAreaField
                    label={values.dischargeReasonCode === DischargeReasonCodes_class.enum.Item10 ? "How did the employer know the claimant was responsible for the theft?" : "How did the employer know the claimant was responsible for the damage?"}
                    name={`employerDamage`}
                    value={values.employerDamage}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.employerDamage_Attributes.stringLength.maxLength}
                  /> 
                }

                {isDamagesReportedBy(values) &&
                  <SiTextField
                    label={"Who reported the damage?"}
                    name={`damagesReportedBy`}
                    value={values.damagesReportedBy}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.damagesReportedBy_Attributes.stringLength.maxLength}
                  /> 
                }

                {isActCommittedViolatedLaw(values) &&
                  <SiTextAreaField
                    label={"What act did the claimant commit that violated the law?"}
                    name={`actCommittedViolatedLaw`}
                    value={values.actCommittedViolatedLaw}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.actCommittedViolatedLaw_Attributes.stringLength.maxLength}
                  /> 
                }
                {isAdverseEffectOnEmployer(values) &&
                  <SiTextAreaField
                    label={"How did the act adversely affect the employer?"}
                    name={`adverseEffectOnEmployer`}
                    value={values.adverseEffectOnEmployer}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.adverseEffectOnEmployer_Attributes.stringLength.maxLength}
                  /> 
                }

                {isAltercationOnCompanyPropertyInd(values) &&
                  <IndicatorTypeField
                    label={"Did the altercation take place on company property?"}
                    name={`altercationOnCompanyPropertyInd`}
                    value={values.altercationOnCompanyPropertyInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }
                {isAltercationOnCompanyTimeInd(values) &&
                  <IndicatorTypeField
                    label={"Did the altercation take place on company time?"}
                    name={`altercationOnCompanyTimeInd`}
                    value={values.altercationOnCompanyTimeInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }
                {isClaimantStartAltercationInd(values) &&
                  <IndicatorTypeWithUnknownField
                    label={"Did the claimant start the altercation?"}
                    name={`claimantStartAltercationInd`}
                    value={values.claimantStartAltercationInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }
                {isClaimantAvoidAltercationInd(values) &&
                  <IndicatorTypeWithUnknownField
                    label={"Could the claimant have avoided the altercation?"}
                    name={`claimantAvoidAltercationInd`}
                    value={values.claimantAvoidAltercationInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }


                {/* Witnesses */}
                {isWereThereWitnessesInd(values) &&
                  <IndicatorTypesWithUnknownDiscloseField
                    label={"Were there witnesses to the incident?"}
                    name={`wereThereWitnessesInd`}
                    value={values.wereThereWitnessesInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }
                {values.wereThereWitnessesInd === 0 &&
                  <SiTextField
                    label={"Witness First and Last Name"}
                    name={`uisSiWitnessInformationOccurrences[0].witnessName`}
                    value={values.uisSiWitnessInformationOccurrences ? values.uisSiWitnessInformationOccurrences[0]?.witnessName || '' : ''}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={IUisSiWitnessInformationOccurrence_PropertyAttributes.witnessName_Attributes.stringLength.maxLength}
                  /> 
                }
                {values.wereThereWitnessesInd === 0 &&
                  <SiTextField
                    label={"Witness Job Title"}
                    name={`uisSiWitnessInformationOccurrences[0].witnessJobTitle`}
                    value={values.uisSiWitnessInformationOccurrences ? values.uisSiWitnessInformationOccurrences[0]?.witnessJobTitle || '' : ''}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={IUisSiWitnessInformationOccurrence_PropertyAttributes.witnessJobTitle_Attributes.stringLength.maxLength}
                  /> 
                }


                {/*<IndicatorTypeField*/}
                {/*  label={"Was the inadequate performance due to carelessness or negligence?"}*/}
                {/*  name={`inadequatePerfReasonInd`}*/}
                {/*  value={values.inadequatePerfReasonInd}*/}
                {/*  errors={errors}*/}
                {/*  setFieldValue={setFieldValue}*/}
                {/*  handleBlur={handleBlur}*/}
                {/*  isReadOnly={isReadOnly}*/}
                {/*/>*/}

                {/*<IndicatorTypeField*/}
                {/*  label={"Did anything change about the job?"}*/}
                {/*  name={`jobChangeInd`}*/}
                {/*  value={values.jobChangeInd}*/}
                {/*  errors={errors}*/}
                {/*  setFieldValue={setFieldValue}*/}
                {/*  handleBlur={handleBlur}*/}
                {/*  isReadOnly={isReadOnly}*/}
                {/*/>*/}

                {/*<SiTextAreaField*/}
                {/*  label={"Explain what changed about the job."}*/}
                {/*  name={`whatConditionsChanged`}*/}
                {/*  value={values.whatConditionsChanged}*/}
                {/*  errors={errors}*/}
                {/*  handleChange={handleChange}*/}
                {/*  handleBlur={handleBlur}*/}
                {/*  isReadOnly={isReadOnly}*/}
                {/*  max={ISi_PropertyAttributes.whatConditionsChanged_Attributes.stringLength.maxLength}*/}
                {/*/>*/}

                <IndicatorTypeField
                  label={"Violation of Company Policy?"}
                  name={`violateCompanyPolicyInd`}
                  value={values.violateCompanyPolicyInd}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  callBack={handleViolateCompanyPolicyIndChange}
                />

                <IndicatorTypeField
                  label={"Claimant Aware of Policy?"}
                  name={`dischargePolicyAwareInd`}
                  value={values.dischargePolicyAwareInd}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                />

                <SiSelectOptions
                  label={"How"}
                  name={`dischargePolicyAwareExplanationCode`}
                  value={values.dischargePolicyAwareExplanationCode}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  options={DischargeExplanationCodes_class.enumAsSelectOptions}
                />

                <SiDateField
                  label={"Final Incident Date"}
                  name={`finalIncidentDate`}
                  value={values.finalIncidentDate}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  inputlabelprops={{ shrink: true }}
                />
                
                <SiTextAreaField
                  label={"Final Incident Description"}
                  name={`finalIncidentReason`}
                  value={values.finalIncidentReason}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  max={ISi_PropertyAttributes.finalIncidentReason_Attributes.stringLength.maxLength}
                />

                <SiTextField
                  label={"Who Discharged?"}
                  name={`whoDischargedName`}
                  value={values.whoDischargedName}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  max={ISi_PropertyAttributes.whoDischargedName_Attributes.stringLength.maxLength}
                />

                <SiTextField
                  label={"Title"}
                  name={`whoDischargedTitle`}
                  value={values.whoDischargedTitle}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  max={ISi_PropertyAttributes.whoDischargedTitle_Attributes.stringLength.maxLength}
                />

                <SiTextAreaField
                  label={`Additional Discharge Comments (Required if "Not Listed Above" selected as a Discharge Reason)`}
                  name={`dischargeReasonComments`}
                  value={values.dischargeReasonComments}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  max={ISi_PropertyAttributes.dischargeReasonComments_Attributes.stringLength.maxLength}
                />

                {isPriorIncidentInd(values) &&
                  <IndicatorTypeField
                    label={"Were there prior incident(s)?"}
                    name={`priorIncidentInd`}
                    value={values.priorIncidentInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }
                
                
              </Grid>
            </CardContent>
          </NavCard>
        </NavOptionsGrid>

        <NavOptionsGrid item xs={6}>
          <NavCard>
            <CardContent>
              <Grid container spacing={2}>
                {values.uisSiPriorIncidentOccurrences && values.uisSiPriorIncidentOccurrences.length !== 0 ?
                  <>
                    <SiDateField
                      label={"Prior Incident Date"}
                      name={`uisSiPriorIncidentOccurrences[${incidentPageNumber - 1}].priorIncidentDate`}
                      value={values.uisSiPriorIncidentOccurrences ? values.uisSiPriorIncidentOccurrences[incidentPageNumber - 1]?.priorIncidentDate || null : null}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                      size={6}
                      inputlabelprops={{ shrink: true }}
                    />
                    <Grid item xs={4}></Grid>
                  </> : <Grid item xs={10}><Label>Prior Incidents</Label></Grid>
                }
               
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <Grid container alignContent="flex-end">
                    <Grid item xs={6}>
                      {values.uisSiPriorIncidentOccurrences && values.uisSiPriorIncidentOccurrences?.length !== 0 ?
                        <Icon
                          title="Remove Incident"
                          color="secondary"
                          onClick={() => {
                            setTimeout(() => {
                              arrayHelpers.remove(incidentPageNumber - 1);
                              setIncidentPageNumber(!!values.uisSiPriorIncidentOccurrences ? values.uisSiPriorIncidentOccurrences.length - 1 : 0);
                            }, 200);
                          }}
                          data-cy="changeMe"
                          redcolor="true"
                        >
                          <IndeterminateCheckBoxIcon />
                        </Icon> :
                        null
                      }
                    </Grid>
                    <Grid item xs={6}>
                      <Icon title="Add Incident" color="secondary"
                        onClick={() => {
                          arrayHelpers.push({} as IUisSiPriorIncidentOccurrence);
                          setIncidentPageNumber(!!values.uisSiPriorIncidentOccurrences ? values.uisSiPriorIncidentOccurrences.length + 1 : 0);
                        }} data-cy="changeMe">
                        <AddBoxRoundedIcon />
                      </Icon>
                    </Grid>
                  </Grid>
                </Grid>

                {Boolean(getIn(errors, 'uisSiPriorIncidentOccurrences')) &&
                  <Grid item xs={12}><Typography component="span" color="error">
                    {typeof getIn(errors, 'uisSiPriorIncidentOccurrences') === 'string' && getIn(errors, 'uisSiPriorIncidentOccurrences')}
                  </Typography></Grid>}
                
                {values.uisSiPriorIncidentOccurrences && values.uisSiPriorIncidentOccurrences?.length !== 0 ?
                  <>
                    <SiTextAreaField
                      label={"Prior Incident Description"}
                      name={`uisSiPriorIncidentOccurrences[${incidentPageNumber - 1}].priorIncidentReason`}
                      value={values.uisSiPriorIncidentOccurrences[incidentPageNumber - 1]?.priorIncidentReason}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                      max={IUisSiPriorIncidentOccurrence_PropertyAttributes.priorIncidentReason_Attributes.stringLength.maxLength}
                    />

                    <IndicatorTypeField
                      label={"Warning Issued"}
                      name={`uisSiPriorIncidentOccurrences[${incidentPageNumber - 1}].priorIncidentWarningInd`}
                      value={values.uisSiPriorIncidentOccurrences[incidentPageNumber - 1]?.priorIncidentWarningInd}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                    />

                    <SiDateField
                      label={"Warning Issue Date"}
                      name={`uisSiPriorIncidentOccurrences[${incidentPageNumber - 1}].priorIncidentWarningDate`}
                      value={values.uisSiPriorIncidentOccurrences[incidentPageNumber - 1]?.priorIncidentWarningDate || null}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly && !(values.uisSiPriorIncidentOccurrences[incidentPageNumber - 1]?.priorIncidentWarningInd === 0)}
                      size={6}
                      inputlabelprops={{ shrink: true }}
                    />

                    <SiTextAreaField
                      label={"Warning Description"}
                      name={`uisSiPriorIncidentOccurrences[${incidentPageNumber - 1}].priorIncidentWarningDescription`}
                      value={values.uisSiPriorIncidentOccurrences[incidentPageNumber - 1]?.priorIncidentWarningDescription}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly && !(values.uisSiPriorIncidentOccurrences[incidentPageNumber - 1]?.priorIncidentWarningInd === 0)}
                      max={IUisSiPriorIncidentOccurrence_PropertyAttributes.priorIncidentWarningDescription_Attributes.stringLength.maxLength}
                    />

                    <SidesPagination
                      boundaryCount={20}
                      hidden={values.uisSiPriorIncidentOccurrences.length === 0}
                      count={values.uisSiPriorIncidentOccurrences.length}
                      variant="outlined"
                      color="primary"
                      size="small"
                      errorindex={handleGetErrors()}
                      page={incidentPageNumber}
                      onChange={(e, p) => { setIncidentPageNumber(p); }} />
                  </> :
                  null
                }
                
              </Grid>
            </CardContent>
          </NavCard>
        </NavOptionsGrid>
      </Grid>
    )} />
  )
}

export default SiDischarge;
