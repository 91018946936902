


import { ISelectOption } from "../dtos/SelectOptionDto";
enum WagesWeeksNeededCodes {
        WO = 0,
        WW = 1,
        NA = 2,
         
}

export class WagesWeeksNeededCodes_class {

public static enum = WagesWeeksNeededCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['WO']: 0,
    ['WW']: 1,
    ['NA']: 2,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "Wages Only",
    [1]: "Weeks and Wages",
    [2]: "Not Needed",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "Wages Only"},
  {value: 1, text: "Weeks and Wages"},
  {value: 2, text: "Not Needed"},
  ]
}

