


import { ISelectOption } from "../dtos/SelectOptionDto";
enum ContributionCodes {
        C = 0,
        E = 1,
        B = 2,
        U = 3,
         
}

export class ContributionCodes_class {

public static enum = ContributionCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['C']: 0,
    ['E']: 1,
    ['B']: 2,
    ['U']: 3,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "Claimant",
    [1]: "Employer",
    [2]: "Both",
    [3]: "Unknown",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "Claimant"},
  {value: 1, text: "Employer"},
  {value: 2, text: "Both"},
  {value: 3, text: "Unknown"},
  ]
}

