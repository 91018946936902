import React from 'react';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
import { useMediaQuery, } from '@material-ui/core';
import TabPanel from '../../../common/components/tabPanel';
import ClaimsTemplatesList from './components/claimsTemplatesList';
import AppealsTemplatesList from './components/appealsTemplatesList';
import { getEmailTemplatesActions } from '../../../common/actions/emailTemplate.actions';
import { ClearAppBar, StyledCardContent, StyledTab, StyledTabs, ViewCard, ViewGrid } from '../../../common/styles/styles';


const EmailTemplates: React.FC = () => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = React.useState(0);

  const shouldCollapse = useMediaQuery('(max-width:768px)');


  React.useEffect(() => { dispatch({ type: getType(getEmailTemplatesActions.request) }); }, [dispatch]);


  const changeTab = (event: any, newValue: number) => {
    setTabValue(newValue);
  }


  return <ViewGrid container item xs={12}>
    <ViewCard>
      <StyledCardContent>
        <ClearAppBar position="static">
          <StyledTabs aria-labelledby={`tab-${tabValue}`} orientation={shouldCollapse ? 'vertical' : 'horizontal'} centered={true} value={tabValue} onChange={changeTab} aria-label="Email Templates" variant="fullWidth" scrollButtons="auto">
            <StyledTab id="tab-0" aria-controls="tabpanel-0" label="Claims Templates" data-cy="codes_eligibility_tab" />
            <StyledTab id="tab-1" aria-controls="tabpanel-1" label="Appeals Templates" data-cy="codes_separation_tab" />
          </StyledTabs>
        </ClearAppBar>

        <TabPanel value={tabValue} index={0}><ClaimsTemplatesList /> </TabPanel>
        <TabPanel value={tabValue} index={1}><AppealsTemplatesList /></TabPanel>

      </StyledCardContent>
    </ViewCard>
  </ViewGrid>
}

export default EmailTemplates;
