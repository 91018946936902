import styled from 'styled-components';

// material ui
import {
  Card as MuiCard,  Select,
  Button,  TextField,
} from '@material-ui/core';

import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';

// Styled components ....

export const T3Select = styled(Select)`
  margin-top: 13px;
`;

export const UAYearSelect = styled(TextField)`

margin-top: 3px;

`;

export const TextSelect = styled(TextField)`

margin-top: 13px;

`;
export const BUAddIcon = styled(AddBoxRoundedIcon)`

top: 10px;
`;
export const BUTextField = styled(TextField)`
    
padding-bottom: 8px;

`;
export const StateTextField = styled(TextField)`

margin-top: -23px;

`;

export const LocationTextField = styled(TextField)`

margin-top: 8px;

`;

export const ImportTable = styled(MuiCard)`  
    width: 100%;
    padding: 8px 2px;
    margin: 10px 0;
`;


export const PaddedStreetTextField = styled(TextField)`

    padding-top: 15px;
`;



export const DialogMultiLineTextField = styled(TextField)`
    
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`



export const AddButton = styled(Button)`
    margin-top: 12px;
    margin-left: 5px;
`;