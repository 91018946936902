import React from 'react';
import NumberFormat from 'react-number-format';

interface NumberFormatProps {
  allowLeadingZeros: boolean;
    inputRef: (instance: NumberFormat | null) => void;
  isSSN: boolean;
  name: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
  showSeparator?: boolean;
  maxValue?: number;
  customFormat?: string | undefined;
}

const NumberField: React.FC<NumberFormatProps> = (props: NumberFormatProps) => {
  const {
    allowLeadingZeros = false,
    inputRef,
    isSSN = false,
    onChange,
    maxValue,
    showSeparator = true,
    customFormat,
    ...other
  } = props;

  return (
    <>
      <NumberFormat
        {...other}
        decimalScale={0}
        format={customFormat}
        fixedDecimalScale={true}
        getInputRef={inputRef}
        allowLeadingZeros={allowLeadingZeros || isSSN}
        onValueChange={(values) => {
          let exceededMaxValue =
            maxValue !== undefined && (values.floatValue ?? 0) > maxValue;

          if (!exceededMaxValue)
            onChange({
              target: {
                name: props.name,
                value: values.value,
              },
            });
        }}
        thousandSeparator={showSeparator && !isSSN}
      />
    </>
  );
};

export default NumberField;
