import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

// material ui
import TextField from '@material-ui/core/TextField';
import { Pagination } from '@material-ui/lab';
import {
  Button,
  Card,
  CardContent,
  Link,
  Grid,
  Tabs,
  Tab,
  AppBar,
  Typography,
  IconButton,
  FormControlLabel,
  CardHeader,
  Radio,
  InputLabel,
  DialogContent,
  DialogTitle,
  Dialog,
  Checkbox,
} from '@material-ui/core';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { forEach } from 'lodash';

// Styled login components ....
export const ViewGrid = styled(Grid)`
  margin: 2px 2px 27px 2px;
`;

export const RedRemoveButton = styled(RemoveCircleRoundedIcon)`
  color: #8b0000;
`;

export const PaddedTextField = styled(TextField)`
  padding-bottom: 15px;
`;

export const PaddedBottomTopTextField = styled(TextField)`
  padding-top: 10px;
  padding-bottom: 15px;
`;

export const NoPaddingVisibilityIcon = styled(VisibilityIcon)`
  padding: 0px;
`;

export const NoPaddingVisibilityOffIcon = styled(VisibilityOffIcon)`
  padding: 0px;
`;

export const NoPaddingIconButton = styled(IconButton)`
  padding: 0px;
  padding-left: 5px;
`;
export const WideDialogContent = styled(DialogContent)`
  width: 500px;
`;

export const PaddedBottomTextField = styled(TextField)`
  padding-bottom: 15px;
`;
export const PaddedTopTextField = styled(TextField)`
  padding-top: 15px;
`;
export const TextInputSpan = styled.span`
  top: -16px;
  position: relative;
`;
export const PaddedTopGrid = styled(Grid)`
  padding-top: 15px;
`;

export const ChooseFileBox = styled.div`
  bottom: 35px;
  position: absolute;
  border-color: transparent;
  background-color: #00426a;
  color: white;
  font-weight: 700;
  font-size: 1.025em;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background-color: #008a86;
  }
  > small {
    left: 88px;
    position: absolute;
    bottom: 10px;
    color: black;
    font-size: 10px;
    font-weight: 400;
  }
`;

export const UploadBox = styled.div`
  bottom: 8px;
  position: absolute;
  border-color: transparent;
  background-color: #00426a;
  color: white;
  font-weight: 700;
  font-size: 1.025em;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background-color: #008a86;
  }
  > small {
    left: 88px;
    position: absolute;
    bottom: 10px;
    color: black;
    font-size: 10px;
    font-weight: 400;
  }
`;

export const SidesUploadBox = styled.div`
  border-color: transparent;
  background-color: #00426a;
  color: white;
  font-weight: 700;
  font-size: 1.025em;
  padding: 6px;
  text-align: center;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background-color: #008a86;
  }
  > small {
    left: 88px;
    position: relative;
    bottom: 10px;
    color: black;
    font-size: 10px;
    font-weight: 400;
  }
`;

export const SidesCardContent = styled(CardContent)`
  padding: 8px; 16px;
  //height: calc(100vh - 0px);
`;

export const IssueLabel = styled(InputLabel)`
  padding-top: 5px;
  color: #7c878e;
  margin-left: 30px;
`;

export const StyledDiv = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledCard = styled(Card)`
  width: 500px;
`;

export const StyledCardContent = styled(CardContent)`
  padding: 8px; 16px;
`;

export const ButtonGrid = styled(Grid)`
  display: inline;
`;

export const IndentGrid = styled(Grid)`
  margin: 0 8px 8px 4px;
  > .MuiGrid-item {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
`;

export const IndentFileGrid = styled(Grid)`
  margin: 0 8px 8px 16px;
  > .MuiGrid-item {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
`;

export const EmailGrid = styled(Grid)`
  margin: 0 8px 8px 4px;
  > .MuiGrid-item {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: -8px;
  }
`;

export const PhoneGrid = styled(Grid)`
  margin: 0 8px 8px 4px;
  > .MuiGrid-item {
    padding-top: 0 !important;
    padding-bottom: 10px !important;
  }
`;

export const IndentGrid2 = styled(Grid)`
  margin: 0 8px 0 20px;
  > .MuiGrid-item {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
`;

export const StyledCancelLink = styled(Link).attrs({
  component: RouterLink,
  to: '/',
})`
  color: #f05123;
  font-weight: 600;
  font-size: 1.035em;
`;

export const StyledForgotPasswordSubmission = styled(Link).attrs({
  component: RouterLink,
  to: '/',
})`
  font-weight: 600;
  font-size: 1.085em;
`;

export const LabelLink = styled(Link).attrs({ component: Button })`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.palette.primary.main};
  background-color: inherit !important;
  padding-left: 0px;
`;

export const SmallLabelLink = styled(Link).attrs({ component: Button })`
  font-size: 10px;
  font-weight: 600;
  color: ${(props) => props.theme.palette.primary.main};
  background-color: inherit !important;
  padding-left: 0px;
  top: 4px;
`;

export const RedLabelLink = styled(Link).attrs({ component: Button })`
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => props.theme.palette.error.main};
  background-color: inherit !important;
  padding-left: 0px;
`;
export const StyledCancelButton = styled(Button)`
  margin: 8px 0 8px;
  color: ${(props) => props.theme.secondaryColors.lightOrange};
  float: right;
  font-weight: 600;
  font-size: 1.2em;
`;

export const StyledSubmitButton = styled(Button)<{ ccolor?: string }>`
  margin: 8px 0 8px;
  float: right;
  font-weight: 700;
  font-size: 1.2em;
  color: ${(props) => (props.ccolor ? props.ccolor : '#005C59')} !important;
`;

export const PostButton = styled(Button)<{
  islargebutton?: boolean;
  orangecolor?: boolean;
}>`
  float: right;
  font-weight: 700;
  font-size: ${(props) =>
    props.islargebutton && props.islargebutton ? '1.2' : '0.8'}em;
  color: ${(props) =>
    props.orangecolor && props.orangecolor
      ? props.theme.secondaryColors.lightOrange
      : props.theme.secondaryColors.teal};
`;

export const EmailTemplateLinkButtons = styled(Link).attrs({
  component: Button,
})`
  margin: 0 52px;
  float: right;
  font-weight: 700;
  font-size: 1.4em;
  color: ${(props) => props.theme.secondaryColors.teal};
  span > p {
    font-size: inherit;
    color: inherit;
  }
`;

export const StyledForm = styled.form`
  width: 100%;
  margin-top: 8px;
`;

export const StyledTextField = styled(TextField)`
  margin-top: 12px;
`;

export const CardTextField = styled(TextField)`
  margin-top: -12px;
  & > div > input {
    margin-top: 8px;
    margin-bottom: -4px;
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

export const ClearAppBar = styled(AppBar)`
  background-color: #fff;
  margin: 0 0 12px 0;
`;

export const BottomAppBar = styled(AppBar)`
  background-color: ${(props) => props.theme.palette.grey[50]};
  top: auto;
  bottom: 0;
`;

export const FeatureHeader = styled.div`
  bottom: auto;
  top: 62px;
`;

export const BottomCancelButton = styled(Button)`
  margin: 8px 0;
  float: right;
  font-weight: 700;
  color: ${(props) => props.theme.secondaryColors.orange};
  font-size: 1.075em;
`;

export const BottomSaveButton = styled(Button)`
  margin: 8px 24px;
  float: right;
  font-weight: 700;
  font-size: 1.075em;
  // color: #008A86;
  color: #005c59;
`;

export const StyledTabs = styled(Tabs)`
  padding: 0 32px;
  border-bottom: 1px solid #e8e8e8;
  color: gray;
`;

export const StyledTab = styled(Tab)`
  min-width: 72px;
  // font-size: 1.22em;
  &:hover {
    color: #008a86;
    opacity: 1;
  }
  &.Mui-selected {
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: 600;
  }
`;

export const LargeIconButton = styled(IconButton)`
  float: right;
  padding: 0;
  > span {
    > svg {
      width: 2.25rem;
      height: 2.25rem;
    }
  }
  &:hover {
    color: #008a86;
    opacity: 1;
  }
`;

export const Icon = styled(IconButton)<{ redcolor?: string }>`
  float: right;

  ${(props) => {
    if (!!props.redcolor) return `color: ${props.theme.palette.error.main};`;
  }};
`;

export const RemoveIcon = styled(IconButton)`
  float: right;
  color: ${(props) => props.theme.palette.error.main};
`;

export const FormIcon = styled(IconButton)`
  padding: 0;
`;

export const RedFormNameIcon = styled(IconButton)`
  padding: 0;
  margin-bottom: 30px;
  color: ${(props) => props.theme.palette.error.main};
`;

export const MinusIcon = styled(Icon)`
  color: ${(props) => props.theme.palette.error.main};
`;

export const FormNameIcon = styled(IconButton)`
  padding: 0;
  margin-bottom: 30px;
`;

export const QuestionnaireAddIcon = styled(IconButton)`
  padding: 0;
  margin-top: 10px;
`;

export const NoBorderTextField = styled(TextField)`
  fieldset {
    border: none;
  }
  input {
    padding: 4px;
  }
`;

export const ContactCardContent = styled(CardContent)`
  padding: 4px;
  padding-bottom: 4px !important;
`;

export const EndButton = styled(Button)`
  float: right;
  margin-top: 8px;
  margin-bottom: 8px;
  &:hover {
    background-color: #008a86;
    opacity: 1;
  }
`;

export const EndButton2 = styled(Button)`
  float: right;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const TypographyWithMargin = styled(Typography)<{ tma?: string }>`
  margin-top: ${(props) => (props.tma ? props.tma : 'inherit')}px;
  margin-bottom: ${(props) => (props.tma ? props.tma : 'inherit')}px;
`;

export const CustomColorTypography = styled(Typography)<{ ccolor: string }>`
  color: ${(props) => props.ccolor} !important;
`;

export const RequestAssistanceTitle = styled(DialogTitle)`
  margin-left: 4px;
`;

export const ToolLabel = styled(Link).attrs({ component: Button })`
  margin-top: 24px;
  padding: 4px;
`;
export const Checkbox1 = styled(FormControlLabel)<{ outlined?: string }>`
  margin-top: 12px;
  padding: 4px;
  border-radius: 2px;
  border: ${(props) => (props.outlined ? '2px solid #e8e8e8' : 'none')};
`;

export const Checkbox2 = styled(FormControlLabel)`
  margin-top: 1px;
`;

export const CardCheckbox = styled(FormControlLabel)`
  margin-top: -4px;
  float: right;
  > span {
    padding: 0;
  }
`;

export const CardCheckbox2 = styled(FormControlLabel)`
  margin-top: 3px;
  float: right;
  > span {
    padding: 0;
  }
`;

export const CardCheckbox3 = styled(FormControlLabel)`
  > span {
    > span {
      margin-left: 16px;
    }
  }
`;

export const CardContent1 = styled(CardContent)`
  padding: 8px;
  padding-bottom: 8px !important;
`;

export const CardContent2 = styled(CardContent)`
  padding: 16px;
  padding-bottom: 8px !important;
`;

export const CardContent3 = styled(CardContent)`
  padding: 0px 16px !important;
`;

export const ListCardContent = styled(CardContent)`
  padding: 8px;
  padding-bottom: 2px !important;
`;

export const ClickableListCardContent = styled(CardContent)`
  padding: 8px;
  padding-bottom: 2px !important;
  cursor: pointer;
`;

export const CardImage = styled(CardContent)`
  padding: 8px;
  position: relative;
  min-height: 96px;
`;

export const CardHeaderDivider = styled(CardHeader)`
  border-bottom: 1px solid #e8e8e8;
`;

export const CardHeaderDivider1 = styled(CardHeader)`
  border-bottom: 1px solid #e8e8e8;
  padding: 8px;
`;

export const FeatureCardHeader = styled(CardHeader)`
  padding: 16px 16px 0 16px;
  //height: 74px;
`;

export const CancelLinkCardHeader = styled(CardHeader)`
  padding: 10em 15em;
`;

export const CustomCardSubHeader = styled(CardHeader)<{ color: string }>`
  .MuiCardHeader-subheader {
    color: ${(props) => props.color};
  }
`;

export const BoldText = styled.span`
  font-weight: 600;
  color: black;
`;

export const BlackText = styled.span`
  color: black;
`;
export const DarkText = styled.span`
  color: #333333;
`;

export const DashedBox = styled(Grid)`
  border: 3px dotted darkgrey;
  padding-top: 32px;
  padding-bottom: 32px;
  &:hover {
    cursor: pointer;
  }
`;

export const BMGrid = styled(Grid)`
  margin-bottom: 8px;
`;

export const TMGrid = styled(Grid)`
  margin-top: 8px;
`;

export const PaddedTMGrid = styled(Grid)`
  margin-top: 8px;
  padding: 5px;
`;

export const TMGrid1 = styled(Grid)`
  margin-top: 3px;
`;

export const TMGrid2 = styled(Grid)`
  margin-top: 10px;
`;

export const TMGrid3 = styled(Grid)`
  margin-top: 24px;
`;

export const LMGrid = styled(Grid)`
  margin-left: 8px;
`;

export const TLMGrid = styled(Grid)`
  margin-top: 8px;
  margin-left: 8px;
  margin-right: -8px;
`;

export const PGrid = styled(Grid)`
  padding: 8px;
`;

export const UnderlinedGrid = styled(Grid)`
  border-bottom: 2px solid #b0b0b0;
  padding-left: 10px;
  padding-bottom: 5px;
`;

export const CondensedGrid = styled(Grid)`
  > p {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

export const FormDateField = styled(TextField)`
  width: 120px;
`;

export const HeaderSearchTextField = styled(TextField)`
  &.label.Mui-focused {
    color: white;
  }
  &.MuiInput-underline:after {
    borderbottomcolor: white;
  }
  &.MuiOutlinedInput-root {
    &:fieldset {
      bordercolor: white;
    }
    &:hover fieldset {
      bordercolor: white;
    }
    &.Mui-focused fieldset {
      bordercolor: white;
    }
  }
  width: 135px;
  padding: 5px;
  margin-bottom: 5px;
`;

export const TextNumberField = styled(TextField)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: 5px;

  input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0 !important;
    height: 1.1875em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none !important;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    -webkit-tap-highlight-color: transparent;
  }
  input:focus {
    outline: none;
  }
`;

export const SettingsGrid = styled(Grid)`
  margin: 2px 2px 27px 2px;
`;

export const MHARadio = styled(Radio)`
  &.Mui-checked {
    color: #008a86;
  }
`;

export const MHACheckbox = styled(Checkbox)`
  &.Mui-checked {
    color: #008a86;
  }
`;

export const AutocompleteSearch = styled(Autocomplete)<
  AutocompleteProps<string, undefined, undefined, true>
>`
  > div {
    width: 175px !important;
  }
`;

export const PdfGrid = styled(Grid)<{ scale: number }>`
  > div.react-pdf__Document {
    transform: scale(${(props) => props.scale ?? 1});
  }
`;

export const ViewCard = styled(Card)`
  width: 100%;
`;

export const TableCard = styled(Card)`
  width: 100%;
  padding: 2px;
`;

export const ChooseFileButton = styled(Button)`
  margin: 8px 24px;
  float: left;
  font-weight: 700;
  font-size: 1.075em;
  color: white;
`;

export const MfaDialog = styled(Dialog)`
  max-width: 820px;
`;

export const MfaIframe = styled.iframe`
  width: 700px !important;
  min-width: 700px !important;
  max-width: 820px !important;
  height: 420px;
  border: none;
`;

export const MfaDiv = styled.div`
  width: 700px !important;
  min-width: 700px !important;
  max-width: 820px !important;
  height: 420px;
  border: none;
`;

export const Emphasize = styled.span`
  color: ${(props) => props.theme.palette.primary.main};
  text-decoration: underline;
`;

export const TelerikDiv = styled.div<{ scrollx?: string }>`
  //div.trv-report-page { width: 95% !important; }
  //div.sheet  { width: 100% !important; }
  //div.textBox7 { width: 95% !important; }

  ${(props) => {
    if (!!props.scrollx)
      return `div.trv-report-page > div.sheet { 
      //overflow-x: scroll !important;
      //overflow-y: scroll !important;
      //height: calc(100vh - 300px) !important;
      //width: calc(100vw - 280px) !important;
}`;
  }};

  //overflow: hidden;
  clear: both;
  > .trv-report-viewer {
    height: calc(100vh - 240px);
  }
`;

export const ReportGrid = styled(Grid)`
  .trv-report-viewer {
    height: calc(80vh - 20px) !important;
  }
`;

export const FixedGrid = styled(Grid)<{
  sideswidth?: string;
  sidesheight?: string;
}>`
  position: fixed;
  width: calc(
    33vw - ${(props) => (props.sideswidth ? props.sideswidth : '40')}px
  );
  overflow: scroll;
  height: calc(
    70vh - ${(props) => (props.sidesheight ? props.sidesheight : '8')}px
  );

  @media only screen and (max-width: 960px) {
    position: relative;
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-height: 1000px) and (min-width: 960px) {
    height: calc(
      69vh - ${(props) => (props.sidesheight ? props.sidesheight : '8')}px
    );
  }
  @media only screen and (max-height: 1000px) and (max-width: 1280px) {
    height: calc(
      57vh - ${(props) => (props.sidesheight ? props.sidesheight : '8')}px
    );
  }

  @media only screen and (max-height: 900px) and (min-width: 960px) {
    height: calc(
      65vh - ${(props) => (props.sidesheight ? props.sidesheight : '8')}px
    );
  }
  @media only screen and (max-height: 900px) and (max-width: 1280px) {
    height: calc(
      52vh - ${(props) => (props.sidesheight ? props.sidesheight : '8')}px
    );
  }

  @media only screen and (max-height: 800px) and (min-width: 960px) {
    height: calc(
      61vh - ${(props) => (props.sidesheight ? props.sidesheight : '8')}px
    );
  }
  @media only screen and (max-height: 800px) and (max-width: 1280px) {
    height: calc(
      45vh - ${(props) => (props.sidesheight ? props.sidesheight : '8')}px
    );
  }

  @media only screen and (max-height: 700px) and (min-width: 960px) {
    height: calc(
      55vh - ${(props) => (props.sidesheight ? props.sidesheight : '8')}px
    );
  }
  @media only screen and (max-height: 700px) and (max-width: 1280px) {
    height: calc(
      36vh - ${(props) => (props.sidesheight ? props.sidesheight : '8')}px
    );
  }

  @media only screen and (max-height: 600px) and (min-width: 960px) {
    height: calc(
      46vh - ${(props) => (props.sidesheight ? props.sidesheight : '8')}px
    );
  }
  @media only screen and (max-height: 600px) and (max-width: 1280px) {
    height: calc(
      24vh - ${(props) => (props.sidesheight ? props.sidesheight : '8')}px
    );
  }

  @media only screen and (max-height: 500px) and (min-width: 960px) {
    height: calc(36vh - 8px);
  }
  @media only screen and (max-height: 500px) and (max-width: 1280px) {
    height: calc(16vh - 8px);
  }

  @media only screen and (max-height: 400px) and (min-width: 960px) {
    height: calc(26vh - 8px);
  }
  @media only screen and (max-height: 400px) and (max-width: 1280px) {
    height: calc(12vh - 8px);
  }
`;

export const VeritclePadding = styled(Grid).attrs({ item: true, xs: 12 })<{
  px?: number;
}>`
  height: ${(props) => (!!props.px ? props.px : '40')}px;
`;

export const SidesPagination = styled(Pagination)<{ errorindex: number[] }>`
  ${(props) =>
    props.errorindex.map(
      (e) =>
        `li:nth-child(${e + 2}) button.MuiPaginationItem-outlinedPrimary {
        background-color: pink;
      }`
    )};
`;
