import React from 'react';
import { FormikErrors, FormikHandlers, getIn } from 'formik';

// material ui
import { Grid, Card, Typography, Checkbox, TextField } from '@material-ui/core';

import { ClientQuestionnaireDto } from '../dto';
import {
  CardContent3,
  CustomCardSubHeader,
} from '../../../common/styles/styles';
import Label from '../../../common/components/label';
import { IsCompleteBox } from '../../claims/claims.styles';
import theme from '../../../common/themes/theme';

interface Props extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  setShouldBlockNavigation: (o: boolean) => void;
  errors: FormikErrors<ClientQuestionnaireDto>;
}

const ClaimProfileCard: React.FC<Props> = ({
  values,
  handleChange,
  handleBlur,
  setShouldBlockNavigation,
  errors,
}) => {
  return (
    <Card>
      <CustomCardSubHeader
        subheader='Ready to Submit?'
        color={theme.secondaryColors.orange}
        subheaderTypographyProps={{ variant: 'h5' }}
      ></CustomCardSubHeader>
      <CardContent3>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              id='submitName'
              size='small'
              margin='none'
              value={values.submitName || ''}
              label='Your Name'
              name='submitName'
              onChange={(e) => {
                handleChange(e);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              error={Boolean(getIn(errors, 'submitName'))}
              helperText={
                Boolean(getIn(errors, 'submitName')) &&
                getIn(errors, 'submitName')
              }
              data-cy='clientQuestionnaire_submitName'
              aria-label='Your Name'
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            &nbsp;
          </Grid>

          <Grid item xs={6}>
            <TextField
              id='submitEMail'
              size='small'
              margin='none'
              value={values.submitEMail || ''}
              label='Your Email Address'
              name='submitEMail'
              onChange={(e) => {
                handleChange(e);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              error={Boolean(getIn(errors, 'submitEMail'))}
              helperText={
                Boolean(getIn(errors, 'submitEMail')) &&
                getIn(errors, 'submitEMail')
              }
              data-cy='clientQuestionnaire_submitEMail'
              aria-label='Your Email Address'
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            &nbsp;
          </Grid>

          <Grid item xs={12}>
            <Typography variant='h6' color='textSecondary' display='block'>
              You may save and revisit this questionnaire by clicking save at
              the bottom of the page, and re-opening the link at any point, or
              forwarding the link to another individual to complete. Once you
              have completed the questionnaire, to submit your responses, please
              check the "complete" box below and select Save - this will submit
              your responses to
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <IsCompleteBox
              id='completed'
              name='completed'
              onChange={(e) => {
                handleChange(e);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              value={values.completed}
              checked={values.completed}
              control={
                <Checkbox
                  size='medium'
                  data-cy='claimNotes_dialog_isCompleteCheckbox'
                />
              }
              label={<Typography variant='h6'>Complete</Typography>}
            />
          </Grid>
        </Grid>

        <br />
        <br />
      </CardContent3>
    </Card>
  );
};

export default ClaimProfileCard;
