import { combineReducers } from 'redux';
import { createReducer, getType } from 'typesafe-actions';

import { getTimeZonesActions, timeZonesShouldRefresh } from '../actions/timeZones.actions';
import { TimeZonesDto } from '../dtos/timeZones';



export const timeZonesReducer = combineReducers({
  timeZonesList: createReducer([] as TimeZonesDto[])
    .handleAction(
      [
        getTimeZonesActions.success,
      ], (state, action) => action.payload),

  refreshTimeZones: createReducer(true)
    .handleType(getType(timeZonesShouldRefresh), (state, action) => action.payload),

});

export default timeZonesReducer;