


import { ISelectOption } from "../dtos/SelectOptionDto";
enum FrequencyCodes {
        W = 0,
        B = 1,
        M = 2,
        Q = 3,
        L = 4,
        D = 5,
        S = 6,
        Z = 7,
         
}

export class FrequencyCodes_class {

public static enum = FrequencyCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['W']: 0,
    ['B']: 1,
    ['M']: 2,
    ['Q']: 3,
    ['L']: 4,
    ['D']: 5,
    ['S']: 6,
    ['Z']: 7,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "Weekly",
    [1]: "Bi-weekly",
    [2]: "Monthly",
    [3]: "Quarterly",
    [4]: "Lump sum",
    [5]: "Daily",
    [6]: "Semi-monthly",
    [7]: "Not Able to Provide",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "Weekly"},
  {value: 1, text: "Bi-weekly"},
  {value: 2, text: "Monthly"},
  {value: 3, text: "Quarterly"},
  {value: 4, text: "Lump sum"},
  {value: 5, text: "Daily"},
  {value: 6, text: "Semi-monthly"},
  {value: 7, text: "Not Able to Provide"},
  ]
}

