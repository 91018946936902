import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { getReportsApi } from './api';
import { getTelerikReportsActions } from './actions';
import { TelerikReportDto } from './dtos';



function* handleGetReports() {
  const reportList: TelerikReportDto[] = yield select(state => state.reports.list);
  const reportStorage = sessionStorage.getItem('reports');
  const sessionReports = reportStorage === null ? [] : JSON.parse(reportStorage) as TelerikReportDto[];
  const reports: TelerikReportDto[] = reportList !== undefined && reportList.length !== 0 ? reportList :
    sessionReports.length !== 0 ? sessionReports :
      yield call(getReportsApi);
  yield put({ type: getType(getTelerikReportsActions.success), payload: reports });
  if (reportStorage === null) {
    sessionStorage.setItem('reports', JSON.stringify(reports))
  }
}

export function* watchReportsGetRequest() {
  try {
    yield takeLatest(getType(getTelerikReportsActions.request), handleGetReports);
  }
  catch (err) {
    
  }
}



