import React from 'react';
import { FormikHandlers, FormikErrors, getIn } from 'formik';

// material ui
import { Grid, TextField } from '@material-ui/core';
import { AdditionalQuestionDto } from '../dto';


interface Props extends React.HTMLProps<React.ReactNode> {
  question: AdditionalQuestionDto;
  errors: FormikErrors<AdditionalQuestionDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setShouldBlockNavigation: (o: boolean) => void;
  index?: number;
}

const AdditionalQuestions: React.FC<Props> = (
  {
    question,
    errors,
    handleChange,
    handleBlur,
    index,
    setShouldBlockNavigation
  }) => {

  return (
    <>
      <Grid item xs={12}>{question.question}</Grid>
      <Grid item sm={12} md={6} lg={3} key={'additionalQuestion_' + index}>
        <TextField
          id={`questions[${index}].answer`}
          size="small"
          margin="none"
          value={question.answer || ''}
          InputLabelProps={{ required: true }}
          name={`questions[${index}].answer`}
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(getIn(errors, `questions[${index}].answer`))}
          helperText={getIn(errors, `questions[${index}].answer`) && getIn(errors, `questions[${index}].answer`)}
          data-cy={`questions[${index}].answer`}
          fullWidth
        />
      </Grid>
    </>
  )
}

export default AdditionalQuestions;
