import { combineReducers } from 'redux';
import { createReducer, getType } from 'typesafe-actions';

import {
  getCompaniesActions, getCompanyActions, companyShouldRefresh, companyListShouldRefresh, getCompaniesHsActions,
    companyImportHistoryShouldRefresh, getCompanyBulkImportHistoryActions, mhaContactListShouldRefresh, getCompanyRateActions, 
} from './actions';
import { CompanyDto, companyInit, CompanyHsDto } from './dtos';
import { BulkImportDto } from '../../common/dtos/bulkImportDto';
import { getMhaContactsActions } from '../../common/actions/actions';
import { MhaContactsDto } from '../../common/dtos/dto';
import { RateWrapperDto, RateWrapperInit } from '../../common/dtos/billing';


const companiesReducer = combineReducers({
  companiesList: createReducer([] as CompanyDto[])
    .handleAction(
      [
        getCompaniesActions.success
      ], (state, action) => action.payload),

  companyRequest: createReducer("")
    .handleAction(
      [
        getCompanyActions.request
      ], (state, action) => action.payload),

  company: createReducer(companyInit)
    .handleAction(
      [
        getCompanyActions.success
        ], (state, action) => action.payload),

    rateRequest: createReducer(RateWrapperInit as RateWrapperDto)
        .handleAction(
            [
                getCompanyRateActions.request
            ], (state, action) => action.payload),

    rate: createReducer(RateWrapperInit as RateWrapperDto)
        .handleAction(
            [
                getCompanyRateActions.success
            ], (state, action) => action.payload),




  refreshCompany: createReducer('')
    .handleType(getType(companyShouldRefresh), (state, action) => action.payload),

  refreshCompanyList: createReducer(true)
    .handleType(getType(companyListShouldRefresh), (state, action) => action.payload),

  refreshCompanyImportHistory: createReducer(true)
    .handleType(getType(companyImportHistoryShouldRefresh), (state, action) => action.payload),

  refreshMhaContacts: createReducer(true)
    .handleType(getType(mhaContactListShouldRefresh), (state, action) => action.payload),

  bulkImportHistory: createReducer([] as BulkImportDto[])
    .handleAction(
      [
        getCompanyBulkImportHistoryActions.success,
      ], (state, action) => action.payload),

  mhaContacts: createReducer([] as MhaContactsDto[])
    .handleAction(
      [
        getMhaContactsActions.success,
      ], (state, action) => action.payload),

  hsCompanies: createReducer([] as CompanyHsDto[])
    .handleAction(
      [
        getCompaniesHsActions.success
      ], (state, action) => action.payload),
});

export default companiesReducer;
