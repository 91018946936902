import { AxiosError } from 'axios';
import { ApiError } from '../../../common/api/apiResponse';
import apiClient from '../../../common/api/apiClient';
import { EligibilityCodeDto, LocationCodeDto, SeparationCodeDto } from './codes.dtos';
import { showNotification } from '../../../common/actionCreators/dispatchNotification';


// See other Api files for examples.
// endpoints
const codesUrl = 'settings/codes';
const eligibilityCodesUrl = 'settings/codes/eligibility';
const separationCodesUrl = 'settings/codes/separation';
const locationCodesUrl = 'settings/codes/location';


export const getCodesApi = async () => {
  return await apiClient().get(`${codesUrl}`)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while retrieving codes.', 'error');
      throw err;
    });
};

export const getEligibilityCodesApi = async () => {
  return await apiClient().get(`${eligibilityCodesUrl}`)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while retrieving codes.', 'error');
      throw err;
    });
};

export const getSeparationCodesApi = async () => {
  return await apiClient().get(`${separationCodesUrl}`)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while retrieving codes.', 'error');
      throw err;
    });
};

export const getLocationCodesApi = async () => {
  return await apiClient().get(`${locationCodesUrl}`)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while retrieving codes.', 'error');
      throw err;
    });
};

export const updateEligibilityCodesApi = async (request: EligibilityCodeDto[]) => {
  return await apiClient().put(eligibilityCodesUrl, request)
    .then((res) => {
      showNotification('Eligibility Code saved.', 'success');
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while updating Eligibility Code.', 'error');
      throw err;
    });
};

export const createEligibilityCodesApi = async (request: EligibilityCodeDto[]) => {
  return await apiClient().post(eligibilityCodesUrl, request)
    .then((res) => {
      showNotification('Eligibility Code created.', 'success');
      return res.data.result;

    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while creating Eligibility Code.', 'error');
      throw err;
    });
};


export const updateSeparationCodesApi = async (request: SeparationCodeDto[]) => {
  return await apiClient().put(separationCodesUrl, request)
    .then((res) => {
      showNotification('Separation Code saved.', 'success');
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while updating Separation Code.', 'error');
      throw err;
    });
};

export const createSeparationCodesApi = async (request: SeparationCodeDto[]) => {
  return await apiClient().post(separationCodesUrl, request)
    .then((res) => {
      showNotification('Separation Code created.', 'success');
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while creating Separation Code.', 'error');
      throw err;
    });
};

export const updateLocationCodesApi = async (request: LocationCodeDto[]) => {
  return await apiClient().put(locationCodesUrl, request)
    .then((res) => {
      showNotification('Location Code saved.', 'success');
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while updating Location Code.', 'error');
      throw err;
    });
};

export const createLocationCodesApi = async (request: LocationCodeDto[]) => {
  return await apiClient().post(locationCodesUrl, request)
    .then((res) => {
      showNotification('Location Code created.', 'success');
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while creating Location Code.', 'error');
      throw err;
    });
};


