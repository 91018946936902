import React from 'react';
import { useDispatch } from 'react-redux';
import { FieldArray, getIn, Formik, FieldArrayRenderProps } from 'formik';
import * as Yup from 'yup';
import { getType } from 'typesafe-actions';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import Dropzone from 'react-dropzone';
import moment from 'moment';
// material ui
import Pagination from '@material-ui/lab/Pagination';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  Grid,
  Typography,
  TextField,
  Card,
  Checkbox,
  Button,
  Paper,
  FormControlLabel,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  Link,
  MenuItem,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ClaimDto, ClaimInit } from '../claims.dtos';
import {
  DocumentsDto,
  ClaimDocumentRequestDto,
} from '../../documents/document.dtos';
import { documentTypeOptionsList } from '../../../common/enums/claimEnums';
import { rootSelector } from '../../../common/selectors/selectors';
import BottomActionFormBar from '../../../common/components/bottomActionFormBar';
import { deleteDocumentsActions } from '../../documents/actions';
import {
  documentUploadRequestActions,
  documentZipRequestActions,
  getClaimDocumentsActions,
  getClaimActions,
  updateClaimActions,
} from '../actions';
import {
  emailTemplateRecipient,
  toggleEmailTemplateDialog,
} from '../../../common/actions/emailTemplate.actions';
import { ImportTable } from '../claims.styles';
import {
  IndentGrid,
  UnderlinedGrid,
  DarkText,
  TMGrid3,
  CardHeaderDivider1,
  CardContent2,
  BlackText,
  DashedBox,
} from '../../../common/styles/styles';
import { FeatureTypeEnum } from '../../../common/enums/featureTypeEnum';

interface DocumentUploadProps extends React.HTMLProps<React.ReactNode> {
  isClientUser: boolean;
  isReadOnly: boolean;
}

const DocumentUpload: React.FC<DocumentUploadProps> = ({
  isClientUser,
  isReadOnly,
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const dispatch = useDispatch();

  const baseUrl = process.env.REACT_APP_API_URL ?? '';
  const downloadDocumentUrl = baseUrl + 'download/documents/';

  const systemClaim = rootSelector((state) => state.claims.claim);

  const [id, setId] = React.useState<string | undefined>(undefined);
  const [dto, setDto] = React.useState<ClaimDto>(ClaimInit);
  const [fileNames, setFileNames] = React.useState<string[]>([]);
  const [shouldBlockNavigation, setShouldBlockNavigation] =
    React.useState<boolean>(false);
  const [documentUrl, setDocumentUrl] = React.useState<string | any>('');
  const [pdfScale, setPdfScale] = React.useState<number>(0.4);
  const [numPages, setNumPages] = React.useState<number>(0);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [emailRecipient, setEmailRecipient] = React.useState<string>('');

  React.useEffect(() => {
    if (id === undefined) {
      dispatch({
        type: getType(getClaimDocumentsActions.request),
        payload: systemClaim.id,
      });
      setId(systemClaim.id);
    }
  }, [dispatch, systemClaim, id]);

  React.useEffect(() => {
    setDto(systemClaim);
    setEmailRecipient(systemClaim.primaryEmployerContactEmail ?? '');
  }, [systemClaim]);

  function onDocumentLoadSuccess({ numPages }: any) {
    setPageNumber(1);
    setNumPages(numPages);
  }

  const handleCancel = () => {
    window.location.href = '/claims';
  };

  const handleDrop = (
    acceptedFiles: File[],
    arrayHelpers: FieldArrayRenderProps
  ) => {
    let fileList = [] as string[];
    acceptedFiles.forEach((f) => {
      fileList.push(f.name);
      let newDoc: DocumentsDto = {
        claimId: dto.id,
        fileName: f.name,
        status: 0,
        type: 0,
        isComplete: false,
        isInternal: false,
        fileUpload: f,
      };

      arrayHelpers.push(newDoc);
    });
    setFileNames(fileList);
  };

  const handleZoomIn = () => {
    let newScale = pdfScale >= 2 ? 2 : pdfScale + 0.2;
    setPdfScale(newScale);
  };

  const handleZoomOut = () => {
    let newScale = pdfScale <= 0.2 ? 0.2 : pdfScale - 0.2;
    setPdfScale(newScale);
  };

  const handleGetDocumentsZip = (v: ClaimDto) => {
    let documents = v.documents.filter((d) => d.isSelected);

    if (documents.length > 0) {
      let getZipRequest: ClaimDocumentRequestDto = {
        claimId: dto.id ?? '',
        documents: documents,
      };

      dispatch({
        type: getType(documentZipRequestActions.request),
        payload: getZipRequest,
      });

      // Reset checkboxes.
      let newDto: ClaimDto = JSON.parse(JSON.stringify(dto));
      newDto.documents.forEach((d) => (d.isSelected = false));

      setDto(newDto);
    }

    dispatch({
      type: getType(emailTemplateRecipient),
      payload: emailRecipient,
    });
    dispatch({ type: getType(toggleEmailTemplateDialog), payload: true });
  };

  const handleDeleteDocuments = (v: ClaimDto) => {
    let documents = v.documents.filter((d) => d.isSelected);

    documents.forEach((d) => {
      dispatch({
        type: getType(deleteDocumentsActions.request),
        payload: d.id,
      });
    });

    //Reset checkboxes.
    let newDto: ClaimDto = JSON.parse(JSON.stringify(v));
    newDto.documents = newDto.documents.filter((d) => !d.isSelected);

    //setDto(newDto);
    dispatch({ type: getType(getClaimActions.success), payload: newDto });
  };

  return (
    <>
      <Formik
        initialValues={dto}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
          documents: Yup.array().of(
            Yup.object().shape({
              dateReceived: Yup.date().nullable(),
              type: Yup.number().min(0).max(19).nullable(),
              isInternal: Yup.boolean().nullable(),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
          }, 700);
          //let documents = values.documents.filter(d => d.id === undefined || d.id === '');
          let uploadRequest: ClaimDocumentRequestDto = {
            claimId: dto.id ?? '',
            documents: values.documents,
          };

          dispatch({
            type: getType(documentUploadRequestActions.request),
            payload: uploadRequest,
          });
        }}
        enableReinitialize={true}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            handleBlur,
            isSubmitting,
          } = props;
          return (
            <form onSubmit={handleSubmit}>
              <FieldArray
                name='documents'
                render={(arrayHelpers) => (
                  <>
                    <Card>
                      <CardHeaderDivider1
                        title={
                          <>
                            <BlackText>
                              <Typography variant='subtitle1'>
                                Upload Documents
                              </Typography>
                            </BlackText>
                          </>
                        }
                      ></CardHeaderDivider1>
                      <CardContent2>
                        <div
                          hidden={isReadOnly === true || isClientUser === true}
                          data-cy='dropZone_div'
                        >
                          <Grid container justifyContent='center'>
                            <Dropzone
                              aria-label='File drop zone'
                              onDrop={(a) => {
                                handleDrop(a, arrayHelpers);
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <DashedBox
                                  container
                                  item
                                  xs={9}
                                  justifyContent='center'
                                  {...getRootProps({ className: 'dropzone' })}
                                >
                                  <input
                                    aria-label='File input area'
                                    {...getInputProps()}
                                  />
                                  <Typography variant='h5' display='block'>
                                    Drag your file here or click to select
                                  </Typography>
                                </DashedBox>
                              )}
                            </Dropzone>
                          </Grid>
                        </div>
                        <Grid container justifyContent='center'>
                          <div>
                            <ul>
                              {fileNames.map((fileName) => (
                                <li key={fileName}>{fileName}</li>
                              ))}
                            </ul>
                          </div>
                        </Grid>
                      </CardContent2>
                    </Card>

                    <Grid container spacing={2}>
                      <IndentGrid container justifyContent='center'>
                        {documentUrl.length === 0 ? (
                          <Paper
                            style={{
                              height: 200,
                              width: '100%',
                              marginLeft: 4,
                              backgroundColor: 'lightgray',
                              marginTop: 16,
                            }}
                            elevation={3}
                          >
                            <Grid container justifyContent='center'>
                              <span
                                style={{
                                  marginTop: 84,
                                  fontSize: 32,
                                  fontWeight: 400,
                                }}
                              >
                                PDF VIEWER
                              </span>
                            </Grid>
                          </Paper>
                        ) : (
                          <>
                            <TMGrid3
                              container
                              item
                              xs={12}
                              justifyContent='center'
                              hidden={numPages === 0}
                            >
                              <Button
                                title='Reset Zoom'
                                color='secondary'
                                onClick={() => setPdfScale(0.4)}
                                data-cy='claim_pdf_zoomIn'
                              >
                                <RefreshIcon />
                              </Button>
                            </TMGrid3>

                            <TMGrid3
                              container
                              item
                              xs={12}
                              justifyContent='center'
                              hidden={numPages === 0}
                            >
                              <Button
                                title='Zoom in pdf'
                                color='secondary'
                                onClick={handleZoomOut}
                                data-cy='claim_pdf_zoomOut'
                              >
                                <ZoomOutIcon />
                              </Button>
                              <Pagination
                                page={pageNumber}
                                count={numPages}
                                variant='outlined'
                                color='primary'
                                size='small'
                                onChange={(e, p) => {
                                  setPageNumber(p);
                                }}
                              />
                              <Button
                                title='Zoom in pdf'
                                color='secondary'
                                onClick={handleZoomIn}
                                data-cy='claim_pdf_zoomIn'
                              >
                                <ZoomInIcon />
                              </Button>
                            </TMGrid3>
                            <Grid item>
                              <Paper
                                style={{
                                  maxHeight: 600,
                                  width: '100%',
                                  maxWidth: window.parent.innerWidth - 252,
                                  overflow: 'auto',
                                  marginTop: 16,
                                }}
                                elevation={3}
                              >
                                <Grid item xs={12}>
                                  <Document
                                    file={documentUrl}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                  >
                                    <Page
                                      pageNumber={pageNumber}
                                      width={window.parent.innerWidth - 124}
                                      scale={pdfScale}
                                    />
                                  </Document>
                                </Grid>
                              </Paper>
                            </Grid>
                          </>
                        )}
                      </IndentGrid>
                    </Grid>

                    <ImportTable>
                      <Grid container>
                        <UnderlinedGrid container>
                          <Grid item xs={10}>
                            <DarkText>
                              <Typography variant='subtitle1'>
                                Documents
                              </Typography>
                            </DarkText>
                          </Grid>
                          <Grid container item xs={1} justifyContent='flex-end'>
                            <div
                              hidden={
                                isClientUser === true || isReadOnly === true
                              }
                            >
                              <Link
                                component={Button}
                                onClick={() => handleGetDocumentsZip(values)}
                                color='primary'
                                title='Email Documents'
                              >
                                <Typography color='primary' variant='body1'>
                                  Email
                                </Typography>
                              </Link>
                            </div>
                          </Grid>
                          <Grid container item xs={1} justifyContent='flex-end'>
                            <div
                              hidden={
                                isClientUser === true || isReadOnly === true
                              }
                            >
                              <Link
                                component={Button}
                                onClick={() => handleDeleteDocuments(values)}
                                color='primary'
                                title='Delete Documents'
                              >
                                <Typography color='primary' variant='body1'>
                                  DELETE
                                </Typography>
                              </Link>
                            </div>
                          </Grid>
                        </UnderlinedGrid>

                        <br />
                        <Grid item xs={12}>
                          <TableContainer>
                            <Table
                              size='small'
                              aria-label='Employer Import History Table'
                              style={{ width: '100%' }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    title='Date Received'
                                    align='center'
                                    colSpan={2}
                                  >
                                    Date Received
                                  </TableCell>
                                  <TableCell
                                    title='Date Indexed'
                                    align='center'
                                  >
                                    Date Indexed
                                  </TableCell>
                                  <TableCell title='Uploaded By' align='center'>
                                    Uploaded By
                                  </TableCell>
                                  <TableCell
                                    title='Document Type'
                                    align='center'
                                  >
                                    Document Type
                                  </TableCell>
                                  <TableCell title='File Name' align='center'>
                                    File Name
                                  </TableCell>
                                  <TableCell title='GUID' align='center'>
                                    GUID
                                  </TableCell>
                                  {isClientUser === false ? (
                                    <>
                                      <TableCell
                                        title='Internal'
                                        data-cy='documentUploadTableHead_internal'
                                        align='center'
                                      >
                                        Internal
                                      </TableCell>
                                      <TableCell
                                        title='Download'
                                        data-cy='documentUploadTableHead_download'
                                        align='center'
                                      >
                                        Download
                                      </TableCell>
                                      <TableCell
                                        title='Select'
                                        data-cy='documentUploadTableHead_select'
                                        align='center'
                                      >
                                        Select
                                      </TableCell>
                                    </>
                                  ) : (
                                    <>
                                      <TableCell
                                        title='Download'
                                        data-cy='documentUploadTableHead_download'
                                        align='center'
                                      >
                                        Download
                                      </TableCell>
                                    </>
                                  )}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {values.documents &&
                                  values.documents
                                    .sort((a, b) => {
                                      return moment(a.dateReceived).diff(
                                        moment(b.dateReceived)
                                      );
                                    })
                                    .map((o, index) => (
                                      <TableRow key={'dateReceived_' + index}>
                                        <TableCell
                                          padding='checkbox'
                                          component='th'
                                          scope='row'
                                          align='center'
                                          colSpan={2}
                                        >
                                          <KeyboardDatePicker
                                            margin='normal'
                                            id={`documents[${index}].dateReceived`}
                                            name={`documents[${index}].dateReceived`}
                                            format='MM/DD/yyyy'
                                            disabled={
                                              isReadOnly === true ||
                                              isClientUser === true
                                            }
                                            value={o.dateReceived}
                                            fullWidth
                                            onChange={(
                                              date: MaterialUiPickersDate
                                            ) =>
                                              setFieldValue(
                                                `documents[${index}].dateReceived`,
                                                date
                                              )
                                            }
                                            KeyboardButtonProps={{
                                              'aria-label': 'date received',
                                            }}
                                          />
                                        </TableCell>

                                        <TableCell
                                          padding='checkbox'
                                          component='th'
                                          scope='row'
                                          align='center'
                                        >
                                          {moment(o.dateIndexed).format(
                                            'MM/DD/yyyy'
                                          ) || ''}
                                        </TableCell>

                                        <TableCell
                                          padding='checkbox'
                                          component='th'
                                          scope='row'
                                          align='center'
                                        >
                                          {o.uploadedBy || ''}
                                        </TableCell>

                                        <TableCell
                                          padding='checkbox'
                                          component='th'
                                          scope='row'
                                          align='center'
                                        >
                                          <TextField
                                            id={`documents[${index}].type`}
                                            size='small'
                                            margin='none'
                                            select
                                            value={o.type}
                                            //style={{ marginBottom: 20 }}
                                            //label="Document Type"
                                            name={`documents[${index}].type`}
                                            onChange={(e) => {
                                              handleChange(e);
                                              setShouldBlockNavigation(true);
                                            }}
                                            onBlur={handleBlur}
                                            disabled={
                                              isReadOnly === true ||
                                              isClientUser === true
                                            }
                                            error={Boolean(
                                              getIn(
                                                errors,
                                                `documents[${index}].type`
                                              ) &&
                                                getIn(
                                                  touched,
                                                  `documents[${index}].type`
                                                )
                                            )}
                                            helperText={
                                              Boolean(
                                                getIn(
                                                  errors,
                                                  `documents[${index}].type`
                                                )
                                              ) &&
                                              Boolean(
                                                getIn(
                                                  touched,
                                                  `documents[${index}].type`
                                                )
                                              ) &&
                                              getIn(
                                                errors,
                                                `documents[${index}].type`
                                              )
                                            }
                                            fullWidth
                                          >
                                            {documentTypeOptionsList
                                              .filter(
                                                (option) => option.name !== ''
                                              )
                                              .map((option, index) => (
                                                <MenuItem
                                                  key={option.name + index}
                                                  value={option.id}
                                                >
                                                  {option.name}
                                                </MenuItem>
                                              ))}
                                          </TextField>
                                        </TableCell>

                                        <TableCell
                                          padding='checkbox'
                                          component='th'
                                          scope='row'
                                          align='center'
                                        >
                                          {o.id !== undefined &&
                                          o.id.length !== 0 ? (
                                            <Link
                                              component={Button}
                                              onClick={() =>
                                                setDocumentUrl(
                                                  downloadDocumentUrl +
                                                    encodeURIComponent(
                                                      o.fileName ?? ''
                                                    )
                                                )
                                              }
                                              color='primary'
                                            >
                                              {o.fileName}
                                            </Link>
                                          ) : (
                                            <Link
                                              component={Button}
                                              onClick={() =>
                                                setDocumentUrl(o.fileUpload)
                                              }
                                              color='primary'
                                            >
                                              {o.fileName}
                                            </Link>
                                          )}
                                        </TableCell>

                                        <TableCell
                                          padding='checkbox'
                                          component='th'
                                          scope='row'
                                          align='center'
                                        >
                                          {isClientUser === false
                                            ? o.sidesId
                                            : null}
                                        </TableCell>
                                        {isClientUser === false ? (
                                          <>
                                            <TableCell
                                              padding='checkbox'
                                              component='th'
                                              scope='row'
                                              align='center'
                                            >
                                              <FormControlLabel
                                                data-cy='documentUploadTableCell_isInternalCheckbox'
                                                id={`documents[${index}].isInternal`}
                                                name={`documents[${index}].isInternal`}
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  setShouldBlockNavigation(
                                                    true
                                                  );
                                                }}
                                                onBlur={handleBlur}
                                                value={o.isInternal}
                                                checked={o.isInternal}
                                                disabled={isReadOnly}
                                                control={
                                                  <Checkbox
                                                    style={{ marginLeft: 12 }}
                                                  />
                                                }
                                                label=''
                                              />
                                            </TableCell>

                                            <TableCell
                                              padding='checkbox'
                                              component='th'
                                              scope='row'
                                              align='center'
                                            >
                                              {o.id !== undefined &&
                                                o.id.length !== 0 && (
                                                  <Link
                                                    href={
                                                      downloadDocumentUrl +
                                                      encodeURIComponent(
                                                        o.fileName ?? ''
                                                      )
                                                    }
                                                    download
                                                    data-cy='documentUploadTableCell_downloadLink'
                                                  >
                                                    {o.fileName !== undefined &&
                                                    o.fileName.length !== 0
                                                      ? 'Download'
                                                      : ''}
                                                  </Link>
                                                )}
                                            </TableCell>

                                            <TableCell
                                              padding='checkbox'
                                              component='th'
                                              scope='row'
                                              align='center'
                                            >
                                              <FormControlLabel
                                                id={`documents[${index}].isSelected`}
                                                name={`documents[${index}].isSelected`}
                                                data-cy='documentUploadTableCell_isSelectedCheckbox'
                                                onChange={(e) => {
                                                  handleChange(e);
                                                  setShouldBlockNavigation(
                                                    true
                                                  );
                                                }}
                                                onBlur={handleBlur}
                                                disabled={isReadOnly}
                                                value={o.isSelected || false}
                                                checked={o.isSelected || false}
                                                control={
                                                  <Checkbox
                                                    style={{ marginLeft: 32 }}
                                                  />
                                                }
                                                label=''
                                              />
                                            </TableCell>
                                          </>
                                        ) : (
                                          <>
                                            <TableCell
                                              padding='checkbox'
                                              component='th'
                                              scope='row'
                                              align='center'
                                            >
                                              {o.id !== undefined &&
                                                o.id.length !== 0 && (
                                                  <Link
                                                    href={
                                                      downloadDocumentUrl +
                                                      encodeURIComponent(
                                                        o.fileName ?? ''
                                                      )
                                                    }
                                                    download
                                                    data-cy='documentUploadTableCell_downloadLink'
                                                  >
                                                    {o.fileName !== undefined &&
                                                    o.fileName.length !== 0
                                                      ? 'Download'
                                                      : ''}
                                                  </Link>
                                                )}
                                            </TableCell>
                                          </>
                                        )}
                                      </TableRow>
                                    ))}
                              </TableBody>
                            </Table>
                            <br />
                            <br />
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </ImportTable>
                    <BottomActionFormBar
                      cancelClickHandler={handleCancel}
                      submitting={isSubmitting}
                      featureType={FeatureTypeEnum.Payments}
                    />
                  </>
                )}
              />
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default DocumentUpload;
