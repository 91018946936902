import * as Yup from "yup";
import { dateError, requiredError } from "../../common/constants/strings";

export type reportParameterTypes =
  | BenchmarkAndComparisonReportParametersDto
  | ClaimantProfileReportParametersDto
  | ExecutiveSummaryReportParametersDto
  | ExecutiveSummaryWithIndividualBreakdownsReportParametersDto
  | NewUnemploymentClaimsReportParametersDto
  | ProductivityReportParametersDto
  | UnemploymentClaimsChargeReportParametersDto
  | UnemploymentClaimsSummaryReportParametersDto;

export interface TelerikReportDto {
  reportId: number;
  name: string;
  description: string;
  reportDefinitionFileName: string;
  parameters?: any;
}

export interface BaseReportParametersDto {
  startYearParameter?: number;
  endYearParameter?: number;
  groups: string[][];
  names: string[];
  healthSystems: string[];
  healthGroups: string[];
  /**
   * @deprecated This is an array of businessUnit**Ids**
   */
  businessUnits?: string[];
}

//#region "Annual Report Summary" Report
export interface AnnualSummaryReportParametersDto
  extends UnemploymentClaimsSummaryReportParametersDto {}

export const annualSummaryReportParametersInit: AnnualSummaryReportParametersDto =
  {
    startYearParameter: 0,
    endYearParameter: 0,
    groups: [[]],
    names: [],
    healthSystems: ["All-MHA"],
    healthGroups: [""],
    employeeId: "",
    excludeFiledPriorToPOA: false,
    excludeSecondYearClaim: false,
  };

export const annualSummaryReportValidationSchema = Yup.object({
  startYearParameter: Yup.number()
    .min(1900, "Year is required.")
    .max(2400, "Year is required.")
    .required(requiredError),
  healthSystems: Yup.array()
    .of(Yup.string())
    .test("has_required_values", requiredError, (v) => {
      for (let i = 0; i < 1; i++) {
        if (v[i] === undefined || (v[i] && v[i].length === 0)) return false;
      }
      return true;
    }),
  healthGroups: Yup.array().of(Yup.string()),
  groups: Yup.array().of(Yup.array().of(Yup.string())),
  employeeId: Yup.string()
    .max(200, "Must be 200 characters or less")
    .nullable(),
  excludeFiledPriorToPOA: Yup.boolean(),
  excludeSecondYearClaim: Yup.boolean(),
});
//#endregion

//#region "Benchmark And Comparison Report" Report
export interface BenchmarkAndComparisonReportParametersDto
  extends BaseReportParametersDto {}

export const benchmarkAndComparisonReportParametersInit: BenchmarkAndComparisonReportParametersDto =
  {
    startYearParameter: 0,
    endYearParameter: 0,
    groups: [[], [], [], [], []],
    names: [],
    healthSystems: ["All-MHA", "", "", "", "", "", "", "", "", ""],
    healthGroups: ["", "", "", "", "", "", "", "", "", ""],
  };

export const benchmarkAndComparisonReportValidationSchema = Yup.object({
  startYearParameter: Yup.number()
    .min(1900, "Start year is required.")
    .max(2400, "Start year is required.")
    .required(requiredError),
  endYearParameter: Yup.number()
    .min(1900, "End year is required.")
    .max(2400, "End year is required.")
    .required(requiredError),
  healthSystems: Yup.array()
    .of(Yup.string())
    .test("has_required_values", requiredError, (v) => {
      for (let i = 0; i < 5; i++) {
        if (v[i] === undefined || (v[i] && v[i].length === 0)) return false;
      }
      return true;
    }),
  healthGroups: Yup.array().of(Yup.string()),
  groups: Yup.array().of(Yup.array().of(Yup.string())),
});
//#endregion

//#region "Claimant Profile" Report
export interface ClaimantProfileReportParametersDto {
  claimId: string;
  displayParameter?: string;
}

export const claimantProfileReportParametersInit: ClaimantProfileReportParametersDto =
  {
    claimId: "",
  };

export const claimantProfileReportValidationSchema = Yup.object({
  claimId: Yup.string().required("Claimant is required"),
});

export const claimantProfileReportRequestValidationSchema = Yup.object({
  searchString: Yup.string().max(200, "Must be less than 200 characters"),
  ssn: Yup.string().max(200, "Must be less than 200 characters"),
  suta: Yup.string().max(200, "Must be less than 200 characters"),
  employeeId: Yup.string()
    .max(200, "Must be 200 characters or less")
    .nullable(),
});
//#endregion

//#region Credit Due Report
export interface CreditDueReportParametersDto extends BaseReportParametersDto {
  startDatetimeParameter?: Date;
  endDatetimeParameter?: Date;
  employeeId: string;
  excludeFiledPriorToPOA: boolean;
  excludeSecondYearClaim: boolean;
}

export const creditDueReportParametersInit: UnemploymentClaimsSummaryReportParametersDto =
  {
    startYearParameter: 0,
    endYearParameter: 0,
    groups: [[]],
    names: [],
    healthSystems: ["All-MHA"],
    healthGroups: [""],
    employeeId: "",
    excludeFiledPriorToPOA: false,
    excludeSecondYearClaim: false,
  };

export const creditDueReportValidationSchema = Yup.object({
  startDatetimeParameter: Yup.date()
    .typeError(dateError)
    .required(requiredError),
  endDatetimeParameter: Yup.date().typeError(dateError).required(requiredError),
  healthSystems: Yup.array()
    .of(Yup.string())
    .test("has_required_values", requiredError, (v) => {
      for (let i = 0; i < 1; i++) {
        if (v[i] === undefined || (v[i] && v[i].length === 0)) return false;
      }
      return true;
    }),
  healthGroups: Yup.array().of(Yup.string()),
  groups: Yup.array().of(Yup.array().of(Yup.string())),
  employeeId: Yup.string()
    .max(200, "Must be 200 characters or less")
    .nullable(),
  excludeFiledPriorToPOA: Yup.boolean(),
  excludeSecondYearClaim: Yup.boolean(),
});
//#endregion

//#region "Executive Summary Report" Report
export interface ExecutiveSummaryReportParametersDto
  extends UnemploymentClaimsSummaryReportParametersDto {
  startDatetimeParameter?: Date;
  endDatetimeParameter?: Date;
  hideInvestigations: boolean;
}

export const executiveSummaryReportParametersInit: ExecutiveSummaryReportParametersDto =
  {
    startYearParameter: 0,
    endYearParameter: 0,
    groups: [[]],
    names: [],
    healthSystems: ["All-MHA"],
    healthGroups: [""],
    employeeId: "",
    excludeFiledPriorToPOA: false,
    excludeSecondYearClaim: false,
    hideInvestigations: false,
  };

export const executiveSummaryReportValidationSchema = Yup.object({
  startDatetimeParameter: Yup.date()
    .typeError(dateError)
    .required(requiredError),
  endDatetimeParameter: Yup.date().typeError(dateError).required(requiredError),
  healthSystems: Yup.array()
    .of(Yup.string())
    .test("has_required_values", requiredError, (v) => {
      for (let i = 0; i < 1; i++) {
        if (v[i] === undefined || (v[i] && v[i].length === 0)) return false;
      }
      return true;
    }),
  healthGroups: Yup.array().of(Yup.string()),
  groups: Yup.array().of(Yup.array().of(Yup.string())),
  employeeId: Yup.string()
    .max(200, "Must be 200 characters or less")
    .nullable(),
  excludeFiledPriorToPOA: Yup.boolean(),
  excludeSecondYearClaim: Yup.boolean(),
  hideInvestigations: Yup.boolean(),
});
//#endregion

//#region "Executive Summary Report with Individual Employer Breakdowns" Report
export interface ExecutiveSummaryWithIndividualBreakdownsReportParametersDto
  extends UnemploymentClaimsSummaryReportParametersDto {
  startDatetimeParameter?: Date;
  endDatetimeParameter?: Date;
  hideInvestigations: boolean;
  groupIds: string[];
  healthSytemsIds: string[];
}

export const executiveSummaryWithIndividualBreakdownsReportParametersInit: ExecutiveSummaryWithIndividualBreakdownsReportParametersDto =
  {
    startYearParameter: 0,
    endYearParameter: 0,
    groups: [[]],
    names: [],
    healthSystems: ["All-MHA"],
    healthGroups: [""],
    employeeId: "",
    excludeFiledPriorToPOA: false,
    excludeSecondYearClaim: false,
    hideInvestigations: false,
    groupIds: [],
    healthSytemsIds: [],
  };

export const executiveSummaryWithIndividualBreakdownsReportValidationSchema =
  Yup.object({
    startDatetimeParameter: Yup.date()
      .typeError(dateError)
      .required(requiredError),
    endDatetimeParameter: Yup.date()
      .typeError(dateError)
      .required(requiredError),
    healthSystems: Yup.array()
      .of(Yup.string())
      .test("has_required_values", requiredError, (v) => {
        for (let i = 0; i < 1; i++) {
          if (v[i] === undefined || (v[i] && v[i].length === 0)) return false;
        }
        return true;
      }),
    healthGroups: Yup.array().of(Yup.string()),
    groups: Yup.array().of(Yup.array().of(Yup.string())),
    employeeId: Yup.string()
      .max(200, "Must be 200 characters or less")
      .nullable(),
    excludeFiledPriorToPOA: Yup.boolean(),
    excludeSecondYearClaim: Yup.boolean(),
    hideInvestigations: Yup.boolean(),
  });
//#endregion

//#region "New Unemployment Claims Report" Report
export interface NewUnemploymentClaimsReportParametersDto
  extends BaseReportParametersDto {
  startDatetimeParameter?: Date;
  endDatetimeParameter?: Date;
  employeeId: string;
  excludePrior: boolean;
  excludeSecond: boolean;
  display?: string;
}

export const newUnemploymentClaimsReportParametersInit: NewUnemploymentClaimsReportParametersDto =
  {
    startYearParameter: 0,
    endYearParameter: 0,
    groups: [[]],
    names: [],
    healthSystems: ["All-MHA"],
    healthGroups: [""],
    employeeId: "",
    excludePrior: false,
    excludeSecond: false,
    display: "ssn",
  };

export const newUnemploymentClaimsReportValidationSchema = Yup.object({
  startDatetimeParameter: Yup.date()
    .typeError(dateError)
    .required(requiredError),
  endDatetimeParameter: Yup.date().typeError(dateError).required(requiredError),
  healthSystems: Yup.array()
    .of(Yup.string())
    .test("has_required_values", requiredError, (v) => {
      for (let i = 0; i < 1; i++) {
        if (v[i] === undefined || (v[i] && v[i].length === 0)) return false;
      }
      return true;
    }),
  healthGroups: Yup.array().of(Yup.string()),
  groups: Yup.array().of(Yup.array().of(Yup.string())),
  employeeId: Yup.string()
    .max(200, "Must be 200 characters or less")
    .nullable(),
  excludeSecond: Yup.boolean(),
});
//#endregion

//#region "Productivity Report" Report
export interface ProductivityReportParametersDto
  extends BaseReportParametersDto {
  startDatetimeParameter?: Date;
  endDatetimeParameter?: Date;
  users: string;
  detail: boolean;
  summary: boolean;
}

export const productivityReportParametersInit: ProductivityReportParametersDto =
  {
    startYearParameter: 0,
    endYearParameter: 0,
    groups: [[]],
    names: [],
    users: "",
    healthSystems: ["All-MHA"],
    healthGroups: [""],
    detail: false,
    summary: false,
  };

export const productivityReportValidationSchema = Yup.object({
  startDatetimeParameter: Yup.date()
    .typeError(dateError)
    .required(requiredError),
  endDatetimeParameter: Yup.date().typeError(dateError).required(requiredError),
  healthSystems: Yup.array()
    .of(Yup.string())
    .test("has_required_values", requiredError, (v) => {
      for (let i = 0; i < 1; i++) {
        if (v[i] === undefined || (v[i] && v[i].length === 0)) return false;
      }
      return true;
    }),
  healthGroups: Yup.array().of(Yup.string()),
  groups: Yup.array().of(Yup.array().of(Yup.string())),
  users: Yup.string(),
  //users: Yup.array().of(Yup.array().of(Yup.string())),
  detail: Yup.boolean(),
  summary: Yup.boolean(),
});
//#endregion

//#region "Unemployment Claims Summary Report" Report
export interface UnemploymentClaimsSummaryReportParametersDto
  extends BaseReportParametersDto {
  startDatetimeParameter?: Date;
  endDatetimeParameter?: Date;
  employeeId: string;
  excludeFiledPriorToPOA: boolean;
  excludeSecondYearClaim: boolean;
  display?: string;
}

export const unemploymentClaimsSummaryReportParametersInit: UnemploymentClaimsSummaryReportParametersDto =
  {
    startYearParameter: 0,
    endYearParameter: 0,
    groups: [[]],
    names: [],
    healthSystems: ["All-MHA"],
    healthGroups: [""],
    employeeId: "",
    excludeFiledPriorToPOA: false,
    excludeSecondYearClaim: false,
  };

export const unemploymentClaimsSummaryReportValidationSchema = Yup.object({
  startDatetimeParameter: Yup.date()
    .typeError(dateError)
    .required(requiredError),
  endDatetimeParameter: Yup.date().typeError(dateError).required(requiredError),
  healthSystems: Yup.array()
    .of(Yup.string())
    .test("has_required_values", requiredError, (v) => {
      for (let i = 0; i < 1; i++) {
        if (v[i] === undefined || (v[i] && v[i].length === 0)) return false;
      }
      return true;
    }),
  healthGroups: Yup.array().of(Yup.string()),
  groups: Yup.array().of(Yup.array().of(Yup.string())),
  employeeId: Yup.string()
    .max(200, "Must be 200 characters or less")
    .nullable(),
  excludeFiledPriorToPOA: Yup.boolean(),
  excludeSecondYearClaim: Yup.boolean(),
});
//#endregion

//#region "Unemployment Claims Charge Report" Report
export interface UnemploymentClaimsChargeReportParametersDto
  extends UnemploymentClaimsSummaryReportParametersDto {
  period: number;
  month?: number;
  quarter?: number;
  year: number;
  display?: string;
}

export const unemploymentClaimsChargeReportParametersInit: UnemploymentClaimsChargeReportParametersDto =
  {
    period: 1,
    month: 0,
    quarter: 0,
    year: 0,
    startYearParameter: 0,
    endYearParameter: 0,
    groups: [[]],
    names: [],
    healthSystems: ["All-MHA"],
    healthGroups: [""],
    employeeId: "",
    excludeFiledPriorToPOA: false,
    excludeSecondYearClaim: false,
  };

export const unemploymentClaimsChargeReportValidationSchema =
  unemploymentClaimsSummaryReportValidationSchema.shape({
    //startDatetimeParameter: Yup.date().typeError(dateError),
    //endDatetimeParameter: Yup.date().typeError(dateError),
    period: Yup.number()
      .min(1, "Must be a valid period")
      .max(new Date().getFullYear(), "Must be a valid period")
      .required("Period is required"),
    month: Yup.number().when("period", {
      is: (period: number) => {
        return period == 1;
      },
      then: Yup.number()
        .required("Month is required")
        .min(1, "Month is required")
        .max(12, "Must be a valid month"),
      otherwise: Yup.number().nullable(),
    }),
    quarter: Yup.number().when("period", {
      is: (period: number) => {
        return period == 2;
      },
      then: Yup.number()
        .required("Quarter is required")
        .min(1, "Quarter is required")
        .max(12, "Must be a valid quarter"),
      otherwise: Yup.number().nullable(),
    }),
    year: Yup.number()
      .min(2000, "Year is required")
      .max(new Date().getFullYear(), "Must not be in the future")
      .required("Year is required"),
    employeeId: Yup.string()
      .max(200, "Must be 200 characters or less")
      .nullable(),
  });

//#endregion
