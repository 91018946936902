import { Grid, GridSize } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FormikErrors, FormikHandlers, FormikHelpers, getIn } from 'formik';
import React from 'react';
import { IMc } from '../../../../common/dtos/McDto';
import { PaddedTextField } from '../../../../common/styles/styles';

interface Props {
  label: string;
  name: string;
  value: number | string | undefined;
  optionName?: string;
  optionValueName?: string;
  errors: FormikErrors<IMc>;
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<IMc>["setFieldValue"];
  isReadOnly: boolean;
  size?: GridSize;
  options: any[];
  callBack?: (v: any |null) => void;
}

const SiSelectOptions: React.FC<Props> = (
  {
    label,
    name,
    value,
    optionName = 'text',
    optionValueName = 'value',
    errors,
    handleBlur,
    setFieldValue,
    isReadOnly,
    size = 12,
    options,
    callBack
  }) => {

  const handleOnChange = (_e: any, v: any | null) => {
    setFieldValue(name, v === null || v === undefined ? undefined : v[optionValueName] ?? undefined);

    if (callBack) callBack(v);
  }

  return (
    <Grid item xs={12} sm={12} md={size} lg={size}>
      <Autocomplete
        id="stateCode"
        options={options}
        getOptionLabel={option => option[optionName] ?? ''}
        value={options.find(o => o[optionValueName] === (value ?? -1)) || null}
        includeInputInList
        disabled={isReadOnly}
        autoComplete
        onChange={handleOnChange}
        renderInput={(params) => (
          <PaddedTextField
            {...params}
            name={name}
            size="small"
            margin="none"
            disabled={isReadOnly}
            value={value || ''}
            label={label}
            onBlur={handleBlur}
            error={Boolean(getIn(errors, name))}
            helperText={getIn(errors, name) && getIn(errors, name)}
            data-cy={`sidesResponse_${name}`}
            fullWidth
          />
        )} />
    </Grid>
  );

}

export default SiSelectOptions;