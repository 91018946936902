import { combineReducers } from 'redux';
import { createReducer, getType } from 'typesafe-actions';

import { getContactInformationActions, contactInformationShouldRefresh } from '../actions/contactInformation.actions';
import { ContactInformationDto } from '../dtos/contactInformation';



export const contactInformationReducer = combineReducers({
    contactInformationList: createReducer([] as ContactInformationDto[])
        .handleAction(
            [
                getContactInformationActions.success,
            ], (state, action) => action.payload),

    refreshContactInformation: createReducer(true)
        .handleType(getType(contactInformationShouldRefresh), (state, action) => action.payload),

});

export default contactInformationReducer;