import React from 'react';
import { FormikHandlers, FormikErrors } from 'formik';

// material ui
import {
  FormControlLabel,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core';

import { ClientQuestionnaireDto } from '../dto';
import { PaddedTextField } from '../../../common/styles/styles';
import { initialSeparationReasonOptions } from '../constants/options';

interface InitialSeparationQuestionProps
  extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  setShouldBlockNavigation: (o: boolean) => void;
  handleResetFromSeparation: (q: ClientQuestionnaireDto, id: number) => void;
}

const InitialSeparationQuestion: React.FC<InitialSeparationQuestionProps> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  setShouldBlockNavigation,
  handleResetFromSeparation,
}) => {
  return (
    <React.Fragment>
      <br />
      <Grid item xs={10}>
        <Grid item xs={12}>
          What is the reason for the claimant's employment separation (if still
          employed, or there has been no separation, please select Other).
        </Grid>
        <Grid item md={12} xs={12} sm={12} lg={5}>
          <PaddedTextField
            id='initialSeparationQuestionId'
            size='small'
            margin='none'
            select
            value={values.initialSeparationQuestionId || ''}
            name='initialSeparationQuestionId'
            onChange={(e) => {
              handleChange(e);
              setShouldBlockNavigation(true);
              handleResetFromSeparation(
                values,
                +(e.target as HTMLInputElement).value
              );
            }}
            onBlur={handleBlur}
            error={Boolean(errors.initialSeparationQuestionId)}
            helperText={
              errors.initialSeparationQuestionId &&
              errors.initialSeparationQuestionId
            }
            data-cy='clientQuestionnaire_jobStatus'
            aria-label='Reason for separation'
            fullWidth
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
          >
            {initialSeparationReasonOptions
              .filter((item) => item.name !== '')
              .map((option, index) => (
                <MenuItem key={option.name + index} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
          </PaddedTextField>
        </Grid>
        {values.initialSeparationQuestionId == 1 ||
        values.initialSeparationQuestionId == 2 ? (
          <Grid item xs={12}>
            <Typography color='error' variant='body2'>
              {values.initialSeparationQuestionId == 1
                ? 'Please attach a copy of the resignation letter (if one provided in writing), ' +
                  'or email the documentation to your claims representative. In addition, please ' +
                  'attach or send  any supporting documentation that supports the claimant’s resignation ' +
                  'from employment (manager notes, witness statements, etc.).'
                : values.initialSeparationQuestionId == 2
                ? 'Please attach a copy of the supporting documentation, or email the documentation ' +
                  'to your claims representative. To prevent payment of benefits, the state will request ' +
                  'copies of the discharge paperwork, any prior warnings (even verbal) issued to the claimant, ' +
                  'and supporting documentation describing why the claimant was discharged.'
                : undefined}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <br />
    </React.Fragment>
  );
};

export default InitialSeparationQuestion;
