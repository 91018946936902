import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  CardActions,
  Grid,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { getType } from 'typesafe-actions';
import { useLocation } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { resetPasswordInit } from './resetPassword.dtos';
import { resetPasswordActions } from './resetPassword.actions';
import {
  passwordError,
  passwordMaxError,
  passwordRegex,
  requiredError,
} from '../../../common/constants/strings';
import {
  StyledDiv,
  StyledForm,
  StyledCard,
  StyledCardContent,
  StyledTextField,
  ButtonGrid,
  StyledSubmitButton,
  StyledCancelButton,
  StyledCancelLink,
} from '../../../common/styles/styles';
import { ResetPasswordContainer, H3 } from './resetPassword.styles';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword: React.FC = () => {
  const dispatch = useDispatch();
  let query = useQuery();
  const code = query.get('code');

  const [initialDto, setInitialDto] = React.useState(resetPasswordInit);
  const [isPassword, setIsPassword] = React.useState(true);
  const [isConfirmPassword, setIsConfirmPassword] = React.useState(true);

  React.useEffect(() => {
    let dto = resetPasswordInit;
    dto.token = code ?? '';
    setInitialDto(dto);
  }, [code]);

  const handlePasswordViewToggle = () => {
    setIsPassword(!isPassword);
  };

  const handleConfirmPasswordViewToggle = () => {
    setIsConfirmPassword(!isConfirmPassword);
  };

  return (
    <ResetPasswordContainer component='main'>
      <StyledDiv>
        <StyledCard>
          {/*<ResetPasswordLogoContainer>
          <ResetPasswordLogo>
            <img src={process.env.PUBLIC_URL + '/logo.png'} alt="MHA logo" width="475" />
          </ResetPasswordLogo>
        </ResetPasswordLogoContainer>*/}
          <Formik
            initialValues={initialDto}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Yup.object({
              userId: Yup.string()
                .email('Invalid email address')
                .required(requiredError),
              password: Yup.string()
                .min(12, passwordError)
                .max(50, passwordMaxError)
                .matches(passwordRegex, passwordError)
                .required(requiredError),
              confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Must match new password.')
                .required(requiredError),
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setTimeout(() => {
                  setSubmitting(false);
                }, 700);

                values.token = initialDto.token;

                dispatch({
                  type: getType(resetPasswordActions.request),
                  payload: values,
                });
              }, 400);
            }}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <StyledCardContent>
                    <H3>Reset Password</H3>
                    <small>
                      Password must be 12 characters and have at least one
                      uppercase letter, one lowercase letter, one number, one
                      special character.
                    </small>
                    <Grid container>
                      <Grid item xs={12}>
                        <StyledTextField
                          id='userId'
                          margin='normal'
                          InputLabelProps={{ required: true }}
                          fullWidth
                          value={values.userId}
                          name='userId'
                          label='Username'
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(errors.userId) && touched.userId}
                          helperText={
                            errors.userId && touched.userId && errors.userId
                          }
                          data-cy='resetPassword_username'
                        />
                      </Grid>
                      {/*new password */}
                      <Grid item xs={12}>
                        <StyledTextField
                          id='password'
                          margin='normal'
                          InputLabelProps={{ required: true }}
                          fullWidth
                          value={values.password}
                          name='password'
                          label='New Password'
                          type={isPassword ? 'password' : 'text'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(errors.password) && touched.password}
                          helperText={
                            errors.password &&
                            touched.password &&
                            errors.password
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position='end'
                                role='viewPasswordToggle'
                                onClick={handlePasswordViewToggle}
                              >
                                {isPassword ? (
                                  <IconButton
                                    tabIndex={-1}
                                    title='View New Password'
                                  >
                                    <VisibilityIcon color='primary' />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    tabIndex={-1}
                                    title='Hide New Password'
                                  >
                                    <VisibilityOffIcon color='primary' />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                          data-cy='resetPassword_password'
                        />
                      </Grid>

                      {/* new password */}
                      <Grid item xs={12}>
                        <StyledTextField
                          id='confirmPassword'
                          margin='normal'
                          InputLabelProps={{ required: true }}
                          fullWidth
                          value={values.confirmPassword}
                          name='confirmPassword'
                          label='Confirm Password'
                          type={isConfirmPassword ? 'password' : 'text'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            Boolean(errors.confirmPassword) &&
                            touched.confirmPassword
                          }
                          helperText={
                            errors.confirmPassword &&
                            touched.confirmPassword &&
                            errors.confirmPassword
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position='end'
                                role='viewPasswordToggle'
                                onClick={handleConfirmPasswordViewToggle}
                              >
                                {isConfirmPassword ? (
                                  <IconButton
                                    tabIndex={-1}
                                    title='View Confirm Password'
                                  >
                                    <VisibilityIcon color='primary' />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    tabIndex={-1}
                                    title='Hide Confirm Password'
                                  >
                                    <VisibilityOffIcon color='primary' />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                          data-cy='resetPassword_confirmPassword'
                        />
                      </Grid>
                    </Grid>
                  </StyledCardContent>
                  <CardActions>
                    <ButtonGrid container>
                      <StyledSubmitButton
                        type='submit'
                        color='primary'
                        disabled={isSubmitting}
                        data-cy='resetPassword_submit'
                      >
                        RESET PASSWORD
                      </StyledSubmitButton>

                      <StyledCancelButton>
                        <StyledCancelLink
                          to={`/${
                            localStorage.getItem('TenantId') ?? 'mha'
                          }/login`}
                          data-cy='resetPassword_cancel'
                        >
                          CANCEL
                        </StyledCancelLink>
                      </StyledCancelButton>
                    </ButtonGrid>
                  </CardActions>
                </StyledForm>
              );
            }}
          </Formik>
        </StyledCard>
      </StyledDiv>
    </ResetPasswordContainer>
  );
};

export default ResetPassword;
