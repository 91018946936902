import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors } from 'formik';
import { Grid, MenuItem } from '@material-ui/core';

import { ClientQuestionnaireDto } from '../dto';
import DisasterComponent from './other/disasterComponent';
import DisciplinarySuspensionComponent from './other/disciplinarySuspensionComponent';
import FullTimeComponent from './other/fullTimeComponent';
import PartTimeComponent from './other/partTimeComponent';
import HoursReducedComponent from './other/hoursReducedComponent';
import LaborDisputeComponent from './other/laborDisputeComponent';
import LeaveOfAbsenceComponent from './other/leaveOfAbsenceComponent';
import SchoolEmployeeComponent from './other/schoolEmployeeComponent';
import SeasonalEmploymentComponent from './other/seasonalEmploymentComponent';
import { otherSeparationReasonOptions } from '../constants/options';
import { PaddedTextField } from '../../../common/styles/styles';


interface OtherOptionComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
  attachments: File[];
  onAttachmentDelete: (file: File) => void;
  onAttachmentUpload: (files: File[]) => void;
}

const OtherOptionComponent: React.FC<OtherOptionComponentProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation,
    attachments,
    onAttachmentDelete,
    onAttachmentUpload }) => {

  const renderSwitch = (id: number,
    values: ClientQuestionnaireDto,
    errors: FormikErrors<ClientQuestionnaireDto>,
    handleChange: FormikHandlers["handleChange"],
    handleBlur: FormikHandlers["handleBlur"],
    setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"]) => {
    switch (id) {
      case 1:
        return <SchoolEmployeeComponent setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />

      case 2:
        return <FullTimeComponent setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />

      case 3:
      case 4:
        return <PartTimeComponent setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />
     
      case 5:
        return <HoursReducedComponent attachments={attachments} onAttachmentUpload={onAttachmentUpload} onAttachmentDelete={onAttachmentDelete}
          setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values} errors={errors}
          handleChange={handleChange} handleBlur={handleBlur} />

      case 6:
        return <SeasonalEmploymentComponent setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          handleBlur={handleBlur} />

      case 7:
        return <LeaveOfAbsenceComponent setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />

      case 8:
        return <DisciplinarySuspensionComponent setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />

      case 9:
        return <LaborDisputeComponent setShouldBlockNavigation={setShouldBlockNavigation} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />

      case 10:
        return <DisasterComponent setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />

      default:
        return null;
    }
  }

  return (
    <React.Fragment>
      <Grid item xs={10}>
        <Grid item xs={12}>You indicated "Other". Please choose the option that best fits the situation.</Grid>
        <Grid item md={12} xs={12} sm={12} lg={5}>
          <PaddedTextField
            id="otherSeparationReasonId"
            size="small"
            margin="none"
            select
            value={values.otherSeparationReasonId || ''}
            name="otherSeparationReasonId"
            onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
            onBlur={handleBlur}
            error={Boolean(errors.otherSeparationReasonId)}
            helperText={errors.otherSeparationReasonId && errors.otherSeparationReasonId}
            data-cy="clientQuestionnaire_otherSeparationReasonId"
            aria-label="Other separation reason"
            fullWidth
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
          >
            {otherSeparationReasonOptions.filter((item) => item.name !== '')
              .map((option, index) => (
                <MenuItem key={option.name + index} value={option.id}>{option.name}</MenuItem>
              ))}
          </PaddedTextField>
        </Grid>
      </Grid>
      {values.otherSeparationReasonId && renderSwitch(values.otherSeparationReasonId, values, errors, handleChange, handleBlur, setFieldValue)
      }
    </React.Fragment>
  )
}

export default OtherOptionComponent;
