import React from 'react';
import { Prompt } from 'react-router';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Dialog, DialogContent, DialogTitle, Grid, TextField, DialogActions } from '@material-ui/core';
import { getType } from 'typesafe-actions';
import { deleteDocumentsActions } from '../actions';
import { DeleteConfirmationLabel, DeleteLabel } from '../documents.styles';
import { ButtonGrid, StyledCancelButton, StyledCardContent, StyledSubmitButton } from '../../../common/styles/styles';
import { requiredError } from '../../../common/constants/strings';


interface Props {
  id: string,
  open: boolean,
  onCancel: () => void,
}

const DeleteDialog: React.FC<Props> = (
  {
    id,
    open,
    onCancel
  }) => {
  const dispatch = useDispatch();

  const cancelDialog = () => onCancel();


  return <Formik
    initialValues={{ deleteConfirmation: '' }}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={
      Yup.object({
        deleteConfirmation: Yup.string().max(30, "Must be 30 characters or less").required(requiredError),
      })
    }
    onSubmit={(values, { setSubmitting, resetForm }) => {
      setTimeout(() => {
        setSubmitting(false);
        resetForm();
      }, 700);

      if (values.deleteConfirmation.length !== 0 && values.deleteConfirmation.toLowerCase() === 'delete') {
        dispatch({ type: getType(deleteDocumentsActions.request), payload: id });
        cancelDialog();
      }
      onCancel();
    }}
    enableReinitialize={true}
  >
    {(props) => {
      const {
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
      } = props;
      return (
        <Dialog
          open={open}
          data-cy="delete_dialog"
        >
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <DialogTitle style={{ marginLeft: 4 }}>Delete</DialogTitle>
              <DeleteConfirmationLabel style={{ marginLeft: 30 }}>Are you sure you want to delete?</DeleteConfirmationLabel>
              <DialogContent style={{ width: 500 }}>
                <form onSubmit={handleSubmit}>
                  <StyledCardContent>

                    <Grid item xs={12}>
                      <DeleteLabel>Type DELETE to confirm</DeleteLabel>
                      <TextField
                        id="deleteConfirmation"
                        name="deleteConfirmation"
                        onChange={handleChange}
                        value={values.deleteConfirmation}
                        fullWidth
                        data-cy="delete_dialog_confirmation"
                        onBlur={handleBlur}
                        error={Boolean(errors.deleteConfirmation)}
                        helperText={errors.deleteConfirmation}>
                        InputLabelProps={{ required: true }}
                      </TextField>

                    </Grid>

                  </StyledCardContent>

                  <DialogActions>
                    <ButtonGrid container>
                      <StyledSubmitButton
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                        data-cy="delete_dialog_submitButton"
                      >
                        Save
                  </StyledSubmitButton>

                      <StyledCancelButton
                        data-cy="delete_dialog_cancelButton"
                        color="secondary"
                        onClick={cancelDialog}
                      >
                        Cancel
                  </StyledCancelButton>
                    </ButtonGrid>
                  </DialogActions>
                </form>
              </DialogContent>
              <Prompt
                // when={shouldBlockNavigation}
                message='You have unsaved edits, are you sure you want to leave?'
              />
            </Grid>
          </Grid>
        </Dialog>
      );
    }}
  </Formik>
}

export default DeleteDialog;
