
export enum FeatureTypeEnum {
  ActionList = 1,
  Payments = 2,
  Employers = 3,
  Contacts = 4,
  Claims = 5,
  Reports = 6,
  Settings = 7,
}

export class FeatureTypeEnum_class {

  public static enum = FeatureTypeEnum;
}