import React from 'react';
import { useDispatch } from 'react-redux';
import { rootSelector } from '../../../common/selectors/selectors';
import QRCode from 'react-qr-code';
import {
  Dialog,
  DialogActions,
  Grid,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography,
  Link,
} from '@material-ui/core';
import { getType } from 'typesafe-actions';
import { tokenInit, LoginDto, TokenDto } from '../login/login.dtos';
import { loginActions } from '../login/login.actions';
import {
  ButtonGrid,
  StyledCancelButton,
  StyledForm,
  StyledTextField,
  StyledSubmitButton,
} from '../../../common/styles/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { requiredError } from '../../../common/constants/strings';
import NumberField from '../../../common/components/numberField';
import MfaHelpDialog from './MfaHelpDialog';
import MfaRegistrationCompleteDialog from './MfaRegistrationCompleteDialog';

interface Props {
  isPasswordExpired: boolean;
  waitForMFA: (shouldWait: boolean) => void;
}

const MfaDialog: React.FC<Props> = ({ isPasswordExpired, waitForMFA }) => {
  const dispatch = useDispatch();

  const systemLogin = rootSelector((state) => state.login.loginSuccess);
  const { isAuthenticated } = rootSelector((state) => state.auth);

  const [dto, setDto] = React.useState<TokenDto>(tokenInit);
  const [isOpen, setIsOpen] = React.useState(false);
  const [qrUrl, setQrUrl] = React.useState('');
  const [manualCode, setManualCode] = React.useState('');
  const [helpDialogOpen, setHelpDialogOpen] = React.useState(false);
  const [completeDialogOpen, setCompleteDialogOpen] = React.useState(false);

  const cancelDialog = () => {
    waitForMFA(false);
    dispatch({
      type: getType(loginActions.success),
      payload: JSON.parse(JSON.stringify(tokenInit)),
    });
  };

  React.useEffect(() => {
    return () => {
      waitForMFA(false);
    };
  }, [dto]);

  React.useEffect(() => {
    if ((dto.qrUrl ?? '').length !== 0) waitForMFA(true);
  }, [dto]);

  React.useEffect(() => {
    setQrUrl(systemLogin.qrUrl ?? '');
    setManualCode(systemLogin.sig ?? '');

    if (isAuthenticated || isPasswordExpired) {
      handleOpenCompleteDialog();
    }
    setTimeout(() => {
      setIsOpen(
        (systemLogin.mfaRequired ?? false) && !(systemLogin.useDuoMfa ?? true)
      );
      setDto(JSON.parse(JSON.stringify(systemLogin)));
    }, 300);

    return () => {
      setDto(tokenInit);
      setQrUrl('');
      setManualCode('');
    };
  }, [systemLogin, isAuthenticated]);

  const handleMfa = (res: string) => {
    const loginCreds: LoginDto = {
      username: dto.userName,
      password: dto.userPassword,
      duoRes: res,
    };
    dispatch({ type: getType(loginActions.request), payload: loginCreds });
  };

  const handleOpenHelpDialog = () => setHelpDialogOpen(true);
  const handleCancelHelpDialog = () => setHelpDialogOpen(false);
  const handleOpenCompleteDialog = () => setCompleteDialogOpen(true);
  const handleCancelCompleteDialog = () => {
    setCompleteDialogOpen(false);
    cancelDialog();
  };

  return (
    <Dialog open={isOpen} maxWidth='md' data-cy='mfa_dialog'>
      {helpDialogOpen && (
        <MfaHelpDialog
          open={helpDialogOpen}
          onCancel={handleCancelHelpDialog}
        />
      )}
      {completeDialogOpen && (
        <MfaRegistrationCompleteDialog
          open={completeDialogOpen}
          onCancel={handleCancelCompleteDialog}
        />
      )}
      <Formik
        initialValues={{ code: '' } as { code: string }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
          code: Yup.string()
            .min(6, ' The code must be 6 digits.')
            .required(requiredError),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setTimeout(() => {
            setSubmitting(false);
            resetForm();
          }, 700);
          handleMfa(values.code);
        }}
        enableReinitialize={true}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <StyledForm onSubmit={handleSubmit}>
              <Grid container alignItems='center'>
                <Grid item xs={12}>
                  <DialogTitle id='form-dialog-title'>
                    Verify your identity
                  </DialogTitle>
                  <DialogContent>
                    <Grid
                      container
                      alignItems='center'
                      justifyContent='center'
                      spacing={2}
                    >
                      {qrUrl.length === 0 ? (
                        <>
                          <Grid item xs={12}>
                            <DialogContentText>
                              Use your registered authenticator app to retrive
                              the validation code below.
                            </DialogContentText>

                            <small>
                              <Typography>
                                <Link href='#' onClick={handleOpenHelpDialog}>
                                  What is the authenticator app?
                                </Link>
                              </Typography>
                            </small>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12}>
                            <DialogContentText>
                              Please register with an Authenticator App. Scan
                              the QR code with your preferred authenticator app
                              or enter <strong>{manualCode}</strong> manually in
                              the authenticator app.
                            </DialogContentText>

                            <small>
                              <Typography>
                                <Link href='#' onClick={handleOpenHelpDialog}>
                                  Need help finding an authenticator app?
                                </Link>
                              </Typography>
                            </small>
                          </Grid>

                          <Grid item xs={3}>
                            <QRCode value={qrUrl} size={128} />
                          </Grid>
                        </>
                      )}

                      <Grid item xs={3}>
                        <StyledTextField
                          InputLabelProps={{ required: true }}
                          InputProps={{ inputComponent: NumberField as any }}
                          inputProps={{
                            showSeparator: false,
                            customFormat: '### ###',
                          }}
                          fullWidth
                          value={values.code}
                          name='code'
                          label='Validation Code'
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(errors.code) && touched.code}
                          helperText={
                            errors.code && touched.code && errors.code
                          }
                          data-cy='client-mfa-code'
                          autoFocus
                        />
                      </Grid>
                    </Grid>
                    <DialogActions>
                      <ButtonGrid container>
                        <StyledSubmitButton
                          type='submit'
                          color='primary'
                          disabled={isSubmitting}
                          data-cy='resetPassword_submit'
                        >
                          Confirm
                        </StyledSubmitButton>

                        <StyledCancelButton
                          onClick={cancelDialog}
                          data-cy='mfa_cancelButton'
                        >
                          CANCEL
                        </StyledCancelButton>
                      </ButtonGrid>
                    </DialogActions>
                  </DialogContent>
                </Grid>
              </Grid>
            </StyledForm>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default MfaDialog;
