import React from "react";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { getType } from "typesafe-actions";
import moment from "moment";
// material ui
import {
  Grid,
  Box,
  Typography,
  InputAdornment,
  Card,
  CardContent,
  Button,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import { rootSelector } from "../../common/selectors/selectors";
import AddBatchPaymentDialog from "./dialogs/addBatchPaymentDialog";
import { isReadOnly } from "../../common/dtos/auth";
import { ListBatchPaymentDto } from "./dtos";
import { getBatchPaymentsListActions, uploadMiWamImport } from "./actions";
import {
  BlackText,
  CardContent1,
  CardHeaderDivider,
  Checkbox1,
  DashedBox,
  LabelLink,
  ListCardContent,
  RedLabelLink,
  TableCard,
} from "../../common/styles/styles";
import {
  ImportExportIconBlue,
  ImportExportIconRed,
  PaddedPlaceholderGrid,
  PaddedSearchfield,
} from "../documents/documents.styles";
import { FeatureTypeEnum } from "../../common/enums/featureTypeEnum";
import Dropzone from "react-dropzone";

const BatchPaymentsList: React.FC = () => {
  const dispatch = useDispatch();

  const systemBatchList = rootSelector((state) => state.batchPayments.list);

  const [batchPaymentsList, setBatchPaymentsList] = React.useState<
    ListBatchPaymentDto[]
  >([]);
  const [batchPaymentSearchOptions, setBatchPaymentSearchOptions] =
    React.useState<string[]>([]);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [batchId, setBatchId] = React.useState<string>("");
  const [sortDueDateDesc, setSortDueDateDesc] = React.useState<boolean>(true);
  const [addBatchPaymentDialogOpen, setAddBatchPaymentDialogOpen] =
    React.useState<boolean>(false);
  const [fileNames, setFileNames] = React.useState<string[]>([]);

  const handleDrop = (acceptedFiles: File[]) => {
    setFileNames(acceptedFiles.map((file) => file.name));
    dispatch({ type: getType(uploadMiWamImport), payload: acceptedFiles[0] });
  };

  React.useEffect(() => {
    dispatch({ type: getType(getBatchPaymentsListActions.request) });
  }, [dispatch]);

  React.useEffect(() => {
    setBatchPaymentsList(systemBatchList);

    if (systemBatchList.length === 0 && addBatchPaymentDialogOpen) {
      dispatch({ type: getType(getBatchPaymentsListActions.request) });
    }
  }, [systemBatchList]);

  React.useEffect(() => {
    let searchOptions: string[] = [];
    if (batchPaymentsList.length !== 0 && batchPaymentsList !== undefined) {
      batchPaymentsList.forEach((item: ListBatchPaymentDto) => {
        if (item.label !== undefined) {
          searchOptions.push(item.label);
        }
      });
    }
    setBatchPaymentSearchOptions(
      Array.from(new Set(searchOptions))
        .sort((a, b) => a.localeCompare(b))
        .filter((s) => s !== undefined && s !== "" && s !== " ")
    );
  }, [batchPaymentsList]);

  const handleAddNewBatchPayment = (id: string) => {
    setBatchId(id);
    setAddBatchPaymentDialogOpen(true);
  };
  const handleCancelDialog = () => {
    setAddBatchPaymentDialogOpen(false);
  };

  return (
    <>
      {addBatchPaymentDialogOpen && (
        <AddBatchPaymentDialog
          open={addBatchPaymentDialogOpen}
          isReadOnly={isReadOnly(FeatureTypeEnum.Payments)}
          onCancel={handleCancelDialog}
          batchId={batchId}
        />
      )}

      <Box p={2}>
        <Grid
          container
          data-cy="batchPayments_list_Container"
          item
          xs={8}
          spacing={2}
        >
          <PaddedPlaceholderGrid item xs={12}>
            <Typography variant="subtitle1" display="inline">
              Batch Payments
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              display="inline"
            >
              {" "}
              (
              {
                batchPaymentsList.filter((item: ListBatchPaymentDto) =>
                  item.label.toLowerCase().includes(searchQuery.toLowerCase())
                ).length
              }
              )
            </Typography>
          </PaddedPlaceholderGrid>

          <Grid item xs={12}>
            <Card>
              <CardHeaderDivider
                title={
                  <>
                    <BlackText>
                      <Typography variant="subtitle1">
                        Upload Documents
                      </Typography>
                    </BlackText>
                  </>
                }
              ></CardHeaderDivider>
              <CardContent1>
                <br />

                <Grid container justifyContent="center">
                  <Dropzone aria-label="File drop zone" onDrop={handleDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <DashedBox
                        container
                        item
                        xs={9}
                        justifyContent="center"
                        {...getRootProps({ className: "dropzone" })}
                      >
                        <input
                          aria-label="File input area"
                          {...getInputProps()}
                        />
                        <Typography variant="h5" display="block">
                          Drag your file here or click to select
                        </Typography>
                      </DashedBox>
                    )}
                  </Dropzone>
                </Grid>
                <Grid container justifyContent="center">
                  <div>
                    <ul>
                      {fileNames.map((fileName) => (
                        <li key={fileName}>{fileName}</li>
                      ))}
                    </ul>
                  </div>
                </Grid>
                <br />
                <br />
              </CardContent1>
            </Card>
          </Grid>

          <br></br>
          <br></br>

          <Grid item xs={12} sm={4}>
            <Formik
              initialValues={{ search: "" }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={Yup.object({
                search: Yup.string().max(200, "Must be 200 characters or less"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(false);
                }, 700);
                setSearchQuery(values.search);
              }}
              enableReinitialize={true}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                } = props;
                return (
                  <form>
                    <Autocomplete
                      id="autoSearch"
                      options={batchPaymentSearchOptions}
                      autoComplete
                      includeInputInList
                      freeSolo
                      inputValue={values.search || ""}
                      onInputChange={(_e: any, v: string | null) => {
                        setFieldValue("search", v ?? "");
                        handleSubmit();
                      }}
                      renderInput={(params) => (
                        <PaddedSearchfield
                          {...params}
                          id="search"
                          size="small"
                          margin="none"
                          value={values.search}
                          label="Search"
                          name="search"
                          onChange={(e) => {
                            handleChange(e);
                            handleSubmit();
                          }}
                          error={Boolean(errors.search)}
                          helperText={
                            errors.search && touched.search && errors.search
                          }
                          data-cy="batchPayments_ListSearch"
                          onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                            }
                          }}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </form>
                );
              }}
            </Formik>
          </Grid>

          <Grid container justifyContent="flex-end" item sm={12} md={6} lg={6}>
            <Checkbox1
              onChange={() => setSortDueDateDesc(!sortDueDateDesc)}
              aria-label="Due Date Sort Toggle"
              //outlined={sortDueDateDesc ? "outlined" : undefined}
              name="sortDueDateDesc"
              value={sortDueDateDesc || false}
              checked={sortDueDateDesc || false}
              control={
                <Checkbox
                  checkedIcon={
                    sortDueDateDesc ? (
                      <ImportExportIconBlue />
                    ) : (
                      <ImportExportIconRed />
                    )
                  }
                  icon={
                    sortDueDateDesc ? (
                      <ImportExportIconBlue />
                    ) : (
                      <ImportExportIconRed />
                    )
                  }
                  size="small"
                  data-cy="batchPayments_dueDateCheckbox"
                />
              }
              label={<Typography variant="subtitle2">Due Date</Typography>}
            />
          </Grid>

          <br></br>
          <Grid container spacing={2}>
            {batchPaymentsList &&
              batchPaymentsList
                .filter((item: ListBatchPaymentDto) =>
                  item.label.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .sort((a, b) => {
                  return a.dueDateTime === undefined &&
                    b.dueDateTime === undefined
                    ? (a.sortOrder ?? 0) -
                        (b.sortOrder ?? 0) /*a.label.localeCompare(b.label)*/
                    : b.dueDateTime === undefined
                    ? -1
                    : a.dueDateTime === undefined
                    ? 1
                    : sortDueDateDesc
                    ? moment(a.dueDateTime).diff(moment(b.dueDateTime))
                    : moment(b.dueDateTime).diff(moment(a.dueDateTime));
                })
                .map((o, index) => (
                  <Grid item xs={12} key={"batchPayments_list_item_" + index}>
                    <TableCard raised>
                      <ListCardContent>
                        <Grid container>
                          <Grid item xs={8}>
                            {o.hasErrors ? (
                              <RedLabelLink
                                component={Button}
                                onClick={() =>
                                  handleAddNewBatchPayment(o.id ?? "")
                                }
                                color="secondary"
                              >
                                {o.label}
                              </RedLabelLink>
                            ) : (
                              <LabelLink
                                component={Button}
                                onClick={() =>
                                  handleAddNewBatchPayment(o.id ?? "")
                                }
                                color="secondary"
                              >
                                {o.label}
                              </LabelLink>
                            )}
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={2}>
                            <Typography variant="body2" color="textPrimary">
                              Statement Date
                            </Typography>
                            <Typography variant="body2" color="secondary">
                              {o.statementDate === undefined
                                ? "Not on file"
                                : moment(o.statementDate)
                                    .utc()
                                    .format("MM/DD/yyyy")}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6} lg={2}>
                            <Typography variant="body2" color="textPrimary">
                              Due Date
                            </Typography>
                            <Typography variant="body2" color="secondary">
                              {o.dueDateTime === undefined
                                ? "Not on file"
                                : moment(o.dueDateTime)
                                    .utc()
                                    .format("MM/DD/yyyy")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListCardContent>
                    </TableCard>
                  </Grid>
                ))}
          </Grid>
          {batchPaymentsList &&
            batchPaymentsList.filter((item: ListBatchPaymentDto) =>
              item.label.toLowerCase().includes(searchQuery.toLowerCase())
            ).length === 0 && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container justifyContent="center" alignItems="center">
                      <Typography variant="subtitle1" color="secondary">
                        No records found
                        {searchQuery.length === 0
                          ? "."
                          : " matching " + searchQuery}
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
                <br />
              </Grid>
            )}
        </Grid>
      </Box>
    </>
  );
};

export default BatchPaymentsList;
