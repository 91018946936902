import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors } from 'formik';

// material ui
import { Grid, TextField } from '@material-ui/core';
import { ClientQuestionnaireDto } from '../../dto';

interface OtherComponentComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setShouldBlockNavigation: (o: boolean) => void;
}

const OtherComponentComponent: React.FC<OtherComponentComponentProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setShouldBlockNavigation
  }) => {

  return (
    <React.Fragment>
      <Grid item xs={12}>What reason did the claimant give for resignation?</Grid>
      <Grid item xs={4}>
        <TextField
          id="reasonClaimantGaveForResignation"
          size="small"
          margin="none"
          value={values.reasonClaimantGaveForResignation || ''}
          //label="Job Title"
          name="reasonClaimantGaveForResignation"
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.reasonClaimantGaveForResignation)}
          helperText={errors.reasonClaimantGaveForResignation && errors.reasonClaimantGaveForResignation}
          data-cy="clientQuestionnaire_reasonClaimantGaveForResignation"
          aria-label="What was the claimant dissatisfied about?"
          fullWidth
        />
      </Grid>
    </React.Fragment>
  )
}

export default OtherComponentComponent;
