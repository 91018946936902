import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

//import { rootSelector } from '../../../common/selectors/selectors';
import { ProfileDto, CountryDto, GoverningDistrictDto } from './profile.dtos';
import { createProfileApi, getCountriesApi, getGoverningDistrictsApi, getProfileApi, updateProfileApi } from './profile.api';
import { createProfileActions, getCountriesActions, getGoverningDistrictsActions, getProfileActions, updateProfileActions } from './profile.actions';


function* handleCountriesGet() {
  //const countriesList = yield select(state => state.countries.countryList);
  //const countries: CountryDto[] = (countriesList !== undefined && countriesList.length > 0) ? countriesList : yield call(getCountriesApi);
  //yield put({ type: getType(getCountriesActions.success), payload: countries });
  const countriesList: CountryDto[] = yield select(state => state.countries.countryList);
  const countriesStorage = sessionStorage.getItem('countries');
  const sessionCountries = countriesStorage === null ? [] : JSON.parse(countriesStorage) as CountryDto[];
  const countries: CountryDto[] = countriesList !== undefined && countriesList.length !== 0 ? countriesList :
    sessionCountries.length !== 0 ? sessionCountries :
      yield call(getCountriesApi);

  yield put({ type: getType(getCountriesActions.success), payload: countries });
  sessionStorage.setItem('countries', JSON.stringify(countries));
}

function* handleGoverningDistrictsGet() {
  //const governingDistrictList = yield select(state => state.governingDistricts.governingDistrictList);
  //const governingDistricts: GoverningDistrictDto[] = (governingDistrictList !== undefined && governingDistrictList.length > 0) ? governingDistrictList : yield call(getGoverningDistrictsApi);
  //yield put({ type: getType(getGoverningDistrictsActions.success), payload: governingDistricts });
  const governingDistrictList: GoverningDistrictDto[] = yield select(state => state.governingDistricts.governingDistrictList);
  const governingDistrictStorage = sessionStorage.getItem('governingDistricts');
  const sessiongoverningDistrict = governingDistrictStorage === null ? [] : JSON.parse(governingDistrictStorage) as GoverningDistrictDto[];
  const governingDistricts: GoverningDistrictDto[] = governingDistrictList !== undefined && governingDistrictList.length !== 0 ? governingDistrictList :
    sessiongoverningDistrict.length !== 0 ? sessiongoverningDistrict :
      yield call(getGoverningDistrictsApi);

  yield put({ type: getType(getGoverningDistrictsActions.success), payload: governingDistricts });
  sessionStorage.setItem('governingDistricts', JSON.stringify(governingDistricts));
}

function* handleProfileGet() {
  const profile: ProfileDto = yield call(getProfileApi);
  yield put({ type: getType(getProfileActions.success), payload: profile });
}

function* handleProfileUpdate(request: ReturnType<typeof updateProfileActions.request>) {
  const updatedProfile: ProfileDto = yield call(updateProfileApi, request.payload);
  yield put({ type: getType(updateProfileActions.success), payload: updatedProfile });
}

function* handleProfileCreate(request: ReturnType<typeof createProfileActions.request>) {
  const createdProfile: ProfileDto = yield call(createProfileApi, request.payload);
  yield put({ type: getType(createProfileActions.success), payload: createdProfile });
}



 function* watchCountriesGetRequest() {
  try { yield takeLatest(getType(getCountriesActions.request), handleCountriesGet); }
  catch (err) { }
}

 function* watchGoverningDistrictsGetRequest() {
  try { yield takeLatest(getType(getGoverningDistrictsActions.request), handleGoverningDistrictsGet); }
  catch (err) { }
}

 function* watchProfileGetRequest() {
  try { yield takeLatest(getType(getProfileActions.request), handleProfileGet); }
  catch (err) { }
}

 function* watchProfileUpdateRequest() {
  try { yield takeLatest(getType(updateProfileActions.request), handleProfileUpdate); }
  catch (err) { }
}

 function* watchProfileCreateRequest() {
  try { yield takeLatest(getType(createProfileActions.request), handleProfileCreate); }
  catch (err) { }
}



export default [
  watchCountriesGetRequest,
  watchGoverningDistrictsGetRequest,
  //watchProfileGetRequest,
  //watchProfileUpdateRequest,
  //watchProfileCreateRequest,
];