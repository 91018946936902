import { FormControl, FormControlLabel, FormHelperText, Grid } from '@material-ui/core';
import { FormikErrors, FormikHandlers, FormikHelpers, getIn } from 'formik';
import React from 'react';
import Label from '../../../../common/components/label';
import { ISi } from '../../../../common/dtos/SiDto';
import { IndentGrid, MHARadio } from '../../../../common/styles/styles';

interface Props {
  label: string;
  name: string;
  value: number | undefined;
  errors: FormikErrors<ISi>;
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ISi>["setFieldValue"];
  isReadOnly: boolean;
}

const Pension401kCodeField: React.FC<Props> = ({ label, name, value, errors, handleBlur, setFieldValue, isReadOnly }) => {

  return (
    <>
      <Grid item xs={12}>
        <Label>{label}</Label>
      </Grid>
      <IndentGrid container direction="row">
        <FormControl error={Boolean(getIn(errors, name))} >
          <IndentGrid container direction="row">
            <FormControlLabel
              checked={value === 0}
              value={value}
              data-cy={`sidesResponse_${name}_Pension`}
              onChange={() => setFieldValue(name, 0)}
              onBlur={handleBlur}
              control={<MHARadio disabled={isReadOnly} size="small" name={name} />}
              label="Pension" />
            <FormControlLabel
              checked={value === 1}
              value={value}
              data-cy={`sidesResponse_${name}_401K`}
              onChange={() => setFieldValue(name, 1)}
              onBlur={handleBlur}
              control={<MHARadio disabled={isReadOnly} name={name} size="small" />}
              label="401K" />
            <FormControlLabel
              checked={value === undefined}
              value={value}
              data-cy={`sidesResponse_${name}_undefined`}
              onChange={() => setFieldValue(name, undefined)}
              onBlur={handleBlur}
              control={<MHARadio disabled={isReadOnly} name={name} size="small" />}
              label="N/A" />
          </IndentGrid>
          {Boolean(getIn(errors, name)) &&
            <FormHelperText
            id={`${name}-helper_text`}
          >
              {getIn(errors, name)}
            </FormHelperText>
          }
        </FormControl>
      </IndentGrid>
    </>
  );

}

export default Pension401kCodeField;