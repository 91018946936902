import { Formik, getIn } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
// material ui
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Link,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import { getMhaContactsActions } from '../../common/actions/actions';
import { MhaContactsDto } from '../../common/dtos/dto';
import { rootSelector } from '../../common/selectors/selectors';
import { getDocumentsActions } from './actions';
import DeleteDialog from './dialogs/deleteDialog';
import ReassignDialog from './dialogs/reassignDialog';
import {
  ActionsRequestDto,
  DocumentIdDto,
  DocumentsDto,
  DocumentsInit,
  ReassignDto,
  actionListValidationSchema,
  actionsRequestInit,
} from './document.dtos';
//import SidesMCResponseDialog from './dialogs/sidesMCResponseDialog';
import {
  getContactId,
  isClientPortalUser,
  isReadOnly,
} from '../../common/dtos/auth';
import { FeatureTypeEnum } from '../../common/enums/featureTypeEnum';
import {
  BMGrid,
  CardCheckbox2,
  Checkbox1,
  LabelLink,
  ListCardContent,
  NoPaddingIconButton,
  NoPaddingVisibilityIcon,
  NoPaddingVisibilityOffIcon,
  TableCard,
} from '../../common/styles/styles';
import { formatStaticSSN } from '../../utils/helperFunctions';
import { getGoverningDistrictsActions } from '../account/profile/profile.actions';
import { GoverningDistrictDto } from '../account/profile/profile.dtos';
import ClaimEditDialog from '../claims/dialogs/claimEditDialog';
import { documentTypeOptions, multiUserOptions } from '../claims/options';
import SidesMcResponseDialog from './dialogs/sidesMPCResponseDialog';
import SidesSIResponseDialog from './dialogs/sidesSIResponseDialog';
import {
  DueSoonButton,
  DueTodayButton,
  HistoryCheckbox,
  ImportExportIconBlue,
  ImportExportIconRed,
  OverdueButton,
  PaddedTextField,
  PastDueCheckbox,
  ReassignButton,
  SortGrid,
} from './documents.styles';

const DocumentsList: React.FC = () => {
  const dispatch = useDispatch();

  const systemTotalDocuments = rootSelector(
    (state) => state.documents.totalDocuments
  );
  const systemDocuments = rootSelector(
    (state) => state.documents.documentsList
  );
  const systemMhaContacts = rootSelector(
    (state) => state.companies.mhaContacts
  );
  const governingDistricts = rootSelector(
    (state) => state.governingDistricts.governingDistrictList
  );

  const baseUrl = process.env.REACT_APP_API_URL ?? '';
  const downloadDocumentUrl = baseUrl + 'download/documents/';

  const [totalDocuments, setTotalDocuments] = React.useState(0);
  const [documentsList, setDocumentsList] = React.useState(
    [] as DocumentsDto[]
  );
  const [requestDto, setRequestDto] = React.useState<ActionsRequestDto>({
    ...actionsRequestInit,
  });
  const [responsiblePersonOptions, setResponsiblePersonOptions] =
    React.useState<MhaContactsDto[]>([]);
  const [selectedDocuments, setSelectedDocuments] = React.useState(
    [] as string[]
  );
  const [reassignDto, setReassignDto] = React.useState({
    mhaContactId: '',
    documents: [],
  } as ReassignDto);
  const [deleteDocId, setDeleteDocId] = React.useState('');
  const [reassign, setReassign] = React.useState(false);
  const [deleteDoc, setDeleteDoc] = React.useState(false);
  const [claimEditDialogOpen, setClaimEditDialogOpen] = React.useState(false);
  const [selectedDocument, setSelectedDocument] = React.useState(
    DocumentsInit as DocumentsDto
  );
  const [highlightFilter, setHighlightFilter] = React.useState(false);
  const [highlightCriteria, setHighlightCriteria] = React.useState(
    [] as number[]
  );
  const [sidesMCResponse, setSidesMCResponse] = React.useState(false);
  const [sidesSIResponse, setSidesSIResponse] = React.useState(false);
  const [sidesClaimDocument, setSidesClaimDocument] = React.useState(
    DocumentsInit as DocumentsDto
  );
  const [readOnlyStatus, setReadOnlyStatus] = React.useState(false);
  const [stateOptions, setStateOptions] = React.useState(
    [] as GoverningDistrictDto[]
  );
  const [isMasked, setIsMasked] = React.useState(false);
  const [searchComplete, setSearchComplete] = React.useState<boolean>(false);

  const handleSSNMaskToggle = () => {
    setIsMasked(!isMasked);
  };

  React.useEffect(() => {
    setReadOnlyStatus(isReadOnly(FeatureTypeEnum.ActionList));
  }, [readOnlyStatus]);
  React.useEffect(() => {
    setStateOptions(governingDistricts);
  }, [governingDistricts]);
  React.useEffect(() => {
    setTotalDocuments(systemTotalDocuments);
  }, [systemTotalDocuments]);

  React.useEffect(() => {
    if (responsiblePersonOptions && responsiblePersonOptions.length !== 0) {
      let ContactId = getContactId();
      let filters: ActionsRequestDto = { ...actionsRequestInit };
      let filterString = sessionStorage.getItem('actionFilters');
      if (
        filterString !== undefined &&
        filterString !== null &&
        filterString?.length !== 0
      )
        filters = JSON.parse(filterString!);

      if (
        !!!filters.responsiblePerson &&
        responsiblePersonOptions.length !== 0 &&
        responsiblePersonOptions.some((s) => s.contactId === ContactId)
      )
        filters.responsiblePerson = ContactId;

      setRequestDto(filters);

      handleSubmitActionFilters(filters, undefined);
    }
  }, [responsiblePersonOptions]);

  React.useEffect(() => {
    dispatch({ type: getType(getMhaContactsActions.request) });
    dispatch({ type: getType(getGoverningDistrictsActions.request) });
  }, [dispatch]);

  React.useEffect(() => {
    if (highlightCriteria.length > 0) setHighlightFilter(true);
    else setHighlightFilter(false);
  }, [highlightCriteria]);

  React.useEffect(() => {
    setDocumentsList(systemDocuments);
  }, [systemDocuments]);

  React.useEffect(() => {
    if (systemMhaContacts.length !== 0) {
      let contacts = JSON.parse(
        JSON.stringify(systemMhaContacts)
      ) as MhaContactsDto[];
      contacts.unshift({ contactId: '00', name: 'All Team Members' });
      setResponsiblePersonOptions(contacts);
    }
  }, [systemMhaContacts]);

  const handleDocumentSelectionToggle = (id: string) => {
    let selected = JSON.parse(JSON.stringify(selectedDocuments)) as string[];
    let index = selected.findIndex((o) => o === id);

    if (index === -1) selected.push(id);
    else selected.splice(index, 1);

    setSelectedDocuments(selected);
  };

  const handleReassign = () => {
    let s = isReadOnly(FeatureTypeEnum.ActionList);
    if (s !== true) {
      setRequestDto({ ...actionsRequestInit });
      if (selectedDocuments.length === 0) {
        // Show select documents to reassign message.
      } else {
        let reassignRequest: ReassignDto = {
          mhaContactId: '0',
          documents: selectedDocuments.map((o) => {
            return { id: o } as DocumentIdDto;
          }),
        };

        setSelectedDocuments([] as string[]);

        setReassignDto(reassignRequest);
        setReassign(true);
      }
    }
  };

  const handleHighlightFilter = (id: number) => {
    if (highlightCriteria.includes(id)) {
      highlightCriteria.splice(highlightCriteria.indexOf(id), 1);
    } else {
      let d = highlightCriteria;
      d.push(id);
      setHighlightCriteria(d);
    }
  };

  const handleDeleteDoc = (docId: string) => {
    setDeleteDocId(docId);
    setDeleteDoc(true);
  };

  const handleSidesResponse = (doc: DocumentsDto) => {
    setSidesClaimDocument(doc);
    if (doc.sidesFormat === 'mc') {
      setSidesSIResponse(false);
      setSidesMCResponse(true);
    } else {
      setSidesMCResponse(false);
      setSidesSIResponse(true);
    }
  };

  const handleGetMaskedSSNValue = (ssn?: string) => {
    if (ssn === undefined || ssn === '') {
      return ssn;
    } else {
      if (ssn.includes('-')) return ssn;

      if (ssn.replace('-', '').length > 3)
        return '***-**-' + ssn.replace('-', '').slice(-4);
      else return '***-**-';
    }
  };

  const handleCancelReassign = () => {
    setReassign(false);
  };
  const handleCancelDeleteDoc = () => {
    setDeleteDoc(false);
  };
  const handleCancelSidesMCResponse = () => {
    setSidesMCResponse(false);
  };
  const handleCancelSidesSIResponse = () => {
    setSidesSIResponse(false);
  };
  const handleCancelClaimeEditDialog = () => {
    setClaimEditDialogOpen(false);
    // Comment below to stop auto reload.
    window.location.reload();
  };

  const handleClaimEditDialogOpen = (document: DocumentsDto) => {
    setSelectedDocument(document);
    setClaimEditDialogOpen(true);
  };

  const handleSubmitActionFilters = (
    filters: ActionsRequestDto | undefined,
    pageNumber: number | undefined
  ) => {
    if (filters === undefined) {
      let filterString = sessionStorage.getItem('actionFilters');
      if (
        filterString !== undefined &&
        filterString !== null &&
        filterString?.length !== 0
      )
        filters = JSON.parse(filterString);
      if (filters !== undefined && pageNumber !== undefined)
        filters.pageNumber = pageNumber;
    }

    if (filters === undefined) return;

    setSearchComplete(false);

    let request = JSON.parse(JSON.stringify(filters));

    if (request.responsiblePerson === '00') {
      request.allTeamMembers = true;
      delete request.responsiblePerson;
    } else request.allTeamMembers = false;
    sessionStorage.setItem('actionFilters', JSON.stringify(filters));

    dispatch({ type: getType(getDocumentsActions.request), payload: request });
    setTimeout(() => {
      setSearchComplete(true);
    }, 5000);
  };

  const handleSortFilterChange = (
    sortName: string,
    currentValue: boolean | undefined,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    if (currentValue === undefined) setFieldValue(sortName, true);
    else if (currentValue === true) setFieldValue(sortName, false);
    else if (currentValue === false) setFieldValue(sortName, undefined);

    if (sortName === 'sortCreatedDateDesc') {
      setFieldValue('sortDueDateDesc', undefined);
    } else if (sortName === 'sortDueDateDesc') {
      setFieldValue('sortCreatedDateDesc', undefined);
    }
  };

  return (
    <>
      {claimEditDialogOpen && (
        <ClaimEditDialog
          open={claimEditDialogOpen}
          onCancel={handleCancelClaimeEditDialog}
          claimDocument={selectedDocument}
          mhaContacts={systemMhaContacts}
        />
      )}
      {reassign && (
        <ReassignDialog
          open={reassign}
          dto={reassignDto}
          mhaContacts={systemMhaContacts}
          onCancel={handleCancelReassign}
        />
      )}

      {deleteDoc && (
        <DeleteDialog
          open={deleteDoc}
          id={deleteDocId}
          onCancel={handleCancelDeleteDoc}
        />
      )}

      {sidesMCResponse && (
        <SidesMcResponseDialog
          open={sidesMCResponse}
          claimDocument={sidesClaimDocument}
          isReadOnly={readOnlyStatus}
          onCancel={handleCancelSidesMCResponse}
        />
      )}

      {sidesSIResponse && (
        <SidesSIResponseDialog
          open={sidesSIResponse}
          claimDocument={sidesClaimDocument}
          isReadOnly={readOnlyStatus}
          onCancel={handleCancelSidesSIResponse}
        />
      )}

      <Formik
        initialValues={requestDto}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={actionListValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
          }, 700);
          let r = JSON.parse(JSON.stringify(values));
          r.pageNumber = 1;
          handleSubmitActionFilters(r, undefined);
        }}
        enableReinitialize={true}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            handleBlur,
          } = props;
          return (
            <Box p={2}>
              <Grid container item xs={11}>
                <BMGrid item xs={12}>
                  <Typography variant='subtitle1' display='inline'>
                    Action List
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    color='textSecondary'
                    display='inline'
                  >
                    (
                    {totalDocuments < 250
                      ? totalDocuments
                      : `${(values.pageNumber - 1) * 250 + 1} - ${
                          values.pageNumber * 250 < totalDocuments
                            ? values.pageNumber * 250
                            : totalDocuments
                        } of ${totalDocuments}`}
                    )
                  </Typography>
                </BMGrid>
                <BMGrid container item xs={12}>
                  <Grid container item xs={12} sm={8}>
                    <BMGrid container item xs={12}>
                      <Grid item xs={12} md={6} lg={3}>
                        <PaddedTextField
                          id='claimantFirstName'
                          size='small'
                          margin='none'
                          fullWidth
                          label='Claimant First Name'
                          name='claimantFirstName'
                          value={values.claimantFirstName || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(errors.claimantFirstName!)}
                          helperText={
                            errors.claimantFirstName! &&
                            touched.claimantFirstName!
                          }
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              setFieldValue('pageNumber', 1);
                              handleSubmit();
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <PaddedTextField
                          id='claimantLastName'
                          size='small'
                          margin='none'
                          fullWidth
                          label='Claimant Last Name'
                          name='claimantLastName'
                          value={values.claimantLastName || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(errors.claimantLastName!)}
                          helperText={
                            errors.claimantLastName! &&
                            touched.claimantLastName!
                          }
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              setFieldValue('pageNumber', 1);
                              handleSubmit();
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={2}>
                        <PaddedTextField
                          id='claimantSsn'
                          size='small'
                          margin='none'
                          fullWidth
                          label='SSN'
                          name='claimantSsn'
                          value={values.claimantSsn || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(errors.claimantSsn!)}
                          helperText={
                            errors.claimantSsn! &&
                            touched.claimantSsn! &&
                            errors.claimantSsn!
                          }
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              setFieldValue('pageNumber', 1);
                              handleSubmit();
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={2}>
                        <PaddedTextField
                          id='employerName'
                          size='small'
                          margin='none'
                          fullWidth
                          label='Employer Name'
                          name='employerName'
                          value={values.employerName || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(errors.employerName!)}
                          helperText={
                            errors.employerName! && touched.employerName!
                          }
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              setFieldValue('pageNumber', 1);
                              handleSubmit();
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={2}>
                        <PaddedTextField
                          id='employerSuta'
                          size='small'
                          margin='none'
                          fullWidth
                          label='Employer SUTA'
                          name='employerSuta'
                          value={values.employerSuta || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(errors.employerSuta!)}
                          helperText={
                            errors.employerSuta! && touched.employerSuta!
                          }
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              setFieldValue('pageNumber', 1);
                              handleSubmit();
                            }
                          }}
                        />
                      </Grid>
                    </BMGrid>
                    <Grid container item xs={12}>
                      <Grid item xs={12} md={6} lg={4}>
                        <PaddedTextField
                          id='responsiblePerson'
                          size='small'
                          margin='none'
                          select
                          value={
                            isClientPortalUser()
                              ? '00'
                              : values.responsiblePerson || ''
                          }
                          label='Responsible Person'
                          aria-label='Responsible Person Select'
                          name='responsiblePerson'
                          onChange={(e) => {
                            handleChange(e);
                            handleHighlightFilter(1);
                          }}
                          onBlur={(e) => {
                            handleBlur(e);
                            handleHighlightFilter(1);
                          }}
                          error={Boolean(
                            errors.responsiblePerson &&
                              touched.responsiblePerson
                          )}
                          helperText={
                            touched.responsiblePerson &&
                            errors.responsiblePerson
                          }
                          fullWidth
                        >
                          {responsiblePersonOptions
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((option, index) => (
                              <MenuItem
                                key={option.name + index}
                                value={option.contactId}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                        </PaddedTextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <PaddedTextField
                          id='documentStatus'
                          size='small'
                          margin='none'
                          select
                          value={values.documentStatus || ''}
                          label='Document Status'
                          name='documentStatus'
                          aria-label='Document Status Select'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            errors.documentStatus && touched.documentStatus
                          )}
                          helperText={
                            touched.documentStatus && errors.documentStatus
                          }
                          fullWidth
                        >
                          {multiUserOptions
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((option, index) => (
                              <MenuItem
                                key={option.name + index}
                                value={option.id}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                        </PaddedTextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={3}>
                        <PaddedTextField
                          id='documentType'
                          size='small'
                          margin='none'
                          select
                          value={values.documentType || ''}
                          label='Document Type'
                          name='documentType'
                          aria-label='Document Type Select'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(
                            errors.documentType && touched.documentType
                          )}
                          helperText={
                            touched.documentType && errors.documentType
                          }
                          fullWidth
                        >
                          {documentTypeOptions
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((option, index) => (
                              <MenuItem
                                key={option.name + index}
                                value={option.id}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                        </PaddedTextField>
                      </Grid>
                      <Grid item xs={12} md={6} lg={1}>
                        <Autocomplete
                          id='state'
                          options={stateOptions}
                          getOptionLabel={(option) => option.code}
                          value={
                            stateOptions.find(
                              (o) => o.code === values.stateCode
                            ) || null
                          }
                          includeInputInList
                          autoComplete
                          onChange={(
                            _e: any,
                            v: GoverningDistrictDto | null
                          ) => {
                            setFieldValue('stateCode', v?.code ?? '');
                          }}
                          renderInput={(params) => (
                            <PaddedTextField
                              {...params}
                              id='state'
                              size='small'
                              margin='none'
                              value={values.stateCode || ''}
                              label='State'
                              onBlur={(e) => {
                                handleBlur(e);
                              }}
                              error={Boolean(
                                getIn(errors, 'stateCode') &&
                                  getIn(touched, 'stateCode')
                              )}
                              helperText={
                                getIn(errors, 'stateCode') &&
                                getIn(touched, 'stateCode')
                              }
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item direction='row' xs={12} lg={4}>
                    <Grid container item xs={12}>
                      <Grid
                        container
                        item
                        justifyContent='flex-start'
                        xs={12}
                        sm={4}
                      >
                        <PaddedTextField
                          id='employeeId'
                          size='small'
                          margin='none'
                          fullWidth
                          label='Employee ID'
                          name='employeeId'
                          value={values.employeeId || ''}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(errors.employeeId!)}
                          helperText={errors.employeeId! && touched.employeeId!}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              setFieldValue('pageNumber', 1);
                              handleSubmit();
                            }
                          }}
                        />
                      </Grid>
                      <Grid container item justifyContent='flex-start' sm={3}>
                        <HistoryCheckbox
                          onChange={(e) => {
                            handleChange(e);
                            handleHighlightFilter(3);
                          }}
                          name='history'
                          id='history'
                          aria-label='View History Checkbox'
                          value={values.history || false}
                          checked={values.history || false}
                          control={<Checkbox size='small' />}
                          label={
                            <Typography variant='subtitle2'>History</Typography>
                          }
                        />
                      </Grid>
                      <Grid container item justifyContent='center' sm={3}>
                        <PastDueCheckbox
                          onChange={handleChange}
                          aria-label='Past Due Only Checkbox'
                          name='pastDueOnly'
                          id='pastDueOnly'
                          value={values.pastDueOnly || false}
                          checked={values.pastDueOnly || false}
                          control={<Checkbox size='small' />}
                          label={
                            <Typography variant='subtitle2'>
                              Due Today
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        justifyContent='flex-end'
                        hidden={readOnlyStatus}
                        xs={12}
                        sm={2}
                      >
                        <ReassignButton
                          title='REASSIGN Button'
                          disabled={readOnlyStatus}
                          color='secondary'
                          onClick={() => {
                            handleReassign();
                          }}
                          variant={
                            selectedDocuments.length > 0 ? 'outlined' : 'text'
                          }
                        >
                          <Typography color='primary' variant='subtitle2'>
                            REASSIGN
                          </Typography>
                        </ReassignButton>
                      </Grid>
                    </Grid>
                    <Grid container item justifyContent='space-between' xs={12}>
                      <Grid
                        container
                        item
                        justifyContent='flex-start'
                        sm={12}
                        md={3}
                      >
                        <Button
                          title='CLEAR FILTERS BUTTON'
                          color='secondary'
                          onClick={() => {
                            let ContactId = getContactId();
                            sessionStorage.removeItem('actionFilters');
                            setFieldValue('claimantFirstName', '');
                            setFieldValue('claimantLastName', '');
                            setFieldValue('claimantSsn', '');
                            setFieldValue('employerName', '');
                            setFieldValue('employerSuta', '');
                            setFieldValue('employeeId', '');
                            if (
                              responsiblePersonOptions.length !== 0 &&
                              responsiblePersonOptions.some(
                                (s) => s.contactId === ContactId
                              )
                            )
                              setFieldValue('responsiblePerson', ContactId);
                            else setFieldValue('responsiblePerson', undefined);
                            setFieldValue('documentStatus', undefined);
                            setFieldValue('documentType', undefined);
                            setFieldValue('stateCode', undefined);
                            setFieldValue('history', undefined);
                            setFieldValue('pastDueOnly', undefined);
                            setFieldValue('sortDueDateDesc', undefined);
                            setFieldValue('sortCreatedDateDesc', undefined);
                            setFieldValue('pageNumber', 1);
                            setTimeout(() => {
                              handleSubmit();
                            }, 700);
                          }}
                        >
                          <Typography color='primary' variant='subtitle2'>
                            CLEAR FILTERS
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid
                        container
                        item
                        justifyContent='flex-start'
                        sm={12}
                        md={3}
                      >
                        <Button
                          title='FILTER BUTTON'
                          variant={highlightFilter ? 'outlined' : 'text'}
                          color='secondary'
                          onClick={() => {
                            setFieldValue('pageNumber', 1);
                            handleSubmit();
                          }}
                        >
                          <Typography color='primary' variant='subtitle2'>
                            FILTER
                          </Typography>
                        </Button>
                      </Grid>
                      <SortGrid
                        container
                        item
                        justifyContent='flex-end'
                        xs={12}
                        md={6}
                        spacing={3}
                      >
                        <Grid
                          container
                          item
                          justifyContent='flex-end'
                          sm={12}
                          md={6}
                        >
                          <Checkbox1
                            onChange={() =>
                              handleSortFilterChange(
                                'sortDueDateDesc',
                                values.sortDueDateDesc,
                                setFieldValue
                              )
                            }
                            aria-label='Due Date Sort Toggle'
                            outlined={
                              values.sortDueDateDesc !== undefined
                                ? 'outlined'
                                : undefined
                            }
                            name='sortDueDateDesc'
                            id='sortDueDateDesc'
                            value={values.sortDueDateDesc || false}
                            checked={values.sortDueDateDesc || false}
                            control={
                              <Checkbox
                                checkedIcon={
                                  values.sortDueDateDesc ? (
                                    <ImportExportIconRed />
                                  ) : (
                                    <ImportExportIconBlue />
                                  )
                                }
                                icon={
                                  values.sortDueDateDesc ? (
                                    <ImportExportIconRed />
                                  ) : (
                                    <ImportExportIconBlue />
                                  )
                                }
                                size='small'
                              />
                            }
                            label={
                              <Typography variant='subtitle2'>
                                Due Date
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid
                          container
                          item
                          justifyContent='flex-end'
                          sm={12}
                          md={6}
                        >
                          <Checkbox1
                            onChange={() =>
                              handleSortFilterChange(
                                'sortCreatedDateDesc',
                                values.sortCreatedDateDesc,
                                setFieldValue
                              )
                            }
                            aria-label='Date Created Sort Toggle'
                            name='sortCreatedDateDesc'
                            id='sortCreatedDateDesc'
                            outlined={
                              values.sortCreatedDateDesc !== undefined
                                ? 'outlined'
                                : undefined
                            }
                            value={values.sortCreatedDateDesc || false}
                            checked={values.sortCreatedDateDesc || false}
                            control={
                              <Checkbox
                                checkedIcon={
                                  values.sortCreatedDateDesc ? (
                                    <ImportExportIconRed />
                                  ) : (
                                    <ImportExportIconBlue />
                                  )
                                }
                                icon={
                                  values.sortCreatedDateDesc ? (
                                    <ImportExportIconRed />
                                  ) : (
                                    <ImportExportIconBlue />
                                  )
                                }
                                size='small'
                              />
                            }
                            label={
                              <Typography variant='subtitle2'>
                                Date Created
                              </Typography>
                            }
                          />
                        </Grid>
                      </SortGrid>
                    </Grid>
                  </Grid>
                </BMGrid>

                <Grid container spacing={2}>
                  {(documentsList && documentsList).map((o, index) => (
                    <Grid item xs={12} key={'documentsList_item_' + index}>
                      <TableCard raised>
                        <ListCardContent>
                          <Grid container>
                            <div hidden></div>
                            <Grid item xs={11}>
                              <LabelLink
                                component={Button}
                                onClick={() => handleClaimEditDialogOpen(o)}
                                color='secondary'
                              >
                                {o.label &&
                                o.label.length !== 0 &&
                                o.label !== ' '
                                  ? o.label
                                  : '-NEW MAIL-'}
                              </LabelLink>
                            </Grid>

                            <Grid
                              container
                              alignItems='flex-start'
                              item
                              xs={1}
                              justifyContent='flex-end'
                            >
                              {Math.ceil(
                                -moment().utc().diff(o.dueDate, 'hours') / 24
                              ) > 0 ? (
                                <DueSoonButton
                                  type='button'
                                  variant='contained'
                                  color='primary'
                                >
                                  Due Soon
                                </DueSoonButton>
                              ) : Math.ceil(
                                  -moment().utc().diff(o.dueDate, 'hours') / 24
                                ) === 0 ? (
                                <DueTodayButton
                                  type='button'
                                  variant='contained'
                                  color='primary'
                                >
                                  Due Today
                                </DueTodayButton>
                              ) : Math.ceil(
                                  -moment().utc().diff(o.dueDate, 'hours') / 24
                                ) < 0 ? (
                                <OverdueButton
                                  type='button'
                                  variant='contained'
                                  color='primary'
                                >
                                  Overdue
                                </OverdueButton>
                              ) : null}
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={1}>
                              <Typography variant='body2' color='textPrimary'>
                                Due Date
                              </Typography>
                              <Typography variant='body2' color='secondary'>
                                {o.dueDate === undefined
                                  ? 'None on File'
                                  : moment(o.dueDate).format('MM/DD/yyyy')}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={1}>
                              <Typography variant='body2' color='textPrimary'>
                                BYE
                              </Typography>
                              <Typography variant='body2' color='secondary'>
                                {o.benefitYearEndingDate === undefined
                                  ? 'None on File'
                                  : moment(o.benefitYearEndingDate).format(
                                      'MM/DD/yyyy'
                                    )}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={1}>
                              <Typography variant='body2' color='textPrimary'>
                                Employer
                              </Typography>
                              <Typography variant='body2' color='secondary'>
                                {o.employerName === undefined ||
                                o.employerName.replace(' ', '').length === 0
                                  ? 'None on File'
                                  : (o.employerName ?? '').toUpperCase() ||
                                    'None on File'}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={1}>
                              <Typography variant='body2' color='textPrimary'>
                                SUTA
                              </Typography>
                              <Typography variant='body2' color='secondary'>
                                {o.suta || 'None on File'}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={1}>
                              <Typography variant='body2' color='textPrimary'>
                                State Code
                              </Typography>
                              <Typography variant='body2' color='secondary'>
                                {o.stateCode || 'None on File'}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={1}>
                              <Typography variant='body2' color='textPrimary'>
                                Employee ID
                              </Typography>
                              <Typography
                                variant='body2'
                                color='secondary'
                                style={{ wordWrap: 'break-word' }}
                              >
                                {o.employeeId || 'None on File'}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              lg={1}
                              style={{ top: 25 }}
                            >
                              <Typography variant='body2' color='textPrimary'>
                                SSN{' '}
                                {!isMasked ? (
                                  <NoPaddingIconButton
                                    tabIndex={-1}
                                    aria-label='View SSN'
                                    title='View SSN'
                                    onClick={() => handleSSNMaskToggle()}
                                  >
                                    <NoPaddingVisibilityIcon color='primary' />
                                  </NoPaddingIconButton>
                                ) : (
                                  <NoPaddingIconButton
                                    title='Hide SSN'
                                    aria-label='Hide SSN'
                                  >
                                    <NoPaddingVisibilityOffIcon
                                      onClick={() => handleSSNMaskToggle()}
                                      color='primary'
                                    />
                                  </NoPaddingIconButton>
                                )}
                              </Typography>
                              <Typography variant='body2' color='secondary'>
                                {isMasked
                                  ? handleGetMaskedSSNValue(o.claimantSsn) ||
                                    'None on File'
                                  : formatStaticSSN(o.claimantSsn) ||
                                    'None on File'}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={2}>
                              <Typography variant='body2' color='textPrimary'>
                                Document Type
                              </Typography>
                              <Typography variant='body2' color='secondary'>
                                {documentTypeOptions[
                                  documentTypeOptions.findIndex(
                                    (x) => o.type === x.id
                                  ) ?? 0
                                ]?.name || 'None on file'}
                                {!!o.sidesId &&
                                  o.sidesId.length !== 0 &&
                                  ` (format type: ${
                                    o.sidesFormat === 'mc' ? 'MC' : 'SI'
                                  })`}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={2}>
                              <Typography variant='body2' color='textPrimary'>
                                Document Status
                              </Typography>
                              <Typography variant='body2' color='secondary'>
                                {multiUserOptions.find((x) => o.status === x.id)
                                  ?.name || 'None on File'}
                              </Typography>
                            </Grid>

                            <Grid item xs={8}>
                              <span
                                hidden={
                                  Math.ceil(
                                    -moment().utc().diff(o.dueDate, 'hours') /
                                      24
                                  ) === 0
                                }
                              >
                                <span
                                  hidden={
                                    Math.ceil(
                                      -moment().utc().diff(o.dueDate, 'hours') /
                                        24
                                    ) < 1
                                  }
                                >
                                  Due in{' '}
                                  {Math.ceil(
                                    -moment().utc().diff(o.dueDate, 'hours') /
                                      24
                                  )}{' '}
                                  days
                                </span>
                                <span
                                  hidden={
                                    Math.ceil(
                                      -moment().utc().diff(o.dueDate, 'hours') /
                                        24
                                    ) >= 1
                                  }
                                  style={{ color: '#94280A' }}
                                >
                                  Due in{' '}
                                  {Math.ceil(
                                    -moment().utc().diff(o.dueDate, 'hours') /
                                      24
                                  )}{' '}
                                  days
                                </span>
                              </span>
                              <span
                                hidden={
                                  Math.ceil(
                                    -moment().utc().diff(o.dueDate, 'hours') /
                                      24
                                  ) !== 0
                                }
                                style={{ color: '#ef8d22' }}
                              >
                                Due today
                              </span>
                            </Grid>
                            <Grid
                              container
                              alignItems='flex-start'
                              item
                              xs={4}
                              justifyContent='flex-end'
                            >
                              <Grid item xs={12} sm={12} md={12} lg={4}>
                                {o.fileName !== undefined &&
                                  o.fileName.length !== 0 && (
                                    <Link
                                      id='downloadDocument'
                                      href={
                                        downloadDocumentUrl +
                                        encodeURIComponent(o.fileName ?? '')
                                      }
                                      download
                                    >
                                      {o.fileName !== undefined &&
                                      o.fileName.length !== 0
                                        ? 'Download'
                                        : ''}
                                    </Link>
                                  )}
                              </Grid>
                              <CardCheckbox2
                                onChange={() => {
                                  handleDocumentSelectionToggle(
                                    o.id ?? 'null' + index
                                  );
                                }}
                                name={'selected_' + index}
                                id={'selected_' + index}
                                hidden={readOnlyStatus}
                                value={
                                  selectedDocuments.includes(
                                    o.id ?? 'null' + index
                                  ) || false
                                }
                                checked={
                                  selectedDocuments.includes(
                                    o.id ?? 'null' + index
                                  ) || false
                                }
                                control={
                                  <Checkbox
                                    size='small'
                                    disabled={readOnlyStatus}
                                    aria-label='Select Document Checkbox'
                                  />
                                }
                                label=''
                              />
                              {o.sidesId === undefined ||
                              o.sidesId?.length === 0 ? (
                                <Button
                                  title='Delete Button'
                                  disabled={readOnlyStatus}
                                  hidden={readOnlyStatus}
                                  color='secondary'
                                  onClick={() => {
                                    handleDeleteDoc(o.id ?? '');
                                  }}
                                  size='medium'
                                >
                                  Delete
                                </Button>
                              ) : (
                                <Button
                                  title='SIDES Response Button'
                                  disabled={readOnlyStatus}
                                  hidden={readOnlyStatus}
                                  color='secondary'
                                  onClick={() => {
                                    handleSidesResponse(o);
                                  }}
                                  size='medium'
                                >
                                  Respond
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </ListCardContent>
                      </TableCard>
                    </Grid>
                  ))}
                  <Pagination
                    hidden={
                      documentsList &&
                      documentsList.length === 0 &&
                      values.pageNumber === 1
                    }
                    count={Math.ceil(totalDocuments / 250)}
                    variant='outlined'
                    color='primary'
                    size='small'
                    page={values.pageNumber || 1}
                    onChange={(e, p) => {
                      setFieldValue('pageNumber', p);
                      handleSubmitActionFilters(undefined, p);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  hidden={documentsList && documentsList.length !== 0}
                >
                  <Card>
                    <CardContent>
                      <Grid
                        container
                        justifyContent='center'
                        alignItems='center'
                      >
                        <Typography variant='subtitle1' color='secondary'>
                          {!searchComplete ? '' : `No records found`}
                        </Typography>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          );
        }}
      </Formik>
    </>
  );
};

export default DocumentsList;
