import { FormikErrors, FormikHelpers } from "formik";
import { IMc } from "../common/dtos/McDto";
import { EmployerSepReasonCodes_class } from "../common/enums/EmployerSepReasonCodes";
import { NotReturningCodes_class } from "../common/enums/NotReturningCodes";
import { VoluntarySepReasonCodes_class } from "../common/enums/VoluntarySepReasonCodes";

/**
 * Handles IndicatorTypeY type UISIDES MC fields
 * @param name: string
 * @param checked: boolean
 * @param set: function
 */
export const handleIndicatorTypeY = (
  name: string,
  checked: boolean,
  set: FormikHelpers<IMc>["setFieldValue"]
): void => {
  if (checked) set(name, 0);
  else set(name, undefined);
};

/**
 * Handles IndicatorType type UISIDES MC fields
 * @param name: string
 * @param checked: boolean
 * @param set: function
 */
export const handleIndicatorType = (
  name: string,
  checked: boolean,
  set: FormikHelpers<IMc>["setFieldValue"]
): void => {
  if (checked) set(name, 0);
  else set(name, undefined);
};

/**
 * Returns if the properity name is required based on the separation Code.
 * @param name: string
 * @param value: number | undefined
 */
export const isRequiredBySepCode = (
  name: string,
  value?: number | undefined
): boolean => {
  if (value === undefined) return false;
  let e = EmployerSepReasonCodes_class.enum;

  switch (name) {
    case 'workingAllAvailableHoursInd':
      return [e.Item10, e.Item11, e.Item12].includes(value ?? -1);
    case 'notWorkingAvailableHoursReason':
      return [e.Item18].includes(value ?? -1);

    default: return false;
  }
};

/**
 * Returns if the properity name is required based on the NotReturningCodes.
 * @param name: string
 * @param value: number | undefined
 */
export const isRequiredByNotReturningCode = (
  name: string,
  value?: number | undefined
): boolean => {
  if (value === undefined) return false;
  let e = NotReturningCodes_class.enum;

  switch (name) {
    case 'notWorkingAvailableHoursReason':
      return [e.Item18].includes(value ?? -1);

    default: return false;
  }
};


/**
 * Returns if the tab name has an error.
 * @param tab: string
 * @param value: FormikErrors
 */
export const hasTabError = (
  tab: 'details' | 'general' | 'misc',
  errors: FormikErrors<IMc>,
  values?: IMc
): boolean => {
  //console.log('hasTabError', tab, errors);
  if (errors == null) return false;
  let errorList: string[] = Object.keys(errors);
  if (errorList.length == 0) return false;

  let mList = [
    'employerReportedDependents', 'relationshipEndedInd', 'workingNewAssignmentInd', 'notWorkingOnAssignment'
  ];
  
  switch (tab) {
    case 'details':
      return [
        'claimantFirstName', 'claimantMiddleInitial', 'claimantLastName', 'claimantSuffix', 'otherLastName', 'otherSsn',
        'requestingStateAbbreviation', 'responseDueDate', 'benefitYearBeginDate', 'correctedStateEmployerAccountNbr', 'correctedStateEmployerAccountNbr',
        'correctedFein', 'typeofEmployerCode', 'formNumber', 'claimEffectiveDate', 'claimNumber', 'requestUiofficeName', 'requestUiofficePhone',
        'requestUiofficeFax', 'requestUiofficeEmailAddress', 'fraudInd', 'fraudReasons', 'fraudComments', 'attachmentInd', 'amendedResponse',
        'amendedResponseDescription'
      ].some(s => errorList.includes(s));
    case 'general':
      return [
        'lastDayWorked', 'employerSeparationReasonCode', 'refuseToProvideInd', 'additionalSeparationAckInd', 'employerContactName',
        'employerPhone', 'employerStreetAddress', 'employerCity', 'employerState', 'employerZip', 'repContractTermDate', 'employerSepReasonAnsweredInd',
        'seasonalEmploymentInd', 'temporaryStaffingInd', 'employerResponseComments', 'dischargeReasonCode', 'voluntarySepReasonCode',
        'requestReliefofChargesInd'
      ].some(s => errorList.includes(s));
    case 'misc':
      return mList.some(s => errorList.includes(s));

    default: return false; //'', '', '',
  }

  return false;
};
