import React from 'react';
import { useDispatch } from 'react-redux';
import { rootSelector } from '../../../common/selectors/selectors';
import * as Duo from '../../../assets/Duo-Web-v2';
import {
  Dialog, DialogActions, DialogContent
} from '@material-ui/core';
import { getType } from 'typesafe-actions';
import { ButtonGrid, StyledCancelButton, MfaIframe } from '../../../common/styles/styles';
import { tokenInit, LoginDto } from '../login/login.dtos';
import { loginActions } from '../login/login.actions';


const DuoMfaDialog: React.FC = () => {
  const dispatch = useDispatch();

  const systemLogin = rootSelector(state => state.login.loginSuccess);

  const [dto, setDto] = React.useState(tokenInit);
  const [isOpen, setIsOpen] = React.useState(false);
  const [host, setHost] = React.useState('');
  const [sig, setSig] = React.useState('');
  const [enrolUrl, setEnrolUrl] = React.useState('');

  const cancelDialog = () => {
    dispatch({ type: getType(loginActions.success), payload: JSON.parse(JSON.stringify(tokenInit)) });
  }

  React.useEffect(() => {
    setTimeout(() => {
      if (systemLogin.mfaRequired !== undefined && systemLogin.mfaRequired && systemLogin.useDuoMfa) {
        setHost(systemLogin.host ?? '');
        setSig(systemLogin.sig ?? '');
      }

      setDto(systemLogin);
      setIsOpen((systemLogin.mfaRequired ?? false) && (systemLogin.useDuoMfa ?? false));
      setEnrolUrl(systemLogin.enrollmentUrl ?? '');
    }, 300);

    return () => {
      setIsOpen(false);
      setDto(tokenInit);
      setHost('');
      setSig('');
      setEnrolUrl('');
    }
  }, [systemLogin]);
  
  const handleMfa = (res: any) => {
    if (res !== undefined && (res as HTMLFormElement).elements[0] !== undefined) {
      let duoRes: string = ((res as HTMLFormElement).elements[0] as HTMLInputElement).value;
      const loginCreds: LoginDto = {
        username: dto.userName,
        password: dto.userPassword,
        duoRes: duoRes
      };
      dispatch({ type: getType(loginActions.request), payload: loginCreds });
      cancelDialog();
    }
  }

  if (host.length === 0 && enrolUrl.length === 0)
    return null;

  return <Dialog
    open={isOpen && (host.length !== 0 || enrolUrl.length !== 0)}
    maxWidth="lg"
    data-cy="mfa_dialog"
  >
    {enrolUrl.length === 0 ? 
      <> 
        <MfaIframe
          id="duo_iframe"
          title='Two-Factor Authentication'
          data-host={host}
          data-sig-request={sig}
          data-submit-callback="handleMfa"
        >
        </MfaIframe>
        {setTimeout(() => { Duo.init({ host: host, sig_request: sig, iframe: 'duo_iframe', submit_callback: handleMfa }) }, 100) ? null : null}
      </> 
      :
      <>
        <MfaIframe
          title='Two-Factor Authentication'
          src={enrolUrl}
          data-submit-callback="handleMfa"
        >
        </MfaIframe>
        <br></br>
        <DialogContent>
          <DialogActions>
            <ButtonGrid container>
              <StyledCancelButton
                onClick={cancelDialog}
                data-cy="mfa_cancelButton"
              >BACK
              </StyledCancelButton>
            </ButtonGrid>
          </DialogActions>
        </DialogContent>
      </>
      }
  </Dialog>
}

export default DuoMfaDialog;
