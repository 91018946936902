import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { Autocomplete } from "@material-ui/lab";
import { FormikErrors, FormikHandlers, FormikHelpers, getIn } from "formik";
import React from "react";
import { months, quarters, years } from "../../../common/enums/dateEnums";
import { MHARadio, PaddedTextField } from "../../../common/styles/styles";
import { getReportGroups } from "../../../utils/reportHelperFunctions";
import { BusinessUnitDto, CompanyHsDto } from "../../company/dtos";
import { UnemploymentClaimsChargeReportParametersDto } from "../dtos";

interface Props extends React.HTMLProps<React.ReactNode> {
  values: UnemploymentClaimsChargeReportParametersDto;
  errors: FormikErrors<UnemploymentClaimsChargeReportParametersDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<UnemploymentClaimsChargeReportParametersDto>["setFieldValue"];
  heathSystems: string[];
  companies: CompanyHsDto[];
  showDisplayParameter?: boolean;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const handleGetStartDate = (
  period: number,
  year: number,
  month?: number,
  quarter?: number
): Date => {
  let startDatetimeParameter;
  if (period === 1 && month) {
    // "month" passed in is 1-indexed, but JS Date months are 0-indexed, so "month - 1" for the correct index.
    startDatetimeParameter = new Date(Date.UTC(year, month - 1, 1, 0, 0, 0, 0));
  } else if (period === 2 && quarter) {
    // "quater" passed in is 1-indexed based on the month it starts in, but JS Date months are 0-indexed, so "quarter - 1" for the correct index.
    startDatetimeParameter = new Date(
      Date.UTC(year, quarter - 1, 1, 0, 0, 0, 0)
    );
  } else {
    startDatetimeParameter = new Date(Date.UTC(year, 0, 1, 0, 0, 0, 0));
  }
  return startDatetimeParameter;
};

const handleGetEndDate = (
  period: number,
  year: number,
  month?: number,
  quarter?: number
): Date => {
  let endDatetimeParameter;
  if (period === 1 && month) {
    // The last day of the previous month is returned when the day argument is 0, so "month" is not subtracted from to compensate.
    endDatetimeParameter = new Date(Date.UTC(year, month, 0, 23, 59, 59, 999));
  } else if (period === 2 && quarter) {
    // The last day of the previous month is returned when the day argument is 0, and quarters are 3 months long so "quarter + 2" to compensate.
    endDatetimeParameter = new Date(
      Date.UTC(year, quarter + 2, 0, 23, 59, 59, 999)
    );
  } else {
    endDatetimeParameter = new Date(Date.UTC(year + 1, 0, 0, 23, 59, 59, 999));
  }
  return endDatetimeParameter;
};

const UnemploymentClaimsChargeReportParameters: React.FC<Props> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  heathSystems,
  companies,
  showDisplayParameter = true,
}) => {
  const periodOptions = [
    { name: "Monthly", id: 1 },
    { name: "Quarterly", id: 2 },
    { name: "Yearly", id: 3 },
  ];

  return (
    <>
      <Grid container item>
        <Grid container item xs={7}>
          {periodOptions.map((o) => (
            <Grid container item xs={12} sm={4} key={o.name}>
              <FormControlLabel
                onChange={() => {
                  setFieldValue("period", o.id);
                  setFieldValue("month", 0);
                  setFieldValue("quarter", 0);
                }}
                checked={o.id === values.period}
                value={o.name}
                control={<MHARadio size="small" />}
                label={o.name}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row">
        <Grid item xs={12} lg={4}>
          <Grid item xs={9}>
            <TextField
              id="year"
              size="small"
              margin="none"
              value={values.year || 0}
              InputLabelProps={{ required: true }}
              label="Year"
              name="year"
              select
              onChange={(e) => {
                handleChange(e);
                setFieldValue(
                  "startDatetimeParameter",
                  handleGetStartDate(
                    values.period,
                    parseInt(e.target.value ?? "0"),
                    values.month,
                    values.quarter
                  )
                );
                setFieldValue(
                  "endDatetimeParameter",
                  handleGetEndDate(
                    values.period,
                    parseInt(e.target.value ?? "0"),
                    values.month,
                    values.quarter
                  )
                );
              }}
              fullWidth
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
              error={Boolean(errors.year)}
              helperText={errors.year}
            >
              {years.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        {values.period === 1 ? (
          <Grid item xs={12} lg={4}>
            <Grid item xs={9}>
              <TextField
                id="month"
                size="small"
                margin="none"
                value={values.month || 0}
                InputLabelProps={{ required: true }}
                label="Month"
                name="month"
                select
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(
                    "startDatetimeParameter",
                    handleGetStartDate(
                      values.period,
                      values.year,
                      parseInt(e.target.value ?? "0"),
                      values.quarter
                    )
                  );
                  setFieldValue(
                    "endDatetimeParameter",
                    handleGetEndDate(
                      values.period,
                      values.year,
                      parseInt(e.target.value ?? "0"),
                      values.quarter
                    )
                  );
                }}
                fullWidth
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                error={Boolean(errors.month)}
                helperText={errors.month}
              >
                {months.map((option) => (
                  <MenuItem key={option.monthId} value={option.monthId}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        ) : null}

        {values.period === 2 ? (
          <Grid item xs={12} lg={4}>
            <Grid item xs={9}>
              <TextField
                id="quarter"
                size="small"
                margin="none"
                value={values.quarter || 0}
                InputLabelProps={{ required: true }}
                label="Quarter"
                name="quarter"
                select
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(
                    "startDatetimeParameter",
                    handleGetStartDate(
                      values.period,
                      values.year,
                      values.month,
                      parseInt(e.target.value ?? "0")
                    )
                  );
                  setFieldValue(
                    "endDatetimeParameter",
                    handleGetEndDate(
                      values.period,
                      values.year,
                      values.month,
                      parseInt(e.target.value ?? "0")
                    )
                  );
                }}
                fullWidth
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                error={Boolean(errors.quarter)}
                helperText={errors.quarter}
              >
                {quarters.map((option) => (
                  <MenuItem key={option.quarterId} value={option.quarterId}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      {values.groups.map((g, i) =>
        i < 1 ? (
          <React.Fragment key={"Group_HS_" + i}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <TextField
                name={`healthSystems[${i}]`}
                label="Health System"
                size="small"
                margin="none"
                select
                value={values.healthSystems[i] || ""}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(`groups[${i}]`, []);
                  setFieldValue(`healthGroups[${i}]`, "");
                }}
                fullWidth
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                error={
                  Boolean(getIn(errors, `healthSystems[${i}]`)) &&
                  values.healthSystems[i].length === 0
                }
                helperText={
                  errors.healthSystems &&
                  values.healthSystems[i].length === 0 &&
                  errors.healthSystems
                }
              >
                {heathSystems.map((option, index) => (
                  <MenuItem key={option + index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <TextField
                name={`healthGroups[${i}]`}
                label="Health System Group"
                size="small"
                margin="none"
                select
                value={values.healthGroups[i] || ""}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(`groups[${i}]`, []);
                }}
                fullWidth
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
              >
                {getReportGroups(companies, values.healthSystems, i)}
              </TextField>
            </Grid>

            {values.healthGroups[i]?.length !== 0 && (
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Autocomplete
                  id={"selectedEmployers" + i}
                  options={
                    Array.from(
                      new Set(
                        companies
                          .filter(
                            (c) =>
                              c.healthSystem === values.healthSystems[i] ||
                              values.healthSystems[i] === "All-MHA"
                          )
                          .filter((c) =>
                            c.group.includes(values.healthGroups[i])
                          )
                      )
                    ) as CompanyHsDto[]
                  }
                  getOptionLabel={(o: CompanyHsDto) => o.name ?? ""}
                  renderOption={(option) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={g.includes(option.companyId ?? "")}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  disableCloseOnSelect
                  multiple
                  value={Array.from(new Set(companies)).filter((o) =>
                    values.groups[i].map((m) => m).includes(o.companyId ?? "")
                  )}
                  onChange={(_e: any, v: CompanyHsDto[] | null) => {
                    let ids: string[] = [];
                    v?.forEach((c) => ids.push(c.companyId ?? ""));
                    setFieldValue(`groups[${i}]`, ids);
                    setFieldValue(`businessUnits`, []);
                  }}
                  renderInput={(params) => (
                    <PaddedTextField
                      {...params}
                      name={"selectedEmployers" + i}
                      label="Employers"
                      size="small"
                      margin="none"
                      aria-label="Employers"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}

            {values.groups[i]?.length !== 0 &&
              values.healthGroups[i]?.length !== 0 && (
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Autocomplete
                    id={`selectBusinessUnits-${i}`}
                    getOptionLabel={(option) => option.name}
                    options={[...companies]
                      .filter((company) =>
                        values.groups[i]
                          .map((group) => group)
                          .includes(company.companyId!)
                      )
                      .flatMap((company) =>
                        company.businessUnitList
                          .filter((businessUnit) => businessUnit.isActive)
                          .map((businessUnit) => businessUnit)
                      )}
                    renderOption={(option) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={(values.businessUnits ?? []).includes(
                            option.id!
                          )}
                        />
                        {option.name}
                      </React.Fragment>
                    )}
                    disableCloseOnSelect
                    multiple
                    value={[...companies]
                      .filter((company) =>
                        company.group.includes(values.healthGroups[i])
                      )
                      .flatMap((company) =>
                        company.businessUnitList.map(
                          (businessUnit) => businessUnit
                        )
                      )
                      .filter((businessUnit) =>
                        (values.businessUnits ?? []).includes(businessUnit.id!)
                      )}
                    onChange={(_e: any, value: BusinessUnitDto[] | null) => {
                      setFieldValue(
                        "businessUnits",
                        value?.map((businessUnit) => businessUnit.id)
                      );
                    }}
                    renderInput={(params) => (
                      <PaddedTextField
                        {...params}
                        aria-label="Business Units"
                        fullWidth
                        id={`selectedBusinessUnits-${i}`}
                        label="Business Units"
                        margin="none"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              )}

            {values.healthGroups[i]?.length !== 0 &&
              values.groups[i]?.length == 0 && (
                // This Grid exists to ensure that the EmployeeId field appears on its own row
                <Grid item xs={12} lg={6}></Grid>
              )}

            <Grid item xs={12} sm={6}>
              {showDisplayParameter && (
                <FormControl fullWidth>
                  <FormLabel id="display-radio-buttons-group">
                    Display
                  </FormLabel>

                  <RadioGroup
                    row
                    aria-labelledby="display-radio-buttons-group"
                    name="display"
                    value={values.display || "ssn"}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="ssn"
                      control={<Radio />}
                      label="Last 4 SSN"
                    />

                    <FormControlLabel
                      value="employeeId"
                      control={<Radio />}
                      label="Employee ID"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Grid>

            {/* <Grid item xs={12} lg={6}>
              <PaddedTextField
                id='employeeId'
                name='employeeId'
                size='small'
                margin='none'
                label='Employee Id'
                value={values.employeeId || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.employeeId)}
                helperText={errors.employeeId && errors.employeeId}
                fullWidth
              />
            </Grid> */}
          </React.Fragment>
        ) : null
      )}

      <Grid item xs={12} sm={7}>
        <FormControlLabel
          name="excludeFiledPriorToPOA"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.excludeFiledPriorToPOA || false}
          checked={values.excludeFiledPriorToPOA || false}
          control={
            <Checkbox
              value={values.excludeFiledPriorToPOA || false}
              style={{ paddingRight: 6 }}
              size="small"
              data-cy="uccParams_excludeFiledPriorToPOA"
            />
          }
          label={
            <Typography variant="subtitle2">
              Exclude Claims Filed Prior to Power of Attorney / Representation
            </Typography>
          }
        />
      </Grid>

      <Grid item xs={12} sm={5}>
        <FormControlLabel
          name="excludeSecondYearClaim"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.excludeSecondYearClaim || false}
          checked={values.excludeSecondYearClaim || false}
          control={
            <Checkbox
              value={values.excludeSecondYearClaim || false}
              style={{ paddingRight: 6 }}
              size="small"
              data-cy="uccParams_excludeSecondYearClaim"
            />
          }
          label={
            <Typography variant="subtitle2">
              Exclude Second Year Claims
            </Typography>
          }
        />
      </Grid>
    </>
  );
};

export default UnemploymentClaimsChargeReportParameters;
