import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { getClaimantsApi } from '../api/claimants';
import { claimantReportActions } from '../actions/claimant';
import { ClaimantReportDto } from '../dtos/claimantReportDto';

function* handleGetClaimantReportOptions(request: ReturnType<typeof claimantReportActions.request>) {
  const claimants: ClaimantReportDto[] = yield call(getClaimantsApi, request.payload);

  yield put({ type: getType(claimantReportActions.success), payload: claimants });
}

export function* watchClaimantReportRequest() {
  try {
    yield takeLatest(getType(claimantReportActions.request), handleGetClaimantReportOptions);
  }
  catch (err) {}
}