


import { ISelectOption } from "../dtos/SelectOptionDto";
enum IndicatorTypesWithD {
        Y = 0,
        N = 1,
        D = 2,
         
}

export class IndicatorTypesWithD_class {

public static enum = IndicatorTypesWithD;

  public static readonly enumByText : { [key : string ]: number} = {
    ['Y']: 0,
    ['N']: 1,
    ['D']: 2,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "Yes",
    [1]: "No",
    [2]: "Display flow",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "Yes"},
  {value: 1, text: "No"},
  {value: 2, text: "Display flow"},
  ]
}

