import React from 'react';
import { ProductivityReportParametersDto } from '../dtos';
import { FormikErrors, FormikHandlers, FormikHelpers, getIn } from 'formik';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  Grid,
  TextField,
  MenuItem,
  Checkbox,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { BusinessUnitDto, CompanyHsDto } from '../../company/dtos';
import { Autocomplete } from '@material-ui/lab';
import { PaddedTextField } from '../../../common/styles/styles';
import { getReportGroups } from '../../../utils/reportHelperFunctions';
import { ContactIdDto } from '../../claims/claims.dtos';

interface Props extends React.HTMLProps<React.ReactNode> {
  values: ProductivityReportParametersDto;
  errors: FormikErrors<ProductivityReportParametersDto>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  setFieldValue: FormikHelpers<ProductivityReportParametersDto>['setFieldValue'];
  heathSystems: string[];
  companies: CompanyHsDto[];
  claimsUsers: ContactIdDto[];
}

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const ProductivityReportParameters: React.FC<Props> = ({
  values,
  errors,
  handleBlur,
  setFieldValue,
  handleChange,
  heathSystems,
  companies,
  claimsUsers,
}) => {
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <KeyboardDatePicker
          id='startDatetimeParameter'
          name='startDatetimeParameter'
          size='small'
          margin='none'
          format='MM/DD/yyyy'
          value={values.startDatetimeParameter || null}
          label='Start Date'
          onChange={(date: MaterialUiPickersDate) =>
            setFieldValue('startDatetimeParameter', date)
          }
          onBlur={handleBlur}
          error={Boolean(errors.startDatetimeParameter)}
          helperText={
            Boolean(errors.startDatetimeParameter) &&
            errors.startDatetimeParameter
          }
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6}>
        <KeyboardDatePicker
          id='endDatetimeParameter'
          name='endDatetimeParameter'
          size='small'
          margin='none'
          format='MM/DD/yyyy'
          value={values.endDatetimeParameter || null}
          label='End Date'
          onChange={(date: MaterialUiPickersDate) =>
            setFieldValue('endDatetimeParameter', date)
          }
          onBlur={handleBlur}
          error={Boolean(errors.endDatetimeParameter)}
          helperText={
            Boolean(errors.endDatetimeParameter) && errors.endDatetimeParameter
          }
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6}>
        <TextField
          name={`users`}
          label='User(s)'
          size='small'
          margin='none'
          select
          value={values.users || ''}
          onChange={handleChange}
          fullWidth
          SelectProps={{ MenuProps: { disableScrollLock: true } }}
          error={Boolean(getIn(errors, `users`)) && values.users.length === 0}
          helperText={
            errors.healthSystems &&
            values.users.length === 0 &&
            errors.healthSystems
          }
        >
          {claimsUsers.map((option, index) => (
            <MenuItem
              key={(option.contactId ?? '') + index}
              value={option.contactId}
            >
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6}></Grid>

      {values.groups.map((g, i) =>
        i < 1 ? (
          <React.Fragment key={'Group_HS_' + i}>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                name={`healthSystems[${i}]`}
                label='Health System'
                size='small'
                margin='none'
                select
                value={values.healthSystems[i] || ''}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(`groups[${i}]`, []);
                  setFieldValue(`healthGroups[${i}]`, '');
                }}
                fullWidth
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                error={
                  Boolean(getIn(errors, `healthSystems[${i}]`)) &&
                  values.healthSystems[i].length === 0
                }
                helperText={
                  errors.healthSystems &&
                  values.healthSystems[i].length === 0 &&
                  errors.healthSystems
                }
              >
                {heathSystems.map((option, index) => (
                  <MenuItem key={option + index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                name={`healthGroups[${i}]`}
                label='Health System Group'
                size='small'
                margin='none'
                select
                value={values.healthGroups[i] || ''}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(`groups[${i}]`, []);
                }}
                fullWidth
                disabled={values.healthSystems[i] === 'All-MHA'}
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
              >
                {getReportGroups(companies, values.healthSystems, i)}
              </TextField>
            </Grid>

            {values.healthGroups[i]?.length !== 0 && (
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Autocomplete
                  id={'selectedEmployers' + i}
                  options={
                    Array.from(
                      new Set(
                        companies
                          .filter(
                            (c) =>
                              c.healthSystem === values.healthSystems[i] ||
                              values.healthSystems[i] === 'All-MHA'
                          )
                          .filter((c) =>
                            c.group.includes(values.healthGroups[i])
                          )
                      )
                    ) as CompanyHsDto[]
                  }
                  getOptionLabel={(o: CompanyHsDto) => o.name ?? ''}
                  renderOption={(option) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={g.includes(option.companyId ?? '')}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  disableCloseOnSelect
                  disabled={values.healthSystems[i] === 'All-MHA'}
                  multiple
                  value={Array.from(new Set(companies)).filter((o) =>
                    values.groups[i].map((m) => m).includes(o.companyId ?? '')
                  )}
                  onChange={(_e: any, v: CompanyHsDto[] | null) => {
                    let ids: string[] = [];
                    v?.forEach((c) => ids.push(c.companyId ?? ''));
                    setFieldValue(`groups[${i}]`, ids);
                  }}
                  renderInput={(params) => (
                    <PaddedTextField
                      {...params}
                      name={'selectedEmployers' + i}
                      size='small'
                      margin='none'
                      aria-label='Employers'
                      label='Employers'
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}

            {values.groups[i]?.length !== 0 &&
              values.healthGroups[i]?.length !== 0 && (
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Autocomplete
                    id={`selectBusinessUnits-${i}`}
                    getOptionLabel={(option) => option.name}
                    options={[...companies]
                      .filter((company) =>
                        values.groups[i]
                          .map((group) => group)
                          .includes(company.companyId!)
                      )
                      .flatMap((company) =>
                        company.businessUnitList
                          .filter((businessUnit) => businessUnit.isActive)
                          .map((businessUnit) => businessUnit)
                      )}
                    renderOption={(option) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={(values.businessUnits ?? []).includes(
                            option.id!
                          )}
                        />
                        {option.name}
                      </React.Fragment>
                    )}
                    disableCloseOnSelect
                    multiple
                    value={[...companies]
                      .filter((company) =>
                        company.group.includes(values.healthGroups[i])
                      )
                      .flatMap((company) =>
                        company.businessUnitList.map(
                          (businessUnit) => businessUnit
                        )
                      )
                      .filter((businessUnit) =>
                        (values.businessUnits ?? []).includes(businessUnit.id!)
                      )}
                    onChange={(_e: any, value: BusinessUnitDto[] | null) => {
                      setFieldValue(
                        'businessUnits',
                        value?.map((businessUnit) => businessUnit.id)
                      );
                    }}
                    renderInput={(params) => (
                      <PaddedTextField
                        {...params}
                        aria-label='Business Units'
                        fullWidth
                        id={`selectedBusinessUnits-${i}`}
                        label='Business Units'
                        margin='none'
                        size='small'
                      />
                    )}
                  />
                </Grid>
              )}
          </React.Fragment>
        ) : null
      )}

      <Grid item xs={12} sm={12}>
        <FormControl component='fieldset'>
          <RadioGroup
            row
            aria-label='Summary or Detail'
            name='summary'
            value={values.summary?.toString() || 'false'}
            onChange={(e) => {
              setFieldValue('summary', e.currentTarget.value);
            }}
          >
            <FormControlLabel
              value='false'
              control={<Radio />}
              label={<Typography variant='subtitle2'>Detail</Typography>}
            />
            <FormControlLabel
              value='true'
              control={<Radio style={{ paddingLeft: 50 }} />}
              label={<Typography variant='subtitle2'>Summary</Typography>}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </>
  );
};

export default ProductivityReportParameters;

//<Grid item xs={12} sm={7}>
//        <FormControlLabel
//          name="detail"
//          onChange={handleChange}
//          onBlur={handleBlur}
//          value={values.detail || false}
//          checked={values.detail || false}
//          control={<Checkbox value={values.detail || false} style={{ paddingRight: 6 }}
//            size="small" data-cy="prParams_detail" />}
//          label={<Typography variant="subtitle2">Detail</Typography>}
//        />
//      </Grid>

//      <Grid item xs={12} sm={5}>
//        <FormControlLabel
//          name="summary"
//          onChange={handleChange}
//          onBlur={handleBlur}
//          value={values.summary || false}
//          checked={values.summary || false}
//          control={<Checkbox value={values.summary || false} style={{ paddingRight: 6 }}
//            size="small" data-cy="prParams_summary" />}
//          label={<Typography variant="subtitle2">Summary</Typography>}
//        />
//      </Grid>
