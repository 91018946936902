import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors } from 'formik';

// material ui
import { Grid, MenuItem } from '@material-ui/core';

import AbsenteeismComponent from './discharge/absenteeismComponent';
import DrugsOrAlcoholComponent from './discharge/drugsOrAlcoholComponent';
import LossOfLicenseComponent from './discharge/lossOfLicenseComponent';
import NeglectOfDutiesComponent from './discharge/neglectOfDutiesComponent';
import NotQualifiedComponent from './discharge/notQualifiedComponent';
import TheftComponent from './discharge/theftComponent';
import { dischargeReasonOptions } from '../constants/options';
import { ClientQuestionnaireDto } from '../dto';
import { PaddedTextField } from '../../../common/styles/styles';


interface DischargeComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
  attachments: File[];
  onAttachmentDelete: (file: File) => void;
  onAttachmentUpload: (files: File[]) => void;
}

const DischargeComponent: React.FC<DischargeComponentProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation,
    attachments,
    onAttachmentUpload,
    onAttachmentDelete
  }) => {

  const renderSwitch = (
    id: number,
    values: ClientQuestionnaireDto,
    errors: FormikErrors<ClientQuestionnaireDto>,
    handleChange: FormikHandlers["handleChange"],
    handleBlur: FormikHandlers["handleBlur"],
    setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"]
  ) => {
    switch (id) {
      case 1:
      case 2:
        return <NotQualifiedComponent setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />

      case 3:
      case 4:
      case 8:
      case 10:
      case 11:
        return <NeglectOfDutiesComponent setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />

      case 5:
        return <AbsenteeismComponent setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />

      case 6:
        return <DrugsOrAlcoholComponent attachments={attachments} onAttachmentUpload={onAttachmentUpload} onAttachmentDelete={onAttachmentDelete}
          setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values} errors={errors}
          handleChange={handleChange} handleBlur={handleBlur} />

      case 7:
        return <TheftComponent setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />
      
      case 9:
        return <LossOfLicenseComponent setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />
   
      default:
        return null;
    }
  }

  return (
    <React.Fragment>
      <Grid item xs={10}>
        <Grid item xs={12}>What is the reason for the discharge?</Grid>
        <Grid item md={12} xs={12} sm={12} lg={5}>
          <PaddedTextField
            id="dischargeReasonId"
            size="small"
            margin="none"
            select
            value={values.dischargeReasonId || ''}
            name="dischargeReasonId"
            onChange={e => { handleChange(e); }}
            onBlur={handleBlur}
            error={Boolean(errors.dischargeReasonId)}
            helperText={errors.dischargeReasonId && errors.dischargeReasonId}
            data-cy="clientQuestionnaire_dischargeReason"
            aria-label="Resignation"
            fullWidth
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
          >
            {dischargeReasonOptions.filter((item) => item.name !== '')
              .map((option, index) => (
                <MenuItem key={option.name + index} value={option.id}>{option.name}</MenuItem>
              ))}
          </PaddedTextField>
        </Grid>
      </Grid>
      {
        values.dischargeReasonId && renderSwitch(values.dischargeReasonId, values, errors, handleChange, handleBlur, setFieldValue)
      }
      <br />
    </React.Fragment>
  )
}

export default DischargeComponent;
