


import { ISelectOption } from "../dtos/SelectOptionDto";
enum ContactCodes {
        Item1 = 0,
        Item2 = 1,
        Item3 = 2,
         
}

export class ContactCodes_class {

public static enum = ContactCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['Item1']: 0,
    ['Item2']: 1,
    ['Item3']: 2,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "1- Workers’ Compensation",
    [1]: "2- Pension",
    [2]: "3- 401K",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "1- Workers’ Compensation"},
  {value: 1, text: "2- Pension"},
  {value: 2, text: "3- 401K"},
  ]
}

