import React from 'react';
import { FormikHandlers, FormikTouched, FormikErrors, FormikHelpers, getIn } from 'formik';

// material ui
import { Grid, TextField, Box, FormControlLabel, Card, CardContent } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import * as Style from '../claims.styles';
import * as Styles from '../../../common/styles/styles';
import { ClaimDto } from '../claims.dtos';
import { getFullName } from '../../../common/dtos/auth';
import Label from '../../../common/components/label';

interface ClaimNotesProps extends React.HTMLProps<React.ReactNode> {
    values: ClaimDto,
    touched: FormikTouched<ClaimDto>,
    errors: FormikErrors<ClaimDto>,
    handleChange: FormikHandlers["handleChange"],
    handleBlur: FormikHandlers["handleBlur"],
    setShouldBlockNavigation: (o: boolean) => void,
    setFieldValue: FormikHelpers<ClaimDto>["setFieldValue"],
    noteType: number,
    handleGeneralNotesSelection: () => void,
    handleInternalNotesSelection: () => void,
    isClientUser: boolean,
    userType: number,
    claimsUserNotes: string[],
    appealsUserNotes: string[],
    multiUserNotes: string[],
    selectedAppealsNoteIndex: number,
    selectedMultiUserNoteIndex: number,
    selectedClaimsNoteIndex: number,
    noteIndex: number,
    handleClaimsNoteSelection: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => void,
    handleAppealsNoteSelection: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => void,
    handleMultiUserNoteSelection: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => void,
    isReadOnly: boolean,
}

const ClaimNotes: React.FC<ClaimNotesProps> = (props: ClaimNotesProps) => {
    const { values, touched, errors, handleChange, handleBlur, setShouldBlockNavigation, setFieldValue,
        userType, noteType, claimsUserNotes, handleGeneralNotesSelection, handleInternalNotesSelection, isClientUser, appealsUserNotes,
        noteIndex, selectedAppealsNoteIndex, selectedClaimsNoteIndex, selectedMultiUserNoteIndex, multiUserNotes,
        handleAppealsNoteSelection, handleClaimsNoteSelection, handleMultiUserNoteSelection, isReadOnly
  } = props;
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
               
                        {isClientUser ? <Grid item xs={12}><Label>Notes</Label></Grid>
                            : null}                       
                        <Box><Grid container direction="row">
                            {values.notes && noteType === 4 ?
                                <Grid item xs={3} hidden={isClientUser}><Breadcrumbs data-cy="claimNotes_generalNotesSelected" hidden={isClientUser} separator="  "><Style.NotesNavButtonSelected data-cy="claimNotes_GeneralNotesButton" disabled={isClientUser} disableRipple={false} onClick={handleGeneralNotesSelection}>General Notes</Style.NotesNavButtonSelected></Breadcrumbs></Grid>
                                : values.notes !== undefined ?
                                    <Grid item xs={3} hidden={isClientUser}><Breadcrumbs hidden={isClientUser} data-cy="claimNotes_generalNotesNotSelected" separator="  "><Style.NotesNavButtonUnSelected data-cy="claimNotes_GeneralNotesButtonSelect" disableRipple={false} onClick={handleGeneralNotesSelection}>General Notes</Style.NotesNavButtonUnSelected></Breadcrumbs></Grid>
                                    : null
                            }
                            {values.notes && noteType === 5 && values.notes?.filter(n => n.type === 5 && n.entry !== '').length > 0 ?
                                <Grid item xs={4}><Breadcrumbs data-cy="claimNotes_internalNotesSelected" hidden={isClientUser}><Style.NotesNavButtonSelected disableRipple={false} data-cy="claimNotes_InternalNotesSelectedButton" onClick={handleInternalNotesSelection}>Internal Notes</Style.NotesNavButtonSelected></Breadcrumbs></Grid>
                                : values.notes !== undefined && values.notes?.filter(n => n.type === 5 && n.entry !== '').length > 0 ?
                                    <Grid item xs={4}><Breadcrumbs data-cy="claimNotes_internalNotesNotSelected" hidden={isClientUser}><Style.NotesNavButtonUnSelected disableRipple={false} data-cy="claimNotes_InternalNotesNotSelectedButton" onClick={handleInternalNotesSelection}>Internal Notes</Style.NotesNavButtonUnSelected></Breadcrumbs></Grid>
                                    : values.notes && values.notes?.filter(n => n.type === 5 && n.entry !== '').length === 0 ?
                                        <Grid item xs={4}><Breadcrumbs data-cy="claimNotes_internalEmpty" hidden={isClientUser}><Style.NotesNavButtonEmpty data-cy="claimNotes_InternalNotesEmptyButton" disableRipple={false} onClick={handleInternalNotesSelection}>Internal Notes</Style.NotesNavButtonEmpty></Breadcrumbs></Grid>

                                        : null}
                        </Grid>
                            <br></br>
                            <Styles.IndentGrid container item xs={12} sm={12} >
                                {userType === 1 ?

                                    claimsUserNotes.map((o, index) => (
                                        <Grid item xs={12} sm={6} key={o + index} hidden={isClientUser === true || isReadOnly === true}>
                                        <FormControlLabel checked={index === selectedClaimsNoteIndex} value={o}
                                          onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
                                                onChange={(e, c) => {
                                                    handleClaimsNoteSelection(e, c);
                                                    let noteIndex = values.notes?.findIndex(n => n.type === noteType) ?? -1;
                                                    let newNotes = values.notes === undefined ? '' : values.notes![noteIndex]?.entry ?? '';
                                                    let newNote = '';
                                                    newNote += (e.target as HTMLInputElement).value;
                                                    newNote += '\xa0 \xa0';
                                                    newNote += '- \xa0' + getFullName();
                                                    newNote += '\xa0 \xa0';
                                                    newNote += '-\xa0' + new Date().toLocaleString();
                                                    if (newNotes.length !== 0) { newNote += '\n'; newNote += '\n'; }
                                                    newNotes = newNote + newNotes;
                                                    if (noteIndex !== -1) {
                                                        setFieldValue(`notes[${noteIndex}].entry`, newNotes);
                                                    }
                                                }}
                                                    control={<Styles.MHARadio
                                                    disabled={isClientUser || isReadOnly}
                                                    size="small" />} label={o} data-cy="claimNotes_CannedNoteGroup1" />
                                        </Grid>
                                    ))
                                    : userType === 2 ?
                                        appealsUserNotes.map((o, index) => (
                                            <Grid item xs={12} sm={6} key={o + index} hidden={isClientUser === true || isReadOnly === true}>
                                            <FormControlLabel checked={index === selectedAppealsNoteIndex} value={o}
                                              onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
                                                    onChange={(e, c) => {
                                                        handleAppealsNoteSelection(e, c);
                                                        let noteIndex = values.notes?.findIndex(n => n.type === noteType) ?? -1;

                                                        let newNotes = values.notes === undefined ? '' : values.notes![noteIndex]?.entry ?? '';
                                                        let newNote = '';
                                                        newNote += (e.target as HTMLInputElement).value;
                                                        newNote += '\xa0 \xa0';
                                                        newNote += '- \xa0' + getFullName();
                                                        newNote += '\xa0 \xa0';
                                                        newNote += '-\xa0' + new Date().toLocaleString();
                                                        if (newNotes.length !== 0) { newNote += '\n'; newNote += '\n'; }
                                                        newNotes = newNote + newNotes;
                                                        if (noteIndex !== -1) {
                                                            setFieldValue(`notes[${noteIndex}].entry`, newNotes);
                                                        }
                                                    }} control={<Styles.MHARadio size="small"
                                                        disabled={isClientUser || isReadOnly}
                                                    />}
                                                    label={o} data-cy="claimNotes_CannedNoteGroup2" />
                                            </Grid>
                                        )) :
                                        userType === 3 ?
                                            multiUserNotes.map((o, index) => (
                                                <Grid item xs={12} sm={6} key={o + index} hidden={isClientUser === true || isReadOnly === true}>
                                                <FormControlLabel checked={index === selectedMultiUserNoteIndex} value={o}
                                                  onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
                                                        onChange={(e, c) => {
                                                            handleMultiUserNoteSelection(e, c);
                                                            let noteIndex = values.notes?.findIndex(n => n.type === noteType) ?? -1;

                                                            let newNotes = values.notes === undefined ? '' : values.notes![noteIndex]?.entry ?? '';
                                                            let newNote = '';
                                                            newNote += (e.target as HTMLInputElement).value;
                                                            newNote += '\xa0 \xa0';
                                                            newNote += '- \xa0' + getFullName();
                                                            newNote += '\xa0 \xa0';
                                                            newNote += '-\xa0' + new Date().toLocaleString();
                                                            if (newNotes.length !== 0) { newNote += '\n'; newNote += '\n'; }
                                                            newNotes = newNote + newNotes;
                                                            if (noteIndex !== -1) {
                                                                setFieldValue(`notes[${noteIndex}].entry`, newNotes);
                                                            }
                                                        }} control={<Styles.MHARadio size="small"
                                                            disabled={isClientUser || isReadOnly}
                                                        />}
                                                        label={o} data-cy="claimNotes_CannedNoteGroup3" />
                                                </Grid>
                                            ))
                                            : null}
                            </Styles.IndentGrid>
                        </Box>
                        <Grid item xs={12}>
                            {(values.notes && values.notes[0]) && values.notes.filter(n => n.type === noteType).map((o, index) => (
                                <Card key={'notesFragmentText' + index}>
                                    <CardContent>
                                        <TextField id={`notes[${noteIndex}].entry`}
                                            size="small"
                                            margin="none"
                                            value={o.entry || ''}
                                            label="Notes"
                                            name={`notes[${noteIndex}].entry`}
                                            onChange={e => { handleChange(e); }}
                                            multiline
                                            disabled={isClientUser || isReadOnly}
                                            onBlur={e => { handleBlur(e); setShouldBlockNavigation(true) }}
                                            error={Boolean(getIn(errors, `notes[${noteIndex}].entry`) && getIn(touched, `notes[${noteIndex}].entry`))}
                                            helperText={(getIn(errors, `notes[${noteIndex}].entry`) && getIn(touched, `notes[${noteIndex}].entry`)) && getIn(errors, `notes[${noteIndex}].entry`)}
                                            data-cy={`claim_notes_${noteIndex}_notesInput`}
                                            fullWidth />
                                    </CardContent>
                                </Card>
                            ))}
                        </Grid>
                 
            </Grid>
                     
                  </Grid >
    )
}

export default ClaimNotes;
