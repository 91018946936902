 
/* Autogenerated by Typewriter do NOT edit
   Dtos.tst is the generating template */

import {IClaim} from './ClaimDto';
import {IUisSiAttachment} from './UisSiAttachmentDto';
import {IUisSiContactOccurrence} from './UisSiContactOccurrenceDto';
import {IUisSiFailedToReportOccurrence} from './UisSiFailedToReportOccurrenceDto';
import {IUisSiPension401Koccurrence} from './UisSiPension401KoccurrenceDto';
import {IUisSiPreviousAssignmentOccurrence} from './UisSiPreviousAssignmentOccurrenceDto';
import {IUisSiPriorIncidentOccurrence} from './UisSiPriorIncidentOccurrenceDto';
import {IUisSiRemunerationOccurrence} from './UisSiRemunerationOccurrenceDto';
import {IUisSiWitnessInformationOccurrence} from './UisSiWitnessInformationOccurrenceDto';

export interface ISi  { 
    
    claim?: IClaim;
    uisSiAttachments?: IUisSiAttachment[];
    uisSiContactOccurrences?: IUisSiContactOccurrence[];
    uisSiFailedToReportOccurrences?: IUisSiFailedToReportOccurrence[];
    uisSiPension401Koccurrences?: IUisSiPension401Koccurrence[];
    uisSiPreviousAssignmentOccurrences?: IUisSiPreviousAssignmentOccurrence[];
    uisSiPriorIncidentOccurrences?: IUisSiPriorIncidentOccurrence[];
    uisSiRemunerationOccurrences?: IUisSiRemunerationOccurrence[];
    uisSiWitnessInformationOccurrences?: IUisSiWitnessInformationOccurrence[];
    documentId?: string;
    isReadyToRespond?: boolean;
    stateRequestRecordGuid?: string;
    sender?: string;
    requestingStateAbbreviation?: number;
    requestUiofficeName?: string;
    requestUiofficePhone?: string;
    requestUiofficeFax?: string;
    requestUiofficeEmailAddress?: string;
    formNumber?: string;
    allocationRequiredInd?: number;
    stateEmployerAccountNbr?: string;
    employerName?: string;
    fein?: string;
    typeofEmployerCode?: number;
    pin?: string;
    ssn?: string;
    claimantLastName?: string;
    otherLastName?: string;
    claimantFirstName?: string;
    claimantMiddleInitial?: string;
    claimantSuffix?: string;
    claimEffectiveDate?: Date;
    claimNumber?: string;
    typeofClaimCode?: number;
    benefitYearBeginDate?: Date;
    requestDate?: Date;
    responseDueDate?: Date;
    requestClaimantJobTitle?: string;
    claimantReportedFirstDayofWork?: Date;
    claimantReportedLastDayofWork?: Date;
    claimantSepReasonCode?: number;
    claimantSepReasonComments?: string;
    requestReturntoWorkDate?: Date;
    wagesWeeksNeededCode?: number;
    wagesNeededBeginDate?: Date;
    wagesNeededEndDate?: Date;
    respondedDateTime?: Date;
    amendedResponse?: number;
    amendedResponseDescription?: string;
    correctedStateEmployerAccountNbr?: string;
    correctedEmployerName?: string;
    correctedFein?: string;
    otherSsn?: string;
    claimantNameWorkedAsForEmployer?: string;
    fraudInd?: number;
    fraudReasons?: number;
    fraudComments?: string;
    contractEmployee1099Ind?: number;
    seasonalEmploymentInd?: number;
    employerSepReasonCode?: number;
    temporaryStaffingInd?: number;
    claimantJobTitle?: string;
    employerReportedClaimantFirstDayofWork?: Date;
    employerReportedClaimantLastDayofWork?: Date;
    separationDateInd?: number;
    effectiveSeparationDate?: Date;
    returnToWorkInd?: number;
    returnToWorkDateInd?: number;
    whyLeaveOfAbsence?: string;
    agreedReturnToWorkDateInd?: number;
    returnToWorkDate?: Date;
    claimantReportedReturnToWorkDate?: Date;
    whoReducedHours?: number;
    workingAllAvailableHoursInd?: number;
    notWorkingAvailableHoursReason?: string;
    dischargeReasonCode?: number;
    paidDuringSuspensionInd?: number;
    receiveNormalRateOfPayInd?: number;
    inadequatePerfReasonInd?: number;
    finalIncidentDate?: Date;
    finalIncidentReason?: string;
    metPastExpections?: number;
    reasonForFinalAbsence?: string;
    askedToTestInd?: number;
    testPerformedInd?: number;
    whyNotPerformed?: string;
    whyRequestedToTest?: number;
    whoPerformedTest?: number;
    testerName?: string;
    testerCompanyName?: string;
    testResults?: string;
    chainOfCustodyInd?: number;
    secondTestSplitSampleGiven?: number;
    secondTestSplitSampleResults?: string;
    jobChangeInd?: number;
    whatConditionsChanged?: string;
    whatWasStolen?: string;
    actCommittedViolatedLaw?: string;
    adverseEffectOnEmployer?: string;
    valueAttachedToActInd?: number;
    dollarValue?: number;
    claimantAdmissionInd?: number;
    employerDamage?: string;
    damagesReportedBy?: string;
    altercationOnCompanyPropertyInd?: number;
    altercationOnCompanyTimeInd?: number;
    claimantStartAltercationInd?: number;
    claimantAvoidAltercationInd?: number;
    wereThereWitnessesInd?: number;
    violateCompanyPolicyInd?: number;
    dischargePolicyAwareInd?: number;
    dischargePolicyAwareExplanationCode?: number;
    whoDischargedName?: string;
    whoDischargedTitle?: string;
    dischargeReasonComments?: string;
    priorIncidentInd?: number;
    voluntarySepReasonCode?: number;
    claimantNotifyOfMedicalIssue?: number;
    claimantRequestMedicalLoa?: number;
    medicalLoaavailable?: number;
    loaapprovedInd?: number;
    whyLoadenied?: string;
    quitUnderAdviceOfHealthcareProvider?: number;
    medicalStatementProvidedInd?: number;
    dutiesClaimantCouldNotPerform?: string;
    workRestrictionsInd?: number;
    claimantWorkRestrictions?: string;
    workAvailableWithinRestrictionsInd?: number;
    otherWorkOfferedInd?: number;
    inLieuInd?: number;
    continuingWorkAvailableInd?: number;
    reasonDissatisfied?: string;
    workingConditions?: string;
    workingConditionsChangedInd?: number;
    changesPermanentInd?: number;
    changesScheduledToLast?: string;
    mandatoryRetirementInd?: number;
    whyRetireComments?: string;
    hiringAgreementChangesCode?: number;
    hiringAgreementChangesComments?: string;
    effectiveDateOfChanges?: Date;
    claimantActionsToAvoidQuitInd?: number;
    actionTakenComments?: string;
    claimantContactToExplainInd?: number;
    dateMadeContact?: Date;
    reasonForFailToReport?: string;
    claimantNoticeInd?: number;
    dateNoticeGiven?: Date;
    dateNoticeGivenUnknown?: number;
    claimantProvidedIntendedLastDayWork?: Date;
    claimantProvidedIntendedLastDayWorkUnknown?: number;
    claimantWorkOutNoticeInd?: number;
    whyNoticeWasntWorkedOut?: string;
    claimantPaidThroughNoticeInd?: number;
    voluntarySepReasonComments?: string;
    whyNotReturning?: number;
    firstDayOfContract?: Date;
    lastDayOfContract?: Date;
    reasonForBreak?: number;
    firstDayOfBreak?: Date;
    lastDayOfBreak?: Date;
    workContinueDuringBreakInd?: number;
    positionReturningTo?: string;
    newContractJobOfferInd?: number;
    personOfferedReasonableAssurance?: string;
    haveAuthorityToOfferInd?: number;
    howOfferConveyed?: number;
    whyContractNotOffered?: string;
    contractAccepted?: number;
    laborDisputeTypeInd?: number;
    locationOfLaborDispute?: string;
    unionNameAndLocalNo?: string;
    dateDisputeBegan?: Date;
    laborDisputeEndedInd?: number;
    dateDisputeEnded?: Date;
    replacementWorkersHiredInd?: number;
    clmtStrikingUnionMemberInd?: number;
    clmtMemberAnotherUnionInd?: number;
    whyUnemployedLaborDispute?: string;
    settlementAffectClmtInd?: number;
    howSettlementAffects?: string;
    relationshipEndedInd?: number;
    workingNewAssignmentInd?: number;
    notWorkingOnAssignment?: number;
    tempAgencyPolicyInd?: number;
    tempPolicyAwarenessCode?: number;
    tempAssignmentLastDay?: Date;
    newTempAssignmentDateOfferMade?: Date;
    newTempAssignmentStartDate?: Date;
    newTempAssignmentJobDuties?: string;
    newTempAssignmentRateOfPay?: number;
    newTempAssignmentCommutingDistance?: string;
    newTempAssignmentHours?: string;
    newTempAssignmentWhyRefused?: string;
    newTempAssignmentDateRefused?: Date;
    tempAssignmentWhoEnded?: number;
    tempAssignmentWhyNotCompleted?: string;
    tempAssignmentClaimantContactAgencyInd?: number;
    tempAssignmentWorkAvailableInd?: number;
    employerSepReasonComments?: string;
    refuseToProvideInd?: number;
    employerContactName?: string;
    employerPhone?: string;
    employerStreetAddress?: string;
    employerCity?: string;
    employerState?: number;
    employerZip?: string;
    repContractTermDate?: Date;
    totalEarnedWagesNeededInd?: number;
    totalEarnedWages?: number;
    totalWeeksWorkedNeededInd?: number;
    totalWeeksWorked?: string;
    wagesEarnedAfterClaimEffectiveDate?: number;
    numberOfHoursWorkedAfterClaimEffectiveDate?: string;
    regularRateofPay?: number;
    rateofPayPerPeriod?: number;
    averageNumberofHoursWorkedperWeek?: string;
    customaryWeeklyWorkSchedule?: string;
    remunerationInd?: number;
    receivingWorkersCompensationInd?: number;
    dateOfInjury?: Date;
    dateAppliedForWorkersCompKnownInd?: number;
    dateAppliedForWorkersComp?: Date;
    typeOfWorkersCompReceiving?: number;
    weeklyWorkersCompRate?: number;
    periodOfTimeWorkersCompPaymentCovers?: string;
    claimantReleasedToReturnToWork?: number;
    dateClaimantReleased?: Date;
    employerAccommodateRestrictionsInd?: number;
    whyEmployerCouldNotAccommodate?: string;
    workersCompComments?: string;
    receivePension401Kind?: number;
    mandatoryPension?: number;
    fullAmount401KrolledOver?: number;
    percentage401KrolledOver?: number;
    percentage401KrolledOverUnknown?: number;
    paidOut401KtoClaimant?: number;
    additionalSeparationAckInd?: number;
    attachmentInd?: number;
    preparerTypeCode?: number;
    preparerCompanyName?: string;
    preparerTelephoneNumberPlusExt?: string;
    preparerContactName?: string;
    preparerTitle?: string;
    preparerFaxNbr?: string;
    preparerEmailAddress?: string; 
} 

export class ISi_PropertyAttributes {
    static readonly claim_Attributes = {
      required: { value: true }, name: "Claim", 
      };
       static readonly uisSiAttachments_Attributes = {
      required: { value: false }, name: "UisSiAttachments", 
      };
       static readonly uisSiContactOccurrences_Attributes = {
      required: { value: false }, name: "UisSiContactOccurrences", 
      };
       static readonly uisSiFailedToReportOccurrences_Attributes = {
      required: { value: false }, name: "UisSiFailedToReportOccurrences", 
      };
       static readonly uisSiPension401Koccurrences_Attributes = {
      required: { value: false }, name: "UisSiPension401Koccurrences", 
      };
       static readonly uisSiPreviousAssignmentOccurrences_Attributes = {
      required: { value: false }, name: "UisSiPreviousAssignmentOccurrences", 
      };
       static readonly uisSiPriorIncidentOccurrences_Attributes = {
      required: { value: false }, name: "UisSiPriorIncidentOccurrences", 
      };
       static readonly uisSiRemunerationOccurrences_Attributes = {
      required: { value: false }, name: "UisSiRemunerationOccurrences", 
      };
       static readonly uisSiWitnessInformationOccurrences_Attributes = {
      required: { value: false }, name: "UisSiWitnessInformationOccurrences", 
      };
       static readonly documentId_Attributes = {
      required: { value: false }, name: "DocumentId", 
      };
       static readonly isReadyToRespond_Attributes = {
      required: { value: true }, name: "IsReadyToRespond", 
      };
       static readonly stateRequestRecordGuid_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 32, }, name: "StateRequestRecordGuid", 
      };
       static readonly sender_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 10, }, name: "Sender", 
      };
       static readonly requestingStateAbbreviation_Attributes = {
      required: { value: true }, name: "RequestingStateAbbreviation", 
      };
       static readonly requestUiofficeName_Attributes = {
      stringLength: { maxLength: 25, }, required: { value: false }, name: "RequestUiofficeName", 
      };
       static readonly requestUiofficePhone_Attributes = {
      stringLength: { maxLength: 15, }, required: { value: false }, name: "RequestUiofficePhone", 
      };
       static readonly requestUiofficeFax_Attributes = {
      stringLength: { maxLength: 10, }, required: { value: false }, name: "RequestUiofficeFax", 
      };
       static readonly requestUiofficeEmailAddress_Attributes = {
      stringLength: { maxLength: 60, }, required: { value: false }, name: "RequestUiofficeEmailAddress", 
      };
       static readonly formNumber_Attributes = {
      stringLength: { maxLength: 12, }, required: { value: false }, name: "FormNumber", 
      };
       static readonly allocationRequiredInd_Attributes = {
      required: { value: true }, name: "AllocationRequiredInd", 
      };
       static readonly stateEmployerAccountNbr_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 20, }, name: "StateEmployerAccountNbr", 
      };
       static readonly employerName_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 100, }, name: "EmployerName", 
      };
       static readonly fein_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 9, }, name: "Fein", 
      };
       static readonly typeofEmployerCode_Attributes = {
      required: { value: false }, name: "TypeofEmployerCode", 
      };
       static readonly pin_Attributes = {
      stringLength: { maxLength: 20, }, required: { value: false }, name: "Pin", 
      };
       static readonly ssn_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 9, }, name: "Ssn", 
      };
       static readonly claimantLastName_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 30, }, name: "ClaimantLastName", 
      };
       static readonly otherLastName_Attributes = {
      stringLength: { maxLength: 30, }, required: { value: false }, name: "OtherLastName", 
      };
       static readonly claimantFirstName_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 25, }, name: "ClaimantFirstName", 
      };
       static readonly claimantMiddleInitial_Attributes = {
      stringLength: { maxLength: 1, }, required: { value: false }, name: "ClaimantMiddleInitial", 
      };
       static readonly claimantSuffix_Attributes = {
      stringLength: { maxLength: 8, }, required: { value: false }, name: "ClaimantSuffix", 
      };
       static readonly claimEffectiveDate_Attributes = {
      required: { value: true }, name: "ClaimEffectiveDate", 
      };
       static readonly claimNumber_Attributes = {
      stringLength: { maxLength: 18, }, required: { value: false }, name: "ClaimNumber", 
      };
       static readonly typeofClaimCode_Attributes = {
      required: { value: true }, name: "TypeofClaimCode", 
      };
       static readonly benefitYearBeginDate_Attributes = {
      required: { value: true }, name: "BenefitYearBeginDate", 
      };
       static readonly requestDate_Attributes = {
      required: { value: true }, name: "RequestDate", 
      };
       static readonly responseDueDate_Attributes = {
      required: { value: true }, name: "ResponseDueDate", 
      };
       static readonly requestClaimantJobTitle_Attributes = {
      stringLength: { maxLength: 30, }, required: { value: false }, name: "RequestClaimantJobTitle", 
      };
       static readonly claimantReportedFirstDayofWork_Attributes = {
      required: { value: false }, name: "ClaimantReportedFirstDayofWork", 
      };
       static readonly claimantReportedLastDayofWork_Attributes = {
      required: { value: false }, name: "ClaimantReportedLastDayofWork", 
      };
       static readonly claimantSepReasonCode_Attributes = {
      required: { value: true }, name: "ClaimantSepReasonCode", 
      };
       static readonly claimantSepReasonComments_Attributes = {
      stringLength: { maxLength: 4000, }, required: { value: false }, name: "ClaimantSepReasonComments", 
      };
       static readonly requestReturntoWorkDate_Attributes = {
      required: { value: false }, name: "RequestReturntoWorkDate", 
      };
       static readonly wagesWeeksNeededCode_Attributes = {
      required: { value: true }, name: "WagesWeeksNeededCode", 
      };
       static readonly wagesNeededBeginDate_Attributes = {
      required: { value: false }, name: "WagesNeededBeginDate", 
      };
       static readonly wagesNeededEndDate_Attributes = {
      required: { value: false }, name: "WagesNeededEndDate", 
      };
       static readonly respondedDateTime_Attributes = {
      required: { value: false }, name: "RespondedDateTime", 
      };
       static readonly amendedResponse_Attributes = {
      required: { value: true }, name: "AmendedResponse", 
      };
       static readonly amendedResponseDescription_Attributes = {
      stringLength: { maxLength: 2000, }, required: { value: false }, name: "AmendedResponseDescription", 
      };
       static readonly correctedStateEmployerAccountNbr_Attributes = {
      stringLength: { maxLength: 20, }, required: { value: false }, name: "CorrectedStateEmployerAccountNbr", 
      };
       static readonly correctedEmployerName_Attributes = {
      stringLength: { maxLength: 100, }, required: { value: false }, name: "CorrectedEmployerName", 
      };
       static readonly correctedFein_Attributes = {
      stringLength: { maxLength: 9, }, required: { value: false }, name: "CorrectedFein", 
      };
       static readonly otherSsn_Attributes = {
      stringLength: { maxLength: 9, }, required: { value: false }, name: "OtherSsn", 
      };
       static readonly claimantNameWorkedAsForEmployer_Attributes = {
      stringLength: { maxLength: 70, }, required: { value: false }, name: "ClaimantNameWorkedAsForEmployer", 
      };
       static readonly fraudInd_Attributes = {
      required: { value: false }, name: "FraudInd", 
      };
       static readonly fraudReasons_Attributes = {
      required: { value: false }, name: "FraudReasons", 
      };
       static readonly fraudComments_Attributes = {
      stringLength: { maxLength: 100, }, required: { value: false }, name: "FraudComments", 
      };
       static readonly contractEmployee1099Ind_Attributes = {
      required: { value: false }, name: "ContractEmployee1099Ind", 
      };
       static readonly seasonalEmploymentInd_Attributes = {
      required: { value: false }, name: "SeasonalEmploymentInd", 
      };
       static readonly employerSepReasonCode_Attributes = {
      required: { value: false }, name: "EmployerSepReasonCode", 
      };
       static readonly temporaryStaffingInd_Attributes = {
      required: { value: false }, name: "TemporaryStaffingInd", 
      };
       static readonly claimantJobTitle_Attributes = {
      stringLength: { maxLength: 30, }, required: { value: false }, name: "ClaimantJobTitle", 
      };
       static readonly employerReportedClaimantFirstDayofWork_Attributes = {
      required: { value: false }, name: "EmployerReportedClaimantFirstDayofWork", 
      };
       static readonly employerReportedClaimantLastDayofWork_Attributes = {
      required: { value: false }, name: "EmployerReportedClaimantLastDayofWork", 
      };
       static readonly separationDateInd_Attributes = {
      required: { value: false }, name: "SeparationDateInd", 
      };
       static readonly effectiveSeparationDate_Attributes = {
      required: { value: false }, name: "EffectiveSeparationDate", 
      };
       static readonly returnToWorkInd_Attributes = {
      required: { value: false }, name: "ReturnToWorkInd", 
      };
       static readonly returnToWorkDateInd_Attributes = {
      required: { value: false }, name: "ReturnToWorkDateInd", 
      };
       static readonly whyLeaveOfAbsence_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "WhyLeaveOfAbsence", 
      };
       static readonly agreedReturnToWorkDateInd_Attributes = {
      required: { value: false }, name: "AgreedReturnToWorkDateInd", 
      };
       static readonly returnToWorkDate_Attributes = {
      required: { value: false }, name: "ReturnToWorkDate", 
      };
       static readonly claimantReportedReturnToWorkDate_Attributes = {
      required: { value: false }, name: "ClaimantReportedReturnToWorkDate", 
      };
       static readonly whoReducedHours_Attributes = {
      required: { value: false }, name: "WhoReducedHours", 
      };
       static readonly workingAllAvailableHoursInd_Attributes = {
      required: { value: false }, name: "WorkingAllAvailableHoursInd", 
      };
       static readonly notWorkingAvailableHoursReason_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "NotWorkingAvailableHoursReason", 
      };
       static readonly dischargeReasonCode_Attributes = {
      required: { value: false }, name: "DischargeReasonCode", 
      };
       static readonly paidDuringSuspensionInd_Attributes = {
      required: { value: false }, name: "PaidDuringSuspensionInd", 
      };
       static readonly receiveNormalRateOfPayInd_Attributes = {
      required: { value: false }, name: "ReceiveNormalRateOfPayInd", 
      };
       static readonly inadequatePerfReasonInd_Attributes = {
      required: { value: false }, name: "InadequatePerfReasonInd", 
      };
       static readonly finalIncidentDate_Attributes = {
      required: { value: false }, name: "FinalIncidentDate", 
      };
       static readonly finalIncidentReason_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "FinalIncidentReason", 
      };
       static readonly metPastExpections_Attributes = {
      required: { value: false }, name: "MetPastExpections", 
      };
       static readonly reasonForFinalAbsence_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "ReasonForFinalAbsence", 
      };
       static readonly askedToTestInd_Attributes = {
      required: { value: false }, name: "AskedToTestInd", 
      };
       static readonly testPerformedInd_Attributes = {
      required: { value: false }, name: "TestPerformedInd", 
      };
       static readonly whyNotPerformed_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "WhyNotPerformed", 
      };
       static readonly whyRequestedToTest_Attributes = {
      required: { value: false }, name: "WhyRequestedToTest", 
      };
       static readonly whoPerformedTest_Attributes = {
      required: { value: false }, name: "WhoPerformedTest", 
      };
       static readonly testerName_Attributes = {
      stringLength: { maxLength: 60, }, required: { value: false }, name: "TesterName", 
      };
       static readonly testerCompanyName_Attributes = {
      stringLength: { maxLength: 100, }, required: { value: false }, name: "TesterCompanyName", 
      };
       static readonly testResults_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "TestResults", 
      };
       static readonly chainOfCustodyInd_Attributes = {
      required: { value: false }, name: "ChainOfCustodyInd", 
      };
       static readonly secondTestSplitSampleGiven_Attributes = {
      required: { value: false }, name: "SecondTestSplitSampleGiven", 
      };
       static readonly secondTestSplitSampleResults_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "SecondTestSplitSampleResults", 
      };
       static readonly jobChangeInd_Attributes = {
      required: { value: false }, name: "JobChangeInd", 
      };
       static readonly whatConditionsChanged_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "WhatConditionsChanged", 
      };
       static readonly whatWasStolen_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "WhatWasStolen", 
      };
       static readonly actCommittedViolatedLaw_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "ActCommittedViolatedLaw", 
      };
       static readonly adverseEffectOnEmployer_Attributes = {
      stringLength: { maxLength: 2000, }, required: { value: false }, name: "AdverseEffectOnEmployer", 
      };
       static readonly valueAttachedToActInd_Attributes = {
      required: { value: false }, name: "ValueAttachedToActInd", 
      };
       static readonly dollarValue_Attributes = {
      required: { value: false }, name: "DollarValue", 
      };
       static readonly claimantAdmissionInd_Attributes = {
      required: { value: false }, name: "ClaimantAdmissionInd", 
      };
       static readonly employerDamage_Attributes = {
      stringLength: { maxLength: 250, }, required: { value: false }, name: "EmployerDamage", 
      };
       static readonly damagesReportedBy_Attributes = {
      stringLength: { maxLength: 30, }, required: { value: false }, name: "DamagesReportedBy", 
      };
       static readonly altercationOnCompanyPropertyInd_Attributes = {
      required: { value: false }, name: "AltercationOnCompanyPropertyInd", 
      };
       static readonly altercationOnCompanyTimeInd_Attributes = {
      required: { value: false }, name: "AltercationOnCompanyTimeInd", 
      };
       static readonly claimantStartAltercationInd_Attributes = {
      required: { value: false }, name: "ClaimantStartAltercationInd", 
      };
       static readonly claimantAvoidAltercationInd_Attributes = {
      required: { value: false }, name: "ClaimantAvoidAltercationInd", 
      };
       static readonly wereThereWitnessesInd_Attributes = {
      required: { value: false }, name: "WereThereWitnessesInd", 
      };
       static readonly violateCompanyPolicyInd_Attributes = {
      required: { value: false }, name: "ViolateCompanyPolicyInd", 
      };
       static readonly dischargePolicyAwareInd_Attributes = {
      required: { value: false }, name: "DischargePolicyAwareInd", 
      };
       static readonly dischargePolicyAwareExplanationCode_Attributes = {
      required: { value: false }, name: "DischargePolicyAwareExplanationCode", 
      };
       static readonly whoDischargedName_Attributes = {
      stringLength: { maxLength: 60, }, required: { value: false }, name: "WhoDischargedName", 
      };
       static readonly whoDischargedTitle_Attributes = {
      stringLength: { maxLength: 60, }, required: { value: false }, name: "WhoDischargedTitle", 
      };
       static readonly dischargeReasonComments_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "DischargeReasonComments", 
      };
       static readonly priorIncidentInd_Attributes = {
      required: { value: false }, name: "PriorIncidentInd", 
      };
       static readonly voluntarySepReasonCode_Attributes = {
      required: { value: false }, name: "VoluntarySepReasonCode", 
      };
       static readonly claimantNotifyOfMedicalIssue_Attributes = {
      required: { value: false }, name: "ClaimantNotifyOfMedicalIssue", 
      };
       static readonly claimantRequestMedicalLoa_Attributes = {
      required: { value: false }, name: "ClaimantRequestMedicalLoa", 
      };
       static readonly medicalLoaavailable_Attributes = {
      required: { value: false }, name: "MedicalLoaavailable", 
      };
       static readonly loaapprovedInd_Attributes = {
      required: { value: false }, name: "LoaapprovedInd", 
      };
       static readonly whyLoadenied_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "WhyLoadenied", 
      };
       static readonly quitUnderAdviceOfHealthcareProvider_Attributes = {
      required: { value: false }, name: "QuitUnderAdviceOfHealthcareProvider", 
      };
       static readonly medicalStatementProvidedInd_Attributes = {
      required: { value: false }, name: "MedicalStatementProvidedInd", 
      };
       static readonly dutiesClaimantCouldNotPerform_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "DutiesClaimantCouldNotPerform", 
      };
       static readonly workRestrictionsInd_Attributes = {
      required: { value: false }, name: "WorkRestrictionsInd", 
      };
       static readonly claimantWorkRestrictions_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "ClaimantWorkRestrictions", 
      };
       static readonly workAvailableWithinRestrictionsInd_Attributes = {
      required: { value: false }, name: "WorkAvailableWithinRestrictionsInd", 
      };
       static readonly otherWorkOfferedInd_Attributes = {
      required: { value: false }, name: "OtherWorkOfferedInd", 
      };
       static readonly inLieuInd_Attributes = {
      required: { value: false }, name: "InLieuInd", 
      };
       static readonly continuingWorkAvailableInd_Attributes = {
      required: { value: false }, name: "ContinuingWorkAvailableInd", 
      };
       static readonly reasonDissatisfied_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "ReasonDissatisfied", 
      };
       static readonly workingConditions_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "WorkingConditions", 
      };
       static readonly workingConditionsChangedInd_Attributes = {
      required: { value: false }, name: "WorkingConditionsChangedInd", 
      };
       static readonly changesPermanentInd_Attributes = {
      required: { value: false }, name: "ChangesPermanentInd", 
      };
       static readonly changesScheduledToLast_Attributes = {
      stringLength: { maxLength: 60, }, required: { value: false }, name: "ChangesScheduledToLast", 
      };
       static readonly mandatoryRetirementInd_Attributes = {
      required: { value: false }, name: "MandatoryRetirementInd", 
      };
       static readonly whyRetireComments_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "WhyRetireComments", 
      };
       static readonly hiringAgreementChangesCode_Attributes = {
      required: { value: false }, name: "HiringAgreementChangesCode", 
      };
       static readonly hiringAgreementChangesComments_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "HiringAgreementChangesComments", 
      };
       static readonly effectiveDateOfChanges_Attributes = {
      required: { value: false }, name: "EffectiveDateOfChanges", 
      };
       static readonly claimantActionsToAvoidQuitInd_Attributes = {
      required: { value: false }, name: "ClaimantActionsToAvoidQuitInd", 
      };
       static readonly actionTakenComments_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "ActionTakenComments", 
      };
       static readonly claimantContactToExplainInd_Attributes = {
      required: { value: false }, name: "ClaimantContactToExplainInd", 
      };
       static readonly dateMadeContact_Attributes = {
      required: { value: false }, name: "DateMadeContact", 
      };
       static readonly reasonForFailToReport_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "ReasonForFailToReport", 
      };
       static readonly claimantNoticeInd_Attributes = {
      required: { value: false }, name: "ClaimantNoticeInd", 
      };
       static readonly dateNoticeGiven_Attributes = {
      required: { value: false }, name: "DateNoticeGiven", 
      };
       static readonly dateNoticeGivenUnknown_Attributes = {
      required: { value: false }, name: "DateNoticeGivenUnknown", 
      };
       static readonly claimantProvidedIntendedLastDayWork_Attributes = {
      required: { value: false }, name: "ClaimantProvidedIntendedLastDayWork", 
      };
       static readonly claimantProvidedIntendedLastDayWorkUnknown_Attributes = {
      required: { value: false }, name: "ClaimantProvidedIntendedLastDayWorkUnknown", 
      };
       static readonly claimantWorkOutNoticeInd_Attributes = {
      required: { value: false }, name: "ClaimantWorkOutNoticeInd", 
      };
       static readonly whyNoticeWasntWorkedOut_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "WhyNoticeWasntWorkedOut", 
      };
       static readonly claimantPaidThroughNoticeInd_Attributes = {
      required: { value: false }, name: "ClaimantPaidThroughNoticeInd", 
      };
       static readonly voluntarySepReasonComments_Attributes = {
      stringLength: { maxLength: 2000, }, required: { value: false }, name: "VoluntarySepReasonComments", 
      };
       static readonly whyNotReturning_Attributes = {
      required: { value: false }, name: "WhyNotReturning", 
      };
       static readonly firstDayOfContract_Attributes = {
      required: { value: false }, name: "FirstDayOfContract", 
      };
       static readonly lastDayOfContract_Attributes = {
      required: { value: false }, name: "LastDayOfContract", 
      };
       static readonly reasonForBreak_Attributes = {
      required: { value: false }, name: "ReasonForBreak", 
      };
       static readonly firstDayOfBreak_Attributes = {
      required: { value: false }, name: "FirstDayOfBreak", 
      };
       static readonly lastDayOfBreak_Attributes = {
      required: { value: false }, name: "LastDayOfBreak", 
      };
       static readonly workContinueDuringBreakInd_Attributes = {
      required: { value: false }, name: "WorkContinueDuringBreakInd", 
      };
       static readonly positionReturningTo_Attributes = {
      stringLength: { maxLength: 30, }, required: { value: false }, name: "PositionReturningTo", 
      };
       static readonly newContractJobOfferInd_Attributes = {
      required: { value: false }, name: "NewContractJobOfferInd", 
      };
       static readonly personOfferedReasonableAssurance_Attributes = {
      stringLength: { maxLength: 60, }, required: { value: false }, name: "PersonOfferedReasonableAssurance", 
      };
       static readonly haveAuthorityToOfferInd_Attributes = {
      required: { value: false }, name: "HaveAuthorityToOfferInd", 
      };
       static readonly howOfferConveyed_Attributes = {
      required: { value: false }, name: "HowOfferConveyed", 
      };
       static readonly whyContractNotOffered_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "WhyContractNotOffered", 
      };
       static readonly contractAccepted_Attributes = {
      required: { value: false }, name: "ContractAccepted", 
      };
       static readonly laborDisputeTypeInd_Attributes = {
      required: { value: false }, name: "LaborDisputeTypeInd", 
      };
       static readonly locationOfLaborDispute_Attributes = {
      stringLength: { maxLength: 250, }, required: { value: false }, name: "LocationOfLaborDispute", 
      };
       static readonly unionNameAndLocalNo_Attributes = {
      stringLength: { maxLength: 100, }, required: { value: false }, name: "UnionNameAndLocalNo", 
      };
       static readonly dateDisputeBegan_Attributes = {
      required: { value: false }, name: "DateDisputeBegan", 
      };
       static readonly laborDisputeEndedInd_Attributes = {
      required: { value: false }, name: "LaborDisputeEndedInd", 
      };
       static readonly dateDisputeEnded_Attributes = {
      required: { value: false }, name: "DateDisputeEnded", 
      };
       static readonly replacementWorkersHiredInd_Attributes = {
      required: { value: false }, name: "ReplacementWorkersHiredInd", 
      };
       static readonly clmtStrikingUnionMemberInd_Attributes = {
      required: { value: false }, name: "ClmtStrikingUnionMemberInd", 
      };
       static readonly clmtMemberAnotherUnionInd_Attributes = {
      required: { value: false }, name: "ClmtMemberAnotherUnionInd", 
      };
       static readonly whyUnemployedLaborDispute_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "WhyUnemployedLaborDispute", 
      };
       static readonly settlementAffectClmtInd_Attributes = {
      required: { value: false }, name: "SettlementAffectClmtInd", 
      };
       static readonly howSettlementAffects_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "HowSettlementAffects", 
      };
       static readonly relationshipEndedInd_Attributes = {
      required: { value: false }, name: "RelationshipEndedInd", 
      };
       static readonly workingNewAssignmentInd_Attributes = {
      required: { value: false }, name: "WorkingNewAssignmentInd", 
      };
       static readonly notWorkingOnAssignment_Attributes = {
      required: { value: false }, name: "NotWorkingOnAssignment", 
      };
       static readonly tempAgencyPolicyInd_Attributes = {
      required: { value: false }, name: "TempAgencyPolicyInd", 
      };
       static readonly tempPolicyAwarenessCode_Attributes = {
      required: { value: false }, name: "TempPolicyAwarenessCode", 
      };
       static readonly tempAssignmentLastDay_Attributes = {
      required: { value: false }, name: "TempAssignmentLastDay", 
      };
       static readonly newTempAssignmentDateOfferMade_Attributes = {
      required: { value: false }, name: "NewTempAssignmentDateOfferMade", 
      };
       static readonly newTempAssignmentStartDate_Attributes = {
      required: { value: false }, name: "NewTempAssignmentStartDate", 
      };
       static readonly newTempAssignmentJobDuties_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "NewTempAssignmentJobDuties", 
      };
       static readonly newTempAssignmentRateOfPay_Attributes = {
      required: { value: false }, name: "NewTempAssignmentRateOfPay", 
      };
       static readonly newTempAssignmentCommutingDistance_Attributes = {
      stringLength: { maxLength: 50, }, required: { value: false }, name: "NewTempAssignmentCommutingDistance", 
      };
       static readonly newTempAssignmentHours_Attributes = {
      stringLength: { maxLength: 100, }, required: { value: false }, name: "NewTempAssignmentHours", 
      };
       static readonly newTempAssignmentWhyRefused_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "NewTempAssignmentWhyRefused", 
      };
       static readonly newTempAssignmentDateRefused_Attributes = {
      required: { value: false }, name: "NewTempAssignmentDateRefused", 
      };
       static readonly tempAssignmentWhoEnded_Attributes = {
      required: { value: false }, name: "TempAssignmentWhoEnded", 
      };
       static readonly tempAssignmentWhyNotCompleted_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "TempAssignmentWhyNotCompleted", 
      };
       static readonly tempAssignmentClaimantContactAgencyInd_Attributes = {
      required: { value: false }, name: "TempAssignmentClaimantContactAgencyInd", 
      };
       static readonly tempAssignmentWorkAvailableInd_Attributes = {
      required: { value: false }, name: "TempAssignmentWorkAvailableInd", 
      };
       static readonly employerSepReasonComments_Attributes = {
      stringLength: { maxLength: 2000, }, required: { value: false }, name: "EmployerSepReasonComments", 
      };
       static readonly refuseToProvideInd_Attributes = {
      required: { value: false }, name: "RefuseToProvideInd", 
      };
       static readonly employerContactName_Attributes = {
      stringLength: { maxLength: 60, }, required: { value: false }, name: "EmployerContactName", 
      };
       static readonly employerPhone_Attributes = {
      stringLength: { maxLength: 15, }, required: { value: false }, name: "EmployerPhone", 
      };
       static readonly employerStreetAddress_Attributes = {
      stringLength: { maxLength: 35, }, required: { value: false }, name: "EmployerStreetAddress", 
      };
       static readonly employerCity_Attributes = {
      stringLength: { maxLength: 19, }, required: { value: false }, name: "EmployerCity", 
      };
       static readonly employerState_Attributes = {
      required: { value: false }, name: "EmployerState", 
      };
       static readonly employerZip_Attributes = {
      stringLength: { maxLength: 19, }, required: { value: false }, name: "EmployerZip", 
      };
       static readonly repContractTermDate_Attributes = {
      required: { value: false }, name: "RepContractTermDate", 
      };
       static readonly totalEarnedWagesNeededInd_Attributes = {
      required: { value: false }, name: "TotalEarnedWagesNeededInd", 
      };
       static readonly totalEarnedWages_Attributes = {
      required: { value: false }, name: "TotalEarnedWages", 
      };
       static readonly totalWeeksWorkedNeededInd_Attributes = {
      required: { value: false }, name: "TotalWeeksWorkedNeededInd", 
      };
       static readonly totalWeeksWorked_Attributes = {
      stringLength: { maxLength: 2, }, required: { value: false }, name: "TotalWeeksWorked", 
      };
       static readonly wagesEarnedAfterClaimEffectiveDate_Attributes = {
      required: { value: false }, name: "WagesEarnedAfterClaimEffectiveDate", 
      };
       static readonly numberOfHoursWorkedAfterClaimEffectiveDate_Attributes = {
      stringLength: { maxLength: 5, }, required: { value: false }, name: "NumberOfHoursWorkedAfterClaimEffectiveDate", 
      };
       static readonly regularRateofPay_Attributes = {
      required: { value: false }, name: "RegularRateofPay", 
      };
       static readonly rateofPayPerPeriod_Attributes = {
      required: { value: false }, name: "RateofPayPerPeriod", 
      };
       static readonly averageNumberofHoursWorkedperWeek_Attributes = {
      stringLength: { maxLength: 2, }, required: { value: false }, name: "AverageNumberofHoursWorkedperWeek", 
      };
       static readonly customaryWeeklyWorkSchedule_Attributes = {
      stringLength: { maxLength: 100, }, required: { value: false }, name: "CustomaryWeeklyWorkSchedule", 
      };
       static readonly remunerationInd_Attributes = {
      required: { value: false }, name: "RemunerationInd", 
      };
       static readonly receivingWorkersCompensationInd_Attributes = {
      required: { value: false }, name: "ReceivingWorkersCompensationInd", 
      };
       static readonly dateOfInjury_Attributes = {
      required: { value: false }, name: "DateOfInjury", 
      };
       static readonly dateAppliedForWorkersCompKnownInd_Attributes = {
      required: { value: false }, name: "DateAppliedForWorkersCompKnownInd", 
      };
       static readonly dateAppliedForWorkersComp_Attributes = {
      required: { value: false }, name: "DateAppliedForWorkersComp", 
      };
       static readonly typeOfWorkersCompReceiving_Attributes = {
      required: { value: false }, name: "TypeOfWorkersCompReceiving", 
      };
       static readonly weeklyWorkersCompRate_Attributes = {
      required: { value: false }, name: "WeeklyWorkersCompRate", 
      };
       static readonly periodOfTimeWorkersCompPaymentCovers_Attributes = {
      stringLength: { maxLength: 250, }, required: { value: false }, name: "PeriodOfTimeWorkersCompPaymentCovers", 
      };
       static readonly claimantReleasedToReturnToWork_Attributes = {
      required: { value: false }, name: "ClaimantReleasedToReturnToWork", 
      };
       static readonly dateClaimantReleased_Attributes = {
      required: { value: false }, name: "DateClaimantReleased", 
      };
       static readonly employerAccommodateRestrictionsInd_Attributes = {
      required: { value: false }, name: "EmployerAccommodateRestrictionsInd", 
      };
       static readonly whyEmployerCouldNotAccommodate_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "WhyEmployerCouldNotAccommodate", 
      };
       static readonly workersCompComments_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "WorkersCompComments", 
      };
       static readonly receivePension401Kind_Attributes = {
      required: { value: false }, name: "ReceivePension401Kind", 
      };
       static readonly mandatoryPension_Attributes = {
      required: { value: false }, name: "MandatoryPension", 
      };
       static readonly fullAmount401KrolledOver_Attributes = {
      required: { value: false }, name: "FullAmount401KrolledOver", 
      };
       static readonly percentage401KrolledOver_Attributes = {
      required: { value: false }, name: "Percentage401KrolledOver", 
      };
       static readonly percentage401KrolledOverUnknown_Attributes = {
      required: { value: false }, name: "Percentage401KrolledOverUnknown", 
      };
       static readonly paidOut401KtoClaimant_Attributes = {
      required: { value: false }, name: "PaidOut401KtoClaimant", 
      };
       static readonly additionalSeparationAckInd_Attributes = {
      required: { value: false }, name: "AdditionalSeparationAckInd", 
      };
       static readonly attachmentInd_Attributes = {
      required: { value: false }, name: "AttachmentInd", 
      };
       static readonly preparerTypeCode_Attributes = {
      required: { value: false }, name: "PreparerTypeCode", 
      };
       static readonly preparerCompanyName_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "PreparerCompanyName", 
      };
       static readonly preparerTelephoneNumberPlusExt_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "PreparerTelephoneNumberPlusExt", 
      };
       static readonly preparerContactName_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "PreparerContactName", 
      };
       static readonly preparerTitle_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "PreparerTitle", 
      };
       static readonly preparerFaxNbr_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "PreparerFaxNbr", 
      };
       static readonly preparerEmailAddress_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "PreparerEmailAddress", 
      };
      
}

