import styled from 'styled-components';

// material ui
import { Grid, FormControl as FC, TextField as TF, FormControlLabel as FCL, List } from '@material-ui/core';


// Styled Comany Customer Settings components ....
export const WorkOrderSettingsGrid = styled(Grid)`
    margin: 2px 2px 27px 2px;
`;

export const WorkOrderSettingsToggleGrid = styled(Grid)`
    > form {
      > div.MuiTextField-root { margin-top: 0; }
    }
    ${props => {
    if (props.hidden) {
      return `display: none;`;
    }
  }}
`;

export const FormControl = styled(FC)`
    margin-right: ${props => props.theme.spacing(2)}px;
    min-width: 140px;
    
`;

export const TextField = styled(TF)`
    margin-top: 3px !important;
    margin-right: ${props => props.theme.spacing(2)}px;
`;


export const FullForm = styled.form`
    width: 100%;
`;

export const FullList = styled(List)`
    width: 100%;
`;

export const FormControlLabel = styled(FCL)`
    > span.MuiFormControlLabel-label {
      font-size: .70rem;
    }
`;

export const ActionContainer = styled.div`
    float: right;
    position: relative;
`;

export const ControlLabel = styled(FCL)`
    margin-left: ${props => props.theme.spacing(1)}px;
    > span.MuiFormControlLabel-label {
      font-size: .70rem;
    }
`;