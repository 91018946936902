import React from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, Redirect, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { CardActions, Grid, Link, IconButton } from "@material-ui/core";
import { getType } from "typesafe-actions";
import {
  requiredError,
  storageAuthToken,
  tenantId,
} from "../../../common/constants/strings";
import { rootSelector } from "../../../common/selectors/selectors";
import {
  isPasswordExpired as authIsPasswordExpired,
  isReadOnly,
} from "../../../common/dtos/auth";
//import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { loginInit } from "./login.dtos";
import { loginActions } from "./login.actions";
import DuoMfaDialog from "../dialogs/DuoMfaDialog";
import MfaDialog from "../dialogs/MfaDialog";
import {
  LoginContainer,
  LogoContainer,
  Logo,
  StyledInputAdornment,
  StyledVisibilityIcon,
  ForgotPasswordButton,
} from "./login.styles";
import {
  StyledDiv,
  StyledCard,
  StyledForm,
  StyledTextField,
  ButtonGrid,
  StyledSubmitButton,
  StyledCardContent,
} from "../../../common/styles/styles";

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { tenant } = useParams<{ tenant: string }>();

  const { isAuthenticated } = rootSelector((state) => state.auth);
  const { session } = rootSelector((state) => state.login);

  const [isPassword, setIsPassword] = React.useState<boolean>(true);
  const [waitingForMFA, setWaitingForMFA] = React.useState<boolean>(false);
  const [authenticated, setAuthenticated] = React.useState<boolean>(false);
  const [isPasswordExpired, setIsPasswordExpired] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    let storageAuthenticated =
      sessionStorage.getItem(storageAuthToken) ?? false;
    setAuthenticated(typeof storageAuthenticated === "string");
  }, []);

  React.useEffect(() => {
    localStorage.setItem(tenantId, tenant);
  }, [tenant]);

  React.useEffect(() => {
    if (isAuthenticated && !waitingForMFA && session) setAuthenticated(true);
    else setAuthenticated(false);
    setIsPasswordExpired(authIsPasswordExpired());
  }, [isAuthenticated, waitingForMFA, session]);

  const handlePasswordViewToggle = () => {
    setIsPassword(!isPassword);
  };

  const handleGetLocation = (): string => {
    if (isReadOnly(0)) {
      return "/employers";
    }
    return "/actions";
  };

  const handleGetUpdatePasswordUrl = (): string => {
    return `/${tenant ?? "mha"}/update-password`;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <LoginContainer component="main">
          {authenticated && <Redirect to={handleGetLocation()} />}

          {isPasswordExpired && ( // Currently this redirect to the update password page does not happen automatically. Somehow the redirect upon successfully logging in already re-updates the page after rendering the <Redirect />.
            <Redirect to={handleGetUpdatePasswordUrl()} />
          )}

          <DuoMfaDialog />
          <MfaDialog
            isPasswordExpired={isPasswordExpired}
            waitForMFA={(shouldWait: boolean) => setWaitingForMFA(shouldWait)}
          />
          <StyledDiv>
            <StyledCard>
              <LogoContainer>
                <br />
                <Logo>
                  <img
                    src={process.env.PUBLIC_URL + "/logo.png"}
                    alt="MHA/IHA logo"
                    width="470"
                  />
                </Logo>
              </LogoContainer>
              <Formik
                initialValues={loginInit}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={Yup.object({
                  username: Yup.string()
                    .email("Invalid email address")
                    .required(requiredError),
                  password: Yup.string()
                    .max(50, "Must be 50 characters or less")
                    .required(requiredError),
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setTimeout(() => {
                    setSubmitting(false);
                    resetForm();
                  }, 700);
                  dispatch({
                    type: getType(loginActions.request),
                    payload: values,
                  });
                }}
                enableReinitialize={true}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props;
                  return (
                    <StyledForm onSubmit={handleSubmit}>
                      <StyledCardContent>
                        <Grid container>
                          {/* username */}
                          <Grid item xs={12}>
                            <StyledTextField
                              id="username"
                              margin="normal"
                              InputLabelProps={{ required: true }}
                              fullWidth
                              value={values.username}
                              label="Email Address"
                              name="username"
                              autoComplete="email"
                              autoFocus
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(errors.username)}
                              helperText={
                                errors.username &&
                                touched.username &&
                                errors.username
                              }
                              data-cy="login_username"
                            />
                          </Grid>

                          {/* password */}
                          <Grid item xs={12}>
                            <StyledTextField
                              id="password"
                              margin="normal"
                              InputLabelProps={{ required: true }}
                              fullWidth
                              value={values.password}
                              name="password"
                              label="Password"
                              type={isPassword ? "password" : "text"}
                              autoComplete="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(errors.password)}
                              helperText={
                                errors.password &&
                                touched.password &&
                                errors.password
                              }
                              InputProps={{
                                endAdornment: (
                                  <StyledInputAdornment
                                    position="end"
                                    role="viewPasswordToggle"
                                    onClick={handlePasswordViewToggle}
                                  >
                                    {isPassword ? (
                                      <IconButton
                                        tabIndex={-1}
                                        aria-label="View Password"
                                        title="View Password"
                                      >
                                        <StyledVisibilityIcon color="primary" />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        tabIndex={-1}
                                        title="Hide Password"
                                        aria-label="Hide password"
                                      >
                                        <VisibilityOffIcon color="primary" />
                                      </IconButton>
                                    )}
                                  </StyledInputAdornment>
                                ),
                              }}
                              data-cy="login_password"
                            />
                          </Grid>
                        </Grid>
                      </StyledCardContent>
                      <CardActions>
                        <ButtonGrid container>
                          <ForgotPasswordButton tabIndex={-1}>
                            <Link
                              component={RouterLink}
                              to={`/${
                                localStorage.getItem(tenantId) ?? "mha"
                              }/forgot-password`}
                              data-cy="login_forgotpassword"
                            >
                              Forgot password?
                            </Link>
                          </ForgotPasswordButton>

                          <StyledSubmitButton
                            type="submit"
                            color="primary"
                            disabled={isSubmitting}
                            data-cy="login_submit"
                          >
                            Login
                          </StyledSubmitButton>
                        </ButtonGrid>
                      </CardActions>
                    </StyledForm>
                  );
                }}
              </Formik>
            </StyledCard>
          </StyledDiv>
        </LoginContainer>
      </Grid>
    </Grid>
  );
};

export default Login;
