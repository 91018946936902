export interface RoleDto {
  roleId: number,
  name: string,
  selected: boolean,
}

export const roleInit: RoleDto = { roleId: 0, name: '', selected: false };

export interface UserRoleDto {
  roleId: number,
}