import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors } from 'formik';

// material ui
import { Grid, MenuItem } from '@material-ui/core';

import HealthReasonsComponent from '../formComponents/resignation/healthReasonsComponent';
import FailureToReturnComponent from '../formComponents/resignation/failureToReturnComponent';
import FailureToReportComponent from '../formComponents/resignation/failureToReportComponent';
import InLieuOfDischargeComponent from '../formComponents/resignation/inLieuOfDischargeComponent';
import JobDissatisfactionComponent from '../formComponents/resignation/jobDissatisfactionComponent';
import WorkingConditionsComponent from '../formComponents/resignation/workingConditionsComponent';
import OtherComponent from '../formComponents/resignation/otherComponent';
import { resignationReasonOptions } from '../constants/options';

import { ClientQuestionnaireDto } from '../dto';
import { PaddedTextField } from '../../../common/styles/styles';


interface ResignationComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
}

const ResignationComponent: React.FC<ResignationComponentProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation
  }) => {

  const renderSwitch = (
    id: number,
    values: ClientQuestionnaireDto,
    errors: FormikErrors<ClientQuestionnaireDto>,
    handleChange: FormikHandlers["handleChange"],
    handleBlur: FormikHandlers["handleBlur"],
    setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"]
  ) => {
    switch (id) {
      case 1:
      case 2:
      case 6:
      case 8:
        return <></>
      case 3:
        return <HealthReasonsComponent setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          handleBlur={handleBlur} errors={errors} handleChange={handleChange} />
      case 4:
        return <JobDissatisfactionComponent setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />
      case 5:
        return <InLieuOfDischargeComponent setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />
      case 7:
        return <FailureToReportComponent setShouldBlockNavigation={setShouldBlockNavigation} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />
      case 9:
        return <WorkingConditionsComponent setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />
      case 10:
        return <FailureToReturnComponent setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />
      case 11:
        return <OtherComponent setShouldBlockNavigation={setShouldBlockNavigation} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />
      default:
        return null;
    }
  }

  return (
    <React.Fragment>
      <Grid item xs={10}>
        <Grid item xs={12}>What is the reason the employee gave for resigning?</Grid>
        <Grid item md={12} xs={12} sm={12} lg={5}>
          <PaddedTextField
            id="resignationReasonId"
            size="small"
            margin="none"
            select
            value={values.resignationReasonId || ''}
            name="resignationReasonId"
            onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
            onBlur={handleBlur}
            error={Boolean(errors.resignationReasonId)}
            helperText={errors.resignationReasonId && errors.resignationReasonId}
            data-cy="clientQuestionnaire_resignationReason"
            aria-label="Resignation reason"
            fullWidth
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
          >
            {resignationReasonOptions.filter((item) => item.name !== '')
              .map((option, index) => (
                <MenuItem key={option.name + index} value={option.id}>{option.name}</MenuItem>
              ))}
          </PaddedTextField>
        </Grid>
      </Grid>

      {values.resignationReasonId && renderSwitch(values.resignationReasonId, values, errors, handleChange, handleBlur, setFieldValue)}

      <br />
    </React.Fragment>
  )
}

export default ResignationComponent;
