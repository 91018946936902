import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors, } from 'formik';

// material ui
import { Grid, TextField, FormControlLabel, } from '@material-ui/core';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ClientQuestionnaireDto, } from '../../dto';
import { MHARadio } from '../../../../common/styles/styles';
import { radioButtonBooleanOptions } from '../../constants/options';


interface DisasterComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
}

const DisasterComponent: React.FC<DisasterComponentProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation
  }) => {

  return (
    <React.Fragment>
      <Grid item xs={12}>What was the claimant's last day worked prior to the natural disaster?</Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <KeyboardDatePicker
          margin="normal"
          id="lastDayPriorToDisaster"
          name="lastDayPriorToDisaster"
          //label="Hire Date"
          format="MM/DD/yyyy"
          value={values.lastDayPriorToDisaster || null}
          data-cy="clientQuestionnaire_lastDayPriorToDisaster"
          fullWidth
          onChange={(date: MaterialUiPickersDate) => { setFieldValue('lastDayPriorToDisaster', date); setShouldBlockNavigation(true); }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <br />
      <Grid item xs={12}>On what date(s) was the claimant's work site/location closed?</Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <KeyboardDatePicker
          margin="normal"
          id="workSiteClosedDate"
          name="workSiteClosedDate"
          //label="Hire Date"
          format="MM/DD/yyyy"
          value={values.workSiteClosedDate || null}
          data-cy="clientQuestionnaire_workSiteClosedDate"
          fullWidth
          onChange={(date: MaterialUiPickersDate) => { setFieldValue('workSiteClosedDate', date); setShouldBlockNavigation(true); }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <br />
      <Grid item xs={12}>Has the work site/location opened since the natural disaster?</Grid>
      <Grid container item xs={12} direction="row">
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="hasWorkSiteOpened"
              name="hasWorkSiteOpened"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('hasWorkSiteOpened', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? 1);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              //add errors for validation
              checked={o.status === values.hasWorkSiteOpened}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_hasWorkSiteOpened" />
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid item xs={12}>Did the claimant receive compensation for any time missed due to the natural disaster?</Grid>
      <Grid container item xs={12} direction="row">
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="disasterCompensationReceived"
              name="disasterCompensationReceived"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('disasterCompensationReceived', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? 1);
                setShouldBlockNavigation(true);
              }}
              onBlur={e => { handleBlur(e); }}
              //add errors for validation
              checked={o.status === values.disasterCompensationReceived}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_disasterCompensationReceived" />
          </Grid>
        ))}
      </Grid>
      <br />
      {values.disasterCompensationReceived !== undefined && values.disasterCompensationReceived === true ?
        <>
          <Grid item xs={12}>What type of pay?</Grid>
          <Grid item xs={4}>
            <TextField
              id="typeOfPay"
              size="small"
              margin="none"
              value={values.typeOfPay || ''}
              name="typeOfPay"
              multiline
              onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
              onBlur={handleBlur}
              error={Boolean(errors.typeOfPay)}
              helperText={errors.typeOfPay && errors.typeOfPay}
              data-cy="clientQuestionnaire_typeOfPay"
              aria-label="What type of pay?"
              fullWidth
            />
          </Grid>
          <br />
        </>
        : null}

      <br />

    </React.Fragment>
  )
}

export default DisasterComponent;
