import moment from 'moment';
import * as Yup from 'yup';
import {
  minDateError,
  maxDateError,
  maxError,
  maxNumError,
  notRequiredError,
  requiredError,
} from '../../common/constants/strings';
import { IMc } from '../../common/dtos/McDto';
import {
  isAmendedResponseDescription,
  isAttachmentInd,
  isDischargeReasonCode,
  isEmployerSeparationReasonCode,
  isEmployerSepReasonComments,
  isFraudComments,
  isFraudReasons,
  isNotWorkingOnAssignment,
  isRefuseToProvideInd,
  isRelationshipEndedInd,
  isVoluntarySepReasonCode,
  isWorkingNewAssignmentInd,
  isRequestReliefofChargesInd,
  isLastDayWorked,
  isEmployerSepReasonAnsweredInd,
} from '../../utils/mcRequiredPropFunctions';
import {
  opEmployerCity,
  opEmployerContactName,
  opEmployerState,
  opEmployerStreetAddress,
  opEmployerZip,
  opFraudInd,
  opSeasonalEmploymentInd,
  opTemporaryStaffingInd,
  opEmployerReportedDependents,
  opRepContractTermDate,
  opEmployerSeparationReasonCode,
} from '../../utils/mcOptionalPropFunctions';

/*
 * Yup Validation Schema based on
 * SIDES Monetary and Potential Charges Standard Format - 7-26-23.pdf
 *
 * Comments after values in mcValidationSchema reference that the value is mapped through the
 * GetSafeString() method at Orca.Application\Helpers\Mapper\UiSides\McMapProfile.cs:202
 * The method throws an error if the string doesn't match the regex pattern there.
 */

export const mcValidationSchema = Yup.object().shape({
  amendedResponseDescription: Yup.string()
    .max(2000, maxError)
    .nullable()
    .test('amendedResponseDescription', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v === undefined && isAmendedResponseDescription(this.parent))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isAmendedResponseDescription(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  correctedStateEmployerAccountNbr: Yup.string().max(20, maxError).nullable(), // Uses GetSafeString
  correctedEmployerName: Yup.string().max(100, maxError).nullable(), // Uses GetSafeString
  correctedFEIN: Yup.string().max(9, maxError).nullable(), // Uses GetSafeString
  otherSSN: Yup.string().max(9, maxError).nullable(), // Uses GetSafeString
  claimantNameWorkedAsForEmployer: Yup.string().max(70, maxError).nullable(),
  employerSepReasonAnsweredInd: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('employerSepReasonAnsweredInd', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v === undefined && isEmployerSepReasonAnsweredInd(this.parent))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isEmployerSepReasonAnsweredInd(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  fraudInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('fraudInd', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v !== undefined && !opFraudInd(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  fraudReasons: Yup.number()
    .max(6, maxNumError)
    .nullable()
    .test('fraudReasons', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v === undefined && isFraudReasons(this.parent))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isFraudReasons(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  fraudComments: Yup.string()
    .max(100, maxError)
    .nullable()
    .test('fraudComments', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v === undefined && isFraudComments(this.parent))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isFraudComments(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  seasonalEmploymentInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('seasonalEmploymentInd', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v !== undefined && !opSeasonalEmploymentInd(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  employerSeparationReasonCode: Yup.number()
    .max(23, maxNumError)
    .nullable()
    .test('employerSeparationReasonCode', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v === undefined && isEmployerSeparationReasonCode(this.parent))
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isEmployerSeparationReasonCode(this.parent) &&
        !opEmployerSeparationReasonCode(this.parent)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  temporaryStaffingInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('temporaryStaffingInd', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v !== undefined && !opTemporaryStaffingInd(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  employerReportedDependents: Yup.string()
    .max(1, maxError)
    .nullable()
    .test('employerReportedDependents', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v !== undefined && !opEmployerReportedDependents(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  lastDayWorked: Yup.date()
    .nullable()
    .test('lastDayWorked', '', function (v) {
      const { isReadyToRespond, requestDate, claimEffectiveDate } = this
        .parent as IMc;
      if (!isReadyToRespond) return true;
      if (v === undefined && isLastDayWorked(this.parent))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isLastDayWorked(this.parent))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(requestDate);
      let checkLowDate = moment(requestDate).add(-6, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  requestReliefofChargesInd: Yup.number()
    .max(8, maxNumError)
    .nullable()
    .test('requestReliefofChargesInd', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v === undefined && isRequestReliefofChargesInd(this.parent))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isRequestReliefofChargesInd(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  dischargeReasonCode: Yup.number()
    .max(11, maxNumError)
    .nullable()
    .test('dischargeReasonCode', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v === undefined && isDischargeReasonCode(this.parent))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isDischargeReasonCode(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  voluntarySepReasonCode: Yup.number()
    .max(9, maxNumError)
    .nullable()
    .test('voluntarySepReasonCode', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v === undefined && isVoluntarySepReasonCode(this.parent))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isVoluntarySepReasonCode(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  relationshipEndedInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('relationshipEndedInd', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v === undefined && isRelationshipEndedInd(this.parent))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isRelationshipEndedInd(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  workingNewAssignmentInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('workingNewAssignmentInd', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v === undefined && isWorkingNewAssignmentInd(this.parent))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWorkingNewAssignmentInd(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  notWorkingOnAssignment: Yup.number()
    .max(4, maxNumError)
    .nullable()
    .test('notWorkingOnAssignment', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v === undefined && isNotWorkingOnAssignment(this.parent))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isNotWorkingOnAssignment(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  employerResponseComments: Yup.string()
    .max(2000, maxError)
    .nullable()
    .test('employerResponseComments', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v === undefined && isEmployerSepReasonComments(this.parent))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isEmployerSepReasonComments(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  refuseToProvideInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('refuseToProvideInd', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v === undefined && isRefuseToProvideInd(this.parent))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isRefuseToProvideInd(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  employerContactName: Yup.string()
    .max(60, maxError)
    .nullable()
    .test('employerContactName', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v !== undefined && !opEmployerContactName(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  employerStreetAddress: Yup.string()
    .max(35, maxError)
    .nullable()
    .test('employerStreetAddress', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v !== undefined && !opEmployerStreetAddress(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  employerCity: Yup.string()
    .max(19, maxError)
    .nullable()
    .test('employerCity', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v !== undefined && !opEmployerCity(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  employerState: Yup.string()
    .max(3, maxError)
    .nullable()
    .test('employerState', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v !== undefined && !opEmployerState(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  employerZip: Yup.string()
    .max(10, maxError)
    .nullable()
    .test('employerZip', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v !== undefined && !opEmployerZip(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  repContractTermDate: Yup.date()
    .nullable()
    .test('repContractTermDate', '', function (v) {
      const { isReadyToRespond, requestDate } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v !== undefined && !opRepContractTermDate(this.parent))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(requestDate);
      let checkLowDate = moment(requestDate).add(-100, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  attachmentInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('attachmentInd', '', function (v) {
      const { isReadyToRespond } = this.parent as IMc;
      if (!isReadyToRespond) return true;
      if (v === undefined && isAttachmentInd(this.parent))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isAttachmentInd(this.parent))
        return this.createError({ message: notRequiredError });
      return true;
    }),

  attachments: Yup.array().of(
    Yup.object().shape({
      fileName: Yup.string()
        .max(500, 'Must be 500 characters or less')
        .nullable(),
      fileUpload: Yup.mixed().nullable(),
    })
  ),
  requestDate: Yup.date().nullable(),
});
