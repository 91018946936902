import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { createProfileActions, getProfileActions, updateProfileActions, getCountriesActions, getGoverningDistrictsActions } from './profile.actions';
import { ProfileDto, CountryDto, GoverningDistrictDto, profileInit } from './profile.dtos';

export const profileReducer = combineReducers({
  profile: createReducer(profileInit as ProfileDto)
    .handleAction(
      [
        getProfileActions.success,
        updateProfileActions.request,
        updateProfileActions.success,
        createProfileActions.request,
        createProfileActions.success,
      ], (state, action) => action.payload)
});

export const countriesReducer = combineReducers({
  countryList: createReducer([] as CountryDto[])
    .handleAction(
      [
        getCountriesActions.success,
      ], (state, action) => action.payload)
});

export const governingDistrictsReducer = combineReducers({
  governingDistrictList: createReducer([] as GoverningDistrictDto[])
    .handleAction(
      [
        getGoverningDistrictsActions.success,
      ], (state, action) => action.payload)
});