import React from 'react';
import {
  FormikHandlers,
  FormikErrors,
  getIn,
  FormikHelpers,
  FieldArrayRenderProps,
} from 'formik';
// material ui
import { Grid, TextField } from '@material-ui/core';
import { ContactDto, ContactEmployerDto } from '../contacts.dtos';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BusinessUnitDto, CompanyDto } from '../../company/dtos';
import { FormNameIcon, RedRemoveButton } from '../../../common/styles/styles';

interface Props extends React.HTMLProps<React.ReactNode> {
  contactEmployers: ContactEmployerDto;
  errors: FormikErrors<ContactDto>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  index: number;
  setShouldBlockNavigation: (o: boolean) => void;
  employerOptions: string[];
  setFieldValue: FormikHelpers<ContactDto>['setFieldValue'];
  arrayHelpers: FieldArrayRenderProps;
  isReadOnly: boolean;
  companies: CompanyDto[];
}

const ContactEmployers: React.FC<Props> = ({
  contactEmployers,
  errors,
  companies,
  handleBlur,
  index,
  setShouldBlockNavigation,
  employerOptions,
  setFieldValue,
  arrayHelpers,
  isReadOnly,
}) => {
  const handleRemove = () => {
    if (contactEmployers.id === undefined) arrayHelpers.remove(index);
    else {
      setFieldValue(`contactEmployers[${index}].isActive`, false);
      setFieldValue(`contactEmployers[${index}].businessUnits`, []);
    }
  };

  const handleEmployerOnChange = (v: string | null) => {
    if (v === null || !!contactEmployers.id) {
      handleBusinessUnitOnChange([]);
    }
    setFieldValue(`contactEmployers[${index}].name`, v ?? '');
  };

  const handleBusinessUnitOnChange = (v: string[] | null) => {
    if (v === null || v.length === 0) {
      setFieldValue(`contactEmployers[${index}].businessUnits`, []);
    }

    if (v !== null && v !== undefined && v.length !== 0) {
      if (contactEmployers.businessUnits === undefined)
        setFieldValue(
          `contactEmployers[${index}].businessUnits`,
          v
            .filter((f) => f.length !== 0)
            .map((m) => ({ name: m, isActive: true })) as BusinessUnitDto[]
        );
      else {
        let newBusinessUnits: BusinessUnitDto[] = [];

        // get new BusinessUnits
        let newBUs = v
          .filter((f) => f.length !== 0)
          .filter(
            (f) =>
              !(contactEmployers.businessUnits ?? [])
                .map((m) => m.name)
                .includes(f)
          )
          .map((m) => ({ name: m, isActive: true })) as BusinessUnitDto[];

        contactEmployers.businessUnits.forEach((bu) => {
          // check if BU is not in list.
          if (!v.includes(bu.name)) {
            if (bu.id !== undefined) {
              bu.isActive = false;
              newBusinessUnits.push(bu);
            }
          } else {
            bu.isActive = true;
            newBusinessUnits.push(bu);
          }
        });

        newBUs.forEach((x) => newBusinessUnits.push(x));
        setFieldValue(
          `contactEmployers[${index}].businessUnits`,
          newBusinessUnits
        );
        setShouldBlockNavigation(true);
      }
    }
  };

  return (
    <React.Fragment key={'employerFragment' + index}>
      {contactEmployers.isActive && (
        <>
          <Grid
            item
            xs={index === 0 ? 11 : 10}
            key={'employerGrid_Empolyer_' + index}
          >
            <Autocomplete
              id='autoSearch'
              options={employerOptions}
              value={contactEmployers.name || ''}
              disabled={isReadOnly}
              aria-label='Employer Options Autocomplete Options'
              onChange={(_e: any, v: string | null, r: string) => {
                handleEmployerOnChange(v);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={`contactEmployers[${index}].name`}
                  size='small'
                  disabled={isReadOnly}
                  margin='none'
                  aria-label='Employer Options Input'
                  value={contactEmployers.name || ''}
                  label='Employer *'
                  name={`contactEmployers[${index}].name`}
                  onBlur={handleBlur}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                    }
                  }}
                  error={Boolean(
                    getIn(errors, `contactEmployers[${index}].name`)
                  )}
                  helperText={
                    getIn(errors, `contactEmployers[${index}].name`) &&
                    getIn(errors, `contactEmployers[${index}].name`)
                  }
                  data-cy={`contact_employer_${index}_contactEmployerInput`}
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={index === 0 ? 11 : 10} key={'employerGrid_BU' + index}>
            <Autocomplete
              id='autoSearch'
              options={
                companies
                  .find(
                    (o) =>
                      (o.name ?? '').toUpperCase() ===
                      (contactEmployers.name ?? '').toUpperCase()
                  )
                  ?.businessUnitList.map((o) => o.name)
                  .sort((a, b) => a.localeCompare(b)) ?? []
              }
              value={
                contactEmployers.businessUnits
                  ?.filter((f) => f.isActive)
                  .map((m) => m.name) ?? []
              }
              multiple
              disabled={isReadOnly}
              aria-label='Employer Options Autocomplete Options'
              onChange={(_e: any, v: string[] | null, r: string) => {
                handleBusinessUnitOnChange(v);
              }}
              onBlur={handleBlur}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={`contactEmployers[${index}].businessUnits`}
                  size='small'
                  disabled={isReadOnly}
                  margin='none'
                  aria-label='Employer Business Unit Input'
                  value={contactEmployers.businessUnits || []}
                  //onBlur={e =>
                  //  handleBusinessUnitOnChange([...((contactEmployers.businessUnits?.filter(f => f.isActive) ?? [{ name: '' }] as BusinessUnitDto[])
                  //    .map(m => m.name)), (e.currentTarget as HTMLInputElement).value])
                  //}
                  label='Business Unit *'
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                    }
                  }}
                  error={Boolean(
                    getIn(errors, `contactEmployers[${index}].businessUnits`)
                  )}
                  helperText={
                    (getIn(
                      errors,
                      `contactEmployers[${index}].businessUnits[0].name`
                    ) &&
                      getIn(
                        errors,
                        `contactEmployers[${index}].businessUnits[0].name`
                      )) ||
                    (getIn(
                      errors,
                      `contactEmployers[${index}].businessUnits`
                    ) &&
                      getIn(errors, `contactEmployers[${index}].businessUnits`))
                  }
                  data-cy={`contacts_contactEmployers[${index}].businessUnits`}
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            container
            justifyContent='flex-end'
            item
            md={1}
            sm={1}
            xs={1}
            lg={1}
          >
            <span
              hidden={isReadOnly || index === 0}
              style={{ top: 10, right: 25 }}
            >
              <FormNameIcon
                title='Remove from Employer List'
                color='secondary'
                onClick={() => handleRemove()}
              >
                <RedRemoveButton />
              </FormNameIcon>
            </span>
          </Grid>
        </>
      )}
    </React.Fragment>
  );
};

export default ContactEmployers;
