


import { ISelectOption } from "../dtos/SelectOptionDto";
enum ExtendedStateAbrCodes {
        AL = 0,
        AK = 1,
        AZ = 2,
        AR = 3,
        CA = 4,
        CO = 5,
        CT = 6,
        DE = 7,
        DC = 8,
        FL = 9,
        GA = 10,
        HI = 11,
        ID = 12,
        IL = 13,
        IN = 14,
        IA = 15,
        KS = 16,
        KY = 17,
        LA = 18,
        ME = 19,
        MD = 20,
        MA = 21,
        MI = 22,
        MN = 23,
        MS = 24,
        MO = 25,
        MT = 26,
        NE = 27,
        NV = 28,
        NH = 29,
        NJ = 30,
        NM = 31,
        NY = 32,
        NC = 33,
        ND = 34,
        OH = 35,
        OK = 36,
        OR = 37,
        PA = 38,
        RI = 39,
        SC = 40,
        SD = 41,
        TN = 42,
        TX = 43,
        UT = 44,
        VT = 45,
        VA = 46,
        WA = 47,
        WV = 48,
        WI = 49,
        WY = 50,
        PR = 51,
        VI = 52,
        AB = 53,
        BC = 54,
        MB = 55,
        NB = 56,
        NL = 57,
        NT = 58,
        NS = 59,
        NU = 60,
        ON = 61,
        PE = 62,
        QC = 63,
        SK = 64,
        YT = 65,
        AGU = 66,
        BCN = 67,
        BCS = 68,
        CAM = 69,
        CHH = 70,
        CHP = 71,
        COA = 72,
        COL = 73,
        DIF = 74,
        DUR = 75,
        GRO = 76,
        GUA = 77,
        HID = 78,
        JAL = 79,
        MEX = 80,
        MIC = 81,
        MOR = 82,
        NAY = 83,
        NLE = 84,
        OAX = 85,
        PUE = 86,
        QUE = 87,
        ROO = 88,
        SIN = 89,
        SLP = 90,
        SON = 91,
        TAB = 92,
        TAM = 93,
        TLA = 94,
        VER = 95,
        YUC = 96,
        ZAC = 97,
         
}

export class ExtendedStateAbrCodes_class {

public static enum = ExtendedStateAbrCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['AL']: 0,
    ['AK']: 1,
    ['AZ']: 2,
    ['AR']: 3,
    ['CA']: 4,
    ['CO']: 5,
    ['CT']: 6,
    ['DE']: 7,
    ['DC']: 8,
    ['FL']: 9,
    ['GA']: 10,
    ['HI']: 11,
    ['ID']: 12,
    ['IL']: 13,
    ['IN']: 14,
    ['IA']: 15,
    ['KS']: 16,
    ['KY']: 17,
    ['LA']: 18,
    ['ME']: 19,
    ['MD']: 20,
    ['MA']: 21,
    ['MI']: 22,
    ['MN']: 23,
    ['MS']: 24,
    ['MO']: 25,
    ['MT']: 26,
    ['NE']: 27,
    ['NV']: 28,
    ['NH']: 29,
    ['NJ']: 30,
    ['NM']: 31,
    ['NY']: 32,
    ['NC']: 33,
    ['ND']: 34,
    ['OH']: 35,
    ['OK']: 36,
    ['OR']: 37,
    ['PA']: 38,
    ['RI']: 39,
    ['SC']: 40,
    ['SD']: 41,
    ['TN']: 42,
    ['TX']: 43,
    ['UT']: 44,
    ['VT']: 45,
    ['VA']: 46,
    ['WA']: 47,
    ['WV']: 48,
    ['WI']: 49,
    ['WY']: 50,
    ['PR']: 51,
    ['VI']: 52,
    ['AB']: 53,
    ['BC']: 54,
    ['MB']: 55,
    ['NB']: 56,
    ['NL']: 57,
    ['NT']: 58,
    ['NS']: 59,
    ['NU']: 60,
    ['ON']: 61,
    ['PE']: 62,
    ['QC']: 63,
    ['SK']: 64,
    ['YT']: 65,
    ['AGU']: 66,
    ['BCN']: 67,
    ['BCS']: 68,
    ['CAM']: 69,
    ['CHH']: 70,
    ['CHP']: 71,
    ['COA']: 72,
    ['COL']: 73,
    ['DIF']: 74,
    ['DUR']: 75,
    ['GRO']: 76,
    ['GUA']: 77,
    ['HID']: 78,
    ['JAL']: 79,
    ['MEX']: 80,
    ['MIC']: 81,
    ['MOR']: 82,
    ['NAY']: 83,
    ['NLE']: 84,
    ['OAX']: 85,
    ['PUE']: 86,
    ['QUE']: 87,
    ['ROO']: 88,
    ['SIN']: 89,
    ['SLP']: 90,
    ['SON']: 91,
    ['TAB']: 92,
    ['TAM']: 93,
    ['TLA']: 94,
    ['VER']: 95,
    ['YUC']: 96,
    ['ZAC']: 97,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "AL",
    [1]: "AK",
    [2]: "AZ",
    [3]: "AR",
    [4]: "CA",
    [5]: "CO",
    [6]: "CT",
    [7]: "DE",
    [8]: "DC",
    [9]: "FL",
    [10]: "GA",
    [11]: "HI",
    [12]: "ID",
    [13]: "IL",
    [14]: "IN",
    [15]: "IA",
    [16]: "KS",
    [17]: "KY",
    [18]: "LA",
    [19]: "ME",
    [20]: "MD",
    [21]: "MA",
    [22]: "MI",
    [23]: "MN",
    [24]: "MS",
    [25]: "MO",
    [26]: "MT",
    [27]: "NE",
    [28]: "NV",
    [29]: "NH",
    [30]: "NJ",
    [31]: "NM",
    [32]: "NY",
    [33]: "NC",
    [34]: "ND",
    [35]: "OH",
    [36]: "OK",
    [37]: "OR",
    [38]: "PA",
    [39]: "RI",
    [40]: "SC",
    [41]: "SD",
    [42]: "TN",
    [43]: "TX",
    [44]: "UT",
    [45]: "VT",
    [46]: "VA",
    [47]: "WA",
    [48]: "WV",
    [49]: "WI",
    [50]: "WY",
    [51]: "PR",
    [52]: "VI",
    [53]: "AB",
    [54]: "BC",
    [55]: "MB",
    [56]: "NB",
    [57]: "NL",
    [58]: "NT",
    [59]: "NS",
    [60]: "NU",
    [61]: "ON",
    [62]: "PE",
    [63]: "QC",
    [64]: "SK",
    [65]: "YT",
    [66]: "AGU",
    [67]: "BCN",
    [68]: "BCS",
    [69]: "CAM",
    [70]: "CHH",
    [71]: "CHP",
    [72]: "COA",
    [73]: "COL",
    [74]: "DIF",
    [75]: "DUR",
    [76]: "GRO",
    [77]: "GUA",
    [78]: "HID",
    [79]: "JAL",
    [80]: "MEX",
    [81]: "MIC",
    [82]: "MOR",
    [83]: "NAY",
    [84]: "NLE",
    [85]: "OAX",
    [86]: "PUE",
    [87]: "QUE",
    [88]: "ROO",
    [89]: "SIN",
    [90]: "SLP",
    [91]: "SON",
    [92]: "TAB",
    [93]: "TAM",
    [94]: "TLA",
    [95]: "VER",
    [96]: "YUC",
    [97]: "ZAC",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "AL"},
  {value: 1, text: "AK"},
  {value: 2, text: "AZ"},
  {value: 3, text: "AR"},
  {value: 4, text: "CA"},
  {value: 5, text: "CO"},
  {value: 6, text: "CT"},
  {value: 7, text: "DE"},
  {value: 8, text: "DC"},
  {value: 9, text: "FL"},
  {value: 10, text: "GA"},
  {value: 11, text: "HI"},
  {value: 12, text: "ID"},
  {value: 13, text: "IL"},
  {value: 14, text: "IN"},
  {value: 15, text: "IA"},
  {value: 16, text: "KS"},
  {value: 17, text: "KY"},
  {value: 18, text: "LA"},
  {value: 19, text: "ME"},
  {value: 20, text: "MD"},
  {value: 21, text: "MA"},
  {value: 22, text: "MI"},
  {value: 23, text: "MN"},
  {value: 24, text: "MS"},
  {value: 25, text: "MO"},
  {value: 26, text: "MT"},
  {value: 27, text: "NE"},
  {value: 28, text: "NV"},
  {value: 29, text: "NH"},
  {value: 30, text: "NJ"},
  {value: 31, text: "NM"},
  {value: 32, text: "NY"},
  {value: 33, text: "NC"},
  {value: 34, text: "ND"},
  {value: 35, text: "OH"},
  {value: 36, text: "OK"},
  {value: 37, text: "OR"},
  {value: 38, text: "PA"},
  {value: 39, text: "RI"},
  {value: 40, text: "SC"},
  {value: 41, text: "SD"},
  {value: 42, text: "TN"},
  {value: 43, text: "TX"},
  {value: 44, text: "UT"},
  {value: 45, text: "VT"},
  {value: 46, text: "VA"},
  {value: 47, text: "WA"},
  {value: 48, text: "WV"},
  {value: 49, text: "WI"},
  {value: 50, text: "WY"},
  {value: 51, text: "PR"},
  {value: 52, text: "VI"},
  {value: 53, text: "AB"},
  {value: 54, text: "BC"},
  {value: 55, text: "MB"},
  {value: 56, text: "NB"},
  {value: 57, text: "NL"},
  {value: 58, text: "NT"},
  {value: 59, text: "NS"},
  {value: 60, text: "NU"},
  {value: 61, text: "ON"},
  {value: 62, text: "PE"},
  {value: 63, text: "QC"},
  {value: 64, text: "SK"},
  {value: 65, text: "YT"},
  {value: 66, text: "AGU"},
  {value: 67, text: "BCN"},
  {value: 68, text: "BCS"},
  {value: 69, text: "CAM"},
  {value: 70, text: "CHH"},
  {value: 71, text: "CHP"},
  {value: 72, text: "COA"},
  {value: 73, text: "COL"},
  {value: 74, text: "DIF"},
  {value: 75, text: "DUR"},
  {value: 76, text: "GRO"},
  {value: 77, text: "GUA"},
  {value: 78, text: "HID"},
  {value: 79, text: "JAL"},
  {value: 80, text: "MEX"},
  {value: 81, text: "MIC"},
  {value: 82, text: "MOR"},
  {value: 83, text: "NAY"},
  {value: 84, text: "NLE"},
  {value: 85, text: "OAX"},
  {value: 86, text: "PUE"},
  {value: 87, text: "QUE"},
  {value: 88, text: "ROO"},
  {value: 89, text: "SIN"},
  {value: 90, text: "SLP"},
  {value: 91, text: "SON"},
  {value: 92, text: "TAB"},
  {value: 93, text: "TAM"},
  {value: 94, text: "TLA"},
  {value: 95, text: "VER"},
  {value: 96, text: "YUC"},
  {value: 97, text: "ZAC"},
  ]
}

