import React from 'react';
import { FormikHandlers, FormikHelpers } from 'formik';
import { Grid, FormControlLabel } from '@material-ui/core';

import { ClientQuestionnaireDto } from '../dto';
import { radioButtonBooleanOptions } from '../constants/options';
import { MHARadio } from '../../../common/styles/styles';


interface Props extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
}

const NeverEmployedComponent: React.FC<Props> = (
  {
    values,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation,
  }) => {


  return (
    <React.Fragment>
      <Grid item xs={10}>
        <Grid item xs={12}>Was this individual an independent contractor for your organization?</Grid>
        <Grid container item xs={12} direction="row">
          {radioButtonBooleanOptions.map((o, index) => (
            <Grid item xs={3} key={o.name + index}>
              <FormControlLabel
                id="isIndependentContractor"
                name="isIndependentContractor"
                onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                  setFieldValue('isIndependentContractor', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? 1);
                  setShouldBlockNavigation(true);
                }}
                onBlur={handleBlur}
                //add errors for validation
                checked={o.status === values.isIndependentContractor}
                value={o.name}
                control={<MHARadio
                  size="small" />} label={o.name} data-cy="clientQuestionnaire_isIndependentContractor" />
            </Grid>
          ))}
        </Grid>
        <br />
      </Grid>
    </React.Fragment>
  )
}

export default NeverEmployedComponent;
