import React from "react";
import { Formik } from "formik";
import { Dialog, DialogTitle, Grid, DialogActions } from "@material-ui/core";
import { DeleteConfirmationLabel, WideDialogContent } from "../claims.styles";
import {
  ButtonGrid,
  StyledCancelButton,
  StyledSubmitButton,
} from "../../../common/styles/styles";

interface Props {
  open: boolean;
  onCancel: () => void;
  handleConfirm: () => void;
}

const MovePotentialConfirmDialog: React.FC<Props> = ({
  open,
  onCancel,
  handleConfirm,
}) => {
  const cancelDialog = () => onCancel();

  return (
    <Formik
      initialValues={{ confirmation: "" }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setTimeout(() => {
          setSubmitting(false);
          resetForm();
        }, 700);
        if (values.confirmation === "") handleConfirm();
      }}
      enableReinitialize={true}
    >
      {(props) => {
        const { isSubmitting, handleSubmit } = props;
        return (
          <Dialog open={open} data-cy="movePotential_dialog">
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <DialogTitle style={{ marginLeft: 4 }}></DialogTitle>
                <DeleteConfirmationLabel style={{ marginLeft: 30 }}>
                  Should the entire potential move to savings?
                </DeleteConfirmationLabel>
                <WideDialogContent>
                  <form onSubmit={handleSubmit}>
                    <DialogActions>
                      <ButtonGrid container>
                        <StyledSubmitButton
                          type="submit"
                          color="primary"
                          disabled={isSubmitting}
                          data-cy="movepotential_dialog_submitButton"
                        >
                          Yes
                        </StyledSubmitButton>

                        <StyledCancelButton
                          data-cy="movepotential_dialog_cancelButton"
                          color="secondary"
                          onClick={cancelDialog}
                        >
                          No
                        </StyledCancelButton>
                      </ButtonGrid>
                    </DialogActions>
                  </form>
                </WideDialogContent>
              </Grid>
            </Grid>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default MovePotentialConfirmDialog;
