import React from "react";
import { useDispatch } from "react-redux";
import { FieldArray, getIn, Formik, FieldArrayRenderProps } from "formik";
import * as Yup from "yup";
import { getType } from "typesafe-actions";
import { Prompt, useHistory } from "react-router";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import Dropzone from "react-dropzone";
import moment from "moment";
// material ui
import Pagination from "@material-ui/lab/Pagination";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import RefreshIcon from "@material-ui/icons/Refresh";
import {
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  Card,
  Button,
  Paper,
  FormControlLabel,
  Table,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  Link,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { rootSelector } from "../../../common/selectors/selectors";
import { reportedWageTypes } from "../../../common/enums/paymentEnums";
import { getFullName } from "../../../common/dtos/auth";
import EligibilityEventPanel from "../formComponents/eligibilityEventPanel";
import Label from "../../../common/components/label";
import { batchPaymentNotes } from "../options";
import MoneyField from "../../../common/components/numberFields";
import { ClaimDto, ClaimInit } from "../claims.dtos";
import { BatchPaymentsDto } from "../../batchPayments/dtos";
import {
  getClaimPaymentsActions,
  updateClaimPaymentsActions,
} from "../../batchPayments/actions";
import { updateClaimActions } from "../actions";
import BottomActionFormBar from "../../../common/components/bottomActionFormBar";
import { requiredError } from "../../../common/constants/strings";
import {
  CardHeaderDivider1,
  CardContent2,
  DashedBox,
  IndentGrid,
  TMGrid3,
  MHARadio,
  BlackText,
  TextNumberField,
  CardContent1,
} from "../../../common/styles/styles";
import {
  ImportTable,
  TextNumberPaymentField,
  PaymentTF,
  PaymentCard,
  NetPaymentCard,
} from "../claims.styles";
import { FeatureTypeEnum } from "../../../common/enums/featureTypeEnum";

interface Props extends React.HTMLProps<React.ReactNode> {
  isClientUser: boolean;
  isReadOnly: boolean;
}

const BatchPayments: React.FC<Props> = ({ isClientUser, isReadOnly }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const baseUrl = process.env.REACT_APP_API_URL ?? "";
  const downloadDocumentUrl = baseUrl + "download/documents/";
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const systemClaim = rootSelector((state) => state.claims.claim);
  const systemPayments = rootSelector(
    (state) => state.batchPayments.claimPayments
  );

  const [dto, setDto] = React.useState(ClaimInit);
  const [fileNames, setFileNames] = React.useState([] as string[]);
  const [shouldBlockNavigation, setShouldBlockNavigation] =
    React.useState(false);
  const [claimNotesUpdated, setClaimNotesUpdated] = React.useState(false);
  const [documentUrl, setDocumentUrl] = React.useState("" as string | any);
  const [pdfScale, setPdfScale] = React.useState(0.4);
  const [numPages, setNumPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [selectedBatchNoteIndex, setSelectedBatchNoteIndex] = React.useState(
    -1 as number
  );
  const [noteIndex, setNoteIndex] = React.useState(0 as number);
  const [payments, setPayments] = React.useState([] as BatchPaymentsDto[]);

  React.useEffect(() => {
    if (systemClaim.id !== undefined) {
      dispatch({
        type: getType(getClaimPaymentsActions.request),
        payload: systemClaim.id,
      });

      setDto({ ...systemClaim });
      let noteInd = systemClaim.notes?.findIndex((n) => n.type === 4) ?? -1;
      if (noteInd !== -1) setNoteIndex(noteInd);
    }
  }, [dispatch, systemClaim]);

  React.useEffect(() => {
    setPayments(systemPayments);
  }, [systemPayments]);

  function onDocumentLoadSuccess({ numPages }: any) {
    setPageNumber(1);
    setNumPages(numPages);
  }

  const handleCancel = () => {
    history.push("/claims");
  };

  const getChargeValue = (
    charges: BatchPaymentsDto[],
    positiveOnly: boolean | undefined = undefined
  ): number => {
    let c: BatchPaymentsDto[] = JSON.parse(JSON.stringify(charges));
    if (positiveOnly === undefined)
      return c
        .flatMap((f) =>
          f.charges?.map((m) => parseFloat(m.chargeAmount ?? "0.00"))
        )
        .reduce(
          (v, n) =>
            parseFloat((parseFloat(v.toFixed(2)) + n).toFixed(2) ?? "0.00"),
          0.0
        );
    else
      return c
        .flatMap((f) =>
          f.charges?.map((m) => parseFloat(m.chargeAmount ?? "0.00"))
        )
        .filter((c) => (positiveOnly === true ? c > 0 : c < 0))
        .reduce(
          (v, n) =>
            parseFloat((parseFloat(v.toFixed(2)) + n).toFixed(2) ?? "0.00"),
          0.0
        );
  };

  const handleDrop = (
    acceptedFiles: File[],
    arrayHelpers: FieldArrayRenderProps
  ) => {
    let fileList = [] as string[];
    acceptedFiles.forEach((f) => {
      fileList.push(f.name);
      let newBatch: BatchPaymentsDto = {
        fileName: f.name,
        reportedWageTypeId: 0,
        batchPaymentTypeId: 0,
        employerId: dto.employerId,
        charges: [
          {
            claimId: dto.id,
            ssn: dto.claimantSsn ?? "",
            chargeAmount: "",
            reportedWages: "",
          },
        ],
        isComplete: false,
        fileUpload: f,
      };

      arrayHelpers.push(newBatch);
    });
    setFileNames(fileList);
  };

  const handleZoomIn = () => {
    let newScale = pdfScale >= 2 ? 2 : pdfScale + 0.2;
    setPdfScale(newScale);
  };

  const handleZoomOut = () => {
    let newScale = pdfScale <= 0.2 ? 0.2 : pdfScale - 0.2;
    setPdfScale(newScale);
  };

  const handleBatchNoteSelection = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>,
    checked: boolean
  ) => {
    const element = event.target as HTMLInputElement;
    if (element.value !== undefined) {
      const sBatchNoteIndex = batchPaymentNotes.findIndex(
        (note: string) => note === element.value
      );
      if (sBatchNoteIndex > -1) {
        setSelectedBatchNoteIndex(sBatchNoteIndex);
      }
    }
  };

  const handleNoteUpdate = (entry: string) => {
    let newDto = JSON.parse(JSON.stringify(dto)) as ClaimDto;
    newDto.notes?.forEach((n) => {
      if (n.type === 6) {
        n.entry = entry;
      }
    });
    setDto(newDto);
  };

  return (
    <>
      <Formik
        initialValues={{
          creditDue: dto.creditDue,
          creditLastPursuedDate: dto.creditLastPursuedDate,
          creditDueNotes: dto.creditDueNotes,
          payments,
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
          payments: Yup.array().of(
            Yup.object().shape({
              statementDate: Yup.date().required(requiredError),
              reportedWageTypeId: Yup.number()
                .min(1, "Please select reporting type.")
                .max(3)
                .required(requiredError),
              periodCoveredFromDate: Yup.date().nullable(),
              periodCoveredToDate: Yup.date().nullable(),
              charges: Yup.array().of(
                Yup.object().shape({
                  chargeAmount: Yup.string().required(requiredError),
                  chargeDate: Yup.date().required(requiredError),
                  reportedWages: Yup.string(),
                })
              ),
            })
          ),
          creditDue: Yup.boolean(),
          creditLastPursuedDate: Yup.date()
            .typeError("Please enter a valid date.")
            .nullable(),
          creditDueNotes: Yup.string().max(
            2000,
            "Credit Due Notes cannot be more than 2,000 characters."
          ),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            setShouldBlockNavigation(false);
            setClaimNotesUpdated(false);
          }, 700);

          // This if statement was causing issues with claims inconsistently saving Credit Due values.
          // if (
          //   claimNotesUpdated ||
          //   !(
          //     dto.creditDue === values.creditDue ||
          //     dto.creditLastPursuedDate === values.creditLastPursuedDate ||
          //     dto.creditDueNotes === values.creditDueNotes
          //   )
          // ) {
          let request: ClaimDto = {
            ...dto,
            creditDue: values.creditDue,
            creditLastPursuedDate: values.creditLastPursuedDate,
            creditDueNotes: values.creditDueNotes,
          };
          request.notes = request.notes?.filter((n) => n.entry?.length !== 0);

          dispatch({
            type: getType(updateClaimActions.request),
            payload: request,
          });
          // }

          dispatch({
            type: getType(updateClaimPaymentsActions.request),
            payload: values.payments,
          });
        }}
        enableReinitialize={true}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            handleBlur,
            isSubmitting,
          } = props;

          return (
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={8}>
                  <EligibilityEventPanel
                    claim={dto}
                    isClientUser={isClientUser}
                    isBatchPayment={true}
                    isReadOnly={isReadOnly}
                  />
                </Grid>

                <Grid item xs={4}>
                  {!isClientUser && (
                    <Grid item container spacing={2} alignItems="center">
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.creditDue || false}
                              name="creditDue"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.creditDue || false}
                            />
                          }
                          label="Credit Due"
                        />
                      </Grid>

                      <Grid item>
                        <KeyboardDatePicker
                          error={Boolean(
                            touched.creditLastPursuedDate &&
                              errors.creditLastPursuedDate
                          )}
                          format="MM/DD/yyyy"
                          fullWidth
                          helperText={
                            touched.creditLastPursuedDate &&
                            errors.creditLastPursuedDate
                          }
                          InputLabelProps={{ shrink: true }}
                          label="Credit Last Pursued"
                          name="creditLastPursuedDate"
                          onBlur={handleBlur}
                          onChange={(date) => {
                            setFieldValue(
                              "creditLastPursuedDate",
                              date ?? null
                            );
                          }}
                          value={values.creditLastPursuedDate || null}
                        />
                      </Grid>

                      <Grid item xs={11}>
                        <TextField
                          error={Boolean(
                            touched.creditDueNotes && errors.creditDueNotes
                          )}
                          fullWidth
                          helperText={
                            touched.creditDueNotes && errors.creditDueNotes
                          }
                          InputLabelProps={{ shrink: true }}
                          label="Credit Due Notes"
                          maxRows={4}
                          minRows={4}
                          multiline
                          name="creditDueNotes"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.creditDueNotes}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <FieldArray
                name="payments"
                render={(arrayHelpers) => (
                  <>
                    <Card>
                      <CardHeaderDivider1
                        title={
                          <>
                            <BlackText>
                              <Typography variant="subtitle1">
                                Upload Documents
                              </Typography>
                            </BlackText>
                          </>
                        }
                      ></CardHeaderDivider1>
                      <CardContent2>
                        <div
                          hidden={isReadOnly === true || isClientUser === true}
                          data-cy="dropZone_div"
                        >
                          <Grid container justifyContent="center">
                            <Dropzone
                              aria-label="File drop zone"
                              onDrop={(a) => {
                                handleDrop(a, arrayHelpers);
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <DashedBox
                                  container
                                  item
                                  xs={9}
                                  justifyContent="center"
                                  {...getRootProps({ className: "dropzone" })}
                                >
                                  <input
                                    aria-label="File input area"
                                    {...getInputProps()}
                                  />
                                  <Typography variant="h5" display="block">
                                    Drag your file here or click to select
                                  </Typography>
                                </DashedBox>
                              )}
                            </Dropzone>
                          </Grid>
                        </div>
                        <Grid container justifyContent="center">
                          <div>
                            <ul>
                              {fileNames.map((fileName) => (
                                <li key={fileName}>{fileName}</li>
                              ))}
                            </ul>
                          </div>
                        </Grid>
                      </CardContent2>
                    </Card>

                    <Grid container spacing={2}>
                      <IndentGrid container justifyContent="center">
                        {documentUrl.length === 0 ? (
                          <Paper
                            style={{
                              height: 200,
                              width: "100%",
                              marginLeft: 4,
                              backgroundColor: "lightgray",
                              marginTop: 16,
                            }}
                            elevation={3}
                          >
                            <Grid container justifyContent="center">
                              <span
                                style={{
                                  marginTop: 84,
                                  fontSize: 32,
                                  fontWeight: 400,
                                }}
                              >
                                PDF VIEWER
                              </span>
                            </Grid>
                          </Paper>
                        ) : (
                          <>
                            <TMGrid3
                              container
                              item
                              xs={12}
                              justifyContent="center"
                              hidden={numPages === 0}
                            >
                              <Button
                                title="Reset Zoom"
                                color="secondary"
                                onClick={() => setPdfScale(0.4)}
                                data-cy="claim_pdf_zoomIn"
                              >
                                <RefreshIcon />
                              </Button>
                            </TMGrid3>

                            <TMGrid3
                              container
                              item
                              xs={12}
                              justifyContent="center"
                              hidden={numPages === 0}
                            >
                              <Button
                                title="Zoom in pdf"
                                color="secondary"
                                onClick={handleZoomOut}
                                data-cy="claim_pdf_zoomOut"
                              >
                                <ZoomOutIcon />
                              </Button>
                              <Pagination
                                page={pageNumber}
                                count={numPages}
                                variant="outlined"
                                color="primary"
                                size="small"
                                onChange={(e, p) => {
                                  setPageNumber(p);
                                }}
                              />
                              <Button
                                title="Zoom in pdf"
                                color="secondary"
                                onClick={handleZoomIn}
                                data-cy="claim_pdf_zoomIn"
                              >
                                <ZoomInIcon />
                              </Button>
                            </TMGrid3>
                            <Grid item>
                              <Paper
                                style={{
                                  maxHeight: 600,
                                  width: "100%",
                                  maxWidth: window.parent.innerWidth - 252,
                                  overflow: "auto",
                                  marginTop: 16,
                                }}
                                elevation={3}
                              >
                                <Grid item xs={12}>
                                  <Document
                                    file={documentUrl}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                  >
                                    <Page
                                      pageNumber={pageNumber}
                                      width={window.parent.innerWidth - 124}
                                      scale={pdfScale}
                                    />
                                  </Document>
                                </Grid>
                              </Paper>
                            </Grid>
                          </>
                        )}
                      </IndentGrid>
                    </Grid>

                    <ImportTable>
                      <Grid container spacing={2}>
                        <IndentGrid item xs={12}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            display="block"
                          >
                            Claim Payments
                          </Typography>
                        </IndentGrid>
                        <Grid item xs={8}>
                          <IndentGrid container direction="row" spacing={2}>
                            <Grid item xs={3}>
                              <BlackText>
                                <Typography variant="subtitle1">
                                  Total Charges
                                </Typography>
                              </BlackText>
                              <PaymentCard raised>
                                <TextNumberField
                                  id="Total Charges"
                                  size="small"
                                  disabled={isClientUser || isReadOnly || true}
                                  margin="none"
                                  value={getChargeValue(values.payments, true)}
                                  fullWidth
                                  InputProps={{
                                    inputComponent: MoneyField as any,
                                  }}
                                  data-cy="claim_payments_totalCharges"
                                />
                              </PaymentCard>
                            </Grid>
                            <Grid item xs={3}>
                              <BlackText>
                                <Typography variant="subtitle1">
                                  Total Credits
                                </Typography>
                              </BlackText>
                              <PaymentCard raised>
                                <TextNumberField
                                  id="Total Credits"
                                  size="small"
                                  disabled={isClientUser || isReadOnly || true}
                                  margin="none"
                                  value={getChargeValue(values.payments, false)}
                                  fullWidth
                                  InputProps={{
                                    inputComponent: MoneyField as any,
                                  }}
                                  data-cy="claim_payments_totalCredits"
                                />
                              </PaymentCard>
                            </Grid>
                            <Grid item xs={3}>
                              <BlackText>
                                <Typography variant="subtitle1">
                                  Net Charges
                                </Typography>
                              </BlackText>
                              <NetPaymentCard raised>
                                <TextNumberField
                                  id="Net Charges"
                                  size="small"
                                  disabled={isClientUser || isReadOnly || true}
                                  margin="none"
                                  value={getChargeValue(values.payments)}
                                  fullWidth
                                  InputProps={{
                                    inputComponent: MoneyField as any,
                                  }}
                                  data-cy="claim_payments_netCharges"
                                />
                              </NetPaymentCard>
                            </Grid>
                            <Grid item xs={3}>
                              <BlackText>
                                <Typography variant="subtitle1">
                                  Actual Liability
                                </Typography>
                              </BlackText>
                              <NetPaymentCard raised>
                                <TextNumberField
                                  id="Net Charges"
                                  size="small"
                                  disabled={isClientUser || isReadOnly || true}
                                  margin="none"
                                  value={dto.actualLiabilityAmount || "0.00"}
                                  fullWidth
                                  InputProps={{
                                    inputComponent: MoneyField as any,
                                  }}
                                  data-cy="claim_payments_netCharges"
                                />
                              </NetPaymentCard>
                            </Grid>
                          </IndentGrid>

                          <br />
                          <TableContainer>
                            <Table
                              size="small"
                              aria-label="Employer Import History Table"
                              style={{ width: "100%" }}
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">
                                    Statement Date
                                  </TableCell>
                                  <TableCell align="center">
                                    Charge Date
                                  </TableCell>
                                  <TableCell align="center">
                                    Period Covered From
                                  </TableCell>
                                  <TableCell align="center">
                                    Period Covered To
                                  </TableCell>
                                  <TableCell align="center">
                                    Reporting Type
                                  </TableCell>
                                  <TableCell align="center">Amount</TableCell>
                                  <TableCell align="center">
                                    Reported Wages
                                  </TableCell>
                                  <TableCell align="center">
                                    Document View
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {values.payments
                                  .sort((a, b) =>
                                    moment(a.statementDate).diff(
                                      moment(b.statementDate)
                                    )
                                  )
                                  .map((o, index) =>
                                    o.charges.map((c, i) => (
                                      <TableRow
                                        key={"dateReceived_" + index + "_" + i}
                                      >
                                        <TableCell
                                          padding="checkbox"
                                          component="th"
                                          scope="row"
                                          align="center"
                                        >
                                          <KeyboardDatePicker
                                            margin="normal"
                                            id={`payments[${index}].statementDate`}
                                            name={`payments[${index}].statementDate`}
                                            format="MM/DD/yyyy"
                                            disabled={
                                              isReadOnly === true ||
                                              isClientUser === true
                                            }
                                            // value={o.statementDate}
                                            value={
                                              o.statementDate
                                                ? moment.utc(o.statementDate)
                                                : null
                                            }
                                            fullWidth
                                            onBlur={(e) => {
                                              handleBlur(e);
                                              setShouldBlockNavigation(true);
                                            }}
                                            onChange={(
                                              date: MaterialUiPickersDate
                                            ) =>
                                              setFieldValue(
                                                `payments[${index}].statementDate`,
                                                date
                                              )
                                            }
                                            KeyboardButtonProps={{
                                              "aria-label": "date received",
                                            }}
                                            error={Boolean(
                                              getIn(
                                                errors,
                                                `payments[${index}].statementDate`
                                              )
                                            )}
                                            helperText={
                                              Boolean(
                                                getIn(
                                                  errors,
                                                  `payments[${index}].statementDate`
                                                )
                                              ) &&
                                              getIn(
                                                errors,
                                                `payments[${index}].statementDate`
                                              )
                                            }
                                          />
                                        </TableCell>

                                        <TableCell
                                          padding="checkbox"
                                          component="th"
                                          scope="row"
                                          align="center"
                                        >
                                          <KeyboardDatePicker
                                            margin="normal"
                                            id={`payments[${index}].charges[${i}].chargeDate`}
                                            name={`payments[${index}].charges[${i}].chargeDate`}
                                            format="MM/DD/yyyy"
                                            disabled={
                                              isReadOnly === true ||
                                              isClientUser === true
                                            }
                                            value={
                                              o.charges === undefined
                                                ? null
                                                : o.charges.length !== 0
                                                ? moment.utc(
                                                    o.charges[i].chargeDate
                                                  )
                                                : null
                                            }
                                            fullWidth
                                            onBlur={(e) => {
                                              handleBlur(e);
                                              setShouldBlockNavigation(true);
                                            }}
                                            onChange={(
                                              date: MaterialUiPickersDate
                                            ) =>
                                              setFieldValue(
                                                `payments[${index}].charges[${i}].chargeDate`,
                                                date
                                              )
                                            }
                                            KeyboardButtonProps={{
                                              "aria-label": "date received",
                                            }}
                                            error={Boolean(
                                              getIn(
                                                errors,
                                                `payments[${index}].charges[${i}].chargeDate`
                                              )
                                            )}
                                            helperText={
                                              Boolean(
                                                getIn(
                                                  errors,
                                                  `payments[${index}].charges[${i}].chargeDate`
                                                )
                                              ) &&
                                              getIn(
                                                errors,
                                                `payments[${index}].charges[${i}].chargeDate`
                                              )
                                            }
                                          />
                                        </TableCell>

                                        <TableCell
                                          padding="checkbox"
                                          component="th"
                                          scope="row"
                                          align="center"
                                        >
                                          <KeyboardDatePicker
                                            margin="normal"
                                            id={`payments[${index}].periodCoveredFromDate`}
                                            name={`payments[${index}].periodCoveredFromDate`}
                                            format="MM/DD/yyyy"
                                            disabled={
                                              isReadOnly === true ||
                                              isClientUser === true
                                            }
                                            value={
                                              o.periodCoveredFromDate
                                                ? moment.utc(
                                                    o.periodCoveredFromDate
                                                  )
                                                : null
                                            }
                                            fullWidth
                                            onBlur={(e) => {
                                              handleBlur(e);
                                              setShouldBlockNavigation(true);
                                            }}
                                            onChange={(
                                              date: MaterialUiPickersDate
                                            ) =>
                                              setFieldValue(
                                                `payments[${index}].periodCoveredFromDate`,
                                                date
                                              )
                                            }
                                            KeyboardButtonProps={{
                                              "aria-label": "date received",
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          padding="checkbox"
                                          component="th"
                                          scope="row"
                                          align="center"
                                        >
                                          <KeyboardDatePicker
                                            margin="normal"
                                            id={`payments[${index}].periodCoveredToDate`}
                                            name={`payments[${index}].periodCoveredToDate`}
                                            format="MM/DD/yyyy"
                                            disabled={
                                              isReadOnly === true ||
                                              isClientUser === true
                                            }
                                            value={
                                              o.periodCoveredToDate
                                                ? moment.utc(
                                                    o.periodCoveredToDate
                                                  )
                                                : null
                                            }
                                            fullWidth
                                            onBlur={(e) => {
                                              handleBlur(e);
                                              setShouldBlockNavigation(true);
                                            }}
                                            onChange={(
                                              date: MaterialUiPickersDate
                                            ) =>
                                              setFieldValue(
                                                `payments[${index}].periodCoveredToDate`,
                                                date
                                              )
                                            }
                                            KeyboardButtonProps={{
                                              "aria-label": "date received",
                                            }}
                                          />
                                        </TableCell>
                                        <TableCell
                                          padding="checkbox"
                                          component="th"
                                          scope="row"
                                          align="center"
                                        >
                                          <PaymentTF
                                            id={`payments[${index}].reportedWageTypeId`}
                                            size="small"
                                            margin="none"
                                            select
                                            value={o.reportedWageTypeId}
                                            name={`payments[${index}].reportedWageTypeId`}
                                            onChange={(e) => {
                                              handleChange(e);
                                              setShouldBlockNavigation(true);
                                            }}
                                            onBlur={(e) => {
                                              handleBlur(e);
                                              setShouldBlockNavigation(true);
                                            }}
                                            disabled={
                                              isReadOnly === true ||
                                              isClientUser === true
                                            }
                                            error={Boolean(
                                              getIn(
                                                errors,
                                                `payments[${index}].reportedWageTypeId`
                                              )
                                            )}
                                            helperText={
                                              Boolean(
                                                getIn(
                                                  errors,
                                                  `payments[${index}].reportedWageTypeId`
                                                )
                                              ) &&
                                              getIn(
                                                errors,
                                                `payments[${index}].reportedWageTypeId`
                                              )
                                            }
                                            fullWidth
                                          >
                                            {reportedWageTypes
                                              .filter(
                                                (option) => option.name !== ""
                                              )
                                              .map((option, index) => (
                                                <MenuItem
                                                  key={option.name + index}
                                                  value={option.id}
                                                >
                                                  {option.name}
                                                </MenuItem>
                                              ))}
                                          </PaymentTF>
                                        </TableCell>

                                        <TableCell
                                          padding="checkbox"
                                          component="th"
                                          scope="row"
                                          align="center"
                                        >
                                          <TextNumberPaymentField
                                            id={`payments[${index}].charges[${i}].chargeAmount`}
                                            size="small"
                                            disabled={
                                              isClientUser || isReadOnly
                                            }
                                            margin="none"
                                            value={
                                              o.charges === undefined
                                                ? ""
                                                : o.charges.length !== 0
                                                ? o.charges[i].chargeAmount
                                                : ""
                                            }
                                            name={`payments[${index}].charges[${i}].chargeAmount`}
                                            onChange={(e) => {
                                              handleChange(e);
                                            }}
                                            onBlur={(e) => {
                                              handleBlur(e);
                                              setShouldBlockNavigation(true);
                                            }}
                                            error={Boolean(
                                              getIn(
                                                errors,
                                                `payments[${index}].charges[${i}].chargeAmount`
                                              ) &&
                                                getIn(
                                                  touched,
                                                  `payments[${index}].charges[${i}].chargeAmount`
                                                )
                                            )}
                                            helperText={
                                              Boolean(
                                                getIn(
                                                  errors,
                                                  `payments[${index}].charges[${i}].chargeAmount`
                                                )
                                              ) &&
                                              Boolean(
                                                getIn(
                                                  touched,
                                                  `payments[${index}].charges[${i}].chargeAmount`
                                                )
                                              ) &&
                                              getIn(
                                                errors,
                                                `payments[${index}].charges[${i}].chargeAmount`
                                              )
                                            }
                                            fullWidth
                                            InputProps={{
                                              inputComponent: MoneyField as any,
                                            }}
                                            //data-cy="claim_payment_chargeAmount"
                                          />
                                        </TableCell>

                                        <TableCell
                                          padding="checkbox"
                                          component="th"
                                          scope="row"
                                          align="center"
                                        >
                                          <TextNumberPaymentField
                                            id={`payments[${index}].charges[${i}].reportedWages`}
                                            size="small"
                                            disabled={
                                              isClientUser || isReadOnly
                                            }
                                            margin="none"
                                            value={
                                              o.charges === undefined
                                                ? null
                                                : o.charges.length !== 0
                                                ? o.charges[i].reportedWages
                                                : null
                                            }
                                            name={`payments[${index}].charges[${i}].reportedWages`}
                                            onChange={(e) => {
                                              handleChange(e);
                                            }}
                                            onBlur={(e) => {
                                              handleBlur(e);
                                              setShouldBlockNavigation(true);
                                            }}
                                            error={Boolean(
                                              getIn(
                                                errors,
                                                `payments[${index}].charges[${i}].reportedWages`
                                              ) &&
                                                getIn(
                                                  touched,
                                                  `payments[${index}].charges[${i}].reportedWages`
                                                )
                                            )}
                                            helperText={
                                              Boolean(
                                                getIn(
                                                  errors,
                                                  `payments[${index}].charges[${i}].reportedWages`
                                                )
                                              ) &&
                                              Boolean(
                                                getIn(
                                                  touched,
                                                  `payments[${index}].charges[${i}].reportedWages`
                                                )
                                              ) &&
                                              getIn(
                                                errors,
                                                `payments[${index}].charges[${i}].reportedWages`
                                              )
                                            }
                                            fullWidth
                                            InputProps={{
                                              inputComponent: MoneyField as any,
                                            }}
                                            //data-cy="claim_payment_reportedWages"
                                          />
                                        </TableCell>

                                        <TableCell
                                          padding="checkbox"
                                          component="th"
                                          scope="row"
                                          align="center"
                                        >
                                          {o.id !== undefined &&
                                          o.id.length !== 0 ? (
                                            i === 0 ? (
                                              <Link
                                                component={Button}
                                                onClick={() =>
                                                  setDocumentUrl(
                                                    downloadDocumentUrl +
                                                      encodeURIComponent(
                                                        o.fileName ?? ""
                                                      )
                                                  )
                                                }
                                                color="primary"
                                              >
                                                View
                                              </Link>
                                            ) : null
                                          ) : (
                                            <Link
                                              component={Button}
                                              onClick={() =>
                                                setDocumentUrl(o.fileUpload)
                                              }
                                              color="primary"
                                            >
                                              View
                                            </Link>
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  )}
                              </TableBody>
                            </Table>
                            <br />
                            <br />
                          </TableContainer>
                        </Grid>
                        <Grid item xs={3}>
                          <Card>
                            <CardContent1>
                              <Label>Notes</Label>
                              <Box>
                                <br></br>
                                <IndentGrid container item xs={12} sm={12}>
                                  {batchPaymentNotes.map((o, index) => (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      key={o + index}
                                      hidden={
                                        isClientUser === true ||
                                        isReadOnly === true
                                      }
                                    >
                                      <FormControlLabel
                                        checked={
                                          index === selectedBatchNoteIndex
                                        }
                                        value={o}
                                        onBlur={(e) => {
                                          handleBlur(e);
                                          setShouldBlockNavigation(true);
                                        }}
                                        onChange={(e, c) => {
                                          setClaimNotesUpdated(true);
                                          handleBatchNoteSelection(e, c);
                                          let noteIndex =
                                            dto.notes?.findIndex(
                                              (n) => n.type === 6
                                            ) ?? -1;
                                          if (noteIndex !== -1) {
                                            let newNotes =
                                              dto.notes === undefined
                                                ? ""
                                                : dto.notes![noteIndex]
                                                    ?.entry ?? "";
                                            let newNote = "";
                                            newNote += (
                                              e.target as HTMLInputElement
                                            ).value;
                                            newNote += "\xa0 \xa0";
                                            newNote += "- \xa0" + getFullName();
                                            newNote += "\xa0 \xa0";
                                            newNote +=
                                              "-\xa0" +
                                              new Date().toLocaleString();
                                            if (newNotes.length !== 0) {
                                              newNote += "\n";
                                              newNote += "\n";
                                            }
                                            newNotes = newNote + newNotes;
                                            handleNoteUpdate(newNotes);
                                          }
                                        }}
                                        control={
                                          <MHARadio
                                            disabled={
                                              isClientUser || isReadOnly
                                            }
                                            size="small"
                                          />
                                        }
                                        label={o}
                                        data-cy="claimNotes_CannedNoteGroup1"
                                      />
                                    </Grid>
                                  ))}
                                  <Grid item xs={12}>
                                    {dto.notes &&
                                      dto.notes[0] &&
                                      dto.notes
                                        .filter((n) => n.type === 6)
                                        .map((o, index) => (
                                          <Card
                                            key={"notesFragmentText" + index}
                                          >
                                            <CardContent>
                                              <TextField
                                                id={`notes[${noteIndex}].entry`}
                                                size="small"
                                                margin="none"
                                                value={o.entry || ""}
                                                label="Notes"
                                                name={`notes[${noteIndex}].entry`}
                                                onChange={(e) => {
                                                  handleNoteUpdate(
                                                    (
                                                      e.target as HTMLInputElement
                                                    ).value
                                                  );
                                                  setClaimNotesUpdated(true);
                                                }}
                                                multiline
                                                disabled={
                                                  isClientUser || isReadOnly
                                                }
                                                onBlur={(e) => {
                                                  handleBlur(e);
                                                  setShouldBlockNavigation(
                                                    true
                                                  );
                                                }}
                                                data-cy={`claim_notes_${noteIndex}_notesInput`}
                                                fullWidth
                                              />
                                            </CardContent>
                                          </Card>
                                        ))}
                                  </Grid>
                                </IndentGrid>
                              </Box>
                            </CardContent1>
                          </Card>
                        </Grid>
                      </Grid>
                    </ImportTable>

                    <BottomActionFormBar
                      cancelClickHandler={handleCancel}
                      submitting={isSubmitting}
                      featureType={FeatureTypeEnum.Payments}
                    />
                    <Prompt
                      when={shouldBlockNavigation}
                      message="You have unsaved edits, are you sure you want to leave?"
                    />
                  </>
                )}
              />
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default BatchPayments;
