import React from "react";

// material ui
import { Grid, Link, Button } from "@material-ui/core";

import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import * as Style from "../claims.styles";

import { ContactInformationDto } from "../../../common/dtos/contactInformation";

interface HearingContactDetailProp extends React.HTMLProps<React.ReactNode> {
  contact: ContactInformationDto;
  index: number;
  isReadOnly: boolean;
  isClientUser: boolean;
  handleEmailPrompt: (email: string) => void;
}

const HearingContactDetails: React.FC<HearingContactDetailProp> = (
  props: HearingContactDetailProp
) => {
  const { contact, index, handleEmailPrompt, isReadOnly, isClientUser } = props;

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handlePanelChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      <Style.ClearAccordion
        expanded={expanded === "panel4"}
        onChange={handlePanelChange("panel4")}
      >
        <Style.AccordionSummaryBgWhite
          expandIcon={<ExpandMoreIcon />}
          aria-controls="Hearing Contact"
          id="panel1bh-header"
        >
          {isClientUser === true || isReadOnly === true ? null : (
            <>
              <Style.HearingContactName variant="button">
                {contact.fullName}
              </Style.HearingContactName>
              <Link
                component={Button}
                title="Mail Hearing Contact"
                onClick={() => {
                  handleEmailPrompt(contact.primaryEmail ?? "");
                }}
              >
                <Style.MHAMailicon />
              </Link>
            </>
          )}
        </Style.AccordionSummaryBgWhite>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Style.DisabledHearingTextField
                id={"contact_fullName_" + index}
                size="small"
                margin="none"
                value={contact.primaryEmail || ""}
                label="Email Address"
                name="contact_fullName"
                fullWidth
                disabled
                data-cy="claim_hearingContact_fullName"
              />
            </Grid>

            <Grid item xs={12}>
              <Style.DisabledHearingTextField
                id={"contact_workPhone" + index}
                size="small"
                margin="none"
                value={contact.workPhoneNumber || ""}
                label="Phone Number - Work"
                name="contact_workPhone"
                fullWidth
                data-cy="claim_hearingContact_workPhone"
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Style.DisabledHearingTextField
                id={"contact_homePhone" + index}
                size="small"
                margin="none"
                value={contact.homePhoneNumber || ""}
                label="Phone Number - Home"
                name="contact_homePhone"
                data-cy="claim_hearingContact_homePhoneNumber"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Style.DisabledHearingTextField
                id={"contact_fax" + index}
                size="small"
                margin="none"
                value={contact.faxPhoneNumber || ""}
                label="Fax Number"
                name="contact_fax"
                data-cy="claim_hearingContact_fax"
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <Style.DisabledHearingTextField
                id={"contact_motes" + index}
                size="small"
                margin="none"
                multiline
                value={contact.notes || ""}
                label="Notes"
                name="contact_notes"
                data-cy="claim_hearingContact_notes"
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Style.ClearAccordion>
    </>
  );
};
export default HearingContactDetails;
