import React from 'react';
import { FormikHandlers, FormikErrors, FormikHelpers } from 'formik';
import { Grid, CardContent} from '@material-ui/core';
import { NavCard, NavOptionsGrid } from '../../documents.styles';
import { ISi, ISi_PropertyAttributes } from '../../../../common/dtos/SiDto';
import SiSelectOptions from '../siFieldComponents/SiSelectOptions';
import { VoluntarySepReasonCodes_class } from '../../../../common/enums/VoluntarySepReasonCodes';
import SiTextAreaField from '../siFieldComponents/SiTextAreaField';
import IndicatorTypeField from '../siFieldComponents/IndicatorTypeField';
import { HiringChangesCodes_class } from '../../../../common/enums/HiringChangesCodes';
import SiDateField from '../siFieldComponents/SiDateField';
import { 
  isClaimantNoticeInd, isClaimantNotifyOfMedicalIssue, isClaimantPaidThroughNoticeInd, isClaimantProvidedIntendedLastDayWork, isClaimantProvidedIntendedLastDayWorkUnknown, 
  isClaimantWorkOutNoticeInd, isDateNoticeGiven, isDateNoticeGivenUnknown, isWhyNoticeWasntWorkedOut, isChangesPermanentInd, isChangesScheduledToLast, isClaimantContactToExplainInd,
  isClaimantRequestMedicalLoa, isClaimantWorkRestrictions, isDateFailedToReport, isDateFailedtoReportUnknown, isDateMadeContact, isDutiesClaimantCouldNotPerform, isInLieuInd,
  isLoaapprovedInd, isMandatoryRetirementInd, isMedicalLoaavailable, isMedicalStatementProvidedInd, isOtherWorkOfferedInd, isQuitUnderAdviceOfHealthcareProvider, isReasonDissatisfied,
  isReasonForFailToReport, isWhyLoadenied, isWhyRetireComments, isWorkAvailableWithinRestrictionsInd, isWorkingConditions, isWorkingConditionsChangedInd, isWorkRestrictionsInd, opEmployerAccommodateRestrictionsInd, opWhyEmployerCouldNotAccommodate, isClaimantActionsToAvoidQuitInd, isActionTakenComments, opWhyRetireComments, isContinuingWorkAvailableInd, opContinuingWorkAvailableInd
} from '../../../../utils';
import IndicatorTypeWithUnknownField from '../siFieldComponents/IndicatorTypeWithUnknownField';
import IndicatorTypeYField from '../siFieldComponents/IndicatorTypeYField';
import SiTextField from '../siFieldComponents/SiTextField';


interface Props extends React.HTMLProps<React.ReactNode> {
  values: ISi,
  errors: FormikErrors<ISi>,
  handleChange: FormikHandlers["handleChange"],
  handleBlur: FormikHandlers["handleBlur"],
  setFieldValue: FormikHelpers<ISi>["setFieldValue"],
  isReadOnly: boolean,
}


const SiVoluntaryQuit: React.FC<Props> = (
  {
    values,
    errors,
    handleChange,
    isReadOnly,
    handleBlur,
    setFieldValue,
  }) => {
  //React.useEffect(() => { console.log('errors', errors); }, [errors]);

  React.useEffect(() => {
    if (!isClaimantNotifyOfMedicalIssue(values) && values.claimantNotifyOfMedicalIssue !== undefined) setFieldValue('claimantNotifyOfMedicalIssue', undefined);
    if (!isClaimantRequestMedicalLoa(values) && values.claimantRequestMedicalLoa !== undefined) setFieldValue('claimantRequestMedicalLoa', undefined);
    if (!isMedicalLoaavailable(values) && values.medicalLoaavailable !== undefined) setFieldValue('medicalLoaavailable', undefined);
    if (!isLoaapprovedInd(values) && values.loaapprovedInd !== undefined) setFieldValue('loaapprovedInd', undefined);
    if (!isWhyLoadenied(values) && values.whyLoadenied !== undefined) setFieldValue('whyLoadenied', undefined);
    if (!isQuitUnderAdviceOfHealthcareProvider(values) && values.quitUnderAdviceOfHealthcareProvider !== undefined) setFieldValue('quitUnderAdviceOfHealthcareProvider', undefined);
    if (!isMedicalStatementProvidedInd(values) && values.medicalStatementProvidedInd !== undefined) setFieldValue('medicalStatementProvidedInd', undefined);
    if (!isDutiesClaimantCouldNotPerform(values) && values.dutiesClaimantCouldNotPerform !== undefined) setFieldValue('dutiesClaimantCouldNotPerform', undefined);
    if (!isWorkRestrictionsInd(values) && values.workRestrictionsInd !== undefined) setFieldValue('workRestrictionsInd', undefined);
    if (!isClaimantWorkRestrictions(values) && values.claimantWorkRestrictions !== undefined) setFieldValue('claimantWorkRestrictions', undefined);
    if (!isWorkAvailableWithinRestrictionsInd(values) && values.workAvailableWithinRestrictionsInd !== undefined) setFieldValue('workAvailableWithinRestrictionsInd', undefined);
    if (!isOtherWorkOfferedInd(values) && values.otherWorkOfferedInd !== undefined) setFieldValue('otherWorkOfferedInd', undefined);
    if (!isReasonDissatisfied(values) && values.reasonDissatisfied !== undefined) setFieldValue('reasonDissatisfied', undefined);
    if (!isWorkingConditions(values) && values.workingConditions !== undefined) setFieldValue('workingConditions', undefined);
    if (!isWorkingConditionsChangedInd(values) && values.workingConditionsChangedInd !== undefined) setFieldValue('workingConditionsChangedInd', undefined);
    if (!isChangesPermanentInd(values) && values.changesPermanentInd !== undefined) setFieldValue('changesPermanentInd', undefined);
    if (!isChangesScheduledToLast(values) && values.changesScheduledToLast !== undefined) setFieldValue('changesScheduledToLast', undefined);
    if (!isInLieuInd(values) && values.inLieuInd !== undefined) setFieldValue('inLieuInd', undefined);
    if (!isDateFailedToReport(values, 0) && values.uisSiFailedToReportOccurrences && values.uisSiFailedToReportOccurrences[0]?.dateFailedToReport !== undefined) setFieldValue('uisSiFailedToReportOccurrences[0].dateFailedToReport', undefined);
    if (!isDateFailedtoReportUnknown(values, 0) && values.uisSiFailedToReportOccurrences && values.uisSiFailedToReportOccurrences[0]?.dateFailedtoReportUnknown !== undefined) setFieldValue('uisSiFailedToReportOccurrences[0].dateFailedtoReportUnknown', undefined);
    if (!isDateFailedtoReportUnknown(values, 0) && !isDateFailedToReport(values, 0) && values.uisSiFailedToReportOccurrences && values.uisSiFailedToReportOccurrences.length !== 0) setFieldValue('uisSiFailedToReportOccurrences', []);
    if (!isClaimantContactToExplainInd(values) && values.claimantContactToExplainInd !== undefined) setFieldValue('claimantContactToExplainInd', undefined);
    if (!isDateMadeContact(values) && values.dateMadeContact !== undefined) setFieldValue('dateMadeContact', undefined);
    if (!isReasonForFailToReport(values) && values.reasonForFailToReport !== undefined) setFieldValue('reasonForFailToReport', undefined);
    if (!isMandatoryRetirementInd(values) && values.mandatoryRetirementInd !== undefined) setFieldValue('mandatoryRetirementInd', undefined);
    if (!isWhyRetireComments(values) && !opWhyRetireComments(values) && values.whyRetireComments !== undefined) setFieldValue('whyRetireComments', undefined);
    if (!isClaimantNoticeInd(values) && values.claimantNoticeInd !== undefined) setFieldValue('claimantNoticeInd', undefined);
    if (!isDateNoticeGiven(values) && values.dateNoticeGiven !== undefined) setFieldValue('dateNoticeGiven', undefined);
    if (!isDateNoticeGivenUnknown(values) && values.dateNoticeGivenUnknown !== undefined) setFieldValue('dateNoticeGivenUnknown', undefined);
    if (!isClaimantProvidedIntendedLastDayWork(values) && values.claimantProvidedIntendedLastDayWork !== undefined) setFieldValue('claimantProvidedIntendedLastDayWork', undefined);
    if (!isClaimantProvidedIntendedLastDayWorkUnknown(values) && values.claimantProvidedIntendedLastDayWorkUnknown !== undefined) setFieldValue('claimantProvidedIntendedLastDayWorkUnknown', undefined);
    if (!isClaimantWorkOutNoticeInd(values) && values.claimantWorkOutNoticeInd !== undefined) setFieldValue('claimantWorkOutNoticeInd', undefined);
    if (!isWhyNoticeWasntWorkedOut(values) && values.whyNoticeWasntWorkedOut !== undefined) setFieldValue('whyNoticeWasntWorkedOut', undefined);
    if (!isClaimantPaidThroughNoticeInd(values) && values.claimantPaidThroughNoticeInd !== undefined) setFieldValue('claimantPaidThroughNoticeInd', undefined);
    if (!opEmployerAccommodateRestrictionsInd(values) && values.employerAccommodateRestrictionsInd !== undefined) setFieldValue('employerAccommodateRestrictionsInd', undefined);
    if (!opWhyEmployerCouldNotAccommodate(values) && values.whyEmployerCouldNotAccommodate !== undefined) setFieldValue('whyEmployerCouldNotAccommodate', undefined);
    if (!isClaimantActionsToAvoidQuitInd(values) && values.claimantActionsToAvoidQuitInd !== undefined) setFieldValue('claimantActionsToAvoidQuitInd', undefined);
    if (!isActionTakenComments(values) && values.actionTakenComments !== undefined) setFieldValue('actionTakenComments', undefined);
    if (!isContinuingWorkAvailableInd(values) && !opContinuingWorkAvailableInd(values) && values.continuingWorkAvailableInd !== undefined) setFieldValue('continuingWorkAvailableInd', undefined);
  }, [values]);

  const handleVoluntarySepReasonCodeChange = (v: any | null) => {
    let C = VoluntarySepReasonCodes_class.enum;
    if (!!v && [C.Item1, C.Item2, C.Item3, C.Item4, C.Item6, C.Item7, C.Item8, C.Item9].findIndex(f => f === v.value) !== -1) {
      setFieldValue('continuingWorkAvailableInd', 0);
    }
    else setFieldValue('continuingWorkAvailableInd', undefined);
  };

  return (
    <NavOptionsGrid item xs={8} data-cy="sidesResponse_voluntaryQuit_container">
      <NavCard>
        <CardContent>
          <Grid container spacing={2}> 
            <SiSelectOptions
              label={"Reason for Quit"}
              name={`voluntarySepReasonCode`}
              value={values.voluntarySepReasonCode}
              errors={errors}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              isReadOnly={isReadOnly}
              options={VoluntarySepReasonCodes_class.enumAsSelectOptions}
              size={10}
              callBack={handleVoluntarySepReasonCodeChange}
            />

            {/*Conditional Questions*/}
            {isClaimantNotifyOfMedicalIssue(values) &&
              <IndicatorTypeField
                label={"Did the claimant notify the employer of the medical issue prior to separation?"}
                name={`claimantNotifyOfMedicalIssue`}
                value={values.claimantNotifyOfMedicalIssue}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
            }
            {isClaimantRequestMedicalLoa(values) &&
              <IndicatorTypeWithUnknownField
                label={"Prior to the separation, did the claimant request a medical leave of absence?"}
                name={`claimantRequestMedicalLoa`}
                value={values.claimantRequestMedicalLoa}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
            }
            {isMedicalLoaavailable(values) &&
              <IndicatorTypeField
                label={"Was a medical leave of absence available if one had been requested?"}
                name={`medicalLoaavailable`}
                value={values.medicalLoaavailable}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
            }
            {isLoaapprovedInd(values) &&
              <IndicatorTypeField
                label={"Was the leave of absence approved?"}
                name={`loaapprovedInd`}
                value={values.loaapprovedInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
            }
            {isWhyLoadenied(values) &&
              <SiTextAreaField
                label={"Why was the leave of absence denied?"}
                name={`whyLoadenied`}
                value={values.whyLoadenied}
              errors={errors}
              setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                max={ISi_PropertyAttributes.whyLoadenied_Attributes.stringLength.maxLength}
              />
            }
            {isQuitUnderAdviceOfHealthcareProvider(values) &&
              <IndicatorTypeWithUnknownField
                label={"Did a licensed healthcare provider advise the claimant to quit?"}
                name={`quitUnderAdviceOfHealthcareProvider`}
                value={values.quitUnderAdviceOfHealthcareProvider}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
            }
            {isMedicalStatementProvidedInd(values) &&
              <IndicatorTypeField
                label={"Was there a medical statement provided?"}
                name={`medicalStatementProvidedInd`}
                value={values.medicalStatementProvidedInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
            }
            {isDutiesClaimantCouldNotPerform(values) &&
              <SiTextAreaField
                label={"What were the duties the claimant could not perform?"}
                name={`dutiesClaimantCouldNotPerform`}
                value={values.dutiesClaimantCouldNotPerform}
              errors={errors}
              setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                max={ISi_PropertyAttributes.dutiesClaimantCouldNotPerform_Attributes.stringLength.maxLength}
              /> 
            }
            {isWorkRestrictionsInd(values) && values.receivingWorkersCompensationInd !== 0 &&
              <IndicatorTypeField
                label={"Did the claimant have work restrictions?"}
                name={`workRestrictionsInd`}
                value={values.workRestrictionsInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
            }
            {isClaimantWorkRestrictions(values) && values.receivingWorkersCompensationInd !== 0 &&
              <SiTextAreaField
                label={"What were the claimant’s work restrictions?"}
                name={`claimantWorkRestrictions`}
                value={values.claimantWorkRestrictions}
              errors={errors}
              setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                max={ISi_PropertyAttributes.claimantWorkRestrictions_Attributes.stringLength.maxLength}
              />
            }
            {isWorkAvailableWithinRestrictionsInd(values) &&
              <IndicatorTypeField
                label={"Did the company have work the claimant could perform with the work restrictions?"}
                name={`workAvailableWithinRestrictionsInd`}
                value={values.workAvailableWithinRestrictionsInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              /> 
            }
            {isOtherWorkOfferedInd(values) &&
              <IndicatorTypeField
                label={"Was other work offered?"}
                name={`otherWorkOfferedInd`}
                value={values.otherWorkOfferedInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              /> 
            }

            {/*Moved up from the bottom.*/}
            {(opEmployerAccommodateRestrictionsInd(values) && values.receivingWorkersCompensationInd !== 0) &&
              <IndicatorTypeField
                label={"Could you accommodate those restrictions?"}
                name={`employerAccommodateRestrictionsInd`}
                value={values.employerAccommodateRestrictionsInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
            }

            {opWhyEmployerCouldNotAccommodate(values) &&
              <SiTextAreaField
                label={"Why couldn't you accomodate those restrictions?"}
                name={`whyEmployerCouldNotAccommodate`}
                value={values.whyEmployerCouldNotAccommodate}
                errors={errors}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                max={ISi_PropertyAttributes.whyEmployerCouldNotAccommodate_Attributes.stringLength.maxLength}
              />
            }

            {isClaimantActionsToAvoidQuitInd(values) &&
              <IndicatorTypeField
                label={"Claimant Took Action To Avoid Quitting"}
                name={`claimantActionsToAvoidQuitInd`}
                value={values.claimantActionsToAvoidQuitInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
            }

            {isActionTakenComments(values) &&
              <SiTextAreaField
                label={"What action(s) were taken to avoid quitting?"}
                name={`actionTakenComments`}
                value={values.actionTakenComments}
                errors={errors}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                max={ISi_PropertyAttributes.actionTakenComments_Attributes.stringLength.maxLength}
              />
            }


            {isReasonDissatisfied(values) &&
              <SiTextAreaField
                label={"With what part(s) of the job was the claimant dissatisfied?"}
                name={`reasonDissatisfied`}
                value={values.reasonDissatisfied}
              errors={errors}
              setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                max={ISi_PropertyAttributes.reasonDissatisfied_Attributes.stringLength.maxLength}
              />
            }

            {isWorkingConditions(values) &&
              <SiTextAreaField
                label={"What working conditions caused the claimant to leave?"}
                name={`workingConditions`}
                value={values.workingConditions}
              errors={errors}
              setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                max={ISi_PropertyAttributes.workingConditions_Attributes.stringLength.maxLength}
              />
            }
            {isWorkingConditionsChangedInd(values) &&
              <IndicatorTypeField
                label={"Were the working conditions or duties changed?"}
                name={`workingConditionsChangedInd`}
                value={values.workingConditionsChangedInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
            }
            {isChangesPermanentInd(values) &&
              <IndicatorTypeField
                label={"Were the changes permanent?"}
                name={`changesPermanentInd`}
                value={values.changesPermanentInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
            }
            {isChangesScheduledToLast(values) &&
              <SiTextField
                label={"How long were the changes scheduled to last?"}
                name={`changesScheduledToLast`}
                value={values.changesScheduledToLast}
              errors={errors}
              setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                max={ISi_PropertyAttributes.changesScheduledToLast_Attributes.stringLength.maxLength}
              />
            }

            {isInLieuInd(values) &&
              <IndicatorTypeField
                label={"Was the claimant going to be discharged if they hadn’t quit?"}
                name={`inLieuInd`}
                value={values.inLieuInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
            }

            {isDateFailedToReport(values, 0) &&
              <SiDateField
                label={"What date did the claimant fail to report?"}
                name={`uisSiFailedToReportOccurrences[0].dateFailedToReport`}
                value={values.uisSiFailedToReportOccurrences ? values.uisSiFailedToReportOccurrences[0]?.dateFailedToReport || null : null}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                inputlabelprops={{ shrink: true }}
                size={5}
              />
            }
            {isDateFailedtoReportUnknown(values, 0) &&
              <IndicatorTypeYField
                label={"Claimant fail to report unknown"}
                name={`uisSiFailedToReportOccurrences[0].dateFailedtoReportUnknown`}
                value={values.uisSiFailedToReportOccurrences ? values.uisSiFailedToReportOccurrences[0]?.dateFailedtoReportUnknown : undefined}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                size={6}
              /> 
            }
            {isClaimantContactToExplainInd(values) &&
              <IndicatorTypeWithUnknownField
                label={"Did the claimant contact the employer to explain their failure to report?"}
                name={`claimantContactToExplainInd`}
                value={values.claimantContactToExplainInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              /> 
            }
            {isDateMadeContact(values) &&
              <SiDateField
              label={"What date did the claimant make contact?"}
              name={`dateMadeContact`}
              value={values.dateMadeContact}
              errors={errors}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              isReadOnly={isReadOnly}
              inputlabelprops={{ shrink: true }}
              size={5}
              />
            }
            {isReasonForFailToReport(values) &&
              <SiTextAreaField
                label={"What was the reason the claimant provided for failing to report?"}
                name={`reasonForFailToReport`}
                value={values.reasonForFailToReport}
              errors={errors}
              setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                max={ISi_PropertyAttributes.reasonForFailToReport_Attributes.stringLength.maxLength}
              />
            }

            {(isMandatoryRetirementInd(values) && values.voluntarySepReasonCode === VoluntarySepReasonCodes_class.enum.Item8) &&
              <IndicatorTypeField
                label={"Was the retirement mandatory?"}
                name={`mandatoryRetirementInd`}
                value={values.mandatoryRetirementInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
            }
            {((isWhyRetireComments(values) || opWhyRetireComments(values)) && values.voluntarySepReasonCode === VoluntarySepReasonCodes_class.enum.Item8) &&
              <SiTextAreaField
                label={values.mandatoryRetirementInd === 0 ? "Why was the claimant required to retire?" : "Why did the claimant retire?"}
                name={`whyRetireComments`}
                value={values.whyRetireComments}
                errors={errors}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                max={ISi_PropertyAttributes.whyRetireComments_Attributes.stringLength.maxLength}
              /> 
            }
            

            {/* Notice */}
            {isClaimantNoticeInd(values) &&
              <IndicatorTypeWithUnknownField
                label={"Did the claimant give notice?"}
                name={`claimantNoticeInd`}
                value={values.claimantNoticeInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              /> 
            }
            {isDateNoticeGiven(values) &&
              <SiDateField
                label={"Date Notice Given"}
                name={`dateNoticeGiven`}
                value={values.dateNoticeGiven}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                inputlabelprops={{ shrink: true }}
                size={5}
              />
            }
            {isDateNoticeGivenUnknown(values) &&
              <IndicatorTypeYField
                label={"Date Notice Given Unknown"}
                name={`dateNoticeGivenUnknown`}
                value={values.dateNoticeGivenUnknown}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                size={6}
              /> 
            }
            {isClaimantProvidedIntendedLastDayWork(values) &&
              <SiDateField
                label={"What date did the claimant provide as an intended last day of work?"}
                name={`claimantProvidedIntendedLastDayWork`}
                value={values.claimantProvidedIntendedLastDayWork}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                inputlabelprops={{ shrink: true }}
                size={5}
              />
            }
            {isClaimantProvidedIntendedLastDayWorkUnknown(values) &&
              <IndicatorTypeYField
                label={"Claimant intended last day unknown"}
                name={`claimantProvidedIntendedLastDayWorkUnknown`}
                value={values.claimantProvidedIntendedLastDayWorkUnknown}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                size={6}
              /> 
            }
            {isClaimantWorkOutNoticeInd(values) &&
              <IndicatorTypeWithUnknownField
                label={"Did the claimant work out their notice?"}
                name={`claimantWorkOutNoticeInd`}
                value={values.claimantWorkOutNoticeInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
            }
            {isWhyNoticeWasntWorkedOut(values) &&
              <SiTextAreaField
                label={"Why not?"}
                name={`whyNoticeWasntWorkedOut`}
                value={values.whyNoticeWasntWorkedOut}
              errors={errors}
              setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                max={ISi_PropertyAttributes.whyNoticeWasntWorkedOut_Attributes.stringLength.maxLength}
              />
            }
            {isClaimantPaidThroughNoticeInd(values) &&
              <IndicatorTypeField
                label={"Was the claimant paid through the notice?"}
                name={`claimantPaidThroughNoticeInd`}
                value={values.claimantPaidThroughNoticeInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              /> 
            }


            <SiTextAreaField
              label={"Reason for Quit"}
              name={`voluntarySepReasonComments`}
              value={values.voluntarySepReasonComments}
              errors={errors}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              handleBlur={handleBlur}
              isReadOnly={isReadOnly}
              max={ISi_PropertyAttributes.voluntarySepReasonComments_Attributes.stringLength.maxLength}
            />

            {(isContinuingWorkAvailableInd(values) && values.voluntarySepReasonCode !== undefined) &&
              <IndicatorTypeField
                label={"Continuing work available?"}
                name={`continuingWorkAvailableInd`}
                value={values.continuingWorkAvailableInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
            }

            <SiSelectOptions
              label={"Were there changes in the claimant’s hiring agreement that contributed to the claimant quitting this job?"}
              name={`hiringAgreementChangesCode`}
              value={values.hiringAgreementChangesCode}
              errors={errors}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              isReadOnly={isReadOnly}
              options={HiringChangesCodes_class.enumAsSelectOptions}
              size={12}
            />

            <SiTextAreaField
              label={"Description of Changes"}
              name={`hiringAgreementChangesComments`}
              value={values.hiringAgreementChangesComments}
              errors={errors}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              handleBlur={handleBlur}
              isReadOnly={isReadOnly}
              max={ISi_PropertyAttributes.hiringAgreementChangesComments_Attributes.stringLength.maxLength}
            />
            
            <SiDateField
              label={"On what date did the changes take effect?"}
              name={`effectiveDateOfChanges`}
              value={values.effectiveDateOfChanges}
              errors={errors}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              isReadOnly={isReadOnly}
              inputlabelprops={{ shrink: true }}
              size={5}
            />

            {/*<IndicatorTypeField*/}
            {/*  label={"Did the claimant have work restrictions?"}*/}
            {/*  name={`workRestrictionsInd`}*/}
            {/*  value={values.workRestrictionsInd}*/}
            {/*  errors={errors}*/}
            {/*  setFieldValue={setFieldValue}*/}
            {/*  handleBlur={handleBlur}*/}
            {/*  isReadOnly={isReadOnly}*/}
            {/*/>*/}

            

          </Grid>
        </CardContent>
      </NavCard>
    </NavOptionsGrid>
  )
}

export default SiVoluntaryQuit;
