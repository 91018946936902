import React from 'react';
import styled from 'styled-components';
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

const NoOverflowTypography = styled(Typography)`
  overflow: ellipsis;
`;

interface Props {
    file: File,
    onDelete: (file: File) => void
}

const UploadProgress: React.SFC<Props> = (props) => {
    // Doing onDelete this way allows us to not have to specify an event.
    const onDelete = () => props.onDelete(props.file);

    return (
      <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
                <Tooltip title={props.file.name}>
                    <NoOverflowTypography
                        variant="caption"
                    >
                        {props.file.name}
                    </NoOverflowTypography>
                </Tooltip>
            </Grid>

            <Grid item>
                <IconButton onClick={onDelete}>
                    <Clear fontSize="small" />
                </IconButton>
            </Grid>
        </Grid>
    )
}

export default UploadProgress;