import { call, put, takeLatest, select, takeEvery, putResolve } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { DocumentsDto } from '../documents/document.dtos';
import { ClaimDto, ListClaimDto, AppellateEventDto, EligibilityEventDto, ContactIdDto, ClaimInit, ClaimLogDto, ClaimEventDto } from './claims.dtos';
import { ContactInformationDto } from '../../common/dtos/contactInformation';
import {
  getClaimApi, getClaimsApi, createClaimEventApi, createClaimApi, updateClaimApi, getEligibilityEventsApi,
  updateAppellateEventApi, updateEligibilityEventApi, createAppellateEventApi, createEligibilityEventApi,
  getMhaAppealContactsApi, updateClaimEventApi, getMhaClaimsContactsApi, getMhaContactsApi, getMhaHearingContactsApi, getEventsApi, deleteClaimDocumentsApi,
  uploadDocumentsApi, getAppellateEventsApi, getLogsApi, getClaimDocumentsApi, getDocumentsZipApi, getHearingContactInformationApi, deleteAppellateEventApi,
  deleteEligibilityEventApi, updateSidesApi, getClaimByDocumentIdApi, getAllClaimsApi, deleteClaimApi, getMhaHearingRepresentativesApi
} from './api';
import {
  getClaimsActions, getClaimActions, createClaimEventActions, updateClaimActions, createClaimActions, deleteClaimDocumentsActions,
  getClaimDocumentsActions, getEventsActions, getMhaHearingContactsActions, getHearingContactsInformationActions,
  createAppellateEventActions, createEligibilityEventActions, getEligibilityEventsActions,
  updateAppellateEventActions, updateEligibilityEventActions, getLogsActions, getClaimByDocumentActions,
  getMhaAppealContactsActions, getMhaClaimsContactsActions, getMhaContactsActions, deleteClaimActions,
  deleteEligibilityEventActions, deleteAppellateEventActions, documentUploadRequestActions, getMhaHearingRepresentativesActions,
  documentZipRequestActions, updateQuickClaimActions, createQuickClaimActions, updateSidesActions, updateClaimEventActions, getAppellateEventsActions, getAllClaimsActions
} from './actions';



function* handleGetClaims(request: ReturnType<typeof getClaimsActions.request>) {
  //const claimsList = yield select(state => state.claims.claimsList);
  //const claims: ListClaimDto[] = claimsList !== undefined && claimsList.length !== 0 ? claimsList : yield call(getClaimsApi, request.payload);
  const claims: ListClaimDto[] = request.payload === undefined ? [] :
    yield call(getClaimsApi, request.payload);
  yield put({ type: getType(getClaimsActions.success), payload: claims });
}

function* handleGetAllClaims(request: ReturnType<typeof getAllClaimsActions.request>) {
  const claimsList: ListClaimDto[] = yield select(state => state.claims.allClaimsList);
  const claims: ListClaimDto[] = claimsList !== undefined && claimsList.length !== 0 ? claimsList : yield call(getAllClaimsApi);
  yield put({ type: getType(getAllClaimsActions.success), payload: claims });
}

function* handleGetClaim(request: ReturnType<typeof getClaimActions.request>) {
  const claim: ClaimDto =  yield call(getClaimApi, request.payload);

  yield put({ type: getType(getClaimActions.success), payload: claim });
}

function* handleGetClaimByDocumentId(request: ReturnType<typeof getClaimByDocumentActions.request>) {
  const storeClaim: ClaimDto = yield select(state => state.claims.claim);
  let documentId: string | undefined = storeClaim.documents[0]?.id;
  const claim: ClaimDto = (documentId !== undefined && documentId === request.payload && (storeClaim.documents ?? []).length === 1) ?
    storeClaim : yield call(getClaimByDocumentIdApi, request.payload);

  yield put({ type: getType(getClaimActions.success), payload: claim });
}

function* handleUpdateClaim(request: ReturnType<typeof updateClaimActions.request>) {
  const storeClaim: ClaimDto = yield select(state => state.claims.claim);
  let updatedClaim: ClaimDto | boolean = yield call(updateClaimApi, request.payload);

  if (updatedClaim === false)
    yield put({ type: getType(updateClaimActions.failure), payload: "true" });
  else {
    if (storeClaim !== undefined && storeClaim.documents !== undefined &&
      storeClaim.documents.length > 0 && storeClaim.id === (updatedClaim as ClaimDto).id) {
      (updatedClaim as ClaimDto).documents = storeClaim.documents;
    }

    yield put({ type: getType(getClaimActions.success), payload: updatedClaim });
    yield putResolve({ type: getType(getClaimsActions.success), payload: [] });
  }
}

function* handleCreateClaim(request: ReturnType<typeof createClaimActions.request>) {
  const createdClaim: ClaimDto | boolean = yield call(createClaimApi, request.payload);

  if (createdClaim === false)
    yield put({ type: getType(createClaimActions.failure), payload: "true" });
  else {
    yield put({ type: getType(getClaimActions.success), payload: createdClaim });
    yield putResolve({ type: getType(getClaimsActions.success), payload: [] });
  }
}

function* handleQuickUpdateClaim(request: ReturnType<typeof updateClaimActions.request>) {
  const updatedClaim: ClaimDto | boolean = yield call(updateClaimApi, request.payload);

  if (updatedClaim === false)
    yield put({ type: getType(updateClaimActions.failure), payload: "true" });
  else {
    //Mha currently doesn't want to be redirected on dialog close
    if ((updatedClaim as ClaimDto).documents[0]?.isComplete) {
      setTimeout(() => { window.location.href = "/actions"; }, 1500);
    }

    yield put({ type: getType(getClaimActions.success), payload: updatedClaim });
    yield putResolve({ type: getType(getClaimsActions.success), payload: [] });
  }
}

function* handleQuickCreateClaim(request: ReturnType<typeof createClaimActions.request>) {
  const createdClaim: ClaimDto | boolean = yield call(createClaimApi, request.payload);

  if (createdClaim === false)
    yield put({ type: getType(createClaimActions.failure), payload: "true" });
  else {
    yield put({ type: getType(getClaimActions.success), payload: createdClaim });
    yield putResolve({ type: getType(getClaimsActions.success), payload: [] });

  //Mha currently doesn't want to be redirected on dialog close
  //setTimeout(() => { window.location.href = "/actions"; }, 1500);
  }
}

function* handleDeleteClaim(request: ReturnType<typeof deleteClaimActions.request>) {
  const claimDeleted: boolean | null = yield call(deleteClaimApi, request.payload);
  yield put({ type: getType(deleteClaimActions.success), payload: claimDeleted });

  if (claimDeleted) {
    const claimsList: ListClaimDto[] = yield select(state => state.claims.allClaimsList);
    const claims: ListClaimDto[] = JSON.parse(JSON.stringify(claimsList.filter(f => f.id !== request.payload[0])));
    yield put({ type: getType(getAllClaimsActions.success), payload: claims });
  }
}


function* handleGetClaimDocuments(request: ReturnType<typeof getClaimDocumentsActions.request>) {
  const storeClaim: ClaimDto = yield select(state => state.claims.claim);

  if (storeClaim !== null && storeClaim.documents.length === 0) {
    const claimDocs: DocumentsDto[] = yield call(getClaimDocumentsApi, request.payload);
    let newClaim = JSON.parse(JSON.stringify(storeClaim)) as ClaimDto;
    claimDocs.forEach(d => newClaim.documents.push(d));

    yield put({ type: getType(getClaimActions.success), payload: newClaim });
  }
}

function* handleGetMhaContacts() {
  const mhaContactsList: ContactIdDto[] = yield select(state => state.claims.mhaContactsList);
  const mhaContacts: ContactIdDto[] = (mhaContactsList !== undefined && mhaContactsList.length !== 0) ? mhaContactsList : yield call(getMhaContactsApi);

  yield put({ type: getType(getMhaContactsActions.success), payload: mhaContacts });
}


function* handleGetMhaAppealContacts() {
  const mhaAppealContactsList: ContactIdDto[] = yield select(state => state.claims.mhaAppealContactsList);
  const mhaAppealContacts: ContactIdDto[] = (mhaAppealContactsList !== undefined && mhaAppealContactsList.length !== 0) ?
    mhaAppealContactsList : yield call(getMhaAppealContactsApi);

  yield put({ type: getType(getMhaAppealContactsActions.success), payload: mhaAppealContacts });
}


function* handleGetMhaClaimsContacts() {
  const mhaClaimsContactsList: ContactIdDto[] = yield select(state => state.claims.mhaClaimsContactsList);
  const mhaClaimsContacts: ContactIdDto[] = (mhaClaimsContactsList !== undefined && mhaClaimsContactsList.length !== 0) ?
    mhaClaimsContactsList : yield call(getMhaClaimsContactsApi);

  yield put({ type: getType(getMhaClaimsContactsActions.success), payload: mhaClaimsContacts });
}


function* handleGetMhaHearingContacts() {
  const mhaHearingContactsList: ContactIdDto[] = yield select(state => state.claims.mhaHearingContactsList);
  const mhaHearingContacts: ContactIdDto[] = (mhaHearingContactsList !== undefined && mhaHearingContactsList.length !== 0) ?
    mhaHearingContactsList : yield call(getMhaHearingContactsApi);

  yield put({ type: getType(getMhaHearingContactsActions.success), payload: mhaHearingContacts });
}


function* handleGetMhaHearingRepresentatives() {
  const mhaHearingRepresentativesList: ContactIdDto[] = yield select(state => state.claims.mhaHearingRepresentativesList);
  const mhaHearingRepresentatives: ContactIdDto[] = (mhaHearingRepresentativesList !== undefined && mhaHearingRepresentativesList.length !== 0) ?
    mhaHearingRepresentativesList : yield call(getMhaHearingRepresentativesApi);

  yield put({ type: getType(getMhaHearingRepresentativesActions.success), payload: mhaHearingRepresentatives });
}


function* handleDeleteClaimDocument(request: ReturnType<typeof deleteClaimDocumentsActions.request>) {
  yield call(deleteClaimDocumentsApi, request.payload);
}

//SIDES
function* handleUpdateSides(request: ReturnType<typeof updateSidesActions.request>) {
  //console.log('handleUpdateSides', request.payload);
  const result: ClaimDto | string = yield call(updateSidesApi, request.payload);
  
  if (typeof result === "string") {
    yield put({ type: getType(updateSidesActions.failure), payload: result });
    yield put({ type: getType(getClaimActions.success), payload: request.payload });
  }
  else {
    yield put({ type: getType(getClaimActions.success), payload: result });
    yield put({ type: getType(updateSidesActions.failure), payload: "" });
  }
  yield putResolve({ type: getType(getClaimsActions.success), payload: [] });
  //setTimeout(() => { window.location.href = "/actions"; }, 1500);
}


// Logs
function* handleGetLogs(request: ReturnType<typeof getLogsActions.request>) {
  const storeLogs: ClaimLogDto[] = yield select(state => state.claims.logs);
  const firstLog: ClaimLogDto | undefined = (storeLogs !== undefined && storeLogs.length !== 0) ? storeLogs[0] : undefined;
  const logs: ClaimLogDto[] = (firstLog !== undefined && firstLog.claimId === request.payload) ?
    storeLogs : yield call(getLogsApi, request.payload);

  yield put({ type: getType(getLogsActions.success), payload: logs });
}

// Claim Events
function* handleGetEvents(request: ReturnType<typeof getEventsActions.request>) {
  const storeEvents: ClaimEventDto[] = yield select(state => state.claims.claimEvents);
  const firstEvent: ClaimEventDto | undefined = (storeEvents !== undefined && storeEvents.length !== 0) ? storeEvents[0] : undefined;
  const events: ClaimEventDto[] = (firstEvent !== undefined && firstEvent.claimId === request.payload) ?
    storeEvents : yield call(getEventsApi, request.payload);

  yield put({ type: getType(getEventsActions.success), payload: events });
}

function* handleCreateClaimEvent(request: ReturnType<typeof createClaimEventActions.request>) {
  const createdEvent: ClaimEventDto = yield call(createClaimEventApi, request.payload);
  const storeEvents: ClaimEventDto[] = yield select(state => state.claims.claimEvents);

  let newEvents: ClaimEventDto[] = [...storeEvents];
  newEvents.push(createdEvent);
  yield put({ type: getType(getEventsActions.success), payload: newEvents });
}
function* handleUpdateClaimEvent(request: ReturnType<typeof updateClaimEventActions.request>) {
  const updatedEvent: ClaimEventDto = yield call(updateClaimEventApi, request.payload);
  const storeEvents: ClaimEventDto[] = yield select(state => state.claims.claimEvents);

  let newEvents: ClaimEventDto[] = [ ...storeEvents ];
  let eventIndex = newEvents.findIndex(f => f.id === updatedEvent.id);
  if (eventIndex !== -1) {
    newEvents[eventIndex] = updatedEvent;
    yield put({ type: getType(getEventsActions.success), payload: newEvents });
  }
}

// Eligibility Events
function* handleGetEligibilityEvents(request: ReturnType<typeof getEligibilityEventsActions.request>) {
  const storeEvents: EligibilityEventDto[] = yield select(state => state.claims.eligibilityEvents);
  const firstEvent: EligibilityEventDto | undefined = (storeEvents !== undefined && storeEvents.length !== 0) ? storeEvents[0] : undefined;
  const events: EligibilityEventDto[] = (firstEvent !== undefined && firstEvent.claimId === request.payload) ?
    storeEvents : yield call(getEligibilityEventsApi, request.payload);

  yield put({ type: getType(getEligibilityEventsActions.success), payload: events });
}

function* handleUpdateEligibilityEvent(request: ReturnType<typeof updateEligibilityEventActions.request>) {
  const updatedEvent: EligibilityEventDto = yield call(updateEligibilityEventApi, request.payload);
  const storeEvents: EligibilityEventDto[] = yield select(state => state.claims.eligibilityEvents);

  let newEvents: EligibilityEventDto[] = [...storeEvents];
  let eventEIndex = newEvents.findIndex(f => f.id === updatedEvent.id);
  if (eventEIndex !== -1) {
    newEvents[eventEIndex] = updatedEvent;
    yield put({ type: getType(getEligibilityEventsActions.success), payload: newEvents });
  }
}

function* handleCreateEligibilityEvent(request: ReturnType<typeof createEligibilityEventActions.request>) {
  const createdEvent: EligibilityEventDto = yield call(createEligibilityEventApi, request.payload);
  const storeEvents: EligibilityEventDto[] = yield select(state => state.claims.eligibilityEvents);

  let newEvents: EligibilityEventDto[] = [...storeEvents];
  newEvents.push(createdEvent);
  yield put({ type: getType(getEligibilityEventsActions.success), payload: newEvents });
}

function* handleDeleteEligibilityEvent(request: ReturnType<typeof deleteEligibilityEventActions.request>) {
  var result: boolean = yield call(deleteEligibilityEventApi, request.payload);

  if (result) {
    const storeEvents: AppellateEventDto[] = yield select(state => state.claims.eligibilityEvents);
    yield put({ type: getType(getEligibilityEventsActions.success), payload: [...storeEvents].filter(f => f.id !== request.payload) });
  }
}

// Appellate Events
function* handleGetAppellateEvents(request: ReturnType<typeof getAppellateEventsActions.request>) {
  const storeEvents: AppellateEventDto[] = yield select(state => state.claims.appellateEvents);
  const firstEvent: AppellateEventDto | undefined = (storeEvents !== undefined && storeEvents.length !== 0) ? storeEvents[0] : undefined;
  const events: AppellateEventDto[] = (firstEvent !== undefined && firstEvent.claimId === request.payload) ?
    storeEvents : yield call(getAppellateEventsApi, request.payload);

  yield put({ type: getType(getAppellateEventsActions.success), payload: events });
}

function* handleUpdateAppellateEvent(request: ReturnType<typeof updateAppellateEventActions.request>) {
  const updatedEvent: AppellateEventDto = yield call(updateAppellateEventApi, request.payload);
  const storeEvents: AppellateEventDto[] = yield select(state => state.claims.appellateEvents);

  let newEvents: AppellateEventDto[] = [...storeEvents];
  let eventAIndex = newEvents.findIndex(f => f.id === updatedEvent.id);
  if (eventAIndex !== -1) {
    newEvents[eventAIndex] = updatedEvent;
    yield put({ type: getType(getAppellateEventsActions.success), payload: newEvents });
  }
}

function* handleCreateAppellateEvent(request: ReturnType<typeof createAppellateEventActions.request>) {
  const createdEvent: AppellateEventDto = yield call(createAppellateEventApi, request.payload);
  const storeEvents: AppellateEventDto[] = yield select(state => state.claims.appellateEvents);

  let newEvents: AppellateEventDto[] = [...storeEvents];
  newEvents.push(createdEvent);
  yield put({ type: getType(getAppellateEventsActions.success), payload: newEvents });
}

function* handleDeleteAppellateEvent(request: ReturnType<typeof deleteAppellateEventActions.request>) {
  var result: boolean = yield call(deleteAppellateEventApi, request.payload);

  if (result) {
    const storeEvents: AppellateEventDto[] = yield select(state => state.claims.appellateEvents);
    yield put({ type: getType(getAppellateEventsActions.success), payload: [...storeEvents].filter(f => f.id !== request.payload) });
  }
}


//Upload Documents
function* handleUploadDocuments(request: ReturnType<typeof documentUploadRequestActions.request>) {
  let result: boolean = yield call(uploadDocumentsApi, request.payload);
  if (result) yield put({ type: getType(getClaimActions.request), payload: request.payload.claimId });
}

//Get Documents Zip
function* handleGetDocumentsZip(request: ReturnType<typeof documentZipRequestActions.request>) {
  yield call(getDocumentsZipApi, request.payload);
}

//Get Hearing Contacts Information
function* handleHearingContactInfo(request: ReturnType<typeof getHearingContactsInformationActions.request>) {
  if (request.payload.length !== 0) {
    const storeInfo: ContactInformationDto[] = yield select(state => state.claims.hearingContactsInfoList);
    let info: ContactInformationDto[] = (storeInfo.length === 0) ? yield call(getHearingContactInformationApi, request.payload) : storeInfo;
    yield put({ type: getType(getHearingContactsInformationActions.success), payload: info });
  }
}



 function* watchClaimGetRequest() {
  try { yield takeLatest(getType(getClaimActions.request), handleGetClaim); }
  catch (err) { yield put({ type: getType(getClaimActions.success), payload: ClaimInit }); }
}

function* watchClaimGetByDocumentIdRequest() {
  try { yield takeLatest(getType(getClaimByDocumentActions.request), handleGetClaimByDocumentId); }
  catch (err) { yield put({ type: getType(getClaimActions.success), payload: ClaimInit }); }
}

 function* watchClaimsGetRequest() {
  try { yield takeLatest(getType(getClaimsActions.request), handleGetClaims); }
  catch (err) { }
}

function* watchGetAllClaimsRequest() {
  try { yield takeLatest(getType(getAllClaimsActions.request), handleGetAllClaims); }
  catch (err) { }
}


 function* watchClaimUpdateRequest() {
  try { yield takeLatest(getType(updateClaimActions.request), handleUpdateClaim); }
  catch (err) {}
}

 function* watchClaimCreateRequest() {
  try { yield takeLatest(getType(createClaimActions.request), handleCreateClaim); }
  catch (err) { }
}

 function* watchQuickClaimUpdateRequest() {
  try { yield takeLatest(getType(updateQuickClaimActions.request), handleQuickUpdateClaim); }
  catch (err) { }
}

 function* watchQuickClaimCreateRequest() {
  try { yield takeLatest(getType(createQuickClaimActions.request), handleQuickCreateClaim); }
  catch (err) { }
}

 function* watchClaimDocumentsGetRequest() {
  try { yield takeLatest(getType(getClaimDocumentsActions.request), handleGetClaimDocuments); }
  catch (err) { }
}

 function* watchMhaContactsGetRequest() {
  try { yield takeLatest(getType(getMhaContactsActions.request), handleGetMhaContacts); }
  catch (err) { }
}

 function* watchMhaAppealContactsGetRequest() {
  try { yield takeLatest(getType(getMhaAppealContactsActions.request), handleGetMhaAppealContacts); }
  catch (err) { }
}

 function* watchMhaHearingContactsGetRequest() {
  try { yield takeLatest(getType(getMhaHearingContactsActions.request), handleGetMhaHearingContacts); }
  catch (err) { }
}

function* watchMhaHearingRepresentativesGetRequest() {
  try { yield takeLatest(getType(getMhaHearingContactsActions.request), handleGetMhaHearingRepresentatives); }
  catch (err) { }
}

 function* watchMhaClaimContactsGetRequest() {
  try { yield takeLatest(getType(getMhaClaimsContactsActions.request), handleGetMhaClaimsContacts); }
  catch (err) { }
}

 function* watchDeleteClaimDocumentsRequest() {
  try { yield takeEvery(getType(deleteClaimDocumentsActions.request), handleDeleteClaimDocument); }
  catch (err) { }
}

// Sides
 function* watchSidesUpdateRequest() {
  try { yield takeLatest(getType(updateSidesActions.request), handleUpdateSides); }
  catch (err) { }
}

// Events
 function* watchEventsGetRequest() {
  try { yield takeLatest(getType(getEventsActions.request), handleGetEvents); }
  catch (err) { }
}

// Claim events
 function* watchClaimEventCreateRequest() {
  try { yield takeLatest(getType(createClaimEventActions.request), handleCreateClaimEvent); }
  catch (err) { }
}

// Eligibility
 function* watchEligibilityEventsGetRequest() {
  try { yield takeLatest(getType(getEligibilityEventsActions.request), handleGetEligibilityEvents); }
  catch (err) { }
}

 function* watchEligibilityEventUpdateRequest() {
  try { yield takeLatest(getType(updateEligibilityEventActions.request), handleUpdateEligibilityEvent); }
  catch (err) { }
}

 function* watchEligibilityEventCreateRequest() {
  try { yield takeLatest(getType(createEligibilityEventActions.request), handleCreateEligibilityEvent); }
  catch (err) { }
}

// Appellate
 function* watchAppellateEventsGetRequest() {
  try { yield takeLatest(getType(getAppellateEventsActions.request), handleGetAppellateEvents); }
  catch (err) { }
}

 function* watchAppellateEventUpdateRequest() {
  try { yield takeLatest(getType(updateAppellateEventActions.request), handleUpdateAppellateEvent); }
  catch (err) { }
}

 function* watchAppellateEventCreateRequest() {
  try { yield takeLatest(getType(createAppellateEventActions.request), handleCreateAppellateEvent); }
  catch (err) { }
}

// deletes
 function* watchDeleteAppellateEventRequest() {
  try { yield takeLatest(getType(deleteAppellateEventActions.request), handleDeleteAppellateEvent); }
  catch (err) { }
}

 function* watchDeleteEligibilityEventRequest() {
  try { yield takeLatest(getType(deleteEligibilityEventActions.request), handleDeleteEligibilityEvent); }
  catch (err) { }
}

 function* watchUploadDocumentsRequest() {
  try { yield takeLatest(getType(documentUploadRequestActions.request), handleUploadDocuments); }
  catch (err) { }
}

 function* watchGetDocumentZipRequest() {
  try { yield takeLatest(getType(documentZipRequestActions.request), handleGetDocumentsZip); }
  catch (err) { }
}

 function* watchHearingContactInfoRequest() {
  try { yield takeLatest(getType(getHearingContactsInformationActions.request), handleHearingContactInfo); }
  catch (err) { }
}

function* watchDeleteClaimRequest() {
  try { yield takeLatest(getType(deleteClaimActions.request), handleDeleteClaim); }
  catch (err) { }
}

// Logs
 function* watchLogsGetRequest() {
  try { yield takeLatest(getType(getLogsActions.request), handleGetLogs); }
  catch (err) { }
}



export default [
  watchClaimCreateRequest,
  watchClaimGetRequest,
  watchClaimUpdateRequest,
  watchClaimGetByDocumentIdRequest,
  watchClaimsGetRequest,
  watchQuickClaimCreateRequest,
  watchQuickClaimUpdateRequest,
  watchAppellateEventCreateRequest,
  watchAppellateEventUpdateRequest,
  watchUploadDocumentsRequest,
  watchGetDocumentZipRequest,
  watchEligibilityEventUpdateRequest,
  watchEligibilityEventCreateRequest,
  watchEventsGetRequest,
  watchSidesUpdateRequest,
  watchMhaAppealContactsGetRequest,
  watchMhaClaimContactsGetRequest,
  watchHearingContactInfoRequest,
  watchDeleteAppellateEventRequest,
  watchDeleteEligibilityEventRequest,
  watchMhaHearingContactsGetRequest,
  watchMhaHearingRepresentativesGetRequest,
  watchDeleteClaimDocumentsRequest,
  watchClaimDocumentsGetRequest,
  watchEligibilityEventsGetRequest,
  watchClaimEventCreateRequest,
  watchAppellateEventsGetRequest,
  watchLogsGetRequest,
  watchGetAllClaimsRequest,
  watchDeleteClaimRequest
];