import React from 'react';
import { isClientPortalUser } from '../../common/dtos/auth';
import DocumentsList from './mhaActionList';
import ClientActionList from './clientActionList';

const ActionList: React.FC = () => {

    return (
    <>
    {isClientPortalUser() ?
        <ClientActionList />
        :
        <DocumentsList />
            }
</>
)
}

export default ActionList;