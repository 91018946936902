import { createAsyncAction } from 'typesafe-actions';
import { ClientQuestionnaireDto, CreateQuestionnaireDto } from './dto';

export const createQuestionnaireActions = createAsyncAction(
    'CREATE_QUESTIONNAIRE_REQUEST',
    'CREATE_QUESTIONNAIRE_SUCCESS',
    'CREATE_QUESTIONNAIRE_ERROR'
)<CreateQuestionnaireDto, CreateQuestionnaireDto, string>();

export const getQuestionnaireActions = createAsyncAction(
    'GET_QUESTIONNAIRE_REQUEST',
    'GET_QUESTIONNAIRE_SUCCESS',
    'GET_QUESTIONNAIRE_ERROR',
)<string, ClientQuestionnaireDto, string>();

export const saveQuestionnaireActions = createAsyncAction(
    'SAVE_QUESTIONNAIRE_REQUEST',
    'SAVE_QUESTIONNAIRE_SUCCESS',
    'SAVE_QUESTIONNAIRE_ERROR',
)<ClientQuestionnaireDto, ClientQuestionnaireDto, string>();