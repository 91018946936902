import { createAsyncAction, createAction } from 'typesafe-actions';
import { ClaimDto, ListClaimDto, EligibilityEventDto, AppellateEventDto, ClaimEventDto, ContactIdDto, ClaimLogDto, HearingContactsDto, ClaimSearchDto } from './claims.dtos';
import { ClaimDocumentRequestDto, DocumentsDto } from '../documents/document.dtos';
import { ContactInformationDto } from '../../common/dtos/contactInformation';

export const getClaimsActions = createAsyncAction(
  'GET_CLAIMS_REQUEST',
  'GET_CLAIMS_SUCCESS',
  'GET_CLAIMS_ERROR'
)<ClaimSearchDto, ListClaimDto[], string>();


export const getAllClaimsActions = createAsyncAction(
  'GET_ALL_CLAIMS_REQUEST',
  'GET_ALL_CLAIMS_SUCCESS',
  'GET_ALL_CLAIMS_ERROR'
)<undefined, ListClaimDto[], string>();

export const getClaimActions = createAsyncAction(
  'GET_CLAIM_REQUEST',
  'GET_CLAIM_SUCCESS',
  'GET_CLAIM_ERROR',
)<string, ClaimDto, string>();

export const getClaimByDocumentActions = createAsyncAction(
  'GET_CLAIM_BY_DOCUMENT_REQUEST',
  'GET_CLAIM_BY_DOCUMENT_SUCCESS',
  'GET_CLAIM_BY_DOCUMENT_ERROR',
)<string, ClaimDto, string>();

export const updateClaimActions = createAsyncAction(
  'UPDATE_CLAIM_REQUEST',
  'UPDATE_CLAIM_SUCCESS',
  'UPDATE_CLAIM_ERROR'
)<ClaimDto, ClaimDto, string>();

export const createClaimActions = createAsyncAction(
  'CREATE_CLAIM_REQUEST',
  'CREATE_CLAIM_SUCCESS',
  'CREATE_CLAIM_ERROR'
)<ClaimDto, ClaimDto, string>();

export const updateQuickClaimActions = createAsyncAction(
  'UPDATE_QUICK_CLAIM_REQUEST',
  'UPDATE_QUICK_CLAIM_SUCCESS',
  'UPDATE_QUICK_CLAIM_ERROR'
)<ClaimDto, ClaimDto, string>();

export const createQuickClaimActions = createAsyncAction(
  'CREATE_QUICK_CLAIM_REQUEST',
  'CREATE_QUICK_CLAIM_SUCCESS',
  'CREATE_QUICK_CLAIM_ERROR'
)<ClaimDto, ClaimDto, string>();

export const deleteClaimActions = createAsyncAction(
  'DELETE_CLAIM_REQUEST',
  'DELETE_CLAIM_SUCCESS',
  'DELETE_CLAIM_ERROR',
)<string[], boolean | null, string>();

export const getClaimDocumentsActions = createAsyncAction(
  'GET_CLAIM_DOCUMENTS_REQUEST',
  'GET_CLAIM_DOCUMENTS_SUCCESS',
  'GET_CLAIM_DOCUMENTS_ERROR',
)<string, DocumentsDto[], string>();

export const deleteClaimDocumentsActions = createAsyncAction(
  'DELETE_CLAIM_DOCUMENT_REQUEST',
  'DELETE_CLAIM_DOCUMENT_SUCCESS',
  'DELETE_CLAIM_DOCUMENT_ERROR',
)<string, string, string>();


export const getMhaContactsActions = createAsyncAction(
  'GET_MHA_CONTACTS_REQUEST',
  'GET_MHA_CONTACTS_SUCCESS',
  'GET_MHA_CONTACTS_ERROR'
)<undefined, ContactIdDto[], string>();

export const getMhaAppealContactsActions = createAsyncAction(
  'GET_MHA_APPEAL_CONTACTS_REQUEST',
  'GET_MHA_APPEAL_CONTACTS_SUCCESS',
  'GET_MHA_APPEAL_CONTACTS_ERROR'
)<undefined, ContactIdDto[], string>();

export const getMhaClaimsContactsActions = createAsyncAction(
  'GET_MHA_CLAIMS_CONTACTS_REQUEST',
  'GET_MHA_CLAIMS_CONTACTS_SUCCESS',
  'GET_MHA_CLAIMS_CONTACTS_ERROR'
)<undefined, ContactIdDto[], string>();

export const getMhaHearingContactsActions = createAsyncAction(
  'GET_MHA_HEARING_CONTACTS_REQUEST',
  'GET_MHA_HEARING_CONTACTS_SUCCESS',
  'GET_MHA_HEARING_CONTACTS_ERROR'
)<undefined, ContactIdDto[], string>();

export const getMhaHearingRepresentativesActions = createAsyncAction(
  'GET_MHA_HEARING_REPRESENTATIVES_REQUEST',
  'GET_MHA_HEARING_REPRESENTATIVES_SUCCESS',
  'GET_MHA_HEARING_REPRESENTATIVES_ERROR'
)<undefined, ContactIdDto[], string>();

export const getHearingContactsInformationActions = createAsyncAction(
  'GET_HEARING_CONTACTS_INFO_REQUEST',
  'GET_HEARING_CONTACTS_INFO_SUCCESS',
  'GET_HEARING_CONTACTS_INFO_ERROR'
)<HearingContactsDto[], ContactInformationDto[], string>();


//Sides
export const updateSidesActions = createAsyncAction(
    'UPDATE_SIDES_REQUEST',
    'UPDATE_SIDES_SUCCESS',
    'UPDATE_SIDES_ERROR'
)<ClaimDto, ClaimDto, string>();


// Claim Events
export const getLogsActions = createAsyncAction(
  'GET_CLAIM_LOGS_REQUEST',
  'GET_CLAIM_LOGS_SUCCESS',
  'GET_CLAIM_LOGS_ERROR'
)<string, ClaimLogDto[], string>();

export const getEventsActions = createAsyncAction(
  'GET_CLAIM_EVENTS_REQUEST',
  'GET_CLAIM_EVENTS_SUCCESS',
  'GET_CLAIM_EVENTS_ERROR'
)<string, ClaimEventDto[], string>();

export const createClaimEventActions = createAsyncAction(
    'CREATE_CLAIM_EVENT_REQUEST',
    'CREATE_CLAIM_EVENT_SUCCESS',
    'CREATE_CLAIM_EVENT_ERROR'
)<ClaimEventDto, ClaimEventDto, string>();

export const updateClaimEventActions = createAsyncAction(
    'UPDATE_CLAIM_EVENT_REQUEST',
    'UPDATE_CLAIM_EVENT_SUCCESS',
    'UPDATE_CLAIM_EVENT_ERROR'
)<ClaimEventDto, ClaimEventDto, string>();

//Eligibility Events
export const getEligibilityEventsActions = createAsyncAction(
    'GET_ELIGIBILITY_EVENTS_REQUEST',
    'GET_ELIGIBILITY_EVENTS_SUCCESS',
    'GET_ELIGIBILITY_EVENTS_ERROR'
)<string, EligibilityEventDto[], string>();

export const updateEligibilityEventActions = createAsyncAction(
  'UPDATE_EligibilityEvent_REQUEST',
  'UPDATE_ELIGIBILITY_EVENT_SUCCESS',
  'UPDATE_ELIGIBILITY_EVENT_ERROR'
)<EligibilityEventDto, EligibilityEventDto, string>();

export const createEligibilityEventActions = createAsyncAction(
  'CREATE_ELIGIBILITY_EVENT_REQUEST',
  'CREATE_ELIGIBILITY_EVENT_SUCCESS',
  'CREATE_ELIGIBILITY_EVENT_ERROR'
)<EligibilityEventDto, EligibilityEventDto, string>();

export const deleteEligibilityEventActions = createAsyncAction(
  'DELETE_ELIGIBILITY_EVENT_REQUEST',
  'DELETE_ELIGIBILITY_EVENT_SUCCESS',
  'DELETE_ELIGIBILITY_EVENT_ERROR',
)<string, undefined, string>();

//Appellate Events
export const getAppellateEventsActions = createAsyncAction(
    'GET_APPELLATE_EVENTS_REQUEST',
    'GET_APPELLATE_EVENTS_SUCCESS',
    'GET_APPELLATE_EVENTS_ERROR'
)<string, AppellateEventDto[], string>();

export const updateAppellateEventActions = createAsyncAction(
  'UPDATE_APPELLATE_EVENT_REQUEST',
  'UPDATE_APPELLATE_EVENT_SUCCESS',
  'UPDATE_APPELLATE_EVENT_ERROR'
)<AppellateEventDto, AppellateEventDto, string>();

export const createAppellateEventActions = createAsyncAction(
  'CREATE_APPELLATE_EVENT_REQUEST',
  'CREATE_APPELLATE_EVENT_SUCCESS',
  'CREATE_APPELLATE_EVENT_ERROR'
)<AppellateEventDto, AppellateEventDto, string>();

export const deleteAppellateEventActions = createAsyncAction(
  'DELETE_APPELLATE_EVENT_REQUEST',
  'DELETE_APPELLATE_EVENT_SUCCESS',
  'DELETE_APPELLATE_EVENT_ERROR',
)<string, undefined, string>();


// Document Request - For upload and get zip file.
export const documentUploadRequestActions = createAsyncAction(
  'DOCUMENT_UPLOAD_REQUEST',
  'DOCUMENT_UPLOAD_SUCCESS',
  'DOCUMENT_UPLOAD_ERROR',
)<ClaimDocumentRequestDto, string, string>();

export const documentZipRequestActions = createAsyncAction(
  'DOCUMENT_ZIP_REQUEST',
  'DOCUMENT_ZIP_SUCCESS',
  'DOCUMENT_ZIP_ERROR',
)<ClaimDocumentRequestDto, any, string>();

export const didClaimDisassociate = createAction('DID_CLAIM_DISASSOCIATE')<boolean>();
