import React from 'react';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';

// material ui
import { Grid, Typography, Button, Collapse } from '@material-ui/core';
import * as Style from '../claims.styles';
import { rootSelector } from '../../../common/selectors/selectors';
import ClaimLogCard from '../components/claimLogCard'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { ClaimLogDto } from '../claims.dtos';
import { getLogsActions } from '../actions';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { KeyboardDatePicker, } from '@material-ui/pickers';


interface ClaimLogPanelProps extends React.HTMLProps<React.ReactNode> {
  isClientUser: boolean;
}

const ClaimLogPanel: React.FC<ClaimLogPanelProps> = (
  {
    isClientUser
  }) => {
  const dispatch = useDispatch();

  const systemClaim = rootSelector(state => state.claims.claim);
  const systemClaimLogs = rootSelector(state => state.claims.logs);

  const [expanded, setExpanded] = React.useState(false);
  const [beginDate, setBeginDate] = React.useState(moment().subtract(2, 'weeks'));
  const [endDate, setEndDate] = React.useState(moment().add(2, 'weeks'));
  const [claimLogs, setClaimLogs] = React.useState([] as ClaimLogDto[]);


  React.useEffect(() => setClaimLogs(systemClaimLogs), [systemClaimLogs]);
  React.useEffect(() => {
    if (systemClaim && systemClaim.id !== undefined && systemClaim.id !== '') {
      dispatch({ type: getType(getLogsActions.request), payload: systemClaim.id });
    }
  }, [dispatch, systemClaim]);
  

  const handleExpandChange = () => {
    setExpanded(!expanded)
  };

  const handleStartDateChange = (date: moment.Moment) => {
    setBeginDate(date);
    if (date.diff(beginDate) > 0) {
      setEndDate(date);
    }
  }

  const handleEndDateChange = (date: moment.Moment) => {
    setEndDate(date);
    if (date.diff(beginDate) < 0) {
      setBeginDate(date);
    }
  }

  return (
    <>
      <Grid container alignItems="center">
              
        <Grid item md={12} lg={1}>
          <Button onClick={() => handleExpandChange()} data-cy="claim_log_expandList">
            {expanded ? <ExpandLess fontSize="large" /> : <ExpandMore fontSize="large" />}
          </Button>
              </Grid>
              

        <Grid item md={12} lg={2}>
          <Typography variant="subtitle1" color="textSecondary" display="block">Claim Log</Typography>
        </Grid>
              <Style.DatePickerGrid item xs={12} sm={12} md={12} lg={2}>
          <KeyboardDatePicker
            margin="normal"
            id="claim_beginDate"
            name="beginDate"
            label="Created Date Start"
            format="MM/DD/yyyy"
            data-cy="claim_event_beginDate"
            value={beginDate}
            onChange={(date: MaterialUiPickersDate) => handleStartDateChange(moment(date))}
            KeyboardButtonProps={{
              'aria-label': 'change begin date',
            }}
          />
        </Style.DatePickerGrid>
              <Style.DatePickerGrid item xs={12} sm={12} md={12} lg={2}>
          <KeyboardDatePicker
            margin="normal"
            id="claim_endDate"
            name="endDate"
            label="Created Date End"
            format="MM/DD/yyyy"
            data-cy="claim_event_endDate"
            value={endDate}
            onChange={(date: MaterialUiPickersDate) => handleEndDateChange(moment(date))}
            KeyboardButtonProps={{
              'aria-label': 'change end date',
            }}
          />
        </Style.DatePickerGrid>
      </Grid>
      <Collapse in={expanded}>
        <Grid container spacing={2}>
          {(claimLogs && claimLogs !== undefined && claimLogs
            .filter(o => moment(o.createdDate).valueOf() >= moment(beginDate).valueOf() && moment(o.createdDate).valueOf() <= moment(endDate).valueOf())
            .length !== 0) ?
            claimLogs
              .filter(o => moment(o.createdDate).valueOf() >= moment(beginDate).valueOf() && moment(o.createdDate).valueOf() <= moment(endDate).valueOf())
              .map((o, index) => (
                <Grid item xs={12} key={'claim_event_' + index}>
                  <ClaimLogCard dto={o} />
                </Grid>
              ))
                      : <Grid item xs={12}><Style.NoDateRangeText variant="subtitle1" color="secondary">No records found in this date range.</Style.NoDateRangeText></Grid>}
        </Grid>
      </Collapse>
    </>
  )
}
export default ClaimLogPanel;