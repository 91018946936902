import React from 'react';
import {
  Dialog, DialogActions, Grid, DialogContent, DialogTitle, DialogContentText
} from '@material-ui/core';
import { ButtonGrid, StyledSubmitButton} from '../../../common/styles/styles';

interface Props {
  open: boolean;
  onCancel: () => void;
}

const MfaRegistrationCompleteDialog: React.FC<Props> = (
  {
    open,
    onCancel,
  }) => {

  const cancelDialog = () => onCancel();

  return <Dialog
    open={open}
    maxWidth="sm"
    fullWidth
    data-cy="mfa-help-dialog"
  >
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <DialogTitle id="form-dialog-title">Multi-Factor Authentication Complete</DialogTitle>
        <DialogContent>
          <Grid container alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <DialogContentText>
                Next time, when prompted to confirm your identity, please use your authenticator app to retrieve your verification code.
              </DialogContentText>
            </Grid>
          </Grid>
          <DialogActions>
            <ButtonGrid container alignItems="center" justifyContent="center">
              <StyledSubmitButton
                onClick={cancelDialog}
                data-cy="mfa-registration-complete-cancelButton"
              >Ok
              </StyledSubmitButton>
            </ButtonGrid>
          </DialogActions>
        </DialogContent>
      </Grid>
    </Grid>
  </Dialog>
}

export default MfaRegistrationCompleteDialog;
