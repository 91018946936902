import React from 'react';
import UtahMask from './stateSutaMasks/utahMask'
import PennsylvaniaMask from './stateSutaMasks/pennsylvaniaMask';
import DistrictOfColumbiaMask from './stateSutaMasks/districtOfColumbiaMask';
import AlaskaMask from './stateSutaMasks/alaskaMask';
import AlabamaMask from './stateSutaMasks/alabamaMask';
import ArkansasMask from './stateSutaMasks/arkansasMask';
import ArizonaMask from './stateSutaMasks/arizonaMask';
import CaliforniaMask from './stateSutaMasks/californiaMask';
import ColoradoMask from './stateSutaMasks/coloradoMask';
import ConnecticutMask from './stateSutaMasks/connecticutMask';
import DelawareMask from './stateSutaMasks/delawareMask';
import FloridaMask from './stateSutaMasks/floridaMask';
import GeorgiaMask from './stateSutaMasks/georgiaMask';
import HawaiiMask from './stateSutaMasks/hawaiiMask';
import IowaMask from './stateSutaMasks/iowaMask';
import IdahoMask from './stateSutaMasks/idahoMask';
import IllinoisMask from './stateSutaMasks/illinoisMask';
import IndianaMask from './stateSutaMasks/indianaMask';
import KansasMask from './stateSutaMasks/kansasMask';
import KentuckyMask from './stateSutaMasks/kentuckyMask';
import LouisianaMask from './stateSutaMasks/louisianaMask';
import MassachusettsMask from './stateSutaMasks/massachusettsMask';
import MarylandMask from './stateSutaMasks/marylandMask';
import MaineMask from './stateSutaMasks/maineMask';
import MichiganMask from './stateSutaMasks/michiganMask';
import MinnesotaMask from './stateSutaMasks/minnesotaMask';
import MissouriMask from './stateSutaMasks/missouriMask';
import MississippiMask from './stateSutaMasks/mississippiMask';
import MontanaMask from './stateSutaMasks/montanaMask';
import NorthCarolinaMask from './stateSutaMasks/northCarolinaMask';
import NorthDakotaMask from './stateSutaMasks/northDakotaMask';
import NebraskaMask from './stateSutaMasks/nebraskaMask';
import NewHampshireMask from './stateSutaMasks/newHampshireMask';
import NewJerseyMask from './stateSutaMasks/newJerseyMask';
import NewMexicoMask from './stateSutaMasks/newMexicoMask';
import NevadaMask from './stateSutaMasks/nevadaMask';
import NewYorkMask from './stateSutaMasks/newYorkMask';
import OhioMask from './stateSutaMasks/ohioMask';
import OklahomaMask from './stateSutaMasks/oklahomaMask';
import OregonMask from './stateSutaMasks/oregonMask';
import RhodeIslandMask from './stateSutaMasks/rhodeIslandMask';
import SouthCarolinaMask from './stateSutaMasks/southCarolinaMask';
import SouthDakotaMask from './stateSutaMasks/southDakotaMask';
import TennesseeMask from './stateSutaMasks/tennesseeMask';
import TexasMask from './stateSutaMasks/texasMask';
import VirginiaMask from './stateSutaMasks/virginiaMask';
import VermontMask from './stateSutaMasks/vermontMask';
import WashingtonMask from './stateSutaMasks/washingtonMask';
import WisconsinMask from './stateSutaMasks/wisconsinMask';
import WestVirginiaMask from './stateSutaMasks/westVirginiaMask';
import WyomingMask from './stateSutaMasks/wyomingMask';

export const MaskHandler = (state: string) => {

    switch (state) {
        case 'Alaska':
        case 'AK':
            return AlaskaMask as any;
        case 'Alabama':
        case 'AL':
            return AlabamaMask as any;
        case 
            'Arkansas':
         case   'AR':
            return ArkansasMask as any;
        case 'Arizona':
        case 'AZ':
            return ArizonaMask as any;
        case 'California':
        case 'CA':
            return CaliforniaMask as any;
        case 'Colorado':
        case 'CO':
            return ColoradoMask as any;
        case 'Connecticut':
        case 'CT':
            return ConnecticutMask as any;
        case "District Of Columbia":
        case "DC":
            return DistrictOfColumbiaMask as any;
        case 'Delaware':
        case 'DE':
            return DelawareMask as any;
        case 'Florida':
        case 'FL':
            return FloridaMask as any;
        case 'Georgia':
        case 'GA':
            return GeorgiaMask as any;
        case 'Hawaii':
        case 'HI':
            return HawaiiMask as any;
        case 'Iowa':
        case 'IA':
            return IowaMask as any;
        case 'Idaho':
        case 'ID':
            return IdahoMask as any;
        case 'Illinois':
        case 'IL':
            return IllinoisMask as any;
        case 'Indiana':
        case 'IN':
            return IndianaMask as any;
        case 'Kansas':
        case 'KS':
            return KansasMask as any;
        case 'Kentucky':
        case 'KY':
            return KentuckyMask as any;
        case 'Louisiana':
        case 'LA':
            return LouisianaMask as any;
        case 'Massachusetts':
        case 'MA':
            return MassachusettsMask as any;
        case 'Maryland':
        case 'MD':
            return MarylandMask as any;
        case 'Maine':
        case 'ME':
            return MaineMask as any;
        case 'Michigan':
        case 'MI':
            return MichiganMask as any;
        case 'Minnesota':
        case 'MN':
            return MinnesotaMask as any;
        case 'Missouri':
        case 'MO':
            return MissouriMask as any;
        case 'Mississippi':
        case 'MS':
            return MississippiMask as any;
        case 'Montana':
        case 'MT':
            return MontanaMask as any;
        case 'North Carolina':
        case 'NC':
            return NorthCarolinaMask as any;
        case 'North Dakota':
        case 'ND':
            return NorthDakotaMask as any;
        case 'Nebraska':
        case 'NE':
            return NebraskaMask as any;
        case 'New Hampshire':
        case 'NH':
            return NewHampshireMask as any;
        case 'New Jersey':
        case 'NJ':
            return NewJerseyMask as any;
        case 'New Mexico':
        case 'NM':
            return NewMexicoMask as any;
        case 'Nevada':
        case 'NV':
            return NevadaMask as any;
        case 'New York':
        case 'NY':
            return NewYorkMask as any;
        case 'Ohio':
        case 'OH':
            return OhioMask as any;
        case 'Oklahoma':
        case 'OK':
            return OklahomaMask as any;
        case 'Oregon':
        case 'OR':
            return OregonMask as any;
        case 'Pennsylvania':
        case 'PA':
            return PennsylvaniaMask as any;
        case 'Rhode Island':
        case 'RI':
            return RhodeIslandMask as any;
        case 'South Carolina':
        case 'SC':
            return SouthCarolinaMask as any;
        case 'South Dakota':
        case 'SD':
            return SouthDakotaMask as any;
        case 'Tennessee':
        case 'TN':
            return TennesseeMask as any;
        case 'Texas':
        case 'TX':
            return TexasMask as any;
        case 'Utah':
        case 'UT':
            return UtahMask as any;
        case 'Virginia':
        case 'VA':
            return VirginiaMask as any;
        case 'Vermont':
        case 'VT':
            return VermontMask as any;
        case 'Washington':
        case 'WA':
            return WashingtonMask as any;
        case 'Wisconsin':
        case 'WI':
            return WisconsinMask as any;
        case 'West Virginia':
        case 'WV':
            return WestVirginiaMask as any;
        case 'Wyoming':
        case 'WY':
            return WyomingMask as any;
        case '':
            break;
    }

}


export default MaskHandler;