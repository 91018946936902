import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import * as Styles from '../../../common/styles/styles';
import { ClaimEventDto } from '../claims.dtos';
import moment from 'moment';
import { alternativeIssueOptions, claimEventTypeOptions } from '../options';
import { SeparationCodeDto } from '../../settings/codes/codes.dtos';


interface Props {
  dto: ClaimEventDto;
  separationCodes: SeparationCodeDto[]; 
  separationCodeId: string;
}

const ClaimEventCard: React.FC<Props> = (
  {
    dto,
    separationCodes,
    separationCodeId,
  }) => {

  return (

    <Styles.TableCard raised>
      <Styles.ListCardContent>
        <Grid container data-cy="claims_event_container">
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Typography variant="body2" color="textPrimary">Event Type</Typography>
            <Typography variant="body2" color="secondary">
              {claimEventTypeOptions.find(f => f.id === dto.eventTypeId)?.name || "None on File"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Typography variant="body2" color="textPrimary">Issue</Typography>
            <Typography variant="body2" color="secondary">
              {alternativeIssueOptions.find(f => f.id === dto.alternativeIssueId)?.name ||
                separationCodes.find(x => x.id === separationCodeId)?.description ||
                "None on File"}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <Typography variant="body2" color="textPrimary">Completed Date</Typography>
            <Typography variant="body2" color="secondary">{moment(dto.completedDate).format('yyyy-MM-DD') || "None on File"}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={2}>
            <Typography variant="body2" color="textPrimary">Created By</Typography>
            <Typography variant="body2" color="secondary">{dto.createdBy}</Typography>
          </Grid>
        </Grid>

      </Styles.ListCardContent>
    </Styles.TableCard>
  )
};

export default ClaimEventCard;
