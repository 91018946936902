import { combineReducers } from 'redux';
import { createReducer, getType } from 'typesafe-actions';

import {
  getQuestionnaireActions
} from './actions';

import { ClientQuestionnaireInit } from './dto';

const questionnaireReducer = combineReducers({

  questionnaire: createReducer(ClientQuestionnaireInit)
    .handleAction(
      [getQuestionnaireActions.success ], (state, action) => action.payload),

  questionnaireFailed: createReducer('')
    .handleAction([ getQuestionnaireActions.failure ], (state, action) => action.payload),

});


export default questionnaireReducer;