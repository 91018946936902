


import { ISelectOption } from "../dtos/SelectOptionDto";
enum IndicatorTypes {
        Y = 0,
        N = 1,
         
}

export class IndicatorTypes_class {

public static enum = IndicatorTypes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['Y']: 0,
    ['N']: 1,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "Yes",
    [1]: "No",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "Yes"},
  {value: 1, text: "No"},
  ]
}

