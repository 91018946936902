//import { combineReducers } from 'redux';
import { createReducer, getType } from 'typesafe-actions';

import { userLoggedIn, userLoginFail, userXsrfToken, tenantAction } from '../actions/auth.actions';
import { authInit } from '../dtos/auth';

const authReducer = createReducer(authInit)
  .handleType(getType(userLoggedIn), (state, action) => action.payload)
  .handleType(getType(userLoginFail), (state, action) => action.payload);

export default authReducer;

export const token = createReducer('')
  .handleType(getType(userXsrfToken), (state, action) => action.payload);

export const tenant = createReducer('oha')
  .handleType(getType(tenantAction), (state, action) => action.payload);