import React from 'react';
import { HealthSystemInit, HealthSystemDto, healthSystemValidationSchema } from '../healthSystems.dtos';
import { Formik, getIn, FormikHelpers } from 'formik';
import { Dialog, DialogContent, DialogActions, Grid, TextField, DialogTitle } from '@material-ui/core';
import { StyledSubmitButton, StyledCancelButton } from '../../../../common/styles/styles';
import { DialogCardContent } from '../../codes/codes.styles';


interface Props {
  healthGroupIndex: number;
  item: HealthSystemDto;
  open: boolean;
  onClose: () => void;
  setFieldValue: FormikHelpers<HealthSystemDto>["setFieldValue"];
  setShouldBlockNavigation: (val: boolean) => void;
}


const EditHealthGroupDialog: React.FC<Props> = (
  {
    setShouldBlockNavigation,
    healthGroupIndex,
    onClose,
    setFieldValue,
    item,
    open
  }) => {

  const [dto, setDto] = React.useState<HealthSystemDto>(HealthSystemInit);

  React.useEffect(() => {
    setDto(item);
  }, [item, healthGroupIndex]);

  const cancelDialog = () => onClose();

  return <Formik
    initialValues={dto as HealthSystemDto}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={healthSystemValidationSchema}
    onSubmit={(values, { setSubmitting, resetForm }) => {
      setTimeout(() => {
        setSubmitting(false);
        resetForm();
      }, 700);

      setFieldValue(`groups[${healthGroupIndex}].name`, values.groups[healthGroupIndex].name);
      setShouldBlockNavigation(true);
      cancelDialog();
    }}
    enableReinitialize={true}
  >
    {(props) => {
      const {
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
      } = props;
      return (
        <Dialog
          open={open}
          data-cy="healthGroup_edit_dialog"
          fullWidth maxWidth="xs"
        >
          <DialogTitle>Edit Group</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>

              <DialogCardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      name={`groups[${healthGroupIndex}].name`}
                      label="Group Name"
                      onChange={handleChange}
                      fullWidth
                      InputLabelProps={{ required: true }}
                      value={values.groups[healthGroupIndex]?.name || ''}
                      data-cy="healthGroup_edit_name"
                      onBlur={handleBlur}
                      error={Boolean(getIn(errors, `groups[${healthGroupIndex}].name`))}
                      helperText={getIn(errors, `groups[${healthGroupIndex}].name`)}
                    />
                  </Grid>
                  
                </Grid>
              </DialogCardContent>
              <DialogActions>
                <StyledCancelButton
                  data-cy="healthGroup_edit_cancelButton"
                  color="secondary"
                  onClick={cancelDialog}
                >
                  Cancel
                  </StyledCancelButton>

                <StyledSubmitButton
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  data-cy="healthGroup_edit_submitButton"
                >
                  Save
                  </StyledSubmitButton>
              </DialogActions>
            </form>
          </DialogContent>

        </Dialog>
      );
    }}
  </Formik>
}

export default EditHealthGroupDialog;