import React from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router';
import { Formik, getIn } from 'formik';
import * as Yup from 'yup';
import { getType } from 'typesafe-actions';
import { rootSelector } from '../../../common/selectors/selectors';
// material ui
import {
  Grid, Typography, Checkbox, TextField, Button
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { showNotification } from '../../../common/actionCreators/dispatchNotification';
import Label from '../../../common/components/label';
import BottomActionFormBar from '../../../common/components/bottomActionFormBar';
import { contactInit } from '../contacts.dtos';
import { updateContactActions } from '../contacts.actions';
import { getRoleSystemSettingsActions } from '../../settings/system/system.actions';
import { sendPasswordResetActions } from '../contacts.actions';
import { SystemRoleSettingDto } from '../../settings/system/system.dtos';
import { RoleDto } from '../../../common/dtos/role';
import { isAdmin, isReadOnly } from '../../../common/dtos/auth';
import { IndentGrid2, TMGrid } from '../../../common/styles/styles';
import { CheckboxLabel, TabColumnCard } from '../contacts.styles';
import { FeatureTypeEnum } from '../../../common/enums/featureTypeEnum';


const UserSetup: React.FC = () => {
  const dispatch = useDispatch();

  const systemContact = rootSelector(state => state.contacts.contact);
  const systemRoles = rootSelector(state => state.systemSettings.systemRoleSettings);

  const [dto, setDto] = React.useState(contactInit);
  const [roles, setRoles] = React.useState<SystemRoleSettingDto[]>([]);
  const [shouldBlockNavigation, setShouldBlockNavigation] = React.useState<boolean>(false);
  const [userNameOptions, setUserNameOptions] = React.useState<string[]>([]);
  const [readOnlyStatus, setReadOnlyStatus] = React.useState<boolean>(false);
  const [hasClientOnlyRole, setHasClientOnlyRole] = React.useState<boolean>(false);


  React.useEffect(() => {
    let clientRoleCheck = !roles
      .filter(f => f.role.selected)
      .every(s => (s.role?.name ?? '').includes('Client'));
    setHasClientOnlyRole(clientRoleCheck);
  }, [roles]);

  React.useEffect(() => {
    let s = isReadOnly(FeatureTypeEnum.Contacts);
    setReadOnlyStatus(s);
  }, [readOnlyStatus]);

  React.useEffect(() => {
    dispatch({ type: getType(getRoleSystemSettingsActions.request) });
  }, [dispatch]);

  React.useEffect(() => {
    let roles = systemRoles;
    roles?.forEach((sr) => { sr.role.selected = dto.roles?.findIndex((ur: RoleDto) => ur.roleId === sr.role.roleId) > -1; });

    setRoles(roles);
  }, [systemRoles, dto]);

  React.useEffect(() => {
    setDto(systemContact);
    let userNameList = systemContact.emails?.map((email) => email.emailAddress) as string[];

    if (userNameList !== undefined) {
      setUserNameOptions(userNameList);
    }
  }, [systemContact]);


  const handleCancel = () => {
    window.location.href = "/contacts";
  }

  const handleRoleSelection = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
    const element = event.target as HTMLInputElement;
    if (element.value !== undefined) {
      let adminRoles = roles.filter(f => f.role.name === "System Admin" || f.role.name === "MHA Employee Claims").map(m => m.role);
      if ((element.value === "System Admin" || element.value === "MHA Employee Claims") && !isAdmin()) return;
      if (dto.roles.some(s => adminRoles.some(a => a.roleId === s.roleId)) && !isAdmin()) return;

      const sRoleIndex = roles.findIndex((sr: SystemRoleSettingDto) => sr.role.name === element.value);
      if (sRoleIndex > -1) {
        let newRoles: SystemRoleSettingDto[] = JSON.parse(JSON.stringify(roles)) as SystemRoleSettingDto[];
        newRoles[sRoleIndex].role.selected = checked;
        setRoles(newRoles);

        setShouldBlockNavigation(true);
      }
    }
  }

  return <>
    <Formik
      initialValues={dto}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={Yup.object().shape({
        allowUserToSignIn: Yup.boolean(),
        userName: Yup.string().email('Invalid email address').max(500, 'Must be 500 characters or less'),
        mfauserId: Yup.string().max(200, 'Must be 200 characters or less'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => { setSubmitting(false); }, 700);

        // Check to ensure required fields are present.
        if (dto.firstName.length === 0 || dto.lastName.length === 0 || dto.contactId === undefined || dto.contactId.length === 0) {
          showNotification('Please save contact details before attempting to setup user access.');
        }
        else {
          let dtoCopy = values;
          dtoCopy.emails = dtoCopy.emails.filter((e) => e.emailAddress.length !== 0);
          dtoCopy.phones = dtoCopy.phones.filter((p) => p.phoneNumber.length !== 0);
          dtoCopy.notes = dtoCopy.notes.filter((n) => n.entry.length !== 0);
          dtoCopy.addresses = dto.addresses.filter((a) => (a.address1 !== undefined && a.address1!.length !== 0) && (a.city !== undefined && a.city!.length !== 0));
          dtoCopy.roles = roles.filter(sr => sr.role.selected)?.map(r => r.role);
          dtoCopy.contactEmployers = dtoCopy.contactEmployers?.filter(c => (c.name ?? '').length !== 0);

          dispatch({ type: getType(updateContactActions.request), payload: dtoCopy });
          setShouldBlockNavigation(false);
        }
        
      }}
      enableReinitialize={true}
    >
      {(props) => {
        const { values, touched, errors, handleChange, handleSubmit, setFieldValue, handleBlur, isSubmitting } = props;
        return (
          <form onSubmit={handleSubmit}>
            <IndentGrid2 container spacing={2}>

              <Grid item xs={12}>
                <CheckboxLabel
                  label="Allow this user to sign in"
                  control={
                    <Checkbox
                      disableRipple={true}
                      size="medium"
                      name="allowUserToSignIn"
                      onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
                      checked={values.allowUserToSignIn}
                      value={values.allowUserToSignIn}
                      disabled={readOnlyStatus}
                      onChange={handleChange}
                    />
                  }
                  data-cy="userSetup_AllowUserToSignInCheckBox"
                />

              </Grid>

              <Grid container item alignItems="flex-end">
                <Grid item xs={1}>
                  <Label>User Name</Label>
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    key="userNameOptions_field"
                    id="userName"
                    disabled={readOnlyStatus}

                    value={values.userName || ''}
                    options={userNameOptions}
                    onChange={(_e: any, v: string | null) => { setFieldValue('userName', v ?? ''); handleChange(_e); }}
                    onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
                    freeSolo
                    renderInput={(params) =>
                    (<TextField
                      {...params}
                      key='userName_field'
                      id="userName"
                      disabled={readOnlyStatus}
                      InputLabelProps={{ required: true }}
                      size="small"
                      margin="none"
                      value={values.userName || ''}
                      label="User Name"
                      name="userName"
                      onChange={handleChange}
                      onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
                      error={Boolean(getIn(errors, `userName`) && getIn(touched, `userName`))}
                      helperText={(getIn(errors, `userName`) && getIn(touched, `userName`)) && getIn(errors, `userName`)}
                      InputProps={{ ...params.InputProps, }}
                      data-cy='userSetup_userNameTextField'
                    />)}
                  />
                </Grid>
                <Grid item xs={8}></Grid>

                <TMGrid item container alignItems="center">
                  <Grid item xs={1}>
                    <Label>Password</Label>
                  </Grid>
                  <Grid item xs={11} hidden={readOnlyStatus} data-cy="userSetup_sendPassword_grid">
                    <Button color="secondary" data-cy="userSetup_sendPassword_button" disabled={readOnlyStatus} onClick={() => {
                      if (dto.userName !== '') { dispatch({ type: getType(sendPasswordResetActions.request), payload: dto.userName }); }
                      else { alert('Please enter an email.'); }
                    }}
                    >Send Email</Button>
                  </Grid>
                </TMGrid>

                <Grid item xs={1}>
                  <Label>{hasClientOnlyRole ? 'DUO User Name' : 'MFA Secret (Clear to reset. DO NOT CHANGE)'}</Label>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="mfauserId"
                    size="small"
                    margin="none"
                    value={values.mfauserId || ''}
                    name="mfauserId"
                    onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
                    onBlur={handleBlur}
                    error={Boolean(errors.mfauserId)}
                    helperText={errors.mfauserId && errors.mfauserId}
                    data-cy="contact_details_mfauserId"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={8}></Grid>
              </Grid>

              
              <br /><br />
              <br /><br />

              <Grid item container xs={9}>
                <Grid item xs={12}>
                  <Typography variant="body2">User Roles</Typography>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <IndentGrid2 container direction="column">
                    {roles.sort((a, b) => a.role.name.localeCompare(b.role.name))?.map((o, index) => (
                      <Grid item key={o.role.name}>
                        <CheckboxLabel
                          checked={o.role.selected}
                          value={o.role.name}
                          onChange={handleRoleSelection}
                          control={<Checkbox disabled={readOnlyStatus}
                            size="medium" />} label={o.role.name} data-cy="settings_userRole" />
                      </Grid>
                    ))}
                  </IndentGrid2>
                </Grid>

                <TMGrid item xs={12} md={7}>
                  <TabColumnCard>
                    <Typography variant="body2">User Access</Typography>
                    <Label>
                      {Array.from(new Set(roles
                        .filter(sr => sr.role.selected)
                        .map(sr => sr.accessRoles
                          .filter(ua => ua.selected)
                          .map(r => r.name)).flat()))
                        .map(name => (
                          <small key={name}>{name + ', '}</small>
                        ))
                      }
                    </Label>

                    <br /><br />

                    <Typography variant="body2">Report Access</Typography>

                    <Label> {'Management Reports: '}
                      {Array.from(new Set(roles
                        .filter(sr => sr.role.selected)
                        .map(sr => sr.reportManagementRoles
                          .filter(mr => mr.selected)
                          .map(r => r.name)).flat()))
                        .map(name => (
                          <small key={name}>{name + ', '}</small>
                        ))
                      }
                    </Label>

                    <Label> {'Accounting Reports: '}
                      {Array.from(new Set(roles
                        .filter(sr => sr.role.selected)
                        .map(sr => sr.reportAccountingRoles
                          .filter(ar => ar.selected)
                          .map(r => r.name)).flat()))
                        .map(name => (
                          <small key={name}>{name + ', '}</small>
                        ))
                      }
                    </Label>

                  </TabColumnCard>
                </TMGrid>
              </Grid>
            </IndentGrid2>

            <BottomActionFormBar cancelClickHandler={handleCancel} submitting={isSubmitting} featureType={FeatureTypeEnum.Contacts} />

          </form>
        );
      }}
    </Formik>
    <Prompt
      when={shouldBlockNavigation}
      message='You have unsaved edits, are you sure you want to leave?'
    />
    <br /><br />
  </>

}

export default UserSetup;

//& nbsp;
//<Checkbox2
//  disabled={true}
//  control={<Checkbox />}
//  label="" />