import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors, FieldArray } from 'formik';
// material ui
import { Grid, TextField, FormControlLabel } from '@material-ui/core';
import { ClientQuestionnaireDto } from '../dto';
import { radioButtonBooleanOptions } from '../constants/options';
import { MHARadio } from '../../../common/styles/styles';
import MoneyField from '../../../common/components/numberFields';
import SeparationPayment from './separationPayment';


interface Props extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
}

const FirstQuestion: React.FC<Props> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation
  }) => {

  return (
    <React.Fragment>
      <Grid item sm={12} md={12} lg={12}>
        <Grid item xs={12}>Did the claimant receive separation pay, other than regular wages earned (for example: PTO payout, severance pay, notice pay, bonus pay, etc.)?</Grid>
        <Grid container item xs={12} direction="row">
          {radioButtonBooleanOptions.map((o, index) => (
            <Grid item xs={3} key={o.name + index}>
              <FormControlLabel
                id="questionA"
                name="questionA"
                onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                  setFieldValue('questionA', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? false);
                  setShouldBlockNavigation(true);
                }}
                onBlur={handleBlur}
                //add errors for validation
                checked={o.status === values.questionA}
                value={o.name}
                control={<MHARadio
                  size="small" />} label={o.name} data-cy="clientQuestionnaire_questionA" />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <br />
      {values.questionA !== undefined && values.questionA === true ?
        <>
          <Grid item xs={12}>Claimant's weekly average wage?</Grid>
          <Grid item xs={4}>
            <TextField
              id="weeklyAvgWage"
              size="small"
              margin="none"
              value={values.weeklyAvgWage || ''}
              name="weeklyAvgWage"
              multiline
              onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
              onBlur={handleBlur}
              error={Boolean(errors.weeklyAvgWage)}
              helperText={errors.weeklyAvgWage && errors.weeklyAvgWage}
              data-cy="clientQuestionnaire_weeklyAvgWage"
              aria-label="Claimant's weekly average wage"
              InputProps={{ inputComponent: MoneyField as any }}
              fullWidth
            />
          </Grid>
          <br />
          <Grid item xs={12}>Claimant's weekly average hours?</Grid>
          <Grid item xs={4}>
            <TextField
              id="weeklyAvgHours"
              size="small"
              margin="none"
              value={values.weeklyAvgHours || ''}
              name="weeklyAvgHours"
              multiline
              onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
              onBlur={handleBlur}
              error={Boolean(errors.weeklyAvgHours)}
              helperText={errors.weeklyAvgHours && errors.weeklyAvgHours}
              data-cy="clientQuestionnaire_weeklyAvgHours"
              aria-label="Weekly average hours?"
              fullWidth
            />
          </Grid>
          <br />

          {/* Added new separation payments*/}
          <FieldArray name="payments" render={arrayHelpers => (
            <>
              {values.payments &&
                values.payments
                .map((p, i) => (
                  <SeparationPayment key={`separationPayment-${i}`} values={values} errors={errors} handleChange={handleChange}
                    handleBlur={handleBlur} setFieldValue={setFieldValue} setShouldBlockNavigation={setShouldBlockNavigation}
                    payment={p} arrayHelpers={arrayHelpers} index={i} />
                  ))
              }
            </>)} />

        </>

        : null}
    </React.Fragment>
  )
}

export default FirstQuestion;