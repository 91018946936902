import React from 'react';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
import { useHistory, useParams } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { Grid, Typography, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { rootSelector } from '../../common/selectors/selectors';
import TabPanel from '../../common/components/tabPanel';
import ClaimsDetails from './components/details';
import DocumentUpload from './components/claimsDocumentsList';
import ClaimEvents from './components/claimEvents';
import { CompanyDto } from '../company/dtos';
import { ClaimDto, ClaimInit } from './claims.dtos';
import {
  deleteClaimActions,
  getClaimActions,
  getMhaContactsActions,
} from './actions';
import {
  SeparationCodeDto,
  EligibilityCodeDto,
} from '../settings/codes/codes.dtos';
import {
  isClientPortalUser,
  isReadOnly,
  isAuthorized,
  isPaymentsUser,
  isAdmin,
} from '../../common/dtos/auth';
import { documentTypeOptions } from './options';
import BatchPayments from './components/batchPayments';
import ClaimLogPanel from './formComponents/claimLogPanel';
import {
  PGrid,
  ViewGrid,
  ViewCard,
  BlackText,
  ClearAppBar,
  StyledTabs,
  StyledTab,
  StyledCardContent,
  BottomCancelButton,
} from '../../common/styles/styles';
import DeleteClaimDialog from './dialogs/deleteClaimDialog';
import { FeatureTypeEnum } from '../../common/enums/featureTypeEnum';
import { formatStaticSSN } from '../../utils/helperFunctions';

const Claim: React.FC = () => {
  const { claimId } = useParams<{ claimId: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const shouldCollapse = useMediaQuery('(max-width:768px)');

  const systemClaim = rootSelector((state) => state.claims.claim);
  const systemClaimDeleted = rootSelector((state) => state.claims.didDelete);
  const systemSeparationCodes = rootSelector(
    (state) => state.codes.separationCodes
  );
  const systemEligibilityCodes = rootSelector(
    (state) => state.codes.eligibilityCodes
  );
  const systemCompaniesList = rootSelector(
    (state) => state.companies.companiesList
  );
  const systemMhaContacts = rootSelector(
    (state) => state.companies.mhaContacts
  );

  const [isMasked, setIsMasked] = React.useState(false);
  const [dto, setDto] = React.useState(ClaimInit as ClaimDto);
  const [tabValue, setTabValue] = React.useState(0);
  const [sepCodes, setSepCodes] = React.useState([] as SeparationCodeDto[]);
  const [eligibilityCodes, setEligibilityCodes] = React.useState(
    [] as EligibilityCodeDto[]
  );
  const [companies, setCompanies] = React.useState([] as CompanyDto[]);
  const [claimSuta, setClaimSuta] = React.useState('' as string);
  const [deleteDialogOpen, setDeleteDialogOpen] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (systemClaimDeleted === true) {
      handleCloseDeleteDialog();
      dispatch({ type: getType(deleteClaimActions.success), payload: null });
      setTimeout(() => history.push('/claims'), 500);
    } else if (systemClaimDeleted === false) {
      handleOpenDeleteDialog();
      dispatch({ type: getType(deleteClaimActions.success), payload: null });
    }
  }, [systemClaimDeleted, dispatch]);

  React.useEffect(() => {
    if (claimId !== undefined && claimId !== 'new') {
      dispatch({ type: getType(getClaimActions.request), payload: claimId });
    }
  }, [claimId, dispatch]);

  React.useEffect(() => {
    dispatch({ type: getType(getMhaContactsActions.request) });
  }, [dispatch]);

  React.useEffect(() => {
    setCompanies(systemCompaniesList);
    let claim = systemClaim;
    let company = systemCompaniesList.find(
      (o) => o.companyId === claim.employerId
    );
    if (company !== undefined) {
      claim.employerName = company.name;
      setClaimSuta(company.suta);
    }
    setDto(claim);
  }, [systemClaim, systemCompaniesList]);

  React.useEffect(() => {
    setSepCodes(JSON.parse(JSON.stringify(systemSeparationCodes)));
  }, [systemSeparationCodes]);

  React.useEffect(() => {
    setEligibilityCodes(JSON.parse(JSON.stringify(systemEligibilityCodes)));
  }, [systemEligibilityCodes]);

  const changeTab = (event: any, newValue: number) => {
    setTabValue(newValue);
  };

  const handleDelete = () => {
    dispatch({ type: getType(deleteClaimActions.request), payload: [claimId] });
  };

  const handleEmployerNameUpdates = (EmployerName: string) => {
    let d = JSON.parse(JSON.stringify(dto));

    d.employerName = companies.find((o) => o.companyId === EmployerName)?.name;
    let company = companies.find((o) => o.companyId === EmployerName);
    if (company !== undefined) {
      setClaimSuta(company.suta);
    }
    setDto(d);
  };

  const handleClaimantFirstNameUpdates = (ClaimantFirstName: string) => {
    let d = JSON.parse(JSON.stringify(dto));
    d.claimantFirstName = ClaimantFirstName;
    setDto(d);
  };

  const handleClaimantLastNameUpdates = (ClaimantLastName: string) => {
    let d = JSON.parse(JSON.stringify(dto));
    d.claimantLastName = ClaimantLastName;
    setDto(d);
  };

  const handleSSNMaskToggle = () => {
    setIsMasked(!isMasked);
  };

  const handleCloseDeleteDialog = () => setDeleteDialogOpen(false);
  const handleOpenDeleteDialog = () => setDeleteDialogOpen(true);

  const handleGetMaskedSSNValue = (ssn?: string) => {
    if (!!ssn) {
      let v = ssn ?? '';
      if (v.replace('-', '').length > 3) {
        return '***-**-' + v.replace('-', '').slice(-4);
      } else {
        return '***-**-';
      }
    }
  };

  return (
    <>
      {deleteDialogOpen && (
        <DeleteClaimDialog
          open={deleteDialogOpen}
          onCancel={handleCloseDeleteDialog}
          claim={{ ...dto }}
          isReadOnly={isReadOnly(FeatureTypeEnum.Claims)}
        />
      )}
      <ViewGrid container item xs={12}>
        <ViewCard>
          <PGrid container>
            <Grid item md={12} lg={6}>
              <Typography variant='h5'>
                <BlackText>
                  {dto.employerName || ''} - {dto.claimantFirstName || ''}{' '}
                  {dto.claimantLastName || ''} - {claimSuta}{' '}
                </BlackText>
              </Typography>
            </Grid>
            <Grid
              hidden={dto.claimantSsn === undefined || dto.claimantSsn === ''}
              item
              md={6}
              lg={2}
            >
              <Typography
                variant='body2'
                color='textPrimary'
                onClick={() => {
                  handleSSNMaskToggle();
                }}
              >
                SSN
                {isAuthorized() && (
                  <span>
                    {isMasked ? (
                      <IconButton
                        style={{ top: -2, padding: 0, left: 4 }}
                        aria-label='View SSN'
                        title='View SSN'
                        size='small'
                      >
                        <VisibilityIcon color='primary' />
                      </IconButton>
                    ) : (
                      <IconButton
                        style={{ top: -2, padding: 0, left: 4 }}
                        title='Hide SSN'
                        aria-label='Hide SSN'
                        size='small'
                      >
                        <VisibilityOffIcon color='primary' />
                      </IconButton>
                    )}
                  </span>
                )}
              </Typography>
              <Typography variant='body2' color='secondary'>
                {isMasked
                  ? handleGetMaskedSSNValue(dto.claimantSsn)
                  : formatStaticSSN(dto.claimantSsn)}
              </Typography>
            </Grid>
            <Grid
              item
              hidden={
                dto.separationCodeId === undefined ||
                dto.separationCodeId === ''
              }
              md={6}
              lg={2}
            >
              <Typography variant='body2' color='textPrimary'>
                Separation Code
              </Typography>
              <Typography variant='body2' color='secondary'>
                {isClientPortalUser()
                  ? sepCodes.find((o) => o.id === dto.separationCodeId)
                      ?.description || ''
                  : sepCodes.find((o) => o.id === dto.separationCodeId)?.name ||
                    ''}
              </Typography>
            </Grid>

            <Grid
              item
              hidden={
                isReadOnly(FeatureTypeEnum.Claims) || isClientPortalUser()
              }
              md={6}
              lg={2}
            >
              <BottomCancelButton
                type='button'
                onClick={handleDelete}
                data-cy='claimDetails-delete'
              >
                <Typography variant='body2' color='error'>
                  Delete
                </Typography>
              </BottomCancelButton>
            </Grid>
          </PGrid>
          <StyledCardContent>
            <ClearAppBar position='static'>
              <StyledTabs
                aria-labelledby={`tab-${tabValue}`}
                orientation={shouldCollapse ? 'vertical' : 'horizontal'}
                centered={true}
                value={tabValue}
                onChange={changeTab}
                aria-label='Profile options'
                variant='fullWidth'
                scrollButtons='auto'
              >
                <StyledTab
                  id='tab-0'
                  aria-controls='tabpanel-0'
                  label='Details'
                  data-cy='claim-details-tab'
                />
                <StyledTab
                  id='tab-1'
                  aria-controls='tabpanel-1'
                  label='Documents'
                  data-cy='claim-documents-tab'
                />
                {(isPaymentsUser() || isAuthorized()) && (
                  <StyledTab
                    id='tab-2'
                    aria-controls='tabpanel-2'
                    label='Events'
                    data-cy='claim-events-tab'
                  />
                )}
                {(isPaymentsUser() || isAuthorized()) && (
                  <StyledTab
                    id='tab-3'
                    aria-controls='tabpanel-3'
                    label='Payments'
                    data-cy='claim-payments-tab'
                  />
                )}
                <StyledTab
                  id='tab-4'
                  aria-controls='tabpanel-4'
                  label='Claim Log'
                  data-cy='claim-log-tab'
                />
              </StyledTabs>
            </ClearAppBar>

            <TabPanel value={tabValue} index={0}>
              <ClaimsDetails
                isQuickEdit={false}
                isReadOnly={isReadOnly(FeatureTypeEnum.Claims)}
                isClientUser={isClientPortalUser()}
                documentTypeOptions={documentTypeOptions}
                handleEmployerNameUpdates={handleEmployerNameUpdates}
                handleClaimantLastNameUpdates={handleClaimantLastNameUpdates}
                handleClaimantFirstNameUpdates={handleClaimantFirstNameUpdates}
                mhaContacts={systemMhaContacts}
                separationCodes={sepCodes}
                eligibilityCodes={eligibilityCodes}
                close={() => {}}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <DocumentUpload
                isReadOnly={isReadOnly(FeatureTypeEnum.Claims)}
                isClientUser={isClientPortalUser()}
              />
            </TabPanel>

            {(isPaymentsUser() || isAuthorized()) && (
              <TabPanel value={tabValue} index={2}>
                <ClaimEvents
                  isReadOnly={isReadOnly(FeatureTypeEnum.Claims)}
                  isClientUser={isClientPortalUser()}
                  separationCodes={sepCodes}
                  eligibilityCodes={eligibilityCodes}
                />
              </TabPanel>
            )}
            {(isPaymentsUser() || isAuthorized()) && (
              <TabPanel value={tabValue} index={3}>
                <BatchPayments
                  isReadOnly={isReadOnly(FeatureTypeEnum.Claims)}
                  isClientUser={isClientPortalUser()}
                />
              </TabPanel>
            )}
            <TabPanel
              value={tabValue}
              index={isPaymentsUser() || isAuthorized() ? 4 : 2}
            >
              <ClaimLogPanel isClientUser={isClientPortalUser()} />
            </TabPanel>
          </StyledCardContent>
        </ViewCard>
      </ViewGrid>
    </>
  );
};
export default Claim;
