import React from 'react';
import { FormikHandlers, FormikErrors, FormikHelpers } from 'formik';
// material ui
import { Grid, CardContent } from '@material-ui/core';
import { NavCard, NavOptionsGrid } from '../../documents.styles';
import { ISi, ISi_PropertyAttributes } from '../../../../common/dtos/SiDto';
import SiTextField from '../siFieldComponents/SiTextField';
import SiDateField from '../siFieldComponents/SiDateField';
import SiSelectOptions from '../siFieldComponents/SiSelectOptions';
import IndicatorTypeYField from '../siFieldComponents/IndicatorTypeYField';
import { EmployerSepReasonCodes_class } from '../../../../common/enums/EmployerSepReasonCodes';
import IndicatorTypeField from '../siFieldComponents/IndicatorTypeField';
import SiTextAreaField from '../siFieldComponents/SiTextAreaField';
import IndicatorTypeWithUnknownField from '../siFieldComponents/IndicatorTypeWithUnknownField';
import {
  isAgreedReturnToWorkDateInd, isReturnToWorkDateInd, isWhyLeaveOfAbsence, isWhoReducedHours, isPaidDuringSuspensionInd, isReceiveNormalRateOfPayInd, isWhyRetireComments,
  isReceivePension401Kind,
  opEmployerContactName, opEmployerPhone, opEmployerStreetAddress, opEmployerCity, opEmployerState, opEmployerZip, opRepContractTermDate, opWhyRetireComments
} from '../../../../utils';
import { ExtendedStateAbrCodes_class } from '../../../../common/enums/ExtendedStateAbrCodes';
import { Pension401kReceivedCodes_class } from '../../../../common/enums/Pension401kReceivedCodes';
import ClaimantEmployerCodesField from '../siFieldComponents/ClaimantEmployerCodesField';
import phoneNumberField from '../../../../common/components/phoneNumberField';
import { isAdditionalSeparationAckInd, isAttachmentInd, isHiringAgreementChangesCode, isNotWorkingAvailableHoursReason, isRefuseToProvideInd, isWorkingAllAvailableHoursInd } from '../../../../utils/siRequiredPropFunctions';
import { HiringChangesCodes_class } from '../../../../common/enums/HiringChangesCodes';
import { FraudTypeCodes_class } from '../../../../common/enums/FraudTypeCodes';
import { ISelectOption } from '../../../../common/dtos/SelectOptionDto';


interface Props extends React.HTMLProps<React.ReactNode> {
  values: ISi;
  errors: FormikErrors<ISi>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ISi>["setFieldValue"];
  isReadOnly: boolean;
}

const SiGeneral: React.FC<Props> = (
  {
    values,
    errors,
    handleChange,
    isReadOnly,
    handleBlur,
    setFieldValue,
  }) => {

  const [separationCodeOptions, setSeparationCodeOptions] = React.useState<Array<ISelectOption<number>>>(EmployerSepReasonCodes_class.enumAsSelectOptions);

  //React.useEffect(() => {
  //  console.log('errors', errors);
  //}, [errors]);

  React.useEffect(() => {
    handleCheckemployerSepReasonCode();
    if (!isRefuseToProvideInd(values) && values.refuseToProvideInd !== undefined) setFieldValue('refuseToProvideInd', undefined);
    if (!isReturnToWorkDateInd(values) && values.returnToWorkDateInd !== undefined) setFieldValue('returnToWorkDateInd', undefined);
    if (!isWhyLeaveOfAbsence(values) && values.whyLeaveOfAbsence !== undefined) setFieldValue('whyLeaveOfAbsence', undefined);
    if (!isAgreedReturnToWorkDateInd(values) && values.agreedReturnToWorkDateInd !== undefined) setFieldValue('agreedReturnToWorkDateInd', undefined);
    if (!isWhoReducedHours(values) && values.whoReducedHours !== undefined) setFieldValue('whoReducedHours', undefined);
    if (!isPaidDuringSuspensionInd(values) && values.paidDuringSuspensionInd !== undefined) setFieldValue('paidDuringSuspensionInd', undefined);
    if (!isReceiveNormalRateOfPayInd(values) && values.receiveNormalRateOfPayInd !== undefined) setFieldValue('receiveNormalRateOfPayInd', undefined);
    if (!(isWhyRetireComments(values) && !opWhyRetireComments(values)) && values.whyRetireComments !== undefined) setFieldValue('whyRetireComments', undefined);
    if (!opEmployerContactName(values) && values.employerContactName !== undefined) setFieldValue('employerContactName', undefined);
    if (!opEmployerPhone(values) && values.employerPhone !== undefined) setFieldValue('employerPhone', undefined);
    if (!opEmployerStreetAddress(values) && values.employerStreetAddress !== undefined) setFieldValue('employerStreetAddress', undefined);
    if (!opEmployerCity(values) && values.employerCity !== undefined) setFieldValue('employerCity', undefined);
    if (!opEmployerState(values) && values.employerState !== undefined) setFieldValue('employerState', undefined);
    if (!opEmployerZip(values) && values.employerZip !== undefined) setFieldValue('employerZip', undefined);
    if (!opRepContractTermDate(values) && values.repContractTermDate !== undefined) setFieldValue('repContractTermDate', undefined);
    //if (!isReceivePension401Kind(values) && values.receivePension401Kind !== undefined) setFieldValue('receivePension401Kind', undefined);
    if (!isNotWorkingAvailableHoursReason(values) && values.notWorkingAvailableHoursReason !== undefined) setFieldValue('notWorkingAvailableHoursReason', undefined);
    if (!isWorkingAllAvailableHoursInd(values) && values.workingAllAvailableHoursInd !== undefined) setFieldValue('workingAllAvailableHoursInd', undefined);
    if (!isAttachmentInd(values) && values.attachmentInd !== undefined) setFieldValue('attachmentInd', undefined);
  }, [values]);

  const handleCheckemployerSepReasonCode = () => {
    let C = EmployerSepReasonCodes_class.enum;
    if (values.fraudReasons == FraudTypeCodes_class.enum.Item2)
      setSeparationCodeOptions(EmployerSepReasonCodes_class.enumAsSelectOptions
        .filter(f => [C.Item9, C.Item10, C.Item11, C.Item12].findIndex(fi => fi === f.value) !== -1)
      );
    else if (values.temporaryStaffingInd === 0 || values.contractEmployee1099Ind === 0)
      setSeparationCodeOptions(EmployerSepReasonCodes_class.enumAsSelectOptions
        .filter(f => C.Item20 !== f.value)
      );
    else
      setSeparationCodeOptions(EmployerSepReasonCodes_class.enumAsSelectOptions);
  };

  const handlEemployerSepReasonCodeChange = (v: any | null) => {
    let C = EmployerSepReasonCodes_class.enum;
    if (!!v && v.value === C.Item4) {
      setFieldValue('returnToWorkInd', 0);
    }
    else if (!!v && v.value === C.Item6) { //&& isHiringAgreementChangesCode(values)
      setFieldValue('hiringAgreementChangesCode', HiringChangesCodes_class.enum.Item1);
    }
    else if (!!v && v.value === C.Item14) {
      setFieldValue('mandatoryRetirementInd', 1);
    }
  };

  return (
    <Grid container spacing={2} data-cy="sidesResponse_generalInformation_container">

      <NavOptionsGrid item xs={6}> 
        <NavCard>
          <CardContent>
            <Grid container spacing={1}>
              {/*{JSON.stringify(errors)}*/}
              <SiTextField
                label={"Job Title"}
                name={`claimantJobTitle`}
                value={values.claimantJobTitle}
                errors={errors}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                max={ISi_PropertyAttributes.claimantJobTitle_Attributes.stringLength.maxLength}
              />
              <IndicatorTypeField
                label={"Separated on the last day worked"}
                name={`separationDateInd`}
                value={values.separationDateInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
              <SiDateField
                label={"First Day Worked"}
                name={`employerReportedClaimantFirstDayofWork`}
                value={values.employerReportedClaimantFirstDayofWork}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                size={6}
                inputlabelprops={{ shrink: true }}
              />
              <SiDateField
                label={"Last Day Worked"}
                name={`employerReportedClaimantLastDayofWork`}
                value={values.employerReportedClaimantLastDayofWork}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                size={6}
                inputlabelprops={{ shrink: true }}
              />
              <SiDateField
                label={"Return To Work Date"}
                name={`returnToWorkDate`}
                value={values.returnToWorkDate}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                size={6}
                inputlabelprops={{ shrink: true }}
              />
              <SiDateField
                label={values.employerSepReasonCode === EmployerSepReasonCodes_class.enum.Item15 ? "What date did the suspension begin?" : "Effective Sep Date"}
                name={`effectiveSeparationDate`}
                value={values.effectiveSeparationDate}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                size={6}
                inputlabelprops={{ shrink: true }}
              />

              <SiSelectOptions
                label={"Separation Reason"}
                name={`employerSepReasonCode`}
                value={values.employerSepReasonCode}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                options={separationCodeOptions}
                callBack={handlEemployerSepReasonCodeChange}
              />

              {isRefuseToProvideInd(values) &&
                <IndicatorTypeYField
                  label={`Yes, I agree.`}
                  customLabel={`By selecting REFUSE TO PROVIDE, I acknowledge that I have not
provided the requested information either because it is not available,
or I am unwilling or cannot present the information requested. I
understand and agree that the state will proceed in making a
determination based on the information on file. Failure to provide the
information requested may have an adverse effect on the employer
account.`}
                  name={`refuseToProvideInd`}
                  value={values.refuseToProvideInd}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                /> 
              }

              {/*Conditional Questions*/}
              {isAdditionalSeparationAckInd(values) ?
                <IndicatorTypeField
                  label={"I acknowledge the state’s request for additional information."}
                  name={`additionalSeparationAckInd`}
                  value={values.additionalSeparationAckInd}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  allowNull={false}
                /> : null
              }
              {isReturnToWorkDateInd(values) &&
                <IndicatorTypeField
                  label={"Do you know the date the claimant is returning to work?"}
                  name={`returnToWorkDateInd`}
                  value={values.returnToWorkDateInd}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                />
              }
              {isWhyLeaveOfAbsence(values) &&
                <SiTextAreaField
                  label={"Why is the claimant on a leave of absence?"}
                  name={`whyLeaveOfAbsence`}
                  value={values.whyLeaveOfAbsence}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  max={ISi_PropertyAttributes.whyLeaveOfAbsence_Attributes.stringLength.maxLength}
                />
              }
              {isAgreedReturnToWorkDateInd(values) &&
                <IndicatorTypeWithUnknownField
                  label={"When the leave began, was a return to work date agreed upon?"}
                  name={`agreedReturnToWorkDateInd`}
                  value={values.agreedReturnToWorkDateInd}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                />
              }
              {isWhoReducedHours(values) &&
                <ClaimantEmployerCodesField
                  label={"Who reduced the claimant’s hours?"}
                  name={`whoReducedHours`}
                  value={values.whoReducedHours}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                />
              }
              {isPaidDuringSuspensionInd(values) &&
                <IndicatorTypeField
                  label={"Is the claimant being paid during the suspension?"}
                  name={`paidDuringSuspensionInd`}
                  value={values.paidDuringSuspensionInd}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                /> 
              }
              {isReceiveNormalRateOfPayInd(values) &&
                <IndicatorTypeField
                  label={"Is the claimant continuing to receive their normal rate of pay?"}
                  name={`receiveNormalRateOfPayInd`}
                  value={values.receiveNormalRateOfPayInd}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                /> 
              }
              {/*{(isWhyRetireComments(values) && values.mandatoryRetirementInd === 0) &&*/} {/*This is repeated.  Included in MISC and VQ*/}
              {/*  <SiTextField*/}
              {/*    label={"Why did the claimant retire?"}*/}
              {/*    name={`whyRetireComments`}*/}
              {/*    value={values.whyRetireComments}*/}
              {/*  errors={errors}*/}
              {/*  setFieldValue={setFieldValue}*/}
              {/*    handleChange={handleChange}*/}
              {/*    handleBlur={handleBlur}*/}
              {/*    isReadOnly={isReadOnly}*/}
              {/*    max={ISi_PropertyAttributes.whyRetireComments_Attributes.stringLength.maxLength}*/}
              {/*  /> */}
              {/*}*/}
              {opEmployerContactName(values) &&
                <SiTextField
                  label={"Employer contact name"}
                  name={`employerContactName`}
                  value={values.employerContactName}
                errors={errors}
                setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  max={ISi_PropertyAttributes.employerContactName_Attributes.stringLength.maxLength}
                /> 
              }
              {opEmployerPhone(values) &&
                <SiTextField
                  label={"Employer contact phone number"}
                  name={`employerPhone`}
                  value={values.employerPhone}
                errors={errors}
                setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  max={ISi_PropertyAttributes.employerContactName_Attributes.stringLength.maxLength}
                  inputprops={{ inputComponent: (phoneNumberField as any) }}
                /> 
              }
              {opEmployerStreetAddress(values) &&
                <SiTextField
                  label={"Employer street address"}
                  name={`employerStreetAddress`}
                  value={values.employerStreetAddress}
                errors={errors}
                setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  max={ISi_PropertyAttributes.employerStreetAddress_Attributes.stringLength.maxLength}
                /> 
              }
              {opEmployerCity(values) &&
                <SiTextField
                  label={"Employer city"}
                  name={`employerCity`}
                  value={values.employerCity}
                errors={errors}
                setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  max={ISi_PropertyAttributes.employerCity_Attributes.stringLength.maxLength}
                /> 
              }
              {opEmployerState(values) &&
                <SiSelectOptions
                  label={"Employer state"}
                  name={`employerState`}
                  value={values.employerState}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  options={ExtendedStateAbrCodes_class.enumAsSelectOptions}
                /> 
              }
              {opEmployerZip(values) &&
                <SiTextField
                  label={"Employer zip code"}
                  name={`employerZip`}
                  value={values.employerZip}
                errors={errors}
                setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  max={ISi_PropertyAttributes.employerZip_Attributes.stringLength.maxLength}
                /> 
              }
              {opRepContractTermDate(values) &&
                <SiDateField
                  label={"Date of contract termination"}
                  name={`repContractTermDate`}
                  value={values.repContractTermDate}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  inputlabelprops={{ shrink: true }}
                /> 
              }
              {/*{isReceivePension401Kind(values) &&*/}
              {/*  <SiSelectOptions*/}
              {/*    label={"Is or will the claimant receive a company pension and/or 401K disbursement?"}*/}
              {/*    name={`receivePension401Kind`}*/}
              {/*    value={values.receivePension401Kind}*/}
              {/*    errors={errors}*/}
              {/*    setFieldValue={setFieldValue}*/}
              {/*    handleBlur={handleBlur}*/}
              {/*    isReadOnly={isReadOnly}*/}
              {/*    options={Pension401kReceivedCodes_class.enumAsSelectOptions}*/}
              {/*  />*/}
              {/*}*/}

            </Grid>
          </CardContent>
        </NavCard>
      </NavOptionsGrid>

      <NavOptionsGrid item xs={6}>
        <NavCard>
          <CardContent>
            <Grid container spacing={1}>
              <IndicatorTypeField
                label={"Seasonal"}
                name={`seasonalEmploymentInd`}
                value={values.seasonalEmploymentInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
              <IndicatorTypeField
                label={"Does the claimant have reasonable expectation of returning to work?"}
                name={`returnToWorkInd`}
                value={values.returnToWorkInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
              <IndicatorTypeYField
                label={"1099 or Contract Employee"}
                name={`contractEmployee1099Ind`}
                value={values.contractEmployee1099Ind}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
              <IndicatorTypeYField
                label={"Temporary Staffing"}
                name={`temporaryStaffingInd`}
                value={values.temporaryStaffingInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />

              <br></br>

              <SiTextAreaField
                label={"Claimant's Separation Reason Comments"}
                name={`claimantSepReasonComments`}
                value={values.claimantSepReasonComments}
                errors={errors}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isReadOnly={true}
              />

              <SiTextAreaField
                label={"Employer's Separation Reason Comments"}
                label2={"(Don't use VQ and DC - specific sections on those tabs)"}
                name={`employerSepReasonComments`}
                value={values.employerSepReasonComments}
                errors={errors}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                max={ISi_PropertyAttributes.employerSepReasonComments_Attributes.stringLength.maxLength}
              />
            </Grid>
          </CardContent>
        </NavCard>


        {(isWorkingAllAvailableHoursInd(values) || isNotWorkingAvailableHoursReason(values)) &&
          <>
          <br></br>
          <Grid container item xs={12}>
            <NavCard>
              <CardContent>
                <Grid container spacing={1}>
                  {isWorkingAllAvailableHoursInd(values) &&
                    <IndicatorTypeWithUnknownField
                      label={"Is the claimant working all available hours?"}
                      name={`workingAllAvailableHoursInd`}
                      value={values.workingAllAvailableHoursInd}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                    />
                  }
                  
                  {isNotWorkingAvailableHoursReason(values) &&
                    <SiTextAreaField
                      label={"Why is the claimant not working all available hours?"}
                      name={`notWorkingAvailableHoursReason`}
                      value={values.notWorkingAvailableHoursReason}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                      max={ISi_PropertyAttributes.notWorkingAvailableHoursReason_Attributes.stringLength.maxLength}
                    />
                  }

                </Grid>

              </CardContent>
            </NavCard>
          </Grid>
          </>
        }
        
      </NavOptionsGrid>
    </Grid>
  )
}

export default SiGeneral;
