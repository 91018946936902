import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { resetPasswordApi } from './resetPassword.api';
import { resetPasswordActions } from './resetPassword.actions';
import { tenantId } from '../../../common/constants/strings';


function* handleResetPassword(dto: ReturnType<typeof resetPasswordActions.request>) {

  try {

    const response: boolean = yield call(resetPasswordApi, dto.payload);

    if (response) {
      yield put({ type: getType(resetPasswordActions.success), payload: true });
      setTimeout(() => { window.location.href = `/${localStorage.getItem(tenantId) ?? 'mha'}/login`; }, 1500);
    }
    else {
      put({ type: getType(resetPasswordActions.failure), payload: "Reset Password Failed" });
    }

  }
  catch (error) {
    put({ type: getType(resetPasswordActions.failure), payload: "Reset Password Failed" });
  }

}

export function* watchResetPasswordRequest() {
  try {
    yield takeLatest(getType(resetPasswordActions.request), handleResetPassword);
  }
  catch (err) {
  }
}