import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { sidesRepullActions } from './administration.actions';

const administrationReducer = combineReducers({
  sidesRepullResponsesList: createReducer([] as string[]).handleAction(
    [sidesRepullActions.success],
    (state, action) => action.payload
  ),
});

export default administrationReducer;
