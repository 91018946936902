import React from 'react';
import {
  Grid
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import ClientPortalQuestionnaire from './clientPortalQuestionnaire';


const QuestionnaireLinkPage: React.FC = () => {
  const { token } = useParams<{ token: string }>();

  return <Grid container justifyContent="center">
    <Grid item>
      <ClientPortalQuestionnaire token={token} />
    </Grid>
  </Grid>
}

export default QuestionnaireLinkPage;
