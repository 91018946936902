import { ISi } from "../common/dtos/SiDto";
import { EmployerSepReasonCodes_class } from "../common/enums/EmployerSepReasonCodes";
import { NotReturningCodes_class } from "../common/enums/NotReturningCodes";
import { FraudTypeCodes_class } from "../common/enums/FraudTypeCodes";
import { VoluntarySepReasonCodes_class } from "../common/enums/VoluntarySepReasonCodes";
import { DischargeReasonCodes_class } from "../common/enums/DischargeReasonCodes";
import { HiringChangesCodes_class } from "../common/enums/HiringChangesCodes";
import { NotWorkingCodes_class } from "../common/enums/NotWorkingCodes";
import { WagesWeeksNeededCodes_class } from "../common/enums/WagesWeeksNeededCodes";
import { TotalEarnedWagesNeededIndCodes_class } from "../common/enums/TotalEarnedWagesNeededIndCodes";
import { TotalWeeksWorkedNeededIndCodes_class } from "../common/enums/TotalWeeksWorkedNeededIndCodes";
import { Pension401kReceivedCodes_class } from "../common/enums/Pension401kReceivedCodes";
import { FrequencyCodes_class } from "../common/enums/FrequencyCodes";
import { Pension401kCodes_class } from "../common/enums/Pension401kCodes";
import { ContributionCodes_class } from "../common/enums/ContributionCodes";

/*
 * A function for each ISi prop that is conditionally or optionally required from the client. 
 * Returns true or false. 
 * Based on
 * G:\.shortcut-targets-by-id\0B4pGxV3b_knZRlptUWtUR0xfdm8\Clients\Michigan Health and Hospital Association\Unemployment Claims Processing - 24866 25550\Design Specs\R4 - SIDES Re-write\SIDES Separation Information Standard Format.docx
 */
const SC = EmployerSepReasonCodes_class.enum;
const DC = DischargeReasonCodes_class.enum;
const VC = VoluntarySepReasonCodes_class.enum;
const HC = HiringChangesCodes_class.enum;
const PRC = Pension401kReceivedCodes_class.enum;
const FC = FrequencyCodes_class.enum;
const CC = ContributionCodes_class.enum;

export const isAmendedResponseDescription = (v: ISi): boolean => v.respondedDateTime !== undefined;
export const isFraudReasons = (v: ISi): boolean => v.fraudInd === 0;
export const isFraudComments = (v: ISi): boolean => v.fraudReasons === FraudTypeCodes_class.enum.Item5;
//export const isContractEmployee1099Ind = (v: ISi): boolean =>// Has Optional condition
export const isEmployerSepReasonCode = (v: ISi): boolean => v.relationshipEndedInd !== 1;
//export const isTemporaryStaffingInd = (v: ISi): boolean =>// Has Optional condition
export const isEmployerReportedClaimantLastDayofWork = (v: ISi): boolean => {
  if (v.employerSepReasonCode === SC.Item8 && v.whyNotReturning !== NotReturningCodes_class.enum.Item99) return true;
  if (v.employerSepReasonCode !== undefined && [SC.Item8, SC.Item20, SC.Item21, SC.Item23, SC.Item99].findIndex(f => f === v.employerSepReasonCode) === -1) return true;
  return false;
}
export const isSeparationDateInd = (v: ISi): boolean => {
  if (v.relationshipEndedInd === 1 ||
    [SC.Item1, SC.Item4, SC.Item7, SC.Item9, SC.Item10, SC.Item11, SC.Item12, SC.Item13, SC.Item16, SC.Item17, SC.Item20, SC.Item21, SC.Item23]
      .findIndex(f => f === v.employerSepReasonCode) !== -1 ||
    v.whyNotReturning === NotReturningCodes_class.enum.Item16
  ) return false;
  if (v.employerSepReasonCode === SC.Item8 && v.whyNotReturning !== NotReturningCodes_class.enum.Item16 && v.whyNotReturning !== NotReturningCodes_class.enum.Item99) return true;
  if ([SC.Item2, SC.Item3, SC.Item5, SC.Item6, SC.Item8, SC.Item14, SC.Item15, SC.Item18, SC.Item19]
      .findIndex(f => f === v.employerSepReasonCode) !== -1) return true;
  return false;
}
export const isEffectiveSeparationDate = (v: ISi): boolean => v.separationDateInd === 1;
export const isReturnToWorkInd = (v: ISi): boolean => v.employerSepReasonCode === SC.Item4;
export const isReturnToWorkDateInd = (v: ISi): boolean => v.employerSepReasonCode === SC.Item1 || v.returnToWorkInd === 0;
export const isWhyLeaveOfAbsence = (v: ISi): boolean => v.employerSepReasonCode === SC.Item13;
export const isAgreedReturnToWorkDateInd = (v: ISi): boolean => v.employerSepReasonCode === SC.Item13;
export const isReturnToWorkDate = (v: ISi): boolean => v.returnToWorkDateInd === 0 || v.agreedReturnToWorkDateInd === 0;
export const isWhoReducedHours = (v: ISi): boolean => v.employerSepReasonCode === SC.Item11;
export const isWorkingAllAvailableHoursInd = (v: ISi): boolean => [SC.Item10, SC.Item11, SC.Item12].findIndex(f => f === v.employerSepReasonCode) !== -1;
export const isNotWorkingAvailableHoursReason = (v: ISi): boolean => v.workingAllAvailableHoursInd === 1 || v.employerSepReasonCode === SC.Item18 ||
  v.whyNotReturning === NotReturningCodes_class.enum.Item18;
export const isDischargeReasonCode = (v: ISi): boolean => {
  if (v.whyNotReturning === NotReturningCodes_class.enum.Item6 && v.voluntarySepReasonCode === VC.Item5 && v.inLieuInd === 0) return true;
  if (v.whyNotReturning === NotReturningCodes_class.enum.Item3 || v.whyNotReturning === NotReturningCodes_class.enum.Item5) return true;
  if (v.employerSepReasonCode === SC.Item6 && v.voluntarySepReasonCode === VC.Item5 && v.inLieuInd === 0) return true;
  if ([SC.Item3, SC.Item5, SC.Item15].findIndex(f => f === v.employerSepReasonCode) !== -1) return true;
  return false;
}
export const isPaidDuringSuspensionInd = (v: ISi): boolean => v.employerSepReasonCode === SC.Item15;
export const isReceiveNormalRateOfPayInd = (v: ISi): boolean => v.paidDuringSuspensionInd === 0;
export const isInadequatePerfReasonInd = (v: ISi): boolean => v.dischargeReasonCode === DC.Item1;
export const isFinalIncidentDate = (v: ISi): boolean => [DC.Item1, DC.Item2, DC.Item3, DC.Item4, DC.Item5, DC.Item7, DC.Item8, DC.Item9, DC.Item10, DC.Item10, DC.Item11, DC.Item12, DC.Item13].findIndex(f => f === v.dischargeReasonCode) !== -1;
export const isFinalIncidentReason = (v: ISi): boolean => [DC.Item1, DC.Item2, DC.Item3, DC.Item4, DC.Item5, DC.Item7, DC.Item8, DC.Item9, DC.Item10, DC.Item10, DC.Item11, DC.Item12, DC.Item13].findIndex(f => f === v.dischargeReasonCode) !== -1;
export const isMetPastExpections = (v: ISi): boolean => v.dischargeReasonCode === DC.Item1;
export const isReasonForFinalAbsence = (v: ISi): boolean => v.dischargeReasonCode === DC.Item2;
export const isAskedToTestInd = (v: ISi): boolean => [DC.Item4, DC.Item9].findIndex(f => f === v.dischargeReasonCode) !== -1;
export const isTestPerformedInd = (v: ISi): boolean => v.askedToTestInd === 0;
export const isWhyNotPerformed = (v: ISi): boolean => v.testPerformedInd === 1;
export const isWhyRequestedToTest = (v: ISi): boolean => v.askedToTestInd === 0;
//export const isWhoPerformedTest = (v: ISi): boolean => v.testPerformedInd === 0; //Optional if true;
//export const isTesterName = (v: ISi): boolean => v.testPerformedInd === 0; //Optional if true;
//export const isTesterCompanyName = (v: ISi): boolean => v.testPerformedInd === 0; //Optional if true;
//export const isTestResults = (v: ISi): boolean => v.testPerformedInd === 0; //Optional if true;
//export const isChainOfCustodyInd = (v: ISi): boolean => v.testPerformedInd === 0; //Optional if true;
//export const isSecondTestSplitSampleGiven = (v: ISi): boolean => v.testPerformedInd === 0; //Optional if true;
//export const isSecondTestSplitSampleResults = (v: ISi): boolean => v.secondTestSplitSampleGiven === 0; //Optional if true;
export const isJobChangeInd = (v: ISi): boolean => v.metPastExpections === 0;
export const isWhatConditionsChanged = (v: ISi): boolean => v.jobChangeInd === 0;
export const isWhatWasStolen = (v: ISi): boolean => v.dischargeReasonCode === DC.Item10;
export const isActCommittedViolatedLaw = (v: ISi): boolean => v.dischargeReasonCode === DC.Item11;
export const isAdverseEffectOnEmployer = (v: ISi): boolean => [DC.Item5, DC.Item10, DC.Item11].findIndex(f => f === v.dischargeReasonCode) !== -1;
export const isValueAttachedToActInd = (v: ISi): boolean => v.dischargeReasonCode === DC.Item10;
export const isDollarValue = (v: ISi): boolean => v.valueAttachedToActInd === 0; // Has Optional condition
export const isClaimantAdmissionInd = (v: ISi): boolean => [DC.Item10, DC.Item12].findIndex(f => f === v.dischargeReasonCode) !== -1;
export const isEmployerDamage = (v: ISi): boolean => v.claimantAdmissionInd === 1;
export const isDamagesReportedBy = (v: ISi): boolean => v.dischargeReasonCode === DC.Item12;
export const isAltercationOnCompanyPropertyInd = (v: ISi): boolean => v.dischargeReasonCode === DC.Item13;
export const isAltercationOnCompanyTimeInd = (v: ISi): boolean => v.dischargeReasonCode === DC.Item13;
export const isClaimantStartAltercationInd = (v: ISi): boolean => v.dischargeReasonCode === DC.Item13;
export const isClaimantAvoidAltercationInd = (v: ISi): boolean => v.dischargeReasonCode === DC.Item13;
export const isWereThereWitnessesInd = (v: ISi): boolean => [DC.Item10, DC.Item12, DC.Item13].findIndex(f => f === v.dischargeReasonCode) !== -1;
export const isViolateCompanyPolicyInd = (v: ISi): boolean => [DC.Item3, DC.Item4, DC.Item9].findIndex(f => f === v.dischargeReasonCode) !== -1; // Has Optional condition
export const isDischargePolicyAwareInd = (v: ISi): boolean => v.violateCompanyPolicyInd === 0;
export const isDischargePolicyAwareExplanationCode = (v: ISi): boolean => v.dischargePolicyAwareInd === 0;
export const isWhoDischargedName = (v: ISi): boolean => v.dischargeReasonCode !== undefined;
export const isWhoDischargedTitle = (v: ISi): boolean => v.dischargeReasonCode !== undefined;
export const isDischargeReasonComments = (v: ISi): boolean => v.inLieuInd === 1; // Has Optional condition
export const isPriorIncidentInd = (v: ISi): boolean => {
  if (v.whyNotReturning === NotReturningCodes_class.enum.Item6 && v.voluntarySepReasonCode === VC.Item5 && v.inLieuInd === 0) return true;
  if (v.employerSepReasonCode === SC.Item6 && v.voluntarySepReasonCode === VC.Item5 && v.inLieuInd === 0) return true;
  if (v.whyNotReturning === NotReturningCodes_class.enum.Item3 || v.whyNotReturning === NotReturningCodes_class.enum.Item5) return true;
  if ([SC.Item3, SC.Item5, SC.Item15].findIndex(f => f === v.employerSepReasonCode) !== -1) return true;
  return false;
}
export const isVoluntarySepReasonCode = (v: ISi): boolean => v.employerSepReasonCode === SC.Item6 || v.whyNotReturning === NotReturningCodes_class.enum.Item6;
export const isClaimantNotifyOfMedicalIssue = (v: ISi): boolean => v.voluntarySepReasonCode === VC.Item3;
export const isClaimantRequestMedicalLoa = (v: ISi): boolean => v.voluntarySepReasonCode === VC.Item3;
export const isMedicalLoaavailable = (v: ISi): boolean => v.claimantRequestMedicalLoa === 1;
export const isLoaapprovedInd = (v: ISi): boolean => v.claimantRequestMedicalLoa === 0;
export const isWhyLoadenied = (v: ISi): boolean => v.loaapprovedInd === 1;
export const isQuitUnderAdviceOfHealthcareProvider = (v: ISi): boolean => v.voluntarySepReasonCode === VC.Item3;
export const isMedicalStatementProvidedInd = (v: ISi): boolean => v.quitUnderAdviceOfHealthcareProvider === 0;
export const isDutiesClaimantCouldNotPerform = (v: ISi): boolean => v.voluntarySepReasonCode === VC.Item3;
export const isWorkRestrictionsInd = (v: ISi): boolean => v.voluntarySepReasonCode === VC.Item3 || v.receivingWorkersCompensationInd === 0;
export const isClaimantWorkRestrictions = (v: ISi): boolean => v.workRestrictionsInd === 0;
export const isWorkAvailableWithinRestrictionsInd = (v: ISi): boolean => v.voluntarySepReasonCode === VC.Item3 && v.workRestrictionsInd === 0;
export const isOtherWorkOfferedInd = (v: ISi): boolean => v.workAvailableWithinRestrictionsInd === 0;
export const isInLieuInd = (v: ISi): boolean => v.voluntarySepReasonCode === VC.Item5;
/* FailedToReport */
export const isDateFailedToReport = (v: ISi, i: number): boolean => v.voluntarySepReasonCode === VC.Item7 && (v.uisSiFailedToReportOccurrences ?? [])[i]?.dateFailedtoReportUnknown === undefined;
export const isDateFailedtoReportUnknown = (v: ISi, i: number): boolean => v.voluntarySepReasonCode === VC.Item7 && (v.uisSiFailedToReportOccurrences ?? [])[i]?.dateFailedToReport === undefined;

export const isContinuingWorkAvailableInd = (v: ISi): boolean => [VC.Item1, VC.Item2, VC.Item4, VC.Item6, VC.Item7, VC.Item8, VC.Item9, VC.Item10]
  .findIndex(f => f === v.voluntarySepReasonCode) !== -1 || v.mandatoryRetirementInd === 1; // Has Optional condition
export const isReasonDissatisfied = (v: ISi): boolean => v.voluntarySepReasonCode === VC.Item4;
export const isWorkingConditions = (v: ISi): boolean => v.voluntarySepReasonCode === VC.Item9;
export const isWorkingConditionsChangedInd = (v: ISi): boolean => v.voluntarySepReasonCode === VC.Item9;
export const isChangesPermanentInd = (v: ISi): boolean => v.workingConditionsChangedInd === 0;
export const isChangesScheduledToLast = (v: ISi): boolean => v.changesPermanentInd === 1;
export const isMandatoryRetirementInd = (v: ISi): boolean => v.employerSepReasonCode === SC.Item14 || v.whyNotReturning === NotReturningCodes_class.enum.Item14;
export const isWhyRetireComments = (v: ISi): boolean => v.employerSepReasonCode === SC.Item14 || v.whyNotReturning === NotReturningCodes_class.enum.Item14; // Has Optional condition
export const isHiringAgreementChangesCode = (v: ISi): boolean => [VC.Item1, VC.Item2, VC.Item3, VC.Item4, VC.Item6, VC.Item10].findIndex(f => f === v.voluntarySepReasonCode) !== -1; // Has Optional condition
export const isHiringAgreementChangesComments = (v: ISi): boolean => [HC.Item2, HC.Item3, HC.Item4, HC.Item5, HC.Item6].findIndex(f => f === v.hiringAgreementChangesCode) !== -1;
export const isEffectiveDateOfChanges = (v: ISi): boolean => [HC.Item2, HC.Item3, HC.Item4, HC.Item5, HC.Item6].findIndex(f => f === v.hiringAgreementChangesCode) !== -1;
export const isClaimantActionsToAvoidQuitInd = (v: ISi): boolean => [VC.Item1, VC.Item2, VC.Item3, VC.Item4, VC.Item6, VC.Item7, VC.Item9, VC.Item10]
  .findIndex(f => f === v.voluntarySepReasonCode) !== -1;
export const isActionTakenComments = (v: ISi): boolean => v.claimantActionsToAvoidQuitInd === 0;
export const isClaimantContactToExplainInd = (v: ISi): boolean => v.voluntarySepReasonCode === VC.Item7;
export const isDateMadeContact = (v: ISi): boolean => v.claimantContactToExplainInd === 0;
export const isReasonForFailToReport = (v: ISi): boolean => v.claimantContactToExplainInd === 0;
export const isClaimantNoticeInd = (v: ISi): boolean => [VC.Item1, VC.Item2, VC.Item3, VC.Item4, VC.Item6, VC.Item9, VC.Item10]
  .findIndex(f => f === v.voluntarySepReasonCode) !== -1;
export const isDateNoticeGiven = (v: ISi): boolean => v.claimantNoticeInd === 0 && v.dateNoticeGivenUnknown === undefined;
export const isDateNoticeGivenUnknown = (v: ISi): boolean => v.claimantNoticeInd === 0 && v.dateNoticeGiven === undefined;
export const isClaimantProvidedIntendedLastDayWork = (v: ISi): boolean => v.claimantNoticeInd === 0 && v.claimantProvidedIntendedLastDayWorkUnknown === undefined;
export const isClaimantProvidedIntendedLastDayWorkUnknown = (v: ISi): boolean => v.claimantNoticeInd === 0 && v.claimantProvidedIntendedLastDayWork === undefined;
export const isClaimantWorkOutNoticeInd = (v: ISi): boolean => v.claimantNoticeInd === 0;
export const isWhyNoticeWasntWorkedOut = (v: ISi): boolean => v.claimantWorkOutNoticeInd === 1;
export const isClaimantPaidThroughNoticeInd = (v: ISi): boolean => v.claimantWorkOutNoticeInd === 1;
export const isVoluntarySepReasonComments = (v: ISi): boolean => v.voluntarySepReasonCode === VC.Item10; // Has Optional condition
export const isWhyNotReturning = (v: ISi): boolean => v.employerSepReasonCode === SC.Item8; 
export const isFirstDayOfContract = (v: ISi): boolean => v.employerSepReasonCode === SC.Item17;
export const isLastDayOfContract = (v: ISi): boolean => v.employerSepReasonCode === SC.Item17;
export const isReasonForBreak = (v: ISi): boolean => v.employerSepReasonCode === SC.Item7;
export const isFirstDayOfBreak = (v: ISi): boolean => v.employerSepReasonCode === SC.Item7; // Has Optional condition
export const isLastDayOfBreak = (v: ISi): boolean => v.employerSepReasonCode === SC.Item7; // Has Optional condition
//export const isWorkContinueDuringBreakInd = (v: ISi): boolean => // Has Optional condition
export const isPositionReturningTo = (v: ISi): boolean => v.employerSepReasonCode === SC.Item7;
export const isNewContractJobOfferInd = (v: ISi): boolean => v.employerSepReasonCode === SC.Item17; // Has Optional condition
export const isPersonOfferedReasonableAssurance = (v: ISi): boolean =>
  v.newContractJobOfferInd === 0 || v.notWorkingOnAssignment === NotWorkingCodes_class.enum.Item3;
export const isHaveAuthorityToOfferInd = (v: ISi): boolean => v.newContractJobOfferInd === 0;
export const isHowOfferConveyed = (v: ISi): boolean => v.newContractJobOfferInd === 0 || v.notWorkingOnAssignment === NotWorkingCodes_class.enum.Item3;
export const isWhyContractNotOffered = (v: ISi): boolean => v.newContractJobOfferInd === 1;
export const isContractAccepted = (v: ISi): boolean => v.newContractJobOfferInd === 0;
export const isLaborDisputeTypeInd = (v: ISi): boolean => v.employerSepReasonCode === SC.Item16 || v.whyNotReturning === NotReturningCodes_class.enum.Item16;
//export const isLocationOfLaborDispute = (v: ISi): boolean => // Has Optional condition
//export const isUnionNameAndLocalNo = (v: ISi): boolean => // Has Optional condition
export const isDateDisputeBegan = (v: ISi): boolean => v.employerSepReasonCode === SC.Item16 || v.whyNotReturning === NotReturningCodes_class.enum.Item16;
export const isLaborDisputeEndedInd = (v: ISi): boolean => v.employerSepReasonCode === SC.Item16 || v.whyNotReturning === NotReturningCodes_class.enum.Item16;
export const isDateDisputeEnded = (v: ISi): boolean => v.laborDisputeEndedInd === 0;
export const isReplacementWorkersHiredInd = (v: ISi): boolean => v.employerSepReasonCode === SC.Item16 || v.whyNotReturning === NotReturningCodes_class.enum.Item16;
export const isClmtStrikingUnionMemberInd = (v: ISi): boolean => v.employerSepReasonCode === SC.Item16 || v.whyNotReturning === NotReturningCodes_class.enum.Item16;
//export const isclmtMemberAnotherUnionInd = (v: ISi): boolean => // Has Optional condition
export const isWhyUnemployedLaborDispute = (v: ISi): boolean => v.clmtStrikingUnionMemberInd === 1;
//export const isSettlementAffectClmtInd = (v: ISi): boolean => // Has Optional condition
//export const isHowSettlementAffects = (v: ISi): boolean => // Has Optional condition
export const isRelationshipEndedInd = (v: ISi): boolean => v.temporaryStaffingInd === 0;
export const isWorkingNewAssignmentInd = (v: ISi): boolean => v.relationshipEndedInd === 1;
export const isNotWorkingOnAssignment = (v: ISi): boolean => v.workingNewAssignmentInd === 1;
export const isTempAgencyPolicyInd = (v: ISi): boolean => [NotWorkingCodes_class.enum.Item2, NotWorkingCodes_class.enum.Item4,].findIndex(f => f === v.notWorkingOnAssignment) !== -1;
export const isTempPolicyAwarenessCode = (v: ISi): boolean => v.tempAgencyPolicyInd === 0;
export const isTempAssignmentLastDay = (v: ISi): boolean => [NotWorkingCodes_class.enum.Item2, NotWorkingCodes_class.enum.Item4,].findIndex(f => f === v.notWorkingOnAssignment) !== -1;
/* PreviousAssignment */
export const isPrevTempAssignmentStartDate = (v: ISi): boolean => true;
export const isPrevTempAssignmentEndDate = (v: ISi): boolean => true;
export const isPrevTempAssignmentJobDuties = (v: ISi): boolean => true;
export const isPrevTempAssignmentRateOfPay = (v: ISi): boolean => true;
export const isPrevTempAssignmentCommutingDistance = (v: ISi): boolean => true;
export const isPrevTempAssignmentHours = (v: ISi): boolean => true;

export const isNewTempAssignmentDateOfferMade = (v: ISi): boolean => v.notWorkingOnAssignment === NotWorkingCodes_class.enum.Item3;
export const isNewTempAssignmentStartDate = (v: ISi): boolean => v.notWorkingOnAssignment === NotWorkingCodes_class.enum.Item3;
export const isNewTempAssignmentJobDuties = (v: ISi): boolean => v.notWorkingOnAssignment === NotWorkingCodes_class.enum.Item3;
export const isNewTempAssignmentRateOfPay = (v: ISi): boolean => v.notWorkingOnAssignment === NotWorkingCodes_class.enum.Item3;
export const isNewTempAssignmentCommutingDistance = (v: ISi): boolean => v.notWorkingOnAssignment === NotWorkingCodes_class.enum.Item3;
export const isNewTempAssignmentHours = (v: ISi): boolean => v.notWorkingOnAssignment === NotWorkingCodes_class.enum.Item3;
export const isNewTempAssignmentWhyRefused = (v: ISi): boolean => v.notWorkingOnAssignment === NotWorkingCodes_class.enum.Item3;
export const isNewTempAssignmentDateRefused = (v: ISi): boolean => v.notWorkingOnAssignment === NotWorkingCodes_class.enum.Item3;
export const isTempAssignmentWhoEnded = (v: ISi): boolean => v.notWorkingOnAssignment === NotWorkingCodes_class.enum.Item4;
export const isTempAssignmentWhyNotCompleted = (v: ISi): boolean => v.notWorkingOnAssignment === NotWorkingCodes_class.enum.Item4;
export const isTempAssignmentClaimantContactAgencyInd = (v: ISi): boolean => v.notWorkingOnAssignment === NotWorkingCodes_class.enum.Item4;
export const isTempAssignmentWorkAvailableInd = (v: ISi): boolean => v.tempAssignmentClaimantContactAgencyInd === 1;
export const isEmployerSepReasonComments = (v: ISi): boolean => {
  if (v.employerSepReasonCode === SC.Item1 && v.returnToWorkDateInd === 1) return true;
  if (v.employerSepReasonCode === SC.Item4) return true;
  if ([SC.Item9, SC.Item10, SC.Item11, SC.Item12, SC.Item13, SC.Item19].findIndex(f => f === v.employerSepReasonCode) !== -1) return true;
  if (v.whyNotReturning === NotReturningCodes_class.enum.Item19) return true;
  return false;
}
export const isRefuseToProvideInd = (v: ISi): boolean => v.employerSepReasonCode === SC.Item99 || v.whyNotReturning === NotReturningCodes_class.enum.Item99;
//export const isEmployerContactName = (v: ISi): boolean => // Has Optional condition
//export const isEmployerPhone = (v: ISi): boolean => // Has Optional condition
//export const isEmployerStreetAddress = (v: ISi): boolean => // Has Optional condition
//export const isEmployerCity = (v: ISi): boolean => // Has Optional condition
//export const isEmployerState = (v: ISi): boolean => // Has Optional condition
//export const isEmployerZIP = (v: ISi): boolean => // Has Optional condition
//export const isRepContractTermDate = (v: ISi): boolean => // Has Optional condition
export const isTotalEarnedWagesNeededInd = (v: ISi): boolean => v.wagesNeededBeginDate !== undefined && v.wagesNeededEndDate !== undefined && 
  [WagesWeeksNeededCodes_class.enum.WW, WagesWeeksNeededCodes_class.enum.WO].findIndex(f => f === v.wagesWeeksNeededCode) !== -1 &&
  [SC.Item20, SC.Item21, SC.Item23].findIndex(f => f === v.employerSepReasonCode) === -1;
export const isTotalEarnedWages = (v: ISi): boolean => v.totalEarnedWagesNeededInd === TotalEarnedWagesNeededIndCodes_class.enum.Item1;
export const isTotalWeeksWorkedNeededInd = (v: ISi): boolean => v.wagesNeededBeginDate !== undefined && v.wagesNeededEndDate !== undefined &&
  [WagesWeeksNeededCodes_class.enum.WW].findIndex(f => f === v.wagesWeeksNeededCode) !== -1 && // WagesWeeksNeededCodes_class.enum.WO, KK: updated BR
 [SC.Item20, SC.Item21, SC.Item23].findIndex(f => f === v.employerSepReasonCode) === -1;
export const isTotalWeeksWorked = (v: ISi): boolean => v.totalWeeksWorkedNeededInd === TotalWeeksWorkedNeededIndCodes_class.enum.Item1;
//export const isWagesEarnedAfterClaimEffectiveDate = (v: ISi): boolean => // Has Optional condition
//export const isNumberOfHoursWorkedAfterClaimEffectiveDate = (v: ISi): boolean => // Has Optional condition
export const isRegularRateofPay = (v: ISi): boolean => v.remunerationInd === 0 || [PRC.Item1, PRC.Item2, PRC.Item3].findIndex(f => f === v.receivePension401Kind) !== -1;
export const isRateofPayPerPeriod = (v: ISi): boolean => v.remunerationInd === 0 || [PRC.Item1, PRC.Item2, PRC.Item3].findIndex(f => f === v.receivePension401Kind) !== -1;
export const isAverageNumberofHoursWorkedperWeek = (v: ISi): boolean => v.remunerationInd === 0 || [PRC.Item1, PRC.Item2, PRC.Item3].findIndex(f => f === v.receivePension401Kind) !== -1;
export const isCustomaryWeeklyWorkSchedule = (v: ISi): boolean => v.remunerationInd === 0 || [PRC.Item1, PRC.Item2, PRC.Item3].findIndex(f => f === v.receivePension401Kind) !== -1;
export const isRemunerationInd = (v: ISi): boolean => [SC.Item20, SC.Item21, SC.Item23].findIndex(f => f === v.employerSepReasonCode) === -1; //v.employerSepReasonCode !== undefined && 
/* Remuneration */
export const isRemunerationTypeCode = (v: ISi): boolean => true;
export const isEmployerAllocationInd = (v: ISi): boolean => v.allocationRequiredInd === 0;
export const isAllocationBeginDate = (v: ISi, i: number): boolean => v.uisSiRemunerationOccurrences !== undefined && v.uisSiRemunerationOccurrences[i]?.employerAllocationInd === 0 &&
  v.uisSiRemunerationOccurrences[i]?.allocationBeginDateUnknownInd === undefined;
export const isAllocationBeginDateUnknownInd = (v: ISi, i: number): boolean => v.uisSiRemunerationOccurrences !== undefined && v.uisSiRemunerationOccurrences[i]?.employerAllocationInd === 0 &&
  v.uisSiRemunerationOccurrences[i]?.allocationBeginDate === undefined;
export const isAllocationEndDate = (v: ISi, i: number): boolean => v.uisSiRemunerationOccurrences !== undefined && v.uisSiRemunerationOccurrences[i]?.employerAllocationInd === 0 &&
  v.uisSiRemunerationOccurrences[i]?.allocationEndDateUnknownInd === undefined;
export const isRemunerationPeriodFrequencyCode = (v: ISi, i: number): boolean => true;
export const isRemunerationAmountPerPeriod = (v: ISi, i: number): boolean => [FC.D, FC.W, FC.B, FC.S, FC.M, FC.Q, FC.L]
  .findIndex(f => f === (v.uisSiRemunerationOccurrences !== undefined ? (v.uisSiRemunerationOccurrences[i]?.remunerationPeriodFrequencyCode ?? -1) : -1)) !== -1; // Has Optional condition
export const isNumberHoursRepresented = (v: ISi, i: number): boolean => v.uisSiRemunerationOccurrences !== undefined && v.uisSiRemunerationOccurrences[i]?.remunerationTypeCode !== undefined &&
  [FC.D, FC.W, FC.B, FC.S, FC.M, FC.Q, FC.L]
    .findIndex(f => f === (v.uisSiRemunerationOccurrences !== undefined ? (v.uisSiRemunerationOccurrences[i]?.remunerationPeriodFrequencyCode ?? -1) : -1)) !== -1; // Has Optional condition
export const isDateRemunerationIssued = (v: ISi, i: number): boolean => v.uisSiRemunerationOccurrences !== undefined && v.uisSiRemunerationOccurrences[i]?.remunerationTypeCode !== undefined &&
  [FC.D, FC.W, FC.B, FC.S, FC.M, FC.Q, FC.L]
    .findIndex(f => f === (v.uisSiRemunerationOccurrences !== undefined ? (v.uisSiRemunerationOccurrences[i]?.remunerationPeriodFrequencyCode ?? -1) : -1)) !== -1; // Has Optional condition
//export const isRemunerationComments = (v: ISi): boolean => // Has Optional condition

//export const isReceivingWorkersCompensationInd = (v: ISi): boolean => // Has Optional condition
export const isDateOfInjury = (v: ISi): boolean => v.receivingWorkersCompensationInd === 0;
export const isDateAppliedForWorkersCompKnownInd = (v: ISi): boolean => v.receivingWorkersCompensationInd === 0;
export const isDateAppliedForWorkersComp = (v: ISi): boolean => v.dateAppliedForWorkersCompKnownInd === 0;
export const isTypeOfWorkersCompReceiving = (v: ISi): boolean => v.receivingWorkersCompensationInd === 0;
//export const isWeeklyWorkersCompRate = (v: ISi): boolean => // Has Optional condition
//export const isPeriodOfTimeWorkersCompPaymentCovers = (v: ISi): boolean => // Has Optional condition
export const isClaimantReleasedToReturnToWork = (v: ISi): boolean => v.receivingWorkersCompensationInd === 0;
export const isDateClaimantReleased = (v: ISi): boolean => v.claimantReleasedToReturnToWork === 0;
//export const isEmployerAccommodateRestrictionsInd = (v: ISi): boolean => // Has Optional condition
//export const isWhyEmployerCouldNotAccommodate = (v: ISi): boolean => // Has Optional condition
//export const isWorkersCompComments = (v: ISi): boolean => // Has Optional condition
export const isReceivePension401Kind = (v: ISi): boolean => [SC.Item20, SC.Item21, SC.Item23].findIndex(f => f === v.employerSepReasonCode) === -1; //v.employerSepReasonCode !== undefined && 

/* Pension401 */
export const isPension401Kind = (v: ISi, i: number): boolean => true;
export const isPension401KfrequencyCode = (v: ISi, i: number): boolean => v.uisSiPension401Koccurrences !== undefined &&
  (v.uisSiPension401Koccurrences[i]?.pension401Kind === Pension401kCodes_class.enum.Item1 || v.paidOut401KtoClaimant === 0);
export const isPension401KamountPerPeriod = (v: ISi, i: number): boolean => v.uisSiPension401Koccurrences !== undefined &&
  (v.uisSiPension401Koccurrences[i]?.pension401KamountPerPeriodUnknown === undefined && v.uisSiPension401Koccurrences[i]?.pension401KfrequencyCode !== undefined
    && v.uisSiPension401Koccurrences[i]?.pension401KfrequencyCode !== FC.Z);
export const isPension401KamountPerPeriodUnknown = (v: ISi, i: number): boolean => v.uisSiPension401Koccurrences !== undefined &&
  (v.uisSiPension401Koccurrences[i]?.pension401KamountPerPeriod === undefined && v.uisSiPension401Koccurrences[i]?.pension401KfrequencyCode !== undefined);
export const isDatePension401Kissued = (v: ISi, i: number): boolean => v.uisSiPension401Koccurrences !== undefined &&
  (v.uisSiPension401Koccurrences[i]?.datePension401KissuedUnknown === undefined && v.uisSiPension401Koccurrences[i]?.pension401KfrequencyCode !== undefined
    && v.uisSiPension401Koccurrences[i]?.pension401KfrequencyCode !== FC.Z); // Has Optional condition
export const isDatePension401KissuedUnknown = (v: ISi, i: number): boolean => v.uisSiPension401Koccurrences !== undefined &&
  (v.uisSiPension401Koccurrences[i]?.datePension401Kissued === undefined && v.uisSiPension401Koccurrences[i]?.pension401KfrequencyCode !== undefined
    && v.uisSiPension401Koccurrences[i]?.pension401KfrequencyCode !== FC.Z); // Has Optional condition
export const isWhoContributedPension401K = (v: ISi, i: number): boolean => v.uisSiPension401Koccurrences !== undefined &&
  v.uisSiPension401Koccurrences[i]?.pension401KfrequencyCode !== undefined;
export const isClaimantPensionContributionPercent = (v: ISi, i: number): boolean => v.uisSiPension401Koccurrences !== undefined &&
  [CC.C, CC.B]
    .findIndex(f => f === (v.uisSiPension401Koccurrences !== undefined ? (v.uisSiPension401Koccurrences[i]?.whoContributedPension401K ?? -1) : -1)) !== -1 &&
  v.uisSiPension401Koccurrences[i]?.claimantPension401KcontributionPercentUnknown === undefined;
export const isClaimantPension401KcontributionPercentUnknown = (v: ISi, i: number): boolean => v.uisSiPension401Koccurrences !== undefined &&
  [CC.C, CC.B]
    .findIndex(f => f === (v.uisSiPension401Koccurrences !== undefined ? (v.uisSiPension401Koccurrences[i]?.whoContributedPension401K ?? -1) : -1)) !== -1 &&
  v.uisSiPension401Koccurrences[i]?.claimantPensionContributionPercent === undefined;
export const isEmployerPension401KcontributionPercent = (v: ISi, i: number): boolean => v.uisSiPension401Koccurrences !== undefined &&
  [CC.E, CC.B]
    .findIndex(f => f === (v.uisSiPension401Koccurrences !== undefined ? (v.uisSiPension401Koccurrences[i]?.whoContributedPension401K ?? -1) : -1)) !== -1 &&
  v.uisSiPension401Koccurrences[i]?.employerPension401KcontributionPercentUnknown === undefined;
export const isEmployerPension401KcontributionPercentUnknown = (v: ISi, i: number): boolean => v.uisSiPension401Koccurrences !== undefined &&
  [CC.E, CC.B]
    .findIndex(f => f === (v.uisSiPension401Koccurrences !== undefined ? (v.uisSiPension401Koccurrences[i]?.whoContributedPension401K ?? -1) : -1)) !== -1 &&
  v.uisSiPension401Koccurrences[i]?.employerPension401KcontributionPercent === undefined;
export const isEmployerLastContributionDate = (v: ISi, i: number): boolean => v.uisSiPension401Koccurrences !== undefined &&
  [CC.E, CC.B]
    .findIndex(f => f === (v.uisSiPension401Koccurrences !== undefined ? (v.uisSiPension401Koccurrences[i]?.whoContributedPension401K ?? -1) : -1)) !== -1 &&
  v.uisSiPension401Koccurrences[i]?.employerLastContributionDateUnknown === undefined;
export const isEmployerLastContributionDateUnknown = (v: ISi, i: number): boolean => v.uisSiPension401Koccurrences !== undefined &&
  [CC.E, CC.B]
    .findIndex(f => f === (v.uisSiPension401Koccurrences !== undefined ? (v.uisSiPension401Koccurrences[i]?.whoContributedPension401K ?? -1) : -1)) !== -1 &&
  v.uisSiPension401Koccurrences[i]?.employerLastContributionDate === undefined;
//export const isPension401Kcomments = (v: ISi): boolean => // Has Optional condition

export const isMandatoryPension = (v: ISi): boolean => v.receivePension401Kind === PRC.Item1 || v.receivePension401Kind === PRC.Item3;
export const isFullAmount401KrolledOver = (v: ISi): boolean => v.receivePension401Kind === PRC.Item2 || v.receivePension401Kind === PRC.Item3;
export const isPercentage401KrolledOver = (v: ISi): boolean => v.fullAmount401KrolledOver === 1 && v.percentage401KrolledOverUnknown === undefined;
export const isPercentage401KrolledOverUnknown = (v: ISi): boolean => v.fullAmount401KrolledOver === 1 && v.percentage401KrolledOver === undefined;
export const isPaidOut401KtoClaimant = (v: ISi): boolean => v.fullAmount401KrolledOver === 1;

/* ExternalBusinessContactInformation */
export const isExternalContactTypeInd = (v: ISi, i: number): boolean => true;
export const isExternalBusinessCompanyName = (v: ISi, i: number): boolean => v.uisSiContactOccurrences !== undefined &&
  (v.uisSiContactOccurrences[i]?.externalBusinessContactName === undefined && v.uisSiContactOccurrences[i]?.externalBusinessEmail === undefined
    && v.uisSiContactOccurrences[i]?.externalBusinessPhone === undefined);
export const isExternalBusinessContactName = (v: ISi, i: number): boolean => v.uisSiContactOccurrences !== undefined &&
  (v.uisSiContactOccurrences[i]?.externalBusinessCompanyName === undefined && v.uisSiContactOccurrences[i]?.externalBusinessEmail === undefined
    && v.uisSiContactOccurrences[i]?.externalBusinessPhone === undefined);
export const isExternalBusinessEmail = (v: ISi, i: number): boolean => v.uisSiContactOccurrences !== undefined &&
  (v.uisSiContactOccurrences[i]?.externalBusinessContactName === undefined && v.uisSiContactOccurrences[i]?.externalBusinessCompanyName === undefined
    && v.uisSiContactOccurrences[i]?.externalBusinessPhone === undefined);
export const isExternalBusinessPhone = (v: ISi, i: number): boolean => v.uisSiContactOccurrences !== undefined &&
  (v.uisSiContactOccurrences[i]?.externalBusinessContactName === undefined && v.uisSiContactOccurrences[i]?.externalBusinessEmail === undefined
    && v.uisSiContactOccurrences[i]?.externalBusinessCompanyName === undefined);

/* PriorIncidentOccurrence */

export const isAdditionalSeparationAckInd = (v: ISi): boolean => v.additionalSeparationAckInd !== undefined && [SC.Item20, SC.Item21, SC.Item23].indexOf(v.employerSepReasonCode ?? -1) === -1;
export const isAttachmentInd = (v: ISi): boolean => [SC.Item20, SC.Item21, SC.Item23].indexOf(v.employerSepReasonCode ?? -1) === -1;
/* Attachment */
// Handled automatically

/* Preparer */
// Handled automatically

/* Arrays */
export const isUisSiWitnessInformationOccurrences = (v: ISi): boolean => v.wereThereWitnessesInd === 0;
export const isUisSiPriorIncidentOccurrences = (v: ISi): boolean => v.priorIncidentInd === 0;
export const isUisSiFailedToReportOccurrences = (v: ISi): boolean => v.voluntarySepReasonCode === VC.Item7;
export const isUisSiPreviousAssignmentOccurrences = (v: ISi): boolean => v.notWorkingOnAssignment === NotWorkingCodes_class.enum.Item3;
export const isUisSiRemunerationOccurrences = (v: ISi): boolean => v.remunerationInd === 0;
export const isUisSiPension401Koccurrences = (v: ISi): boolean => [PRC.Item1, PRC.Item2, PRC.Item3].indexOf(v.receivePension401Kind ?? -1) !== -1;