import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
import { rootSelector } from '../../common/selectors/selectors';
import { BMGrid, ListCardContent, TableCard } from '../../common/styles/styles';
import { sidesRepullActions } from './administration.actions';
import {
  SidesRepullDto,
  sidesRepullInit,
  sidesRepullValidationSchema,
} from './administration.dtos';
import moment from 'moment';

const Administration: React.FC = () => {
  const dispatch = useDispatch();

  const sidesRepullResponses = rootSelector(
    (state) => state.administration.sidesRepullResponsesList
  );

  const [sidesRepullDto, setSidesRepullDto] = React.useState<SidesRepullDto>({
    ...sidesRepullInit,
  });

  return (
    <Formik
      initialValues={sidesRepullDto}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={sidesRepullValidationSchema}
      onSubmit={(values) => {
        dispatch({
          type: getType(sidesRepullActions.request),
          payload: values,
        });
      }}
      enableReinitialize={true}
    >
      {(props) => {
        const { values, touched, errors, handleSubmit, setFieldValue } = props;
        return (
          <Box p={2}>
            <Grid container item xs={11}>
              <BMGrid item xs={12}>
                <Typography variant='subtitle1' display='inline'>
                  Administration
                </Typography>
              </BMGrid>
              <BMGrid container item xs={12}>
                <Grid container item xs={12} sm={8} spacing={2}>
                  <Grid container item xs={12} md={6} lg={3}>
                    <DateTimePicker
                      id='fromUtc'
                      name='fromUtc'
                      label='From (Local time: passed to SIDES as UTC)'
                      format='MM/DD/yyyy hh:mm A'
                      value={values.fromUtc || null}
                      fullWidth
                      onChange={(date: MaterialUiPickersDate) => {
                        setFieldValue('fromUtc', date);
                      }}
                      error={Boolean(errors.fromUtc!)}
                      helperText={touched.fromUtc! && errors.fromUtc!}
                    />
                  </Grid>
                  <Grid container item xs={12} md={6} lg={3}>
                    <DateTimePicker
                      id='toUtc'
                      name='toUtc'
                      label='To (Local time: passed to SIDES as UTC)'
                      format='MM/DD/yyyy hh:mm A'
                      value={values.toUtc || null}
                      fullWidth
                      onChange={(date: MaterialUiPickersDate) => {
                        setFieldValue('toUtc', date);
                      }}
                      error={Boolean(errors.toUtc!)}
                      helperText={touched.toUtc! && errors.toUtc!}
                    />
                  </Grid>
                  <Grid container item xs={12} sm={5}>
                    <Button
                      title='REPULL SIDES BUTTON'
                      color='secondary'
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      <Typography color='primary' variant='subtitle2'>
                        Repull SIDES
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </BMGrid>
              <Grid container spacing={2}>
                {(sidesRepullResponses && sidesRepullResponses).map(
                  (response, index) => (
                    <Grid item xs={12} key={'sidesRepullResponse_' + index}>
                      <TableCard raised>
                        <ListCardContent>
                          <Grid container>
                            <Grid item xs={11}>
                              <Typography variant='subtitle1' color='secondary'>
                                {response}
                              </Typography>
                            </Grid>
                          </Grid>
                        </ListCardContent>
                      </TableCard>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          </Box>
        );
      }}
    </Formik>
  );
};

export default Administration;
