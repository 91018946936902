import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { createUserRoleSystemSettingActions, getRoleSystemSettingsActions, updateRoleSystemSettingsActions } from './system.actions';
import { SystemRoleSettingDto } from './system.dtos';
import { roleInit } from '../../../common/dtos/role';

const systemSettingsReducer = combineReducers({
  systemRoleSettings: createReducer([] as SystemRoleSettingDto[])
    .handleAction(
      [
        getRoleSystemSettingsActions.success,
        updateRoleSystemSettingsActions.request,
        updateRoleSystemSettingsActions.success,
      ], (state, action) => action.payload)
    .handleAction(
      [
        createUserRoleSystemSettingActions.success
      ], (state, action) => action.payload), 

  getSystemUserRoleSettings: createReducer(roleInit)
    .handleAction(
      [
        getRoleSystemSettingsActions.request,
      ], (state, action) => state),

  systemUserRoleSettings: createReducer(roleInit)
    .handleAction(
      [
        createUserRoleSystemSettingActions.request,
      ], (state, action) => action.payload),

});

export default systemSettingsReducer;