import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Grid, Box, Typography, Checkbox, TextField,
  InputAdornment, Card, CardContent, Link,
} from '@material-ui/core';
import { LocationCodeDto, LocationCodeInit } from '../codes.dtos'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import SearchIcon from '@material-ui/icons/Search';
import LocationCodeDialog from '../dialogs/locationCodeDialog';
import { isReadOnly } from '../../../../common/dtos/auth';
import { rootSelector } from '../../../../common/selectors/selectors';
import { BMGrid, CardCheckbox, Checkbox1, LargeIconButton, ListCardContent, TableCard } from '../../../../common/styles/styles';
import { FeatureTypeEnum } from '../../../../common/enums/featureTypeEnum';


const LocationCodesList: React.FC = () => {
  const [showInactive, setShowInactive] = React.useState(false);
  const [codesList, setCodesList] = React.useState([] as LocationCodeDto[]);
  const [codeSearchOptions, setCodeSearchOptions] = React.useState([] as string[]);
  const [codeSearchString, setCodeSearchString] = React.useState('');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dto, setDto] = React.useState(LocationCodeInit)

    const [readOnlyStatus, setReadOnlyStatus] = React.useState(false);


    React.useEffect(() => {
      let s = isReadOnly(FeatureTypeEnum.Claims);
        setReadOnlyStatus(s);

    }, [readOnlyStatus]);

  const codes = rootSelector(state => state.codes.locationCodes);

  const handleDialogLink = (dto: LocationCodeDto) => {
    setDto(dto);
    setDialogOpen(!dialogOpen);
  }

  React.useEffect(() => {
    let searchOptions: string[] | undefined = [];
    codesList.forEach((item: LocationCodeDto) => {
      searchOptions!.push(item.name!);
    });
    setCodeSearchOptions(searchOptions.sort((a, b) => a.localeCompare(b)));
  }, [codesList]);

  React.useEffect(() => {
    setCodesList(codes as LocationCodeDto[]);
  }, [codes]);

  const handleShowInactiveToggle = () => {
    setShowInactive(!showInactive);
  };

  const handleDialogClose = () => {
    setDialogOpen(!dialogOpen);
  }

  return <>
    {dialogOpen ?
      <LocationCodeDialog
        dto={dto}
        open={dialogOpen}
              onCancel={handleDialogClose}
              isReadOnly={readOnlyStatus}
      /> :
      null
    }
    <Box p={2}>
      <Grid container data-cy="location_codes_list_container" item xs={9}>

        <BMGrid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Formik initialValues={{ search: '' }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={Yup.object({
                search: Yup.string().max(200, 'Must be 200 characters or less')
              })}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => { setSubmitting(false); }, 700);
                setCodeSearchString(values.search);
              }}
              enableReinitialize={true}
            >
              {(props) => {
                const { values, touched, errors, handleChange, handleSubmit, setFieldValue } = props;
                return (
                  <form>
                    <Autocomplete
                      id="autoSearch"
                      options={codeSearchOptions}
                      autoComplete
                      includeInputInList
                      freeSolo
                      inputValue={values.search}
                      onInputChange={(_e: any, v: string | null) => { setFieldValue('search', v ?? ''); handleSubmit(); }}
                      renderInput={(params) => (<TextField {...params} id="search" size="small" margin="none" value={values.search} label="Search" name="search"
                        onChange={(e) => { handleChange(e); handleSubmit(); }} error={Boolean(errors.search)}
                        helperText={(errors.search && touched.search) && errors.search} data-cy="location_code_list_search"
                        InputProps={{ ...params.InputProps, startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>), }}
                      />)} />
                  </form>);
              }}
            </Formik>
          </Grid>
          <Grid item xs={11} sm={7}>
            <Checkbox1 onChange={handleShowInactiveToggle} value={!showInactive} checked={showInactive}
              control={<Checkbox size="small" data-cy="location_code_list_showInactiveCheckbox" />}
              label={<Typography variant="subtitle2">Show Inactive</Typography>} />
          </Grid>
                  <Grid item xs={1} hidden={readOnlyStatus}>
            <LargeIconButton title="Add Location Code" color="secondary" onClick={() => handleDialogLink(LocationCodeInit)} data-cy="location_code_list_details_addLocationCode">
              <AddBoxRoundedIcon />
            </LargeIconButton>
          </Grid>

        </BMGrid>

        <Grid container spacing={2}>
          {codesList && codesList
            .filter((item: LocationCodeDto) => item.isActive || item.isActive === !showInactive)
            .filter((item: LocationCodeDto) => item.name?.toLowerCase().includes(codeSearchString.toLowerCase()))
            .sort((a, b) => a.name!.localeCompare(b.name!)).map((o, index) => (
              <Grid item xs={12} key={'LocationItem-' + index}>
                <TableCard key={'LocationCard-' + index} raised>
                  <ListCardContent>
                    <Grid container>
                      <div hidden data-cy={`location_code_list_card_id_${o.name}`}>{o.id}</div>

                      <Grid item xs={12}>
                      <Link component="button" onClick={() => handleDialogLink(o)} data-cy={`location_code_list_card_link_${o.name}`} variant="subtitle1" color="secondary">{o.name}</Link>
                      </Grid>

                      <Grid item xs={6} sm={9}>
                        <Typography variant="body2" color="textPrimary">Description</Typography>
                        <Typography variant="body2" color="secondary">{o.description || "None on File"}</Typography>
                      </Grid>

                      {/* Active checkbox */}
                      <Grid item xs={6} sm={3}>
                        <CardCheckbox
                          value={o.isActive}
                          checked={o.isActive}
                          control={<Checkbox size="small" />}
                          label={<Typography variant="subtitle2">Active</Typography>}
                          labelPlacement="top"
                        />
                      </Grid>
                    </Grid>
                  </ListCardContent>
                </TableCard>
              </Grid>
            ))}


          <Grid item xs={12} hidden={codesList
            .filter((item: LocationCodeDto) => item.isActive || item.isActive === !showInactive)
            .filter((item: LocationCodeDto) => item.name?.toLowerCase().includes(codeSearchString.toLowerCase())).length !== 0}>
            <Card>
              <CardContent>
                <Grid container justifyContent="center" alignItems="center">
                  <Typography variant="subtitle1" color="secondary">No Records Found</Typography>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

        </Grid>

      </Grid>
    </Box>
  </>

}

export default LocationCodesList;