import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { claimHeaderSearchValidationSchema } from '../../../features/claims/claims.dtos';
import NumberField from '../../components/numberField';

// Styles
const StyledTextField = styled(TextField)`
         .label.Mui-focused {
            color: white;
        }
       .MuiInputBase-root {
            color: white;
        }
        .MuiFormLabel-root {
            color: white;
        }
        .MuiInput-underline:before {
            border-bottom-color: white;
        }
        .MuiInput-underline:after {
            border-bottom-color: white;
        }
        .MuiOutlinedInput-root {
            &.fieldset: {
                border-color: white;
                font-size: 3px;
                font-weight: bold;
                font-color: white;
                
            },
            &:hover fieldset {
                border-color: white;
            },
            &.Mui-focused fieldset {
                border-color: white;
            },
        };
        width: 150px;
        padding: 5px;
        margin-top: 2px;

`;

const ClaimSearch: React.FC = () => {
  return (
    <Formik
      initialValues={{ ssn: '' }}
      validateOnChange={false}
      validateOnBlur={true}
      validationSchema={claimHeaderSearchValidationSchema}
      onSubmit={(values, { setSubmitting }) => {
        if (values.ssn !== '' && values.ssn !== undefined) {
          setSubmitting(false);
          window.location.assign('/claims?search=' + values.ssn);
        }
      }}
      enableReinitialize={true}
    >
      {(props) => {
        const { values, errors, handleChange, handleBlur, handleSubmit } =
          props;
        return (
          <form noValidate onSubmit={handleSubmit}>
            <Grid item xs={12} sm={12}>
              <StyledTextField
                size='small'
                margin='none'
                value={values.ssn || ''}
                placeholder='Claims'
                name='ssn'
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.ssn !== undefined}
                helperText={errors.ssn && errors.ssn}
                data-cy='claim_search'
                fullWidth
                InputProps={{
                  inputComponent: NumberField as any,
                  startAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='Header Claims Search Icon'
                        onClick={() => handleSubmit()}
                      >
                        <SearchIcon htmlColor='#ffffff' fontSize='medium' />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputProps={{ isSSN: true }}
              />
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default ClaimSearch;
