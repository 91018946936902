import React from 'react';

import useAppendScript from '../../utils/appendScript';
import { TelerikInit, TelerikDto } from '../dtos/telerik';
import { TelerikDiv } from '../styles/styles';
import { TelerikReportDto } from '../../features/reports/dtos';

interface ReportViewerProps extends React.HTMLProps<React.ReactNode> {
  report: TelerikReportDto | undefined;
  scrollx?: string | undefined;
}


const ReportViewer: React.FC<ReportViewerProps> = (props: ReportViewerProps) => {
  const { report, scrollx } = props;

  const [telerikReport, setTelerikReport] = React.useState(TelerikInit);

  //useAppendScript('https://code.jquery.com/jquery-3.3.1.min.js', true);
  useAppendScript(process.env.REACT_APP_API_URL + 'telerik/reports/resources/js/telerikReportViewer');
  useAppendScript(process.env.REACT_APP_API_URL + 'telerik/reports/resources/js/telerikReportViewer.kendo');

  React.useEffect(() => {
    let newReport: TelerikDto = JSON.parse(JSON.stringify(telerikReport));
    if (report !== undefined)
      newReport.reportSource.report = report.reportDefinitionFileName;
    
    if (report !== undefined && report.parameters !== undefined) {
      newReport.reportSource.parameters = report.parameters;
    }
    
    setTelerikReport(newReport);
  }, [report]);

  React.useEffect(() => {
    setTimeout(() => {
      if (
        // @ts-ignore
        window.jQuery() !== undefined && window.jQuery().telerik_ReportViewer &&
        telerikReport !== undefined &&
        telerikReport.reportSource !== undefined &&
        telerikReport.reportSource.report !== undefined
      )
        // @ts-ignore
        window.jQuery("#reportViewer1").telerik_ReportViewer({
          serviceUrl: process.env.REACT_APP_API_URL + "telerik/reports",
          reportSource: {
            report: report?.reportDefinitionFileName || "undefined",
            parameters: report?.parameters || {},
          },
          scale: "1.0",
          scaleMode: "SPECIFIC",
          viewMode: "INTERACTIVE",
          printMode: "SPECIFIC",
          sendEmail: { enabled: true },
          parameters: { editors: { singleSelect: "COMBO_BOX", multiSelect: "COMBO_BOX" } }
        });
    }, 700)

  }, [window, telerikReport]);


  return <TelerikDiv id="reportViewer1" scrollx={scrollx} />

}

export default ReportViewer;