import { createAsyncAction } from 'typesafe-actions';
import { ProfileDto, CountryDto, GoverningDistrictDto } from './profile.dtos';

export const getProfileActions = createAsyncAction(
  'GET_PROFILE_REQUEST',
  'GET_PROFILE_SUCCESS',
  'GET_PROFILE_ERROR',
)<undefined, ProfileDto, string>();

export const updateProfileActions = createAsyncAction(
  'UPDATE_PROFILE_REQUEST',
  'UPDATE_PROFILE_SUCCESS',
  'UPDATE_PROFILE_ERROR',
)<ProfileDto, ProfileDto, string>();

export const createProfileActions = createAsyncAction(
  'CREATE_PROFILE_REQUEST',
  'CREATE_PROFILE_SUCCESS',
  'CREATE_PROFILE_ERROR',
)<ProfileDto, ProfileDto, string>();

export const getCountriesActions = createAsyncAction(
  'GET_COUNTRIES_REQUEST',
  'GET_COUNTRIES_SUCCESS',
  'GET_COUNTRIES_ERROR',
)<undefined, CountryDto[], string>();

export const getGoverningDistrictsActions = createAsyncAction(
  'GET_GOVERNING_DISTRICTS_REQUEST',
  'GET_GOVERNING_DISTRICTS_SUCCESS',
  'GET_GOVERNING_DISTRICTS_ERROR',
)<undefined, GoverningDistrictDto[], string>();