


import { ISelectOption } from "../dtos/SelectOptionDto";
enum ClaimTypeCodes {
        Item1 = 0,
        Item2 = 1,
        Item3 = 2,
        Item4 = 3,
        Item5 = 4,
        Item6 = 5,
         
}

export class ClaimTypeCodes_class {

public static enum = ClaimTypeCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['Item1']: 0,
    ['Item2']: 1,
    ['Item3']: 2,
    ['Item4']: 3,
    ['Item5']: 4,
    ['Item6']: 5,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "Regular UI, New Initial Claim",
    [1]: "Regular UI, Additional Claim",
    [2]: "Federal Extended Programs, New Initial Claim",
    [3]: "Federal Extended Programs, Additional Claim",
    [4]: "State Extended Programs, New Initial Claim",
    [5]: "State Extended Programs, Additional Claim",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "Regular UI, New Initial Claim"},
  {value: 1, text: "Regular UI, Additional Claim"},
  {value: 2, text: "Federal Extended Programs, New Initial Claim"},
  {value: 3, text: "Federal Extended Programs, Additional Claim"},
  {value: 4, text: "State Extended Programs, New Initial Claim"},
  {value: 5, text: "State Extended Programs, Additional Claim"},
  ]
}

