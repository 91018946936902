import React from 'react';
import { useDispatch } from 'react-redux';
//import { Prompt } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { getType } from 'typesafe-actions';
import Dropzone from 'react-dropzone';
import moment from 'moment';
// material ui
import {
  Grid, Typography, TextField, Card, Button, InputAdornment, Box, Table, TableCell, TableBody, TableRow, TableHead, TableContainer, Link,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { BulkImportDto } from '../../common/dtos/bulkImportDto';
import { rootSelector } from '../../common/selectors/selectors';
import BottomActionBarClose from '../../common/components/bottomActionBarClose';
import { getCompanyBulkImportHistoryActions, uploadCompanyBulkImport } from './actions';
import { BlackText, BoldText, CardContent1, CardHeaderDivider, CondensedGrid, DarkText, DashedBox, UnderlinedGrid } from '../../common/styles/styles';
import { ImportTable } from '../claims/claims.styles';


const CompanyBulkInsert: React.FC = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const baseUrl = process.env.REACT_APP_API_URL ?? "";

  React.useEffect(() => {
    dispatch({ type: getType(getCompanyBulkImportHistoryActions.request) });
  }, [dispatch]);

  const systemCompanyImportHistoryList = rootSelector(state => state.companies.bulkImportHistory);
  const downloadTemplateUrl = baseUrl + "download/import-template/company";
  const downloadErrorFileBaseUrl = baseUrl + "download/import-error/";

  const [fileNames, setFileNames] = React.useState([] as string[]);

  const [companyImportSearchHistoryString, setCompanyImportSearchHistoryString] = React.useState('');
  const [companyImportSearchHistoryOptions, setCompanyImportSearchHistoryOptions] = React.useState([] as string[]);
  const [companyImportHistoryList, setCompanyImportHistoryList] = React.useState([] as BulkImportDto[]);

  const handleDrop = (acceptedFiles: File[]) => {
    setFileNames(acceptedFiles.map(file => file.name));
    dispatch({ type: getType(uploadCompanyBulkImport), payload: acceptedFiles[0] });
  }

  const handleClose = () => {
    history.goBack();
  }

  React.useEffect(() => {
    setCompanyImportHistoryList(systemCompanyImportHistoryList);

    let searchOptions: string[] = [];
    systemCompanyImportHistoryList.forEach((item: BulkImportDto) => {
      searchOptions.push(moment(item.downloadDate).format('MM/DD/yyyy')); 
      searchOptions.push(item.fileName);
      searchOptions.push(item.createdBy);
      searchOptions.push(item.importStatus);
    });
    let newArray = Array.from(new Set(searchOptions.sort((a, b) => a.localeCompare(b)).filter(s => s !== undefined)));
    setCompanyImportSearchHistoryOptions(newArray);
  }, [systemCompanyImportHistoryList]);


  return <Grid item xs={12}>
    <Card>
      <CardHeaderDivider title={<><BlackText><Typography variant="subtitle1" >Employer Upload</Typography></BlackText></>}></CardHeaderDivider>
      <CardContent1>
        <br />
        <Grid container justifyContent="center"><BlackText><Typography variant="subtitle1">Upload Instructions</Typography></BlackText></Grid>
        <br />
        <Grid container justifyContent="center">
          <CondensedGrid container item xs={7} justifyContent="flex-start">
            <p><BoldText>1. Download the template file:</BoldText> This file must be used with the exact column names specified.</p>
            <p><BoldText>2. Required Fields:</BoldText> Companies will be imported using this file template.
                The import will search for an exact match on Name to determine whether to import as a new employer.
            </p>
            <p><BoldText>3. Upload File:</BoldText> Drop your file into the area below to begin the import process.</p>
          </CondensedGrid>
        </Grid>
        <br /><br /><br />
        <Grid container justifyContent="center">
          <Button href={downloadTemplateUrl} download color="secondary" variant="contained" >Download Template File</Button>
        </Grid>
        <br /><br />
        <Grid container justifyContent="center">
          <Dropzone aria-label="File drop zone" onDrop={handleDrop}>
            {({ getRootProps, getInputProps }) => (
              <DashedBox container item xs={9} justifyContent="center" {...getRootProps({ className: "dropzone" })}>
                <input aria-label="File input area" {...getInputProps()} />
                <Typography variant="h5" display="block">Drag your file here or click to select</Typography>
              </DashedBox>
            )}
          </Dropzone>
        </Grid>
        <Grid container justifyContent="center">
          <div>
            <ul>
              {fileNames.map(fileName => (
                <li key={fileName}>{fileName}</li>
              ))}
            </ul>
          </div>
        </Grid>
        <br /><br />
      </CardContent1>
    </Card>

    <ImportTable>
      <Grid container>
        <UnderlinedGrid item xs={12} ><DarkText><Typography variant="subtitle1">Employer Import History</Typography></DarkText></UnderlinedGrid>
        <br />
        <Grid item xs={2}>
          <Formik initialValues={{ search: '' }}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Yup.object({
              search: Yup.string().max(200, 'Must be 200 characters or less')
            })}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => { setSubmitting(false); }, 700);
              setCompanyImportSearchHistoryString(values.search);
            }}
            enableReinitialize={true}
          >
            {(props) => {
              const { values, touched, errors, handleChange, handleSubmit, setFieldValue } = props; 
              return (
                <Box p={2}>
                  <form>
                    <Autocomplete
                      id="autoSearch"
                      options={companyImportSearchHistoryOptions}
                      autoComplete
                      includeInputInList
                      freeSolo
                      onChange={(_e: any, v: string | null) => { setFieldValue('search', v ?? ''); handleSubmit(); }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          id="search"
                          size="small"
                          margin="none"
                          value={values.search}
                          label="Search "
                          name="search"
                          onChange={(e) => { handleChange(e); handleSubmit(); }} error={Boolean(errors.search)}
                          onKeyDown={e => { if (e.keyCode === 13) { e.preventDefault(); } }}
                          helperText={(errors.search && touched.search) && errors.search}
                          data-cy="company-import-search"
                          InputProps={{ ...params.InputProps, startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>), }}
                      />)} />
                  </form>
                </Box>
              );
            }}
          </Formik>
        </Grid>
        <TableContainer>
          <Table aria-label="Employer Import History Table" style={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Download</TableCell>
                <TableCell align="center">Imported By</TableCell>
                <TableCell align="center">File Name</TableCell>
                <TableCell align="center"># Records In File</TableCell>
                <TableCell align="center"># Records Imported</TableCell>
                <TableCell align="center">Import Status</TableCell>
                <TableCell align="center">Records Not Imported</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyImportHistoryList
                .filter((item: BulkImportDto) => (item.fileName?.includes(companyImportSearchHistoryString)
                  || (item.createdBy?.includes(companyImportSearchHistoryString))
                  || (item.importStatus?.includes(companyImportSearchHistoryString))
                  || (moment(item.downloadDate).format('MM/DD/yyyy').includes(companyImportSearchHistoryString))
                ))
                .sort((a, b) => a.downloadDate.valueOf() - b.downloadDate.valueOf()).map((o, index) => (
                  <TableRow key={o.downloadDate.valueOf()}>
                    <TableCell padding="checkbox" component="th" scope="row" align="center">{moment(o.downloadDate).format('MM/DD/yyyy')}</TableCell>
                    <TableCell padding="checkbox" align="center">{o.createdBy}</TableCell>
                    <TableCell padding="checkbox" align="center">{o.fileName}</TableCell>
                    <TableCell padding="checkbox" align="center">{o.recordsInFile}</TableCell>
                    <TableCell padding="checkbox" align="center">{o.recordsImported}</TableCell>
                    <TableCell padding="checkbox" align="center">{o.importStatus}</TableCell>
                    <TableCell padding="checkbox" align="center">
                      <Link href={o.errorFileName !== undefined && o.errorFileName.length !== 0 ? downloadErrorFileBaseUrl + o.errorFileName : downloadTemplateUrl} download>Download</Link>
                    </TableCell>
                  </TableRow>
                ))}

              {companyImportHistoryList.filter((item: BulkImportDto) => (item.fileName?.includes(companyImportSearchHistoryString)
                || (item.createdBy?.includes(companyImportSearchHistoryString))
                || (item.importStatus?.includes(companyImportSearchHistoryString))
                || (moment(item.downloadDate).format('MM/DD/yyyy').includes(companyImportSearchHistoryString))
              )).length === 0 &&
                <TableRow>
                <TableCell size="medium" colSpan={7} align="center">No records found{companyImportSearchHistoryString.length === 0 ? '.' : ' matching ' + companyImportSearchHistoryString}</TableCell>
                </TableRow>
              }
            </TableBody>

          </Table>
          <br /><br />
        </TableContainer>
      </Grid>
    </ImportTable>
    <br /><br /><br /><br /><br /><br /><br /><br />
    <BottomActionBarClose closeClickHandler={handleClose} />
  </Grid>

}

export default CompanyBulkInsert;