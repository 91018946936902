import { combineReducers } from 'redux';
import { createReducer, getType } from 'typesafe-actions';

import { loginActions, session } from './login.actions';
import { tokenInit, loginInit } from './login.dtos';

const loginReducer = combineReducers({
  loginRequest: createReducer(loginInit)
    .handleAction(
      [
        loginActions.request,
      ], (state, action) => action.payload),

  loginSuccess: createReducer(tokenInit)
    .handleAction(
      [
        loginActions.success,
      ], (state, action) => action.payload),

  loginError: createReducer('')
    .handleAction(
      [
        loginActions.failure,
      ], (state, action) => action.payload),

  session: createReducer<boolean>(false)
    .handleType(getType(session), (state, action) => action.payload),
});

export default loginReducer;