import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, getIn, FieldArray, FieldArrayRenderProps } from 'formik';
import * as Yup from 'yup';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
} from '@material-ui/core';
import { getType } from 'typesafe-actions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dropzone from 'react-dropzone';
import { rootSelector } from '../../../../common/selectors/selectors';
import {
  EmailTemplateDto,
  emailTemplateInit,
  FileUploadDto,
} from '../../../../common/dtos/emailTemplateDto';
import {
  updateEmailTemplateActions,
  createEmailTemplateActions,
  getEditEmailTemplateActions,
} from '../../../../common/actions/emailTemplate.actions';
import { getGoverningDistrictsActions } from '../../../account/profile/profile.actions';
import { GoverningDistrictDto } from '../../../account/profile/profile.dtos';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import {
  DialogCardContent,
  DialogInputLabel,
  SmallFileText,
  EmailTemplateInteractionDialogActions,
} from '../../codes/codes.styles';
import {
  ButtonGrid,
  IndentFileGrid,
  MHARadio,
  PaddedTextField,
  RemoveIcon,
  SidesUploadBox,
  StyledCancelButton,
  StyledSubmitButton,
} from '../../../../common/styles/styles';
import { requiredError } from '../../../../common/constants/strings';

interface Props {
  templateId: string;
  templateTypeId?: number;
  open: boolean;
  onCancel: () => void;
  isReadOnly: boolean;
}

const appealsTemplateTypeOptions = [
  { name: '', id: 0 },
  { name: 'Decision', id: 2 },
  { name: 'Hearing', id: 3 },
];

const AddEditEmailTemplateDialog: React.FC<Props> = ({
  open,
  templateId,
  onCancel,
  isReadOnly,
  templateTypeId,
}) => {
  const dispatch = useDispatch();

  const governingDistricts = rootSelector(
    (state) => state.governingDistricts.governingDistrictList
  );
  const systemTemplate = rootSelector(
    (state) => state.emailTemplates.editTemplate
  );

  const [submitted, setSubmitted] = React.useState(false);
  const [dto, setDto] = React.useState(emailTemplateInit as EmailTemplateDto);
  const [stateOptions, setStateOptions] = React.useState(
    [] as GoverningDistrictDto[]
  );

  React.useEffect(() => {
    setSubmitted(false);
    dispatch({ type: getType(getGoverningDistrictsActions.request) });

    if (templateId !== undefined && templateId !== 'new')
      dispatch({
        type: getType(getEditEmailTemplateActions.request),
        payload: templateId,
      });
    else
      dispatch({
        type: getType(getEditEmailTemplateActions.success),
        payload: emailTemplateInit,
      });
  }, [templateId, dispatch, open]);

  React.useEffect(() => {
    setStateOptions(governingDistricts);
  }, [governingDistricts]);

  React.useEffect(() => {
    let newDto = systemTemplate;

    if (templateTypeId !== undefined && templateTypeId === 1) {
      newDto.emailTemplateTypeId = templateTypeId;
      newDto.emailTemplateUserTypeId = 1;
    } else if (templateTypeId !== undefined && templateTypeId === 2)
      newDto.emailTemplateUserTypeId = 2;

    setDto(newDto);
  }, [systemTemplate]);

  const cancelDialog = () => onCancel();

  const handleDrop = (
    acceptedFiles: File[],
    arrayHelpers: FieldArrayRenderProps
  ) => {
    let fileList = [] as string[];
    acceptedFiles.forEach((f) => {
      fileList.push(f.name);
      let newDoc: FileUploadDto = {
        fileName: f.name,
        fileUpload: f,
      };

      arrayHelpers.push(newDoc);
    });
  };

  return (
    <Formik
      initialValues={dto}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={Yup.object({
        isActive: Yup.boolean().required(requiredError),
        body: Yup.string()
          .max(1900, 'Must be 1900 characters or less')
          .required(requiredError),
        subject: Yup.string()
          .max(100, 'Must be 100 characters or less')
          .required(requiredError),
        label: Yup.string()
          .max(50, 'Must be 50 characters or less')
          .required(requiredError),
        state: Yup.string().max(3, 'Must be 3 characters or less').nullable(),
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setTimeout(() => {
          setSubmitting(false);
          resetForm();
          setSubmitted(true);
        }, 700);
        let newObj = { ...values };
        if (newObj.emailTemplateUserTypeId === 2) newObj.state = '0';

        dispatch({
          type: newObj.id
            ? getType(updateEmailTemplateActions.request)
            : getType(createEmailTemplateActions.request),
          payload: newObj,
        });
        //setDialogOpen(false);
      }}
      enableReinitialize={true}
    >
      {(props) => {
        const {
          values,
          errors,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
        } = props;
        return (
          <Dialog
            open={open}
            data-cy='emailTemplates_addEdit_dialog'
            fullWidth
            maxWidth='sm'
          >
            <DialogTitle>
              {values.emailTemplateUserTypeId === 1
                ? 'Appeals'
                : values.emailTemplateUserTypeId === 2
                ? 'Claims'
                : null}{' '}
              Email Template Details
            </DialogTitle>
            <DialogContent>
              <FieldArray
                name='supportingDocuments'
                render={(arrayHelpers) => (
                  <form onSubmit={handleSubmit}>
                    <DialogCardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              id='isActive'
                              name='isActive'
                              onChange={handleChange}
                              value={values.isActive}
                              checked={values.isActive}
                              control={
                                <Checkbox
                                  size='small'
                                  disabled={isReadOnly}
                                  data-cy='emailTemplates_addEdit_isActiveCheckbox'
                                />
                              }
                              label={
                                <Typography variant='subtitle2'>
                                  Active
                                </Typography>
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <DialogInputLabel>* Template Name</DialogInputLabel>
                            <TextField
                              id='label'
                              name='label'
                              onChange={handleChange}
                              fullWidth
                              value={values.label || ''}
                              data-cy='emailTemplates_addEdit_label'
                              onBlur={handleBlur}
                              disabled={isReadOnly}
                              error={Boolean(errors.label)}
                              helperText={errors.label}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <DialogInputLabel>* Email Subject</DialogInputLabel>
                            <TextField
                              id='subject'
                              name='subject'
                              onChange={handleChange}
                              fullWidth
                              multiline
                              value={values.subject || ''}
                              data-cy='emailTemplates_addEdit_subject'
                              onBlur={handleBlur}
                              disabled={isReadOnly}
                              error={Boolean(errors.subject)}
                              helperText={errors.subject}
                            />
                          </Grid>

                          <Grid item xs={12} container spacing={2}>
                            <Grid
                              item
                              xs={4}
                              hidden={values.emailTemplateUserTypeId === 2}
                            >
                              <DialogInputLabel>* State</DialogInputLabel>
                              <Autocomplete
                                id='state'
                                options={stateOptions}
                                getOptionLabel={(option) => option.code}
                                value={
                                  stateOptions.find(
                                    (o) => o.code === values.state
                                  ) || null
                                }
                                disabled={isReadOnly}
                                autoComplete
                                onChange={(
                                  _e: any,
                                  v: GoverningDistrictDto | null
                                ) => {
                                  setFieldValue('state', v?.code ?? '');
                                }}
                                renderInput={(params) => (
                                  <PaddedTextField
                                    {...params}
                                    id='state'
                                    size='small'
                                    margin='none'
                                    disabled={isReadOnly}
                                    value={values.state || ''}
                                    onBlur={(e) => {
                                      handleBlur(e);
                                    }}
                                    error={Boolean(getIn(errors, 'state'))}
                                    helperText={
                                      getIn(errors, 'state') &&
                                      getIn(errors, 'state')
                                    }
                                    data-cy='emailTemplates_addEdit_state'
                                    fullWidth
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              hidden={values.emailTemplateUserTypeId === 2}
                            >
                              <DialogInputLabel>* Appeal Type</DialogInputLabel>
                              <Grid container direction='row'>
                                {appealsTemplateTypeOptions
                                  .filter((g) => g.name !== '')
                                  .map((o, index) => (
                                    <Grid
                                      item
                                      xs={12}
                                      lg={6}
                                      key={o.name + index}
                                    >
                                      <FormControlLabel
                                        onChange={(
                                          e:
                                            | React.ChangeEvent<HTMLInputElement>
                                            | React.ChangeEvent<{}>,
                                          checked: boolean
                                        ) => {
                                          setFieldValue(
                                            'emailTemplateTypeId',
                                            appealsTemplateTypeOptions.find(
                                              (o) =>
                                                o.name ===
                                                (e.target as HTMLInputElement)
                                                  .value
                                            )?.id ?? 1
                                          );
                                        }}
                                        checked={
                                          o.id === values.emailTemplateTypeId
                                        }
                                        value={o.name}
                                        control={
                                          <MHARadio
                                            disabled={isReadOnly}
                                            size='small'
                                          />
                                        }
                                        label={o.name}
                                        data-cy=''
                                      />
                                    </Grid>
                                  ))}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <DialogInputLabel>* Template Body</DialogInputLabel>
                            <TextField
                              id='body'
                              name='body'
                              onChange={handleChange}
                              fullWidth
                              value={values.body || ''}
                              data-cy='emailTemplates_addEdit_body'
                              onBlur={handleBlur}
                              disabled={isReadOnly}
                              multiline
                              error={Boolean(errors.body)}
                              helperText={errors.body}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} style={{ paddingTop: 45 }}>
                            <Dropzone
                              aria-label='Supporting document upload'
                              accept='application/pdf,.eml'
                              onDrop={(e) => {
                                handleDrop(e, arrayHelpers);
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <SidesUploadBox
                                  {...getRootProps({ className: 'dropzone' })}
                                >
                                  <input
                                    id='fileUpload'
                                    name='fileUpload'
                                    aria-label='File input area'
                                    {...getInputProps()}
                                  />
                                  <Typography variant='h6' display='block'>
                                    Choose File
                                  </Typography>
                                </SidesUploadBox>
                              )}
                            </Dropzone>
                            <br></br>
                            {values.supportingDocuments &&
                              values.supportingDocuments.map((o, index) => (
                                <IndentFileGrid
                                  key={o.fileName}
                                  container
                                  justifyContent='flex-start'
                                >
                                  <Grid item xs={1}>
                                    <RemoveIcon
                                      title='Remove File'
                                      onClick={() => {
                                        arrayHelpers.remove(index);
                                      }}
                                      data-cy='changeMe'
                                    >
                                      <IndeterminateCheckBoxIcon />
                                    </RemoveIcon>
                                  </Grid>
                                  <Grid item xs={10}>
                                    <SmallFileText>{o.fileName}</SmallFileText>
                                  </Grid>
                                </IndentFileGrid>
                              ))}
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              id='shouldDownloadActionListDocument'
                              name='shouldDownloadActionListDocument'
                              onChange={handleChange}
                              value={values.shouldDownloadActionListDocument}
                              checked={values.shouldDownloadActionListDocument}
                              control={
                                <Checkbox
                                  size='small'
                                  disabled={isReadOnly}
                                  data-cy='emailTemplates_addEdit_shouldDownloadActionListDocumentCheckbox'
                                />
                              }
                              label={
                                <Typography variant='subtitle2'>
                                  Download Action List Document
                                </Typography>
                              }
                            />
                          </Grid>
                        </Grid>

                        <Grid item xs={4}>
                          <br />
                          <br />

                          <Typography variant='subtitle1'>
                            Available Variables
                          </Typography>
                          <br />
                          <Typography variant='subtitle2'>
                            #Employer#
                          </Typography>
                          <Typography variant='subtitle2'>
                            #employeeId#
                          </Typography>
                          <Typography variant='subtitle2'>
                            #claimantName#
                          </Typography>
                          <Typography variant='subtitle2'>
                            #claimantSsn#
                          </Typography>
                          <Typography variant='subtitle2'>
                            #businessUnit#
                          </Typography>
                          <Typography variant='subtitle2'>#duedate#</Typography>
                          <Typography variant='subtitle2'>#bybDate#</Typography>
                          <Typography variant='subtitle2'>
                            #separationDate#
                          </Typography>
                          <Typography variant='subtitle2'>
                            #potentialLiability#
                          </Typography>
                          <Typography variant='subtitle2'>#savings#</Typography>
                          <Typography variant='subtitle2'>
                            #hearingRepresentative#
                          </Typography>
                          <Typography variant='subtitle2'>
                            #hearingDate#
                          </Typography>
                        </Grid>
                      </Grid>
                    </DialogCardContent>
                    <EmailTemplateInteractionDialogActions>
                      <ButtonGrid container>
                        <StyledSubmitButton
                          type='submit'
                          color='primary'
                          disabled={isSubmitting || isReadOnly}
                          data-cy='emailTemplates_addEdit_submitButton'
                        >
                          Save
                        </StyledSubmitButton>

                        <StyledCancelButton
                          data-cy='emailTemplates_addEdit_cancelButton'
                          color='secondary'
                          onClick={cancelDialog}
                        >
                          {submitted ? 'Close' : 'Cancel'}
                        </StyledCancelButton>
                      </ButtonGrid>
                    </EmailTemplateInteractionDialogActions>
                  </form>
                )}
              />
            </DialogContent>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default AddEditEmailTemplateDialog;
