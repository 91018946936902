import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getType } from 'typesafe-actions';
import styled from 'styled-components';
import { IconButton, Avatar, Menu, MenuItem, ListItemText, ListItemIcon, Divider } from '@material-ui/core';
import IconArrowDropDown from '@material-ui/icons/ArrowDropDown';
import IconSettings from '@material-ui/icons/Settings';
import IconLogout from '@material-ui/icons/ExitToApp';

//import { rootSelector } from '../../selectors/selectors';
import { getFullName } from '../../dtos/auth';
import { logoutActions } from '../../../features/account/login/login.actions';
//import { UserDto } from '../../../features/account/dtos/userDto';


// Styles
const StyledDiv = styled.div`
    display: inline-flex;
`;
const StyledIconButton = styled(IconButton)`
    border-radius: 30;
    fontSize: 1.2rem;
    padding: 8;
`;
const StyledAvatar = styled(Avatar)`
    width: 35;
    height: 35;
    margin-right: 10;
`;
const StyledMenu = styled(Menu)`
    margin-left: -16px;
`;

const HeaderProfile = () => {
  const fullName = getFullName();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  //const user: UserDto = {  };
  const dispatch = useDispatch();

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleLogout() {
    dispatch({ type: getType(logoutActions.request) });
    setAnchorEl(null);
  }

  return (
    <StyledDiv>
      <StyledIconButton
        edge="start"
        color="inherit"
        aria-label="Search"
        aria-controls="simple-menu"
        aria-haspopup="true"
              onClick={handleClick}
              data-cy="header_profileAvatar"
      >
        <StyledAvatar
          alt={fullName}
        />
        <IconArrowDropDown />
      </StyledIconButton>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
        getContentAnchorEl={null}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
              <MenuItem onClick={handleClose} component={Link} to="/auth/change-password" data-cy="header_profileChangePassword">
          <ListItemIcon>
            <IconSettings />
          </ListItemIcon>
          <ListItemText primary="Change Password" />
        </MenuItem>
        <Divider />
              <MenuItem onClick={handleLogout} data-cy="header_profileLogout">
          <ListItemIcon>
            <IconLogout />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </StyledMenu>
    </StyledDiv>
  )
}

export default HeaderProfile;


  //<StyledSpan>{fullName}</StyledSpan>
