import React from 'react';
import {
  FormikHandlers,
  FormikErrors,
  getIn,
  FormikHelpers,
  FieldArrayRenderProps,
} from 'formik';
// material ui
import { Grid } from '@material-ui/core';
import { CompanyDto, BusinessUnitDto } from '../dtos';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { BUTextField } from '../styles';
import { RedFormNameIcon } from '../../../common/styles/styles';

interface Props extends React.HTMLProps<React.ReactNode> {
  bu: BusinessUnitDto;
  errors: FormikErrors<CompanyDto>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  setFieldValue: FormikHelpers<CompanyDto>['setFieldValue'];
  arrayHelpers: FieldArrayRenderProps;
  index: number;
  setShouldBlockNavigation: (o: boolean) => void;
  isReadOnly: boolean;
}

const CompanyBusinessUnit: React.FC<Props> = ({
  bu,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  arrayHelpers,
  index,
  setShouldBlockNavigation,
  isReadOnly,
}) => {
  const handleRemoveBU = () => {
    if (bu.id === undefined) arrayHelpers.remove(index);
    else setFieldValue(`businessUnitList[${index}].isActive`, false);
  };

  return bu.isActive ? (
    <React.Fragment key={'businessUnitFragment' + index}>
      <Grid item xs={11}>
        <BUTextField
          key={'businessUnit_field' + index}
          id={`businessUnitList[${index}].name`}
          size='small'
          margin='none'
          disabled={isReadOnly}
          value={bu.name || ''}
          label=''
          name={`businessUnitList[${index}].name`}
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(getIn(errors, `businessUnitList[${index}].name`))}
          helperText={
            getIn(errors, `businessUnitList[${index}].name`) &&
            getIn(errors, `businessUnitList[${index}].name`)
          }
          data-cy={`employer_businessUnit_${index}_businessUnitInput`}
          inputProps={{ autoComplete: 'new-password' }}
          fullWidth
        />
      </Grid>
      <Grid item xs={1}>
        {!isReadOnly && (
          <RedFormNameIcon
            title='Remove from Business Unit List'
            color='secondary'
            onClick={() => handleRemoveBU()}
          >
            <IndeterminateCheckBoxIcon />
          </RedFormNameIcon>
        )}
      </Grid>
    </React.Fragment>
  ) : null;
};

export default CompanyBusinessUnit;
