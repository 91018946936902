import { combineReducers } from 'redux';
import { createReducer, getType } from 'typesafe-actions';

import {
  getEmailTemplateActions, getEmailTemplatesActions, toggleEmailTemplateDialog, emailTemplateRecipient,
  getEditEmailTemplateActions, downloadActionListDocument, openReachOutEmailTemplateDialog
} from '../actions/emailTemplate.actions';
import { EmailTemplateDto, emailTemplateInit, EmailTemplateRequestWrapper } from '../dtos/emailTemplateDto';



export const emailTemplatesReducer = combineReducers({
  templateList: createReducer([] as EmailTemplateDto[])
    .handleAction([getEmailTemplatesActions.success,], (state, action) => action.payload),

  template: createReducer(emailTemplateInit)
    .handleAction([getEmailTemplateActions.success,], (state, action) => action.payload),

  editTemplate: createReducer(emailTemplateInit)
    .handleAction([getEditEmailTemplateActions.success,], (state, action) => action.payload),

  getTemplate: createReducer({ request: { id: '' } } as EmailTemplateRequestWrapper)
    .handleAction([getEmailTemplateActions.request,], (state, action) => action.payload),

  dialog: createReducer(false)
    .handleType(getType(toggleEmailTemplateDialog), (state, action) => action.payload),

  openReachOut: createReducer(false)
    .handleType(getType(openReachOutEmailTemplateDialog), (state, action) => action.payload),

  downloadDocument: createReducer('')
    .handleType(getType(downloadActionListDocument), (state, action) => action.payload),

  recipient: createReducer('')
    .handleType(getType(emailTemplateRecipient), (state, action) => action.payload),

});

export default emailTemplatesReducer;