import React from 'react';
import { Prompt } from 'react-router';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Dialog, DialogContent, DialogTitle, Grid, TextField, MenuItem, DialogActions
} from '@material-ui/core';
import { getType } from 'typesafe-actions';
import { ReassignDto } from '../document.dtos';
import { reassignDocumentsActions } from '../actions';
import { MhaContactsDto } from '../../../common/dtos/dto';
import { ButtonGrid, StyledCancelButton, StyledCardContent, StyledSubmitButton } from '../../../common/styles/styles';
import { DialogInputLabel } from '../documents.styles';
import { requiredError } from '../../../common/constants/strings';


interface Props {
  dto: ReassignDto;
  open: boolean;
  onCancel: () => void;
  mhaContacts: MhaContactsDto[];
}

const ReassignDialog: React.FC<Props> = (
  {
    dto,
    open,
    onCancel,
    mhaContacts
  }) => {
  const dispatch = useDispatch();
  const cancelDialog = () => onCancel();

  return <Formik
    initialValues={dto as ReassignDto}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={
      Yup.object({
        mhaContactId: Yup.string().max(300, "Must be 300 characters or less").required(requiredError),
      })
    }
    onSubmit={(values, { setSubmitting, resetForm }) => {
      setTimeout(() => {
        setSubmitting(false);
        resetForm();
      }, 700);

      if (values.mhaContactId.length !== 0) {
        dispatch({ type: getType(reassignDocumentsActions.request), payload: values });
        cancelDialog();
      }
    }}
    enableReinitialize={true}
  >
    {(props) => {
      const {
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        touched
      } = props;
      return (
        <Dialog
          open={open}
          data-cy="reassign_dialog"
        >
          <DialogTitle style={{ marginLeft: 5 }}>Reassign</DialogTitle>
          <DialogContent style={{ width: 500 }}>
            <form onSubmit={handleSubmit}>
              <StyledCardContent>
                <Grid container alignContent="center" item xs={12}>
                  <Grid item xs={5}>
                    <DialogInputLabel>Team Member</DialogInputLabel>
                    <TextField
                      id="mhaContactId"
                      name="mhaContactId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.mhaContactId} 
                      select
                      fullWidth
                      data-cy="reassign_teamMemberName"
                      error={(Boolean(errors.mhaContactId && touched.mhaContactId))}
                      helperText={(Boolean(errors.mhaContactId) && touched.mhaContactId) && errors.mhaContactId}
                    >
                      <MenuItem key={'Please select' + -1} value={'0'}>{'Please select'}</MenuItem>
                      {mhaContacts.sort((a, b) => a.name.localeCompare(b.name)).map((option, index) => (
                        <MenuItem key={option.name + index} value={option.contactId}>{option.name}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={7}></Grid>
                </Grid>

              </StyledCardContent>

              <DialogActions>
                <ButtonGrid container>
                  <StyledSubmitButton
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    data-cy="reassign_submitButton"
                  >
                    Save
                  </StyledSubmitButton>

                  <StyledCancelButton
                    data-cy="reassign_cancelButton"
                    color="secondary"
                    onClick={cancelDialog}
                  >
                    Cancel
                  </StyledCancelButton>
                </ButtonGrid>
              </DialogActions>
            </form>
          </DialogContent>
          <Prompt
            // when={shouldBlockNavigation}
            message='You have unsaved edits, are you sure you want to leave?'
          />
        </Dialog>
      );
    }}
  </Formik>
}

export default ReassignDialog;
