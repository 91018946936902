import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { getTodoInteractionsHistoryActions, createTodoInteractionsActions, getTodoInteractionsActions, updateTodoInteractionsActions } from './sales.actions';
import { TodoInteractionDto, toDoInteractionInit } from './sales.dtos';

const toDoInteractionsReducer = combineReducers({
  toDoInteractions: createReducer([] as TodoInteractionDto[])
    .handleAction(
      [
        getTodoInteractionsActions.success,
      ],
      (state, action) => action.payload),

  createUpdateTodoInteractions: createReducer(toDoInteractionInit)
    .handleAction(
      [
        createTodoInteractionsActions.request,
        createTodoInteractionsActions.success,
        updateTodoInteractionsActions.request,
        updateTodoInteractionsActions.success,
      ], (state, action) => action.payload),

  toDoInteractionsHistory: createReducer([] as TodoInteractionDto[])
    .handleAction(
        [
        getTodoInteractionsHistoryActions.success,

      ], (state, action) => action.payload),

    getTodoInteractionsHistoryRequest: createReducer('')
        .handleAction(
            [
                getTodoInteractionsHistoryActions.request,
            ], (state, action) => action.payload)

    
});

export default toDoInteractionsReducer;