import { IMc } from "../common/dtos/McDto";
import { EmployerSepReasonCodes_class } from "../common/enums/EmployerSepReasonCodes";
import { IndicatorTypesWithD_class } from "../common/enums/IndicatorTypesWithD";
import { IndicatorTypes_class } from "../common/enums/IndicatorTypes";

/*
 * A function for each IMc prop that op conditionally or optionally required from the client. 
 * Returns true or false. 
 * based on 
 * SIDES Monetary and Potential Charges Standard Format - 7-26-23.pdf
 * */
const SC = EmployerSepReasonCodes_class.enum;


export const opEmployerSeparationReasonCode = (v: IMc): boolean => {
    if ([SC.Item20, SC.Item21, SC.Item23].findIndex(f => f === v.employerSeparationReasonCode) !== -1) return true;
    return false;
}

export const opFraudInd = (v: IMc): boolean => {
  if (v.employerSepReasonAnsweredInd == IndicatorTypes_class.enum.Y) return false;
  if (v.employerSepReasonAnsweredInd == IndicatorTypes_class.enum.N) return true;
  if (v.employerSepQuestionShownInd == IndicatorTypesWithD_class.enum.D) return true;
  return false;
}
export const opSeasonalEmploymentInd = (v: IMc): boolean => {
  if (v.employerSepReasonAnsweredInd == IndicatorTypes_class.enum.Y) return false;
  if (v.relationshipEndedInd == IndicatorTypes_class.enum.N) return false;
  if (v.employerSepReasonAnsweredInd == IndicatorTypes_class.enum.N) return true;
  if (v.employerSepQuestionShownInd == IndicatorTypesWithD_class.enum.D) return true;
  return false;
}
export const opTemporaryStaffingInd = (v: IMc): boolean => {
  if ([SC.Item20, SC.Item21, SC.Item23].findIndex(f => f === v.employerSeparationReasonCode) !== -1) return false;
  if (v.employerSepReasonAnsweredInd == IndicatorTypes_class.enum.Y) return false;
  if (v.employerSepQuestionShownInd == IndicatorTypesWithD_class.enum.N || v.employerSepQuestionShownInd == null) return false;
  return true;
}
  

export const opEmployerContactName = (v: IMc): boolean => [SC.Item21, SC.Item23].findIndex(f => f === v.employerSeparationReasonCode) !== -1;
export const opEmployerPhone = (v: IMc): boolean => [SC.Item21, SC.Item23].findIndex(f => f === v.employerSeparationReasonCode) !== -1;
export const opEmployerStreetAddress = (v: IMc): boolean => [SC.Item21, SC.Item23].findIndex(f => f === v.employerSeparationReasonCode) !== -1;
export const opEmployerCity = (v: IMc): boolean => [SC.Item21, SC.Item23].findIndex(f => f === v.employerSeparationReasonCode) !== -1;
export const opEmployerState = (v: IMc): boolean => [SC.Item21, SC.Item23].findIndex(f => f === v.employerSeparationReasonCode) !== -1;
export const opEmployerZip = (v: IMc): boolean => [SC.Item21, SC.Item23].findIndex(f => f === v.employerSeparationReasonCode) !== -1;
export const opRepContractTermDate = (v: IMc): boolean => [SC.Item21, SC.Item23].findIndex(f => f === v.employerSeparationReasonCode) !== -1;


export const opEmployerReportedDependents = (v: IMc): boolean => v.dependents == null ? false : true;


