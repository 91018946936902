import { AxiosError } from 'axios';

import { ApiError } from '../../common/api/apiResponse';
import apiClient from '../../common/api/apiClient';
import { ContactDto } from '../contacts/contacts.dtos';
import { showNotification } from '../../common/actionCreators/dispatchNotification';


// See other Api files for examples.
// endpoints
const contactsUrl = 'contacts';
const contactsImportUrl = 'upload/import/contact';
const contactsImportHistoryUrl = 'upload/history/contact';
const sendPasswordResetUrl = 'send-password-reset-email';

export const getContactsApi = async () => {
  return await apiClient().get(`${contactsUrl}`)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while retrieving contacts.', 'error');
      throw err;
    });
};

export const getContactApi = async (id: string) => {
  return await apiClient().get(`${contactsUrl}/${id}`)
    .then((res) => {

      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while retrieving contacts.', 'error');
      throw err;
    });
};

export const updateContactApi = async (request: ContactDto) => {
  let data = new FormData();
  if (request.fileUpload !== undefined) {
    data.append('file', request.fileUpload);
  }
  delete request.alternateEmployers;
  data.append('model', JSON.stringify(request));
  
  return await apiClient().put(`${contactsUrl}`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((res) => {
      showNotification("Contact Updated", 'success');
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while updating a contact.', 'error');
      throw err;
    });
};

export const createContactApi = async (request: ContactDto) => {
  const data = new FormData();
  if (request.fileUpload !== undefined) {
    data.append('file', request.fileUpload);
  }
  data.append('model', JSON.stringify(request));
  return await apiClient().post(`${contactsUrl}`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then((res) => {
      showNotification("Contact Created", 'success');
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while creating a contact.', 'error');
      throw err;
    });
};

export const getContactUploadHistoryApi = async () => {
  return await apiClient().get(`${contactsImportHistoryUrl}`)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while retrieving contact inports history.', 'error');
      throw err;
    });
};

export const uploadContactImportApi = async (request: any) => {
  const data = new FormData();
  data.append('file', request);
  return await apiClient().post(`${contactsImportUrl}`, data)
    .then((res) => {
      showNotification("Processing upload.", 'success');
      return true;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while processing bulk import.', 'error');
      throw err;
    });
};

export const createInteractionApi = async (request: ContactDto) => {
  return await apiClient().post(`${contactsUrl}`, request)
    .then((res) => {
      showNotification("Interaction Created", 'success');
      return res.data.result as ContactDto;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while creating an interaction.', 'error');
      throw err;
    });
};

export const sendPasswordResetApi = async (username: string) => {
  return await apiClient().get(`/account/${username}/${sendPasswordResetUrl}`)
    .then((res) => {
      showNotification('Set password email sent.', 'success');
      return true;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while sending password reset email.', 'error');
      throw err;
    });
};