


import { ISelectOption } from "../dtos/SelectOptionDto";
enum TotalWeeksWorkedNeededIndCodes {
        Item1 = 0,
        Item2 = 1,
        Item3 = 2,
         
}

export class TotalWeeksWorkedNeededIndCodes_class {

public static enum = TotalWeeksWorkedNeededIndCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['Item1']: 0,
    ['Item2']: 1,
    ['Item3']: 2,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "1- Weeks are available",
    [1]: "2- Weeks not currently available",
    [2]: "3- Weeks not available, state should proceed without weeks",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "1- Weeks are available"},
  {value: 1, text: "2- Weeks not currently available"},
  {value: 2, text: "3- Weeks not available, state should proceed without weeks"},
  ]
}

