import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getType } from 'typesafe-actions';
// material ui
import {
  Grid, Box, Typography, Checkbox, TextField,
  InputAdornment, Card, CardContent, Link,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import SearchIcon from '@material-ui/icons/Search';
import { HealthSystemDto, } from './healthSystems.dtos';
import { getHealthSystemsActions, healthSystemShouldRefresh } from './healthSystems.actions';
import { isReadOnly } from '../../../common/dtos/auth';
import { rootSelector } from '../../../common/selectors/selectors';
import { BMGrid, CardCheckbox, CardContent1, Checkbox1, LargeIconButton } from '../../../common/styles/styles';
import { FeatureTypeEnum } from '../../../common/enums/featureTypeEnum';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const HealthSystems: React.FC = () => {
  const dispatch = useDispatch();
  let query = useQuery();

  const search = query.get("search");

  const healthSystems = rootSelector(state => state.healthSystems.healthSystems);

  const [viewActiveOnly, setViewActiveOnly] = React.useState<boolean>(true);
  const [healthSystemsList, setHealthSystemsList] = React.useState<HealthSystemDto[]>([]);
  const [healthSystemSearchOptions, setHealthSystemSearchOptions] = React.useState<string[]>([]);
  const [searchQuery, setSearchQuery] = React.useState<string>("");

  React.useEffect(() => {
    let searchOptions: string[] = [];
    healthSystemsList.forEach((item: HealthSystemDto) => {
      if (item.healthSystemName !== undefined && item.healthSystemName.length !== 0) { searchOptions.push(item.healthSystemName); }
    });
    setHealthSystemSearchOptions(searchOptions.sort((a, b) => a.localeCompare(b)));
  }, [healthSystemsList]);

  React.useEffect(() => {
    if (search !== undefined && search !== null && search.length !== 0) {
      setSearchQuery(search);
    }
  }, [search]);

  React.useEffect(() => {
    setHealthSystemsList(JSON.parse(JSON.stringify(healthSystems)));
  }, [healthSystems]);

  React.useEffect(() => {
    dispatch({ type: getType(getHealthSystemsActions.request) });
  }, [dispatch]);


  const handleAddHealthSystem = () => {
    let s = isReadOnly(FeatureTypeEnum.Settings);
    if (s === false) {
      dispatch({ type: getType(healthSystemShouldRefresh), payload: '' });
      window.location.assign('healthSystems/new');
    }
  }

  const handleViewActiveOnlyToggle = () => {
    setViewActiveOnly(!viewActiveOnly);
  }


  return <>
    <Box p={2}>
      <Grid container data-cy="healthSystems_Container" item xs={9}>
        <Grid item xs={11}>
          <Typography variant="subtitle1" display="inline">Health Systems</Typography> <Typography
            variant="subtitle1"
            color="textSecondary"
            display="inline">
            ({healthSystemsList
              .filter((item: HealthSystemDto) => item.isActive || item.isActive === viewActiveOnly)
              .filter((item: HealthSystemDto) => (item.healthSystemName ?? '').toLowerCase()
                .includes(searchQuery.toLowerCase())).length})
            </Typography>
        </Grid>
        <Grid item xs={1} hidden={isReadOnly(FeatureTypeEnum.Settings)}>
          <LargeIconButton title="Add Health System" color="secondary" onClick={handleAddHealthSystem} data-cy="healthSystems_details_addHealthSystem">
            <AddBoxRoundedIcon />
          </LargeIconButton>
        </Grid>

        <BMGrid container spacing={1}>
          <Grid item xs={12} sm={2}>
            <Formik initialValues={{ search: search ?? '' }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={Yup.object({
                search: Yup.string().max(200, 'Must be 200 characters or less')
              })}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => { setSubmitting(false); }, 700);
                setSearchQuery(values.search);
              }}
              enableReinitialize={true}
            >
              {(props) => {
                const { values, touched, errors, handleChange, handleSubmit, setFieldValue } = props;
                return (
                  <form>
                    <Autocomplete
                      id="autoSearch"
                      options={healthSystemSearchOptions}
                      inputValue={values.search || ''}
                      autoComplete
                      includeInputInList
                      freeSolo
                      onInputChange={(_e: any, v: string | null) => { setFieldValue('search', v ?? ''); handleSubmit(); }}
                      renderInput={(params) => (<TextField {...params} id="search" size="small" margin="none" value={values.search} label="Search" name="search"
                        onChange={(e) => { handleChange(e); handleSubmit(); }} error={Boolean(errors.search)}
                        onKeyDown={e => { if (e.keyCode === 13) { e.preventDefault(); } }}
                        helperText={(errors.search && touched.search) && errors.search} data-cy="healthSystem_search"
                        InputProps={{ ...params.InputProps, startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>), }}
                      />)} />
                  </form>);
              }}
            </Formik>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Checkbox1 onChange={handleViewActiveOnlyToggle} value={!viewActiveOnly} checked={!viewActiveOnly}
              control={<Checkbox size="small" data-cy="healthSystems_viewActiveOnly" />}
              label={<Typography variant="subtitle2">Show Inactive</Typography>} />
          </Grid>

        </BMGrid>

        <Grid container spacing={2}>
          {healthSystemsList
            .filter((item: HealthSystemDto) => item.isActive || item.isActive === viewActiveOnly)
            .filter((item: HealthSystemDto) => (item.healthSystemName ?? '').toLowerCase().includes(searchQuery.toLowerCase()))
            .sort((a, b) => (a.healthSystemName ?? '').localeCompare(b.healthSystemName ?? '')).map((o, index) => (
              <Grid key={'grid' + index} item xs={12}>
                <Card key={'card' + index} raised>
                  <CardContent1 key={'cardContent' + index}>
                    <Grid container>
                      <div hidden data-cy={`healthSystems_card_id_${o.healthSystemName}`}>{o.healthSystemId}</div>
                      <Grid item xs={12} key={o.healthSystemName + index}>
                        <Link
                          component={RouterLink}
                          to={`/settings/healthSystems/${o.healthSystemId}`}
                          data-cy={`healthSystems_list_card_link_${o.healthSystemName}`}
                          variant="subtitle1"
                          color="secondary">{o.healthSystemName ?? '-No name on file-'}</Link>
                        <CardCheckbox value={''}
                          control={<Checkbox size="small" />}
                          label={<Typography variant="subtitle2">Active</Typography>} labelPlacement="top" checked={o.isActive} />
                      </Grid>
                    </Grid>
                  </CardContent1>
                </Card>
              </Grid>
            ))}


          <Grid item xs={12} hidden={healthSystemsList
            .filter((item: HealthSystemDto) => item.isActive || item.isActive === viewActiveOnly)
            .filter((item: HealthSystemDto) => (item.healthSystemName ?? '').toLowerCase().includes(searchQuery.toLowerCase())).length !== 0}>
            <Card>
              <CardContent>
                <Grid container justifyContent="center" alignItems="center">
                  <Typography variant="subtitle1" color="secondary">No Records Found</Typography>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

        </Grid>

      </Grid>
    </Box>
  </>

}

export default HealthSystems;