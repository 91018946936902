import { createAsyncAction } from 'typesafe-actions';
import { RoleDto } from '../../../common/dtos/role';
import { SystemRoleSettingDto } from './system.dtos';

export const getRoleSystemSettingsActions = createAsyncAction(
  'GET_ROLE_SYSTEM_SETTINGS_REQUEST',
  'GET_ROLE_SYSTEM_SETTINGS_SUCCESS',
  'GET_ROLE_SYSTEM_SETTINGS_ERROR',
)<undefined, SystemRoleSettingDto[], string>();

export const updateRoleSystemSettingsActions = createAsyncAction(
  'UPDATE_ROLE_SYSTEM_SETTINGS_REQUEST',
  'UPDATE_ROLE_SYSTEM_SETTINGS_SUCCESS',
  'UPDATE_ROLE_SYSTEM_SETTINGS_ERROR',
)<SystemRoleSettingDto[], SystemRoleSettingDto[], string>();

export const createUserRoleSystemSettingActions = createAsyncAction(
  'CREATE_USER_ROLE_SYSTEM_SETTING_REQUEST',
  'CREATE_USER_ROLE_SYSTEM_SETTING_SUCCESS',
  'CREATE_USER_ROLE_SYSTEM_SETTING_ERROR',
)<RoleDto, SystemRoleSettingDto[], string>();