import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { forgotPasswordApi } from './forgotPassword.api';
import { forgotPasswordActions } from './forgotPassword.actions';
import { tenantId } from '../../../common/constants/strings';


function* handleForgotPassword(dto: ReturnType<typeof forgotPasswordActions.request>) {

  try {

    const response: boolean = yield call(forgotPasswordApi, dto.payload);
    if (response) {
      yield put({ type: getType(forgotPasswordActions.success), payload: true });
      setTimeout(() => { window.location.href = `/${localStorage.getItem(tenantId) ?? 'mha'}/login`; }, 1500);
    }
    else {
      put({ type: getType(forgotPasswordActions.failure), payload: "Forgot Password Failed" });
    }
    
  }
  catch (error) {
    put({ type: getType(forgotPasswordActions.failure), payload: "Forgot Password Failed" });
  }
  
}

export function* watchForgotPasswordRequest() {
  try {
    yield takeLatest(getType(forgotPasswordActions.request), handleForgotPassword);
  }
  catch (err) {
  }
}