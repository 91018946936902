import React from 'react';
import { FormikHandlers, FormikErrors, getIn } from 'formik';

// material ui
import { Grid, MenuItem } from '@material-ui/core';
import { ClaimDto, ContactIdDto } from '../claims.dtos';
import { IndentGrid, PaddedTextField } from '../../../common/styles/styles';


interface ContactInformationProps extends React.HTMLProps<React.ReactNode> {
  values: ClaimDto;
  errors: FormikErrors<ClaimDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setShouldBlockNavigation: (o: boolean) => void;
  claimsUsers: ContactIdDto[];
  isClientUser: boolean;
  isReadOnly: boolean;
}

const TeamDetails: React.FC<ContactInformationProps> = (
  {
    values,
    errors,
    handleBlur,
    setShouldBlockNavigation,
    claimsUsers,
    handleChange,
    isClientUser,
    isReadOnly
  }) => {

  return (
    <IndentGrid container item xs={12} spacing={2}>

      <Grid item xs={12} sm={12}>
        <PaddedTextField
          id='claimUserId'
          size="small"
          margin="none"
          disabled={isClientUser || isReadOnly}
          value={values.claimUserId || ''}
          label="Claims User"
          onChange={(e) => handleChange(e)}
          name="claimUserId"
          onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
          error={Boolean(getIn(errors, 'claimUserId'))}
          helperText={getIn(errors, 'claimUserId') && getIn(errors, 'claimUserId')}
          data-cy='claim_teamDetails_claimUser'
          fullWidth
          select
        >
          {claimsUsers.map(option => (
            <MenuItem key={option.contactId} value={option.contactId}>{option.name}</MenuItem>
            ))}
        </PaddedTextField>
      </Grid>
      
    </IndentGrid>
  )
}

export default TeamDetails;
