import { combineReducers } from 'redux';
import { createReducer, getType } from 'typesafe-actions';

import { getHealthSystemsByIdActions, createHealthSystemsActions, getHealthSystemsActions, getHealthSystemRateActions, updateHealthSystemRateActions, createHealthSystemRateActions, updateHealthSystemsActions, healthSystemListShouldRefresh, healthSystemShouldRefresh } from './healthSystems.actions';
import { HealthSystemDto, HealthSystemInit } from './healthSystems.dtos';
import { RateDto, RateWrapperDto, RateWrapperInit } from '../../../common/dtos/billing';


const healthSystemsReducer = combineReducers({
  healthSystems: createReducer([] as HealthSystemDto[])
    .handleAction(
      [
        getHealthSystemsActions.success,
      ],
      (state, action) => action.payload),

  createUpdateHealthSystems: createReducer(HealthSystemInit)
    .handleAction(
      [
        createHealthSystemsActions.request,
        createHealthSystemsActions.success,
        updateHealthSystemsActions.request,
        updateHealthSystemsActions.success,
      ], (state, action) => action.payload),

  getHealthSystemsById: createReducer(HealthSystemInit as HealthSystemDto)
    .handleAction(
      [
        getHealthSystemsByIdActions.success,

      ], (state, action) => action.payload),

  getHealthSystemsByIdRequest: createReducer('')
    .handleAction(
      [
        getHealthSystemsByIdActions.request,
      ], (state, action) => action.payload),

  refreshHealthSystem: createReducer('')
    .handleType(getType(healthSystemShouldRefresh), (state, action) => action.payload),

  refreshHealthSystemList: createReducer(true)
    .handleType(getType(healthSystemListShouldRefresh), (state, action) => action.payload),


    rateRequest: createReducer(RateWrapperInit as RateWrapperDto)
        .handleAction(
            [
                getHealthSystemRateActions.request
            ], (state, action) => action.payload),

    rate: createReducer(RateWrapperInit as RateWrapperDto)
        .handleAction(
            [
                getHealthSystemRateActions.success
            ], (state, action) => action.payload),


});

export default healthSystemsReducer;