export interface TelerikDto {
  serviceUrl: string,
  reportSource: TelerikReportSourceDto,
  scale: number,
  viewMode: string,
  printMode: string,
  sendEmail: TelerikSendEmailDto,
  parameters?: BCTelerikParamsDto,
};

export interface TelerikReportSourceDto {
  report?: string,
  parameters?: any
};

export interface TelerikSendEmailDto {
  enabled: boolean,
};

export interface BCTelerikParamsDto {
  StartYearParameter: number,
  EndYearParameter: number
  EmployerParameter: string,
  EmployerNameParameter: string,
  PeerGroup1Parameter: string,
  PeerGroup2Parameter: string,
  PeerGroup3Parameter: string,
  PeerGroup4Parameter: string,
  PeerGroup5Parameter?: string,
  PeerGroup6Parameter?: string,
  PeerGroup7Parameter?: string,
  PeerGroup8Parameter?: string,
};



export const TelerikInit: TelerikDto = {
  serviceUrl: process.env.REACT_APP_API_URL + "api/telerik/reports",
  reportSource: {
    parameters: { StartDateParameter: new Date().setDate(1).toString(), EndDateParameter: new Date().setDate(27).toString() }
  },
  scale: 1.0,
  viewMode: 'INTERACTIVE',
  printMode: 'SPECIFIC',
  sendEmail: { enabled: false },
}