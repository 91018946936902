import React from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getType } from 'typesafe-actions';

import { Grid, Box, Button, Checkbox, FormControlLabel, InputLabel, Select, Card, CardContent, Typography, MenuItem, ListItem } from '@material-ui/core';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';

import BottomActionBar from '../../../../common/components/bottomActionBar';
import MoneyField from '../../../../common/components/numberFields';
import * as Style from '../workOrders.styles';
import * as Styles from '../../../../common/styles/styles';

import { rootSelector } from '../../../../common/selectors/selectors';
import { taskTypesWorkOrderSettingsInit, TaskTypesWorkOrderSettingsDto } from '../workOrders.dtos';
import { getTaskTypesWorkOrderSettingsActions, updateTaskTypesWorkOrderSettingsActions } from '../workOrders.actions';
import TaskTypeEdit from './taskRateEdit';

const TaskTypeSettings: React.FC = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch({ type: getType(getTaskTypesWorkOrderSettingsActions.request) });
  }, [dispatch]);

  const sytemTaskTypes = rootSelector(state => state.taskTypesWorkOrderSettings.taskTypeWorkOrderSettings);

  const [savingTaskTypes, setSavingTaskTypes] = React.useState(false);
  const [hideAddTaskType, setHideAddTaskType] = React.useState(true);
  const [hideInUse, setHideInUse] = React.useState(false);
  const [taskTypes, setTaskTypes] = React.useState([] as TaskTypesWorkOrderSettingsDto[]);
  const [shouldBlockNavigation, setShouldBlockNavigation] = React.useState(false);


  React.useEffect(() => {
    setTaskTypes(JSON.parse(JSON.stringify(sytemTaskTypes)));
    setShouldBlockNavigation(false);
  }, [sytemTaskTypes]);

  const handleCancel = () => {
    window.location.href = "/settings/work-orders";
  }
  const handleSubmit = () => {
    setSavingTaskTypes(true);
    setTimeout(() => { setSavingTaskTypes(false); }, 700);
    taskTypes.forEach((tt: TaskTypesWorkOrderSettingsDto) => {
      if (tt.taskTypeId !== undefined && tt.taskTypeId.startsWith('isNew-')) {
        delete tt.taskTypeId;
        tt.taskCategoryId = +tt.taskCategoryId;
      }
    });

    dispatch({ type: getType(updateTaskTypesWorkOrderSettingsActions.request), payload: taskTypes });
  }
  const handleAddTaskTypeToggle = () => {
    setHideAddTaskType(false);
  }
  const handleTaskTypeSelection = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
    const element = event.target as HTMLInputElement;
    if (element.value !== undefined) {
      const selectedTaskType = taskTypes.find((tt: TaskTypesWorkOrderSettingsDto) => tt.taskTypeId === element.value);
      if (selectedTaskType) {
        selectedTaskType.checked = checked;
        setTaskTypes(JSON.parse(JSON.stringify(taskTypes)));
      }
    }
  }
  const handleSelectedTaskTypeChange = (object: TaskTypesWorkOrderSettingsDto) => {
    let updatedTaskTypeIndex = taskTypes.findIndex((tt: TaskTypesWorkOrderSettingsDto) => tt.taskTypeId === object.taskTypeId);
    if (updatedTaskTypeIndex > -1) {
      let tt = JSON.parse(JSON.stringify(taskTypes)) as TaskTypesWorkOrderSettingsDto[];
      tt[updatedTaskTypeIndex] = JSON.parse(JSON.stringify(object)) as TaskTypesWorkOrderSettingsDto;
      setTaskTypes(tt);
      setShouldBlockNavigation(true);
    }
  }
  const handleHideInUseToggle = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
    const element = event.target as HTMLInputElement;
    if (element.value !== undefined) {
      setHideInUse(checked);
    }
  }

  return <>
    <Box p={2}>
      <Card>
        <CardContent>
          <Grid container data-cy="settings_workOrderTaskContainer">
            <Grid item xs={11}><Typography variant="subtitle1">Tasks/Rates</Typography></Grid>
            <Grid item xs={1}>
              <Styles.LargeIconButton title="Add task type" aria-label="Add task type" color="primary" onClick={handleAddTaskTypeToggle} data-cy="settings_workOrderTaskAdd">
                <AddBoxRoundedIcon />
              </Styles.LargeIconButton>
            </Grid>
            <Style.WorkOrderSettingsToggleGrid hidden={hideAddTaskType} item xs={12}>
              <Formik initialValues={taskTypesWorkOrderSettingsInit}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={Yup.object({
                  taskCategoryId: Yup.number().integer().min(1).max(4).required('Required'),
                  name: Yup.string().max(200, 'Must be 200 characters or less').required('Required'),
                  invoiceName: Yup.string().max(200, 'Must be 200 characters or less').required('Required'),
                  defaultRate: Yup.string().required('Required'),
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setTimeout(() => { setSubmitting(false); resetForm(); }, 700);
                  let tt = JSON.parse(JSON.stringify(taskTypes));
                  values.taskTypeId = 'isNew-' + Date.now().toString();
                  tt.push(values);
                  setTaskTypes(tt);
                  setHideAddTaskType(true);
                  setShouldBlockNavigation(true);
                }}
                enableReinitialize={true}
              >
                {(props) => {
                  const { values, touched, errors, isSubmitting, handleChange, handleSubmit } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Style.FormControl>
                        <InputLabel required htmlFor="taskCategoryId">Category</InputLabel>
                        <Select required value={values.taskCategoryId} onChange={handleChange} name="taskCategoryId"
                          inputProps={{ name: 'taskCategoryId', id: 'taskCategoryId' }} MenuProps={{ disableScrollLock: true }}
                          autoFocus error={Boolean(errors.taskCategoryId)} data-cy="settings_workOrderNewTaskCategory">
                          <MenuItem value={0}>Please select an item</MenuItem>
                          <MenuItem value={1}>Define</MenuItem>
                          <MenuItem value={2}>Design</MenuItem>
                          <MenuItem value={3}>Build</MenuItem>
                          <MenuItem value={4}>Deliver</MenuItem>
                        </Select>
                      </Style.FormControl>
                      <Style.TextField id="task-name" size="small" margin="none" required value={values.name} label="Name" name="name"
                              onChange={handleChange} error={Boolean(errors.name)} helperText={(errors.name && touched.name) && errors.name} data-cy="settings_workOrderNewTaskName" />
                      <Style.TextField id="task-invoiceName" size="small" margin="none" required value={values.invoiceName} label="Invoice Name" name="invoiceName"
                              onChange={handleChange} error={Boolean(errors.invoiceName)} helperText={(errors.name && touched.invoiceName) && errors.invoiceName} data-cy="settings_workOrderNewTaskInvoiceName"/>
                      <Styles.TextNumberField id="task-defaultRate" size="small" margin="none" required value={values.defaultRate} label="Default Rate" name="defaultRate"
                        onChange={handleChange} error={Boolean(errors.defaultRate)} helperText={(errors.name && touched.defaultRate) && errors.defaultRate}
                              InputProps={{ inputComponent: MoneyField as any }} data-cy="settings_workOrderNewTaskDefaultRate"/>

                          <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} data-cy="settings_workOrderTaskSave">Add </Button>
                    </form>);
                }}
              </Formik>
              <br />
            </Style.WorkOrderSettingsToggleGrid>


            <Grid item xs={12}>
              <Typography variant="subtitle1">
                              Task Types <Style.ControlLabel onChange={handleHideInUseToggle} value={hideInUse} checked={hideInUse} control={<Checkbox size="small" data-cy="settings_workOrderTaskHideNotInUse" />} label={<Typography variant="subtitle2">Hide Not in Use Types</Typography>} />
              </Typography>
            </Grid>

            {taskTypes.sort((a, b) => a.name.localeCompare(b.name)).filter((tt: TaskTypesWorkOrderSettingsDto) => tt.inUse === hideInUse).map((o, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={o.taskTypeId}>
                    <FormControlLabel onChange={handleTaskTypeSelection} value={o.taskTypeId} checked={o.checked} control={<Checkbox size="small" name={o.name} />} label={o.name} data-cy="settings_workOrderTaskType"/>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Box>

    <Box p={2}>
      <Card hidden={taskTypes.filter((tt: TaskTypesWorkOrderSettingsDto) => tt.checked && tt.inUse === hideInUse).length === 0}>
        <CardContent>
          <Grid container item xs={12} >
            <Style.FullList>
              {taskTypes.filter((tt: TaskTypesWorkOrderSettingsDto) => tt.checked && tt.inUse === hideInUse).map((o, index) => (
                <ListItem key={'list-' + o.taskTypeId} disableGutters>
                  <TaskTypeEdit key={'edit-item-' + o.taskTypeId} value={o} onChange={handleSelectedTaskTypeChange} />
                </ListItem>
              ))}
            </Style.FullList>
          </Grid>
        </CardContent>
      </Card>
    </Box>

    <Prompt
      when={shouldBlockNavigation}
      message='You have unsaved edits, are you sure you want to leave?'
    />
    <BottomActionBar cancelClickHandler={handleCancel} submitClickHandler={handleSubmit} submitting={savingTaskTypes} />
  </>

}

export default TaskTypeSettings;