import styled from 'styled-components';

// material ui
import {
  TextField, DialogActions, InputLabel, CardContent
} from '@material-ui/core';


// Styled components ....


export const SmallFileText = styled.small`
    
position: relative;
top: 12px;

`;

export const DialogCardContent = styled(CardContent)`
    margin-top: -24px;
`;



export const DialogForm = styled.form`
width: 500px;
height: 600px;
`;


export const DialogInputLabel = styled(InputLabel)`
margin-top: 25px;
margin-bottom: 10px;
`;

export const DialogMultiLineTextField = styled(TextField)`
    
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`;



export const EmailTemplateInteractionDialogActions = styled(DialogActions)`

margin-top: -30px;
    
`;
