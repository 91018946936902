import React from 'react'

//import styled from 'styled-components';
import { Typography, TypographyProps } from '@material-ui/core';

//interface LabelProps {
//  children?: any
//}

const Label = (props: TypographyProps) => (
  <Typography variant="body2" color="textSecondary" display="block" {...props}>{props.children}</Typography>
);

export default Label
