import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import { rootSelector } from '../selectors/selectors';

const SpinnerContainer = styled.div`
  position: fixed;
  z-index: 5000;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
`;

const Spinner: React.FC = () => {
  return rootSelector((state) => state.spinner) > 0 ? (
    <SpinnerContainer>
      <CircularProgress size={70} />
    </SpinnerContainer>
  ) : null;
};

export default Spinner;
