import React from 'react';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
// material ui
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { getClaimsActions } from '../actions';
import {
  ClaimSearchDto,
  ClaimSearchInit,
  claimSearchValidationSchema,
  ListClaimDto,
} from '../claims.dtos';
//import { documentStatusOptionsList } from "../../../common/enums/claimEnums";
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import NumberField from '../../../common/components/numberField';
import { rootSelector } from '../../../common/selectors/selectors';
import { BMGrid } from '../../../common/styles/styles';
import ClaimDialogCard from '../components/claimDialogCard';

interface Props {
  handleAssociate: (id: string) => void;
  handleDeassociate: (id: string) => void;
  handleSSNMaskToggle: () => void;
  handleGetMaskedSSNValue: (ssn: string | undefined) => string | undefined;
  isMasked: boolean;
  isReadOnly: boolean;
  attached: boolean;
  hideNewClaim: boolean;
  claim: ListClaimDto;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AssociateClaim: React.FC<Props> = ({
  handleAssociate,
  handleGetMaskedSSNValue,
  handleSSNMaskToggle,
  handleDeassociate,
  isMasked,
  isReadOnly,
  attached,
  hideNewClaim,
  claim,
}) => {
  const dispatch = useDispatch();
  let query = useQuery();
  const claimLimit = 20;

  const systemClaims = rootSelector((state) => state.claims.claimsList);

  const [claimsList, setClaimsList] = React.useState([] as ListClaimDto[]);
  const [claimsListPage, setClaimsListPage] = React.useState(1);
  const [claimsCount, setClaimsCount] = React.useState(0);
  const [searchComplete, setSearchComplete] = React.useState<boolean>(false);
  const [searchDto, setSearchDto] = React.useState(
    JSON.parse(JSON.stringify(ClaimSearchInit))
  );

  React.useEffect(() => {
    let claims: ListClaimDto[] = systemClaims.sort((a, b) =>
      (a.label ?? '').localeCompare(b.label ?? '')
    );

    setClaimsCount(claims.length);
    setClaimsList(claims);
    if (systemClaims.length === 0) setSearchComplete(false);
    else setSearchComplete(true);
    setTimeout(() => {
      setSearchComplete(true);
    }, 5000);
  }, [systemClaims]);

  React.useEffect(() => {
    let search = query.get('search');
    if (search !== undefined && search !== null && search.length !== 0) {
      //setSearchQuery(search);
      query.delete('search');
    }
  }, []);

  const hasSearchCriteria = (dto?: ClaimSearchDto): boolean => {
    let search: ClaimSearchDto =
      dto === undefined ? JSON.parse(JSON.stringify(searchDto)) : dto;

    return !(
      search.claimantFirstName === undefined &&
      search.claimantLastName === undefined &&
      search.ssn === undefined &&
      search.separationCode === undefined &&
      search.employerId === undefined &&
      search.stateCode === undefined &&
      search.benefitYearBeginningDate === undefined &&
      search.benefitYearEndingDate === undefined
    );
  };

  return (
    <Grid container data-cy='claims_list_container' item xs={12}>
      {!attached ? (
        <BMGrid container spacing={1}>
          <Formik
            initialValues={searchDto}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={claimSearchValidationSchema}
            onSubmit={(values) => {
              if (hasSearchCriteria(values)) {
                let request: ClaimSearchDto = JSON.parse(
                  JSON.stringify(values)
                );

                dispatch({
                  type: getType(getClaimsActions.request),
                  payload: request,
                });
                setSearchDto(request);
              }
            }}
            enableReinitialize={true}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleSubmit,
                handleBlur,
              } = props;
              return (
                <form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit();
                    }
                  }}
                  style={{ width: '100%' }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={2}>
                      <TextField
                        size='small'
                        margin='none'
                        value={values.claimantFirstName || ''}
                        label='Claimant First Name'
                        name='claimantFirstName'
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.claimantFirstName)}
                        helperText={
                          errors.claimantFirstName && touched.claimantFirstName
                        }
                      />
                    </Grid>
                    <Grid item xs={4} sm={2}>
                      <TextField
                        size='small'
                        margin='none'
                        value={values.claimantLastName || ''}
                        label='Claimant Last Name'
                        name='claimantLastName'
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.claimantLastName)}
                        helperText={
                          errors.claimantLastName && touched.claimantLastName
                        }
                      />
                    </Grid>
                    <Grid item xs={4} sm={1}>
                      <TextField
                        size='small'
                        margin='none'
                        value={values.ssn || ''}
                        label={'SSN'}
                        name='ssn'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.ssn !== undefined}
                        helperText={errors.ssn && errors.ssn}
                        fullWidth
                        InputProps={{
                          inputComponent: NumberField as any,
                        }}
                        inputProps={{
                          isSSN: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={4} sm={1}>
                      <Button
                        title='Search Button'
                        variant='contained'
                        color='secondary'
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        <Typography variant='subtitle2'>Search</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <br />
                </form>
              );
            }}
          </Formik>
        </BMGrid>
      ) : null}

      <Grid container spacing={2}>
        {!attached ? (
          <Grid
            container
            item
            style={{ maxHeight: '325px', overflowY: 'scroll' }}
          >
            {claimsList &&
              claimsList
                .slice(
                  claimsListPage * claimLimit - claimLimit,
                  claimsListPage * claimLimit
                )
                .map((o, index) => (
                  <ClaimDialogCard
                    key={`ClaimDialogCard_${index}`}
                    handleAssociate={handleAssociate}
                    handleDeassociate={handleDeassociate}
                    attached={attached}
                    isReadOnly={isReadOnly}
                    handleGetMaskedSSNValue={handleGetMaskedSSNValue}
                    handleSSNMaskToggle={handleSSNMaskToggle}
                    index={index}
                    dto={o}
                    isMasked={isMasked}
                  />
                ))}
          </Grid>
        ) : (
          <ClaimDialogCard
            key='ClaimDialogCard_Selected'
            handleAssociate={handleAssociate}
            handleDeassociate={handleDeassociate}
            attached={attached}
            isReadOnly={isReadOnly}
            handleGetMaskedSSNValue={handleGetMaskedSSNValue}
            handleSSNMaskToggle={handleSSNMaskToggle}
            dto={claim}
            isMasked={isMasked}
          />
        )}

        <Pagination
          style={{ marginTop: '15px' }}
          hidden={claimsCount === 0}
          count={Math.ceil(claimsCount / claimLimit)}
          variant='outlined'
          color='primary'
          size='small'
          page={claimsListPage}
          onChange={(e, p) => {
            setClaimsListPage(p);
          }}
        />
        {!attached && claimsCount === 0 && (
          <Grid item xs={12}>
            <Card raised>
              <CardContent>
                <Grid container justifyContent='center' alignItems='center'>
                  <Typography variant='subtitle1' color='secondary'>
                    {!searchComplete || !hasSearchCriteria(undefined)
                      ? ''
                      : `No records found matching the criteria above`}
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
            <br />
          </Grid>
        )}
      </Grid>
      <br />
      <br />
    </Grid>
  );
};

export default AssociateClaim;
