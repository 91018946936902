import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Formik,
  FormikState,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import {
  Dialog,
  DialogContent,
  Grid,
  DialogActions,
  Button,
  Paper,
  Typography,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { getType } from 'typesafe-actions';
import { rootSelector } from '../../../common/selectors/selectors';
import { DocumentsDto, McInit } from '../document.dtos';
import { getCompaniesActions } from '../../company/actions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { updateSidesActions } from '../../claims/actions';
import {
  ButtonGrid,
  StyledSubmitButton,
  StyledCancelButton,
  FixedGrid,
  VeritclePadding,
  SidesCardContent,
} from '../../../common/styles/styles';
import { SidesOptionsGrid, SidesResponseNavOption } from '../documents.styles';
import { hasTabError } from '../../../utils/mcHelperFunctions';
import { updateUisMcActions, getUisMcActions } from '../actions';
import { getCheckedMcRequest } from '../../../utils/getCheckedClaim';
import McDetails from './mcComponents/mcDetails';
import McGeneral from './mcComponents/mcGeneral';
import McMisc from './mcComponents/mcMisc';
import { mcValidationSchema } from '../sidesMcValidationScheme';
import { IMc } from '../../../common/dtos/McDto';
import { opEmployerReportedDependents } from '../../../utils/mcOptionalPropFunctions';
import { isRelationshipEndedInd, isWorkingNewAssignmentInd, isNotWorkingOnAssignment } from '../../../utils/mcRequiredPropFunctions';


interface Props {
  open: boolean;
  onCancel: () => void;
  isReadOnly: boolean;
  claimDocument: DocumentsDto;
}

const SidesMcResponseDialog: React.FC<Props> = ({
  open,
  onCancel,
  isReadOnly,
  claimDocument,
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const dispatch = useDispatch();

  const systemMc = rootSelector((state) => state.documents.uisMc);
  const systemSidesError = rootSelector((state) => state.claims.sidesError);

  const [dto, setDto] = React.useState<IMc>(McInit);
  const [navOption, setNavOption] = React.useState<number>(1);
  const [pdfScale, setPdfScale] = React.useState<number>(0.4);
  const [numPages, setNumPages] = React.useState<number>(0);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [fileName, setFileName] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [finishText, setFinishText] = React.useState<string>('Cancel');

  const baseUrl = process.env.REACT_APP_API_URL ?? '';
  const downloadDocumentUrl = baseUrl + 'download/documents/';

  React.useEffect(() => {
    setErrorMessage(systemSidesError);
  }, [systemSidesError, dto, window]);

  React.useEffect(() => {
    dispatch({ type: getType(getCompaniesActions.request) });
    //console.log('claimDocument.id', claimDocument.id);
    if (claimDocument.id !== undefined) {
      dispatch({
        type: getType(getUisMcActions.request),
        payload: claimDocument.id,
      });
    }
    setFinishText('Cancel');
  }, [dispatch, claimDocument]);

  React.useEffect(() => {
    let mc: IMc = JSON.parse(JSON.stringify(systemMc));
    if (claimDocument.id === mc.documentId) {
      setDto(getCheckedMcRequest(mc, claimDocument));
      setFileName(claimDocument?.fileName ?? '');
    } else setFileName('');
    //console.log('claimDocument', claimDocument);
    //console.log('systemMc', systemMc);
  }, [systemMc, claimDocument]);

  const handleZoomIn = () => {
    let newScale = pdfScale >= 2 ? 2 : pdfScale + 0.2;
    setPdfScale(newScale);
  };

  const handleZoomOut = () => {
    let newScale = pdfScale <= 0.2 ? 0.2 : pdfScale - 0.2;
    setPdfScale(newScale);
  };

  const handleNavOptionSwitch = (option: number) => {
    if (option >= 1 && option <= 6) setNavOption(option);
  };
  const cancelDialog = (
    reset: (nextState?: Partial<FormikState<IMc>> | undefined) => void
  ) => {
    reset();
    setNavOption(1);
    onCancel();
    dispatch({ type: getType(updateSidesActions.failure), payload: '' });
  };

  function onDocumentLoadSuccess({ numPages }: any) {
    setPageNumber(1);
    setNumPages(numPages);
  }

  function shouldShowMisc(values: IMc): boolean {
    return opEmployerReportedDependents(values)
      || isRelationshipEndedInd(values)
      || isWorkingNewAssignmentInd(values)
      || isNotWorkingOnAssignment(values)
      || (values.uisMcEmployerCharges ?? []).length !== 0
  }

  return (
    <Formik
      initialValues={dto}
      validateOnChange={false}
      validate={(value) => {
        try {
          validateYupSchema(value, mcValidationSchema, true, value);
        } catch (err) {
          //console.log('validate in catch, should have errors', err);
          return yupToFormErrors(err); //for rendering validation errors
        }
        //console.log('validate after try catch', value);
        return {};
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        //console.log('onSubmit', values);
        setTimeout(() => {
          setSubmitting(false);
          //resetForm();
          setFinishText('Back');
        }, 700);

        dispatch({
          type: getType(updateUisMcActions.request),
          payload: values,
        });
      }}
      enableReinitialize={true}
    >
      {(props) => {
        const {
          values,
          errors,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
        } = props;
        return (
          <Dialog open={open} data-cy='sidesResponse_dialog' fullScreen>
            <DialogContent>
              <SidesCardContent>
                <Grid container>
                  <Grid container item xs={4}>
                    {fileName !== undefined && fileName.length === 0 ? (
                      <Paper elevation={3}>
                        <Grid container justifyContent='center'>
                          <span
                            style={{
                              marginTop: 84,
                              fontSize: 32,
                              fontWeight: 400,
                            }}
                          >
                            PDF VIEWER
                          </span>
                        </Grid>
                      </Paper>
                    ) : (
                      <FixedGrid
                        item
                        xs={12}
                        sideswidth='48'
                        sidesheight='-132'
                      >
                        <Grid
                          container
                          item
                          xs={12}
                          justifyContent='center'
                          hidden={numPages === 0}
                        >
                          <Button
                            title='Reset Zoom'
                            color='secondary'
                            onClick={() => setPdfScale(0.4)}
                            data-cy='claim_pdf_zoomIn'
                          >
                            <RefreshIcon />
                          </Button>
                        </Grid>

                        <Grid
                          container
                          item
                          xs={12}
                          justifyContent='center'
                          hidden={numPages === 0}
                        >
                          <Button
                            title='Zoom in pdf'
                            color='secondary'
                            onClick={handleZoomOut}
                            data-cy='claim_pdf_zoomOut'
                          >
                            <ZoomOutIcon />
                          </Button>
                          <Pagination
                            page={pageNumber}
                            count={numPages}
                            variant='outlined'
                            color='primary'
                            size='small'
                            onChange={(e, p) => {
                              setPageNumber(p);
                            }}
                          />
                          <Button
                            title='Zoom in pdf'
                            color='secondary'
                            onClick={handleZoomIn}
                            data-cy='claim_pdf_zoomIn'
                          >
                            <ZoomInIcon />
                          </Button>
                        </Grid>
                        <Paper elevation={3}>
                          <Document
                            file={
                              fileName !== undefined &&
                              (fileName ?? '').length !== 0
                                ? downloadDocumentUrl +
                                  encodeURIComponent(fileName ?? '')
                                : null
                            }
                            onLoadSuccess={onDocumentLoadSuccess}
                          >
                            <Page
                              pageNumber={pageNumber}
                              width={1000}
                              scale={pdfScale}
                            />
                          </Document>
                        </Paper>
                      </FixedGrid>
                    )}
                  </Grid>
                  <Grid container alignContent='center' item xs={8} spacing={0}>
                    <SidesOptionsGrid item container direction='row' xs={7}>
                      <>
                        <Breadcrumbs>
                          <SidesResponseNavOption
                            aria-label='Details Information Tab'
                            selected={navOption === 1}
                            onClick={() => handleNavOptionSwitch(1)}
                            data-cy='claim_sidesResponse_details_tab'
                            haserror={
                              hasTabError('details', errors)
                                ? 'true'
                                : undefined
                            }
                          >
                            Details
                          </SidesResponseNavOption>
                        </Breadcrumbs>

                        <Breadcrumbs>
                          <SidesResponseNavOption
                            aria-label='General Information Tab'
                            selected={navOption === 2}
                            onClick={() => handleNavOptionSwitch(2)}
                            data-cy='claim_sidesResponse_general_tab'
                            haserror={
                              hasTabError('general', errors)
                                ? 'true'
                                : undefined
                            }
                          >
                            General
                          </SidesResponseNavOption>
                        </Breadcrumbs>

                        {shouldShowMisc(values) &&
                          <Breadcrumbs>
                            <SidesResponseNavOption
                              aria-label='Misc Information Tab'
                              selected={navOption === 3}
                              onClick={() => handleNavOptionSwitch(3)}
                              data-cy='claim_sidesResponse_misc_tab'
                              haserror={
                                hasTabError('misc', errors) ? 'true' : undefined
                              }
                            >
                              Miscellaneous
                            </SidesResponseNavOption>
                          </Breadcrumbs>
                        }
                      </>

                      {/* Sides Error message(s) */}
                      {errorMessage.length !== 0 && (
                        <Grid item xs={12} id='SidesDialog'>
                          <Typography variant='subtitle1' color='textPrimary'>
                            <Typography component='span' color='error'>
                              **{' '}
                            </Typography>
                            Response not submitted to SIDES
                            <Typography component='span' color='error'>
                              {' '}
                              **
                            </Typography>
                          </Typography>
                          {errorMessage.split('|').map((error) => (
                            <Typography
                              key={error}
                              variant='body2'
                              color='error'
                            >
                              {error}
                            </Typography>
                          ))}
                          <br />
                        </Grid>
                      )}
                    </SidesOptionsGrid>

                    <VeritclePadding
                      px={
                        errorMessage.length === 0
                          ? undefined
                          : errorMessage.split('|').length * 12 + 32 + 92
                      }
                    />

                    {navOption === 1 ? (
                      <McDetails
                        isReadOnly={isReadOnly}
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    ) : navOption === 2 ? (
                      <McGeneral
                        isReadOnly={isReadOnly}
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    ) : navOption === 3 ? (
                      <McMisc
                        isReadOnly={isReadOnly}
                        setFieldValue={setFieldValue}
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                    ) : null}
                  </Grid>
                </Grid>
              </SidesCardContent>

              <DialogActions>
                <ButtonGrid container>
                  <StyledSubmitButton
                    type='button'
                    color='primary'
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting || isReadOnly}
                    data-cy='sidesResponse_submitButton'
                  >
                    {!!values.isReadyToRespond ? 'SEND' : 'SAVE'}
                  </StyledSubmitButton>
                  <StyledCancelButton
                    data-cy='sidesResponse_cancelButton'
                    color='secondary'
                    onClick={() => cancelDialog(resetForm)}
                  >
                    {finishText}
                  </StyledCancelButton>
                </ButtonGrid>
              </DialogActions>
            </DialogContent>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default SidesMcResponseDialog;
