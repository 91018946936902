 
/* Autogenerated by Typewriter do NOT edit
   Dtos.tst is the generating template */


export interface IUisSiContactOccurrence  { 
    
    id?: number;
    externalContactTypeInd?: number;
    externalBusinessCompanyName?: string;
    externalBusinessContactName?: string;
    externalBusinessEmail?: string;
    externalBusinessPhone?: string; 
} 

export class IUisSiContactOccurrence_PropertyAttributes {
    static readonly id_Attributes = {
      required: { value: true }, name: "Id", 
      };
       static readonly externalContactTypeInd_Attributes = {
      required: { value: true }, name: "ExternalContactTypeInd", 
      };
       static readonly externalBusinessCompanyName_Attributes = {
      stringLength: { maxLength: 100, }, required: { value: false }, name: "ExternalBusinessCompanyName", 
      };
       static readonly externalBusinessContactName_Attributes = {
      stringLength: { maxLength: 60, }, required: { value: false }, name: "ExternalBusinessContactName", 
      };
       static readonly externalBusinessEmail_Attributes = {
      stringLength: { maxLength: 60, }, required: { value: false }, name: "ExternalBusinessEmail", 
      };
       static readonly externalBusinessPhone_Attributes = {
      stringLength: { maxLength: 15, }, required: { value: false }, name: "ExternalBusinessPhone", 
      };
      
}

