import {
  CardActions,
  Container,
  Grid,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { getType } from 'typesafe-actions';
import {
  ButtonGrid,
  StyledCancelButton,
  StyledCard,
  StyledCardContent,
  StyledDiv,
  StyledForm,
  StyledSubmitButton,
  StyledTextField,
} from '../../../common/styles/styles';
import { logoutActions } from '../login/login.actions';
import { updatePasswordActions } from './updatePassword.actions';
import { updatePasswordValidationSchema } from './updatePassword.constants';
import { updatePasswordInit } from './updatePassword.dtos';

const UpdatePasswordContainer = styled(Container).attrs({
  component: 'main',
})`
  margin-top: 125px;
`;

const H3 = styled.h3`
  margin-bottom: 0;
`;

const UpdatePassword: React.FC = () => {
  const dispatch = useDispatch();

  const [initialDto, setInitialDto] = React.useState(updatePasswordInit);
  const [isCurrentPasswordHidden, setIsCurrentPasswordHidden] =
    React.useState(true);
  const [isNewPasswordHidden, setIsNewPasswordHidden] = React.useState(true);
  const [isConfirmNewPasswordHidden, setIsConfirmNewPasswordHidden] =
    React.useState(true);

  React.useEffect(() => {
    let dto = updatePasswordInit;
    setInitialDto(dto);
  }, []);

  function handleLogout() {
    dispatch({ type: getType(logoutActions.request) });
    window.location.replace(
      `/${localStorage.getItem('TenantId') ?? 'mha'}/login`
    );
  }

  const handleCurrentPasswordViewToggle = () => {
    setIsCurrentPasswordHidden(!isCurrentPasswordHidden);
  };

  const handleNewPasswordViewToggle = () => {
    setIsNewPasswordHidden(!isNewPasswordHidden);
  };

  const handleConfirmNewPasswordViewToggle = () => {
    setIsConfirmNewPasswordHidden(!isConfirmNewPasswordHidden);
  };

  return (
    <UpdatePasswordContainer component='main'>
      <StyledDiv>
        <StyledCard>
          <Formik
            initialValues={initialDto}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={updatePasswordValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                setSubmitting(false);
              }, 700);

              dispatch({
                type: getType(updatePasswordActions.request),
                payload: values,
              });
            }}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <StyledCardContent>
                    <H3>Change Password</H3>
                    <small>
                      Your password has expired and must be changed.
                      <br />
                      Password must be 12 characters and have at least one
                      uppercase letter, one lowercase letter, one number, one
                      special character.
                    </small>
                    {/* Username */}
                    <Grid container>
                      <Grid item xs={12}>
                        <StyledTextField
                          id='username'
                          margin='normal'
                          InputLabelProps={{ required: true }}
                          fullWidth
                          value={values.username}
                          name='username'
                          label='Username'
                          type='text'
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(errors.username) && touched.username}
                          helperText={
                            errors.username &&
                            touched.username &&
                            errors.username
                          }
                        />
                      </Grid>
                      {/* Current password */}
                      <Grid item xs={12}>
                        <StyledTextField
                          id='currentPassword'
                          margin='normal'
                          InputLabelProps={{ required: true }}
                          fullWidth
                          value={values.currentPassword}
                          name='currentPassword'
                          label='Current Password'
                          type={isCurrentPasswordHidden ? 'password' : 'text'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            Boolean(errors.currentPassword) &&
                            touched.currentPassword
                          }
                          helperText={
                            errors.currentPassword &&
                            touched.currentPassword &&
                            errors.currentPassword
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position='end'
                                role='viewPasswordToggle'
                                onClick={handleCurrentPasswordViewToggle}
                              >
                                {isCurrentPasswordHidden ? (
                                  <IconButton
                                    tabIndex={-1}
                                    title='View Current Password'
                                  >
                                    <VisibilityIcon color='primary' />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    tabIndex={-1}
                                    title='Hide Current Password'
                                  >
                                    <VisibilityOffIcon color='primary' />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      {/* New password */}
                      <Grid item xs={12}>
                        <StyledTextField
                          id='newPassword'
                          margin='normal'
                          InputLabelProps={{ required: true }}
                          fullWidth
                          value={values.newPassword}
                          name='newPassword'
                          label='New Password'
                          type={isNewPasswordHidden ? 'password' : 'text'}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            Boolean(errors.newPassword) && touched.newPassword
                          }
                          helperText={
                            errors.newPassword &&
                            touched.newPassword &&
                            errors.newPassword
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position='end'
                                role='viewPasswordToggle'
                                onClick={handleNewPasswordViewToggle}
                              >
                                {isNewPasswordHidden ? (
                                  <IconButton
                                    tabIndex={-1}
                                    title='View New Password'
                                  >
                                    <VisibilityIcon color='primary' />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    tabIndex={-1}
                                    title='Hide New Password'
                                  >
                                    <VisibilityOffIcon color='primary' />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      {/* Confirm new password */}
                      <Grid item xs={12}>
                        <StyledTextField
                          id='confirmNewPassword'
                          margin='normal'
                          InputLabelProps={{ required: true }}
                          fullWidth
                          value={values.confirmNewPassword}
                          name='confirmNewPassword'
                          label='Confirm New Password'
                          type={
                            isConfirmNewPasswordHidden ? 'password' : 'text'
                          }
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            Boolean(errors.confirmNewPassword) &&
                            touched.confirmNewPassword
                          }
                          helperText={
                            errors.confirmNewPassword &&
                            touched.confirmNewPassword &&
                            errors.confirmNewPassword
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position='end'
                                role='viewPasswordToggle'
                                onClick={handleConfirmNewPasswordViewToggle}
                              >
                                {isConfirmNewPasswordHidden ? (
                                  <IconButton
                                    tabIndex={-1}
                                    title='View Confirm New Password'
                                  >
                                    <VisibilityIcon color='primary' />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    tabIndex={-1}
                                    title='Hide Confirm New Password'
                                  >
                                    <VisibilityOffIcon color='primary' />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </StyledCardContent>
                  <CardActions>
                    <ButtonGrid container>
                      <StyledSubmitButton
                        type='submit'
                        color='primary'
                        disabled={isSubmitting}
                      >
                        CHANGE PASSWORD
                      </StyledSubmitButton>

                      <StyledCancelButton onClick={handleLogout}>
                        CANCEL
                      </StyledCancelButton>
                    </ButtonGrid>
                  </CardActions>
                </StyledForm>
              );
            }}
          </Formik>
        </StyledCard>
      </StyledDiv>
    </UpdatePasswordContainer>
  );
};

export default UpdatePassword;
