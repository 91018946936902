import { Moment } from 'moment';

export const storageAuthToken = 'UserIdentityToken';
export const storageAuthRefreshToken = 'UserIdentityRefreshToken';
export const storageAuthInformation = 'UserInformation';
export const storageAuthTokenValidTo = 'UserIdentityTokenValidTo';
export const storageRoles = 'UserRoles';
export const XSRFToken = 'XSRF-TOKEN';
export const initialLogin = 'InitialLogin';
export const tenantId = 'TenantId';

// Report Role Types
export const roleTypeAccountingReport = 'accounting report type';
export const roleTypeDashboardReport = 'dashboard report type';
export const roleTypeManagementReport = 'management report type';

// User Roles
export const admin = 'system admin';
export const claimsUser = 'claims user';
export const appealsUser = 'appeals user';
export const readOnly = 'read-only';
export const paymentsUser = 'payments';
export const reportsUser = 'reports';

//User Roles - Client Portal
export const clientPortal_claimsUser = 'client portal-claim';
export const clientPortal_employer = 'client portal-employer';
export const clientPortal_healthSystem = 'client portal-health system';

// Feature Roles
export const canGetRoles = 'can get roles';
export const canImpersonate = 'can impersonate';
export const canAddEditUsers = 'can add/edit users';
export const canAddEditCompanies = 'can add/edit companies';
export const anAddEditContacts = 'can add/edit contacts';
export const canAddEditCustomers = 'can add/edit customers';
export const canAccessAccounting = 'accounting access';
export const canAccessHumanResources = 'human resources';
export const canBeAssignedProjects = 'can be assigned projects';
export const userTasksCanBeAssignedTo = 'user (Tasks can be assigned to)';
export const canAddEditInventory = 'can add edit inventory';
export const canAddEditTimePunches = 'can add edit time punches';
export const canAddEditAssets = 'can add edit assets';
export const canAddEditTimeEntryPeriodEndDates =
  'can add edit time entry period end dates';
export const canCreateTasks = 'can create tasks';
export const canAccessSettings = 'settings access';

// Report Categories
export const accounting = 'Accounting';
export const dashboard = 'Dashboard';
export const management = 'Management';

//Settings Types
export const howTheyFoundUs = 'How They Found Us';
export const socialChannels = 'Social Channels';
export const paymentTerms = 'Payment Terms';

// Misc
export const dateError = 'This must be a valid date.';
export const requiredError = 'This is a required field.';
export const passwordError =
  'Password must contain at least 12 characters, one uppercase letter, one lowercase letter, one number, and one special character.';
export const passwordMaxError = 'Password must be 50 characters or less';
export const passwordRegex =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#'"\\-])[A-Za-z\d-!$%^&*()_+|~=`{}\[\]:\/;<>?,.@#'"\\-]{12,50}$/;
export const notRequiredError = 'This field is not required and must be be N/A';
export const notRequiredEmptyError =
  'This field is not required and must be empty';
export const maxError = 'Must be ${max} characters or less';
export const maxNumError = 'Incorrect value selected';
export const maxDateError = (date: Moment): string =>
  `This field must be before than or equal to ${date.format('MM/DD/yyyy')}`;
export const minDateError = (date: Moment): string =>
  `This field must be after (${date.format('MM/DD/yyyy')})`;
