import { useEffect } from 'react';
function useAppendScript(resourceUrl: string, setCookieFlags: boolean = false) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = resourceUrl;
    script.setAttribute('type', 'text/javascript');
    if (setCookieFlags) {
      script.setAttribute('cookie_flags', 'samesite=none;secure');
      script.crossOrigin = '';
    }

    //attributes.forEach(a => {
    //  script.setAttribute(a[0], a[1]);
    //});

    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, [resourceUrl]);
};
export default useAppendScript;