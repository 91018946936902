import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors } from 'formik';

// material ui
import { Grid, TextField, MenuItem, FormControlLabel } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ClientQuestionnaireDto } from '../../dto';
import { PaddedTextField, MHARadio } from '../../../../common/styles/styles';
import {
  dischargeNotificationOptions,
  radioButtonBooleanOptions,
  indicatorOptions,
} from '../../constants/options';

interface NeglectOfDutiesComponentProps
  extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>['setFieldValue'];
  setShouldBlockNavigation: (o: boolean) => void;
}

const NeglectOfDutiesComponent: React.FC<NeglectOfDutiesComponentProps> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setShouldBlockNavigation,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        On what date did the final incident occur?
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <KeyboardDatePicker
          margin='normal'
          id='finalIncidentDate'
          name='finalIncidentDate'
          //label="Hire Date"
          format='MM/DD/yyyy'
          value={values.finalIncidentDate || null}
          data-cy='clientQuestionnaire_finalIncidentDate'
          fullWidth
          onChange={(date: MaterialUiPickersDate) => {
            setFieldValue('finalIncidentDate', date);
            setShouldBlockNavigation(true);
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <br />
      <Grid item xs={12}>
        Please provide details of the final incident:{' '}
      </Grid>
      <Grid item xs={4}>
        <TextField
          id='finalIncidentDetails'
          size='small'
          margin='none'
          value={values.finalIncidentDetails || ''}
          multiline
          //label="Job Title"
          name='finalIncidentDetails'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.finalIncidentDetails)}
          helperText={
            errors.finalIncidentDetails && errors.finalIncidentDetails
          }
          data-cy='clientQuestionnaire_finalIncidentDetails'
          aria-label='What was the claimant dissatisfied about?'
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={12}>
        Was the claimant previously warned, either in writing or verbally, for
        these types of violations?
      </Grid>
      <Grid container item xs={12} direction='row'>
        {indicatorOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id='wasClaimantPreviouslyWarnedIndicatorId'
              name='wasClaimantPreviouslyWarnedIndicatorId'
              onChange={(
                e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>,
                checked: boolean
              ) => {
                setFieldValue(
                  'wasClaimantPreviouslyWarnedIndicatorId',
                  indicatorOptions.find(
                    (x) => x.name === (e.target as HTMLInputElement).value
                  )?.id ?? 1
                );
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              checked={o.id === values.wasClaimantPreviouslyWarnedIndicatorId}
              value={o.name}
              control={<MHARadio size='small' />}
              label={o.name}
              data-cy='clientQuestionnaire_wasClaimantPreviouslyWarned'
            />
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid item xs={12}>
        Do you have a written policy related to the final violation incident?
      </Grid>
      <Grid container item xs={12} direction='row'>
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id='writtenPolicyExists'
              name='writtenPolicyExists'
              onChange={(
                e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>,
                checked: boolean
              ) => {
                setFieldValue(
                  'writtenPolicyExists',
                  radioButtonBooleanOptions.find(
                    (x) => x.name === (e.target as HTMLInputElement).value
                  )?.status ?? 1
                );
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              checked={o.status === values.writtenPolicyExists}
              value={o.name}
              control={<MHARadio size='small' />}
              label={o.name}
              data-cy='clientQuestionnaire_writtenPolicyExists'
            />
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid item xs={12}>
        What are the adverse effects to your company as a result of the
        claimant's actions or inactions?
      </Grid>
      <Grid item xs={4}>
        <TextField
          id='adverseEffectsOnCompany'
          size='small'
          margin='none'
          value={values.adverseEffectsOnCompany || ''}
          multiline
          //label="Job Title"
          name='adverseEffectsOnCompany'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.adverseEffectsOnCompany)}
          helperText={
            errors.adverseEffectsOnCompany && errors.adverseEffectsOnCompany
          }
          data-cy='clientQuestionnaire_adverseEffectsOnCompany'
          aria-label='Adverse effects on company'
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={10}>
        <Grid item xs={12}>
          How was the claimant notified of their discharge from employment?
        </Grid>
        <Grid item md={12} xs={12} sm={12} lg={5}>
          <PaddedTextField
            id='dischargeNotificationId'
            size='small'
            margin='none'
            select
            value={values.dischargeNotificationId || ''}
            name='dischargeNotificationId'
            onChange={(e) => {
              handleChange(e);
              setShouldBlockNavigation(true);
            }}
            onBlur={handleBlur}
            error={Boolean(errors.dischargeNotificationId)}
            helperText={
              errors.dischargeNotificationId && errors.dischargeNotificationId
            }
            data-cy='clientQuestionnaire_jobStatus'
            aria-label='Most Recent Job Status'
            fullWidth
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
          >
            {dischargeNotificationOptions
              .filter((item) => item.name !== '')
              .map((option, index) => (
                <MenuItem key={option.name + index} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
          </PaddedTextField>
        </Grid>
      </Grid>
      <br />

      <Grid item xs={12}>
        Who (name and title) discharged the claimant?
      </Grid>
      <Grid item xs={4}>
        <TextField
          id='personWhoMadeDecisionToDischarge'
          size='small'
          margin='none'
          value={values.personWhoMadeDecisionToDischarge || ''}
          multiline
          label='Name'
          name='personWhoMadeDecisionToDischarge'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.personWhoMadeDecisionToDischarge)}
          helperText={
            errors.personWhoMadeDecisionToDischarge &&
            errors.personWhoMadeDecisionToDischarge
          }
          data-cy='clientQuestionnaire_adverseEffectsOnCompany'
          aria-label='Adverse effects on company'
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={4}>
        <TextField
          id='personWhoMadeDecisionTitle'
          size='small'
          margin='none'
          value={values.personWhoMadeDecisionTitle || ''}
          multiline
          label='Job Title'
          name='personWhoMadeDecisionTitle'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.personWhoMadeDecisionTitle)}
          helperText={
            errors.personWhoMadeDecisionTitle &&
            errors.personWhoMadeDecisionTitle
          }
          data-cy='clientQuestionnaire_adverseEffectsOnCompany'
          aria-label='Adverse effects on company'
          fullWidth
        />
      </Grid>
      <br />
    </React.Fragment>
  );
};

export default NeglectOfDutiesComponent;
