import { ClaimDto } from '../claims/claims.dtos';
import * as Yup from 'yup';
import { requiredError } from '../../common/constants/strings';

export interface AdditionalQuestionDto {
  id?: string;
  question: string;
  answer?: string;
}

export interface SeparationPaymentDto {
  id?: string;
  separationPayTypeId: number;
  amountOfPay: string;
  frequencyOfPayId?: number;
  paymentIssuedDate?: Date;
  paymentTimePeriod?: string;
}

export interface CreateQuestionnaireDto {
  claim?: ClaimDto;
  questions: AdditionalQuestionDto[];
  additionalQuestionnaireInformation?: string;
  provideFullSSN: boolean;
}

export const createQuestionnaireDialogInit: CreateQuestionnaireDto = {
  questions: [{ question: '' }],
  provideFullSSN: false,
};
export const SeparationPaymentInit: SeparationPaymentDto = {
  separationPayTypeId: 0,
  amountOfPay: '',
};

//FOR YUP VALIDATION
//Strings should not exceed their limits on swagger.Check Swagger for specifications.Decimals will not be strings.Their length will be 200.

export interface QuestionnaireFileDto {
  fileName: string;
  fileUpload?: any;
}

export interface ClientQuestionnaireDto {
  id?: string;
  claimId?: string;
  parentClaim?: ClaimDto;
  questions?: AdditionalQuestionDto[];
  suta?: string;
  hireDateTime?: Date;
  lastRateOfPay?: string;
  hrContact?: string;
  additionalInformation?: string;
  documents?: QuestionnaireFileDto[]; //MAIN DOCS ARRAY - OTHER ARRAYS FEED IN TO THIS
  preexistingAttachedDocumentFilenames?: string[]; // The filenames of documents that have previously been uploaded to the claim by the customer
  completed: boolean;
  finalIncidentDate?: Date;
  finalIncidentDetails?: string;
  adverseEffectsOnCompany?: string;
  personWhoMadeDecisionToDischarge?: string;
  personWhoMadeDecisionTitle?: string;
  didClaimantRaiseComplaint?: boolean;
  whatWasDoneToAddressComplaints?: string;
  wasClaimantPreviouslyWarnedIndicatorId?: number;
  writtenPolicyExists?: boolean;

  claimantJobStatusTypeId?: number;
  claimantJobTitle?: string; // 200 character max.
  separationDate?: Date;
  lastDayWorkedDate?: Date;

  //Did the claimant receive separation pay; other than regular wages earned(for example: PTOpayout;
  //severance pay; notice pay; bonus pay; etc.)?
  questionA?: boolean;

  //Has the claimant either been laid off; or is experiencing a reduction in hours related to COVID 19 ?
  questionB?: boolean;

  //Do you believe this claim was filed fraudulently ?
  questionC?: boolean;

  initialSeparationQuestionId?: number;
  weeklyAvgWage?: string;
  weeklyAvgHours?: string;
  falseFiling?: boolean;
  payments?: SeparationPaymentDto[];

  //Reason 1 - Resignation
  resignationReasonId?: number;
  didClaimantRequestLoa?: boolean;
  wasLeaveGranted?: boolean;
  whyLeaveNotGranted?: string;
  didClaimantProvideMedicalDocs?: boolean;
  didClaimantExploreOptions?: boolean;
  resignationStartDate?: Date;
  resignationAnticipatedEndDate?: Date;

  //Job dissatisfaction
  dissatisfactionReason?: string;

  //Failure to report/job abandonment
  noCallNoShowDates?: string;

  //Working conditions
  issuesReported?: string;

  //Failure to return from LOA (Leave of Absence)
  failureToReturnLoaBeginDate?: Date;
  anticipatedLeaveDate?: Date;
  failureToReturnReasonId?: number;
  employerNotified?: boolean;
  claimantWouldReturnToPosition?: boolean;
  failureToReturnWhyNot?: string;
  //Other
  reasonClaimantGaveForResignation?: string;

  //Reason 2 - Discharge
  dischargeReasonId?: number;
  contestClaim?: boolean;

  //Not qualified/job performance
  dischargeNotificationId?: number;

  //Absenteeism
  reasonForAbsence?: string;
  advanceNoticeGiven?: boolean;
  disciplineConsideredIndicatorId?: number;

  // Drugs / Alcohol
  wasClaimantTested?: boolean;
  drugTestDate?: Date;
  drugTestReason?: string;
  wasDrugTestPositive?: boolean;
  substance?: string;

  //Theft
  wereThereWitnesses?: string;
  whatWasStolen?: string;
  itemOwner?: string;
  howDidEmployerLearn?: string;
  admittedToTheft?: boolean;
  policeContacted?: boolean;
  dischargeIncidentInformation?: string;

  //Loss of license
  claimantJobDuties?: string;
  requirementLost?: string;
  licenseRequiredAtHire?: boolean;
  licenseNecessary?: boolean;
  reasonForLostLicense?: string;
  explanationOfferedIndicatorId?: number;
  licenseLostDetails?: string;

  //Reason 3 - Lack Of Work
  lackOfWorkReasonId?: number;
  scheduledReturnDate?: Date;
  covidLayoff?: boolean;

  //Reason 4 - Other
  otherSeparationReasonId?: number;

  //School employee on semester/holiday break
  reasonableAssuranceGiven?: boolean;
  currentBreakDates?: string;
  expectedReturnDate?: Date;
  willClaimantReturn?: string;

  //Still employed - full time
  hoursWorkedOriginal?: string;

  //Still employed - part time
  hoursWorked?: string;
  workingAllHours?: boolean;
  offersRefused?: boolean;
  reasonForRefusal?: string;

  //Still employed - hours recently reduced
  whoInitiatedReductionId?: number;
  effectiveChangeDate?: Date;
  hoursReductionReason?: string;
  requestInWriting?: boolean;

  //Seasonal/Temporary employment
  seasonalStatus?: boolean;

  //Leave Of Absence
  loaBeginDate?: Date;
  typeOfLeaveGrantedId?: number;
  loaEnded?: boolean;
  loaEndDate?: Date;
  anticipatedReturnDate?: Date;
  returnedToWork?: boolean;
  reasonForNotReturning?: string;
  payReceived?: boolean;
  typeOfPay?: string;

  //Disciplinary Suspension
  suspensionDates?: string;
  amountPaidDuringSuspension?: string;
  whoSuspendedClaimant?: string;
  whoSuspendedClaimantTitle?: string;

  //Labor dispute
  laborDisputeSituation?: string;

  //Disaster Related Separation
  lastDayPriorToDisaster?: Date;
  workSiteClosedDate?: Date;
  hasWorkSiteOpened?: boolean;
  disasterCompensationReceived?: boolean;

  isIndependentContractor?: boolean;
  additionalQuestionnaireInformation?: string;
  provideFullSSN: boolean;
  employeeId?: string;
  submitName?: string;
  submitEMail?: string;
}

export const ClientQuestionnaireInit: ClientQuestionnaireDto = {
  documents: [],
  payments: [SeparationPaymentInit],
  completed: false,
  provideFullSSN: false,
};

export const questionnaireValidationSchema = Yup.object().shape({
  lastDayWorkedDate: Yup.date().nullable(),
  claimantJobTitle: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable()
    .when(['completed', 'falseFiling'], {
      is: (c, f) => c && !f,
      then: Yup.string().required(requiredError),
    }),
  claimantJobStatusTypeId: Yup.number().min(0).max(5).nullable(),
  hireDateTime: Yup.date()
    .nullable()
    .when(['completed', 'falseFiling'], {
      is: (c, f) => c && !f,
      then: Yup.date().required(requiredError),
    }),
  lastRateOfPay: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  hrContact: Yup.string().max(40, 'Must be 40 characters or less').nullable(),
  additionalInformation: Yup.string()
    .max(1000, 'Must be 1000 characters or less')
    .nullable(),
  completed: Yup.boolean().nullable(),
  finalIncidentDate: Yup.date().nullable(),
  finalIncidentDetails: Yup.string()
    .max(1000, 'Must be 1000 characters or less')
    .nullable(),
  adverseEffectsOnCompany: Yup.string()
    .max(1000, 'Must be 1000 characters or less')
    .nullable(),
  personWhoMadeDecisionToDischarge: Yup.string()
    .max(400, 'Must be 400 characters or less')
    .nullable(),
  personWhoMadeDecisionTitle: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  didClaimantRaiseComplaint: Yup.boolean().nullable(),
  whatWasDoneToAddressComplaints: Yup.string()
    .max(1000, 'Must be 1000 characters or less')
    .nullable(),
  wasClaimantPreviouslyWarnedIndicatorId: Yup.number().nullable(),
  writtenPolicyExists: Yup.boolean().nullable(),
  separationDate: Yup.date().nullable(),
  questionA: Yup.boolean().nullable(),
  questionB: Yup.boolean().nullable(),
  questionC: Yup.boolean().nullable(),
  initialSeparationQuestionId: Yup.number()
    .nullable()
    .when(['completed', 'falseFiling'], {
      is: (c, f) => c && !f,
      then: Yup.number().required(requiredError),
    }),
  weeklyAvgWage: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  weeklyAvgHours: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  separationPayments: Yup.array().of(
    Yup.object().shape({
      separationPayTypeId: Yup.number().nullable(),
      amountOfPay: Yup.string()
        .max(200, 'Must be 200 characters or less')
        .nullable(),
      frequencyOfPayId: Yup.number().nullable(),
      paymentIssuedDate: Yup.date().nullable(),
      paymentTimePeriod: Yup.string()
        .max(200, 'Must be 200 characters or less')
        .nullable(),
    })
  ),
  falseFiling: Yup.boolean().nullable(),
  resignationReasonId: Yup.number().nullable(),
  didClaimantRequestLoa: Yup.boolean().nullable(),
  wasLeaveGranted: Yup.boolean().nullable(),
  didClaimantProvideMedicalDocs: Yup.boolean().nullable(),
  didClaimantExploreOptions: Yup.boolean().nullable(),
  resignationStartDate: Yup.date().nullable(),
  resignationAnticipatedEndDate: Yup.date().nullable(),
  dissatisfactionReason: Yup.string()
    .max(1000, 'Must be 1000 characters or less')
    .nullable(),
  noCallNoShowDates: Yup.string()
    .max(1000, 'Must be 1000 characters or less')
    .nullable(),
  issuesReported: Yup.string()
    .max(1000, 'Must be 1000 characters or less')
    .nullable(),
  failureToReturnLoaBeginDate: Yup.date().nullable(),
  anticipatedLeaveDate: Yup.date().nullable(),
  failureToReturnReasonId: Yup.number().nullable(),
  employerNotified: Yup.boolean().nullable(),
  claimantWouldReturnToPosition: Yup.boolean().nullable(),
  failureToReturnWhyNot: Yup.string()
    .max(1000, 'Must be 1000 characters or less')
    .nullable(),
  reasonClaimantGaveForResignation: Yup.string()
    .max(1000, 'Must be 1000 characters or less')
    .nullable(),
  dischargeReasonId: Yup.number().nullable(),
  contestClaim: Yup.boolean().nullable(),
  dischargeNotificationId: Yup.number().nullable(),
  reasonForAbsence: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  advanceNoticeGiven: Yup.boolean().nullable(),
  disciplineConsideredIndicatorId: Yup.number().nullable(),
  wasClaimantTested: Yup.boolean().nullable(),
  drugTestDate: Yup.date().nullable(),
  drugTestReason: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  wasDrugTestPositive: Yup.boolean().nullable(),
  substance: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
  wereThereWitnesses: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  whatWasStolen: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  itemOwner: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
  howDidEmployerLearn: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  admittedToTheft: Yup.boolean().nullable(),
  policeContacted: Yup.boolean().nullable(),
  dischargeIncidentInformation: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  claimantJobDuties: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  requirementLost: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  licenseRequiredAtHire: Yup.boolean().nullable(),
  licenseNecessary: Yup.boolean().nullable(),
  reasonForLostLicense: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  explanationOfferedIndicatorId: Yup.number().nullable(),
  licenseLostDetails: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  lackOfWorkReasonId: Yup.number().nullable(),
  scheduledReturnDate: Yup.date().nullable(),
  covidLayoff: Yup.boolean().nullable(),
  otherSeparationReasonId: Yup.number().nullable(),
  reasonableAssuranceGiven: Yup.boolean().nullable(),
  currentBreakDates: Yup.string()
    .max(500, 'Must be 500 characters or less')
    .nullable(),
  expectedReturnDate: Yup.date().nullable(),
  willClaimantReturn: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  hoursWorkedOriginal: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  hoursWorked: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  workingAllHours: Yup.boolean().nullable(),
  offersRefused: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  reasonForRefusal: Yup.string()
    .max(1000, 'Must be 1000 characters or less')
    .nullable(),
  whoInitiatedReductionId: Yup.number().nullable(),
  effectiveChangeDate: Yup.date().nullable(),
  hoursReductionReason: Yup.string()
    .max(1000, 'Must be 1000 characters or less')
    .nullable(),
  requestInWriting: Yup.boolean().nullable(),
  seasonalStatus: Yup.boolean().nullable(),
  loaBeginDate: Yup.date().nullable(),
  typeOfLeaveGrantedId: Yup.number().nullable(),
  loaEnded: Yup.boolean().nullable(),
  loaEndDate: Yup.date().nullable(),
  anticipatedReturnDate: Yup.date().nullable(),
  returnedToWork: Yup.boolean().nullable(),
  reasonForNotReturning: Yup.string()
    .max(1000, 'Must be 1000 characters or less')
    .nullable(),
  payReceived: Yup.boolean().nullable(),
  typeOfPay: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
  suspensionDates: Yup.string()
    .max(500, 'Must be 500 characters or less')
    .nullable(),
  amountPaidDuringSuspension: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  whoSuspendedClaimant: Yup.string()
    .max(500, 'Must be 500 characters or less')
    .nullable(),
  whoSuspendedClaimantTitle: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  laborDisputeSituation: Yup.string()
    .max(1000, 'Must be 1000 characters or less')
    .nullable(),
  lastDayPriorToDisaster: Yup.date().nullable(),
  workSiteClosedDate: Yup.date().nullable(),
  hasWorkSiteOpened: Yup.boolean().nullable(),
  disasterCompensationReceived: Yup.boolean().nullable(),
  submitName: Yup.string()
    .max(500, 'Must be 500 characters or less')
    .nullable()
    .when('completed', {
      is: true,
      then: Yup.string()
        .max(500, 'Must be 500 characters or less')
        .required(requiredError),
    }),
  submitEMail: Yup.string()
    .max(500, 'Must be 500 characters or less')
    .nullable()
    .when('completed', {
      is: true,
      then: Yup.string()
        .max(500, 'Must be 500 characters or less')
        .required(requiredError),
    }),
  employeeId: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
});
