import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors, getIn } from 'formik';
// material ui
import { Grid, TextField, MenuItem, FormControlLabel, Checkbox, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { ContactDto } from '../contacts.dtos';
import { AddressDto } from '../../../common/dtos/address';
import postalCodeField from '../../../common/components/postalCodeField';
import { PaddedBottomTextField } from '../../../common/styles/styles';
import { PaddedStreetTextField } from '../contacts.styles';


interface ContactAddressProps extends React.HTMLProps<React.ReactNode> {
  address: AddressDto;
  addresses: AddressDto[];
  errors: FormikErrors<ContactDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ContactDto>["setFieldValue"];
  index: number;
  stateOptions: string[];
  countryOptions: string[];
  setShouldBlockNavigation: (o: boolean) => void;
  handleCountryChange: (name: string) => void;
  isReadOnly: boolean;
}

const ContactAddress: React.FC<ContactAddressProps> = (
  {
    address,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    index,
    stateOptions,
    countryOptions,
    addresses,
    isReadOnly,
    setShouldBlockNavigation,
    handleCountryChange
}) => {

  React.useEffect(() => {
    let usa = "United States of America (the)";
    if (countryOptions.includes(usa) && ((address.country === undefined || address.country.length === 0) || address.country === "United States")) {
      setFieldValue(`addresses[${index}].country`, usa);
      handleCountryChange(usa);
    }
  }, [countryOptions, address, index, setFieldValue, handleCountryChange]);

  const handlePrimaryAddresses = () => {
    for (let i = 0; i < addresses.length; i++) {
      if (i !== index) {
        setFieldValue(`addresses[${i}].isPrimary`, false);
      }
    }
  }


  return (
    <React.Fragment key={'addressFragment' + index}>
      <Grid item sm={12} md={12} lg={3} key={'address_addressTypeId' + index} >
        <PaddedBottomTextField
          id={`addresses[${index}].addressTypeId`}
          name={`addresses[${index}].addressTypeId`}
          value={address.addressTypeId || 2}
                  onChange={e => { handleChange(e); }}
                  aria-label="Address Type Input"
          onBlur={(e) => { handleBlur(e); setShouldBlockNavigation(true); }}
                  select

                  fullWidth
                  disabled={isReadOnly}

          data-cy={`contact_address_${index}_addressDropDown`}
        >
          <MenuItem value={0} disabled>Please select an item</MenuItem>
          <MenuItem value={4}>Billing</MenuItem>
          <MenuItem value={2}>Business</MenuItem>
          <MenuItem value={3}>PO Box</MenuItem>
          <MenuItem value={1}>Residential</MenuItem>
          <MenuItem value={5}>Shipping</MenuItem>
        </PaddedBottomTextField>
      </Grid>
      <Grid item xs={12} md={12} lg={9}></Grid>
      <Grid container item direction="row" spacing={1}>
        <Grid item xs={12} md={12} key={'address_address1' + index}>
          <PaddedStreetTextField
            key={'address1_field' + index}
            id={`addresses[${index}].address1`}
            size="small"
            margin="none"
            aria-label="Address Street Input"
            value={address.address1 || ''}
            label="Street"
            disabled={isReadOnly}
            name={`addresses[${index}].address1`}
            onChange={e => { handleChange(e); }}
            onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
            error={Boolean(getIn(errors, `addresses[${index}].address1`))}
            helperText={getIn(errors, `addresses[${index}].address1`) && getIn(errors, `addresses[${index}].address1`)}
            data-cy={`contact_address_${index}_address1Input`}
            fullWidth
          />
        </Grid>
        <Grid item sm={12} md={12} lg={3} key={'address_city' + index}>
          <TextField
            key={'city_field' + index}
            id={`addresses[${index}].city`}
            size="small"
            margin="none"
            aria-label="Address City Input"
            value={address.city || ''}
            label="City"
            disabled={isReadOnly}
            name={`addresses[${index}].city`}
            onChange={e => { handleChange(e); }}
            onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
            error={Boolean(getIn(errors, `addresses[${index}].city`))}
            helperText={getIn(errors, `addresses[${index}].city`) && getIn(errors, `addresses[${index}].city`)}
            data-cy={`contact_address_${index}_cityInput`}
            fullWidth
          />
        </Grid>
        <Grid hidden={stateOptions.length === 0} item sm={12} md={12} lg={3} key={'address_stateProvence' + index}>
          <Autocomplete
            key={'stateProvence_field-' + index}
            id={`addressesTypehead[${index}].stateProvence`}
            options={stateOptions}
            disabled={isReadOnly}
            inputValue={address.stateProvence || ''}
            onChange={(_e: any, v: string | null) => { setFieldValue(`addresses[${index}].stateProvence`, v ?? ''); }}
            onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
            fullWidth
            renderInput={(params) =>
              (<TextField
                {...params}
                key={'stateProvence_field' + index}
                id={`addresses[${index}].stateProvence`}
                value={address.stateProvence || ''}
                label="State/Province"
                aria-label="Address State Input"
                name={`addresses[${index}].stateProvence`}
                size="small"
                margin="none"
                disabled={isReadOnly}
                onChange={e => { handleChange(e); }}
                onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
                error={Boolean(getIn(errors, `addresses[${index}].stateProvence`))}
                helperText={getIn(errors, `addresses[${index}].stateProvence`) && getIn(errors, `addresses[${index}].stateProvence`)}
                InputProps={{ ...params.InputProps, }}
                data-cy={`contact_address_${index}_stateProvenceInput`}
                fullWidth
                inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
              />
              )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={3} key={'address_postalCode' + index}>
          <TextField
            key={'postalCode_field' + index}
            id={`addresses[${index}].postalCode`}
            size="small"
            margin="none"
            disabled={isReadOnly}
            value={address.postalCode || ''}
            label="Postal Code"
            aria-label="Address Postal Code Input"
            name={`addresses[${index}].postalCode`}
            onChange={e => { handleChange(e); }}
            onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
            error={Boolean(getIn(errors, `addresses[${index}].postalCode`))}
            helperText={getIn(errors, `addresses[${index}].postalCode`) && getIn(errors, `addresses[${index}].postalCode`)}
            data-cy={`contact_address_${index}_postalCodeInput`}
            fullWidth
            InputProps={{ inputComponent: (postalCodeField as any) }}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={3} key={'address_country' + index}>
          <Autocomplete
            key={'country_field-' + index}
            id={`addresses[${index}].country`}
            options={countryOptions}
            disabled={isReadOnly}
            //value={address.country || ''}
            inputValue={address.country || ''}
            //onInputChange={(event, newInputValue) => { setFieldValue(`addresses[${index}].country`, newInputValue); }}
            onChange={(_e: any, v: string | null) => { setFieldValue(`addresses[${index}].country`, v ?? ''); handleCountryChange(v ?? ''); }}
            onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
            fullWidth
            renderInput={(params) =>
              (<TextField
                {...params}
                key={'country_field' + index}
                id={`addresses[${index}].country`}
                size="small"
                margin="none"
                aria-label="Address Country Input"
                disabled={isReadOnly}
                value={address.country || ''}
                label="Country"
                onChange={e => { handleChange(e); }}
                onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
                name={`addresses[${index}].country`}
                error={Boolean(getIn(errors, `addresses[${index}].country`))}
                helperText={getIn(errors, `addresses[${index}].country`) && getIn(errors, `addresses[${index}].country`)}
                InputProps={{ ...params.InputProps, }}
                data-cy={`contact_address_${index}_countryInput`}
                fullWidth
                inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
              />)}
          />

          <br />
        </Grid>
        <Grid item xs={2} sm={10} hidden={addresses.length === 1} />

        <Grid item xs={10} sm={2} hidden={addresses.length === 1}>
          <FormControlLabel
            id={`addresses[${index}].isPrimary`}
            name={`addresses[${index}].isPrimary`}
            disabled={isReadOnly}
            aria-label="Address Is Primary Checkbox"
            onChange={(e) => { handleChange(e); handlePrimaryAddresses(); }}
            onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
            value={address.isPrimary}
            checked={address.isPrimary}
            control={
              <Checkbox
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<RadioButtonCheckedIcon />}
                size="small"
                data-cy={`contact_address_${index}_addressPrimaryInput`}
            />}
            label={<Typography variant="subtitle2">Primary</Typography>}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default ContactAddress;
