import styled from 'styled-components';

// material ui
import {
  Container,
    Card,
    Grid,
    Button,
  FormControlLabel as FCL, TextField as TF
} from '@material-ui/core';


// Styled Comany Customer Settings components ....
export const CompanyCustomerSettingsContainer = styled(Container).attrs({ component: 'main' })`
    margin: 5px;
`;

export const SystemSettingsToggleGrid = styled(Grid)`
    > form {
      > div.MuiTextField-root { margin-top: 0; }
    }
    ${props => {
        if (props.hidden) {
            return `display: none;`;
        }
}}

`;
export const CancelButton = styled(Button)`
    
    margin-top: 11px;
    margin-left: 5px;
    background-color: #c64d00;
    color: white;
`;

export const AddButton = styled(Button)`
    
    margin-top: 12px;
    margin-left: 5px;
  
`;

export const LabelContainer = styled(Container).attrs({ component: 'main' })`
    display: flex;
    flex-direction: row,
    align-items: center;
    margin-bottom: 50px;
    justify-content: space-between;
`;
export const CheckLabelContainer = styled(Container).attrs({ component: 'main' })`
    display: flex;
    flex-direction: row,
    align-items: flex-start;
    margin-bottom: 50px;
`;


export const StyledDiv1 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-left: 4px;
    margin-right: 4px;
`;
export const StyledDiv3 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-left: 4px;
    margin-right: 4px;
`;
export const StyledDiv2 = styled.div`
    display: flex;
    flex-direction: column;
    
`;

export const StyledParentCard = styled(Card)`
    width: 500px;
    height: 300px;
`;
export const StyledChildCard = styled(Card)`
    width: 375px;
`;

export const ControlLabel = styled(FCL)`
    
    > span.MuiFormControlLabel-label {
      font-size: 12px;
      color: #b1b0b0;
    }
`;

export const TextField = styled(TF)`
    margin-top: 3px !important;
    margin-right: ${props => props.theme.spacing(2)}px;
`;
export const FormControlLabel = styled(FCL)`
    > span.MuiFormControlLabel-label {
      font-size: .70rem;
    }
    float: right;
`;

export const PaddedForm = styled.form`


    padding-bottom:15px;
`;

//font: 'roboto',
    //color: '#b1b0b0',
        //fontSize: 12,
           // fontWeight: 'bolder'