import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import ReportViewer from '../../common/components/ReportViewer';
import { ReportGrid } from '../../common/styles/styles';
import { getEmployerId, getEmployers } from '../../common/dtos/auth';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
import { rootSelector } from '../../common/selectors/selectors';
import { TelerikReportDto } from '../reports/dtos';
import { getTelerikReportsActions } from '../reports/actions';

const Dashboard = () => {
  const dispatch = useDispatch();

  const systemReports = rootSelector((state) => state.reports.list);

  const [report, setReport] = React.useState(
    undefined as TelerikReportDto | undefined
  );
  const [reports, setReports] = React.useState([] as TelerikReportDto[]);
  const [reportId, setReportId] = React.useState(0);

  React.useEffect(() => {
    setReports(systemReports);
    if (systemReports.length > 0) setReportId(systemReports.filter(f => f.name === "Client Dashboard Reports")[0]?.reportId ?? undefined);
  }, [systemReports]);

  React.useEffect(() => {
    dispatch({ type: getType(getTelerikReportsActions.request) });
  }, [dispatch]);

  React.useEffect(() => {
    setReport(undefined);
    setTimeout(() => {
      if (reportId !== 0) {
        let selectedReport = reports.find((r) => r.reportId === reportId);
        if (selectedReport !== undefined) {
          selectedReport.parameters = {
            EmployerIdParameter: getEmployers().join(','),
          };
        }

        setReport(selectedReport);
      }
    }, 500);
  }, [reportId]);

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" display="inline">
            Dashboard
          </Typography>
        </Grid>
        <ReportGrid item xs={12}>
          {report !== undefined ? <ReportViewer report={report} /> : null}
        </ReportGrid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
