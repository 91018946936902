import { Grid, GridSize, InputLabelProps, InputProps } from '@material-ui/core';
import { FormikErrors, FormikHandlers, FormikHelpers, getIn } from 'formik';
import React from 'react';
import { ISi } from '../../../../common/dtos/SiDto';
import { PaddedBottomTextField, TextInputSpan } from '../../../../common/styles/styles';

interface Props {
  label: string;
  name: string;
  value: string | undefined;
  errors: FormikErrors<ISi>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  isReadOnly: boolean;
  size?: GridSize;
  max?: number | undefined;
  inputlabelprops?: Partial<InputLabelProps>;
  inputprops?: Partial<InputProps>;
  setFieldValue: FormikHelpers<ISi>["setFieldValue"];
}

const SiTextField: React.FC<Props> = (
  {
    label,
    name,
    value,
    errors,
    handleChange,
    handleBlur,
    isReadOnly,
    max,
    size = 12,
    inputlabelprops,
    inputprops,
    setFieldValue
  }) => {

  return (
    <Grid item xs={12} sm={12} md={12} lg={size}>
      <PaddedBottomTextField
        size="small"
        margin="none"
        disabled={isReadOnly}
        value={value || ''}
        label={label}
        name={name}
        onChange={e => { e.target.value !== undefined && e.target.value.length === 0 ? setFieldValue(name, undefined) : handleChange(e); }}
        onBlur={handleBlur}
        error={Boolean(getIn(errors, name))}
        helperText={getIn(errors, name) && getIn(errors, name)}
        data-cy={`sidesResponse_${name}`}
        fullWidth
        InputLabelProps={inputlabelprops}
        InputProps={inputprops}
        inputProps={{ maxLength: max }}
      />
      {(max !== undefined && value !== undefined && value.length !== 0) && <TextInputSpan>({value === undefined ? undefined : value.length}/{max})</TextInputSpan>}
    </Grid>
  );

}

export default SiTextField;