import { MenuItem } from "@material-ui/core";
import React from "react";
import { CompanyHsDto } from "../features/company/dtos";


/**
 * Determines report group options.
 * @param companies: CompanyHsDto[]
 * @param healthSystems: string[]
 * @param index: number
 */
export const getHealthGroups = (
  companies: CompanyHsDto[],
  healthSystems: string[],
  index: number
): string[] => {
  return healthSystems[index] === 'All-MHA' ?
    Array.from(new Set(companies.map(c => c.group))) :
    Array.from(new Set(companies.filter(c => c.healthSystem === healthSystems[index]).map(c => c.group)));
};

export const getHealthGroupsFromMultipleSystems = (
  companies: CompanyHsDto[],
  healthSystems: string[]
): string[] => Array.from(new Set(companies.filter(c => healthSystems.includes(c.healthSystem)).map(c => c.group)));

/**
 * Determines report group options.
 * @param companies: CompanyHsDto[]
 * @param healthSystems: string[]
 * @param index: number
 */
export const getReportGroups = (
  companies: CompanyHsDto[],
  healthSystems: string[],
  index: number
) => {
  let list: string[] = getHealthGroups(companies, healthSystems, index);
  return list.sort((a, b) => a.localeCompare(b)).map((option, i) => (<MenuItem key={option + i} value={option}>{option}</MenuItem>));
};

