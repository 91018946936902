import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { getTelerikReportsActions } from './actions';
import { TelerikReportDto } from './dtos';

const reportsReducer = combineReducers({
  list: createReducer([] as TelerikReportDto[])
    .handleAction(
      [
        getTelerikReportsActions.success,
      ], (state, action) => action.payload),  

});


export default reportsReducer;