import { createAsyncAction, createAction } from 'typesafe-actions';
import { LoginDto, TokenDto } from './login.dtos';


export const loginActions = createAsyncAction(
  'LOGIN_USER_REQUEST',
  'LOGIN_USER_SUCCESS',
  'LOGIN_USER_ERROR',
)<LoginDto, TokenDto, string>();

export const logoutActions = createAsyncAction(
  'LOGOUT_USER_REQUEST',
  'LOGOUT_USER_SUCCESS',
  'LOGOUT_USER_ERROR',
)<undefined, boolean, string>();

export const session = createAction('GET_SESSION')<boolean>();