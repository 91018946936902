import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { RoleDto } from '../../../common/dtos/role';
import { SystemRoleSettingDto } from './system.dtos';
import { createSystemUserRoleSettingApi, getSystemRoleSettingsApi, updateSystemRoleSettingsApi } from './system.api';
import { createUserRoleSystemSettingActions, getRoleSystemSettingsActions, updateRoleSystemSettingsActions } from './system.actions';
import { isAuthorized, isReadOnly } from '../../../common/dtos/auth';
import { FeatureTypeEnum } from '../../../common/enums/featureTypeEnum';


function* handleSystemRolesGet() {
  if (isAuthorized() || isReadOnly(FeatureTypeEnum.Settings)) {
    const systemRoles: SystemRoleSettingDto[] = yield call(getSystemRoleSettingsApi);
    yield put({ type: getType(getRoleSystemSettingsActions.success), payload: systemRoles });
  }
}

function* handleSystemRolesUpdate(request: ReturnType<typeof updateRoleSystemSettingsActions.request>) {
  const updatedSystemRoles: SystemRoleSettingDto[] = yield call(updateSystemRoleSettingsApi, request.payload);
  yield put({ type: getType(updateRoleSystemSettingsActions.success), payload: updatedSystemRoles });
}

function* handleSystemUserRolesCreate(request: ReturnType<typeof createUserRoleSystemSettingActions.request>) {
  const createdRole: RoleDto = yield call(createSystemUserRoleSettingApi, request.payload);
  yield put({ type: getType(createUserRoleSystemSettingActions.success), payload: createdRole });
}



 function* watchSystemRoleSettingsGetRequest() {
  try { yield takeLatest(getType(getRoleSystemSettingsActions.request), handleSystemRolesGet); }
  catch (err) { }
}

 function* watchSystemRoleSettingsUpdateRequest() {
  try { yield takeLatest(getType(updateRoleSystemSettingsActions.request), handleSystemRolesUpdate); }
  catch (err) { }
}

 function* watchSystemUserRoleSettingsCreateRequest() {
  try { yield takeLatest(getType(createUserRoleSystemSettingActions.request), handleSystemUserRolesCreate); }
  catch (err) { }
}



export default [
  watchSystemRoleSettingsGetRequest,
  watchSystemRoleSettingsUpdateRequest,
  watchSystemUserRoleSettingsCreateRequest,
];