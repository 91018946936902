import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Grid, Box, Typography, Checkbox, TextField,
  InputAdornment, Card, CardContent, Link,
} from '@material-ui/core';
import { EmailTemplateDto } from '../../../../common/dtos/emailTemplateDto';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import SearchIcon from '@material-ui/icons/Search';
import { isReadOnly } from '../../../../common/dtos/auth';
import AddEditEmailTemplateDialog from '../dialogs/addEditEmailTemplateDialog';
import { rootSelector } from '../../../../common/selectors/selectors';
import { BMGrid, CardCheckbox, Checkbox1, LargeIconButton, ListCardContent, TableCard } from '../../../../common/styles/styles';
import { FeatureTypeEnum } from '../../../../common/enums/featureTypeEnum';


const AppealsTemplatesList: React.FC = () => {
  const templates = rootSelector(state => state.emailTemplates.templateList);

  const [showInactive, setShowInactive] = React.useState(false);
  const [templatesList, setTemplatesList] = React.useState([] as EmailTemplateDto[]);
  const [templateSearchOptions, setTemplateSearchOptions] = React.useState([] as string[]);
  const [templateSearchString, setTemplateSearchString] = React.useState('');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [templateId, setTemplateId] = React.useState('' as string);
  const [templateType, setTemplateType] = React.useState(0);
  const [readOnlyStatus, setReadOnlyStatus] = React.useState(false);


  React.useEffect(() => {
    let s = isReadOnly(FeatureTypeEnum.Settings);
    setReadOnlyStatus(s);

  }, [readOnlyStatus]);

  React.useEffect(() => {
    let searchOptions: string[] | undefined = [];
    templatesList.forEach((item: EmailTemplateDto) => {
      if (item!.emailTemplateUserTypeId === 1) {
        if (!searchOptions!.includes(item.label!))
          searchOptions!.push(item.label!);
        if (!searchOptions!.includes(item.state!) && item!.state !== '0')
          searchOptions!.push(item.state!);
      }
    });
    setTemplateSearchOptions(searchOptions.sort((a, b) => a.localeCompare(b)));
  }, [templatesList]);

  React.useEffect(() => {
    setTemplatesList(templates as EmailTemplateDto[]);
  }, [templates]);


  const handleDialogLink = (templateId: string, type?: number) => {
    if (type !== undefined) {
      setTemplateType(type as number);
    }
    setTemplateId(templateId);
    setDialogOpen(!dialogOpen);
  }

  const handleShowInactiveToggle = () => {
    setShowInactive(!showInactive);
  };

  const handleDialogClose = () => {
    setDialogOpen(!dialogOpen);
  }


  return <>
    {dialogOpen ?
      <AddEditEmailTemplateDialog
        templateId={templateId}
        templateTypeId={templateType}
        open={dialogOpen}
        onCancel={handleDialogClose}
        isReadOnly={readOnlyStatus}
      /> :
      null
    }
    <Box p={2}>
      <Grid container data-cy="emailTemplates_appealsTemplates_list_container" item xs={9}>
        <BMGrid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Formik initialValues={{ search: '' }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={Yup.object({
                search: Yup.string().max(200, 'Must be 200 characters or less')
              })}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => { setSubmitting(false); }, 700);
                setTemplateSearchString(values.search);

              }}
              enableReinitialize={true}
            >
              {(props) => {
                const { values, touched, errors, handleChange, handleSubmit, setFieldValue } = props;
                return (
                  <form>
                    <Autocomplete
                      id="autoSearch"
                      options={templateSearchOptions}
                      autoComplete
                      includeInputInList
                      freeSolo
                      inputValue={values.search}
                      onInputChange={(_e: any, v: string | null) => { setFieldValue('search', v ?? ''); handleSubmit(); }}
                      renderInput={(params) => (<TextField {...params} id="search" size="small" margin="none" value={values.search} label="Search" name="search"
                        onChange={(e) => { handleChange(e); handleSubmit(); }} error={Boolean(errors.search)}
                        helperText={(errors.search && touched.search) && errors.search} data-cy="emailTemplates_appealsTemplates_list_search"
                        InputProps={{ ...params.InputProps, startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>), }}
                      />)} />
                  </form>);
              }}
            </Formik>
          </Grid>
          <Grid item xs={11} sm={7}>
            <Checkbox1 onChange={handleShowInactiveToggle} value={!showInactive} checked={showInactive}
              control={<Checkbox size="small" data-cy="emailTemplates_appealsTemplates_showInactiveCheckbox" />}
              label={<Typography variant="subtitle2">Show Inactive</Typography>} />
          </Grid>
          <Grid item xs={1} hidden={readOnlyStatus}>
            <LargeIconButton title="Add Appeals Email Template" color="secondary" onClick={() => handleDialogLink('new', 1)} data-cy="emailTemplates_appealsTemplates_addTemplate">
              <AddBoxRoundedIcon />
            </LargeIconButton>
          </Grid>

        </BMGrid>

        <Grid container spacing={2}>
          {templatesList && templatesList
            .filter((item: EmailTemplateDto) => item.emailTemplateUserTypeId === 1)
            .filter((item: EmailTemplateDto) => item.isActive || showInactive)
            .filter((item: EmailTemplateDto) => item.label?.toLowerCase().includes(templateSearchString.toLowerCase()))
            .sort((a, b) => a.label!.localeCompare(b.label!)).map((o, index) => (
              <Grid item xs={12} key={'AppealsEmailTemplateItem-' + index}>
                <TableCard key={'AppealsEmailTemplateCard-' + index} raised>
                  <ListCardContent>
                    <Grid container>
                      <div hidden data-cy={`"emailTemplates_appealsTemplates_list_card_id_${o.label}`}>{o.id}</div>

                      <Grid item xs={12}>
                        <Link component="button" onClick={() => handleDialogLink(o.id ?? 'new')} data-cy={`"emailTemplates_appealsTemplates_list_card_link_${o.label}`} variant="subtitle1" color="secondary">{o.label}</Link>
                      </Grid>

                      <Grid item xs={6} sm={9}>
                        <Typography variant="body2" color="textPrimary">State</Typography>
                        <Typography variant="body2" color="secondary">{o.state || "None on File"}</Typography>
                      </Grid>

                      {/* Active checkbox */}
                      <Grid item xs={6} sm={3}>
                        <CardCheckbox
                          value={o.isActive}
                          checked={o.isActive}
                          control={<Checkbox name="isActive" id="isActive" size="small" />}
                          label={<Typography variant="subtitle2">Active</Typography>}
                          labelPlacement="top"
                        />
                      </Grid>
                    </Grid>
                  </ListCardContent>
                </TableCard>
              </Grid>
            ))}
          <Grid item xs={12} hidden={templatesList
            .filter((item: EmailTemplateDto) => item.emailTemplateUserTypeId === 1)
            .filter((item: EmailTemplateDto) => item.isActive || showInactive)
            .filter((item: EmailTemplateDto) => item.label?.toLowerCase().includes(templateSearchString.toLowerCase())).length !== 0}>
            <Card>
              <CardContent>
                <Grid container justifyContent="center" alignItems="center">
                  <Typography variant="subtitle1" color="secondary">No Records Found</Typography>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

        </Grid>

      </Grid>
    </Box>
  </>

}

export default AppealsTemplatesList;