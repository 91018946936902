import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import {
  getDocumentsActions, reassignDocumentsActions, getDocumentPageNumber, getDocumentPageTotal, deleteDocumentsActions,
  documentAssociateActions, documentDeassociateActions, getDocumentActions, getUisSiActions, updateUisSiActions, siteBaseUrlAction,
  getUisMcActions, updateUisMcActions
} from './actions';

import { DocumentsDto, ReassignDto, AssociateDocumentDto, DocumentsInit, SiInit, McInit } from './document.dtos';

const documentsReducer = combineReducers({
  baseUrl: createReducer('')
    .handleAction([siteBaseUrlAction,], (state, action) => action.payload),

  document: createReducer(DocumentsInit)
    .handleAction([getDocumentActions.success,], (state, action) => action.payload),

  documentsList: createReducer([] as DocumentsDto[])
    .handleAction([ getDocumentsActions.success, ], (state, action) => action.payload),

  reassignDocument: createReducer({ mhaContactId: '', documents: [] } as ReassignDto)
    .handleAction([reassignDocumentsActions.request,], (state, action) => action.payload),

  deleteDocument: createReducer('')
    .handleAction([ deleteDocumentsActions.request, ], (state, action) => action.payload),

  associateDocument: createReducer({ id: '', claimId: '' } as AssociateDocumentDto)
    .handleAction([ documentAssociateActions.request, ], (state, action) => action.payload),

  deassociateDocument: createReducer({ id: '', claimId: '' } as AssociateDocumentDto)
    .handleAction([documentDeassociateActions.request,], (state, action) => action.payload),

  totalDocuments: createReducer(0 as number)
    .handleAction([ getDocumentPageTotal.success, ], (state, action) => action.payload),

  documentPageNumber: createReducer(0 as number)
    .handleAction([getDocumentPageNumber.success,], (state, action) => action.payload),

  uisSi: createReducer(SiInit)
    .handleAction([getUisSiActions.success, updateUisSiActions.success], (state, action) => action.payload),

  uisMc: createReducer(McInit)
    .handleAction([getUisMcActions.success, updateUisMcActions.success ], (state, action) => action.payload),
});


export default documentsReducer;