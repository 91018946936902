
export const documentTypeOptionsList = [
  { name: '', id: 0 },
  { name: 'Monetary Notice', id: 1 },
  { name: 'State Fact-Finding Form', id: 2 },
  { name: 'SIDES', id: 3 },
  { name: 'Transmittal to State', id: 4 },
  { name: 'Information from Client', id: 5 },
  { name: 'Wage Data', id: 6 },
  { name: 'Decision', id: 7 },
  { name: 'Notice of Fact-Finding Interview', id: 8 },
  { name: 'Hearing Notice', id: 9 },
  { name: 'Hearing Decision', id: 10 },
  { name: 'Board Decision', id: 11 },
  { name: 'Circuit Court Decision', id: 12 },
  { name: 'Attorney Notes from Hearing', id: 13 },
  { name: 'Appellate Documents', id: 14 },
  { name: 'Claim General Documents', id: 15 },
  { name: 'Client Communications', id: 16 },
  { name: 'Charge Protest', id: 17 },
  { name: 'General', id: 18 },
  { name: 'NoticeOfAppeal', id: 19 },
];

export const documentStatusOptionsList = [
  { name: '', id: 0 },
  { name: 'New Mail', id: 1 },
  { name: 'Waiting on Client', id: 2 },
  { name: 'Needs Review', id: 3 },
  { name: 'Ready to Protest', id: 4 },
  { name: 'No Protest Needed', id: 5 },
  { name: 'Decision', id: 6 },
  { name: 'Confirmation Pending', id: 7 },
  { name: 'Need Check', id: 8 },
  { name: 'New Decision', id: 9 },
  { name: 'Hearing Scheduled', id: 10 },
  { name: 'Waiting on State Action', id: 11 },
  { name: 'Waiting on Assigned Representative', id: 12 },
  { name: 'To Be Assigned', id: 13 },
  { name: 'Appeal Pending', id: 14 },
];


