import React from 'react';
import MaskedInput from 'react-text-mask';

interface SsnTextFieldProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  rows: number,
}

const SsnTextField: React.FC<SsnTextFieldProps> = (props: SsnTextFieldProps) => {
  const { inputRef, rows, ...other } = props;

  //const replaceAt = (value: string, index: number, replacement: string) => {
  //  return value.substr(0, index) + replacement + value.substr(index + replacement.length);
  //}

  const handleMaskedValue = (v: string): string => {

    if (rows === 3) {
      if (v.replace('-', '').length > 5) {
        return '***-**-' + v.replace('-', '').slice(5);
      }
      else {
        return '***-**-';
      }
    }
   
    return v;
  }

  const handleFormat = (value: string): any[] => {
    let format: any[] = [];
    let length = value === undefined ? 0 : value.length;

    switch (length) {
      //case 0: { format = []; break; }
      //case 1: { format = [/\d/]; break; }
      //case 2: { format = [/\d/, /\d/]; break; }
      //case 3: { format = [/\d/, /\d/, /\d/, '-']; break; }
      //case 4: 
      //case 5: { format = [/\d/, /\d/, /\d/, '-', /\d/]; break; }
      //case 6: { format = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-']; break; }
      //case 7: 
      //case 8: { format = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/]; break; }
      //case 9: { format = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]; break; }
      //case 10: { format = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/]; break; }
      default: { format = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]; break; }
    }
    return format;
  }

  return (
    <>
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        //mask={v => ha}
        mask={v => handleFormat(v)}
        placeholderChar={'_'}
        showMask
        pipe={v => handleMaskedValue(v)}
      />
    </>
  );

}

export default SsnTextField;