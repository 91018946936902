import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Formik,
  getIn,
  FieldArray,
  yupToFormErrors,
  validateYupSchema,
} from 'formik';
import { getType } from 'typesafe-actions';
import { Prompt, useHistory } from 'react-router';
import Dropzone from 'react-dropzone';
import moment from 'moment';
// material ui
import {
  Grid,
  Card,
  Typography,
  Checkbox,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { rootSelector } from '../../../common/selectors/selectors';
import {
  updateContactActions,
  createContactActions,
} from '../contacts.actions';
import {
  getCountriesActions,
  getGoverningDistrictsActions,
} from '../../account/profile/profile.actions';
import { getTimeZonesActions } from '../../../common/actions/timeZones.actions';
import { CompanyDto } from '../../company/dtos';
import ContactInformation from '../formComponents/information';
import ContactEmail from '../formComponents/email';
import ContactPhone from '../formComponents/phone';
import ContactAddress from '../formComponents/address';
import { ContactDto, ContactemployersInit } from '../contacts.dtos';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import { contactInit, contactValidationSchema } from '../contacts.dtos';
import Label from '../../../common/components/label';
import BottomActionFormBar from '../../../common/components/bottomActionFormBar';
import { noteInit } from '../../../common/dtos/note';
import { addressInit } from '../../../common/dtos/address';
import { emailInit } from '../../../common/dtos/email';
import { phoneInit } from '../../../common/dtos/phone';
import { TimeZonesDto } from '../../../common/dtos/timeZones';
import { getCompaniesActions } from '../../company/actions';
import { isReadOnly } from '../../../common/dtos/auth';
import { functionOptions } from '../../../common/constants/options';
import {
  CardCheckbox,
  CardContent1,
  CardImage,
  EmailGrid,
  Icon,
  IndentGrid,
  PhoneGrid,
  UploadBox,
} from '../../../common/styles/styles';
import { Logo, T3Select } from '../contacts.styles';
import { FeatureTypeEnum } from '../../../common/enums/featureTypeEnum';

const ContactDetails: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const baseUrl = process.env.REACT_APP_API_URL ?? '';
  const baseLogoUrl = baseUrl + 'download/profile-image/';

  const systemContact = rootSelector((state) => state.contacts.contact);

  const systemCompaniesList = rootSelector(
    (state) => state.companies.companiesList
  );
  const countries = rootSelector((state) => state.countries.countryList);
  const governingDistricts = rootSelector(
    (state) => state.governingDistricts.governingDistrictList
  );
  const systemTimeZones = rootSelector(
    (state) => state.timeZones.timeZonesList
  );

  const [dto, setDto] = React.useState<ContactDto>(contactInit);
  const [shouldBlockNavigation, setShouldBlockNavigation] =
    React.useState(false);
  const [savingInput, setSavingInput] = React.useState(false);
  const [countryOptions, setCountryOptions] = React.useState([] as string[]);
  const [stateOptions, setStateOptions] = React.useState([] as string[]);
  const [timeZones, setTimeZones] = React.useState([] as TimeZonesDto[]);
  const [newFileName, setNewFileName] = React.useState('' as string);
  const [logoUrl, setLogoUrl] = React.useState(baseLogoUrl);
  const [fileUpload, setFileUpload] = React.useState(undefined as any);
  const [employerListOptions, setEmployerListOptions] = React.useState(
    [] as string[]
  );

  const [readOnlyStatus, setReadOnlyStatus] = React.useState(false);

  // ReadOnly allowed to edit contacts 11.09.2021
  //React.useEffect(() => {
  //  let s = isReadOnly(FeatureTypeEnum.Contacts);
  //  setReadOnlyStatus(s);
  //}, [readOnlyStatus]);

  React.useEffect(() => {
    dispatch({ type: getType(getCountriesActions.request) });
    dispatch({ type: getType(getGoverningDistrictsActions.request) });
    dispatch({ type: getType(getTimeZonesActions.request) });
    dispatch({ type: getType(getCompaniesActions.request) });
  }, [dispatch]);

  React.useEffect(() => {
    let searchOptions: string[] = [];
    systemCompaniesList.forEach((item: CompanyDto) => {
      if (item.name !== undefined && item.name.length !== 0) {
        searchOptions.push(item.name.toUpperCase());
      }
    });
    setEmployerListOptions(searchOptions.sort((a, b) => a.localeCompare(b)));
  }, [systemCompaniesList]);

  React.useEffect(() => {
    let newDto = systemContact;

    if (newDto?.contactEmployers?.length === 0) {
      newDto.contactEmployers?.push(ContactemployersInit);
    }
    if (newDto?.addresses?.length === 0) {
      newDto.addresses?.push(addressInit);
    }
    if (newDto?.emails?.length === 0) {
      newDto.emails?.push(emailInit);
    }
    if (newDto?.phones?.length === 0) {
      newDto.phones?.push(phoneInit);
    }
    if (newDto?.notes?.length === 0) {
      newDto.notes?.push(noteInit);
    }
    setDto(systemContact);
  }, [systemContact]);

  React.useEffect(() => {
    setTimeZones(systemTimeZones);
  }, [systemTimeZones]);

  React.useEffect(() => {
    let options = countries.map((c) => c.name);
    options.push('');
    setCountryOptions(options);
  }, [countries]);

  React.useEffect(() => {
    let options = governingDistricts
      .filter(
        (f) =>
          f.countryId ===
          countries.find((c) =>
            dto.addresses?.flatMap((a) => a.country).includes(c.name)
          )?.countryId
      )
      .map((g) => g.name);
    options.push('');
    setStateOptions(options);
  }, [governingDistricts, countries, dto.addresses]);

  React.useEffect(() => {
    if (
      dto.profileImageName !== undefined &&
      dto.profileImageName.length !== 0
    ) {
      setLogoUrl(baseLogoUrl + dto.profileImageName);
    }
  }, [dto.profileImageName, baseLogoUrl]);

  const handleDrop = (acceptedFiles: File[]) => {
    setFileUpload(acceptedFiles[0]);

    setNewFileName(acceptedFiles[0].name);

    var reader: FileReader = new FileReader();
    reader.onloadend = (e) => {
      setLogoUrl(reader.result!.toString());
    };
    reader.readAsDataURL(acceptedFiles[0]);
  };

  const handleCountryChange = (name: string) => {
    setStateOptions(
      governingDistricts
        .filter(
          (f) =>
            f.countryId === countries.find((c) => c.name === name)?.countryId
        )
        .map((g) => g.name)
    );
  };

  const handleCancel = () => {
    history.push('/contacts');
  };

  return (
    <>
      <Formik
        initialValues={dto}
        validateOnChange={false}
        validateOnBlur={false}
        //validationSchema={contactValidationSchema}
        validate={(value) => {
          try {
            validateYupSchema(value, contactValidationSchema, true, value);
          } catch (err) {
            return yupToFormErrors(err); //for rendering validation errors
          }
          return {};
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            setSavingInput(false);
          }, 700);
          setSavingInput(true);

          let dto: ContactDto = JSON.parse(JSON.stringify(values));
          dto.emails = dto.emails.filter((e) => e.emailAddress.length !== 0);
          dto.phones = dto.phones.filter((p) => p.phoneNumber.length !== 0);
          dto.notes = dto.notes.filter((n) => n.entry.length !== 0);
          dto.addresses = dto.addresses.filter(
            (a) =>
              a.address1 !== undefined &&
              a.address1!.length !== 0 &&
              a.city !== undefined &&
              a.city!.length !== 0
          );

          if (dto.userName === undefined || dto.userName.length === 0)
            delete dto.userName;
          if (fileUpload !== undefined) dto.fileUpload = fileUpload;

          dto.contactEmployers = (dto.contactEmployers ?? []).filter(
            (f) => !!f.name
          );

          dispatch({
            type: dto.contactId
              ? getType(updateContactActions.request)
              : getType(createContactActions.request),
            payload: dto,
          });
          setShouldBlockNavigation(false);
        }}
        enableReinitialize={true}
      >
        {(props) => {
          const {
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            handleBlur,
          } = props;
          return (
            <form onSubmit={handleSubmit} data-cy={'contact_details_form'}>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12}>
                  <Card>
                    <CardContent1>
                      <Grid container>
                        <Grid container item xs={12} alignItems='center'>
                          <Grid item xs={10}>
                            <Label>Contact Information</Label>
                          </Grid>
                          <Grid item xs={2}>
                            <CardCheckbox
                              id='isActive'
                              name='isActive'
                              onChange={(e) => {
                                handleChange(e);
                                setShouldBlockNavigation(true);
                              }}
                              onBlur={handleBlur}
                              value={values.isActive}
                              checked={values.isActive}
                              control={
                                <Checkbox
                                  disabled={readOnlyStatus}
                                  value={values.isActive}
                                  style={{ paddingRight: 6 }}
                                  size='small'
                                  data-cy='contact_detailsViewActiveOnly'
                                />
                              }
                              label={
                                <Typography variant='subtitle2'>
                                  Active
                                </Typography>
                              }
                            />
                          </Grid>
                        </Grid>

                        <br />
                        <br />
                        <ContactInformation
                          isReadOnly={readOnlyStatus}
                          companies={systemCompaniesList}
                          setFieldValue={setFieldValue}
                          values={values}
                          employerOptions={employerListOptions}
                          setShouldBlockNavigation={setShouldBlockNavigation}
                          errors={errors}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          functionOptions={functionOptions}
                        />

                        <FieldArray
                          name='emails'
                          render={(arrayHelpers) => (
                            <>
                              <Grid container item xs={12} alignItems='center'>
                                <Grid item xs={11}>
                                  <Label>Email Addresses</Label>
                                </Grid>
                                <Grid item xs={1} hidden={readOnlyStatus}>
                                  <Icon
                                    title='Add To Email Address List'
                                    color='secondary'
                                    onClick={
                                      readOnlyStatus === true
                                        ? () => null
                                        : () => {
                                            arrayHelpers.push(emailInit);
                                          }
                                    }
                                    data-cy='contact_details_addEmailAddress'
                                  >
                                    <AddBoxRoundedIcon />
                                  </Icon>
                                </Grid>
                              </Grid>
                              <EmailGrid container item xs={12} spacing={2}>
                                {values.emails &&
                                  values.emails.map((o, index) => (
                                    <ContactEmail
                                      isReadOnly={readOnlyStatus}
                                      key={'ContactEmail-' + index}
                                      email={o}
                                      index={index}
                                      errors={errors}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      setFieldValue={setFieldValue}
                                      emails={values.emails}
                                      setShouldBlockNavigation={
                                        setShouldBlockNavigation
                                      }
                                    />
                                  ))}
                              </EmailGrid>
                            </>
                          )}
                        />

                        <FieldArray
                          name='phones'
                          render={(arrayHelpers) => (
                            <>
                              <Grid container item xs={12} alignItems='center'>
                                <Grid item xs={11}>
                                  <Label>Phone Numbers</Label>
                                </Grid>
                                <Grid item xs={1} hidden={readOnlyStatus}>
                                  <Icon
                                    title='Add To Phone Number List'
                                    color='secondary'
                                    onClick={
                                      readOnlyStatus === true
                                        ? () => null
                                        : () => {
                                            arrayHelpers.push(phoneInit);
                                          }
                                    }
                                    data-cy='contact_details_addPhoneNumber'
                                  >
                                    <AddBoxRoundedIcon />
                                  </Icon>
                                </Grid>
                              </Grid>
                              <PhoneGrid
                                container
                                item
                                xs={12}
                                spacing={2}
                                direction='row'
                                alignItems='center'
                              >
                                {values.phones &&
                                  values.phones.map((o, index) => (
                                    <ContactPhone
                                      isReadOnly={readOnlyStatus}
                                      key={'ContactPhone-' + index}
                                      phones={values.phones}
                                      setFieldValue={setFieldValue}
                                      phone={o}
                                      index={index}
                                      errors={errors}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      setShouldBlockNavigation={
                                        setShouldBlockNavigation
                                      }
                                    />
                                  ))}
                              </PhoneGrid>
                            </>
                          )}
                        />

                        <FieldArray
                          name='addresses'
                          render={(arrayHelpers) => (
                            <>
                              <Grid container item xs={12} alignItems='center'>
                                <Grid item xs={11}>
                                  <Label>Mailing Addresses</Label>
                                </Grid>
                                <Grid item xs={1} hidden={readOnlyStatus}>
                                  <Icon
                                    title='Add To Employer Mailing Address'
                                    color='secondary'
                                    onClick={
                                      readOnlyStatus === true
                                        ? () => null
                                        : () => {
                                            arrayHelpers.push(addressInit);
                                          }
                                    }
                                    data-cy='contact_details_addMailingAddress'
                                  >
                                    <AddBoxRoundedIcon />
                                  </Icon>
                                </Grid>
                              </Grid>
                              <IndentGrid container item xs={12} spacing={2}>
                                {values.addresses !== undefined &&
                                  values.addresses.map((o, index) => (
                                    <ContactAddress
                                      isReadOnly={readOnlyStatus}
                                      key={'ContactAddress-' + index}
                                      address={o}
                                      index={index}
                                      addresses={values.addresses}
                                      errors={errors}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      stateOptions={stateOptions}
                                      countryOptions={countryOptions}
                                      setFieldValue={setFieldValue}
                                      setShouldBlockNavigation={
                                        setShouldBlockNavigation
                                      }
                                      handleCountryChange={handleCountryChange}
                                    />
                                  ))}
                              </IndentGrid>
                            </>
                          )}
                        />
                      </Grid>
                    </CardContent1>
                  </Card>
                </Grid>

                <Grid item md={6} sm={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} hidden={readOnlyStatus}>
                      <Card>
                        <CardImage>
                          <Grid container>
                            <Grid item xs={3}>
                              <Label>Profile Picture</Label>
                              <Dropzone
                                aria-label='Logo Upload'
                                accept='image/*'
                                onDrop={handleDrop}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <UploadBox
                                    {...getRootProps({ className: 'dropzone' })}
                                  >
                                    <input
                                      id='fileUpload'
                                      name='fileUpload'
                                      aria-label='File input area'
                                      {...getInputProps()}
                                    />
                                    <Typography variant='h6' display='block'>
                                      Choose File
                                    </Typography>
                                    <small>{newFileName}</small>
                                  </UploadBox>
                                )}
                              </Dropzone>
                            </Grid>
                            <Logo
                              hidden={
                                (values.profileImageName ?? '').length === 0 ||
                                (logoUrl ?? '').length === 0
                              }
                              src={
                                (values.profileImageName ?? '').length !== 0 &&
                                (logoUrl ?? '').length !== 0
                                  ? logoUrl
                                  : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
                              }
                              alt=''
                              width='164'
                            />
                            <Grid item xs={9}></Grid>
                          </Grid>
                        </CardImage>
                      </Card>
                    </Grid>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent1>
                          <Grid container>
                            <Label>Notes</Label>
                            <IndentGrid container item xs={12} spacing={2}>
                              <Grid item xs={12}>
                                {values.notes &&
                                  values.notes.map((o, index) => (
                                    <React.Fragment
                                      key={'notesFragment' + index}
                                    >
                                      <TextField
                                        id={`notes[${index}].entry`}
                                        disabled={readOnlyStatus}
                                        size='small'
                                        margin='none'
                                        value={o.entry}
                                        name={`notes[${index}].entry`}
                                        onChange={(e) => {
                                          handleChange(e);
                                          setShouldBlockNavigation(true);
                                        }}
                                        onBlur={handleBlur}
                                        error={Boolean(
                                          getIn(errors, `notes[${index}].entry`)
                                        )}
                                        helperText={
                                          getIn(
                                            errors,
                                            `notes[${index}].entry`
                                          ) &&
                                          getIn(errors, `notes[${index}].entry`)
                                        }
                                        data-cy={`contact_notes_${index}_notesInput`}
                                        multiline
                                        minRows={4}
                                        fullWidth
                                      />
                                    </React.Fragment>
                                  ))}
                              </Grid>
                            </IndentGrid>
                          </Grid>

                          <br />
                          <br />

                          <Grid container>
                            <Label>Time Zone</Label>
                            <IndentGrid container item xs={12} spacing={2}>
                              <Grid item xs={12}>
                                <T3Select
                                  fullWidth
                                  name='timeZoneId'
                                  value={values.timeZoneId || 0}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setShouldBlockNavigation(true);
                                  }}
                                  onBlur={handleBlur}
                                  displayEmpty
                                  disabled={readOnlyStatus}
                                  MenuProps={{ disableScrollLock: true }}
                                  data-cy='contact_details_timeZoneDropDown'
                                >
                                  <MenuItem value={0} disabled>
                                    Please select an item
                                  </MenuItem>
                                  {timeZones.map((timeZone, index) => {
                                    return (
                                      <MenuItem
                                        key={'item-' + index}
                                        value={timeZone.timeZoneId}
                                      >
                                        {timeZone.displayName}
                                      </MenuItem>
                                    );
                                  })}
                                </T3Select>
                              </Grid>
                            </IndentGrid>
                          </Grid>
                          <br />
                          <br />

                          <IndentGrid container item xs={12} spacing={2}>
                            <Grid item xs={12}>
                              {dto.createdBy && (
                                <Typography variant='subtitle2'>
                                  Created{' '}
                                  {moment(dto.createdDateTime).format(
                                    'MM/DD/yyyy h:mm A'
                                  )}{' '}
                                  by {dto.createdBy}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant='subtitle2'>
                                Last edit{' '}
                                {moment(dto.updatedDateTime).format(
                                  'MM/DD/yyyy h:mm A'
                                )}{' '}
                                by {dto.updatedBy}
                              </Typography>
                            </Grid>
                          </IndentGrid>
                        </CardContent1>
                      </Card>

                      <br />
                      <br />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <br />
              <br />

              <BottomActionFormBar
                cancelClickHandler={handleCancel}
                submitting={savingInput}
                featureType={FeatureTypeEnum.Contacts}
              />
            </form>
          );
        }}
      </Formik>
      <Prompt
        when={shouldBlockNavigation}
        message='You have unsaved edits, are you sure you want to leave?'
      />
    </>
  );
};
export default ContactDetails;
