


import { ISelectOption } from "../dtos/SelectOptionDto";
enum NotWorkingCodes {
        Item1 = 0,
        Item2 = 1,
        Item3 = 2,
        Item4 = 3,
         
}

export class NotWorkingCodes_class {

public static enum = NotWorkingCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['Item1']: 0,
    ['Item2']: 1,
    ['Item3']: 2,
    ['Item4']: 3,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "1- Assignment Completed, Claimant called agency and no work available",
    [1]: "2- Assignment Completed, Claimant failed to contact the agency",
    [2]: "3- Assignment completed, contact established, claimant declined new assignment",
    [3]: "4- Assignment Not Completed",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "1- Assignment Completed, Claimant called agency and no work available"},
  {value: 1, text: "2- Assignment Completed, Claimant failed to contact the agency"},
  {value: 2, text: "3- Assignment completed, contact established, claimant declined new assignment"},
  {value: 3, text: "4- Assignment Not Completed"},
  ]
}

