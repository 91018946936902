export interface LoginDto {
  username: string;
  password: string;
  duoRes: string;
}

export const loginInit: LoginDto = { username: '', password: '', duoRes: '' };

export interface TokenDto {
  userName: string;
  userPassword: string;
  userId: string;
  employerId: string;
  contactId: string;
  authToken: string;
  validTo: string; //($date - time)
  refreshToken: string;
  fullName: string;
  roles: string[];
  employers: string[];
  expiresIn: number;
  isPasswordExpired: boolean;

  // MFA
  mfaRequired?: boolean;
  useDuoMfa?: boolean;
  qrUrl?: string;
  enrollmentUrl?: string;
  host?: string;
  sig?: string;
}

export const tokenInit: TokenDto = {
  userName: '',
  userPassword: '',
  userId: '',
  employerId: '',
  contactId: '',
  authToken: '',
  validTo: '',
  refreshToken: '',
  fullName: '',
  roles: [],
  employers: [],
  expiresIn: 0,
  isPasswordExpired: false,
};
