import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { EligibilityCodeDto, LocationCodeDto, SeparationCodeDto } from './codes.dtos';
import { createEligibilityCodesApi, getSeparationCodesApi, getEligibilityCodesApi, getLocationCodesApi, updateEligibilityCodesApi, createLocationCodesApi, updateLocationCodesApi, createSeparationCodesApi, updateSeparationCodesApi, getCodesApi } from './codes.api';
import { getLocationCodesActions,getEligibilityCodesActions, getSeparationCodesActions, createEligibilityCodesActions, createSeparationCodesActions, createLocationCodesActions, updateSeparationCodesActions, updateLocationCodesActions, updateEligibilityCodesActions, getCodesActions } from './codes.actions';


function* handleGetCodes() {

  const eligibilityStoreCodes: EligibilityCodeDto[] = yield select(state => state.codes.eligibilityCodes);
  const locationStoreCodes: LocationCodeDto[] = yield select(state => state.codes.locationCodes);
  const separationStoreCodes: SeparationCodeDto[] = yield select(state => state.codes.separationCodes);

  //If any array is undefined or 0 
  if( (eligibilityStoreCodes === undefined || locationStoreCodes === undefined || separationStoreCodes === undefined ) || (eligibilityStoreCodes.length === 0 || locationStoreCodes.length === 0  || separationStoreCodes.length == 0) ){
   const eligibilityCodes: EligibilityCodeDto[] = yield call(getEligibilityCodesApi);
   const locationCodes: LocationCodeDto[] = yield call(getLocationCodesApi);
   const separationCodes: SeparationCodeDto[] = yield call(getSeparationCodesApi);

    yield put({ type: getType(getEligibilityCodesActions.success), payload: eligibilityCodes });
    yield put({ type: getType(getLocationCodesActions.success), payload: locationCodes });
    yield put({ type: getType(getSeparationCodesActions.success), payload: separationCodes });
  }
};


function* handleGetEligibilityCodes(){
  const eligibilityStoreCodes: EligibilityCodeDto[] = yield select(state => state.codes.eligibilityCodes);
  if((eligibilityStoreCodes === undefined) || (eligibilityStoreCodes.length === 0 )){
    const eligibilityCodes: EligibilityCodeDto[] = yield call(getEligibilityCodesApi);
 
     yield put({ type: getType(getEligibilityCodesActions.success), payload: eligibilityCodes });
   }

}


function* handleGetLocationCodes(){

  const locationStoreCodes: LocationCodeDto[] = yield select(state => state.codes.locationCodes);

   //If location array is undefined or 0
   if((locationStoreCodes === undefined) || (locationStoreCodes.length === 0 )){

    const locationCodes: LocationCodeDto[] = yield call(getLocationCodesApi);

    yield put({ type: getType(getLocationCodesActions.success), payload: locationCodes });
  }


};

function* handleGetSeparationCodes(){
  const separationStoreCodes: SeparationCodeDto[] = yield select(state => state.codes.separationCodes);

  if(separationStoreCodes === undefined || separationStoreCodes.length === 0 ){

    const separationCodes: SeparationCodeDto[] = yield call(getSeparationCodesApi);
 
    yield put({ type: getType(getSeparationCodesActions.success), payload: separationCodes });
  }
}


function* handleUpdateEligibilityCodes(request: ReturnType<typeof updateEligibilityCodesActions.request>) {
  const updatedEligibilityCodes: EligibilityCodeDto[] = yield call(updateEligibilityCodesApi, request.payload);
  const storeCodes: EligibilityCodeDto[] = yield select(state => state.codes.eligibilityCodes);
  let codes = JSON.parse(JSON.stringify(storeCodes)) as EligibilityCodeDto[];

  updatedEligibilityCodes.forEach(c => {
    let updatedCodeIndex = codes.findIndex(x => x.id === c.id);
    if (updatedCodeIndex !== -1) {
      codes[updatedCodeIndex] = c;
    }
  });
  yield put({ type: getType(getEligibilityCodesActions.success), payload: codes });
}

function* handleCreateEligibilityCodes(request: ReturnType<typeof createEligibilityCodesActions.request>) {
  let createdEligibilityCodes: EligibilityCodeDto[] = yield call(createEligibilityCodesApi, request.payload);
  const storeCodes: EligibilityCodeDto[] = yield select(state => state.codes.eligibilityCodes);
  let codes = JSON.parse(JSON.stringify(storeCodes)) as EligibilityCodeDto[];

  createdEligibilityCodes.forEach(c => codes.push(c));
  yield put({ type: getType(getEligibilityCodesActions.success), payload: codes });
}

function* handleUpdateSeparationCodes(request: ReturnType<typeof updateSeparationCodesActions.request>) {
  const updatedSeparationCodes: SeparationCodeDto[] = yield call(updateSeparationCodesApi, request.payload);
  const storeCodes: SeparationCodeDto[] = yield select(state => state.codes.separationCodes);
  let codes = JSON.parse(JSON.stringify(storeCodes)) as SeparationCodeDto[];

  updatedSeparationCodes.forEach(c => {
    let updatedCodeIndex = codes.findIndex(x => x.id === c.id);
    if (updatedCodeIndex !== -1) {
      codes[updatedCodeIndex] = c;
    }
  });

  yield put({ type: getType(getSeparationCodesActions.success), payload: codes });
}

function* handleCreateSeparationCodes(request: ReturnType<typeof createSeparationCodesActions.request>) {
  let createdSeparationCodes: SeparationCodeDto[] = yield call(createSeparationCodesApi, request.payload);
  const storeCodes: SeparationCodeDto[] = yield select(state => state.codes.separationCodes);
  let codes = JSON.parse(JSON.stringify(storeCodes)) as SeparationCodeDto[];

  createdSeparationCodes.forEach(c => codes.push(c));

  yield put({ type: getType(getSeparationCodesActions.success), payload: codes });
}

function* handleUpdateLocationCodes(request: ReturnType<typeof updateLocationCodesActions.request>) {
  const updatedLocationCodes: LocationCodeDto[] = yield call(updateLocationCodesApi, request.payload);
  const storeCodes: LocationCodeDto[] = yield select(state => state.codes.locationCodes);
  let codes = JSON.parse(JSON.stringify(storeCodes)) as LocationCodeDto[];

  updatedLocationCodes.forEach(c => {
    let updatedCodeIndex = codes.findIndex(x => x.id === c.id);
    if (updatedCodeIndex !== -1) {
      codes[updatedCodeIndex] = c;
    }
  });

  yield put({ type: getType(getLocationCodesActions.success), payload: codes });
}

function* handleCreateLocationCodes(request: ReturnType<typeof createLocationCodesActions.request>) {
  let createdLocationCodes: LocationCodeDto[] = yield call(createLocationCodesApi, request.payload);
  const storeCodes: LocationCodeDto[] = yield select(state => state.codes.locationCodes);
  let codes = JSON.parse(JSON.stringify(storeCodes)) as LocationCodeDto[];

  createdLocationCodes.forEach(c => codes.push(c));

  yield put({ type: getType(getLocationCodesActions.success), payload: codes });
}





 function* watchCodesGetRequest() {
  try { yield takeLatest(getType(getCodesActions.request), handleGetCodes); }
  catch (err) { }
}

 function* watchEligibilityCodesGetRequest() {
  try { yield takeLatest(getType(getEligibilityCodesActions.request), handleGetEligibilityCodes); }
  catch (err) { }
}

 function* watchLocationCodesGetRequest() {
  try { yield takeLatest(getType(getLocationCodesActions.request), handleGetLocationCodes); }
  catch (err) { }
}

 function* watchSeparationCodesGetRequest() {
  try { yield takeLatest(getType(getSeparationCodesActions.request), handleGetSeparationCodes); }
  catch (err) { }
}

 function* watchEligibilityCodesUpdateRequest() {
  try { yield takeLatest(getType(updateEligibilityCodesActions.request), handleUpdateEligibilityCodes); }
  catch (err) { }
}

 function* watchEligibilityCodesCreateRequest() {
  try { yield takeLatest(getType(createEligibilityCodesActions.request), handleCreateEligibilityCodes); }
  catch (err) { }
}

 function* watchSeparationCodesUpdateRequest() {
  try { yield takeLatest(getType(updateSeparationCodesActions.request), handleUpdateSeparationCodes); }
  catch (err) { }
}

 function* watchSeparationCodesCreateRequest() {
  try { yield takeLatest(getType(createSeparationCodesActions.request), handleCreateSeparationCodes); }
  catch (err) { }
}

 function* watchLocationCodesUpdateRequest() {
  try { yield takeLatest(getType(updateLocationCodesActions.request), handleUpdateLocationCodes); }
  catch (err) { }
}

 function* watchLocationCodesCreateRequest() {
  try { yield takeLatest(getType(createLocationCodesActions.request), handleCreateLocationCodes); }
  catch (err) { }
}



export default [
  watchCodesGetRequest,
  watchEligibilityCodesGetRequest,
  watchLocationCodesGetRequest,
  watchSeparationCodesGetRequest,
  watchEligibilityCodesUpdateRequest,
  watchEligibilityCodesCreateRequest,
  watchSeparationCodesUpdateRequest,
  watchSeparationCodesCreateRequest,
  watchLocationCodesUpdateRequest,
  watchLocationCodesCreateRequest,
];

