import React from 'react';
import { FormikHandlers, FormikErrors } from 'formik';

// material ui
import { Grid, Card, CardHeader, Tooltip, Typography } from '@material-ui/core';
import { ClientQuestionnaireDto } from '../dto';
import {
  ContactCardContent,
  CardContent3,
  NoBorderTextField,
  TypographyWithMargin,
} from '../../../common/styles/styles';
import AttachmentsDropzone from '../../../common/components/attachmentDropzone';

interface Props extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  setShouldBlockNavigation: (o: boolean) => void;
  onInformationAttachmentDelete: (file: File) => void;
  onInformationAttachmentUpload: (files: File[]) => void;
  informationAttachments: File[];
}

const AdditionalInformationCard: React.FC<Props> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  setShouldBlockNavigation,
  onInformationAttachmentDelete,
  onInformationAttachmentUpload,
  informationAttachments,
}) => {
  return (
    <Card>
      <CardHeader
        title='Additional Information'
        titleTypographyProps={{ variant: 'h6' }}
      ></CardHeader>
      <CardContent3>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <>
              <TypographyWithMargin
                variant='body2'
                color='textSecondary'
                tma='4'
              >
                Add any additional information you would like to include
              </TypographyWithMargin>
              <Card elevation={2}>
                <ContactCardContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <NoBorderTextField
                        id='additionalInformation'
                        size='small'
                        name='additionalInformation'
                        value={values.additionalInformation || ''}
                        onChange={(e) => {
                          handleChange(e);
                          setShouldBlockNavigation(true);
                        }}
                        onBlur={handleBlur}
                        aria-label='Additional information'
                        label=''
                        variant='outlined'
                        fullWidth
                        multiline
                        minRows={1}
                        error={Boolean(errors.additionalInformation)}
                        helperText={errors.additionalInformation}
                        data-cy={'clientQuestionnaire_additionalInformation'}
                      />
                    </Grid>
                  </Grid>
                </ContactCardContent>
              </Card>
            </>
          </Grid>

          <Grid item xs={12}>
            <AttachmentsDropzone
              attachments={informationAttachments}
              onDelete={onInformationAttachmentDelete}
              onDrop={onInformationAttachmentUpload}
            />
          </Grid>
          {values.preexistingAttachedDocumentFilenames &&
            values.preexistingAttachedDocumentFilenames?.length > 0 && (
              <Grid container item xs={12}>
                Previously Attached Documents:
                {values.preexistingAttachedDocumentFilenames?.map(
                  (filename) => (
                    <Grid item xs={12}>
                      <Tooltip title={filename}>
                        <Typography variant='caption'>{filename}</Typography>
                      </Tooltip>
                    </Grid>
                  )
                )}
              </Grid>
            )}
          <Grid item xs={10}>
            <Grid item xs={12}>
              ATTENTION:
            </Grid>
            <Grid item xs={12}>
              Frequently, the State Agency requests copies of documentation from
              the employer to support a claim. If there is documentation you are
              providing that you would prefer to remain confidential, please let
              us know which documents should remain private.
            </Grid>
          </Grid>
        </Grid>

        <br />
        <br />
      </CardContent3>
    </Card>
  );
};

export default AdditionalInformationCard;
