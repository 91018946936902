


import { ISelectOption } from "../dtos/SelectOptionDto";
enum RatePayPerCodes {
        H = 0,
        W = 1,
        B = 2,
        M = 3,
        S = 4,
         
}

export class RatePayPerCodes_class {

public static enum = RatePayPerCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['H']: 0,
    ['W']: 1,
    ['B']: 2,
    ['M']: 3,
    ['S']: 4,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "Hourly",
    [1]: "Weekly",
    [2]: "Bi-weekly",
    [3]: "Monthly",
    [4]: "Semi-Monthly",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "Hourly"},
  {value: 1, text: "Weekly"},
  {value: 2, text: "Bi-weekly"},
  {value: 3, text: "Monthly"},
  {value: 4, text: "Semi-Monthly"},
  ]
}

