import React from 'react';
import { Prompt } from 'react-router';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Dialog, DialogContent, DialogTitle, Grid, TextField, DialogActions,
  MenuItem
} from '@material-ui/core';
import { getType } from 'typesafe-actions';
import { KeyboardDatePicker, } from '@material-ui/pickers';
import { DialogInputLabel } from '../claims.styles';
import { StyledCardContent, PaddedTextField, ButtonGrid, StyledSubmitButton, StyledCancelButton } from '../../../common/styles/styles';
import { updateClaimEventActions, createClaimEventActions } from '../actions'
import { ClaimDto, ClaimEventDto, ClaimEventInit } from '../claims.dtos'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { SeparationCodeDto } from '../../settings/codes/codes.dtos';
import { claimEventTypeOptions, alternativeIssueOptions } from '../options';
import { getFullName } from '../../../common/dtos/auth';


interface Props {
  open: boolean;
  onCancel: () => void;
  isClientUser: boolean;
  isReadOnly: boolean;
  claim: ClaimDto;
  separationCodes: SeparationCodeDto[];
}

const AddClaimEventDialog: React.FC<Props> = (
  {
    open,
    onCancel,
    isClientUser,
    isReadOnly,
    claim,
    separationCodes,
  }) => {
  const dispatch = useDispatch();

  const [dto, setDto] = React.useState(ClaimEventInit as ClaimEventDto)

  const cancelDialog = () => onCancel();

  return <Formik
    initialValues={dto}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={Yup.object().shape({
      alternativeIssueId: Yup.number().nullable(),
      completedDate: Yup.date().nullable(),
      eventTypeId: Yup.number().nullable(),
    })
    }
    onSubmit={(values, { setSubmitting, resetForm }) => {
      setTimeout(() => {
        setSubmitting(false);
        resetForm();
      }, 700);

      let request = values;
      request.claimId = claim.id;
      request.createdBy = getFullName();


      dispatch({
        type: values.id ?
          getType(updateClaimEventActions.request) :
          getType(createClaimEventActions.request),
        payload: request
      });
      cancelDialog();
    }}
    enableReinitialize={true}
  >
    {(props) => {
      const {
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      } = props;
      return (
        <Dialog
          open={open}
          data-cy="addClaimEvent_dialog">
          <DialogTitle>Add Event - Claim</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <StyledCardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <DialogInputLabel>* Type of Event</DialogInputLabel>
                    <TextField
                      id="eventTypeId"
                      name="eventTypeId"
                      onChange={handleChange}
                      value={values.eventTypeId ?? ''}
                      disabled={isClientUser || isReadOnly}
                      select
                      InputLabelProps={{ required: true }}
                      fullWidth
                      data-cy="addClaimEvent_eventTypeId"
                      onBlur={handleBlur}
                      error={Boolean(errors.eventTypeId)}
                      helperText={errors.eventTypeId}>
                      <MenuItem value={''} disabled>Please select an item</MenuItem>
                      {claimEventTypeOptions.map((option, index) => (
                        <MenuItem key={option.name + index} value={option.id}>{option.name}</MenuItem>
                      ))}
                    </TextField>

                  </Grid>

                  <Grid item xs={12}>
                    <DialogInputLabel>Issue</DialogInputLabel>
                    <PaddedTextField
                      id="issue"
                      size="small"
                      margin="none"
                      disabled
                      value={separationCodes.find(x => x.id === claim.separationCodeId)?.description || ''}
                      name="issue"
                      data-cy="addClaim_issue"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <DialogInputLabel>Alternative Issue</DialogInputLabel>
                    <TextField
                      id="alternativeIssueId"
                      name="alternativeIssueId"
                      onChange={handleChange}
                      value={values.alternativeIssueId ?? ''}
                      disabled={isClientUser || isReadOnly}
                      select
                      fullWidth
                      data-cy="addClaimEvent_alternativeIssueId"
                      onBlur={handleBlur}
                      error={Boolean(errors.alternativeIssueId)}
                      helperText={errors.alternativeIssueId}>
                      <MenuItem value={''} disabled>Please select an item</MenuItem>
                      {alternativeIssueOptions.map((option, index) => (
                        <MenuItem key={option.name + index} value={option.id}>{option.name}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12}>
                    <DialogInputLabel>Completed Date</DialogInputLabel>
                    <KeyboardDatePicker
                      margin="normal"
                      id="completedDate"
                      name="completedDate"
                      disabled={isClientUser || isReadOnly}
                      // label="Date picker dialog"
                      format="MM/DD/yyyy"
                      fullWidth
                      data-cy="addClaimEvent_completedDate"
                      value={values.completedDate}
                      // onChange={handleChange}
                      onChange={(date: MaterialUiPickersDate) => { setFieldValue('completedDate', date) }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                </Grid>
              </StyledCardContent>

              <DialogActions>
                <ButtonGrid container>
                  <StyledSubmitButton
                    type="button"
                    color="primary"
                    onClick={() => handleSubmit()}
                    disabled={isSubmitting || isClientUser || isReadOnly}
                    data-cy="addClaimEvent_submitButton"
                  >
                    Save
                  </StyledSubmitButton>

                  <StyledCancelButton
                    data-cy="addClaimEvent_cancelButton"
                    color="secondary"
                    onClick={cancelDialog}
                  >
                    Cancel
                  </StyledCancelButton>
                </ButtonGrid>
              </DialogActions>
            </form>
          </DialogContent>
          <Prompt
            // when={shouldBlockNavigation}
            message='You have unsaved edits, are you sure you want to leave?'
          />
        </Dialog>
      );
    }}
  </Formik>
}

export default AddClaimEventDialog;
