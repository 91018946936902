import React from 'react';
import {  Grid, Typography } from '@material-ui/core';
import * as Styles from '../../../common/styles/styles';
import { ClaimLogDto } from '../claims.dtos';
import moment from 'moment';
import { documentTypeOptions, documentStatusOptions } from '../../../common/constants/options';


interface Props {
  dto: ClaimLogDto
}

const ClaimLogCard: React.FC<Props> = ({ dto }) => {

    const [docType, setDocType] = React.useState('' as string);
    const [docStatus, setDocStatus] = React.useState('' as string);


    React.useEffect(() => {
        if (documentTypeOptions.find(o => o.id === dto.documentType) !== undefined) {
            let name = documentTypeOptions.find(o => o.id === dto.documentType)!.name;
            name !== '' ?
                setDocType(name)
                :
                setDocType('None on File')
        }
        if (documentStatusOptions.find(o => o.id === dto.documentStatus) !== undefined) {
            let name = documentStatusOptions.find(o => o.id === dto.documentStatus)!.name;
            name !== '' ?
                setDocStatus(name)
                :
                setDocStatus('None on File')
        }       
    }, [dto]);


  return (
    
    <Styles.TableCard raised>
    <Styles.ListCardContent>
      <Grid container data-cy="claims_event_container">       
        <Grid item xs={12} sm={12} md={6} lg={2}>
          <Typography variant="body2" color="textPrimary">Due Date</Typography>
          <Typography variant="body2" color="secondary">{moment(dto.dueDate).format('yyyy-MM-DD') || "None on File"}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2}>
                      <Typography variant="body2" color="textPrimary">Date Received</Typography>
                      <Typography variant="body2" color="secondary">{moment(dto.dateRecieved).format('yyyy-MM-DD') || "None on File"}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2}>
          <Typography variant="body2" color="textPrimary">Document Type</Typography>
                      <Typography variant="body2" color="secondary">{docType || 'None on File'}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2}>
        <Typography variant="body2" color="textPrimary">Document Status</Typography>
                      <Typography variant="body2" color="secondary">{docStatus || 'None on File'}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2}>
                      <Typography variant="body2" color="textPrimary">Created Date</Typography>
                      <Typography variant="body2" color="secondary">{moment(dto.createdDate).format('yyyy-MM-DD') || "None on File"}</Typography>        
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2}>
          <Typography variant="body2" color="textPrimary">Created By</Typography>
                      <Typography variant="body2" color="secondary">{dto.createdBy}</Typography>        
        </Grid>    
      </Grid>

    </Styles.ListCardContent>
  </Styles.TableCard>
  )
};

export default ClaimLogCard;
