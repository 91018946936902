import * as React from 'react';
import { useMediaQuery } from '@material-ui/core';

import Header from './header/header';
import Menu from './menu/menu';
import Footer from './footer';
import { DashboardContainer, HeaderContainer, MainSidebar, Drawer, Main, HeaderSpacer } from './menu/menu.styles';


export interface MainLayoutProps {
  children?: any
}

export default function MainLayout({ children }: MainLayoutProps) {
  const shouldCollapse = useMediaQuery('(max-width:960px)'); //768px
  const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(shouldCollapse);

  function handleSidebarToggle() {
    setIsSidebarCollapsed(!isSidebarCollapsed);
    
  }

  return (
    <DashboardContainer>
      <HeaderContainer>
        <Header
          onToggle={handleSidebarToggle}
          isCollapsed={isSidebarCollapsed || shouldCollapse}
        />
      </HeaderContainer>
      <MainSidebar issidebarcollapsed={`${isSidebarCollapsed}`} shouldcollapse={`${shouldCollapse}`}>
        <Drawer variant="permanent" issidebarcollapsed={`${isSidebarCollapsed}`} shouldcollapse={`${shouldCollapse}`}>
          <Menu
            isCollapsed={isSidebarCollapsed || shouldCollapse}
          />
        </Drawer>
      </MainSidebar>
      <Main item xs={12} issidebarcollapsed={`${isSidebarCollapsed}`} shouldcollapse={`${shouldCollapse}`}>
        <HeaderSpacer />
        {children}
        <Footer />
      </Main>
    </DashboardContainer>
  )
}