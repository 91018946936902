import { FormControl, FormHelperText, Grid, Checkbox, GridSize } from '@material-ui/core';
import { FormikErrors, FormikHandlers, FormikHelpers, getIn } from 'formik';
import React from 'react';
import Label from '../../../../common/components/label';
import { ISi } from '../../../../common/dtos/SiDto';
import { IndentGrid } from '../../../../common/styles/styles';
import { handleIndicatorTypeY } from '../../../../utils/siHelperFunctions';
import { CheckboxLabel } from '../../documents.styles';

interface Props {
  label: string;
  customLabel?: string;
  name: string;
  value: number | undefined;
  errors: FormikErrors<ISi>;
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ISi>["setFieldValue"];
  isReadOnly: boolean;
  size?: GridSize;
}

const IndicatorTypeYField: React.FC<Props> = (
  {
    label,
    customLabel,
    name,
    value,
    errors,
    handleBlur,
    setFieldValue,
    isReadOnly,
    size = 12.
  }) => {

  return (
    <>
      {customLabel !== undefined &&
        <Grid item xs={12} sm={12} md={12} lg={size}>
        <Label>{customLabel}</Label>
        </Grid>
      }
      
      <IndentGrid container direction="row" item xs={12} sm={12} md={12} lg={size}>
        <FormControl error={Boolean(getIn(errors, name))} >
          <CheckboxLabel
            checked={value === 0 ? true : false}
            value={value === 0 ? true : false}
            onChange={(_e, c) => { handleIndicatorTypeY(name, c, setFieldValue) }}
            onBlur={handleBlur}
            control={<Checkbox disabled={isReadOnly} size="medium" />}
            name={name}
            label={label}
            data-cy={`sidesResponse_${name}`} />
          {Boolean(getIn(errors, name)) &&
            <FormHelperText id={`${name}_helper_text`}>
              {getIn(errors, name)}
            </FormHelperText>
          }
        </FormControl>
      </IndentGrid>
    </>
  );

}

export default IndicatorTypeYField;