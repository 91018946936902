import { ISi } from "../common/dtos/SiDto";
import { EmployerSepReasonCodes_class } from "../common/enums/EmployerSepReasonCodes";
import { NotReturningCodes_class } from "../common/enums/NotReturningCodes";
import { VoluntarySepReasonCodes_class } from "../common/enums/VoluntarySepReasonCodes";
import { DischargeReasonCodes_class } from "../common/enums/DischargeReasonCodes";
import { FrequencyCodes_class } from "../common/enums/FrequencyCodes";
import { BreakReasonCodes_class } from "../common/enums/BreakReasonCodes";
import { Pension401kReceivedCodes_class } from "../common/enums/Pension401kReceivedCodes";

/*
 * A function for each ISi prop that op conditionally or optionally required from the client. 
 * Returns true or false. 
 * Based on
 * G:\.shortcut-targets-by-id\0B4pGxV3b_knZRlptUWtUR0xfdm8\Clients\Michigan Health and Hospital Association\Unemployment Claims Processing - 24866 25550\Design Specs\R4 - SIDES Re-write\SIDES Separation Information Standard Format.docx
 */
const SC = EmployerSepReasonCodes_class.enum;
const NRC = NotReturningCodes_class.enum;
const DC = DischargeReasonCodes_class.enum;
const VC = VoluntarySepReasonCodes_class.enum;
const FC = FrequencyCodes_class.enum;
const BRC = BreakReasonCodes_class.enum;
const PRC = Pension401kReceivedCodes_class.enum;


export const opFraudInd = (v: ISi): boolean => true;
export const opContractEmployee1099Ind = (v: ISi): boolean =>
  [SC.Item20, SC.Item21, SC.Item23].findIndex(f => f === v.employerSepReasonCode) !== -1 || v.temporaryStaffingInd === 0 ? false : true;
export const opSeasonalEmploymentInd = (v: ISi): boolean => true;
export const opTemporaryStaffingInd = (v: ISi): boolean =>
  [SC.Item20, SC.Item21, SC.Item23].findIndex(f => f === v.employerSepReasonCode) !== -1 || v.contractEmployee1099Ind === 0 ? false : true;
export const opClaimantJobTitle = (v: ISi): boolean => true;
export const opEmployerReportedClaimantFirstDayofWork = (v: ISi): boolean => true;
export const opEmployerReportedClaimantLastDayofWork = (v: ISi): boolean => v.employerSepReasonCode == SC.Item99 || v.whyNotReturning === NRC.Item99;
export const opSeparationDateInd = (v: ISi): boolean => v.employerSepReasonCode == SC.Item99 || (v.whyNotReturning === NRC.Item99 && v.employerSepReasonCode == SC.Item8);

export const opWhoPerformedTest = (v: ISi): boolean => v.testPerformedInd === 0;
export const opTesterName = (v: ISi): boolean => v.testPerformedInd === 0; 
export const opTesterCompanyName = (v: ISi): boolean => v.testPerformedInd === 0; 
export const opTestResults = (v: ISi): boolean => v.testPerformedInd === 0; 
export const opChainOfCustodyInd = (v: ISi): boolean => v.testPerformedInd === 0; 
export const opSecondTestSplitSampleGiven = (v: ISi): boolean => v.testPerformedInd === 0; 
export const opSecondTestSplitSampleResults = (v: ISi): boolean => v.secondTestSplitSampleGiven === 0;
export const opDollarValue = (v: ISi): boolean => v.dischargeReasonCode === DC.Item12;
export const opViolateCompanyPolicyInd = (v: ISi): boolean => [DC.Item1, DC.Item2, DC.Item5, DC.Item7, DC.Item8, DC.Item10, DC.Item11, DC.Item12, DC.Item13]
  .findIndex(f => f === v.dischargeReasonCode) !== -1;
export const opDischargeReasonComments = (v: ISi): boolean => [DC.Item1, DC.Item2, DC.Item3, DC.Item4, DC.Item5, DC.Item7, DC.Item8, DC.Item9, DC.Item10, DC.Item11, DC.Item12, DC.Item13]
  .findIndex(f => f === v.dischargeReasonCode) !== -1;
export const opContinuingWorkAvailableInd = (v: ISi): boolean => v.employerSepReasonCode == SC.Item18 || v.whyNotReturning === NRC.Item18;
export const opWhyRetireComments = (v: ISi): boolean => v.voluntarySepReasonCode === VC.Item8;
export const opHiringAgreementChangesCode = (v: ISi): boolean => v.voluntarySepReasonCode === VC.Item8 || v.mandatoryRetirementInd === 1;
export const opVoluntarySepReasonComments = (v: ISi): boolean => [VC.Item1, VC.Item2, VC.Item3, VC.Item4, VC.Item6, VC.Item7, VC.Item8, VC.Item9]
  .findIndex(f => f === v.voluntarySepReasonCode) !== -1;
export const opFirstDayOfBreak = (v: ISi): boolean => v.employerSepReasonCode === SC.Item17;
export const opLastDayOfBreak = (v: ISi): boolean => v.employerSepReasonCode === SC.Item17;
export const opWorkContinueDuringBreakInd = (v: ISi): boolean => v.employerSepReasonCode === SC.Item7;
export const opNewContractJobOfferInd = (v: ISi): boolean => v.reasonForBreak === BRC.Item2;
export const opContractAccepted = (v: ISi): boolean => v.newContractJobOfferInd === 0;
export const opLocationOfLaborDispute = (v: ISi): boolean => v.employerSepReasonCode === SC.Item16 || v.whyNotReturning === NRC.Item16;
export const opUnionNameAndLocalNo = (v: ISi): boolean => v.employerSepReasonCode === SC.Item16 || v.whyNotReturning === NRC.Item16;
export const opClmtMemberAnotherUnionInd = (v: ISi): boolean => v.clmtStrikingUnionMemberInd === 1;
export const opSettlementAffectClmtInd = (v: ISi): boolean => v.employerSepReasonCode === SC.Item16 || v.whyNotReturning === NRC.Item16;
export const opHowSettlementAffects = (v: ISi): boolean => v.settlementAffectClmtInd === 0;
export const opEmployerSepReasonComments = (v: ISi): boolean => [SC.Item7, SC.Item2, SC.Item14, SC.Item15, SC.Item16, SC.Item17, SC.Item18, SC.Item20, SC.Item21, SC.Item23]
  .findIndex(f => f === v.employerSepReasonCode) !== -1 || [NRC.Item2, NRC.Item14, NRC.Item16, NRC.Item18]
    .findIndex(f => f === v.whyNotReturning) !== -1 || v.temporaryStaffingInd === 0;
export const opEmployerContactName = (v: ISi): boolean => [SC.Item21, SC.Item23].findIndex(f => f === v.employerSepReasonCode) !== -1;
export const opEmployerPhone = (v: ISi): boolean => [SC.Item21, SC.Item23].findIndex(f => f === v.employerSepReasonCode) !== -1;
export const opEmployerStreetAddress = (v: ISi): boolean => [SC.Item21, SC.Item23].findIndex(f => f === v.employerSepReasonCode) !== -1;
export const opEmployerCity = (v: ISi): boolean => [SC.Item21, SC.Item23].findIndex(f => f === v.employerSepReasonCode) !== -1;
export const opEmployerState = (v: ISi): boolean => [SC.Item21, SC.Item23].findIndex(f => f === v.employerSepReasonCode) !== -1;
export const opEmployerZip = (v: ISi): boolean => [SC.Item21, SC.Item23].findIndex(f => f === v.employerSepReasonCode) !== -1;
export const opRepContractTermDate = (v: ISi): boolean => [SC.Item21, SC.Item23].findIndex(f => f === v.employerSepReasonCode) !== -1;
export const opWagesEarnedAfterClaimEffectiveDate = (v: ISi): boolean => true;
export const opNumberOfHoursWorkedAfterClaimEffectiveDate = (v: ISi): boolean => !!v.wagesEarnedAfterClaimEffectiveDate && v.wagesEarnedAfterClaimEffectiveDate > 0;

export const opRemunerationAmountPerPeriod = (v: ISi, i: number): boolean => !!v.uisSiRemunerationOccurrences &&
  v.uisSiRemunerationOccurrences[i]?.remunerationPeriodFrequencyCode === FC.Z;
export const opNumberHoursRepresented = (v: ISi, i: number): boolean => !!v.uisSiRemunerationOccurrences && v.uisSiRemunerationOccurrences[i]?.remunerationTypeCode !== undefined &&
  v.uisSiRemunerationOccurrences[i]?.remunerationPeriodFrequencyCode === FC.Z;
export const opDateRemunerationIssued = (v: ISi, i: number): boolean => !!v.uisSiRemunerationOccurrences && v.uisSiRemunerationOccurrences[i]?.remunerationTypeCode !== undefined &&
  v.uisSiRemunerationOccurrences[i]?.remunerationPeriodFrequencyCode === FC.Z;
export const opRemunerationComments = (v: ISi, i: number): boolean => !!v.uisSiRemunerationOccurrences && v.uisSiRemunerationOccurrences[i]?.remunerationTypeCode !== undefined;

export const opReceivingWorkersCompensationInd = (v: ISi): boolean => true;
export const opWeeklyWorkersCompRate = (v: ISi): boolean => v.receivingWorkersCompensationInd === 0;
export const opPeriodOfTimeWorkersCompPaymentCovers = (v: ISi): boolean => v.receivingWorkersCompensationInd === 0;
export const opEmployerAccommodateRestrictionsInd = (v: ISi): boolean => v.workRestrictionsInd === 0; // Check
export const opWhyEmployerCouldNotAccommodate = (v: ISi): boolean => v.employerAccommodateRestrictionsInd === 1;
export const opWorkersCompComments = (v: ISi): boolean => v.receivingWorkersCompensationInd === 0;

/* Pension401 */
export const opPension401KamountPerPeriod = (v: ISi, i: number): boolean => v.uisSiPension401Koccurrences !== undefined && v.uisSiPension401Koccurrences[i]?.pension401KfrequencyCode === FC.Z;
export const opDatePension401Kissued = (v: ISi, i: number): boolean => v.uisSiPension401Koccurrences !== undefined && v.uisSiPension401Koccurrences[i]?.pension401KfrequencyCode === FC.Z;
export const opDatePension401KissuedUnknown = (v: ISi, i: number): boolean => v.uisSiPension401Koccurrences !== undefined && v.uisSiPension401Koccurrences[i]?.pension401KfrequencyCode === FC.Z;
export const opPension401Kcomments = (v: ISi, i: number): boolean => v.uisSiPension401Koccurrences !== undefined &&
  v.uisSiPension401Koccurrences[i]?.pension401Kind !== undefined;


export const opUisSiContactOccurrences = (v: ISi): boolean => v.receivingWorkersCompensationInd === 0 || [PRC.Item1, PRC.Item2, PRC.Item3].findIndex(f => f === v.receivePension401Kind ?? -1) !== -1;