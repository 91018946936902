import { AxiosError } from 'axios';

import { ApiError } from '../../../common/api/apiResponse';
import apiClient from '../../../common/api/apiClient';

import { showNotification } from '../../../common/actionCreators/dispatchNotification';
import { HealthSystemDto } from './healthSystems.dtos';
import { RateDto, RateWrapperDto } from '../../../common/dtos/billing';


// endpoints
const healthSystemsUrl = 'settings/health-systems'; // GET & PUT Get permissions and update permission releationships 


export const getHealthSystemsByIdApi = async (id: string) => {
  return await apiClient().get(`${healthSystemsUrl}/${id}`)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while retrieving Health System.', 'error');
      throw err;
    });
};

export const getHealthSystemsApi = async () => {
  return await apiClient().get(healthSystemsUrl)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while getting Health Systems.', 'error');
      throw err;
    });
};

export const updateHealthSystemsApi = async (request: HealthSystemDto) => {
  return await apiClient().put(healthSystemsUrl, request)
    .then((res) => {
      showNotification('Health System saved.', 'success');
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while updating Health System.', 'error');
      throw err;
    });
};

export const createHealthSystemsApi = async (request: HealthSystemDto) => {
  return await apiClient().post(healthSystemsUrl, request)
    .then((res) => {
      showNotification('Health System created.', 'success');
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while creating Health System.', 'error');
      throw err;
    });
};


export const getHealthSystemRateApi = async (request: RateWrapperDto) => {
  return await apiClient().get(healthSystemsUrl + "/" + request.id + "/rate")
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting rate.", "error");
      throw err;
    });
};

export const createHealthSystemRateApi = async (request: RateWrapperDto) => {
  return await apiClient().post((healthSystemsUrl + "/" + request.id + "/rate"), request.rate)
    .then(res => {
      showNotification("Rate saved.", "success");
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while creating rate.", "error");
      throw err;
    });
};

export const updateHealthSystemRateApi = async (request: RateWrapperDto) => {
  return await apiClient().put((healthSystemsUrl + "/" + request.id + "/rate"), request.rate)
    .then(res => {
      showNotification("Rate saved.", "success");
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while updating rate.", "error");
      throw err;
    });
};