import styled from 'styled-components';

// material ui
import {
    Card as MuiCard, TextField, Button, Checkbox, FormControlLabel, Grid,  InputLabel, ButtonBaseProps
} from '@material-ui/core';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import {
    KeyboardDatePicker,
} from '@material-ui/pickers';

// Styled components ....


export const AlignedDatePicker = styled(KeyboardDatePicker)`
  margin-top: -3px;
`;

export const SortGrid = styled(Grid)`
  margin-top: -18px;
`;

export const PastDueCheckbox = styled(FormControlLabel)`
  margin-top: 12px;
  margin-left: 25px;
`;

export const HistoryCheckbox = styled(FormControlLabel)`
  margin-top: 12px;
  margin-left: 2px;
`;

export const ImportExportIconBlue = styled(ImportExportIcon)`
  color: ${props => props.theme.palette.primary.main};
  font-size: 22px; 
`;


export const ImportExportIconRed = styled(ImportExportIcon)`
  color: red;
  font-size: 22px; 
  // Flip arrows
  transform: scaleX(-1);
`;

export const WageDateGrid = styled(Grid)`
  padding-bottom: 10px;
`;

export const ImportExportIconNotSelected = styled(ImportExportIcon)`
  font-size: 22px; 
`;

export const PaddedPlaceholderGrid = styled(Grid)`
  padding-bottom: 20px;
`;
export const PaddedSearchfield = styled(TextField)`
  padding-bottom: 10px;
`;


export const DueTodayButton = styled(Button)`
  margin-top: 5px;
  background-color: #ef8d22;
  color: white !important;
  &:disabled {
      color: white;
      background-color: #ef8d22;
    }
  width: 100%;
  pointer-events: none;
`;


export const OverdueButton = styled(Button)`
  margin-top: 5px;
  background-color: #e36260;
  color: white !important;
  &:disabled {
      color: white;
      background-color: #e36260;
    }
  width: 100%;
  cursor: unset;
  pointer-events: none;
`;


export const DueSoonButton = styled(Button)`
  margin-top: 5px;
  background-color: #177674;
  color: white !important;
  &:disabled {
      color: white;
      background-color: #177674;
    }
  width: 100%;
  pointer-events: none;
`;

export const StyledAddButton = styled(AddBoxRoundedIcon)`
  margin-right: 25px;
`;

export const NavOptionsGrid = styled(Grid)`
  padding-bottom: 10px;
  justify-content: space-between;
`;

export const SidesOptionsGrid = styled(Grid)`
  padding-bottom: 10px;
  justify-content: space-between;
  position: fixed;
  background: #fff;
  z-index: 1000;
  padding: 24px 62px;
  margin-top: -28px;
`;

export const ChooseFileCard = styled(MuiCard)`
  width: 100%;
  padding: 2px;
`;

export const NavCard = styled(MuiCard)`
  width: 100%;
  padding: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const PaymentCard = styled(MuiCard)`
  width: 100%;
  height: 30%;
  padding: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const SmallFileText = styled.small`
  margin-left: 8px;
  margin-top: 12px;
  position: relative;
  top: 11px;
`;
export const SidesResponseNavOption = styled(Button) <{ selected: boolean, haserror?: string }>`
  margin-left: 5px;
  font-size: ${props => !!props.haserror ? '16' : props.selected ? '13': '11'}px;
  font-weight: 550;
  color: ${props => !!props.haserror ? props.theme.palette.error.dark :
  props.selected ? props.theme.palette.primary.main : props.theme.secondaryColors.grey};
  background-color: inherit !important;
  padding-left: 0px;
`;

export const PaddedTextField = styled(TextField)`
  padding-right: 5px;
`;

export const ReassignButton = styled(Button)`
  margin-top: 15px;
  padding: 5px 15px;
  margin-left: 22px;
`;

export const DialogInputLabel = styled(InputLabel)`
  margin-top: 25px;
  margin-bottom: 10px;
`;

export const DateToGrid = styled(Grid)`
  margin-left: 8px;
  margin-top: 5px;
`;

export const FilterLinksContainer = styled(Grid)`
  margin-top: 15px;
`;

export const UnavailableCheckbox = styled(Checkbox)`
  margin-left: 20px;
  margin-top: 10px;
  position: relative;
  > span {
    font-size: 12px;
    color: ${props => props.theme.palette.primary.secondary};
    position: relative;
  }
`;

export const CheckboxLabel = styled(FormControlLabel)`
  padding: 0px  
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  line-height: 1px !important;
  > span {        
    font-size: 12px;
    color: ${props => props.theme.palette.primary.secondary};
    margin-top: 8px;
  }
`;

export const DeleteConfirmationLabel = styled(InputLabel)`
  color: #7C878E;
`;

export const DeleteLabel = styled(InputLabel)`
  margin-top: 25px;
  margin-bottom: 10px;
  color: black;
`;