import { createAsyncAction } from 'typesafe-actions';
import { TaskTypesWorkOrderSettingsDto } from './workOrders.dtos';

export const getTaskTypesWorkOrderSettingsActions = createAsyncAction(
  'GET_TASK_TYPES_WORK_ORDER_SETTINGS_REQUEST',
  'GET_TASK_TYPES_WORK_ORDER_SETTINGS_SUCCESS',
  'GET_TASK_TYPES_WORK_ORDER_SETTINGS_ERROR',
)<undefined, TaskTypesWorkOrderSettingsDto[], string>();

export const updateTaskTypesWorkOrderSettingsActions = createAsyncAction(
  'UPDATE_TASK_TYPES_WORK_ORDER_SETTINGS_REQUEST',
  'UPDATE_TASK_TYPES_WORK_ORDER_SETTINGS_SUCCESS',
  'UPDATE_TASK_TYPES_WORK_ORDER_SETTINGS_ERROR',
)<TaskTypesWorkOrderSettingsDto[], TaskTypesWorkOrderSettingsDto[], string>(); 