import React from "react";
import {
  FormikHandlers,
  FormikTouched,
  FormikErrors,
  FormikHelpers,
  getIn,
  FieldArray,
} from "formik";

// material ui
import {
  Grid,
  MenuItem,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";

import * as Style from "../claims.styles";
import * as Styles from "../../../common/styles/styles";
import {
  ClaimDto,
  SepCodeDto,
  HearingContactsDto,
  ContactIdDto,
  ClaimantPaymentInit,
} from "../claims.dtos";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MoneyField from "../../../common/components/numberFields";
import { ContactDto } from "../../contacts/contacts.dtos";
import Label from "../../../common/components/label";
import moment from "moment";
import AddBoxRoundedIcon from "@material-ui/icons/AddBoxRounded";

import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { separationPayTypeOptions } from "../options";
import { setCompareDate, dateCompareType } from "../../../utils/setCompareDate";
import { jobStatusOptions } from "../../../common/constants/options";

interface InformationProps extends React.HTMLProps<React.ReactNode> {
  values: ClaimDto;
  touched: FormikTouched<ClaimDto>;
  errors: FormikErrors<ClaimDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setShouldBlockNavigation: (o: boolean) => void;
  setFieldValue: FormikHelpers<ClaimDto>["setFieldValue"];
  claimStatusOptions: { name: string; id: number }[];
  employerTypeOptions: { name: string; id: number }[];
  hearingContactOptions: ContactIdDto[];
  separationCodes: SepCodeDto[];
  isQuickEdit: boolean;
  isClientUser: boolean;
  isReadOnly: boolean;
  handleGetEmailPrompt: (email: string) => void;
  handleShowMovePotentialDialog: () => void;
}

const InformationDetails: React.FC<InformationProps> = (
  props: InformationProps
) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isQuickEdit,
    isReadOnly,
    isClientUser,
    setShouldBlockNavigation,
    setFieldValue,
    separationCodes,
    claimStatusOptions,
    employerTypeOptions,
    hearingContactOptions,
    handleGetEmailPrompt,
    handleShowMovePotentialDialog,
  } = props;

  const [dateWarning, setDateWarning] = React.useState<boolean>(false);

  const handleSelectHearingContacts = (
    hearingContacts: HearingContactsDto[]
  ) => {
    setFieldValue("hearingContacts", hearingContacts);
  };

  const compareFirstDayLastDayWorked = (lastDay: Date) => {
    var first = moment(values.dateFirstWorked).toDate();
    var last = values.dateLastWorked;

    if (first!.valueOf() > lastDay!.valueOf()) {
      setDateWarning(true);
    }
  };

  return (
    <Styles.IndentGrid container item xs={12} spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <div hidden={isQuickEdit}>
          <KeyboardDatePicker
            margin="normal"
            id="dateReceived"
            name="dateReceived"
            label="Date Received"
            format="MM/DD/yyyy"
            value={values.dateReceived || null}
            data-cy="claim_claimant_dateReceived"
            fullWidth
            disabled={isClientUser || isReadOnly}
            onChange={(date: MaterialUiPickersDate) =>
              setFieldValue("dateReceived", date)
            }
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </div>
      </Grid>
      <Styles.IndentGrid container spacing={1}>
        {isQuickEdit ? (
          <React.Fragment>
            <Grid item sm={12} md={6}>
              <Styles.PaddedTextField
                id="status"
                size="small"
                margin="none"
                select
                value={values.status || 0}
                label="Status"
                name="status"
                disabled={isClientUser || isReadOnly}
                onChange={(e) => {
                  handleChange(e);
                  if (parseInt(e.target.value) === 1)
                    handleShowMovePotentialDialog();
                }}
                onBlur={(e) => {
                  handleBlur(e);
                  if (parseInt(e.target.value) === 1)
                    setShouldBlockNavigation(true);
                }}
                error={Boolean(errors.status && touched.status)}
                helperText={
                  Boolean(errors.status) && touched.status && errors.status
                }
                data-cy="claim_information_status"
                fullWidth
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
              >
                {claimStatusOptions
                  .filter((item) => item.name !== "")
                  .map((option, index) => (
                    <MenuItem key={option.name + index} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
              </Styles.PaddedTextField>
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                disabled
                fullWidth
                InputProps={{ inputComponent: MoneyField as any }}
                label="Net Charges To Date"
                margin="none"
                size="small"
                value={
                  values.readOnlyNetChargesToDate
                    ? values.readOnlyNetChargesToDate
                    : 0
                }
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={4}>
              <KeyboardDatePicker
                margin="normal"
                id="benefitYearBeginningDate"
                name="benefitYearBeginningDate"
                label="Benefit Year Beginning"
                format="MM/DD/yyyy"
                InputLabelProps={{ required: true }}
                disabled={isClientUser || isReadOnly}
                shouldDisableDate={(d) => d?.weekday() !== 0}
                value={values.benefitYearBeginningDate || null}
                data-cy="claim_information_benefitYearBeginningDate"
                fullWidth
                //onChange={(date: MaterialUiPickersDate) => { setFieldValue('benefitYearBeginningDate', date); handleBenefitYearBeginningDateChange(date, values.benefitYearEndingDate); }}
                onChange={(date: MaterialUiPickersDate) =>
                  setCompareDate(
                    date,
                    values.benefitYearEndingDate,
                    setFieldValue,
                    "benefitYearBeginningDate",
                    "benefitYearEndingDate",
                    dateCompareType.byb
                  )
                }
                error={errors.benefitYearBeginningDate !== undefined}
                helperText={
                  errors.benefitYearBeginningDate &&
                  errors.benefitYearBeginningDate
                }
                KeyboardButtonProps={{
                  "aria-label": "change benefit year Beginning date",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <KeyboardDatePicker
                margin="normal"
                id="benefitYearEndingDate"
                name="benefitYearEndingDate"
                label="Benefit Year Ending"
                format="MM/DD/yyyy"
                InputLabelProps={{ required: true }}
                disabled={isClientUser || isReadOnly}
                shouldDisableDate={(d) => d?.weekday() !== 6}
                value={values.benefitYearEndingDate || null}
                data-cy="claim_information_benefitYearEndingDate"
                fullWidth
                //onChange={(date: MaterialUiPickersDate) => { setFieldValue('benefitYearEndingDate', date); handleBenefitYearEndingDateChange(date, values.benefitYearBeginningDate); }}
                onChange={(date: MaterialUiPickersDate) =>
                  setCompareDate(
                    date,
                    values.benefitYearEndingDate,
                    setFieldValue,
                    "benefitYearEndingDate",
                    "benefitYearBeginningDate",
                    dateCompareType.bye
                  )
                }
                error={errors.benefitYearEndingDate !== undefined}
                helperText={
                  errors.benefitYearEndingDate && errors.benefitYearEndingDate
                }
                KeyboardButtonProps={{
                  "aria-label": "change benefit year ending date",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <Style.PaddedAmountTextField
                id="weeklyBenefitAmount"
                size="small"
                margin="none"
                style={{ marginTop: 19 }}
                value={values.weeklyBenefitAmount}
                label="Weekly Benefit Amount"
                name="weeklyBenefitAmount"
                onChange={handleChange}
                fullWidth
                error={Boolean(errors.weeklyBenefitAmount)}
                disabled={isClientUser || isReadOnly}
                helperText={
                  errors.weeklyBenefitAmount &&
                  touched.weeklyBenefitAmount &&
                  errors.weeklyBenefitAmount
                }
                InputProps={{ inputComponent: MoneyField as any }}
                data-cy="claim_information_weeklyBenefitAmount"
              />
            </Grid>
            <>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <Styles.TextNumberField
                  id="potentialLiabilityAmount"
                  size="small"
                  margin="none"
                  disabled={isClientUser || isReadOnly}
                  value={values.potentialLiabilityAmount}
                  label="Potential Liability"
                  name="potentialLiabilityAmount"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  error={Boolean(errors.potentialLiabilityAmount)}
                  fullWidth
                  helperText={
                    errors.potentialLiabilityAmount &&
                    touched.potentialLiabilityAmount &&
                    errors.potentialLiabilityAmount
                  }
                  InputProps={{ inputComponent: MoneyField as any }}
                  data-cy="claim_information_potentialLiabilityAmount"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <Styles.TextNumberField
                  id="actualLiabilityAmountAmount"
                  size="small"
                  margin="none"
                  disabled={isClientUser || isReadOnly}
                  value={values.actualLiabilityAmount}
                  label="Actual Liability"
                  name="actualLiabilityAmount"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  error={Boolean(errors.actualLiabilityAmount)}
                  fullWidth
                  helperText={
                    errors.actualLiabilityAmount &&
                    touched.actualLiabilityAmount &&
                    errors.actualLiabilityAmount
                  }
                  onBlur={(e) => {
                    handleBlur(e);
                    setShouldBlockNavigation(true);
                  }}
                  InputProps={{ inputComponent: MoneyField as any }}
                  data-cy="claim_information_actualLiabilityAmount"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={4}>
                <Styles.TextNumberField
                  id="savingsAmount"
                  size="small"
                  disabled={isClientUser || isReadOnly}
                  margin="none"
                  value={
                    values.potentialLiabilityAmount !== "" &&
                    values.potentialLiabilityAmount !== undefined &&
                    values.actualLiabilityAmount !== "" &&
                    values.actualLiabilityAmount !== undefined
                      ? (
                          parseFloat(values.potentialLiabilityAmount!) -
                          parseFloat(values.actualLiabilityAmount!)
                        ).toString()
                      : "0.00"
                  }
                  label="Savings"
                  name="savingsAmount"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={(e) => {
                    handleBlur(e);
                    setShouldBlockNavigation(true);
                  }}
                  error={Boolean(errors.savingsAmount)}
                  helperText={
                    errors.savingsAmount &&
                    touched.savingsAmount &&
                    errors.savingsAmount
                  }
                  fullWidth
                  InputProps={{ inputComponent: MoneyField as any }}
                  data-cy="claim_information_savingsAmount"
                />
              </Grid>
            </>
          </React.Fragment>
        ) : (
          <>
            <Styles.PaddedTextField
              id="status"
              size="small"
              margin="none"
              select
              value={values.status || 0}
              label="Status"
              name="status"
              disabled={isClientUser || isReadOnly}
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={(e) => {
                handleBlur(e);
                setShouldBlockNavigation(true);
              }}
              error={Boolean(errors.status && touched.status)}
              helperText={
                Boolean(errors.status) && touched.status && errors.status
              }
              data-cy="claim_information_status"
              fullWidth
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
            >
              {claimStatusOptions
                .filter((item) => item.name !== "")
                .map((option, index) => (
                  <MenuItem key={option.name + index} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </Styles.PaddedTextField>
          </>
        )}
      </Styles.IndentGrid>

      <Grid item xs={12}>
        <Styles.PaddedTextField
          id="employerType"
          size="small"
          margin="none"
          select
          disabled={isClientUser || isReadOnly}
          value={values.employerType || ""}
          label="Type of Employer"
          name="employerType"
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={(e) => {
            handleBlur(e);
            setShouldBlockNavigation(true);
          }}
          error={Boolean(errors.employerType && touched.employerType)}
          helperText={
            Boolean(errors.employerType) &&
            touched.employerType &&
            errors.employerType
          }
          data-cy="claim_information_employerType"
          aria-label="Employer Type"
          fullWidth
          SelectProps={{ MenuProps: { disableScrollLock: true } }}
        >
          {employerTypeOptions
            .filter((item) => item.name !== "")
            .map((option, index) => (
              <MenuItem key={option.name + index} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
        </Styles.PaddedTextField>
      </Grid>

      <Grid item xs={12}>
        <div hidden={isQuickEdit}>
          <Label>
            Hearing Contact
            <Style.ClaimMailLink
              component={Button}
              title="Mail Hearing Contact"
              onClick={() => {
                if (handleGetEmailPrompt)
                  handleGetEmailPrompt(
                    hearingContactOptions
                      ?.filter((o) =>
                        values.hearingContacts
                          ?.map((m) => m.id)
                          .includes(o.contactId)
                      )
                      ?.map((e) => e.email)
                      ?.join(",") ?? ""
                  );
              }}
            >
              <Style.MHAMailicon />
              &nbsp;
            </Style.ClaimMailLink>
          </Label>
          <Autocomplete
            id="selectedHearingContacts"
            options={
              hearingContactOptions?.filter((hearingContactOption) =>
                hearingContactOption.contactEmployers?.some(
                  (contactEmployer) =>
                    contactEmployer.companyId === values.employerId
                )
              ) as ContactIdDto[]
            }
            autoComplete
            getOptionLabel={(o: ContactIdDto) => o.name ?? ""}
            multiple
            disabled={isClientUser || isReadOnly}
            value={hearingContactOptions.filter((o) =>
              values.hearingContacts.map((m) => m.id).includes(o.contactId)
            )}
            onChange={(_e: any, v: ContactIdDto[] | null) => {
              handleSelectHearingContacts(
                v === null
                  ? ([] as HearingContactsDto[])
                  : v.map((o) => {
                      return { id: o.contactId } as HearingContactsDto;
                    })
              );
            }}
            renderInput={(params) => (
              <Styles.PaddedTextField
                {...params}
                id="selectedHearingContacts"
                size="small"
                name="selectedHearingContacts"
                margin="none"
                aria-label="Hearing Contact"
                onBlur={(e) => {
                  handleBlur(e);
                  setShouldBlockNavigation(true);
                }}
                data-cy="claim_information_hearingContacts"
                fullWidth
              />
            )}
          />
        </div>
      </Grid>

      <Grid item xs={12}></Grid>

      <Grid item xs={12}>
        <Autocomplete
          id="separationCodeId"
          options={separationCodes
            .filter((f) => f.isSep)
            .sort((a, b) => a.name.localeCompare(b.name))}
          getOptionLabel={
            isClientUser
              ? (option) => option.description ?? ""
              : (option) => `${option.name} - ${option.description}` ?? ""
          }
          disabled={isClientUser || isReadOnly}
          includeInputInList
          value={
            separationCodes.find((o) => o.id === values.separationCodeId) ||
            null
          }
          autoComplete
          onChange={(_e: any, v: SepCodeDto | null) => {
            setFieldValue("separationCodeId", !!v?.id ? v.id : "");
          }}
          renderInput={(params) => (
            <Styles.PaddedTextField
              {...params}
              id="separationCodeId"
              size="small"
              margin="none"
              disabled={isClientUser || isReadOnly}
              value={values.separationCodeId || ""}
              label="Separation Code"
              name="separationCodeId"
              onBlur={(e) => {
                handleBlur(e);
                setShouldBlockNavigation(true);
              }}
              error={Boolean(
                getIn(errors, "separationCodeId") &&
                  getIn(touched, "separationCodeId")
              )}
              helperText={
                getIn(errors, "separationCodeId") &&
                getIn(touched, "separationCodeId") &&
                getIn(errors, "separationCodeId")
              }
              data-cy="claim_information_separationCodeId"
              fullWidth
            />
          )}
        />
      </Grid>

      {!isQuickEdit ? (
        <>
          <Grid item xs={12} sm={12} md={12} lg={6}>
            <KeyboardDatePicker
              margin="normal"
              id="benefitYearBeginningDate"
              name="benefitYearBeginningDate"
              label="Benefit Year Beginning"
              format="MM/DD/yyyy"
              InputLabelProps={{ required: true }}
              disabled={isClientUser || isReadOnly}
              shouldDisableDate={(d) => d?.weekday() !== 0}
              value={values.benefitYearBeginningDate || null}
              data-cy="claim_information_benefitYearBeginningDate"
              fullWidth
              onChange={(date: MaterialUiPickersDate) =>
                setCompareDate(
                  date,
                  values.benefitYearEndingDate,
                  setFieldValue,
                  "benefitYearBeginningDate",
                  "benefitYearEndingDate",
                  dateCompareType.byb
                )
              }
              error={errors.benefitYearBeginningDate !== undefined}
              helperText={
                errors.benefitYearBeginningDate &&
                errors.benefitYearBeginningDate
              }
              KeyboardButtonProps={{
                "aria-label": "change benefit year Beginning date",
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6}>
            <KeyboardDatePicker
              margin="normal"
              id="benefitYearEndingDate"
              name="benefitYearEndingDate"
              label="Benefit Year Ending"
              format="MM/DD/yyyy"
              InputLabelProps={{ required: true }}
              disabled={isClientUser || isReadOnly}
              shouldDisableDate={(d) => d?.weekday() !== 6}
              value={values.benefitYearEndingDate || null}
              data-cy="claim_information_benefitYearEndingDate"
              fullWidth
              onChange={(date: MaterialUiPickersDate) =>
                setCompareDate(
                  date,
                  values.benefitYearBeginningDate,
                  setFieldValue,
                  "benefitYearEndingDate",
                  "benefitYearBeginningDate",
                  dateCompareType.bye
                )
              }
              error={errors.benefitYearEndingDate !== undefined}
              helperText={
                errors.benefitYearEndingDate && errors.benefitYearEndingDate
              }
              KeyboardButtonProps={{
                "aria-label": "change benefit year ending date",
              }}
            />
          </Grid>
        </>
      ) : null}
      {!isQuickEdit ? (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Style.PaddedAmountTextField
              id="weeklyBenefitAmount"
              size="small"
              margin="none"
              style={{ marginTop: 19 }}
              value={values.weeklyBenefitAmount}
              label="Weekly Benefit Amount"
              name="weeklyBenefitAmount"
              onChange={handleChange}
              fullWidth
              error={Boolean(errors.weeklyBenefitAmount)}
              disabled={isClientUser || isReadOnly}
              helperText={
                errors.weeklyBenefitAmount &&
                touched.weeklyBenefitAmount &&
                errors.weeklyBenefitAmount
              }
              InputProps={{ inputComponent: MoneyField as any }}
              data-cy="claim_information_weeklyBenefitAmount"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Styles.TextNumberField
              id="potentialLiabilityAmount"
              size="small"
              margin="none"
              disabled={isClientUser || isReadOnly}
              value={values.potentialLiabilityAmount}
              label="Potential Liability"
              name="potentialLiabilityAmount"
              onChange={(e) => {
                handleChange(e);
              }}
              error={Boolean(errors.potentialLiabilityAmount)}
              fullWidth
              helperText={
                errors.potentialLiabilityAmount &&
                touched.potentialLiabilityAmount &&
                errors.potentialLiabilityAmount
              }
              InputProps={{ inputComponent: MoneyField as any }}
              data-cy="claim_information_potentialLiabilityAmount"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Styles.TextNumberField
              id="actualLiabilityAmountAmount"
              size="small"
              margin="none"
              disabled={isClientUser || isReadOnly}
              value={values.actualLiabilityAmount}
              label="Actual Liability"
              name="actualLiabilityAmount"
              onChange={(e) => {
                handleChange(e);
              }}
              error={Boolean(errors.actualLiabilityAmount)}
              fullWidth
              helperText={
                errors.actualLiabilityAmount &&
                touched.actualLiabilityAmount &&
                errors.actualLiabilityAmount
              }
              onBlur={(e) => {
                handleBlur(e);
                setShouldBlockNavigation(true);
              }}
              InputProps={{ inputComponent: MoneyField as any }}
              data-cy="claim_information_actualLiabilityAmount"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Styles.TextNumberField
              id="savingsAmount"
              size="small"
              disabled={isClientUser || isReadOnly}
              margin="none"
              value={
                values.potentialLiabilityAmount !== "" &&
                values.potentialLiabilityAmount !== undefined &&
                values.actualLiabilityAmount !== "" &&
                values.actualLiabilityAmount !== undefined
                  ? (
                      parseFloat(values.potentialLiabilityAmount!) -
                      parseFloat(values.actualLiabilityAmount!)
                    ).toString()
                  : "0.00"
              }
              label="Savings"
              name="savingsAmount"
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={(e) => {
                handleBlur(e);
                setShouldBlockNavigation(true);
              }}
              error={Boolean(errors.savingsAmount)}
              helperText={
                errors.savingsAmount &&
                touched.savingsAmount &&
                errors.savingsAmount
              }
              fullWidth
              InputProps={{ inputComponent: MoneyField as any }}
              data-cy="claim_information_savingsAmount"
            />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Styles.PaddedTextField
              id="claimantJobTitle"
              size="small"
              margin="none"
              value={values.claimantJobTitle || ""}
              label="Job Title"
              disabled={isClientUser || isReadOnly}
              name="claimantJobTitle"
              onChange={(e) => {
                handleChange(e);
              }}
              onBlur={(e) => {
                handleBlur(e);
                setShouldBlockNavigation(true);
              }}
              error={Boolean(
                getIn(errors, "claimantJobTitle") &&
                  getIn(touched, "claimantJobTitle")
              )}
              helperText={
                getIn(errors, "claimantJobTitle") &&
                getIn(touched, "claimantJobTitle") &&
                getIn(errors, "claimantJobTitle")
              }
              data-cy="claim_claimant_jobTitle"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="claimantJobStatusTypeId"
              options={jobStatusOptions}
              getOptionLabel={(option) => option.name ?? ""}
              disabled={isClientUser || isReadOnly}
              includeInputInList
              value={
                jobStatusOptions.find(
                  (o) => o.id === values.claimantJobStatusTypeId
                ) || null
              }
              autoComplete
              onChange={(_e: any, v: { id: number; name: string } | null) => {
                setFieldValue("claimantJobStatusTypeId", v?.id ?? 0);
              }}
              renderInput={(params) => (
                <Styles.PaddedTextField
                  {...params}
                  id="claimantJobStatusTypeId"
                  size="small"
                  margin="none"
                  disabled={isClientUser || isReadOnly}
                  value={values.claimantJobTitle || ""}
                  label="Job Status"
                  name="claimantJobStatusTypeId"
                  onBlur={(e) => {
                    handleBlur(e);
                    setShouldBlockNavigation(true);
                  }}
                  error={Boolean(
                    getIn(errors, "claimantJobStatusTypeId") &&
                      getIn(touched, "claimantJobStatusTypeId")
                  )}
                  helperText={
                    getIn(errors, "claimantJobStatusTypeId") &&
                    getIn(touched, "claimantJobStatusTypeId") &&
                    getIn(errors, "claimantJobStatusTypeId")
                  }
                  data-cy="claim_information_claimantJobStatusTypeId"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <KeyboardDatePicker
              margin="normal"
              id="dateFirstWorked"
              name="dateFirstWorked"
              label="First Day Worked"
              disabled={isClientUser || isReadOnly}
              format="MM/DD/yyyy"
              value={values.dateFirstWorked || null}
              data-cy="claim_claimant_dateFirstWorked"
              fullWidth
              onChange={(date: MaterialUiPickersDate) =>
                setFieldValue("dateFirstWorked", date)
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4}>
            <KeyboardDatePicker
              margin="normal"
              id="dateLastWorked"
              name="dateLastWorked"
              label="Last Day Worked"
              disabled={isClientUser || isReadOnly}
              format="MM/DD/yyyy"
              value={values.dateLastWorked || null}
              data-cy="claim_claimant_dateLastWorked"
              fullWidth
              onChange={(date: MaterialUiPickersDate) => {
                setFieldValue("dateLastWorked", date);
                compareFirstDayLastDayWorked(moment(date).toDate());
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            {dateWarning ? (
              <Typography variant="body2" style={{ color: "#ff7a00" }}>
                Last Day Worked is before First Day Worked
              </Typography>
            ) : null}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4}>
            <KeyboardDatePicker
              margin="normal"
              id="separationDate"
              name="separationDate"
              label="Separation Date"
              format="MM/DD/yyyy"
              value={values.separationDate || null}
              data-cy="claim_claimant_separationDate"
              fullWidth
              disabled={isClientUser || isReadOnly}
              onChange={(date: MaterialUiPickersDate) =>
                setFieldValue("separationDate", date)
              }
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4}>
            <KeyboardDatePicker
              data-cy="basePeriodStartDate"
              disabled={isClientUser || isReadOnly}
              format="MM/DD/yyyy"
              fullWidth
              id="basePeriodStartDate"
              KeyboardButtonProps={{ "aria-label": "change date" }}
              label="Base Period Start"
              margin="normal"
              name="basePeriodStartDate"
              onChange={(date: MaterialUiPickersDate) =>
                setFieldValue("basePeriodStartDate", date)
              }
              value={values.basePeriodStartDate || null}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={4}>
            <KeyboardDatePicker
              data-cy="basePeriodEndDate"
              disabled={isClientUser || isReadOnly}
              format="MM/DD/yyyy"
              fullWidth
              id="basePeriodEndDate"
              KeyboardButtonProps={{ "aria-label": "change date" }}
              label="Base Period End"
              margin="normal"
              name="basePeriodEndDate"
              onChange={(date: MaterialUiPickersDate) =>
                setFieldValue("basePeriodEndDate", date)
              }
              value={values.basePeriodEndDate || null}
            />
          </Grid>

          <Grid item md={12} lg={4} />

          <FieldArray
            name="claimantPaymentList"
            render={(arrayHelpers) => (
              <Styles.TMGrid item xs={12} container justifyContent="flex-end">
                <div hidden={isReadOnly}>
                  <Styles.FormIcon
                    title="Add to Payments List"
                    color="secondary"
                    onClick={() => {
                      arrayHelpers.push(ClaimantPaymentInit);
                    }}
                    data-cy="employer_details_addBusinessUnit"
                  >
                    <AddBoxRoundedIcon />
                  </Styles.FormIcon>
                </div>
              </Styles.TMGrid>
            )}
          />
          {values.claimantPaymentList &&
            values.claimantPaymentList.map((o, index) => (
              <React.Fragment key={"ClaimantPayments_" + index}>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Style.SelectionTF
                    id={`claimantPaymentList[${index}].separationPayId`}
                    size="small"
                    margin="none"
                    disabled={isClientUser || isReadOnly}
                    select
                    value={o.separationPayId || ""}
                    label="Separation Pay"
                    name={`claimantPaymentList[${index}].separationPayId`}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      setShouldBlockNavigation(true);
                    }}
                    error={Boolean(
                      getIn(
                        errors,
                        `claimantPaymentList[${index}].separationPayId`
                      ) &&
                        getIn(
                          touched,
                          `claimantPaymentList[${index}].separationPayId`
                        )
                    )}
                    helperText={
                      getIn(
                        errors,
                        `claimantPaymentList[${index}].separationPayId`
                      ) &&
                      getIn(
                        touched,
                        `claimantPaymentList[${index}].separationPayId`
                      ) &&
                      getIn(
                        errors,
                        `claimantPaymentList[${index}].separationPayId`
                      )
                    }
                    data-cy="claim_claimant_separationPayType"
                    fullWidth
                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                  >
                    {separationPayTypeOptions
                      .filter((item) => item.name !== "")
                      .map((option, index) => (
                        <MenuItem key={option.name + index} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                  </Style.SelectionTF>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="datePaid"
                    name={`claimantPaymentList[${index}].datePaid`}
                    label="Date Paid"
                    fullWidth
                    disabled={isClientUser || isReadOnly}
                    format="MM/DD/yyyy"
                    value={o.datePaid || null}
                    data-cy="claim_claimant_datePaid"
                    onChange={(date: MaterialUiPickersDate) =>
                      setFieldValue(
                        `claimantPaymentList[${index}].datePaid`,
                        date
                      )
                    }
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={4}>
                  <Style.SelectionTF
                    id="amountPaid"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    size="small"
                    margin="none"
                    disabled={isClientUser || isReadOnly}
                    value={o.amountPaid || ""}
                    label="Amount Paid"
                    name={`claimantPaymentList[${index}].amountPaid`}
                    fullWidth
                    onBlur={(e) => {
                      handleBlur(e);
                      setShouldBlockNavigation(true);
                    }}
                    error={Boolean(
                      getIn(
                        errors,
                        `claimantPaymentList[${index}].amountPaid`
                      ) &&
                        getIn(
                          touched,
                          `claimantPaymentList[${index}].amountPaid`
                        )
                    )}
                    InputProps={{ inputComponent: MoneyField as any }}
                    data-cy="claim_claimant_amountPaid"
                  />
                </Grid>
              </React.Fragment>
            ))}
        </>
      )}
    </Styles.IndentGrid>
  );
};

export default InformationDetails;
