import styled from 'styled-components';

// material ui
import {
  List, ListSubheader, Menu as MuiMenu, Badge, Grid, Drawer as MuiDrawer
} from '@material-ui/core';

export const Sidebar = styled.aside`
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    color: ${props => props.theme.menu.color};
    background: ${props => props.theme.menu.background};
    overflow-x: hidden;
    overflow-y: auto;
`;
export const SidebarHeader = styled.div`
    
    padding: 0 ${props => props.theme.spacing(2)}px;
    ${props => props.theme.mixins.toolbar};
`;

export const NavList = styled(List)`
    width: ${props => props.theme.menu.width}px;
    font-size: 1.1em;
    font-weight: 400;
    line-height: 1.5px;
    letter-spacing: 0.00938em;
`;
export const NavListHeader = styled(ListSubheader)`
    text-align: center;
`;

export const Menu = styled(MuiMenu)`
    > .MuiMenu-paper {
      left: 60px !important;
    }
`;
export const MenuBadge = styled(Badge)`
    > div {
      margin-top: 10px;
    }
    > span.MuiBadge-badge {
      position: relative;
      top: 10px;
    }
`;

// Main 
export const DashboardContainer = styled.div`
    display: flex;
`;
export const HeaderContainer = styled.div`
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    width: 100%;
    z-index: ${props => props.theme.zIndex.drawer + 1};
`;
export const MainSidebar = styled.div <{ issidebarcollapsed: string, shouldcollapse: string }>`
    //top: ${props => props.theme.spacing(2)}px;
    position: relative;
    transition: ${props => props.theme.transitions.create(['width'], {
  easing: props.theme.transitions.easing.sharp,
  duration: props.theme.transitions.duration.leavingScreen,
})};
    width: ${props => props.theme.menu.width}px;
    min-width: ${props => props.theme.menu.width}px;
    ${props => {
  if (props.issidebarcollapsed === 'true' || props.shouldcollapse === 'true') {
      return `width: ${props.theme.menu.widthCollapsed}px; min-width: ${props.theme.menu.widthCollapsed}px;`;
    }
  }};
    @media (max-width: 768px) {
      top: ${props => props.theme.spacing(2)}px;
      height: calc(100vh - ${props => props.theme.spacing(2)}px);
    }
`;
export const Drawer = styled(MuiDrawer) <{ issidebarcollapsed: string, shouldcollapse: string }>`
    > .MuiDrawer-paper {
      width: 100%;
      position: absolute;
      ${props => {
  if (props.issidebarcollapsed === 'true' || props.shouldcollapse === 'true') {
      return `overflow-x: hidden;`;
    }
  }};
    }
`;
export const Main = styled(Grid) <{ issidebarcollapsed: string, shouldcollapse: string }>`
    flex-grow: 1;
    height: calc(100vh - ${props => props.theme.spacing(8)}px);
    over-flow: auto;
    flex-direction: column;
    display: flex;
    max-width: calc(100vw - ${props => props.theme.menu.width}px - ${props => props.theme.spacing(3)}px);
    ${props => {
  if (props.issidebarcollapsed === 'true' || props.shouldcollapse === 'true') {
      return `max-width: calc(100vw - ${props.theme.menu.widthCollapsed}px - ${props.theme.spacing(3)}px);`;
    }
  }};
`;
export const HeaderSpacer = styled.div`
    min-height: ${props => props.theme.spacing(8)}px;
`;