import React from 'react';
//import { useDispatch } from 'react-redux';
//import { Link as RouterLink } from 'react-router-dom';
//import { Formik, Form } from 'formik';
//import * as Yup from 'yup';
//import { CardActions, Grid, Link } from '@material-ui/core';
//import { getType } from 'typesafe-actions';

//icons

//import * as Style from './launchpad.styles';
//import * as Styles from '../../../common/styles/styles';
//import { loginInit } from './launchpad.dtos';
//import { loginUser } from './launchpad.actions';



const Launchpad: React.FC = () => {
  //const dispatch = useDispatch();
  return <div>Launchpad</div>

}

export default Launchpad;