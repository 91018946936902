export interface RateDto {
    id?: string,
    determinedByHealthSystem: boolean,
    billingTypeId: number,
    capAmount?: string,
    quarterlyAdminFee?: string,
    economic?: string,
    nonEconomic?: string,
    standardInvestigations?: string,
    standardHearings?: string,
    standardAppeals?: string,
    capDiscountedInvestigations?: string,
    capDiscountedHearings?: string,
    capDiscountedAppeals?: string,
}

export interface RateWrapperDto {

    id?: string, //Company OR Health System id
    rate: RateDto,
}


//Inits


export const RateInit: RateDto = {

    determinedByHealthSystem: false,
    billingTypeId: 0,

}

export const RateWrapperInit: RateWrapperDto = {

    rate: RateInit
}