import React from 'react'

import styled from 'styled-components';
import Container from '@material-ui/core/Container';

interface PageContainerProps {
  children?: any
}

const container = ({ children }: PageContainerProps) => (
  <Container maxWidth="lg" className="container">
    {children}
  </Container>
);

const StyledContainer = styled(container)`
    flex: 1;
    paddingTop: 32px;
    paddingBottom: 32px;
  `;

const Page = ({ children }: PageContainerProps) => {

  return <StyledContainer>{children}</StyledContainer>
}

export default Page
