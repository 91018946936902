import { AxiosError } from 'axios';
import { ApiError } from './apiResponse';
import apiClient from './apiClient';
import { showNotification } from '../actionCreators/dispatchNotification';

// endpoints
const contactInformationUrl = '/companies/'

export const getContactInformationApi = async (id: string) => {
    let url = contactInformationUrl;
    if (id !== undefined && id !== '00000000-0000-0000-0000-000000000000') {
        url += id + '/contacts';
    }
    return await apiClient().get(url)
        .then(res => {
            return res.data.result;
        })
        .catch(err => {
            if (err && err.response) {
              const axiosError = err as AxiosError<ApiError>;
              if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
            }
            else showNotification("An error occurred while getting contact information.", "error");

            throw err;
        });
};