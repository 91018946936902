


import { ISelectOption } from "../dtos/SelectOptionDto";
enum DischargeReasonCodes {
        Item1 = 0,
        Item2 = 1,
        Item3 = 2,
        Item4 = 3,
        Item5 = 4,
        Item7 = 5,
        Item8 = 6,
        Item9 = 7,
        Item10 = 8,
        Item11 = 9,
        Item12 = 10,
        Item13 = 11,
         
}

export class DischargeReasonCodes_class {

public static enum = DischargeReasonCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['Item1']: 0,
    ['Item2']: 1,
    ['Item3']: 2,
    ['Item4']: 3,
    ['Item5']: 4,
    ['Item7']: 5,
    ['Item8']: 6,
    ['Item9']: 7,
    ['Item10']: 8,
    ['Item11']: 9,
    ['Item12']: 10,
    ['Item13']: 11,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "1- Not Qualified / Inadequate Performance",
    [1]: "2- Absenteeism/Lateness",
    [2]: "3- Failed To Follow Instructions / Policy / Contract",
    [3]: "4- Drugs",
    [4]: "5- Dishonesty",
    [5]: "7- Failed Employment Requirements",
    [6]: "8- Not Listed Above",
    [7]: "9- Alcohol",
    [8]: "10- Theft",
    [9]: "11- Felony/ Misdemeanor, Violation of Law, Criminal, Illegal Acts",
    [10]: "12- Property Damage",
    [11]: "13- Physical/Verbal Altercation",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "1- Not Qualified / Inadequate Performance"},
  {value: 1, text: "2- Absenteeism/Lateness"},
  {value: 2, text: "3- Failed To Follow Instructions / Policy / Contract"},
  {value: 3, text: "4- Drugs"},
  {value: 4, text: "5- Dishonesty"},
  {value: 5, text: "7- Failed Employment Requirements"},
  {value: 6, text: "8- Not Listed Above"},
  {value: 7, text: "9- Alcohol"},
  {value: 8, text: "10- Theft"},
  {value: 9, text: "11- Felony/ Misdemeanor, Violation of Law, Criminal, Illegal Acts"},
  {value: 10, text: "12- Property Damage"},
  {value: 11, text: "13- Physical/Verbal Altercation"},
  ]
}

