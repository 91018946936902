import { getType } from 'typesafe-actions';

import store from '../../app/store';
import { notification } from '../actions/notification.actions';
import { v1 } from 'uuid';


export const showNotification = (message: string, type: string | undefined = undefined) => {
  store.dispatch({
    type: getType(notification),
    payload: {
      message,
      ud: v1(),
      type
    }
  })
};

export const cancelNotification = () => {
  store.dispatch({
    type: getType(notification),
    payload: {
      message: '',
      ud: v1(),
      type: undefined
    }
  })
};