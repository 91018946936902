import React from 'react';
import { EmployerChargeDto } from '../dtos';
import * as Styles from '../../../common/styles/styles';
import { Card, Grid, Typography } from '@material-ui/core';

interface UAChargeProps {
    EmployerCharge: EmployerChargeDto,
    index: number
}

const UnemploymentChargesCard: React.FC<UAChargeProps> = (props: UAChargeProps) => {
    const { EmployerCharge, index } = props;
    return <Grid key={'grid-' + index} item xs={12} data-cy={'employer_EmployerCharge_card_' + index}>
        <Card key={'card-' + index}>
            <Styles.CardContent1 key={'cardContent-' + index}>
                <Grid container spacing={0} key={'gridContainer-' + index} >
                    <Grid item xs={3} key={'year-' + index}>
                        <Typography variant="subtitle1" color="primary">{EmployerCharge.year}</Typography>
                    </Grid>

                    <Grid item xs={3} key={'count-' + index}>
                        <Typography variant="subtitle1" color="primary">${EmployerCharge.charge}</Typography>
                    </Grid>

                </Grid>
            </Styles.CardContent1>
        </Card>
    </Grid>
}

export default UnemploymentChargesCard;
