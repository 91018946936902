import { FormControl, FormControlLabel, FormHelperText, Grid } from '@material-ui/core';
import { FormikErrors, FormikHandlers, FormikHelpers, getIn } from 'formik';
import React from 'react';
import Label from '../../../../common/components/label';
import { ISi } from '../../../../common/dtos/SiDto';
import { FrequencyCodes_class } from '../../../../common/enums/FrequencyCodes';
import { IndentGrid, MHARadio } from '../../../../common/styles/styles';

interface Props {
  label: string;
  name: string;
  value: number | undefined;
  errors: FormikErrors<ISi>;
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ISi>["setFieldValue"];
  isReadOnly: boolean;
}

const Pension401KFrequencyCodeField: React.FC<Props> = ({ label, name, value, errors, handleBlur, setFieldValue, isReadOnly }) => {

  return (
    <>
      <Grid item xs={12}>
        <Label>{label}</Label>
      </Grid>
      <IndentGrid container direction="row">
        <FormControl error={Boolean(getIn(errors, name))} >
          <IndentGrid container direction="row">
            <FormControlLabel
              checked={value === FrequencyCodes_class.enum.W}
              value={value}
              data-cy={`sidesResponse_${name}_Weekly`}
              onChange={() => setFieldValue(name, FrequencyCodes_class.enum.W)}
              onBlur={handleBlur}
              control={<MHARadio disabled={isReadOnly} size="small" name={name} />}
              label="Weekly" />
            <FormControlLabel
              checked={value === FrequencyCodes_class.enum.B}
              value={value}
              data-cy={`sidesResponse_${name}_Bi-weekly`}
              onChange={() => setFieldValue(name, FrequencyCodes_class.enum.B)}
              onBlur={handleBlur}
              control={<MHARadio disabled={isReadOnly} name={name} size="small" />}
              label="Bi-weekly" />
            <FormControlLabel
              checked={value === FrequencyCodes_class.enum.M}
              value={value}
              data-cy={`sidesResponse_${name}_Monthly`}
              onChange={() => setFieldValue(name, FrequencyCodes_class.enum.M)}
              onBlur={handleBlur}
              control={<MHARadio disabled={isReadOnly} name={name} size="small" />}
              label="Monthly" />
            <FormControlLabel
              checked={value === FrequencyCodes_class.enum.Q}
              value={value}
              data-cy={`sidesResponse_${name}_Quarterly`}
              onChange={() => setFieldValue(name, FrequencyCodes_class.enum.Q)}
              onBlur={handleBlur}
              control={<MHARadio disabled={isReadOnly} name={name} size="small" />}
              label="Quarterly" />
            <FormControlLabel
              checked={value === FrequencyCodes_class.enum.L}
              value={value}
              data-cy={`sidesResponse_${name}_Lumpsum`}
              onChange={() => setFieldValue(name, FrequencyCodes_class.enum.L)}
              onBlur={handleBlur}
              control={<MHARadio disabled={isReadOnly} name={name} size="small" />}
              label="Lump sum" />
            <FormControlLabel
              checked={value === FrequencyCodes_class.enum.D}
              value={value}
              data-cy={`sidesResponse_${name}_Daily`}
              onChange={() => setFieldValue(name, FrequencyCodes_class.enum.D)}
              onBlur={handleBlur}
              control={<MHARadio disabled={isReadOnly} name={name} size="small" />}
              label="Daily" />
            <FormControlLabel
              checked={value === FrequencyCodes_class.enum.S}
              value={value}
              data-cy={`sidesResponse_${name}_Semi-monthly`}
              onChange={() => setFieldValue(name, FrequencyCodes_class.enum.S)}
              onBlur={handleBlur}
              control={<MHARadio disabled={isReadOnly} name={name} size="small" />}
              label="Semi-monthly" />
            <FormControlLabel
              checked={value === FrequencyCodes_class.enum.Z}
              value={value}
              data-cy={`sidesResponse_${name}_Z`}
              onChange={() => setFieldValue(name, FrequencyCodes_class.enum.Z)}
              onBlur={handleBlur}
              control={<MHARadio disabled={isReadOnly} name={name} size="small" />}
              label="Not Able to Provide" />
            <FormControlLabel
              checked={value === undefined}
              value={value}
              data-cy={`sidesResponse_${name}_undefined`}
              onChange={() => setFieldValue(name, undefined)}
              onBlur={handleBlur}
              control={<MHARadio disabled={isReadOnly} name={name} size="small" />}
              label="N/A" />
          </IndentGrid>
          {Boolean(getIn(errors, name)) &&
            <FormHelperText
            id={`${name}-helper_text`}
          >
              {getIn(errors, name)}
            </FormHelperText>
          }
        </FormControl>
      </IndentGrid>
    </>
  );

}

export default Pension401KFrequencyCodeField;