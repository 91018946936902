export const functionOptions = [
  //{ name: 'Please select', id: 0 },
  { name: 'Initial Claims Contact', id: 1 },
  { name: 'Alternative Claims Contact', id: 2 },
  { name: 'Hearing Contact', id: 3 },
  { name: 'Forwarding Mail', id: 4 },
  { name: 'POA Contact', id: 5 },
  { name: 'Law Changes Contact', id: 6 },
  { name: 'Billing Contact', id: 7 },
  { name: 'HR Conference', id: 8 },
  //{ name: 'MHA', id: 9 },
  { name: 'Hearing Representative', id: 10 }
];

export const jobStatusOptions = [
  { name: '', id: 0 },
  { name: 'Full-time', id: 1 },
  { name: 'Part-time', id: 2 },
  { name: 'On-call/PRN', id: 3 },
  { name: 'Seasonal', id: 4 },
  { name: 'Temporary', id: 5 },

];

export const documentTypeOptions = [
  { name: '', id: 0 },
  { name: 'Monetary notice', id: 1 },
  { name: 'State fact-finding form', id: 2 },
  { name: 'SIDES', id: 3 },
  { name: 'Transmittal to State', id: 4 },
  { name: 'Information from Client', id: 5 },
  { name: 'Wage Data', id: 6 },
  { name: 'Decision', id: 7 },
  { name: 'Notice of Fact-Finding Interview', id: 8 },
  { name: 'Hearing Notice', id: 9 },
  { name: 'Hearing Decision', id: 10 },
  { name: 'Board Decision', id: 11 },
  { name: 'Circuit Court Decision', id: 12 },
  { name: 'Attorney notes from hearing', id: 13 },
  { name: 'Appellate Documents', id: 14 },
  { name: 'Claim General Documents', id: 15 },
  { name: 'Client Communications', id: 16 },
  { name: 'Charge Protest', id: 17 },
  { name: 'General', id: 18 },
  { name: 'NoticeOfAppeal', id: 19 },
];

export const documentStatusOptions = [
  { name: '', id: 0 },
  { name: 'New Mail', id: 1 },
  { name: 'Waiting on Client', id: 2 },
  { name: 'Needs Reviewed', id: 3 },
  { name: 'Ready to Protest', id: 4 },
  { name: 'No Protest Needed', id: 5 },
  { name: 'Decision', id: 6 },
  { name: 'Confirmation Pending', id: 7 },
  { name: 'Needs checked', id: 8 },
  { name: 'New Decision', id: 9 },
  { name: 'Hearing Scheduled', id: 10 },
  { name: 'Waiting on State Action', id: 11 },
  { name: 'Waiting on Assigned Rep', id: 12 },
  { name: 'To be assigned', id: 13 },
  { name: 'Appeal Pending', id: 14 },


];


