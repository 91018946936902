import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getType } from 'typesafe-actions';
import { Grid, Card, Typography, Box } from '@material-ui/core';
import { Prompt } from 'react-router';
import { showNotification } from '../../../common/actionCreators/dispatchNotification';
import NumberField from '../../../common/components/numberField';
import { rootSelector } from '../../../common/selectors/selectors';
import { updateCompanyActions, createCompanyActions } from '../actions';
import { companyInit, EmployeeCountDto, CompanyDto } from '../dtos';
import EmployeeCountCard from './employeeCountCard';
import { isReadOnly } from '../../../common/dtos/auth';
import { requiredError } from '../../../common/constants/strings';
import { FeatureTypeEnum } from '../../../common/enums/featureTypeEnum';
import { CardContent1, TextNumberField } from '../../../common/styles/styles';
import { AddButton } from '../styles';

const EmployeeCounts: React.FC = () => {
  const dispatch = useDispatch();

  const systemCompany = rootSelector((state) => state.companies.company);

  const [dto, setDto] = React.useState<CompanyDto>(
    JSON.parse(JSON.stringify(companyInit))
  );
  const [formDto, setFormDto] = React.useState<{
    year: number | undefined;
    count: number | undefined;
  }>({ year: undefined, count: undefined });
  const [shouldBlockNavigation, setShouldBlockNavigation] =
    React.useState<boolean>(false);
  const [maxYear, setMaxYear] = React.useState<number>(0);
  const [minYear, setMinYear] = React.useState<number>(0);

  React.useEffect(() => {
    setDto(JSON.parse(JSON.stringify(systemCompany)));
    setShouldBlockNavigation(false);
    setFormDto({ year: undefined, count: undefined });
  }, [systemCompany]);

  React.useEffect(() => {
    let year = new Date().getFullYear();
    setMaxYear(year);
    setMinYear(year - 100);
  }, []);

  return (
    <>
      <Box p={1}>
        <Grid container data-cy='employer_employeeCount_container' spacing={2}>
          <Formik
            initialValues={formDto}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              count: Yup.number().min(0).required(requiredError),
              year: Yup.number()
                .min(minYear)
                .max(maxYear)
                .required(requiredError),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                setSubmitting(false);
              }, 750);

              if (
                dto.companyId === undefined ||
                dto.companyId.length === 0 ||
                dto.name.length === 0 ||
                dto.fein === undefined ||
                dto.fein.length === 0 ||
                dto.suta === undefined ||
                dto.suta.length === 0
              ) {
                showNotification(
                  'Please save employer details before attempting to add to employee count.'
                );
              } else {
                let dtoToSave = dto;
                dtoToSave.emails = dtoToSave.emails.filter(
                  (e) => e.emailAddress.length !== 0
                );
                dtoToSave.phones = dtoToSave.phones.filter(
                  (p) => p.phoneNumber.length !== 0
                );
                dtoToSave.notes = dtoToSave.notes.filter(
                  (n) => n.entry.length !== 0
                );
                dtoToSave.addresses = dtoToSave.addresses.filter(
                  (a) =>
                    a.address1 !== undefined &&
                    a.address1!.length !== 0 &&
                    a.city !== undefined &&
                    a.city!.length !== 0
                );
                dtoToSave.businessUnitList = dtoToSave.businessUnitList.filter(
                  (b) => b.name.length !== 0
                );
                dtoToSave.employeeCountList =
                  dtoToSave.employeeCountList.filter((e) => e.year !== 0);
                dtoToSave.employerChargeList =
                  dtoToSave.employerChargeList.filter((c) => c.year !== 0);

                let dtoIndex = dtoToSave.employeeCountList.findIndex(
                  (f) => f.year === +values.year!
                );

                if (dtoIndex === -1) {
                  let newEmployeeCount: EmployeeCountDto = {
                    year: values.year ?? 0,
                    count: values.count ?? 0,
                  };

                  dtoToSave.employeeCountList.push(newEmployeeCount);
                } else {
                  dtoToSave.employeeCountList[dtoIndex].count =
                    values.count ?? 0;
                }

                dispatch({
                  type: dtoToSave.companyId
                    ? getType(updateCompanyActions.request)
                    : getType(createCompanyActions.request),
                  payload: dtoToSave,
                });

                resetForm();
              }
            }}
            enableReinitialize={true}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleSubmit,
                handleBlur,
                isSubmitting,
              } = props;
              return (
                <Grid item xs={12}>
                  <Card>
                    <CardContent1>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={3}
                          hidden={isReadOnly(FeatureTypeEnum.Employers)}
                        >
                          <TextNumberField
                            size='small'
                            margin='none'
                            InputLabelProps={{ required: true }}
                            value={values.count || ''}
                            label='Number of Employees'
                            name='count'
                            onBlur={handleBlur}
                            error={Boolean(errors.count)}
                            helperText={
                              errors.count && touched.count && errors.count
                            }
                            InputProps={{ inputComponent: NumberField as any }}
                            onChange={(e) => {
                              handleChange(e);
                              setShouldBlockNavigation(true);
                            }}
                            data-cy='employer_employeeCount_countInput'
                          />
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          hidden={isReadOnly(FeatureTypeEnum.Employers)}
                        >
                          <TextNumberField
                            size='small'
                            margin='none'
                            InputLabelProps={{ required: true }}
                            value={values.year || ''}
                            label='Year'
                            name='year'
                            onBlur={handleBlur}
                            error={Boolean(errors.year)}
                            helperText={
                              errors.year && touched.year && errors.year
                            }
                            InputProps={{ inputComponent: NumberField as any }}
                            inputProps={{ showSeparator: false }}
                            onChange={(e) => {
                              handleChange(e);
                              setShouldBlockNavigation(true);
                            }}
                            data-cy='employer_employeeCount_yearInput'
                          />
                        </Grid>

                        <Grid
                          item
                          xs={3}
                          hidden={isReadOnly(FeatureTypeEnum.Employers)}
                        >
                          <AddButton
                            type='button'
                            data-cy='employer_employeeCount_addButton'
                            variant='contained'
                            color='primary'
                            disabled={
                              isSubmitting ||
                              isReadOnly(FeatureTypeEnum.Employers)
                            }
                            onClick={() => handleSubmit()}
                          >
                            Add
                          </AddButton>
                        </Grid>
                      </Grid>
                    </CardContent1>
                  </Card>
                </Grid>
              );
            }}
          </Formik>

          {dto.employeeCountList &&
          dto.employeeCountList.filter((e) => e.year !== 0).length > 0 ? (
            dto.employeeCountList
              .filter((e) => e.year !== 0)
              .sort((a, b) => {
                return a.year === b.year ? 0 : a.year! < b.year! ? 1 : -1;
              })
              .map((o, index) => (
                <EmployeeCountCard
                  employeeCount={o}
                  index={index}
                  key={'employer_employeeCount_card_' + index}
                />
              ))
          ) : (
            <Grid item xs={12}>
              <Card>
                <CardContent1>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Typography variant='subtitle1' color='primary'>
                        No Records at this time.
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent1>
              </Card>
              <br />
            </Grid>
          )}
          <Prompt
            when={shouldBlockNavigation}
            message='You have unsaved edits, are you sure you want to leave?'
          />
        </Grid>
      </Box>
    </>
  );
};

export default EmployeeCounts;
