import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors, } from 'formik';

// material ui
import { Grid, TextField } from '@material-ui/core';
import { ClientQuestionnaireDto } from '../../dto';

interface LaborDisputeComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setShouldBlockNavigation: (o: boolean) => void;
}

const LaborDisputeComponent: React.FC<LaborDisputeComponentProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setShouldBlockNavigation
  }) => {

  return (
    <React.Fragment>
      <Grid item xs={12}>Please describe the situation leading to the labor dispute. (strike/lock-out) </Grid>
      <Grid item xs={4}>
        <TextField
          id="laborDisputeSituation"
          size="small"
          margin="none"
          value={values.laborDisputeSituation || ''}
          multiline
          label="Job Title"
          name="laborDisputeSituation"
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.laborDisputeSituation)}
          helperText={errors.laborDisputeSituation && errors.laborDisputeSituation}
          data-cy="clientQuestionnaire_laborDisputeSituation"
          aria-label="Situation surrounding labor dispute"
          fullWidth
        />
      </Grid>
      <br />

    </React.Fragment>
  )
}

export default LaborDisputeComponent;
