import React from 'react';
// material ui
import { Card, CardHeader } from '@material-ui/core';
import { ClientQuestionnaireDto } from '../dto';


interface Props extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
}

const MhaAdditionalInformationCard: React.FC<Props> = (
  {
    values,
  }) => {

  return (
    <Card>
      <CardHeader
        title="Additional Information from MHA"
        titleTypographyProps={{ variant: 'h6' }}
        subheader={values.additionalQuestionnaireInformation}
      ></CardHeader>
    </Card>
  )
}

export default MhaAdditionalInformationCard;
