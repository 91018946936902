import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getType } from 'typesafe-actions';
import { Prompt, useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import MoneyField from '../../../common/components/numberFields';

// material ui
import { Grid, TextField, Box, MenuItem, Checkbox } from '@material-ui/core';

import { rootSelector } from '../../../common/selectors/selectors';
import * as Styles from '../../../common/styles/styles';

import { getCompanyRateActions, updateCompanyRateActions, createCompanyRateActions } from '../actions';

import { RateDto, RateInit, RateWrapperDto, RateWrapperInit } from '../../../common/dtos/billing';

import { isReadOnly } from '../../../common/dtos/auth';
import Label from '../../../common/components/label';
import BottomActionFormBar from '../../../common/components/bottomActionFormBar';
import { requiredError } from '../../../common/constants/strings';
import { FeatureTypeEnum } from '../../../common/enums/featureTypeEnum';


const billingTypeOptions = [

  { id: 1, name: 'Fee for Service' },
  { id: 2, name: 'Shared Service' },
  { id: 3, name: 'Trinity Contract' },
  { id: 4, name: 'Other' },
]



const CompanyBilling: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { employerId } = useParams<{ employerId: string }>();


  const systemRateWrapper = rootSelector(state => state.companies.rate);
  const [dto, setDto] = React.useState(RateInit as RateDto);
  const [shouldBlockNavigation, setShouldBlockNavigation] = React.useState(false);
  const [savingInput, setSavingInput] = React.useState(false);
  const [readOnlyStatus, setReadOnlyStatus] = React.useState(false);

  React.useEffect(() => {
    let s = isReadOnly(FeatureTypeEnum.Employers);
    setReadOnlyStatus(s);
  }, [readOnlyStatus]);

  React.useEffect(() => {
    dispatch({ type: getType(getCompanyRateActions.request), payload: { id: employerId ?? '', rate: RateInit } });
  }, [dispatch]);

  React.useEffect(() => {
    let newRate = JSON.parse(JSON.stringify(systemRateWrapper.rate)) as RateDto;
    if (newRate.id === undefined || newRate.id.length === 0) { newRate.determinedByHealthSystem = true; }

    setDto(newRate);
    setShouldBlockNavigation(false);
  }, [systemRateWrapper]);



  const handleCancel = () => {
    history.push("/employers");
  }

  return (
    <>
      <Formik initialValues={dto}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object().shape({
          determinedByHealthSystem: Yup.boolean(),
          billingTypeId: Yup.number().required(requiredError).max(4, 'Please choose a valid option').nullable(),
          capAmount: Yup.string().required('Required').max(15, 'Must be 15 characters or less'),
          quarterlyAdminFee: Yup.string().max(15, 'Must be 15 characters or less').nullable(),
          economic: Yup.string().max(15, 'Must be 15 characters or less').nullable(),
          nonEconomic: Yup.string().max(15, 'Must be 15 characters or less').nullable(),
          standardInvestigations: Yup.string().max(15, 'Must be 15 characters or less').nullable(),
          standardHearings: Yup.string().max(15, 'Must be 15 characters or less').nullable(),
          standardAppeals: Yup.string().max(15, 'Must be 15 characters or less').nullable(),
          capDiscountedInvestigations: Yup.string().max(15, 'Must be 15 characters or less').nullable(),
          capDiscountedHearings: Yup.string().max(15, 'Must be 15 characters or less').nullable(),
          capDiscountedAppeals: Yup.string().max(15, 'Must be 15 characters or less').nullable(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => { setSubmitting(false); setSavingInput(false); }, 700);
          setSavingInput(true);

          let newDto = { id: employerId, rate: values } as RateWrapperDto;

          dispatch({
            type: values.id ?
              getType(updateCompanyRateActions.request) :
              getType(createCompanyRateActions.request),
            payload: newDto
          });
        }}
        enableReinitialize={true}
      >
        {(props) => {
          const { values, touched, errors, handleChange, handleSubmit, handleBlur } = props;
          return (
            <form onSubmit={handleSubmit} data-cy="employer_billing_form">
              <Box p={2}>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" >
                    <Grid item xs={1}>
                      <Checkbox id="determinedByHealthSystem"
                        name="determinedByHealthSystem"
                        onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
                        onBlur={handleBlur}
                        value={values.determinedByHealthSystem}
                        checked={values.determinedByHealthSystem}
                        disabled={readOnlyStatus}
                        size="small" data-cy="contact_detailsViewActiveOnly" />

                    </Grid>
                    <Grid item xs={11}><Label style={{ marginLeft: -80 }}>Determined By Health System</Label></Grid>
                  </Grid>


                  <Grid container item xs={3} spacing={2}>
                    <Grid item xs={10}>
                      <Label>Default Rates</Label>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        id="billingTypeId"
                        size="small"
                        margin="none"
                        value={values.billingTypeId || 0}
                        InputLabelProps={{ required: true }}
                        label="Billing Type"
                        name="billingTypeId"
                        select
                        disabled={readOnlyStatus || values.determinedByHealthSystem}
                        onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
                        onBlur={handleBlur}
                        error={(Boolean(errors.billingTypeId && touched.billingTypeId))}
                        helperText={(errors.billingTypeId && touched.billingTypeId) && errors.billingTypeId}
                        data-cy="employer_details_billingTypeId"
                        fullWidth
                        SelectProps={{ MenuProps: { disableScrollLock: true } }}
                      >
                        {billingTypeOptions.filter((item) => item.name !== '')
                          .map((option, index) => (
                            <MenuItem key={option.name + index} value={option.id}>{option.name}</MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        id="capAmount"
                        size="small"
                        margin="none"
                        value={values.capAmount || ''}
                        InputLabelProps={{ required: true }}
                        label="Cap Amount"
                        name="capAmount"
                        disabled={readOnlyStatus || values.determinedByHealthSystem}
                        onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
                        onBlur={handleBlur}
                        error={(Boolean(errors.capAmount && touched.capAmount))}
                        helperText={(errors.capAmount && touched.capAmount) && errors.capAmount}
                        InputProps={{ inputComponent: MoneyField as any }}

                        data-cy="employer_details_capAmount"
                        fullWidth

                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Styles.PaddedBottomTextField
                        id="quarterlyAdminFee"
                        size="small"
                        margin="none"
                        value={values.quarterlyAdminFee || ''}
                        InputLabelProps={{ required: true }}
                        label="Quarterly Admin Fee"
                        name="quarterlyAdminFee"
                        disabled={readOnlyStatus || values.determinedByHealthSystem} 
                        onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
                        onBlur={handleBlur}
                        error={(Boolean(errors.quarterlyAdminFee && touched.quarterlyAdminFee))}
                        helperText={(errors.quarterlyAdminFee && touched.quarterlyAdminFee) && errors.quarterlyAdminFee}
                        InputProps={{ inputComponent: MoneyField as any }}

                        data-cy="employer_details_quarterlyAdminFee"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Label>Standard Rates</Label>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        id="economic"
                        size="small"
                        margin="none"
                        value={values.economic || ''}
                        InputLabelProps={{ required: true }}
                        label="Economic"
                        name="economic"
                        disabled={readOnlyStatus || values.determinedByHealthSystem}
                        onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
                        onBlur={handleBlur}
                        error={(Boolean(errors.economic && touched.economic))}
                        helperText={(errors.economic && touched.economic) && errors.economic}
                        InputProps={{ inputComponent: MoneyField as any }}

                        data-cy="employer_details_economic"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        id="nonEconomic"
                        size="small"
                        margin="none"
                        value={values.nonEconomic || ''}
                        InputLabelProps={{ required: true }}
                        label="Non-Economic"
                        name="nonEconomic"
                        disabled={readOnlyStatus || values.determinedByHealthSystem}
                        onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
                        onBlur={handleBlur}
                        error={(Boolean(errors.nonEconomic && touched.nonEconomic))}
                        helperText={(errors.nonEconomic && touched.nonEconomic) && errors.nonEconomic}
                        InputProps={{ inputComponent: MoneyField as any }}

                        data-cy="employer_details_nonEconomic"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        id="standardInvestigations"
                        size="small"
                        margin="none"
                        value={values.standardInvestigations || ''}
                        InputLabelProps={{ required: true }}
                        label="Investigations"
                        name="standardInvestigations"
                        disabled={readOnlyStatus || values.determinedByHealthSystem}
                        onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
                        onBlur={handleBlur}
                        error={(Boolean(errors.standardInvestigations && touched.standardInvestigations))}
                        helperText={(errors.standardInvestigations && touched.standardInvestigations) && errors.standardInvestigations}
                        InputProps={{ inputComponent: MoneyField as any }}

                        data-cy="employer_details_standardInvestigations"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        id="standardHearings"
                        size="small"
                        margin="none"
                        value={values.standardHearings || ''}
                        InputLabelProps={{ required: true }}
                        label="Hearings"
                        name="standardHearings"
                        disabled={readOnlyStatus || values.determinedByHealthSystem}
                        onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
                        onBlur={handleBlur}
                        error={(Boolean(errors.standardHearings && touched.standardHearings))}
                        helperText={(errors.standardHearings && touched.standardHearings) && errors.standardHearings}
                        InputProps={{ inputComponent: MoneyField as any }}

                        data-cy="employer_details_standardHearings"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={10}>
                      <Styles.PaddedBottomTextField
                        id="standardAppeals"
                        size="small"
                        margin="none"
                        value={values.standardAppeals || ''}
                        InputLabelProps={{ required: true }}
                        label="Board Appeals"
                        name="standardAppeals"
                        disabled={readOnlyStatus || values.determinedByHealthSystem}
                        onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
                        onBlur={handleBlur}
                        error={(Boolean(errors.standardAppeals && touched.standardAppeals))}
                        helperText={(errors.standardAppeals && touched.standardAppeals) && errors.standardAppeals}
                        InputProps={{ inputComponent: MoneyField as any }}
                        data-cy="employer_details_standardAppeals"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Label>Cap Discounted Rates</Label>
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        id="capDiscountedInvestigations"
                        size="small"
                        margin="none"
                        value={values.capDiscountedInvestigations || ''}
                        InputLabelProps={{ required: true }}
                        label="Investigations"
                        name="capDiscountedInvestigations"
                        disabled={readOnlyStatus || values.determinedByHealthSystem}
                        onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
                        onBlur={handleBlur}
                        error={(Boolean(errors.capDiscountedInvestigations && touched.capDiscountedInvestigations))}
                        helperText={(errors.capDiscountedInvestigations && touched.capDiscountedInvestigations) && errors.capDiscountedInvestigations}
                        InputProps={{ inputComponent: MoneyField as any }}
                        data-cy="employer_details_capDiscountedInvestigations"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <TextField
                        id="capDiscountedHearings"
                        size="small"
                        margin="none"
                        value={values.capDiscountedHearings || ''}
                        InputLabelProps={{ required: true }}
                        label="Hearings"
                        name="capDiscountedHearings"
                        disabled={readOnlyStatus || values.determinedByHealthSystem}
                        onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
                        onBlur={handleBlur}
                        error={(Boolean(errors.capDiscountedHearings && touched.capDiscountedHearings))}
                        helperText={(errors.capDiscountedHearings && touched.capDiscountedHearings) && errors.capDiscountedHearings}
                        InputProps={{ inputComponent: MoneyField as any }}
                        data-cy="employer_details_capDiscountedHearings"
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={10}>
                      <Styles.PaddedBottomTextField
                        id="capDiscountedAppeals"
                        size="small"
                        margin="none"
                        value={values.capDiscountedAppeals || ''}
                        InputLabelProps={{ required: true }}
                        label="Board Appeals"
                        name="capDiscountedAppeals"
                        disabled={readOnlyStatus || values.determinedByHealthSystem}
                        onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
                        onBlur={handleBlur}
                        error={(Boolean(errors.capDiscountedAppeals && touched.capDiscountedAppeals))}
                        helperText={(errors.capDiscountedAppeals && touched.capDiscountedAppeals) && errors.capDiscountedAppeals}
                        InputProps={{ inputComponent: MoneyField as any }}
                        data-cy="employer_details_capDiscountedAppeals"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <BottomActionFormBar cancelClickHandler={handleCancel} submitting={savingInput} featureType={FeatureTypeEnum.Employers} />
              <Prompt
                when={shouldBlockNavigation}
                message={() => {
                  return 'You have unsaved edits, are you sure you want to leave?';
                }}
              />
            </form>
          )
        }}
      </Formik>
    </>
  )
}
export default CompanyBilling;