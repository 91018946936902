import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { getContactInformationApi } from '../api/contactInformation';
import { getContactInformationActions } from '../actions/contactInformation.actions';
import { ContactInformationDto } from '../dtos/contactInformation';

function* handleGetContactInformation(request: ReturnType<typeof getContactInformationActions.request>) {
  const contactInformationList: ContactInformationDto[] = yield select(state => state.contactInformation.contactInformationList);
  const contactInformationEmployerId: string | undefined = contactInformationList[0]?.employerId;
  const contactInformation: ContactInformationDto[] = contactInformationEmployerId !== undefined && contactInformationEmployerId === request.payload
    ? contactInformationList : yield call(getContactInformationApi, request.payload);

  yield put({ type: getType(getContactInformationActions.success), payload: contactInformation });
}

export function* watchContactInformationGetRequest() {
  try {
    yield takeLatest(getType(getContactInformationActions.request), handleGetContactInformation);
  }
  catch (err) {
  }
}