import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import * as serviceWorker from "./assets/serviceWorker";

import WebFont from "webfontloader";
WebFont.load({
  google: {
    families: ["Roboto:300,400,500,700,900", "Arial:300,400,500,700,900"],
  },
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
