import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { getTaskTypesWorkOrderSettingsActions, updateTaskTypesWorkOrderSettingsActions } from './workOrders.actions';
import { TaskTypesWorkOrderSettingsDto, taskTypesWorkOrderSettingsInit } from './workOrders.dtos';

const taskTypesWorkOrderSettingsReducer = combineReducers({
  taskTypeWorkOrderSettings: createReducer([] as TaskTypesWorkOrderSettingsDto[])
    .handleAction(
      [
        getTaskTypesWorkOrderSettingsActions.success,
        updateTaskTypesWorkOrderSettingsActions.request,
        updateTaskTypesWorkOrderSettingsActions.success,
      ], (state, action) => action.payload),

  getTaskTypeWorkOrderSettings: createReducer(taskTypesWorkOrderSettingsInit)
    .handleAction(
      [
        getTaskTypesWorkOrderSettingsActions.request,
      ], (state, action) => state),
});

export default taskTypesWorkOrderSettingsReducer;