import axios, { AxiosInstance } from 'axios';
import { getType } from 'typesafe-actions';

import store from '../../app/store';
import { getToken } from '../dtos/auth';
import { spinnerStart, spinnerStop } from '../actions/spinner.actions';
import { logoutActions } from '../../features/account/login/login.actions';
import { showNotification } from '../actionCreators/dispatchNotification';


export const startSpinner = () => {
  store.dispatch({ type: getType(spinnerStart) });
}

export const stopSpinner = () => {
  store.dispatch({ type: getType(spinnerStop) });
}

function apiClient(): AxiosInstance {
  let client = axios.create({
    baseURL: "/api/",
    headers: {
      'Content-Type': 'application/json'//,
    },
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
  });

  client.interceptors.request.use(async (config) => {
    await startSpinner();
    const authToken = getToken();
    config.headers.Authorization = `Bearer ${authToken}`;

    return config;
  }, error => {
    return Promise.reject(error);
  });

  client.interceptors.response.use(
    async response => {
      if (response.status === 401) {
        store.dispatch({ type: getType(logoutActions.request) });
        showNotification('Your session timed out.', 'error');
      }
      setTimeout(async () => { await stopSpinner(); }, 500);
      return response
    },
    async error => {
      if (String(error).includes('401')) {
        store.dispatch({ type: getType(logoutActions.request) });
        showNotification('Your session timed out.', 'error');
      }
      await stopSpinner();
      return Promise.reject(error);
    }
  );

  return client;
}



export default apiClient;
