import { createAsyncAction, createAction } from 'typesafe-actions';
import { EligibilityCodeDto, SeparationCodeDto, LocationCodeDto, CodesDto } from './codes.dtos';

export const getCodesActions = createAsyncAction(
  'GET_CODES_REQUEST',
  'GET_CODES_SUCCESS',
  'GET_CODES_ERROR',
)<undefined, CodesDto, string>();

export const getEligibilityCodesActions = createAsyncAction(
  'GET_ELIGIBILITY_CODES_REQUEST',
  'GET_ELIGIBILITY_CODES_SUCCESS',
  'GET_ELIGIBILITY_CODES_ERROR',
)<undefined, EligibilityCodeDto[], string>();

export const getSeparationCodesActions = createAsyncAction(
  'GET_SEPARATION_CODES_REQUEST',
  'GET_SEPARATION_CODES_SUCCESS',
  'GET_SEPARATION_CODES_ERROR',
)<undefined, SeparationCodeDto[], string>();

export const getLocationCodesActions = createAsyncAction(
  'GET_LOCATION_CODES_REQUEST',
  'GET_LOCATION_CODES_SUCCESS',
  'GET_LOCATION_CODES_ERROR',
)<undefined, LocationCodeDto[], string>();


export const updateEligibilityCodesActions = createAsyncAction(
  'UPDATE_ELIGIBILITY_CODES_REQUEST',
  'UPDATE_ELIGIBILITY_CODES_SUCCESS',
  'UPDATE_ELIGIBILITY_CODES_ERROR',
)<EligibilityCodeDto[], EligibilityCodeDto[], string>();

export const createEligibilityCodesActions = createAsyncAction(
  'CREATE_ELIGIBILITY_CODES_REQUEST',
  'CREATE_ELIGIBILITY_CODES_SUCCESS',
  'CREATE_ELIGIBILITY_CODES_ERROR',
)<EligibilityCodeDto[], EligibilityCodeDto[], string>();


export const updateSeparationCodesActions = createAsyncAction(
  'UPDATE_SEPARATION_CODES_REQUEST',
  'UPDATE_SEPARATION_CODES_SUCCESS',
  'UPDATE_SEPARATION_CODES_ERROR',
)<SeparationCodeDto[], SeparationCodeDto[], string>();

export const createSeparationCodesActions = createAsyncAction(
  'CREATE_SEPARATION_CODES_REQUEST',
  'CREATE_SEPARATION_CODES_SUCCESS',
  'CREATE_SEPARATION_CODES_ERROR',
)<SeparationCodeDto[], SeparationCodeDto[], string>();

export const updateLocationCodesActions = createAsyncAction(
  'UPDATE_LOCATION_CODES_REQUEST',
  'UPDATE_LOCATION_CODES_SUCCESS',
  'UPDATE_LOCATION_CODES_ERROR',
)<LocationCodeDto[], LocationCodeDto[], string>();

export const createLocationCodesActions = createAsyncAction(
  'CREATE_LOCATION_CODES_REQUEST',
  'CREATE_LOCATION_CODES_SUCCESS',
  'CREATE_LOCATION_CODES_ERROR',
)<LocationCodeDto[], LocationCodeDto[], string>();




