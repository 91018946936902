import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors, } from 'formik';

// material ui
import { Grid, TextField, MenuItem, FormControlLabel, } from '@material-ui/core';
import { ClientQuestionnaireDto, } from '../../dto';
import { PaddedTextField, MHARadio } from '../../../../common/styles/styles';
import { dischargeNotificationOptions, radioButtonBooleanOptions, lossOfLicenseIndicatorOptions, indicatorOptions } from '../../constants/options';


interface LossOfLicenseComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
}

const LossOfLicenseComponent: React.FC<LossOfLicenseComponentProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation
  }) => {

  return (
    <React.Fragment>
      <Grid item xs={12}>What were the claimant's job duties?</Grid>
      <Grid item xs={4}>
        <TextField
          id="claimantJobDuties"
          size="small"
          margin="none"
          value={values.claimantJobDuties || ''}
          multiline
          name="claimantJobDuties"
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.claimantJobDuties)}
          helperText={errors.claimantJobDuties && errors.claimantJobDuties}
          data-cy="clientQuestionnaire_claimantJobDuties"
          aria-label="Claimant job duties"
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={12}>What license, certification or other job requirement did the claimant lose or fail to maintain?</Grid>
      <Grid item xs={4}>
        <TextField
          id="requirementLost"
          size="small"
          margin="none"
          value={values.requirementLost || ''}
          multiline
          name="requirementLost"
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.requirementLost)}
          helperText={errors.requirementLost && errors.requirementLost}
          data-cy="clientQuestionnaire_requirementLost"
          aria-label="Claimant job duties"
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={12}>Was this license, certification or job requirement a requirement at the time of hire?</Grid>
      <Grid container item xs={12} direction="row">
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="licenseRequiredAtHire"
              name="licenseRequiredAtHire"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('licenseRequiredAtHire', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? 1);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              //add errors for validation
              checked={o.status === values.licenseRequiredAtHire}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_licenseRequiredAtHire" />
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid item xs={12}>Was this license, certification or job requirement necessary to have for continued employment in the claimant'ss most recent position?</Grid>
      <Grid container item xs={12} direction="row">
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="licenseNecessary"
              name="licenseNecessary"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('licenseNecessary', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? 1);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              //add errors for validation
              checked={o.status === values.licenseNecessary}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_licenseNecessary" />
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid item xs={12}>Why did the claimant lose their license, certification or job requirement?</Grid>
      <Grid item xs={4}>
        <TextField
          id="reasonForLostLicense"
          size="small"
          margin="none"
          value={values.reasonForLostLicense || ''}
          multiline
          name="reasonForLostLicense"
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.reasonForLostLicense)}
          helperText={errors.reasonForLostLicense && errors.reasonForLostLicense}
          data-cy="clientQuestionnaire_reasonForLostLicense"
          aria-label="Claimant job duties"
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={12}>Did the claimant offer an explanation for the loss?</Grid>
      <Grid container item xs={12} direction="row">
        {lossOfLicenseIndicatorOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="explanationOfferedIndicatorId"
              name="explanationOfferedIndicatorId"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('explanationOfferedIndicatorId', lossOfLicenseIndicatorOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.id ?? 1);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              //add errors for validation
              checked={o.id === values.explanationOfferedIndicatorId}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_explanationOffered" />
          </Grid>
        ))}
      </Grid>
      <br />
      {values.explanationOfferedIndicatorId && values.explanationOfferedIndicatorId === 2 ?
        <>
          <Grid item xs={12}>Please provide details.</Grid>
          <Grid item xs={4}>
            <TextField
              id="licenseLostDetails"
              size="small"
              margin="none"
              value={values.licenseLostDetails || ''}
              multiline
              name="licenseLostDetails"
              onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
              onBlur={handleBlur}
              error={Boolean(errors.licenseLostDetails)}
              helperText={errors.licenseLostDetails && errors.licenseLostDetails}
              data-cy="clientQuestionnaire_licenseLostDetails"
              aria-label="Details for lost license"
              fullWidth
            />
          </Grid>
          <br />
        </>
        : null}
      <Grid item xs={12}>Did the employer previously warn or give notice to the claimant about the licensure status, certification status, or the need for the job requirement before the expiration or loss?</Grid>
      <Grid container item xs={12} direction="row">
        {indicatorOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="wasClaimantPreviouslyWarnedIndicatorId"
              name="wasClaimantPreviouslyWarnedIndicatorId"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('wasClaimantPreviouslyWarnedIndicatorId',indicatorOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.id ?? 1);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              checked={o.id === values.wasClaimantPreviouslyWarnedIndicatorId}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_wasClaimantPreviouslyWarned" />
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid item xs={12}>Do you have a written policy related to the final violation incident?</Grid>
      <Grid container item xs={12} direction="row">
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="writtenPolicyExists"
              name="writtenPolicyExists"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('writtenPolicyExists', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? 1);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              checked={o.status === values.writtenPolicyExists}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_writtenPolicyExists" />
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid item xs={12}>What are the adverse effects to your company as a result of the claimant's actions or inactions?</Grid>
      <Grid item xs={4}>
        <TextField
          id="adverseEffectsOnCompany"
          size="small"
          margin="none"
          value={values.adverseEffectsOnCompany || ''}
          multiline
          //label="Job Title"
          name="adverseEffectsOnCompany"
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.adverseEffectsOnCompany)}
          helperText={errors.adverseEffectsOnCompany && errors.adverseEffectsOnCompany}
          data-cy="clientQuestionnaire_adverseEffectsOnCompany"
          aria-label="Adverse effects on company"
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={10}>
        <Grid item xs={12}>How was the claimant notified of their discharge from employment?</Grid>
        <Grid item md={12} xs={12} sm={12} lg={5}>
          <PaddedTextField
            id="dischargeNotificationId"
            size="small"
            margin="none"
            select
            value={values.dischargeNotificationId || ''}
            name="dischargeNotificationId"
            onChange={e => { handleChange(e); }}
            onBlur={handleBlur}
            error={Boolean(errors.dischargeNotificationId)}
            helperText={errors.dischargeNotificationId && errors.dischargeNotificationId}
            data-cy="clientQuestionnaire_jobStatus"
            aria-label="Most Recent Job Status"
            fullWidth
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
          >
            {dischargeNotificationOptions.filter((item) => item.name !== '')
              .map((option, index) => (
                <MenuItem key={option.name + index} value={option.id}>{option.name}</MenuItem>
              ))}
          </PaddedTextField>
        </Grid>
      </Grid>
      <br />
      <Grid item xs={12}>Who (name and title) made the decision that the claimant would be discharged had they not elected to resign?</Grid>
      <Grid item xs={4}>
        <TextField
          id="personWhoMadeDecisionToDischarge"
          size="small"
          margin="none"
          value={values.personWhoMadeDecisionToDischarge || ''}
          multiline
          label="Name"
          name="personWhoMadeDecisionToDischarge"
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.personWhoMadeDecisionToDischarge)}
          helperText={errors.personWhoMadeDecisionToDischarge && errors.personWhoMadeDecisionToDischarge}
          data-cy="clientQuestionnaire_adverseEffectsOnCompany"
          aria-label="Adverse effects on company"
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={4}>
        <TextField
          id="personWhoMadeDecisionTitle"
          size="small"
          margin="none"
          value={values.personWhoMadeDecisionTitle || ''}
          multiline
          label="Job Title"
          name="personWhoMadeDecisionTitle"
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.personWhoMadeDecisionTitle)}
          helperText={errors.personWhoMadeDecisionTitle && errors.personWhoMadeDecisionTitle}
          data-cy="clientQuestionnaire_adverseEffectsOnCompany"
          aria-label="Adverse effects on company"
          fullWidth
        />
      </Grid>
      <br />

    </React.Fragment>
  )
}

export default LossOfLicenseComponent;
