import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { Prompt } from "react-router";
import { getType } from "typesafe-actions";
import { ClaimDto } from "../../features/claims/claims.dtos";
import { DocumentsDto } from "../../features/documents/document.dtos";
import {
  emailTemplateRecipient,
  getEmailTemplateActions,
  getEmailTemplatesActions,
  toggleEmailTemplateDialog,
} from "../actions/emailTemplate.actions";
import { isAppealsUser, isAuthorized, isClaimsUser } from "../dtos/auth";
import {
  EmailTemplateDto,
  emailTemplateWrapperInit,
} from "../dtos/emailTemplateDto";
import { rootSelector } from "../selectors/selectors";
import {
  ButtonGrid,
  EmailTemplateLinkButtons,
  StyledCancelButton,
  StyledCardContent,
  StyledSubmitButton,
} from "../styles/styles";

const EmailTemplateDialog: React.FC = () => {
  const dispatch = useDispatch();

  const systemOpenDialog: boolean = rootSelector(
    (state) => state.emailTemplates.dialog
  );
  const systemOpenReachOut: boolean = rootSelector(
    (state) => state.emailTemplates.openReachOut
  );
  const systemTemplates: EmailTemplateDto[] = rootSelector(
    (state) => state.emailTemplates.templateList
  );
  const systemTemplate: EmailTemplateDto = rootSelector(
    (state) => state.emailTemplates.template
  );
  const systemRecipient: string = rootSelector(
    (state) => state.emailTemplates.recipient
  );
  const systemDocumentFilename: string = rootSelector(
    (state) => state.emailTemplates.downloadDocument
  );
  const systemClaim: ClaimDto = rootSelector((state) => state.claims.claim);
  const systemDocument: DocumentsDto = rootSelector(
    (state) => state.documents.document
  );

  const [dtoList, setDtoList] = React.useState([] as EmailTemplateDto[]);
  const [templateId, setTemplateId] = React.useState("");
  const [claimId, setClaimId] = React.useState("");
  const [documentId, setDocumentId] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [openReachOut, setOpenReachOut] = React.useState(false);
  const [recipient, setRecipient] = React.useState("");
  const [state, setState] = React.useState("" as string);
  const [userType, setUserType] = React.useState(0);
  const [stateOptions, setStateOptions] = React.useState([] as string[]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [persistState, setPersistState] = React.useState(false);

  React.useEffect(() => {
    dispatch({ type: getType(getEmailTemplatesActions.request) });
  }, [dispatch]);

  React.useEffect(() => {
    setIsOpen(systemOpenDialog);
  }, [systemOpenDialog]);

  React.useEffect(() => {
    setRecipient(systemRecipient);
  }, [systemRecipient]);

  React.useEffect(() => {
    setOpenReachOut(systemOpenReachOut);
  }, [systemOpenReachOut]);

  React.useEffect(() => {
    setDtoList(systemTemplates);
    let states = systemTemplates
      .filter((o) => o.state?.length !== 0 && o.state !== undefined)
      .map((o) => o.state) as string[];
    if (states !== undefined) {
      //I don't know how to fix this
      setStateOptions(Array.from(new Set(states)));
      //setStateOptions(states);
    }

    if (openReachOut) {
      let reachOutTemplateId = systemTemplates.findIndex(
        (f) =>
          f.label?.toLowerCase().includes("initial reach-out") &&
          f.state === "0"
      );
      if (reachOutTemplateId !== -1) {
        handleSubmit(systemTemplates[reachOutTemplateId].id!);
      }
    }
  }, [systemTemplates, openReachOut]);

  React.useEffect(() => {
    if (
      systemTemplate !== undefined &&
      systemTemplate.id !== undefined &&
      systemTemplate.id.length !== 0
    ) {
      handleOpenMail(systemTemplate);
      setIsSubmitting(false);
    }
  }, [systemTemplate]);

  React.useEffect(() => {
    if (systemClaim.id !== undefined && systemClaim.id.length !== 0) {
      if (
        systemClaim.stateCode !== undefined &&
        systemClaim.stateCode !== null &&
        systemClaim.stateCode.length !== 0
      ) {
        setState(systemClaim.stateCode ?? "");
        setPersistState(false);
      } else {
        setPersistState(true);
        setState("");
      }
      setClaimId(systemClaim.id);
      setDocumentId(systemClaim.documents[0]?.id ?? "");
    } else {
      setClaimId("");
      setDocumentId("");
    }
  }, [systemClaim, systemOpenDialog]);

  React.useEffect(() => {
    if (isAppealsUser() && !isClaimsUser()) {
      setUserType(1);
    } else if (!isAppealsUser() && isClaimsUser()) {
      setUserType(2);
    }
  }, [isAppealsUser, isClaimsUser, systemOpenDialog]);

  React.useEffect(() => {
    if (userType === 2) {
      setState("0");
    }
  }, [userType]);

  const handleOpenMail = (dto: EmailTemplateDto) => {
    let mailRecipient = recipient;
    if (recipient.includes(";")) {
      mailRecipient = mailRecipient.replace(new RegExp(";", "g"), ",");
    }

    let recipients = mailRecipient.split(",");
    for (let i = 0; i < recipients.length; i++) {
      if (recipients[i].includes("+")) {
        let emailparts = recipients[i].split("@");
        recipients[i] = emailparts[0].split("+")[0] + "@" + emailparts[1];
      }
    }

    let recip = recipients.join("; ");
    var mailString = "mailto:" + recip + "?";
    mailString += "subject=" + dto.subject;
    mailString += "&body=" + encodeURIComponent(dto.body);

    var mail = document.createElement("a");
    mail.href = mailString;
    mail.click();

    setTimeout(() => {
      mail.remove();
      handleCancel();
    }, 400);
  };

  const handleSubmit = (id: string | undefined) => {
    if (id === undefined) {
      if (isAuthorized()) id = templateId;
      else handleCancel();
    }

    if (id !== undefined && id.length !== 0) {
      setIsSubmitting(true);

      let fileName: string = "";
      if (!!systemDocumentFilename && systemDocumentFilename.length !== 0) {
        fileName = systemDocumentFilename;
      }

      if (
        fileName.length === 0 &&
        !!systemDocument?.id &&
        !!systemDocument?.fileName &&
        systemDocument?.id === documentId
      )
        fileName = systemDocument?.fileName ?? "";

      const payload =
        claimId === ""
          ? { fileName, request: { id } }
          : {
              fileName,
              request: { id, claimId, documentId },
            };

      dispatch({
        type: getType(getEmailTemplateActions.request),
        payload: payload,
      });
    }
  };

  const handleCancel = () => {
    setIsSubmitting(false);
    dispatch({ type: getType(toggleEmailTemplateDialog), payload: false });
    dispatch({ type: getType(emailTemplateRecipient), payload: "" });
    dispatch({
      type: getType(getEmailTemplateActions.success),
      payload: emailTemplateWrapperInit,
    });
    setUserType(0);
    setState("");
    setTemplateId("");
    setPersistState(false);
  };

  return (
    <Dialog open={isOpen} data-cy="reassign_dialog">
      <DialogTitle style={{ marginLeft: 5 }}>Send Mail</DialogTitle>
      <DialogContent style={{ width: 500 }}>
        <StyledCardContent>
          <Grid container alignContent="center" item xs={12} spacing={2}>
            {isAuthorized() && userType === 0 && (
              <Grid container justifyContent="center" item xs={12}>
                <EmailTemplateLinkButtons
                  component={Button}
                  onClick={() => setUserType(2)}
                >
                  <Typography color="primary" variant="body1">
                    CLAIMS
                  </Typography>
                </EmailTemplateLinkButtons>
                <EmailTemplateLinkButtons
                  component={Button}
                  onClick={() => setUserType(1)}
                >
                  <Typography color="primary" variant="body1">
                    APPEALS
                  </Typography>
                </EmailTemplateLinkButtons>
              </Grid>
            )}

            {((state?.length === 0 && userType !== 0 && userType !== 2) ||
              (persistState && userType !== 0 && userType !== 2)) && (
              <Grid item xs={12}>
                <TextField
                  id="emailTemplateState"
                  name="emailTemplateState"
                  onChange={(e) => {
                    setState(e.target.value ?? "");
                    setTemplateId("");
                  }}
                  label="Select State"
                  value={state}
                  select
                  fullWidth
                >
                  {stateOptions
                    .filter((o) => o !== "0")
                    .sort((a, b) => a.localeCompare(b))
                    .map((option, index) => (
                      <MenuItem key={option + index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            )}

            {state?.length !== 0 && (userType === 1 || userType === 2) && (
              <Grid item xs={12}>
                <TextField
                  id="emailTemplate"
                  name="emailTemplate"
                  onChange={(e) => {
                    setTemplateId(e.target.value ?? "");
                  }}
                  value={templateId}
                  label="Select Template"
                  select
                  fullWidth
                >
                  {dtoList
                    .filter(
                      (o) =>
                        o.isActive &&
                        (o.state === state ||
                          (o.state === "0" && o.emailTemplateTypeId !== 4) ||
                          (o.state === "0" &&
                            state !== "MI" &&
                            o.emailTemplateTypeId === 4)) &&
                        o.emailTemplateUserTypeId === userType
                    )
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((o, i) => (
                      <MenuItem key={"template_" + i} value={o.id}>
                        {o.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            )}
          </Grid>
        </StyledCardContent>

        <DialogActions>
          <ButtonGrid container>
            {userType !== 0 && (
              <StyledSubmitButton
                onClick={() => handleSubmit(undefined)}
                color="primary"
                disabled={isSubmitting && userType === 0 && state === ""}
                data-cy="reassign_submitButton"
              >
                SELECT
              </StyledSubmitButton>
            )}

            <StyledCancelButton
              data-cy="reassign_cancelButton"
              color="secondary"
              onClick={handleCancel}
            >
              Cancel
            </StyledCancelButton>
          </ButtonGrid>
        </DialogActions>
      </DialogContent>
      <Prompt
        // when={shouldBlockNavigation}
        message="You have unsaved edits, are you sure you want to leave?"
      />
    </Dialog>
  );
};

export default EmailTemplateDialog;
