import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, FormikHelpers } from 'formik';
import { getType } from 'typesafe-actions';
import { Prompt, useHistory } from 'react-router';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
// material ui
import Pagination from '@material-ui/lab/Pagination';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  Grid,
  Card,
  FormControlLabel,
  Checkbox,
  Paper,
  Button,
  Typography,
  Link,
} from '@material-ui/core';
//Selectors and DTOs
import { rootSelector } from '../../../common/selectors/selectors';
import TeamDetails from '../formComponents/teamDetails';
import EmployerDetails from '../formComponents/employer';
import ClaimantDetails from '../formComponents/claimant';
import InformationDetails from '../formComponents/information';
import DocumentActions from '../formComponents/documentActions';
import ClaimNotes from '../formComponents/claimNotes';
import {
  ClaimDto,
  ClaimInit,
  SepCodeDto,
  claimValidationSchema,
} from '../claims.dtos';
import Label from '../../../common/components/label';
import BottomActionBar from '../../../common/components/bottomActionBar';
import { DocumentsDto } from '../../documents/document.dtos';
import { getCompaniesActions } from '../../company/actions';
import { getContactsActions } from '../../contacts/contacts.actions';
import {
  getMhaAppealContactsActions,
  getMhaHearingContactsActions,
  getMhaClaimsContactsActions,
  getMhaContactsActions,
  createClaimActions,
  updateClaimActions,
  updateQuickClaimActions,
  createQuickClaimActions,
} from '../actions';
import {
  claimsUserOptions,
  appealsUserOptions,
  multiUserOptions,
  claimStatusOptions,
  employerTypeOptions,
  separationPayTypeOptions,
  multiUserNotes,
  appealsUserNotes,
  claimsUserNotes,
} from '../options';
import {
  getSeparationCodesActions,
  getEligibilityCodesActions,
} from '../../settings/codes/codes.actions';
import {
  toggleEmailTemplateDialog,
  emailTemplateRecipient,
  downloadActionListDocument,
} from '../../../common/actions/emailTemplate.actions';
import {
  isAppealsUser,
  isClaimsUser,
  isAuthorized,
} from '../../../common/dtos/auth';
import CreateQuestionnaireDialog from '../../questionnaire/createQuestionnaireDialog';
import {
  ClaimDetailsLabel,
  MHAMailicon,
  IsCompleteBox,
} from '../claims.styles';
import {
  CondensedGrid,
  CardContent1,
  FixedGrid,
} from '../../../common/styles/styles';
import { getCheckedClaim } from '../../../utils/getCheckedClaim';
import DuplicateConfirmDialog from '../dialogs/duplicateConfirmDialog';
import MovePotentialConfirmDialog from '../dialogs/movePotentialConfirmDialog';
import theme from '../../../common/themes/theme';
import { MhaContactsDto } from '../../../common/dtos/dto';
import {
  SeparationCodeDto,
  EligibilityCodeDto,
} from '../../settings/codes/codes.dtos';
import { DocTypeEnum } from '../../../common/enums/docTypeEnum';

interface Props extends React.HTMLProps<React.ReactNode> {
  isQuickEdit: boolean;
  documentTypeOptions: { name: string; id: number }[];
  handleClaimantFirstNameUpdates: (ClaimantFirstName: string) => void;
  handleClaimantLastNameUpdates: (ClaimantLastName: string) => void;
  handleEmployerNameUpdates: (EmployerName: string) => void;
  claimDocument?: DocumentsDto;
  close: () => void;
  isClientUser: boolean;
  isReadOnly: boolean;
  mhaContacts: MhaContactsDto[];
  separationCodes: SeparationCodeDto[];
  eligibilityCodes: EligibilityCodeDto[];
}

const ClaimsDetails: React.FC<Props> = ({
  isQuickEdit,
  claimDocument,
  handleClaimantFirstNameUpdates,
  handleClaimantLastNameUpdates,
  handleEmployerNameUpdates,
  isClientUser,
  isReadOnly,
  close,
  mhaContacts,
  separationCodes,
  eligibilityCodes,
}) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const dispatch = useDispatch();
  const history = useHistory();
  const baseUrl = process.env.REACT_APP_API_URL ?? '/api/';
  const downloadDocumentUrl = baseUrl + 'download/documents/';

  const systemContactInformation = rootSelector(
    (state) => state.contactInformation.contactInformationList
  );
  const systemMhaHearingContacts = rootSelector(
    (state) => state.claims.mhaHearingContactsList
  );
  const systemMhaClaimsContacts = rootSelector(
    (state) => state.claims.mhaClaimsContactsList
  );
  const systemClaim: ClaimDto = rootSelector((state) => state.claims.claim);
  const systemClaimDuplicate = rootSelector(
    (state) => state.claims.claimDuplicate
  );
  const systemCompaniesList = rootSelector(
    (state) => state.companies.companiesList
  );

  const [dto, setDto] = React.useState<ClaimDto>(ClaimInit);
  const [document, setDocument] = React.useState<DocumentsDto | undefined>();
  const [documents, setDocuments] = React.useState<DocumentsDto[]>([]);
  const [savingInput, setSavingInput] = React.useState(false);
  const [selectedAppealsNoteIndex, setSelectedAppealsNoteIndex] =
    React.useState<number>(-1);
  const [selectedClaimsNoteIndex, setSelectedClaimsNoteIndex] =
    React.useState<number>(-1);
  const [selectedMultiUserNoteIndex, setSelectedMultiUserNoteIndex] =
    React.useState<number>(-1);
  const [noteType, setNoteType] = React.useState<number>(4);
  const [userType, setUserType] = React.useState<number>(0);
  const [shouldBlockNavigation, setShouldBlockNavigation] =
    React.useState<boolean>(false);
  const [pdfScale, setPdfScale] = React.useState<number>(0.4);
  const [documentIndex, setDocumentIndex] = React.useState<number>(0);
  const [displayedDocumentIndex, setDisplayedDocumentIndex] =
    React.useState<number>(0);
  const [userOptions, setUserOptions] = React.useState<
    { name: string; id: number }[]
  >([{ name: '', id: 0 }]);
  const [codes, setCodes] = React.useState<SepCodeDto[]>([]);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [noteIndex, setNoteIndex] = React.useState<number>(0);
  const [emailRecipient, setEmailRecipient] = React.useState<string>('');
  const [hideNewClaim, setHideNewClaim] = React.useState<boolean>(false);
  const [isMhaUser, setIsMhaUser] = React.useState<boolean>(false);
  const [slicedDocuments, setSlicedDocuments] = React.useState<DocumentsDto[]>(
    []
  );
  const [reachOutDialogOpen, setReachOutDialogOpen] =
    React.useState<boolean>(false);
  const [duplicateClaimDialogOpen, setDuplicateClaimDialogOpen] =
    React.useState<boolean>(false);
  const [movePotentialDialogOpen, setMovePotentialDialogOpen] =
    React.useState<boolean>(false);
  const [canComplete, setCanComplete] = React.useState<boolean>(true);

  React.useEffect(() => {
    dispatch({ type: getType(getCompaniesActions.request) });
    dispatch({ type: getType(getSeparationCodesActions.request) });
    dispatch({ type: getType(getEligibilityCodesActions.request) });
    dispatch({ type: getType(getMhaContactsActions.request) });
    dispatch({ type: getType(getMhaHearingContactsActions.request) });
    dispatch({ type: getType(getMhaAppealContactsActions.request) });
    dispatch({ type: getType(getMhaClaimsContactsActions.request) });
  }, [dispatch]);

  React.useEffect(() => {
    let identifiedDocument = systemClaim.documents.find(
      (f) => f.id == claimDocument?.id
    );
    let doc: DocumentsDto | undefined = !!identifiedDocument
      ? JSON.parse(JSON.stringify(identifiedDocument))
      : undefined;
    if (doc?.type === 3) {
      let respondedDateTime: Date | undefined =
        doc?.respondedDateTime ?? doc?.uiSides?.general?.respondedDateTime;
      setCanComplete(
        respondedDateTime !== undefined || doc?.sidesFormat === 'mc'
      );
    }
    setDocument(doc);
  }, [systemClaim, claimDocument]);

  React.useEffect(() => {
    if (isQuickEdit === true) {
      let storeClaim: ClaimDto = JSON.parse(JSON.stringify(systemClaim));
      let docsToBeSliced = storeClaim.documents.filter(
        (d) => d.id !== claimDocument?.id
      );

      storeClaim.documents =
        storeClaim.documents.filter((d) => d.id === claimDocument?.id) ??
        storeClaim.documents[0];

      if (!!!storeClaim) storeClaim = { ...ClaimInit };

      if (
        (!!!storeClaim.documents || storeClaim.documents.length === 0) &&
        !!claimDocument
      ) {
        storeClaim.documents = [];
        storeClaim.documents.push(claimDocument);
      }

      if (!!claimDocument)
        setDocuments(
          [
            claimDocument,
            ...systemClaim.documents.filter(
              (x) =>
                x.type === DocTypeEnum.InformationFromClient ||
                (x.uploadedBy !== undefined &&
                  x.uploadedBy.length !== 0 &&
                  x.type === DocTypeEnum.SIDES)
            ),
          ].sort((a, b) =>
            a.id == claimDocument.id
              ? -1
              : b.id == claimDocument.id
              ? 1
              : a.type === DocTypeEnum.SIDES
              ? -1
              : b.type === DocTypeEnum.SIDES
              ? 1
              : 1
          )
        );
      else {
        setDocuments(
          systemClaim.documents
            .filter(
              (x) =>
                x.type === DocTypeEnum.InformationFromClient ||
                (x.uploadedBy !== undefined &&
                  x.uploadedBy.length !== 0 &&
                  x.type === DocTypeEnum.SIDES)
            )
            .sort((a, b) =>
              a.type === DocTypeEnum.SIDES
                ? -1
                : b.type === DocTypeEnum.SIDES
                ? 1
                : 1
            )
        );
      }

      setDto(getCheckedClaim(storeClaim));
      setSlicedDocuments(docsToBeSliced);
    } else if (isQuickEdit === false) {
      let storeClaim = JSON.parse(JSON.stringify(systemClaim)) as ClaimDto;
      storeClaim.documents = [];
      setDto(storeClaim);
    }

    let noteInd =
      systemClaim.notes?.findIndex((n) => n.type === noteType) ?? -1;
    if (isReadOnly && isQuickEdit && claimDocument?.claimId === undefined) {
      setHideNewClaim(true);
    }
    if (noteInd !== -1) {
      setNoteIndex(noteInd);
    }
    setEmailRecipient(systemClaim.primaryEmployerContactEmail ?? '');
  }, [systemClaim, claimDocument, noteType, isQuickEdit, document]);

  React.useEffect(() => {
    if (systemClaimDuplicate) {
      handleOpenDuplicateClaimDialog();
      dispatch({ type: getType(createClaimActions.failure), payload: '' });
    }
  }, [systemClaimDuplicate]);

  React.useEffect(() => {
    let codes = [] as SepCodeDto[];
    (
      JSON.parse(JSON.stringify(separationCodes)) as SeparationCodeDto[]
    ).forEach((s) =>
      codes.push({
        id: s.id ?? '',
        name: s.name ?? '',
        description: s.description ?? '',
        isSep: true,
      })
    );
    (
      JSON.parse(JSON.stringify(eligibilityCodes)) as EligibilityCodeDto[]
    ).forEach((s) =>
      codes.push({
        id: s.id ?? '',
        name: s.name ?? '',
        description: s.description ?? '',
        isSep: false,
      })
    );
    setCodes(codes);
  }, [separationCodes, eligibilityCodes]);

  React.useEffect(() => {
    let one = isClaimsUser();
    let two = isAppealsUser();
    if (two === false && one === true) {
      setUserType(1);
      setUserOptions(claimsUserOptions);
    } else if (one === false && two === true) {
      setUserType(2);
      setUserOptions(appealsUserOptions);
    } else if (one === true && two === true) {
      setUserType(3);
      setUserOptions(multiUserOptions);
    }

    if (one || two) setIsMhaUser(true);
  }, [isClaimsUser, isAppealsUser]);

  function onDocumentLoadSuccess({ numPages }: any) {
    setPageNumber(1);
    setNumPages(numPages);
  }

  const handleMultiUserNoteSelection = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>,
    checked: boolean
  ) => {
    const element = event.target as HTMLInputElement;
    if (element.value !== undefined) {
      const sMultiUserNoteIndex = multiUserNotes.findIndex(
        (note: string) => note === element.value
      );
      if (sMultiUserNoteIndex > -1) {
        setSelectedMultiUserNoteIndex(sMultiUserNoteIndex);
      }
    }
  };

  const handleAppealsNoteSelection = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>,
    checked: boolean
  ) => {
    const element = event.target as HTMLInputElement;
    if (element.value !== undefined) {
      const sAppealsNoteIndex = appealsUserNotes.findIndex(
        (note: string) => note === element.value
      );
      if (sAppealsNoteIndex > -1) {
        setSelectedAppealsNoteIndex(sAppealsNoteIndex);
      }
    }
  };

  const handleClaimsNoteSelection = (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>,
    checked: boolean
  ) => {
    const element = event.target as HTMLInputElement;
    if (element.value !== undefined) {
      const sClaimsNoteIndex = claimsUserNotes.findIndex(
        (note: string) => note === element.value
      );
      if (sClaimsNoteIndex > -1) {
        setSelectedClaimsNoteIndex(sClaimsNoteIndex);
      }
    }
  };

  const handleCancel = () => {
    let path = isQuickEdit ? '/actions' : '/claims';
    history.push(path);
    close();
  };

  const handleZoomIn = () => {
    let newScale = pdfScale >= 3 ? 3 : pdfScale + 0.2;
    setPdfScale(newScale);
  };

  const handleZoomOut = () => {
    let newScale = pdfScale <= 0.2 ? 0.2 : pdfScale - 0.2;
    setPdfScale(newScale);
  };

  const handleGeneralNotesSelection = () => setNoteType(4);
  const handleInternalNotesSelection = () => setNoteType(5);

  const handleContactEmailSelection = (email: string) =>
    setEmailRecipient(email);

  const handleGetEmailPrompt = (email?: string, fileName?: string) => {
    if (isAuthorized()) {
      let emailAddress = email === '' || null ? emailRecipient : email;

      if (fileName !== '' && fileName !== undefined) {
        dispatch({
          type: getType(downloadActionListDocument),
          payload: fileName,
        });
      } else if (isQuickEdit && !!claimDocument?.fileName) {
        dispatch({
          type: getType(downloadActionListDocument),
          payload: claimDocument?.fileName ?? '',
        });
      }

      dispatch({
        type: getType(emailTemplateRecipient),
        payload: emailAddress,
      });
      dispatch({ type: getType(toggleEmailTemplateDialog), payload: true });
    }
  };

  const handleCancelReachOutDialog = () => setReachOutDialogOpen(false);
  const handleOpenReachOutDialog = () => {
    dispatch({
      type: getType(emailTemplateRecipient),
      payload:
        systemContactInformation.map((m) => m.primaryEmail).join(',') ?? '',
    });
    setReachOutDialogOpen(true);
  };

  const handleCancelMovePotentialDialog = () =>
    setMovePotentialDialogOpen(false);
  const handleOpenMovePotentialDialog = () => setMovePotentialDialogOpen(true);

  const handleCancelDuplicateClaimDialog = () =>
    setDuplicateClaimDialogOpen(false);
  const handleOpenDuplicateClaimDialog = () =>
    setDuplicateClaimDialogOpen(true);
  const handleCreateDuplicateClaim = () => {
    let request = JSON.parse(JSON.stringify(dto)) as ClaimDto;
    request.allowExisting = true;
    request.notes = request.notes?.filter((n) => n.entry?.length !== 0);

    slicedDocuments
      .filter((f) => f.fileName !== undefined && f.fileName.length !== 0)
      .forEach((d) => {
        request.documents.push(d);
      });

    if (isQuickEdit) {
      let okToSubmit = true;
      if (okToSubmit || (!okToSubmit && true)) {
        dispatch({
          type: request?.id
            ? getType(updateQuickClaimActions.request)
            : getType(createQuickClaimActions.request),
          payload: request,
        });
      }
    } else {
      dispatch({
        type: request.id
          ? getType(updateClaimActions.request)
          : getType(createClaimActions.request),
        payload: request,
      });
    }
    setDuplicateClaimDialogOpen(false);
  };

  const handleFiledPriorToPoaCheckbox = (
    e: React.ChangeEvent<any>,
    setFieldValue: FormikHelpers<ClaimDto>['setFieldValue'],
    values: ClaimDto
  ) => {
    if (e.target.checked && values.isSecondYearClaim)
      setFieldValue('isSecondYearClaim', false);

    setFieldValue('wasFiledPriorToPoa', e.target.checked);
  };

  const handleSecondYearClaimCheckbox = (
    e: React.ChangeEvent<any>,
    setFieldValue: FormikHelpers<ClaimDto>['setFieldValue'],
    values: ClaimDto
  ) => {
    if (e.target.checked && values.wasFiledPriorToPoa)
      setFieldValue('wasFiledPriorToPoa', false);

    setFieldValue('isSecondYearClaim', e.target.checked);
  };

  return (
    <Fragment key={dto.id}>
      <Formik
        initialValues={dto}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={claimValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (isMhaUser) {
            setTimeout(() => {
              setSubmitting(false);
              setSavingInput(false);
            }, 700);
            setSavingInput(true);
            setDto(values);

            let request: ClaimDto = JSON.parse(JSON.stringify(values));
            request.notes = request.notes?.filter((n) => n.entry?.length !== 0);

            // Update claim display data.
            const selectedEmployerIndex = systemCompaniesList.findIndex(
              (f) => f.companyId === request.employerId
            );
            if (selectedEmployerIndex !== -1) {
              if (
                systemCompaniesList[selectedEmployerIndex].name !==
                request.employerName
              )
                request.employerName =
                  systemCompaniesList[selectedEmployerIndex].name;
            }

            const label = `${request.claimantFirstName} ${request.claimantLastName}`;
            if (request.label !== label) request.label = label;

            slicedDocuments
              .filter(
                (f) => f.fileName !== undefined && f.fileName.length !== 0
              )
              .forEach((d) => {
                request.documents.push(d);
              });

            if (isQuickEdit) {
              let okToSubmit = true;
              if (okToSubmit || (!okToSubmit && true)) {
                dispatch({
                  type: request?.id
                    ? getType(updateQuickClaimActions.request)
                    : getType(createQuickClaimActions.request),
                  payload: { ...request, creditDue: null },
                });
              }
            } else {
              dispatch({
                type: request.id
                  ? getType(updateClaimActions.request)
                  : getType(createClaimActions.request),
                payload: { ...request, creditDue: null },
              });
            }
            setShouldBlockNavigation(false);
          }
        }}
        enableReinitialize={true}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            handleBlur,
          } = props;
          return (
            <>
              {reachOutDialogOpen &&
                (dto.id !== undefined || dto.id !== 'new') && (
                  <CreateQuestionnaireDialog
                    isReadOnly={isReadOnly}
                    open={reachOutDialogOpen}
                    onCancel={handleCancelReachOutDialog}
                    claim={{ ...values }}
                    isComplete={values.questionnaireSubmitted}
                  />
                )}

              {duplicateClaimDialogOpen && (
                <DuplicateConfirmDialog
                  open={duplicateClaimDialogOpen}
                  onCancel={handleCancelDuplicateClaimDialog}
                  handleConfirm={handleCreateDuplicateClaim}
                />
              )}

              {movePotentialDialogOpen && (
                <MovePotentialConfirmDialog
                  open={movePotentialDialogOpen}
                  onCancel={handleCancelMovePotentialDialog}
                  handleConfirm={() => {
                    setFieldValue('actualLiabilityAmount', 0);
                    handleCancelMovePotentialDialog();
                  }}
                />
              )}

              <Grid container>
                {!hideNewClaim && (
                  <>
                    <div hidden={isQuickEdit}>
                      <ClaimDetailsLabel>Claim Details</ClaimDetailsLabel>
                    </div>
                    <br></br>
                    <Grid
                      data-cy='claim_details_container'
                      container
                      spacing={2}
                    >
                      {isQuickEdit ? (
                        <Grid
                          item
                          xs={12}
                          md={4}
                          style={{ position: 'relative' }}
                        >
                          <FixedGrid item style={{ paddingBottom: 300 }}>
                            <Grid
                              container
                              item
                              justifyContent='center'
                              hidden={numPages === 0}
                            >
                              <Button
                                title='Reset Zoom'
                                color='secondary'
                                onClick={() => {
                                  setPdfScale(0.4);
                                  setDisplayedDocumentIndex(0);
                                }}
                                data-cy='claim_pdf_zoomIn'
                              >
                                <RefreshIcon />
                              </Button>
                            </Grid>

                            <Grid
                              container
                              item
                              justifyContent='center'
                              hidden={numPages === 0}
                            >
                              <Button
                                title='Zoom in pdf'
                                color='secondary'
                                onClick={handleZoomOut}
                                data-cy='claim_pdf_zoomOut'
                              >
                                <ZoomOutIcon />
                              </Button>
                              <Pagination
                                page={pageNumber}
                                count={numPages}
                                variant='outlined'
                                color='primary'
                                size='small'
                                onChange={(e, p) => {
                                  setPageNumber(p);
                                }}
                              />
                              <Button
                                title='Zoom in pdf'
                                color='secondary'
                                onClick={handleZoomIn}
                                data-cy='claim_pdf_zoomIn'
                              >
                                <ZoomInIcon />
                              </Button>
                            </Grid>

                            <Grid container item justifyContent='flex-end'>
                              {documents[displayedDocumentIndex]?.fileName !==
                                undefined &&
                                (
                                  documents[displayedDocumentIndex]?.fileName ??
                                  ''
                                ).length !== 0 && (
                                  <Link
                                    href={
                                      downloadDocumentUrl +
                                      encodeURIComponent(
                                        documents[displayedDocumentIndex]
                                          ?.fileName ?? ''
                                      )
                                    }
                                    download
                                    data-cy='documentUploadTableCell_downloadLink'
                                  >
                                    {documents &&
                                    documents[displayedDocumentIndex]?.fileName
                                      ?.length !== 0
                                      ? 'Download'
                                      : ''}
                                  </Link>
                                )}
                            </Grid>

                            <Paper
                              //style={{ maxHeight: window.parent.innerHeight - 382, maxWidth: '192%', overflow: 'auto', position: 'absolute' }}
                              elevation={3}
                            >
                              <Document
                                file={
                                  documents[displayedDocumentIndex]
                                    ?.fileName !== undefined &&
                                  (
                                    documents[displayedDocumentIndex]
                                      ?.fileName ?? ''
                                  )
                                    .toLowerCase()
                                    .includes('.pdf')
                                    ? downloadDocumentUrl +
                                      encodeURIComponent(
                                        documents[displayedDocumentIndex]
                                          ?.fileName ?? ''
                                      )
                                    : null
                                }
                                onLoadSuccess={onDocumentLoadSuccess}
                              >
                                <Page
                                  pageNumber={pageNumber}
                                  width={1200}
                                  scale={pdfScale}
                                />
                              </Document>
                            </Paper>
                            <Grid
                              container
                              item
                              direction='column'
                              alignItems='flex-start'
                            >
                              <Typography>Submitted Documents</Typography>
                              {documents.map((d, index) => (
                                <Button
                                  key={`document_file_${d.fileName ?? index}`}
                                  variant={
                                    displayedDocumentIndex === index
                                      ? 'outlined'
                                      : 'text'
                                  }
                                  onClick={() =>
                                    setDisplayedDocumentIndex(index)
                                  }
                                >
                                  {d.fileName}{' '}
                                  {d.id === claimDocument?.id
                                    ? ' - Action List document'
                                    : ''}
                                </Button>
                              ))}
                            </Grid>
                          </FixedGrid>
                        </Grid>
                      ) : null}

                      <Grid item sm={12} md={isQuickEdit ? 4 : 6}>
                        <Grid container spacing={2}>
                          <Grid hidden={isQuickEdit} item xs={12}>
                            <Card>
                              <CardContent1>
                                <CondensedGrid item xs={12}>
                                  <Label>Team Information</Label>
                                </CondensedGrid>
                                <br></br>
                                <TeamDetails
                                  claimsUsers={systemMhaClaimsContacts}
                                  values={values}
                                  setShouldBlockNavigation={
                                    setShouldBlockNavigation
                                  }
                                  errors={errors}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  isClientUser={isClientUser}
                                  isReadOnly={isReadOnly}
                                />
                              </CardContent1>
                            </Card>
                          </Grid>

                          <Grid item xs={12}>
                            <Card>
                              <CardContent1>
                                {isQuickEdit ? (
                                  <>
                                    <Grid item xs={12}>
                                      <Label>Claimant Details</Label>
                                    </Grid>
                                    <br></br>
                                    <ClaimantDetails
                                      isReadOnly={isReadOnly}
                                      isClientUser={isClientUser}
                                      handleClaimantFirstNameUpdates={
                                        handleClaimantFirstNameUpdates
                                      }
                                      handleClaimantLastNameUpdates={
                                        handleClaimantLastNameUpdates
                                      }
                                      isQuickEdit={isQuickEdit}
                                      separationPayTypeOptions={
                                        separationPayTypeOptions
                                      }
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      setShouldBlockNavigation={
                                        setShouldBlockNavigation
                                      }
                                      errors={errors}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Grid item xs={12}>
                                      <Label>
                                        Employer Details
                                        <Link
                                          disabled={isClientUser || isReadOnly}
                                          component={Button}
                                          title='Mail Employer'
                                          onClick={() =>
                                            handleGetEmailPrompt(
                                              systemContactInformation
                                                .map((m) => m.primaryEmail)
                                                .join(',') ?? ''
                                            )
                                          }
                                        >
                                          <MHAMailicon />
                                          &nbsp;
                                        </Link>
                                      </Label>
                                    </Grid>
                                    <br></br>
                                    <EmployerDetails
                                      handleGetEmailPrompt={
                                        handleGetEmailPrompt
                                      }
                                      hearingContactOptions={
                                        systemMhaHearingContacts
                                      }
                                      isReadOnly={isReadOnly}
                                      handleEmployerNameUpdates={
                                        handleEmployerNameUpdates
                                      }
                                      isClientUser={isClientUser}
                                      isQuickEdit={isQuickEdit}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      setShouldBlockNavigation={
                                        setShouldBlockNavigation
                                      }
                                      errors={errors}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      handleContactEmailUpdates={
                                        handleContactEmailSelection
                                      }
                                      systemContactInformation={
                                        systemContactInformation
                                      }
                                    />{' '}
                                  </>
                                )}
                              </CardContent1>
                            </Card>
                          </Grid>
                          <Grid item xs={12}>
                            <Card>
                              <CardContent1>
                                {isQuickEdit ? (
                                  <>
                                    <Grid
                                      container
                                      item
                                      xs={12}
                                      direction='row'
                                    >
                                      <Grid item xs={8}>
                                        <Label>
                                          Employer Details
                                          <Link
                                            component={Button}
                                            onClick={() =>
                                              handleGetEmailPrompt(
                                                systemContactInformation
                                                  .map((m) => m.primaryEmail)
                                                  .join(',') ?? ''
                                              )
                                            }
                                            disabled={
                                              isClientUser || isReadOnly
                                            }
                                          >
                                            <MHAMailicon />
                                            &nbsp;
                                          </Link>
                                        </Label>
                                      </Grid>
                                      <Grid item xs={4}>
                                        {isAuthorized() &&
                                          values.id !== undefined &&
                                          values.id !== 'new' && (
                                            <Button
                                              onClick={() =>
                                                handleOpenReachOutDialog()
                                              }
                                              data-cy='claimDetails_initialReachOut'
                                              variant='outlined'
                                              color='secondary'
                                              style={{
                                                color:
                                                  values.questionnaireSubmitted
                                                    ? 'red'
                                                    : values.hasQuestionnaire
                                                    ? '#ff7d00'
                                                    : theme.palette.secondary
                                                        .main,
                                                borderColor:
                                                  values.questionnaireSubmitted
                                                    ? 'red'
                                                    : values.hasQuestionnaire
                                                    ? '#ff7d00'
                                                    : theme.palette.secondary
                                                        .main,
                                              }}
                                            >
                                              INITIAL REACH OUT
                                            </Button>
                                          )}
                                      </Grid>
                                    </Grid>
                                    <br></br>
                                    <EmployerDetails
                                      handleGetEmailPrompt={
                                        handleGetEmailPrompt
                                      }
                                      hearingContactOptions={
                                        systemMhaHearingContacts
                                      }
                                      isReadOnly={isReadOnly}
                                      handleEmployerNameUpdates={
                                        handleEmployerNameUpdates
                                      }
                                      isClientUser={isClientUser}
                                      isQuickEdit={isQuickEdit}
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      setShouldBlockNavigation={
                                        setShouldBlockNavigation
                                      }
                                      errors={errors}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                      handleContactEmailUpdates={
                                        handleContactEmailSelection
                                      }
                                      systemContactInformation={
                                        systemContactInformation
                                      }
                                    />{' '}
                                  </>
                                ) : (
                                  <>
                                    <Grid item xs={12}>
                                      <Label>Claimant Details</Label>
                                    </Grid>
                                    <br></br>
                                    <ClaimantDetails
                                      isReadOnly={isReadOnly}
                                      handleClaimantFirstNameUpdates={
                                        handleClaimantFirstNameUpdates
                                      }
                                      isClientUser={isClientUser}
                                      handleClaimantLastNameUpdates={
                                        handleClaimantLastNameUpdates
                                      }
                                      isQuickEdit={isQuickEdit}
                                      separationPayTypeOptions={
                                        separationPayTypeOptions
                                      }
                                      setFieldValue={setFieldValue}
                                      values={values}
                                      setShouldBlockNavigation={
                                        setShouldBlockNavigation
                                      }
                                      errors={errors}
                                      handleChange={handleChange}
                                      handleBlur={handleBlur}
                                    />
                                    <br></br>
                                    <br></br>
                                  </>
                                )}
                              </CardContent1>
                            </Card>
                            {isQuickEdit ? (
                              <>
                                <br />
                                <Grid container>
                                  <Card>
                                    <CardContent1>
                                      <Grid container direction='row'>
                                        <Grid item xs={6}>
                                          <Label>Claim Information</Label>
                                        </Grid>
                                        <Grid item xs={3}>
                                          <FormControlLabel
                                            id='wasFiledPriorToPoa'
                                            name='wasFiledPriorToPoa'
                                            onChange={(e) => {
                                              handleChange(e);
                                            }}
                                            onBlur={(e) => {
                                              handleBlur(e);
                                              setShouldBlockNavigation(true);
                                            }}
                                            value={values.wasFiledPriorToPoa}
                                            checked={values.wasFiledPriorToPoa}
                                            control={
                                              <Checkbox
                                                size='small'
                                                disabled={
                                                  isClientUser || isReadOnly
                                                }
                                                data-cy='claim_information_wasFiledPriorToPoaCheckbox'
                                              />
                                            }
                                            label={
                                              <Typography
                                                color='textSecondary'
                                                variant='subtitle2'
                                              >
                                                Filed Prior to POA
                                              </Typography>
                                            }
                                          />
                                        </Grid>
                                        <Grid item xs={3}>
                                          <FormControlLabel
                                            id='isSecondYearClaim'
                                            name='isSecondYearClaim'
                                            onChange={(e) => {
                                              handleChange(e);
                                            }}
                                            onBlur={(e) => {
                                              handleBlur(e);
                                              setShouldBlockNavigation(true);
                                            }}
                                            value={values.isSecondYearClaim}
                                            checked={values.isSecondYearClaim}
                                            disabled={
                                              isClientUser || isReadOnly
                                            }
                                            control={
                                              <Checkbox
                                                size='small'
                                                data-cy='claim_information_isSecondYearClaimCheckbox'
                                              />
                                            }
                                            label={
                                              <Typography
                                                color='textSecondary'
                                                variant='subtitle2'
                                              >
                                                Second Year Claim
                                              </Typography>
                                            }
                                          />
                                        </Grid>
                                      </Grid>
                                      <br></br>
                                      <InformationDetails
                                        handleGetEmailPrompt={
                                          handleGetEmailPrompt
                                        }
                                        isReadOnly={isReadOnly}
                                        isQuickEdit={isQuickEdit}
                                        isClientUser={isClientUser}
                                        separationCodes={codes}
                                        claimStatusOptions={claimStatusOptions}
                                        hearingContactOptions={
                                          systemMhaHearingContacts
                                        }
                                        employerTypeOptions={
                                          employerTypeOptions
                                        }
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        touched={touched}
                                        setShouldBlockNavigation={
                                          setShouldBlockNavigation
                                        }
                                        errors={errors}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        handleShowMovePotentialDialog={
                                          handleOpenMovePotentialDialog
                                        }
                                      />

                                      <br></br>
                                    </CardContent1>
                                  </Card>
                                </Grid>
                              </>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                      {!isQuickEdit ? (
                        <Grid item md={6} sm={12}>
                          <Card>
                            <CardContent1>
                              <Grid container direction='row'>
                                <Grid item xs={6}>
                                  <Label>Claim Information</Label>
                                </Grid>
                                <Grid item xs={3}>
                                  <FormControlLabel
                                    id='wasFiledPriorToPoa'
                                    name='wasFiledPriorToPoa'
                                    onChange={(e) =>
                                      handleFiledPriorToPoaCheckbox(
                                        e,
                                        setFieldValue,
                                        values
                                      )
                                    }
                                    onBlur={(e) => {
                                      handleBlur(e);
                                      setShouldBlockNavigation(true);
                                    }}
                                    value={values.wasFiledPriorToPoa}
                                    checked={values.wasFiledPriorToPoa}
                                    disabled={isClientUser || isReadOnly}
                                    control={
                                      <Checkbox
                                        size='small'
                                        disabled={isClientUser || isReadOnly}
                                        data-cy='claim_information_wasFiledPriorToPoaCheckbox'
                                      />
                                    }
                                    label={
                                      <Typography
                                        color='textSecondary'
                                        variant='subtitle2'
                                      >
                                        Filed Prior to POA
                                      </Typography>
                                    }
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <FormControlLabel
                                    id='isSecondYearClaim'
                                    name='isSecondYearClaim'
                                    onChange={(e) =>
                                      handleSecondYearClaimCheckbox(
                                        e,
                                        setFieldValue,
                                        values
                                      )
                                    }
                                    onBlur={(e) => {
                                      handleBlur(e);
                                      setShouldBlockNavigation(true);
                                    }}
                                    value={values.isSecondYearClaim}
                                    disabled={isClientUser || isReadOnly}
                                    checked={values.isSecondYearClaim}
                                    control={
                                      <Checkbox
                                        size='small'
                                        data-cy='claim_information_isSecondYearClaimCheckbox'
                                      />
                                    }
                                    label={
                                      <Typography
                                        color='textSecondary'
                                        variant='subtitle2'
                                      >
                                        Second Year Claim
                                      </Typography>
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <br></br>
                              <InformationDetails
                                handleGetEmailPrompt={handleGetEmailPrompt}
                                isReadOnly={isReadOnly}
                                isQuickEdit={isQuickEdit}
                                isClientUser={isClientUser}
                                separationCodes={codes}
                                claimStatusOptions={claimStatusOptions}
                                hearingContactOptions={systemMhaHearingContacts}
                                employerTypeOptions={employerTypeOptions}
                                setFieldValue={setFieldValue}
                                values={values}
                                touched={touched}
                                setShouldBlockNavigation={
                                  setShouldBlockNavigation
                                }
                                errors={errors}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                handleShowMovePotentialDialog={
                                  handleOpenMovePotentialDialog
                                }
                              />
                            </CardContent1>
                          </Card>
                          <br></br>
                          <ClaimNotes
                            isReadOnly={isReadOnly}
                            isClientUser={isClientUser}
                            values={values}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setShouldBlockNavigation={setShouldBlockNavigation}
                            setFieldValue={setFieldValue}
                            noteIndex={noteIndex}
                            noteType={noteType}
                            handleMultiUserNoteSelection={
                              handleMultiUserNoteSelection
                            }
                            handleClaimsNoteSelection={
                              handleClaimsNoteSelection
                            }
                            handleAppealsNoteSelection={
                              handleAppealsNoteSelection
                            }
                            handleGeneralNotesSelection={
                              handleGeneralNotesSelection
                            }
                            handleInternalNotesSelection={
                              handleInternalNotesSelection
                            }
                            selectedMultiUserNoteIndex={
                              selectedMultiUserNoteIndex
                            }
                            selectedClaimsNoteIndex={selectedClaimsNoteIndex}
                            selectedAppealsNoteIndex={selectedAppealsNoteIndex}
                            userType={userType}
                            claimsUserNotes={claimsUserNotes}
                            appealsUserNotes={appealsUserNotes}
                            multiUserNotes={multiUserNotes}
                          />
                          <br />
                          <br />
                          <br />
                          <br />
                        </Grid>
                      ) : (
                        <Grid container item sm={12} md={4}>
                          <Card>
                            <CardContent1>
                              <Grid container>
                                <Grid item xs={10}>
                                  <Label>Actions</Label>
                                  {mhaContacts.findIndex(
                                    (x) =>
                                      values.documents[0]?.assignedId ===
                                      x.contactId
                                  ) !== -1 && (
                                    <Typography
                                      variant='body2'
                                      color='secondary'
                                    >
                                      Assigned to{' '}
                                      {mhaContacts.find(
                                        (x) =>
                                          values.documents[0].assignedId ===
                                          x.contactId
                                      )?.name || 'Not Available'}
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                              <br></br>
                              <DocumentActions
                                isReadOnly={isReadOnly}
                                isClientUser={isClientUser}
                                userOptions={userOptions}
                                setFieldValue={setFieldValue}
                                values={values.documents[documentIndex]}
                                setShouldBlockNavigation={
                                  setShouldBlockNavigation
                                }
                                errors={errors}
                                handleChange={handleChange}
                                index={documentIndex}
                                handleBlur={handleBlur}
                              />
                              <br></br>
                              <Card>
                                <CardContent1>
                                  <ClaimNotes
                                    isReadOnly={isReadOnly}
                                    isClientUser={isClientUser}
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    setShouldBlockNavigation={
                                      setShouldBlockNavigation
                                    }
                                    setFieldValue={setFieldValue}
                                    noteIndex={noteIndex}
                                    noteType={noteType}
                                    handleMultiUserNoteSelection={
                                      handleMultiUserNoteSelection
                                    }
                                    handleClaimsNoteSelection={
                                      handleClaimsNoteSelection
                                    }
                                    handleAppealsNoteSelection={
                                      handleAppealsNoteSelection
                                    }
                                    handleGeneralNotesSelection={
                                      handleGeneralNotesSelection
                                    }
                                    handleInternalNotesSelection={
                                      handleInternalNotesSelection
                                    }
                                    selectedMultiUserNoteIndex={
                                      selectedMultiUserNoteIndex
                                    }
                                    selectedClaimsNoteIndex={
                                      selectedClaimsNoteIndex
                                    }
                                    selectedAppealsNoteIndex={
                                      selectedAppealsNoteIndex
                                    }
                                    userType={userType}
                                    claimsUserNotes={claimsUserNotes}
                                    appealsUserNotes={appealsUserNotes}
                                    multiUserNotes={multiUserNotes}
                                  />
                                  <br></br>
                                  <Grid item xs={12}>
                                    <IsCompleteBox
                                      id={`documents[${documentIndex}].isComplete`}
                                      name={`documents[${documentIndex}].isComplete`}
                                      onChange={handleChange}
                                      onBlur={(e) => {
                                        handleBlur(e);
                                        setShouldBlockNavigation(true);
                                      }}
                                      value={
                                        values.documents[documentIndex]
                                          ?.isComplete || false
                                      }
                                      checked={
                                        values.documents[documentIndex]
                                          ?.isComplete || false
                                      }
                                      disabled={
                                        isClientUser ||
                                        isReadOnly ||
                                        !canComplete
                                      }
                                      control={
                                        <Checkbox
                                          size='small'
                                          data-cy='claimNotes_dialog_isCompleteCheckbox'
                                        />
                                      }
                                      label={
                                        <Typography variant='subtitle2'>
                                          Complete
                                        </Typography>
                                      }
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    hidden={
                                      (!values.documents[documentIndex]
                                        ?.isComplete &&
                                        values.selectedBusinessUnit !== null &&
                                        values.businessUnit !== null) ||
                                      false
                                    }
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      color='error'
                                    >
                                      ARE YOU SURE THIS IS COMPLETE?
                                    </Typography>
                                  </Grid>
                                </CardContent1>
                              </Card>
                            </CardContent1>
                          </Card>
                        </Grid>
                      )}
                    </Grid>
                  </>
                )}

                <BottomActionBar
                  cancelClickHandler={handleCancel}
                  submitClickHandler={handleSubmit}
                  submitting={savingInput}
                  disabled={isClientUser || isReadOnly}
                  showSaveButton={!(isClientUser || isReadOnly)}
                  claimDialog={isQuickEdit}
                />
                <Prompt
                  when={shouldBlockNavigation}
                  message={() => {
                    return 'You have unsaved edits, are you sure you want to leave?';
                  }}
                />
              </Grid>
            </>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default ClaimsDetails;
