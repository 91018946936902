import * as Yup from 'yup';


export const notesValidationSchema = Yup.array().of(Yup.object().shape({
  entry: Yup.string().max(2000, 'Must be 2000 characters or less').nullable(),
}));


export interface NoteDto {
  noteId?: string, //($uuid)
  entry: string, //maxLength: 2000

}

export const noteInit: NoteDto = {
  entry: '',
};