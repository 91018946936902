import styled from 'styled-components';

// material ui
import {
  Container
} from '@material-ui/core';


// Styled forgotPassword components ....
export const ForgotPasswordContainer = styled(Container).attrs({ component: 'main'})`
    margin-top: 125px;
`;

export const H3 = styled.h3`
    margin-bottom: 0;
`;

export const SP = styled.p`
    color: darkgrey;
`;