import React from 'react';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
import { useMediaQuery, } from '@material-ui/core';
import TabPanel from '../../../common/components/tabPanel';
import EligibilityCodesList from './components/eligibilityCodesList';
import SeparationCodesList from './components/separationCodesList';
import LocationCodesList from './components/locationCodesList';
import { getCodesActions } from './codes.actions';
import { ClearAppBar, StyledCardContent, StyledTab, StyledTabs, ViewCard, ViewGrid } from '../../../common/styles/styles';


const Codes: React.FC = () => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = React.useState(0);

  const shouldCollapse = useMediaQuery('(max-width:768px)');

  React.useEffect(() => {
    dispatch({ type: getType(getCodesActions.request) });
  }, [dispatch]);


  const changeTab = (event: any, newValue: number) => {
    setTabValue(newValue);
  }


  return <ViewGrid container item xs={12}>
    <ViewCard>
      <StyledCardContent>
        <ClearAppBar position="static">
          <StyledTabs aria-labelledby={`tab-${tabValue}`} orientation={shouldCollapse ? 'vertical' : 'horizontal'} centered={true} value={tabValue} onChange={changeTab} aria-label="Profile options" variant="fullWidth" scrollButtons="auto">
            <StyledTab id="tab-0" aria-controls="tabpanel-0" label="Eligibility" data-cy="codes_eligibility_tab" />
            <StyledTab id="tab-1" aria-controls="tabpanel-1" label="Separation" data-cy="codes_separation_tab" />
            <StyledTab id="tab-2" aria-controls="tabpanel-2" label="Location" data-cy="codes_location_tab" />
          </StyledTabs>
        </ClearAppBar>

        <TabPanel value={tabValue} index={0}><EligibilityCodesList /> </TabPanel>
        <TabPanel value={tabValue} index={1}><SeparationCodesList /></TabPanel>
        <TabPanel value={tabValue} index={2}><LocationCodesList /></TabPanel>

      </StyledCardContent>
    </ViewCard>
  </ViewGrid>
}

export default Codes;
