import React from 'react';
import { Provider, useDispatch } from 'react-redux';

import { ThemeProvider } from "styled-components";
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useIdleTimer } from 'react-idle-timer';

import theme from '../common/themes/theme';
import store from './store';
import AppRouter from './AppRouter';

import Spinner from '../common/components/spinner';
import SnackbarNotification from '../common/components/notification';
import { getType } from 'typesafe-actions';
import { logoutActions } from '../features/account/login/login.actions';
import { isUserAuthenticated } from '../common/dtos/auth';
import useAppendScript from '../utils/appendScript';


const App: React.FC = () => {
  const dispatch = useDispatch();
  //useAppendScript('https://cdn.userway.org/widget.js', true, [['data-account', 'sMV0ek6rkd'], ['cookie_flags', 'samesite=none;secure']]);
  useAppendScript('https://code.jquery.com/jquery-3.3.1.min.js', true);

  React.useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute('data-account', 'sMV0ek6rkd');
    script.setAttribute('cookie_flags', 'samesite=none;secure');
    script.src = 'https://cdn.userway.org/widget.js';
    script.async = true;
    //script.crossOrigin = '';
    document.body.appendChild(script);
  }, []);

  const handleOnIdle = (event: Event) => {
    if (isUserAuthenticated())
      dispatch({ type: getType(logoutActions.request) });
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 10,
    onIdle: handleOnIdle,
    //onActive: handleOnActive,
    //onAction: handleOnAction,
    debounce: 500
  })


  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <script>Duo.init();</script>
      <div className="App">
        <CssBaseline />
        <Spinner />
        <SnackbarNotification />
        <AppRouter />
      </div>
    </MuiPickersUtilsProvider>

  )
}

export default () => (
  <MuiThemeProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </MuiThemeProvider>
)
