//Claim Options

export const claimsUserOptions = [
  { name: '', id: 0 },
  { name: 'New Mail', id: 1 },
  { name: 'Waiting on Client', id: 2 },
  { name: 'Needs Reviewed', id: 3 },
  { name: 'Ready to Protest', id: 4 },
  { name: 'No Protest Needed', id: 5 },
  { name: 'Decision', id: 6 },
  { name: 'Confirmation Pending', id: 7 },
  { name: 'Needs checked', id: 8 },

];



export const appealsUserOptions = [

  { name: '', id: 0 },
  { name: 'New Mail', id: 1 },
  { name: 'New Decision', id: 9 },
  { name: 'Hearing Scheduled', id: 10 },
  { name: 'Waiting on State Action', id: 11 },
  { name: 'Waiting on Assigned Rep', id: 12 },
  { name: 'To be assigned', id: 13 },
  { name: 'Appeal Pending', id: 14 },
  { name: 'New Hearing', id: 15 },


];

export const multiUserOptions = [
  { name: '', id: 0 },
  { name: 'New Mail', id: 1 },
  { name: 'Waiting on Client', id: 2 },
  { name: 'Needs Reviewed', id: 3 },
  { name: 'Ready to Protest', id: 4 },
  { name: 'No Protest Needed', id: 5 },
  { name: 'Decision', id: 6 },
  { name: 'Confirmation Pending', id: 7 },
  { name: 'Needs checked', id: 8 },
  { name: 'New Decision', id: 9 },
  { name: 'Hearing Scheduled', id: 10 },
  { name: 'Waiting on State Action', id: 11 },
  { name: 'Waiting on Assigned Rep', id: 12 },
  { name: 'To be assigned', id: 13 },
  { name: 'Appeal Pending', id: 14 },
  { name: 'New Hearing', id: 15 },
];

export const claimStatusOptions = [
  { name: '', id: 0 },
  { name: 'Win', id: 1 },
  { name: 'Loss', id: 2 },
  { name: 'Eligible', id: 3 },
  { name: 'Pending', id: 4 },
];

export const employerTypeOptions = [
  { name: '', id: 0 },
  { name: 'Base Period Employer', id: 1 },
  { name: 'Last Employer', id: 2 },
  { name: 'Both', id: 3 },

];

export const separationPayTypeOptions = [
  { name: '', id: 0 },
  { name: 'Vacation Pay', id: 1 },
  { name: 'Severance Pay', id: 2 },
  { name: 'Payment in lieu of notice', id: 3 },
  { name: 'Retirement Pay', id: 4 },
  { name: 'Bonus Pay', id: 5 },
  { name: 'Other Payments', id: 6 },

];


export const reportingTypeOptions = [
    { name: 'Week Ending', id: 1 },
    { name: 'Month Ending', id: 2 },
    { name: 'Year Ending', id: 3 }

];

export const multiUserNotes =
  [
    'First requested information',
    'Additional information request',
    'Client responded',
    'General Note',
    'Notice of hearing sent to client',
    'Waiting on decision from State',
    'Hearing decision sent to atty to review',
        'Hearing decision sent to client',
        'Appeal submitted',
        'Protest submitted',
  ];

export const claimsUserNotes =
  ['First requested information',
    'Additional information request',
        'Client responded',
    'Protest Submitted',
    'General Note'
  ];

export const appealsUserNotes = [
    'Notice of hearing sent to client',
    'Waiting on decision from State',
    'Hearing decision sent to atty to review',
    'Hearing decision sent to client',
    'Appeal submitted',
    'General note',

];

export const batchPaymentNotes = [
    'Charges Verified',
    'Charges Protested',

];

export const documentTypeOptions = [
    { name: '', id: 0 },
    { name: 'Monetary notice', id: 1 },
    { name: 'State fact-finding form', id: 2 },
    { name: 'SIDES', id: 3 },
    { name: 'Transmittal to State', id: 4 },
    { name: 'Information from Client', id: 5 },
    { name: 'Wage Data', id: 6 },
    { name: 'Decision', id: 7 },
    { name: 'Notice of Fact-Finding Interview', id: 8 },
    { name: 'Hearing Notice', id: 9 },
    { name: 'Hearing Decision', id: 10 },
    { name: 'Board Decision', id: 11 },
    { name: 'Circuit Court Decision', id: 12 },
    { name: 'Attorney notes from hearing', id: 13 },
    { name: 'Appellate Documents', id: 14 },
    { name: 'Claim General Documents', id: 15 },
    { name: 'Client Communications', id: 16 },
    { name: 'Charge Protest', id: 17 },
];



//Appellate Event Options

export const chargeOptions = [
    { name: 'Full Charge', id: 3 },
    { name: 'Partial Charge', id: 2 },
    { name: 'No Charge', id: 1 }

];


export const eventTypeDropdownOptions = [
    { name: 'Board Appeal', id: 2 },
    { name: 'Circuit Court Appeal', id: 3 },
    { name: 'Court of Appeals', id: 4 },
    { name: 'Supreme Court', id: 5 },
    { name: 'Hearing Notice', id: 9 },
];

export const appealedByDropdownOptions = [
    { name: 'Claimant', id: 1 },
    { name: 'Employer', id: 2 },

];

export const hearingTypeOptions = [
    { name: 'Eligibility', id: 1 },
    { name: 'Separation', id: 2 },

];

export const hearingLocationDropdownOptions = [
    { name: 'Phone', id: 1 },
    { name: 'In Person', id: 2 },

];



export const hearingOutcomeDropdownOptions = [
    { name: 'Pending', id: 1 },
    { name: 'Win', id: 2 },
    { name: 'Partial Win', id: 3 },
    { name: 'Loss', id: 4 },
    { name: 'Withdrawal', id: 5 },
    { name: 'No-show', id: 6 },
    { name: 'Postponed', id: 7 },
];


//Appellate
export const alternativeIssueOptions = [
    { name: '', id: 0 },
    { name: 'Availability for full-time work', id: 1 },
    { name: 'Ability to perform full-time work', id: 2 },
    { name: 'Seeking full-time work', id: 3 },
    { name: 'Late Appeal Issue', id: 4 },
    { name: 'Registration with State Agency', id: 5 },
    { name: 'Remuneration', id: 6 },
    { name: 'Transfer of Liability', id: 7 },
    { name: 'Validity of Training Waiver', id: 8 },
    { name: 'Fraudulent ID Theft Claim', id: 9 },
    { name: 'Fraudulent Activity', id: 10 }
];

//Claim event
export const claimEventTypeOptions = [
    { name: '', id: 0 },
    { name: 'Claim Established', id: 1 },
    { name: 'Monetary Notice', id: 2 },
    { name: 'Fact-finding Form', id: 3 },
    { name: 'Fact-finding Interview', id: 4 },
    { name: 'Favorable determination', id: 5 },
    { name: 'Adverse determination', id: 6 },    
];


