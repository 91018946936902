import { createAsyncAction, createAction } from 'typesafe-actions';
import { ContactDto } from '../contacts/contacts.dtos';
import { BulkImportDto } from '../../common/dtos/bulkImportDto';

// KK this is a dupe of above. This on should be removed
export const getContactsActions = createAsyncAction(
  'GET_CONTACTS_REQUEST',
  'GET_CONTACTS_SUCCESS',
  'GET_CONTACTS_ERROR',
)<undefined, ContactDto[], string>();

export const getContactActions = createAsyncAction(
  'GET_CONTACT_REQUEST',
  'GET_CONTACT_SUCCESS',
  'GET_CONTACT_ERROR',
)<string, ContactDto, string>();

export const updateContactActions = createAsyncAction(
  'UPDATE_CONTACT_REQUEST',
  'UPDATE_CONTACT_SUCCESS',
  'UPDATE_CONTACT_ERROR',
)<ContactDto, ContactDto, string>();

export const createContactActions = createAsyncAction(
  'CREATE_CONTACT_REQUEST',
  'CREATE_CONTACT_SUCCESS',
  'CREATE_CONTACT_ERROR',
)<ContactDto, ContactDto, string>();

export const contactListShouldRefresh = createAction('REFRESH_CONTACT_LIST')<boolean>();
export const contactShouldRefresh = createAction('REFRESH_CONTACT')<string>();
export const contactImportHistoryShouldRefresh = createAction('REFRESH_CONTACT_IMPORT_HISTORY')<boolean>();

export const getContactBulkImportHistoryActions = createAsyncAction(
  'GET_CONTACT_BULK_IMPORT_HISTORY_REQUEST',
  'GET_CONTACT_BULK_IMPORT_HISTORY_SUCCESS',
  'GET_CONTACT_BULK_IMPORT_HISTORY_ERROR',
)<undefined, BulkImportDto[], string>();

export const uploadContactBulkImport = createAction('UPLOAD_CONTACT_BULK_IMPORT')<any>();



export const createInteractionActions = createAsyncAction(
  'GET_INTERACTIONS_REQUEST',
  'GET_INTERACTIONS_SUCCESS',
  'GET_INTERACTIONS_ERROR',
)<ContactDto, ContactDto, string>();

export const sendPasswordResetActions = createAsyncAction(
  'SEND_PASSWORD_RESET_REQUEST',
  'SEND_PASSWORD_RESET_SUCCESS',
  'SEND_PASSWORD_RESET_ERROR',
)<string, boolean, string>();