import { AxiosError } from 'axios';

import { ApiError } from '../../common/api/apiResponse';
import apiClient from '../../common/api/apiClient';
import { ClientQuestionnaireDto, CreateQuestionnaireDto } from './dto';
import { showNotification } from '../../common/actionCreators/dispatchNotification';

const questionnaireUrl = '/questionnaire';

export const getQuestionnaireApi = async (id: string) => {
  return await apiClient()
    .get(`${questionnaireUrl}/${id}`)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      throw err;
    });
};

export const createQuestionnaireApi = async (
  request: CreateQuestionnaireDto
) => {
  return await apiClient()
    .post(`${questionnaireUrl}/${request.claim?.id}`, request)
    .then((res) => {
      showNotification('Questionnaire created', 'success');
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response)
          showNotification(axiosError.response.data.error.message, 'error');
      } else
        showNotification(
          'An error occurred while submitting questionnaire',
          'error'
        );
      throw err;
    });
};

export const saveQuestionnaireApi = async (request: ClientQuestionnaireDto) => {
  let data = new FormData();
  //For each of the documents arrays, do this block
  if (request.documents !== undefined && request.documents.length > 0) {
    request.documents.forEach((d) => {
      if (d.fileUpload !== undefined) data.append('file', d.fileUpload);
    });
  }

  data.append('model', JSON.stringify(request));

  return await apiClient()
    .put(`${questionnaireUrl}/${request.parentClaim?.id}`, data, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
    .then((res) => {
      let messge = request.completed
        ? 'Questionnaire has been submitted.'
        : 'Questionnaire saved.';
      showNotification(messge, 'success');

      return true;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response)
          showNotification(axiosError.response.data.error.message, 'error');
      } else
        showNotification(
          'An error occurred while saving questionnaire.',
          'error'
        );
      throw err;
    });
};
