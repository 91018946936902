import { call, put, takeLatest, select } from "redux-saga/effects";
import { getType } from "typesafe-actions";

import {
  getEmailTemplatesApi,
  getEmailTemplateApi,
  downloadWitnessInfo,
  createEmailTemplateApi,
  updateEmailTemplateApi,
  downloadActionListDocument,
  downloadSupportingDocument,
} from "../api/emailTemplate.api";
import {
  getEmailTemplatesActions,
  getEmailTemplateActions,
  updateEmailTemplateActions,
  createEmailTemplateActions,
  getEditEmailTemplateActions,
  openReachOutEmailTemplateDialog,
} from "../actions/emailTemplate.actions";
import { EmailTemplateDto } from "../dtos/emailTemplateDto";
import { isAuthorized } from "../dtos/auth";

// @ts-ignore
function debounce(func, timeout = 300) {
  // @ts-ignore
  let timer;
  // @ts-ignore
  return (...args) => {
    // @ts-ignore
    clearTimeout(timer);
    timer = setTimeout(() => {
      // @ts-ignore
      func.apply(this, args);
    }, timeout);
  };
}

function* handleGetEmailTemplates() {
  const storeTemplates: EmailTemplateDto[] = yield select(
    (state) => state.emailTemplates.templateList
  );
  const templates: EmailTemplateDto[] =
    storeTemplates !== undefined && storeTemplates.length !== 0
      ? storeTemplates
      : yield call(getEmailTemplatesApi);

  yield put({
    type: getType(getEmailTemplatesActions.success),
    payload: templates,
  });
}

function* handleGetEmailTemplate(
  request: ReturnType<typeof getEmailTemplateActions.request>
) {
  const storeTemplate: EmailTemplateDto = yield select(
    (state) => state.emailTemplates.template
  );
  const template: EmailTemplateDto =
    storeTemplate !== undefined &&
    storeTemplate.id === request.payload.request.id
      ? storeTemplate
      : yield call(getEmailTemplateApi, request.payload);
  if (template.emailTemplateTypeId === 3) {
    yield call(downloadWitnessInfo, template.state === "MI");
  }

  if (
    template.shouldDownloadActionListDocument === true &&
    request.payload.fileName !== undefined &&
    request.payload.fileName !== ""
  ) {
    debounce(downloadActionListDocument(request.payload?.fileName ?? ""), 300);
  }

  if (
    template.supportingDocuments !== undefined &&
    template.supportingDocuments.length > 0
  ) {
    for (let i = 0; i < template.supportingDocuments.length; i++) {
      yield call(
        downloadSupportingDocument,
        template.supportingDocuments[i].fileName
      );
    }
  }

  yield put({
    type: getType(getEmailTemplateActions.success),
    payload: template,
  });
  yield put({
    type: getType(openReachOutEmailTemplateDialog),
    payload: false,
  });
}

function* handleGetEditEmailTemplate(
  request: ReturnType<typeof getEditEmailTemplateActions.request>
) {
  if (isAuthorized()) {
    const storeTemplate: EmailTemplateDto = yield select(
      (state) => state.emailTemplates.template
    );

    const template: EmailTemplateDto =
      storeTemplate !== undefined && storeTemplate.id === request.payload
        ? storeTemplate
        : yield call(getEmailTemplateApi, { request: { id: request.payload } });

    yield put({
      type: getType(getEditEmailTemplateActions.success),
      payload: template,
    });
  }
}

function* handleUpdateEmailTemplate(
  request: ReturnType<typeof updateEmailTemplateActions.request>
) {
  const updatedEmailTemplate: EmailTemplateDto = yield call(
    updateEmailTemplateApi,
    request.payload
  );
  const storeEmailTemplates: EmailTemplateDto[] = yield select(
    (state) => state.emailTemplates.templateList
  );
  let emailTemplates = JSON.parse(
    JSON.stringify(storeEmailTemplates)
  ) as EmailTemplateDto[];
  let updatedEmailTemplateIndex = emailTemplates.findIndex(
    (c) => c.id === updatedEmailTemplate.id
  );

  if (updatedEmailTemplateIndex !== -1) {
    emailTemplates[updatedEmailTemplateIndex] = updatedEmailTemplate;
  }

  yield put({
    type: getType(getEditEmailTemplateActions.success),
    payload: updatedEmailTemplate,
  });
  yield put({
    type: getType(getEmailTemplatesActions.success),
    payload: emailTemplates,
  });
}

function* handleCreateEmailTemplate(
  request: ReturnType<typeof createEmailTemplateActions.request>
) {
  const createdEmailTemplate: EmailTemplateDto = yield call(
    createEmailTemplateApi,
    request.payload
  );
  const storeEmailTemplates: EmailTemplateDto[] = yield select(
    (state) => state.emailTemplates.templateList
  );
  let EmailTemplates = JSON.parse(
    JSON.stringify(storeEmailTemplates)
  ) as EmailTemplateDto[];
  EmailTemplates.push(createdEmailTemplate);

  yield put({
    type: getType(getEditEmailTemplateActions.success),
    payload: createdEmailTemplate,
  });

  yield put({
    type: getType(getEmailTemplatesActions.success),
    payload: EmailTemplates,
  });
}

function* watchGetEmailTemplatesRequest() {
  try {
    yield takeLatest(
      getType(getEmailTemplatesActions.request),
      handleGetEmailTemplates
    );
  } catch (err) {}
}

function* watchGetEmailTemplateRequest() {
  try {
    yield takeLatest(
      getType(getEmailTemplateActions.request),
      handleGetEmailTemplate
    );
  } catch (err) {}
}

function* watchGetEditEmailTemplateRequest() {
  try {
    yield takeLatest(
      getType(getEditEmailTemplateActions.request),
      handleGetEditEmailTemplate
    );
  } catch (err) {}
}

function* watchEmailTemplateUpdateRequest() {
  try {
    yield takeLatest(
      getType(updateEmailTemplateActions.request),
      handleUpdateEmailTemplate
    );
  } catch (err) {}
}

function* watchEmailTemplateCreateRequest() {
  try {
    yield takeLatest(
      getType(createEmailTemplateActions.request),
      handleCreateEmailTemplate
    );
  } catch (err) {}
}

export default [
  watchGetEmailTemplatesRequest,
  watchGetEmailTemplateRequest,
  watchGetEditEmailTemplateRequest,
  watchEmailTemplateUpdateRequest,
  watchEmailTemplateCreateRequest,
];
