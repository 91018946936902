import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { TableCard, ClickableListCardContent } from '../../../common/styles/styles';
import { DeleteLink } from '../claims.styles';
import { EligibilityEventDto } from '../claims.dtos';
import moment from 'moment';
import { rootSelector } from '../../../common/selectors/selectors';
import DeleteDialog from '../dialogs/deleteEventDialog';


interface EligibilityEventCardProps {
  dto: EligibilityEventDto,
    handleAddNewEligibilityEvent: (event: EligibilityEventDto | undefined) => void,
    isClientUser: boolean,
    isReadOnly: boolean,
    isBatchPayment?: boolean,
}

const EligibilityEventCard: React.FC<EligibilityEventCardProps> = (props: EligibilityEventCardProps) => {
    const { dto, handleAddNewEligibilityEvent, isClientUser, isReadOnly, isBatchPayment } = props;
  const eligibilityCodes = rootSelector(state => state.codes.eligibilityCodes);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleDeleteEvent = () => {
    if (!isClientUser) {
      setDialogOpen(true);
    }
  }

  const handleCancelDialog = () => { setDialogOpen(false); }


  return (
    <>
      <DeleteDialog open={dialogOpen} onCancel={handleCancelDialog} eventId={dto.id ?? ''} isAppellate={false} isClientUser={isClientUser} isReadOnly={isReadOnly} />
          <TableCard raised>
              <ClickableListCardContent data-cy={`eligibility_list_card_link_${dto.comments}`} onClick={isClientUser === true || isBatchPayment === true ? () => null : () => { handleAddNewEligibilityEvent(dto); }}>
          <Grid container spacing={1}>

            <Grid item xs={12} sm={12} md={6} lg={3}>
              <Typography variant="body2" color="textPrimary">Start Date of Ineligibility Period</Typography>
              <Typography variant="body2" color="secondary">{moment(dto.ineligibleStartDate).format('yyyy-MM-DD') || "None on File"}</Typography>
              <Typography variant="body2" color="textPrimary">Issue</Typography>
              <Typography variant="body2" color="secondary">{eligibilityCodes.find(o => o.id === dto.eligibilityCodeId)?.name || 'None on File'}</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={3}>
              <Typography variant="body2" color="textPrimary">End Date of Ineligibility Period</Typography>
              <Typography variant="body2" color="secondary">{moment(dto.ineligibleEndDate).format('yyyy-MM-DD') || "None on File"}</Typography>
              <Typography variant="body2" color="textPrimary">Comments</Typography>
              <Typography variant="body2" color="secondary">{dto.comments || 'None on file'}</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={2}>
              <Typography variant="body2" color="textPrimary">Case #</Typography>
              <Typography variant="body2" color="secondary">{dto.caseNumber || 'None on file'}</Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={2}>
              <Typography variant="body2" color="textPrimary">Created Date</Typography>
              <Typography variant="body2" color="secondary">{moment(dto.createdDate).format('yyyy-MM-DD') || "None on File"}</Typography>
            </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={2} hidden={isClientUser || isBatchPayment || isReadOnly} >
              <Typography variant="body2" color="textPrimary">Created By</Typography>
              <Typography variant="body2" color="secondary">{dto.createdBy || ''}</Typography>
              <DeleteLink component={Button} onClick={(event: any) => { event.stopPropagation(); handleDeleteEvent(); }} data-cy='' color="secondary">
                DELETE
              </DeleteLink>
            </Grid>

          </Grid>
        </ClickableListCardContent>
      </TableCard>
    </>
  )
};

export default EligibilityEventCard;
