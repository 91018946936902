import React from 'react';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
// material ui
import { Grid, Typography, Button, Collapse, Box } from '@material-ui/core';
import { ClaimDto, EligibilityEventDto } from '../claims.dtos';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import EligibilityEventCard from '../components/eligibilityEventCard';
import EligibilityEventDialog from '../dialogs/eligibilityEventDialog';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { getEligibilityCodesActions } from '../../settings/codes/codes.actions'
import { rootSelector } from '../../../common/selectors/selectors';
import { getEligibilityEventsActions } from '../actions';
import { SmallIconButton, NoDateRangeText } from '../claims.styles';


interface Props extends React.HTMLProps<React.ReactNode> {
  isClientUser: boolean;
  isReadOnly: boolean;
  isBatchPayment?: boolean;
  claim: ClaimDto;
}

const EligibilityPanel: React.FC<Props> = (
  {
    isClientUser,
    isReadOnly,
    isBatchPayment,
    claim,
  }) => {
  const dispatch = useDispatch();

  const systemEvents = rootSelector(state => state.claims.eligibilityEvents); 

  const [expanded, setExpanded] = React.useState(true);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [activeEvent, setActiveEvent] = React.useState(undefined as EligibilityEventDto | undefined);
  const [events, setEvents] = React.useState([] as EligibilityEventDto[]);


  React.useEffect(() => {
    setActiveEvent(undefined);
    dispatch({ type: getType(getEligibilityCodesActions.request) });

    if (claim && claim.id !== undefined && claim.id !== '')
      dispatch({ type: getType(getEligibilityEventsActions.request), payload: claim.id });
  }, [dispatch, claim]);

  React.useEffect(() => setEvents(systemEvents), [systemEvents]);


  const handleAddNewEligibilityEvent = (event: EligibilityEventDto | undefined) => {
    if (isClientUser === false) {
      setActiveEvent(event);
      setDialogOpen(true);
    }
  }


  const handleExpandChange = () => setExpanded(!expanded);
  const handleCancelDialog = () => { setDialogOpen(false); }


  return (
    <>
      {dialogOpen &&
        <EligibilityEventDialog
        isReadOnly={isReadOnly}
        isClientUser={isClientUser}
        open={dialogOpen}
        onCancel={handleCancelDialog}
        existingEligibilityEvent={activeEvent}
        byb={claim.benefitYearBeginningDate}
        bye={claim.benefitYearEndingDate}
      />
          }
      <Grid container alignItems="center">

        <Grid item md={12} lg={1}>
          <Button onClick={() => handleExpandChange()} data-cy="eligibility_event_expandList">
            {expanded ? <ExpandLess fontSize="large" /> : <ExpandMore fontSize="large" />}
          </Button>
        </Grid>

        <Grid item md={12} lg={2}>
          <Typography variant="subtitle1" color="textSecondary" display="block">Eligibility Events</Typography>
        </Grid>
        
              <Grid item xs={1} md={1} lg={4} hidden={isClientUser === true || isReadOnly === true || isBatchPayment === true}>
            <SmallIconButton title="Add Eligibility Event" onClick={() => handleAddNewEligibilityEvent(undefined)} color="secondary" data-cy="eligibility_event_add_button">
              <AddBoxRoundedIcon />
            </SmallIconButton>
        </Grid>

      </Grid>
      <Collapse in={expanded} >
        <Grid container spacing={2}>
          {(events
            .length !== 0) ?
            events
              .map((o, index) => (
                <Grid item xs={12} key={'eligibility_event_' + index}>
                      <EligibilityEventCard isReadOnly={isReadOnly} isClientUser={isClientUser} dto={o} isBatchPayment={isBatchPayment} handleAddNewEligibilityEvent={handleAddNewEligibilityEvent} />
                </Grid>
              ))
            : <Grid item xs={12}><NoDateRangeText variant="subtitle1" color="secondary">No records found.</NoDateRangeText></Grid>}
        </Grid>
      </Collapse>
    </>
  )
}
export default EligibilityPanel;