import React from 'react';
import { FormikHandlers, FormikErrors, FormikHelpers, getIn } from 'formik';
import { useDispatch } from 'react-redux';
// material ui
import { Grid, Button } from '@material-ui/core';
import Label from '../../../common/components/label';
import { ClaimDto, ContactIdDto, HearingContactsDto } from '../claims.dtos';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { BusinessUnitDto, CompanyDto } from '../../../features/company/dtos';
import { rootSelector } from '../../../common/selectors/selectors';
import { ContactInformationDto } from '../../../common/dtos/contactInformation';
import { getContactInformationActions } from '../../../common/actions/contactInformation.actions';
import { getType } from 'typesafe-actions';
import { IndentGrid, PaddedTextField } from '../../../common/styles/styles';
import { ClaimMailLink, MHAMailicon } from '../claims.styles';

interface Props extends React.HTMLProps<React.ReactNode> {
  values: ClaimDto;
  errors: FormikErrors<ClaimDto>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  setShouldBlockNavigation: (o: boolean) => void;
  setFieldValue: FormikHelpers<ClaimDto>['setFieldValue'];
  handleEmployerNameUpdates: (EmployerName: string) => void;
  handleContactEmailUpdates: (email: string) => void;
  isClientUser: boolean;
  isReadOnly: boolean;
  isQuickEdit: boolean;
  handleGetEmailPrompt?: (email?: string) => void;
  hearingContactOptions?: ContactIdDto[];
  systemContactInformation: ContactInformationDto[];
}

const EmployerDetails: React.FC<Props> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  setShouldBlockNavigation,
  setFieldValue,
  isQuickEdit,
  handleEmployerNameUpdates,
  isClientUser,
  handleContactEmailUpdates,
  isReadOnly,
  handleGetEmailPrompt,
  hearingContactOptions,
  systemContactInformation,
}) => {
  const dispatch = useDispatch();

  const systemCompaniesList = rootSelector(
    (state) => state.companies.companiesList
  );

  const [companies, setCompanies] = React.useState<CompanyDto[]>([]);

  React.useEffect(() => {
    if (systemContactInformation[0]?.primaryEmail !== undefined) {
      setFieldValue(
        'employerContactEmail',
        systemContactInformation[0].primaryEmail ?? ''
      );
      let emailListString =
        systemContactInformation?.map((m) => m.primaryEmail)?.join(',') ?? '';
      handleContactEmailUpdates(emailListString);
    }
    if (systemContactInformation[0]?.phoneNumber !== undefined) {
      setFieldValue(
        'employerContactPhone',
        systemContactInformation[0].phoneNumber ?? ''
      );
    }
  }, [systemContactInformation]);

  React.useEffect(() => {
    let companyList: CompanyDto[] = JSON.parse(
      JSON.stringify(systemCompaniesList)
    );
    setCompanies(
      companyList.filter((f) => f.name !== undefined && f.name.length !== 0)
    );
  }, [systemCompaniesList]);

  React.useEffect(() => {
    if (values.employerId !== undefined && values.employerId !== '')
      dispatch({
        type: getType(getContactInformationActions.request),
        payload: values.employerId,
      });
  }, [values.employerId]);

  React.useEffect(() => {
    if (
      values.hearingContacts === undefined ||
      values.hearingContacts.length === 0
    ) {
      let employer = systemCompaniesList.find(
        (f) => f.companyId === values.employerId
      );
      if (employer !== undefined) {
        let contacts = hearingContactOptions?.filter((f) =>
          f.contactEmployers?.some(
            (contactEmployer) =>
              contactEmployer.name === employer?.name &&
              contactEmployer.businessUnits?.some(
                (contactBusinessUnit) =>
                  contactBusinessUnit.id === values.businessUnit?.id
              )
          )
        );

        if (contacts !== undefined && contacts.length !== 0)
          setFieldValue(
            'hearingContacts',
            contacts.map((m) => ({ id: m.contactId }))
          );
      }
    }
  }, [values, hearingContactOptions, systemCompaniesList]);

  const handleSelectHearingContacts = (
    hearingContacts: HearingContactsDto[]
  ) => {
    setFieldValue('hearingContacts', hearingContacts);
  };

  return (
    <IndentGrid container item xs={12} spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <Autocomplete
          id='employerName'
          options={companies}
          getOptionLabel={(option) => (option.name ?? '').toUpperCase()}
          value={
            companies.find((o) => o.companyId === values.employerId) || null
          }
          disabled={isClientUser || isReadOnly}
          autoComplete
          onChange={(_e: any, v: CompanyDto | null) => {
            setFieldValue('employerId', v?.companyId ?? '');
            handleEmployerNameUpdates(v?.companyId ?? '');
            setFieldValue('businessUnit', '');
            setFieldValue('selectedBusinessUnit', '');
            handleSelectHearingContacts(
              hearingContactOptions === undefined
                ? ([] as HearingContactsDto[])
                : hearingContactOptions
                    ?.filter((hearingContactOption) =>
                      hearingContactOption.contactEmployers?.some(
                        (contactEmployer) =>
                          contactEmployer.companyId === v?.companyId
                      )
                    )
                    .map((hearingContactOption) => {
                      return {
                        id: hearingContactOption.contactId,
                      } as HearingContactsDto;
                    })
            );
          }}
          renderInput={(params) => (
            <PaddedTextField
              {...params}
              id='employerName'
              size='small'
              margin='none'
              disabled={isClientUser || isReadOnly}
              value={values.employerId || ''}
              label='Employer'
              onBlur={(e) => {
                handleBlur(e);
                setShouldBlockNavigation(true);
              }}
              error={errors.employerId !== undefined}
              helperText={errors.employerId && errors.employerId}
              data-cy='claim_employer_employerName'
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} lg={4}>
        <Autocomplete
          id='employerId'
          options={companies}
          getOptionLabel={(option) => option.suta ?? '1'}
          autoComplete
          disabled={isClientUser || isReadOnly}
          value={
            companies.find((o) => o.companyId === values.employerId) || null
          }
          onChange={(_e: any, v: CompanyDto | null) => {
            setFieldValue('employerId', v?.companyId ?? '');
            handleEmployerNameUpdates(v?.companyId ?? '');
            setFieldValue('businessUnit', '');
            setFieldValue('selectedBusinessUnit', '');
            handleSelectHearingContacts(
              hearingContactOptions === undefined
                ? ([] as HearingContactsDto[])
                : hearingContactOptions
                    ?.filter((hearingContactOption) =>
                      hearingContactOption.contactEmployers?.some(
                        (contactEmployer) =>
                          contactEmployer.companyId === v?.companyId
                      )
                    )
                    .map((hearingContactOption) => {
                      return {
                        id: hearingContactOption.contactId,
                      } as HearingContactsDto;
                    })
            );
          }}
          renderInput={(params) => (
            <PaddedTextField
              {...params}
              id='employerSuta'
              size='small'
              margin='none'
              value={values.employerId || ''}
              label='SUTA'
              InputLabelProps={{ required: true }}
              disabled={isClientUser || isReadOnly}
              onBlur={(e) => {
                handleBlur(e);
                setShouldBlockNavigation(true);
              }}
              error={errors.employerId !== undefined}
              helperText={errors.employerId && errors.employerId}
              data-cy='claim_employer_suta'
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <Autocomplete
          id='stateCode'
          options={companies}
          getOptionLabel={(option) => option.stateCode ?? ''}
          value={
            companies.find((o) => o.companyId === values.employerId) || null
          }
          disabled
          autoComplete
          onChange={(_e: any, v: CompanyDto | null) => {
            setFieldValue('stateCode', v?.stateCode ?? '');
          }}
          renderInput={(params) => (
            <PaddedTextField
              {...params}
              id='stateCode'
              size='small'
              margin='none'
              disabled
              value={values.stateCode || ''}
              label='State'
              onBlur={(e) => {
                handleBlur(e);
              }}
              error={Boolean(getIn(errors, 'stateCode'))}
              helperText={
                getIn(errors, 'stateCode') && getIn(errors, 'stateCode')
              }
              data-cy='claim_employer_stateCode'
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={8}>
        <Autocomplete
          id='businessUnit'
          options={[
            ...(companies.find(
              (company) => company.companyId === values.employerId
            )?.businessUnitList ?? []),
            //Include the current selectedBusinessUnit as a dropdown option if selectedBusinessUnit is different than the current business unit names on the claim's employer
            ...(values.selectedBusinessUnit &&
            values.selectedBusinessUnit !== values.businessUnit?.name
              ? [
                  {
                    id: values.businessUnit?.id,
                    name: values.selectedBusinessUnit,
                    isActive: true,
                  },
                ]
              : []),
          ]}
          autoComplete
          getOptionLabel={(o: BusinessUnitDto) => o.name ?? ''}
          disabled={isClientUser || isReadOnly}
          value={{
            id: values.businessUnit?.id,
            name:
              values.selectedBusinessUnit ?? values.businessUnit?.name ?? '',
            isActive: true,
          }}
          onChange={(_e: any, value: BusinessUnitDto | null) => {
            setFieldValue('businessUnit', value);
            setFieldValue('selectedBusinessUnit', value?.name);
          }}
          renderInput={(params) => (
            <PaddedTextField
              {...params}
              id='businessUnit'
              size='small'
              margin='none'
              disabled={isClientUser || isReadOnly}
              label='Business Unit'
              name='businessUnit'
              onChange={(e) => {
                setShouldBlockNavigation(true);
              }}
              onBlur={(e) => {
                handleBlur(e);
                setShouldBlockNavigation(true);
              }}
              error={Boolean(errors.businessUnit)}
              helperText={Boolean(errors.businessUnit) && errors.businessUnit}
              data-cy='claim_employer_businessUnit'
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid item xs={12} lg={4}>
        <PaddedTextField
          id='employeeId'
          size='small'
          margin='none'
          value={values.employeeId || ''}
          label='Employee ID'
          disabled={isClientUser || isReadOnly}
          name='employeeId'
          onChange={(e) => {
            handleChange(e);
          }}
          onBlur={(e) => {
            handleBlur(e);
            setShouldBlockNavigation(true);
          }}
          error={Boolean(getIn(errors, 'employeeId'))}
          helperText={
            getIn(errors, 'employeeId') && getIn(errors, 'employeeId')
          }
          data-cy='claim_employer_employeeId'
          fullWidth
        />
      </Grid>

      <Grid hidden={!isQuickEdit} item xs={12}>
        <Label>
          Hearing Contact
          <ClaimMailLink
            component={Button}
            title='Mail Hearing Contact'
            onClick={() => {
              if (handleGetEmailPrompt)
                handleGetEmailPrompt(
                  hearingContactOptions
                    ?.filter((o) =>
                      values.hearingContacts
                        ?.map((m) => m.id)
                        .includes(o.contactId)
                    )
                    ?.map((e) => e.email)
                    ?.join(',') ?? ''
                );
            }}
          >
            <MHAMailicon />
            &nbsp;
          </ClaimMailLink>
        </Label>
        <Autocomplete
          id='selectedHearingContacts'
          options={
            hearingContactOptions?.filter((hearingContactOption) =>
              hearingContactOption.contactEmployers?.some(
                (contactEmployer) =>
                  contactEmployer.companyId === values.employerId
              )
            ) as ContactIdDto[]
          }
          autoComplete
          getOptionLabel={(o: ContactIdDto) => o.name ?? ''}
          multiple
          disabled={isClientUser || isReadOnly}
          value={hearingContactOptions?.filter((o) =>
            values.hearingContacts.map((m) => m.id).includes(o.contactId)
          )}
          onChange={(_e: any, v: ContactIdDto[] | null) => {
            handleSelectHearingContacts(
              v === null
                ? ([] as HearingContactsDto[])
                : v.map((o) => {
                    return { id: o.contactId } as HearingContactsDto;
                  })
            );
          }}
          renderInput={(params) => (
            <PaddedTextField
              {...params}
              id='selectedHearingContacts'
              size='small'
              name='selectedHearingContacts'
              margin='none'
              aria-label='Hearing Contact'
              onBlur={(e) => {
                handleBlur(e);
                setShouldBlockNavigation(true);
              }}
              data-cy='claim_information_hearingContacts'
              fullWidth
            />
          )}
        />
      </Grid>
    </IndentGrid>
  );
};

export default EmployerDetails;
