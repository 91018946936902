import { FormikHelpers } from 'formik';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment, { Moment } from 'moment';


export enum day {
  sunday,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday
};

export enum dateCompareType {
  start,
  end,
  byb,
  bye
};

/**
 * Sets and compares dates
 * parameter. 
 * @param controllDate: MaterialUiPickersDate | Date | null | undefined | string (Date user is controlling.)
 * @param controlledDate: Date | undefined (Date that may be effected.)
 * @param setFieldValue: FormikHelpers<any>["setFieldValue"] (Formik helper to update form dates.)
 * @param controllName: string (Form field name being changed.)
 * @param controlledName: string (Form field name that may be effected )
 * @param compareType: dateCompareType (Determines how the effected date may change.)
 */
export const setCompareDate = (
  controllDate: MaterialUiPickersDate | Date | null | undefined | string,
  controlledDate: Date | undefined,
  setFieldValue: FormikHelpers<any>["setFieldValue"],
  controllName: string,
  controlledName: string,
  compareType: dateCompareType
): void => {
  if (controllDate !== undefined && controllDate !== null && ((controllDate as moment.Moment).isValid())) {
    setFieldValue(controllName, controllDate);
    var newDate: MaterialUiPickersDate | Date | null | undefined | string;
    switch (compareType) {
      case dateCompareType.byb:
        let bybDate = moment(controllDate).add(1, "year");
        while (bybDate.day() !== day.saturday) {
          bybDate.add(-1, "day");
        };
        newDate = bybDate.utc().toDate();
        break;
      case dateCompareType.bye:
        let byeDate = moment(controllDate).add(-1, "year");
        while (byeDate.day() !== day.sunday) {
          byeDate.add(1, "day");
        };
        newDate = byeDate.utc().toDate();
        break;
      default:
        newDate = controllDate;
        break;
    }

    if (controlledDate === null || controlledDate === undefined) setFieldValue(controlledName, newDate);
    else if (controlledDate && controllDate && moment(controllDate).format('yyyy-MM-DD') !== '0001-01-01') {
      switch (compareType) {
        case dateCompareType.start:
          if (moment(controllDate).diff(controlledDate) > 0) setFieldValue(controlledName, newDate);
          break;
        case dateCompareType.end:
          if (moment(controllDate).diff(controlledDate) < 0) setFieldValue(controlledName, newDate);
          break;
        case dateCompareType.byb:
        case dateCompareType.bye:
          setFieldValue(controlledName, newDate);
          break;
      }
      
    }
  }    
};

/**
 * Compares dates and returns the controlled date.
 * parameter. 
 * @param controllDate: MaterialUiPickersDate | Date | null | undefined | string (Date user is controlling.)
 * @param controlledDate: Date | undefined (Date that may be effected.)
 * @param compareType: dateCompareType (Determines how the effected date may change.)
 */
export const getCompareDate = (
  controllDate: MaterialUiPickersDate | Date | null | undefined | string,
  controlledDate: Date | undefined,
  compareType: dateCompareType
): Date | undefined => {
  if (controllDate !== undefined && controllDate !== null && (moment(controllDate).isValid())) {
    var newDate: MaterialUiPickersDate | Date | null | undefined | string;
    switch (compareType) {
      case dateCompareType.byb:
        let bybDate = moment(controllDate).add(1, "year");
        while (bybDate.day() !== day.saturday) {
          bybDate.add(-1, "day");
        };
        newDate = bybDate.utc().toDate();
        break;
      case dateCompareType.bye:
        let byeDate = moment(controllDate).add(-1, "year");
        while (byeDate.day() !== day.sunday) {
          byeDate.add(1, "day");
        };
        newDate = byeDate.utc().toDate();
        break;
      default:
        newDate = controllDate;
        break;
    }

    if (controlledDate === null || controlledDate === undefined) return moment(newDate).toDate();
    else if (controlledDate && controllDate && moment(controllDate).format('yyyy-MM-DD') !== '0001-01-01') {
      switch (compareType) {
        case dateCompareType.start:
          if (moment(controllDate).diff(controlledDate) > 0) return moment(newDate).toDate();
          
        case dateCompareType.end:
          if (moment(controllDate).diff(controlledDate) < 0) return moment(newDate).toDate();
          
        case dateCompareType.byb:
        case dateCompareType.bye:
          return moment(newDate).toDate();
      }
    }
    return undefined;
  }
};

