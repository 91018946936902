import {
  call,
  put,
  take,
  race,
  takeLatest,
  putResolve,
} from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { loginApi, refreshAuth, session } from './login.api';
import {
  userLoggedIn,
  userLoginFail,
} from '../../../common/actions/auth.actions';
import {
  storageAuthToken,
  storageRoles,
  storageAuthInformation,
  storageAuthRefreshToken,
  XSRFToken,
} from '../../../common/constants/strings';
import {
  loginActions,
  logoutActions,
  session as sessionStart,
} from './login.actions';

import { TokenDto, LoginDto } from './login.dtos';
import { AuthDto, authInit } from '../../../common/dtos/auth';
import { parse } from 'path';
//import { Session } from 'inspector';

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const getCookie = (): string => {
  var cookie = document.cookie
    .split(';')
    .find((strCookie) => strCookie.indexOf(XSRFToken) >= 0);
  if (cookie != null) {
    return cookie.trim().substring(XSRFToken.length + 1);
  }
  return '';
};

function* authorize(creds: LoginDto) {
  try {
    const token: TokenDto = yield call(loginApi, creds);
    if (token !== undefined && token.isPasswordExpired) {
      yield put({
        type: getType(userLoginFail),
        payload: 'Password Update Required',
      });
      return token;
    } else if (token.authToken && token.authToken.length > 0) {
      const auth: AuthDto = {
        authToken: token.authToken,
        isAdmin: Boolean(token.roles.find((r: string) => r === 'admin')),
        isAuthenticated: true,
        isPasswordExpired: token.isPasswordExpired,
        refreshToken: token.refreshToken,
        roles: token.roles,
        fullName: token.fullName,
        employerId: token.employerId,
        contactId: token.employerId,
        employers: token.employers,
      };

      sessionStorage.setItem(storageAuthToken, token.authToken);
      sessionStorage.setItem(storageRoles, JSON.stringify(token.roles));
      sessionStorage.setItem(storageAuthInformation, JSON.stringify(token));
      sessionStorage.setItem(storageAuthRefreshToken, token.refreshToken);

      yield putResolve({ type: getType(userLoggedIn), payload: auth });

      const startSession: boolean = yield call(session);
      yield putResolve({ type: getType(sessionStart), payload: startSession });

      return token;
    } else if (token.mfaRequired !== undefined && token.mfaRequired) {
      let newToken: TokenDto = JSON.parse(JSON.stringify(token)) as TokenDto;
      newToken.userName = creds.username;
      newToken.userPassword = creds.password;
      yield put({ type: getType(loginActions.success), payload: newToken });
    } else {
      yield put({ type: getType(userLoginFail), payload: 'Login Failed' });
    }
  } catch (error) {
    yield put({ type: getType(userLoginFail), payload: 'Login Failed' });
  }

  return undefined;
}

function* loginAndRefreshToken(creds: ReturnType<typeof loginActions.request>) {
  const token: TokenDto = yield call(authorize, creds.payload);

  if (token !== undefined && token.isPasswordExpired) {
    sessionStorage.setItem(
      storageAuthInformation,
      JSON.stringify({
        ...JSON.parse(sessionStorage.getItem(storageAuthInformation) ?? 'null'),
        isPasswordExpired: token.isPasswordExpired,
      })
    );
    put({ type: getType(userLoginFail), payload: 'Password Update Required' });
  } else if (
    token !== undefined &&
    token.expiresIn !== undefined &&
    token.refreshToken !== undefined
  ) {
    while (true) {
      yield call(delay, token.expiresIn - 1000 * 60 * 3);

      let refreshToken = sessionStorage.getItem(storageAuthRefreshToken) ?? '';
      const newToken: TokenDto = yield call(refreshAuth, refreshToken);

      if (newToken.authToken && newToken.authToken.length > 0) {
        const auth: AuthDto = {
          authToken: newToken.authToken,
          isAdmin: Boolean(newToken.roles.find((r: string) => r === 'admin')),
          isAuthenticated: true,
          isPasswordExpired: token.isPasswordExpired,
          refreshToken: newToken.refreshToken,
          roles: newToken.roles,
          fullName: newToken.fullName,
          employerId: token.employerId,
          contactId: token.employerId,
          employers: token.employers,
        };

        sessionStorage.setItem(storageAuthToken, newToken.authToken);
        sessionStorage.setItem(storageRoles, JSON.stringify(newToken.roles));
        sessionStorage.setItem(
          storageAuthInformation,
          JSON.stringify(newToken)
        );
        sessionStorage.setItem(storageAuthRefreshToken, token.refreshToken);

        yield put({ type: getType(userLoggedIn), payload: auth });
      } else {
        put({ type: getType(userLoginFail), payload: 'Unauthorized' });
      }
    }
  }
}

function* handleLogout() {
  yield put({ type: getType(userLoggedIn), payload: authInit });

  sessionStorage.removeItem(storageAuthToken);
  sessionStorage.removeItem(storageRoles);
  sessionStorage.removeItem(storageAuthInformation);
  sessionStorage.clear();

  window.location.reload();
}

function* watchLoginRequest() {
  try {
    while (true) {
      const creds: ReturnType<typeof loginActions.request> = yield take(
        getType(loginActions.request)
      );
      yield race([
        take(getType(logoutActions.request)),
        call(loginAndRefreshToken, creds),
      ]);
    }
  } catch (err) {}
}

function* watchLogoutRequest() {
  yield takeLatest(getType(logoutActions.request), handleLogout);
}

export default [watchLoginRequest, watchLogoutRequest];
