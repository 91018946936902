import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors, getIn } from 'formik';
import { Dialog, DialogTitle, DialogActions, Grid, TextField } from '@material-ui/core';
import { CompanyDto } from '../dtos';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GoverningDistrictDto } from '../../account/profile/profile.dtos';
import { ButtonGrid, IssueLabel, StyledCancelButton, StyledCardContent, StyledSubmitButton, WideDialogContent } from '../../../common/styles/styles';


interface Props extends React.HTMLProps<React.ReactNode> {
  values: CompanyDto;
  errors: FormikErrors<CompanyDto>;
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<CompanyDto>["setFieldValue"];
  stateOptions: GoverningDistrictDto[];
  open: boolean;
  onCancel: () => void;
}

const SelectStatePrompt: React.FC<Props> = (
  {
    values,
    errors,
    handleBlur,
    setFieldValue,
    stateOptions,
    open,
    onCancel,
  }) => {

  React.useEffect(() => {
    if (values.addresses[0]?.stateProvence !== undefined && values.addresses[0]?.stateProvence.length !== 0)
      onCancel();
  }, [values]);

  const saveDialog = () => {
    if (values.addresses[0]?.stateProvence === null) {
      setFieldValue('addresses[0].stateProvence', undefined);
    }
    onCancel();
  }

  const cancelDialog = () => {
    if (values.addresses[0]?.stateProvence !== null) {
      setFieldValue('addresses[0].stateProvence', undefined);
    }
    onCancel();
  }

  const handleChange = (_e: any, v: GoverningDistrictDto | null) => {
    setFieldValue('stateCode', v?.code ?? '');
    setFieldValue('addresses[0].stateProvence', v?.name ?? '');
  }

  return (
    <Dialog
      open={open}
      data-cy="statePrompt_dialog"
    >
      <Grid container alignItems="center" data-cy="statePrompt_container">
        <Grid item xs={12}>
          <DialogTitle style={{ marginLeft: 4 }}>Enter a State</DialogTitle>
          <IssueLabel>Please select the state of this employer before entering a SUTA</IssueLabel>
          <WideDialogContent>
            <StyledCardContent>
              <Grid container>
                <Grid item xs={12}>
                  <Autocomplete
                    id="addresses[0].stateProvence"
                    options={stateOptions}
                    getOptionLabel={option => option.name}
                    value={stateOptions.find(o => o.name === values.addresses[0]?.stateProvence) || undefined}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={values.addresses[0].stateProvence || ''}
                        label="State/Province"
                        id='addresses[0].stateProvence'
                        size="small"
                        margin="none"
                        onBlur={handleBlur}
                        error={Boolean(getIn(errors, 'addresses[0].stateProvence'))}
                        helperText={getIn(errors, 'addresses[0].stateProvence') && getIn(errors, 'addresses[0].stateProvence')}
                        inputProps={{ ...params.inputProps, autoComplete: 'new-password' }}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </StyledCardContent>
            <br></br>
            <DialogActions>
              <ButtonGrid container>
                <StyledSubmitButton
                  type="submit"
                  color="primary"
                  onClick={saveDialog}
                  data-cy="statePrompt_submitButton"
                >
                  Save
                  </StyledSubmitButton>
                <StyledCancelButton
                  onClick={cancelDialog}
                  data-cy="statePrompt_cancelButton"
                >CANCEL
                  </StyledCancelButton>
              </ButtonGrid>
            </DialogActions>
          </WideDialogContent>
        </Grid>
      </Grid>
    </Dialog>
  );

}

export default SelectStatePrompt;
