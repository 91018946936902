import { CardContent, Grid, GridSize, InputLabelProps, InputProps } from '@material-ui/core';
import { FormikErrors, FormikHandlers, FormikHelpers, getIn } from 'formik';
import React from 'react';
import Label from '../../../../common/components/label';
import { IMc } from '../../../../common/dtos/McDto';
import { PaddedBottomTextField, TextInputSpan } from '../../../../common/styles/styles';
import { NavCard } from '../../documents.styles';

interface Props {
  label: string;
  label2?: string | undefined;
  name: string;
  value: string | undefined;
  errors: FormikErrors<IMc>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  isReadOnly: boolean;
  size?: GridSize;
  max?: number | undefined;
  inputlabelprops?: Partial<InputLabelProps>;
  inputprops?: Partial<InputProps>;
  setFieldValue: FormikHelpers<IMc>["setFieldValue"];
}

const SiTextAreaField: React.FC<Props> = (
  {
    label,
    label2,
    name,
    value,
    errors,
    handleChange,
    handleBlur,
    isReadOnly,
    max,
    size = 12,
    inputlabelprops,
    inputprops,
    setFieldValue,
  }) => {

  return (
    <Grid item xs={12} sm={12} md={size} lg={size}>
      <Label>{label}</Label>
      {label2 !== undefined &&
        <Label>{label2}</Label>
      }

      <NavCard>
        <CardContent>
          <Grid item xs={12}>
            <PaddedBottomTextField
              size="small"
              margin="none"
              disabled={isReadOnly}
              value={value || ''}
              name={name}
              multiline
              onChange={e => { e.target.value !== undefined && e.target.value.length === 0 ? setFieldValue(name, undefined) : handleChange(e); }}
              onBlur={handleBlur}
              error={Boolean(getIn(errors, name))}
              helperText={getIn(errors, name) && getIn(errors, name)}
              data-cy={`sidesResponse_${name}`}
              fullWidth
              InputLabelProps={inputlabelprops}
              InputProps={inputprops}
              inputProps={{ maxLength: max }}
            />
            {(max !== undefined && value !== undefined && value.length !== 0) && <TextInputSpan>({value === undefined ? undefined : value.length}/{max})</TextInputSpan>}
          </Grid>
        </CardContent>
      </NavCard>
    </Grid>
  );

}

export default SiTextAreaField;