import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors, } from 'formik';

// material ui
import { Grid, TextField, MenuItem, FormControlLabel, } from '@material-ui/core';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ClientQuestionnaireDto, } from '../../dto';
import AttachmentsDropzone from '../../../../common/components/attachmentDropzone'
import { MHARadio, PaddedTextField } from '../../../../common/styles/styles';
import { radioButtonBooleanOptions, whoInitiatedHoursReductionOptions } from '../../constants/options';


interface HoursReducedComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
  attachments: File[];
  onAttachmentDelete: (file: File) => void;
  onAttachmentUpload: (files: File[]) => void;
}

const HoursReducedComponent: React.FC<HoursReducedComponentProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation,
    attachments,
    onAttachmentDelete,
    onAttachmentUpload
  }) => {

  return (
    <React.Fragment>
      <Grid item xs={12}>How many hours was the claimant originally working per week?</Grid>
      <Grid item xs={4}>
        <TextField
          id="hoursWorkedOriginal"
          size="small"
          margin="none"
          value={values.hoursWorkedOriginal || ''}
          //label="Job Title"
          name="hoursWorkedOriginal"
          multiline
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.hoursWorkedOriginal)}
          helperText={errors.hoursWorkedOriginal && errors.hoursWorkedOriginal}
          data-cy="clientQuestionnaire_hoursWorkedOriginal"
          aria-label="Number of hours worked each week"
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={12}>How many hours will the claimant work each week after the change?</Grid>
      <Grid item xs={4}>
        <TextField
          id="hoursWorked"
          size="small"
          margin="none"
          value={values.hoursWorked || ''}
          //label="Job Title"
          name="hoursWorked"
          multiline
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.hoursWorked)}
          helperText={errors.hoursWorked && errors.hoursWorked}
          data-cy="clientQuestionnaire_hoursWorked"
          aria-label="How many hours will the claimant work?"
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={12}>Who initiated the reduction in hours?</Grid>
      <Grid item md={12} xs={12} sm={12} lg={5}>
        <PaddedTextField
          id="whoInitiatedReductionId"
          size="small"
          margin="none"
          select
          value={values.whoInitiatedReductionId || ''}
          name="whoInitiatedReductionId"
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.whoInitiatedReductionId)}
          helperText={errors.whoInitiatedReductionId && errors.whoInitiatedReductionId}
          data-cy="clientQuestionnaire_whoInitiatedReductionId"
          aria-label="Who initiated the reduction?"

          fullWidth
          SelectProps={{ MenuProps: { disableScrollLock: true } }}
        >
          {whoInitiatedHoursReductionOptions.filter((item) => item.name !== '')
            .map((option, index) => (
              <MenuItem key={option.name + index} value={option.id}>{option.name}</MenuItem>
            ))}
        </PaddedTextField>
      </Grid>
      {values.whoInitiatedReductionId === 2 ?
        <>
          <Grid item xs={12}>Why did claimant reduce their work hours?</Grid>
          <Grid item xs={4}>
            <TextField
              id="hoursReductionReason"
              size="small"
              margin="none"
              value={values.hoursReductionReason || ''}
              multiline
              //label="Job Title"
              name="hoursReductionReason"
              onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
              onBlur={handleBlur}
              error={Boolean(errors.hoursReductionReason)}
              helperText={errors.hoursReductionReason && errors.hoursReductionReason}
              data-cy="clientQuestionnaire_hoursReductionReason"
              aria-label="Reason for hours reduction"
              fullWidth
            />
          </Grid>
          <br />
          <Grid item xs={12}>Was the request made in writing or contained in an email?</Grid>
          <Grid container item xs={12} direction="row">
            {radioButtonBooleanOptions.map((o, index) => (
              <Grid item xs={3} key={o.name + index}>
                <FormControlLabel
                  id="requestInWriting"
                  name="requestInWriting"
                  onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                    setFieldValue('requestInWriting', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? 1);
                    setShouldBlockNavigation(true);
                  }}
                  onBlur={handleBlur}
                  //add errors for validation
                  checked={o.status === values.requestInWriting}
                  value={o.name}
                  control={<MHARadio
                    size="small" />} label={o.name} data-cy="clientQuestionnaire_requestInWriting" />
              </Grid>
            ))}
          </Grid>
          <br />
          {values.requestInWriting && values.requestInWriting === true ?
            <>
              <Grid item xs={12}>Please provide a copy of the claimant's request to reduce their status.</Grid>
              <br />
              <Grid item xs={12}>
                <AttachmentsDropzone
                  attachments={attachments}
                  onDelete={onAttachmentDelete}
                  onDrop={onAttachmentUpload}
                  shouldHaveBox={false}
                />
              </Grid>
              <br />
            </>
            : null}
        </>
        : null}
      <br />
      <Grid item xs={12}>What was the effective date of the change?</Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <KeyboardDatePicker
          margin="normal"
          id="effectiveChangeDate"
          name="effectiveChangeDate"
          //label="Hire Date"
          format="MM/DD/yyyy"
          value={values.effectiveChangeDate || null}
          data-cy="clientQuestionnaire_effectiveChangeDate"
          fullWidth
          onChange={(date: MaterialUiPickersDate) => { setFieldValue('effectiveChangeDate', date); setShouldBlockNavigation(true); }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <br />

    </React.Fragment>
  )
}

export default HoursReducedComponent;
