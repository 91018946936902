import React from 'react';
import NumberFormat from 'react-number-format';

interface NewYorkMaskProps {
    inputRef: (instance: NumberFormat | null) => void,
    name: string,
    onChange: (event: { target: { name: string; value: string; } }) => void,
}

const NewYorkMask: React.FC<NewYorkMaskProps> = (props: NewYorkMaskProps) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <>
            <NumberFormat
                {...other}
                format="##-#####"
                mask="_"
                decimalScale={0}
                fixedDecimalScale={true}
                getInputRef={inputRef}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                isNumericString
            />
        </>
    );

}

export default NewYorkMask;