import React from 'react';
import { isClientPortalUser, isReadOnly } from '../dtos/auth';
import { BottomAppBar, BottomCancelButton, BottomSaveButton, ButtonGrid } from '../styles/styles';


interface Props extends React.HTMLProps<React.ReactNode> {
  cancelClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  submitting: boolean;
  featureType: number;
}

const BottomActionFormBar: React.FC<Props> = (props: Props) => {
  const { cancelClickHandler, submitting, featureType } = props;

  const [showSave, setShowSave] = React.useState(true);

  React.useEffect(() => {
    if (isClientPortalUser() || isReadOnly(featureType)) {
      setShowSave(false);
    }
  }, [isClientPortalUser, isReadOnly]);

  return <BottomAppBar position="fixed">
    <ButtonGrid container>
      {showSave &&
        <BottomSaveButton
          type="submit"
          disabled={submitting}
          data-cy="page_form_saveBottom"
        >Save
          </BottomSaveButton>
      }

      <BottomCancelButton type="button" onClick={cancelClickHandler} data-cy="page_form_cancelBottom">
        {showSave ? 'CANCEL' : 'BACK'}
      </BottomCancelButton>
    </ButtonGrid>
  </BottomAppBar>
}

export default BottomActionFormBar;