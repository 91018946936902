// export enum months {
//     January = 1,
//     February = 2,
//     March = 3,
//     April = 4,
//     May = 5,
//     June = 6,
//     July = 7,
//     August = 8,
//     September = 9,
//     October = 10,
//     November = 11,
//     December = 12,
//   }

//   export enum quarters {
//     First = 1,
//     Second = 4,
//     Third = 7,
//     Fourth = 10,
//   }

export interface Month {
  monthId: number;
  name: string;
}

export const months: Month[] = [
  { monthId: 1, name: 'January' },
  { monthId: 2, name: 'February' },
  { monthId: 3, name: 'March' },
  { monthId: 4, name: 'April' },
  { monthId: 5, name: 'May' },
  { monthId: 6, name: 'June' },
  { monthId: 7, name: 'July' },
  { monthId: 8, name: 'August' },
  { monthId: 9, name: 'September' },
  { monthId: 10, name: 'October' },
  { monthId: 11, name: 'November' },
  { monthId: 12, name: 'December' },
];

export interface Quarter {
  quarterId: number;
  name: string;
}

export const quarters: Quarter[] = [
  { quarterId: 1, name: 'First' },
  { quarterId: 4, name: 'Second' },
  { quarterId: 7, name: 'Third' },
  { quarterId: 10, name: 'Fourth' },
];

const setYears = () => {
  let years: number[] = [];
  for (let i = new Date().getFullYear(); i >= 2000; i--) {
    years.push(i);
  }

  return years;
};

export let years: number[] = setYears();
