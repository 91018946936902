import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { BatchPaymentsDto, ListBatchPaymentDto, MiWamDto } from './dtos';
import {
  getBatchPaymentApi, getBatchPaymentListApi, getClaimPaymentsApi, createBatchPaymentApi,
  updateBatchPaymentApi, updateClaimPaymentsApi, updatePaymentDiscrepancyApi, uploadMiWamImportApi, getMiWamBatchDataApi
} from './api';
import {
  getBatchPaymentsListActions, getBatchPaymentActions, getClaimPaymentsActions, createBatchPaymentActions, updateBatchPaymentActions,
  updateClaimPaymentsActions, resolvePaymentDiscrepancyActions, uploadMiWamImport, getMiWamDataActions
} from './actions';


function* handleGetBatchPaymentList() {
  const storeBatchList: ListBatchPaymentDto[] = yield select(state => state.batchPayments.list);
  const batches: ListBatchPaymentDto[] = (storeBatchList !== undefined && storeBatchList.length !== 0) ? storeBatchList : yield call(getBatchPaymentListApi);

  yield put({ type: getType(getBatchPaymentsListActions.success), payload: batches });
}

function* handleGetBatchPayment(request: ReturnType<typeof getBatchPaymentActions.request>) {
  const batch: BatchPaymentsDto = yield call(getBatchPaymentApi, request.payload);
  yield put({ type: getType(getBatchPaymentActions.success), payload: batch });
}

function* handleUpdateBatchPayment(request: ReturnType<typeof updateBatchPaymentActions.request>) {
  let updatedBatch: BatchPaymentsDto = yield call(updateBatchPaymentApi, request.payload);

  yield put({ type: getType(getBatchPaymentsListActions.success), payload: [] });
  yield handleGetBatchPaymentList();

  const getRequest: ReturnType<typeof getBatchPaymentActions.request> = {
    type: getType(getBatchPaymentActions.request), payload: updatedBatch.id ?? request.payload.id ?? ''
  };
  yield handleGetBatchPayment(getRequest);
}

function* handleCreateBatchPayment(request: ReturnType<typeof createBatchPaymentActions.request>) {
  let createdBatch: BatchPaymentsDto = yield call(createBatchPaymentApi, request.payload);
  yield put({ type: getType(getBatchPaymentActions.success), payload: createdBatch });

  yield put({ type: getType(getBatchPaymentsListActions.success), payload: [] });
  yield handleGetBatchPaymentList();
}

function* handleGetClaimPayments(request: ReturnType<typeof getClaimPaymentsActions.request>) {
  const storePayments: BatchPaymentsDto[] = yield select(state => state.batchPayments.claimPayments);
  const payments: BatchPaymentsDto =
    (storePayments !== undefined &&
      storePayments.length !== 0 &&
      storePayments[0].charges !== undefined &&
      storePayments[0].charges.length !== 0 &&
      storePayments[0].charges[0].claimId === request.payload) ? storePayments : yield call(getClaimPaymentsApi, request.payload);

  yield put({ type: getType(getClaimPaymentsActions.success), payload: payments });
}

function* handleUpdateClaimPayments(request: ReturnType<typeof updateClaimPaymentsActions.request>) {
  const updatedPayments: BatchPaymentsDto[] = yield call(updateClaimPaymentsApi, request.payload);

  yield put({ type: getType(getClaimPaymentsActions.success), payload: updatedPayments });
}

function* handleResolvePaymentDiscrepancy(request: ReturnType<typeof resolvePaymentDiscrepancyActions.request>) {
  let result: boolean = yield call(updatePaymentDiscrepancyApi, request.payload);
  if (result) {
    const storebatch: BatchPaymentsDto = yield select(state => state.batchPayments.batch);
    if (storebatch !== undefined && storebatch.id != undefined) {
      const getRequest: ReturnType<typeof getBatchPaymentActions.request> = { type: getType(getBatchPaymentActions.request), payload: storebatch.id };
      yield handleGetBatchPayment(getRequest);
    }
  }
}

function* handleUploadMiWamImport(request: ReturnType<typeof uploadMiWamImport>) {
  yield call(uploadMiWamImportApi, request.payload);
}

function* handleGetBatchData(request: ReturnType<typeof getMiWamDataActions.request>) {
  const data: MiWamDto[] = yield call(getMiWamBatchDataApi, request.payload);
  yield put({ type: getType(getMiWamDataActions.success), payload: data });
}



function* watchBatchPaymentListGetRequest() {
  try { yield takeLatest(getType(getBatchPaymentsListActions.request), handleGetBatchPaymentList); }
  catch (err) { }
}

function* watchBatchPaymentGetRequest() {
  try { yield takeLatest(getType(getBatchPaymentActions.request), handleGetBatchPayment); }
  catch (err) { }
}

function* watchBatchPaymentUpdateRequest() {
  try { yield takeLatest(getType(updateBatchPaymentActions.request), handleUpdateBatchPayment); }
  catch (err) { }
}

function* watchBatchPaymentCreateRequest() {
  try { yield takeLatest(getType(createBatchPaymentActions.request), handleCreateBatchPayment); }
  catch (err) { }
}

function* watchClaimPaymentsGetRequest() {
  try { yield takeLatest(getType(getClaimPaymentsActions.request), handleGetClaimPayments); }
  catch (err) { }
}

function* watchClaimPaymentsUpdateRequest() {
  try { yield takeLatest(getType(updateClaimPaymentsActions.request), handleUpdateClaimPayments); }
  catch (err) { }
}

function* watchResolvePaymentDiscrepancyRequest() {
  try { yield takeLatest(getType(resolvePaymentDiscrepancyActions.request), handleResolvePaymentDiscrepancy); }
  catch (err) { }
}

function* watchUploadMiWamImportRequest() {
  try { yield takeLatest(getType(uploadMiWamImport), handleUploadMiWamImport); }
  catch (err) { }
}

function* watchGetMiWamDataRequest() {
  try { yield takeLatest(getType(getMiWamDataActions.request), handleGetBatchData); }
  catch (err) { }
}



export default [
  watchBatchPaymentListGetRequest,
  watchBatchPaymentGetRequest,
  watchBatchPaymentUpdateRequest,
  watchBatchPaymentCreateRequest,
  watchClaimPaymentsGetRequest,
  watchClaimPaymentsUpdateRequest,
  watchResolvePaymentDiscrepancyRequest,
  watchUploadMiWamImportRequest,
  watchGetMiWamDataRequest,
];
