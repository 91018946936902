import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getType } from 'typesafe-actions';
import { Prompt } from 'react-router';
import { Grid, Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import BottomActionBar from '../../../../common/components/bottomActionBar';
import { rootSelector } from '../../../../common/selectors/selectors';
import { roleInit, RoleDto } from '../../../../common/dtos/role';
import { systemRoleSettingInit, SystemRoleSettingDto } from '../system.dtos';
import { getRoleSystemSettingsActions, updateRoleSystemSettingsActions } from '../system.actions';
import { admin, requiredError } from '../../../../common/constants/strings';
import { isReadOnly } from '../../../../common/dtos/auth';
import { LargeIconButton, StyledTextField } from '../../../../common/styles/styles';
import { AddButton, SystemSettingsToggleGrid, CancelButton, RolesRadio } from '../system.styles';
import { FeatureTypeEnum } from '../../../../common/enums/featureTypeEnum';


const SystemRoleSettings: React.FC = () => {
  const dispatch = useDispatch();

  const systemRoles = rootSelector(state => state.systemSettings.systemRoleSettings);

  const [savingRoles, setSavingRoles] = React.useState(false);
  const [hideAddRole, setHideAddRole] = React.useState(true);
  const [initialRole, setInitialRole] = React.useState(JSON.parse(JSON.stringify(systemRoleSettingInit)) as SystemRoleSettingDto);
  const [roles, setRoles] = React.useState([] as SystemRoleSettingDto[]);
  const [selectedRoleIndex, setSelectedRoleIndex] = React.useState(0 as number);
  const [shouldBlockNavigation, setShouldBlockNavigation] = React.useState(false);


    const [readOnlyStatus, setReadOnlyStatus] = React.useState(false);


    React.useEffect(() => {
      let s = isReadOnly(FeatureTypeEnum.Settings);
        setReadOnlyStatus(s);

    }, [readOnlyStatus]);

    React.useEffect(() => {
        dispatch({ type: getType(getRoleSystemSettingsActions.request) });
    }, [dispatch]);

  React.useEffect(() => {
    setRoles(JSON.parse(JSON.stringify(systemRoles)));

    if (systemRoles.length > 0) {
      let access = JSON.parse(JSON.stringify(systemRoles[0].accessRoles)) as RoleDto[];
      access.forEach((role) => { role.selected = false; });
      initialRole.accessRoles = access;
      setInitialRole(initialRole);
    }
    setShouldBlockNavigation(false);
  }, [systemRoles, initialRole]);

  const handleCancel = () => {
    window.location.href = "/settings/system";
  }
  const handleSubmit = () => {
    setSavingRoles(true);
    setTimeout(() => { setSavingRoles(false); }, 700);
    dispatch({ type: getType(updateRoleSystemSettingsActions.request), payload: roles });
  }
    const handleAddRoleToggle = () => {
        if (readOnlyStatus === false) {
            setHideAddRole(false);
        }
  }
  const handleRoleSelection = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
    const element = event.target as HTMLInputElement;
    if (element.value !== undefined) {
      const sRoleIndex = roles.findIndex((sr: SystemRoleSettingDto) => sr.role.name === element.value);
      if (sRoleIndex > -1) {
        setSelectedRoleIndex(sRoleIndex);
      }
    }
  }
  const handleRoleAccessSelection = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
    const element = event.target as HTMLInputElement;
    if (element.value !== undefined) {
      let selectedRoleAccess = roles[selectedRoleIndex].accessRoles.find((sra: RoleDto) => sra.name === element.value);
      if (selectedRoleAccess) {
        selectedRoleAccess.selected = checked;
        setRoles(JSON.parse(JSON.stringify(roles)));
      }
    }
    setShouldBlockNavigation(true);
  }

  return <>
    <Box p={2}>
      <Box>
        <Grid container data-cy="settings_userRoleContainer">
          <Grid item xs={11}><Typography variant="subtitle1">User Roles</Typography></Grid>
            <Grid item xs={1} hidden={readOnlyStatus}>
            <LargeIconButton title="Add new user role" data-cy="settings_addNewUserRole" aria-label="Add new user role" color="primary" onClick={handleAddRoleToggle}>
              <AddBoxRoundedIcon />
            </LargeIconButton>
          </Grid>
          <SystemSettingsToggleGrid hidden={hideAddRole} item xs={12}>
            <Formik initialValues={roleInit}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={Yup.object({ name: Yup.string().max(200, 'Must be 200 characters or less').required(requiredError), })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setTimeout(() => { setSubmitting(false); resetForm(); }, 700);
                let systemRoleSettingInitCopy = JSON.parse(JSON.stringify(initialRole));
                systemRoleSettingInitCopy.role = values;
                roles.push(systemRoleSettingInitCopy);
                setRoles(roles);
                setHideAddRole(true);
                setShouldBlockNavigation(true);
              }}>
              {(props) => {
                const { values, touched, errors, isSubmitting, handleChange, handleSubmit, handleReset } = props;
                return (
                  <form onSubmit={handleSubmit} data-cy="settings_AddNewUserRoleElement">
                    <StyledTextField
                      id="role-name"
                      size="small"
                      margin="none"
                      disabled={readOnlyStatus}
                      data-cy="settings_userRolesAddNewField"
                      InputLabelProps={{ required: true }}
                      value={values.name}
                      label="Enter Role Name"
                      name="name"
                      onChange={handleChange}
                      autoFocus
                      error={Boolean(errors.name)}
                      helperText={(errors.name && touched.name) && errors.name}
                    />
                    <AddButton
                      type="submit"
                      data-cy="settings_userRolesSaveRole"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting || readOnlyStatus} >
                      Add
                    </AddButton>
                    <CancelButton
                      type="button"
                      variant="contained"
                      data-cy="settings_userRolesCancelRole"
                      color="primary"
                      onClick={(e) => { handleReset(e); setHideAddRole(true); }}>
                      Cancel
                      </CancelButton>
                  </form>);
              }}
            </Formik>
            <br />
          </SystemSettingsToggleGrid>

          {roles.sort((a, b) => a.role.name.localeCompare(b.role.name)).map((o, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={o.role.name}>
              <FormControlLabel
                checked={index === selectedRoleIndex}
                value={o.role.name}
                data-cy="settings_userRole"
                onChange={handleRoleSelection}
                control={<RolesRadio size="small" />} label={o.role.name} />
            </Grid>
          ))}

        </Grid>
      </Box><br />

      <Box hidden={selectedRoleIndex === -1}>
        <Grid container data-cy="settings_roleAccessContainer">
          <Grid item xs={12}><Typography variant="subtitle1">Role Access</Typography></Grid>

          {roles[selectedRoleIndex]?.accessRoles.map((o, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={o.name}>
              <FormControlLabel
                onChange={handleRoleAccessSelection}
                value={o.name} checked={o.selected}
                data-cy="settings_roleAccess"
                disabled={roles[selectedRoleIndex]!.role.name.toLowerCase() === admin || readOnlyStatus}
                control={<Checkbox size="small" name={o.name} />} label={o.name} />
            </Grid>
          ))}
        </Grid>
      </Box><br />

    </Box>

    <Prompt
      when={shouldBlockNavigation && readOnlyStatus === false}
      message='You have unsaved edits, are you sure you want to leave?'
      />
      <div hidden={readOnlyStatus}>
          <BottomActionBar hidden={readOnlyStatus} cancelClickHandler={handleCancel} submitClickHandler={handleSubmit} submitting={savingRoles} />
          </div>
  </>

}

export default SystemRoleSettings;