export interface DiscrepancyDto {
  id?: string;
  error: number;
}

export interface PaymentsDto {
  id?: string;
  claimId?: string;
  ssn: string;
  chargeAmount?: string;
  actualLiabilityAmount?: string;
  chargeDate?: Date;
  reportedWages?: string;
  discrepancy?: DiscrepancyDto;
  claimant?: string;
}

export interface BatchPaymentsDto {
  id?: string;
  statementDate?: Date;
  fileName?: string;
  reportedWageTypeId: number;
  batchPaymentTypeId: number;
  employerId?: string;
  periodCoveredFromDate?: Date;
  periodCoveredToDate?: Date;
  dueDateTime?: Date;
  charges: PaymentsDto[];
  isComplete: boolean;
  fileUpload?: any;
  needsUpdate?: boolean;
  wagesReported?: string;
  sortOrder?: number;
}

export interface MiWamDto {
  id?: number;
  employerId: string;
  claimantSsn: string;
  processedDate: Date;
  weekEndingDate: Date;
  adjustmentType: string;
  earnedIncome: number;
  amount: number;
  claimantLastName: string;
  claimantFirstInitial: string;
}

export interface GetMiWamDto {
  employerId: number;
  fileName: string;
}

export interface ListBatchPaymentDto {
  id?: string;
  statementDate?: Date;
  label: string;
  periodCoveredToDate?: Date;
  hasErrors: boolean;
  dueDateTime?: Date;
  sortOrder?: number;
}

export interface ResolveDiscrepancyDto {
  id?: string;
  isValid: boolean;
}

export const paymentDtoInit: PaymentsDto = {
  ssn: "",
};
