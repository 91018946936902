import { AxiosError } from 'axios';
import { ApiError } from './apiResponse';
import apiClient from './apiClient';
import { showNotification } from '../actionCreators/dispatchNotification';
import { ClaimantReportRequest } from '../dtos/claimantReportDto';
// endpoints
const url = '/claimants';

export const getClaimantsApi = async (request: ClaimantReportRequest) => {
  return await apiClient()
    .get(
      `${url}?searchText=${request.searchString}&ssn=${request.ssn}&suta=${request.suta}&employeeId=${request.employeeId}`
    )
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response)
          showNotification(axiosError.response.data.error.message, 'error');
      } else showNotification('An error occurred fetching claimants.', 'error');

      throw err;
    });
};
