import * as Yup from 'yup';
import {
  passwordError,
  passwordMaxError,
  passwordRegex,
  requiredError,
} from '../../../common/constants/strings';

export const updatePasswordValidationSchema = Yup.object({
  username: Yup.string().email('Invalid email address').required(requiredError),
  currentPassword: Yup.string().required(requiredError),
  newPassword: Yup.string()
    .min(12, passwordError)
    .max(50, passwordMaxError)
    .matches(passwordRegex, passwordError)
    .notOneOf(
      [Yup.ref('currentPassword'), null],
      'New Password must not match Current Password.'
    )
    .required(requiredError),
  confirmNewPassword: Yup.string()
    .oneOf(
      [Yup.ref('newPassword'), null],
      'Confirm New Password must match New Password.'
    )
    .required(requiredError),
});
