import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors, } from 'formik';

// material ui
import { Grid, TextField, MenuItem, FormControlLabel, } from '@material-ui/core';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ClientQuestionnaireDto, } from '../../dto';
import { MHARadio, PaddedTextField } from '../../../../common/styles/styles';
import { radioButtonBooleanOptions, typeOfLeaveGrantedOptions } from '../../constants/options';


interface LeaveOfAbsenceComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto,
  errors: FormikErrors<ClientQuestionnaireDto>,
  handleChange: FormikHandlers["handleChange"],
  handleBlur: FormikHandlers["handleBlur"],
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"],
  setShouldBlockNavigation: (o: boolean) => void,
}

const LeaveOfAbsenceComponent: React.FC<LeaveOfAbsenceComponentProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation
  }) => {

  return (
    <React.Fragment>
      <Grid item xs={12}>What date did the leave of absence begin?</Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <KeyboardDatePicker
          margin="normal"
          id="loaBeginDate"
          name="loaBeginDate"
          //label="Hire Date"
          format="MM/DD/yyyy"
          value={values.loaBeginDate || null}
          data-cy="clientQuestionnaire_loaBeginDate"
          fullWidth
          onChange={(date: MaterialUiPickersDate) => setFieldValue('loaBeginDate', date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <br />
      <Grid item xs={12}>What type of leave was granted?</Grid>
      <Grid item md={12} xs={12} sm={12} lg={4}>
        <PaddedTextField
          id="typeOfLeaveGrantedId"
          size="small"
          margin="none"
          select
          value={values.typeOfLeaveGrantedId || ''}
          name="typeOfLeaveGrantedId"
          onChange={e => { handleChange(e); }}
          onBlur={e => { handleBlur(e); }}
          error={Boolean(errors.typeOfLeaveGrantedId)}
          helperText={errors.typeOfLeaveGrantedId && errors.typeOfLeaveGrantedId}
          data-cy="clientQuestionnaire_typeOfLeaveGrantedId"
          aria-label="Other separation reason"
          fullWidth
          SelectProps={{ MenuProps: { disableScrollLock: true } }}>
          {typeOfLeaveGrantedOptions.filter((item) => item.name !== '')
            .map((option, index) => (
              <MenuItem key={option.name + index} value={option.id}>{option.name}</MenuItem>
            ))}
        </PaddedTextField>
      </Grid>
      <br />
      <Grid item xs={12}>Has the leave of absence ended?</Grid>
      <Grid container item xs={12} direction="row">
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="loaEnded"
              name="loaEnded"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('loaEnded', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? 1);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              //add errors for validation
              checked={o.status === values.loaEnded}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_loaEnded" />
          </Grid>
        ))}
      </Grid>
      <br />
      {values.loaEnded !== undefined && values.loaEnded === true ?
        <>
          <Grid item xs={12}>What date did the leave of absence end?</Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <KeyboardDatePicker
              margin="normal"
              id="loaEndDate"
              name="loaEndDate"
              //label="Hire Date"
              format="MM/DD/yyyy"
              value={values.loaEndDate || null}
              data-cy="clientQuestionnaire_loaEndDate"
              fullWidth
              onChange={(date: MaterialUiPickersDate) => { setFieldValue('loaEndDate', date); setShouldBlockNavigation(true); }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <br />
          <Grid item xs={12}>Did the claimant return to work after their leave?</Grid>
          <Grid container item xs={12} direction="row">
            {radioButtonBooleanOptions.map((o, index) => (
              <Grid item xs={3} key={o.name + index}>
                <FormControlLabel
                  id="returnedToWork"
                  name="returnedToWork"
                  onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                    setFieldValue('returnedToWork', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? 1);
                    setShouldBlockNavigation(true);
                  }}
                  onBlur={handleBlur}
                  //add errors for validation
                  checked={o.status === values.returnedToWork}
                  value={o.name}
                  control={<MHARadio
                    size="small" />} label={o.name} data-cy="clientQuestionnaire_returnedToWork" />
              </Grid>
            ))}
          </Grid>
          <br />
          {values.returnedToWork !== undefined && values.returnedToWork === false ?
            <>
              <Grid item xs={12}>Why did the claimant not return to work?</Grid>
              <Grid item xs={4}>
                <TextField
                  id="reasonForNotReturning"
                  size="small"
                  margin="none"
                  value={values.reasonForNotReturning || ''}
                  //label="Job Title"
                  name="reasonForNotReturning"
                  multiline
                  onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
                  onBlur={handleBlur}
                  error={Boolean(errors.reasonForNotReturning)}
                  helperText={errors.reasonForNotReturning && errors.reasonForNotReturning}
                  data-cy="clientQuestionnaire_reasonForNotReturning"
                  aria-label="What type of pay?"
                  fullWidth
                />
              </Grid>
              <br />
            </>
            : null}
        </>
        :
        values.loaEnded !== undefined && values.loaEnded === false ?
          <>
            <Grid item xs={12}>What is the anticipated return to work date for the claimant?</Grid>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <KeyboardDatePicker
                margin="normal"
                id="anticipatedReturnDate"
                name="anticipatedReturnDate"
                //label="Hire Date"
                format="MM/DD/yyyy"
                value={values.anticipatedReturnDate || null}
                data-cy="clientQuestionnaire_anticipatedReturnDate"
                fullWidth
                onChange={(date: MaterialUiPickersDate) => { setFieldValue('anticipatedReturnDate', date); setShouldBlockNavigation(true); }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <br />
            <Grid item xs={12}>Has the claimant maintained communication with the employer while on the leave?</Grid>
            <Grid container item xs={12} direction="row">
              {radioButtonBooleanOptions.map((o, index) => (
                <Grid item xs={3} key={o.name + index}>
                  <FormControlLabel
                    id="employerNotified"
                    name="employerNotified"
                    onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                      setFieldValue('employerNotified', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? 1);
                      setShouldBlockNavigation(true);
                    }}
                    onBlur={handleBlur}
                    //add errors for validation
                    checked={o.status === values.employerNotified}
                    value={o.name}
                    control={<MHARadio
                      size="small" />} label={o.name} data-cy="clientQuestionnaire_employerNotified" />
                </Grid>
              ))}
            </Grid>
            <br />
          </>
          : null}
      <Grid item xs={12}>Is the claimant receiving any type of pay during their leave of absence?</Grid>
      <Grid container item xs={12} direction="row">
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="payReceived"
              name="payReceived"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('payReceived', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? 1);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              //add errors for validation
              checked={o.status === values.payReceived}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_payReceived" />
          </Grid>
        ))}
      </Grid>
      <br />
      <div hidden={values.payReceived === false}>
        <Grid item xs={12}>What type of pay?</Grid>
        <Grid item xs={4}>
          <TextField
            id="typeOfPay"
            size="small"
            margin="none"
            value={values.typeOfPay || ''}
            //label="Job Title"
            name="typeOfPay"
            multiline
            onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
            onBlur={handleBlur}
            error={Boolean(errors.typeOfPay)}
            helperText={errors.typeOfPay && errors.typeOfPay}
            data-cy="clientQuestionnaire_typeOfPay"
            aria-label="What type of pay?"
            fullWidth
          />
        </Grid>
      </div>
      <br />
    </React.Fragment>
  )
}

export default LeaveOfAbsenceComponent;
