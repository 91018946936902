import React from 'react';
import { BottomAppBar, BottomCancelButton, BottomSaveButton, ButtonGrid } from '../styles/styles';


interface Props extends React.HTMLProps<React.ReactNode> {
  cancelClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void;
  submitClickHandler: () => void;
  submitting: boolean;
  showSaveButton?: boolean;
  claimDialog?: boolean;
}

const BottomActionBar: React.FC<Props> = ({
  cancelClickHandler,
  submitClickHandler,
  submitting,
  showSaveButton,
  claimDialog
}) => {

  const [showSave, setShowSave] = React.useState(true);

  React.useEffect(() => {
    if (showSaveButton !== undefined) {
      setShowSave(showSaveButton);
    }
  }, [showSaveButton]);


  return <>
    <BottomAppBar position="fixed">
      <ButtonGrid container>
        {showSave && 
          <BottomSaveButton
            type="submit"
            onClick={submitClickHandler}
            disabled={submitting}
            data-cy="page_bottomSave"
          >Save
          </BottomSaveButton>
        }
        <BottomCancelButton type="button" onClick={cancelClickHandler} data-cy="page_bottomCancel">
          {showSave && claimDialog !== true ? 'CANCEL' : showSave && claimDialog === true ? 'Close' : 'BACK'}
        </BottomCancelButton>
      </ButtonGrid>
    </BottomAppBar>
  </>
}

export default BottomActionBar;