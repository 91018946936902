import {
  storageAuthToken,
  storageAuthRefreshToken,
  storageRoles,
  storageAuthInformation,
  paymentsUser,
  appealsUser,
  claimsUser,
  admin,
  readOnly,
  canImpersonate,
  canAddEditUsers,
  clientPortal_claimsUser,
  clientPortal_employer,
  clientPortal_healthSystem,
  XSRFToken,
  reportsUser,
} from '../constants/strings';
import { FeatureTypeEnum } from '../enums/featureTypeEnum';

export interface AuthDto {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isPasswordExpired: boolean;
  authToken: string;
  refreshToken: string;
  roles: string[];
  fullName: string;
  employerId: string;
  contactId: string;
  employers: string[];
}

const roles = (): string[] =>
  JSON.parse(sessionStorage.getItem(storageRoles) ?? JSON.stringify([]));
export const authInfo = (): AuthDto =>
  JSON.parse(
    sessionStorage.getItem(storageAuthInformation) ?? JSON.stringify(authInit)
  ) as AuthDto;
const isReadOnlyByFeature = (
  feature: number,
  auto: boolean = false
): boolean => {
  switch (feature) {
    case FeatureTypeEnum.Employers:
      if (isAppealsUser()) return true;
      break;
    case FeatureTypeEnum.Settings:
      if (isClaimsUser()) return true;
      if (isPaymentsUser()) return true;
      break;
    case FeatureTypeEnum.Contacts:
      return false;
    default:
      return auto;
  }

  return auto;
};

export const authInit: AuthDto = {
  isAuthenticated: false,
  isAdmin: false,
  isPasswordExpired: false,
  authToken: '',
  refreshToken: '',
  roles: [],
  fullName: '',
  employerId: '',
  contactId: '',
  employers: [],
};
export const isUserAuthenticated = (): boolean => !(getToken().length === 0);
export const isPasswordExpired = (): boolean =>
  authInfo().isPasswordExpired ?? authInit.isPasswordExpired;

export const getToken = (): string =>
  sessionStorage.getItem(storageAuthToken) ?? '';
export const getXSRFToken = (): string =>
  sessionStorage.getItem(XSRFToken) ?? 'test';
export const getRefreshToken = (): string =>
  sessionStorage.getItem(storageAuthRefreshToken) ?? '';
export const getFullName = (): string => authInfo().fullName;
export const getEmployerId = (): string => authInfo().employerId;
export const getContactId = (): string => authInfo().contactId;
export const getEmployers = (): string[] => authInfo().employers;

export const isAdmin = (): boolean =>
  roles().findIndex((item: string) => item === admin) > -1;
export const isClientPortal_ClaimsUser = (): boolean =>
  roles().findIndex((item: string) => item === clientPortal_claimsUser) > -1;
export const isClientPortal_Employer = (): boolean =>
  roles().findIndex((item: string) => item === clientPortal_employer) > -1;
export const isClientPortal_HealthSystem = (): boolean =>
  roles().findIndex((item: string) => item === clientPortal_healthSystem) > -1;
export const isClientPortalUser = (): boolean => {
  if (
    isAdmin() !== true &&
    roles().findIndex(
      (item: string) =>
        item === clientPortal_employer || item === clientPortal_healthSystem
    ) > -1
  )
    return true;
  else return false;
};

export const isAppealsUser = (): boolean =>
  roles().findIndex((item: string) => item === appealsUser) > -1;
export const isClaimsUser = (): boolean =>
  roles().findIndex((item: string) => item === claimsUser) > -1;
export const isPaymentsUser = (): boolean =>
  roles().findIndex((item: string) => item === paymentsUser) > -1;
export const canUserImpersonate = (): boolean =>
  roles().findIndex((item: string) => item === canImpersonate) > -1;
export const canSetupUsers = (): boolean =>
  roles().findIndex(
    (item: string) => item === canAddEditUsers || item === admin
  ) > -1;
export const isReadOnly = (feature: number): boolean => {
  if (isAdmin()) return false;
  if (roles().findIndex((item: string) => item === readOnly) > -1)
    return isReadOnlyByFeature(feature, true);

  return isReadOnlyByFeature(feature);
};

export const isAuthorized = (): boolean => {
  if (
    roles().findIndex(
      (item: string) =>
        item === admin ||
        item === appealsUser ||
        item === claimsUser ||
        item === paymentsUser ||
        item === readOnly
    ) > -1
  )
    return true;
  else return false;
};

export const canAccessActionList = (): boolean =>
  isUserAuthenticated() && !isReadOnly(0);
export const canAccessPayments = (): boolean =>
  roles().findIndex((item: string) => item === admin || item === paymentsUser) >
  -1;
export const canAccessEmployers = (): boolean => isAuthorized();
export const canAccessContacts = (): boolean => isAuthorized();
export const canAccessClaims = (): boolean => isUserAuthenticated();
export const canAccessReports = (): boolean =>
  roles().findIndex((item: string) => item === admin || item === reportsUser) >
  -1;
export const canAccessSettings = (): boolean =>
  roles().findIndex((item: string) => item === admin || item === claimsUser) >
  -1;
export const canAccessHelp = (): boolean =>
  isUserAuthenticated() && !isAuthorized();
