import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Checkbox, Grid, Typography, Link } from '@material-ui/core';
import { ContactDto } from '../contacts.dtos';
import { TableCard, ListCardContent, CardCheckbox } from '../../../common/styles/styles';


interface Props {
  contact: ContactDto
}

const ContactListCard: React.FC<Props> = ({ contact }) => {

  return (
    <Grid item xs={12}>
      <TableCard raised>
        <ListCardContent>
          <Grid container>
            <div hidden data-cy={`contact_list_card_id_${contact.firstName}`}>{contact.contactId}</div>

            {/* Employer name */}
            <Grid item xs={12}>
              <Link component={RouterLink} to={`/contact/${contact.contactId}`} data-cy={`contact_list_card_link_${contact.firstName}`} variant="subtitle1" color="secondary">{contact.fullName}</Link>
            </Grid>

            {/* Contact */}
            <Grid item xs={6} sm={3}>
                          <Typography variant="body2" color="textPrimary">Employer</Typography>
              <Typography variant="body2" color="secondary">{(contact.primaryCompanyName ?? '').toUpperCase() || "None on file"}</Typography>
            </Grid>

            {/* Phone */}
            <Grid item xs={6} sm={3}>
              <Typography variant="body2" color="textPrimary">Phone</Typography>
              <Typography variant="body2" color="secondary">{contact.primaryPhoneNumber || "None on File"}</Typography>
            </Grid>

            {/* Email */}
            <Grid item xs={6} sm={3}>
              <Typography variant="body2" color="textPrimary">Email</Typography>
              <Typography variant="body2" color="secondary">
                {(contact.primaryEmailAddress &&
                  <a href={`mailto:${contact.primaryEmailAddress}`} data-cy={`contact_list_card_email_${contact.firstName}`}>
                    {contact.primaryEmailAddress}
                  </a>) || "None on File"}
              </Typography>
            </Grid>

            {/* Active checkbox */}
            <Grid item xs={6} sm={3}>
              <CardCheckbox
                value={contact.isActive}
                checked={contact.isActive}
                control={<Checkbox size="small" />}
                label={<Typography variant="subtitle2">Active</Typography>}
                labelPlacement="top"
              />
            </Grid>
          </Grid>
        </ListCardContent>
      </TableCard>
    </Grid>
  )
};

export default ContactListCard;
