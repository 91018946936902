import { AxiosError } from 'axios';

import apiClient from '../../../common/api/apiClient';
import { ApiResponse, ApiError } from '../../../common/api/apiResponse';
import { LoginDto, TokenDto } from './login.dtos';
import { showNotification } from '../../../common/actionCreators/dispatchNotification';
const sessionUrl = 'account/session';


// endpoints
const loginUrl = '/account/login';
const refreshTokenUrl = '/account/refresh-token';

export const loginApi = async (creds: LoginDto) => {
  return await apiClient().post<ApiResponse<TokenDto>>(loginUrl, creds)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, 'error');
        }
      }
      else {
        showNotification('An error occurred while logging in.', 'error');
      }
      throw err;
    });
};

export const session = async () =>
  await apiClient()
    .get(sessionUrl)
    .then(() => true)
    .catch(() => showNotification('Not authorized.'));

export const refreshAuth = async (token: string) => {
  return await apiClient().post<ApiResponse<TokenDto>>(refreshTokenUrl, { refreshToken: token})
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, 'error');
        }
      }
      else {
        showNotification('Not authorized.', 'error');
      }
      throw err;
    });
};
