import styled from 'styled-components';

// material ui
import {
    Container, Button, InputAdornment,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
//import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

// Styled login components ....
export const StyledDiv = styled.div`
    margin-top: ${props => props.theme.spacing(8)};
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const LoginContainer = styled(Container).attrs({ component: 'main' })`
    margin-top: 125px;
`;

export const LogoContainer = styled.div`
    display: flex;
    padding: 16px 16px 0 16px;
`;

export const Logo = styled.div`
    margin: 0 auto;
`;

export const ForgotPasswordButton = styled(Button)`
    margin: 8px 0 8px;
    float: left;
    font-weight: 500;
    font-size: 1.085em;
    color: #4FADAE;
`;

export const StyledVisibilityIcon = styled(VisibilityIcon)`
    
    background-color: #e8f0fe;
`
export const StyledInputAdornment = styled(InputAdornment)`
    
    color: #e8f0fe;
`;
export const StyledButton = styled(Button)`
    
    background-color: #e8f0fe;
`;