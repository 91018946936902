import * as Yup from 'yup';
import moment from 'moment';
import { requiredError } from '../../common/constants/strings';

var now = new Date();

export const sidesRepullValidationSchema = Yup.object().shape({
  fromUtc: Yup.date()
    .test('isToAfterFrom', '"From" cannot be after "To"', function (value) {
      return !moment(value).isAfter(moment(this.parent.toUtc));
    })
    .test(
      'isTimespan5DaysOrLess',
      '"From" cannot be more than 5 days before "To"',
      function (value) {
        return !moment(value)
          .add(5, 'days')
          .isBefore(moment(this.parent.toUtc));
      }
    )
    .required(requiredError),
  toUtc: Yup.date().required(requiredError),
});

export interface SidesRepullDto {
  fromUtc?: Date;
  toUtc?: Date;
}

export const sidesRepullInit: SidesRepullDto = {
  fromUtc: moment(now).utc().add(-1, 'days').toDate(),
  toUtc: moment(now).utc().toDate(),
};
