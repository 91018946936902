import { AxiosError } from 'axios';

import apiClient from '../../common/api/apiClient';
import { ApiError } from '../../common/api/apiResponse';
import { CompanyDto, CompanyHsDto } from './dtos';
import { showNotification } from '../../common/actionCreators/dispatchNotification';
import { RateWrapperDto } from '../../common/dtos/billing';


// endpoints
const companiesUrl = "companies";
const mhaContactsUrl = "contacts/mha";
const companiesImportUrl = 'upload/import/company';
const companiesImportHistoryUrl = 'upload/history/company';
const companiesHsUrl = 'companies/health-system';

export const getCompaniesApi = async () => {
  return await apiClient().get(companiesUrl)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting employers.", "error");

      throw err;
    });
};

export const getCompanyApi = async (id: string) => {
  return await apiClient().get(companiesUrl + "/" + id)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting employer.", "error");

      throw err;
    });
};

export const updateCompanyApi = async (request: CompanyDto) => {
  return await apiClient().put(companiesUrl, request)
    .then(res => {
      showNotification("Company saved.", "success");
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while updating employer.", "error");

      throw err;
    });
};

export const createCompanyApi = async (request: CompanyDto) => {
  return await apiClient().post(companiesUrl, request)
    .then(res => {
      showNotification("Company saved.", "success");
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while creating employer.", "error");

      throw err;
    });
};

export const getCompanyUploadHistoryApi = async () => {
  return await apiClient().get(`${companiesImportHistoryUrl}`)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, 'error');
        }
      }
      else {
        showNotification('An error occurred while retrieving employer inports history.', 'error');
      }
      throw err;
    });
};

export const uploadCompanyImportApi = async (request: any) => {
  const data = new FormData();
  data.append('file', request);
  return await apiClient().post(`${companiesImportUrl}`, data)
    .then((res) => {
      showNotification("Processing upload.", 'success');
      return true;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, 'error');
        }
      }
      else {
        showNotification('An error occurred while processing bulk import.', 'error');
      }
      throw err;
    });
};

export const getMhaContactsApi = async () => {
  return await apiClient().get(mhaContactsUrl)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, "error");
        }
      }
      else {
        showNotification("An error occurred while getting MHA contacts.", "error");
      }

      throw err;
    });
};

export const getCompanyRateApi = async (request: RateWrapperDto) => {
    return await apiClient().get(companiesUrl + "/" + request.id + "/rate")
        .then(res => {
            return res.data.result;
        })
        .catch(err => {
            if (err && err.response) {
                const axiosError = err as AxiosError<ApiError>;

              if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
            }
            else showNotification("An error occurred while getting rate.", "error");

            throw err;
        });
};

export const createCompanyRateApi = async (request: RateWrapperDto ) => {
    return await apiClient().post((companiesUrl + "/" + request.id + "/rate"), request.rate)
        .then(res => {
            showNotification("Rate saved.", "success");
            return res.data.result;
        })
        .catch(err => {
            if (err && err.response) {
                const axiosError = err as AxiosError<ApiError>;

              if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
            }
            else showNotification("An error occurred while creating rate.", "error");

            throw err;
       });
};

export const updateCompanyRateApi = async (request: RateWrapperDto) => {
   
    return await apiClient().put((companiesUrl + "/" + request.id + "/rate"), request.rate)
        .then(res => {
            showNotification("Rate saved.", "success");
            return res.data.result;
        })
        .catch(err => {
            if (err && err.response) {
                const axiosError = err as AxiosError<ApiError>;

              if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
            }
            else showNotification("An error occurred while updating rate.", "error");

            throw err;
        });
};

export const getCompaniesHsApi = async () => {
  return await apiClient().get(companiesHsUrl)
    .then(res => {
      return res.data.result as CompanyHsDto[];
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting employers with health systems.", "error");

      throw err;
    });
};