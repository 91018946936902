import React from 'react';
import { FormikHandlers, FormikErrors, FormikHelpers, FieldArray, getIn } from 'formik';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
// material ui
import { Grid, CardContent, Typography } from '@material-ui/core';
import Label from '../../../../common/components/label';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { getCompaniesActions } from '../../../company/actions';
import { getGoverningDistrictsActions } from '../../../account/profile/profile.actions';
import { NavCard, NavOptionsGrid } from '../../documents.styles';
import { Icon, SidesPagination } from '../../../../common/styles/styles';
import { ISi, ISi_PropertyAttributes } from '../../../../common/dtos/SiDto';
import SiSelectOptions from '../siFieldComponents/SiSelectOptions';
import { NotReturningCodes_class } from '../../../../common/enums/NotReturningCodes';
import IndicatorTypeField from '../siFieldComponents/IndicatorTypeField';
import { NotWorkingCodes_class } from '../../../../common/enums/NotWorkingCodes';
import WrittenVerbalBothCodeField from '../siFieldComponents/WrittenVerbalBothCodeField';
import SiDateField from '../siFieldComponents/SiDateField';
import { IUisSiPreviousAssignmentOccurrence, IUisSiPreviousAssignmentOccurrence_PropertyAttributes } from '../../../../common/dtos/UisSiPreviousAssignmentOccurrenceDto';
import SiTextAreaField from '../siFieldComponents/SiTextAreaField';
import SiTextField from '../siFieldComponents/SiTextField';
import MoneyField from '../../../../common/components/numberFields';
import {
  isNotWorkingOnAssignment, isTempAgencyPolicyInd, isTempAssignmentLastDay, isTempPolicyAwarenessCode, isClmtStrikingUnionMemberInd, isContractAccepted, isDateDisputeBegan,
  isDateDisputeEnded, isFirstDayOfBreak, isFirstDayOfContract, isHaveAuthorityToOfferInd, isHowOfferConveyed, isLaborDisputeEndedInd, isLaborDisputeTypeInd, isLastDayOfBreak,
  isLastDayOfContract, isNewContractJobOfferInd, isNewTempAssignmentCommutingDistance, isNewTempAssignmentDateOfferMade, isNewTempAssignmentDateRefused,
  isNewTempAssignmentHours, isNewTempAssignmentJobDuties, isNewTempAssignmentRateOfPay, isNewTempAssignmentStartDate, isNewTempAssignmentWhyRefused, isPersonOfferedReasonableAssurance,
  isPositionReturningTo, isReasonForBreak, isReplacementWorkersHiredInd, isTempAssignmentClaimantContactAgencyInd, isTempAssignmentWhoEnded, isTempAssignmentWhyNotCompleted,
  isTempAssignmentWorkAvailableInd, isWhyContractNotOffered, isWhyRetireComments, isWhyUnemployedLaborDispute,
  opWorkContinueDuringBreakInd, opClmtMemberAnotherUnionInd, opSettlementAffectClmtInd, opHowSettlementAffects, opLocationOfLaborDispute, opUnionNameAndLocalNo, isWhyNotReturning, isRelationshipEndedInd, isWorkingNewAssignmentInd, opNewContractJobOfferInd, opFirstDayOfBreak, opLastDayOfBreak, isMandatoryRetirementInd, opWhyRetireComments, isContinuingWorkAvailableInd, opContinuingWorkAvailableInd, isUisSiPreviousAssignmentOccurrences
} from '../../../../utils';
import ClaimantEmployerAgencyCodeField from '../siFieldComponents/ClaimantEmployerAgencyCodeField';
import { EmployerSepReasonCodes_class } from '../../../../common/enums/EmployerSepReasonCodes';
import BreakReasonCodeField from '../siFieldComponents/BreakReasonCodeField';
import IndicatorTypeWithUnknownField from '../siFieldComponents/IndicatorTypeWithUnknownField';
import { IUisSiContactOccurrence, IUisSiContactOccurrence_PropertyAttributes } from '../../../../common/dtos/UisSiContactOccurrenceDto';
import { ContactCodes_class } from '../../../../common/enums/ContactCodes';
import phoneNumberField from '../../../../common/components/phoneNumberField';
import { VoluntarySepReasonCodes_class } from '../../../../common/enums/VoluntarySepReasonCodes';


interface Props extends React.HTMLProps<React.ReactNode> {
  values: ISi;
  errors: FormikErrors<ISi>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ISi>["setFieldValue"];
  isReadOnly: boolean;
}

const SiMisc: React.FC<Props> = (
  {
    values,
    errors,
    handleChange,
    isReadOnly,
    handleBlur,
    setFieldValue,
  }) => {
  const dispatch = useDispatch();
  const [paTypePageNumber, setPATypePageNumber] = React.useState(1);
  const [contactTypePageNumber, setContactTypePageNumber] = React.useState(0);
  //React.useEffect(() => { console.log('errors', errors); }, [errors]);

  React.useEffect(() => {
    if (!!values.uisSiPreviousAssignmentOccurrences && values.uisSiPreviousAssignmentOccurrences.length !== 0)
      setPATypePageNumber(1);
    if (!!values.uisSiContactOccurrences && values.uisSiContactOccurrences.length !== 0)
      setContactTypePageNumber(1);
  }, []);

  React.useEffect(() => {
    dispatch({ type: getType(getCompaniesActions.request) });
    dispatch({ type: getType(getGoverningDistrictsActions.request) });
  }, [dispatch]);

  React.useEffect(() => {
    if (!isWhyNotReturning(values) && values.whyNotReturning !== undefined) setFieldValue('whyNotReturning', undefined);
    if (!isRelationshipEndedInd(values) && values.relationshipEndedInd !== undefined) setFieldValue('relationshipEndedInd', undefined);
    if (!isWorkingNewAssignmentInd(values) && values.workingNewAssignmentInd !== undefined) setFieldValue('workingNewAssignmentInd', undefined);
    if (!isNotWorkingOnAssignment(values) && values.notWorkingOnAssignment !== undefined) setFieldValue('notWorkingOnAssignment', undefined);
    if (!isTempAgencyPolicyInd(values) && values.tempAgencyPolicyInd !== undefined) setFieldValue('tempAgencyPolicyInd', undefined);
    if (!isTempPolicyAwarenessCode(values) && values.tempPolicyAwarenessCode !== undefined) setFieldValue('tempPolicyAwarenessCode', undefined);
    if (!isTempAssignmentLastDay(values) && values.tempAssignmentLastDay !== undefined) setFieldValue('tempAssignmentLastDay', undefined);
    if (!isTempAssignmentWhoEnded(values) && values.tempAssignmentWhoEnded !== undefined) setFieldValue('tempAssignmentWhoEnded', undefined);
    if (!isTempAssignmentWhyNotCompleted(values) && values.tempAssignmentWhyNotCompleted !== undefined) setFieldValue('tempAssignmentWhyNotCompleted', undefined);
    if (!isTempAssignmentClaimantContactAgencyInd(values) && values.tempAssignmentClaimantContactAgencyInd !== undefined) setFieldValue('tempAssignmentClaimantContactAgencyInd', undefined);
    if (!isTempAssignmentWorkAvailableInd(values) && values.tempAssignmentWorkAvailableInd !== undefined) setFieldValue('tempAssignmentWorkAvailableInd', undefined);
    if (!isNewTempAssignmentDateOfferMade(values) && values.newTempAssignmentDateOfferMade !== undefined) setFieldValue('newTempAssignmentDateOfferMade', undefined);
    if (!isNewTempAssignmentStartDate(values) && values.newTempAssignmentStartDate !== undefined) setFieldValue('newTempAssignmentStartDate', undefined);
    if (!isNewTempAssignmentJobDuties(values) && values.newTempAssignmentJobDuties !== undefined) setFieldValue('newTempAssignmentJobDuties', undefined);
    if (!isNewTempAssignmentRateOfPay(values) && values.newTempAssignmentRateOfPay !== undefined) setFieldValue('newTempAssignmentRateOfPay', undefined);
    if (!isNewTempAssignmentCommutingDistance(values) && values.newTempAssignmentCommutingDistance !== undefined) setFieldValue('newTempAssignmentCommutingDistance', undefined);
    if (!isNewTempAssignmentHours(values) && values.newTempAssignmentHours !== undefined) setFieldValue('newTempAssignmentHours', undefined);
    if (!isNewTempAssignmentWhyRefused(values) && values.newTempAssignmentWhyRefused !== undefined) setFieldValue('newTempAssignmentWhyRefused', undefined);
    if (!isNewTempAssignmentDateRefused(values) && values.newTempAssignmentDateRefused !== undefined) setFieldValue('newTempAssignmentDateRefused', undefined);
    if (!isPersonOfferedReasonableAssurance(values) && values.personOfferedReasonableAssurance !== undefined) setFieldValue('personOfferedReasonableAssurance', undefined);
    if (!isHowOfferConveyed(values) && values.howOfferConveyed !== undefined) setFieldValue('howOfferConveyed', undefined);
    if (!isReasonForBreak(values) && values.reasonForBreak !== undefined) setFieldValue('reasonForBreak', undefined);
    if (!isFirstDayOfBreak(values) && values.firstDayOfBreak !== undefined && !opFirstDayOfBreak(values)) setFieldValue('firstDayOfBreak', undefined);
    if (!isLastDayOfBreak(values) && values.lastDayOfBreak !== undefined && !opLastDayOfBreak(values)) setFieldValue('lastDayOfBreak', undefined);
    if (!opWorkContinueDuringBreakInd(values) && values.workContinueDuringBreakInd !== undefined) setFieldValue('workContinueDuringBreakInd', undefined);
    if (!isPositionReturningTo(values) && values.positionReturningTo !== undefined) setFieldValue('positionReturningTo', undefined);
    if (!isNewContractJobOfferInd(values) && !opNewContractJobOfferInd(values) && values.newContractJobOfferInd !== undefined) setFieldValue('newContractJobOfferInd', undefined);
    if (!isPersonOfferedReasonableAssurance(values) && values.personOfferedReasonableAssurance !== undefined) setFieldValue('personOfferedReasonableAssurance', undefined);
    if (!isHaveAuthorityToOfferInd(values) && values.haveAuthorityToOfferInd !== undefined) setFieldValue('haveAuthorityToOfferInd', undefined);
    if (!isHowOfferConveyed(values) && values.howOfferConveyed !== undefined) setFieldValue('howOfferConveyed', undefined);
    if (!isContractAccepted(values) && values.contractAccepted !== undefined) setFieldValue('contractAccepted', undefined);
    if (!isWhyContractNotOffered(values) && values.whyContractNotOffered !== undefined) setFieldValue('whyContractNotOffered', undefined);
    if (!isFirstDayOfContract(values) && values.firstDayOfContract !== undefined) setFieldValue('firstDayOfContract', undefined);
    if (!isLastDayOfContract(values) && values.lastDayOfContract !== undefined) setFieldValue('lastDayOfContract', undefined);
    if (!isLaborDisputeTypeInd(values) && values.laborDisputeTypeInd !== undefined) setFieldValue('laborDisputeTypeInd', undefined);
    if (!opLocationOfLaborDispute(values) && values.locationOfLaborDispute !== undefined) setFieldValue('locationOfLaborDispute', undefined);
    if (!opUnionNameAndLocalNo(values) && values.unionNameAndLocalNo !== undefined) setFieldValue('unionNameAndLocalNo', undefined);
    if (!isDateDisputeBegan(values) && values.dateDisputeBegan !== undefined) setFieldValue('dateDisputeBegan', undefined);
    if (!isLaborDisputeEndedInd(values) && values.laborDisputeEndedInd !== undefined) setFieldValue('laborDisputeEndedInd', undefined);
    if (!isDateDisputeEnded(values) && values.dateDisputeEnded !== undefined) setFieldValue('dateDisputeEnded', undefined);
    if (!isReplacementWorkersHiredInd(values) && values.replacementWorkersHiredInd !== undefined) setFieldValue('replacementWorkersHiredInd', undefined);
    if (!isClmtStrikingUnionMemberInd(values) && values.clmtStrikingUnionMemberInd !== undefined) setFieldValue('clmtStrikingUnionMemberInd', undefined);
    if (!opClmtMemberAnotherUnionInd(values) && values.clmtMemberAnotherUnionInd !== undefined) setFieldValue('clmtMemberAnotherUnionInd', undefined);
    if (!isWhyUnemployedLaborDispute(values) && values.whyUnemployedLaborDispute !== undefined) setFieldValue('whyUnemployedLaborDispute', undefined);
    if (!opSettlementAffectClmtInd(values) && values.settlementAffectClmtInd !== undefined) setFieldValue('settlementAffectClmtInd', undefined);
    if (!opHowSettlementAffects(values) && values.howSettlementAffects !== undefined) setFieldValue('howSettlementAffects', undefined);
    if (!isWhyRetireComments(values) && !opWhyRetireComments(values) && values.whyRetireComments !== undefined && values.whyRetireComments !== undefined) setFieldValue('whyRetireComments', undefined);
    if (!isMandatoryRetirementInd(values) && values.mandatoryRetirementInd !== undefined) setFieldValue('mandatoryRetirementInd', undefined);
    if (!isMandatoryRetirementInd(values) && values.mandatoryRetirementInd !== undefined) setFieldValue('mandatoryRetirementInd', undefined);
    if (!isContinuingWorkAvailableInd(values) && !opContinuingWorkAvailableInd(values) && values.continuingWorkAvailableInd !== undefined) setFieldValue('continuingWorkAvailableInd', undefined);
    if (!isUisSiPreviousAssignmentOccurrences(values) && (values.uisSiPreviousAssignmentOccurrences ?? []).length !== 0) setFieldValue('uisSiPreviousAssignmentOccurrences', []);
  }, [values]);

  const handleGetErrors = (list: any[], listName: string) => {
    let errorIndexList = [];
    for (let i = 0; i < (list.length ?? 0); i++) {
      if (Boolean(getIn(errors, `${listName}[${i}]`)))
        errorIndexList.push(i);
    }

    return errorIndexList;
  };


  return (
    <Grid container spacing={2} data-cy="sidesResponse_generalInformation_container">
      <NavOptionsGrid item xs={6}>
        {(isWhyNotReturning(values) || isRelationshipEndedInd(values) || isWorkingNewAssignmentInd(values)) &&
          <NavCard>
            <CardContent>
              <Grid container item xs={12} spacing={2}>
                {isWhyNotReturning(values) &&
                  <SiSelectOptions
                    label={"Why is the claimant not likely to return?"}
                    name={`whyNotReturning`}
                    value={values.whyNotReturning}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    options={NotReturningCodes_class.enumAsSelectOptions.filter(f => (f.text ?? '').length !== 0)}
                  />
                }
                {isRelationshipEndedInd(values) &&
                  <IndicatorTypeField
                    label={"Has the relationship ended between the agency and the claimant?"}
                    name={`relationshipEndedInd`}
                    value={values.relationshipEndedInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isWorkingNewAssignmentInd(values) &&
                  <IndicatorTypeField
                    label={"Is the claimant currently working a new assignment?"}
                    name={`workingNewAssignmentInd`}
                    value={values.workingNewAssignmentInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }

                {isNotWorkingOnAssignment(values) &&
                  <SiSelectOptions
                    label={"Why is the claimant not working on an assignment?"}
                    name={`notWorkingOnAssignment`}
                    value={values.notWorkingOnAssignment}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    options={NotWorkingCodes_class.enumAsSelectOptions}
                  />
                }

                {isTempAgencyPolicyInd(values) &&
                  <IndicatorTypeField
                    label={"Is there a policy concerning contacting the agency?"}
                    name={`tempAgencyPolicyInd`}
                    value={values.tempAgencyPolicyInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isTempPolicyAwarenessCode(values) &&
                  <WrittenVerbalBothCodeField
                    label={"How was the claimant informed of the policy?"}
                    name={`tempPolicyAwarenessCode`}
                    value={values.tempPolicyAwarenessCode}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isTempAssignmentLastDay(values) &&
                  <SiDateField
                    label={"Last day of work at the assignment"}
                    name={`tempAssignmentLastDay`}
                    value={values.tempAssignmentLastDay}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputlabelprops={{ shrink: true }}
                    size={6}
                  />
                }
                {isTempAssignmentWhoEnded(values) &&
                  <ClaimantEmployerAgencyCodeField
                    label={"Who ended the assignment?"}
                    name={`tempAssignmentWhoEnded`}
                    value={values.tempAssignmentWhoEnded}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isTempAssignmentWhyNotCompleted(values) &&
                  <SiTextAreaField
                    label={"Why was the assignment not completed?"}
                    name={`tempAssignmentWhyNotCompleted`}
                    value={values.tempAssignmentWhyNotCompleted}
                errors={errors}
                setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.tempAssignmentWhyNotCompleted_Attributes.stringLength.maxLength}
                  />
                }
                {isTempAssignmentClaimantContactAgencyInd(values) &&
                  <IndicatorTypeField
                    label={"Did the claimant contact the agency for a new assignment?"}
                    name={`tempAssignmentClaimantContactAgencyInd`}
                    value={values.tempAssignmentClaimantContactAgencyInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isTempAssignmentWorkAvailableInd(values) &&
                  <IndicatorTypeField
                    label={"If the claimant had contacted the agency, would work have been available?"}
                    name={`tempAssignmentWorkAvailableInd`}
                    value={values.tempAssignmentWorkAvailableInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }

              </Grid>
            </CardContent>
          </NavCard>
        }

        {isUisSiPreviousAssignmentOccurrences(values) &&
          <NavCard>
            <CardContent>
              <Grid container spacing={2}>
                {/* Previous Assignment(s) */}
              <FieldArray name={`uisSiPreviousAssignmentOccurrences`} render={arrayHelpers => (
                <>
                  <Grid item xs={10}><Label>Previous Assignments</Label></Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Grid container alignContent="flex-end">
                      <Grid item xs={6}>
                        {values.uisSiPreviousAssignmentOccurrences && values.uisSiPreviousAssignmentOccurrences?.length !== 0 ?
                          <Icon
                            title="Remove Previous Assignment"
                            color="secondary"
                            onClick={() => {
                              setTimeout(() => {
                                arrayHelpers.remove(paTypePageNumber - 1);
                                setPATypePageNumber(!!values.uisSiPreviousAssignmentOccurrences ? values.uisSiPreviousAssignmentOccurrences.length - 1 : 0);
                              }, 200);
                            }}
                            data-cy="changeMe"
                            redcolor="true"
                          >
                            <IndeterminateCheckBoxIcon />
                          </Icon> :
                          null
                        }
                      </Grid>
                      <Grid item xs={6}>
                        <Icon title="Add Previous Assignment" color="secondary"
                          onClick={() => {
                            if (!values.uisSiPreviousAssignmentOccurrences) setFieldValue('uisSiPreviousAssignmentOccurrences', []);
                            if ((values.uisSiPreviousAssignmentOccurrences ?? []).length < 5) {
                              arrayHelpers.push({} as IUisSiPreviousAssignmentOccurrence);
                              setPATypePageNumber(!!values.uisSiPreviousAssignmentOccurrences ? values.uisSiPreviousAssignmentOccurrences.length + 1 : 0);
                            }
                          }} data-cy="changeMe">
                          <AddBoxRoundedIcon />
                        </Icon>
                      </Grid>
                    </Grid>
                  </Grid>
                  </>
              )} />

              {Boolean(getIn(errors, 'uisSiPreviousAssignmentOccurrences')) &&
                <Grid item xs={12}><Typography component="span" color="error">
                  {typeof getIn(errors, 'uisSiPreviousAssignmentOccurrences') === 'string' && getIn(errors, 'uisSiPreviousAssignmentOccurrences')}
                </Typography></Grid>}

                {
                  (values.uisSiPreviousAssignmentOccurrences && values.uisSiPreviousAssignmentOccurrences?.length !== 0) &&
                  <>
                    <SiDateField
                      label={"Assignment start date"}
                      name={`uisSiPreviousAssignmentOccurrences[${paTypePageNumber - 1}].prevTempAssignmentStartDate`}
                      value={values.uisSiPreviousAssignmentOccurrences[paTypePageNumber - 1]?.prevTempAssignmentStartDate}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                      inputlabelprops={{ shrink: true }}
                      size={6}
                    />
                    <SiDateField
                      label={"Assignment end date"}
                      name={`uisSiPreviousAssignmentOccurrences[${paTypePageNumber - 1}].prevTempAssignmentEndDate`}
                      value={values.uisSiPreviousAssignmentOccurrences[paTypePageNumber - 1]?.prevTempAssignmentEndDate}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                      inputlabelprops={{ shrink: true }}
                      size={6}
                    />
                    <SiTextAreaField
                      label={"Prior assignment duties?"}
                      name={`uisSiPreviousAssignmentOccurrences[${paTypePageNumber - 1}].prevTempAssignmentJobDuties`}
                      value={values.uisSiPreviousAssignmentOccurrences[paTypePageNumber - 1]?.prevTempAssignmentJobDuties}
                    errors={errors}
                    setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                      max={IUisSiPreviousAssignmentOccurrence_PropertyAttributes.prevTempAssignmentJobDuties_Attributes.stringLength.maxLength}
                    />
                    <SiTextField
                      label={"Prior assignment rate of pay?"}
                      name={`uisSiPreviousAssignmentOccurrences[${paTypePageNumber - 1}].prevTempAssignmentRateOfPay`}
                      value={values.uisSiPreviousAssignmentOccurrences[paTypePageNumber - 1]?.prevTempAssignmentRateOfPay?.toString()}
                    errors={errors}
                    setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                      inputprops={{ inputComponent: MoneyField as any }}
                      max={17}
                    />
                    <SiTextField
                      label={"Prior assignment commuting distance from claimant's home?"}
                      name={`uisSiPreviousAssignmentOccurrences[${paTypePageNumber - 1}].prevTempAssignmentCommutingDistance`}
                      value={values.uisSiPreviousAssignmentOccurrences[paTypePageNumber - 1]?.prevTempAssignmentCommutingDistance}
                    errors={errors}
                    setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                      max={IUisSiPreviousAssignmentOccurrence_PropertyAttributes.prevTempAssignmentCommutingDistance_Attributes.stringLength.maxLength}
                    />
                    <SiTextField
                      label={"Prior assignment hours - include the number of hours per week and work scheduled?"}
                      name={`uisSiPreviousAssignmentOccurrences[${paTypePageNumber - 1}].prevTempAssignmentHours`}
                      value={values.uisSiPreviousAssignmentOccurrences[paTypePageNumber - 1]?.prevTempAssignmentHours}
                    errors={errors}
                    setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                      max={IUisSiPreviousAssignmentOccurrence_PropertyAttributes.prevTempAssignmentHours_Attributes.stringLength.maxLength}
                    />

                  <SidesPagination
                    hidden={values.uisSiPreviousAssignmentOccurrences && values.uisSiPreviousAssignmentOccurrences?.length === 0}
                    count={values.uisSiPreviousAssignmentOccurrences?.length}
                    variant="outlined"
                    color="primary"
                    size="small"
                    page={paTypePageNumber}
                    errorindex={handleGetErrors(values.uisSiPreviousAssignmentOccurrences, 'uisSiPreviousAssignmentOccurrences')}
                    onChange={(e, p) => { setPATypePageNumber(p); }} />

                  <br/><br/>

                    {isNewTempAssignmentDateOfferMade(values) &&
                      <SiDateField
                        label={"Date new offer was made?"}
                        name={`newTempAssignmentDateOfferMade`}
                        value={values.newTempAssignmentDateOfferMade}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                        inputlabelprops={{ shrink: true }}
                        size={12}
                      />
                    }
                    {isNewTempAssignmentStartDate(values) &&
                      <SiDateField
                        label={"New assignment start date?"}
                        name={`newTempAssignmentStartDate`}
                        value={values.newTempAssignmentStartDate}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                        inputlabelprops={{ shrink: true }}
                        size={12}
                      />
                    }
                    {isNewTempAssignmentJobDuties(values) &&
                      <SiTextAreaField
                        label={"New assignment duties?"}
                        name={`newTempAssignmentJobDuties`}
                        value={values.newTempAssignmentJobDuties}
                    errors={errors}
                    setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                        max={ISi_PropertyAttributes.newTempAssignmentJobDuties_Attributes.stringLength.maxLength}
                      />
                    }
                    {isNewTempAssignmentRateOfPay(values) &&
                      <SiTextField
                        label={"New assignment rate of pay?"}
                        name={`newTempAssignmentRateOfPay`}
                        value={values.newTempAssignmentRateOfPay?.toString()}
                    errors={errors}
                    setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                        inputprops={{ inputComponent: MoneyField as any }}
                        max={17}
                      />
                    }
                    {isNewTempAssignmentCommutingDistance(values) &&
                      <SiTextField
                        label={"New assignment commuting distance from claimant's home?"}
                        name={`newTempAssignmentCommutingDistance`}
                        value={values.newTempAssignmentCommutingDistance}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                        max={ISi_PropertyAttributes.newTempAssignmentCommutingDistance_Attributes.stringLength.maxLength}
                      />
                    }
                    {isNewTempAssignmentHours(values) &&
                      <SiTextField
                        label={"New assignment hours - include the number of hours per week and work scheduled?"}
                        name={`newTempAssignmentHours`}
                        value={values.newTempAssignmentHours}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                        max={ISi_PropertyAttributes.newTempAssignmentHours_Attributes.stringLength.maxLength}
                      />
                    }
                    {isNewTempAssignmentWhyRefused(values) &&
                      <SiTextAreaField
                        label={"Reason the claimant refused the new assignment?"}
                        name={`newTempAssignmentWhyRefused`}
                        value={values.newTempAssignmentWhyRefused}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                        max={ISi_PropertyAttributes.newTempAssignmentWhyRefused_Attributes.stringLength.maxLength}
                      />
                    }
                    {isNewTempAssignmentDateRefused(values) &&
                      <SiDateField
                        label={"Date the new offer was refused?"}
                        name={`newTempAssignmentDateRefused`}
                        value={values.newTempAssignmentDateRefused}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                        inputlabelprops={{ shrink: true }}
                        size={6}
                      />
                    }
                    {isPersonOfferedReasonableAssurance(values) && values.notWorkingOnAssignment === NotWorkingCodes_class.enum.Item3 &&
                      <SiTextField
                        label={"Who made the offer of work?"}
                        name={`personOfferedReasonableAssurance`}
                        value={values.personOfferedReasonableAssurance}
                    errors={errors}
                    setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                        max={ISi_PropertyAttributes.personOfferedReasonableAssurance_Attributes.stringLength.maxLength}
                      />
                    }
                    {isHowOfferConveyed(values) && values.notWorkingOnAssignment === NotWorkingCodes_class.enum.Item3 &&
                      <WrittenVerbalBothCodeField
                        label={"How was the offer conveyed?"}
                        name={`howOfferConveyed`}
                        value={values.howOfferConveyed}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                      />
                    }

                  </>
                }

              </Grid>
            </CardContent>
          </NavCard>
        }

        {true &&
          <NavCard>
            <CardContent>
              <Grid container spacing={2}>
                {/* Contacts */}
                <Grid item xs={12} sm={12} md={10} lg={10}><Label>Contacts</Label></Grid>

                <FieldArray name={`uisSiContactOccurrences`} render={arrayHelpers => (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Grid container alignItems="flex-end">
                      <Grid item xs={6}>
                        {!(values.uisSiContactOccurrences && values.uisSiContactOccurrences?.length === 0) ?
                          <Icon
                            title="Remove Contact"
                            color="secondary"
                            onClick={() => {
                              setTimeout(() => {
                                arrayHelpers.remove(contactTypePageNumber - 1);
                                setContactTypePageNumber(!!values.uisSiContactOccurrences ? values.uisSiContactOccurrences.length - 1 : 0);
                              }, 200);
                            }}
                            data-cy="changeMe"
                            redcolor="true"
                          >
                            <IndeterminateCheckBoxIcon />
                          </Icon> :
                          null
                        }
                      </Grid>
                      <Grid item xs={6}>
                        <Icon title="Add Contact" color="secondary"
                          onClick={() => {
                            arrayHelpers.push({} as IUisSiContactOccurrence);
                            setContactTypePageNumber(!!values.uisSiContactOccurrences ? values.uisSiContactOccurrences.length + 1 : 0);
                          }} data-cy="changeMe">
                          <AddBoxRoundedIcon />
                        </Icon>
                      </Grid>
                    </Grid>
                  </Grid>
              )} />

              {Boolean(getIn(errors, 'uisSiContactOccurrences')) &&
                <Grid item xs={12}><Typography component="span" color="error">
                  {typeof getIn(errors, 'uisSiContactOccurrences') === 'string' && getIn(errors, 'uisSiContactOccurrences')}
                </Typography></Grid>}

                {
                  (values.uisSiContactOccurrences && values.uisSiContactOccurrences?.length !== 0) &&
                  <>
                    <SiSelectOptions
                      label={"Type of Contact"}
                      name={`uisSiContactOccurrences[${contactTypePageNumber - 1}].externalContactTypeInd`}
                      value={values.uisSiContactOccurrences[contactTypePageNumber - 1]?.externalContactTypeInd}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                      options={ContactCodes_class.enumAsSelectOptions}
                    />

                    <SiTextField
                      label={"Company Name"}
                      name={`uisSiContactOccurrences[${contactTypePageNumber - 1}].externalBusinessCompanyName`}
                      value={values.uisSiContactOccurrences[contactTypePageNumber - 1]?.externalBusinessCompanyName}
                    errors={errors}
                    setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                      max={IUisSiContactOccurrence_PropertyAttributes.externalBusinessCompanyName_Attributes.stringLength.maxLength}
                    />
                    <SiTextField
                      label={"Contact Name"}
                      name={`uisSiContactOccurrences[${contactTypePageNumber - 1}].externalBusinessContactName`}
                      value={values.uisSiContactOccurrences[contactTypePageNumber - 1]?.externalBusinessContactName}
                    errors={errors}
                    setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                      max={IUisSiContactOccurrence_PropertyAttributes.externalBusinessContactName_Attributes.stringLength.maxLength}
                    />
                    <SiTextField
                      label={"Email"}
                      name={`uisSiContactOccurrences[${contactTypePageNumber - 1}].externalBusinessEmail`}
                      value={values.uisSiContactOccurrences[contactTypePageNumber - 1]?.externalBusinessEmail}
                    errors={errors}
                    setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                      max={IUisSiContactOccurrence_PropertyAttributes.externalBusinessEmail_Attributes.stringLength.maxLength}
                    />
                    <SiTextField
                      label={"Phone"}
                      name={`uisSiContactOccurrences[${contactTypePageNumber - 1}].externalBusinessPhone`}
                      value={values.uisSiContactOccurrences[contactTypePageNumber - 1]?.externalBusinessPhone}
                    errors={errors}
                    setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                      inputprops={{ inputComponent: (phoneNumberField as any) }}
                      //max={IUisSiContactOccurrence_PropertyAttributes.externalBusinessPhone_Attributes.stringLength.maxLength}
                    />

                    <SidesPagination
                      hidden={values.uisSiContactOccurrences && values.uisSiContactOccurrences?.length === 0}
                      count={values.uisSiContactOccurrences?.length}
                      variant="outlined"
                      color="primary"
                      size="small"
                      page={contactTypePageNumber}
                      errorindex={handleGetErrors(values.uisSiContactOccurrences, 'uisSiContactOccurrences')}
                      onChange={(e, p) => { setContactTypePageNumber(p); }} />
                  </>
                }

              </Grid>
            </CardContent>
          </NavCard>
        }

      </NavOptionsGrid>
      <NavOptionsGrid item xs={6}>
        {values.employerSepReasonCode === EmployerSepReasonCodes_class.enum.Item7 &&
          <NavCard>
            <CardContent>
              <Grid container spacing={1}>
                <Label>{EmployerSepReasonCodes_class.enumByNumber[EmployerSepReasonCodes_class.enum.Item7]}</Label>

                {isReasonForBreak(values) &&
                  <BreakReasonCodeField
                    label={"Reason for break"}
                    name={`reasonForBreak`}
                    value={values.reasonForBreak}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }
                {isFirstDayOfBreak(values) &&
                  <SiDateField
                    label={"First day of break for claimant"}
                    name={`firstDayOfBreak`}
                    value={values.firstDayOfBreak}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputlabelprops={{ shrink: true }}
                    size={6}
                  />
                }
                {isLastDayOfBreak(values) &&
                  <SiDateField
                    label={"Last day of break for claimant"}
                    name={`lastDayOfBreak`}
                    value={values.lastDayOfBreak}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputlabelprops={{ shrink: true }}
                    size={6}
                  />
                }
                {opWorkContinueDuringBreakInd(values) &&
                  <IndicatorTypeField
                    label={"Does the claimant’s work continue during the break?"}
                    name={`workContinueDuringBreakInd`}
                    value={values.workContinueDuringBreakInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isPositionReturningTo(values) &&
                  <SiTextField
                    label={"What position will the claimant be returning to?"}
                    name={`positionReturningTo`}
                    value={values.positionReturningTo}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.positionReturningTo_Attributes.stringLength.maxLength}
                  />
                }
                {(isNewContractJobOfferInd(values) || opNewContractJobOfferInd(values)) &&
                  <IndicatorTypeField
                    label={"Has a new contract or job offer been made?"}
                    name={`newContractJobOfferInd`}
                    value={values.newContractJobOfferInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isPersonOfferedReasonableAssurance(values) && values.notWorkingOnAssignment !== NotWorkingCodes_class.enum.Item3 &&
                  <SiTextField
                    label={"Who offered the claimant assurance for continued employment or offered continued work?"}
                    name={`personOfferedReasonableAssurance`}
                    value={values.personOfferedReasonableAssurance}
                errors={errors}
                setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.personOfferedReasonableAssurance_Attributes.stringLength.maxLength}
                  />
                }
                {isHaveAuthorityToOfferInd(values) &&
                  <IndicatorTypeField
                    label={"Did the individual have the authority to make the offer?"}
                    name={`haveAuthorityToOfferInd`}
                    value={values.haveAuthorityToOfferInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isHowOfferConveyed(values) && values.notWorkingOnAssignment !== NotWorkingCodes_class.enum.Item3 &&
                  <WrittenVerbalBothCodeField
                    label={"How was the offer conveyed?"}
                    name={`howOfferConveyed`}
                    value={values.howOfferConveyed}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isContractAccepted(values) &&
                  <IndicatorTypeField
                    label={"Has the contract or job offer been accepted?"}
                    name={`contractAccepted`}
                    value={values.contractAccepted}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isWhyContractNotOffered(values) &&
                  <SiTextAreaField
                    label={"Why was a new contract or job offer not made?"}
                    name={`whyContractNotOffered`}
                    value={values.whyContractNotOffered}
                errors={errors}
                setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.whyContractNotOffered_Attributes.stringLength.maxLength}
                  />
                }
              </Grid>
            </CardContent>
          </NavCard>
        }

        {values.employerSepReasonCode === EmployerSepReasonCodes_class.enum.Item17 &&
          <NavCard>
            <CardContent>
              <Grid container spacing={1}>
                <Label>{EmployerSepReasonCodes_class.enumByNumber[EmployerSepReasonCodes_class.enum.Item17]}</Label>

                <br/><br/>

                {isFirstDayOfContract(values) &&
                  <SiDateField
                    label={"First day of contract?"}
                    name={`firstDayOfContract`}
                    value={values.firstDayOfContract}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputlabelprops={{ shrink: true }}
                    //size={6}
                  />
                }
                {isLastDayOfContract(values) &&
                  <SiDateField
                    label={"Last day of contract?"}
                    name={`lastDayOfContract`}
                    value={values.lastDayOfContract}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputlabelprops={{ shrink: true }}
                    //size={6}
                  />
                }
                {(isFirstDayOfBreak(values) || opFirstDayOfBreak(values)) &&
                  <SiDateField
                    label={"Off season start date"}
                    name={`firstDayOfBreak`}
                    value={values.firstDayOfBreak}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputlabelprops={{ shrink: true }}
                    //size={6}
                  />
                }
                {(isLastDayOfBreak(values) || opLastDayOfBreak(values)) &&
                  <SiDateField
                    label={"Off season end date"}
                    name={`lastDayOfBreak`}
                    value={values.lastDayOfBreak}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputlabelprops={{ shrink: true }}
                    //size={6}
                  /> 
                }
                {isNewContractJobOfferInd(values) &&
                  <IndicatorTypeField
                    label={"Has a new contract or job offer been made?"}
                    name={`newContractJobOfferInd`}
                    value={values.newContractJobOfferInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isPersonOfferedReasonableAssurance(values) && values.notWorkingOnAssignment !== NotWorkingCodes_class.enum.Item3 &&
                  <SiTextField
                    label={"Who offered the claimant assurance for continued employment or offered continued work?"}
                    name={`personOfferedReasonableAssurance`}
                    value={values.personOfferedReasonableAssurance}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.personOfferedReasonableAssurance_Attributes.stringLength.maxLength}
                  />
                }
                {isHaveAuthorityToOfferInd(values) &&
                  <IndicatorTypeField
                    label={"Did the individual have the authority to make the offer?"}
                    name={`haveAuthorityToOfferInd`}
                    value={values.haveAuthorityToOfferInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }
                {isHowOfferConveyed(values) && values.notWorkingOnAssignment !== NotWorkingCodes_class.enum.Item3 &&
                  <WrittenVerbalBothCodeField
                    label={"How was the offer conveyed?"}
                    name={`howOfferConveyed`}
                    value={values.howOfferConveyed}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isContractAccepted(values) &&
                  <IndicatorTypeField
                    label={"Has the contract or job offer been accepted?"}
                    name={`contractAccepted`}
                    value={values.contractAccepted}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isWhyContractNotOffered(values) &&
                  <SiTextAreaField
                    label={"Why was a new contract or job offer not made?"}
                    name={`whyContractNotOffered`}
                    value={values.whyContractNotOffered}
                errors={errors}
                setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.whyContractNotOffered_Attributes.stringLength.maxLength}
                  />
                }
              </Grid>
            </CardContent>
          </NavCard>
        }

        {(values.whyNotReturning === NotReturningCodes_class.enum.Item16 || values.employerSepReasonCode === EmployerSepReasonCodes_class.enum.Item16) &&
          <NavCard>
            <CardContent>
              <Grid container spacing={1}>
                <Label>{EmployerSepReasonCodes_class.enumByNumber[EmployerSepReasonCodes_class.enum.Item16]}</Label>

                {isLaborDisputeTypeInd(values) &&
                  <IndicatorTypeField
                    label={"Is the claimant not working due to a strike or a lockout?"}
                    name={`laborDisputeTypeInd`}
                    value={values.laborDisputeTypeInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {opLocationOfLaborDispute(values) &&
                  <SiTextAreaField
                    label={"What is the location of the establishment where the labor dispute exists?"}
                    name={`locationOfLaborDispute`}
                    value={values.locationOfLaborDispute}
                errors={errors}
                setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.locationOfLaborDispute_Attributes.stringLength.maxLength}
                  />
                }
                {opUnionNameAndLocalNo(values) &&
                  <SiTextField
                    label={"What is the name and local number of the union involved in the labor dispute?"}
                    name={`unionNameAndLocalNo`}
                    value={values.unionNameAndLocalNo}
                errors={errors}
                setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.unionNameAndLocalNo_Attributes.stringLength.maxLength}
                  />
                }
                {isDateDisputeBegan(values) &&
                  <SiDateField
                    label={"When did the labor dispute begin?"}
                    name={`dateDisputeBegan`}
                    value={values.dateDisputeBegan}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputlabelprops={{ shrink: true }}
                    size={6}
                  />
                }
                {isLaborDisputeEndedInd(values) &&
                  <IndicatorTypeField
                    label={"Has the labor dispute ended?"}
                    name={`laborDisputeEndedInd`}
                    value={values.laborDisputeEndedInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isDateDisputeEnded(values) &&
                  <SiDateField
                    label={"When did the labor dispute end?"}
                    name={`dateDisputeEnded`}
                    value={values.dateDisputeEnded}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputlabelprops={{ shrink: true }}
                    size={6}
                  />
                }
                {isReplacementWorkersHiredInd(values) &&
                  <IndicatorTypeWithUnknownField
                    label={"Have permanent replacement workers been hired?"}
                    name={`replacementWorkersHiredInd`}
                    value={values.replacementWorkersHiredInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isClmtStrikingUnionMemberInd(values) &&
                  <IndicatorTypeField
                    label={"Is the employee a member of the striking union?"}
                    name={`clmtStrikingUnionMemberInd`}
                    value={values.clmtStrikingUnionMemberInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {opClmtMemberAnotherUnionInd(values) &&
                  <IndicatorTypeField
                    label={"Is the employee a member of another union?"}
                    name={`clmtMemberAnotherUnionInd`}
                    value={values.clmtMemberAnotherUnionInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {isWhyUnemployedLaborDispute(values) &&
                  <SiTextAreaField
                    label={"Explain fully why the employee is now unemployed."}
                    name={`whyUnemployedLaborDispute`}
                    value={values.whyUnemployedLaborDispute}
                errors={errors}
                setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.whyUnemployedLaborDispute_Attributes.stringLength.maxLength}
                  />
                }
                {opSettlementAffectClmtInd(values) &&
                  <IndicatorTypeField
                    label={"Will settlement of the labor dispute affect the employee’s wages, hours, or working conditions?"}
                    name={`settlementAffectClmtInd`}
                    value={values.settlementAffectClmtInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {opHowSettlementAffects(values) &&
                  <SiTextAreaField
                    label={"Describe how?"}
                    name={`howSettlementAffects`}
                    value={values.howSettlementAffects}
                errors={errors}
                setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.howSettlementAffects_Attributes.stringLength.maxLength}
                  />
                }
              </Grid>
            </CardContent>
          </NavCard>
        }

        {(
          (values.employerSepReasonCode === EmployerSepReasonCodes_class.enum.Item14 && values.voluntarySepReasonCode !== VoluntarySepReasonCodes_class.enum.Item8) ||
          opContinuingWorkAvailableInd(values) ||
          isWhyRetireComments(values) ||
          isWhyRetireComments(values)
        ) &&
          <NavCard>
            <CardContent>
              <Grid container spacing={1}>
                <Label>{EmployerSepReasonCodes_class.enumByNumber[EmployerSepReasonCodes_class.enum.Item14]}</Label>

                {(isMandatoryRetirementInd(values)) &&
                  <IndicatorTypeField
                    label={"Was the retirement mandatory?"}
                    name={`mandatoryRetirementInd`}
                    value={values.mandatoryRetirementInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
              {((isWhyRetireComments(values) || opWhyRetireComments(values)) && values.mandatoryRetirementInd !== 0 && values.voluntarySepReasonCode === undefined) &&
                  <SiTextAreaField
                    label={"Why did the claimant retire?"}
                    name={`whyRetireComments`}
                    value={values.whyRetireComments}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.whyRetireComments_Attributes.stringLength.maxLength}
                  />
              }
              {((isContinuingWorkAvailableInd(values) || opContinuingWorkAvailableInd(values)) && values.voluntarySepReasonCode === undefined) &&
                <IndicatorTypeField
                  label={"Continuing work available?"}
                  name={`continuingWorkAvailableInd`}
                  value={values.continuingWorkAvailableInd}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                />
              }
              </Grid>
            </CardContent>
          </NavCard>
        }

      </NavOptionsGrid>
    </Grid>
  )
}

export default SiMisc;
