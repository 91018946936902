import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { getType } from 'typesafe-actions';
import {
  Box, Checkbox, Card, CardContent, IconButton,
  Grid, InputAdornment, TextField, Typography
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { rootSelector } from '../../../common/selectors/selectors';
import { ContactDto } from '../contacts.dtos';
import ContactListCard from './contactListCard';
import { getContactsActions, contactShouldRefresh } from '../contacts.actions';
import { isReadOnly } from '../../../common/dtos/auth';
import { Checkbox1, EndButton, LargeIconButton } from '../../../common/styles/styles';
import { FeatureTypeEnum } from '../../../common/enums/featureTypeEnum';


function useQuery() {
  return new URLSearchParams(useLocation().search);
}


const ContactList: React.FC = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  let query = useQuery();
  const limit = 9; 

  const systemContactsList = rootSelector(state => state.contacts.contactsList);

  const [listPage, setListPage] = React.useState(1);
  const [resultCount, setResultCount] = React.useState(0);
  const [viewActiveOnly, setViewActiveOnly] = React.useState(true);
  const [contactsList, setContactsList] = React.useState([] as ContactDto[]);
  const [searchQuery, setSearchQuery] = React.useState("");
 

  React.useEffect(() => {
    dispatch({ type: getType(getContactsActions.request) });
  }, [dispatch]);

  React.useEffect(() => {
    let search = query.get("search");
    if (search !== undefined && search !== null && search.length !== 0) {
      setSearchQuery(search);
    }
  }, []);

  React.useEffect(() => {
    let s = searchQuery.toLocaleLowerCase();
    let contacts = [...systemContactsList];
    
    let list = contacts
      .filter((item: ContactDto) => !viewActiveOnly || item.isActive)
      .filter((item: ContactDto) => (
        (item.fullName !== undefined && item.fullName.toLowerCase().includes(s))
        || (item.primaryCompanyName !== undefined && item.primaryCompanyName.toLowerCase().includes(s))
        || (item.alternateEmployers !== undefined && item.alternateEmployers.toLowerCase().includes(s))
        || (item.functions !== undefined && item.functions.toLowerCase().includes(s))
      ))
      .sort((a, b) => (a.fullName ?? '').localeCompare(b.fullName ?? ''));
    
    setResultCount(list.length);
    setContactsList(list.slice((listPage * limit) - limit, (listPage * limit)));
  }, [systemContactsList, searchQuery, listPage, viewActiveOnly]);


  const onAddContact = () => {
    let s = isReadOnly(FeatureTypeEnum.Contacts);
    if (s === false)
      dispatch({ type: getType(contactShouldRefresh), payload: '' });
    window.location.assign('contact/new');
  }

  const onCompaniesImport = () => {
    history.push('/bulk-import/contacts');
  }

  const handleViewActiveOnlyToggle = () => {
    setViewActiveOnly(!viewActiveOnly);
  }

  const handleCancelSearch = () => {
    setSearchQuery('');
    setListPage(1);
  }


  return <>
    <Box p={2}>
      <Grid container data-cy="contact_list_Container">
        <Grid item xs={11}>
          <Typography variant="subtitle1" display="inline">Contacts</Typography>
          <Typography variant="subtitle1" color="textSecondary" display="inline">{" "} ({resultCount})</Typography>
        </Grid>
        <Grid item xs={1} hidden={isReadOnly(FeatureTypeEnum.Contacts)} >
          <LargeIconButton title="Add Contact" hidden={isReadOnly(FeatureTypeEnum.Contacts)} color="secondary" onClick={onAddContact} data-cy="contact_add_button">
            <AddBoxRoundedIcon />
          </LargeIconButton>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <Formik initialValues={{ search: searchQuery }}
              validateOnChange={false}
              validateOnBlur={false}
              validationSchema={Yup.object({
                search: Yup.string().max(200, 'Must be 200 characters or less')
              })}
              onSubmit={(values) => {
                setSearchQuery(values.search);
              }}
              enableReinitialize={true}
            >
              {(props) => {
                const { values, touched, errors, handleChange, handleSubmit, handleBlur } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      id="search"
                      size="small"
                      margin="none"
                      value={values.search}
                      label="Search"
                      name="search"
                      fullWidth
                      onChange={handleChange}
                      onBlur={(e) => { handleBlur(e); handleSubmit(); }}
                      error={Boolean(errors.search)}
                      helperText={(errors.search && touched.search) && errors.search}
                      data-cy="contactsListSearch"
                      InputProps={{
                        startAdornment: (<InputAdornment position="start">
                          <IconButton
                            aria-label="Search Contacts"
                            onClick={() => handleSubmit()}
                            edge="start"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>),
                        endAdornment: (searchQuery.length === 0 ? <></> : <InputAdornment position="end">
                          <IconButton
                            aria-label="Clear Search"
                            onClick={() => handleCancelSearch()}
                            edge="start"
                          >
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>)
                      }}
                    />
                  </form>);
              }}
            </Formik>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Checkbox1 onChange={handleViewActiveOnlyToggle} value={!viewActiveOnly} checked={!viewActiveOnly}
              control={<Checkbox size="small" data-cy="pageTemplate_template_3ViewActiveOnly" />}
              label={<Typography variant="subtitle2">Show inactive</Typography>} />
          </Grid>
          <Grid item xs={12} sm={4} hidden={isReadOnly(FeatureTypeEnum.Contacts)}>
            <EndButton hidden={isReadOnly(FeatureTypeEnum.Contacts)} title="Bulk Import" type="button" variant="contained" color="primary"
              onClick={onCompaniesImport} disabled={isReadOnly(FeatureTypeEnum.Contacts)} data-cy="contactsListImport">Bulk Import</EndButton>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {contactsList
            .map((o, index) => (
              <ContactListCard key={'ContactCard-' + index} contact={o} />
            ))}
        </Grid>

        <Grid item xs={12}>&nbsp;</Grid>
        <Grid item xs={12}>
          <Pagination
            hidden={contactsList.length === 0}
            count={
              Math.ceil(resultCount / limit)
            }
            variant="outlined"
            color="primary"
            size="small"
            page={listPage}
            onChange={(e, p) => { setListPage(p); }} />
        </Grid>

        {contactsList.length === 0 &&
          <Grid item xs={12}>
            <Card>
              <CardContent>
              <Grid container justifyContent="center" alignItems="center">
                  <Typography variant="subtitle1" color="secondary">No records found{searchQuery.length === 0 ? '.' : ' matching ' + searchQuery}</Typography>
                </Grid>
              </CardContent>
            </Card>
            <br />
          </Grid>
        }
      </Grid>
    </Box>
  </>
}

export default ContactList;
