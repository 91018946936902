import React from 'react';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';

import HearingContactDetails from '../formComponents/hearingContactDetails';
import EligibilityEventPanel from '../formComponents/eligibilityEventPanel';
import AppellateEventPanel from '../formComponents/appellateEventPanel';
import ClaimEventPanel from '../formComponents/claimEventPanel';

// material ui
import { Grid, Card, CardContent } from '@material-ui/core';
import { rootSelector } from '../../../common/selectors/selectors';


import { ClaimDto, ClaimInit } from '../claims.dtos';
import { getHearingContactsInformationActions, getAppellateEventsActions, getEligibilityEventsActions } from '../actions';
import { ContactInformationDto } from '../../../common/dtos/contactInformation';
import { emailTemplateRecipient, toggleEmailTemplateDialog } from '../../../common/actions/emailTemplate.actions';
import { SeparationCodeDto, EligibilityCodeDto } from '../../settings/codes/codes.dtos';

interface ClaimEventsProps extends React.HTMLProps<React.ReactNode> {
  isClientUser: boolean;
  isReadOnly: boolean;
  separationCodes: SeparationCodeDto[];
  eligibilityCodes: EligibilityCodeDto[];
}

const ClaimEvents: React.FC<ClaimEventsProps> = (
  {
    isClientUser,
    isReadOnly,
    separationCodes,
    eligibilityCodes
  }) => {
  const dispatch = useDispatch();

  const systemClaim = rootSelector(state => state.claims.claim);
  const systemHearingContacts = rootSelector(state => state.claims.hearingContactsInfoList);

  const [dto, setDto] = React.useState(ClaimInit as ClaimDto);
  const [hearingContacts, setHearingContacts] = React.useState<ContactInformationDto[]>([]);

  // Todo: Finish troubleshooting hearing contact not updating issue.
  React.useEffect(() => {
    setDto(systemClaim);
    if (systemClaim.hearingContacts === undefined || systemClaim.hearingContacts.length === 0)
      setHearingContacts([]);
      }, [systemClaim]);
  React.useEffect(() => {
    setHearingContacts(systemHearingContacts);
    return () => { setHearingContacts([]); }
  }, [systemHearingContacts]);

  React.useEffect(() => {
    dispatch({ type: getType(getHearingContactsInformationActions.request), payload: systemClaim.hearingContacts });
  }, [dispatch, systemClaim]);  


  const handleGetEmailPrompt = (email: string) => {
    dispatch({ type: getType(emailTemplateRecipient), payload: email });
    dispatch({ type: getType(toggleEmailTemplateDialog), payload: true });
  }


  return <Grid container spacing={2}>
    <Grid item md={12} lg={9}>
      <br /><br />

      <EligibilityEventPanel
        claim={dto}
        isReadOnly={isReadOnly}
        isClientUser={isClientUser}
      />

      <br /><br />

      <AppellateEventPanel
        claim={dto}
        isReadOnly={isReadOnly}
        isClientUser={isClientUser}
        separationCodes={separationCodes}
        eligibilityCodes={eligibilityCodes}
      />

      <br /><br />

      <ClaimEventPanel
        claim={dto}
        isClientUser={isClientUser}
        isReadOnly={isReadOnly}
        separationCodes={separationCodes}
      />

      <br /><br />
      <br /><br />
    </Grid>

    <Grid container item md={12} lg={3}>
      <Card>
        <CardContent>
          Hearing Contact Details
          {hearingContacts.map((o, i) => (
          <Grid item xs={12} key={'hearing_contact_' + i}>
            <HearingContactDetails isReadOnly={isReadOnly} isClientUser={isClientUser} contact={o} index={i} handleEmailPrompt={handleGetEmailPrompt} />
          </Grid>
        ))}
        </CardContent>
      </Card>
    </Grid>
  </Grid>
}

export default ClaimEvents;