import React from 'react';
import { Prompt } from 'react-router';
import { Formik, FieldArray, getIn } from 'formik';
import * as Yup from 'yup';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import { getType } from 'typesafe-actions';
import { useDispatch } from 'react-redux';

import { CreateQuestionnaireDto, createQuestionnaireDialogInit } from './dto';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { createQuestionnaireActions } from './actions';
import {
  DialogCardContent,
  DialogInputLabel,
} from '../settings/codes/codes.styles';
import {
  ButtonGrid,
  StyledSubmitButton,
  StyledCancelButton,
  RemoveIcon,
  QuestionnaireAddIcon,
} from '../../common/styles/styles';
import { ClaimDto } from '../claims/claims.dtos';
import { requiredError } from '../../common/constants/strings';

interface Props {
  claim: ClaimDto;
  open: boolean;
  onCancel: () => void;
  isReadOnly: boolean;
  isComplete: boolean;
}

const CreateQuestionnaireDialog: React.FC<Props> = ({
  claim,
  onCancel,
  isReadOnly,
  open,
  isComplete,
}) => {
  const dispatch = useDispatch();

  const [dto, setDto] = React.useState<CreateQuestionnaireDto>(
    createQuestionnaireDialogInit
  );

  React.useEffect(() => {
    setDto({
      claim: claim,
      additionalQuestionnaireInformation:
        claim.additionalQuestionnaireInformation,
      provideFullSSN: claim.questionnaireProvideFullSSN,
      questions: claim.additionalQuestionnaireQuestions
        ? [...claim.additionalQuestionnaireQuestions]
        : [],
    });
  }, [claim]);

  const cancelDialog = () => onCancel();

  return (
    <Formik
      initialValues={dto}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={Yup.object({
        questions: Yup.array().of(
          Yup.string()
            .max(500, 'Must be 500 characters or less')
            .required(requiredError)
            .min(1, 'Please enter a question')
        ),
        additionalQuestionnaireInformation: Yup.string().max(
          2000,
          'Must be 2000 characters or less'
        ),
        provideFullSSN: Yup.boolean(),
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setTimeout(() => {
          setSubmitting(false);
          resetForm();
          cancelDialog();
        }, 700);

        dispatch({
          type: getType(createQuestionnaireActions.request),
          payload: values,
        });
      }}
      enableReinitialize={true}
    >
      {(props) => {
        const {
          values,
          errors,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
        } = props;
        return (
          <Dialog
            open={open}
            data-cy='createQuestionnaireDialog_dialog'
            fullWidth
            maxWidth='sm'
          >
            <DialogTitle>Create Questionnaire</DialogTitle>
            <DialogContent>
              <form onSubmit={handleSubmit}>
                <FieldArray
                  name='questions'
                  render={(arrayHelpers) => (
                    <>
                      <DialogCardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={11}>
                            <DialogInputLabel>
                              Additional Questions (Optional){' '}
                            </DialogInputLabel>
                          </Grid>

                          <Grid item xs={1}>
                            <Grid
                              container
                              item
                              justifyContent='flex-end'
                              xs={12}
                            >
                              <QuestionnaireAddIcon
                                title='Add to questions list'
                                color='secondary'
                                disabled={isReadOnly || isComplete}
                                onClick={() => {
                                  arrayHelpers.push({
                                    question: '',
                                    answer: '',
                                  });
                                }}
                              >
                                <AddBoxRoundedIcon />
                              </QuestionnaireAddIcon>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container>
                              {values.questions &&
                                values.questions.map((q, index) => (
                                  <Grid
                                    container
                                    key={`questions[${index}].question` + index}
                                  >
                                    <Grid item xs={11}>
                                      <TextField
                                        id={`questions[${index}].question`}
                                        name={`questions[${index}].question`}
                                        onChange={handleChange}
                                        fullWidth
                                        value={q.question || ''}
                                        data-cy='createQuestionnaireDialog_additionalQuestionInput'
                                        onBlur={handleBlur}
                                        disabled={isReadOnly || isComplete}
                                        error={Boolean(
                                          getIn(
                                            errors,
                                            `questions[${index}].question`
                                          )
                                        )}
                                        helperText={
                                          Boolean(getIn(errors, q.question)) &&
                                          getIn(
                                            errors,
                                            `questions[${index}].question`
                                          )
                                        }
                                      />
                                    </Grid>
                                    <br />
                                    <Grid
                                      container
                                      justifyContent='flex-end'
                                      item
                                      xs={1}
                                    >
                                      <RemoveIcon
                                        title='Add to questions list'
                                        color='secondary'
                                        disabled={isReadOnly || isComplete}
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                        data-cy='createQuestionnaireDialog_additionalQuestionInput_remove'
                                      >
                                        <IndeterminateCheckBoxIcon />
                                      </RemoveIcon>
                                    </Grid>
                                    <br />
                                    <br />
                                  </Grid>
                                ))}
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              id='additionalQuestionnaireInformation'
                              name='additionalQuestionnaireInformation'
                              label='Additional Information'
                              onChange={handleChange}
                              fullWidth
                              value={
                                values.additionalQuestionnaireInformation || ''
                              }
                              data-cy='createQuestionnaireDialog_additionalQuestionnaireInformation'
                              onBlur={handleBlur}
                              disabled={isReadOnly || isComplete}
                              error={Boolean(
                                getIn(
                                  errors,
                                  `additionalQuestionnaireInformation`
                                )
                              )}
                              helperText={
                                Boolean(
                                  getIn(
                                    errors,
                                    `additionalQuestionnaireInformation`
                                  )
                                ) &&
                                getIn(
                                  errors,
                                  `additionalQuestionnaireInformation`
                                )
                              }
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <FormControlLabel
                              id='provideFullSSN'
                              name='provideFullSSN'
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.provideFullSSN || false}
                              checked={values.provideFullSSN || false}
                              disabled={isReadOnly || isComplete}
                              control={
                                <Checkbox
                                  size='small'
                                  data-cy='createQuestionnaireDialog_provideFullSSN'
                                />
                              }
                              label={
                                <Typography variant='subtitle2'>
                                  Provide full SSN
                                </Typography>
                              }
                            />
                          </Grid>
                        </Grid>
                      </DialogCardContent>
                      <DialogActions>
                        <ButtonGrid container>
                          <StyledSubmitButton
                            type='submit'
                            color='primary'
                            disabled={isSubmitting || isReadOnly || isComplete}
                            data-cy='createQuestionnaireDialog_submitButton'
                          >
                            Send
                          </StyledSubmitButton>

                          <StyledCancelButton
                            data-cy='createQuestionnaireDialog_cancelButton'
                            color='secondary'
                            onClick={cancelDialog}
                          >
                            Cancel
                          </StyledCancelButton>
                        </ButtonGrid>
                      </DialogActions>
                    </>
                  )}
                />
              </form>
            </DialogContent>
            <Prompt message='You have unsaved edits, are you sure you want to leave?' />
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default CreateQuestionnaireDialog;
