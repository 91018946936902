import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { claimantReportActions } from '../actions/claimant';
import { ClaimantReportDto } from '../dtos/claimantReportDto';



export const claimantsReducer = combineReducers({
  claimantReportList: createReducer([] as ClaimantReportDto[])
    .handleAction(
      [claimantReportActions.success, ], (state, action) => action.payload),
});

export default claimantsReducer;