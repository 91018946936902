import React from 'react';
import { useDispatch } from 'react-redux';
import { getIn, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  Dialog, DialogContent, DialogTitle, Grid, TextField, DialogActions,
  MenuItem, FormControlLabel
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getType } from 'typesafe-actions';
import { TimeZonesDto } from '../../../common/dtos/timeZones';
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { getCountriesActions, getGoverningDistrictsActions } from '../../account/profile/profile.actions';
import { updateAppellateEventActions, createAppellateEventActions, getMhaHearingRepresentativesActions } from '../actions'
import { getFullName } from '../../../common/dtos/auth';
import { ClaimDto, AppellateEventDto, AppellateEventInit, ClaimInit, ContactIdDto, SepCodeDto } from '../claims.dtos'
import { rootSelector } from '../../../common/selectors/selectors';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { getTimeZonesActions } from '../../../common/actions/timeZones.actions';
import { GoverningDistrictDto } from '../../account/profile/profile.dtos';
import { chargeOptions, alternativeIssueOptions, eventTypeDropdownOptions, hearingOutcomeDropdownOptions } from '../options';
import { ButtonGrid, StyledCancelButton, StyledSubmitButton, StyledCardContent, PaddedTextField, MHARadio } from '../../../common/styles/styles';
import { DialogInputLabel, DisabledHearingTextField } from '../claims.styles';
import moment from 'moment';
import { EligibilityCodeDto, SeparationCodeDto } from '../../settings/codes/codes.dtos';


interface Props {
  open: boolean;
  onCancel: () => void;
  existingEvent?: AppellateEventDto;
  isClientUser: boolean;
  isReadOnly: boolean;
  separationCodes: SeparationCodeDto[];
  eligibilityCodes: EligibilityCodeDto[];
}

const AppellateEventDialog: React.FC<Props> = (
  {
    open,
    onCancel,
    existingEvent,
    isClientUser,
    isReadOnly,
    separationCodes,
    eligibilityCodes
  }) => {
  const dispatch = useDispatch();

  const governingDistricts = rootSelector(state => state.governingDistricts.governingDistrictList);
  const systemTimeZones = rootSelector(state => state.timeZones.timeZonesList);
  const systemClaim = rootSelector(state => state.claims.claim);
  const sytemHearingRepresentatives = rootSelector(state => state.claims.mhaHearingRepresentativesList);

  const [timeZones, setTimeZones] = React.useState([] as TimeZonesDto[]);
  const [dto, setDto] = React.useState<AppellateEventDto>(AppellateEventInit);
  const [claim, setClaim] = React.useState(ClaimInit as ClaimDto);
  const [hearingRepresentatives, setHearingRepresentatives] = React.useState([] as ContactIdDto[]);
  const [codes, setCodes] = React.useState([] as SepCodeDto[]);


  React.useEffect(() => {
    dispatch({ type: getType(getCountriesActions.request) });
    dispatch({ type: getType(getGoverningDistrictsActions.request) });
    dispatch({ type: getType(getTimeZonesActions.request) });
    dispatch({ type: getType(getMhaHearingRepresentativesActions.request) });
  }, [dispatch]);

  React.useEffect(() => setDto(existingEvent ? existingEvent : AppellateEventInit), [existingEvent]);
  React.useEffect(() => setClaim(systemClaim), [systemClaim]);
  React.useEffect(() => setHearingRepresentatives(sytemHearingRepresentatives), [sytemHearingRepresentatives]);
  React.useEffect(() => setTimeZones(systemTimeZones), [systemTimeZones]);

  React.useEffect(() => {
    let codes = [] as SepCodeDto[];
    (JSON.parse(JSON.stringify(separationCodes)) as SeparationCodeDto[]).forEach(s => codes.push({ id: s.id ?? '', name: s.name ?? '', description: s.description ?? '', isSep: true }));
    (JSON.parse(JSON.stringify(eligibilityCodes)) as EligibilityCodeDto[]).forEach(s => codes.push({ id: s.id ?? '', name: s.name ?? '', description: s.description ?? '', isSep: false }));
    setCodes(codes);
  }, [separationCodes, eligibilityCodes]);

  const cancelDialog = () => onCancel();
  const handleHearingDate = (values: AppellateEventDto, date: MaterialUiPickersDate, setFieldValue: FormikHelpers<AppellateEventDto>["setFieldValue"]) =>
  {
    if (date?.isValid()) {
      let oldDateTime = moment(values.hearingDateTime);
      setFieldValue('hearingDateTime', moment(date).hour(oldDateTime.hour()).minute(oldDateTime.minute()).second(0).toDate());
    }
  };

  const handleHearingDateTime = (values: AppellateEventDto, date: MaterialUiPickersDate, setFieldValue: FormikHelpers<AppellateEventDto>["setFieldValue"]) => {
    if (date?.isValid()) {
      let oldDateTime = moment(values.hearingDateTime);
      setFieldValue('hearingDateTime', moment(date).year(oldDateTime.year()).month(oldDateTime.month()).date(oldDateTime.date()).toDate());
    }
  };


  return <Formik
    initialValues={dto}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={Yup.object().shape({
      type: Yup.number().min(0).max(9).nullable(),
      dueDate: Yup.date().nullable(),
      hearingType: Yup.number().min(0).max(2).nullable(),
      docketIdentifier: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
      appealedById: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
      hearingLocation: Yup.number().min(0).max(2).nullable(),
      city: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
      state: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
      hearingDateTime: Yup.date().nullable(),
      timeZoneId: Yup.number().nullable(),
      dateRecieved: Yup.date().nullable(),
      refereeFullName: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
      hearingOutcome: Yup.number().min(0).max(7),
      hearingDecisionDate: Yup.date().nullable(),
      hearingRepresentatives: Yup.array().of(Yup.object().nullable().shape({
        id: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
      })),
      hearingCharge: Yup.number().min(0).max(3).nullable(),
      investigationCharge: Yup.number().min(0).max(3).nullable(),
      completedDate: Yup.date().nullable(),
      alternativeIssueTypeId: Yup.number().nullable(),
      comments: Yup.string().max(200, 'Must be 2000 characters or less').nullable(),
    })
    }
    onSubmit={(values, { setSubmitting, resetForm }) => {
      setTimeout(() => {
        setSubmitting(false);
        resetForm();
      }, 700);

      let request = values;
      request.claimId = claim.id;
      request.createdBy = getFullName();

      dispatch({
        type: values.id ?
          getType(updateAppellateEventActions.request) :
          getType(createAppellateEventActions.request),
        payload: request
      });
      cancelDialog();
    }}
    enableReinitialize={true}
  >
    {(props) => {
      const {
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        setFieldValue
      } = props;
      return (

        <Dialog
          open={open}
          data-cy="appellate_event_dialog"
        >
          <DialogTitle>Add Event - Appellate</DialogTitle>
          <DialogContent>
            <StyledCardContent>
              <Grid container alignContent="center" item xs={12}>
                <Grid item xs={5}>
                  <DialogInputLabel>Event Type</DialogInputLabel>
                  <TextField
                    id="type"
                    name="type"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleChange(e);
                      if (parseInt((e.target as HTMLInputElement).value) === 9) {
                        setFieldValue('hearingCharge', 3)
                        setFieldValue('investigationCharge', 3)
                      }
                    }}
                    value={values.type}
                    select
                    fullWidth
                    InputProps={{ readOnly: isClientUser || isReadOnly }}
                    data-cy="appellate_event_type"
                    onBlur={handleBlur}
                    error={Boolean(errors.type)}
                    helperText={errors.type}>
                    <MenuItem value={0} disabled>Please select an item</MenuItem>
                    {
                      eventTypeDropdownOptions.map(o => {
                        return (
                          <MenuItem key={'item-' + o.name} value={o.id}>{o.name}</MenuItem>
                        )
                      })
                    }
                  </TextField>

                </Grid>
                <Grid item xs={7}></Grid>
                <Grid item xs={5}>
                  <DialogInputLabel>Due Date</DialogInputLabel>
                  <KeyboardDatePicker
                    margin="normal"
                    id="dueDate"
                    name="dueDate"
                    format="MM/DD/yyyy"
                    fullWidth
                    disabled={isClientUser || isReadOnly}
                    data-cy="appellate_event_dueDate"
                    value={values.dueDate}
                    onChange={(date: MaterialUiPickersDate) => setFieldValue('dueDate', date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                <Grid item xs={7}></Grid>



                <Grid item xs={5}>
                  <DialogInputLabel>Issue</DialogInputLabel>
                  <DisabledHearingTextField
                    id="hearingType"
                    name="hearingType"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={codes.find(o => claim.separationCodeId === o.id && o.isSep)?.description}
                    fullWidth
                    disabled
                    data-cy="appellate_event_hearingType"
                    error={Boolean(errors.hearingType)}
                    helperText={errors.hearingType}
                  />
                </Grid>
                <Grid item xs={7}></Grid>
                <Grid item xs={5}>
                  <DialogInputLabel>Alternative Issue</DialogInputLabel>
                  <TextField
                    id="alternativeIssueTypeId"
                    name="alternativeIssueTypeId"
                    onChange={handleChange}
                    value={values.alternativeIssueTypeId || 0} 
                    select
                    fullWidth
                    disabled={isClientUser || isReadOnly}
                    data-cy="appellate_event_alternativeIssueTypeId"
                    onBlur={handleBlur}
                    error={Boolean(errors.alternativeIssueTypeId)}
                    helperText={errors.alternativeIssueTypeId}>
                    <MenuItem value={0} disabled>Please select an item</MenuItem>
                    {
                      alternativeIssueOptions.map(o => {
                        return (
                          <MenuItem key={'item-' + o.name} value={o.id}>{o.name}</MenuItem>
                        )
                      })
                    }
                  </TextField>

                </Grid>
                <Grid item xs={7}></Grid>
                <Grid item xs={5}>
                  <DialogInputLabel>Docket Number</DialogInputLabel>

                  <TextField
                    id="docketIdentifier"
                    size="small"
                    margin="none"
                    value={values.docketIdentifier || ''}
                    name="docketIdentifier"
                    onChange={e => { handleChange(e) }}
                    onBlur={handleBlur}
                    disabled={isClientUser || isReadOnly}

                    error={(Boolean(errors.docketIdentifier && touched.docketIdentifier))}
                    helperText={(Boolean(errors.docketIdentifier) && touched.docketIdentifier) && errors.docketIdentifier}
                    data-cy="appellate_event_docketIdentifier"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={7}></Grid>

                <Grid item xs={5}>
                  <DialogInputLabel>Appealed By</DialogInputLabel>
                  <TextField
                    id="appealedBy"
                    name="appealedBy"
                    onChange={handleChange}
                    value={values.appealedBy || ''}
                    select
                    fullWidth
                    disabled={isClientUser || isReadOnly}

                    data-cy="appellate_event_appealedBy"
                    onBlur={handleBlur}
                    error={Boolean(errors.appealedBy)}
                    helperText={errors.appealedBy}>
                    <MenuItem value={0} disabled>Please select an item</MenuItem>
                    <MenuItem value={1}>Claimant</MenuItem>
                    <MenuItem value={2}>Employer</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={7}></Grid>


                <Grid item xs={5}>
                  <DialogInputLabel>Hearing Location</DialogInputLabel>
                  <TextField
                    id="hearingLocation"
                    name="hearingLocation"
                    onChange={handleChange}
                    value={values.hearingLocation || ''}
                    select
                    fullWidth
                    data-cy="appellate_event_hearingLocation"
                    onBlur={handleBlur}
                    error={Boolean(errors.hearingLocation)}
                    disabled={isClientUser || isReadOnly}

                    helperText={errors.hearingLocation}>
                    <MenuItem value={0} disabled>Please select an item</MenuItem>
                    <MenuItem value={1}>Phone</MenuItem>
                    <MenuItem value={2}>In-Person</MenuItem>

                  </TextField>
                </Grid>
                <Grid item xs={7}></Grid>

                <Grid item xs={5}>
                  <DialogInputLabel>City</DialogInputLabel>

                  <TextField
                    id="city"
                    size="small"
                    margin="none"
                    value={values.city || ''}
                    name="city"
                    onChange={e => { handleChange(e) }}
                    disabled={isClientUser || isReadOnly}
                    onBlur={handleBlur}
                    error={(Boolean(errors.city && touched.city))}
                    helperText={(Boolean(errors.city) && touched.city) && errors.city}
                    data-cy="appellate_event_city"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={1}></Grid>


                <Grid item xs={5}>
                  <DialogInputLabel>State</DialogInputLabel>

                  <Autocomplete
                    id='state'
                    options={governingDistricts}
                    disabled={isClientUser || isReadOnly}
                    getOptionLabel={option => option.name}
                    value={governingDistricts.find(o => o.name === values.state) || null}
                    includeInputInList
                    autoComplete
                    onChange={(_e: any, v: GoverningDistrictDto | null) => { setFieldValue('state', v?.name ?? '') }}
                    renderInput={(params) => (
                      <PaddedTextField
                        {...params}
                        id='state'
                        size="small"
                        margin="none"
                        value={values.state || ''}
                        name="state"
                        onBlur={handleBlur}
                        error={Boolean(getIn(errors, 'state'))}
                        helperText={getIn(errors, 'state') && getIn(errors, 'state')}
                        data-cy='appellate_event_state'
                        fullWidth
                      />
                    )} />
                </Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={5}>
                  <DialogInputLabel>Hearing Date</DialogInputLabel>
                  <KeyboardDatePicker
                    margin="normal"
                    id="hearingDateTime"
                    name="hearingDateTime"
                    format="MM/DD/yyyy"
                    disabled={isClientUser || isReadOnly}
                    value={values.hearingDateTime}
                    data-cy="appellate_event_hearingDate"
                    fullWidth
                    onChange={(date: MaterialUiPickersDate) => handleHearingDate(values, date, setFieldValue)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />

                </Grid>
                <Grid item xs={7}></Grid>

                <Grid item xs={5}>
                  <DialogInputLabel>Hearing Time</DialogInputLabel>
                  <KeyboardTimePicker
                    margin="normal"
                    name="hearingDateTime"
                    id="hearingDateTime1"
                    disabled={isClientUser || isReadOnly}
                    value={values.hearingDateTime}
                    onChange={(date: MaterialUiPickersDate) => handleHearingDateTime(values, date, setFieldValue)}
                    data-cy="appellate_event_hearingTime"
                    fullWidth
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </Grid>
                <Grid item xs={1}></Grid>


                <Grid item xs={5} style={{ marginTop: -33 }}>

                  <Autocomplete
                    id="timeZoneId"
                    options={timeZones}
                    getOptionLabel={option => option.displayName ?? ''}
                    value={timeZones.find(o => o.timeZoneId === values.timeZoneId) || { displayName: 'Please select time zone', timeZoneId: 1 } as TimeZonesDto}
                    autoComplete
                    includeInputInList
                    disabled={isClientUser || isReadOnly}
                    onChange={(_e: any, v: TimeZonesDto | null) => { setFieldValue('timeZoneId', v?.timeZoneId ?? 1) }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id='timeZoneId'
                        size="small"
                        margin="none"
                        name="timeZoneId"
                        disabled={isClientUser || isReadOnly}
                        onBlur={handleBlur}
                        error={Boolean(getIn(errors, 'timeZoneId') && getIn(touched, 'timeZoneId'))}
                        helperText={(getIn(errors, 'timeZoneId') && getIn(touched, 'timeZoneId')) && getIn(errors, 'timeZoneId')}
                        data-cy='appellate_event_timeZoneId'
                        fullWidth
                      />
                    )} />

                </Grid>
                <Grid item xs={1}></Grid>

                <Grid item xs={5}>
                  <DialogInputLabel>Date Received</DialogInputLabel>
                  <KeyboardDatePicker
                    margin="normal"
                    name="dateRecieved"
                    format="MM/DD/yyyy"
                    id="dateRecieved"
                    disabled={isClientUser || isReadOnly}
                    value={values.dateRecieved}
                    onChange={(date: MaterialUiPickersDate) => setFieldValue('dateRecieved', date)}
                    data-cy="appellate_event_hearingTime"
                    fullWidth
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </Grid>
                <Grid item xs={7}></Grid>


                <Grid item xs={5}>
                  <DialogInputLabel>Referee Name</DialogInputLabel>

                  <TextField
                    id="refereeFullName"
                    size="small"
                    margin="none"
                    value={values.refereeFullName || ''}
                    name="refereeFullName"
                    onChange={e => { handleChange(e) }}
                    onBlur={handleBlur}
                    disabled={isClientUser || isReadOnly}
                    error={Boolean(errors.refereeFullName)}
                    helperText={Boolean(errors.refereeFullName) && errors.refereeFullName}
                    data-cy="appellate_event_refereeFullName"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={7}></Grid>

                <Grid item xs={5}>
                  <DialogInputLabel>Hearing Representative</DialogInputLabel>
                  <Autocomplete id="hearingRepresentatives"
                    options={hearingRepresentatives as ContactIdDto[]}
                    autoComplete
                    getOptionLabel={o => o.name ?? ''}
                    multiple
                    disabled={isClientUser || isReadOnly}
                    value={hearingRepresentatives.filter(o => values.hearingRepresentatives.map(m => m.id).includes(o.contactId))}
                    onChange={(_e: any, v: ContactIdDto[] | null) => { setFieldValue('hearingRepresentatives', (v === null ? ([] as ContactIdDto[]) : v.map(o => { return ({ id: o.contactId } as ContactIdDto) }))) }}
                    renderInput={(params) => (
                      <PaddedTextField
                        {...params}
                        id="hearingRepresentatives"
                        size="small"
                        margin="none"
                        onBlur={handleBlur}
                        disabled={isClientUser || isReadOnly}
                        error={(Boolean(errors.hearingRepresentatives && touched.hearingRepresentatives))}
                        helperText={(Boolean(errors.hearingRepresentatives) && touched.hearingRepresentatives) && errors.hearingRepresentatives}
                        data-cy="appellate_event_hearingRepresentatives"
                        fullWidth
                      />
                    )} />

                </Grid>
                <Grid item xs={7}></Grid>

                <Grid item xs={5}>
                  <DialogInputLabel>Hearing Decision Date</DialogInputLabel>
                  <KeyboardDatePicker
                    margin="normal"
                    id="hearingDecisionDate"
                    name="hearingDecisionDate"
                    format="MM/DD/yyyy"
                    disabled={isClientUser || isReadOnly}
                    data-cy="appellate_event_hearingDecisionDate"
                    value={values.hearingDecisionDate ?? null}
                    onChange={(date: MaterialUiPickersDate) => setFieldValue('hearingDecisionDate', date)}
                    fullWidth
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                <Grid item xs={7}></Grid>


                <Grid item xs={5}>
                  <DialogInputLabel>Hearing Outcome</DialogInputLabel>
                  <TextField
                    id="hearingOutcome"
                    name="hearingOutcome"
                    onChange={handleChange}
                    value={values.hearingOutcome}
                    select
                    fullWidth
                    disabled={isClientUser || isReadOnly}
                    data-cy="appellate_event_hearingOutcome"
                    onBlur={handleBlur}
                    error={Boolean(errors.hearingOutcome)}
                    helperText={errors.hearingOutcome}>
                    <MenuItem value={0} disabled>Please select an item</MenuItem>
                    {
                      hearingOutcomeDropdownOptions.map(o => {
                        return (
                          <MenuItem key={'item-' + o.name} value={o.id}>{o.name}</MenuItem>
                        )
                      })
                    }
                  </TextField>

                </Grid>

                <Grid item xs={7}></Grid>

                <DialogInputLabel>Hearing Charge</DialogInputLabel>

                <Grid container item xs={12} sm={12}>
                  {chargeOptions.map(o => (
                    <Grid item xs={12} sm={4} key={o.name}>
                      <FormControlLabel
                        onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => { setFieldValue('hearingCharge', chargeOptions.find(o => o.name === (e.target as HTMLInputElement).value)?.id ?? 1); }}
                        checked={o.id === values.hearingCharge}
                        value={o.name}
                        control={<MHARadio disabled={isClientUser || isReadOnly}
                          size="small" />} label={o.name} data-cy="changeMe" />
                    </Grid>
                  ))}
                </Grid>
                <DialogInputLabel>Investigation Charge</DialogInputLabel>

                <Grid container item xs={12} sm={12}>
                  {chargeOptions.map(o => (
                    <Grid item xs={12} sm={4} key={o.name}>
                      {/* dont forget onChange */}
                      <FormControlLabel
                        onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => { setFieldValue('investigationCharge', chargeOptions.find(o => o.name === (e.target as HTMLInputElement).value)?.id ?? 1); }}
                        checked={o.id === values.investigationCharge}
                        value={o.name}
                        control={<MHARadio disabled={isClientUser || isReadOnly}
                          size="small" />} label={o.name} data-cy="" />
                    </Grid>
                  ))}

                </Grid>

                <Grid item xs={5}>
                  <DialogInputLabel>Comments</DialogInputLabel>

                  <PaddedTextField
                    id="comments"
                    size="small"
                    margin="none"
                    multiline
                    value={values.comments || ''}
                    name="comments"
                    disabled={isClientUser || isReadOnly}
                    onChange={e => { handleChange(e) }}
                    onBlur={handleBlur}
                    data-cy="appellate_event_comments"
                    error={(Boolean(errors.comments && touched.comments))}
                    helperText={(Boolean(errors.comments) && touched.comments) && errors.comments}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={7}></Grid>


                <Grid item xs={5}>
                  <DialogInputLabel>Completed Date</DialogInputLabel>
                  <KeyboardDatePicker
                    margin="normal"
                    name="completedDate"
                    id="completedDate"
                    format="MM/DD/yyyy"
                    value={values.completedDate ?? null}
                    disabled={isClientUser || isReadOnly}
                    data-cy="appellate_event_completedDate"
                    onChange={(date: MaterialUiPickersDate) => setFieldValue('completedDate', date)}
                    fullWidth
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />  </Grid>

                <Grid item xs={7}></Grid>

              </Grid>
            </StyledCardContent>

            <DialogActions>
              <ButtonGrid container>
                <StyledSubmitButton
                  type="button"
                  color="primary"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting || isClientUser || isReadOnly}
                  data-cy="appellate_event_submitButton"
                >
                  Save
                </StyledSubmitButton>

                <StyledCancelButton
                  data-cy="appellate_event_cancelButton"
                  color="secondary"
                  onClick={cancelDialog}
                >
                  Cancel
                </StyledCancelButton>
              </ButtonGrid>
            </DialogActions>
          </DialogContent>
        </Dialog>
      );

    }}
  </Formik>
}

export default AppellateEventDialog;
