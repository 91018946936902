import { createAsyncAction } from 'typesafe-actions';
import { TelerikReportDto } from './dtos';

export const getTelerikReportsActions = createAsyncAction(
  'GET_TELERIK_REPORTS_REQUEST',
  'GET_TELERIK_REPORTS_SUCCESS',
  'GET_TELERIK_REPORTS_ERROR'
)<undefined, TelerikReportDto[], string>();



