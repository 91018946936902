


import { ISelectOption } from "../dtos/SelectOptionDto";
enum VoluntarySepReasonCodes {
        Item1 = 0,
        Item2 = 1,
        Item3 = 2,
        Item4 = 3,
        Item5 = 4,
        Item6 = 5,
        Item7 = 6,
        Item8 = 7,
        Item9 = 8,
        Item10 = 9,
         
}

export class VoluntarySepReasonCodes_class {

public static enum = VoluntarySepReasonCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['Item1']: 0,
    ['Item2']: 1,
    ['Item3']: 2,
    ['Item4']: 3,
    ['Item5']: 4,
    ['Item6']: 5,
    ['Item7']: 6,
    ['Item8']: 7,
    ['Item9']: 8,
    ['Item10']: 9,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "1- No Reason Given",
    [1]: "2- To Seek or Accept Other Employment",
    [2]: "3- Due to Health Reasons",
    [3]: "4- Due to Job Dissatisfaction",
    [4]: "5- In Lieu of Discharge",
    [5]: "6- Personal Reasons",
    [6]: "7- Failure to Report for Work / Abandonment",
    [7]: "8- Retirement",
    [8]: "9- Working Conditions",
    [9]: "10- Not Listed Above",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "1- No Reason Given"},
  {value: 1, text: "2- To Seek or Accept Other Employment"},
  {value: 2, text: "3- Due to Health Reasons"},
  {value: 3, text: "4- Due to Job Dissatisfaction"},
  {value: 4, text: "5- In Lieu of Discharge"},
  {value: 5, text: "6- Personal Reasons"},
  {value: 6, text: "7- Failure to Report for Work / Abandonment"},
  {value: 7, text: "8- Retirement"},
  {value: 8, text: "9- Working Conditions"},
  {value: 9, text: "10- Not Listed Above"},
  ]
}

