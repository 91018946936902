import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors } from 'formik';

// material ui
import { Grid, TextField, MenuItem, FormControlLabel } from '@material-ui/core';

import { failureToReturnOptions, radioButtonBooleanOptions } from '../../constants/options';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ClientQuestionnaireDto } from '../../dto';
import { PaddedTextField, MHARadio } from '../../../../common/styles/styles';


interface FailureToReturnComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
}

const FailureToReturnComponent: React.FC<FailureToReturnComponentProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation
  }) => {

  return (
    <React.Fragment>
      <Grid item xs={12}>What date did the leave of absence begin?</Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <KeyboardDatePicker
          margin="normal"
          id="failureToReturnLoaBeginDate"
          name="failureToReturnLoaBeginDate"
          //label="Hire Date"
          format="MM/DD/yyyy"
          value={values.failureToReturnLoaBeginDate || null}
          data-cy="clientQuestionnaire_failureToReturnLoaBeginDate"
          fullWidth
          onChange={(date: MaterialUiPickersDate) => setFieldValue('failureToReturnLoaBeginDate', date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <br />
      <Grid item xs={12}>What was the anticipated end date of the leave?</Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <KeyboardDatePicker
          margin="normal"
          id="anticipatedLeaveDate"
          name="anticipatedLeaveDate"
          //label="Hire Date"
          format="MM/DD/yyyy"
          value={values.anticipatedLeaveDate || null}
          data-cy="clientQuestionnaire_anticipatedLeaveDate"
          fullWidth
          onChange={(date: MaterialUiPickersDate) => setFieldValue('anticipatedLeaveDate', date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <br />
      <Grid item xs={12}>What was the reason given for the leave of absence?</Grid>
      <Grid item xs={4}>
        <PaddedTextField
          id="failureToReturnReasonId"
          size="small"
          margin="none"
          select
          value={values.failureToReturnReasonId || ''}
          //label="Most Recent Job Status"
          name="failureToReturnReasonId"
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.failureToReturnReasonId)}
          helperText={errors.failureToReturnReasonId && errors.failureToReturnReasonId}
          data-cy="clientQuestionnaire_jobStatus"
          aria-label="Reason for not returning"
          fullWidth
          SelectProps={{ MenuProps: { disableScrollLock: true } }}
        >
          {failureToReturnOptions.filter((item) => item.name !== '')
            .map((option, index) => (
              <MenuItem key={option.name + index} value={option.id}>{option.name}</MenuItem>
            ))}
        </PaddedTextField>
      </Grid>
      <br />
      <Grid item xs={12}>Did the claimant notify the employer that they would not be returning to work?</Grid>
      <Grid container item xs={12} direction="row">
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="employerNotified"
              name="employerNotified"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('employerNotified', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? false);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              //add errors for validation
              checked={o.status === values.employerNotified}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_employerNotified" />
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid item xs={12}>If the claimant had not resigned, would they have been returned to their prior position?</Grid>
      <Grid container item xs={12} direction="row">
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="claimantWouldReturnToPosition"
              name="claimantWouldReturnToPosition"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('claimantWouldReturnToPosition', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? false);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              //add errors for validation
              checked={o.status === values.claimantWouldReturnToPosition}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_claimantWouldReturnToPosition" />
          </Grid>
        ))}
      </Grid>
      <br />
      {values.claimantWouldReturnToPosition === false ?
        <>
          <Grid item xs={12}>Why not?</Grid>
          <Grid item xs={4}>
            <TextField
              id="failureToReturnWhyNot"
              size="small"
              margin="none"
              value={values.failureToReturnWhyNot || ''}
              multiline
              //label="Job Title"
              name="failureToReturnWhyNot"
              onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
              onBlur={handleBlur}
              error={Boolean(errors.failureToReturnWhyNot)}
              helperText={errors.failureToReturnWhyNot && errors.failureToReturnWhyNot}
              data-cy="clientQuestionnaire_failureToReturnWhyNot"
              aria-label="Why not?"
              fullWidth
            />
          </Grid>
          <br />
        </>
        : null}

      <br />

    </React.Fragment>
  )
}

export default FailureToReturnComponent;
