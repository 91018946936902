


import { ISelectOption } from "../dtos/SelectOptionDto";
enum EmployerTypeCodes {
        Item1 = 0,
        Item2 = 1,
        Item3 = 2,
        Item4 = 3,
        Item5 = 4,
        Item6 = 5,
        Item7 = 6,
         
}

export class EmployerTypeCodes_class {

public static enum = EmployerTypeCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['Item1']: 0,
    ['Item2']: 1,
    ['Item3']: 2,
    ['Item4']: 3,
    ['Item5']: 4,
    ['Item6']: 5,
    ['Item7']: 6,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "Last, Base Period, and Lag Quarter Employer",
    [1]: "Last Employer",
    [2]: "Base Period Employer",
    [3]: "Lag Quarter Employer",
    [4]: "Last and Base Period Employer",
    [5]: "Last and Lag Quarter Employer",
    [6]: "Base Period and Lag Quarter Employer",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "Last, Base Period, and Lag Quarter Employer"},
  {value: 1, text: "Last Employer"},
  {value: 2, text: "Base Period Employer"},
  {value: 3, text: "Lag Quarter Employer"},
  {value: 4, text: "Last and Base Period Employer"},
  {value: 5, text: "Last and Lag Quarter Employer"},
  {value: 6, text: "Base Period and Lag Quarter Employer"},
  ]
}

