import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { forgotPasswordActions } from './forgotPassword.actions';
import { forgotPasswordInit } from './forgotPassword.dtos';

const forgotPasswordReducer = combineReducers({
  forgotPasswordRequest: createReducer(forgotPasswordInit)
    .handleAction(
      [
        forgotPasswordActions.request,
      ], (state, action) => action.payload),

  forgotPasswordSuccess: createReducer(false as boolean)
    .handleAction(
      [
        forgotPasswordActions.success,
      ], (state, action) => true),

  forgotPasswordError: createReducer('')
    .handleAction(
      [
        forgotPasswordActions.failure,
      ], (state, action) => action.payload)
});

export default forgotPasswordReducer;