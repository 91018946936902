import { AxiosError } from 'axios';

import apiClient from '../../../common/api/apiClient';
import { ApiError } from '../../../common/api/apiResponse';
import { ForgotPasswordDto } from './forgotPassword.dtos';
import { showNotification } from '../../../common/actionCreators/dispatchNotification';


// endpoints
const forgotPasswordUrl = '/account/forgot-password';

export const forgotPasswordApi = async (request: ForgotPasswordDto) => {
  return await apiClient().post(forgotPasswordUrl, request)
    .then((res) => {
      showNotification('Forgot Password email has been sent.', 'success');
      return true;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          if (axiosError.response.data.error !== undefined) {
            showNotification(axiosError.response.data.error.message, 'error');
          }
          else {
            showNotification('An error occurred while sending password reset email.', 'error');
          }
        }
      }
      else {
        showNotification('An error occurred while sending password reset email.');
      }
      throw err;
    });
};
