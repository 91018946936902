import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
//import Link from '@material-ui/core/Link';

const StyledFooter = styled.footer`
    position: absolute;
    bottom: 0;
    width: 100%;
    display: none;
    background: #fff;
    padding: 0.5rem 1rem;
    justify-content: space-between;
`;
//display: none; //was flex // Hidden for now

const Footer = () => {
  return (
    <StyledFooter>
      <Typography variant="body2" color="textSecondary" align="left">
        
      </Typography>
    </StyledFooter>
  )
}

export default Footer
