
export enum DocTypeEnum {
    Na = 0,
    MonetaryNotice = 1,
    StateFactFindingForm = 2,
    SIDES = 3,
    TransmittalToState = 4,
    InformationFromClient = 5,
    WageData = 6,
    Decision = 7,
    NoticeOfFactFindingInterview = 8,
    HearingNotice = 9,
    HearingDecision = 10,
    BoardDecision = 11,
    CircuitCourtDecision = 12,
    AttorneyNotesFromHearing = 13,
    AppellateDocuments = 14,
    ClaimGeneralDocuments = 15,
    ClientCommunications = 16,
    ChargeProtest = 17,
  }
  
  export class DocTypeEnum_class {
  
    public static enum = DocTypeEnum;
  
    public static readonly enumByText : { [key : string ]: number} = {
        ['Na']: 0,
      ['MonetaryNotice']: 1,
      ['StateFactFindingForm']: 2,
      ['SIDES']: 3,
      ['TransmittalToState']: 4,
      ['InformationFromClient']: 5,
      ['WageData']: 6,
      ['Decision']: 7,
      ['NoticeOfFactFindingInterview']: 8,
      ['HearingNotice']: 9,
      ['HearingDecision']: 10,
      ['BoardDecision']: 11,
      ['CircuitCourtDecision']: 12,
      ['AttorneyNotesFromHearing']: 13,
      ['AppellateDocuments']: 14,
      ['ClaimGeneralDocuments']: 15,
      ['ClientCommunications']: 16,
      ['ChargeProtest']: 17,
      
    };
    public static readonly enumByNumber : { [key : number ]: string} = {
        [0]: "Na",
      [1]: "MonetaryNotice",
      [2]: "StateFactFindingForm",
      [3]: "SIDES",
      [4]: "TransmittalToState",
      [5]: "InformationFromClient",
      [6]: "WageData",
      [7]: "Decision",
      [8]: "NoticeOfFactFindingInterview",
      [9]: "HearingNotice",
      [10]: "HearingDecision",
      [11]: "BoardDecision",
      [12]: "CircuitCourtDecision",
      [13]: "AttorneyNotesFromHearing",
      [14]: "AppellateDocuments",
      [15]: "ClaimGeneralDocuments",
      [16]: "ClientCommunications",
      [17]: "ChargeProtest",
      
    };
  }