import { createAsyncAction, createAction } from 'typesafe-actions';
import { CompanyDto, CompanyHsDto } from './dtos';
import { BulkImportDto } from '../../common/dtos/bulkImportDto';
import { RateDto, RateWrapperDto } from '../../common/dtos/billing';


export const getCompaniesActions = createAsyncAction(
  'GET_COMPANIES_REQUEST',
  'GET_COMPANIES_SUCCESS',
  'GET_COMPANIES_ERROR'
)<undefined, CompanyDto[], string>();

export const getCompanyActions = createAsyncAction(
  'GET_COMPANY_REQUEST',
  'GET_COMPANY_SUCCESS',
  'GET_COMPANY_ERROR',
)<string, CompanyDto, string>();

export const updateCompanyActions = createAsyncAction(
  'UPDATE_COMPANY_REQUEST',
  'UPDATE_COMPANY_SUCCESS',
  'UPDATE_COMPANY_ERROR'
)<CompanyDto, CompanyDto, string>();

export const createCompanyActions = createAsyncAction(
  'CREATE_COMPANY_REQUEST',
  'CREATE_COMPANY_SUCCESS',
  'CREATE_COMPANY_ERROR'
)<CompanyDto, CompanyDto, string>();

export const mhaContactListShouldRefresh = createAction('REFRESH_MHA_CONTACT_LIST')<boolean>();
export const companyListShouldRefresh = createAction('REFRESH_COMPANY_LIST')<boolean>();
export const companyShouldRefresh = createAction('REFRESH_COMPANY')<string>();
export const companyImportHistoryShouldRefresh = createAction('REFRESH_COMPANY_IMPORT_HISTORY')<boolean>();

export const getCompanyBulkImportHistoryActions = createAsyncAction(
  'GET_COMPANY_BULK_IMPORT_HISTORY_REQUEST',
  'GET_COMPANY_BULK_IMPORT_HISTORY_SUCCESS',
  'GET_COMPANY_BULK_IMPORT_HISTORY_ERROR',
)<undefined, BulkImportDto[], string>();

export const uploadCompanyBulkImport = createAction('UPLOAD_COMPANY_BULK_IMPORT')<any>();

export const createInteractionActions = createAsyncAction(
  'CREATE_COMPANY_INTERACTION_REQUEST',
  'CREATE_COMPANY_INTERACTION_SUCCESS',
  'CREATE_COMPANY_INTERACTION_ERROR'
)<CompanyDto, CompanyDto, string>();


export const getCompanyRateActions = createAsyncAction(
    'GET_COMPANY_RATE_REQUEST',
    'GET_COMPANY_RATE_SUCCESS',
    'GET_COMPANY_RATE_ERROR',

)<RateWrapperDto, RateWrapperDto, string>();


export const updateCompanyRateActions = createAsyncAction(
    'UPDATE_COMPANY_RATE_REQUEST',
    'UPDATE_COMPANY_RATE_SUCCESS',
    'UPDATE_COMPANY_RATE_ERROR',
)<RateWrapperDto, RateDto, string>();

export const createCompanyRateActions = createAsyncAction(
    'CREATE_COMPANY_RATE_REQUEST',
    'CREATE_COMPANY_RATE_SUCCESS',
    'CREATE_COMPANY_RATE_ERROR',
)<RateWrapperDto, RateDto, string>();

export const getCompaniesHsActions = createAsyncAction(
  'GET_COMPANIES_HS_REQUEST',
  'GET_COMPANIES_HS_SUCCESS',
  'GET_COMPANIES_HS_ERROR'
)<undefined, CompanyHsDto[], string>();