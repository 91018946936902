import React from 'react';
import NumberFormat from 'react-number-format';

interface AlaskaMaskProps {
    inputRef: (instance: NumberFormat | null) => void,
  name: string,
  onChange: (event: { target: { name: string; value: string; }}) => void,
}

const AlaskaMask: React.FC<AlaskaMaskProps> = (props: AlaskaMaskProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <>
      <NumberFormat
        {...other}
        format="########"
        mask="_"
        decimalScale={0}
        fixedDecimalScale={true}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
      />
    </>
  );

}

export default AlaskaMask;