import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { getEligibilityCodesActions, getLocationCodesActions, getSeparationCodesActions, updateEligibilityCodesActions, updateLocationCodesActions, updateSeparationCodesActions, createEligibilityCodesActions, createLocationCodesActions, createSeparationCodesActions } from './codes.actions';
import { SeparationCodeDto, LocationCodeDto, EligibilityCodeDto } from './codes.dtos';

const codesReducer = combineReducers({
  eligibilityCodes: createReducer([] as EligibilityCodeDto[])
    .handleAction(
      [
        getEligibilityCodesActions.success,
      ],
      (state, action) => action.payload),

   separationCodes: createReducer([] as SeparationCodeDto[])
    .handleAction(
      [
        getSeparationCodesActions.success,
      ],
      (state, action) => action.payload),

   locationCodes: createReducer([] as LocationCodeDto[])
    .handleAction(
      [
        getLocationCodesActions.success,
      ],
      (state, action) => action.payload),

  createUpdateEligibilityCodes: createReducer([] as EligibilityCodeDto[])
    .handleAction(
      [
        createEligibilityCodesActions.request,
        updateEligibilityCodesActions.request,
      ], (state, action) => action.payload),

  createUpdateSeparationCodes: createReducer([] as SeparationCodeDto[])
    .handleAction(
      [
        createSeparationCodesActions.request,
        updateSeparationCodesActions.request,
      ], (state, action) => action.payload),


  createUpdateLocationCodes: createReducer([] as LocationCodeDto[])
    .handleAction(
      [
        createLocationCodesActions.request,
        updateLocationCodesActions.request,
      ], (state, action) => action.payload),

    


});

export default codesReducer;