import React from 'react';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
// material ui
import { Grid, Typography, Button, Collapse } from '@material-ui/core';
import { rootSelector } from '../../../common/selectors/selectors';
import ClaimEventCard from '../components/claimEventCard'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { ClaimEventDto, ClaimDto } from '../claims.dtos';
import { getEventsActions } from '../actions';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import AddClaimEventDialog from '../dialogs/addClaimEventDialog'
import { SmallIconButton, NoDateRangeText } from '../claims.styles';
import { SeparationCodeDto } from '../../settings/codes/codes.dtos';


interface Props extends React.HTMLProps<React.ReactNode> {
  isClientUser: boolean;
  isReadOnly: boolean;
  claim: ClaimDto;
  separationCodes: SeparationCodeDto[];
}

const ClaimEventPanel: React.FC<Props> = (
  {
    isClientUser,
    isReadOnly,
    claim,
    separationCodes
  }) => {
  const dispatch = useDispatch();

  const systemEvents = rootSelector(state => state.claims.claimEvents); 
  //const systemSeparationCodes = rootSelector(state => state.codes.separationCodes);

  const [expanded, setExpanded] = React.useState(false);
  const [claimEvents, setClaimEvents] = React.useState<ClaimEventDto[]>([]);
  //const [separationCodes, setSeparationCodes] = React.useState<SeparationCodeDto[]>([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);


  React.useEffect(() => {
    if (claim && claim.id !== undefined && claim.id !== '')
      dispatch({ type: getType(getEventsActions.request), payload: claim.id });
  }, [dispatch, claim]);

  React.useEffect(() => {
    setClaimEvents(systemEvents);
  }, [systemEvents]);

  const handleExpandChange = () => {
    setExpanded(!expanded)
  };

  const handleAddNewClaimEvent = () => setDialogOpen(true);
  const handleCancelDialog = () => setDialogOpen(false);


  return (
    <>
      {dialogOpen &&
        <AddClaimEventDialog
        isReadOnly={isReadOnly}
        isClientUser={isClientUser}
        open={dialogOpen}
        onCancel={handleCancelDialog}
        separationCodes={separationCodes}
        claim={claim}
        />
      }

      <Grid container alignItems="center">

        <Grid item md={12} lg={1}>
          <Button onClick={() => handleExpandChange()} data-cy="claim_event_expandList">
            {expanded ? <ExpandLess fontSize="large" /> : <ExpandMore fontSize="large" />}
          </Button>
        </Grid>


        <Grid item md={12} lg={2}>
          <Typography variant="subtitle1" color="textSecondary" display="block">Claim Events</Typography>
        </Grid>
        
        <Grid item xs={1} md={1} lg={4} hidden={isClientUser === true || isReadOnly === true}>
          <SmallIconButton title="Add Appellate Event" onClick={() => handleAddNewClaimEvent()} color="secondary" data-cy="appellate_event_add_button">
            <AddBoxRoundedIcon />
          </SmallIconButton>
        </Grid>
      </Grid>

      <Collapse in={expanded}>
        <Grid container spacing={2}>
          {claimEvents
            .length !== 0 ?
            claimEvents
              .map((o, index) => (
                <Grid item xs={12} key={'claim_event_' + index}>
                  <ClaimEventCard dto={o} separationCodes={separationCodes} separationCodeId={claim.separationCodeId ?? ''} />
                </Grid>
              ))
            : <Grid item xs={12}><NoDateRangeText variant="subtitle1" color="secondary"> No records found.</NoDateRangeText></Grid>}
        </Grid>
      </Collapse>
    </>
  )
}
export default ClaimEventPanel;