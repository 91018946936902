import { Formik, getIn } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { getType } from 'typesafe-actions';
// material ui
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete, Pagination } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import { isAuthorized } from '../../../common/dtos/auth';
import { rootSelector } from '../../../common/selectors/selectors';
import {
  ListCardContent,
  NoPaddingIconButton,
  NoPaddingVisibilityIcon,
  NoPaddingVisibilityOffIcon,
  TableCard,
} from '../../../common/styles/styles';
import { formatStaticSSN } from '../../../utils/helperFunctions';
import { getCompaniesActions } from '../../company/actions';
import { CompanyDto } from '../../company/dtos';
import { getSeparationCodesActions } from '../../settings/codes/codes.actions';
import { SeparationCodeDto } from '../../settings/codes/codes.dtos';
import { getClaimsActions } from '../actions';
import {
  ClaimSearchDto,
  ClaimSearchInit,
  ListClaimDto,
  claimSearchValidationSchema,
} from '../claims.dtos';
import { claimStatusOptions } from '../options';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ClaimsList: React.FC = () => {
  const dispatch = useDispatch();
  let query = useQuery();
  const limit = 9;

  const systemClaims = rootSelector((state) => state.claims.claimsList);
  const systemSeparationCodes = rootSelector(
    (state) => state.codes.separationCodes
  );
  const systemCompaniesList = rootSelector(
    (state) => state.companies.companiesList
  );

  const [listPage, setListPage] = React.useState<number>(1);
  const [resultCount, setResultCount] = React.useState<number>(0);
  const [claimsList, setClaimsList] = React.useState<ListClaimDto[]>([]);
  const [sepCodes, setSepCodes] = React.useState<SeparationCodeDto[]>([]);
  const [searchDto, setSearchDto] = React.useState(
    JSON.parse(JSON.stringify(ClaimSearchInit))
  );
  const [isMasked, setIsMasked] = React.useState<boolean>(false);
  const [searchComplete, setSearchComplete] = React.useState<boolean>(false);
  const [companies, setCompanies] = React.useState([] as CompanyDto[]);

  React.useEffect(() => {
    let ssn = query.get('search');
    query.delete('search');
    if (ssn !== undefined && ssn !== null && ssn.length !== 0) {
      setSearchDto({ ssn, ...searchDto });
    }
  }, []);

  React.useEffect(() => {
    if (searchDto.ssn !== undefined && searchDto.ssn !== null) {
      let request: ClaimSearchDto = JSON.parse(JSON.stringify(searchDto));

      dispatch({
        type: getType(getClaimsActions.request),
        payload: request,
      });
    }
  }, [searchDto]);

  React.useEffect(() => {
    dispatch({ type: getType(getCompaniesActions.request) });
    dispatch({ type: getType(getSeparationCodesActions.request) });
  }, [dispatch]);

  React.useEffect(() => {
    let list = systemClaims.sort((a, b) =>
      (a.label ?? '').localeCompare(b.label ?? '')
    );

    setResultCount(list.length);
    setClaimsList(list.slice(listPage * limit - limit, listPage * limit));

    if (list.length === 0) setSearchComplete(false);
    else setSearchComplete(true);
    setTimeout(() => {
      setSearchComplete(true);
    }, 5000);
  }, [systemClaims, listPage]); //searchQuery

  React.useEffect(() => {
    setSepCodes(JSON.parse(JSON.stringify(systemSeparationCodes)));
  }, [systemSeparationCodes]);

  React.useEffect(() => {
    setCompanies(systemCompaniesList);
  }, [systemCompaniesList]);

  const handleSSNMaskToggle = () => {
    setIsMasked(!isMasked);
  };

  const handleGetMaskedSSNValue = (ssn?: string) => {
    if (!!ssn) {
      let v = ssn ?? '';
      if (v.replace('-', '').length > 3) {
        return '***-**-' + v.replace('-', '').slice(-4);
      } else {
        return '***-**-';
      }
    }
  };

  const hasSearchCriteria = (dto?: ClaimSearchDto): boolean => {
    let search: ClaimSearchDto =
      dto === undefined ? JSON.parse(JSON.stringify(searchDto)) : dto;

    return (
      (search.claimantFirstName !== null &&
        search.claimantFirstName !== undefined &&
        search.claimantFirstName !== '') ||
      (search.claimantLastName !== null &&
        search.claimantLastName !== undefined &&
        search.claimantLastName !== '') ||
      (search.ssn !== null && search.ssn !== undefined && search.ssn !== '') ||
      (search.separationCode !== null &&
        search.separationCode !== undefined &&
        search.separationCode !== '') ||
      (search.employerId !== null &&
        search.employerId !== undefined &&
        search.employerId !== '') ||
      (search.employeeId !== null &&
        search.employeeId !== undefined &&
        search.employeeId !== '') ||
      (search.stateCode !== null &&
        search.stateCode !== undefined &&
        search.stateCode !== '') ||
      (search.benefitYearBeginningDate !== null &&
        search.benefitYearBeginningDate !== undefined) ||
      (search.benefitYearEndingDate !== null &&
        search.benefitYearEndingDate !== undefined)
    );
  };

  return (
    <>
      <Box p={2}>
        <Grid container data-cy='claims_list_container' item xs={12}>
          <Grid item xs={12}>
            <Typography variant='subtitle1' display='inline'>
              Claims
            </Typography>
            <Typography
              variant='subtitle1'
              color='textSecondary'
              display='inline'
            >
              {' '}
              ({resultCount})
            </Typography>
          </Grid>

          <br />
          <br />

          <Formik
            initialValues={searchDto}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={claimSearchValidationSchema}
            onSubmit={(values) => {
              if (hasSearchCriteria(values)) {
                let request: ClaimSearchDto = JSON.parse(
                  JSON.stringify(values)
                );
                dispatch({
                  type: getType(getClaimsActions.request),
                  payload: request,
                });
                setSearchDto(request);
                setListPage(1);
              }
            }}
            enableReinitialize={true}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldValue,
              } = props;
              return (
                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                  <Grid container>
                    <Grid container item xs={11}>
                      <Grid container item spacing={2} xs={12}>
                        <Grid item xs={6} sm={2}>
                          <TextField
                            size='small'
                            margin='none'
                            value={values.claimantFirstName || ''}
                            label='Claimant First Name'
                            name='claimantFirstName'
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSubmit();
                              }
                            }}
                            error={Boolean(errors.claimantFirstName)}
                            helperText={
                              errors.claimantFirstName &&
                              touched.claimantFirstName
                            }
                          />
                        </Grid>
                        <Grid item xs={6} sm={2}>
                          <TextField
                            size='small'
                            margin='none'
                            value={values.claimantLastName || ''}
                            label='Claimant Last Name'
                            name='claimantLastName'
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSubmit();
                              }
                            }}
                            error={Boolean(errors.claimantLastName)}
                            helperText={
                              errors.claimantLastName &&
                              touched.claimantLastName
                            }
                          />
                        </Grid>
                        <Grid item xs={6} sm={2}>
                          <TextField
                            size='small'
                            margin='none'
                            value={values.ssn || ''}
                            label={'SSN'}
                            name='ssn'
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSubmit();
                              }
                            }}
                            error={errors.ssn !== undefined}
                            helperText={errors.ssn && errors.ssn}
                            data-cy='claims-search-ssn'
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={6} sm={2}>
                          <Autocomplete
                            id='separationCode'
                            options={sepCodes.sort((a, b) =>
                              a.name.localeCompare(b.name)
                            )}
                            getOptionLabel={(option) =>
                              `${option.name} - ${option.description}` ?? ''
                            }
                            includeInputInList
                            value={
                              sepCodes.find(
                                (o) => o.id === values.separationCode
                              ) || null
                            }
                            autoComplete
                            onChange={(
                              _e: any,
                              v: SeparationCodeDto | null
                            ) => {
                              setFieldValue('separationCode', v?.id ?? '');
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSubmit();
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id='separationCode'
                                size='small'
                                margin='none'
                                value={values.separationCode || ''}
                                label='Separation Code'
                                onBlur={handleBlur}
                                error={errors.separationCode !== undefined}
                                helperText={errors.separationCode}
                                data-cy='claims-search-separationCode'
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} sm={1}>
                          <Autocomplete
                            id='employerId'
                            options={companies}
                            getOptionLabel={(option) => option.suta ?? '1'}
                            autoComplete
                            value={
                              companies.find(
                                (o) => o.companyId === values.employerId
                              ) || null
                            }
                            onChange={(_e: any, v: CompanyDto | null) => {
                              setFieldValue('employerId', v?.companyId ?? '');
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSubmit();
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id='employerSuta'
                                size='small'
                                margin='none'
                                value={values.employerId || ''}
                                label='SUTA'
                                onBlur={handleBlur}
                                error={errors.employerId !== undefined}
                                helperText={
                                  errors.employerId && errors.employerId
                                }
                                data-cy='claims-search-suta'
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} sm={1}>
                          <Autocomplete
                            id='stateCode'
                            options={Array.from(
                              new Set(companies.map((m) => m.stateCode ?? ''))
                            ).sort((a, b) => (a ?? '').localeCompare(b ?? ''))}
                            //getOptionLabel={(option) => option.stateCode ?? ''}
                            value={
                              companies.find(
                                (o) => o.stateCode === values.stateCode
                              )?.stateCode || null
                            }
                            autoComplete
                            onChange={(_e: any, v: string | null) => {
                              setFieldValue('stateCode', v ?? '');
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSubmit();
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id='stateCode'
                                size='small'
                                margin='none'
                                value={values.stateCode || ''}
                                label='State'
                                onBlur={handleBlur}
                                error={Boolean(getIn(errors, 'stateCode'))}
                                helperText={
                                  getIn(errors, 'stateCode') &&
                                  getIn(errors, 'stateCode')
                                }
                                data-cy='claims-search-stateCode'
                                fullWidth
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} sm={1}>
                          <KeyboardDatePicker
                            name='benefitYearBeginningDate'
                            label='Benefit Year Beginning'
                            format='MM/DD/yyyy'
                            value={values.benefitYearBeginningDate || null}
                            data-cy='claims-search-benefitYearBeginningDate'
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            onChange={(date: MaterialUiPickersDate) => {
                              setFieldValue('benefitYearBeginningDate', date);
                            }}
                            error={
                              errors.benefitYearBeginningDate !== undefined
                            }
                            helperText={
                              errors.benefitYearBeginningDate &&
                              errors.benefitYearBeginningDate
                            }
                            KeyboardButtonProps={{
                              'aria-label':
                                'change benefit year Beginning date',
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSubmit();
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={6} sm={1}>
                          <KeyboardDatePicker
                            name='benefitYearEndingDate'
                            label='Benefit Year Ending'
                            format='MM/DD/yyyy'
                            value={values.benefitYearEndingDate || null}
                            data-cy='claims-search-benefitYearEndingDate'
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            onChange={(date: MaterialUiPickersDate) => {
                              setFieldValue('benefitYearEndingDate', date);
                            }}
                            error={errors.benefitYearEndingDate !== undefined}
                            helperText={
                              errors.benefitYearEndingDate &&
                              errors.benefitYearEndingDate
                            }
                            KeyboardButtonProps={{
                              'aria-label': 'change benefit year ending date',
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSubmit();
                              }
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container item spacing={2} xs={12}>
                        <Grid item xs={6} sm={2}>
                          <TextField
                            size='small'
                            margin='none'
                            value={values.employeeId || ''}
                            label='Employee ID'
                            name='employeeId'
                            fullWidth
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSubmit();
                              }
                            }}
                            error={Boolean(errors.employeeId)}
                            helperText={errors.employeeId && touched.employeeId}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      sm={1}
                      justifyContent='flex-start'
                    >
                      <Grid item xs={6} sm={12}>
                        <Button
                          title='Search Button'
                          variant='contained'
                          color='secondary'
                          onClick={() => {
                            handleSubmit();
                          }}
                          data-cy='claims-search-submit'
                        >
                          <Typography variant='subtitle2'>Search</Typography>
                        </Button>
                      </Grid>
                      <Grid item xs={6} sm={12}>
                        <Button
                          title='Clear Filters Button'
                          variant='contained'
                          color='secondary'
                          onClick={() => {
                            setFieldValue('claimantFirstName', undefined);
                            setFieldValue('claimantLastName', undefined);
                            setFieldValue('ssn', undefined);
                            setFieldValue('separationCode', undefined);
                            setFieldValue('employerId', undefined);
                            setFieldValue('employerSuta', undefined);
                            setFieldValue('stateCode', undefined);
                            setFieldValue(
                              'benefitYearBeginningDate',
                              undefined
                            );
                            setFieldValue('benefitYearEndingDate', undefined);
                          }}
                        >
                          <Typography variant='subtitle2'>
                            CLEAR FILTERS
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                </form>
              );
            }}
          </Formik>

          <Grid container spacing={2}>
            {claimsList.map((o, index) => (
              <Grid item xs={11} key={index}>
                <TableCard raised>
                  <ListCardContent>
                    <Grid container>
                      <div hidden data-cy={`o_list_card_id_${o.label}`}>
                        {o.label}
                      </div>

                      <Grid item xs={12} md={12} lg={12}>
                        <Link
                          component={RouterLink}
                          to={`/claim/${o.id}`}
                          data-cy={`claim_list_card_link_${o.suta}`}
                          variant='subtitle1'
                          color='secondary'
                        >
                          {(o.label ?? '').replace(' ', '').length === 0
                            ? 'NONE ON FILE'
                            : o.label?.toUpperCase()}
                        </Link>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <Typography variant='body2' color='textPrimary'>
                          BYE
                        </Typography>
                        <Typography variant='body2' color='secondary'>
                          {o.benefitYearEndingDate === undefined
                            ? 'NONE ON FILE'
                            : moment(o.benefitYearEndingDate).format(
                                'MM/DD/yyyy'
                              ) || 'NONE ON FILE'}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <Typography variant='body2' color='textPrimary'>
                          Employer Name
                        </Typography>
                        <Typography variant='body2' color='secondary'>
                          {o.employerName?.toUpperCase()}{' '}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <Typography variant='body2' color='textPrimary'>
                          SUTA
                        </Typography>
                        <Typography variant='body2' color='secondary'>
                          {o.suta || 'NONE ON FILE'}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <Typography variant='body2' color='textPrimary'>
                          Employee ID
                        </Typography>
                        <Typography variant='body2' color='secondary'>
                          {o.employeeId || 'NONE ON FILE'}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <Typography variant='body2' color='textPrimary'>
                          State Code
                        </Typography>
                        <Typography variant='body2' color='secondary'>
                          {o.stateCode || 'NONE ON FILE'}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <Typography variant='body2' color='textPrimary'>
                          SSN
                          {!isAuthorized() ? null : isMasked ? (
                            <NoPaddingIconButton
                              tabIndex={-1}
                              aria-label='View SSN'
                              title='View SSN'
                              onClick={() => handleSSNMaskToggle()}
                            >
                              <NoPaddingVisibilityIcon color='primary' />
                            </NoPaddingIconButton>
                          ) : (
                            <NoPaddingIconButton
                              title='Hide SSN'
                              aria-label='Hide SSN'
                              onClick={() => handleSSNMaskToggle()}
                            >
                              <NoPaddingVisibilityOffIcon color='primary' />
                            </NoPaddingIconButton>
                          )}
                        </Typography>
                        <Typography variant='body2' color='secondary'>
                          {(isMasked
                            ? handleGetMaskedSSNValue(o.claimantSsn)
                            : formatStaticSSN(o.claimantSsn)) || 'NONE ON FILE'}
                        </Typography>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={2}>
                        <Typography variant='body2' color='textPrimary'>
                          Claim Status
                        </Typography>
                        <Typography variant='body2' color='secondary'>
                          {claimStatusOptions[
                            claimStatusOptions.findIndex(
                              (g) => g.id === o.status
                            )
                          ].name?.toUpperCase()}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </ListCardContent>
                </TableCard>
              </Grid>
            ))}
            <Grid item xs={1}></Grid>

            <Grid item xs={12}>
              <Pagination
                hidden={claimsList.length === 0}
                count={Math.ceil(resultCount / limit)}
                variant='outlined'
                color='primary'
                size='small'
                page={listPage}
                onChange={(e, p) => {
                  setListPage(p);
                }}
              />
            </Grid>

            {claimsList.length === 0 && (
              <Grid item xs={11}>
                <Card>
                  <CardContent>
                    <Grid container justifyContent='center' alignItems='center'>
                      <Typography variant='subtitle1' color='secondary'>
                        {!searchComplete || !hasSearchCriteria(undefined)
                          ? ''
                          : `No records found matching the criteria above`}
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
                <br />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ClaimsList;
