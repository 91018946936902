import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors, } from 'formik';
import { Grid, MenuItem, FormControlLabel } from '@material-ui/core';
import { lackOfWorkReasonOptions, radioButtonBooleanOptions } from '../constants/options';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ClientQuestionnaireDto, } from '../dto';
import { MHARadio, PaddedTextField } from '../../../common/styles/styles';


interface LackOfWorkComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
}

const LackOfWorkComponent: React.FC<LackOfWorkComponentProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation
  }) => {

  return (
    <React.Fragment>
      <Grid item xs={10}>
        <Grid item xs={12}>Was this separation a permanent or temporary layoff?</Grid>
        <Grid item md={12} xs={12} sm={12} lg={5}>
          <PaddedTextField
            id="lackOfWorkReasonId"
            size="small"
            margin="none"
            select
            value={values.lackOfWorkReasonId || ''}
            name="lackOfWorkReasonId"
            onChange={e => { handleChange(e); setShouldBlockNavigation(true) }}
            onBlur={handleBlur}
            error={Boolean(errors.lackOfWorkReasonId)}
            helperText={errors.lackOfWorkReasonId && errors.lackOfWorkReasonId}
            data-cy="clientQuestionnaire_resignationReason"
            aria-label="Was this separation permanent or temporary?"

            fullWidth
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
          >
            {lackOfWorkReasonOptions.filter((item) => item.name !== '')
              .map((option, index) => (
                <MenuItem key={option.name + index} value={option.id}>{option.name}</MenuItem>
              ))}
          </PaddedTextField>
        </Grid>
      </Grid>
      {values.lackOfWorkReasonId === 1 ?
        <>
          <Grid item xs={12}>On what date is the claimant scheduled to return to work?</Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <KeyboardDatePicker
              margin="normal"
              id="scheduledReturnDate"
              name="scheduledReturnDate"
              //label="Hire Date"
              format="MM/DD/yyyy"
              value={values.scheduledReturnDate || null}
              data-cy="clientQuestionnaire_scheduledReturnDate"
              fullWidth
              onChange={(date: MaterialUiPickersDate) => { setFieldValue('scheduledReturnDate', date); setShouldBlockNavigation(true); }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <br />
        </>
        : null}
      <Grid item xs={12}>Was the layoff a result of, or related to, the COVID 19 pandemic?</Grid>
      <Grid container item xs={12} direction="row">
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="covidLayoff"
              name="covidLayoff"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('covidLayoff', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? 1);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              //add errors for validation
              checked={o.status === values.covidLayoff}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_covidLayoff" />
          </Grid>
        ))}
      </Grid>
      <br />
    </React.Fragment>
  )
}

export default LackOfWorkComponent;
