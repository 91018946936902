import React from 'react';
// material ui
import { Grid, Typography, Button, Collapse } from '@material-ui/core';
import { ClaimDto, AppellateEventDto } from '../claims.dtos';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import AppellateEventCard from '../components/appellateEventCard'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import AppellateEventDialog from '../dialogs/appellateEventDialog';
import { rootSelector } from '../../../common/selectors/selectors';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
import { getAppellateEventsActions } from '../actions';
import { SmallIconButton, NoDateRangeText } from '../claims.styles';
import { SeparationCodeDto, EligibilityCodeDto } from '../../settings/codes/codes.dtos';


interface Props extends React.HTMLProps<React.ReactNode> {
  isClientUser: boolean;
  isReadOnly: boolean;
  claim: ClaimDto;
  separationCodes: SeparationCodeDto[];
  eligibilityCodes: EligibilityCodeDto[];
}

const AppellatePanel: React.FC<Props> = (
  {
    isClientUser,
    isReadOnly,
    claim,
    separationCodes,
    eligibilityCodes
  }) => {
  const dispatch = useDispatch();

  const systemEvents = rootSelector(state => state.claims.appellateEvents); 

  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const [events, setEvents] = React.useState<AppellateEventDto[]>([]);
  const [activeEvent, setActiveEvent] = React.useState<AppellateEventDto | undefined>(undefined);


  React.useEffect(() => {
    setActiveEvent(undefined);
    if (claim && claim.id !== undefined && claim.id !== '')
      dispatch({ type: getType(getAppellateEventsActions.request), payload: claim.id });
  }, [dispatch, claim]);

  React.useEffect(() => setEvents(systemEvents), [systemEvents]);


  const handleAddNewAppellateEvent = (event: AppellateEventDto | undefined) => {
    if (!isClientUser) {
      setActiveEvent(event);
      setDialogOpen(true);
    }
  }
  const handleCancelDialog = () => { setDialogOpen(false); }

  const handleExpandChange = () => {
    setExpanded(!expanded)
  };


  return (
    <>
      {dialogOpen &&
        <AppellateEventDialog
          isReadOnly={isReadOnly}
          isClientUser={isClientUser}
          open={dialogOpen}
          onCancel={handleCancelDialog}
          existingEvent={activeEvent}
          separationCodes={separationCodes}
          eligibilityCodes={eligibilityCodes}
        />
      }
      
      <Grid container alignItems="center">
        <Grid item md={12} lg={1}>
          <Button onClick={() => handleExpandChange()} data-cy="appellate_event_expandList">
            {expanded ? <ExpandLess fontSize="large" /> : <ExpandMore fontSize="large" />}
          </Button>
        </Grid>
        <Grid item md={12} lg={2}>
          <Typography variant="subtitle1" color="textSecondary" display="block">Appellate Events</Typography>
        </Grid>
        
        <Grid item xs={1} md={1} lg={4} hidden={isClientUser === true || isReadOnly === true}>
          <SmallIconButton title="Add Appellate Event" onClick={() => handleAddNewAppellateEvent(undefined)} color="secondary" data-cy="appellate_event_add_button">
            <AddBoxRoundedIcon />
          </SmallIconButton>
        </Grid>
      </Grid>
      <Collapse in={expanded} >
        <Grid container spacing={2}>
          {(events
            .length !== 0) ?
            events
              .sort((a, b) => moment.utc(a.hearingDateTime).diff(moment.utc(b.hearingDateTime)))
              .map((o, index) => (
                <Grid item xs={12} key={'appellate_event_' + index}>
                  <AppellateEventCard isReadOnly={isReadOnly} dto={o} isClientUser={isClientUser} handleAddNewAppellateEvent={handleAddNewAppellateEvent} />
                </Grid>
              ))
            : <Grid item xs={12}><NoDateRangeText variant="subtitle1" color="secondary">No records found.</NoDateRangeText></Grid>}
        </Grid>
      </Collapse>
    </>
  )
}
export default AppellatePanel;