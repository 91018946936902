import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { Formik, FormikErrors, FormikHelpers } from "formik";
import React from "react";
import * as Yup from "yup";
import { isClientPortalUser } from "../../../common/dtos/auth";
import {
  ButtonGrid,
  StyledCancelButton,
  StyledSubmitButton,
} from "../../../common/styles/styles";
import theme from "../../../common/themes/theme";
import { ContactIdDto } from "../../claims/claims.dtos";
import { CompanyHsDto } from "../../company/dtos";
import { HealthSystemDto } from "../../settings/healthSystems/healthSystems.dtos";
import AnnualSummaryReportParameters from "../components/AnnualSummaryReportParameters";
import BenchmarkAndComparisonReportParameters from "../components/BenchmarkAndComparisonReportParameters";
import ClaimantProfileReportParameters from "../components/ClaimantProfileReportParameters";
import CreditDueReportParams from "../components/CreditDueReportParameters";
import ExecutiveSummaryReportParameters from "../components/ExecutiveSummaryReportParameters";
import ExecutiveSummaryWithIndividualBreakdownsReportParameters from "../components/ExecutiveSummaryWithIndividualBreakdownsReportParameters";
import NewUnemploymentClaimsReportParameters from "../components/NewUnemploymentClaimsReportParameters";
import ProductivityReportParameters from "../components/ProductivityReportParameters";
import UnemploymentClaimsChargeReportParameters from "../components/UnemploymentClaimsChargeReportParameters";
import UnemploymentClaimsSummaryReportParameters from "../components/UnemploymentClaimsSummaryReportParameters";
import {
  AnnualSummaryReportParametersDto,
  BenchmarkAndComparisonReportParametersDto,
  ClaimantProfileReportParametersDto,
  CreditDueReportParametersDto,
  ExecutiveSummaryReportParametersDto,
  ExecutiveSummaryWithIndividualBreakdownsReportParametersDto,
  NewUnemploymentClaimsReportParametersDto,
  ProductivityReportParametersDto,
  UnemploymentClaimsChargeReportParametersDto,
  UnemploymentClaimsSummaryReportParametersDto,
  annualSummaryReportParametersInit,
  annualSummaryReportValidationSchema,
  benchmarkAndComparisonReportParametersInit,
  benchmarkAndComparisonReportValidationSchema,
  claimantProfileReportParametersInit,
  claimantProfileReportValidationSchema,
  creditDueReportParametersInit,
  creditDueReportValidationSchema,
  executiveSummaryReportParametersInit,
  executiveSummaryReportValidationSchema,
  executiveSummaryWithIndividualBreakdownsReportParametersInit,
  executiveSummaryWithIndividualBreakdownsReportValidationSchema,
  newUnemploymentClaimsReportParametersInit,
  newUnemploymentClaimsReportValidationSchema,
  productivityReportParametersInit,
  productivityReportValidationSchema,
  reportParameterTypes,
  unemploymentClaimsChargeReportParametersInit,
  unemploymentClaimsChargeReportValidationSchema,
  unemploymentClaimsSummaryReportParametersInit,
  unemploymentClaimsSummaryReportValidationSchema,
} from "../dtos";

interface Props {
  open: boolean;
  onCancel: () => void;
  handleSelectedParams: (params: reportParameterTypes) => void;
  reportName: string;
  reportDescription: string;
  systemHealthSystems: HealthSystemDto[];
  systemHsCompanies: CompanyHsDto[];
  systemMhaClaimsContacts: ContactIdDto[];
}

const ReportParamsDialog: React.FC<Props> = ({
  open,
  onCancel,
  handleSelectedParams,
  reportName,
  reportDescription,
  systemHealthSystems,
  systemHsCompanies,
  systemMhaClaimsContacts,
}) => {
  const [companies, setCompanies] = React.useState<CompanyHsDto[]>([]);
  const [heathSystems, setHeathSystems] = React.useState<string[]>([]);
  const [users, setUsers] = React.useState<ContactIdDto[]>([]);
  const [dto, setDto] = React.useState<reportParameterTypes>(
    benchmarkAndComparisonReportParametersInit
  );
  const [validationSchema, setValidationSchema] =
    React.useState<Yup.ObjectSchema>(Yup.object());
  const [healthSystemsList, setHealthSystemsList] = React.useState<
    HealthSystemDto[]
  >([]);

  React.useEffect(() => {
    setHealthSystemsList(JSON.parse(JSON.stringify(systemHealthSystems)));
    return () => setHeathSystems([]);
  }, [systemHealthSystems]);

  React.useEffect(() => {
    setDto({
      ...JSON.parse(JSON.stringify(benchmarkAndComparisonReportParametersInit)),
    });
    return () =>
      setDto({
        ...JSON.parse(
          JSON.stringify(benchmarkAndComparisonReportParametersInit)
        ),
      });
  }, []);

  React.useEffect(() => {
    let dto: any = {};
    switch (reportName) {
      case "Benchmark And Comparison Report":
        dto = JSON.parse(
          JSON.stringify(benchmarkAndComparisonReportParametersInit)
        ) as BenchmarkAndComparisonReportParametersDto;
        if (isClientPortalUser()) dto.healthSystems[0] = "";
        setValidationSchema(benchmarkAndComparisonReportValidationSchema);
        break;
      case "Unemployment Claims Summary Report":
        dto = JSON.parse(
          JSON.stringify(unemploymentClaimsSummaryReportParametersInit)
        ) as UnemploymentClaimsSummaryReportParametersDto;
        if (isClientPortalUser()) dto.healthSystems[0] = "";
        setValidationSchema(unemploymentClaimsSummaryReportValidationSchema);
        break;
      case "Executive Summary Report":
        dto = JSON.parse(
          JSON.stringify(executiveSummaryReportParametersInit)
        ) as ExecutiveSummaryReportParametersDto;
        if (isClientPortalUser()) dto.healthSystems[0] = "";
        setValidationSchema(executiveSummaryReportValidationSchema);
        break;
      case "Annual Report Summary":
        dto = JSON.parse(
          JSON.stringify(annualSummaryReportParametersInit)
        ) as AnnualSummaryReportParametersDto;
        if (isClientPortalUser()) dto.healthSystems[0] = "";
        setValidationSchema(annualSummaryReportValidationSchema);
        break;
      case "Claimant Profile":
        dto = JSON.parse(
          JSON.stringify(claimantProfileReportParametersInit)
        ) as ClaimantProfileReportParametersDto;
        setValidationSchema(claimantProfileReportValidationSchema);
        break;
      case "Productivity Report":
        dto = JSON.parse(
          JSON.stringify(productivityReportParametersInit)
        ) as ProductivityReportParametersDto;
        if (isClientPortalUser()) dto.healthSystems[0] = "";
        setValidationSchema(productivityReportValidationSchema);
        break;
      case "New Unemployment Claims Report":
        dto = JSON.parse(
          JSON.stringify(newUnemploymentClaimsReportParametersInit)
        ) as NewUnemploymentClaimsReportParametersDto;
        if (isClientPortalUser()) dto.healthSystems[0] = "";
        setValidationSchema(newUnemploymentClaimsReportValidationSchema);
        break;
      case "Executive Summary Report with Individual Employer Breakdowns":
        dto = JSON.parse(
          JSON.stringify(
            executiveSummaryWithIndividualBreakdownsReportParametersInit
          )
        ) as ExecutiveSummaryWithIndividualBreakdownsReportParametersDto;
        if (isClientPortalUser()) dto.healthSystems[0] = "";
        setValidationSchema(
          executiveSummaryWithIndividualBreakdownsReportValidationSchema
        );
        break;
      case "Unemployment Claims Hearings Report":
      case "Unemployment Claims Charge Report":
        dto = JSON.parse(
          JSON.stringify(unemploymentClaimsChargeReportParametersInit)
        ) as UnemploymentClaimsChargeReportParametersDto;
        if (isClientPortalUser()) dto.healthSystems[0] = "";
        setValidationSchema(unemploymentClaimsChargeReportValidationSchema);
        break;
      case "Credit Due Report":
        dto = JSON.parse(
          JSON.stringify(creditDueReportParametersInit)
        ) as CreditDueReportParametersDto;
        if (isClientPortalUser()) dto.healthSystems[0] = "";
        setValidationSchema(creditDueReportValidationSchema);
        break;
    }

    setDto(JSON.parse(JSON.stringify(dto)));
  }, [reportName]);

  React.useEffect(() => {
    setCompanies(JSON.parse(JSON.stringify(systemHsCompanies)));
    let hs: string[] = Array.from(
      new Set(systemHsCompanies.map((c) => c.healthSystem))
    ).sort((a, b) => a.localeCompare(b));
    if (isClientPortalUser() === false) hs.unshift("All-MHA");
    setHeathSystems(JSON.parse(JSON.stringify(hs)));
  }, [systemHsCompanies]);

  React.useEffect(() => {
    let u: ContactIdDto[] = JSON.parse(JSON.stringify(systemMhaClaimsContacts));
    if (isClientPortalUser() === false)
      u.unshift({ name: "All-Users", contactId: "all-users" } as ContactIdDto);
    setUsers(JSON.parse(JSON.stringify(u)));
  }, [systemMhaClaimsContacts]);

  return (
    <Formik
      initialValues={dto}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setTimeout(() => {
          setSubmitting(false);
          resetForm(
            JSON.parse(
              JSON.stringify(benchmarkAndComparisonReportParametersInit)
            )
          );
        }, 700);
        let params =
          reportName === "Productivity Report"
            ? (values as ProductivityReportParametersDto)
            : (values as BenchmarkAndComparisonReportParametersDto);
        if (reportName !== "Claimant Profile") {
          // Productivity Report
          if (reportName === "Productivity Report") {
            if (
              (params as ProductivityReportParametersDto).users === "all-users"
            )
              (params as ProductivityReportParametersDto).users = users
                .filter((f) => f.contactId !== "all-users" && !!f.contactId)
                .map((m) => m.contactId)
                .join(",");
          }

          // Executive Summary Report with Individual Employer Breakdowns
          if (
            reportName ===
            "Executive Summary Report with Individual Employer Breakdowns"
          ) {
            if (params.groups[0].some((s) => s.length !== 0)) {
              (
                params as ExecutiveSummaryWithIndividualBreakdownsReportParametersDto
              ).groupIds = [""];
              (
                params as ExecutiveSummaryWithIndividualBreakdownsReportParametersDto
              ).healthSytemsIds = [""];
            } else if (
              !!!params.healthGroups ||
              !params.healthGroups.some((s) => s.length !== 0)
            ) {
              (
                params as ExecutiveSummaryWithIndividualBreakdownsReportParametersDto
              ).groupIds = [""];
              (
                params as ExecutiveSummaryWithIndividualBreakdownsReportParametersDto
              ).healthSytemsIds = params.healthSystems.includes("All-MHA")
                ? healthSystemsList
                    .filter(
                      (f) =>
                        !!f.healthSystemId &&
                        !!f.healthSystemName &&
                        heathSystems.includes(f.healthSystemName)
                    )
                    .map((m) => m.healthSystemId!)
                : healthSystemsList
                    .filter(
                      (f) =>
                        !!f.healthSystemId &&
                        !!f.healthSystemName &&
                        params.healthSystems.includes(f.healthSystemName)
                    )
                    .map((m) => m.healthSystemId!);
            } else {
              (
                params as ExecutiveSummaryWithIndividualBreakdownsReportParametersDto
              ).healthSytemsIds = [""];
              (
                params as ExecutiveSummaryWithIndividualBreakdownsReportParametersDto
              ).groupIds = healthSystemsList
                .filter((f) => heathSystems.includes(f.healthSystemName))
                .flatMap((fm) => fm.groups)
                .filter(
                  (gf) =>
                    !!gf.id &&
                    !!gf.name &&
                    params.healthGroups.includes(gf.name)
                )
                .map((m) => m.id!);
            }
          } else {
            for (let i = 0; i < params.healthSystems.length; i++) {
              if (params.groups[i]?.length === 0) {
                let list: CompanyHsDto[] = Array.from(
                  new Set(
                    companies
                      .filter((c) =>
                        params.healthSystems[i] === "All-MHA"
                          ? true
                          : c.healthSystem === params.healthSystems[i]
                      )
                      .filter((c) => c.group.includes(params.healthGroups[i]))
                  )
                );

                list.forEach((c) => params.groups[i].push(c.companyId ?? ""));
              }
            }
          }

          // Set Param Name(s)
          params.names = [];

          if (
            reportName !==
            "Executive Summary Report with Individual Employer Breakdowns"
          ) {
            if (params.healthGroups[0].length === 0)
              params.names[0] = params.healthSystems[0];
            else if (params.groups[0].length === 1)
              params.groups[0].forEach((s) =>
                params.names.push(
                  companies.find((c) => c.companyId === s)?.name ?? ""
                )
              );
            else params.names[0] = params.healthGroups[0];
          }
        }

        handleSelectedParams(params);
      }}
      enableReinitialize={true}
    >
      {(props) => {
        const {
          values,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          handleReset,
        } = props;
        return (
          <Dialog
            open={open}
            data-cy="isComplete_dialog"
            fullWidth={true}
            maxWidth="md"
          >
            <DialogTitle>
              {reportName} -- {reportDescription}
            </DialogTitle>

            <DialogContent>
              <Grid
                container
                alignContent="center"
                spacing={2}
                justifyContent="flex-start"
              >
                {reportName === "Benchmark And Comparison Report" && (
                  <BenchmarkAndComparisonReportParameters
                    values={values as BenchmarkAndComparisonReportParametersDto}
                    errors={
                      errors as FormikErrors<BenchmarkAndComparisonReportParametersDto>
                    }
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={
                      setFieldValue as FormikHelpers<BenchmarkAndComparisonReportParametersDto>["setFieldValue"]
                    }
                    heathSystems={heathSystems}
                    companies={companies}
                  />
                )}

                {reportName === "Credit Due Report" && (
                  <CreditDueReportParams
                    values={values as CreditDueReportParametersDto}
                    errors={
                      errors as FormikErrors<CreditDueReportParametersDto>
                    }
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={
                      setFieldValue as FormikHelpers<CreditDueReportParametersDto>["setFieldValue"]
                    }
                    heathSystems={heathSystems}
                    companies={companies}
                  />
                )}

                {reportName === "Unemployment Claims Summary Report" && (
                  <UnemploymentClaimsSummaryReportParameters
                    values={
                      values as UnemploymentClaimsSummaryReportParametersDto
                    }
                    errors={
                      errors as FormikErrors<UnemploymentClaimsSummaryReportParametersDto>
                    }
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={
                      setFieldValue as FormikHelpers<UnemploymentClaimsSummaryReportParametersDto>["setFieldValue"]
                    }
                    heathSystems={heathSystems}
                    companies={companies}
                  />
                )}

                {reportName === "Executive Summary Report" && (
                  <ExecutiveSummaryReportParameters
                    values={values as ExecutiveSummaryReportParametersDto}
                    errors={
                      errors as FormikErrors<ExecutiveSummaryReportParametersDto>
                    }
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={
                      setFieldValue as FormikHelpers<ExecutiveSummaryReportParametersDto>["setFieldValue"]
                    }
                    heathSystems={heathSystems}
                    companies={companies}
                  />
                )}

                {reportName === "Annual Report Summary" && (
                  <AnnualSummaryReportParameters
                    values={values as AnnualSummaryReportParametersDto}
                    errors={
                      errors as FormikErrors<AnnualSummaryReportParametersDto>
                    }
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={
                      setFieldValue as FormikHelpers<AnnualSummaryReportParametersDto>["setFieldValue"]
                    }
                    heathSystems={heathSystems}
                    companies={companies}
                  />
                )}

                {reportName === "Productivity Report" && (
                  <ProductivityReportParameters
                    values={values as ProductivityReportParametersDto}
                    errors={
                      errors as FormikErrors<ProductivityReportParametersDto>
                    }
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={
                      setFieldValue as FormikHelpers<ProductivityReportParametersDto>["setFieldValue"]
                    }
                    heathSystems={heathSystems}
                    companies={companies}
                    claimsUsers={users}
                  />
                )}

                {reportName === "New Unemployment Claims Report" && (
                  <NewUnemploymentClaimsReportParameters
                    values={values as NewUnemploymentClaimsReportParametersDto}
                    errors={
                      errors as FormikErrors<NewUnemploymentClaimsReportParametersDto>
                    }
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={
                      setFieldValue as FormikHelpers<NewUnemploymentClaimsReportParametersDto>["setFieldValue"]
                    }
                    heathSystems={heathSystems}
                    companies={companies}
                  />
                )}

                {reportName ===
                  "Executive Summary Report with Individual Employer Breakdowns" && (
                  <ExecutiveSummaryWithIndividualBreakdownsReportParameters
                    values={
                      values as ExecutiveSummaryWithIndividualBreakdownsReportParametersDto
                    }
                    errors={
                      errors as FormikErrors<ExecutiveSummaryWithIndividualBreakdownsReportParametersDto>
                    }
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={
                      setFieldValue as FormikHelpers<ExecutiveSummaryWithIndividualBreakdownsReportParametersDto>["setFieldValue"]
                    }
                    heathSystems={heathSystems}
                    companies={companies}
                  />
                )}

                {reportName === "Claimant Profile" && (
                  <ClaimantProfileReportParameters
                    setFieldValue={
                      setFieldValue as FormikHelpers<ClaimantProfileReportParametersDto>["setFieldValue"]
                    }
                    submit={handleSubmit}
                  />
                )}

                {(reportName === "Unemployment Claims Charge Report" ||
                  reportName === "Unemployment Claims Hearings Report") && (
                  <UnemploymentClaimsChargeReportParameters
                    values={
                      values as UnemploymentClaimsChargeReportParametersDto
                    }
                    errors={
                      errors as FormikErrors<UnemploymentClaimsChargeReportParametersDto>
                    }
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={
                      setFieldValue as FormikHelpers<UnemploymentClaimsChargeReportParametersDto>["setFieldValue"]
                    }
                    heathSystems={heathSystems}
                    companies={companies}
                    showDisplayParameter={
                      reportName !== "Unemployment Claims Hearings Report"
                    }
                  />
                )}
              </Grid>

              <br />
              <br />
              <br />
              <br />
            </DialogContent>

            <DialogActions>
              <ButtonGrid container>
                {reportName !== "Claimant Profile" && (
                  <StyledSubmitButton
                    onClick={() => handleSubmit()}
                    ccolor={theme.secondaryColors.customTeal}
                    disabled={isSubmitting}
                  >
                    Generate Report
                  </StyledSubmitButton>
                )}

                <StyledCancelButton
                  color="secondary"
                  onClick={() => {
                    handleReset();
                    onCancel();
                  }}
                >
                  Cancel
                </StyledCancelButton>
              </ButtonGrid>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default ReportParamsDialog;
