import { RoleDto, roleInit } from '../../../common/dtos/role';


export interface SystemRoleSettingDto {
  role: RoleDto,
  accessRoles: RoleDto[],
  reportAccountingRoles: RoleDto[],
  reportDashboardRoles: RoleDto[],
  reportManagementRoles: RoleDto[]
}

export const systemRoleSettingInit: SystemRoleSettingDto = { role: roleInit, accessRoles: [], reportAccountingRoles: [], reportDashboardRoles: [], reportManagementRoles: [] };