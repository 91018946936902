import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { updatePasswordApi } from './updatePassword.api';
import { updatePasswordActions } from './updatePassword.actions';
import {
  storageAuthInformation,
  tenantId,
} from '../../../common/constants/strings';
import { logoutActions } from '../login/login.actions';

function* handleUpdatePassword(
  dto: ReturnType<typeof updatePasswordActions.request>
) {
  try {
    const response: boolean = yield call(updatePasswordApi, dto.payload);

    if (response) {
      yield put({
        type: getType(updatePasswordActions.success),
        payload: true,
      });
      yield put({ type: logoutActions.request });
      sessionStorage.setItem(
        storageAuthInformation,
        JSON.stringify({
          ...JSON.parse(
            sessionStorage.getItem(storageAuthInformation) ?? 'null'
          ),
          isPasswordExpired: false,
        })
      );

      window.location.replace(
        // Currently this redirect to the login page after changing the password does not work. isPasswordExpired() needs to be set to false upon successfully updating the password.
        `/${localStorage.getItem(tenantId) ?? 'mha'}/login`
      );
    } else {
      put({
        type: getType(updatePasswordActions.failure),
        payload: 'Change Password Failed',
      });
    }
  } catch (error) {
    put({
      type: getType(updatePasswordActions.failure),
      payload: 'Change Password Failed',
    });
  }
}

export function* watchUpdatePasswordRequest() {
  try {
    yield takeLatest(
      getType(updatePasswordActions.request),
      handleUpdatePassword
    );
  } catch (err) {}
}
