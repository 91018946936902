import { AxiosError } from 'axios';
import { showNotification } from '../../common/actionCreators/dispatchNotification';
import apiClient from '../../common/api/apiClient';
import { ApiError } from '../../common/api/apiResponse';
import { SidesRepullDto } from './administration.dtos';

export const sidesRepullApi = async (request: SidesRepullDto) => {
  return await apiClient()
    .post('administration/manual-pull', request)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response)
          showNotification(axiosError.response.data.error.message, 'error');
      } else showNotification('ERROR', 'error');
      throw err;
    });
};
