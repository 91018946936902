import { FormikErrors, FormikHelpers } from "formik";
import { ISi } from "../common/dtos/SiDto";
import { EmployerSepReasonCodes_class } from "../common/enums/EmployerSepReasonCodes";
import { NotReturningCodes_class } from "../common/enums/NotReturningCodes";
import { VoluntarySepReasonCodes_class } from "../common/enums/VoluntarySepReasonCodes";

/**
 * Handles IndicatorTypeY type UISIDES SI fields
 * @param name: string
 * @param checked: boolean
 * @param set: function
 */
export const handleIndicatorTypeY = (
  name: string,
  checked: boolean,
  set: FormikHelpers<ISi>["setFieldValue"]
): void => {
  if (checked) set(name, 0);
  else set(name, undefined);
};

/**
 * Handles IndicatorType type UISIDES SI fields
 * @param name: string
 * @param checked: boolean
 * @param set: function
 */
export const handleIndicatorType = (
  name: string,
  checked: boolean,
  set: FormikHelpers<ISi>["setFieldValue"]
): void => {
  if (checked) set(name, 0);
  else set(name, undefined);
};

/**
 * Returns if the properity name is required based on the separation Code.
 * @param name: string
 * @param value: number | undefined
 */
export const isRequiredBySepCode = (
  name: string,
  value?: number | undefined
): boolean => {
  if (value === undefined) return false;
  let e = EmployerSepReasonCodes_class.enum;

  switch (name) {
    case 'workingAllAvailableHoursInd':
      return [e.Item10, e.Item11, e.Item12].includes(value ?? -1);
    case 'notWorkingAvailableHoursReason':
      return [e.Item18].includes(value ?? -1);

    default: return false;
  }
};

/**
 * Returns if the properity name is required based on the NotReturningCodes.
 * @param name: string
 * @param value: number | undefined
 */
export const isRequiredByNotReturningCode = (
  name: string,
  value?: number | undefined
): boolean => {
  if (value === undefined) return false;
  let e = NotReturningCodes_class.enum;

  switch (name) {
    case 'notWorkingAvailableHoursReason':
      return [e.Item18].includes(value ?? -1);

    default: return false;
  }
};


/**
 * Returns if the tab name has an error.
 * @param tab: string
 * @param value: FormikErrors
 */
export const hasTabError = (
  tab: 'details' | 'general' | 'discharge' | 'vquit' | 'payments' | 'misc',
  errors: FormikErrors<ISi>,
  values?: ISi
): boolean => {
  //console.log('hasTabError', tab, errors);
  if (errors == null) return false;
  let errorList: string[] = Object.keys(errors);
  if (errorList.length == 0) return false;

  let vqList = [
    'voluntarySepReasonCode', 'claimantNotifyOfMedicalIssue', 'claimantRequestMedicalLoa', 'medicalLoaavailable', 'loaapprovedInd', 'whyLoadenied',
    'quitUnderAdviceOfHealthcareProvider', 'medicalStatementProvidedInd', 'dutiesClaimantCouldNotPerform', 'workRestrictionsInd', 'claimantWorkRestrictions',
    'workAvailableWithinRestrictionsInd', 'otherWorkOfferedInd', 'inLieuInd', 'continuingWorkAvailableInd', 'reasonDissatisfied', 'workingConditions', 'workingConditionsChangedInd',
    'changesPermanentInd', 'changesScheduledToLast', 'hiringAgreementChangesCode', 'hiringAgreementChangesComments',
    'effectiveDateOfChanges', 'claimantActionsToAvoidQuitInd', 'actionTakenComments', 'claimantContactToExplainInd', 'dateMadeContact', 'reasonForFailToReport', 'claimantNoticeInd',
    'dateNoticeGiven', 'claimantProvidedIntendedLastDayWork', 'claimantProvidedIntendedLastDayWorkUnknown', 'claimantWorkOutNoticeInd', 'whyNoticeWasntWorkedOut',
    'claimantPaidThroughNoticeInd', 'voluntarySepReasonComments'
  ];

  let mList = [
    'whyNotReturning', 'firstDayOfContract', 'lastDayOfContract', 'reasonForBreak', 'firstDayOfBreak', 'lastDayOfBreak', 'workContinueDuringBreakInd', 'positionReturningTo',
    'newContractJobOfferInd', 'personOfferedReasonableAssurance', 'haveAuthorityToOfferInd', 'whyContractNotOffered', 'contractAccepted', 'laborDisputeTypeInd',
    'locationOfLaborDispute', 'unionNameAndLocalNo', 'dateDisputeBegan', 'laborDisputeEndedInd', 'dateDisputeEnded', 'replacementWorkersHiredInd', 'clmtStrikingUnionMemberInd',
    'clmtMemberAnotherUnionInd', 'whyUnemployedLaborDispute', 'settlementAffectClmtInd', 'howSettlementAffects', 'relationshipEndedInd', 'workingNewAssignmentInd',
    'notWorkingOnAssignment', 'tempAgencyPolicyInd', 'tempPolicyAwarenessCode', 'tempAssignmentLastDay', 'newTempAssignmentDateOfferMade', 'newTempAssignmentStartDate',
    'newTempAssignmentJobDuties', 'newTempAssignmentRateOfPay', 'newTempAssignmentCommutingDistance', 'newTempAssignmentHours', 'newTempAssignmentWhyRefused',
    'newTempAssignmentDateRefused', 'tempAssignmentWhoEnded', 'tempAssignmentWhyNotCompleted', 'tempAssignmentClaimantContactAgencyInd', 'tempAssignmentWorkAvailableInd',
    'uisSiContactOccurrences', 'uisSiPreviousAssignmentOccurrences'
  ];

  if (!!values && values.voluntarySepReasonCode === VoluntarySepReasonCodes_class.enum.Item8) {
    vqList.push('mandatoryRetirementInd');
    vqList.push('whyRetireComments');
  }
  else {
    mList.push('mandatoryRetirementInd');
    mList.push('whyRetireComments');
  }
  
  switch (tab) {
    case 'details':
      return [
        'amendedResponseDescription', 'correctedStateEmployerAccountNbr', 'correctedFEIN', 'otherSSN', 'fraudInd', 'fraudReasons', 'fraudComments', 'attachmentInd'
      ].some(s => errorList.includes(s));
    case 'general':
      return [
        'contractEmployee1099Ind', 'seasonalEmploymentInd', 'employerSepReasonCode', 'temporaryStaffingInd', 'claimantJobTitle', 'employerReportedClaimantFirstDayofWork',
        'employerReportedClaimantLastDayofWork', 'separationDateInd', 'returnToWorkInd', 'returnToWorkDateInd', 'whyLeaveOfAbsence', 'agreedReturnToWorkDateInd', 'returnToWorkDate',
        'whoReducedHours', 'workingAllAvailableHoursInd', 'notWorkingAvailableHoursReason', 'receiveNormalRateOfPayInd', 'employerSepReasonComments', 'refuseToProvideInd',
        'employerContactName', 'employerPhone', 'employerStreetAddress', 'employerCity', 'employerState', 'employerZip', 'repContractTermDate', 'effectiveSeparationDate'
      ].some(s => errorList.includes(s));
    case 'discharge':
      return [
        'dischargeReasonCode', 'inadequatePerfReasonInd', 'finalIncidentDate', 'finalIncidentReason', 'metPastExpections', 'reasonForFinalAbsence', 'askedToTestInd', 'testPerformedInd',
        'whyNotPerformed', 'whyRequestedToTest', 'whoPerformedTest', 'testerName', 'testerCompanyName', 'testResults', 'chainOfCustodyInd', 'secondTestSplitSampleGiven',
        'secondTestSplitSampleResults', 'jobChangeInd', 'whatConditionsChanged', 'whatWasStolen', 'actCommittedViolatedLaw', 'adverseEffectOnEmployer', 'valueAttachedToActInd',
        'dollarValue', 'claimantAdmissionInd', 'damagesReportedBy', 'altercationOnCompanyPropertyInd', 'altercationOnCompanyTimeInd', 'claimantStartAltercationInd',
        'claimantAvoidAltercationInd', 'wereThereWitnessesInd', 'violateCompanyPolicyInd', 'dischargePolicyAwareInd', 'dischargePolicyAwareExplanationCode', 'whoDischargedName',
        'whoDischargedTitle', 'dischargeReasonComments', 'priorIncidentInd', 'uisSiPriorIncidentOccurrences'
      ].some(s => errorList.includes(s));
    case 'vquit':
      return vqList.some(s => errorList.includes(s));
    case 'payments':
      return [
        'totalEarnedWagesNeededInd', 'totalEarnedWages', 'totalWeeksWorkedNeededInd', 'totalWeeksWorked', 'WagesEarnedAfterClaimEffectiveDate',
        'numberOfHoursWorkedAfterClaimEffectiveDate', 'regularRateofPay', 'rateofPayPerPeriod', 'averageNumberofHoursWorkedperWeek', 'customaryWeeklyWorkSchedule',
        'customaryWeeklyWorkSchedule', 'remunerationInd', 'receivingWorkersCompensationInd', 'dateOfInjury', 'dateAppliedForWorkersCompKnownInd', 'dateAppliedForWorkersComp',
        'typeOfWorkersCompReceiving', 'weeklyWorkersCompRate', 'periodOfTimeWorkersCompPaymentCovers', 'dateClaimantReleased', 'employerAccommodateRestrictionsInd',
        'whyEmployerCouldNotAccommodate', 'workersCompComments', 'receivePension401KInd', 'mandatoryPension', 'percentage401KrolledOver', 'percentage401KrolledOverUnknown',
        'paidOut401KtoClaimant', 'uisSiRemunerationOccurrences', 'uisSiPension401Koccurrences', 'workRestrictionsInd'
      ].some(s => errorList.includes(s));
    case 'misc':
      return mList.some(s => errorList.includes(s));

    default: return false; //'', '', '',
  }

  return false;
};
