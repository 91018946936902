

export const reportedWageTypes = [
  { name: '', id: 0 },
  { name: 'Week Ending', id: 1 },
  { name: 'Month Ending', id: 2 },
  { name: 'Quarter Ending', id: 3 },
];


export const paymentErrorTypes = [
  { name: '', id: 0 },
  { name: 'Ineligibility', id: 1 },
  { name: 'Pending Status', id: 2 },
  { name: 'No claim', id: 3 },
  { name: 'Had no claim', id: 4 },
  { name: 'Won Status', id: 5 },
  { name: 'Actual liability', id: 6 },
];