import * as Yup from 'yup';


export const emailsValidationSchema = Yup.array().of(Yup.object().shape({
  contactTypeId: Yup.number().min(7).max(12),
  emailAddress: Yup.string().email('Invalid email address').nullable()
    //.matches(/^[A-Za-z\d-._,\-\s*@+]{0,30}$/, "', _, -, +, .' are the only special characters allowed"),
}));


export interface EmailDto {
  contactLinkId?: string, //($uuid)
  contactTypeId: number, //
  emailAddress: string,
  //maxLength: 500
  label?: string,
  //maxLength: 50,
  isPrimary: boolean,
}

export const emailInit: EmailDto = {
  contactTypeId: 9,
  emailAddress: '',
  isPrimary: false
};