import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors } from 'formik';
// material ui
import { Grid, Card, CardHeader } from '@material-ui/core';
import { ClientQuestionnaireDto } from '../dto';
import FirstQuestion from '../formComponents/firstQuestion';
import SecondQuestion from '../formComponents/secondQuestion';
import ThirdQuestion from '../formComponents/thirdQuestion';
import InitialSeparationQuestion from '../formComponents/initialSeparationQuestion';
import AdditionalQuestions from '../formComponents/additionalQuestions';
import { CardContent3 } from '../../../common/styles/styles';


interface Props extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
  renderSwitch: (id: number, values: ClientQuestionnaireDto,
    errors: FormikErrors<ClientQuestionnaireDto>,
    handleChange: FormikHandlers["handleChange"],
    handleBlur: FormikHandlers["handleBlur"],
    setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"]
  ) => void;
  handleResetFromSeparation: (q: ClientQuestionnaireDto, id: number) => void;
}

const QuestionsCard: React.FC<Props> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation,
    renderSwitch,
    handleResetFromSeparation
  }) => {

  return (
    <Card>
      <CardHeader title="Questions" titleTypographyProps={{variant: 'h6'}}></CardHeader>
      <CardContent3>

        <FirstQuestion setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          errors={errors} handleChange={handleChange} handleBlur={handleBlur} />

        <SecondQuestion setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          handleBlur={handleBlur} />

        <ThirdQuestion setShouldBlockNavigation={setShouldBlockNavigation} setFieldValue={setFieldValue} values={values}
          handleBlur={handleBlur} />

        {!(!!values.questionC && !!values.falseFiling) &&
          <InitialSeparationQuestion setShouldBlockNavigation={setShouldBlockNavigation} values={values} errors={errors}
          handleChange={handleChange} handleBlur={handleBlur} handleResetFromSeparation={handleResetFromSeparation} />
        }

        {
          (!(!!values.questionC && !!values.falseFiling) && values.initialSeparationQuestionId) &&
          renderSwitch(values.initialSeparationQuestionId, values, errors, handleChange, handleBlur, setFieldValue)
        }

        <br />
        <Grid container item xs={12} spacing={2}>
          {values.questions && values.questions.length > 0 ?
            <>
              <Grid item xs={12}>Additional Questions</Grid>
              <br />
              {values.questions && values.questions !== undefined && values.questions.map((o, index) => (
                <AdditionalQuestions question={o} key={index} index={index} errors={errors} handleChange={handleChange}
                  handleBlur={handleBlur} setShouldBlockNavigation={setShouldBlockNavigation}
                />
              ))}
            </>
            : null}
        </Grid>

        <br /><br />

      </CardContent3>
    </Card>
  )
}

export default QuestionsCard;
