import { all, call, spawn } from 'redux-saga/effects';
import { watchClaimantReportRequest } from '../common/sagas/claimants';
import { watchContactInformationGetRequest } from '../common/sagas/contactInformation.saga';
import emailTemplate from '../common/sagas/emailTemplate.saga';
import { watchAssistanceRequest } from '../common/sagas/requestAssistance.saga';
import { watchtimeZonesGetRequest } from '../common/sagas/timeZones.saga';
import { watchChangePasswordRequest } from '../features/account/changePassword/changePassword.saga';
import { watchForgotPasswordRequest } from '../features/account/forgotPassword/forgotPassword.saga';
import login from '../features/account/login/login.saga';
import profile from '../features/account/profile/profile.saga';
import { watchResetPasswordRequest } from '../features/account/resetPassword/resetPassword.saga';
import { watchUpdatePasswordRequest } from '../features/account/updatePassword/updatePassword.saga';
import administration from '../features/administration/administration.saga';
import batchPayments from '../features/batchPayments/saga';
import claims from '../features/claims/saga';
import company from '../features/company/saga';
import contacts from '../features/contacts/contacts.saga';
import documents from '../features/documents/saga';
import questionnaire from '../features/questionnaire/saga';
import { watchReportsGetRequest } from '../features/reports/saga';
import codes from '../features/settings/codes/codes.saga';
import healthSystems from '../features/settings/healthSystems/healthSystems.saga';
import system from '../features/settings/system/system.saga';

export default function* rootSaga() {
  const sagas: any[] = [
    /* List sagas here*/
    ...administration,
    ...batchPayments,
    ...claims,
    ...codes,
    ...company,
    ...contacts,
    ...documents,
    ...emailTemplate,
    ...healthSystems,
    ...login,
    ...profile,
    ...questionnaire,
    ...system,
    watchAssistanceRequest,
    watchChangePasswordRequest,
    watchClaimantReportRequest,
    watchContactInformationGetRequest,
    watchUpdatePasswordRequest,
    watchForgotPasswordRequest,
    watchReportsGetRequest,
    watchResetPasswordRequest,
    watchtimeZonesGetRequest,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {}
        }
      })
    )
  );
}
