import styled from 'styled-components';

// material ui
import {
    Card as MuiCard, Avatar,  Select, TextField,  FormControlLabel
} from '@material-ui/core';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';


// Styled components ....

export const T3Select = styled(Select)`
    margin-top: 13px;
`;

export const PaddedStreetTextField = styled(TextField)`

    padding-top: 15px;
    padding-bottom: 5px;
`;

export const StyledAddButton = styled(AddBoxRoundedIcon)`

    margin-right: 25px;
`;


export const TextSelect = styled(TextField)`

margin-top: 13px;

`;

export const HeaderAvatar = styled(Avatar)`
    
    ${props => {
        if (props.src === '') {
            return `visibility: hidden;`;
        }
    }}
            width: 74px;
            height: 74px;                     
`;

export const Logo = styled.img`
    position: relative;
    margin: 24px 8px 8px 20px;
`;


export const TabColumnCard = styled(MuiCard)` 
   width: 90%
`;

export const CheckboxLabel = styled(FormControlLabel)`
  padding: 0px  
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  line-height: 1px !important;
`;

export const TelerikPlaceholder = styled.img`
margin-top: 50px;
`;

export const BulkImportCard = styled(MuiCard)`   
    width: 100%;
    padding: 2px;
  padding-bottom: 15px;

`;