import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { sidesRepullApi } from './administration.api';
import { sidesRepullActions } from './administration.actions';

function* handleSidesRepull(
  request: ReturnType<typeof sidesRepullActions.request>
) {
  const sidesRepullResponseList: string[] = yield select(
    (state) => state.administration.repullResponse
  );
  const sidesRepullResponses: string[] =
    sidesRepullResponseList !== undefined &&
    sidesRepullResponseList.length !== 0
      ? sidesRepullResponseList
      : yield call(sidesRepullApi, request.payload);

  yield put({
    type: getType(sidesRepullActions.success),
    payload: sidesRepullResponses,
  });
}

function* watchSidesRepull() {
  try {
    yield takeLatest(getType(sidesRepullActions.request), handleSidesRepull);
  } catch (err) {}
}

export default [watchSidesRepull];
