import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { CompanyDto, CompanyHsDto } from './dtos';
import {
    createCompanyApi, getCompaniesApi, updateCompanyApi, uploadCompanyImportApi, getCompanyUploadHistoryApi,
    getCompanyApi, getMhaContactsApi, getCompanyRateApi, createCompanyRateApi, updateCompanyRateApi, getCompaniesHsApi
} from './api';
import {
  createCompanyActions, getCompaniesActions, updateCompanyActions, companyImportHistoryShouldRefresh, getCompaniesHsActions,
    getCompanyBulkImportHistoryActions, uploadCompanyBulkImport, getCompanyActions, updateCompanyRateActions, createCompanyRateActions, getCompanyRateActions,
} from './actions';
import { BulkImportDto } from '../../common/dtos/bulkImportDto';
import { getMhaContactsActions } from '../../common/actions/actions';
import { MhaContactsDto } from '../../common/dtos/dto';
import { RateDto, RateWrapperDto } from '../../common/dtos/billing';
import { ClaimInit } from '../claims/claims.dtos';
import { getClaimActions } from '../claims/actions';


function* handleGetCompanies() {
  const companiesList: CompanyDto[] = yield select(state => state.companies.companiesList);
    const companyStorage = sessionStorage.getItem('companies');
    const sessionCompanies = companyStorage === null ? [] : JSON.parse(companyStorage) as CompanyDto[];
    const companies: CompanyDto[] = companiesList !== undefined && companiesList.length !== 0 ? companiesList :
            sessionCompanies.length !== 0 && sessionCompanies !== null ? sessionCompanies :
                yield call(getCompaniesApi);

    yield put({ type: getType(getCompaniesActions.success), payload: companies });
    sessionStorage.setItem('companies', JSON.stringify(companies));
}

function* handleGetCompany(request: ReturnType<typeof getCompanyActions.request>) {
  const storeCompany: CompanyDto = yield select(state => state.companies.company);
  const company: CompanyDto = (storeCompany !== undefined && storeCompany.companyId !== undefined && storeCompany.companyId === request.payload) ? storeCompany : yield call(getCompanyApi, request.payload);

  yield put({ type: getType(getCompanyActions.success), payload: company });
}

function* handleUpdateCompany(request: ReturnType<typeof updateCompanyActions.request>) {
  const updatedCompany: CompanyDto = yield call(updateCompanyApi, request.payload);
  //const storeCompanies = yield select(state => state.companies.companiesList);
  //let companies = JSON.parse(JSON.stringify(storeCompanies)) as CompanyDto[];
  //let updatedCompanyIndex = companies.findIndex(c => c.companyId === updatedCompany.companyId);

  //if (updatedCompanyIndex !== -1) {
  //  companies[updatedCompanyIndex] = updatedCompany;
  //}

  yield put({ type: getType(getCompanyActions.success), payload: updatedCompany });
  yield put({ type: getType(getCompaniesActions.success), payload: [] });
  yield put({ type: getType(getClaimActions.success), payload: { ...ClaimInit } });
  sessionStorage.removeItem('companies');
  sessionStorage.removeItem('hsCompanies');

}

function* handleCreateCompany(request: ReturnType<typeof createCompanyActions.request>) {
  const createdCompany: CompanyDto = yield call(createCompanyApi, request.payload);
  //const storeCompanies = yield select(state => state.companies.companiesList);
  //let companies = JSON.parse(JSON.stringify(storeCompanies)) as CompanyDto[];
  //companies.push(createdCompany);

  yield put({ type: getType(getCompanyActions.success), payload: createdCompany });
  yield put({ type: getType(getCompaniesActions.success), payload: [] });
  sessionStorage.removeItem('companies');
  sessionStorage.removeItem('hsCompanies');
}

function* handleGetCompanyImportHistory() {
  const refreshCompanyImportHistory: boolean = yield select(state => state.companies.refreshCompanyImportHistory);
  const companyImportHistoryList: BulkImportDto[] = yield select(state => state.companies.bulkImportHistory);
  const companyImportHistory: BulkImportDto[] = ((companyImportHistoryList !== undefined && companyImportHistoryList.length > 0) && !refreshCompanyImportHistory) ? companyImportHistoryList : yield call(getCompanyUploadHistoryApi);

  yield put({ type: getType(getCompanyBulkImportHistoryActions.success), payload: companyImportHistory });
  yield put({ type: getType(companyImportHistoryShouldRefresh), payload: false });
}

function* handleUploadCompanyImport(request: ReturnType<typeof uploadCompanyBulkImport>) {
  const success: boolean = yield call(uploadCompanyImportApi, request.payload);
  
  if (success) {
    yield put({ type: getType(companyImportHistoryShouldRefresh), payload: true });
    yield call(handleGetCompanyImportHistory);
    yield put({ type: getType(getCompaniesActions.success), payload: [] as CompanyDto[] });
    sessionStorage.removeItem('companies');
  }
}

function* handleGetMhaContacts() {
  const mhaContactsList: MhaContactsDto[] = yield select(state => state.companies.mhaContacts);
  const mhaContacts: MhaContactsDto[] = (mhaContactsList !== undefined && mhaContactsList.length !== 0) ?
    mhaContactsList : yield call(getMhaContactsApi);

  yield put({ type: getType(getMhaContactsActions.success), payload: mhaContacts });
}

function* handleGetCompanyRate(request: ReturnType<typeof getCompanyRateActions.request>) {
    const storeRate: RateWrapperDto = yield select(state => state.companies.rate);
    const rate: RateDto = (storeRate !== undefined && storeRate !== undefined && storeRate.id === request.payload.id) ? storeRate.rate : yield call(getCompanyRateApi, request.payload);
    const rateWrapper: RateWrapperDto = { id: request.payload.id, rate: rate }

    yield put({ type: getType(getCompanyRateActions.success), payload: rateWrapper });
}


function* handleUpdateCompanyRate(request: ReturnType<typeof updateCompanyRateActions.request>) {
    const updatedRate: RateDto = yield call(updateCompanyRateApi, request.payload);
    const rateWrapper: RateWrapperDto = { id: request.payload.id, rate: updatedRate }

    yield put({ type: getType(getCompanyRateActions.success), payload: rateWrapper });
}

function* handleCreateCompanyRate(request: ReturnType<typeof createCompanyRateActions.request>) {
    const createdRate: RateDto = yield call(createCompanyRateApi, request.payload);  
    const rateWrapper: RateWrapperDto = { id: request.payload.id, rate: createdRate }

    yield put({ type: getType(getCompanyRateActions.success), payload: rateWrapper });
}

function* handleGetHsCompanies() {
  const hsCompaniesList: CompanyHsDto[] = yield select(state => state.companies.hsCompanies);
  const hsCompanyStorage = sessionStorage.getItem('hsCompanies');
  const sessionHsCompanies = hsCompanyStorage === null ? [] : JSON.parse(hsCompanyStorage) as CompanyHsDto[];
  const hsCompanies: CompanyHsDto[] = hsCompaniesList !== undefined && hsCompaniesList.length !== 0 ? hsCompaniesList as CompanyHsDto[] :
    sessionHsCompanies.length !== 0 && sessionHsCompanies !== null ? sessionHsCompanies as CompanyHsDto[] :
      yield call(getCompaniesHsApi); 

  yield put({ type: getType(getCompaniesHsActions.success), payload: hsCompanies });
  sessionStorage.setItem('hsCompanies', JSON.stringify(hsCompanies));
}



 function* watchCompanyGetRequest() {
  try { yield takeLatest(getType(getCompanyActions.request), handleGetCompany); }
  catch (err) { }
}

 function* watchCompaniesGetRequest() {
  try { yield takeLatest(getType(getCompaniesActions.request), handleGetCompanies); }
  catch (err) { }
}

 function* watchCompanyUpdateRequest() {
  try { yield takeLatest(getType(updateCompanyActions.request), handleUpdateCompany); }
  catch (err) { }
}

 function* watchCompanyCreateRequest() {
  try { yield takeLatest(getType(createCompanyActions.request), handleCreateCompany); }
  catch (err) { }
}

 function* watchCompanyImportHistoryGetRequest() {
  try { yield takeLatest(getType(getCompanyBulkImportHistoryActions.request), handleGetCompanyImportHistory); }
  catch (err) { }
}

 function* watchCompanyUploadImportHistoryRequest() {
  try { yield takeLatest(getType(uploadCompanyBulkImport), handleUploadCompanyImport); }
  catch (err) { }
}

 function* watchMhaContactsGetRequest() {
  try { yield takeLatest(getType(getMhaContactsActions.request), handleGetMhaContacts); }
  catch (err) { }
}

 function* watchCompanyRateGetRequest() {
    try { yield takeLatest(getType(getCompanyRateActions.request), handleGetCompanyRate); }
    catch (err) { }
}

 function* watchCompanyRateUpdateRequest() {
    try { yield takeLatest(getType(updateCompanyRateActions.request), handleUpdateCompanyRate); }
    catch (err) { }
}

 function* watchCompanyRateCreateRequest() {
    try { yield takeLatest(getType(createCompanyRateActions.request), handleCreateCompanyRate); }
    catch (err) { }
}

 function* watchCompaniesHsGetRequest() {
  try { yield takeLatest(getType(getCompaniesHsActions.request), handleGetHsCompanies); }
  catch (err) { }
}



export default [
  watchCompanyGetRequest,
  watchCompaniesGetRequest,
  watchCompanyUpdateRequest,
  watchCompanyCreateRequest,
  watchCompanyImportHistoryGetRequest,
  watchCompanyUploadImportHistoryRequest,
  watchMhaContactsGetRequest,
  watchCompanyRateGetRequest,
  watchCompanyRateUpdateRequest,
  watchCompanyRateCreateRequest,
  watchCompaniesHsGetRequest,
];