import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors } from 'formik';

// material ui
import { Grid, TextField, MenuItem, FormControlLabel } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ClientQuestionnaireDto } from '../../dto';
import { PaddedTextField, MHARadio } from '../../../../common/styles/styles';
import {
  dischargeNotificationOptions,
  radioButtonBooleanOptions,
  indicatorOptions,
} from '../../constants/options';

interface TheftComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>['setFieldValue'];
  setShouldBlockNavigation: (o: boolean) => void;
}

const TheftComponent: React.FC<TheftComponentProps> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setShouldBlockNavigation,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        On what date did the final incident occur?
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <KeyboardDatePicker
          margin='normal'
          id='finalIncidentDate'
          name='finalIncidentDate'
          format='MM/DD/yyyy'
          value={values.finalIncidentDate || null}
          data-cy='clientQuestionnaire_finalIncidentDate'
          fullWidth
          onChange={(date: MaterialUiPickersDate) => {
            setFieldValue('finalIncidentDate', date);
            setShouldBlockNavigation(true);
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <br />
      <Grid item xs={12}>
        Were there witnesses to the theft?
      </Grid>
      <Grid item xs={4}>
        <TextField
          id='wereThereWitnesses'
          size='small'
          margin='none'
          value={values.wereThereWitnesses || ''}
          multiline
          name='wereThereWitnesses'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.wereThereWitnesses)}
          helperText={errors.wereThereWitnesses && errors.wereThereWitnesses}
          data-cy='clientQuestionnaire_wereThereWitnesses'
          aria-label='Were there witnesses?'
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={12}>
        What was stolen?
      </Grid>
      <Grid item xs={4}>
        <TextField
          id='whatWasStolen'
          size='small'
          margin='none'
          value={values.whatWasStolen || ''}
          multiline
          name='whatWasStolen'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.whatWasStolen)}
          helperText={errors.whatWasStolen && errors.whatWasStolen}
          data-cy='clientQuestionnaire_whatWasStolen'
          aria-label='Were there witnesses?'
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={12}>
        Who did the item(s) belong to? (Colleague, Employer, Patient, etc.)
      </Grid>
      <Grid item xs={4}>
        <TextField
          id='itemOwner'
          size='small'
          margin='none'
          value={values.itemOwner || ''}
          multiline
          name='itemOwner'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.itemOwner)}
          helperText={errors.itemOwner && errors.itemOwner}
          data-cy='clientQuestionnaire_itemOwner'
          aria-label='Were there witnesses?'
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={12}>
        How did the employer learn of the theft?
      </Grid>
      <Grid item xs={4}>
        <TextField
          id='howDidEmployerLearn'
          size='small'
          margin='none'
          value={values.howDidEmployerLearn || ''}
          multiline
          name='howDidEmployerLearn'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.howDidEmployerLearn)}
          helperText={errors.howDidEmployerLearn && errors.howDidEmployerLearn}
          data-cy='clientQuestionnaire_howDidEmployerLearn'
          aria-label='Were there witnesses?'
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={12}>
        Did the claimant admit to the theft?
      </Grid>
      <Grid container item xs={12} direction='row'>
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id='admittedToTheft'
              name='admittedToTheft'
              onChange={(
                e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>,
                checked: boolean
              ) => {
                setFieldValue(
                  'admittedToTheft',
                  radioButtonBooleanOptions.find(
                    (x) => x.name === (e.target as HTMLInputElement).value
                  )?.status ?? 1
                );
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              checked={o.status === values.admittedToTheft}
              value={o.name}
              control={<MHARadio size='small' />}
              label={o.name}
              data-cy='clientQuestionnaire_admittedToTheft'
            />
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid item xs={12}>
        Were the police contacted?
      </Grid>
      <Grid container item xs={12} direction='row'>
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id='policeContacted'
              name='policeContacted'
              onChange={(
                e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>,
                checked: boolean
              ) => {
                setFieldValue(
                  'policeContacted',
                  radioButtonBooleanOptions.find(
                    (x) => x.name === (e.target as HTMLInputElement).value
                  )?.status ?? 1
                );
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              checked={o.status === values.policeContacted}
              value={o.name}
              control={<MHARadio size='small' />}
              label={o.name}
              data-cy='clientQuestionnaire_policeContacted'
            />
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid item xs={12}>
        Please provide any other information regarding the incident that caused
        the discharge:{' '}
      </Grid>
      <Grid item xs={4}>
        <TextField
          id='dischargeIncidentInformation'
          size='small'
          margin='none'
          value={values.dischargeIncidentInformation || ''}
          multiline
          name='dischargeIncidentInformation'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.dischargeIncidentInformation)}
          helperText={
            errors.dischargeIncidentInformation &&
            errors.dischargeIncidentInformation
          }
          data-cy='clientQuestionnaire_dischargeIncidentInformation'
          aria-label='Were there witnesses?'
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={12}>
        Was the claimant previously warned, either in writing or verbally, for
        these types of violations?
      </Grid>
      <Grid container item xs={12} direction='row'>
        {indicatorOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id='wasClaimantPreviouslyWarnedIndicatorId'
              name='wasClaimantPreviouslyWarnedIndicatorId'
              onChange={(
                e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>,
                checked: boolean
              ) => {
                setFieldValue(
                  'wasClaimantPreviouslyWarnedIndicatorId',
                  indicatorOptions.find(
                    (x) => x.name === (e.target as HTMLInputElement).value
                  )?.id ?? 1
                );
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              checked={o.id === values.wasClaimantPreviouslyWarnedIndicatorId}
              value={o.name}
              control={<MHARadio size='small' />}
              label={o.name}
              data-cy='clientQuestionnaire_wasClaimantPreviouslyWarned'
            />
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid item xs={12}>
        How was the claimant notified of their discharge from employment?
      </Grid>
      <Grid item md={12} xs={12} sm={12} lg={5}>
        <PaddedTextField
          id='dischargeNotificationId'
          size='small'
          margin='none'
          select
          value={values.dischargeNotificationId || ''}
          name='dischargeNotificationId'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.dischargeNotificationId)}
          helperText={
            errors.dischargeNotificationId && errors.dischargeNotificationId
          }
          data-cy='clientQuestionnaire_jobStatus'
          aria-label='Most Recent Job Status'
          fullWidth
          SelectProps={{ MenuProps: { disableScrollLock: true } }}
        >
          {dischargeNotificationOptions
            .filter((item) => item.name !== '')
            .map((option, index) => (
              <MenuItem key={option.name + index} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
        </PaddedTextField>
      </Grid>
      <Grid item xs={12}>
        Who (name and title) discharged the claimant?
      </Grid>
      <Grid item xs={4}>
        <TextField
          id='personWhoMadeDecisionToDischarge'
          size='small'
          margin='none'
          value={values.personWhoMadeDecisionToDischarge || ''}
          multiline
          label='Name'
          name='personWhoMadeDecisionToDischarge'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.personWhoMadeDecisionToDischarge)}
          helperText={
            errors.personWhoMadeDecisionToDischarge &&
            errors.personWhoMadeDecisionToDischarge
          }
          data-cy='clientQuestionnaire_adverseEffectsOnCompany'
          aria-label='Adverse effects on company'
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={4}>
        <TextField
          id='personWhoMadeDecisionTitle'
          size='small'
          margin='none'
          value={values.personWhoMadeDecisionTitle || ''}
          multiline
          label='Job Title'
          name='personWhoMadeDecisionTitle'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.personWhoMadeDecisionTitle)}
          helperText={
            errors.personWhoMadeDecisionTitle &&
            errors.personWhoMadeDecisionTitle
          }
          data-cy='clientQuestionnaire_adverseEffectsOnCompany'
          aria-label='Adverse effects on company'
          fullWidth
        />
      </Grid>
      <br />
    </React.Fragment>
  );
};

export default TheftComponent;
