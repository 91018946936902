import React from 'react';

import { Grid, Typography } from '@material-ui/core';

import { rootSelector } from '../../../common/selectors/selectors';
import Label from '../../../common/components/label';
//import * as Style from '../styles';
import { contactInit } from '../contacts.dtos';
import * as Styles from '../../../common/styles/styles';
import * as Style from '../contacts.styles';


const ContactHeader: React.FC = () => {

  const baseUrl = process.env.REACT_APP_API_URL ?? "";
  const baseLogoUrl = baseUrl + "/download/profile-image/";
  const systemContact = rootSelector(state => state.contacts.contact);

  const [dto, setDto] = React.useState(contactInit);
  const [logoImageUrl, setLogoImageUrl] = React.useState('');

  React.useEffect(() => {
    setDto(systemContact);
  }, [systemContact]);

  React.useEffect(() => {
    setLogoImageUrl((dto.profileImageName === undefined || dto.profileImageName.length === 0) ? '' : baseLogoUrl + dto.profileImageName);
  }, [dto, baseLogoUrl]);

  
  return <Styles.FeatureCardHeader
    avatar={
      <Style.HeaderAvatar src={logoImageUrl.length !== 0 ? logoImageUrl : "https://www.pngfind.com/pngs/m/110-1102775_download-empty-profile-hd-png-download.png"} alt="" />}
    title={
      <Grid container direction="row" item md={12} justifyContent="space-between" sm={12} lg={12} xs={12} data-cy="contact_profile_profileInformation" spacing={1}>

        <Grid item lg={3} sm={12} md={3} xs={12}>
          <Typography variant="subtitle1" >
            {dto.fullName}
          </Typography>
          {dto.title}
      
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Label>Email</Label>
          <Typography variant="body2" >
            {dto.primaryEmailAddress}
          </Typography>
        </Grid>
        <Grid item lg={3} sm={12} md={3} xs={12}>
          <Label>Phone</Label>
          <Typography variant="body2" >
            {dto.primaryPhoneNumber}
          </Typography>
        </Grid>
        <Grid  item lg={3} sm={12} md={3} xs={12}>
          <Label>Address</Label>
          {dto.addresses && dto.addresses[0] ?
            <>
              <Typography variant="body2" display="block">
                {dto.addresses![0].address1}
              </Typography>
              <Typography variant="body2" display="block">
                {dto.addresses![0].city} {dto.addresses![0].stateProvence} {dto.addresses![0].postalCode}
              </Typography>
            </>
            : null}
        </Grid>
      </Grid>
    }
  />
}

export default ContactHeader;
