import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors } from 'formik';

// material ui
import { Grid, TextField, FormControlLabel, } from '@material-ui/core';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ClientQuestionnaireDto, } from '../../dto';
import { MHARadio } from '../../../../common/styles/styles';
import { radioButtonBooleanOptions } from '../../constants/options';


interface SchoolEmployeeComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
};

const SchoolEmployeeComponent: React.FC<SchoolEmployeeComponentProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation
  }) => {

  return (
    <React.Fragment>
      <Grid item xs={12}>Was the claimant provide reasonable assurance about returning to work following the scheduled semester/holiday break?</Grid>
      <Grid container item xs={12} direction="row">
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="reasonableAssuranceGiven"
              name="reasonableAssuranceGiven"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('reasonableAssuranceGiven', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? 1);
                setShouldBlockNavigation(true);
              }}
              onBlur={e => { handleBlur(e); }}
              //add errors for validation
              checked={o.status === values.reasonableAssuranceGiven}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_reasonableAssuranceGiven" />
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid item xs={12}>What are the dates of the current break?</Grid>
      <Grid item xs={4}>
        <TextField
          id="currentBreakDates"
          size="small"
          margin="none"
          value={values.currentBreakDates || ''}
          multiline
          //label="Job Title"
          name="currentBreakDates"
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.currentBreakDates)}
          helperText={errors.currentBreakDates && errors.currentBreakDates}
          data-cy="clientQuestionnaire_currentBreakDates"
          aria-label="Adverse effects on company"
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={12}>On what date is the claimant expected to return to work?</Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <KeyboardDatePicker
          margin="normal"
          id="expectedReturnDate"
          name="expectedReturnDate"
          //label="Hire Date"
          format="MM/DD/yyyy"
          value={values.expectedReturnDate || null}
          data-cy="clientQuestionnaire_expectedReturnDate"
          fullWidth
          onChange={(date: MaterialUiPickersDate) => { setFieldValue('expectedReturnDate', date); setShouldBlockNavigation(true); }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <br />
      <Grid item xs={12}>Will claimant return in the same capacity? (job title, rate of pay, hours, etc.)</Grid>
      <Grid item xs={4}>
        <TextField
          id="willClaimantReturn"
          size="small"
          margin="none"
          value={values.willClaimantReturn || ''}
          multiline
          //label="Job Title"
          name="willClaimantReturn"
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.willClaimantReturn)}
          helperText={errors.willClaimantReturn && errors.willClaimantReturn}
          data-cy="clientQuestionnaire_willClaimantReturn"
          aria-label="Adverse effects on company"
          fullWidth
        />
      </Grid>
      <br />

    </React.Fragment>
  )
}

export default SchoolEmployeeComponent;
