import { AxiosError } from 'axios';

import apiClient from '../../../common/api/apiClient';
import { ApiError } from '../../../common/api/apiResponse';
import { UpdatePasswordDto } from './updatePassword.dtos';
import { showNotification } from '../../../common/actionCreators/dispatchNotification';

// endpoints
const updatePasswordUrl = '/account/update-password';

export const updatePasswordApi = async (request: UpdatePasswordDto) => {
  return await apiClient()
    .post(updatePasswordUrl, request)
    .then(() => {
      showNotification('Your password has been changed.', 'success');
      return true;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          if (axiosError.response.data.error !== undefined) {
            showNotification(axiosError.response.data.error.message, 'error');
          } else {
            showNotification(
              'An error occurred while changing password.',
              'error'
            );
          }
        }
      } else {
        showNotification('An error occurred while changing password.');
      }
      throw err;
    });
};
