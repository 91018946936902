import React from 'react';

import TabPanel from '../../../common/components/tabPanel';
import TaskTypeSettings from './components/taskRates';
import * as Style from './workOrders.styles';
import * as Styles from '../../../common/styles/styles';


const WorkOrderSettings: React.FC = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const changeTab = (event: any, newValue: number) => {
    setTabValue(newValue);
  }

  return <Style.WorkOrderSettingsGrid>
    <Styles.ClearAppBar position="static">
      <Styles.StyledTabs centered={true} value={tabValue} onChange={changeTab} aria-label="Work order settings options" variant="fullWidth" scrollButtons="auto">
              <Styles.StyledTab label="Tasks/Rates" data-cy="settings_workOrderTaskTab" />
        <Styles.StyledTab label="Placeholder" />
        <Styles.StyledTab label="Placeholder" />
      </Styles.StyledTabs>
    </Styles.ClearAppBar>
      <TabPanel value={tabValue} index={0}><TaskTypeSettings /></TabPanel>
    <TabPanel value={tabValue} index={1}></TabPanel>
    <TabPanel value={tabValue} index={2}></TabPanel>
  </Style.WorkOrderSettingsGrid>
}

export default WorkOrderSettings;