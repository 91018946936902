import * as Yup from 'yup';
import { requiredError } from '../../../common/constants/strings';

export const healthGroupValidationSchema = Yup.object().shape({
  name: Yup.string().max(200, 'Must be 200 characters or less').required(requiredError),
});

export const healthSystemValidationSchema = Yup.object().shape({
  healthSystemName: Yup.string().max(200, 'Must be 200 characters or less').required(requiredError),
  groups: Yup.array().of(healthGroupValidationSchema)
});


export interface HealthSystemGroupDto {
  id?: string;	//string($uuid) nullable: true
  name: string;	//string maxLength: 200 minLength: 0
  employers: string[];	//[]...] strings
  isActive: boolean;
};


export interface HealthSystemDto {
  healthSystemId?: string,	//string($uuid) nullable: true
  healthSystemName: string,	//string maxLength: 200 minLength: 0 
  groups: HealthSystemGroupDto[],	//[...] strings
  
  isActive: boolean,	//boolean
};

export const HealthSystemGroupinit: HealthSystemGroupDto = {
  name: '',	//string maxLength: 200 minLength: 0
  employers: [] as string[],	//[]...] strings
  isActive: true
}

export const HealthSystemInit: HealthSystemDto = {

  healthSystemName: '',
  groups: [] as HealthSystemGroupDto[],	//[...] strings
  isActive: true,
};

export const HealthSystemInit1: HealthSystemDto = {

  //healthSystemId: '1',
  healthSystemName: '',
  groups: [] as HealthSystemGroupDto[],	//[...] strings
  isActive: true,
};