import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors, } from 'formik';

// material ui
import { Grid, TextField } from '@material-ui/core';
import { ClientQuestionnaireDto, ClientQuestionnaireInit } from '../../dto';


interface FullTimeComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
}

const FullTimeComponent: React.FC<FullTimeComponentProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation
  }) => {

  return (
    <React.Fragment>
      <Grid item xs={12}>On average, how many hours does the claimant work each week?</Grid>
      <Grid item xs={4}>
        <TextField
          id="hoursWorked"
          size="small"
          margin="none"
          value={values.hoursWorked || ''}
          //label="Job Title"
          name="hoursWorked"
          multiline
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.hoursWorked)}
          helperText={errors.hoursWorked && errors.hoursWorked}
          data-cy="clientQuestionnaire_hoursWorked"
          aria-label="Number of hours worked each week"
          fullWidth
        />
      </Grid>
      <br />
    </React.Fragment>
  )
}

export default FullTimeComponent;
