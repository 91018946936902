import React from 'react';
import QRCode from "react-qr-code";
import {
  Dialog, DialogActions, Grid, DialogContent, DialogTitle, DialogContentText, Typography
} from '@material-ui/core';
import { ButtonGrid, StyledCancelButton} from '../../../common/styles/styles';

interface Props {
  open: boolean;
  onCancel: () => void;
}

const MfaHelpDialog: React.FC<Props> = (
  {
    open,
    onCancel,
  }) => {

  const cancelDialog = () => onCancel();

  return <Dialog
    open={open}
    maxWidth="sm"
    fullWidth
    data-cy="mfa-help-dialog"
  >
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <DialogTitle id="form-dialog-title">Authenticator App Help</DialogTitle>
        <DialogContent>
          <Grid container alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <DialogContentText>
                An authenticator app is an app that adds two-factor authentication as an additional check to confirm your identity.
                Use your mobile device to install an authenticator app, then scan the QR code to retrieve your validation code.
                Entering a valid validation code will complete the authenticator app registration and log you into your session.
              </DialogContentText>
              <small><Typography>For help finding an authentication app visit you app store and search for "authenticator app" or scan the appropriate QR code below.</Typography></small>
            </Grid>

            <Grid item xs={6}>
              <DialogContentText>Apple</DialogContentText>
              <QRCode value={`https://apple.co/2ZIaCfm`} size={128} />
            </Grid>
            <Grid item xs={6}>
              <DialogContentText>Android</DialogContentText>
              <QRCode value={`https://play.google.com/store/apps/details?id=com.azure.authenticator`} size={128} />
            </Grid>
          </Grid>
          <DialogActions>
            <ButtonGrid container>
              <StyledCancelButton
                onClick={cancelDialog}
                data-cy="mfa-help-cancelButton"
              >Back
              </StyledCancelButton>
            </ButtonGrid>
          </DialogActions>
        </DialogContent>
      </Grid>
    </Grid>
  </Dialog>
}

export default MfaHelpDialog;
