import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Checkbox, Grid, Typography, Link } from '@material-ui/core';
import * as Styles from '../../../common/styles/styles';
import * as CompanyStyles from '../styles';
import { CompanyDto } from '../dtos';


interface Props {
  company: CompanyDto
}

const CompanyListCard: React.FC<Props> = ({ company }) => {

  return (
    <Grid item xs={12}>
      <Styles.TableCard raised>
        <Styles.ListCardContent>
          <Grid container>
            <div hidden data-cy={`employer_list_card_id_${company.name}`}>{company.companyId}</div>
   

            {/* Contact */}
            <Grid item xs={6} sm={2}>
             
              <Link component={RouterLink} to={`/employers/${company.companyId}`} variant="subtitle1"
                color="secondary" data-cy={`employer_list_card_link_${company.name}`}>
                {company.name?.toLocaleUpperCase()}
              </Link>
            </Grid>

            {/* Phone */}
            <Grid item xs={6} sm={2}>
              <Typography variant="body2" color="textPrimary">Phone</Typography>
              <Typography variant="body2" color="secondary">{company.primaryPhoneNumber || "None on File"}</Typography>
            </Grid>

            {/* Email */}
            <Grid item xs={6} sm={2}>
              <Typography variant="body2" color="textPrimary">Email</Typography>
              <Typography variant="body2" color="secondary">
                {(company.primaryEmailAddress &&
                  <a href={`mailto:${company.primaryEmailAddress}`} data-cy={`employer_list_card_email_${company.name}`}>
                    {company.primaryEmailAddress?.toLocaleUpperCase()}
                  </a>) || "None on File"}
              </Typography>
            </Grid>
            {/*Health System*/}
            <Grid item xs={6} sm={2}>
              <Typography variant="body2" color="textPrimary">Health System</Typography>
              <Typography variant="body2" color="secondary">{company.healthSystem?.toLocaleUpperCase() || "None on File"}</Typography>
            </Grid>
            {/*Group */}
            <Grid item xs={6} sm={2}>
              <Typography variant="body2" color="textPrimary">Group</Typography>
              <Typography variant="body2" color="secondary">{company.group?.toLocaleUpperCase() || "None on File"}</Typography>

            </Grid>

            {/* Active checkbox */}
            <Grid item xs={6} sm={2}>
              <Styles.CardCheckbox
                value={company.isActive}
                checked={company.isActive}
                control={<Checkbox size="small" />}
                label={<Typography variant="subtitle2">Active</Typography>}
                labelPlacement="top"
              />
            </Grid>
          </Grid>
        </Styles.ListCardContent>
      </Styles.TableCard>
    </Grid>
  )
};

export default CompanyListCard;
