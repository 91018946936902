import React from "react";
import { ExecutiveSummaryWithIndividualBreakdownsReportParametersDto } from "../dtos";
import { FormikErrors, FormikHandlers, FormikHelpers, getIn } from "formik";
import {
  Grid,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { BusinessUnitDto, CompanyHsDto } from "../../company/dtos";
import {
  getHealthGroups,
  getHealthGroupsFromMultipleSystems,
  getReportGroups,
} from "../../../utils/reportHelperFunctions";
import { Autocomplete } from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { PaddedTextField } from "../../../common/styles/styles";

interface Props extends React.HTMLProps<React.ReactNode> {
  values: ExecutiveSummaryWithIndividualBreakdownsReportParametersDto;
  errors: FormikErrors<ExecutiveSummaryWithIndividualBreakdownsReportParametersDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ExecutiveSummaryWithIndividualBreakdownsReportParametersDto>["setFieldValue"];
  heathSystems: string[];
  companies: CompanyHsDto[];
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ExecutiveSummaryWithIndividualBreakdownsReportParameters: React.FC<
  Props
> = ({
  values,
  errors,
  handleBlur,
  setFieldValue,
  handleChange,
  heathSystems,
  companies,
}) => {
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <KeyboardDatePicker
          id="startDatetimeParameter"
          name="startDatetimeParameter"
          size="small"
          margin="none"
          format="MM/DD/yyyy"
          value={values.startDatetimeParameter || null}
          label="Start Date"
          onChange={(date: MaterialUiPickersDate) =>
            setFieldValue("startDatetimeParameter", date)
          }
          onBlur={handleBlur}
          error={Boolean(errors.startDatetimeParameter)}
          helperText={
            Boolean(errors.startDatetimeParameter) &&
            errors.startDatetimeParameter
          }
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6}>
        <KeyboardDatePicker
          id="endDatetimeParameter"
          name="endDatetimeParameter"
          size="small"
          margin="none"
          format="MM/DD/yyyy"
          value={values.endDatetimeParameter || null}
          label="End Date"
          onChange={(date: MaterialUiPickersDate) =>
            setFieldValue("endDatetimeParameter", date)
          }
          onBlur={handleBlur}
          error={Boolean(errors.endDatetimeParameter)}
          helperText={
            Boolean(errors.endDatetimeParameter) && errors.endDatetimeParameter
          }
          fullWidth
        />
      </Grid>

      {values.groups.map((g, i) =>
        i < 1 ? (
          <React.Fragment key={"healthsystems_" + i}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Autocomplete
                id={`healthSystems`}
                options={heathSystems.filter((f) => f.length !== 0)}
                getOptionLabel={(o: string) => o ?? ""}
                renderOption={(option) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={values.healthSystems.includes(option)}
                    />
                    {option}
                  </React.Fragment>
                )}
                disableCloseOnSelect
                multiple
                value={heathSystems
                  .filter((f) => f.length !== 0)
                  .filter((f) => values.healthSystems.includes(f))}
                onChange={(_e: any, v: string[] | null) => {
                  //handleChange(_e);
                  setFieldValue(`groups[${i}]`, []);
                  setFieldValue(`healthGroups`, []);

                  let ids: string[] = [];
                  v?.forEach((c) => ids.push(c ?? ""));
                  setFieldValue(`healthSystems`, ids);
                }}
                renderInput={(params) => (
                  <PaddedTextField
                    {...params}
                    size="small"
                    name={`healthSystems`}
                    margin="none"
                    aria-label="Health System(s)"
                    label="Health System(s)"
                    fullWidth
                    error={Boolean(errors.healthSystems)}
                    helperText={
                      Boolean(errors.healthSystems) && errors.healthSystems
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6}>
              <Autocomplete
                id={`healthGroups`}
                options={getHealthGroupsFromMultipleSystems(
                  companies,
                  values.healthSystems
                )}
                getOptionLabel={(o: string) => o ?? ""}
                renderOption={(option) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={values.healthGroups.includes(option)}
                    />
                    {option}
                  </React.Fragment>
                )}
                disableCloseOnSelect
                multiple
                value={getHealthGroupsFromMultipleSystems(
                  companies,
                  values.healthSystems
                ).filter(
                  (f) => values.healthGroups.includes(f) && f.length !== 0
                )}
                onChange={(_e: any, v: string[] | null) => {
                  let ids: string[] = [];
                  v?.forEach((c) => ids.push(c ?? ""));
                  setFieldValue(`healthGroups`, ids);
                  setFieldValue(`groups[${i}]`, []);
                }}
                renderInput={(params) => (
                  <PaddedTextField
                    {...params}
                    size="small"
                    name={`healthGroups`}
                    margin="none"
                    aria-label="Health System Group(s)"
                    label="Health System Group(s)"
                    fullWidth
                  />
                )}
              />
            </Grid>

            {values.healthGroups[i]?.length !== 0 && (
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Autocomplete
                  id={"selectedEmployers" + i}
                  options={
                    Array.from(
                      new Set(
                        companies
                          .filter(
                            (c) =>
                              values.healthSystems[i] === "All-MHA" ||
                              values.healthSystems.includes(c.healthSystem)
                          )
                          .filter((c) => values.healthGroups.includes(c.group))
                      )
                    ) as CompanyHsDto[]
                  }
                  getOptionLabel={(o: CompanyHsDto) => o.name ?? ""}
                  renderOption={(option) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={g.includes(option.companyId ?? "")}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  disableCloseOnSelect
                  //disabled={values.healthSystems[i] === 'All-MHA'}
                  multiple
                  value={Array.from(new Set(companies)).filter((o) =>
                    values.groups[i].map((m) => m).includes(o.companyId ?? "")
                  )}
                  onChange={(_e: any, v: CompanyHsDto[] | null) => {
                    let ids: string[] = [];
                    v?.forEach((c) => ids.push(c.companyId ?? ""));
                    setFieldValue(`groups[${i}]`, ids);
                  }}
                  renderInput={(params) => (
                    <PaddedTextField
                      {...params}
                      name={"selectedEmployers" + i}
                      size="small"
                      margin="none"
                      aria-label="Employers"
                      label="Employers"
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}

            {values.groups[i]?.length !== 0 &&
              values.healthGroups[i]?.length !== 0 && (
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Autocomplete
                    id={`selectBusinessUnits-${i}`}
                    getOptionLabel={(option) => option.name}
                    options={[...companies]
                      .filter((company) =>
                        values.groups[i]
                          .map((group) => group)
                          .includes(company.companyId!)
                      )
                      .flatMap((company) =>
                        company.businessUnitList
                          .filter((businessUnit) => businessUnit.isActive)
                          .map((businessUnit) => businessUnit)
                      )}
                    renderOption={(option) => (
                      <React.Fragment>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={(values.businessUnits ?? []).includes(
                            option.id!
                          )}
                        />
                        {option.name}
                      </React.Fragment>
                    )}
                    disableCloseOnSelect
                    multiple
                    value={[...companies]
                      .filter((company) =>
                        company.group.includes(values.healthGroups[i])
                      )
                      .flatMap((company) =>
                        company.businessUnitList.map(
                          (businessUnit) => businessUnit
                        )
                      )
                      .filter((businessUnit) =>
                        (values.businessUnits ?? []).includes(businessUnit.id!)
                      )}
                    onChange={(_e: any, value: BusinessUnitDto[] | null) => {
                      setFieldValue(
                        "businessUnits",
                        value?.map((businessUnit) => businessUnit.id)
                      );
                    }}
                    renderInput={(params) => (
                      <PaddedTextField
                        {...params}
                        aria-label="Business Units"
                        fullWidth
                        id={`selectedBusinessUnits-${i}`}
                        label="Business Units"
                        margin="none"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              )}

            {/* {values.healthGroups[i]?.length !== 0 &&
              values.groups[i]?.length == 0 && (
                // This Grid exists to ensure that the EmployeeId field appears on its own row
                <Grid item xs={12} lg={6}></Grid>
              )}

            <Grid item xs={12} lg={6}>
              <PaddedTextField
                id="employeeId"
                name="employeeId"
                size="small"
                margin="none"
                label="Employee ID"
                value={values.employeeId || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.employeeId)}
                helperText={errors.employeeId && errors.employeeId}
                fullWidth
              />
            </Grid> */}
          </React.Fragment>
        ) : null
      )}

      <Grid item xs={12} sm={4}>
        <FormControlLabel
          name="excludeFiledPriorToPOA"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.excludeFiledPriorToPOA || false}
          checked={values.excludeFiledPriorToPOA || false}
          control={
            <Checkbox
              value={values.excludeFiledPriorToPOA || false}
              style={{ paddingRight: 6 }}
              size="small"
              data-cy="esrParams_excludeFiledPriorToPOA"
            />
          }
          label={
            <Typography variant="subtitle2">
              Exclude Claims Filed Prior to Power of Attorney / Representation
            </Typography>
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormControlLabel
          name="excludeSecondYearClaim"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.excludeSecondYearClaim || false}
          checked={values.excludeSecondYearClaim || false}
          control={
            <Checkbox
              value={values.excludeSecondYearClaim || false}
              style={{ paddingRight: 6 }}
              size="small"
              data-cy="esrParams_excludeSecondYearClaim"
            />
          }
          label={
            <Typography variant="subtitle2">
              Exclude Second Year Claims
            </Typography>
          }
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormControlLabel
          name="hideInvestigations"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.hideInvestigations || false}
          checked={values.hideInvestigations || false}
          control={
            <Checkbox
              value={values.hideInvestigations || false}
              style={{ paddingRight: 6 }}
              size="small"
              data-cy="esrParams_hideInvestigations"
            />
          }
          label={
            <Typography variant="subtitle2">
              Hide Summary of Administrative Hearings & Appeals
            </Typography>
          }
        />
      </Grid>
    </>
  );
};

export default ExecutiveSummaryWithIndividualBreakdownsReportParameters;
