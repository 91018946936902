import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors, getIn } from 'formik';

// material ui
import { Grid, TextField, MenuItem, FormControlLabel, Checkbox, Typography, } from '@material-ui/core';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { ContactDto } from '../contacts.dtos';
import { EmailDto } from '../../../common/dtos/email';
import { TextSelect } from '../contacts.styles';


interface ContactEmailProps extends React.HTMLProps<React.ReactNode> {
  email: EmailDto;
  errors: FormikErrors<ContactDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ContactDto>["setFieldValue"];
  emails: EmailDto[];
  index: number;
  setShouldBlockNavigation: (o: boolean) => void;
  isReadOnly: boolean;
}

const ContactEmail: React.FC<ContactEmailProps> = (
  {
    email,
    errors,
    handleChange,
    handleBlur,
    index,
    emails,
    setFieldValue,
    setShouldBlockNavigation,
    isReadOnly
  }) => {

  const handlePrimaryEmails = () => {
    for (let i = 0; i < emails.length; i++) {
      if (i !== index) {
        setFieldValue(`emails[${i}].isPrimary`, false);
      }
    }
  }

  return (
    <React.Fragment key={'emailFragment' + index}>
      <Grid item xs={12} sm={12} md={12} lg={3} key={'email_contactTypeId' + index} >
        <TextSelect
          id={`emails[${index}].contactTypeId`}
          name={`emails[${index}].contactTypeId`}
          value={email.contactTypeId || 9}
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          select
          disabled={isReadOnly}
          aria-label="Contact Type Select"

          style={{ paddingBottom: 10 }}
          data-cy={`contact_email_${index}_emailTypeDropDown`}
          fullWidth
        >
          <MenuItem value={0} disabled>Please select an item</MenuItem>
          <MenuItem value={11}>Billing</MenuItem>
          <MenuItem value={8}>Home</MenuItem>
          <MenuItem value={10}>Other</MenuItem>
          <MenuItem value={9}>Work</MenuItem>
        </TextSelect>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={9} key={'email' + index}>
        <TextField
          key={'email_field' + index}
          id={'emailAddress' + index}
          size="small"
          margin="none"
          value={email.emailAddress || ''}
          label="Email Address"
          aria-label="Email Input"
          name={`emails[${index}].emailAddress`}
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          disabled={isReadOnly}
          error={Boolean(getIn(errors, `emails[${index}].emailAddress`))}
          helperText={getIn(errors, `emails[${index}].emailAddress`) && getIn(errors, `emails[${index}].emailAddress`)}
          data-cy={`contact_email_${index}_emailAddressInput`}
          fullWidth
        />
      </Grid>
      <Grid item xs={2} sm={10} hidden={emails.length === 1} />
      <Grid item xs={10} sm={2} hidden={emails.length === 1}>
        <FormControlLabel
          id={`emails[${index}].isPrimary`}
          name={`emails[${index}].isPrimary`}
          aria-label="Email Is Primary Checkbox"
          onChange={(e) => { handleChange(e); handlePrimaryEmails(); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          value={email.isPrimary}
          disabled={isReadOnly}
          checked={email.isPrimary}
          control={
            <Checkbox
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<RadioButtonCheckedIcon />}
              size="small"
              data-cy={`contact_email_${index}_emailPrimaryInput`}
          />}
          label={<Typography variant="subtitle2">Primary</Typography>}
        />
      </Grid>
    </React.Fragment>
  )
}

export default ContactEmail;
