import { AxiosError } from 'axios';

import { ApiError } from '../../../common/api/apiResponse';
import apiClient from '../../../common/api/apiClient';
import { ProfileDto } from './profile.dtos';
import { showNotification } from '../../../common/actionCreators/dispatchNotification';


// See other Api files for examples.
// endpoints
const profileUrl = '/';
const countriesUrl = '/countries';
const governingDistrictsUrl = '/governing-districts';

export const getProfileApi = async () => {
  return await apiClient().get(profileUrl)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, 'error');
        }
      }
      else {
        showNotification('An error occurred while retrieving page template.', 'error');
      }
      throw err;
    });
};

export const updateProfileApi = async (request: ProfileDto) => {
  return await apiClient().put(profileUrl, request)
    .then((res) => {
      showNotification('page template saved.', 'success');
      return res.data.result;
    })
    .catch((err) => {
      
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, 'error');
        }
      }
      else {
        showNotification('An error occurred while updating page template.', 'error');
      }
      throw err;
    });
};

export const createProfileApi = async (request: ProfileDto) => {
  return await apiClient().put(profileUrl, request)
    .then((res) => {
      showNotification('page template saved.', 'success');
      return res.data.result;
    })
    .catch((err) => {
      
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, 'error');
        }
      }
      else {
        showNotification('An error occurred while creating page template.', 'error');
      }
      throw err;
    });
};

export const getCountriesApi = async () => {
  return await apiClient().get(countriesUrl)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, 'error');
        }
      }
      else {
        showNotification('An error occurred while retrieving page template.', 'error');
      }
      throw err;
    });
};

export const getGoverningDistrictsApi = async () => {
  return await apiClient().get(governingDistrictsUrl)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, 'error');
        }
      }
      else {
        showNotification('An error occurred while retrieving page template.', 'error');
      }
      throw err;
    });
};