import React from 'react';
import { Prompt } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Checkbox, Dialog, DialogContent, DialogTitle, DialogActions, FormControlLabel,
  Grid, MenuItem, TextField,
  Typography
} from '@material-ui/core';
import { getType } from 'typesafe-actions';
import { useDispatch } from 'react-redux';
import { SeparationCodeDto } from '../codes.dtos'
import { createSeparationCodesActions, updateSeparationCodesActions } from '../codes.actions';
import { requiredError } from '../../../../common/constants/strings';
import { ButtonGrid, StyledSubmitButton, StyledCancelButton } from '../../../../common/styles/styles';
import { DialogCardContent, DialogInputLabel } from '../codes.styles';


interface Props {
  dto: SeparationCodeDto;
  open: boolean;
  onCancel: () => void;
  isReadOnly: boolean;
}

const SeparationCodeDialog: React.FC<Props> = ({ dto, onCancel, isReadOnly }) => {

  const [dialogOpen, setDialogOpen] = React.useState(true);

  const codeTypeOptions = [
    { id: 1, name: 'Discharge' }, { id: 2, name: 'ID Theft' }, { id: 3, name: 'Layoff/Reduced Hours' }, { id: 4, name: 'Leave of Absence' }, { id: 5, name: "Resignation" }
  ];
  const claimTypeOptions = [{ id: 0, name: 'Please select an option' }, { id: 1, name: 'Economic' }, { id: 2, name: 'Non-Economic' }];

  const cancelDialog = () => onCancel();

  const dispatch = useDispatch();



  return <Formik
    initialValues={dto}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={
      Yup.object({
        isActive: Yup.boolean().required(requiredError),
        description: Yup.string().max(300, "Must be 300 characters or less").required(requiredError),
        name: Yup.string().max(300, "Must be 300 characters or less").required(requiredError),
        separationCodeTypeId: Yup.number().min(1, requiredError).required(requiredError),
        codeClaimTypeId: Yup.number().min(1, requiredError).required(requiredError)
      })
    }
    onSubmit={(values, { setSubmitting, resetForm }) => {
      setTimeout(() => {
        setSubmitting(false);
        resetForm();

      }, 700);
      dispatch({
        type: values.id ?
          getType(updateSeparationCodesActions.request) :
          getType(createSeparationCodesActions.request),
        payload: [values]
      });
      setDialogOpen(false);
    }}
  >
    {(props) => {
      const {
        values,
        touched,
        errors,
        isSubmitting,
        setFieldValue,
        handleBlur,
        handleChange,
        handleSubmit,
      } = props;
      return (
        <Dialog
          open={dialogOpen}
          data-cy="separation_code_addInteraction_dialog"
        >
          <DialogTitle>Separation Code Information</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <DialogCardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      id="isActive"
                      name="isActive"
                      onChange={handleChange}
                      value={values.isActive}
                      checked={values.isActive}
                      control={
                        <Checkbox
                          size="small"
                          disabled={isReadOnly}
                          data-cy="separation_code_isActiveCheckbox"
                        />
                      }
                      label={
                        <Typography variant="subtitle2">Active</Typography>
                      }
                    />
                  </Grid>


                  <Grid item xs={12}>
                    <DialogInputLabel>* Code</DialogInputLabel>
                    <TextField
                      id="name"
                      name="name"
                      onChange={handleChange}
                      fullWidth
                      InputLabelProps={{ required: true }}
                      value={values.name || ''}
                      data-cy="separation_code_nameInput"
                      onBlur={handleBlur}
                      disabled={isReadOnly}
                      error={Boolean(errors.name)}
                      helperText={errors.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DialogInputLabel>* Description</DialogInputLabel>
                    <TextField
                      id="description"
                      name="description"
                      onChange={handleChange}
                      fullWidth
                      InputLabelProps={{ required: true }}
                      value={values.description || ''}
                      data-cy="separation_code_descriptionInput"
                      onBlur={handleBlur}
                      disabled={isReadOnly}
                      error={Boolean(errors.description)}
                      helperText={errors.description}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <DialogInputLabel>* Code Type</DialogInputLabel>
                    <TextField
                      id="separationCodeTypeId"
                      size="small"
                      margin="none"
                      select
                      disabled={isReadOnly}
                      InputLabelProps={{ required: true }}
                      value={values.separationCodeTypeId || 0}
                      name="separationCodeTypeId"
                      onChange={handleChange}
                      onBlur={e => { handleBlur(e) }}
                      error={(Boolean(errors.separationCodeTypeId && touched.separationCodeTypeId))}
                      helperText={(Boolean(errors.separationCodeTypeId) && touched.separationCodeTypeId) && errors.separationCodeTypeId}
                      data-cy="separation_code_codeTypeInput"
                      fullWidth
                    >
                      {codeTypeOptions.map((option, index) => (
                        <MenuItem key={option.name + index} value={option.id}>{option.name}</MenuItem>
                      ))}
                    </TextField>

                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <DialogInputLabel>* Claim Type</DialogInputLabel>
                    <TextField
                      id="codeClaimTypeId"
                      size="small"
                      margin="none"
                      select
                      disabled={isReadOnly}
                      InputLabelProps={{ required: true }}
                      value={values.codeClaimTypeId || 0}
                      name="codeClaimTypeId"
                      onChange={handleChange}
                      onBlur={e => { handleBlur(e) }}
                      error={(Boolean(errors.codeClaimTypeId && touched.codeClaimTypeId))}
                      helperText={(Boolean(errors.codeClaimTypeId) && touched.codeClaimTypeId) && errors.codeClaimTypeId}
                      data-cy="separationCode_claimTypeInput"
                      fullWidth
                    >
                      {claimTypeOptions.map((option, index) => (
                        <MenuItem key={option.name + index} value={option.id}>{option.name}</MenuItem>
                      ))}
                    </TextField>

                  </Grid>
                </Grid>


              </DialogCardContent>

              <DialogActions>
                <ButtonGrid container>
                  <StyledSubmitButton
                    type="submit"
                    color="primary"
                    disabled={isSubmitting || isReadOnly}
                    data-cy="separation_code_submitButton"
                  >
                    Save
                  </StyledSubmitButton>

                  <StyledCancelButton
                    data-cy="separation_code_cancelButton"
                    color="secondary"
                    onClick={cancelDialog}
                  >
                    Cancel
                  </StyledCancelButton>
                </ButtonGrid>
              </DialogActions>
            </form>
          </DialogContent>
          <Prompt
            message='You have unsaved edits, are you sure you want to leave?'
          />
        </Dialog>
      );
    }}
  </Formik>
}

export default SeparationCodeDialog;
