import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog, Grid, DialogActions, DialogContent
} from '@material-ui/core';
import { StyledCardContent, ButtonGrid, StyledCancelButton } from '../../../common/styles/styles';
import AssociateClaim from './associateClaim';
import { ListClaimDto } from '../claims.dtos';
import { getType } from 'typesafe-actions';
import { deleteClaimActions } from '../actions';


interface Props {
  open: boolean;
  onCancel: () => void;
  isReadOnly: boolean;
  claim: ListClaimDto;
}

const DeleteClaimDialog: React.FC<Props> = ({
  open,
  onCancel,
  isReadOnly,
  claim
}) => {
  const dispatch = useDispatch();

  const [isMasked, setIsMasked] = React.useState(false);

  const handleSSNMaskToggle = () => setIsMasked(!isMasked);

  const handleGetMaskedSSNValue = (ssn?: string) => {
    if (!!ssn) {
      let v = ssn ?? "";
      if (v.replace("-", "").length > 3) {
        return "***-**-" + v.replace("-", "").slice(-4);
      } else {
        return "***-**-";
      }
    }
  };

  const handleAssociate = (id: string) => {
    dispatch({ type: getType(deleteClaimActions.request), payload: [claim.id, id] });
  };


  return <Dialog
    open={open}
    data-cy="delete_dialog"
    fullWidth
    maxWidth="md"
  >
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <DialogContent>
          <StyledCardContent>
            <AssociateClaim
              handleAssociate={handleAssociate}
              handleDeassociate={() => { }}
              attached={false}
              isReadOnly={isReadOnly}
              handleGetMaskedSSNValue={handleGetMaskedSSNValue}
              handleSSNMaskToggle={handleSSNMaskToggle}
              isMasked={isMasked}
              hideNewClaim={false}
              claim={claim}
            />
          </StyledCardContent>

          <DialogActions>
            <ButtonGrid container>
              <StyledCancelButton
                data-cy="delete_dialog_cancelButton"
                color="secondary"
                onClick={onCancel}
              >
                Close
              </StyledCancelButton>
            </ButtonGrid>
          </DialogActions>
        </DialogContent>
      </Grid>
    </Grid>
  </Dialog>
}

export default DeleteClaimDialog;
