import React from 'react';
import { FormikHandlers, FormikErrors, FormikHelpers, getIn } from 'formik';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
// material ui
import { Grid, CardContent } from '@material-ui/core';
import { getCompaniesActions } from '../../../company/actions';
import { getGoverningDistrictsActions } from '../../../account/profile/profile.actions';
import { NavCard, NavOptionsGrid } from '../../documents.styles';
import { NotWorkingCodes_class } from '../../../../common/enums/NotWorkingCodes';
import { IMc } from '../../../../common/dtos/McDto';
import IndicatorTypeField from '../mcFieldComponents/IndicatorTypeField';
import McSelectOptions from '../mcFieldComponents/McSelectOptions';
import { isNotWorkingOnAssignment, isRelationshipEndedInd, isWorkingNewAssignmentInd } from '../../../../utils/mcRequiredPropFunctions';
import { opEmployerReportedDependents } from '../../../../utils/mcOptionalPropFunctions';
import McTextField from '../mcFieldComponents/McTextField';
import MoneyField from '../../../../common/components/numberFields';
import { SidesPagination } from '../../../../common/styles/styles';
import DigitStringField from '../../../../common/components/digitStringField';
import Label from '../../../../common/components/label';


interface Props extends React.HTMLProps<React.ReactNode> {
  values: IMc;
  errors: FormikErrors<IMc>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<IMc>["setFieldValue"];
  isReadOnly: boolean;
}

const McMisc: React.FC<Props> = (
  {
    values,
    errors,
    handleChange,
    isReadOnly,
    handleBlur,
    setFieldValue,
  }) => {
  const dispatch = useDispatch();
  const [contactTypePageNumber, setContactTypePageNumber] = React.useState(0);
  //React.useEffect(() => { console.log('errors', errors); }, [errors]);


  React.useEffect(() => {
    dispatch({ type: getType(getCompaniesActions.request) });
    dispatch({ type: getType(getGoverningDistrictsActions.request) });
  }, [dispatch]);

  React.useEffect(() => {
    if (!isRelationshipEndedInd(values) && values.relationshipEndedInd !== undefined) setFieldValue('relationshipEndedInd', undefined);
    if (!isWorkingNewAssignmentInd(values) && values.workingNewAssignmentInd !== undefined) setFieldValue('workingNewAssignmentInd', undefined);
    if (!isNotWorkingOnAssignment(values) && values.notWorkingOnAssignment !== undefined) setFieldValue('notWorkingOnAssignment', undefined);
    if (!opEmployerReportedDependents(values) && values.employerReportedDependents !== undefined) setFieldValue('employerReportedDependents', undefined);
    
  }, [values]);

  const handleGetErrors = (list: any[], listName: string) => {
    let errorIndexList = [];
    for (let i = 0; i < (list.length ?? 0); i++) {
      if (Boolean(getIn(errors, `${listName}[${i}]`)))
        errorIndexList.push(i);
    }

    return errorIndexList;
  };

  return (
    <Grid container spacing={2} data-cy="sidesResponse_generalInformation_container">
      <NavOptionsGrid item xs={6}>
        <NavCard>
          <CardContent>
            <Grid container item xs={12} spacing={2}>

              {opEmployerReportedDependents(values) &&
                <McTextField
                  label={"Employer reported dependents (0-9)"}
                  name={`employerReportedDependents`}
                  value={values.employerReportedDependents ?? ''}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  inputprops={{ inputComponent: DigitStringField as any }}
                  max={1}
                />
              }

              {isRelationshipEndedInd(values) &&
                <IndicatorTypeField
                  label={"Has the relationship ended between the agency and the claimant?"}
                  name={`relationshipEndedInd`}
                  value={values.relationshipEndedInd}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                />
              }
              {isWorkingNewAssignmentInd(values) &&
                <IndicatorTypeField
                  label={"Is the claimant currently working a new assignment?"}
                  name={`workingNewAssignmentInd`}
                  value={values.workingNewAssignmentInd}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                />
              }

              {isNotWorkingOnAssignment(values) &&
                <McSelectOptions
                  label={"Why is the claimant not working on an assignment?"}
                  name={`notWorkingOnAssignment`}
                  value={values.notWorkingOnAssignment}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  options={NotWorkingCodes_class.enumAsSelectOptions}
                />
              }

            </Grid>
          </CardContent>
        </NavCard>

      </NavOptionsGrid>

      {/* Employer Charge */}
      <NavOptionsGrid item xs={6}>
        <NavCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={10} lg={10}><Label>Employer Charges</Label></Grid>
              {
                (values.uisMcEmployerCharges && values.uisMcEmployerCharges?.length !== 0) &&
                <>
                  <McTextField
                    label={"Begin Week #"}
                    name={`values.uisMcEmployerCharges[${contactTypePageNumber - 1}]?.beginWeekNumber`}
                    value={values.uisMcEmployerCharges[contactTypePageNumber - 1]?.beginWeekNumber ?? ''}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={true}
                    inputprops={{ inputComponent: DigitStringField as any }}
                    max={2}
                  />

                  <McTextField
                    label={"End Week #"}
                    name={`values.uisMcEmployerCharges[${contactTypePageNumber - 1}]?.endWeekNumber`}
                    value={values.uisMcEmployerCharges[contactTypePageNumber - 1]?.endWeekNumber ?? ''}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={true}
                    inputprops={{ inputComponent: DigitStringField as any }}
                    max={2}
                  />

                  <McTextField
                    label={"Total Weekly Employer Charge"}
                    name={`values.uisMcEmployerCharges[${contactTypePageNumber - 1}]?.totalWeeklyEmployerCharge`}
                    value={values.uisMcEmployerCharges[contactTypePageNumber - 1]?.totalWeeklyEmployerCharge?.toString() ?? ''}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={true}
                    inputprops={{ inputComponent: MoneyField as any }}
                    max={999999}
                  />

                  <McTextField
                    label={"Potential Weeks Employer Charge"}
                    name={`values.uisMcEmployerCharges[${contactTypePageNumber - 1}]?.potentialWeeksEmployerCharge`}
                    value={values.uisMcEmployerCharges[contactTypePageNumber - 1]?.potentialWeeksEmployerCharge?.toString() ?? ''}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={true}
                    inputprops={{ inputComponent: MoneyField as any }}
                    max={99999}
                  />

                  <SidesPagination
                    hidden={values.uisMcEmployerCharges && values.uisMcEmployerCharges?.length === 0}
                    count={values.uisMcEmployerCharges?.length}
                    variant="outlined"
                    color="primary"
                    size="small"
                    page={contactTypePageNumber}
                    errorindex={handleGetErrors(values.uisMcEmployerCharges, 'uisMcEmployerCharges')}
                    onChange={(e, p) => { setContactTypePageNumber(p); }} />
                </>
              }

            </Grid>
          </CardContent>
        </NavCard>
        
      </NavOptionsGrid>
    </Grid>
  )
}

export default McMisc;
