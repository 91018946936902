


import { ISelectOption } from "../dtos/SelectOptionDto";
enum NotReturningCodes {
        Item2 = 0,
        Item3 = 1,
        Item5 = 2,
        Item6 = 3,
        Item14 = 4,
        Item16 = 5,
        Item18 = 6,
        Item19 = 7,
        Item99 = 8,
         
}

export class NotReturningCodes_class {

public static enum = NotReturningCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['Item2']: 0,
    ['Item3']: 1,
    ['Item5']: 2,
    ['Item6']: 3,
    ['Item14']: 4,
    ['Item16']: 5,
    ['Item18']: 6,
    ['Item19']: 7,
    ['Item99']: 8,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "2- Laid Off/Lack of Work",
    [1]: "3- Fired/Discharged",
    [2]: "5- Asked to Resign",
    [3]: "6- Voluntary Quit",
    [4]: "14- Retirement",
    [5]: "16- Labor Dispute",
    [6]: "18- Disaster Related ",
    [7]: "19- Not Listed Above",
    [8]: "99- Refuse to Provide",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "2- Laid Off/Lack of Work"},
  {value: 1, text: "3- Fired/Discharged"},
  {value: 2, text: "5- Asked to Resign"},
  {value: 3, text: "6- Voluntary Quit"},
  {value: 4, text: "14- Retirement"},
  {value: 5, text: "16- Labor Dispute"},
  {value: 6, text: "18- Disaster Related "},
  {value: 7, text: "19- Not Listed Above"},
  {value: 8, text: "99- Refuse to Provide"},
  ]
}

