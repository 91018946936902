import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, FormikHandlers, FormikHelpers, FormikErrors } from 'formik';
import { getType } from 'typesafe-actions';
// material ui
import {
  Grid,
  Card,
  CardHeader,
  Box,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import {
  ClientQuestionnaireDto,
  ClientQuestionnaireInit,
  questionnaireValidationSchema,
  SeparationPaymentInit,
} from './dto';
import { rootSelector } from '../../common/selectors/selectors';
import ResignationComponent from './formComponents/resignationComponent';
import DischargeComponent from './formComponents/dischargeComponent';
import LackOfWorkComponent from './formComponents/lackOfWorkComponent';
import OtherOptionComponent from './formComponents/otherOptionComponent';
import NeverEmployedComponent from './formComponents/neverEmployedComponent';
import ClaimProfileCard from './cards/claimProfileCard';
import AdditionalInformationCard from './cards/additionalInformationCard';
import ConclusionCard from './cards/conclusionCard';
import QuestionsCard from './cards/questionsCard';
import { getQuestionnaireActions, saveQuestionnaireActions } from './actions';
import {
  BottomAppBar,
  ButtonGrid,
  BottomSaveButton,
  BottomCancelButton,
  CancelLinkCardHeader,
  CardContent3,
} from '../../common/styles/styles';
import MhaAdditionalInformationCard from './cards/mhaAdditionalInformationCard';
import { useParams } from 'react-router-dom';
import { tenantId } from '../../common/constants/strings';

interface Props extends React.HTMLProps<React.ReactNode> {
  token?: string;
  onCancel?: () => void;
}

const ClientPortalQuestionnaire: React.FC<Props> = ({
  token = '',
  onCancel,
}) => {
  const dispatch = useDispatch();
  const { tenant } = useParams<{ tenant: string }>();

  const systemQuestionnaire = rootSelector(
    (state) => state.questionnaire.questionnaire
  );
  const systemQuestionnaireFailed = rootSelector(
    (state) => state.questionnaire.questionnaireFailed
  );

  const [informationAttachments, setInformationAttachments] = React.useState<
    File[]
  >([]);
  const [optionalAttachments, setOptionalAttachments] = React.useState<File[]>(
    []
  );
  const [dto, setDto] = React.useState<ClientQuestionnaireDto>(
    ClientQuestionnaireInit
  );
  const [shouldBlockNavigation, setShouldBlockNavigation] =
    React.useState<boolean>(false);
  const [savingInput, setSavingInput] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);

  React.useEffect(() => {
    localStorage.setItem(tenantId, tenant);
  }, [tenant]);

  React.useEffect(() => {
    setSavingInput(false);
    let questionnaire = { ...systemQuestionnaire };
    if (
      questionnaire.payments === undefined ||
      questionnaire.payments.length === 0
    )
      questionnaire.payments = [SeparationPaymentInit];
    setDto(questionnaire);
  }, [systemQuestionnaire]);
  React.useEffect(
    () => setShowError(systemQuestionnaireFailed.length !== 0),
    [systemQuestionnaireFailed]
  );
  React.useEffect(() => {
    if (token && token.length !== 0) {
      dispatch({
        type: getType(getQuestionnaireActions.request),
        payload: token,
      });
    }
  }, [dispatch, token]);

  const handleCancel = () => {
    if (onCancel) {
      setDto(ClientQuestionnaireInit);
      onCancel();
    } else window.location.reload();
  };

  const handleResetFromSeparationReason = (
    q: ClientQuestionnaireDto,
    id: number
  ) => {
    let newDto: ClientQuestionnaireDto = { ...systemQuestionnaire };
    newDto.id = q.id;
    newDto.questionA = q.questionA;
    newDto.questionB = q.questionB;
    newDto.questionC = q.questionC;
    newDto.initialSeparationQuestionId = id;
    newDto.claimantJobTitle = q.claimantJobTitle;
    newDto.claimantJobStatusTypeId = q.claimantJobStatusTypeId;
    newDto.hrContact = q.hrContact;
    newDto.hireDateTime = q.hireDateTime;
    newDto.lastDayWorkedDate = q.lastDayWorkedDate;
    newDto.lastRateOfPay = q.lastRateOfPay;
    newDto.separationDate = q.separationDate;
    newDto.weeklyAvgWage = q.weeklyAvgWage;
    newDto.weeklyAvgHours = q.weeklyAvgHours;
    newDto.payments = !!q.payments ? [...q.payments] : [SeparationPaymentInit];
    newDto.falseFiling = q.falseFiling;

    setDto(newDto);
  };

  const onInformationAttachmentDelete = (file: File) =>
    setInformationAttachments(
      informationAttachments.filter(
        (attachment) => attachment.name !== file.name
      )
    );
  const onInformationAttachmentUpload = (files: File[]) =>
    setInformationAttachments([...informationAttachments, ...files]);
  const onOptionalAttachmentDelete = (file: File) =>
    setOptionalAttachments(
      optionalAttachments.filter((attachment) => attachment.name !== file.name)
    );
  const onOptionalAttachmentUpload = (files: File[]) =>
    setOptionalAttachments([...optionalAttachments, ...files]);

  const renderSwitch = (
    id: number,
    values: ClientQuestionnaireDto,
    errors: FormikErrors<ClientQuestionnaireDto>,
    handleChange: FormikHandlers['handleChange'],
    handleBlur: FormikHandlers['handleBlur'],
    setFieldValue: FormikHelpers<ClientQuestionnaireDto>['setFieldValue']
  ) => {
    switch (id) {
      case 1:
        return (
          <ResignationComponent
            setShouldBlockNavigation={setShouldBlockNavigation}
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        );

      case 2:
        return (
          <DischargeComponent
            attachments={optionalAttachments}
            onAttachmentDelete={onOptionalAttachmentDelete}
            onAttachmentUpload={onOptionalAttachmentUpload}
            setShouldBlockNavigation={setShouldBlockNavigation}
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        );

      case 3:
        return (
          <LackOfWorkComponent
            setShouldBlockNavigation={setShouldBlockNavigation}
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        );

      case 4:
        return (
          <NeverEmployedComponent
            setShouldBlockNavigation={setShouldBlockNavigation}
            setFieldValue={setFieldValue}
            values={values}
            handleBlur={handleBlur}
          />
        );

      case 5:
        return (
          <OtherOptionComponent
            attachments={optionalAttachments}
            onAttachmentUpload={onOptionalAttachmentUpload}
            onAttachmentDelete={onOptionalAttachmentDelete}
            setShouldBlockNavigation={setShouldBlockNavigation}
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      {showError || dto.id === undefined ? (
        showError ? (
          <Box p={4}>
            <Card>
              <CancelLinkCardHeader
                title='Your questionnaire has been successfully submitted!'
                //title="Thank you for your feedback."
                //subheader="Please close this browser window."
              />
              <CardContent3></CardContent3>
            </Card>
          </Box>
        ) : (
          <Card>
            <CardContent3>
              <BottomAppBar position='fixed'>
                <ButtonGrid container>
                  <BottomCancelButton
                    type='button'
                    onClick={handleCancel}
                    data-cy='page_form_cancelBottom'
                  >
                    CANCEL
                  </BottomCancelButton>
                </ButtonGrid>
              </BottomAppBar>
            </CardContent3>
          </Card>
        )
      ) : (
        <Formik
          initialValues={dto}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={questionnaireValidationSchema}
          onSubmit={(values, { setFieldValue }) => {
            if (!savingInput) {
              setSavingInput(true);
              if (values.documents === undefined) {
                values.documents = [];
              }

              if (values.preexistingAttachedDocumentFilenames === undefined) {
                values.preexistingAttachedDocumentFilenames = [];
              }

              informationAttachments.forEach((file) => {
                values.documents!.push({
                  fileName: file.name,
                  fileUpload: file,
                });
                values.preexistingAttachedDocumentFilenames?.push(file.name);
              });
              optionalAttachments.forEach((file) => {
                values.documents!.push({
                  fileName: file.name,
                  fileUpload: file,
                });
                values.preexistingAttachedDocumentFilenames?.push(file.name);
              });

              dispatch({
                type: getType(saveQuestionnaireActions.request),
                payload: values,
              });

              if (values.documents.length > 0) {
                setFieldValue('documents', []); // Clear the documents values as they've already been uploaded
              }

              if (informationAttachments.length > 0) {
                setInformationAttachments([]); // Clear the information attachments as they've already been uploaded
              }

              if (optionalAttachments.length > 0) {
                setOptionalAttachments([]); // Clear the optional attachments as they've already been uploaded
              }
            }
          }}
          enableReinitialize={true}
        >
          {(props) => {
            const {
              values,
              errors,
              handleChange,
              handleSubmit,
              setFieldValue,
              handleBlur,
            } = props;
            return (
              <Grid container justifyContent='center'>
                <Grid item xs={8}>
                  <Card>
                    <CardHeader
                      title='Unemployment Questionnaire'
                      subheader='Please complete the following questionnaire. Start with the claim profile, followed by the questions relating to the separation.'
                    ></CardHeader>
                    <CardContent3>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <ClaimProfileCard
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            setShouldBlockNavigation={setShouldBlockNavigation}
                          />
                        </Grid>

                        {!!values.additionalQuestionnaireInformation && (
                          <Grid item xs={12}>
                            <MhaAdditionalInformationCard values={values} />
                          </Grid>
                        )}

                        <Grid item xs={12}>
                          <QuestionsCard
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            setShouldBlockNavigation={setShouldBlockNavigation}
                            renderSwitch={renderSwitch}
                            handleResetFromSeparation={
                              handleResetFromSeparationReason
                            }
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <AdditionalInformationCard
                            values={values}
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setShouldBlockNavigation={setShouldBlockNavigation}
                            onInformationAttachmentDelete={
                              onInformationAttachmentDelete
                            }
                            onInformationAttachmentUpload={
                              onInformationAttachmentUpload
                            }
                            informationAttachments={informationAttachments}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <ConclusionCard
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            setShouldBlockNavigation={setShouldBlockNavigation}
                            errors={errors}
                          />
                        </Grid>
                      </Grid>

                      <br />
                      <br />
                      <br />
                      <br />

                      <BottomAppBar position='fixed'>
                        {JSON.stringify(errors).length > 4 && (
                          <Typography
                            variant='overline'
                            color='error'
                            align='right'
                          >
                            Certain fields are mandatory, as they are required
                            by the State Agency for us to respond on your
                            behalf. Please scroll up and check to see if any
                            mandatory responses were left incomplete. All fields
                            in red are mandatory, so a response will be needed
                            in those fields. Thank you. &nbsp;&nbsp;&nbsp;&nbsp;
                          </Typography>
                        )}

                        <ButtonGrid container>
                          <BottomSaveButton
                            type='button'
                            onClick={() => handleSubmit()}
                            //disabled={savingInput}
                            data-cy='page_form_saveBottom'
                          >
                            {savingInput ? (
                              <CircularProgress size={18} />
                            ) : (
                              'Save'
                            )}
                          </BottomSaveButton>

                          <BottomCancelButton
                            type='button'
                            onClick={handleCancel}
                            data-cy='page_form_cancelBottom'
                          >
                            CANCEL
                          </BottomCancelButton>
                        </ButtonGrid>
                      </BottomAppBar>
                    </CardContent3>
                  </Card>
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default ClientPortalQuestionnaire;
