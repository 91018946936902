import { AxiosError } from 'axios';
import { createImportSpecifier } from 'typescript';
import { showNotification } from '../../common/actionCreators/dispatchNotification';
import apiClient from '../../common/api/apiClient';
import { ApiError } from '../../common/api/apiResponse';
import { IMc } from '../../common/dtos/McDto';
import { ISi } from '../../common/dtos/SiDto';
import { URIQuery } from '../../utils';
import {
  ActionsRequestDto,
  AssociateDocumentDto,
  DocumentsDto,
  ReassignDto,
} from './document.dtos';

const actionsUrl = 'action-items';
const reassignUrl = `${actionsUrl}/reassign`;
const updateAssignedContactsUrl = `${actionsUrl}/assigned-contacts`;
const deleteUrl = `${actionsUrl}/document/`;
const associateUrl = `${actionsUrl}/associate`;
const disassociateUrl = `${actionsUrl}/disassociate/`; // DocId
const usiSiUrl = `uisides/si/`;
const usiMcUrl = `uisides/mc/`;

export const getDocumentsRequestApi = async (dto: ActionsRequestDto) => {
  let query = {
    status: dto.documentStatus,
    type: dto.documentType,
    showHistory: dto.history,
    page: dto.pageNumber,
    ...dto,
  };
  return await apiClient()
    .get(actionsUrl + URIQuery({ ...query }))
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response)
          showNotification(axiosError.response.data.error.message, 'error');
      } else
        showNotification(
          'An error occurred while retrieving documents.',
          'error'
        );
      throw err;
    });
};

export const getDocumentApi = async (id: string) => {
  return await apiClient()
    .get(`${actionsUrl}/${id}`)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response)
          showNotification(axiosError.response.data.error.message, 'error');
      } else
        showNotification(
          'An error occurred while retrieving document.',
          'error'
        );
      throw err;
    });
};

export const getTotalDocumentsNumberApi = async () => {
  return await apiClient()
    .get(`${actionsUrl}`)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response)
          showNotification(axiosError.response.data.error.message, 'error');
      } else
        showNotification(
          'An error occurred while retrieving documents.',
          'error'
        );
      throw err;
    });
};

export const getDocumentsPageNumberApi = async () => {
  return await apiClient()
    .get(`${actionsUrl}`)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response)
          showNotification(axiosError.response.data.error.message, 'error');
      } else
        showNotification(
          'An error occurred while retrieving documents.',
          'error'
        );
      throw err;
    });
};

export const reassignDocumentsApi = async (request: ReassignDto) => {
  return await apiClient()
    .put(reassignUrl, request)
    .then((res) => {
      showNotification('Document reassigned.', 'success');
      return true;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response)
          showNotification(axiosError.response.data.error.message, 'error');
      } else
        showNotification(
          'An error occurred while reassigning documents.',
          'error'
        );

      throw err;
    });
};

export const updateAssignedContactsApi = async (request: DocumentsDto[]) => {
  return await apiClient()
    .put(updateAssignedContactsUrl, request)
    .then((res) => {
      showNotification('Assigned contacts updated', 'success');
      return true;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response)
          showNotification(axiosError.response.data.error.message, 'error');
      } else
        showNotification(
          'An error occurred while updating assigned contacts',
          'error'
        );

      throw err;
    });
};

export const deleteDocumentsApi = async (request: string) => {
  return await apiClient()
    .delete(deleteUrl + '/' + request)
    .then((res) => {
      showNotification('Document deleted.', 'success');
      return true;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response)
          showNotification(axiosError.response.data.error.message, 'error');
      } else
        showNotification('An error occurred while deleting document.', 'error');

      throw err;
    });
};

export const associateDocumentsApi = async (request: AssociateDocumentDto) => {
  return await apiClient()
    .put(associateUrl, request)
    .then((res) => {
      showNotification('Document associated with claim.', 'success');
      return true;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response)
          showNotification(axiosError.response.data.error.message, 'error');
      } else
        showNotification(
          'An error occurred while associated document with claim.',
          'error'
        );

      throw err;
    });
};

export const deassociateDocumentsApi = async (
  request: AssociateDocumentDto
) => {
  return await apiClient()
    .put(`${disassociateUrl}${request.id}`)
    .then((res) => {
      showNotification('Document deassociated with claim.', 'success');
      return true;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response)
          showNotification(axiosError.response.data.error.message, 'error');
      } else
        showNotification(
          'An error occurred while disassociating document with claim.',
          'error'
        );

      throw err;
    });
};

export const getUsiSiApi = async (id: string) => {
  return await apiClient()
    .get(`${usiSiUrl}/${id}`)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response)
          showNotification(axiosError.response.data.error.message, 'error');
      } else
        showNotification(
          'An error occurred while retrieving Ui Sides request.',
          'error'
        );
      throw err;
    });
};

export const updateUsiSiApi = async (request: ISi) => {
  const data = new FormData();
  request.uisSiAttachments?.forEach((f) =>
    f.fileUpload !== undefined
      ? data.append(f.fileName ?? '', f.fileUpload)
      : null
  );
  data.append('model', JSON.stringify(request));
  return await apiClient()
    .put(usiSiUrl, data)
    .then((res) => {
      if (request.isReadyToRespond && request.isReadyToRespond === true)
        showNotification('SIDES Response Sent.', 'success');
      else showNotification('SIDES Response Saved.', 'success');

      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) return axiosError.response.data.error.message;
      } else
        showNotification(
          'An error occurred while setting SIDES response.',
          'error'
        );

      throw err;
    });
};

export const getUsiMcApi = async (id: string) => {
  return await apiClient()
    .get(`${usiMcUrl}/${id}`)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response)
          showNotification(axiosError.response.data.error.message, 'error');
      } else
        showNotification(
          'An error occurred while retrieving Ui Sides request.',
          'error'
        );
      throw err;
    });
};

export const updateUsiMcApi = async (request: IMc) => {
  const data = new FormData();
  request.uisMcAttachments?.forEach((f) =>
    f.fileUpload !== undefined
      ? data.append(f.fileName ?? '', f.fileUpload)
      : null
  );
  data.append('model', JSON.stringify(request));
  return await apiClient()
    .put(usiMcUrl, data)
    .then((res) => {
      if (request.isReadyToRespond && request.isReadyToRespond === true)
        showNotification('SIDES Response Sent.', 'success');
      else showNotification('SIDES Response Saved.', 'success');

      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) return axiosError.response.data.error.message;
      } else
        showNotification(
          'An error occurred while setting SIDES response.',
          'error'
        );

      throw err;
    });
};
