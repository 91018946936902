import { createTheme as createMuiTheme, Theme as MuiTheme, ThemeOptions, } from '@material-ui/core/styles';
//import 'typeface-roboto';

export interface Theme extends MuiTheme {
  menu: {
    width: number
    widthCollapsed: number
    background: string
    color: string,
  }
  header: {
    background: string
  }
  secondaryColors: {
    teal: string,
    lightTeal: string,
    fadedTeal: string,
    orange: string,
    lightOrange: string,
    customOrange: string,
    customTeal: string,
    grey: string

  }
}

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      light: "#5299e8",
      //main: "#2780e3",
      //main: "#1d76d9", //Prior PAC TK color //Suggested by cANDI
      main: "#00426A",
      dark: "#1b599e",
          contrastText: "#fff",
    },
    secondary: {
      light: "#33afff",
      //main: "#009BFF",
      main: "#00426A",

      dark: "#006cb2",
      contrastText: "#fff"
    },
    text: {
      primary: 'rgba(0,0,0,0.70)',
      secondary: 'rgba(0,0,0,0.54)',
      disabled: 'rgba(0,0,0,0.38)',
      hint: 'rgba(0,0,0,0.38)',
    },
    error: {
      light: '#e57373',
      main: '#e13023',
      dark: '#d32f2f',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      //dark: '#c64d00', //Suggested by cANDI
        },
        
  },
  typography: {
    fontFamily: '"Roboto", "Arial"',
    fontSize: 11,
    fontWeightRegular: 500,

    subtitle1: {
      fontWeight: 600,
      fontSize: ".95rem",
      lineHeight: 1.57,
    },

    body2: {
      fontWeight: 700,
      //lineHeight: 1.57,
      lineHeight: 1.75,
      letterSpacing: "0.00714em",
    },

    h6: {
      fontWeight: 700,
      fontSize: ".85rem",
      lineHeight: 1.57,
    }

  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.Mui-disabled': { color: 'inherit !important', cursor: 'not-allowed !important' },
        '.MuiAutocomplete-tag': { fontSize: '12px !important', background: 'transparent !important' },
        '.MuiInputAdornment-positionEnd': { marginLeft: 'inherit !important', position: 'absolute', right: '-6px' },
      }
    }
  }
};

const baseTheme = createMuiTheme(themeOptions);

const mainTheme = {
  //header: {
  //  background: '#fff',
  //},
  menu: {
    width: 188,
    //width: 225,
    widthCollapsed: baseTheme.spacing(7.15),
    background: '#fff',
    //color: '#53565A',

    color: '#4d5054', //Suggested by cANDI. Pac TK color for menu item text color. This MAY need to be darker for MHA
  },
  secondaryColors: {
    teal: '#005C59',
    lightTeal: '#4FADAE',
    fadedTeal: '#82CFCD',
    orange: '#94280A',
    lightOrange: '#F4764C',
    customOrange: '#c64d00',
    customTeal: '#24918D',
    grey: '#7C878E'

  }
}

const theme = {
  ...baseTheme,
  ...mainTheme,
}

export default theme;
