import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Dropzone from 'react-dropzone';
import { useHistory } from 'react-router-dom'
import {
  Dialog,  DialogActions, Grid, TextField, Typography
} from '@material-ui/core';
import { getType } from 'typesafe-actions';
import { requestAssistanceActions } from '../actions/requestAssistance.actions'
import { requestAssistanceInit } from '../dtos/requestAssistance';
import { isClientPortalUser, canAccessHelp } from '../../common/dtos/auth';
import { requiredError } from '../constants/strings';
import {
  RequestAssistanceTitle, IssueLabel, WideDialogContent, StyledCardContent, ChooseFileBox, ButtonGrid, StyledSubmitButton, StyledCancelButton
} from '../styles/styles';


const RequestAssistanceDialog: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isOpen, setIsOpen] = React.useState<boolean>(true);
  const [fileUpload, setFileUpload] = React.useState<any>(undefined);
  const [newFileName, setNewFileName] = React.useState<string>('');
  const [isClient, setIsClient] = React.useState<boolean>(false);
  const [hadFileError, setHadFileError] = React.useState<boolean>(false);

  const cancelDialog = () => {
    setIsOpen(false);
    history.goBack();
  }

  React.useEffect(() => {
    if (!canAccessHelp()) cancelDialog();
    if (isClientPortalUser()) setIsClient(true);
      
  }, [isClient]);



  const handleDrop = (acceptedFiles: File[]) => {
    if (!!!acceptedFiles || acceptedFiles.length === 0) setHadFileError(true);
    else {
      setHadFileError(false);
      setFileUpload(acceptedFiles[0])
      setNewFileName(acceptedFiles[0].name);

      var reader: FileReader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
    }
  }

  const backToPreviousPage = () => {
    history.goBack();
  }

  return <Formik
    initialValues={requestAssistanceInit}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={
      Yup.object({
        message: Yup.string().max(200, "Must be 200 characters or less").required(requiredError),
      })
    }
    onSubmit={(values, { setSubmitting, resetForm }) => {
      setTimeout(() => {
        setSubmitting(false);
        resetForm();
      }, 700);

      if (fileUpload !== undefined) {
        values.file = fileUpload;
      }
      dispatch({ type: getType(requestAssistanceActions.request), payload: values });

      cancelDialog();
    }}
    enableReinitialize={true}
  >
    {(props) => {
      const {
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
      } = props;
      return (
        <Dialog
          open={isOpen}
          data-cy="requestAssistance_dialog"
        >
          <Grid container alignItems="center" data-cy="requestAssistance_container">
            <Grid item xs={12}>
                <RequestAssistanceTitle>Request Assistance</RequestAssistanceTitle>
              <IssueLabel>Please describe your issue. Include a screenshot if needed.</IssueLabel>
              <WideDialogContent>
                <form onSubmit={handleSubmit}>
                  <StyledCardContent>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          id="message"
                          name="message"
                          onChange={handleChange}
                          value={values.message || ''}
                          fullWidth
                          multiline
                          data-cy="requestAssistance_message"
                          onBlur={handleBlur}
                          error={Boolean(errors.message)}
                          helperText={errors.message}>
                        </TextField>

                      </Grid>
                      <Grid item xs={3}>
                        <Dropzone aria-label="Screenshot upload" accept="image/*,application/pdf"
                          onDrop={handleDrop}>
                                              {({ getRootProps, getInputProps }) => (
                                                  <ChooseFileBox  {...getRootProps({ className: "dropzone" })}>
                              <input id="fileUpload" name="fileUpload" aria-label="File input area" {...getInputProps()} />
                              <Typography variant="h6" display="block">Choose File</Typography>
                              <small>{newFileName}</small>
                            </ChooseFileBox>
                          )}
                        </Dropzone>
                      </Grid>
                      <Grid item xs={9} hidden={!hadFileError}>
                        <Typography variant="h6" color="error">Accepted files are images and PDFs.</Typography>
                      </Grid>
                    </Grid>
                  </StyledCardContent>
                  <br></br>
                  <DialogActions>
                    <ButtonGrid container>
                      <StyledSubmitButton
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                        data-cy="requestAssistance_submitButton"
                      >
                        Send
                  </StyledSubmitButton>
                      <StyledCancelButton
                        onClick={backToPreviousPage}
                        data-cy="requestAssistance_cancelButton"
                      >CANCEL
                  </StyledCancelButton>
                    </ButtonGrid>
                  </DialogActions>

                </form>
              </WideDialogContent>

            </Grid>
          </Grid>
        </Dialog>
      );
    }}
  </Formik>
}

export default RequestAssistanceDialog;
