import React from 'react';
import { Sidebar, SidebarHeader } from './menu.styles';
import MenuNav from './menuNav';


interface MenuProps {
  isCollapsed: boolean,
}

const Menu = (props: MenuProps) => {
  const { isCollapsed } = props;

  return (
    <Sidebar>
      <SidebarHeader>
      </SidebarHeader>
      <MenuNav isCollapsed={isCollapsed} />
    </Sidebar>
  )
}


export default Menu;