import { call, put, takeLatest, select, takeLeading } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import {
  reassignDocumentsApi, getDocumentsRequestApi, deleteDocumentsApi, associateDocumentsApi, deassociateDocumentsApi,
  getUsiSiApi, updateUsiSiApi, updateAssignedContactsApi, getDocumentApi, getUsiMcApi, updateUsiMcApi
} from './api';

import {
  getDocumentsActions, reassignDocumentsActions, getDocumentPageTotal, deleteDocumentsActions, documentAssociateActions, documentDeassociateActions,
  getUisSiActions, updateUisSiActions, updateAssignContactActions, siteBaseUrlAction, getDocumentActions, getUisMcActions, updateUisMcActions
} from './actions';
import { getClaimActions, didClaimDisassociate, updateSidesActions } from '../claims/actions';
import { ActionItemsDto, DocumentsDto, } from './document.dtos';
import { ISi } from '../../common/dtos/SiDto';
import { IMc } from '../../common/dtos/McDto';



function* handleGetDocuments(request: ReturnType<typeof getDocumentsActions.request>) {  
  const response: ActionItemsDto = yield call(getDocumentsRequestApi, request.payload);

  yield put({ type: getType(getDocumentPageTotal.success), payload: response.total });
  yield put({ type: getType(getDocumentsActions.success), payload: response.documents });
  yield put({ type: getType(siteBaseUrlAction), payload: response.baseUrl });
}

function* handleGetDocument(request: ReturnType<typeof getDocumentActions.request>) {
  const response: DocumentsDto = yield call(getDocumentApi, request.payload);

  yield put({ type: getType(getDocumentActions.success), payload: response });
}


function* handleReassignDocuments(request: ReturnType<typeof reassignDocumentsActions.request>) {
  const result: boolean = yield call(reassignDocumentsApi, request.payload);

  if (result) {
    setTimeout(() => { window.location.href = "/actions"; }, 1500);
    //const documentsRequest: ActionsRequestDto = yield select(state => state.documents.documentRequest);

    //yield put({ type: getType(getDocumentsActions.success), payload: [] });
    //yield put({ type: getType(getDocumentsActions.request), payload: documentsRequest });
  }
}

function* handleUpdateAssignedContacts(request: ReturnType<typeof updateAssignContactActions.request>) {
  const result: boolean = yield call(updateAssignedContactsApi, request.payload);

  if (result) {
    yield put({ type: getType(getDocumentsActions.success), payload: request.payload });
  }
}

function* handleDeleteDocument(request: ReturnType<typeof deleteDocumentsActions.request>) {
  var result: boolean = yield call(deleteDocumentsApi, request.payload);

  //if (result) {
  //  const documentsRequest = yield select(state => state.documents.documentRequest);

  //  yield put({ type: getType(getDocumentsActions.success), payload: [] });
  //  yield put({ type: getType(getDocumentsActions.request), payload: documentsRequest });
  //}

}

function* handleAssociateDocument(request: ReturnType<typeof documentAssociateActions.request>) {
  var result: boolean = yield call(associateDocumentsApi, request.payload);

  if (result) yield put({ type: getType(getClaimActions.request), payload: request.payload.claimId });
}

function* handleDeassociateDocument(request: ReturnType<typeof documentDeassociateActions.request>) {
    var result: boolean = yield call(deassociateDocumentsApi, request.payload);

    if (result) {
    yield put({ type: getType(didClaimDisassociate), payload: true });
    //yield put({ type: getType(getClaimActions.success), payload: ClaimInit });
    }
}

function* handleGetUisSi(request: ReturnType<typeof getUisSiActions.request>) {
  const storeSi: ISi = yield select(state => state.documents.uisSi);
  const si: ISi = (storeSi !== undefined && storeSi.documentId === request.payload) ? storeSi : yield call(getUsiSiApi, request.payload);

  yield put({ type: getType(getUisSiActions.success), payload: si });
}

function* handleUpdateUisSi(request: ReturnType<typeof updateUisSiActions.request>) {
  const result: ISi | string = yield call(updateUsiSiApi, request.payload);

  if (typeof result === "string") {
    yield put({ type: getType(updateSidesActions.failure), payload: result });
    yield put({ type: getType(updateUisSiActions.success), payload: request.payload });
  }
  else {
    yield put({ type: getType(updateUisSiActions.success), payload: result });
    yield put({ type: getType(updateSidesActions.failure), payload: "" });
  }
}

function* handleGetUisMc(request: ReturnType<typeof getUisMcActions.request>) {
  const storeMc: IMc = yield select(state => state.documents.uisMc);
  const mc: IMc = (storeMc !== undefined && storeMc.documentId === request.payload) ? storeMc : yield call(getUsiMcApi, request.payload);

  yield put({ type: getType(getUisMcActions.success), payload: mc });
}

function* handleUpdateUisMc(request: ReturnType<typeof updateUisMcActions.request>) {
  const result: IMc | string = yield call(updateUsiMcApi, request.payload);

  if (typeof result === "string") {
    yield put({ type: getType(updateSidesActions.failure), payload: result });
    yield put({ type: getType(updateUisMcActions.success), payload: request.payload });
  }
  else {
    yield put({ type: getType(updateUisMcActions.success), payload: result });
    yield put({ type: getType(updateSidesActions.failure), payload: "" });
  }
}



function* watchDocumentsGetRequest() {
  try { yield takeLatest(getType(getDocumentsActions.request), handleGetDocuments); }
  catch (err) { }
}
function* watchDocumentGetRequest() {
  try { yield takeLatest(getType(getDocumentActions.request), handleGetDocument); }
  catch (err) { }
}
 function* watchReassignDocumentsRequest() {
  try { yield takeLatest(getType(reassignDocumentsActions.request), handleReassignDocuments); }
  catch (err) { }
}

function* watchUpdateAssignedContactsRequest() {
  try { yield takeLatest(getType(updateAssignContactActions.request), handleUpdateAssignedContacts); }
  catch (err) { }
}

 function* watchDeleteDocumentsRequest() {
  try { yield takeLatest(getType(deleteDocumentsActions.request), handleDeleteDocument); }
  catch (err) { }
}
function* watchAssociateDocumentsRequest() {
  try { yield takeLatest(getType(documentAssociateActions.request), handleAssociateDocument); }
  catch (err) { }
}
 function* watchDeassociateDocumentsRequest() {
    try { yield takeLatest(getType(documentDeassociateActions.request), handleDeassociateDocument); }
    catch (err) { }
}
function* watchGetUisSiRequest() {
  try { yield takeLeading(getType(getUisSiActions.request), handleGetUisSi); }
  catch (err) { }
}
function* watchUpdateUisSiRequest() {
  try { yield takeLatest(getType(updateUisSiActions.request), handleUpdateUisSi); }
  catch (err) { }
}
function* watchhandleGetUisMc() {
  try { yield takeLatest(getType(getUisMcActions.request), handleGetUisMc); }
  catch (err) { }
}
function* watchUpdateUisMc() {
  try { yield takeLatest(getType(updateUisMcActions.request), handleUpdateUisMc); }
  catch (err) { }
}



export default [
  watchDocumentsGetRequest,
  watchDocumentGetRequest,
  watchReassignDocumentsRequest,
  watchDeleteDocumentsRequest,
  watchAssociateDocumentsRequest,
  watchDeassociateDocumentsRequest,
  watchGetUisSiRequest,
  watchUpdateUisSiRequest,
  watchUpdateAssignedContactsRequest,
  watchhandleGetUisMc,
  watchUpdateUisMc
];


