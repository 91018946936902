enum ClaimStatusEnum {
  NA = 0,
  Win = 1,
  Loss = 2,
  Eligible = 3,
  Pending = 4,
}

export class ClaimStatusEnum_class {

  public static enum = ClaimStatusEnum;

  public static readonly enumByNumber: { [key: number]: string } = {
    [3]: "Eligible",
    [2]: "Loss",
    [4]: "Pending",
    [0]: "None on file",
    [1]: "Win",

  };
}