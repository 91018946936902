/* eslint-disable */

import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { InputLabel, Select, Checkbox, MenuItem } from '@material-ui/core';

import MoneyField from '../../../../common/components/numberFields';
import * as Style from '../workOrders.styles';
import * as Styles from '../../../../common/styles/styles';

import { TaskTypesWorkOrderSettingsDto } from '../workOrders.dtos';
import { requiredError } from '../../../../common/constants/strings';


interface TaskTypeEditProps {
  value: TaskTypesWorkOrderSettingsDto,
  onChange: (object: TaskTypesWorkOrderSettingsDto) => void,
}

const TaskTypeEdit: React.FC<TaskTypeEditProps> = (_props: TaskTypeEditProps) => {
  const { value, onChange } = _props;
  const [currentValue, setCurrentValue] = React.useState(value);

  return <Formik initialValues={currentValue}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={Yup.object({
      taskCategoryId: Yup.number().integer().min(1).max(4).required(requiredError),
      name: Yup.string().max(200, 'Must be 200 characters or less').required(requiredError),
      invoiceName: Yup.string().max(200, 'Must be 200 characters or less').required(requiredError),
      defaultRate: Yup.string().required(requiredError),
      isActive: Yup.bool(),
    })}
    onSubmit={(values, { setSubmitting }) => { setTimeout(() => { setSubmitting(false); 700 }); onChange(values); }}
  >
    {(props) => {
      const { values, touched, errors, handleChange, handleSubmit, handleBlur, submitForm } = props;
      return (
        <form onSubmit={handleSubmit} data-cy='settings_workOrderTaskEdit'>
          <Style.FormControl>
            <InputLabel
              required
              htmlFor="taskCategoryId"
            >Category</InputLabel>
            <Select
              value={values.taskCategoryId}
              onChange={handleChange}
              name="taskCategoryId"
              id={'select-taskCategoryId-' + currentValue.taskTypeId}
              inputProps={{ name: 'taskCategoryId' + currentValue.taskTypeId, id: 'taskCategoryId-' + currentValue.taskTypeId }}
              MenuProps={{ disableScrollLock: true }}
              autoFocus
              error={Boolean(errors.taskCategoryId)}
              onBlur={e => { handleBlur(e); submitForm() }}
            >
              <MenuItem value={0}>Please select an item</MenuItem>
              <MenuItem value={1}>Define</MenuItem>
              <MenuItem value={2}>Design</MenuItem>
              <MenuItem value={3}>Build</MenuItem>
              <MenuItem value={4}>Deliver</MenuItem>
            </Select>
          </Style.FormControl>
          <Style.TextField
            id={'task-name-' + currentValue.taskTypeId}
            size="small"
            margin="none"
            InputLabelProps={{ required: true }}
            value={values.name}
            label="Name"
            name="name"
            onBlur={e => { handleBlur(e); submitForm() }}
            onChange={handleChange}
            error={Boolean(errors.name)}
            helperText={(errors.name && touched.name) && errors.name}
            data-cy="settings_workOrderTaskTypeNameInput"
          />
          <Style.TextField
            id={'task-invoiceName-' + currentValue.taskTypeId}
            size="small" margin="none"
            InputLabelProps={{ required: true }}
            value={values.invoiceName}
            label="Invoice Name"
            name="invoiceName"
            onBlur={e => { handleBlur(e); submitForm() }}
            onChange={handleChange}
            error={Boolean(errors.invoiceName)}
            helperText={(errors.name && touched.invoiceName) && errors.invoiceName}
          />
          <Styles.TextNumberField
            id={'task-defaultRate-' + currentValue.taskTypeId}
            size="small" margin="none"
            InputLabelProps={{ required: true }}
            value={values.defaultRate}
            label="Default Rate"
            name="defaultRate"
            onChange={handleChange}
            error={Boolean(errors.defaultRate)}
            helperText={(errors.name && touched.defaultRate) && errors.defaultRate}
            InputProps={{ inputComponent: MoneyField as any, }}
            onBlur={e => { handleBlur(e); submitForm() }}
          />

          <Style.ActionContainer>
            <Style.FormControlLabel id={'task-inUse-' + currentValue.taskTypeId} value={values.inUse} control={<Checkbox disabled color="default" checked={values.inUse} data-cy="settings_workOrderTaskEditInUse" size="small" />}
              label="In Use" labelPlacement="top" />
            <Style.FormControlLabel id={'task-isActive-' + currentValue.taskTypeId} name="task-isActive" value={values.isActive} onChange={e => { values.isActive = !values.isActive; submitForm(); }}
              onBlur={e => { handleBlur(e); submitForm() }} control={<Checkbox size="small" id="task-isActive" checked={values.isActive} />} label="Active" labelPlacement="top" />
          </Style.ActionContainer>

        </form>);
    }}
  </Formik>
}

export default TaskTypeEdit;
