import { AxiosError } from 'axios';

import { ApiError } from '../../common/api/apiResponse';
import apiClient from '../../common/api/apiClient';
import { BatchPaymentsDto, GetMiWamDto, ResolveDiscrepancyDto } from './dtos';
import { showNotification } from '../../common/actionCreators/dispatchNotification';

// Urls
const batchPaymentsUrl = 'batch-payments';
const claimPaymentsUrl = `${batchPaymentsUrl}/claim`;
const verifyPaymentDiscrepancyUrl = `${batchPaymentsUrl}/verify-discrepancy/`;
const protestPaymentDiscrepancyUrl = `${batchPaymentsUrl}/protest-discrepancy/`;
const miWamImportUrl = 'upload/import/miwam';
const miWamDataUrl = 'batch-data/mi-wam';


export const getBatchPaymentListApi = async () => {
  return await apiClient().get(batchPaymentsUrl)
    .then(res => res.data.result)
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting batch payments.", "error");

      throw err;
    });
};

export const getBatchPaymentApi = async (id: string) => {
  return await apiClient().get(batchPaymentsUrl + "/" + id)
    .then(res => res.data.result)
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting this batch payment.", "error");

      throw err;
    });
};

export const updateBatchPaymentApi = async (request: BatchPaymentsDto) => {
  return await apiClient().put(batchPaymentsUrl, request)
    .then(res => {
      showNotification("Batch payment updated.", "success");
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while updating this batch payment.", "error");

      throw err;
    });
};

export const createBatchPaymentApi = async (request: BatchPaymentsDto) => {
  return await apiClient().post(batchPaymentsUrl, request)
    .then(res => {
      showNotification("Batch payment saved.", "success");
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while creating this batch payment.", "error");

      throw err;
    });
};

export const getClaimPaymentsApi = async (id: string) => {
  return await apiClient().get(claimPaymentsUrl + "/" + id)
    .then(res => res.data.result)
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting this batch payment.", "error");

      throw err;
    });
};

export const updateClaimPaymentsApi = async (request: BatchPaymentsDto[]) => {
  let data = new FormData();
  request.forEach((d, i) => {
    if (d.fileUpload !== undefined && d.fileUpload !== null) {
      data.append(d.fileName ?? 'file_' + i, d.fileUpload);
    }
  });

  data.append('model', JSON.stringify(request));

  return await apiClient().put(claimPaymentsUrl, data)
    .then(res => {
      showNotification("Payment(s) updated.", "success");
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while updating the payment(s).", "error");

      throw err;
    });
};

export const updatePaymentDiscrepancyApi = async (request: ResolveDiscrepancyDto) => {
  return await apiClient().put(`${request.isValid ? verifyPaymentDiscrepancyUrl : protestPaymentDiscrepancyUrl}${request.id}`)
    .then(res => {
      showNotification("Batch payment updated.", "success");
      return true;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while updating this batch payment.", "error");

      throw err;
    });
};

export const uploadMiWamImportApi = async (request: any) => {
  const data = new FormData();
  data.append('file', request);
  return await apiClient().post(`${miWamImportUrl}`, data)
    .then((res) => {
      showNotification("Processing upload. Please check in a few minutes.", 'success');
      return true;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while processing import.', 'error');
      throw err;
    });
};

export const getMiWamBatchDataApi = async (request: GetMiWamDto) => {
  return await apiClient().get(`${miWamDataUrl}?employerId=${request.employerId}&fileName=${request.fileName}`)
    .then(res => res.data.result)
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting batch data.", "error");

      throw err;
    });
};