import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { AppellateEventDto } from '../claims.dtos';
import moment from 'moment';
import DeleteDialog from '../dialogs/deleteEventDialog';
import { alternativeIssueOptions } from '../options';
import { ClickableListCardContent, TableCard } from '../../../common/styles/styles';
import { DeleteLink } from '../claims.styles';

interface AppellateEventCardProps {
  dto: AppellateEventDto
  handleAddNewAppellateEvent: (event: AppellateEventDto | undefined) => void,
  isClientUser: boolean,
  isReadOnly: boolean,
}

const AppellateEventCard: React.FC<AppellateEventCardProps> = (props: AppellateEventCardProps) => {
  const { dto, handleAddNewAppellateEvent, isClientUser, isReadOnly } = props;
  const [dialogOpen, setDialogOpen] = React.useState(false);


  const handleDeleteEvent = () => {
    if (!isClientUser) {
      setDialogOpen(true);
    }
  }
  const handleCancelDialog = () => { setDialogOpen(false); }


  const eventTypeDropdownOptions = [
    { name: 'ALJ Hearing', id: 1 },
    { name: 'Board Appeal', id: 2 },
    { name: 'Circuit Court Appeal', id: 3 },
    { name: 'Court of Appeals', id: 4 },
    { name: 'Supreme Court', id: 5 },
    { name: 'Decision Pending', id: 6 },
    { name: 'Hearing Adjourned', id: 7 },
    { name: 'Continued ALJ Hearing', id: 8 },
    { name: 'Hearing Notice', id: 9 },
  ];

  return (
    <>
      {dialogOpen &&
        <DeleteDialog open={dialogOpen} onCancel={handleCancelDialog} eventId={dto.id ?? ''} isAppellate={true} isClientUser={isClientUser} isReadOnly={isReadOnly} />
      }
      
      <TableCard raised>
        <ClickableListCardContent data-cy={`appellate_list_card_link_${dto.comments}`} onClick={isClientUser === true ? () => null : () => { handleAddNewAppellateEvent(dto); }}>
          <Grid container data-cy="appellate_event_container" spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={3}>
                          <Typography variant="body2" color="textPrimary">Hearing Date</Typography>
              <Typography variant="body2" color="secondary">{dto.hearingDateTime === undefined ? "None on File" : moment(dto.hearingDateTime).format('yyyy-MM-DD')}</Typography>
              <Typography variant="body2" color="textPrimary">Date Received</Typography>
              <Typography variant="body2" color="secondary">{dto.dateRecieved === undefined ? "None on File" : moment(dto.dateRecieved).format('yyyy-MM-DD')}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <Typography variant="body2" color="textPrimary">Event Type</Typography>
              <Typography variant="body2" color="secondary">{eventTypeDropdownOptions[eventTypeDropdownOptions.findIndex(o => o.id === dto.type)]?.name || 'None on File'}</Typography>

              <Typography variant="body2" color="textPrimary">Date Completed</Typography>
              <Typography variant="body2" color="secondary">{dto.completedDate === undefined ? "None on File" : moment(dto.completedDate).format('yyyy-MM-DD')}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2}>
              <Typography variant="body2" color="textPrimary">Docket Number</Typography>
              <Typography variant="body2" color="secondary">{dto.docketIdentifier || "None on File"} </Typography>

              {(dto.alternativeIssueTypeId !== undefined && dto.alternativeIssueTypeId !== 0) && 
                (<>
                  <Typography variant="body2" color="textPrimary">Alternative Issue</Typography>
                  <Typography variant="body2" color="secondary">
                    {alternativeIssueOptions.find(f => f.id === dto.alternativeIssueTypeId)?.name || ''}
                  </Typography>
                </>)
              }
            </Grid>
            <Grid item sm={12} md={6} lg={2}>
              <Typography variant="body2" color="textPrimary">Created Date</Typography>
              <Typography variant="body2" color="secondary">{dto.createdDate === undefined ? "None on File" : moment(dto.createdDate).format('yyyy-MM-DD')}</Typography>
            </Grid>
            <Grid item sm={12} md={6} lg={2}>
              <Typography variant="body2" color="textPrimary">Created By</Typography>
              <Typography variant="body2" color="secondary">{dto.createdBy}</Typography>
              <DeleteLink component={Button} onClick={(event: any) => { event.stopPropagation(); handleDeleteEvent(); }} data-cy='' color="secondary">
                DELETE
              </DeleteLink>
            </Grid>
          </Grid>
        </ClickableListCardContent>
      </TableCard>
    </>

  )

};

export default AppellateEventCard;
