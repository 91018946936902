import { AxiosError } from 'axios';
import { ApiError } from '../../../common/api/apiResponse';
import apiClient from '../../../common/api/apiClient';
import { RoleDto } from '../../../common/dtos/role';
import { SystemRoleSettingDto } from './system.dtos';
import { showNotification } from '../../../common/actionCreators/dispatchNotification';


// endpoints
const systemRoleSettingsUrl = 'settings/system/roles'; // GET & PUT Get permissions and update permission releationships 
const systemUserRoleSettingsUrl = 'settings/system/user-roles'; // Post only - For Adding new user roles

export const getSystemRoleSettingsApi = async () => {
  return await apiClient().get(systemRoleSettingsUrl)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while updating system settings.', 'error');
      throw err;
    });
};

export const updateSystemRoleSettingsApi = async (request: SystemRoleSettingDto[]) => {
  return await apiClient().put(systemRoleSettingsUrl, request)
    .then((res) => {
      showNotification('System role settings saved.', 'success');
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while updating system settings.', 'error');
      throw err;
    });
};

export const createSystemUserRoleSettingApi = async (request: RoleDto) => {
  return await apiClient().post(systemUserRoleSettingsUrl, request)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while updating system settings.', 'error');
      throw err;
    });
};
