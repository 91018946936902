import React from 'react';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import TabPanel from '../../common/components/tabPanel';
import ContactHeader from './components/contactHeader';
import { getContactActions, contactShouldRefresh } from './contacts.actions';
import { contactInit } from './contacts.dtos';
import ContactDetails from './components/details';
import UserSetup from './components/userSetup';
import { ViewGrid, ViewCard, StyledCardContent, StyledTabs, StyledTab, ClearAppBar } from '../../common/styles/styles';


const Contact: React.FC = () => {
  const { contactId } = useParams<{ contactId: string }>();
  const dispatch = useDispatch();


  const [tabValue, setTabValue] = React.useState(0);
  const shouldCollapse = useMediaQuery('(max-width:768px)');


  React.useEffect(() => {
    if (contactId !== undefined && contactId !== 'new') {
      dispatch({ type: getType(contactShouldRefresh), payload: contactId });
      dispatch({ type: getType(getContactActions.request), payload: contactId });
    }
    else {
      dispatch({ type: getType(contactShouldRefresh), payload: '' });
      dispatch({ type: getType(getContactActions.success), payload: contactInit });
    }
  }, [dispatch, contactId]);

  const changeTab = (event: any, newValue: number) => {
    setTabValue(newValue);
  }


  return <ViewGrid container item lg={12} md={12} sm={12} xs={12}>
    <ViewCard>
        <ContactHeader />
      <StyledCardContent>
        <ClearAppBar position="static">
                  <StyledTabs aria-labelledby={`tab-${tabValue}`} orientation={shouldCollapse ? 'vertical' : 'horizontal'} centered={true} value={tabValue} onChange={changeTab} aria-label="Profile options" variant="fullWidth" scrollButtons="auto">
                      <StyledTab id="tab-0" aria-controls="tabpanel-0" label="Details" data-cy="contact_details_tab" />
                      <StyledTab id="tab-1" aria-controls="tabpanel-1" label="User Setup" data-cy="contact_userSetup_tab" />
                      {/*<StyledTab id="tab-2" aria-controls="tabpanel-2" label="Placeholder" />*/}
                      {/*<StyledTab id="tab-3" aria-controls="tabpanel-3" label="Placeholder" />*/}
          </StyledTabs>
        </ClearAppBar>

        <TabPanel value={tabValue} index={0}><ContactDetails /></TabPanel>
        <TabPanel value={tabValue} index={1}><UserSetup></UserSetup></TabPanel>
        {/*<TabPanel value={tabValue} index={2}></TabPanel>*/}
        {/*<TabPanel value={tabValue} index={3}></TabPanel>*/}

      </StyledCardContent>
    </ViewCard>
  </ViewGrid>
}

export default Contact;
