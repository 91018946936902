import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { updatePasswordActions } from './updatePassword.actions';

const updatePasswordReducer = combineReducers({
  updatePasswordSuccess: createReducer(false as boolean).handleAction(
    [updatePasswordActions.success],
    (_state, _action) => true
  ),

  updatePasswordError: createReducer('').handleAction(
    [updatePasswordActions.failure],
    (_state, action) => action.payload
  ),
});

export default updatePasswordReducer;
