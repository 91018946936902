import React from "react";
import { useDispatch } from "react-redux";
import { getType } from "typesafe-actions";
// material ui
import { Grid, Box, Dialog } from "@material-ui/core";
import {
  DocumentsDto,
  AssociateDocumentDto,
  DocumentsInit,
} from "../../documents/document.dtos";
import { ClaimInit, ListClaimDto, ListClaimInit } from "../claims.dtos";
import { getClaimActions, didClaimDisassociate } from "../actions";
import { rootSelector } from "../../../common/selectors/selectors";
import ClaimsDetails from "../components/details";
import {
  documentAssociateActions,
  documentDeassociateActions,
  getDocumentActions,
} from "../../documents/actions";
import { isClientPortalUser, isReadOnly } from "../../../common/dtos/auth";
import { ClaimDetailsLabel } from "../claims.styles";
import AssociateClaim from "./associateClaim";
import { FeatureTypeEnum } from "../../../common/enums/featureTypeEnum";
import { MhaContactsDto } from "../../../common/dtos/dto";
import {
  SeparationCodeDto,
  EligibilityCodeDto,
} from "../../settings/codes/codes.dtos";

const documentTypeOptions = [
  { name: "", id: 0 },
  { name: "Win", id: 1 },
  { name: "Loss", id: 2 },
  { name: "Eligible", id: 3 },
  { name: "Pending", id: 4 },
];

interface Props {
  open: boolean;
  onCancel: () => void;
  claimDocument: DocumentsDto;
  mhaContacts: MhaContactsDto[];
}

const ClaimEditDialog: React.FC<Props> = ({
  open,
  onCancel,
  claimDocument,
  mhaContacts,
}) => {
  const dispatch = useDispatch();

  const systemClaim = rootSelector((state) => state.claims.claim);
  const systemDocument = rootSelector((state) => state.documents.document);
  const systemDidDisassociate = rootSelector(
    (state) => state.claims.didDisassociate
  );
  const systemSeparationCodes = rootSelector(
    (state) => state.codes.separationCodes
  );
  const systemEligibilityCodes = rootSelector(
    (state) => state.codes.eligibilityCodes
  );

  const [attached, setAttached] = React.useState(false);
  const [doc, setDoc] = React.useState<DocumentsDto>(DocumentsInit);
  const [claim, setClaim] = React.useState<ListClaimDto>(ListClaimInit);
  const [hideNewClaim, setHideNewClaim] = React.useState(false); //Hides options shown for a document not associated with a claim
  const [isMasked, setIsMasked] = React.useState(false);
  const [sepCodes, setSepCodes] = React.useState([] as SeparationCodeDto[]);
  const [eligibilityCodes, setEligibilityCodes] = React.useState(
    [] as EligibilityCodeDto[]
  );

  React.useEffect(() => {
    setDoc(systemDocument);
  }, [systemDocument]);

  React.useEffect(() => {
    setClaim({ ...systemClaim /*, creditDue: undefined*/ });
    setAttached(systemClaim.id !== undefined && systemClaim.id.length !== 0);
  }, [systemClaim]);

  React.useEffect(() => {
    handleClaimDocument();
  }, [doc, dispatch]);

  React.useEffect(() => {
    if (claimDocument.claimId === undefined) {
      dispatch({
        type: getType(getDocumentActions.request),
        payload: claimDocument.id,
      });
    } else {
      dispatch({
        type: getType(getDocumentActions.success),
        payload: claimDocument,
      });
    }
  }, [claimDocument, dispatch]);

  React.useEffect(() => {
    setSepCodes(JSON.parse(JSON.stringify(systemSeparationCodes)));
  }, [systemSeparationCodes]);

  React.useEffect(() => {
    setEligibilityCodes(JSON.parse(JSON.stringify(systemEligibilityCodes)));
  }, [systemEligibilityCodes]);

  React.useEffect(() => {
    if (systemDidDisassociate) {
      dispatch({ type: getType(didClaimDisassociate), payload: false });
      let newDoc = { ...doc };
      delete newDoc.claimId;
      setDoc(newDoc);
    }
  }, [systemDidDisassociate, dispatch]);

  const handleClaimDocument = () => {
    if (isReadOnly(FeatureTypeEnum.ActionList) && doc?.claimId === undefined) {
      setHideNewClaim(true);
    }

    if (doc?.claimId === undefined || doc?.claimId?.length === 0) {
      let newClaim = { ...ClaimInit };
      newClaim.documents = [];
      newClaim.documents.push(doc);

      dispatch({ type: getType(getClaimActions.success), payload: newClaim });
      setAttached(false);
    } else {
      dispatch({
        type: getType(getClaimActions.request),
        payload: doc.claimId,
      });
    }
  };

  const handleAssociate = (claimId: string) => {
    if (
      isReadOnly(FeatureTypeEnum.ActionList) === false &&
      claimId !== undefined &&
      claimId !== "new"
    ) {
      let request: AssociateDocumentDto = {
        id: doc.id ?? "",
        claimId: claimId,
      };
      dispatch({
        type: getType(documentAssociateActions.request),
        payload: request,
      });
      setAttached(true);
    }
  };

  const handleSSNMaskToggle = () => setIsMasked(!isMasked);

  const handleDeassociate = (claimId: string) => {
    if (
      isReadOnly(FeatureTypeEnum.ActionList) === false &&
      claimId !== undefined &&
      claimId !== "new"
    ) {
      let request: AssociateDocumentDto = {
        id: doc.id ?? "",
        claimId: claimId,
      };
      dispatch({
        type: getType(documentDeassociateActions.request),
        payload: request,
      });
    }
  };

  const handleGetMaskedSSNValue = (ssn?: string) => {
    if (!!ssn) {
      let v = ssn ?? "";
      if (v.replace("-", "").length > 3) {
        return "***-**-" + v.replace("-", "").slice(-4);
      } else {
        return "***-**-";
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        data-cy="appellate_event_dialog"
        fullWidth
        maxWidth="xl"
      >
        <ClaimDetailsLabel>
          {hideNewClaim ? "Document Details" : "Claim Details"}
        </ClaimDetailsLabel>
        <Box p={2}>
          <Grid
            container
            data-cy="claimsDialog_list_container"
            item
            xs={12}
            spacing={2}
          >
            <AssociateClaim
              handleAssociate={handleAssociate}
              handleDeassociate={handleDeassociate}
              attached={attached}
              isReadOnly={isReadOnly(FeatureTypeEnum.ActionList)}
              handleGetMaskedSSNValue={handleGetMaskedSSNValue}
              handleSSNMaskToggle={handleSSNMaskToggle}
              isMasked={isMasked}
              hideNewClaim={hideNewClaim}
              claim={claim}
            />

            <Grid item xs={12}>
              <ClaimsDetails
                isReadOnly={isReadOnly(FeatureTypeEnum.ActionList)}
                isQuickEdit={true}
                isClientUser={isClientPortalUser()}
                claimDocument={doc}
                documentTypeOptions={documentTypeOptions}
                close={onCancel}
                handleClaimantFirstNameUpdates={(
                  ClaimantFirstName: string
                ) => {}}
                handleClaimantLastNameUpdates={(ClaimantLastName: string) => {}}
                handleEmployerNameUpdates={(EmployerName: string) => {}}
                mhaContacts={mhaContacts}
                separationCodes={sepCodes}
                eligibilityCodes={eligibilityCodes}
              />
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default ClaimEditDialog;
