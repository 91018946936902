import React from 'react';
import { Box, } from '@material-ui/core';
import TabPanel from '../../../common/components/tabPanel';
import SystemRoleSettings from './components/roles';
import { SettingsGrid, ClearAppBar, StyledTabs, StyledTab} from '../../../common/styles/styles';


const SystemSettings: React.FC = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const changeTab = (event: any, newValue: number) => {
    setTabValue(newValue);
  }

  return <Box p={2}>
    <SettingsGrid>
      <ClearAppBar position="static">
              <StyledTabs aria-labelledby={`tab-${tabValue}`} centered={true} value={tabValue} onChange={changeTab} aria-label="Settings options" variant="fullWidth" scrollButtons="auto">
                  <StyledTab id="tab-0" aria-controls="tabpanel-0" label="Roles" data-cy="system_rolesTab"  />
                  {/*<StyledTab id="tab-1" aria-controls="tabpanel-1" label="Placeholder"  />*/}
                  {/*<StyledTab id="tab-2" aria-controls="tabpanel-2" label="Placeholder"  />*/}
        </StyledTabs>
      </ClearAppBar>
          <TabPanel value={tabValue}  index={0} data-cy="system_rolesContainer"><SystemRoleSettings /></TabPanel>
          {/*<TabPanel   value={tabValue} index={1}></TabPanel>*/}
          {/*<TabPanel  value={tabValue} index={2}></TabPanel>*/}
    </SettingsGrid>
  </Box>
}

export default SystemSettings;