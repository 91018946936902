import React from 'react';
import { FormikHandlers, FormikHelpers } from 'formik';

// material ui
import { Grid, FormControlLabel } from '@material-ui/core';
import { ClientQuestionnaireDto } from '../dto';
import { MHARadio } from '../../../common/styles/styles';
import { radioButtonBooleanOptions } from '../constants/options';


interface SecondQuestionProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
}

const SecondQuestion: React.FC<SecondQuestionProps> = (
  {
    values,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation }) => {

  return (
    <React.Fragment>
      <Grid item xs={12}>Has the claimant either been laid off, or is experiencing a reduction in hours related to COVID 19?</Grid>
      <Grid container item xs={12} direction="row">
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id={"questionB_option_${index}"}
              name={"questionB_option_${index}"}
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('questionB', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? false);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              checked={o.status === values.questionB}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_questionB" />
          </Grid>
        ))}
      </Grid>
      <br />
    </React.Fragment>
  )
}

export default SecondQuestion;