import { AddressDto, addressInit } from '../../common/dtos/address';
import { EmailDto, emailInit } from '../../common/dtos/email';
import { PhoneDto, phoneInit } from '../../common/dtos/phone';
import { MhaContactsDto } from '../../common/dtos/dto';
import * as Yup from 'yup';
import { requiredError } from '../../common/constants/strings';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const businessUnitRegexp = /^(?:(?!(\^\^)|(\|\|)).)*$/;

export const employerValidationSchema = Yup.object().shape({
  isActive: Yup.boolean(),
  requiresFullSsn: Yup.boolean(),
  name: Yup.string()
    .required(requiredError)
    .max(500, 'Must be 500 characters or less'),
  locationCode: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .nullable(),
  stateCode: Yup.string()
    .max(2, 'Must be 2 characters or less')
    .required(requiredError),
  fein: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .required(requiredError),
  suta: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .required(requiredError),
  healthSystem: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .required(requiredError),
  group: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
  mhacontactId: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .required(requiredError),
  healthSystemGroupId: Yup.string()
    .max(200, 'Must be 200 characters hr less')
    .required(requiredError),
  billingInformationId: Yup.number()
    .min(1, 'One of these is Required')
    .required(requiredError),
  poacomplatedAtClientDate: Yup.date().nullable(),
  poafiledWithStateDate: Yup.date().nullable(),
  contractStartDate: Yup.date().nullable(),
  contractEndDate: Yup.date().nullable(),
  riskTypeId: Yup.number()
    .min(1, 'One of these is Required')
    .required(requiredError),
  businessUnitList: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .max(50, 'Must be 50 characters or less')
        .matches(
          businessUnitRegexp,
          "Business unit names must not contain '||' or '^^'."
        )
        .nullable(),
    })
  ),
  emails: Yup.array().of(
    Yup.object().shape({
      contactTypeId: Yup.number().min(7).max(12),
      emailAddress: Yup.string().email('Invalid email address').nullable(),
    })
  ),
  phones: Yup.array().of(
    Yup.object().shape({
      contactTypeId: Yup.number().min(0).max(8),
      phoneNumber: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .nullable(),
      extension: Yup.number().min(0).max(9999999999).nullable(),
    })
  ),
  addresses: Yup.array().of(
    Yup.object().shape({
      addressTypeId: Yup.number().min(0).max(6),
      address1: Yup.string().max(200, 'Must be 200 characters or less'),
      address2: Yup.string()
        .max(200, 'Must be 200 characters or less')
        .nullable(),
      city: Yup.string().max(200, 'Must be 200 characters or less'),
      stateProvence: Yup.string()
        .max(200, 'Must be 200 characters or less')
        .nullable(),
      postalCode: Yup.string()
        .min(5, 'Must be at least 5 characters')
        .max(10, 'Must be 10 characters or less'),
      country: Yup.string().max(200, 'Must be 200 characters or less'),
    })
  ),
  notes: Yup.array().of(
    Yup.object().shape({
      entry: Yup.string()
        .max(2000, 'Must be 2000 characters or less')
        .nullable(),
    })
  ),
  timeZoneId: Yup.number().nullable(),
});

export interface EmployeeCountDto {
  id?: string;
  year: number;
  count: number;
}

export interface EmployerChargeDto {
  id?: string;
  year: number;
  charge?: string;
}

export const employeeCountInit: EmployeeCountDto = {
  year: 0,
  count: 0,
};

export const EmployerChargeInit: EmployerChargeDto = {
  year: 0,
};

export interface HealthGroupDto {
  id: string;
  name: string;
}

export interface BusinessUnitDto {
  id?: string;
  name: string;
  isActive: boolean;
}

export const businessUnitInit: BusinessUnitDto = {
  name: '',
  isActive: true,
};

export interface CompanyNoteDto {
  noteId?: string;
  entry: string;
}

export const companyNoteInit: CompanyNoteDto = {
  entry: '',
};

export interface CompanyInteractionDto {
  id?: string;
  isTarget: boolean;
  lastActionId?: number;
  lastActionNote?: string;
  followUpDate?: Date;
  followUpActionId?: number;
  followUpNote?: string;
  createdDate?: Date;
  createdBy?: string;
}

export const companyInteractionInit: CompanyInteractionDto = {
  isTarget: false,
  followUpDate: new Date(),
  followUpNote: '',
  lastActionNote: '',
  createdDate: new Date(),
};

export interface CompanyDto {
  companyId?: string;
  name: string;
  stateCode?: string;
  locationCode?: string;
  fein: string;
  suta: string;
  mhacontactId?: string;
  healthSystemGroupId: string;
  billingInformationId: number;
  timeZoneId: number;
  addresses: AddressDto[];
  emails: EmailDto[];
  phones: PhoneDto[];
  notes: CompanyNoteDto[];
  interactions?: CompanyInteractionDto[];
  businessUnitList: BusinessUnitDto[];
  employeeCountList: EmployeeCountDto[];
  isActive: boolean;
  requiresFullSsn: boolean;
  healthSystem: string;
  group?: string;
  primaryContactFullName?: string;
  primaryEmailAddress?: string;
  primaryPhoneNumber?: string;
  primaryWebsite?: string;
  createdBy?: string;
  createdDateTime?: Date;
  updatedBy?: string;
  updatedDateTime?: Date;
  poacomplatedAtClientDate?: Date;
  poafiledWithStateDate?: Date;
  contractStartDate?: Date;
  contractEndDate?: Date;
  contactList?: string[];
  riskTypeId?: number; //Enum 1 or 2
  employerChargeList: EmployerChargeDto[];
}

export interface CompanyHsDto {
  companyId?: string;
  name: string;
  healthSystem: string;
  group: string;
  selected: boolean;
  businessUnitList: BusinessUnitDto[];
}

export const companyInit: CompanyDto = {
  isActive: true,
  requiresFullSsn: false,
  name: '',
  fein: '',
  suta: '',
  stateCode: '',
  healthSystem: '',
  timeZoneId: 0,
  healthSystemGroupId: '',
  billingInformationId: 0,
  emails: [emailInit] as EmailDto[],
  phones: [phoneInit] as PhoneDto[],
  addresses: [addressInit] as AddressDto[],
  notes: [companyNoteInit] as CompanyNoteDto[],
  businessUnitList: [businessUnitInit] as BusinessUnitDto[],
  employeeCountList: [employeeCountInit] as EmployeeCountDto[],
  employerChargeList: [EmployerChargeInit] as EmployerChargeDto[],
  riskTypeId: 0,
};

export const mhaContactsInit: MhaContactsDto = {
  contactId: '',
  name: '',
};

export const companyHsDtoInit: CompanyHsDto = {
  companyId: '',
  name: '',
  healthSystem: '',
  group: '',
  selected: false,
  businessUnitList: [],
};
