import React from 'react';

import { Grid, Typography, } from '@material-ui/core';

import { rootSelector } from '../../../common/selectors/selectors';
import Label from '../../../common/components/label';
//import * as Style from '../styles';
import { companyInit } from '../dtos';
import * as Styles from '../../../common/styles/styles';


const CompanyHeader: React.FC = () => {

  //const baseUrl = process.env.REACT_APP_API_URL ?? "";
  //const baseLogoUrl = baseUrl + "/download/logo/";
  const systemCompany = rootSelector(state => state.companies.company);

  const [dto, setDto] = React.useState(companyInit);
  //const [logoImageUrl, setLogoImageUrl] = React.useState('');

  React.useEffect(() => {
    setDto(systemCompany);
  }, [systemCompany]);


  return <Styles.FeatureCardHeader

    title={
      <Grid container item md={12} sm={12} lg={12} xs={12} data-cy="_template_profile_profileInformation">

        <Grid item sm={6} md={3} xs={12}>
          <Typography variant="subtitle1" >
            {dto.name}
          </Typography>

        </Grid>
        <Grid item sm={6} md={3} xs={12}>
          <Label>Email</Label>
          <Typography variant="body2" >
            {dto.primaryEmailAddress}
          </Typography>
        </Grid>
        <Grid item sm={6} md={3} xs={12}>
          <Label>Phone</Label>
          <Typography variant="body2" >
            {dto.primaryPhoneNumber}
          </Typography>
        </Grid>
        <br />
        <Grid item sm={6} md={3} xs={12}>
          <Label>Address</Label>
          {dto.addresses && dto.addresses[0] ?
            <>
              <Typography variant="body2" display="block">
                {dto.addresses![0].address1}
              </Typography>
              <Typography variant="body2" display="block">
                {dto.addresses![0].city} {dto.addresses![0].stateProvence} {dto.addresses![0].postalCode}
              </Typography>
            </>
            : null}
        </Grid>
      </Grid>
    }
  />
}

export default CompanyHeader;
