import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { createQuestionnaireApi, saveQuestionnaireApi, getQuestionnaireApi } from './api';

import {
    createQuestionnaireActions, getQuestionnaireActions, saveQuestionnaireActions
} from './actions';
import { ClientQuestionnaireDto, AdditionalQuestionDto } from './dto';
import { getClaimActions } from '../claims/actions';
import { toggleEmailTemplateDialog, openReachOutEmailTemplateDialog } from '../../common/actions/emailTemplate.actions';


function* handleGetQuestionnaire(request: ReturnType<typeof getQuestionnaireActions.request>) {
  try {
    const storeQuestionnaire: ClientQuestionnaireDto = yield select(state => state.questionnaire.questionnaire);
    const questionnaire: ClientQuestionnaireDto = (storeQuestionnaire !== undefined && storeQuestionnaire.claimId === request.payload) ?
      storeQuestionnaire : yield call(getQuestionnaireApi, request.payload);

    yield put({ type: getType(getQuestionnaireActions.failure), payload: '' });
    yield put({ type: getType(getQuestionnaireActions.success), payload: questionnaire });
  }
  catch (err) { yield put({ type: getType(getQuestionnaireActions.failure), payload: 'Failed' }); }
    
}

function* handleCreateQuestionnaire(request: ReturnType<typeof createQuestionnaireActions.request>) {
  const result: ClientQuestionnaireDto = yield call(createQuestionnaireApi, request.payload);

  if (result) {
    let updatedClaim = request.payload.claim;
    if (result && updatedClaim) {
      updatedClaim.additionalQuestionnaireQuestions = result.questions ? [...result.questions] : [];
      updatedClaim.additionalQuestionnaireInformation = result.additionalQuestionnaireInformation;
      updatedClaim.questionnaireProvideFullSSN = result.provideFullSSN;
      yield put({ type: getType(getClaimActions.success), payload: updatedClaim });
    }

    // Open Email
    yield put({ type: getType(openReachOutEmailTemplateDialog), payload: true });
    yield put({ type: getType(toggleEmailTemplateDialog), payload: true });
  }
}


function* handleSaveQuestionnaire(request: ReturnType<typeof saveQuestionnaireActions.request>) {
  let result: boolean = yield call(saveQuestionnaireApi, request.payload);
  yield put({ type: getType(getQuestionnaireActions.success), payload: JSON.parse(JSON.stringify(request.payload)) });

  if (request.payload.completed) {
    yield window.location.reload();
  }
}



 function* watchCreateQuestionnaireRequest() {
  try { yield takeLatest(getType(createQuestionnaireActions.request), handleCreateQuestionnaire); }
  catch (err) { }
}

 function* watchSaveQuestionnaireRequest() {
    try { yield takeLatest(getType(saveQuestionnaireActions.request), handleSaveQuestionnaire); }
    catch (err) { }
}

 function* watchGetQuestionnaireRequest() {
    try { yield takeLatest(getType(getQuestionnaireActions.request), handleGetQuestionnaire); }
    catch (err) { }
}


export default [
  watchCreateQuestionnaireRequest,
  watchGetQuestionnaireRequest,
  watchSaveQuestionnaireRequest,
];

