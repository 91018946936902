import React from 'react';
import { FormikHandlers, FormikHelpers } from 'formik';

// material ui
import { Grid, FormControlLabel } from '@material-ui/core';
import { ClientQuestionnaireDto } from '../dto';
import { radioButtonBooleanOptions } from '../constants/options';
import { MHARadio } from '../../../common/styles/styles';


interface ThirdQuestionProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
}

const ThirdQuestion: React.FC<ThirdQuestionProps> = (
  {
    values,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation
  }) => {


  return (
    <React.Fragment>
      <Grid item xs={12}> Do you believe this claim was filed fraudulently?</Grid>
      <Grid container item xs={12} direction="row">
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="questionC"
              name="questionC"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('questionC', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? false);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              checked={o.status === values.questionC}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_questionC" />
          </Grid>
        ))}
      </Grid>
      <br />
      {values.questionC !== undefined && values.questionC === true ?
        <>
          <Grid item xs={12}>Have you confirmed with the employee that they did not file for unemployment benefits?</Grid>
          <Grid container item xs={12} direction="row">
            {radioButtonBooleanOptions.map((o, index) => (
              <Grid item xs={3} key={o.name + index}>
                <FormControlLabel
                  id="falseFiling"
                  name="falseFiling"
                  onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                    setFieldValue('falseFiling', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? 1);
                    setShouldBlockNavigation(true);
                  }}
                  onBlur={handleBlur}
                  //add errors for validation
                  checked={o.status === values.falseFiling}
                  value={o.name}
                  control={<MHARadio
                    size="small" />} label={o.name} data-cy="clientQuestionnaire_falseFiling" />
              </Grid>
            ))}
          </Grid>
          {values.falseFiling !== undefined && values.falseFiling === false ?
            <Grid item xs={12}>Please note that we cannot report a claim as
            fraudulent unless we have confirmed with
            the employee that they did not file the claim.
            We encourage you to reach out to this
                        employee, and then follow-up to advise us if this claim is fraudulent.</Grid>

            : null}
        </>

        : null}
      <br />
    </React.Fragment>
  )
}

export default ThirdQuestion;
