import React from 'react';
import { BenchmarkAndComparisonReportParametersDto } from '../dtos';
import {
  FormikErrors,
  FormikHandlers,
  FormikHelpers,
  getIn,
  FieldArray,
} from 'formik';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import RemoveCircleRounded from '@material-ui/icons/RemoveCircleRounded';
import { Grid, TextField, MenuItem, Checkbox } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import Label from '../../../common/components/label';
import { CompanyHsDto } from '../../company/dtos';
import { Autocomplete } from '@material-ui/lab';
import { PaddedTextField, Icon } from '../../../common/styles/styles';
import { getReportGroups } from '../../../utils/reportHelperFunctions';

interface Props extends React.HTMLProps<React.ReactNode> {
  values: BenchmarkAndComparisonReportParametersDto;
  errors: FormikErrors<BenchmarkAndComparisonReportParametersDto>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  setFieldValue: FormikHelpers<BenchmarkAndComparisonReportParametersDto>['setFieldValue'];
  heathSystems: string[];
  companies: CompanyHsDto[];
}

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const BenchmarkAndComparisonReportParameters: React.FC<Props> = ({
  values,
  errors,
  handleBlur,
  setFieldValue,
  handleChange,
  heathSystems,
  companies,
}) => {
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={6}>
        <KeyboardDatePicker
          id='startYearParameter'
          name='startYearParameter'
          size='small'
          views={['year']}
          margin='none'
          format='yyyy'
          value={
            values.startYearParameter
              ? new Date().setFullYear(values.startYearParameter)
              : null
          }
          label='Start Year'
          onChange={(date: MaterialUiPickersDate) =>
            setFieldValue('startYearParameter', date?.year() ?? 0)
          }
          onBlur={handleBlur}
          error={Boolean(errors.startYearParameter)}
          helperText={
            Boolean(errors.startYearParameter) && errors.startYearParameter
          }
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={6}>
        <KeyboardDatePicker
          id='endYearParameter'
          name='endYearParameter'
          size='small'
          views={['year']}
          margin='none'
          format='yyyy'
          value={
            values.endYearParameter
              ? new Date().setFullYear(values.endYearParameter)
              : null
          }
          label='End Year'
          onChange={(date: MaterialUiPickersDate) =>
            setFieldValue('endYearParameter', date?.year() ?? 0)
          }
          onBlur={handleBlur}
          error={Boolean(errors.endYearParameter)}
          helperText={
            Boolean(errors.endYearParameter) && errors.endYearParameter
          }
          fullWidth
        />
      </Grid>

      <Grid container item xs={12} alignItems='center'>
        <Grid item xs={12}>
          <Label>Comparing Employer(s)</Label>
        </Grid>
      </Grid>

      {values.groups.map((g, i) =>
        i < 1 ? (
          <React.Fragment key={'Group_HS_' + i}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <TextField
                name={`healthSystems[${i}]`}
                label='Health System'
                size='small'
                margin='none'
                select
                value={values.healthSystems[i] || ''}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(`groups[${i}]`, []);
                  setFieldValue(`healthGroups[${i}]`, '');
                }}
                fullWidth
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                error={
                  Boolean(getIn(errors, `healthSystems[${i}]`)) &&
                  values.healthSystems[i].length === 0
                }
                helperText={
                  errors.healthSystems &&
                  values.healthSystems[i].length === 0 &&
                  errors.healthSystems
                }
              >
                {heathSystems.map((option, index) => (
                  <MenuItem key={option + index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <TextField
                name={`healthGroups[${i}]`}
                label='Health System Group'
                size='small'
                margin='none'
                select
                value={values.healthGroups[i] || ''}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(`groups[${i}]`, []);
                }}
                fullWidth
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
              >
                {getReportGroups(companies, values.healthSystems, i)}
              </TextField>
            </Grid>
            {/*{(values.healthGroups[i]?.length !== 0 || values.healthSystems[i] === 'All-MHA') &&*/}
            {values.healthGroups[i]?.length !== 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                  id={'selectedEmployers' + i}
                  options={
                    Array.from(
                      new Set(
                        companies
                          .filter(
                            (c) => c.healthSystem === values.healthSystems[i]
                          )
                          .filter((c) =>
                            c.group.includes(values.healthGroups[i])
                          )
                      )
                    ) as CompanyHsDto[]
                  }
                  getOptionLabel={(o: CompanyHsDto) => o.name ?? ''}
                  renderOption={(option) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={g.includes(option.companyId ?? '')}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  disableCloseOnSelect
                  multiple
                  value={Array.from(new Set(companies)).filter((o) =>
                    values.groups[i].map((m) => m).includes(o.companyId ?? '')
                  )}
                  onChange={(_e: any, v: CompanyHsDto[] | null) => {
                    let ids: string[] = [];
                    v?.forEach((c) => ids.push(c.companyId ?? ''));
                    setFieldValue(`groups[${i}]`, ids);
                  }}
                  renderInput={(params) => (
                    <PaddedTextField
                      {...params}
                      id={'selectedEmployers' + i}
                      size='small'
                      name='selectedHearingContacts'
                      margin='none'
                      aria-label='Employers'
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}
          </React.Fragment>
        ) : null
      )}

      <Grid container item xs={12} alignItems='center'>
        <Grid item xs={12}>
          <Label>Peer Groups</Label>
        </Grid>
      </Grid>

      {values.groups.map((g, i) =>
        i > 0 && i < 5 ? (
          <React.Fragment key={'Group_HS_' + i}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <TextField
                name={`healthSystems[${i}]`}
                label='Health System'
                size='small'
                margin='none'
                select
                value={values.healthSystems[i] || ''}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(`groups[${i}]`, []);
                  setFieldValue(`healthGroups[${i}]`, '');
                }}
                fullWidth
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
                error={
                  Boolean(getIn(errors, `healthSystems[${i}]`)) &&
                  values.healthSystems[i].length === 0
                }
                helperText={
                  errors.healthSystems &&
                  values.healthSystems[i].length === 0 &&
                  errors.healthSystems
                }
              >
                {heathSystems.map((option, index) => (
                  <MenuItem key={option + index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <TextField
                name={`healthGroups[${i}]`}
                label='Health System Group'
                size='small'
                margin='none'
                select
                value={values.healthGroups[i] || ''}
                onChange={(e) => {
                  handleChange(e);
                  setFieldValue(`groups[${i}]`, []);
                }}
                fullWidth
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
              >
                {getReportGroups(companies, values.healthSystems, i)}
              </TextField>
            </Grid>
            {/*{(values.healthGroups[i]?.length !== 0 || values.healthSystems[i] === 'All-MHA') &&*/}
            {values.healthGroups[i]?.length !== 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Autocomplete
                  id={'selectedEmployers' + i}
                  options={
                    Array.from(
                      new Set(
                        companies
                          .filter(
                            (c) => c.healthSystem === values.healthSystems[i]
                          )
                          .filter((c) =>
                            c.group.includes(values.healthGroups[i])
                          )
                      )
                    ) as CompanyHsDto[]
                  }
                  getOptionLabel={(o: CompanyHsDto) => o.name ?? ''}
                  renderOption={(option) => (
                    <React.Fragment>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={g.includes(option.companyId ?? '')}
                      />
                      {option.name}
                    </React.Fragment>
                  )}
                  disableCloseOnSelect
                  multiple
                  value={Array.from(new Set(companies)).filter((o) =>
                    values.groups[i].map((m) => m).includes(o.companyId ?? '')
                  )}
                  onChange={(_e: any, v: CompanyHsDto[] | null) => {
                    let ids: string[] = [];
                    v?.forEach((c) => ids.push(c.companyId ?? ''));
                    setFieldValue(`groups[${i}]`, ids);
                  }}
                  renderInput={(params) => (
                    <PaddedTextField
                      {...params}
                      id={'selectedEmployers' + i}
                      size='small'
                      name='selectedHearingContacts'
                      margin='none'
                      aria-label='Employers'
                      fullWidth
                    />
                  )}
                />
              </Grid>
            )}
          </React.Fragment>
        ) : null
      )}

      <FieldArray
        name='groups'
        render={(arrayHelpers) => (
          <>
            <Grid container item xs={12} alignItems='center'>
              <Grid item xs={12}>
                <Icon
                  title='Add Peer group'
                  color='secondary'
                  onClick={() => {
                    if (values.groups.length < 9) {
                      arrayHelpers.push([]);
                    }
                  }}
                >
                  <AddBoxRoundedIcon />
                </Icon>
              </Grid>
            </Grid>

            {values.groups.map((g, i) =>
              i > 4 ? (
                <React.Fragment key={'Group_HS_' + i}>
                  <Grid item xs={12} sm={12} md={12} lg={5}>
                    <TextField
                      name={`healthSystems[${i}]`}
                      label='Health System'
                      size='small'
                      margin='none'
                      select
                      value={values.healthSystems[i] || ''}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue(`groups[${i}]`, []);
                        setFieldValue(`healthGroups[${i}]`, '');
                      }}
                      fullWidth
                      SelectProps={{ MenuProps: { disableScrollLock: true } }}
                      error={
                        Boolean(getIn(errors, `healthSystems[${i}]`)) &&
                        values.healthSystems[i].length === 0
                      }
                      helperText={
                        errors.healthSystems &&
                        values.healthSystems[i].length === 0 &&
                        errors.healthSystems
                      }
                    >
                      {heathSystems.map((option, index) => (
                        <MenuItem key={option + index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={5}>
                    <TextField
                      name={`healthGroups[${i}]`}
                      label='Health System Group'
                      size='small'
                      margin='none'
                      select
                      value={values.healthGroups[i] || ''}
                      onChange={(e) => {
                        handleChange(e);
                        setFieldValue(`groups[${i}]`, []);
                      }}
                      fullWidth
                      SelectProps={{ MenuProps: { disableScrollLock: true } }}
                    >
                      {getReportGroups(companies, values.healthSystems, i)}
                    </TextField>
                  </Grid>
                  {i == values.groups.length - 1 ? (
                    <Grid
                      container
                      item
                      xs={2}
                      alignItems='center'
                      justifyContent='flex-end'
                    >
                      <Icon
                        title='Remove Peer group from List'
                        color={undefined}
                        onClick={() => {
                          setFieldValue(`groups[${i}]`, []);
                          setFieldValue(`healthSystems[${i}]`, '');
                          setFieldValue(`healthGroups[${i}]`, '');
                          arrayHelpers.remove(i);
                        }}
                      >
                        <RemoveCircleRounded />
                      </Icon>
                    </Grid>
                  ) : null}
                  {(values.healthGroups[i]?.length !== 0 ||
                    values.healthSystems[i] === 'All-MHA') && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Autocomplete
                        id={'selectedEmployers' + i}
                        options={
                          Array.from(
                            new Set(
                              companies
                                .filter(
                                  (c) =>
                                    c.healthSystem === values.healthSystems[i]
                                )
                                .filter((c) =>
                                  c.group.includes(values.healthGroups[i])
                                )
                            )
                          ) as CompanyHsDto[]
                        }
                        getOptionLabel={(o: CompanyHsDto) => o.name ?? ''}
                        renderOption={(option) => (
                          <React.Fragment>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={g.includes(option.companyId ?? '')}
                            />
                            {option.name}
                          </React.Fragment>
                        )}
                        disableCloseOnSelect
                        multiple
                        value={Array.from(new Set(companies)).filter((o) =>
                          values.groups[i]
                            .map((m) => m)
                            .includes(o.companyId ?? '')
                        )}
                        onChange={(_e: any, v: CompanyHsDto[] | null) => {
                          let ids: string[] = [];
                          v?.forEach((c) => ids.push(c.companyId ?? ''));
                          setFieldValue(`groups[${i}]`, ids);
                        }}
                        renderInput={(params) => (
                          <PaddedTextField
                            {...params}
                            id={'selectedEmployers' + i}
                            size='small'
                            name='selectedHearingContacts'
                            margin='none'
                            aria-label='Employers'
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              ) : null
            )}
          </>
        )}
      />
    </>
  );
};

export default BenchmarkAndComparisonReportParameters;
