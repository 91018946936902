import React from 'react';
import { EmployeeCountDto } from '../dtos';
import * as Styles from '../../../common/styles/styles';
import { Card, Grid, Typography } from '@material-ui/core';

interface EmployeeCountProps {
  employeeCount: EmployeeCountDto,
  index: number
}

const EmployeeCountCard: React.FC<EmployeeCountProps> = (props: EmployeeCountProps) => {
  const { employeeCount, index } = props;
  return <Grid key={'grid-' + index} item xs={12} data-cy={'employer_employeeCount_card_' + index}>
    <Card key={'card-' + index}>
      <Styles.CardContent1 key={'cardContent-' + index}>
        <Grid container spacing={0} key={'gridContainer-' + index} >
          <Grid item xs={3} key={'year-' + index}>
            <Typography variant="subtitle1" color="primary">{employeeCount.year}</Typography>
          </Grid>

          <Grid item xs={3} key={'count-' + index}>
            <Typography variant="subtitle1" color="primary">{employeeCount.count.toLocaleString('en-us')} Employees</Typography>
          </Grid>

        </Grid>
      </Styles.CardContent1>
    </Card>
  </Grid>
}

export default EmployeeCountCard;
