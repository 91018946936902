import React from 'react';
import { useDispatch } from 'react-redux';
import { Prompt } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  Grid,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { getType } from 'typesafe-actions';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import * as Styles from '../../../common/styles/styles';
import * as Style from './changePassword.styles';
import { changePasswordInit } from './changePassword.dtos';
import { changePasswordActions } from './changePassword.actions';
import {
  passwordError,
  passwordMaxError,
  passwordRegex,
  requiredError,
  tenantId,
} from '../../../common/constants/strings';

const ChangePassword: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tenant } = useParams<{ tenant: string }>();

  //const [openDialog, setDialog] = useState(true);
  const [isPassword, setIsPassword] = React.useState(true);
  const [isCurrentPassword, setIsCurrentPassword] = React.useState(true);
  const [isConfirmPassword, setIsConfirmPassword] = React.useState(true);
  const [shouldBlockNavigation, setShouldBlockNavigation] =
    React.useState(false);

  React.useEffect(() => {
    setShouldBlockNavigation(false);
  }, []);

  React.useEffect(() => {
    localStorage.setItem(tenantId, tenant);
  }, [tenant]);

  const backToPreviousPage = () => {
    history.goBack();
  };

  const handleCurrentPasswordViewToggle = () => {
    setIsCurrentPassword(!isCurrentPassword);
  };

  const handlePasswordViewToggle = () => {
    setIsPassword(!isPassword);
  };

  const handleConfirmPasswordViewToggle = () => {
    setIsConfirmPassword(!isConfirmPassword);
  };

  return (
    <Formik
      initialValues={changePasswordInit}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={Yup.object({
        currentPassword: Yup.string().required(requiredError),
        password: Yup.string()
          .min(12, passwordError)
          .max(50, passwordMaxError)
          .matches(passwordRegex, passwordError)
          .notOneOf(
            [Yup.ref('currentPassword'), null],
            'New Password must not match Current Password.'
          )
          .required(requiredError),
        confirmPassword: Yup.string()
          .oneOf(
            [Yup.ref('password'), null],
            'Confirm New Password must match New Password.'
          )
          .required(requiredError),
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setTimeout(() => {
          setSubmitting(false);
          resetForm();
          //backToPreviousPage();
        }, 700);
        setShouldBlockNavigation(false);
        dispatch({
          type: getType(changePasswordActions.request),
          payload: values,
        });
      }}
      enableReinitialize={true}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit,
        } = props;
        return (
          <Dialog open={true} data-cy='changePwd_dialog' maxWidth='xs'>
            <Style.StyledDialogTitle disableTypography>
              <Style.H3>Change Password</Style.H3>
              <small>
                Password must be 12 characters and have at least one uppercase
                letter, one lowercase letter, one number, one special character.
              </small>
            </Style.StyledDialogTitle>

            <Style.StyledDialogContent>
              <form onSubmit={handleSubmit}>
                <Style.StyledCardContent>
                  <Grid container>
                    {/* current password */}
                    <Grid item xs={12}>
                      <Styles.StyledTextField
                        id='currentPassword'
                        margin='dense'
                        InputLabelProps={{ required: true }}
                        fullWidth
                        value={values.currentPassword}
                        label='Current Password'
                        name='currentPassword'
                        type={isCurrentPassword ? 'password' : 'text'}
                        autoFocus
                        onChange={(e) => {
                          handleChange(e);
                          setShouldBlockNavigation(true);
                        }}
                        error={
                          Boolean(errors.currentPassword) &&
                          touched.currentPassword
                        }
                        helperText={
                          errors.currentPassword &&
                          touched.currentPassword &&
                          errors.currentPassword
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position='end'
                              role='viewPasswordToggle'
                              onClick={handleCurrentPasswordViewToggle}
                            >
                              {isCurrentPassword ? (
                                <IconButton
                                  tabIndex={-1}
                                  title='View Current Password'
                                >
                                  <VisibilityIcon color='primary' />
                                </IconButton>
                              ) : (
                                <IconButton
                                  tabIndex={-1}
                                  title='Hide Current Password'
                                >
                                  <VisibilityOffIcon color='primary' />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        data-cy='changePwd_currentPwd'
                      />
                    </Grid>

                    {/* new password */}
                    <Grid item xs={12}>
                      <Styles.StyledTextField
                        id='password'
                        margin='dense'
                        InputLabelProps={{ required: true }}
                        fullWidth
                        value={values.password}
                        name='password'
                        label='Password'
                        onChange={(e) => {
                          handleChange(e);
                          setShouldBlockNavigation(true);
                        }}
                        type={isPassword ? 'password' : 'text'}
                        error={Boolean(errors.password) && touched.password}
                        helperText={
                          errors.password && touched.password && errors.password
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position='end'
                              role='viewPasswordToggle'
                              onClick={handlePasswordViewToggle}
                            >
                              {isPassword ? (
                                <IconButton
                                  tabIndex={-1}
                                  title='View New Password'
                                >
                                  <VisibilityIcon color='primary' />
                                </IconButton>
                              ) : (
                                <IconButton
                                  tabIndex={-1}
                                  title='Hide New Password'
                                >
                                  <VisibilityOffIcon color='primary' />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        data-cy='changePwd_pwd'
                      />
                    </Grid>

                    {/* password */}
                    <Grid item xs={12}>
                      <Styles.StyledTextField
                        id='confirmPassword'
                        margin='dense'
                        InputLabelProps={{ required: true }}
                        fullWidth
                        value={values.confirmPassword}
                        name='confirmPassword'
                        label='Confirm Password'
                        type={isConfirmPassword ? 'password' : 'text'}
                        onChange={(e) => {
                          handleChange(e);
                          setShouldBlockNavigation(true);
                        }}
                        error={
                          Boolean(errors.confirmPassword) &&
                          touched.confirmPassword
                        }
                        helperText={
                          errors.confirmPassword &&
                          touched.confirmPassword &&
                          errors.confirmPassword
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position='end'
                              role='viewPasswordToggle'
                              onClick={handleConfirmPasswordViewToggle}
                            >
                              {isConfirmPassword ? (
                                <IconButton
                                  tabIndex={-1}
                                  title='View Confirm Password'
                                >
                                  <VisibilityIcon color='primary' />
                                </IconButton>
                              ) : (
                                <IconButton
                                  tabIndex={-1}
                                  title='Hide Confirm Password'
                                >
                                  <VisibilityOffIcon color='primary' />
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        data-cy='changePwd_confirmPwd'
                      />
                    </Grid>
                  </Grid>
                </Style.StyledCardContent>
                <DialogActions>
                  <Styles.ButtonGrid container>
                    <Styles.StyledSubmitButton
                      type='submit'
                      color='primary'
                      disabled={isSubmitting}
                      data-cy='changePwd_submit'
                    >
                      CHANGE PASSWORD
                    </Styles.StyledSubmitButton>

                    <Styles.StyledCancelButton
                      onClick={backToPreviousPage}
                      data-cy='changePwd_cancel'
                    >
                      CANCEL
                    </Styles.StyledCancelButton>
                  </Styles.ButtonGrid>
                </DialogActions>
              </form>
            </Style.StyledDialogContent>
            <Prompt
              when={shouldBlockNavigation}
              message='You have unsaved edits, are you sure you want to leave?'
            />
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default ChangePassword;
