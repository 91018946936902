import React from 'react';
import { FormikHandlers, FormikErrors, FormikHelpers, getIn } from 'formik';

// material ui
import { Grid, TextField, MenuItem, } from '@material-ui/core';
import { DocumentsDto } from '../../documents/document.dtos';
import { ClaimDto } from '../claims.dtos';
import * as Styles from '../../../common/styles/styles';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { documentTypeOptions } from '../options';



interface DocumentActionProps extends React.HTMLProps<React.ReactNode> {
  values: DocumentsDto;
  errors: FormikErrors<ClaimDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setShouldBlockNavigation: (o: boolean) => void;
  setFieldValue: FormikHelpers<ClaimDto>["setFieldValue"];
  userOptions: { name: string, id: number }[];
  isClientUser: boolean;
  isReadOnly: boolean;
  index?: number;
}

const DocumentActions: React.FC<DocumentActionProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setShouldBlockNavigation,
    setFieldValue,
    userOptions,
    index,
    isClientUser,
    isReadOnly
  }) => {
  

  return (
    <Styles.IndentGrid container item xs={12} spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <KeyboardDatePicker
          margin="normal"
          id="dateReceived"
          name={`documents[${index}].dateReceived`}
          label="Date Received"
          format="MM/DD/yyyy"
          value={values?.dateReceived || null}
          data-cy="claim_claimant_dateReceived"
          fullWidth
          disabled={isClientUser || isReadOnly}
          onChange={(date: MaterialUiPickersDate) => setFieldValue(`documents[${index}].dateReceived`, date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          error={Boolean(getIn(errors, `documents[${index}].dateReceived`))}
          helperText={Boolean(getIn(errors, `documents[${index}].dateReceived`)) &&
            getIn(errors, `documents[${index}].dateReceived`)}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <KeyboardDatePicker
          margin="normal"
          id="dueDate"
          name={`documents[${index}].dueDate`}
          label="Due Date"
          format="MM/DD/yyyy"
          InputLabelProps={{ required: true }}
          value={values?.dueDate || null}
          data-cy="claim_claimant_dueDate"
          fullWidth
          disabled={isClientUser || isReadOnly}
          onChange={(date: MaterialUiPickersDate) => setFieldValue(`documents[${index}].dueDate`, date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          error={Boolean(getIn(errors, `documents[${index}].dueDate`))}
          helperText={Boolean(getIn(errors, `documents[${index}].dueDate`)) &&
            getIn(errors, `documents[${index}].dueDate`)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          // id="status"
          id={`documents[${index}].status`}
          size="small"
          margin="none"
          name={`documents[${index}].status`}
          select
          disabled={isClientUser || isReadOnly}
          value={values?.status || 0}
          label="Document Status"
          onChange={e => { handleChange(e) }}
          onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
          error={Boolean(getIn(errors, `documents[${index}].status`))}
          helperText={Boolean(getIn(errors, `documents[${index}].status`)) &&
            getIn(errors, `documents[${index}].status`)}
          data-cy="claim_documents_status"
          fullWidth
        >
          {userOptions
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((item) => item.name !== '')
            .map((option, index) => (
              <MenuItem key={option.name + index} value={option.id}>{option.name}</MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id={`documents[${index}].type`}
          size="small"
          margin="none"
          disabled={isClientUser || isReadOnly}
          select
          InputLabelProps={{ required: true }}
          value={values?.type || 0}
          label="Document Type"
          name={`documents[${index}].type`}
          onChange={e => { handleChange(e) }}
          onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
          error={Boolean(getIn(errors, `documents[${index}].type`))}
          helperText={Boolean(getIn(errors, `documents[${index}].type`)) &&
            getIn(errors, `documents[${index}].type`)}
          data-cy="claim_documents_type"
          fullWidth
        >
          {documentTypeOptions
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((item) => item.name !== '')
            .map((option, index) => (
              <MenuItem key={option.name + index} value={option.id}>{option.name}</MenuItem>
            ))}
        </TextField>
      </Grid>
    </Styles.IndentGrid>
  )
}
export default DocumentActions;