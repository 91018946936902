import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { getCompanyCustomerSettingsActions, updateCompanyCustomerSettingsActions } from './customers.actions';
import { CompanyCustomerSettingsDto, companyCustomerSettingsInit } from './customers.dtos';

const companyCustomerSettingsReducer = combineReducers({
  companyCustomerSettings: createReducer([] as CompanyCustomerSettingsDto[])
    .handleAction(
      [
        getCompanyCustomerSettingsActions.success,
        updateCompanyCustomerSettingsActions.request,
        updateCompanyCustomerSettingsActions.success,
      ], (state, action) => action.payload),

  getCompanyCustomerSettings: createReducer(companyCustomerSettingsInit)
    .handleAction(
      [
        getCompanyCustomerSettingsActions.request,
      ], (state, action) => state),
});

export default companyCustomerSettingsReducer;