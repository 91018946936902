

export interface EligibilityCodeDto {

  name: string,
  description: string,
  isActive: boolean,
  id?: string
};

export interface LocationCodeDto {

  name: string,
  description: string,
  isActive: boolean,
  id?: number
};

export interface SeparationCodeDto {

  name: string,
  description: string,
  isActive: boolean,
  separationCodeTypeId?: number,
  codeClaimTypeId?: number,
  id?: string,
};

export interface CodesDto {

  eligibilityCodes: EligibilityCodeDto[],
  separationCodes: SeparationCodeDto[],
  locationCodes: LocationCodeDto[]

}



export const EligiblityCodeInit: EligibilityCodeDto = {

  name: '',
  description: '',
  isActive: true,
}
export const LocationCodeInit: LocationCodeDto = {

  name: '',
  description: '',
  isActive: true,
}

export const SeparationCodeInit: SeparationCodeDto = {

  name: '',
  isActive: true,
  description: '',
  separationCodeTypeId: 0,
  codeClaimTypeId: 0,
}

export const CodesInit: CodesDto = {
  eligibilityCodes: [] as EligibilityCodeDto[],
  separationCodes: [] as SeparationCodeDto[],
  locationCodes: [] as LocationCodeDto[]
}

