


import { ISelectOption } from "../dtos/SelectOptionDto";
enum EmployerSepReasonCodes {
        Item1 = 0,
        Item2 = 1,
        Item3 = 2,
        Item4 = 3,
        Item5 = 4,
        Item6 = 5,
        Item7 = 6,
        Item8 = 7,
        Item9 = 8,
        Item10 = 9,
        Item11 = 10,
        Item12 = 11,
        Item13 = 12,
        Item14 = 13,
        Item15 = 14,
        Item16 = 15,
        Item17 = 16,
        Item18 = 17,
        Item19 = 18,
        Item20 = 19,
        Item21 = 20,
        Item23 = 21,
        Item99 = 22,
         
}

export class EmployerSepReasonCodes_class {

public static enum = EmployerSepReasonCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['Item1']: 0,
    ['Item2']: 1,
    ['Item3']: 2,
    ['Item4']: 3,
    ['Item5']: 4,
    ['Item6']: 5,
    ['Item7']: 6,
    ['Item8']: 7,
    ['Item9']: 8,
    ['Item10']: 9,
    ['Item11']: 10,
    ['Item12']: 11,
    ['Item13']: 12,
    ['Item14']: 13,
    ['Item15']: 14,
    ['Item16']: 15,
    ['Item17']: 16,
    ['Item18']: 17,
    ['Item19']: 18,
    ['Item20']: 19,
    ['Item21']: 20,
    ['Item23']: 21,
    ['Item99']: 22,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "1- Temporary Layoff",
    [1]: "2- Laid Off/Lack of Work",
    [2]: "3- Fired/Discharged",
    [3]: "4- Vacation/ Holiday Shutdown",
    [4]: "5- Asked to Resign",
    [5]: "6- Voluntary Quit / Separation",
    [6]: "7- Educational Institution Employee Between Semesters or Terms, Likely to Return",
    [7]: "8- Educational Institution Employee Between Semesters or Terms, Not Likely to Return",
    [8]: "9- Still Employed, Full Time",
    [9]: "10- Still Employed, Part Time",
    [10]: "11- Still Employed, Hours Reduced by Employer",
    [11]: "12- On Call or Temporary Status",
    [12]: "13- Leave of Absence",
    [13]: "14- Retirement",
    [14]: "15- Suspension",
    [15]: "16- Labor Dispute",
    [16]: "17- Professional Athlete Between Sports Seasons",
    [17]: "18- Disaster Related",
    [18]: "19- Not Listed Above",
    [19]: "20- Never Employed Here",
    [20]: "21- TPA does not represent this employer",
    [21]: "23- PEO does not represent this employer",
    [22]: "99- Refuse To Provide",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "1- Temporary Layoff"},
  {value: 1, text: "2- Laid Off/Lack of Work"},
  {value: 2, text: "3- Fired/Discharged"},
  {value: 3, text: "4- Vacation/ Holiday Shutdown"},
  {value: 4, text: "5- Asked to Resign"},
  {value: 5, text: "6- Voluntary Quit / Separation"},
  {value: 6, text: "7- Educational Institution Employee Between Semesters or Terms, Likely to Return"},
  {value: 7, text: "8- Educational Institution Employee Between Semesters or Terms, Not Likely to Return"},
  {value: 8, text: "9- Still Employed, Full Time"},
  {value: 9, text: "10- Still Employed, Part Time"},
  {value: 10, text: "11- Still Employed, Hours Reduced by Employer"},
  {value: 11, text: "12- On Call or Temporary Status"},
  {value: 12, text: "13- Leave of Absence"},
  {value: 13, text: "14- Retirement"},
  {value: 14, text: "15- Suspension"},
  {value: 15, text: "16- Labor Dispute"},
  {value: 16, text: "17- Professional Athlete Between Sports Seasons"},
  {value: 17, text: "18- Disaster Related"},
  {value: 18, text: "19- Not Listed Above"},
  {value: 19, text: "20- Never Employed Here"},
  {value: 20, text: "21- TPA does not represent this employer"},
  {value: 21, text: "23- PEO does not represent this employer"},
  {value: 22, text: "99- Refuse To Provide"},
  ]
}

