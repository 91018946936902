import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Prompt } from 'react-router';
import {
  Card, CardContent, Checkbox, Grid, FormControlLabel,
  Typography, Box, Radio
} from '@material-ui/core';
import { getType } from 'typesafe-actions';
import BottomActionBar from '../../../../common/components/bottomActionBar';
//import Label from '../../../../common/components/label';
import * as Style from '../customers.styles';
import * as Styles from '../../../../common/styles/styles';

import { CompanyCustomerSettingsDto, CompanySettingsDto, companyCustomerSettingsInit, companySettingsInit, setting1init, setting2init, setting3init } from '../customers.dtos';
import { getCompanyCustomerSettingsActions } from '../customers.actions';
import { updateCompanyCustomerSettingsActions } from '../customers.actions';
import { rootSelector } from '../../../../common/selectors/selectors';
import { howTheyFoundUs, socialChannels, paymentTerms, requiredError } from '../../../../common/constants/strings';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';


const CompanySettings: React.FC = () => {
  const dispatch = useDispatch();

  const companySettingsOptions = [howTheyFoundUs, socialChannels, paymentTerms, 'Placeholder (Not Functional)'];
  const companySettings = rootSelector(state => state.companyCustomerSettings.companyCustomerSettings);
  const [settings, setSettings] = React.useState(companyCustomerSettingsInit as CompanyCustomerSettingsDto[]);
  const [shouldBlockNavigation, setShouldBlockNavigation] = React.useState(false);
  const [settingIndex, setSettingIndex] = React.useState(0 as number);
  //Was hideActive
  const [hideAddNew, setHideAddNew] = React.useState(true);

  const [savingInput, setSavingInput] = React.useState(false);


  React.useEffect(() => {
    if (companySettings.length < 3) {
      let settings = companySettings;
      if (settings[0] === undefined) { settings.push(setting1init); }
      if (settings[1] === undefined) { settings.push(setting2init); }
      if (settings[2] === undefined) { settings.push(setting3init); }

      setSettings(settings);
    }
    else {
      setSettings(companySettings);
    }
    setShouldBlockNavigation(false);
  }, [companySettings]);


  React.useEffect(() => {
    dispatch({ type: getType(getCompanyCustomerSettingsActions.request) });
  }, [dispatch]);

  const handleCancel = () => {
    window.location.href = "/settings/customers";
  }

  const handleAddNewToggle = () => {
    setHideAddNew(false);
  }
  const handleSubmit = () => {
    setShouldBlockNavigation(false);
    setSavingInput(true);
    setTimeout(() => { setSavingInput(false); }, 700);
    let cs = JSON.parse(JSON.stringify(settings));

    setSettings(cs);
    settings.forEach((setting: CompanyCustomerSettingsDto) => {
      if ((setting.settingId !== undefined && setting.settingId.startsWith('isNew-')) || setting.settingId === '') {
        delete setting.settingId
      }
    });
    setShouldBlockNavigation(false);
    dispatch({ type: getType(updateCompanyCustomerSettingsActions.request), payload: settings });
  }




  const handleActiveSettingSelection = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
    const element = event.target as HTMLInputElement;
    if (element.value !== undefined) {
      let selectedSetting = settings[settingIndex].settings.find((cs: CompanySettingsDto) => cs.setting === element.value);
      if (selectedSetting) {
        selectedSetting.isActive = checked;
        setSettings(JSON.parse(JSON.stringify(settings)));
      }
    }
    setShouldBlockNavigation(true);
  }

  const handleSettingSelection = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
    const element = event.target as HTMLInputElement;
    if (element.value !== undefined) {
      let updatedSettingIndex = companySettingsOptions.findIndex((cs: string) => cs === element.value);
      if (updatedSettingIndex > -1) {
        setSettingIndex(updatedSettingIndex);
      }
    }
  }

  return <>
    <Box p={2}>
      <Card>
        <CardContent>
          <Grid container aria-label="Company Settings Container" data-cy="settings_customerCompanyContainer">
            <Grid container spacing={2} data-cy="settings_companyOptionSelection">
              <Grid item xs={12}><Typography variant="subtitle1">Company Details Dropdown</Typography></Grid>

              {companySettingsOptions?.map((option, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={option + index}>
                  <FormControlLabel checked={index === settingIndex} value={option} onChange={handleSettingSelection} control={<Radio size="small" />} label={companySettingsOptions[index]} data-cy="settings_settingSelection" />
                </Grid>
              ))}
            </Grid>
            <br />
            <Grid item xs={12} />
            <br />
            <br />

            <Grid container data-cy="settings_customerCompanySelectionAdditionContainer" spacing={1}>
              <Grid item xs={11}><Typography variant="subtitle1">Dropdown Options</Typography></Grid>
              <Grid item xs={1}>
                <Styles.LargeIconButton title="Add new setting" aria-label="Add new setting" color="primary" data-cy="settings_customerCompanyAddSetting" onClick={handleAddNewToggle}><AddBoxRoundedIcon /></Styles.LargeIconButton>
              </Grid>
              <Style.SystemSettingsToggleGrid hidden={hideAddNew} item xs={12}>
                <Formik initialValues={companySettingsInit}
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={Yup.object({
                    setting: Yup.string()
                      .min(1, 'Please enter an option')
                      .max(200, 'Not more than 200 charcters')
                      .required(requiredError),
                  })}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    setTimeout(() => { setSubmitting(false); resetForm(); }, 700);
                    let settingsCopy = JSON.parse(JSON.stringify(settings));

                    values.id = 'isNew-' + Date.now().toString();

                    settingsCopy[settingIndex].settings.push(values);

                    setSettings(settingsCopy);
                    setShouldBlockNavigation(true);

                    resetForm();

                  }} >
                  {(props) => {
                    const { values, touched, errors, isSubmitting, handleChange, handleSubmit, handleReset } = props;
                    return (
                      <Style.PaddedForm onSubmit={handleSubmit} data-cy="settings_customerCompanyAddNewElement">
                        <Styles.StyledTextField
                          id="role-name"
                          size="small"
                          margin="none"
                          InputLabelProps={{ required: true }}
                          value={values.setting}
                          label="Setting"
                          name="setting"
                          onChange={handleChange}
                          autoFocus
                          error={Boolean(errors.setting)}
                          helperText={(errors.setting && touched.setting) && errors.setting}
                          data-cy="settings_customerCompanyAddOption"
                        />
                        <Style.AddButton type="submit" variant="contained" color="primary" data-cy="settings_customerCompanySaveOption" disabled={isSubmitting} >Add </Style.AddButton>
                        <Style.CancelButton type="button" variant="contained" color="primary" data-cy="settings_customerCompanyCancelOption" onClick={(e) => { handleReset(e); setHideAddNew(true); }}>Cancel</Style.CancelButton>

                      </Style.PaddedForm>
                    );

                  }}
                </Formik>
              </Style.SystemSettingsToggleGrid>
              {settings[settingIndex]?.settings.map((setting, index) => (
                <Grid item xs={12} sm={6} md={3} lg={3} key={setting.setting}>
                  <FormControlLabel onChange={handleActiveSettingSelection} value={setting.setting} checked={setting.isActive}
                    control={<Checkbox size="small" name={setting.setting} />} label={setting.setting} data-cy="settings_customerCompanySettingsOptions" />
                </Grid>
              ))}
            </Grid>

          </Grid>

        </CardContent>
      </Card>
    </Box>


    <Prompt
      when={shouldBlockNavigation}
      message='You have unsaved edits, are you sure you want to leave?'
    />
    <BottomActionBar cancelClickHandler={handleCancel} submitClickHandler={handleSubmit} submitting={savingInput} />
  </>

}

export default CompanySettings;