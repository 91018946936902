import { createAsyncAction, createAction } from 'typesafe-actions';
import { EmailTemplateDto, EmailTemplateRequestDto, EmailTemplateRequestWrapper } from '../dtos/emailTemplateDto';

export const getEmailTemplatesActions = createAsyncAction(
  'GET_EMAIL_TEMPLATES_REQUEST',
  'GET_EMAIL_TEMPLATES_SUCCESS',
  'GET_EMAIL_TEMPLATES_ERROR',
)<undefined, EmailTemplateDto[], string>();

export const getEmailTemplateActions = createAsyncAction(
  'GET_EMAIL_TEMPLATE_REQUEST',
  'GET_EMAIL_TEMPLATE_SUCCESS',
  'GET_EMAIL_TEMPLATE_ERROR',
)<EmailTemplateRequestWrapper, EmailTemplateDto, string>();


export const getEditEmailTemplateActions = createAsyncAction(
    'GET_EDIT_EMAIL_TEMPLATE_REQUEST',
    'GET_EDIT_EMAIL_TEMPLATE_SUCCESS',
    'GET_EDIT_EMAIL_TEMPLATE_ERROR',
)<string, EmailTemplateDto, string>();

export const createEmailTemplateActions = createAsyncAction(
    'CREATE_EMAIL_TEMPLATE_REQUEST',
    'CREATE_EMAIL_TEMPLATE_SUCCESS',
    'CREATE_EMAIL_TEMPLATE_ERROR',
)<EmailTemplateDto, EmailTemplateDto, string>();


export const updateEmailTemplateActions = createAsyncAction(
    'UPDATE_EMAIL_TEMPLATE_REQUEST',
    'UPDATE_EMAIL_TEMPLATE_SUCCESS',
    'UPDATE_EMAIL_TEMPLATE_ERROR',
)<EmailTemplateDto, EmailTemplateDto, string>();


export const toggleEmailTemplateDialog = createAction('SHOW_EMAIL_TEMPLATE_DIALOG')<boolean>();
export const openReachOutEmailTemplateDialog = createAction('OPEN_REACHOUT_EMAIL_TEMPLATE_DIALOG')<boolean>();


export const downloadActionListDocument = createAction('ACTION_LIST_DOCUMENT_NAME')<string>();

export const emailTemplateRecipient = createAction('EMAIL_TEMPLATE_RECIPIENT')<string>();