 
/* Autogenerated by Typewriter do NOT edit
   Dtos.tst is the generating template */

import {IClaim} from './ClaimDto';
import {IUisMcAttachment} from './UisMcAttachmentDto';
import {IUisMcEmployerCharge} from './UisMcEmployerChargeDto';

export interface IMc  { 
    
    claim?: IClaim;
    uisMcAttachments?: IUisMcAttachment[];
    uisMcEmployerCharges?: IUisMcEmployerCharge[];
    documentId?: string;
    isReadyToRespond?: boolean;
    brokerRecordTransactionNumber?: string;
    batchBrokerRecordTransactionNumber?: string;
    stateRequestRecordGuid?: string;
    sender?: string;
    requestingStateAbbreviation?: number;
    requestUiofficeName?: string;
    requestUiofficePhone?: string;
    requestUiofficeFax?: string;
    requestUiofficeEmailAddress?: string;
    fein?: string;
    stateEmployerAccountNbr?: string;
    predecessorEmployerAccountNbr?: string;
    employerName?: string;
    employerAccountType?: number;
    typeofEmployerCode?: number;
    requestforReliefofChargesAllowedInd?: number;
    pin?: string;
    ssn?: string;
    claimantLastName?: string;
    otherLastName?: string;
    claimantFirstName?: string;
    claimantMiddleInitial?: string;
    claimantSuffix?: string;
    dependents?: string;
    claimNumber?: string;
    formNumber?: string;
    benefitYearBeginDate?: Date;
    requestDate?: Date;
    responseDueDate?: Date;
    responseRequired?: number;
    basePeriodStartDate?: Date;
    basePeriodEndDate?: Date;
    numberEmployersInBasePeriod?: string;
    basePeriodWagesAllEmployers?: number;
    basePeriodWagesThisEmployer?: number;
    weeklyBenefitAmount?: number;
    maximumBenefitAmount?: number;
    totalWeeksOfBenefits?: string;
    percentChargableEmployer?: number;
    totalEmployerCharge?: number;
    claimantSepReasonCode?: number;
    employerSepQuestionShownInd?: number;
    wageInstructPhone?: string;
    wageInstructEmail?: string;
    wageInstructUrl?: string;
    wagesInFirstQuarter?: number;
    wagesInSecondQuarter?: number;
    wagesInThirdQuarter?: number;
    wagesInFourthQuarter?: number;
    claimEffectiveDate?: Date;
    respondedDateTime?: Date;
    amendedResponse?: number;
    amendedResponseDescription?: string;
    correctedStateEmployerAccountNbr?: string;
    correctedEmployerName?: string;
    correctedFein?: string;
    otherSsn?: string;
    claimantNameWorkedAsForEmployer?: string;
    employerSepReasonAnsweredInd?: number;
    fraudInd?: number;
    fraudReasons?: number;
    fraudComments?: string;
    temporaryStaffingInd?: number;
    relationshipEndedInd?: number;
    workingNewAssignmentInd?: number;
    notWorkingOnAssignment?: number;
    seasonalEmploymentInd?: number;
    employerReportedDependents?: string;
    lastDayWorked?: Date;
    requestReliefofChargesInd?: number;
    refuseToProvideInd?: number;
    dischargeReasonCode?: number;
    voluntarySepReasonCode?: number;
    employerSeparationReasonCode?: number;
    employerResponseComments?: string;
    additionalSeparationAckInd?: number;
    attachmentInd?: number;
    preparerTypeCode?: number;
    preparerCompanyName?: string;
    preparerTelephoneNumberPlusExt?: string;
    preparerContactName?: string;
    preparerTitle?: string;
    preparerFaxNbr?: string;
    preparerEmailAddress?: string;
    employerContactName?: string;
    employerPhone?: string;
    employerStreetAddress?: string;
    employerCity?: string;
    employerState?: number;
    employerZip?: string;
    repContractTermDate?: Date; 
} 

export class IMc_PropertyAttributes {
    static readonly claim_Attributes = {
      required: { value: true }, name: "Claim", 
      };
       static readonly uisSiAttachments_Attributes = {
      required: { value: false }, name: "UisSiAttachments", 
      };
       static readonly uisMcEmployerCharges_Attributes = {
      required: { value: false }, name: "UisMcEmployerCharges", 
      };
       static readonly documentId_Attributes = {
      required: { value: false }, name: "DocumentId", 
      };
       static readonly isReadyToRespond_Attributes = {
      required: { value: true }, name: "IsReadyToRespond", 
      };
       static readonly brokerRecordTransactionNumber_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 32, }, name: "BrokerRecordTransactionNumber", 
      };
       static readonly batchBrokerRecordTransactionNumber_Attributes = {
      stringLength: { maxLength: 40, }, required: { value: false }, name: "BatchBrokerRecordTransactionNumber", 
      };
       static readonly stateRequestRecordGuid_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 32, }, name: "StateRequestRecordGuid", 
      };
       static readonly sender_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 10, }, name: "Sender", 
      };
       static readonly requestingStateAbbreviation_Attributes = {
      required: { value: true }, name: "RequestingStateAbbreviation", 
      };
       static readonly requestUiofficeName_Attributes = {
      stringLength: { maxLength: 25, }, required: { value: false }, name: "RequestUiofficeName", 
      };
       static readonly requestUiofficePhone_Attributes = {
      stringLength: { maxLength: 15, }, required: { value: false }, name: "RequestUiofficePhone", 
      };
       static readonly requestUiofficeFax_Attributes = {
      stringLength: { maxLength: 10, }, required: { value: false }, name: "RequestUiofficeFax", 
      };
       static readonly requestUiofficeEmailAddress_Attributes = {
      stringLength: { maxLength: 60, }, required: { value: false }, name: "RequestUiofficeEmailAddress", 
      };
       static readonly fein_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 9, }, name: "Fein", 
      };
       static readonly stateEmployerAccountNbr_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 20, }, name: "StateEmployerAccountNbr", 
      };
       static readonly predecessorEmployerAccountNbr_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 20, }, name: "PredecessorEmployerAccountNbr", 
      };
       static readonly employerName_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 100, }, name: "EmployerName", 
      };
       static readonly employerAccountType_Attributes = {
      required: { value: true }, name: "EmployerAccountType", 
      };
       static readonly typeofEmployerCode_Attributes = {
      required: { value: false }, name: "TypeofEmployerCode", 
      };
       static readonly requestforReliefofChargesAllowedInd_Attributes = {
      required: { value: true }, name: "RequestforReliefofChargesAllowedInd", 
      };
       static readonly pin_Attributes = {
      stringLength: { maxLength: 20, }, required: { value: false }, name: "Pin", 
      };
       static readonly ssn_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 9, }, name: "Ssn", 
      };
       static readonly claimantLastName_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 30, }, name: "ClaimantLastName", 
      };
       static readonly otherLastName_Attributes = {
      stringLength: { maxLength: 30, }, required: { value: false }, name: "OtherLastName", 
      };
       static readonly claimantFirstName_Attributes = {
      required: { value: true,  }, stringLength: { maxLength: 25, }, name: "ClaimantFirstName", 
      };
       static readonly claimantMiddleInitial_Attributes = {
      stringLength: { maxLength: 1, }, required: { value: false }, name: "ClaimantMiddleInitial", 
      };
       static readonly claimantSuffix_Attributes = {
      stringLength: { maxLength: 8, }, required: { value: false }, name: "ClaimantSuffix", 
      };
       static readonly dependents_Attributes = {
      stringLength: { maxLength: 1, }, required: { value: false }, name: "Dependents", 
      };
       static readonly claimNumber_Attributes = {
      stringLength: { maxLength: 18, }, required: { value: false }, name: "ClaimNumber", 
      };
       static readonly formNumber_Attributes = {
      stringLength: { maxLength: 12, }, required: { value: false }, name: "FormNumber", 
      };
       static readonly benefitYearBeginDate_Attributes = {
      required: { value: true }, name: "BenefitYearBeginDate", 
      };
       static readonly requestDate_Attributes = {
      required: { value: true }, name: "RequestDate", 
      };
       static readonly responseDueDate_Attributes = {
      required: { value: true }, name: "ResponseDueDate", 
      };
       static readonly responseRequired_Attributes = {
      required: { value: true }, name: "ResponseRequired", 
      };
       static readonly basePeriodStartDate_Attributes = {
      required: { value: true }, name: "BasePeriodStartDate", 
      };
       static readonly basePeriodEndDate_Attributes = {
      required: { value: true }, name: "BasePeriodEndDate", 
      };
       static readonly numberEmployersInBasePeriod_Attributes = {
      stringLength: { maxLength: 3, }, required: { value: false }, name: "NumberEmployersInBasePeriod", 
      };
       static readonly basePeriodWagesAllEmployers_Attributes = {
      required: { value: false }, name: "BasePeriodWagesAllEmployers", 
      };
       static readonly basePeriodWagesThisEmployer_Attributes = {
      required: { value: false }, name: "BasePeriodWagesThisEmployer", 
      };
       static readonly weeklyBenefitAmount_Attributes = {
      required: { value: false }, name: "WeeklyBenefitAmount", 
      };
       static readonly maximumBenefitAmount_Attributes = {
      required: { value: false }, name: "MaximumBenefitAmount", 
      };
       static readonly totalWeeksOfBenefits_Attributes = {
      stringLength: { maxLength: 2, }, required: { value: false }, name: "TotalWeeksOfBenefits", 
      };
       static readonly percentChargableEmployer_Attributes = {
      required: { value: false }, name: "PercentChargableEmployer", 
      };
       static readonly totalEmployerCharge_Attributes = {
      required: { value: false }, name: "TotalEmployerCharge", 
      };
       static readonly claimantSepReasonCode_Attributes = {
      required: { value: false }, name: "ClaimantSepReasonCode", 
      };
       static readonly employerSepQuestionShownInd_Attributes = {
      required: { value: false }, name: "EmployerSepQuestionShownInd", 
      };
       static readonly wageInstructPhone_Attributes = {
      stringLength: { maxLength: 15, }, required: { value: false }, name: "WageInstructPhone", 
      };
       static readonly wageInstructEmail_Attributes = {
      stringLength: { maxLength: 60, }, required: { value: false }, name: "WageInstructEmail", 
      };
       static readonly wageInstructUrl_Attributes = {
      stringLength: { maxLength: 2048, }, required: { value: false }, name: "WageInstructUrl", 
      };
       static readonly wagesInFirstQuarter_Attributes = {
      required: { value: false }, name: "WagesInFirstQuarter", 
      };
       static readonly wagesInSecondQuarter_Attributes = {
      required: { value: false }, name: "WagesInSecondQuarter", 
      };
       static readonly wagesInThirdQuarter_Attributes = {
      required: { value: false }, name: "WagesInThirdQuarter", 
      };
       static readonly wagesInFourthQuarter_Attributes = {
      required: { value: false }, name: "WagesInFourthQuarter", 
      };
       static readonly claimEffectiveDate_Attributes = {
      required: { value: false }, name: "ClaimEffectiveDate", 
      };
       static readonly respondedDateTime_Attributes = {
      required: { value: false }, name: "RespondedDateTime", 
      };
       static readonly amendedResponse_Attributes = {
      required: { value: true }, name: "AmendedResponse", 
      };
       static readonly amendedResponseDescription_Attributes = {
      stringLength: { maxLength: 2000, }, required: { value: false }, name: "AmendedResponseDescription", 
      };
       static readonly correctedStateEmployerAccountNbr_Attributes = {
      stringLength: { maxLength: 20, }, required: { value: false }, name: "CorrectedStateEmployerAccountNbr", 
      };
       static readonly correctedEmployerName_Attributes = {
      stringLength: { maxLength: 100, }, required: { value: false }, name: "CorrectedEmployerName", 
      };
       static readonly correctedFein_Attributes = {
      stringLength: { maxLength: 9, }, required: { value: false }, name: "CorrectedFein", 
      };
       static readonly otherSsn_Attributes = {
      stringLength: { maxLength: 9, }, required: { value: false }, name: "OtherSsn", 
      };
       static readonly claimantNameWorkedAsForEmployer_Attributes = {
      stringLength: { maxLength: 70, }, required: { value: false }, name: "ClaimantNameWorkedAsForEmployer", 
      };
       static readonly employerSepReasonAnsweredInd_Attributes = {
      required: { value: false }, name: "EmployerSepReasonAnsweredInd", 
      };
       static readonly fraudInd_Attributes = {
      required: { value: false }, name: "FraudInd", 
      };
       static readonly fraudReasons_Attributes = {
      required: { value: false }, name: "FraudReasons", 
      };
       static readonly fraudComments_Attributes = {
      stringLength: { maxLength: 100, }, required: { value: false }, name: "FraudComments", 
      };
       static readonly temporaryStaffingInd_Attributes = {
      required: { value: false }, name: "TemporaryStaffingInd", 
      };
       static readonly relationshipEndedInd_Attributes = {
      required: { value: false }, name: "RelationshipEndedInd", 
      };
       static readonly workingNewAssignmentInd_Attributes = {
      required: { value: false }, name: "WorkingNewAssignmentInd", 
      };
       static readonly notWorkingOnAssignment_Attributes = {
      required: { value: false }, name: "NotWorkingOnAssignment", 
      };
       static readonly seasonalEmploymentInd_Attributes = {
      required: { value: false }, name: "SeasonalEmploymentInd", 
      };
       static readonly employerReportedDependents_Attributes = {
      stringLength: { maxLength: 1, }, required: { value: false }, name: "EmployerReportedDependents", 
      };
       static readonly lastDayWorked_Attributes = {
      required: { value: false }, name: "LastDayWorked", 
      };
       static readonly requestReliefofChargesInd_Attributes = {
      required: { value: false }, name: "RequestReliefofChargesInd", 
      };
       static readonly refuseToProvideInd_Attributes = {
      required: { value: false }, name: "RefuseToProvideInd", 
      };
       static readonly dischargeReasonCode_Attributes = {
      required: { value: false }, name: "DischargeReasonCode", 
      };
       static readonly voluntarySepReasonCode_Attributes = {
      required: { value: false }, name: "VoluntarySepReasonCode", 
      };
       static readonly employerSeparationReasonCode_Attributes = {
      required: { value: false }, name: "EmployerSeparationReasonCode", 
      };
       static readonly employerResponseComments_Attributes = {
      stringLength: { maxLength: 2000, }, required: { value: false }, name: "EmployerResponseComments", 
      };
       static readonly additionalSeparationAckInd_Attributes = {
      required: { value: false }, name: "AdditionalSeparationAckInd", 
      };
       static readonly attachmentInd_Attributes = {
      required: { value: false }, name: "AttachmentInd", 
      };
       static readonly preparerTypeCode_Attributes = {
      required: { value: false }, name: "PreparerTypeCode", 
      };
       static readonly preparerCompanyName_Attributes = {
      stringLength: { maxLength: 60, }, required: { value: false }, name: "PreparerCompanyName", 
      };
       static readonly preparerTelephoneNumberPlusExt_Attributes = {
      stringLength: { maxLength: 15, }, required: { value: false }, name: "PreparerTelephoneNumberPlusExt", 
      };
       static readonly preparerContactName_Attributes = {
      stringLength: { maxLength: 60, }, required: { value: false }, name: "PreparerContactName", 
      };
       static readonly preparerTitle_Attributes = {
      stringLength: { maxLength: 30, }, required: { value: false }, name: "PreparerTitle", 
      };
       static readonly preparerFaxNbr_Attributes = {
      stringLength: { maxLength: 10, }, required: { value: false }, name: "PreparerFaxNbr", 
      };
       static readonly preparerEmailAddress_Attributes = {
      stringLength: { maxLength: 60, }, required: { value: false }, name: "PreparerEmailAddress", 
      };
       static readonly employerContactName_Attributes = {
      stringLength: { maxLength: 60, }, required: { value: false }, name: "EmployerContactName", 
      };
       static readonly employerPhone_Attributes = {
      stringLength: { maxLength: 15, }, required: { value: false }, name: "EmployerPhone", 
      };
       static readonly employerStreetAddress_Attributes = {
      stringLength: { maxLength: 35, }, required: { value: false }, name: "EmployerStreetAddress", 
      };
       static readonly employerCity_Attributes = {
      stringLength: { maxLength: 19, }, required: { value: false }, name: "EmployerCity", 
      };
       static readonly employerState_Attributes = {
      required: { value: false }, name: "EmployerState", 
      };
       static readonly employerZip_Attributes = {
      stringLength: { maxLength: 19, }, required: { value: false }, name: "EmployerZip", 
      };
       static readonly repContractTermDate_Attributes = {
      required: { value: false }, name: "RepContractTermDate", 
      };
      
}

