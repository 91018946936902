import * as Yup from 'yup';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const phonesValidationSchema = Yup.array().of(Yup.object().shape({
  contactTypeId: Yup.number().min(0).max(8),
  phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').nullable(),
  extension: Yup.number().min(0).max(9999999999).nullable(),
}));


export interface PhoneDto {
  phoneNumber: string, //($tel)
  contactLinkId?: string, //($uuid)
  contactTypeId: number,
  extension?: string,
  //maxLength: 500
  label?: string,
  //maxLength: 50
  isPrimary: boolean,
}

export const phoneInit: PhoneDto = {
  contactTypeId: 1,
  phoneNumber: '',
  isPrimary: false,
};