import { Grid, GridSize, InputLabelProps } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { FormikErrors, FormikHandlers, getIn, FormikHelpers } from 'formik';
import React from 'react';
import { ISi } from '../../../../common/dtos/SiDto';
import { AlignedDatePicker } from '../../documents.styles';

interface Props {
  label: string;
  name: string;
  value: Date | undefined | null;
  errors: FormikErrors<ISi>;
  setFieldValue: FormikHelpers<ISi>["setFieldValue"];
  handleBlur: FormikHandlers["handleBlur"];
  isReadOnly: boolean;
  size?: GridSize;
  inputlabelprops?: Partial<InputLabelProps>;
}

const SiDateField: React.FC<Props> = (
  {
    label,
    name,
    value,
    errors,
    setFieldValue,
    handleBlur,
    isReadOnly,
    size = 12,
    inputlabelprops,
  }) => {

  return (
    <Grid item xs={12} sm={12} md={size} lg={size}>
      <AlignedDatePicker
        margin="normal"
        name={name}
        label={label}
        fullWidth
        disabled={isReadOnly}
        format="MM/DD/yyyy"
        value={value || null}
        data-cy={`sidesResponse_${name}`}
        onChange={(date: MaterialUiPickersDate) => setFieldValue(name, date === null ? undefined : date)}
        error={Boolean(getIn(errors, name))}
        helperText={getIn(errors, name) && getIn(errors, name)}
        onBlur={handleBlur}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        InputLabelProps={inputlabelprops}
      />
    </Grid>
  );

}

export default SiDateField;