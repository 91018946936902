import { AxiosError } from 'axios';

import apiClient from '../../common/api/apiClient';
import { ApiError } from '../../common/api/apiResponse';
import { ClaimDto, ClaimEventDto, EligibilityEventDto, HearingContactsDto, AppellateEventDto, ClaimSearchDto } from './claims.dtos';
import { ClaimDocumentRequestDto } from '../documents/document.dtos';
import { showNotification } from '../../common/actionCreators/dispatchNotification';


// endpoints
const claimsUrl = "claims";
const claimsByDoumentUrl = "claims/document/";
const claimEventsUrl = 'events/claim-event';
const claimLogsUrl = 'events/claim';
const eligibilityEventsUrl = "events/eligibility";
const appellateEventsUrl = "events/appellate";
const deleteAppellateEventUrl = "events/appellate";
const deleteEligibilityEventUrl = "events/eligibility";
const mhaContactsUrl = "contacts/mha";
const mhaAppealContactsUrl = "contacts/mha-appeal-contacts";
const mhaClaimsContactsUrl = "contacts/mha-claims-contacts";
const mhaHearingContactsUrl = "contacts/mha-hearing-contacts";
const mhaHearingRepresentativesUrl = "contacts/mha-hearing-representatives";
const uploadDocumentsUrl = "action-items/upload";
const documentsZipUrl = "action-items/download";
const contactsInformationUrl = "contacts/information";
const sidesUrl = "claims/sides";
const deleteUrl = "claims/";
const claimDocumentsUrl = "action-items/claim/";


//export const getClaimsApi = async (searchText?: string) => {
//  return await apiClient().get(`${claimsUrl}${!!searchText ? '?searchtext=' + searchText : ''}`)
//    .then(res => {
//      return res.data.result;
//    })
//    .catch(err => {
//      if (err && err.response) {
//        const axiosError = err as AxiosError<ApiError>;

//        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
//      }
//      else showNotification("An error occurred while getting claims.", "error");

//      throw err;
//    });
//};

export const getClaimsApi = async (search: ClaimSearchDto) => {
  return await apiClient().post(`${claimsUrl}/search`, search)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting claims.", "error");

      throw err;
    });
};

export const getAllClaimsApi = async () => {
  return await apiClient().get(`${claimsUrl}/all`)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting claims.", "error");

      throw err;
    });
};

export const getClaimApi = async (id: string) => {
  return await apiClient().get(`${claimsUrl}/${id}`)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting this claim.", "error");

      throw err;
    });
};

export const getClaimByDocumentIdApi = async (id: string) => {
  return await apiClient().get(`${claimsByDoumentUrl}${id}`)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting this claim.", "error");

      throw err;
    });
};

export const updateClaimApi = async (request: ClaimDto) => {
  return await apiClient().put(claimsUrl, request)
    .then(res => {
      showNotification("Claim updated.", "success");
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, "error");
          if (axiosError.response.data.error.message ===
            "This claim with this employer and claimant ssn and benefit year ending date already exists. already exists.")
            return false;
        }
      }
      else showNotification("An error occurred while updating this claim.", "error");

      throw err;
    });
};

export const createClaimApi = async (request: ClaimDto) => {
  return await apiClient().post(claimsUrl, request)
    .then(res => {
      showNotification("Claim saved.", "success");
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, "error");
          if (axiosError.response.data.error.message ===
            "This claim with this employer and claimant ssn and benefit year ending date already exists. already exists.")
            return false;
        }
      }
      else showNotification("An error occurred while creating claim.", "error");
      throw err;
    });
};

export const deleteClaimApi = async (request: string[]) => {
  return await apiClient().delete(`${claimsUrl}?id=${request[0]}${request[1] === undefined ? '' : '&newId=' + request[1]}`)
    .then(res => {
      if (res.data === true) {
        if (request[1] === undefined)
          showNotification("No documents for current claim. Claim successfully deleted.", "success");
        else
          showNotification("Documents have been moved. Claim successfully deleted.", "success");
        return true;
      }
      else
      return false;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while deleting claim.", "error");
      throw err;
    });
};


export const getClaimDocumentsApi = async (claimId: string) => {
  return await apiClient().get(`${claimDocumentsUrl}${claimId}`)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting claim documents.", "error");

      throw err;
    });
};

export const getMhaAppealContactsApi = async () => {
  return await apiClient().get(mhaAppealContactsUrl)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting MHA Appeals Contacts.", "error");

      throw err;
    });
};


export const getMhaHearingContactsApi = async () => {
  return await apiClient().get(mhaHearingContactsUrl)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting MHA Hearing Contacts.", "error");

      throw err;
    });
};


export const getMhaHearingRepresentativesApi = async () => {
  return await apiClient().get(mhaHearingRepresentativesUrl)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting MHA Hearing Representatives.", "error");

      throw err;
    });
};

export const getMhaContactsApi = async () => {
  return await apiClient().get(mhaContactsUrl)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting MHA Contacts.", "error");

      throw err;
    });
};

export const getMhaClaimsContactsApi = async () => {
  return await apiClient().get(mhaClaimsContactsUrl)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting MHA Claims Contacts.", "error");

      throw err;
    });
};

export const deleteClaimDocumentsApi = async (request: string) => {

  return await apiClient().delete(`${deleteUrl}/${request}`)
    .then(res => {
      showNotification("Document deleted.", "success");
      return true;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while deleting document.", "error");

      throw err;
    });
};

//Sides
export const updateSidesApi = async (request: ClaimDto) => {
  const data = new FormData();
  request.documents[0].uiSides?.general?.attachments?.forEach(f =>
    f.fileUpload !== undefined ?
      data.append(f.fileName, f.fileUpload)
      :
      null
  );
  data.append('model', JSON.stringify(request));
  return await apiClient().put(sidesUrl, data)
    .then(res => {
      if (request.documents.some(s => s.uiSides?.general?.isReadyToRespond))
        showNotification("SIDES Response Sent.", "success");
      else
        showNotification("SIDES Response Saved.", "success");
      
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) return axiosError.response.data.error.message;
      }
      else showNotification("An error occurred while setting SIDES response.", "error");

      throw err;
    });
};

export const getLogsApi = async (id: string) => {
  return await apiClient().get(`${claimLogsUrl}?claimId=${id}`)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting events.", "error");

      throw err;
    });
};
//Events

export const getEventsApi = async (id: string) => {
  return await apiClient().get(`${claimEventsUrl}?claimId=${id}`)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting events.", "error");

      throw err;
    });
};

export const createClaimEventApi = async (request: ClaimEventDto) => {
  return await apiClient().post(claimEventsUrl, request)
    .then(res => {
      showNotification("Claim event created.", "success");
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while creating claim event.", "error");

      throw err;
    });
};

export const updateClaimEventApi = async (request: ClaimEventDto) => {
  return await apiClient().put(claimEventsUrl, request)
    .then(res => {
      showNotification("Claim Event saved.", "success");
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while updating Claim Event.", "error");

      throw err;
    });
};

// Eligibility Events
export const getEligibilityEventsApi = async (id: string) => {
  return await apiClient().get(`${eligibilityEventsUrl}?claimId=${id}`)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting Eligibility events.", "error");

      throw err;
    });
};

export const updateEligibilityEventApi = async (request: EligibilityEventDto) => {
  return await apiClient().put(eligibilityEventsUrl, request)
    .then(res => {
      showNotification("Eligibility Event saved.", "success");
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while updating Eligibility Event.", "error");

      throw err;
    });
};

export const createEligibilityEventApi = async (request: EligibilityEventDto) => {
  return await apiClient().post(eligibilityEventsUrl, request)
    .then(res => {
      showNotification("Eligibility Event saved.", "success");
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while creating Eligibility Event.", "error");

      throw err;
    });
};

export const deleteEligibilityEventApi = async (request: string) => {
  return await apiClient().delete(`${deleteEligibilityEventUrl}/${request}`)
    .then(res => {
      showNotification("Eligibility Event deleted.", "success");
      return true;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while deleting document.", "error");

      throw err;
    });
};

// Appellate Events
export const getAppellateEventsApi = async (id: string) => {
  return await apiClient().get(`${appellateEventsUrl}?claimId=${id}`)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while getting Appellate events.", "error");

      throw err;
    });
};

export const deleteAppellateEventApi = async (request: string) => {

  return await apiClient().delete(`${deleteAppellateEventUrl}/${request}`)
    .then(res => {
      showNotification("Appellate Event deleted.", "success");
      return true;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while deleting document.", "error");

      throw err;
    });
};


export const updateAppellateEventApi = async (request: AppellateEventDto) => {
  return await apiClient().put(appellateEventsUrl, request)
    .then(res => {
      showNotification("Appellate Event saved.", "success");
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while updating Appellate Event.", "error");

      throw err;
    });
};

export const createAppellateEventApi = async (request: AppellateEventDto) => {
  return await apiClient().post(appellateEventsUrl, request)
    .then(res => {
      showNotification("Appellate event saved.", "success");
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while creating Appellate Event.", "error");

      throw err;
    });
};


export const uploadDocumentsApi = async (request: ClaimDocumentRequestDto) => {
  let data = new FormData();
  request.documents.forEach((d, i) => {
    if (d.fileUpload !== undefined && d.fileUpload !== null) {
      data.append(d.fileName ?? 'file_' + i, d.fileUpload);
    }
  });

  data.append('model', JSON.stringify(request));

  return await apiClient().put(uploadDocumentsUrl, data)
    .then(res => {
      showNotification("Documents successfully updated.", 'success');
      return true;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while uploading documents.", "error");

      throw err;
    });
};

export const getDocumentsZipApi = async (request: ClaimDocumentRequestDto) => {
  return await apiClient().put(documentsZipUrl, request, {
    responseType: 'blob'
  })
    .then(res => {
      var filename = "document-zip-" + new Date().toLocaleDateString();

      let blob = new Blob([res.data], { type: 'application/zip' });
      let downloadUrl = window.URL.createObjectURL(blob);
      let link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100);
      return true;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while retriving document zip.", "error");

      throw err;
    });
};

export const getHearingContactInformationApi = async (request: HearingContactsDto[]) => {
  return await apiClient().post(contactsInformationUrl, request)
    .then(res => {
      return res.data.result;
    })
    .catch(err => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;

        if (axiosError.response) showNotification(axiosError.response.data.error.message, "error");
      }
      else showNotification("An error occurred while fetching hearing contact information.", "error");

      throw err;
    });
};