import { AxiosError } from 'axios';
import { ApiError } from './apiResponse';
import apiClient from './apiClient';
import { showNotification } from '../actionCreators/dispatchNotification';
import { RequestAssistanceDto } from '../dtos/requestAssistance';
// endpoints
const requestAssistanceUrl = '/assistance/request';

export const requestAssistanceApi = async (request: RequestAssistanceDto) => {
    const data = new FormData();
    if (request.file !== undefined) {
      data.append('file', request.file);
    }
    data.append('model', JSON.stringify(request));
    return await apiClient().post(requestAssistanceUrl, data, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(res => {
            showNotification("Your request has been sent.", "success");
            return res.data.result;
        })
        .catch(err => {
            if (err && err.response) {
                const axiosError = err as AxiosError<ApiError>;

                if (axiosError.response) {
                    showNotification(axiosError.response.data.error.message, "error");
                }
            }
            else {
                showNotification("An error occurred while sending request.", "error");
            }

            throw err;
        });
};