import React from 'react';

import * as Styles from '../styles/styles';


interface BottomActionBarCloseProps extends React.HTMLProps<React.ReactNode> {
  closeClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void,
}


const BottomActionBarClose: React.FC<BottomActionBarCloseProps> = (props: BottomActionBarCloseProps) => {
  const { closeClickHandler } = props;

  return <>
    <Styles.BottomAppBar position="fixed">
      <Styles.ButtonGrid container>
              <Styles.BottomCancelButton type="button" onClick={closeClickHandler} data-cy="page_bottomClose">
          Close
          </Styles.BottomCancelButton>
      </Styles.ButtonGrid>
    </Styles.BottomAppBar>
  </>
}

export default BottomActionBarClose;