import React from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';


interface TabPanelProps extends React.HTMLProps<React.ReactNode> {
  value: number,
  index: number
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => { 
  const { children, value, index} = props;

  return (
    <Typography
      component={Paper}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      //aria-labelledby={`tab-${index}`}
    >
      {value === index && <Grid>{children}</Grid>}
    </Typography>
  );

}

export default TabPanel;

