import { combineReducers } from 'redux';
import authReducer, { tenant, token } from '../common/reducers/auth.reducer';
import claimantsReducer from '../common/reducers/claimants';
import contactInformationReducer from '../common/reducers/contactInformation.reducer';
import emailTemplatesReducer from '../common/reducers/emailTemplate.reducer';
import notificationReducer from '../common/reducers/notification.reducer';
import requestAssistanceReducer from '../common/reducers/requestAssistance.reducer';
import spinnerReducer from '../common/reducers/spinner.reducer';
import timeZonesReducer from '../common/reducers/timeZones.reducer';
import changePasswordReducer from '../features/account/changePassword/changePassword.reducer';
import forgotPasswordReducer from '../features/account/forgotPassword/forgotPassword.reducer';
import loginReducer from '../features/account/login/login.reducer';
import {
  countriesReducer,
  governingDistrictsReducer,
} from '../features/account/profile/profile.reducer';
import resetPasswordReducer from '../features/account/resetPassword/resetPassword.reducer';
import updatePasswordReducer from '../features/account/updatePassword/updatePassword.reducer';
import administrationReducer from '../features/administration/administration.reducer';
import batchPaymentsReducer from '../features/batchPayments/reducer';
import claimsReducer from '../features/claims/reducer';
import companiesReducer from '../features/company/reducer';
import contactsReducer from '../features/contacts/contacts.reducer';
import documentsReducer from '../features/documents/reducer';
import questionnaireReducer from '../features/questionnaire/reducer';
import reportsReducer from '../features/reports/reducer';
import salesReducer from '../features/sales/sales.reducer';
import codesReducer from '../features/settings/codes/codes.reducer';
import companyCustomerSettingsReducer from '../features/settings/customers/customers.reducer';
import healthSystemsReducer from '../features/settings/healthSystems/healthSystems.reducer';
import systemSettingsReducer from '../features/settings/system/system.reducer';
import taskTypesWorkOrderSettingsReducer from '../features/settings/workOrders/workOrders.reducer';

const rootReducer = combineReducers({
  //tenant
  administration: administrationReducer,
  auth: authReducer,
  batchPayments: batchPaymentsReducer,
  changePassword: changePasswordReducer,
  claimants: claimantsReducer,
  claims: claimsReducer,
  codes: codesReducer,
  companies: companiesReducer,
  companyCustomerSettings: companyCustomerSettingsReducer,
  contactInformation: contactInformationReducer,
  contacts: contactsReducer,
  countries: countriesReducer,
  documents: documentsReducer,
  emailTemplates: emailTemplatesReducer,
  updatePassword: updatePasswordReducer,
  forgotPassword: forgotPasswordReducer,
  governingDistricts: governingDistrictsReducer,
  healthSystems: healthSystemsReducer,
  login: loginReducer,
  notification: notificationReducer,
  questionnaire: questionnaireReducer,
  reports: reportsReducer,
  requestAssistance: requestAssistanceReducer,
  resetPassword: resetPasswordReducer,
  spinner: spinnerReducer,
  systemSettings: systemSettingsReducer,
  taskTypesWorkOrderSettings: taskTypesWorkOrderSettingsReducer,
  tenant: tenant,
  timeZones: timeZonesReducer,
  toDos: salesReducer,
  xsrf: token,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
