import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors } from 'formik';

// material ui
import { Grid, FormControlLabel, TextField } from '@material-ui/core';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ClientQuestionnaireDto } from '../../dto';
import { MHARadio, TMGrid } from '../../../../common/styles/styles';
import { radioButtonBooleanOptions } from '../../constants/options';


interface HealthReasonsComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
  handleChange: FormikHandlers["handleChange"];
  errors: FormikErrors<ClientQuestionnaireDto>;
}

const HealthReasonsComponent: React.FC<HealthReasonsComponentProps> = (
  {
    values,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation,
    handleChange,
    errors
  }) => {

  return (
    <>
      <Grid item sm={12} md={12} lg={12}>
        <Grid item xs={12}>Did the claimant request a leave of absence prior to quitting?</Grid>
        <Grid container item xs={12} direction="row">
          {radioButtonBooleanOptions.map((o, index) => (
            <Grid item xs={3} key={o.name + index}>
              <FormControlLabel
                id="didClaimantRequestLoa"
                name="didClaimantRequestLoa"
                onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                  setFieldValue('didClaimantRequestLoa', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? false);
                  setShouldBlockNavigation(true);
                }}
                onBlur={e => { handleBlur(e); }}
                //add errors for validation
                checked={o.status === values.didClaimantRequestLoa}
                value={o.name}
                control={<MHARadio
                  size="small" />} label={o.name} data-cy="clientQuestionnaire_didClaimantRequestLoa" />
            </Grid>
          ))}
        </Grid>
      </Grid>
      {values.didClaimantRequestLoa && values.didClaimantRequestLoa === true ?
        <Grid item sm={12} md={12} lg={12}>
          <Grid item xs={12}>Was the leave of absence request granted?</Grid>
          <Grid container item xs={12} direction="row">
            {radioButtonBooleanOptions.map((o, index) => (
              <Grid item xs={3} key={o.name + index}>
                <FormControlLabel
                  id="wasLeaveGranted"
                  name="wasLeaveGranted"
                  onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                    setFieldValue('wasLeaveGranted', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? false);
                    setShouldBlockNavigation(true);
                  }}
                  onBlur={e => { handleBlur(e); }}
                  //add errors for validation
                  checked={o.status === values.wasLeaveGranted}
                  value={o.name}
                  control={<MHARadio
                    size="small" />} label={o.name} data-cy="clientQuestionnaire_wasLeaveGranted" />
              </Grid>
            ))}
          </Grid>
        </Grid>
        : null}

      {values.wasLeaveGranted !== undefined && values.wasLeaveGranted === true && values.didClaimantRequestLoa === true ?
        <>
          <br />
          <Grid item xs={12}>Please list the start date of the leave and the anticipated end date of the leave.</Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <KeyboardDatePicker
              margin="normal"
              id="resignationStartDate"
              name="resignationStartDate"
              label="Start Date"
              format="MM/DD/yyyy"
              value={values.resignationStartDate || null}
              data-cy="clientQuestionnaire_resignationStartDate"
              fullWidth
              onChange={(date: MaterialUiPickersDate) => { setFieldValue('resignationStartDate', date); setShouldBlockNavigation(true); }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <KeyboardDatePicker
              margin="normal"
              id="resignationAnticipatedEndDate"
              name="resignationAnticipatedEndDate"
              label="Anticipated End Date"
              format="MM/DD/yyyy"
              value={values.resignationAnticipatedEndDate || null}
              data-cy="clientQuestionnaire_resigantionAnticipatedEndDate"
              fullWidth
              onChange={(date: MaterialUiPickersDate) => setFieldValue('resignationAnticipatedEndDate', date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </>
        : null}

      {values.wasLeaveGranted !== undefined && values.wasLeaveGranted === false && values.didClaimantRequestLoa === true ?
        <>
          <br />
          <Grid item xs={12}>Why?</Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <TextField
              id="whyLeaveNotGranted"
              size="small"
              margin="none"
              value={values.whyLeaveNotGranted || ''}
              name="whyLeaveNotGranted"
              multiline
              onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
              onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
              error={Boolean(errors.whyLeaveNotGranted)}
              helperText={errors.whyLeaveNotGranted && errors.whyLeaveNotGranted}
              data-cy="clientQuestionnaire_whyLeaveNotGranted"
              aria-label="Why?"
              fullWidth
            />
          </Grid>
        </>
        : null}

      {true ?
        <TMGrid item sm={12} md={12} lg={12}>
          <Grid item xs={12}>Did the claimant provide medical documentation to support his/her need to resign?</Grid>
          <Grid container item xs={12} direction="row">
            {radioButtonBooleanOptions.map((o, index) => (
              <Grid item xs={3} key={o.name + index}>
                <FormControlLabel
                  id="didClaimantProvideMedicalDocs"
                  name="didClaimantProvideMedicalDocs"
                  onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                    setFieldValue('didClaimantProvideMedicalDocs', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? false);
                    setShouldBlockNavigation(true);
                  }}
                  onBlur={e => { handleBlur(e); }}
                  //add errors for validation
                  checked={o.status === values.didClaimantProvideMedicalDocs}
                  value={o.name}
                  control={<MHARadio
                    size="small" />} label={o.name} data-cy="clientQuestionnaire_didClaimantProvideMedicalDocs" />
              </Grid>
            ))}
            
          </Grid>
          
        </TMGrid>
        : null}

      {true ?
        <TMGrid item sm={12} md={12} lg={12}>
          <Grid item xs={12}>Did the claimant explore all reasonable options prior to quitting?</Grid>
          <Grid container item xs={12} direction="row">
            {radioButtonBooleanOptions.map((o, index) => (
              <Grid item xs={3} key={o.name + index}>
                <FormControlLabel
                  id="didClaimantExploreOptions"
                  name="didClaimantExploreOptions"
                  onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                    setFieldValue('didClaimantExploreOptions', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? false);
                    setShouldBlockNavigation(true);
                  }}
                  onBlur={e => { handleBlur(e); }}
                  //add errors for validation
                  checked={o.status === values.didClaimantExploreOptions}
                  value={o.name}
                  control={<MHARadio
                    size="small" />} label={o.name} data-cy="clientQuestionnaire_didClaimantExploreOptions" />
              </Grid>
            ))}
          </Grid>

        </TMGrid>
        : null}
    </>
  )
}

export default HealthReasonsComponent;
