import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors } from 'formik';

// material ui
import { Grid, TextField, FormControlLabel } from '@material-ui/core';

import { ClientQuestionnaireDto } from '../../dto';
import { radioButtonBooleanOptions } from '../../constants/options';
import { MHARadio } from '../../../../common/styles/styles';


interface WorkingConditionsComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
}

const WorkingConditionsComponent: React.FC<WorkingConditionsComponentProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation
  }) => {

  return (
    <React.Fragment>
      <Grid item xs={12}>What issues or concerns did the claimant report?</Grid>
      <Grid item xs={4}>
        <TextField
          id="issuesReported"
          size="small"
          margin="none"
          value={values.issuesReported || ''}
          //label="Job Title"
          name="issuesReported"
          multiline
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
          error={Boolean(errors.issuesReported)}
          helperText={errors.issuesReported && errors.issuesReported}
          data-cy="clientQuestionnaire_issuesReported"
          aria-label="What was the claimant dissatisfied about?"
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={12}>Prior to quitting, had the claimant raised complaints/concerns about these issues to management or human resources?</Grid>
      <Grid container item xs={12} direction="row">
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="didClaimantRaiseComplaint"
              name="didClaimantRaiseComplaint"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('didClaimantRaiseComplaint', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? false);
                setShouldBlockNavigation(true);
              }}
              onBlur={e => { handleBlur(e); }}
              //add errors for validation
              checked={o.status === values.didClaimantRaiseComplaint}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_didClaimantRaiseComplaint" />
          </Grid>
        ))}
      </Grid>
      <br />
      {values.didClaimantRaiseComplaint !== undefined && values.didClaimantRaiseComplaint === true ?
        <>
          <Grid item xs={12}>What was the done (if anything) to address the complaints/concerns?</Grid>
          <Grid item xs={5}>
            <TextField
              id="whatWasDoneToAddressComplaints"
              size="small"
              margin="none"
              multiline
              value={values.whatWasDoneToAddressComplaints || ''}
              //label="Job Title"
              name="whatWasDoneToAddressComplaints"
              onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
              onBlur={e => handleBlur(e)}
              error={Boolean(errors.whatWasDoneToAddressComplaints)}
              helperText={errors.whatWasDoneToAddressComplaints && errors.whatWasDoneToAddressComplaints}
              data-cy="clientQuestionnaire_whatWasDoneToAddressComplaints"
              aria-label="What was the claimant dissatisfied about?"
              fullWidth
            />
          </Grid>
        </>
        : null}
      <br />

    </React.Fragment>
  )
}

export default WorkingConditionsComponent;
