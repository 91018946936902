import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, getIn, FieldArray } from 'formik';
import * as Yup from 'yup';
import { getType } from 'typesafe-actions';
import { Prompt, useHistory } from 'react-router';
import moment from 'moment';
// material ui
import {
  Grid,
  Card,
  Typography,
  Checkbox,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { rootSelector } from '../../../common/selectors/selectors';
import { getMhaContactsActions } from '../../../common/actions/actions';
import {
  updateCompanyActions,
  createCompanyActions,
  getCompanyActions,
} from '../actions';
import {
  getCountriesActions,
  getGoverningDistrictsActions,
} from '../../account/profile/profile.actions';
import { getHealthSystemsActions } from '../../settings/healthSystems/healthSystems.actions';
import { getTimeZonesActions } from '../../../common/actions/timeZones.actions';
import CompanyInformation from '../formComponents/information';
import CompanyEmail from '../formComponents/email';
import CompanyPhone from '../formComponents/phone';
import CompanyAddress from '../formComponents/address';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import Label from '../../../common/components/label';
import BottomActionFormBar from '../../../common/components/bottomActionFormBar';
import { companyInit, CompanyDto, employerValidationSchema } from '../dtos';
import { MhaContactsDto } from '../../../common/dtos/dto';
import { addressInit } from '../../../common/dtos/address';
import { emailInit } from '../../../common/dtos/email';
import { phoneInit } from '../../../common/dtos/phone';
import { TimeZonesDto } from '../../../common/dtos/timeZones';
import {
  HealthSystemDto,
  HealthSystemGroupDto,
} from '../../settings/healthSystems/healthSystems.dtos';
import { getLocationCodesActions } from '../../settings/codes/codes.actions';
import { isReadOnly } from '../../../common/dtos/auth';
import {
  CardCheckbox,
  CardContent1,
  CondensedGrid,
  Icon,
  IndentGrid,
} from '../../../common/styles/styles';
import { T3Select } from '../styles';
import { FeatureTypeEnum } from '../../../common/enums/featureTypeEnum';

const CompanyDetails: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const systemCompany = rootSelector((state) => state.companies.company);
  const countries = rootSelector((state) => state.countries.countryList);
  const governingDistricts = rootSelector(
    (state) => state.governingDistricts.governingDistrictList
  );
  const systemHealthSystems = rootSelector(
    (state) => state.healthSystems.healthSystems
  );
  const systemTimeZones = rootSelector(
    (state) => state.timeZones.timeZonesList
  );
  const systemMhaContacts = rootSelector(
    (state) => state.companies.mhaContacts
  );
  const locationCodes = rootSelector((state) => state.codes.locationCodes);

  const [dto, setDto] = React.useState(companyInit);
  const [shouldBlockNavigation, setShouldBlockNavigation] =
    React.useState(false);
  const [savingInput, setSavingInput] = React.useState(false);
  const [countryOptions, setCountryOptions] = React.useState([] as string[]);
  const [stateOptions, setStateOptions] = React.useState([] as string[]);
  const [healthSystemOptions, setHealthSystemOptions] = React.useState(
    [] as string[]
  );
  const [groupOptions, setGroupOptions] = React.useState(
    [] as HealthSystemGroupDto[]
  );
  const [mhaContactOptions, setMhaContactOptions] = React.useState(
    [] as MhaContactsDto[]
  );
  const [timeZones, setTimeZones] = React.useState([] as TimeZonesDto[]);
  const [locationCodeOptions, setLocationCodeOptions] = React.useState(
    [] as string[]
  );
  const [readOnlyStatus, setReadOnlyStatus] = React.useState(false);

  React.useEffect(() => {
    let s = isReadOnly(FeatureTypeEnum.Employers);
    setReadOnlyStatus(s);
  }, [readOnlyStatus]);

  React.useEffect(() => {
    dispatch({ type: getType(getCountriesActions.request) });
    dispatch({ type: getType(getGoverningDistrictsActions.request) });
    dispatch({ type: getType(getTimeZonesActions.request) });
    dispatch({ type: getType(getMhaContactsActions.request) });
    dispatch({ type: getType(getHealthSystemsActions.request) });
    dispatch({ type: getType(getLocationCodesActions.request) });
  }, [dispatch]);

  React.useEffect(() => {
    setDto(systemCompany);
    setShouldBlockNavigation(false);
  }, [systemCompany]);

  React.useEffect(() => setTimeZones(systemTimeZones), [systemTimeZones]);
  React.useEffect(
    () => setMhaContactOptions(systemMhaContacts),
    [systemMhaContacts]
  );

  React.useEffect(() => {
    let options = countries.map((c) => c.name);
    options.push('');
    setCountryOptions(options);
  }, [countries]);

  React.useEffect(() => {
    let options = locationCodes.map((c) => c.name!);
    options!.push('');
    setLocationCodeOptions(options);
  }, [locationCodes]);

  React.useEffect(() => {
    let options = governingDistricts
      .filter(
        (f) =>
          f.countryId ===
          countries.find((c) =>
            dto.addresses.flatMap((a) => a.country).includes(c.name)
          )?.countryId
      )
      .map((g) => g.name);
    options.push('');
    setStateOptions(options);
  }, [governingDistricts, countries, dto.addresses]);

  React.useEffect(() => {
    let healthSystemList = systemHealthSystems.map(
      (hs) => hs.healthSystemName
    ) as string[];
    let healthSystemIndex = healthSystemList.findIndex(
      (s) => s === dto.healthSystem
    );
    let selectedIndex = healthSystemIndex !== -1 ? healthSystemIndex : 0;
    let groupList =
      (systemHealthSystems as HealthSystemDto[])[selectedIndex] !== undefined
        ? (systemHealthSystems as HealthSystemDto[])[selectedIndex].groups
        : undefined;

    if (healthSystemList !== undefined) {
      setHealthSystemOptions(healthSystemList);
    }

    if (groupList !== undefined) {
      setGroupOptions(groupList);
    }
  }, [systemHealthSystems, dto.healthSystem]);

  const handleHealthSystemChange = (name: string, values: CompanyDto) => {
    let i = systemHealthSystems.findIndex((hs) => hs.healthSystemName === name);
    if (
      systemHealthSystems[i] !== undefined &&
      systemHealthSystems[i].groups !== undefined
    ) {
      setGroupOptions(systemHealthSystems[i].groups);
    } else {
      setGroupOptions([] as HealthSystemGroupDto[]);
    }

    // reset dto.healthSystemGroupId if needed.
    let newDto = JSON.parse(JSON.stringify(values)) as CompanyDto;
    newDto.healthSystemGroupId = '';
    newDto.healthSystem = name;

    setDto(newDto);
  };

  const handleCountryChange = (name: string) => {
    setStateOptions(
      governingDistricts
        .filter(
          (f) =>
            f.countryId === countries.find((c) => c.name === name)?.countryId
        )
        .map((g) => g.name)
    );
  };

  const handleCancel = () => history.push('/employers');

  return (
    <>
      <Formik
        initialValues={dto}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={employerValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            setSubmitting(false);
            setSavingInput(false);
          }, 700);
          setSavingInput(true);

          let dto = values;
          dto.emails = dto.emails.filter((e) => e.emailAddress.length !== 0);
          dto.phones = dto.phones.filter((p) => p.phoneNumber.length !== 0);
          dto.notes = dto.notes.filter((n) => n.entry.length !== 0);
          dto.addresses = dto.addresses.filter(
            (a) =>
              a.address1 !== undefined &&
              a.address1!.length !== 0 &&
              a.city !== undefined &&
              a.city!.length !== 0
          );
          dto.employeeCountList = dto.employeeCountList.filter(
            (e) => e.year !== 0
          );
          dto.employerChargeList = dto.employerChargeList.filter(
            (c) => c.year !== 0
          );
          // For Radio Select
          dto.billingInformationId = +dto.billingInformationId;

          // handle Business Unit List
          dto.businessUnitList.forEach((bu) => {
            if (
              bu.id !== undefined &&
              bu.name !== undefined &&
              bu.name.length === 0
            ) {
              bu.name += 'Removed_' + bu.name;
              bu.isActive = false;
            }
          });
          dto.businessUnitList = dto.businessUnitList.filter(
            (b) => b.name.length !== 0
          );

          //For Group display
          if (
            dto.healthSystemGroupId !== undefined &&
            dto.healthSystemGroupId.length !== 0
          ) {
            dto.group =
              groupOptions.find((g) => g.id === dto.healthSystemGroupId)
                ?.name ?? '';
          }
          dispatch({
            // If the companyDto has a companyId, update on submit. Otherwise,
            // create a new company.
            type: dto.companyId
              ? getType(updateCompanyActions.request)
              : getType(createCompanyActions.request),
            payload: dto,
          });
        }}
        enableReinitialize={true}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            handleBlur,
          } = props;
          return (
            <form onSubmit={handleSubmit} data-cy='employer_details_form'>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent1>
                          <Grid container>
                            <Grid
                              container
                              item
                              xs={12}
                              alignItems='center'
                              spacing={2}
                            >
                              <CondensedGrid item xs={12} sm={7}>
                                {/*<Label>Employer Information</Label>*/}
                              </CondensedGrid>
                              <CondensedGrid item xs={6} sm={3}>
                                <CardCheckbox
                                  id='requiresFullSsn'
                                  name='requiresFullSsn'
                                  onChange={(e) => {
                                    handleChange(e);
                                    setShouldBlockNavigation(true);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.requiresFullSsn}
                                  checked={values.requiresFullSsn}
                                  disabled={readOnlyStatus}
                                  control={
                                    <Checkbox
                                      style={{ paddingRight: 6 }}
                                      size='small'
                                      data-cy='employer-details-requiresFullSsn'
                                    />
                                  }
                                  label={
                                    <Typography variant='subtitle2'>
                                      Requires Full SSN
                                    </Typography>
                                  }
                                />
                              </CondensedGrid>
                              <CondensedGrid item xs={6} sm={2}>
                                <CardCheckbox
                                  id='isActive'
                                  name='isActive'
                                  onChange={(e) => {
                                    handleChange(e);
                                    setShouldBlockNavigation(true);
                                  }}
                                  onBlur={handleBlur}
                                  value={values.isActive}
                                  checked={values.isActive}
                                  disabled={readOnlyStatus}
                                  control={
                                    <Checkbox
                                      style={{ paddingRight: 6 }}
                                      size='small'
                                      data-cy='employer_details_isActiveCheckbox'
                                    />
                                  }
                                  label={
                                    <Typography variant='subtitle2'>
                                      Active
                                    </Typography>
                                  }
                                />
                              </CondensedGrid>
                            </Grid>

                            <CompanyInformation
                              governingDistricts={governingDistricts}
                              isReadOnly={readOnlyStatus}
                              values={values}
                              errors={errors}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              mhaContactOptions={mhaContactOptions}
                              healthSystemOptions={healthSystemOptions}
                              groupOptions={groupOptions}
                              setFieldValue={setFieldValue}
                              handleHealthSystemChange={
                                handleHealthSystemChange
                              }
                              locationCodeOptions={locationCodeOptions}
                              setShouldBlockNavigation={
                                setShouldBlockNavigation
                              }
                            />
                          </Grid>
                        </CardContent1>
                      </Card>
                    </Grid>

                    <Grid item xs={12}>
                      <Card>
                        <CardContent1>
                          <FieldArray
                            name='emails'
                            render={(arrayHelpers) => (
                              <>
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  alignItems='center'
                                >
                                  <Grid item xs={11}>
                                    <Label>Email Addresses</Label>
                                  </Grid>
                                  <Grid item xs={1} hidden={readOnlyStatus}>
                                    <Icon
                                      title='Add to Email List'
                                      color='secondary'
                                      onClick={() => {
                                        arrayHelpers.push(emailInit);
                                      }}
                                      data-cy='employer_details_addEmailAddress'
                                    >
                                      <AddBoxRoundedIcon />
                                    </Icon>
                                  </Grid>
                                </Grid>
                                <IndentGrid container item xs={12} spacing={2}>
                                  {values.emails &&
                                    values.emails.map((o, index) => (
                                      <CompanyEmail
                                        isReadOnly={readOnlyStatus}
                                        key={'CompanyEmail-' + index}
                                        email={o}
                                        index={index}
                                        errors={errors}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        emails={values.emails}
                                        setShouldBlockNavigation={
                                          setShouldBlockNavigation
                                        }
                                      />
                                    ))}
                                </IndentGrid>
                              </>
                            )}
                          />

                          <FieldArray
                            name='phones'
                            render={(arrayHelpers) => (
                              <>
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  alignItems='center'
                                >
                                  <Grid item xs={11}>
                                    <Label>Phone Numbers</Label>
                                  </Grid>
                                  <Grid item xs={1} hidden={readOnlyStatus}>
                                    <Icon
                                      title='Add To Phone Number List'
                                      color='secondary'
                                      onClick={() => {
                                        arrayHelpers.push(phoneInit);
                                      }}
                                      data-cy='employer_details_addPhoneNumber'
                                    >
                                      <AddBoxRoundedIcon />
                                    </Icon>
                                  </Grid>
                                </Grid>
                                <IndentGrid
                                  container
                                  item
                                  xs={12}
                                  spacing={2}
                                  direction='row'
                                  alignItems='center'
                                >
                                  {values.phones &&
                                    values.phones.map((o, index) => (
                                      <CompanyPhone
                                        isReadOnly={readOnlyStatus}
                                        key={'CompanyPhone-' + index}
                                        phones={values.phones}
                                        phone={o}
                                        index={index}
                                        errors={errors}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        setFieldValue={setFieldValue}
                                        setShouldBlockNavigation={
                                          setShouldBlockNavigation
                                        }
                                      />
                                    ))}
                                </IndentGrid>
                              </>
                            )}
                          />

                          <FieldArray
                            name='addresses'
                            render={(arrayHelpers) => (
                              <>
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  alignItems='center'
                                >
                                  <Grid item xs={11}>
                                    <Label>Mailing Addresses</Label>
                                  </Grid>
                                  <Grid item xs={1} hidden={readOnlyStatus}>
                                    <Icon
                                      title='Add To Employer Mailing Address List'
                                      color='secondary'
                                      onClick={() => {
                                        arrayHelpers.push(addressInit);
                                      }}
                                      data-cy='employer_details_addMailingAddress'
                                    >
                                      <AddBoxRoundedIcon />
                                    </Icon>
                                  </Grid>
                                </Grid>
                                <IndentGrid container item xs={12} spacing={2}>
                                  {values.addresses !== undefined &&
                                    values.addresses.map((o, index) => (
                                      <CompanyAddress
                                        isReadOnly={readOnlyStatus}
                                        key={'CompanyAddress-' + index}
                                        address={o}
                                        addresses={values.addresses}
                                        index={index}
                                        errors={errors}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        stateOptions={stateOptions}
                                        countryOptions={countryOptions}
                                        setFieldValue={setFieldValue}
                                        setShouldBlockNavigation={
                                          setShouldBlockNavigation
                                        }
                                        handleCountryChange={
                                          handleCountryChange
                                        }
                                      />
                                    ))}
                                </IndentGrid>
                              </>
                            )}
                          />
                        </CardContent1>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item sm={6} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Card>
                        <CardContent1>
                          <Grid container>
                            <Label>Notes</Label>
                            <IndentGrid container item xs={12} spacing={2}>
                              <Grid item xs={12}>
                                {values.notes &&
                                  values.notes.map((o, index) => (
                                    <React.Fragment
                                      key={'notesFragment' + index}
                                    >
                                      <TextField
                                        id={`notes[${index}].entry`}
                                        size='small'
                                        margin='none'
                                        value={o.entry}
                                        label='Notes'
                                        disabled={readOnlyStatus}
                                        name={`notes[${index}].entry`}
                                        onChange={(e) => {
                                          handleChange(e);
                                          setShouldBlockNavigation(true);
                                        }}
                                        onBlur={handleBlur}
                                        error={Boolean(
                                          getIn(
                                            errors,
                                            `notes[${index}].entry`
                                          ) &&
                                            getIn(
                                              touched,
                                              `notes[${index}].entry`
                                            )
                                        )}
                                        helperText={
                                          getIn(
                                            errors,
                                            `notes[${index}].entry`
                                          ) &&
                                          getIn(
                                            touched,
                                            `notes[${index}].entry`
                                          ) &&
                                          getIn(errors, `notes[${index}].entry`)
                                        }
                                        data-cy={`employer_notes_${index}_notesInput`}
                                        multiline
                                        minRows={4}
                                        fullWidth
                                      />
                                    </React.Fragment>
                                  ))}
                              </Grid>
                            </IndentGrid>
                          </Grid>

                          <br />
                          <br />

                          <Grid container>
                            <Label>Time Zone</Label>
                            <IndentGrid container item xs={12} spacing={2}>
                              <Grid item xs={12}>
                                <T3Select
                                  fullWidth
                                  name='timeZoneId'
                                  value={values.timeZoneId}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setShouldBlockNavigation(true);
                                  }}
                                  onBlur={handleBlur}
                                  displayEmpty
                                  disabled={readOnlyStatus}
                                  MenuProps={{ disableScrollLock: true }}
                                  data-cy='employer_details_timeZoneDropDown'
                                >
                                  <MenuItem value={0} disabled>
                                    Please select an item
                                  </MenuItem>
                                  {timeZones.map((timeZone, index) => {
                                    return (
                                      <MenuItem
                                        key={'item-' + index}
                                        value={timeZone.timeZoneId}
                                      >
                                        {timeZone.displayName}
                                      </MenuItem>
                                    );
                                  })}
                                </T3Select>
                              </Grid>
                            </IndentGrid>
                          </Grid>

                          <br />
                          <br />

                          <IndentGrid container item xs={12} spacing={2}>
                            <Grid item xs={12}>
                              {dto.createdBy && (
                                <Typography variant='subtitle2'>
                                  Created{' '}
                                  {moment(dto.createdDateTime).format(
                                    'MM/DD/yyyy h:mm A'
                                  )}{' '}
                                  by {dto.createdBy}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant='subtitle2'>
                                Last edit{' '}
                                {moment(dto.updatedDateTime).format(
                                  'MM/DD/yyyy h:mm A'
                                )}{' '}
                                by {dto.updatedBy}
                              </Typography>
                            </Grid>
                          </IndentGrid>
                        </CardContent1>
                      </Card>

                      <br />
                      <br />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <br />
              <br />

              <BottomActionFormBar
                cancelClickHandler={handleCancel}
                submitting={savingInput}
                featureType={FeatureTypeEnum.Employers}
              />
              <Prompt
                when={shouldBlockNavigation}
                message={() => {
                  dispatch({
                    type: getType(getCompanyActions.success),
                    payload: values,
                  });
                  return 'You have unsaved edits, are you sure you want to leave?';
                }}
              />
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default CompanyDetails;
