import React from 'react';
import {
  Redirect,
  Route,
  RouteProps,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';

import { storageAuthToken, tenantId } from '../common/constants/strings';
import MainLayout from '../common/layouts/main';

// Import application features
import NotFound from '../common/components/pageNotFound';
import RequestAssistanceDialog from '../common/dialogs/requestAssistanceDialog';
import ChangePassword from '../features/account/changePassword/changePassword';
import ForgotPassword from '../features/account/forgotPassword/forgotPassword';
import Login from '../features/account/login/login';
import ResetPassword from '../features/account/resetPassword/resetPassword';
import UpdatePassword from '../features/account/updatePassword/updatePassword';
import Administration from '../features/administration/administration';
import BatchPaymentList from '../features/batchPayments/batchPaymentsList';
import Claim from '../features/claims/claim';
import ClaimsList from '../features/claims/components/claimsList';
import CompanyBulkInsert from '../features/company/bulkInsert';
import Companies from '../features/company/companies';
import Company from '../features/company/company';
import ContactBulkInsert from '../features/contacts/components/bulkInsert';
import ContactList from '../features/contacts/components/contactList';
import Contact from '../features/contacts/contact';
import Dashboard from '../features/dashboard/Dashboard';
import ActionList from '../features/documents/actionList';
import Launchpad from '../features/launchpad/launchpad';
import ClientPortalQuestionnaire from '../features/questionnaire/clientPortalQuestionnaire';
import QuestionnaireLinkPage from '../features/questionnaire/questionnaireLinkPage';
import Reports from '../features/reports/reports';
import Codes from '../features/settings/codes/codes';
import CustomerSettings from '../features/settings/customers/customers';
import EmailTemplates from '../features/settings/emailTemplates/emailTemplates';
import HealthSystemsDetails from '../features/settings/healthSystems/components/healthSystemDetails';
import HealthSystems from '../features/settings/healthSystems/healthSystem';
import SystemSettings from '../features/settings/system/system';
import WorkOrderSettings from '../features/settings/workOrders/workOrders';

const LoggedInRouter: React.FC = () => (
  <Switch>
    <Route
      exact
      path='/'
      render={() => (
        <Redirect to={`/${localStorage.getItem(tenantId) ?? 'mha'}/login`} />
      )}
    />
    <RouteWithLayout
      exact
      path='/actions'
      component={ActionList}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/batchPayments'
      component={BatchPaymentList}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/bulk-import/contacts'
      component={ContactBulkInsert}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/bulk-import/employers'
      component={CompanyBulkInsert}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/claims/'
      component={ClaimsList}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/claim/:claimId'
      component={Claim}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/contacts'
      component={ContactList}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/contact/:contactId'
      component={Contact}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/dashboard'
      component={Dashboard}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/employers'
      component={Companies}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/employers/:employerId'
      component={Company}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/help'
      component={RequestAssistanceDialog}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/launchpad'
      component={Launchpad}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/reports'
      component={Reports}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/settings/codes'
      component={Codes}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/settings/customers'
      component={CustomerSettings}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/settings/emailTemplates'
      component={EmailTemplates}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/settings/healthSystems'
      component={HealthSystems}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/settings/healthSystems/:healthSystemId'
      component={HealthSystemsDetails}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/settings/system'
      component={SystemSettings}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/settings/work-orders'
      component={WorkOrderSettings}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path={`/:tenant/change-password`}
      component={ChangePassword}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path={`/:tenant/clientQuestionnaire/:claimId`}
      component={ClientPortalQuestionnaire}
      layout={MainLayout}
    />
    <RouteWithLayout
      exact
      path='/administration/manual-repull'
      component={Administration}
      layout={MainLayout}
    />
    <RouteWithLayout component={NotFound} />
  </Switch>
);

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path={`/:tenant/login`} component={Login} />
        <Route
          exact
          path={`/:tenant/forgot-password`}
          component={ForgotPassword}
        />
        <Route
          exact
          path={`/:tenant/reset-password`}
          component={ResetPassword}
        />
        <Route
          exact
          path={`/:tenant/questionnaire/:token`}
          component={QuestionnaireLinkPage}
        />
        <Route
          exact
          path={`/:tenant/update-password`}
          component={UpdatePassword}
        />
        <RoutePrivate path='/' component={LoggedInRouter} />
      </Switch>
    </Router>
  );
};

const RouteWithLayout = ({
  component: Component,
  layout: Layout,
  ...rest
}: any) => (
  <Route
    {...rest}
    render={(props) => {
      if (Layout) {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      } else {
        return <Component {...props} />;
      }
    }}
  />
);

const RoutePrivate: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}: RouteProps) => {
  const isAuthenticated = sessionStorage.getItem(storageAuthToken) ?? false;

  if (!Component) {
    return <Route {...rest} />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `/${localStorage.getItem(tenantId) ?? 'mha'}/login`,
            }}
          />
        )
      }
    />
  );
};

export default AppRouter;
