import * as Yup from 'yup';


export const addressesValidationSchema = Yup.array().of(Yup.object().shape({
  addressTypeId: Yup.number().min(0).max(6),
  address1: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
  address2: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
  city: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
  stateProvence: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
  postalCode: Yup.string().min(5, 'Must be at least 5 characters').max(10, 'Must be 10 characters or less').nullable(),
  country: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
}));


export interface AddressDto {
  addressLinkId?: string, //($uuid)
  address1: string, //maxLength: 200
  address2?: string, //maxLength: 200
  address3?: string, //maxLength: 200
  city: string, //maxLength: 200
  stateProvence: string,//maxLength: 200
  governingDistrictId?: number, //($int32)
  country: string,  //maxLength: 200
  countryId?: number, //($int32)
  postalCode: string, //maxLength: 12
  label?: string, //maxLength: 50
  addressTypeId: number, //($int32)
  isPrimary: boolean,
}

export const addressInit: AddressDto = {
  address1: '',
  city: '',
  stateProvence: '',
  country: '',
  postalCode: '',
  addressTypeId: 4,
  isPrimary: false,
};