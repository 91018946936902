import * as Yup from 'yup';

import {
  NoteDto,
  noteInit,
  notesValidationSchema,
} from '../../common/dtos/note';
import {
  AddressDto,
  addressInit,
  addressesValidationSchema,
} from '../../common/dtos/address';
import {
  EmailDto,
  emailInit,
  emailsValidationSchema,
} from '../../common/dtos/email';
import {
  PhoneDto,
  phoneInit,
  phonesValidationSchema,
} from '../../common/dtos/phone';
import { RoleDto } from '../../common/dtos/role';
import { BusinessUnitDto } from '../company/dtos';
import { requiredError } from '../../common/constants/strings';

interface Options extends Yup.ValidateOptions {
  originalValue: any;
  context: ContactDto;
}

export const companyBusinessUnitValidationSchema = Yup.array().when(
  'isActive',
  {
    is: (isActive: boolean) => {
      return isActive === true;
    },
    then: Yup.array()
      .min(1, requiredError)
      .of(
        Yup.object().shape({
          name: Yup.string()
            .max(200, 'Must be 200 characters or less')
            .required(requiredError)
            .nullable(),
        })
      ) //.required(requiredError)
      .test('companyBusinessUnitValidationSchemaCheck', '', function (v) {
        let options = this.options as Options;
        const { alternativeEmployer } = options.context;
        if (!!!v && !!!alternativeEmployer)
          return this.createError({ message: requiredError });

        return true;
      }),
    otherwise: Yup.array(),
  }
);

export const contactEmployersValidationSchema = Yup.array()
  .of(
    Yup.object().shape({
      name: Yup.string()
        .max(200, 'Must be 200 characters or less')
        .nullable() //.required(requiredError)
        .test('contactEmployersValidationSchemaCheck', '', function (v) {
          let options = this.options as Options;
          const { alternativeEmployer } = options.context;
          const { businessUnits } = this.parent;
          if (!!!v && !!!alternativeEmployer)
            return this.createError({ message: requiredError });

          return true;
        }),
      businessUnits: companyBusinessUnitValidationSchema,
    })
  )
  .min(1, requiredError)
  .when('alternativeEmployer', {
    is: (alternativeEmployer) => !!alternativeEmployer,
    then: Yup.array().nullable(),
  });

export const mhaContactFunctionsValidationSchema = Yup.array().of(
  Yup.object().shape({
    functionId: Yup.number().nullable(),
  })
);

export const contactValidationSchema = Yup.object().shape({
  isActive: Yup.boolean(),
  firstName: Yup.string()
    .required(requiredError)
    .max(50, 'Must be 50 characters or less'),
  lastName: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .required(requiredError)
    .matches(
      /^[A-Za-z\d-_,\-\s*]{0,30}$/,
      "', _, -' are the only special characters allowed"
    )
    .matches(
      /^[A-Za-z\d-_,\-\s*]{0,30}$/,
      "', _, -' are the only special characters allowed"
    ),
  title: Yup.string()
    .max(30, 'Must be 30 characters or less')
    .nullable()
    .matches(
      /^[A-Za-z\d-_,\-\s*]{0,30}$/,
      "', _, -' are the only special characters allowed"
    ),
  alternativeEmployer: Yup.string()
    .max(500, 'Must be 500 characters or less')
    .nullable(),
  contactEmployers: contactEmployersValidationSchema,
  emails: emailsValidationSchema,
  phones: phonesValidationSchema,
  addresses: addressesValidationSchema,
  notes: notesValidationSchema,
  mhaContactFunctions: mhaContactFunctionsValidationSchema,
  timeZoneId: Yup.number().nullable(),
});

export interface MhaContactFunctionLinkDto {
  functionId: number;
}

export interface ContactDto {
  contactId?: string;
  firstName: string; //max(200)
  middleName?: string;
  lastName: string; //max(200)
  suffix?: string; //max(50)
  nickname?: string; //max(200)
  title: string; //max(200)
  primaryCompanyName: string;
  timeZoneId: number;
  profileImageName?: string;
  birthdate?: Date; //($date - time)
  deathdate?: Date; //($date - time)
  notes: NoteDto[];
  addresses: AddressDto[];
  emails: EmailDto[];
  phones: PhoneDto[];
  //interactions: [];
  isActive: boolean;
  isProspect: boolean;
  fullName?: string;
  primaryEmailAddress?: string;
  primaryPhoneNumber?: string;
  fileUpload?: any;
  allowUserToSignIn: boolean;
  userName?: string;
  roles: RoleDto[];
  createdBy?: string;
  createdDateTime?: Date; //($date - time)
  updatedBy?: Date;
  updatedDateTime?: Date; //($date - time)
  functionId: number; //FunctionTypesinteger($int32) //Enum: Array [ 8 ]
  alternativeEmployer: string; //maxLength: 500 minLength: 0 nullable: true
  contactEmployers?: ContactEmployerDto[];
  mhaContactFunctions?: MhaContactFunctionLinkDto[];
  functions?: string;
  alternateEmployers?: string;
  mfauserId?: string;
}

export interface ContactEmployerDto {
  id?: string;
  companyId?: string;
  name?: string;
  businessUnits?: BusinessUnitDto[];
  isActive: boolean;
}

//export interface CompanyBusinessUnitDto {
//  id?: string;
//  name?: string;
//  businessUnitName?: string;
//  isActive: boolean;
//}

export const ContactemployersInit: ContactEmployerDto = {
  name: '',
  isActive: true,
};
export const contactInit: ContactDto = {
  firstName: '',
  lastName: '',
  // fullName: 'Test for now',
  title: '',
  primaryCompanyName: '',
  timeZoneId: 0,
  notes: [] as NoteDto[],
  addresses: [] as AddressDto[],
  emails: [] as EmailDto[],
  phones: [] as PhoneDto[],
  //interactions: [],
  isActive: true,
  isProspect: false,
  allowUserToSignIn: false,
  roles: [] as RoleDto[],
  functionId: 2,
  alternativeEmployer: '',
  contactEmployers: [] as ContactEmployerDto[],
};

//export const contactInitEmployer: ContactDto = {
//  firstName: '',
//  lastName: '',
//  fullName: 'Test for now',
//  title: '',
//  primaryCompanyName: '',
//  timeZoneId: 0,
//  notes: [noteInit],
//  addresses: [addressInit],
//  emails: [emailInit],
//  phones: [phoneInit],
//  //  websites: [websiteInit],
//  //interactions: [],
//  isActive: true,
//  isProspect: false,
//  allowUserToSignIn: false,
//  //userName: '',
//  roles: [] as RoleDto[],
//  functionId: 2,
//  alternativeEmployer: '',
//  contactEmployers: [ContactemployersInit],
//};
