


import { ISelectOption } from "../dtos/SelectOptionDto";
enum StateAbrCodes {
        ST = 0,
        AL = 1,
        AK = 2,
        AZ = 3,
        AR = 4,
        CA = 5,
        CO = 6,
        CT = 7,
        DE = 8,
        DC = 9,
        FL = 10,
        GA = 11,
        HI = 12,
        ID = 13,
        IL = 14,
        IN = 15,
        IA = 16,
        KS = 17,
        KY = 18,
        LA = 19,
        ME = 20,
        MD = 21,
        MA = 22,
        MI = 23,
        MN = 24,
        MS = 25,
        MO = 26,
        MT = 27,
        NE = 28,
        NV = 29,
        NH = 30,
        NJ = 31,
        NM = 32,
        NY = 33,
        NC = 34,
        ND = 35,
        OH = 36,
        OK = 37,
        OR = 38,
        PA = 39,
        RI = 40,
        SC = 41,
        SD = 42,
        TN = 43,
        TX = 44,
        UT = 45,
        VT = 46,
        VA = 47,
        WA = 48,
        WV = 49,
        WI = 50,
        WY = 51,
        PR = 52,
        VI = 53,
         
}

export class StateAbrCodes_class {

public static enum = StateAbrCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['ST']: 0,
    ['AL']: 1,
    ['AK']: 2,
    ['AZ']: 3,
    ['AR']: 4,
    ['CA']: 5,
    ['CO']: 6,
    ['CT']: 7,
    ['DE']: 8,
    ['DC']: 9,
    ['FL']: 10,
    ['GA']: 11,
    ['HI']: 12,
    ['ID']: 13,
    ['IL']: 14,
    ['IN']: 15,
    ['IA']: 16,
    ['KS']: 17,
    ['KY']: 18,
    ['LA']: 19,
    ['ME']: 20,
    ['MD']: 21,
    ['MA']: 22,
    ['MI']: 23,
    ['MN']: 24,
    ['MS']: 25,
    ['MO']: 26,
    ['MT']: 27,
    ['NE']: 28,
    ['NV']: 29,
    ['NH']: 30,
    ['NJ']: 31,
    ['NM']: 32,
    ['NY']: 33,
    ['NC']: 34,
    ['ND']: 35,
    ['OH']: 36,
    ['OK']: 37,
    ['OR']: 38,
    ['PA']: 39,
    ['RI']: 40,
    ['SC']: 41,
    ['SD']: 42,
    ['TN']: 43,
    ['TX']: 44,
    ['UT']: 45,
    ['VT']: 46,
    ['VA']: 47,
    ['WA']: 48,
    ['WV']: 49,
    ['WI']: 50,
    ['WY']: 51,
    ['PR']: 52,
    ['VI']: 53,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "ST",
    [1]: "AL",
    [2]: "AK",
    [3]: "AZ",
    [4]: "AR",
    [5]: "CA",
    [6]: "CO",
    [7]: "CT",
    [8]: "DE",
    [9]: "DC",
    [10]: "FL",
    [11]: "GA",
    [12]: "HI",
    [13]: "ID",
    [14]: "IL",
    [15]: "IN",
    [16]: "IA",
    [17]: "KS",
    [18]: "KY",
    [19]: "LA",
    [20]: "ME",
    [21]: "MD",
    [22]: "MA",
    [23]: "MI",
    [24]: "MN",
    [25]: "MS",
    [26]: "MO",
    [27]: "MT",
    [28]: "NE",
    [29]: "NV",
    [30]: "NH",
    [31]: "NJ",
    [32]: "NM",
    [33]: "NY",
    [34]: "NC",
    [35]: "ND",
    [36]: "OH",
    [37]: "OK",
    [38]: "OR",
    [39]: "PA",
    [40]: "RI",
    [41]: "SC",
    [42]: "SD",
    [43]: "TN",
    [44]: "TX",
    [45]: "UT",
    [46]: "VT",
    [47]: "VA",
    [48]: "WA",
    [49]: "WV",
    [50]: "WI",
    [51]: "WY",
    [52]: "PR",
    [53]: "VI",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "ST"},
  {value: 1, text: "AL"},
  {value: 2, text: "AK"},
  {value: 3, text: "AZ"},
  {value: 4, text: "AR"},
  {value: 5, text: "CA"},
  {value: 6, text: "CO"},
  {value: 7, text: "CT"},
  {value: 8, text: "DE"},
  {value: 9, text: "DC"},
  {value: 10, text: "FL"},
  {value: 11, text: "GA"},
  {value: 12, text: "HI"},
  {value: 13, text: "ID"},
  {value: 14, text: "IL"},
  {value: 15, text: "IN"},
  {value: 16, text: "IA"},
  {value: 17, text: "KS"},
  {value: 18, text: "KY"},
  {value: 19, text: "LA"},
  {value: 20, text: "ME"},
  {value: 21, text: "MD"},
  {value: 22, text: "MA"},
  {value: 23, text: "MI"},
  {value: 24, text: "MN"},
  {value: 25, text: "MS"},
  {value: 26, text: "MO"},
  {value: 27, text: "MT"},
  {value: 28, text: "NE"},
  {value: 29, text: "NV"},
  {value: 30, text: "NH"},
  {value: 31, text: "NJ"},
  {value: 32, text: "NM"},
  {value: 33, text: "NY"},
  {value: 34, text: "NC"},
  {value: 35, text: "ND"},
  {value: 36, text: "OH"},
  {value: 37, text: "OK"},
  {value: 38, text: "OR"},
  {value: 39, text: "PA"},
  {value: 40, text: "RI"},
  {value: 41, text: "SC"},
  {value: 42, text: "SD"},
  {value: 43, text: "TN"},
  {value: 44, text: "TX"},
  {value: 45, text: "UT"},
  {value: 46, text: "VT"},
  {value: 47, text: "VA"},
  {value: 48, text: "WA"},
  {value: 49, text: "WV"},
  {value: 50, text: "WI"},
  {value: 51, text: "WY"},
  {value: 52, text: "PR"},
  {value: 53, text: "VI"},
  ]
}

