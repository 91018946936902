import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { getContactsApi, getContactApi, updateContactApi, createContactApi, getContactUploadHistoryApi, uploadContactImportApi, sendPasswordResetApi } from './contacts.api';
import {
  getContactsActions, getContactActions, updateContactActions, createContactActions, contactImportHistoryShouldRefresh,
  getContactBulkImportHistoryActions, uploadContactBulkImport, createInteractionActions, sendPasswordResetActions
} from './contacts.actions';
import { ContactDto } from '../contacts/contacts.dtos';
import { BulkImportDto } from '../../common/dtos/bulkImportDto';
import { isAuthorized } from '../../common/dtos/auth';
import { getCompaniesActions } from '../company/actions';
import { getMhaContactsActions } from '../../common/actions/actions';


function* handleGetContacts() {
  const contactsList: ContactDto[] = yield select(state => state.contacts.contactsList);
  const contacts: ContactDto[] = contactsList !== undefined && contactsList.length !== 0 ? contactsList :
      yield call(getContactsApi);

  yield put({ type: getType(getContactsActions.success), payload: contacts });
}

function* handleGetContact(request: ReturnType<typeof getContactActions.request>) {
  const storeContact: ContactDto = yield select(state => state.contacts.contact);
  const contact: ContactDto = (storeContact !== undefined && storeContact.contactId === request.payload) ? storeContact : yield call(getContactApi, request.payload);

  yield put({ type: getType(getContactActions.success), payload: contact });
}

function* handleUpdateContact(request: ReturnType<typeof updateContactActions.request>) {
  const updatedContact: ContactDto = yield call(updateContactApi, request.payload);
  
  yield put({ type: getType(getContactActions.success), payload: updatedContact });
  yield put({ type: getType(getContactsActions.success), payload: [] });
  yield put({ type: getType(getContactsActions.request) });

  sessionStorage.removeItem('companies');
  yield put({ type: getType(getCompaniesActions.success), payload: [] });
  yield put({ type: getType(getCompaniesActions.request) });
  yield put({ type: getType(getMhaContactsActions.success), payload: [] });
}

function* handleCreateContact(request: ReturnType<typeof createContactActions.request>) {
  const createdContact: ContactDto = yield call(createContactApi, request.payload);

  yield put({ type: getType(getContactActions.success), payload: createdContact });
  yield put({ type: getType(getContactsActions.success), payload: [] });
  yield put({ type: getType(getContactsActions.request) });

  sessionStorage.removeItem('companies');
  yield put({ type: getType(getCompaniesActions.success), payload: [] });
  yield put({ type: getType(getCompaniesActions.request) });
  yield put({ type: getType(getMhaContactsActions.success), payload: [] });
}


function* handleGetContactImportHistory() {
  const refreshContactImportHistory: boolean = yield select(state => state.contacts.refreshContactImportHistory);
  const contactImportHistoryList: BulkImportDto[] = yield select(state => state.contacts.bulkImportHistory);
  const contactImportHistory: BulkImportDto[] = ((contactImportHistoryList !== undefined && contactImportHistoryList.length > 0) && !refreshContactImportHistory) ? contactImportHistoryList : yield call(getContactUploadHistoryApi);

  yield put({ type: getType(getContactBulkImportHistoryActions.success), payload: contactImportHistory });
  yield put({ type: getType(contactImportHistoryShouldRefresh), payload: false });
}

function* handleUploadContactImport(request: ReturnType<typeof uploadContactBulkImport>) {
  const success: boolean = yield call(uploadContactImportApi, request.payload);
  if (success) {
    yield put({ type: getType(contactImportHistoryShouldRefresh), payload: true });
    yield call(handleGetContactImportHistory);
    yield put({ type: getType(getContactsActions.success), payload: [] as ContactDto[] });
  }
}


function* handleCreateInteraction(request: ReturnType<typeof createInteractionActions.request>) {

  const interaction: ContactDto = yield call(updateContactApi, request.payload);

  yield put({ type: getType(createInteractionActions.success), payload: interaction });
}

function* handleSendPasswordReset(request: ReturnType<typeof sendPasswordResetActions.request>) {
  if (isAuthorized()) {
    const result: boolean = yield call(sendPasswordResetApi, request.payload);

    yield put({ type: getType(sendPasswordResetActions.success), payload: result });
  }
}



 function* watchInteractionCreateRequest() {
  try { yield takeLatest(getType(createInteractionActions.request), handleCreateInteraction); }
  catch (err) { }
}


 function* watchContactsGetRequest() {
  try { yield takeLatest(getType(getContactsActions.request), handleGetContacts); }
  catch (err) { }
}

 function* watchContactGetRequest() {
  try { yield takeLatest(getType(getContactActions.request), handleGetContact); }
  catch (err) { }
}

 function* watchContactUpdateRequest() {
  try { yield takeLatest(getType(updateContactActions.request), handleUpdateContact); }
  catch (err) { }
}

 function* watchContactCreateRequest() {
  try { yield takeLatest(getType(createContactActions.request), handleCreateContact); }
  catch (err) { }
}

 function* watchContactImportHistoryGetRequest() {
  try { yield takeLatest(getType(getContactBulkImportHistoryActions.request), handleGetContactImportHistory); }
  catch (err) { }
}

 function* watchContactUploadImportHistoryRequest() {
  try { yield takeLatest(getType(uploadContactBulkImport), handleUploadContactImport); }
  catch (err) { }
}

 function* watchSendPasswordResetRequest() {
  try { yield takeLatest(getType(sendPasswordResetActions.request), handleSendPasswordReset); }
  catch (err) { }
}



export default [
  //watchInteractionCreateRequest,
  watchContactsGetRequest,
  watchContactGetRequest,
  watchContactUpdateRequest,
  watchContactCreateRequest,
  watchContactImportHistoryGetRequest,
  watchContactUploadImportHistoryRequest,
  watchSendPasswordResetRequest,
];