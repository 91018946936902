import { createAsyncAction } from 'typesafe-actions';
import { TodoInteractionDto } from './sales.dtos';

export const getTodoInteractionsActions = createAsyncAction(
    'GET_TODO_INTERACTIONS_REQUEST',
    'GET_TODO_INTERACTIONS_SUCCESS',
    'GET_TODO_INTERACTIONS_ERROR',
)<undefined, TodoInteractionDto[], string>();

export const updateTodoInteractionsActions = createAsyncAction(
    'UPDATE_TODO_INTERACTIONS_REQUEST',
    'UPDATE_TODO_INTERACTIONS_SUCCESS',
    'UPDATE_TODO_INTERACTIONS_ERROR',
)<TodoInteractionDto, TodoInteractionDto, string>();

export const createTodoInteractionsActions = createAsyncAction(
    'CREATE_TODO_INTERACTIONS__HISTORY_REQUEST',
    'CREATE_TODO_INTERACTIONS_HISTORY_SUCCESS',
    'CREATE_TODO_INTERACTIONS_HISTORY_ERROR',
)<TodoInteractionDto, TodoInteractionDto, string>();

export const getTodoInteractionsHistoryActions = createAsyncAction(
    'GET_TODO_INTERACTIONS_HISTORY_REQUEST',
    'GET_TODO_INTERACTIONS_HISTORY_SUCCESS',
    'GET_TODO_INTERACTIONS__HISTORY_ERROR',
)<string, TodoInteractionDto[], string>();



