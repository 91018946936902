// react
import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

// material ui
import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import { rootSelector } from '../selectors/selectors';
import { cancelNotification } from '../actionCreators/dispatchNotification';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props}/>
}

const HiddenDiv = styled.div`
    display: none;
`;

const SnackbarNotification: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { message, type } = rootSelector(state => state.notification);

  useEffect(() => {
    if (message !== undefined && message.length > 0)
      setOpen(true);
  }, [message]);

  function handleClose() {
    cancelNotification();
  }

  return (message !== undefined && message.length > 0) ? (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={handleClose}
        >
          {type === "success" ? <CheckIcon /> : <CloseIcon />}
        </IconButton>
      ]}
    ><Alert severity={type === undefined ? "info" : type === 'success' ? "success" : "error"} onClose={handleClose}>{message}</Alert></Snackbar>
  ) : <HiddenDiv />;
}

export default SnackbarNotification;
