import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { ListClaimDto } from "../claims.dtos";
import moment from "moment";
import {
  TableCard,
  ListCardContent,
  LabelLink,
  NoPaddingIconButton,
  NoPaddingVisibilityOffIcon,
  NoPaddingVisibilityIcon,
} from "../../../common/styles/styles";


interface Props {
  dto: ListClaimDto;
  handleAssociate: (id: string) => void;
  handleDeassociate: (id: string) => void;
  handleSSNMaskToggle: () => void;
  handleGetMaskedSSNValue: (ssn: string | undefined) => string | undefined;
  isMasked: boolean;
  isReadOnly: boolean;
  index?: number;
  attached: boolean;
}

const ClaimDialogCard: React.FC<Props> = ({
  dto,
  handleAssociate,
  handleGetMaskedSSNValue,
  handleSSNMaskToggle,
  handleDeassociate,
  isMasked,
  isReadOnly,
  index,
  attached,
}) => {
  return (
    <Grid item xs={12} key={index} style={{ marginTop: "5px" }}>
      <TableCard raised>
        <ListCardContent>
          <Grid container>
            <div hidden data-cy={`o_list_card_id_${dto.label}`}>
              {dto.label}
            </div>

            <Grid item xs={12} sm={12} md={6} lg={2}>
              <Typography variant="body2" color="textPrimary">
                Name
              </Typography>
              <Typography variant="body1" color="secondary">
                {dto.label}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={1}>
              <Typography variant="body1" color="textPrimary">
                SSN{" "}
                {!isMasked ? (
                  <NoPaddingIconButton
                    tabIndex={-1}
                    aria-label="View SSN"
                    title="View SSN"
                    onClick={() => handleSSNMaskToggle()}
                  >
                    <NoPaddingVisibilityIcon color="primary" />
                  </NoPaddingIconButton>
                ) : (
                  <NoPaddingIconButton title="Hide SSN" aria-label="Hide SSN">
                    <NoPaddingVisibilityOffIcon
                      onClick={() => handleSSNMaskToggle()}
                      color="primary"
                    />
                  </NoPaddingIconButton>
                )}
              </Typography>
              <Typography variant="body1" color="secondary">
                {isMasked
                  ? handleGetMaskedSSNValue(dto.claimantSsn)
                  : dto.claimantSsn || "None on File"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={1}>
              <Typography variant="body2" color="textPrimary">
                BYE
              </Typography>
              <Typography variant="body1" color="secondary">
                {dto.benefitYearEndingDate === undefined
                  ? "None on File"
                  : moment(dto.benefitYearEndingDate).format("MM/DD/yyyy") ||
                    "None on File"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={2}>
              <Typography variant="body2" color="textPrimary">
                SUTA
              </Typography>
              <Typography variant="body1" color="secondary">
                {dto.suta || "None on File"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={2}>
              <Typography variant="body2" color="textPrimary">
                Employer
              </Typography>
              <Typography variant="body1" color="secondary">
                {dto.employerName === " " ? "None on File" : (dto.employerName ?? '').toUpperCase()}{" "}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={3}
              hidden={isReadOnly}
              style={{ textAlign: "end" }}
            >
              {!attached ? (
                <LabelLink
                  component={Button}
                  onClick={() => handleAssociate(dto.id ?? "new")}
                  data-cy={`o_list_card_link_${dto.label}`}
                  color="secondary"
                >
                  ASSOCIATE
                </LabelLink>
              ) : (
                <LabelLink
                  component={Button}
                  onClick={() => handleDeassociate(dto.id ?? "new")}
                  data-cy={`o_list_card_link_${dto.label}`}
                  color="secondary"
                >
                  DEASSOCIATE
                </LabelLink>
              )}
            </Grid>
          </Grid>
        </ListCardContent>
      </TableCard>
    </Grid>
  );
};
export default ClaimDialogCard;
