


import { ISelectOption } from "../dtos/SelectOptionDto";
enum ClaimantEmployerAgencyCodes {
        C = 0,
        E = 1,
        A = 2,
         
}

export class ClaimantEmployerAgencyCodes_class {

public static enum = ClaimantEmployerAgencyCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['C']: 0,
    ['E']: 1,
    ['A']: 2,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "Claimant",
    [1]: "Employer Client",
    [2]: "Agency",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "Claimant"},
  {value: 1, text: "Employer Client"},
  {value: 2, text: "Agency"},
  ]
}

