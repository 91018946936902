import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors, } from 'formik';

// material ui
import { Grid, TextField, FormControlLabel } from '@material-ui/core';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ClientQuestionnaireDto } from '../../dto';
import { MHARadio } from '../../../../common/styles/styles';
import { indicatorOptions, radioButtonBooleanOptions } from '../../constants/options';


interface AbsenteeismComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
}

const AbsenteeismComponent: React.FC<AbsenteeismComponentProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation
  }) => {

  return (
    <React.Fragment>
      <Grid item xs={12}>On what date did the final incident occur?</Grid>
      <Grid item xs={12} sm={12} md={12} lg={4}>
        <KeyboardDatePicker
          margin="normal"
          id="finalIncidentDate"
          name="finalIncidentDate"
          //label="Hire Date"
          format="MM/DD/yyyy"
          value={values.finalIncidentDate || null}
          data-cy="clientQuestionnaire_finalIncidentDate"
          fullWidth
          onChange={(date: MaterialUiPickersDate) => { setFieldValue('finalIncidentDate', date); setShouldBlockNavigation(true); }}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <br />
      <Grid item xs={12}>What reason (if any) did the claimant give for being absent/tardy on the final incident?</Grid>
      <Grid item xs={4}>
        <TextField
          id="reasonForAbsence"
          size="small"
          margin="none"
          value={values.reasonForAbsence || ''}
          name="reasonForAbsence"
          multiline
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.reasonForAbsence)}
          helperText={errors.reasonForAbsence && errors.reasonForAbsence}
          data-cy="clientQuestionnaire_reasonForAbsence"
          aria-label="What reason (if any) did the claimant give for being absent/tardy on the final incident?"
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={12}>Did the claimant provide advance notice of the absence/tardy?</Grid>
      <Grid item xs={12} container direction="row">
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="advanceNoticeGiven"
              name="advanceNoticeGiven"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('advanceNoticeGiven', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? false);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              checked={o.status === values.advanceNoticeGiven}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_advanceNoticeGiven" />
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid item xs={12}>Were other disciplinary actions/warnings considered in the decision to discharge?</Grid>
      <Grid container item xs={12} direction="row">
        {indicatorOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="disciplineConsideredIndicatorId"
              name="disciplineConsideredIndicatorId"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('disciplineConsideredIndicatorId', indicatorOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.id ?? 0);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              //add errors for validation
              checked={o.id === values.disciplineConsideredIndicatorId}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_disciplineConsidered" />
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid item xs={12}>Who (name and title) discharged the claimant?</Grid>
      <Grid item xs={4}>
        <TextField
          id="personWhoMadeDecisionToDischarge"
          size="small"
          margin="none"
          value={values.personWhoMadeDecisionToDischarge || ''}
          multiline
          label="Name"
          name="personWhoMadeDecisionToDischarge"
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.personWhoMadeDecisionToDischarge)}
          helperText={errors.personWhoMadeDecisionToDischarge && errors.personWhoMadeDecisionToDischarge}
          data-cy="clientQuestionnaire_adverseEffectsOnCompany"
          aria-label="Adverse effects on company"
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={4}>
        <TextField
          id="personWhoMadeDecisionTitle"
          size="small"
          margin="none"
          value={values.personWhoMadeDecisionTitle || ''}
          multiline
          label="Job Title"
          name="personWhoMadeDecisionTitle"
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          error={Boolean(errors.personWhoMadeDecisionTitle)}
          helperText={errors.personWhoMadeDecisionTitle && errors.personWhoMadeDecisionTitle}
          data-cy="clientQuestionnaire_adverseEffectsOnCompany"
          aria-label="Adverse effects on company"
          fullWidth
        />
      </Grid>
      <br />
    </React.Fragment>
  )
}

export default AbsenteeismComponent;
