import { combineReducers } from 'redux';
import { createReducer, getType } from 'typesafe-actions';

import {
  getClaimActions,
  getClaimsActions,
  getEligibilityEventsActions,
  getAppellateEventsActions,
  getEventsActions,
  getLogsActions,
  getMhaHearingContactsActions,
  getClaimDocumentsActions,
  documentUploadRequestActions,
  documentZipRequestActions,
  getHearingContactsInformationActions,
  didClaimDisassociate,
  getMhaAppealContactsActions,
  getMhaClaimsContactsActions,
  getMhaContactsActions,
  getClaimByDocumentActions,
  updateSidesActions,
  updateClaimActions,
  createClaimActions,
  getAllClaimsActions,
  deleteClaimActions,
  getMhaHearingRepresentativesActions,
} from './actions';
import {
  ClaimDto,
  ClaimInit,
  ClaimEventDto,
  ListClaimDto,
  ContactIdDto,
  ClaimLogDto,
  HearingContactsDto,
  AppellateEventDto,
  EligibilityEventDto,
} from './claims.dtos';
import {
  ClaimDocumentRequestDto,
  DocumentsDto,
} from '../documents/document.dtos';
import { ContactInformationDto } from '../../common/dtos/contactInformation';
import {
  createContactActions,
  updateContactActions,
} from '../contacts/contacts.actions';

const claimsReducer = combineReducers({
  // Claims
  claimsList: createReducer<ListClaimDto[]>([]).handleAction(
    [getClaimsActions.success],
    (state, action) => action.payload
  ),

  allClaimsList: createReducer<ListClaimDto[]>([]).handleAction(
    [getAllClaimsActions.success],
    (state, action) => action.payload
  ),

  claim: createReducer<ClaimDto>(ClaimInit)
    .handleAction(
      [getClaimActions.request, getClaimByDocumentActions.request],
      (state, _action) => ClaimInit
    )
    .handleAction(
      [getClaimActions.success, getClaimByDocumentActions.success],
      (state, action) => action.payload
    ),

  claimDuplicate: createReducer<boolean>(false).handleAction(
    [createClaimActions.failure, updateClaimActions.failure],
    (state, action) => action.payload === 'true'
  ),

  claimDocuments: createReducer<DocumentsDto[]>([]).handleAction(
    [getClaimDocumentsActions.success],
    (state, action) => action.payload
  ),

  didDisassociate: createReducer<boolean>(false).handleType(
    getType(didClaimDisassociate),
    (state, action) => action.payload
  ),

  didDelete: createReducer<boolean | null>(null).handleType(
    getType(deleteClaimActions.success),
    (state, action) => action.payload
  ),

  // Documents
  documentUploadRequest: createReducer<ClaimDocumentRequestDto>({
    claimId: '',
    documents: [],
  }).handleAction(
    [documentUploadRequestActions.request],
    (state, action) => action.payload
  ),

  documentZipRequest: createReducer<ClaimDocumentRequestDto>({
    claimId: '',
    documents: [],
  }).handleAction(
    [documentZipRequestActions.request],
    (state, action) => action.payload
  ),

  documentZip: createReducer<any>(null).handleAction(
    [documentZipRequestActions.success],
    (state, action) => action.payload
  ),

  // Sides
  sidesError: createReducer<string>('').handleAction(
    [updateSidesActions.failure],
    (state, action) => action.payload
  ),

  // Contacts
  mhaContactsList: createReducer<ContactIdDto[]>([])
    .handleAction(
      [getMhaContactsActions.success],
      (_state, action) => action.payload
    )
    .handleAction([createContactActions.request], (_state, _action) => [])
    .handleAction([updateContactActions.request], (_state, _action) => []),

  mhaAppealContactsList: createReducer<ContactIdDto[]>([])
    .handleAction(
      [getMhaAppealContactsActions.success],
      (_state, action) => action.payload
    )
    .handleAction([createContactActions.request], (_state, _action) => [])
    .handleAction([updateContactActions.request], (_state, _action) => []),

  mhaClaimsContactsList: createReducer<ContactIdDto[]>([])
    .handleAction(
      [getMhaClaimsContactsActions.success],
      (_state, action) => action.payload
    )
    .handleAction([createContactActions.request], (_state, _action) => [])
    .handleAction([updateContactActions.request], (_state, _action) => []),

  mhaHearingContactsList: createReducer<ContactIdDto[]>([])
    .handleAction(
      [getMhaHearingContactsActions.success],
      (_state, action) => action.payload
    )
    .handleAction([createContactActions.request], (_state, _action) => [])
    .handleAction([updateContactActions.request], (_state, _action) => []),

  mhaHearingRepresentativesList: createReducer<ContactIdDto[]>([])
    .handleAction(
      [getMhaHearingRepresentativesActions.success],
      (_state, action) => action.payload
    )
    .handleAction([createContactActions.request], (_state, _action) => [])
    .handleAction([updateContactActions.request], (_state, _action) => []),

  hearingContactsInfoList: createReducer<ContactInformationDto[]>([])
    .handleAction(
      [getHearingContactsInformationActions.success],
      (_state, action) => action.payload
    )
    .handleAction([createContactActions.request], (_state, _action) => [])
    .handleAction([updateContactActions.request], (_state, _action) => []),

  hearingContactsInfoRequest: createReducer<HearingContactsDto[]>([])
    .handleAction(
      [getHearingContactsInformationActions.request],
      (_state, action) => action.payload
    )
    .handleAction([createContactActions.request], (_state, _action) => [])
    .handleAction([updateContactActions.request], (_state, _action) => []),

  // Events
  claimEvents: createReducer<ClaimEventDto[]>([]).handleAction(
    [getEventsActions.success],
    (state, action) => action.payload
  ),

  eligibilityEvents: createReducer<EligibilityEventDto[]>([]).handleAction(
    [getEligibilityEventsActions.success],
    (state, action) => action.payload
  ),

  appellateEvents: createReducer<AppellateEventDto[]>([]).handleAction(
    [getAppellateEventsActions.success],
    (state, action) => action.payload
  ),

  //Logs
  logs: createReducer<ClaimLogDto[]>([]).handleAction(
    [getLogsActions.success],
    (state, action) => action.payload
  ),
});

export default claimsReducer;
