 
/* Autogenerated by Typewriter do NOT edit
   Dtos.tst is the generating template */


export interface IUisSiPension401Koccurrence  { 
    
    id?: number;
    pension401Kind?: number;
    pension401KfrequencyCode?: number;
    pension401KamountPerPeriod?: number;
    pension401KamountPerPeriodUnknown?: number;
    datePension401Kissued?: Date;
    datePension401KissuedUnknown?: number;
    whoContributedPension401K?: number;
    claimantPensionContributionPercent?: string;
    claimantPension401KcontributionPercentUnknown?: number;
    employerPension401KcontributionPercent?: string;
    employerPension401KcontributionPercentUnknown?: number;
    employerLastContributionDate?: Date;
    employerLastContributionDateUnknown?: number;
    pension401Kcomments?: string; 
} 

export class IUisSiPension401Koccurrence_PropertyAttributes {
    static readonly id_Attributes = {
      required: { value: true }, name: "Id", 
      };
       static readonly pension401Kind_Attributes = {
      required: { value: false }, name: "Pension401Kind", 
      };
       static readonly pension401KfrequencyCode_Attributes = {
      required: { value: false }, name: "Pension401KfrequencyCode", 
      };
       static readonly pension401KamountPerPeriod_Attributes = {
      required: { value: false }, name: "Pension401KamountPerPeriod", 
      };
       static readonly pension401KamountPerPeriodUnknown_Attributes = {
      required: { value: false }, name: "Pension401KamountPerPeriodUnknown", 
      };
       static readonly datePension401Kissued_Attributes = {
      required: { value: false }, name: "DatePension401Kissued", 
      };
       static readonly datePension401KissuedUnknown_Attributes = {
      required: { value: false }, name: "DatePension401KissuedUnknown", 
      };
       static readonly whoContributedPension401K_Attributes = {
      required: { value: false }, name: "WhoContributedPension401K", 
      };
       static readonly claimantPensionContributionPercent_Attributes = {
      stringLength: { maxLength: 3, }, required: { value: false }, name: "ClaimantPensionContributionPercent", 
      };
       static readonly claimantPension401KcontributionPercentUnknown_Attributes = {
      required: { value: false }, name: "ClaimantPension401KcontributionPercentUnknown", 
      };
       static readonly employerPension401KcontributionPercent_Attributes = {
      stringLength: { maxLength: 3, }, required: { value: false }, name: "EmployerPension401KcontributionPercent", 
      };
       static readonly employerPension401KcontributionPercentUnknown_Attributes = {
      required: { value: false }, name: "EmployerPension401KcontributionPercentUnknown", 
      };
       static readonly employerLastContributionDate_Attributes = {
      required: { value: false }, name: "EmployerLastContributionDate", 
      };
       static readonly employerLastContributionDateUnknown_Attributes = {
      required: { value: false }, name: "EmployerLastContributionDateUnknown", 
      };
       static readonly pension401Kcomments_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "Pension401Kcomments", 
      };
      
}

