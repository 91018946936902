import styled from 'styled-components';

// material ui
import {
  Grid, Card as MuiCard, Button, Radio, TextField, Checkbox
} from '@material-ui/core';


// Styled forgotPassword components ....


export const SettingsSubtitle = styled.h4`
    margin: 1px;
`;

export const HealthSystemRadio = styled(Radio)`
&.Mui-checked { 
  color: #008A86; 
}
`;

export const HealthSystemCheckbox = styled(Checkbox)`
&.Mui-checked { 
  color: #008A86; 
}
`;

export const HealthSystemInput = styled(TextField)`

width: 300px;

`;
export const Card = styled(MuiCard)`
    margin: 16px;
`;

export const SmallButton = styled(Button)`
    width: 72%;
    margin-right: 14%;
    margin-left: 14%;
`;

export const SystemSettingsToggleGrid = styled(Grid)`
    > form {
      > div.MuiTextField-root { margin-top: 0; }
    }
    ${props => {
    if (props.hidden) {
      return `display: none;`;
    }
  }}
`;

export const CancelButton = styled(Button)`
    
    margin-top: 11px;
    margin-left: 5px;
    background-color: #c64d00;
    color: white;
`;

export const AddButton = styled(Button)`
    
    margin-top: 12px;
    margin-left: 5px;
  
`;