import { AxiosError } from 'axios';

import apiClient from '../../../common/api/apiClient';
import { ApiError } from '../../../common/api/apiResponse';
import { ChangePasswordDto } from './changePassword.dtos';
import { showNotification } from '../../../common/actionCreators/dispatchNotification';

// endpoints
const changePasswordUrl = '/account/change-password';

export const changePasswordApi = async (request: ChangePasswordDto) => {
  return await apiClient()
    .post(changePasswordUrl, request)
    .then((res) => {
      showNotification('Your password has been updated.', 'success');
      return true;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, 'error');
        }
      } else {
        showNotification('An error occurred while changing password.', 'error');
      }
      throw err;
    });
};
