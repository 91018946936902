import React from 'react';
import { forwardRef, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { NavLink, NavLinkProps, useHistory } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText, Badge, Collapse, MenuItem } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import IconSpacer from '@material-ui/icons/FiberManualRecord';
import { Theme } from '../../themes/theme';
import { Menu, MenuBadge } from './menu.styles';


export interface MenuNavItemProps {
  name: string
  link?: string
  Icon?: React.ComponentType<SvgIconProps>
  IconStyles?: React.CSSProperties
  IconClassName?: string
  IconClassNameActive?: string
  isCollapsed?: boolean
  isOpen?: boolean
  isNested?: boolean
  nestingLevel?: number
  nestingOffset?: number
  className?: string
  items?: MenuNavItemProps[]
  match?: object
}

export interface ListItemLinkProps extends NavLinkProps { }

export interface ListItemComponentProps extends HTMLAttributes<HTMLElement> {
  link?: string | null
  children?: any
  isCollapsed?: boolean | null
}

// ----------------------------------------------------------------------

export const ListItemLink: React.ExoticComponent<NavLinkProps> = forwardRef(
  (props: ListItemLinkProps, ref: React.Ref<HTMLAnchorElement>) => (
    <NavLink exact {...props} innerRef={ref} />
  ),
)

// Can be a link, or button
export const ListItemComponent: React.ExoticComponent<
  ListItemComponentProps
> = forwardRef((props: ListItemComponentProps, ref: React.Ref<HTMLDivElement>) => {
  // Omit isCollapsed
  const { isCollapsed, ...newProps } = props;
  const classes = useStyles();

  const component =
    typeof props.link === 'string' ? (
      <ListItem {...newProps} button component={ListItemLink} to={props.link} />
    ) : (
        <ListItem {...newProps} button />
      )

  return (
    <div ref={ref} className={clsx(isCollapsed && classes.navItemCollapsedWrapper)} >
      {component}
    </div>
  )
})

const MenuNavItem: React.FC<MenuNavItemProps> = (props: MenuNavItemProps) => {
  let history = useHistory();
  const {
    name,
    link,
    Icon,
    IconStyles = {},
    IconClassName = '',
    isCollapsed,
    nestingLevel = 0,
    nestingOffset = 16,
    className,
    items = [],
  } = props;
  const isTooltipEnabeld = isCollapsed;
  const classes = useStyles();
  const hasChildren = items && items.length > 0;

  function getItemsAll(items: MenuNavItemProps[]): MenuNavItemProps[] {
    return items.reduce((allItems: MenuNavItemProps[], item: MenuNavItemProps) => {

      if (item.items && item.items.length) {
        return allItems.concat([item], getItemsAll(item.items));
      } else {
        return allItems.concat([item]);
      }
    }, []);
  }

  const itemsAll = getItemsAll(items);
  const hasChildrenAndIsActive =
    hasChildren &&
    itemsAll.filter(item => `#${item.link}` === window.location.hash).length > 0;
  const isOpen = hasChildrenAndIsActive || false;
  const [open, setOpen] = React.useState(isOpen);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClick() {
    setOpen(!open);
  }

  function handleCollapsedNavigation(link: string) {
    handleClick();
    history.push(link);
  }

  function handleGetBadgecontent(): number {
    let badgeContent: number = 0;

    switch (name) {
      case 'Sales':
      case 'Projects':
      case 'Tasks':
        badgeContent = 2;
        break;
      default:
        badgeContent = 0;
        break;
    }
    return badgeContent;
  }

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }
  const closeMenu = () => {
    setAnchorEl(null);
  }

  const ListItemIconInner =
    (!!Icon && <Icon />) ||
    (isCollapsed && <IconSpacer className={classes.iconSpacer} />) ||
    '';

  const nestingOffsetChildren = !isCollapsed ? nestingOffset + 16 : 16;

  const menuItemStyle = {
    fontSize: `${1.18 - 0.07 * nestingLevel}em`,
    paddingLeft: `${!ListItemIconInner ? nestingOffset + 25 : nestingOffset}px`,
    paddingTop: `${!ListItemIconInner ? 6 : 4}px`,
    paddingBottom: `${!ListItemIconInner ? 6 : 4}px`,
  }

  const ListItemElement = (
    <ListItemComponent
      link={link}
      className={clsx(
        classes.navItem,
        isCollapsed && classes.navItemCollapsed,
        hasChildrenAndIsActive && 'active',
        className,
      )}
      style={menuItemStyle}
      isCollapsed={isCollapsed}
      onClick={handleClick}
      title={name || link}
    >
      {!!ListItemIconInner && (
        <ListItemIcon
          style={IconStyles}
          className={clsx(classes.navItemIcon, IconClassName)}
        >
          {ListItemIconInner}
        </ListItemIcon>
      )}
      <MenuBadge
        badgeContent={handleGetBadgecontent()}
        color="error"
        invisible={Boolean(handleGetBadgecontent() === 0)}
      >
        <ListItemText primary={name} disableTypography={true} />
      </MenuBadge>
    </ListItemComponent>
  )

  const ListItemMenuElement = (
    <ListItemComponent
      link={link}
      className={clsx(
        classes.navItem,
        //hasChildrenAndIsActive && 'active',
        (link !== undefined ? link : '0000000') === window.location.pathname && 'active',
        className,
      )}
      style={menuItemStyle}
      isCollapsed={false}
      onClick={handleClick}
      title={name || link}
    >
      <ListItemText primary={name} disableTypography={true} title={name} />
    </ListItemComponent>
  )

  const CollapsedMenu = hasChildren ? (
    <Menu
      id="menu-item"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={closeMenu}
    >
      {items.map(item => (
        <MenuItem dense key={item.name || item.link}
          onClick={e => { handleCollapsedNavigation(item.link ?? ''); }}
          className={clsx(
            classes.navItem,
            (item.link !== undefined ? item.link : '0000000') === window.location.pathname && 'active',
            className,
          )}
          style={menuItemStyle}
          title={item.name || item.link}
        >
          <ListItemText primary={item.name} disableTypography={true} title={item.name} />
        </MenuItem>
      ))}

    </Menu >
  ) : (
      <Menu
        id="menu-item"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        {ListItemMenuElement}
      </Menu >)

  const CollapsedListItemElement = (
    <>
      <ListItemComponent
        onClick={openMenu}
        className={clsx(
          classes.navItem,
          isCollapsed && classes.navItemCollapsed,
          //hasChildrenAndIsActive && 'active',
          (link !== undefined ? link : '0000000') === window.location.pathname && 'active',
          className,
        )}
        style={menuItemStyle}
        isCollapsed={false}
        title={name || link}
      >
        {!!ListItemIconInner && (
          <Badge color="error" variant="dot" overlap="circle" invisible={Boolean(handleGetBadgecontent() === 0)}>
            <ListItemIcon
              style={IconStyles}
              className={clsx(classes.navItemIcon, IconClassName)}
            >
              {ListItemIconInner}
            </ListItemIcon>
          </Badge>
        )}
      </ListItemComponent>
      {CollapsedMenu}
    </>
  )

  // Menu Root 
  const ListItemRoot = isTooltipEnabeld ? (
    CollapsedListItemElement
  ) : (ListItemElement)

  const ListItemChildren = hasChildren ? (
    <div className={clsx(classes.navItemChildren)}>
      <Collapse in={open || (!isCollapsed && (items.findIndex((item) => (item.link ?? '00000') === window.location.pathname) > -1))}
        timeout="auto" unmountOnExit>
        {/* <Divider /> */}
        <List component="div" disablePadding>
          {items.map(item => (
            <MenuNavItem
              {...item}
              isNested={true}
              nestingLevel={nestingLevel + 1}
              isCollapsed={isCollapsed}
              key={item.name || item.link}
              isOpen={open}
              nestingOffset={nestingOffsetChildren}
            />
          ))}
        </List>
      </Collapse>
    </div>
  ) : null

  return (
    <div
      className={clsx(
        hasChildrenAndIsActive && classes.navItemWrapperActive,
        hasChildrenAndIsActive && isCollapsed && classes.navItemWrapperActiveCollapsed,
      )}
    >
      {ListItemRoot}
      {ListItemChildren}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navItemWrapper: {
      position: 'relative',
    },
    navItemWrapperActive: {
    },
    navItemWrapperActiveCollapsed: {
      background: '#C0C2C4',
    },
    navItem: {
      position: 'relative',
      transition: 'background .23s ease',
      '&.active': {
        //color: theme.palette.secondary.main,
        background: '#C0C2C4',
        '& .MuiListItemIcon-root': {
          color: theme.palette.secondary.main,
        },
      },
    },
    navItemChildren: {
      transition: 'background .23s ease',
    },
    navItemChildrenActive: {
    },
    navItemCollapsed: {
      whiteSpace: 'nowrap',
      flexWrap: 'nowrap',
      width: theme.menu.widthCollapsed,
      '& $iconToggle': {
        position: 'absolute',
        bottom: -1,
        fontSize: 14,
        marginLeft: '-0.5em',
      },
      '&.active': {
        background: '#C0C2C4',
      },
    },
    navItemCollapsedWrapper: {
      width: theme.menu.widthCollapsed,
    },
    navItemIcon: {
      minWidth: 40,
      '& svg': {
        fontSize: '1.83em',
      }
    },
    iconToggle: {},
    iconSpacer: {
      fontSize: 13,
      marginLeft: 6,
    },
  }),
)

export default MenuNavItem;