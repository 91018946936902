import React from 'react';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
import { useParams } from 'react-router-dom';
import { useMediaQuery, } from '@material-ui/core';
import TabPanel from '../../common/components/tabPanel';
import CompanyHeader from './components/header';
import { getCompanyActions, companyShouldRefresh } from './actions';
import { companyInit } from './dtos';
import CompanyDetails from './components/details';
import EmployeeCounts from './components/employeeCount';
import CompanyBilling from './components/billing';
import UnemploymentCharges from './components/unemploymentCharges';
import { ClearAppBar, StyledCardContent, StyledTab, StyledTabs, ViewCard, ViewGrid } from '../../common/styles/styles';


const Company: React.FC = () => {
  const { employerId } = useParams<{ employerId: string }>();
  const dispatch = useDispatch();

  const [tabValue, setTabValue] = React.useState(0);
  const shouldCollapse = useMediaQuery('(max-width:768px)');

  React.useEffect(() => {
    if (employerId !== undefined && employerId !== 'new') {
      dispatch({ type: getType(companyShouldRefresh), payload: employerId });
      dispatch({ type: getType(getCompanyActions.request), payload: employerId });
    }
    else {
      dispatch({ type: getType(companyShouldRefresh), payload: '' });
      dispatch({ type: getType(getCompanyActions.success), payload: companyInit });
    }
  }, [employerId, dispatch]);


  const changeTab = (event: any, newValue: number) => setTabValue(newValue);


  return <ViewGrid container item xs={12}>
    <ViewCard>
      <CompanyHeader />
      <StyledCardContent>
        <ClearAppBar position="static">
          <StyledTabs aria-labelledby={`tab-${tabValue}`} orientation={shouldCollapse ? 'vertical' : 'horizontal'} centered={true} value={tabValue} onChange={changeTab} aria-label="Profile options" variant="fullWidth" scrollButtons="auto">
            <StyledTab id="tab-0" aria-controls="tabpanel-0" label="Details" data-cy="employer_details_tab" />
            <StyledTab id="tab-1" aria-controls="tabpanel-1" label="Employee Count History" data-cy="employer_employeeCount_tab" />
            <StyledTab id="tab-2" aria-controls="tabpanel-2" label="Unemployment Agency Charges" data-cy="employer_unemployment_tab" />
            <StyledTab id="tab-3" aria-controls="tabpanel-3" label="Billing" data-cy="employer_billing_tab" />

          </StyledTabs>
        </ClearAppBar>
        <TabPanel value={tabValue} index={0}><CompanyDetails /></TabPanel>
        <TabPanel value={tabValue} index={1}><EmployeeCounts /></TabPanel>
        <TabPanel value={tabValue} index={2}><UnemploymentCharges /></TabPanel>
        <TabPanel value={tabValue} index={3}><CompanyBilling /></TabPanel>

      </StyledCardContent>
    </ViewCard>
  </ViewGrid>
}

export default Company;
