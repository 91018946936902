import React from 'react';
import { FormikHandlers, FormikErrors, getIn, FormikHelpers } from 'formik';
// material ui
import { Grid, TextField, MenuItem, Checkbox, Typography, FormControlLabel } from '@material-ui/core';
import { CompanyDto } from '../dtos';
import { PhoneDto } from '../../../common/dtos/phone';
import phoneNumberField from '../../../common/components/phoneNumberField';
import extensionField from '../../../common/components/extensionField';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { TextSelect } from '../styles';


interface Props extends React.HTMLProps<React.ReactNode> {
  phone: PhoneDto;
  errors: FormikErrors<CompanyDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  index: number;
  setShouldBlockNavigation: (o: boolean) => void;
  phones: PhoneDto[];
  setFieldValue: FormikHelpers<CompanyDto>["setFieldValue"];
  isReadOnly: boolean;
}

const CompanyPhone: React.FC<Props> = (
  {
    phone,
    errors,
    handleChange,
    handleBlur,
    index,
    setShouldBlockNavigation,
    phones,
    setFieldValue,
    isReadOnly
  }) => {

  const handlePrimaryPhones = () => {
    for (let i = 0; i < phones.length; i++) {
      if (i !== index) {
        setFieldValue(`phones[${i}].isPrimary`, false);
      }
    }
  }

  return (
    <React.Fragment key={'phoneFragment' + index}>
      <Grid item sm={12} md={3} key={'phone_contactTypeId' + index}>
        <TextSelect
          id={`phones[${index}].contactTypeId`}
          name={`phones[${index}].contactTypeId`}
          value={phone.contactTypeId || 1}
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={handleBlur}
          fullWidth
          select
          disabled={isReadOnly}
          data-cy={`employer_phone_${index}_phoneTypeDropDown`}
        >
          <MenuItem value={0} disabled>Please select an item</MenuItem>
          <MenuItem value={1}>Cell</MenuItem>
          <MenuItem value={2}>Home</MenuItem>
          <MenuItem value={3}>Work</MenuItem>
        </TextSelect>
      </Grid>
      <Grid item xs={12} sm={12} md={6} key={'phone_phoneNumber' + index}>
        <TextField
          key={'phoneNumber_field' + index}
          id={`phones[${index}].phoneNumber`}
          size="small"
          margin="none"
          value={phone.phoneNumber || ''}
          label="Phone Number"
          name={`phones[${index}].phoneNumber`}
          disabled={isReadOnly}
          onChange={e => { handleChange(e); }}
          onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
          error={Boolean(getIn(errors, `phones[${index}].phoneNumber`))}
          helperText={getIn(errors, `phones[${index}].phoneNumber`) && getIn(errors, `phones[${index}].phoneNumber`)}
          data-cy={`employer_phone_${index}_phoneNumberInput`}
          fullWidth
          InputProps={{ inputComponent: (phoneNumberField as any) }}
        />
      </Grid>
      <Grid item sm={12} md={3} key={'phone_extension' + index}>
        <TextField
          key={'extension_field' + index}
          id={`phones[${index}].extension`}
          size="small"
          margin="none"
          value={phone.extension || ''}
          label="Extension"
          disabled={isReadOnly}
          name={`phones[${index}].extension`}
          onChange={e => { handleChange(e); }}
          onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
          error={Boolean(getIn(errors, `phones[${index}].extension`))}
          helperText={getIn(errors, `phones[${index}].extension`) && getIn(errors, `phones[${index}].extension`)}
          data-cy={`employer_phone_${index}_phoneExtensionInput`}
          fullWidth
          InputProps={{ inputComponent: (extensionField as any) }}
        />
      </Grid>
      <Grid item xs={2} sm={10} hidden={phones.length === 1} />
      <Grid item xs={10} sm={2} hidden={phones.length === 1}>
        <FormControlLabel
          id={`phones[${index}].isPrimary`}
          name={`phones[${index}].isPrimary`}
          onChange={(e) => { handleChange(e); handlePrimaryPhones(); }}
          onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
          value={phone.isPrimary}
          checked={phone.isPrimary}
          disabled={isReadOnly}
          control={<Checkbox icon={<RadioButtonUncheckedIcon />} checkedIcon={<RadioButtonCheckedIcon />} size="small"
            data-cy={`employer_phone_${index}_phonePrimaryInput`}
          />}
          label={<Typography variant="subtitle2">Primary</Typography>}
        />
      </Grid>
    </React.Fragment>
  )
}

export default CompanyPhone;
