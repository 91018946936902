import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { changePasswordActions } from './changePassword.actions';
import { changePasswordInit } from './changePassword.dtos';

const changePasswordReducer = combineReducers({
  changePasswordRequest: createReducer(changePasswordInit)
    .handleAction(
      [
        changePasswordActions.request,
      ], (state, action) => action.payload),

  changePasswordSuccess: createReducer(false as boolean)
    .handleAction(
      [
        changePasswordActions.success,
      ], (state, action) => true),

  changePasswordError: createReducer('')
    .handleAction(
      [
        changePasswordActions.failure,
      ], (state, action) => action.payload)
});

export default changePasswordReducer;