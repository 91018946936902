import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import { getType } from 'typesafe-actions';
import {
  Box,
  Checkbox,
  Card,
  CardContent,
  IconButton,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  MenuItem,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { rootSelector } from '../../../common/selectors/selectors';
import { CompanyDto } from '../dtos';
import CompanyListCard from './companyListCard';
import { getCompaniesActions, companyShouldRefresh } from '../actions';
import { isReadOnly } from '../../../common/dtos/auth';
import {
  Checkbox1,
  EndButton,
  LargeIconButton,
} from '../../../common/styles/styles';
import { getGoverningDistrictsActions } from '../../account/profile/profile.actions';
import { FeatureTypeEnum } from '../../../common/enums/featureTypeEnum';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CompanyList: React.FC = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  let query = useQuery();

  const systemCompaniesList = rootSelector(
    (state) => state.companies.companiesList
  );
  const governingDistricts = rootSelector(
    (state) => state.governingDistricts.governingDistrictList
  );

  const limit = 9;

  const [listPage, setListPage] = React.useState(1);
  const [resultCount, setResultCount] = React.useState(0);
  const [viewActiveOnly, setViewActiveOnly] = React.useState(true);
  const [companiesList, setCompaniesList] = React.useState([] as CompanyDto[]);
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [searchState, setSearchState] = React.useState<string>('');

  React.useEffect(() => {
    dispatch({ type: getType(getCompaniesActions.request) });
    dispatch({ type: getType(getGoverningDistrictsActions.request) });
  }, [dispatch]);

  React.useEffect(() => {
    setCompaniesList(systemCompaniesList);
  }, [systemCompaniesList]);

  React.useEffect(() => {
    let search = query.get('search');
    if (search !== undefined && search !== null && search.length !== 0) {
      setSearchQuery(search);
      query.delete('search');
    }
  }, []);

  React.useEffect(() => {
    let list = systemCompaniesList
      .filter(
        (item: CompanyDto) => item.isActive || item.isActive === viewActiveOnly
      )
      .filter(
        (item: CompanyDto) =>
          searchState.length === 0 || item.stateCode === searchState
      )
      .filter(
        (item: CompanyDto) =>
          (item.name !== undefined &&
            item.name !== '' &&
            item.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item.suta !== undefined &&
            item.suta !== '' &&
            item.suta.includes(searchQuery.replace('-', ''))) ||
          (item.fein !== undefined &&
            item.fein !== '' &&
            item.fein.includes(searchQuery.replace('-', '')))
      )
      .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));

    setResultCount(list.length);
    setCompaniesList(list.slice(listPage * limit - limit, listPage * limit));
  }, [systemCompaniesList, searchQuery, searchState, listPage]);

  const onAddCompany = () => {
    let s = isReadOnly(FeatureTypeEnum.Employers);
    if (s === false) {
      dispatch({ type: getType(companyShouldRefresh), payload: '' });
      history.push('/employers/new');
    }
  };

  const onCompaniesImport = () => {
    let s = isReadOnly(FeatureTypeEnum.Employers);
    if (s === false) {
      history.push('/bulk-import/employers');
    }
  };

  const handleViewActiveOnlyToggle = () => {
    setViewActiveOnly(!viewActiveOnly);
  };

  const handleCancelSearch = () => {
    setSearchQuery('');
    setListPage(1);
  };

  return (
    <>
      <Box p={2}>
        <Grid container data-cy='employer_list_Container'>
          <Grid item xs={11}>
            <Typography variant='subtitle1' display='inline'>
              Employers
            </Typography>
            <Typography
              variant='subtitle1'
              color='textSecondary'
              display='inline'
            >
              {' '}
              ({resultCount})
            </Typography>
          </Grid>
          <Grid item xs={1} hidden={isReadOnly(FeatureTypeEnum.Employers)}>
            <LargeIconButton
              title='Add Employer'
              color='secondary'
              onClick={onAddCompany}
              data-cy='employer_add_button'
            >
              <AddBoxRoundedIcon />
            </LargeIconButton>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={2}>
              <Formik
                initialValues={{ search: searchQuery ?? '' }}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={Yup.object({
                  search: Yup.string().max(
                    200,
                    'Must be 200 characters or less'
                  ),
                })}
                onSubmit={(values) => {
                  setSearchQuery(values.search);
                }}
                enableReinitialize={true}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <TextField
                        id='search'
                        size='small'
                        margin='none'
                        value={values.search}
                        label='Search'
                        name='search'
                        fullWidth
                        onChange={handleChange}
                        onBlur={(e) => {
                          handleBlur(e);
                          handleSubmit();
                        }}
                        error={Boolean(errors.search)}
                        helperText={
                          errors.search && touched.search && errors.search
                        }
                        data-cy='companiesListSearch'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <IconButton
                                aria-label='Search Employers'
                                onClick={() => handleSubmit()}
                                edge='start'
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                          endAdornment:
                            searchQuery.length === 0 ? (
                              <></>
                            ) : (
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='Clear Search'
                                  onClick={() => handleCancelSearch()}
                                  edge='start'
                                >
                                  <ClearIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                        }}
                      />
                    </form>
                  );
                }}
              </Formik>
            </Grid>
            <Grid item xs={6} sm={2}>
              <TextField
                id='state'
                size='small'
                margin='none'
                select
                fullWidth
                value={searchState}
                label='Search by state'
                name='searchState'
                onChange={(e) =>
                  setSearchState((e.target as HTMLInputElement).value)
                }
                data-cy='employer_list_state_search'
              >
                <MenuItem key={'0'} value={''}>
                  All
                </MenuItem>
                {governingDistricts.map((o) => (
                  <MenuItem key={o.code} value={o.code}>
                    {o.code}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Checkbox1
                onChange={handleViewActiveOnlyToggle}
                value={!viewActiveOnly}
                checked={!viewActiveOnly}
                control={
                  <Checkbox
                    size='small'
                    data-cy='pageTemplate_template_3ViewActiveOnly'
                  />
                }
                label={
                  <Typography variant='subtitle2'>Show inactive</Typography>
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              hidden={isReadOnly(FeatureTypeEnum.Employers)}
            >
              <EndButton
                title='Bulk Import'
                type='button'
                variant='contained'
                color='primary'
                onClick={onCompaniesImport}
                data-cy='employer_import_button'
              >
                Bulk Import
              </EndButton>
            </Grid>
          </Grid>

          <br />
          <br />

          <Grid container spacing={2}>
            {companiesList.map((o, index) => (
              <CompanyListCard key={'CompanyCard-' + index} company={o} />
            ))}
          </Grid>

          <Grid item xs={12}>
            &nbsp;
          </Grid>
          <Grid item xs={12}>
            <Pagination
              hidden={companiesList.length === 0}
              count={Math.ceil(resultCount / limit)}
              variant='outlined'
              color='primary'
              size='small'
              page={listPage}
              onChange={(e, p) => {
                setListPage(p);
              }}
            />
          </Grid>

          {companiesList.length === 0 && (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container justifyContent='center' alignItems='center'>
                    <Typography variant='subtitle1' color='secondary'>
                      No records found
                      {searchQuery.length === 0
                        ? '.'
                        : ' matching ' + searchQuery}
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
              <br />
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default CompanyList;
