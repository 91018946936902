import React from 'react';
import NumberFormat from 'react-number-format';

interface ExtensionFieldProps {
  inputRef: (instance: NumberFormat | null) => void,
  name: string,
  onChange: (event: { target: { name: string; value: string; }}) => void,
}

const extensionField: React.FC<ExtensionFieldProps> = (props: ExtensionFieldProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <>
      <NumberFormat
        {...other}
        decimalScale={0}
        fixedDecimalScale={true}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
      />
    </>
  );

}

export default extensionField;