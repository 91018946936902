import React from 'react';
import {
  FormikHandlers,
  FormikErrors,
  FieldArray,
  FormikHelpers,
} from 'formik';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import {
  ContactDto,
  ContactemployersInit,
  MhaContactFunctionLinkDto,
} from '../contacts.dtos';
import ContactEmployers from './employer';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CompanyDto } from '../../company/dtos';
import {
  FormIcon,
  IndentGrid,
  PaddedTextField,
  TLMGrid,
  TMGrid,
} from '../../../common/styles/styles';

interface Props extends React.HTMLProps<React.ReactNode> {
  values: ContactDto;
  errors: FormikErrors<ContactDto>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  functionOptions: { name: string; id: number }[];
  setShouldBlockNavigation: (o: boolean) => void;
  employerOptions: string[];
  setFieldValue: FormikHelpers<ContactDto>['setFieldValue'];
  isReadOnly: boolean;
  companies: CompanyDto[];
}

const ContactInformation: React.FC<Props> = ({
  values,
  errors,
  companies,
  handleChange,
  handleBlur,
  functionOptions,
  setShouldBlockNavigation,
  employerOptions,
  setFieldValue,
  isReadOnly,
}) => {
  const handleSelectFunctionOptions = (
    functionOptions: MhaContactFunctionLinkDto[]
  ) => {
    setFieldValue('mhaContactFunctions', functionOptions);
  };

  return (
    <IndentGrid container item xs={12} spacing={2}>
      <Grid item xs={12} sm={5}>
        <PaddedTextField
          id='firstName'
          size='small'
          margin='none'
          value={values.firstName || ''}
          InputLabelProps={{ required: true }}
          label='First Name'
          name='firstName'
          disabled={isReadOnly}
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.firstName)}
          helperText={errors.firstName && errors.firstName}
          data-cy='contact_details_firstNameInput'
          fullWidth
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id='lastName'
          size='small'
          margin='none'
          InputLabelProps={{ required: true }}
          disabled={isReadOnly}
          value={values.lastName || ''}
          label='Last name'
          name='lastName'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.lastName)}
          helperText={errors.lastName && errors.lastName}
          data-cy='contact_details_lastNameInput'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextField
          id='title'
          size='small'
          margin='none'
          value={values.title || ''}
          label='Title'
          name='title'
          disabled={isReadOnly}
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.title)}
          helperText={errors.title && errors.title}
          data-cy='contact_details_titleInput'
          fullWidth
        />
      </Grid>
      <Grid item xs={1}></Grid>

      <Grid item xs={12} sm={6}>
        <Autocomplete
          id='mhaContactFunctions'
          options={functionOptions as { id: number; name: string }[]}
          autoComplete
          getOptionLabel={(o) => o.name ?? ''}
          multiple
          disabled={isReadOnly}
          value={functionOptions.filter((o) =>
            values.mhaContactFunctions?.map((m) => m.functionId).includes(o.id)
          )}
          onChange={(_e: any, v: { id: number; name: string }[] | null) => {
            handleSelectFunctionOptions(
              v === null
                ? ([] as MhaContactFunctionLinkDto[])
                : v.map((o) => {
                    return { functionId: o.id } as MhaContactFunctionLinkDto;
                  })
            );
          }}
          renderInput={(params) => (
            <PaddedTextField
              {...params}
              id='mhaContactFunctions'
              size='small'
              name='mhaContactFunctions'
              margin='none'
              label='Functions'
              aria-label='Function Options'
              onBlur={(e) => {
                handleBlur(e);
                setShouldBlockNavigation(true);
              }}
              data-cy='claim_information_hearingContacts'
              fullWidth
            />
          )}
        />
      </Grid>

      <Grid container item xs={12} spacing={1}>
        <Grid container item xs={12} sm={6}>
          <FieldArray
            name='contactEmployers'
            render={(arrayHelpers) => (
              <>
                <TMGrid item xs={11}>
                  {values.contactEmployers &&
                    values.contactEmployers
                      .filter((f, i) => i === 0)
                      .map((o, index) => (
                        <ContactEmployers
                          companies={companies}
                          isReadOnly={isReadOnly}
                          key={'ContactEmployer-' + index}
                          setFieldValue={setFieldValue}
                          contactEmployers={o}
                          employerOptions={employerOptions}
                          index={index}
                          errors={errors}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setShouldBlockNavigation={setShouldBlockNavigation}
                          arrayHelpers={arrayHelpers}
                        />
                      ))}
                </TMGrid>
                <TMGrid item xs={1} container alignItems='center'>
                  <div hidden={isReadOnly}>
                    <FormIcon
                      hidden={isReadOnly}
                      title='Add to Employer List'
                      color='secondary'
                      onClick={
                        isReadOnly
                          ? () => {}
                          : () => {
                              arrayHelpers.push(ContactemployersInit);
                            }
                      }
                      data-cy='employer_details_addBusinessUnit'
                    >
                      <AddBoxRoundedIcon />
                    </FormIcon>
                  </div>
                </TMGrid>

                {values.contactEmployers &&
                  values.contactEmployers
                    .filter((f, i) => i !== 0)
                    .map((o, index) => (
                      <ContactEmployers
                        companies={companies}
                        isReadOnly={isReadOnly}
                        setFieldValue={setFieldValue}
                        key={'ContactEmployer_Additional-' + index}
                        employerOptions={employerOptions}
                        contactEmployers={o}
                        index={index + 1}
                        errors={errors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setShouldBlockNavigation={setShouldBlockNavigation}
                        arrayHelpers={arrayHelpers}
                      />
                    ))}
              </>
            )}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={6}
          alignContent='flex-start'
          alignItems='flex-end'
        >
          <TLMGrid item xs={12}>
            <TextField
              id='alternativeEmployer'
              size='small'
              margin='none'
              value={values.alternativeEmployer || ''}
              label='Alternative Employer'
              name='alternativeEmployer'
              disabled={isReadOnly}
              onChange={(e) => {
                handleChange(e);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              error={Boolean(errors.alternativeEmployer)}
              helperText={
                errors.alternativeEmployer && errors.alternativeEmployer
              }
              data-cy='contact_details_alternativeEmployerInput'
              fullWidth
            />
          </TLMGrid>
        </Grid>
      </Grid>
    </IndentGrid>
  );
};

export default ContactInformation;
