import { AxiosError } from 'axios';

import { ApiError } from '../../common/api/apiResponse';
import apiClient from '../../common/api/apiClient';
import { showNotification } from '../../common/actionCreators/dispatchNotification';


const reportsUrl = 'reports';

export const getReportsApi = async () => {
  return await apiClient().get(reportsUrl)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) showNotification(axiosError.response.data.error.message, 'error');
      }
      else showNotification('An error occurred while retrieving reports.', 'error');
      throw err;
    });
};
