


import { ISelectOption } from "../dtos/SelectOptionDto";
enum Pension401kReceivedCodes {
        Item1 = 0,
        Item2 = 1,
        Item3 = 2,
        Item4 = 3,
        Item5 = 4,
         
}

export class Pension401kReceivedCodes_class {

public static enum = Pension401kReceivedCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['Item1']: 0,
    ['Item2']: 1,
    ['Item3']: 2,
    ['Item4']: 3,
    ['Item5']: 4,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "1- Pension",
    [1]: "2- 401K",
    [2]: "3- Pension and 401K",
    [3]: "4- No",
    [4]: "5- Unknown",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "1- Pension"},
  {value: 1, text: "2- 401K"},
  {value: 2, text: "3- Pension and 401K"},
  {value: 3, text: "4- No"},
  {value: 4, text: "5- Unknown"},
  ]
}

