import React from 'react';
import { Prompt } from 'react-router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
import {
  Checkbox, Dialog, DialogContent, DialogTitle, DialogActions, FormControlLabel,
  Grid, TextField,
  Typography
} from '@material-ui/core';
import { LocationCodeDto } from '../codes.dtos';
import { createLocationCodesActions, updateLocationCodesActions } from '../codes.actions';
import { requiredError } from '../../../../common/constants/strings';
import { ButtonGrid, StyledSubmitButton, StyledCancelButton } from '../../../../common/styles/styles';
import { DialogCardContent, DialogInputLabel } from '../codes.styles';


interface Props {
  dto: LocationCodeDto;
  open: boolean;
  isReadOnly: boolean;
  onCancel: () => void;
}

const LocationCodeDialog: React.FC<Props> = ({ dto, onCancel, isReadOnly }) => {
  const [dialogOpen, setDialogOpen] = React.useState(true);


  const dispatch = useDispatch();


  const cancelDialog = () => onCancel();


  return <Formik
    initialValues={dto}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={
      Yup.object({
        isActive: Yup.boolean().required(),
        description: Yup.string().max(300, "Must be 300 characters or less").required(requiredError),
        name: Yup.string().max(300, "Must be 300 characters or less").required(requiredError),
      })
    }
    onSubmit={(values, { setSubmitting, resetForm }) => {
      setTimeout(() => {
        setSubmitting(false);
        resetForm();

      }, 700);
      dispatch({
        type: values.id ?
          getType(updateLocationCodesActions.request) :
          getType(createLocationCodesActions.request),
        payload: [values]
      });
      setDialogOpen(false);
    }}
    enableReinitialize={true}
  >
    {(props) => {
      const {
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
      } = props;
      return (
        <Dialog
          open={dialogOpen}
          data-cy="location_code_addInteraction_dialog"
        >
          <DialogTitle>Location Code Information</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <DialogCardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControlLabel
                      id="isActive"
                      name="isActive"
                      onChange={handleChange}
                      value={values.isActive}
                      checked={values.isActive}
                      control={
                        <Checkbox
                          disabled={isReadOnly}

                          size="small"
                          data-cy="location_code_isActiveCheckbox"
                        />
                      }
                      label={
                        <Typography variant="subtitle2">Active</Typography>
                      }
                    />
                  </Grid>


                  <Grid item xs={12}>
                    <DialogInputLabel>* Code</DialogInputLabel>
                    <TextField
                      id="name"
                      name="name"
                      onChange={handleChange}
                      fullWidth
                      InputLabelProps={{ required: true }}
                      value={values.name || ''}
                      data-cy="location_code_nameInput"
                      onBlur={handleBlur}
                      disabled={isReadOnly}

                      error={Boolean(errors.name)}
                      helperText={errors.name}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DialogInputLabel>* Description</DialogInputLabel>
                    <TextField
                      id="description"
                      name="description"
                      onChange={handleChange}
                      fullWidth
                      disabled={isReadOnly}
                      InputLabelProps={{ required: true }}
                      value={values.description || ''}
                      data-cy="location_code_descriptionInput"
                      onBlur={handleBlur}
                      error={Boolean(errors.description)}
                      helperText={errors.description}
                    />
                  </Grid>

                </Grid>

              </DialogCardContent>

              <DialogActions>
                <ButtonGrid container>
                  <StyledSubmitButton
                    type="submit"
                    color="primary"
                    disabled={isSubmitting || isReadOnly}
                    data-cy="location_code_submitButton"
                  >
                    Save
                  </StyledSubmitButton>

                  <StyledCancelButton
                    data-cy="location_code_cancelButton"
                    color="secondary"
                    onClick={cancelDialog}
                  >
                    Cancel
                  </StyledCancelButton>
                </ButtonGrid>
              </DialogActions>
            </form>
          </DialogContent>
          <Prompt
            message='You have unsaved edits, are you sure you want to leave?'
          />
        </Dialog>
      );
    }}
  </Formik>
}

export default LocationCodeDialog;
