import React from 'react';
import {
  FormikHandlers,
  FormikHelpers,
  FieldArray,
  FormikErrors,
  getIn,
} from 'formik';
import moment from 'moment';
// material ui
import {
  Grid,
  TextField,
  MenuItem,
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from '@material-ui/core';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import { businessUnitInit, CompanyDto } from '../dtos';
import { HealthSystemGroupDto } from '../../settings/healthSystems/healthSystems.dtos';
import CompanyBusinessUnit from './businessUnit';
import { MhaContactsDto } from '../../../common/dtos/dto';
import Label from '../../../common/components/label';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { GoverningDistrictDto } from '../../account/profile/profile.dtos';
import SelectStatePrompt from '../dialogs/selectStatePompt';
import MaskHandler from '../../../common/components/maskHandler';
import FeinMask from '../../../common/components/feinMask';
import {
  FormNameIcon,
  IndentGrid,
  MHARadio,
  PaddedBottomTextField,
  PaddedTMGrid,
  TMGrid,
  TMGrid3,
} from '../../../common/styles/styles';
import { LocationTextField, StateTextField } from '../styles';

interface Props extends React.HTMLProps<React.ReactNode> {
  values: CompanyDto;
  errors: FormikErrors<CompanyDto>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  handleHealthSystemChange: (name: string, values: CompanyDto) => void;
  healthSystemOptions: string[];
  groupOptions: HealthSystemGroupDto[];
  mhaContactOptions: MhaContactsDto[];
  setShouldBlockNavigation: (o: boolean) => void;
  setFieldValue: FormikHelpers<CompanyDto>['setFieldValue'];
  locationCodeOptions: string[];
  isReadOnly: boolean;
  governingDistricts: GoverningDistrictDto[];
}

const companyTypeOptions = [
  { name: 'Contributing', id: 1 },
  { name: 'Reimbursing', id: 2 },
];

const CompanyInformation: React.FC<Props> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  healthSystemOptions,
  groupOptions,
  mhaContactOptions,
  handleHealthSystemChange,
  setShouldBlockNavigation,
  setFieldValue,
  locationCodeOptions,
  isReadOnly,
  governingDistricts,
}) => {
  const [statePromptOpen, setStatePromptOpen] = React.useState(false);
  const [stateOptions, setStateOptions] = React.useState(
    [] as GoverningDistrictDto[]
  );
  const [sutaInput, setSutaInput] = React.useState(
    undefined as unknown as HTMLInputElement
  );
  const [checkState, setCheckState] = React.useState(false);

  React.useEffect(
    () => setStateOptions(governingDistricts),
    [governingDistricts]
  );

  const cancelDialog = () => {
    if (sutaInput !== undefined) setTimeout(() => sutaInput.blur(), 100);
    setStatePromptOpen(false);
    setTimeout(() => setCheckState(false), 100);
  };

  const handleStatePromptCheck = (state: string) => {
    if (!checkState && (state === undefined || state === '')) {
      setStatePromptOpen(true);
      setCheckState(true);
    }
  };

  const handleStartDateChange = (
    startDate: MaterialUiPickersDate | Date | null | undefined | string,
    endDate: Date | undefined
  ) => {
    if (
      startDate !== undefined &&
      startDate !== null &&
      (startDate as moment.Moment).isValid()
    ) {
      if (endDate === null || endDate === undefined) {
        setFieldValue('contractEndDate', new Date());
      } else if (endDate && startDate && moment(startDate)) {
        if (moment(startDate) > moment(endDate)) {
          setFieldValue('contractEndDate', startDate);
        }
      }
    }
  };

  const handleEndDateChange = (
    endDate: MaterialUiPickersDate | Date | null | undefined | string,
    startDate: Date | undefined
  ) => {
    if (
      endDate !== undefined &&
      endDate !== null &&
      (endDate as moment.Moment).isValid()
    ) {
      if (startDate === null || startDate === undefined) {
        setFieldValue('contractStartDate', endDate);
      } else if (startDate && endDate && moment(endDate)) {
        if (moment(endDate) < moment(startDate)) {
          setFieldValue('contractStartDate', endDate);
        }
      }
    }
  };

  return (
    <IndentGrid container spacing={2}>
      {statePromptOpen && (
        <SelectStatePrompt
          values={values}
          open={statePromptOpen}
          onCancel={cancelDialog}
          errors={errors}
          handleBlur={handleBlur}
          stateOptions={stateOptions}
          setFieldValue={setFieldValue}
        />
      )}

      <TMGrid container item md={12} sm={12} xs={12} lg={6}>
        <TextField
          id='name'
          size='small'
          margin='none'
          value={values.name || ''}
          label='Name'
          name='name'
          disabled={isReadOnly}
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.name)}
          helperText={errors.name && errors.name}
          data-cy='employer_details_nameInput'
          inputProps={{ autoComplete: 'new-password' }}
          InputLabelProps={{ required: true }}
          fullWidth
        />
      </TMGrid>

      <Grid item sm={12} xs={12} md={12} lg={6}>
        <Autocomplete
          key='Location Code Autocomplete'
          id='locationCode'
          options={locationCodeOptions.filter((c) => c !== '')}
          value={values.locationCode || ''}
          disabled={isReadOnly}
          onChange={(_e: any, v: string | null) => {
            setFieldValue('locationCode', v ?? '');
            handleChange(_e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          renderInput={(params) => (
            <LocationTextField
              {...params}
              key='Location Code'
              id='locationCode'
              size='small'
              margin='none'
              disabled={isReadOnly}
              value={values.locationCode || ''}
              label='Location Code'
              onChange={(e) => {
                handleChange(e);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              name='locationCode'
              error={Boolean(getIn(errors, 'locationCode'))}
              helperText={
                getIn(errors, 'locationCode') && getIn(errors, 'locationCode')
              }
              InputProps={{ ...params.InputProps }}
              data-cy='employer_details_locationCodeInput'
              fullWidth
            />
          )}
        />
      </Grid>

      <TMGrid container item md={12} sm={12} xs={12} lg={6}>
        <FieldArray
          name='businessUnitList'
          render={(arrayHelpers) => (
            <>
              <Grid item xs={11}>
                <Label>Business Units</Label>
              </Grid>
              <Grid item xs={1}>
                <div hidden={isReadOnly} style={{ top: 20 }}>
                  <FormNameIcon
                    title='Add to Business Unit List'
                    color='secondary'
                    onClick={() => arrayHelpers.push(businessUnitInit)}
                  >
                    <AddBoxRoundedIcon />
                  </FormNameIcon>
                </div>
              </Grid>

              <Grid container item xs={12}>
                {values.businessUnitList &&
                  values.businessUnitList.map((o, i) => (
                    <CompanyBusinessUnit
                      isReadOnly={isReadOnly}
                      key={'CompanyBusinessUnit-' + i}
                      bu={o}
                      index={i}
                      errors={errors}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      arrayHelpers={arrayHelpers}
                      handleBlur={handleBlur}
                      setShouldBlockNavigation={setShouldBlockNavigation}
                    />
                  ))}
              </Grid>
            </>
          )}
        />
      </TMGrid>

      <TMGrid3 item md={12} sm={12} xs={12} lg={6}>
        <Autocomplete
          id='stateCode'
          options={stateOptions}
          getOptionLabel={(option) => option.code}
          value={stateOptions.find((o) => o.code === values.stateCode) || null}
          disabled={isReadOnly}
          onChange={(_e: any, v: GoverningDistrictDto | null) => {
            setFieldValue('stateCode', v?.code ?? '');
          }}
          renderInput={(params) => (
            <StateTextField
              {...params}
              id='stateCode'
              size='small'
              name='stateCode'
              margin='none'
              label='State'
              disabled={isReadOnly}
              value={values.stateCode || ''}
              onBlur={(e) => {
                handleBlur(e);
              }}
              error={Boolean(getIn(errors, 'stateCode'))}
              helperText={
                getIn(errors, 'stateCode') && getIn(errors, 'stateCode')
              }
              data-cy='claim_employer_stateCode'
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
              fullWidth
            />
          )}
        />
      </TMGrid3>

      <TMGrid item xs={12} sm={12} md={12} lg={6}>
        <PaddedBottomTextField
          id='fein'
          size='small'
          margin='none'
          value={values.fein || ''}
          label='FEIN'
          name='fein'
          disabled={isReadOnly}
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.fein)}
          helperText={errors.fein && errors.fein}
          InputProps={{ inputComponent: FeinMask as any }}
          data-cy='employer_details_feinInput'
          fullWidth
          InputLabelProps={{ required: true }}
        />
      </TMGrid>

      <TMGrid item xs={12} sm={12} md={12} lg={6}>
        <TextField
          id='suta'
          size='small'
          margin='none'
          value={values.suta || ''}
          label='SUTA'
          name='suta'
          inputRef={(input) => setSutaInput(input)}
          disabled={isReadOnly}
          onFocus={() => {
            handleStatePromptCheck(values.stateCode ?? '');
          }}
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.suta)}
          helperText={errors.suta && errors.suta}
          InputProps={{ inputComponent: MaskHandler(values.stateCode ?? '') }}
          data-cy='employer_details_sutaInput'
          fullWidth
          InputLabelProps={{ required: true }}
        />
      </TMGrid>

      <TMGrid item xs={12} sm={12} md={12} lg={4}>
        <TextField
          id='healthSystem'
          size='small'
          margin='none'
          select
          disabled={isReadOnly}
          value={values.healthSystem || ''}
          label='Health System'
          name='healthSystem'
          onChange={(e) => {
            handleChange(e);
            handleHealthSystemChange(e.target.value ?? '', values);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.healthSystem)}
          helperText={errors.healthSystem && errors.healthSystem}
          data-cy='employer_details_healthSystemInput'
          fullWidth
          InputLabelProps={{ required: true }}
        >
          {healthSystemOptions
            .sort((a, b) => a.localeCompare(b))
            .map((option, index) => (
              <MenuItem key={option + index} value={option}>
                {option}
              </MenuItem>
            ))}
        </TextField>
      </TMGrid>
      <TMGrid item xs={12} sm={12} md={12} lg={4}>
        <TextField
          id='healthSystemGroupId'
          size='small'
          margin='none'
          select
          disabled={isReadOnly}
          value={values.healthSystemGroupId || ''}
          label='Group'
          name='healthSystemGroupId'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.healthSystemGroupId)}
          helperText={errors.healthSystemGroupId && errors.healthSystemGroupId}
          data-cy='employer_details_healthSystemGroupIdInput_select'
          fullWidth
          InputLabelProps={{ required: true }}
        >
          {groupOptions
            .filter((f) => f.isActive)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
        </TextField>
      </TMGrid>
      <TMGrid item xs={12} sm={12} md={12} lg={4}>
        <TextField
          id='mhacontactId'
          size='small'
          margin='none'
          select
          disabled={isReadOnly}
          value={values.mhacontactId || ''}
          label='MHA-Contact'
          name='mhacontactId'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.mhacontactId)}
          helperText={errors.mhacontactId && errors.mhacontactId}
          data-cy='employer_details_mhacontactIdInput_select'
          fullWidth
        >
          {mhaContactOptions
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((option, index) => (
              <MenuItem key={option.contactId + index} value={option.contactId}>
                {option.name}
              </MenuItem>
            ))}
        </TextField>
      </TMGrid>
      <PaddedTMGrid container direction='row' item xs={12}>
        {companyTypeOptions
          .filter((g) => g.name !== '')
          .map((o, index) => (
            <Grid item xs={12} lg={4} key={o.name + index}>
              <FormControl error={Boolean(errors.riskTypeId)}>
                <FormControlLabel
                  onChange={(
                    e:
                      | React.ChangeEvent<HTMLInputElement>
                      | React.ChangeEvent<{}>,
                    checked: boolean
                  ) => {
                    setFieldValue(
                      'riskTypeId',
                      companyTypeOptions.find(
                        (o) => o.name === (e.target as HTMLInputElement).value
                      )?.id ?? 1
                    );
                  }}
                  checked={o.id === values.riskTypeId}
                  value={o.name}
                  control={<MHARadio disabled={isReadOnly} size='small' />}
                  label={o.name}
                  data-cy=''
                />
                <FormHelperText id='riskTypeId_helper_text'>
                  {errors.riskTypeId && errors.riskTypeId}
                </FormHelperText>
              </FormControl>
            </Grid>
          ))}
      </PaddedTMGrid>
      <TMGrid item xs={12}>
        <FormControl
          component='fieldset'
          error={Boolean(errors.billingInformationId)}
        >
          <FormLabel>
            <Label>Billing Information</Label>
          </FormLabel>
          <legend hidden={true}>Billing Information options</legend>
          <RadioGroup
            id='billingInformationId'
            row
            aria-label='Billing Type'
            name='billingInformationId'
            defaultValue='1'
            data-cy='employer_details_billingTypeGroup'
            value={values.billingInformationId + ''}
            onChange={(e) => {
              handleChange(e);
              setShouldBlockNavigation(true);
            }}
            onBlur={handleBlur}
          >
            <FormControlLabel
              value='1'
              label={<small>Fee for service</small>}
              control={<Radio disabled={isReadOnly} color='primary' />}
              data-cy='employer_details_billType1'
            />
            <FormControlLabel
              value='2'
              label={<small>Special Circumstances</small>}
              control={
                <Radio
                  disabled={isReadOnly}
                  color='primary'
                  data-cy='employer_details_billType2'
                />
              }
            />
            <FormControlLabel
              value='3'
              label={<small>Trinity Contract</small>}
              control={<Radio disabled={isReadOnly} color='primary' />}
              data-cy='employer_details_billType3'
            />
            <FormControlLabel
              value='4'
              label={<small>Other</small>}
              control={<Radio disabled={isReadOnly} color='primary' />}
              data-cy='employer_details_billType4'
            />
          </RadioGroup>
          <FormHelperText id='billingInformationId_helper_text'>
            {errors.billingInformationId && errors.billingInformationId}
          </FormHelperText>
        </FormControl>
      </TMGrid>

      <Grid container item xs={12} sm={12} md={12} lg={6} spacing={1}>
        <Grid item xs={12}>
          <Label>POA Information</Label>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <KeyboardDatePicker
            id='poacomplatedAtClientDate'
            size='small'
            margin='none'
            disabled={isReadOnly}
            format='MM/DD/yyyy'
            value={values.poacomplatedAtClientDate || null}
            label='Completed At Client'
            name='poacomplatedAtClientDate'
            onChange={(date: MaterialUiPickersDate) =>
              setFieldValue('poacomplatedAtClientDate', date)
            }
            onBlur={handleBlur}
            error={Boolean(errors.poacomplatedAtClientDate)}
            helperText={
              errors.poacomplatedAtClientDate && errors.poacomplatedAtClientDate
            }
            data-cy='employer_details_poacomplatedAtClientDateInput'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <KeyboardDatePicker
            id='poafiledWithStateDate'
            size='small'
            margin='none'
            format='MM/DD/yyyy'
            disabled={isReadOnly}
            value={values.poafiledWithStateDate || null}
            label='Filed with State'
            name='poafiledWithStateDate'
            onChange={(date: MaterialUiPickersDate) =>
              setFieldValue('poafiledWithStateDate', date)
            }
            data-cy='employer_details_poafiledWithStateDateInput'
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={12} md={12} lg={5} spacing={1}>
        <Grid item xs={12}>
          <Label>Contract Information</Label>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <KeyboardDatePicker
            id='contractStartDate'
            size='small'
            margin='none'
            format='MM/DD/yyyy'
            disabled={isReadOnly}
            //style={{ paddingLeft: 1 }}
            value={values.contractStartDate || null}
            label='Start Date'
            name='contractStartDate'
            onChange={(date: MaterialUiPickersDate) => {
              setFieldValue('contractStartDate', date);
              handleStartDateChange(date, values.contractStartDate);
            }}
            onBlur={handleBlur}
            error={Boolean(errors.contractStartDate)}
            helperText={errors.contractStartDate && errors.contractStartDate}
            data-cy='employer_details_contractStartDateInput'
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <KeyboardDatePicker
            id='contractEndDate'
            size='small'
            margin='none'
            format='MM/DD/yyyy'
            disabled={isReadOnly}
            //style={{ paddingLeft: 2 }}
            value={values.contractEndDate || null}
            label='End Date'
            name='contractEndDate'
            onChange={(date: MaterialUiPickersDate) => {
              setFieldValue('contractEndDate', date);
              handleEndDateChange(date, values.contractEndDate);
            }}
            onBlur={handleBlur}
            error={Boolean(errors.contractEndDate)}
            helperText={errors.contractEndDate && errors.contractEndDate}
            data-cy='employer_details_contractEndDateInput'
            fullWidth
          />
        </Grid>
      </Grid>
    </IndentGrid>
  );
};

export default CompanyInformation;
