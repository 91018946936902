import { IClaim } from '../common/dtos/ClaimDto';
import { IMc } from '../common/dtos/McDto';
import { IQuestionnaire } from '../common/dtos/QuestionnaireDto';
import { ISi } from '../common/dtos/SiDto';
import { Pension401kReceivedCodes_class } from '../common/enums/Pension401kReceivedCodes';
import { ClaimDto } from '../features/claims/claims.dtos';
import {
  DocumentExtraDto,
  DocumentsDto,
  UiSidesDto,
} from '../features/documents/document.dtos';
import { ClientQuestionnaireDto } from '../features/questionnaire/dto';
import { getCompareDate, dateCompareType } from './setCompareDate';

/**
 * Checks and sets claimDto, returns partial filled ClaimDto for a new claim.
 * parameter.
 * @param claim: System ClaimDto
 */
export const getCheckedClaim = (claim: ClaimDto): ClaimDto => {
  if (claim !== undefined && claim.documents[0] !== undefined) {
    let c: ClaimDto = { ...claim } as ClaimDto;
    let d = c.documents[0];
    let extra = d.uiSides === undefined ? d.extra : d.uiSides?.extra;

    // claimantFirstName
    if (
      (c.claimantFirstName === undefined || c.claimantFirstName.length === 0) &&
      extra !== undefined &&
      extra.claimantFirstName !== undefined
    )
      c.claimantFirstName = extra.claimantFirstName;

    // claimantLastName
    if (
      (c.claimantLastName === undefined || c.claimantLastName.length === 0) &&
      extra !== undefined &&
      extra.claimantLastName !== undefined
    )
      c.claimantLastName = extra.claimantLastName;

    // claimantJobTitle
    //if (
    //  (c.claimantJobTitle === undefined || c.claimantJobTitle.length === 0) &&
    //  extra !== undefined && extra.claimantJobTitle !== undefined
    //)
    //  c.claimantJobTitle = extra.claimantJobTitle;

    // claimantSsn
    if (
      (c.claimantSsn === undefined || c.claimantSsn.length === 0) &&
      extra !== undefined &&
      extra.ssn !== undefined
    )
      c.claimantSsn = extra.ssn.replace('-', '');

    // employerId
    if (
      (c.employerId === undefined || c.employerId.length === 0) &&
      extra !== undefined &&
      extra.employerId !== undefined
    )
      c.employerId = extra.employerId;

    // stateCode
    if (
      (c.stateCode === undefined || c.stateCode.length === 0) &&
      extra !== undefined &&
      extra.employerStateCode !== undefined
    )
      c.stateCode = extra.employerStateCode;

    // dateFirstWorked
    //if (
    //  (c.dateFirstWorked === undefined || c.dateFirstWorked === null) &&
    //  extra !== undefined && extra.dateFirstWorked !== undefined
    //)
    //  c.dateFirstWorked = extra.dateFirstWorked;

    // dateLastWorked
    //if (
    //  (c.dateLastWorked === undefined || c.dateLastWorked === null) &&
    //  extra !== undefined && extra.dateLastWorked !== undefined
    //)
    //  c.dateLastWorked = extra.dateLastWorked;

    // benefitYearBeginningDate
    if (
      (c.benefitYearBeginningDate === undefined ||
        c.benefitYearBeginningDate === null) &&
      extra !== undefined &&
      extra.benefitYearBeginingDate !== undefined
    )
      c.benefitYearBeginningDate = extra.benefitYearBeginingDate;

    // benefitYearEndingDate
    if (
      (c.benefitYearEndingDate === undefined ||
        c.benefitYearEndingDate === null) &&
      extra !== undefined &&
      extra.benefitYearEndingDate !== undefined
    )
      c.benefitYearEndingDate = extra.benefitYearEndingDate;
    else if (extra !== undefined && extra.benefitYearBeginingDate !== undefined)
      c.benefitYearEndingDate = getCompareDate(
        extra.benefitYearBeginingDate,
        undefined,
        dateCompareType.byb
      );

    // dateReceived
    if (
      (c.dateReceived === undefined || c.dateReceived === null) &&
      extra !== undefined &&
      extra.documentDate !== undefined
    )
      c.dateReceived = extra.documentDate;

    // weeklyBenefitAmount
    if (
      (c.weeklyBenefitAmount === undefined || c.dateReceived === null) &&
      extra !== undefined &&
      extra.weeklyBenefitAmount !== undefined
    )
      c.weeklyBenefitAmount = extra.weeklyBenefitAmount;

    // potentialLiabilityAmount
    if (
      (c.potentialLiabilityAmount === undefined ||
        c.potentialLiabilityAmount === null) &&
      extra !== undefined &&
      extra.potentialLiability !== undefined
    )
      c.potentialLiabilityAmount = extra.potentialLiability;

    // basePeriodStartDate
    if (
      (c.basePeriodStartDate === undefined || c.basePeriodStartDate === null) &&
      extra !== undefined &&
      extra.basePeriodStartDate !== undefined
    )
      c.basePeriodStartDate = extra.basePeriodStartDate;

    // basePeriodEndDate
    if (
      (c.basePeriodEndDate === undefined || c.basePeriodEndDate === null) &&
      extra !== undefined &&
      extra.basePeriodEndDate !== undefined
    )
      c.basePeriodEndDate = extra.basePeriodEndDate;

    return c;
  } else {
    return { ...claim } as ClaimDto;
  }
};

/**
 * Checks and sets siDto for SIDES SI, returns partial filled SiDto for a new SIDES claim.
 * parameter.
 * @param si: System SiDto
 * @param doc: System Claim
 */
export const getCheckedSiRequest = (si: ISi, doc: DocumentsDto): ISi => {
  if (!!si.correctedFein) return si;
  if (!!si.attachmentInd || si.attachmentInd === 0) return si;

  if (si !== undefined && doc !== undefined) {
    let e: DocumentExtraDto | undefined = doc.extra;
    let c: IClaim | undefined = si.claim;
    let q: IQuestionnaire | undefined = si.claim?.questionnaire;

    // Initial values
    si.attachmentInd = 1;
    si.receivingWorkersCompensationInd = 1;
    si.remunerationInd = 1;
    si.receivePension401Kind = Pension401kReceivedCodes_class.enum.Item4;

    // Extra
    if (!!e) {
      // fein
      if (!!e.fein) si.correctedFein = e.fein;

      // ssn
      if (!!e.ssn) si.ssn = e.ssn;
    }

    // Claim
    if (!!c) {
      // employerName
      if (!!c.employerName) si.employerName = c.employerName;

      // claimantFirstName
      if (!!c.claimantFirstName) si.claimantFirstName = c.claimantFirstName;

      // claimantFirstName
      if (!!c.claimantLastName) si.claimantLastName = c.claimantLastName;
    }

    // Questionnaire
    if (!!q) {
      // claimantJobTitle
      if (q.claimantJobTitle !== undefined)
        si.claimantJobTitle = q.claimantJobTitle;

      // dateFirstWorked
      if (!!q.hireDateTime)
        si.employerReportedClaimantFirstDayofWork = q.hireDateTime;

      // dateLastWorked
      if (!!q.lastDayWorkedDate)
        si.employerReportedClaimantLastDayofWork = q.lastDayWorkedDate;

      // separationDate
      if (!!q.separationDate) si.effectiveSeparationDate = q.separationDate;
    }

    return si;
  } else {
    return { ...si } as ISi;
  }
};

/**
 * Checks and sets siDto for SIDES MC, returns partial filled McDto for a new SIDES claim.
 * parameter.
 * @param mc: System McDto
 * @param doc: System Claim
 */
export const getCheckedMcRequest = (mc: IMc, doc: DocumentsDto): IMc => {
  if (!!mc.correctedFein) return mc;

  if (mc !== undefined && doc !== undefined) {
    let e: DocumentExtraDto | undefined = doc.extra;
    let c: IClaim | undefined = mc.claim;
    let q: IQuestionnaire | undefined = mc.claim?.questionnaire;

    // Initial values
    mc.attachmentInd = 1;

    // Extra
    if (!!e) {
      // fein
      if (!!e.fein) mc.correctedFein = e.fein;

      // ssn
      if (!!e.ssn) mc.ssn = e.ssn;
    }

    // Claim
    if (!!c) {
      // employerName
      if (!!c.employerName) mc.employerName = c.employerName;

      // claimantFirstName
      if (!!c.claimantFirstName) mc.claimantFirstName = c.claimantFirstName;

      // claimantFirstName
      if (!!c.claimantLastName) mc.claimantLastName = c.claimantLastName;
    }

    // Questionnaire
    if (!!q) {
    }

    return mc;
  } else {
    return { ...mc } as IMc;
  }
};

/**
 * Checks and sets claimDto for SIDES, returns partial filled ClaimDto for a new SIDES claim.
 * parameter.
 * @param claim: System ClaimDto
 */
export const getCheckedSIDESClaim = (claim: ClaimDto): ClaimDto => {
  if (claim !== undefined && claim.documents[0] !== undefined) {
    let c: ClaimDto = { ...claim } as ClaimDto;
    let e: DocumentExtraDto | undefined =
      c.documents[0]?.extra || c.documents[0]?.uiSides?.extra;
    let q: ClientQuestionnaireDto | undefined = c.questionnaire;
    let s: UiSidesDto | undefined = c.documents[0]?.uiSides;

    // Check if this is a new claim default s.general.separationReasonId to undefined.
    if (!!s && !!!s?.general?.fein) {
      s.general.separationReasonId = undefined;
      s.general.employmentPeriod = undefined;
    }

    // Edit resonable assurance TODO: think about this.
    //if (!!s && !!s.general.isReasonableAssurance && !!!s.general.separationReasonId && s.general.)

    // Questionnaire
    if (!!q) {
      // claimantJobTitle
      if (
        (c.claimantJobTitle === undefined || c.claimantJobTitle.length === 0) &&
        q.claimantJobTitle !== undefined
      )
        c.claimantJobTitle = q.claimantJobTitle;

      // dateFirstWorked
      if (
        (c.dateFirstWorked === undefined || c.dateFirstWorked === null) &&
        !!q.hireDateTime
      )
        c.dateFirstWorked = q.hireDateTime;

      // dateLastWorked
      if (
        (c.dateLastWorked === undefined || c.dateLastWorked === null) &&
        !!q.lastDayWorkedDate
      )
        c.dateLastWorked = q.lastDayWorkedDate;

      // separationDate
      if (
        (c.separationDate === undefined || c.separationDate === null) &&
        !!q.separationDate
      )
        c.separationDate = q.separationDate;
    }

    // Extra
    if (!!e) {
      // dateReceived
      if (
        (c.dateReceived === undefined || c.dateReceived === null) &&
        !!e.documentDate
      )
        c.dateReceived = e.documentDate;

      // wagesNeededBeginDate
      if (
        !!!c.documents[0]?.uiSides?.payments?.requestedWageFromDate &&
        !!e.wagesNeededBeginDate &&
        !!c.documents[0]?.uiSides
      )
        c.documents[0].uiSides.payments.requestedWageFromDate =
          e.wagesNeededBeginDate;

      // wagesNeededEndDate
      if (
        !!!c.documents[0]?.uiSides?.payments?.requestedWageToDate &&
        !!e.wagesNeededEndDate &&
        !!c.documents[0]?.uiSides
      )
        c.documents[0].uiSides.payments.requestedWageFromDate =
          e.wagesNeededEndDate; // wagesNeededEndDate

      // fein
      if (
        !!!c.documents[0]?.uiSides?.general?.fein &&
        !!e.fein &&
        !!c.documents[0]?.uiSides
      )
        c.documents[0].uiSides.general.fein = e.fein;
    }

    return c;
  } else {
    return { ...claim } as ClaimDto;
  }
};
/**
 * Checks and sets claimDto for SIDES, returns partial filled ClaimDto for a new SIDES claim.
 * parameter.
 * @param claim: System ClaimDto
 */
export const getOLDCheckedSIDESClaim = (claim: ClaimDto): ClaimDto => {
  if (claim !== undefined && claim.documents[0] !== undefined) {
    let c: ClaimDto = { ...claim } as ClaimDto;

    // claimantFirstName
    if (
      (c.claimantFirstName === undefined || c.claimantFirstName.length === 0) &&
      c.documents[0]!.extra !== undefined &&
      c.documents[0]!.uiSides?.extra?.claimantFirstName !== undefined
    )
      c.claimantFirstName = c.documents[0].uiSides.extra.claimantFirstName;

    // claimantLastName
    if (
      (c.claimantLastName === undefined || c.claimantLastName.length === 0) &&
      c.documents[0]!.extra !== undefined &&
      c.documents[0]!.uiSides?.extra?.claimantLastName !== undefined
    )
      c.claimantLastName = c.documents[0].uiSides.extra.claimantLastName;

    // claimantJobTitle
    if (
      (c.claimantJobTitle === undefined || c.claimantJobTitle.length === 0) &&
      c.documents[0]!.extra !== undefined &&
      c.documents[0]!.uiSides?.extra?.claimantJobTitle !== undefined
    )
      c.claimantJobTitle = c.documents[0].uiSides.extra.claimantJobTitle;

    // claimantSsn
    if (
      (c.claimantSsn === undefined || c.claimantSsn.length === 0) &&
      c.documents[0]!.extra !== undefined &&
      c.documents[0]!.uiSides?.extra?.ssn !== undefined
    )
      c.claimantSsn = c.documents[0].uiSides.extra.ssn;

    // employerId
    if (
      (c.employerId === undefined || c.employerId.length === 0) &&
      c.documents[0]!.extra !== undefined &&
      c.documents[0]!.uiSides?.extra?.employerId !== undefined
    )
      c.employerId = c.documents[0].uiSides.extra.employerId;

    // stateCode
    if (
      (c.stateCode === undefined || c.stateCode.length === 0) &&
      c.documents[0]!.extra !== undefined &&
      c.documents[0]!.uiSides?.extra?.employerStateCode !== undefined
    )
      c.stateCode = c.documents[0].uiSides.extra.employerStateCode;

    // dateFirstWorked
    if (
      (c.dateFirstWorked === undefined || c.dateFirstWorked === null) &&
      c.documents[0]!.extra !== undefined &&
      c.documents[0]!.uiSides?.extra?.dateFirstWorked !== undefined
    )
      c.dateFirstWorked = c.documents[0].uiSides.extra.dateFirstWorked;

    // dateLastWorked
    if (
      (c.dateLastWorked === undefined || c.dateLastWorked === null) &&
      c.documents[0]!.extra !== undefined &&
      c.documents[0]!.uiSides?.extra?.dateLastWorked !== undefined
    )
      c.dateLastWorked = c.documents[0].uiSides.extra.dateLastWorked;

    // benefitYearBeginningDate
    if (
      (c.benefitYearBeginningDate === undefined ||
        c.benefitYearBeginningDate === null) &&
      c.documents[0]!.extra !== undefined &&
      c.documents[0]!.uiSides?.extra?.benefitYearBeginingDate !== undefined
    )
      c.benefitYearBeginningDate =
        c.documents[0].uiSides.extra.benefitYearBeginingDate;

    // benefitYearEndingDate
    if (
      (c.benefitYearEndingDate === undefined ||
        c.benefitYearEndingDate === null) &&
      c.documents[0]!.extra !== undefined &&
      c.documents[0]!.uiSides?.extra?.benefitYearEndingDate !== undefined
    )
      c.benefitYearEndingDate =
        c.documents[0].uiSides.extra.benefitYearEndingDate;

    // dateReceived
    if (
      (c.dateReceived === undefined || c.dateReceived === null) &&
      c.documents[0]!.extra !== undefined &&
      c.documents[0]!.uiSides?.extra?.documentDate !== undefined
    )
      c.dateReceived = c.documents[0].uiSides.extra.documentDate;

    return c;
  } else {
    return { ...claim } as ClaimDto;
  }
};
