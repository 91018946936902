import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CardActions, Grid } from '@material-ui/core';
import { getType } from 'typesafe-actions';
//import { Link as RouterLink } from 'react-router-dom';
import * as Style from './forgotPassword.styles';
import * as Styles from '../../../common/styles/styles';
import { forgotPasswordInit } from './forgotPassword.dtos';
import { forgotPasswordActions } from './forgotPassword.actions';
import { requiredError, tenantId } from '../../../common/constants/strings';
import { useParams } from 'react-router-dom';


const ForgotPassword: React.FC = (props) => {
  const dispatch = useDispatch();
  const { tenant } = useParams<{ tenant: string }>();

  React.useEffect(() => {
    localStorage.setItem(tenantId, tenant);
  }, [tenant]);

  return <Style.ForgotPasswordContainer component="main">
    <Styles.StyledDiv>
      <Styles.StyledCard>
        <Formik
          initialValues={forgotPasswordInit}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={Yup.object({
            email: Yup.string()
              .email('Invalid email address')
              .required(requiredError),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
            }, 700);
            dispatch({ type: getType(forgotPasswordActions.request), payload: values });
          }}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <Styles.StyledForm onSubmit={handleSubmit}>
                <Styles.StyledCardContent>
                  <Style.H3>Reset Password</Style.H3>
                  <Style.SP>Enter your email address and we will send you a link to reset your password.</Style.SP>
                  <Grid container>
                    {/* email */}
                    <Grid item xs={12}>
                      <Styles.StyledTextField
                        id="email"
                        margin="normal"
                        InputLabelProps={{ required: true }}
                        fullWidth
                        value={values.email}
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={(Boolean(errors.email) && touched.email)}
                        helperText={(errors.email && touched.email) && errors.email}
                        data-cy="forgotPassword_email"
                      />
                    </Grid>
                  </Grid>
                </Styles.StyledCardContent>
                <CardActions>
                  <Styles.ButtonGrid container>
                    <Styles.StyledSubmitButton
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                      data-cy="forgotPassword_submit"
                    >SEND RESET LINK
                    </Styles.StyledSubmitButton>

                    <Styles.StyledCancelButton>
                      <Styles.StyledCancelLink to={`/${localStorage.getItem('TenantId') ?? 'mha'}/login`} data-cy="forgotPassword_cancel">CANCEL</Styles.StyledCancelLink>
                    </Styles.StyledCancelButton>
                  </Styles.ButtonGrid>
                </CardActions>
              </Styles.StyledForm>
            );
          }}
        </Formik>
      </Styles.StyledCard>
    </Styles.StyledDiv>
  </Style.ForgotPasswordContainer>

}

export default ForgotPassword;