import React from 'react';
import { FormikHandlers, FormikErrors } from 'formik';

// material ui
import { Grid, TextField } from '@material-ui/core';

import { ClientQuestionnaireDto } from '../../dto';


interface FailureToReportComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setShouldBlockNavigation: (o: boolean) => void;
}

const FailureToReportComponent: React.FC<FailureToReportComponentProps> = (
  {
    values,
    errors,
    handleChange,
    handleBlur,
    setShouldBlockNavigation
  }) => {

  return (
    <React.Fragment>
      <Grid item xs={12}>Please provide the details or the dates the claimant was a "no call/no show": </Grid>
      <Grid item xs={4}>
        <TextField
          id="noCallNoShowDates"
          size="small"
          margin="none"
          value={values.noCallNoShowDates || ''}
          //label="Job Title"
          name="noCallNoShowDates"
          multiline
          onChange={e => { handleChange(e); setShouldBlockNavigation(true); }}
          onBlur={e => { handleBlur(e); setShouldBlockNavigation(true); }}
          error={Boolean(errors.noCallNoShowDates)}
          helperText={errors.noCallNoShowDates && errors.noCallNoShowDates}
          data-cy="clientQuestionnaire_noCallNoShowDates"
          aria-label="What was the claimant dissatisfied about?"
          fullWidth
        />
      </Grid>
      <br />

    </React.Fragment>
  )
}

export default FailureToReportComponent;
