import styled from 'styled-components';

// material ui
import {
    Card as MuiCard, DialogContent,  Typography,  Link, TextField, Button,  Grid, InputLabel, IconButton, FormControlLabel, DialogTitle,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import MailIcon from '@material-ui/icons/Mail';
import Label from '../../common/components/label';
import { Document, Page } from 'react-pdf';


// Styled components ....


export const NoDateRangeText = styled(Typography)`

    margin-left: 40px;
`;

export const IsCompleteBox = styled(FormControlLabel)`

margin-left: 5px;

`;
export const WideDialogContent = styled(DialogContent)`

    width: 500px;
`;

export const SelectionTF = styled(TextField)`

margin-top: 19px;

`;

export const DeleteLink = styled(Link).attrs({ component: Button })`
    
    font-weight: 600;
    color: ${props => props.theme.palette.primary.main};
    background-color: inherit !important;
    padding-left: 0px;
     `;

export const DeleteDialogTitle = styled(DialogTitle)`

margin-left: 4px;

`
export const DeleteConfirmationLabel = styled(InputLabel)`
color: #7C878E;
`;

export const DeleteLabel = styled(InputLabel)`
margin-top: 25px;
margin-bottom: 10px;
color: black;
`;

export const EventInputLabel = styled(InputLabel)`

position: relative;

`;

export const DatePickerGrid = styled(Grid)`

    margin-left: 5px;
`;

export const CreateNewLabel = styled(Typography)`

    margin-top: 15px;
    margin-left: 20px;
`;


export const ClaimDetailsLabel = styled(Label)`

    margin-left: 8px;
    margin-top: 8px;
`;

export const PDFPage = styled(Page)`

    width: 700px !important;
    height: 300px !important;
`;
export const PDFDocument = styled(Document)`

    width: 700px !important;
    height: 300px !important;
`;


export const SmallIconButton = styled(IconButton)`
    float: right;
    padding: 0;
    > span {
      > svg   {
          width: 2.00rem;
          height: 2.00rem;
        }
    }  
    &:hover {
        color: #008A86;
        opacity: 1;
      }
`;

export const ClaimMailLink = styled(Link).attrs({ component: Button })`


bottom: 2px;
right: 12px

`;

export const NotesNavLink = styled(Link).attrs({ component: Button })`

    font-size: 12px;
    font-weight: 600;
    color: ${props => props.theme.palette.primary.main};
    background-color: inherit !important;
    padding-left: 0px;
     `;

export const NotesNavButtonSelected = styled(Button)`

    margin-left: 5px;
    font-size: 12px;
    font-weight: 550;
    color: ${props => props.theme.palette.primary.main};
    background-color: inherit !important;
    padding-left: 0px;

     `;
export const NotesNavButtonUnSelected = styled(Button)`

    color: ${props => props.theme.secondaryColors.customOrange};
    margin-left: 5px;
    font-size: 12px;
    font-weight: 550;
    background-color: inherit !important;
    padding-left: 0px;

     `;
export const HearingContactName = styled(Typography)`

    margin-left: 24px;
`;

export const NotesNavButtonEmpty = styled(Button)`

    //color: #7C878E;
color: #535A5F;
    margin-left: 5px;
    font-size: 12px;
    font-weight: 550;
    background-color: inherit !important;
    padding-left: 0px;

     `;
export const MHAMailicon = styled(MailIcon)`

    color: #00426A;
    position: absolute;
    margin-left: 10px;
`;


export const DisabledHearingTextField = styled(TextField)`

  input {
      color: #7C878E;
  }

`;


export const ClearAccordion = styled(Accordion)`

    box-sizing: 0px;
    border: 0px !important;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    elevation: 0px;
    background-color: white;
    boxShadow: none;
   
    .MuiAccordionSummary-expandIcon {

        position: absolute;
        left: 0;
    }
`;

export const AccordionSummaryBgWhite = styled(AccordionSummary)`
background-color: white !important;
`;

export const TextNumberPaymentField = styled(TextField)`
    margin-right: ${props => props.theme.spacing(2)}px;
    margin-top: 6px;

    input {
      font: inherit;
      color: currentColor;
      width: 100%;
      border: 0 !important;
      height: 1.1875em;
      margin: 0;
      display: block;
      padding: 6px 0 7px;
      min-width: 0;
      background: none !important;
      box-sizing: content-box;
      animation-name: mui-auto-fill-cancel;
      -webkit-tap-highlight-color: transparent;
    }
    input:focus { outline: none; }
`;

export const PaddedAmountTextField = styled(TextField)`

padding-bottom: 15px;

`;

export const DialogInputLabel = styled(InputLabel)`
margin-top: 25px;
margin-bottom: 10px;
`;


export const ImportTable = styled(MuiCard)`  
    width: 100%;
    padding: 8px 2px;
    margin: 10px 0;
`;

export const ResetPdfButton = styled(Button)`
    position: relative;
    margin-bottom: 4px;
`;

export const PaymentCard = styled(MuiCard)`   
    padding: 0 8px;
`;

export const NetPaymentCard = styled(MuiCard)`   
    padding: 0 8px;
    background-color: lightgrey;
`;

export const PaymentTF = styled(TextField)`
    margin-top: 10px;
`;