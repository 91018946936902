import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { getTimeZonesApi } from '../api/timeZones.api';
import { getTimeZonesActions, timeZonesShouldRefresh } from '../actions/timeZones.actions';
import { TimeZonesDto } from '../dtos/timeZones';

function* handleGetTimeZones() {
  const timeZonesList: TimeZonesDto[] = yield select(state => state.timeZones.timeZonesList);
  const timeZonesStorage = sessionStorage.getItem('timeZones');
  const sessionTimeZones = timeZonesStorage === null ? [] : JSON.parse(timeZonesStorage) as TimeZonesDto[];
  const timeZones: TimeZonesDto[] = timeZonesList !== undefined && timeZonesList.length !== 0 ? timeZonesList :
    sessionTimeZones.length !== 0 ? sessionTimeZones :
      yield call(getTimeZonesApi);

  yield put({ type: getType(getTimeZonesActions.success), payload: timeZones });
  sessionStorage.setItem('timeZones', JSON.stringify(timeZones));

   //const timeZones: TimeZonesDto[] = ((timeZoneList !== undefined && timeZoneList.length > 0) && !refreshTimeZones) ? timeZoneList : yield call(getTimeZonesApi);
    //const refreshTimeZones = yield select(state => state.timeZones.refreshTimeZones);

  //yield put({ type: getType(timeZonesShouldRefresh), payload: false });

}

export function* watchtimeZonesGetRequest() {
  try {
    yield takeLatest(getType(getTimeZonesActions.request), handleGetTimeZones);
  }
  catch (err) {
  }
}