import React from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import { CloudUpload } from '@material-ui/icons';
import { ButtonBase, Typography } from '@material-ui/core';
import UploadProgress from './UploadProgress';
import { checkFileType } from '../../utils/checkFileType';

const Emphasize = styled.span`
  color: ${(props) => props.theme.palette.primary.main};
  text-decoration: underline;
`;

const Outline = styled(ButtonBase)`
  border: 2px dashed #cecece;
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
`;

interface Props {
    /**
     * Returns the files dropped in the dropzone.
     */
    attachments: File[];
    onDrop: (files: File[]) => void;
    onDelete: (file: File) => void;
    shouldHaveBox?: boolean;
}

// TODO: Way to remove files before save?
const AttachmentDropzone: React.FC<Props> = (props) => {
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    const onDrop = (files: File[]) => {
        
        props.onDrop(
            files.filter(
                (file) =>
                    //checkFileType(file, 'interaction') &&
                    !props.attachments.some((attachment) => attachment.name === file.name)
            )
        );
    };

    return (
        <Dropzone onDrop={onDrop}>
            {({ getRootProps, getInputProps }) => (
                <>

                    <Outline {...getRootProps()}>
                        {props.shouldHaveBox !== undefined && props.shouldHaveBox === false ?
                            null
                            : props.shouldHaveBox === undefined || props.shouldHaveBox === true ?
                                < CloudUpload color='disabled' fontSize='large' />
                                : null }
                        <input {...getInputProps()} />
                        <Typography variant='body1'>
                            Drag and drop or <Emphasize>browse</Emphasize>
                        </Typography>
                    </Outline>
                    {Boolean(errorMessage) ? (
                        <Typography variant='caption' color='error'>
                            {errorMessage}
                        </Typography>
                    ) : null}
                    {props.attachments.map((attachment, index) => (
                        <UploadProgress
                            key={index}
                            file={attachment}
                            onDelete={props.onDelete}
                        />
                    ))}
                </>
            )}
        </Dropzone>
    );
};

export default AttachmentDropzone;