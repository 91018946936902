import { combineReducers } from 'redux';
import { createReducer, getType } from 'typesafe-actions';

import { requestAssistanceActions } from '../actions/requestAssistance.actions';
import { RequestAssistanceDto, requestAssistanceInit } from '../dtos/requestAssistance';


export const requestAssistanceReducer = combineReducers({
    request: createReducer(requestAssistanceInit)
        .handleAction(
            [
                requestAssistanceActions.request,
            ], (state, action) => action.payload),

    

});

export default requestAssistanceReducer;