import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { HealthSystemDto } from './healthSystems.dtos';
import { createHealthSystemsApi, getHealthSystemsApi, updateHealthSystemsApi, getHealthSystemsByIdApi, getHealthSystemRateApi, createHealthSystemRateApi, updateHealthSystemRateApi } from './healthSystems.api';
import { createHealthSystemsActions, getHealthSystemsActions, updateHealthSystemsActions, getHealthSystemsByIdActions, healthSystemShouldRefresh, healthSystemListShouldRefresh, getHealthSystemRateActions, updateHealthSystemRateActions, createHealthSystemRateActions } from './healthSystems.actions';
import { RateDto, RateWrapperDto } from '../../../common/dtos/billing';


function* handleHealthSystemsUpdate(request: ReturnType<typeof updateHealthSystemsActions.request>) {
  const updatedHealthSystems: HealthSystemDto = yield call(updateHealthSystemsApi, request.payload);
  yield put({ type: getType(getHealthSystemsByIdActions.success), payload: updatedHealthSystems });
  yield put({ type: getType(healthSystemListShouldRefresh), payload: true });
}

function* handleHealthSystemsCreate(request: ReturnType<typeof createHealthSystemsActions.request>) {
  const createdHealthSystem: HealthSystemDto = yield call(createHealthSystemsApi, request.payload);
  yield put({ type: getType(getHealthSystemsByIdActions.success), payload: createdHealthSystem });
  yield put({ type: getType(healthSystemListShouldRefresh), payload: true });

}

 function* handleHealthSystemsGet() {
   const refreshHealthSystem: boolean = yield select(state => state.healthSystems.refreshHealthSystem);
   const healthSystems: HealthSystemDto[] = yield select(state => state.healthSystems.healthSystems);
   const healthSystem: HealthSystemDto[] = ((healthSystems !== undefined && healthSystems.length > 0) && !refreshHealthSystem) ? healthSystems : yield call(getHealthSystemsApi);

   yield put({ type: getType(getHealthSystemsActions.success), payload: healthSystem });
   yield put({ type: getType(healthSystemListShouldRefresh), payload: false });
 }

 function* handleHealthSystemsByIdGet(request: ReturnType<typeof getHealthSystemsByIdActions.request>) {
   const refreshHealthSystemId: string = yield select(state => state.healthSystems.refreshHealthSystem);
   const storeHealthSystem: HealthSystemDto = yield select(state => state.healthSystems.healthSystem);
   const healthSystem: HealthSystemDto = (storeHealthSystem !== undefined && storeHealthSystem.healthSystemId === refreshHealthSystemId) ? storeHealthSystem : yield call(getHealthSystemsByIdApi, request.payload);

   yield put({ type: getType(getHealthSystemsByIdActions.success), payload: healthSystem });
   yield put({ type: getType(healthSystemShouldRefresh), payload: healthSystem.healthSystemId });
 }


function* handleGetHealthSystemRate(request: ReturnType<typeof getHealthSystemRateActions.request>) {

    const storeRate: RateWrapperDto = yield select(state => state.healthSystems.rate);
    const rate: RateDto = (storeRate !== undefined && storeRate !== undefined && storeRate.id === request.payload.id) ? storeRate.rate : yield call(getHealthSystemRateApi, request.payload);
    const rateWrapper: RateWrapperDto = { id: request.payload.id, rate: rate }


    yield put({ type: getType(getHealthSystemRateActions.success), payload: rateWrapper });
}


function* handleUpdateHealthSystemRate(request: ReturnType<typeof updateHealthSystemRateActions.request>) {

    const updatedRate: RateDto = yield call(updateHealthSystemRateApi, request.payload);
    const rateWrapper: RateWrapperDto = { id: request.payload.id, rate: updatedRate }

    yield put({ type: getType(getHealthSystemRateActions.success), payload: rateWrapper });
}

function* handleCreateHealthSystemRate(request: ReturnType<typeof createHealthSystemRateActions.request>) {

    const createdRate: RateDto = yield call(createHealthSystemRateApi, request.payload);
    const rateWrapper: RateWrapperDto = { id: request.payload.id, rate: createdRate }

    yield put({ type: getType(getHealthSystemRateActions.success), payload: rateWrapper });
}



 function* watchHealthSystemsGetRequest() {
  try { yield takeLatest(getType(getHealthSystemsActions.request), handleHealthSystemsGet); }
  catch (err) { }
}

 function* watchHealthSystemsUpdateRequest() {
  try { yield takeLatest(getType(updateHealthSystemsActions.request), handleHealthSystemsUpdate); }
  catch (err) { }
}

 function* watchHealthSystemsCreateRequest() {
  try { yield takeLatest(getType(createHealthSystemsActions.request), handleHealthSystemsCreate); }
  catch (err) { }
}

 function* watchHealthSystemsByIdGetRequest() {
  try { yield takeLatest(getType(getHealthSystemsByIdActions.request), handleHealthSystemsByIdGet); }
  catch (err) { }
}

 function* watchHealthSystemRateGetRequest() {
    try { yield takeLatest(getType(getHealthSystemRateActions.request), handleGetHealthSystemRate); }
    catch (err) { }
}

 function* watchHealthSystemRateUpdateRequest() {
    try { yield takeLatest(getType(updateHealthSystemRateActions.request), handleUpdateHealthSystemRate); }
    catch (err) { }
}

 function* watchHealthSystemRateCreateRequest() {
    try { yield takeLatest(getType(createHealthSystemRateActions.request), handleCreateHealthSystemRate); }
    catch (err) { }
}



export default [
  watchHealthSystemsGetRequest,
  watchHealthSystemsUpdateRequest,
  watchHealthSystemsCreateRequest,
  watchHealthSystemsByIdGetRequest,
  watchHealthSystemRateGetRequest,
  watchHealthSystemRateUpdateRequest,
  watchHealthSystemRateCreateRequest,
];