import React from 'react';
import { FormikHandlers, FormikErrors, FormikHelpers, FieldArray, getIn } from 'formik';
// material ui
import { Grid, CardContent, FormControl, FormHelperText, Typography } from '@material-ui/core';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import MoneyField from '../../../../common/components/numberFields';
import { DateToGrid, NavCard, NavOptionsGrid } from '../../documents.styles';
import { Icon, SidesPagination } from '../../../../common/styles/styles';
import NumberField from '../../../../common/components/numberField';
import { ISi, ISi_PropertyAttributes } from '../../../../common/dtos/SiDto';
import SiTextField from '../siFieldComponents/SiTextField';
import IndicatorTypeWithUnknownField from '../siFieldComponents/IndicatorTypeWithUnknownField';
import SiSelectOptions from '../siFieldComponents/SiSelectOptions';
import { RatePayPerCodes_class } from '../../../../common/enums/RatePayPerCodes';
import { RemunerationTypeCodes_class } from '../../../../common/enums/RemunerationTypeCodes';
import { IUisSiRemunerationOccurrence, IUisSiRemunerationOccurrence_PropertyAttributes } from '../../../../common/dtos/UisSiRemunerationOccurrenceDto';
import { FrequencyCodes_class } from '../../../../common/enums/FrequencyCodes';
import SiDateField from '../siFieldComponents/SiDateField';
import IndicatorTypeYField from '../siFieldComponents/IndicatorTypeYField';
import SiTextAreaField from '../siFieldComponents/SiTextAreaField';
import { WagesWeeksNeededCodes_class } from '../../../../common/enums/WagesWeeksNeededCodes';
import TotalEarnedWagesNeededIndField from '../siFieldComponents/TotalEarnedWagesNeededIndField';
import TotalWeeksWorkedNeededIndField from '../siFieldComponents/TotalWeeksWorkedNeededIndField';
import {
  isCustomaryWeeklyWorkSchedule, isRateofPayPerPeriod, isRegularRateofPay, isReceivePension401Kind, isDateOfInjury, isDateAppliedForWorkersCompKnownInd,
  isDateAppliedForWorkersComp, isTypeOfWorkersCompReceiving, isClaimantReleasedToReturnToWork, isDateClaimantReleased, isWorkRestrictionsInd, isClaimantWorkRestrictions,
  isMandatoryPension, isPension401KfrequencyCode, isPension401KamountPerPeriod, isPension401KamountPerPeriodUnknown, isDatePension401Kissued, isDatePension401KissuedUnknown,
  isWhoContributedPension401K, isClaimantPensionContributionPercent, isClaimantPension401KcontributionPercentUnknown, isEmployerPension401KcontributionPercent,
  isEmployerPension401KcontributionPercentUnknown, isEmployerLastContributionDate, isEmployerLastContributionDateUnknown, 
  isFullAmount401KrolledOver, isPercentage401KrolledOver, isPercentage401KrolledOverUnknown, isPaidOut401KtoClaimant,
  opReceivingWorkersCompensationInd, opWeeklyWorkersCompRate, opEmployerAccommodateRestrictionsInd, opWhyEmployerCouldNotAccommodate,
  opWorkersCompComments, opPeriodOfTimeWorkersCompPaymentCovers, opPension401Kcomments,
} from '../../../../utils';
import IndicatorTypeField from '../siFieldComponents/IndicatorTypeField';
import Pension401kReceivedField from '../siFieldComponents/Pension401kReceivedField';
import { WorkersCompCodes_class } from '../../../../common/enums/WorkersCompCodes';
import { IUisSiPension401Koccurrence, IUisSiPension401Koccurrence_PropertyAttributes } from '../../../../common/dtos/UisSiPension401KoccurrenceDto';
import Pension401kCodeField from '../siFieldComponents/Pension401kCodeField';
import ContributionCodeField from '../siFieldComponents/ContributionCodeField';
import Label from '../../../../common/components/label';
import Pension401KFrequencyCodeField from '../siFieldComponents/Pension401KFrequencyCodeField';


interface Props extends React.HTMLProps<React.ReactNode> {
  values: ISi,
  errors: FormikErrors<ISi>,
  handleChange: FormikHandlers["handleChange"],
  handleBlur: FormikHandlers["handleBlur"],
  setFieldValue: FormikHelpers<ISi>["setFieldValue"],
  isReadOnly: boolean,
}


const SiPayments: React.FC<Props> = (
  {
    values,
    errors,
    handleChange,
    isReadOnly,
    handleBlur,
    setFieldValue,
  }) => {
  const [payTypePageNumber, setPayTypePageNumber] = React.useState(0);
  const [p401TypePageNumber, setP401TypePageNumber] = React.useState(0);
  //React.useEffect(() => { console.log('errors', errors); }, [errors]);
  //React.useEffect(() => { console.log('values', values); }, [values]);

  React.useEffect(() => {
    if (!!values.uisSiRemunerationOccurrences && values.uisSiRemunerationOccurrences.length !== 0)
      setPayTypePageNumber(1);
    if (!!values.uisSiPension401Koccurrences && values.uisSiPension401Koccurrences.length !== 0)
      setP401TypePageNumber(1);

  }, []);

  React.useEffect(() => {
    if (!isRegularRateofPay(values) && values.regularRateofPay !== undefined) setFieldValue('regularRateofPay', undefined);
    if (!isRateofPayPerPeriod(values) && values.rateofPayPerPeriod !== undefined) setFieldValue('rateofPayPerPeriod', undefined);
    if (!isCustomaryWeeklyWorkSchedule(values) && values.customaryWeeklyWorkSchedule !== undefined) setFieldValue('customaryWeeklyWorkSchedule', undefined);
    if (!opReceivingWorkersCompensationInd(values) && values.receivingWorkersCompensationInd !== undefined) setFieldValue('receivingWorkersCompensationInd', undefined);
    if (!isDateOfInjury(values) && values.dateOfInjury !== undefined) setFieldValue('dateOfInjury', undefined);
    if (!isDateAppliedForWorkersCompKnownInd(values) && values.dateAppliedForWorkersCompKnownInd !== undefined) setFieldValue('dateAppliedForWorkersCompKnownInd', undefined);
    if (!isDateAppliedForWorkersComp(values) && values.dateAppliedForWorkersComp !== undefined) setFieldValue('dateAppliedForWorkersComp', undefined);
    if (!isTypeOfWorkersCompReceiving(values) && values.typeOfWorkersCompReceiving !== undefined) setFieldValue('typeOfWorkersCompReceiving', undefined);
    if (!opWeeklyWorkersCompRate(values) && values.weeklyWorkersCompRate !== undefined) setFieldValue('weeklyWorkersCompRate', undefined);
    if (!opPeriodOfTimeWorkersCompPaymentCovers(values) && values.periodOfTimeWorkersCompPaymentCovers !== undefined) setFieldValue('periodOfTimeWorkersCompPaymentCovers', undefined);
    if (!isClaimantReleasedToReturnToWork(values) && values.claimantReleasedToReturnToWork !== undefined) setFieldValue('claimantReleasedToReturnToWork', undefined);
    if (!isDateClaimantReleased(values) && values.dateClaimantReleased !== undefined) setFieldValue('dateClaimantReleased', undefined);
    if (!isWorkRestrictionsInd(values) && values.workRestrictionsInd !== undefined) setFieldValue('workRestrictionsInd', undefined);
    if (!isClaimantWorkRestrictions(values) && values.claimantWorkRestrictions !== undefined) setFieldValue('claimantWorkRestrictions', undefined);
    if (!opEmployerAccommodateRestrictionsInd(values) && values.employerAccommodateRestrictionsInd !== undefined) setFieldValue('employerAccommodateRestrictionsInd', undefined);
    if (!opWhyEmployerCouldNotAccommodate(values) && values.whyEmployerCouldNotAccommodate !== undefined) setFieldValue('whyEmployerCouldNotAccommodate', undefined);
    if (!opWorkersCompComments(values) && values.workersCompComments !== undefined) setFieldValue('workersCompComments', undefined);
    if (!isReceivePension401Kind(values) && values.receivePension401Kind !== undefined) setFieldValue('receivePension401Kind', undefined);
    if (!isMandatoryPension(values) && values.mandatoryPension !== undefined) setFieldValue('mandatoryPension', undefined);

    if (!isPension401KfrequencyCode(values, p401TypePageNumber - 1) && values.uisSiPension401Koccurrences && values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.pension401KamountPerPeriod !== undefined) setFieldValue(`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].pension401KfrequencyCode`, undefined);
    if (!isPension401KamountPerPeriod(values, p401TypePageNumber - 1) && values.uisSiPension401Koccurrences && values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.pension401KamountPerPeriod !== undefined) setFieldValue(`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].pension401KamountPerPeriod`, undefined);
    if (!isPension401KamountPerPeriodUnknown(values, p401TypePageNumber - 1) && values.uisSiPension401Koccurrences && values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.pension401KamountPerPeriodUnknown !== undefined) setFieldValue(`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].pension401KamountPerPeriodUnknown`, undefined);
    if (!isDatePension401Kissued(values, p401TypePageNumber - 1) && values.uisSiPension401Koccurrences && values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.datePension401Kissued !== undefined) setFieldValue(`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].datePension401Kissued`, undefined);
    if (!isDatePension401KissuedUnknown(values, p401TypePageNumber - 1) && values.uisSiPension401Koccurrences && values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.datePension401KissuedUnknown !== undefined) setFieldValue(`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].datePension401KissuedUnknown`, undefined);
    if (!isWhoContributedPension401K(values, p401TypePageNumber - 1) && values.uisSiPension401Koccurrences && values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.whoContributedPension401K !== undefined) setFieldValue(`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].whoContributedPension401K`, undefined);
    if (!isClaimantPensionContributionPercent(values, p401TypePageNumber - 1) && values.uisSiPension401Koccurrences && values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.claimantPensionContributionPercent !== undefined) setFieldValue(`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].claimantPensionContributionPercent`, undefined);
    if (!isClaimantPension401KcontributionPercentUnknown(values, p401TypePageNumber - 1) && values.uisSiPension401Koccurrences && values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.claimantPension401KcontributionPercentUnknown !== undefined) setFieldValue(`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].claimantPension401KcontributionPercentUnknown`, undefined);
    if (!isEmployerPension401KcontributionPercent(values, p401TypePageNumber - 1) && values.uisSiPension401Koccurrences && values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.employerPension401KcontributionPercent !== undefined) setFieldValue(`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].employerPension401KcontributionPercent`, undefined);
    if (!isEmployerPension401KcontributionPercentUnknown(values, p401TypePageNumber - 1) && values.uisSiPension401Koccurrences && values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.employerPension401KcontributionPercentUnknown !== undefined) setFieldValue(`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].employerPension401KcontributionPercentUnknown`, undefined);
    if (!isEmployerLastContributionDate(values, p401TypePageNumber - 1) && values.uisSiPension401Koccurrences && values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.employerLastContributionDate !== undefined) setFieldValue(`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].employerLastContributionDate`, undefined);
    if (!isEmployerLastContributionDateUnknown(values, p401TypePageNumber - 1) && values.uisSiPension401Koccurrences && values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.employerLastContributionDateUnknown !== undefined) setFieldValue(`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].employerLastContributionDateUnknown`, undefined);
    if (!opPension401Kcomments(values, p401TypePageNumber - 1) && values.uisSiPension401Koccurrences && values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.pension401Kcomments !== undefined) setFieldValue(`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].pension401Kcomments`, undefined);

    if (!isFullAmount401KrolledOver(values) && values.fullAmount401KrolledOver !== undefined) setFieldValue('fullAmount401KrolledOver', undefined);
    if (!isPercentage401KrolledOver(values) && values.percentage401KrolledOver !== undefined) setFieldValue('percentage401KrolledOver', undefined);
    if (!isPercentage401KrolledOverUnknown(values) && values.percentage401KrolledOverUnknown !== undefined) setFieldValue('percentage401KrolledOverUnknown', undefined);
    if (!isPaidOut401KtoClaimant(values) && values.paidOut401KtoClaimant !== undefined) setFieldValue('paidOut401KtoClaimant', undefined);
  }, [values]);

  const handleGetErrors = (list: any[], listName: string) => {
    let errorIndexList = [];
    for (let i = 0; i < (list.length ?? 0); i++) {
      if (Boolean(getIn(errors, `${listName}[${i}]`)))
        errorIndexList.push(i);
    }

    return errorIndexList;
  };


  return (
    <>
      <Grid container spacing={2} data-cy="sidesResponse_payments_container">
        <NavOptionsGrid item xs={6}>
          <br/>
          <Grid item xs={12}>
            <Typography
              key={'Allocation-message'}
              variant="body2"
              color="error"
            >
              {values.allocationRequiredInd === 0 ?
                'This State allows the employer to choose the allocations dates of the payment.' :
                'This State will choose allocation dates on your behalf.'}
            </Typography>
          </Grid>

          <NavCard>
            <CardContent>
              <Grid container spacing={1}>
                
                <IndicatorTypeWithUnknownField
                  label={"Did or will the claimant receive any compensation on or after the last day of work (excluding wages for hours worked)?"}
                  name={`remunerationInd`}
                  value={values.remunerationInd}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                />


                {/*Conditional Questions*/}
                {isRegularRateofPay(values) &&
                  <SiTextField
                    label={"Regular Rate of Pay"}
                    name={`regularRateofPay`}
                    value={values.regularRateofPay?.toString()}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputprops={{ inputComponent: MoneyField as any }}
                    max={17}
                    size={6}
                  /> 
                }
                {isRateofPayPerPeriod(values) &&
                  <SiSelectOptions
                    label={"Rate of pay per period"}
                    name={`rateofPayPerPeriod`}
                    value={values.rateofPayPerPeriod}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    options={RatePayPerCodes_class.enumAsSelectOptions}
                    size={6}
                  /> 
                }
                {isCustomaryWeeklyWorkSchedule(values) &&
                  <SiTextField
                    label={"Describe the claimant’s customary weekly work schedule"}
                    name={`customaryWeeklyWorkSchedule`}
                    value={values.customaryWeeklyWorkSchedule}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.customaryWeeklyWorkSchedule_Attributes.stringLength.maxLength}
                  /> 
                }
                

                <SiTextField
                  label={"Wages After Claim Date"}
                  name={`wagesEarnedAfterClaimEffectiveDate`}
                  value={values.wagesEarnedAfterClaimEffectiveDate?.toString()}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  inputprops={{ inputComponent: MoneyField as any }}
                  max={17}
                  size={6}
                />
                <SiTextField
                  label={"Hours Worked After Claim Date"}
                  name={`numberOfHoursWorkedAfterClaimEffectiveDate`}
                  value={values.numberOfHoursWorkedAfterClaimEffectiveDate?.toString()}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  inputprops={{ inputComponent: NumberField as any }}
                  max={5}
                  size={6}
                />
                
                <SiTextField
                  label={"Average Weekly Hours"}
                  name={`averageNumberofHoursWorkedperWeek`}
                  value={values.averageNumberofHoursWorkedperWeek?.toString()}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  inputprops={{ inputComponent: NumberField as any }}
                  max={2}
                  size={6}
                />
                
              </Grid>
            </CardContent>
          </NavCard>

          <NavCard>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} hidden={
                  !Boolean(getIn(errors, `uisSiRemunerationOccurrences`))
                  || typeof getIn(errors, `uisSiRemunerationOccurrences`) !== 'string'
                }>
                  <FormControl error={Boolean(getIn(errors, `uisSiRemunerationOccurrences`))} >
                    {Boolean(getIn(errors, `uisSiRemunerationOccurrences`)) &&
                      <FormHelperText id="payList-helper_text">
                        {typeof getIn(errors, `uisSiRemunerationOccurrences`) === 'string' && getIn(errors, `uisSiRemunerationOccurrences`)}
                      </FormHelperText>
                    }
                  </FormControl>
                </Grid>

                {values.uisSiRemunerationOccurrences && values.uisSiRemunerationOccurrences.length !== 0 ?
                  <SiSelectOptions
                    label={"Pay Type"}
                    name={`uisSiRemunerationOccurrences[${payTypePageNumber - 1}].remunerationTypeCode`}
                    value={values.uisSiRemunerationOccurrences[payTypePageNumber - 1]?.remunerationTypeCode}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    options={RemunerationTypeCodes_class.enumAsSelectOptions.filter(f => (f.text ?? '').length !== 0)}
                    size={10}
                  /> : <Grid item xs={10}><Label>Remunerations</Label></Grid>
                }
                
                  
                <FieldArray name={`uisSiRemunerationOccurrences`} render={arrayHelpers => (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Grid container alignContent="flex-end">
                      <Grid item xs={6}>
                        {values.uisSiRemunerationOccurrences && values.uisSiRemunerationOccurrences?.length !== 0 ?
                          <Icon
                            title="Remove Payment"
                            color="secondary"
                            onClick={() => {
                              setTimeout(() => {
                                arrayHelpers.remove(payTypePageNumber - 1);
                                setPayTypePageNumber(!!values.uisSiRemunerationOccurrences ? values.uisSiRemunerationOccurrences.length - 1 : 0);
                              }, 200);
                            }}
                            data-cy="changeMe"
                            redcolor="true"
                          >
                            <IndeterminateCheckBoxIcon />
                          </Icon> :
                          null
                        }
                      </Grid>
                      <Grid item xs={6}>
                        <Icon title="Add Payment" color="secondary"
                          onClick={() => {
                            arrayHelpers.push({} as IUisSiRemunerationOccurrence);
                            setPayTypePageNumber(!!values.uisSiRemunerationOccurrences ? values.uisSiRemunerationOccurrences.length + 1 : 0);
                          }} data-cy="changeMe">
                          <AddBoxRoundedIcon />
                        </Icon>
                      </Grid>
                    </Grid>
                  </Grid>
                )} />

                {Boolean(getIn(errors, 'uisSiRemunerationOccurrences')) &&
                  <Grid item xs={12}><Typography component="span" color="error">
                    {typeof getIn(errors, 'uisSiRemunerationOccurrences') === 'string' ? getIn(errors, 'uisSiRemunerationOccurrences') : 'Please check for error(s).'}
                  </Typography></Grid>}
                
                {(values.uisSiRemunerationOccurrences && values.uisSiRemunerationOccurrences.length !== 0) &&
                  <>
                  <SiTextField
                    label={"Gross amount of payment"}
                    name={`uisSiRemunerationOccurrences[${payTypePageNumber - 1}].remunerationAmountPerPeriod`}
                    value={values.uisSiRemunerationOccurrences[payTypePageNumber - 1]?.remunerationAmountPerPeriod?.toString() || ''}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputprops={{ inputComponent: MoneyField as any }}
                    max={17}
                    size={6}
                  />

                  <SiSelectOptions
                    label={"Frequency of pay"}
                    name={`uisSiRemunerationOccurrences[${payTypePageNumber - 1}].remunerationPeriodFrequencyCode`}
                    value={values.uisSiRemunerationOccurrences[payTypePageNumber - 1]?.remunerationPeriodFrequencyCode}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    options={FrequencyCodes_class.enumAsSelectOptions}
                    size={6}
                  />

                  <SiTextField
                    label={"Number of hours represented"}
                    name={`uisSiRemunerationOccurrences[${payTypePageNumber - 1}].numberHoursRepresented`}
                    value={values.uisSiRemunerationOccurrences[payTypePageNumber - 1]?.numberHoursRepresented?.toString()}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputprops={{ inputComponent: NumberField as any }}
                    max={3}
                    size={6}
                  />

                  <SiDateField
                    label={"Date of first payment"}
                    name={`uisSiRemunerationOccurrences[${payTypePageNumber - 1}].dateRemunerationIssued`}
                    value={values.uisSiRemunerationOccurrences[payTypePageNumber - 1]?.dateRemunerationIssued || null}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputlabelprops={{ shrink: true }}
                    size={6}
                  />

                  <IndicatorTypeWithUnknownField
                    label={"Was the pay allocated to a specific time period?"}
                    name={`uisSiRemunerationOccurrences[${payTypePageNumber - 1}].employerAllocationInd`}
                    value={values.uisSiRemunerationOccurrences[payTypePageNumber - 1]?.employerAllocationInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />

                  <SiDateField
                    label={"Period Allocated"}
                    name={`uisSiRemunerationOccurrences[${payTypePageNumber - 1}].allocationBeginDate`}
                    value={values.uisSiRemunerationOccurrences ? values.uisSiRemunerationOccurrences[payTypePageNumber - 1]?.allocationBeginDate || null : null}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputlabelprops={{ shrink: true }}
                    size={5}
                  />
                  <DateToGrid item xs={12} sm={12} md={1} lg={1} container alignContent="center" justifyContent="center">to</DateToGrid>
                  <SiDateField
                    label={" "}
                    name={`uisSiRemunerationOccurrences[${payTypePageNumber - 1}].allocationEndDate`}
                    value={values.uisSiRemunerationOccurrences ? values.uisSiRemunerationOccurrences[payTypePageNumber - 1]?.allocationEndDate || null : null}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputlabelprops={{ shrink: true }}
                    size={5}
                  />

                  <IndicatorTypeYField
                    label={"Unknown"}
                    name={`uisSiRemunerationOccurrences[${payTypePageNumber - 1}].allocationBeginDateUnknownInd`}
                    value={values.uisSiRemunerationOccurrences[payTypePageNumber - 1]?.allocationBeginDateUnknownInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={5}
                  />

                  <IndicatorTypeYField
                    label={"Unknown"}
                    name={`uisSiRemunerationOccurrences[${payTypePageNumber - 1}].allocationEndDateUnknownInd`}
                    value={values.uisSiRemunerationOccurrences[payTypePageNumber - 1]?.allocationEndDateUnknownInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={5}
                  />

                  <SiTextAreaField
                    label={"Additional information about the payments.  "}
                    name={`uisSiRemunerationOccurrences[${payTypePageNumber - 1}].remunerationComments`}
                    value={values.uisSiRemunerationOccurrences[payTypePageNumber - 1]?.remunerationComments}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={IUisSiRemunerationOccurrence_PropertyAttributes.remunerationComments_Attributes.stringLength.maxLength}
                  />

                  <SidesPagination
                    hidden={values.uisSiRemunerationOccurrences && values.uisSiRemunerationOccurrences?.length === 0}
                    count={values.uisSiRemunerationOccurrences?.length}
                    variant="outlined"
                    color="primary"
                    size="small"
                    page={payTypePageNumber}
                    errorindex={handleGetErrors(values.uisSiRemunerationOccurrences, 'uisSiRemunerationOccurrences')}
                    onChange={(e, p) => { setPayTypePageNumber(p); }} />

                  </>
                }
              </Grid>
            </CardContent>
          </NavCard>
        </NavOptionsGrid>

        <NavOptionsGrid item xs={6}>
          <NavCard>
            <CardContent>
              <Grid container spacing={2}>
                <SiDateField
                  label={"Wage Dates Requested"}
                  name={`wagesNeededBeginDate`}
                  value={values.wagesNeededBeginDate}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly || values.wagesWeeksNeededCode !== WagesWeeksNeededCodes_class.enum.NA}
                  inputlabelprops={{ shrink: true }}
                  size={4}
                />
                <DateToGrid item xs={12} sm={12} md={2} lg={2}>to</DateToGrid>
                <SiDateField
                  label={" "}
                  name={`wagesNeededEndDate`}
                  value={values.wagesNeededEndDate}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly || values.wagesWeeksNeededCode !== WagesWeeksNeededCodes_class.enum.NA}
                  inputlabelprops={{ shrink: true }}
                  size={4}
                />

                <TotalEarnedWagesNeededIndField
                  label={"Are total earned wages available?"}
                  name={`totalEarnedWagesNeededInd`}
                  value={values.totalEarnedWagesNeededInd}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                />

                <SiTextField
                  label={"Total Earned Wages"}
                  name={`totalEarnedWages`}
                  value={values.totalEarnedWages?.toString()}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  inputprops={{ inputComponent: MoneyField as any }}
                  max={17}
                />

                <TotalWeeksWorkedNeededIndField
                  label={"Are total weeks worked available?"}
                  name={`totalWeeksWorkedNeededInd`}
                  value={values.totalWeeksWorkedNeededInd}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                />

                <SiTextField
                  label={"Total Weeks Worked"}
                  name={`totalWeeksWorked`}
                  value={values.totalWeeksWorked?.toString()}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  inputprops={{ inputComponent: NumberField as any }}
                  max={2}
                />

              </Grid>
            </CardContent>
          </NavCard>

          <NavCard>
            <CardContent>
              <Grid container spacing={2}>
                {/*Conditional Questions*/}
                {opReceivingWorkersCompensationInd(values) &&
                  <IndicatorTypeField
                    label={"Is (or was) the claimant receiving workers’ compensation?"}
                    name={`receivingWorkersCompensationInd`}
                    value={values.receivingWorkersCompensationInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }

                {isDateOfInjury(values) &&
                  <SiDateField
                    label={"What was the date of the injury?"}
                    name={`dateOfInjury`}
                    value={values.dateOfInjury}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputlabelprops={{ shrink: true }}
                    size={6}
                  />
                }
                {isDateAppliedForWorkersCompKnownInd(values) &&
                  <IndicatorTypeWithUnknownField
                    label={"Do you know what date the claimant applied for workers’ compensation?"}
                    name={`dateAppliedForWorkersCompKnownInd`}
                    value={values.dateAppliedForWorkersCompKnownInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }
                {isDateAppliedForWorkersComp(values) &&
                  <SiDateField
                    label={"Application Date?"}
                    name={`dateAppliedForWorkersComp`}
                    value={values.dateAppliedForWorkersComp}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputlabelprops={{ shrink: true }}
                    size={6}
                  /> 
                }
                {isTypeOfWorkersCompReceiving(values) &&
                  <SiSelectOptions
                    label={"What type of workers’ compensation is or was the claimant receiving?"}
                    name={`typeOfWorkersCompReceiving`}
                    value={values.typeOfWorkersCompReceiving}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    options={WorkersCompCodes_class.enumAsSelectOptions}
                  /> 
                }
                {opWeeklyWorkersCompRate(values) &&
                  <SiTextField
                    label={"What is the claimant’s weekly workers’ compensation rate?"}
                    name={`weeklyWorkersCompRate`}
                    value={values.weeklyWorkersCompRate?.toString()}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputprops={{ inputComponent: MoneyField as any }}
                    max={17}
                  /> 
                }
                {opPeriodOfTimeWorkersCompPaymentCovers(values) &&
                  <SiTextAreaField
                    label={"What period of time did/does the payment(s) cover?"}
                    name={`periodOfTimeWorkersCompPaymentCovers`}
                    value={values.periodOfTimeWorkersCompPaymentCovers}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.periodOfTimeWorkersCompPaymentCovers_Attributes.stringLength.maxLength}
                  /> 
                }
                {isClaimantReleasedToReturnToWork(values) &&
                  <IndicatorTypeWithUnknownField
                    label={"Has the claimant been released to return to work?"}
                    name={`claimantReleasedToReturnToWork`}
                    value={values.claimantReleasedToReturnToWork}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }
                {isDateClaimantReleased(values) &&
                  <SiDateField
                    label={"On what date was the claimant released?"}
                    name={`dateClaimantReleased`}
                    value={values.dateClaimantReleased}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    inputlabelprops={{ shrink: true }}
                    size={6}
                  />
                }
                {isWorkRestrictionsInd(values) && values.receivingWorkersCompensationInd === 0  &&
                  <IndicatorTypeWithUnknownField
                    label={"Did, or does, the claimant have work restrictions?"}
                    name={`workRestrictionsInd`}
                    value={values.workRestrictionsInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }
                {isClaimantWorkRestrictions(values) && values.receivingWorkersCompensationInd === 0 &&
                  <SiTextAreaField
                    label={"What are the claimant’s work restrictions?"}
                    name={`claimantWorkRestrictions`}
                    value={values.claimantWorkRestrictions}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.claimantWorkRestrictions_Attributes.stringLength.maxLength}
                  /> 
                }
                {opEmployerAccommodateRestrictionsInd(values) && values.receivingWorkersCompensationInd === 0 &&
                  <IndicatorTypeField
                    label={"Could you accommodate those restrictions?"}
                    name={`employerAccommodateRestrictionsInd`}
                    value={values.employerAccommodateRestrictionsInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                }
                {opWhyEmployerCouldNotAccommodate(values) && values.receivingWorkersCompensationInd === 0 &&
                  <SiTextAreaField
                    label={"Why couldn’t you accommodate those restrictions?"}
                    name={`whyEmployerCouldNotAccommodate`}
                    value={values.whyEmployerCouldNotAccommodate}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.whyEmployerCouldNotAccommodate_Attributes.stringLength.maxLength}
                  /> 
                }
                {opWorkersCompComments(values) &&
                  <SiTextAreaField
                    label={"Enter any additional information regarding the claimant’s receipt of workers’ compensation."}
                    name={`workersCompComments`}
                    value={values.workersCompComments}
                  errors={errors}
                  setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={ISi_PropertyAttributes.workersCompComments_Attributes.stringLength.maxLength}
                  /> 
                }
              </Grid>
            </CardContent>
          </NavCard>

          <NavCard>
            <CardContent>
              <Grid container spacing={2}>
                {/*Conditional Questions*/}
                {isReceivePension401Kind(values) &&
                    <Pension401kReceivedField
                      label={"Is or will the claimant receive a company pension and/or 401K disbursement?"}
                      name={`receivePension401Kind`}
                      value={values.receivePension401Kind}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                  />
                }
                {isMandatoryPension(values) &&
                  <IndicatorTypeField
                    label={"Was starting the company pension mandatory?"}
                    name={`mandatoryPension`}
                    value={values.mandatoryPension}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  /> 
                }

                <Grid item xs={12} sm={12} md={10} lg={10}><Label>Pension / 401K</Label></Grid>
                <FieldArray name={`uisSiPension401Koccurrences`} render={arrayHelpers => (
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Grid container alignItems="flex-end">
                      <Grid item xs={6}>
                        {values.uisSiPension401Koccurrences && values.uisSiPension401Koccurrences?.length !== 0 ?
                          <Icon
                            title="Remove Pension/401K"
                            color="secondary"
                            onClick={() => {
                              setTimeout(() => {
                                arrayHelpers.remove(p401TypePageNumber - 1);
                                setP401TypePageNumber(!!values.uisSiPension401Koccurrences ? values.uisSiPension401Koccurrences.length - 1 : 0);
                              }, 200);
                            }}
                            data-cy="changeMe"
                            redcolor="true"
                          >
                            <IndeterminateCheckBoxIcon />
                          </Icon> :
                          null
                        }
                      </Grid>
                      <Grid item xs={6}>
                        <Icon title="Add Pension/401K"
                          color="secondary"
                          onClick={() => {
                            arrayHelpers.push({} as IUisSiPension401Koccurrence);
                            setP401TypePageNumber(!!values.uisSiPension401Koccurrences ? values.uisSiPension401Koccurrences.length + 1 : 0);
                          }} data-cy="changeMe">
                          <AddBoxRoundedIcon />
                        </Icon>
                      </Grid>
                    </Grid>
                  </Grid>
                )} />

                {Boolean(getIn(errors, 'uisSiPension401Koccurrences')) &&
                  <Grid item xs={12}><Typography component="span" color="error">{
                    typeof getIn(errors, 'uisSiPension401Koccurrences') === 'string' && getIn(errors, 'uisSiPension401Koccurrences')}
                  </Typography></Grid>}

                {
                  (values.uisSiPension401Koccurrences && values.uisSiPension401Koccurrences?.length !== 0) &&
                  <>
                    <Pension401kCodeField
                      label={"Type"}
                      name={`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].pension401Kind`}
                      value={values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.pension401Kind}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                    />

                    {isFullAmount401KrolledOver(values) &&
                      <IndicatorTypeWithUnknownField
                        label={"Was the full amount of the 401K rolled over to a new account?"}
                        name={`fullAmount401KrolledOver`}
                        value={values.fullAmount401KrolledOver}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                      />
                    }
                    {isPercentage401KrolledOver(values) &&
                      <SiTextField
                        label={"What percentage was rolled over to a new account?"}
                        name={`percentage401KrolledOver`}
                        value={values.percentage401KrolledOver?.toString()}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                        inputprops={{ inputComponent: NumberField as any }}
                        max={6}
                      />
                    }
                    {isPercentage401KrolledOverUnknown(values) &&
                      <IndicatorTypeYField
                        label={"Percentage was rolled over to a new account unknown"}
                        name={`percentage401KrolledOverUnknown`}
                        value={values.percentage401KrolledOverUnknown}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                      />
                    }
                    {isPaidOut401KtoClaimant(values) &&
                      <IndicatorTypeWithUnknownField
                        label={"Was or will the 401K be paid out to the claimant?"}
                        name={`paidOut401KtoClaimant`}
                        value={values.paidOut401KtoClaimant}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                      />
                    }

                    {isPension401KfrequencyCode(values, p401TypePageNumber - 1) &&
                      <Pension401KFrequencyCodeField
                        label={"Frequency of pay"}
                        name={`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].pension401KfrequencyCode`}
                        value={values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.pension401KfrequencyCode}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                      /> 
                    }
                    {isPension401KamountPerPeriod(values, p401TypePageNumber - 1) &&
                      <SiTextField
                        label={"Total amount of gross pay"}
                        name={`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].pension401KamountPerPeriod`}
                        value={values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.pension401KamountPerPeriod?.toString() || ''}
                      errors={errors}
                      setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                        inputprops={{ inputComponent: MoneyField as any }}
                        max={17}
                      /> 
                    }
                    {isPension401KamountPerPeriodUnknown(values, p401TypePageNumber - 1) &&
                      <IndicatorTypeYField
                      label={"Total amount of gross pay unknown"}
                        name={`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].pension401KamountPerPeriodUnknown`}
                        value={values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.pension401KamountPerPeriodUnknown}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                      /> 
                    }
                    {isDatePension401Kissued(values, p401TypePageNumber - 1) &&
                      <SiDateField
                        label={"Date of first payment"}
                        name={`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].datePension401Kissued`}
                        value={values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.datePension401Kissued || null}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                        inputlabelprops={{ shrink: true }}
                        size={6}
                      /> 
                    }
                    {isDatePension401KissuedUnknown(values, p401TypePageNumber - 1) &&
                      <IndicatorTypeYField
                        label={"Date of first payment unknown"}
                        name={`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].datePension401KissuedUnknown`}
                        value={values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.datePension401KissuedUnknown}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                      />
                    }
                    {isWhoContributedPension401K(values, p401TypePageNumber - 1) &&
                      <ContributionCodeField
                        label={values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.pension401Kind === 0 ? "Who contributed to the pension?" : "Who contributed to the 401K?"}
                        name={`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].whoContributedPension401K`}
                        value={values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.whoContributedPension401K}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                      /> 
                    }
                    {isClaimantPensionContributionPercent(values, p401TypePageNumber - 1) &&
                      <SiTextField
                        label={"What was the percentage contributed by the claimant?"}
                        name={`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].claimantPensionContributionPercent`}
                        value={values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.claimantPensionContributionPercent || ''}
                      errors={errors}
                      setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                        inputprops={{ inputComponent: NumberField as any }}
                        max={3}
                      /> 
                    }
                    {isClaimantPension401KcontributionPercentUnknown(values, p401TypePageNumber - 1) &&
                      <IndicatorTypeYField
                        label={"Percentage contributed by the claimant unknown"}
                        name={`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].claimantPension401KcontributionPercentUnknown`}
                        value={values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.claimantPension401KcontributionPercentUnknown}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                      /> 
                    }
                    {isEmployerPension401KcontributionPercent(values, p401TypePageNumber - 1) &&
                      <SiTextField
                        label={"What was the percentage contributed by the employer?"}
                        name={`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].employerPension401KcontributionPercent`}
                        value={values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.employerPension401KcontributionPercent || ''}
                      errors={errors}
                      setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                        inputprops={{ inputComponent: NumberField as any }}
                        max={3}
                      /> 
                    }
                    {isEmployerPension401KcontributionPercentUnknown(values, p401TypePageNumber - 1) &&
                      <IndicatorTypeYField
                        label={"Percentage contributed by the employer unknown"}
                        name={`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].employerPension401KcontributionPercentUnknown`}
                        value={values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.employerPension401KcontributionPercentUnknown}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                      /> 
                    }
                    {isEmployerLastContributionDate(values, p401TypePageNumber - 1) &&
                      <SiDateField
                        label={"Date of last employer contribution"}
                        name={`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].employerLastContributionDate`}
                        value={values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.employerLastContributionDate || null}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                        inputlabelprops={{ shrink: true }}
                        size={12}
                      /> 
                    }
                    {isEmployerLastContributionDateUnknown(values, p401TypePageNumber - 1) &&
                      <IndicatorTypeYField
                        label={"Date of last employer contribution unknown"}
                        name={`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].employerLastContributionDateUnknown`}
                        value={values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.employerLastContributionDateUnknown}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                      /> 
                    }
                    {opPension401Kcomments(values, p401TypePageNumber - 1) &&
                      <SiTextAreaField
                        label={"Provide any additional information"}
                        name={`uisSiPension401Koccurrences[${p401TypePageNumber - 1}].pension401Kcomments`}
                        value={values.uisSiPension401Koccurrences[p401TypePageNumber - 1]?.pension401Kcomments}
                      errors={errors}
                      setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly}
                        max={IUisSiPension401Koccurrence_PropertyAttributes.pension401Kcomments_Attributes.stringLength.maxLength}
                      /> 
                    }

                    <SidesPagination
                      hidden={values.uisSiPension401Koccurrences && values.uisSiPension401Koccurrences?.length === 0}
                      count={values.uisSiPension401Koccurrences?.length}
                      variant="outlined"
                      color="primary"
                      size="small"
                      page={p401TypePageNumber}
                      errorindex={handleGetErrors(values.uisSiPension401Koccurrences, 'uisSiPension401Koccurrences')}
                      onChange={(e, p) => { setP401TypePageNumber(p); }} />

                  </>
                }
                
              </Grid>
            </CardContent>
          </NavCard>

        </NavOptionsGrid>

      </Grid>
      </>
    
  )
}

export default SiPayments;
