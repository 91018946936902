


import { ISelectOption } from "../dtos/SelectOptionDto";
enum Tester {
        Item1 = 0,
        Item2 = 1,
         
}

export class Tester_class {

public static enum = Tester;

  public static readonly enumByText : { [key : string ]: number} = {
    ['Item1']: 0,
    ['Item2']: 1,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "Company/Employer",
    [1]: "Third Party Lab",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "Company/Employer"},
  {value: 1, text: "Third Party Lab"},
  ]
}

