import * as Yup from 'yup';
import { DocumentsDto } from '../documents/document.dtos';
import { ContactInformationDto } from '../../common/dtos/contactInformation';
import { BusinessUnitDto, CompanyDto } from '../company/dtos';
import {
  AdditionalQuestionDto,
  ClientQuestionnaireDto,
} from '../questionnaire/dto';
import moment from 'moment';
import { ContactEmployerDto } from '../contacts/contacts.dtos';
import { requiredError } from '../../common/constants/strings';

const tenYearsAgo = moment().add(-10, 'years').toDate();
const ssnRegex = /^(\d{4}|\d{9}|\d{3}-\d{2}-\d{4})$/;
export const claimValidationSchema = Yup.object().shape({
  claimsUserId: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  allowExisting: Yup.boolean(),
  appealsUserId: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  employerId: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .required(requiredError)
    .notOneOf(['00000000-0000-0000-0000-000000000000'], requiredError),
  businessUnit: Yup.object()
    .nullable()
    .when('documents[0].isComplete', {
      is: (isComplete) => isComplete,
      then: Yup.object().required(requiredError),
    }),
  selectedBusinessUnit: Yup.string()
    .max(100, 'Must be 200 characters or less')
    .nullable()
    .when('documents[0].isComplete', {
      is: (isComplete) => isComplete,
      then: Yup.string()
        .max(100, 'Must be 200 characters or less')
        .required(requiredError),
    }),
  employeeId: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  documents: Yup.array().of(
    Yup.object().shape({
      label: Yup.string().nullable(),
      benefitYearEndingDate: Yup.date().nullable(),
      type: Yup.number()
        .min(1, 'Please select a document type.')
        .max(19)
        .required(requiredError),
      status: Yup.number().min(0, 'Please select document status.').max(15),
      assignedId: Yup.string().nullable(),
      isComplete: Yup.boolean().nullable(),
      notes: Yup.string()
        .max(2000, 'Must be 2000 characters or less')
        .nullable(),
      dueDate: Yup.date()
        .typeError('Please enter a valid Date.')
        .min(tenYearsAgo, 'Due date is required')
        .required(requiredError),
      dateReceived: Yup.date().nullable(),
      suta: Yup.string().max(500, 'Must be 500 characters or less').nullable(),
      stateCode: Yup.string()
        .max(500, 'Must be 500 characters or less')
        .nullable(),
    })
  ), //.nullable(),
  hearingContacts: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
    })
  ),
  selectedEmployerContacts: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().max(200, 'Must be 200 characters or less').nullable(),
    })
  ),
  claimantFirstName: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .nullable(),
  claimantMiddleInitial: Yup.string()
    .max(1, 'Must be 1 characters or less')
    .nullable(),
  claimantLastName: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .nullable(),
  claimantSsn: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .required(requiredError),
  claimantJobTitle: Yup.string()
    .max(100, 'Must be 100 characters or less')
    .nullable(),
  claimantPaymentList: Yup.array()
    .of(
      Yup.object().shape({
        separationPayId: Yup.number().min(0).max(6).nullable(),
        amountPaid: Yup.string()
          .max(100, 'Must be 100 characters or less')
          .nullable(),
        datePaid: Yup.date().nullable(),
      })
    )
    .nullable(),
  dateFirstWorked: Yup.date().nullable(),
  dateLastWorked: Yup.date().nullable(),
  separationDate: Yup.date().nullable(),
  wasFiledPriorToPoa: Yup.boolean(),
  isSecondYearClaim: Yup.boolean(),
  dateReceived: Yup.date().nullable(),
  status: Yup.number().min(0).max(4),
  employerType: Yup.number().min(0).max(3),
  separationCode: Yup.string()
    .max(100, 'Must be 100 characters or less')
    .nullable(),
  benefitYearBeginningDate: Yup.date().required(requiredError),
  benefitYearEndingDate: Yup.date().required(requiredError),
  weeklyBenefitAmount: Yup.string()
    .max(100, 'Must be 100 characters or less')
    .nullable(),
  potentialLiabilityAmount: Yup.string()
    .max(100, 'Must be 100 characters or less')
    .nullable(),
  actualLiabilityAmount: Yup.string()
    .max(100, 'Must be 100 characters or less')
    .nullable(),
  savingsAmount: Yup.string().nullable(),
  notes: Yup.array().of(
    Yup.object().shape({
      entry: Yup.string()
        .max(2000, 'Must be 2000 characters or less')
        .nullable(),
      type: Yup.number().min(0).max(6),
    })
  ),
  // creditDue: Yup.boolean(),
  // creditLastPursuedDate: Yup.date().nullable(),
  // creditDueNotes: Yup.string().max(
  //   2000,
  //   "Credit Due Notes cannot be more than 2,000 characters."
  // ),
});

export const claimSearchValidationSchema = Yup.object().shape({
  claimantFirstName: Yup.string()
    .max(100, 'Must be 100 characters or less')
    .nullable(),
  claimantLastName: Yup.string()
    .max(100, 'Must be 100 characters or less')
    .nullable(),
  ssn: Yup.string()
    .matches(ssnRegex, 'Must be ####, #########, or ###-##-#### format')
    .nullable(),
  separationCode: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  employerId: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  stateCode: Yup.string().max(2, 'Must be 2 characters or less').nullable(),
  benefitYearBeginningDate: Yup.date().nullable(),
  benefitYearEndingDate: Yup.date().nullable(),
});

export const claimHeaderSearchValidationSchema = Yup.object().shape({
  search: Yup.string()
    .matches(ssnRegex, 'Must be #### or ######### format')
    .nullable(),
});

export interface SepCodeDto {
  id: string;
  name: string;
  description: string;
  isSep: boolean;
}

export interface ClaimNoteDto {
  id?: string;
  claimId?: string;
  entry?: string;
  type: number;
}

export interface HearingRepresenativeDto {
  id?: string;
}

export interface HearingContactsDto {
  id?: string;
}

export interface SelectedEmployerContactDto {
  id?: string;
}

export interface SelectedHearingContact {
  id?: string;
}

export interface ContactIdDto {
  contactId?: string; //($uuid) //nullable: true
  name?: string; //maxLength: 200  minLength: 0        nullable: true readOnly: true
  firstName?: string; //maxLength: 500 minLength: 0 nullable: true
  lastName?: string; // maxLength: 500 minLength: 0 nullable: true
  email?: string;
  contactEmployers?: ContactEmployerDto[];
}

// Events
export interface EligibilityEventDto {
  id?: string; //($uuid) // nullable: true
  claimId?: string; //($uuid)
  eligibilityCodeId?: number; //($uuid)
  ineligibleStartDate?: Date; //($date-time) nullable:  //true
  ineligibleEndDate?: Date; //($date-time) nullable: true
  caseNumber?: string; //maxLength: 200   minLength: 0   nullable: true
  comments?: string; //maxLength: 2000  minLength:  nullable: true
  createdDate: Date;
  createdBy?: string;
}

export interface AppellateEventDto {
  id?: string; //($uuid)    nullable: true
  claimId?: string; //($uuid)
  type: number; //AppellateEventTypesinteger($int32) Enum: Array [ 9 ]
  dueDate?: Date; //($date-time)  nullable: true
  hearingType: number; //	HearingTypesinteger($int32)Enum:   Array [ 2 ]
  docketIdentifier?: string; //  maxLength: 200 minLength: 0 nullable: true
  appealedBy?: number; //($uuid)   nullable: true
  hearingLocation: number; //	HearingLocationOptionsinteger($int32) Enum:  Array [ 2 ]
  city?: string; //maxLength: 200minLength: 0nullable: true
  state?: string; //maxLength: 200 minLength: 0  nullable: true
  hearingDateTime?: Date; //($date-time) nullable: true
  timeZoneId: number; //	integer($int32)
  dateRecieved?: Date; //	string($date-time)    nullable: true
  refereeFullName?: string; //maxLength: 500 minLength: 0   nullable: true
  hearingOutcome: number; //HearingOutcomeOptionsinteger($int32)  Enum: Array [ 6 ]
  coverageStartDate?: Date; //($date-time) nullable: true
  coverageEndDate?: Date; //($date-time)  nullable: true
  hearingDecisionDate?: Date; //($date-time) nullable: true
  hearingRepresentatives: HearingRepresenativeDto[];
  hearingCharge: number; //HearingChargeOptionsinteger($int32) Enum:  Array [ 3 ]
  investigationCharge: number; //HearingChargeOptionsinteger($int32) Enum:  Array [ 3 ]
  comments?: string; //maxLength: 2000 minLength: 0    nullable: true
  completedDate?: Date; //($date-time)   nullable: true
  createdDate: Date;
  createdBy?: string;
  alternativeIssueTypeId?: number;
}

export interface SeparationPayDto {
  separationPayType: number; //SeparationPayTypesinteger($int32) Enum: Array [ 3 ]
  amountPaid?: string; //maxLength: 200 minLength: 0 nullable: true
  datePaid?: Date; //($date-time)
}

export interface ClaimantPaymentDto {
  id?: string; //string($uuid) nullable: true
  separationPayId: number; //SeparationPayTypesinteger($int32) Enum:Array[3]
  amountPaid?: string; //string maxLength: 200minLength: 0nullable: true
  datePaid?: Date; //string($date - time) nullable: true
}

// Claim
export interface ClaimDto {
  employerName?: string;
  stateCode: string;
  id?: string; //($uuid) nullable: true
  allowExisting: boolean;
  suta?: string;
  documents: DocumentsDto[];
  eligibilityEvents: EligibilityEventDto[];
  appellateEvents: AppellateEventDto[];
  claimEvents: ClaimEventDto[];
  hearingContacts: HearingContactsDto[];
  label?: string;
  claimUserId?: string; //($uuid)
  claimUserFullName?: string; //($uuid)
  appealsUserId?: string; //($uuid)
  employerId?: string; //($uuid)
  employer?: CompanyDto; //($uuid)
  businessUnit?: BusinessUnitDto; // nullable: true
  selectedBusinessUnit?: string; // maxLength: 200 minLength: 0 nullable: true This value represents the name of the business unit *when it was last selected on the claim and the claim was saved*
  selectedEmployerContacts: ContactInformationDto[];
  claimantFirstName?: string; //maxLength: 200 minLength: 0 nullable: true
  claimantMiddleInitial?: string; //maxLength: 3 minLength: 0 nullable: true
  claimantLastName?: string; //maxLength: 200 minLength: 0 nullable: true
  claimantSsn?: string; //maxLength: 9 minLength: 0 nullable: true
  claimantJobTitle?: string; //maxLength: 200 minLength: 0 nullable: true
  claimantJobStatusTypeId: number; // NEW
  claimantJobStatus?: string; // NEW
  claimantPaymentList: ClaimantPaymentDto[];
  dateFirstWorked?: Date; //string //($date-time)
  dateLastWorked?: Date; //string //($date-time)
  separationDate?: Date; //string //($date-time)
  wasFiledPriorToPoa: boolean;
  isSecondYearClaim: boolean;
  dateReceived?: Date; //	string //($date-time)
  dueDate?: Date; //string //($date-time)
  status: number; //ClaimStatusTypesinteger($int32) Enum: Array [ 4 ]
  employerType: number; //EmployerTypesinteger($int32) Enum: Array [ 3 ]
  benefitYearBeginningDate?: Date; //Date
  benefitYearEndingDate?: Date; //	string($date-time)
  weeklyBenefitAmount?: string; //maxLength: 200 minLength: 0 nullable: true
  potentialLiabilityAmount?: string; //maxLength: 200 minLength: 0 nullable: true
  actualLiabilityAmount?: string; //maxLength: 200 minLength: 0 nullable: true
  savingsAmount?: string; //maxLength: 200 minLength: 0 nullable: true
  notes?: ClaimNoteDto[]; //maxLength: 2000 minLength: 0 nullable: true
  separationCodeId?: string;
  primaryEmployerContactEmail?: string;
  hasQuestionnaire?: boolean;
  additionalQuestionnaireQuestions?: AdditionalQuestionDto[];
  additionalQuestionnaireInformation?: string;
  questionnaireProvideFullSSN: boolean;
  questionnaireSubmitted: boolean;
  questionnaire?: ClientQuestionnaireDto;
  creditDue?: boolean;
  creditLastPursuedDate?: Date;
  creditDueNotes?: string;
  readOnlyNetChargesToDate?: number;
  basePeriodStartDate?: Date;
  basePeriodEndDate?: Date;
  employeeId?: string;
}

export interface ClaimLogDto {
  claimId?: string; //($uuid)
  dueDate?: Date; //string($date-time)   nullable: true
  dateRecieved?: Date; //string($date-time) nullable: true
  documentType?: number; //DocTypesinteger($int32) Enum Array [ 18 ]
  documentStatus?: number; //DocStatusTypesinteger($int32) Enum: Array [ 15 ]
  createdDate: Date; //string($date-time)
  createdBy?: string; //string nullable: true
}

export interface ClaimEventDto {
  id?: string;
  claimId?: string; //($uuid)
  completedDate: Date; //string($date-time)   nullable: true
  createdBy?: string; //string nullable: true
  alternativeIssueId?: number;
  eventTypeId?: number;
  createdDate?: Date;
}

export interface ListClaimDto {
  id?: string; //($uuid) nullable: true
  label?: string;
  status: number; //ClaimStatusTypesinteger($int32) Enum: Array [ 4 ]
  employerName?: string; //maxLength: 500 minLength: 0 nullable: true
  suta?: string; //maxLength: 200 minLength: 0 nullable: true
  employeeId?: string; //maxLength: 200 minLength: 0 nullable: true
  stateCode?: string; //maxLength: 3 minLength: 0 nullable: true
  claimantSsn?: string; //maxLength: 9 minLength: 0 nullable: true
  benefitYearEndingDate?: Date;
  hasQuestionnaire?: boolean;
}

export interface ClaimSearchDto {
  claimantFirstName?: string;
  claimantLastName?: string;
  ssn?: string;
  separationCode?: string;
  employerId?: string;
  employeeId?: string;
  stateCode?: string;
  benefitYearEndingDate?: Date;
  benefitYearBeginningDate?: Date;
}

// Inits
export const ContactIdInit: ContactIdDto = {
  name: '',
  firstName: '',
  lastName: '',
};

export const ClaimGeneralNoteInit: ClaimNoteDto = {
  type: 4,
  entry: '',
};

export const ClaimInternalNoteInit: ClaimNoteDto = {
  type: 5,
  entry: '',
};

export const HearingContactInit: HearingContactsDto = {};

export const ClaimLogInit: ClaimLogDto = {
  createdDate: new Date(),
};

export const ClaimEventInit: ClaimEventDto = {
  createdDate: new Date(),
  completedDate: new Date(),
};
export const SelectedEmployerContactInit: SelectedEmployerContactDto = {};

export const SelectedHearingContactInit: SelectedHearingContact = {};

export const HearingRepresenativeInit: HearingRepresenativeDto = {};

export const EligibilityEventInit: EligibilityEventDto = {
  ineligibleEndDate: new Date(), //($date-time) nullable:  //true
  ineligibleStartDate: new Date(), //($date-time) nullable:  //true
  createdDate: new Date(),
};

export const AppellateEventInit: AppellateEventDto = {
  type: 0, //AppellateEventTypesinteger($int32) Enum: Array [ 9 ]
  dueDate: new Date(), //($date-time)  nullable: true
  hearingType: 0, //	HearingTypesinteger($int32)Enum:   Array [ 2 ]
  docketIdentifier: '', //  maxLength: 200 minLength: 0 nullable: true
  hearingLocation: 0, //	HearingLocationOptionsinteger($int32) Enum:  Array [ 2 ]
  city: '', //maxLength: 200minLength: 0nullable: true
  state: '', //maxLength: 200 minLength: 0  nullable: true
  hearingDateTime: new Date(), //($date-time) nullable: true
  timeZoneId: 0, //	integer($int32)
  dateRecieved: new Date(), //	string($date-time)    nullable: true
  refereeFullName: '', //maxLength: 500 minLength: 0   nullable: true
  hearingOutcome: 0, //HearingOutcomeOptionsinteger($int32)  Enum: Array [ 6 ]
  coverageStartDate: new Date(), //($date-time) nullable: true
  coverageEndDate: new Date(), //($date-time)  nullable: true
  //hearingDecisionDate: new Date(); //($date-time) nullable: true
  hearingRepresentatives: [] as HearingRepresenativeDto[],
  hearingCharge: 0, //HearingChargeOptionsinteger($int32) Enum:  Array [ 3 ]
  investigationCharge: 0, //HearingChargeOptionsinteger($int32) Enum:  Array [ 3 ]
  comments: '', //maxLength: 2000 minLength: 0    nullable: true
  //completedDate: new Date(); //($date-time)   nullable: true
  createdDate: new Date(),
};

export const ClaimantPaymentInit: ClaimantPaymentDto = {
  separationPayId: 0,
};

export const ClaimInit: ClaimDto = {
  documents: [],
  stateCode: '',
  allowExisting: false,
  eligibilityEvents: [],
  appellateEvents: [],
  claimEvents: [],
  hearingContacts: [],
  selectedEmployerContacts: [],
  claimantPaymentList: [ClaimantPaymentInit],
  wasFiledPriorToPoa: false,
  isSecondYearClaim: false,
  status: 4,
  employerType: 0,
  claimantJobStatusTypeId: 0,
  notes: [
    { type: 4, entry: '' },
    { type: 5, entry: '' },
  ],
  questionnaireProvideFullSSN: false,
  questionnaireSubmitted: false,
  // creditDue: false,
};

export const ListClaimInit: ListClaimDto = {
  status: 0, //ClaimStatusTypesinteger($int32) Enum: Array [ 4 ]
};

export const ClaimSearchInit: ClaimSearchDto = {};
