import React from 'react';

import TabPanel from '../../../../common/components/tabPanel';
import HealthSystemDetailsSettings from './healthSystemDetailsEdit';
import { Box, } from '@material-ui/core';
import { useMediaQuery, } from '@material-ui/core';
import HealthSystemBilling from '../components/billing';
import { SettingsGrid, ClearAppBar, StyledTabs, StyledTab } from '../../../../common/styles/styles';


const HealthSystemDetails: React.FC = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const changeTab = (event: any, newValue: number) => {
    setTabValue(newValue);
  }
  const shouldCollapse = useMediaQuery('(max-width:768px)');

  return <Box p={2}>
    <SettingsGrid>
      <ClearAppBar position="static">
              <StyledTabs aria-labelledby={`tab-${tabValue}`} orientation={shouldCollapse ? 'vertical' : 'horizontal'} centered={true} value={tabValue} onChange={changeTab} aria-label="Settings options" variant="fullWidth" scrollButtons="auto">
                  <StyledTab id="tab-0" aria-controls="tabpanel-0" label="Health System" data-cy="healthSystems_details_tab" />
                  <StyledTab id="tab-1" aria-controls="tabpanel-1"label="Billing" />
                  {/*<StyledTab id="tab-2" aria-controls="tabpanel-2"label="Placeholder" />*/}
                  {/*<StyledTab id="tab-3" aria-controls="tabpanel-3"label="Placeholder" />*/}
                  {/*<StyledTab id="tab-4" aria-controls="tabpanel-4" label="Placeholder" />*/}

        </StyledTabs>
      </ClearAppBar>
      <TabPanel value={tabValue} index={0} data-cy="healthSystems_container"><HealthSystemDetailsSettings /></TabPanel>
          <TabPanel value={tabValue} index={1}><HealthSystemBilling /></TabPanel>
      <TabPanel value={tabValue} index={2}></TabPanel>
    </SettingsGrid>
  </Box >
}

export default HealthSystemDetails;