import React from 'react';
// material ui
import { Grid } from '@material-ui/core';
import CompanyList from './components/companyList';
import { ViewGrid } from '../../common/styles/styles';


const Companies: React.FC = () => {
  return <ViewGrid container>
    <Grid item xs={12}><CompanyList /></Grid>
  </ViewGrid>
}

export default Companies;

