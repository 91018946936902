import React from 'react';
import { FormikHandlers, FormikErrors, FormikHelpers } from 'formik';
// material ui
import { Grid, CardContent } from '@material-ui/core';
import { NavCard, NavOptionsGrid } from '../../documents.styles';
import { EmployerSepReasonCodes_class } from '../../../../common/enums/EmployerSepReasonCodes';
import {
  isAdditionalSeparationAckInd, isAttachmentInd, isDischargeReasonCode, isRefuseToProvideInd, isRequestReliefofChargesInd, isVoluntarySepReasonCode
} from '../../../../utils/mcRequiredPropFunctions';
import {
  opEmployerContactName, opEmployerPhone, opEmployerStreetAddress, opEmployerCity, opEmployerState, opEmployerZip, opRepContractTermDate
} from '../../../../utils/mcOptionalPropFunctions';
import { ExtendedStateAbrCodes_class } from '../../../../common/enums/ExtendedStateAbrCodes';
import phoneNumberField from '../../../../common/components/phoneNumberField';
import { HiringChangesCodes_class } from '../../../../common/enums/HiringChangesCodes';
import { FraudTypeCodes_class } from '../../../../common/enums/FraudTypeCodes';
import { ISelectOption } from '../../../../common/dtos/SelectOptionDto';
import { IMc, IMc_PropertyAttributes } from '../../../../common/dtos/McDto';
import McDateField from '../mcFieldComponents/McDateField';
import McSelectOptions from '../mcFieldComponents/McSelectOptions';
import McTextField from '../mcFieldComponents/McTextField';
import McTextAreaField from '../mcFieldComponents/McTextAreaField';
import IndicatorTypeYField from '../mcFieldComponents/IndicatorTypeYField';
import IndicatorTypeField from '../mcFieldComponents/IndicatorTypeField';
import { DischargeReasonCodes_class } from '../../../../common/enums/DischargeReasonCodes';
import { VoluntarySepReasonCodes_class } from '../../../../common/enums/VoluntarySepReasonCodes';


interface Props extends React.HTMLProps<React.ReactNode> {
  values: IMc;
  errors: FormikErrors<IMc>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<IMc>["setFieldValue"];
  isReadOnly: boolean;
}

const McGeneral: React.FC<Props> = (
  {
    values,
    errors,
    handleChange,
    isReadOnly,
    handleBlur,
    setFieldValue,
  }) => {

  const [separationCodeOptions, setSeparationCodeOptions] = React.useState<Array<ISelectOption<number>>>(EmployerSepReasonCodes_class.enumAsSelectOptions);

  //React.useEffect(() => {
  //  console.log('errors', errors);
  //}, [errors]);

  React.useEffect(() => {
    handleCheckemployerSepReasonCode();
    if (!isRequestReliefofChargesInd(values) && values.requestReliefofChargesInd !== undefined) setFieldValue('requestReliefofChargesInd', undefined);
    if (!isDischargeReasonCode(values) && values.dischargeReasonCode !== undefined) setFieldValue('dischargeReasonCode', undefined);
    if (!isVoluntarySepReasonCode(values) && values.voluntarySepReasonCode !== undefined) setFieldValue('voluntarySepReasonCode', undefined);
    //if (!isAdditionalSeparationAckInd(values) && values.additionalSeparationAckInd !== undefined) setFieldValue('additionalSeparationAckInd', undefined);
    if (!isRefuseToProvideInd(values) && values.refuseToProvideInd !== undefined) setFieldValue('refuseToProvideInd', undefined);
    if (!opEmployerContactName(values) && values.employerContactName !== undefined) setFieldValue('employerContactName', undefined);
    if (!opEmployerPhone(values) && values.employerPhone !== undefined) setFieldValue('employerPhone', undefined);
    if (!opEmployerStreetAddress(values) && values.employerStreetAddress !== undefined) setFieldValue('employerStreetAddress', undefined);
    if (!opEmployerCity(values) && values.employerCity !== undefined) setFieldValue('employerCity', undefined);
    if (!opEmployerState(values) && values.employerState !== undefined) setFieldValue('employerState', undefined);
    if (!opEmployerZip(values) && values.employerZip !== undefined) setFieldValue('employerZip', undefined);
    if (!opRepContractTermDate(values) && values.repContractTermDate !== undefined) setFieldValue('repContractTermDate', undefined);
    if (!isAttachmentInd(values) && values.attachmentInd !== undefined) setFieldValue('attachmentInd', undefined);
  }, [values]);

  const handleCheckemployerSepReasonCode = () => {
    let C = EmployerSepReasonCodes_class.enum;
    if (values.fraudReasons == FraudTypeCodes_class.enum.Item2)
      setSeparationCodeOptions(EmployerSepReasonCodes_class.enumAsSelectOptions
        .filter(f => [C.Item9, C.Item10, C.Item11, C.Item12].findIndex(fi => fi === f.value) !== -1)
      );
    else if (values.temporaryStaffingInd == 0)
      setSeparationCodeOptions(EmployerSepReasonCodes_class.enumAsSelectOptions
        .filter(f => C.Item20 !== f.value)
      );
    else
      setSeparationCodeOptions(EmployerSepReasonCodes_class.enumAsSelectOptions);
  };

  return (
    <Grid container spacing={2} data-cy="mcResponse_generalInformation_container">

      <NavOptionsGrid item xs={6}> 
        <NavCard>
          <CardContent>
            <Grid container spacing={1}>
              {/*{JSON.stringify(errors)}*/}
              
              <McDateField
                label={"Last Day Worked"}
                name={`lastDayWorked`}
                value={values.lastDayWorked}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                size={6}
                inputlabelprops={{ shrink: true }}
              />

              <McSelectOptions
                label={"Separation Reason"}
                name={`employerSeparationReasonCode`}
                value={values.employerSeparationReasonCode}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                options={separationCodeOptions}
              />

              {isRefuseToProvideInd(values) &&
                <IndicatorTypeYField
                  label={`Yes, I agree.`}
                  customLabel={`By selecting REFUSE TO PROVIDE, I acknowledge that I have not
provided the requested information either because it is not available,
or I am unwilling or cannot present the information requested. I
understand and agree that the state will proceed in making a
determination based on the information on file. Failure to provide the
information requested may have an adverse effect on the employer
account.`}
                  name={`refuseToProvideInd`}
                  value={values.refuseToProvideInd}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                /> 
              }

              {/*Conditional Questions*/}
              {isAdditionalSeparationAckInd(values) ?
                <IndicatorTypeField
                  label={"I acknowledge the state’s request for additional information."}
                  name={`additionalSeparationAckInd`}
                  value={values.additionalSeparationAckInd}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  allowNull={false}
                /> : null
              }
              {opEmployerContactName(values) &&
                <McTextField
                  label={"Employer contact name"}
                  name={`employerContactName`}
                  value={values.employerContactName}
                errors={errors}
                setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  max={IMc_PropertyAttributes.employerContactName_Attributes.stringLength.maxLength}
                /> 
              }
              {opEmployerPhone(values) &&
                <McTextField
                  label={"Employer contact phone number"}
                  name={`employerPhone`}
                  value={values.employerPhone}
                errors={errors}
                setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  max={IMc_PropertyAttributes.employerContactName_Attributes.stringLength.maxLength}
                  inputprops={{ inputComponent: (phoneNumberField as any) }}
                /> 
              }
              {opEmployerStreetAddress(values) &&
                <McTextField
                  label={"Employer street address"}
                  name={`employerStreetAddress`}
                  value={values.employerStreetAddress}
                errors={errors}
                setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  max={IMc_PropertyAttributes.employerStreetAddress_Attributes.stringLength.maxLength}
                /> 
              }
              {opEmployerCity(values) &&
                <McTextField
                  label={"Employer city"}
                  name={`employerCity`}
                  value={values.employerCity}
                errors={errors}
                setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  max={IMc_PropertyAttributes.employerCity_Attributes.stringLength.maxLength}
                /> 
              }
              {opEmployerState(values) &&
                <McSelectOptions
                  label={"Employer state"}
                  name={`employerState`}
                  value={values.employerState}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  options={ExtendedStateAbrCodes_class.enumAsSelectOptions}
                /> 
              }
              {opEmployerZip(values) &&
                <McTextField
                  label={"Employer zip code"}
                  name={`employerZip`}
                  value={values.employerZip}
                errors={errors}
                setFieldValue={setFieldValue}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  max={IMc_PropertyAttributes.employerZip_Attributes.stringLength.maxLength}
                /> 
              }
              {opRepContractTermDate(values) &&
                <McDateField
                  label={"Date of contract termination"}
                  name={`repContractTermDate`}
                  value={values.repContractTermDate}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  isReadOnly={isReadOnly}
                  inputlabelprops={{ shrink: true }}
                /> 
              }

            </Grid>
          </CardContent>
        </NavCard>
      </NavOptionsGrid>

      <NavOptionsGrid item xs={6}>
        <NavCard>
          <CardContent>
            <Grid container spacing={1}>
              <IndicatorTypeField
                label={"Already responded to a Separation Info request?"}
                name={`employerSepReasonAnsweredInd`}
                value={values.employerSepReasonAnsweredInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
              <IndicatorTypeField
                label={"Seasonal"}
                name={`seasonalEmploymentInd`}
                value={values.seasonalEmploymentInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />
              <IndicatorTypeYField
                label={"Temporary Staffing"}
                name={`temporaryStaffingInd`}
                value={values.temporaryStaffingInd}
                errors={errors}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
              />

              <br></br>

              <McTextAreaField
                label={"Employer's Reason Comments"}
                name={`employerResponseComments`}
                value={values.employerResponseComments}
                errors={errors}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                handleBlur={handleBlur}
                isReadOnly={isReadOnly}
                max={IMc_PropertyAttributes.employerResponseComments_Attributes.stringLength.maxLength}
              />
            </Grid>
          </CardContent>
        </NavCard>


        {(isDischargeReasonCode(values) || isVoluntarySepReasonCode(values) || isRequestReliefofChargesInd(values)) &&
          <>
          <br></br>
          <Grid container item xs={12}>
            <NavCard>
              <CardContent>
                <Grid container spacing={1}>

                  {isVoluntarySepReasonCode(values) &&
                    <McSelectOptions
                      label={"Reason for Quit"}
                      name={`voluntarySepReasonCode`}
                      value={values.voluntarySepReasonCode}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                      options={VoluntarySepReasonCodes_class.enumAsSelectOptions}
                      size={10}
                    />
                  }
                  
                  {isRequestReliefofChargesInd(values) &&
                    <IndicatorTypeField
                      label={"Requesting relief of charges?"}
                      name={`requestReliefofChargesInd`}
                      value={values.requestReliefofChargesInd}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                    />
                  }

                  {isDischargeReasonCode(values) &&
                    <McSelectOptions
                      label={"Why was the claimant facing discharge or suspension?"}
                      name={`dischargeReasonCode`}
                      value={values.dischargeReasonCode}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                      options={DischargeReasonCodes_class.enumAsSelectOptions}
                    />
                  }

                </Grid>

              </CardContent>
            </NavCard>
          </Grid>
          </>
        }
        
      </NavOptionsGrid>
    </Grid>
  )
}

export default McGeneral;
