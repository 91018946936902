import * as React from "react";
import styled from "styled-components";

import { AppBar, Toolbar, Hidden, IconButton } from "@material-ui/core";
//import GroupIcon from '@material-ui/icons/Group';
//import AccessTimeIcon from '@material-ui/icons/AccessTime';
import IconMenu from "@material-ui/icons/Menu";
import CloseMenu from "@material-ui/icons/Close";

import ClaimSearch from "./claimSearch";
import ContactSearch from "./contactSearch";
import HeaderProfile from "./headerProfile";
import { isClientPortalUser } from "../../../common/dtos/auth";
import EmailTemplateDialog from "../../dialogs/emailTemplateDialog";
//import HeaderWorkStatus from './headerWorkStatus';

interface HeaderProps {
  onToggle?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isCollapsed: boolean;
}

const StyledAppBar = styled(AppBar)`
  /*background: #fff;
    color: #7b7b7b;*/
  box-shadow: none;
`;
const StyledDiv = styled.div`
  margin-left: auto;
  align-items: center;
  display: flex;
`;
const StyledIconButton = styled(IconButton)`
  color: white;
  margin-left: -20px;
`;

const Header = ({ onToggle, isCollapsed }: HeaderProps) => {
  return (
    <StyledAppBar position="absolute">
      <Toolbar>
        <StyledIconButton
          edge="start"
          aria-label="Toggle sidebar"
          onClick={onToggle}
          data-cy="sidebar_menuExpandCollapse"
        >
          {isCollapsed ? (
            <IconMenu fontSize="large" />
          ) : (
            <CloseMenu fontSize="large" />
          )}
        </StyledIconButton>
        <img
          src={process.env.PUBLIC_URL + "/MHAUCPlogowhite.png"}
          alt="MHA logo"
          width="150"
        />
        {/*<Hidden smUp>
          <img src={process.env.PUBLIC_URL + '/MHAUCPlogowhite.png'} alt="MHA logo" width="200" />
        </Hidden>
        <Hidden xsDown>
          <img src={process.env.PUBLIC_URL + '/MHAUCPlogowhite.png'} alt="MHA logo" width="155" />
        </Hidden>*/}
        <StyledDiv>
          <Hidden smDown>
            {!isClientPortalUser() ? <ContactSearch /> : null}
            <ClaimSearch />
          </Hidden>
          {/*<AccessTimeIcon /> &nbsp;
          <HeaderWorkStatus />

          <GroupIcon />*/}{" "}
          &nbsp; &nbsp;
          <HeaderProfile />
          <EmailTemplateDialog />
        </StyledDiv>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
