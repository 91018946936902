 
/* Autogenerated by Typewriter do NOT edit
   Dtos.tst is the generating template */


export interface IUisSiPriorIncidentOccurrence  { 
    
    id?: number;
    priorIncidentDate?: Date;
    priorIncidentReason?: string;
    priorIncidentWarningInd?: number;
    priorIncidentWarningDate?: Date;
    priorIncidentWarningDescription?: string; 
} 

export class IUisSiPriorIncidentOccurrence_PropertyAttributes {
    static readonly id_Attributes = {
      required: { value: true }, name: "Id", 
      };
       static readonly priorIncidentDate_Attributes = {
      required: { value: false }, name: "PriorIncidentDate", 
      };
       static readonly priorIncidentReason_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "PriorIncidentReason", 
      };
       static readonly priorIncidentWarningInd_Attributes = {
      required: { value: false }, name: "PriorIncidentWarningInd", 
      };
       static readonly priorIncidentWarningDate_Attributes = {
      required: { value: false }, name: "PriorIncidentWarningDate", 
      };
       static readonly priorIncidentWarningDescription_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "PriorIncidentWarningDescription", 
      };
      
}

