import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors } from 'formik';

// material ui
import { Grid, TextField, MenuItem, FormControlLabel } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ClientQuestionnaireDto } from '../../dto';
import AttachmentsDropzone from '../../../../common/components/attachmentDropzone';
import {
  dischargeNotificationOptions,
  radioButtonBooleanOptions,
  indicatorOptions,
} from '../../constants/options';
import { PaddedTextField, MHARadio } from '../../../../common/styles/styles';

interface DrugsOrAlcoholComponentProps
  extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>['setFieldValue'];
  setShouldBlockNavigation: (o: boolean) => void;
  attachments: File[];
  onAttachmentDelete: (file: File) => void;
  onAttachmentUpload: (files: File[]) => void;
}

const DrugsOrAlcoholComponent: React.FC<DrugsOrAlcoholComponentProps> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setShouldBlockNavigation,
  attachments,
  onAttachmentDelete,
  onAttachmentUpload,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={12}>
        Was claimant tested for drugs/alcohol?
      </Grid>
      <Grid container item xs={12} direction='row'>
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id='wasClaimantTested'
              name='wasClaimantTested'
              onChange={(
                e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>,
                checked: boolean
              ) => {
                setFieldValue(
                  'wasClaimantTested',
                  radioButtonBooleanOptions.find(
                    (x) => x.name === (e.target as HTMLInputElement).value
                  )?.status ?? 0
                );
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              checked={o.status === values.wasClaimantTested}
              value={o.name}
              control={<MHARadio size='small' />}
              label={o.name}
              data-cy='clientQuestionnaire_wasClaimantTested'
            />
          </Grid>
        ))}
      </Grid>
      <br />
      {values.wasClaimantTested === true ? (
        <>
          <Grid item xs={12}>
            What date was the test administered?
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <KeyboardDatePicker
              margin='normal'
              id='drugTestDate'
              name='drugTestDate'
              //label="Hire Date"
              format='MM/DD/yyyy'
              value={values.drugTestDate || null}
              data-cy='clientQuestionnaire_drugTestDate'
              fullWidth
              onChange={(date: MaterialUiPickersDate) => {
                setFieldValue('drugTestDate', date);
                setShouldBlockNavigation(true);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <br />
          <Grid item xs={12}>
            What was the reason or cause for the test to be administered?
          </Grid>
          <Grid item xs={4}>
            <TextField
              id='drugTestReason'
              size='small'
              margin='none'
              value={values.drugTestReason || ''}
              name='drugTestReason'
              multiline
              onChange={(e) => {
                handleChange(e);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              error={Boolean(errors.drugTestReason)}
              helperText={errors.drugTestReason && errors.drugTestReason}
              data-cy='clientQuestionnaire_drugTestReason'
              aria-label='What was the claimant dissatisfied about?'
              fullWidth
            />
          </Grid>
          <br />

          <Grid item xs={12}>
            Was the test positive?
          </Grid>
          <Grid container item xs={12} direction='row'>
            {radioButtonBooleanOptions.map((o, index) => (
              <Grid item xs={3} key={o.name + index}>
                <FormControlLabel
                  id='wasDrugTestPositive'
                  name='wasDrugTestPositive'
                  onChange={(
                    e:
                      | React.ChangeEvent<HTMLInputElement>
                      | React.ChangeEvent<{}>,
                    checked: boolean
                  ) => {
                    setFieldValue(
                      'wasDrugTestPositive',
                      radioButtonBooleanOptions.find(
                        (x) => x.name === (e.target as HTMLInputElement).value
                      )?.status ?? 1
                    );
                    setShouldBlockNavigation(true);
                  }}
                  onBlur={handleBlur}
                  checked={o.status === values.wasDrugTestPositive}
                  value={o.name}
                  control={<MHARadio size='small' />}
                  label={o.name}
                  data-cy='clientQuestionnaire_wasDrugTestPositive'
                />
              </Grid>
            ))}
          </Grid>
          <br />
          {values.wasDrugTestPositive === true ? (
            <>
              <Grid item xs={12}>
                For what substance?
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id='substance'
                  size='small'
                  margin='none'
                  value={values.substance || ''}
                  name='substance'
                  multiline
                  onChange={(e) => {
                    handleChange(e);
                    setShouldBlockNavigation(true);
                  }}
                  onBlur={handleBlur}
                  error={Boolean(errors.substance)}
                  helperText={errors.substance && errors.substance}
                  data-cy='clientQuestionnaire_substance'
                  aria-label='What was the claimant dissatisfied about?'
                  fullWidth
                />
              </Grid>
              <br />
              <Grid item xs={12}>
                Please provide us with a copy of the test results.
              </Grid>
              <Grid item xs={12}>
                <AttachmentsDropzone
                  attachments={attachments}
                  onDelete={onAttachmentDelete}
                  onDrop={onAttachmentUpload}
                  shouldHaveBox={false}
                />
              </Grid>
              <br />
            </>
          ) : null}
        </>
      ) : null}
      <Grid item xs={12}>
        Was the claimant previously warned, either in writing or verbally, for
        these types of violations?
      </Grid>
      <Grid container item xs={12} direction='row'>
        {indicatorOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id='wasClaimantPreviouslyWarnedIndicatorId'
              name='wasClaimantPreviouslyWarnedIndicatorId'
              onChange={(
                e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>,
                checked: boolean
              ) => {
                setFieldValue(
                  'wasClaimantPreviouslyWarnedIndicatorId',
                  indicatorOptions.find(
                    (x) => x.name === (e.target as HTMLInputElement).value
                  )?.id ?? 1
                );
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              //add errors for validation
              checked={o.id === values.wasClaimantPreviouslyWarnedIndicatorId}
              value={o.name}
              control={<MHARadio size='small' />}
              label={o.name}
              data-cy='clientQuestionnaire_wasClaimantPreviouslyWarned'
            />
          </Grid>
        ))}
      </Grid>
      <br />
      <Grid item xs={12}>
        Do you have a written policy related to the final violation incident?
      </Grid>
      <Grid container item xs={12} direction='row'>
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id='writtenPolicyExists'
              name='writtenPolicyExists'
              onChange={(
                e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>,
                checked: boolean
              ) => {
                setFieldValue(
                  'writtenPolicyExists',
                  radioButtonBooleanOptions.find(
                    (x) => x.name === (e.target as HTMLInputElement).value
                  )?.status ?? 1
                );
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              //add errors for validation
              checked={o.status === values.writtenPolicyExists}
              value={o.name}
              control={<MHARadio size='small' />}
              label={o.name}
              data-cy='clientQuestionnaire_writtenPolicyExists'
            />
          </Grid>
        ))}
      </Grid>
      <br />

      <br />
      <Grid item xs={12}>
        How was the claimant notified of their discharge from employment?
      </Grid>
      <Grid item md={12} xs={12} sm={12} lg={5}>
        <PaddedTextField
          id='dischargeNotificationId'
          size='small'
          margin='none'
          select
          value={values.dischargeNotificationId || ''}
          name='dischargeNotificationId'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.dischargeNotificationId)}
          helperText={
            errors.dischargeNotificationId && errors.dischargeNotificationId
          }
          data-cy='clientQuestionnaire_jobStatus'
          aria-label='Most Recent Job Status'
          fullWidth
          SelectProps={{ MenuProps: { disableScrollLock: true } }}
        >
          {dischargeNotificationOptions
            .filter((item) => item.name !== '')
            .map((option, index) => (
              <MenuItem key={option.name + index} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
        </PaddedTextField>
      </Grid>
      <br />
      <Grid item xs={12}>
        Who (name and title) discharged the claimant?
      </Grid>
      <Grid item xs={4}>
        <TextField
          id='personWhoMadeDecisionToDischarge'
          size='small'
          margin='none'
          value={values.personWhoMadeDecisionToDischarge || ''}
          multiline
          label='Name'
          name='personWhoMadeDecisionToDischarge'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.personWhoMadeDecisionToDischarge)}
          helperText={
            errors.personWhoMadeDecisionToDischarge &&
            errors.personWhoMadeDecisionToDischarge
          }
          data-cy='clientQuestionnaire_personWhoMadeDecision'
          aria-label='Name of person who made decision to discharge'
          fullWidth
        />
      </Grid>
      <br />
      <Grid item xs={4}>
        <TextField
          id='personWhoMadeDecisionTitle'
          size='small'
          margin='none'
          value={values.personWhoMadeDecisionTitle || ''}
          multiline
          label='Job Title'
          name='personWhoMadeDecisionTitle'
          onChange={(e) => {
            handleChange(e);
            setShouldBlockNavigation(true);
          }}
          onBlur={handleBlur}
          error={Boolean(errors.personWhoMadeDecisionTitle)}
          helperText={
            errors.personWhoMadeDecisionTitle &&
            errors.personWhoMadeDecisionTitle
          }
          data-cy='clientQuestionnaire_personWhoMadeDecisionTitle'
          aria-label='Person who made decision title'
          fullWidth
        />
      </Grid>
      <br />
    </React.Fragment>
  );
};

export default DrugsOrAlcoholComponent;
