import { createAsyncAction, createAction } from 'typesafe-actions';
import { BatchPaymentsDto, GetMiWamDto, ListBatchPaymentDto, MiWamDto, ResolveDiscrepancyDto } from './dtos';

export const getBatchPaymentsListActions = createAsyncAction(
  'GET_BATCH_PAYMENTS_LIST_REQUEST',
  'GET_BATCH_PAYMENTS_LIST_SUCCESS',
  'GET_BATCH_PAYMENTS_LIST_ERROR'
)<undefined, ListBatchPaymentDto[], string>();

export const getBatchPaymentActions = createAsyncAction(
  'GET_BATCH_PAYMENT_REQUEST',
  'GET_BATCH_PAYMENT_SUCCESS',
  'GET_BATCH_PAYMENT_ERROR'
)<string, BatchPaymentsDto, string>();

export const createBatchPaymentActions = createAsyncAction(
  'CREATE_BATCH_PAYMENT_REQUEST',
  'CREATE_BATCH_PAYMENT_SUCCESS',
  'CREATE_BATCH_PAYMENT_ERROR'
)<BatchPaymentsDto, BatchPaymentsDto, string>();

export const updateBatchPaymentActions = createAsyncAction(
  'UPDATE_BATCH_PAYMENT_REQUEST',
  'UPDATE_BATCH_PAYMENT_SUCCESS',
  'UPDATE_BATCH_PAYMENT_ERROR'
)<BatchPaymentsDto, BatchPaymentsDto, string>();

export const getClaimPaymentsActions = createAsyncAction(
  'GET_CLAIM_PAYMENTS_REQUEST',
  'GET_CLAIM_PAYMENTS_SUCCESS',
  'GET_CLAIM_PAYMENTS_ERROR'
)<string, BatchPaymentsDto[], string>();

export const updateClaimPaymentsActions = createAsyncAction(
  'UPDATE_CLAIM_PAYMENTS_REQUEST',
  'UPDATE_CLAIM_PAYMENTS_SUCCESS',
  'UPDATE_CLAIM_PAYMENTS_ERROR'
)<BatchPaymentsDto[], BatchPaymentsDto[], string>();

export const resolvePaymentDiscrepancyActions = createAsyncAction(
  'RESOLVE_PAYMENT_DISCREPANCY_REQUEST',
  'RESOLVE_PAYMENT_DISCREPANCY_SUCCESS',
  'RESOLVE_PAYMENT_DISCREPANCY_ERROR'
)<ResolveDiscrepancyDto, boolean, string>();

export const uploadMiWamImport = createAction('UPLOAD_MIWAM_IMPORT')<any>();

export const getMiWamDataActions = createAsyncAction(
  'GET_MIWAM_DATA_REQUEST',
  'GET_MIWAM_DATA_SUCCESS',
  'GET_MIWAM_DATA_ERROR'
)<GetMiWamDto, MiWamDto[], string>();



