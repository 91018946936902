import React from 'react';

import * as Styles from '../../../common/styles/styles';
import TabPanel from '../../../common/components/tabPanel';
import CompanySettings from './components/companySettings';


const CustomerSettings: React.FC = () => {
  const [tabValue, setTabValue] = React.useState(0);
  const changeTab = (event: any, newValue: number) => {
    setTabValue(newValue);
  }
  return <div>
    <Styles.ClearAppBar position="static">
      <Styles.StyledTabs centered={true} value={tabValue} onChange={changeTab} aria-label="Settings options" variant="fullWidth" scrollButtons="auto">
        <Styles.StyledTab label="Company" data-cy="settings_customerCompanyTab" />
        {/*<Styles.StyledTab label="Placeholder" />*/}
        {/*<Styles.StyledTab label="Placeholder" />*/}
      </Styles.StyledTabs>
    </Styles.ClearAppBar>
    <TabPanel value={tabValue} index={0}><CompanySettings /></TabPanel>
    {/*<TabPanel value={tabValue} index={1}>Placeholder</TabPanel>*/}
    {/*<TabPanel value={tabValue} index={2}>Placeholder</TabPanel>*/}
  </div>


}

export default CustomerSettings;