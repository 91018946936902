export interface TaskTypesWorkOrderSettingsDto {
  taskTypeId?: string,
  taskCategoryId: number, // TaskCategoriesinteger($int32)
  name: string,
  //maxLength: 200
  invoiceName: string,
  //maxLength: 200
  defaultRate: string,
  inUse: boolean,
  isActive: boolean,
  checked: boolean
}

export const taskTypesWorkOrderSettingsInit: TaskTypesWorkOrderSettingsDto = { taskTypeId: '', taskCategoryId: 0, name: '', invoiceName: '', defaultRate: '', inUse: false, isActive: true, checked: false };