


import { ISelectOption } from "../dtos/SelectOptionDto";
enum DischargeExplanationCodes {
        W = 0,
        V = 1,
        B = 2,
         
}

export class DischargeExplanationCodes_class {

public static enum = DischargeExplanationCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['W']: 0,
    ['V']: 1,
    ['B']: 2,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "Written",
    [1]: "Verbal",
    [2]: "Both",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "Written"},
  {value: 1, text: "Verbal"},
  {value: 2, text: "Both"},
  ]
}

