import {
  Box,
  Button,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import React from "react";
import { useDispatch } from "react-redux";
import { getType } from "typesafe-actions";
import ReportViewer from "../../common/components/ReportViewer";
import { getEmployers, isAuthorized } from "../../common/dtos/auth";
import { rootSelector } from "../../common/selectors/selectors";
import { getMhaClaimsContactsActions } from "../claims/actions";
import { getCompaniesHsActions } from "../company/actions";
import { getHealthSystemsActions } from "../settings/healthSystems/healthSystems.actions";
import { getTelerikReportsActions } from "./actions";
import ClientReportNoticeDialog from "./dialogs/ClientReportNoticeDialog";
import ReportParamsDialog from "./dialogs/reportParamsDialog";
import {
  AnnualSummaryReportParametersDto,
  BenchmarkAndComparisonReportParametersDto,
  ClaimantProfileReportParametersDto,
  CreditDueReportParametersDto,
  ExecutiveSummaryReportParametersDto,
  ExecutiveSummaryWithIndividualBreakdownsReportParametersDto,
  NewUnemploymentClaimsReportParametersDto,
  ProductivityReportParametersDto,
  TelerikReportDto,
  UnemploymentClaimsSummaryReportParametersDto,
  reportParameterTypes,
} from "./dtos";

const Reports: React.FC = () => {
  const dispatch = useDispatch();
  const baseUrl = process.env.REACT_APP_API_URL ?? "/api/";
  const downloadReportInstructionUrl =
    baseUrl + "download/documents/report-intructions";

  const systemReports = rootSelector((state) => state.reports.list);
  const systemHealthSystems = rootSelector(
    (state) => state.healthSystems.healthSystems
  );
  const systemHsCompanies = rootSelector(
    (state) => state.companies.hsCompanies
  );
  const systemMhaClaimsContacts = rootSelector(
    (state) => state.claims.mhaClaimsContactsList
  );

  const [report, setReport] = React.useState(
    undefined as TelerikReportDto | undefined
  );
  const [reports, setReports] = React.useState([] as TelerikReportDto[]);
  const [reportId, setReportId] = React.useState(0);
  const [reportParamsDialogOpen, setReportParamsDialogOpen] =
    React.useState(false);
  const [reportnoticeDialogOpen, setReportNoticeDialogOpen] =
    React.useState(false);
  const [reportName, setReportName] = React.useState<string>("");
  const [reportDescription, setReportDescription] = React.useState<string>("");
  const [scrollX, setScrollX] = React.useState<string | undefined>(undefined);
  const [reportParams, setReportParams] = React.useState<
    reportParameterTypes | undefined
  >(undefined);

  React.useEffect(() => {
    setReport(undefined);

    setTimeout(() => {
      setScrollX(undefined);
      if (reportId !== 0) {
        let selectedReport = reports.find((r) => r.reportId === reportId);
        setReportName(selectedReport?.name ?? "");
        setReportDescription(selectedReport?.description ?? "");

        switch (selectedReport?.name) {
          case "Client Dashboard Reports":
            handleLoadClientDashboardReports(
              JSON.parse(JSON.stringify(selectedReport))
            );
            break;
          case "Benchmark And Comparison Report - By Request Only":
            setReportNoticeDialogOpen(true);
            break;
          case "Annual Report Summary":
          case "Benchmark And Comparison Report":
          case "Claimant Profile":
          case "Credit Due Report":
          case "Executive Summary Report with Individual Employer Breakdowns":
          case "Executive Summary Report":
          case "New Unemployment Claims Report":
          case "Productivity Report":
          case "Unemployment Claims Charge Report":
          case "Unemployment Claims Hearings Report":
          case "Unemployment Claims Summary Report":
            handleSelectedParams();
            break;

          default:
            if (selectedReport?.name === "Fee For Service Billing Export")
              setScrollX("true");
            setReport(selectedReport);
            break;
        }
      }
    }, 500);
  }, [reportId]);

  React.useEffect(() => {
    let selectedReport = reports.find((r) => r.reportId === reportId);

    if (selectedReport !== undefined && reportParams !== undefined) {
      switch (selectedReport?.name) {
        case "Annual Report Summary":
          handleLoadAnnualSummaryReport(
            reportParams as AnnualSummaryReportParametersDto,
            JSON.parse(JSON.stringify(selectedReport))
          );
          break;
        case "Benchmark And Comparison Report":
          handleLoadBenchmarkAndComparisonReport(
            reportParams as BenchmarkAndComparisonReportParametersDto,
            JSON.parse(JSON.stringify(selectedReport))
          );
          break;
        case "Claimant Profile":
          handleLoadClaimantProfileReport(
            reportParams as ClaimantProfileReportParametersDto,
            JSON.parse(JSON.stringify(selectedReport))
          );
          break;
        case "Credit Due Report":
          handleLoadCreditDueReport(
            reportParams as CreditDueReportParametersDto,
            JSON.parse(JSON.stringify(selectedReport))
          );
          break;
        case "Executive Summary Report":
          handleLoadExecutiveSummaryReport(
            reportParams as ExecutiveSummaryReportParametersDto,
            JSON.parse(JSON.stringify(selectedReport))
          );
          break;
        case "Executive Summary Report with Individual Employer Breakdowns":
          handleLoadExecutiveSummaryWithIndividualBreakdownsReport(
            reportParams as ExecutiveSummaryWithIndividualBreakdownsReportParametersDto,
            JSON.parse(JSON.stringify(selectedReport))
          );
          break;
        case "New Unemployment Claims Report":
          handleLoadNewUnemploymentClaimsReport(
            reportParams as NewUnemploymentClaimsReportParametersDto,
            JSON.parse(JSON.stringify(selectedReport))
          );
          break;
        case "Productivity Report":
          handleLoadProductivityReport(
            reportParams as ProductivityReportParametersDto,
            JSON.parse(JSON.stringify(selectedReport))
          );
          break;
        case "Unemployment Claims Charge Report":
        case "Unemployment Claims Hearings Report":
        case "Unemployment Claims Summary Report":
          handleLoadUnemploymentClaimsSummaryReport(
            reportParams as UnemploymentClaimsSummaryReportParametersDto,
            JSON.parse(JSON.stringify(selectedReport))
          );
          break;
      }
    }
  }, [reportParams, reports]);

  React.useEffect(() => {
    let newReports: TelerikReportDto[] = JSON.parse(
      JSON.stringify(systemReports)
    );
    setReports(newReports);
  }, [systemReports]);

  React.useEffect(() => {
    dispatch({ type: getType(getTelerikReportsActions.request) });
    dispatch({ type: getType(getCompaniesHsActions.request) });
    dispatch({ type: getType(getHealthSystemsActions.request) });
    dispatch({ type: getType(getMhaClaimsContactsActions.request) });
  }, [dispatch]);

  const handleSelectedParams = () => {
    setReportParamsDialogOpen(true);
  };

  const handleSetParamsDialog = (params: reportParameterTypes) => {
    setReportParamsDialogOpen(false);
    setReportParams(params);
    console.log("");
    console.log(params);
  };

  const handleCancelDialog = () => {
    setReportParamsDialogOpen(false);
    setReport(undefined);
    setReportId(0);
  };
  const handleCancelNoticeDialog = () => {
    setReportNoticeDialogOpen(false);
    setReport(undefined);
    setReportId(0);
  };

  const handleReset = () => {
    let currentReportId = reportId;
    setReportId(0);
    setReportParams(undefined);
    setTimeout(() => setReportId(currentReportId), 100);
  };

  const handleLoadAnnualSummaryReport = (
    params: AnnualSummaryReportParametersDto,
    report: TelerikReportDto
  ) => {
    report.parameters = {
      YearParameter: params.startYearParameter ?? 0,
      EmployerParameter: params.groups[0].join(","),
      EmployerNameParameter: params.names[0],
      ExcludePriorToPoaParameter: Boolean(params.excludeFiledPriorToPOA),
      ExcludeSecondYearClaimsParameter: Boolean(params.excludeSecondYearClaim),
      BusinessUnitParameter:
        (params.businessUnits ?? []).length === 0
          ? null
          : (params.businessUnits ?? []).join(","),
      EmployeeIdParameter: params.employeeId,
    };

    setReport(report);
  };

  const handleLoadBenchmarkAndComparisonReport = (
    params: BenchmarkAndComparisonReportParametersDto,
    report: TelerikReportDto
  ) => {
    report.parameters = {
      StartYearParameter: params.startYearParameter ?? 0,
      EndYearParameter: params.endYearParameter ?? 0,
      EmployerParameter: params.groups[0].join(","),
      EmployerNameParameter: params.names.join(","),
      PeerGroup1Parameter: params.groups[1].join(","),
      PeerGroup2Parameter: params.groups[2].join(","),
      PeerGroup3Parameter: params.groups[3].join(","),
      PeerGroup4Parameter: params.groups[4].join(","),
    };

    if (params.groups[5] !== undefined) {
      report.parameters.PeerGroup5Parameter = params.groups[5].join(",");
      if (params.groups[6] !== undefined) {
        report.parameters.PeerGroup6Parameter = params.groups[6].join(",");
        if (params.groups[7] !== undefined) {
          report.parameters.PeerGroup7Parameter = params.groups[7].join(",");
          if (params.groups[8] !== undefined) {
            report.parameters.PeerGroup8Parameter = params.groups[8].join(",");
          }
        }
      }
    }

    setReport(report);
  };

  const handleLoadClaimantProfileReport = (
    params: ClaimantProfileReportParametersDto,
    report: TelerikReportDto
  ) => {
    report.parameters = {
      ClaimId: params.claimId,
      DisplayParameter: params.displayParameter,
    };

    setReport(report);
  };

  const handleLoadClientDashboardReports = (report: TelerikReportDto) => {
    let employerString = isAuthorized()
      ? systemHsCompanies.map((m) => m.companyId).join(",")
      : getEmployers().join(",");
    report.parameters = {
      EmployerIdParameter: employerString,
    };

    setReport(report);
  };

  const handleLoadCreditDueReport = (
    params: CreditDueReportParametersDto,
    report: TelerikReportDto
  ) => {
    report.parameters = {
      StartDateParameter: params.startDatetimeParameter,
      EndDateParameter: params.endDatetimeParameter,
      EmployerParameter: params.groups[0].join(","),
      EmployerNameParameter: params.names[0],
      ExcludePriorToPoaParameter: Boolean(params.excludeFiledPriorToPOA),
      ExcludeSecondYearClaimsParameter: Boolean(params.excludeSecondYearClaim),
      BusinessUnitParameter:
        (params.businessUnits ?? []).length === 0
          ? null
          : (params.businessUnits ?? []).join(","),
      EmployeeIdParameter: params.employeeId,
    };

    setReport(report);
  };

  const handleLoadExecutiveSummaryReport = (
    params: ExecutiveSummaryReportParametersDto,
    report: TelerikReportDto
  ) => {
    report.parameters = {
      StartDateParameter: params.startDatetimeParameter,
      EndDateParameter: params.endDatetimeParameter,
      EmployerParameter: params.groups[0].join(","),
      EmployerNameParameter: params.names[0],
      ExcludePriorToPoaParameter: Boolean(params.excludeFiledPriorToPOA),
      ExcludeSecondYearClaimsParameter: Boolean(params.excludeSecondYearClaim),
      HideSectionsParameter: Boolean(params.hideInvestigations),
      BusinessUnitParameter:
        (params.businessUnits ?? []).length === 0
          ? null
          : (params.businessUnits ?? []).join(","),
      EmployeeIdParameter: params.employeeId,
    };

    setReport(report);
  };

  const handleLoadExecutiveSummaryWithIndividualBreakdownsReport = (
    params: ExecutiveSummaryWithIndividualBreakdownsReportParametersDto,
    report: TelerikReportDto
  ) => {
    report.parameters = {
      StartDateParameter: params.startDatetimeParameter,
      EndDateParameter: params.endDatetimeParameter,
      HealthSystemIdsParameter: params.healthSytemsIds.some(
        (s) => s.length !== 0
      )
        ? params.healthSytemsIds.join(",")
        : null,
      HealthSystemGroupIdsParameter: params.groupIds.some((s) => s.length !== 0)
        ? params.groupIds.join(",")
        : null,
      ExcludePriorToPoaParameter: Boolean(params.excludeFiledPriorToPOA),
      ExcludeSecondYearClaimsParameter: Boolean(params.excludeSecondYearClaim),
      HideSectionsParameter: Boolean(params.hideInvestigations),
      EmployerParameter: params.groups[0].some((s) => s.length !== 0)
        ? params.groups[0].join(",")
        : null,
      BusinessUnitParameter:
        (params.businessUnits ?? []).length === 0
          ? null
          : (params.businessUnits ?? []).join(","),
      EmployeeIdParameter: params.employeeId,
    };

    setReport(report);
  };

  const handleLoadNewUnemploymentClaimsReport = (
    params: NewUnemploymentClaimsReportParametersDto,
    report: TelerikReportDto
  ) => {
    report.parameters = {
      StartDateParameter: params.startDatetimeParameter,
      EndDateParameter: params.endDatetimeParameter,
      EmployerParameter: params.groups[0].join(","),
      EmployerNameParameter: params.names[0],
      ExcludePriorToPoaParameter: Boolean(params.excludePrior),
      ExcludeSecondYearClaimsParameter: Boolean(params.excludeSecond),
      BusinessUnitParameter:
        (params.businessUnits ?? []).length === 0
          ? null
          : (params.businessUnits ?? []).join(","),
      EmployeeIdParameter: params.employeeId,
      DisplayParameter: params.display ?? "ssn",
    };

    setReport(report);
  };

  const handleLoadProductivityReport = (
    params: ProductivityReportParametersDto,
    report: TelerikReportDto
  ) => {
    //let hideSections = Boolean(params.summary) && !Boolean(params.detail)
    report.parameters = {
      StartDateParameter: params.startDatetimeParameter,
      EndDateParameter: params.endDatetimeParameter,
      EmployerParameter: params.groups[0].join(","),
      EmployerNameParameter: params.names[0],
      UserIdParameter: params.users,
      HideSectionsParameter: params.summary,
      BusinessUnitParameter:
        (params.businessUnits ?? []).length === 0
          ? null
          : (params.businessUnits ?? []).join(","),
    };

    setReport(report);
  };

  const handleLoadUnemploymentClaimsSummaryReport = (
    params: UnemploymentClaimsSummaryReportParametersDto,
    report: TelerikReportDto
  ) => {
    report.parameters = {
      StartDateParameter: params.startDatetimeParameter,
      EndDateParameter: params.endDatetimeParameter,
      EmployerParameter: params.groups[0].join(","),
      EmployerNameParameter: params.names[0],
      ExcludePriorToPoaParameter: Boolean(params.excludeFiledPriorToPOA),
      ExcludeSecondYearClaimsParameter: Boolean(params.excludeSecondYearClaim),
      BusinessUnitParameter:
        (params.businessUnits ?? []).length === 0
          ? null
          : (params.businessUnits ?? []).join(","),
      EmployeeIdParameter: params.employeeId,
      DisplayParameter: params.display,
    };

    setReport(report);
  };

  return (
    <>
      {reportParamsDialogOpen && (
        <ReportParamsDialog
          open={reportParamsDialogOpen}
          onCancel={handleCancelDialog}
          handleSelectedParams={handleSetParamsDialog}
          reportName={reportName}
          reportDescription={reportDescription}
          systemHealthSystems={systemHealthSystems}
          systemHsCompanies={systemHsCompanies.filter(
            (f) => (f.name ?? "").length !== 0
          )}
          systemMhaClaimsContacts={systemMhaClaimsContacts}
        />
      )}

      {reportnoticeDialogOpen && (
        <ClientReportNoticeDialog
          open={reportnoticeDialogOpen}
          onCancel={handleCancelNoticeDialog}
        />
      )}

      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" display="inline">
              Reports
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={8}
            spacing={2}
            alignContent="center"
            alignItems="center"
          >
            <Grid item xs={10} sm={6}>
              <TextField
                id="reportId"
                size="small"
                margin="none"
                value={reportId || 0}
                InputLabelProps={{ required: true }}
                label="Select Report"
                name="reportId"
                select
                onChange={(e) => {
                  setReportId(parseInt(e.target.value ?? "0"));
                }}
                fullWidth
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
              >
                <MenuItem value={0}>Please select a report.</MenuItem>
                {reports
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((option, index) => (
                    <MenuItem key={option.name + index} value={option.reportId}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={2} sm={3}>
              <Button
                size="large"
                title="Reset reports"
                color="secondary"
                onClick={handleReset}
                data-cy="reports_refresh_button"
              >
                <RefreshIcon fontSize="large" />
              </Button>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Button
                size="large"
                title="Reset reports"
                color="secondary"
                onClick={handleReset}
                data-cy="reports_refresh_button"
              >
                <Link
                  href={downloadReportInstructionUrl}
                  download
                  data-cy="reports-instructions-link"
                >
                  INSTRUCTIONS FOR SORTING REPORT IN EXCEL
                </Link>
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {report !== undefined ? (
              <ReportViewer report={report} scrollx={scrollX} />
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Reports;
