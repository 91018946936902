import React from 'react';
import { Formik } from 'formik';
import {
  Dialog, DialogTitle, Grid, DialogActions,
} from '@material-ui/core';
import { DeleteConfirmationLabel, WideDialogContent } from '../claims.styles';
import { ButtonGrid, StyledCancelButton, StyledSubmitButton } from '../../../common/styles/styles';


interface Props {
  open: boolean;
  onCancel: () => void;
  handleConfirm: () => void;
}

const DuplicateConfirmDialog: React.FC<Props> = (
  {
    open,
    onCancel,
    handleConfirm
  }) => {
  const cancelDialog = () => onCancel();

  return <Formik
    initialValues={{ confirmation: '' }}
    validateOnChange={false}
    validateOnBlur={false}

    onSubmit={(values, { setSubmitting, resetForm }) => {
      setTimeout(() => {
        setSubmitting(false);
        resetForm();
      }, 700);
      if (values.confirmation === '') 
        handleConfirm();
    }}
    enableReinitialize={true}
  >
    {(props) => {
      const {
        isSubmitting,
        handleSubmit,
      } = props;
      return (
        <Dialog
          open={open}
          data-cy="duplicateClaim_dialog"
        >
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <DialogTitle style={{ marginLeft: 4 }}></DialogTitle>
              <DeleteConfirmationLabel style={{ marginLeft: 30 }}>A claim exists with this criteria. Are you sure you want to create a new claim?</DeleteConfirmationLabel>
              <WideDialogContent>
                <form onSubmit={handleSubmit}>
                  <DialogActions>
                    <ButtonGrid container>
                      <StyledSubmitButton
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                        data-cy="duplicate_dialog_submitButton"
                      >Save
                      </StyledSubmitButton>

                      <StyledCancelButton
                        data-cy="duplicate_dialog_cancelButton"
                        color="secondary"
                        onClick={cancelDialog}
                      >Cancel
                      </StyledCancelButton>
                    </ButtonGrid>
                  </DialogActions>
                </form>
              </WideDialogContent>
            </Grid>
          </Grid>
        </Dialog>
      );
    }}
  </Formik>
}

export default DuplicateConfirmDialog;
