 
/* Autogenerated by Typewriter do NOT edit
   Dtos.tst is the generating template */


export interface IUisSiPreviousAssignmentOccurrence  { 
    
    id?: number;
    prevTempAssignmentStartDate?: Date;
    prevTempAssignmentEndDate?: Date;
    prevTempAssignmentJobDuties?: string;
    prevTempAssignmentRateOfPay?: number;
    prevTempAssignmentCommutingDistance?: string;
    prevTempAssignmentHours?: string; 
} 

export class IUisSiPreviousAssignmentOccurrence_PropertyAttributes {
    static readonly id_Attributes = {
      required: { value: true }, name: "Id", 
      };
       static readonly prevTempAssignmentStartDate_Attributes = {
      required: { value: false }, name: "PrevTempAssignmentStartDate", 
      };
       static readonly prevTempAssignmentEndDate_Attributes = {
      required: { value: false }, name: "PrevTempAssignmentEndDate", 
      };
       static readonly prevTempAssignmentJobDuties_Attributes = {
      stringLength: { maxLength: 500, }, required: { value: false }, name: "PrevTempAssignmentJobDuties", 
      };
       static readonly prevTempAssignmentRateOfPay_Attributes = {
      required: { value: false }, name: "PrevTempAssignmentRateOfPay", 
      };
       static readonly prevTempAssignmentCommutingDistance_Attributes = {
      stringLength: { maxLength: 50, }, required: { value: false }, name: "PrevTempAssignmentCommutingDistance", 
      };
       static readonly prevTempAssignmentHours_Attributes = {
      stringLength: { maxLength: 100, }, required: { value: false }, name: "PrevTempAssignmentHours", 
      };
      
}

