import React from 'react';
import {
  Dialog, DialogContent
} from '@material-ui/core';
import ClientPortalQuestionnaire from './clientPortalQuestionnaire';


interface Props {
  token: string;
  open: boolean;
  onCancel: () => void;
}

const QuestionnaireDialog: React.FC<Props> = (
  {
    token,
    onCancel,
    open
  }) => {

  return <Dialog
    open={open}
    data-cy="questionnaireDialog_dialog"
    fullWidth
    maxWidth='xl'
  >
    <DialogContent>
      <ClientPortalQuestionnaire token={token} onCancel={onCancel} />
    </DialogContent>
  </Dialog>
}

export default QuestionnaireDialog;
