import * as Yup from 'yup';
import { IMc } from '../../common/dtos/McDto';
import { ISi } from '../../common/dtos/SiDto';
import { Pension401kReceivedCodes_class } from '../../common/enums/Pension401kReceivedCodes';

const ssnRegex = /^(\d{4}|\d{9}|\d{3}-\d{2}-\d{4})$/;
const sutaRegex = /^(\d{1,20})$/;
export const actionListValidationSchema = Yup.object({
  claimantFirstName: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  claimantLastName: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  claimantSsn: Yup.string()
    .matches(ssnRegex, 'Must be ####, #########, or ###-##-#### format')
    .nullable(),
  employerName: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  employerSuta: Yup.string()
    .matches(sutaRegex, 'Must be 20 digits or less')
    .nullable(),
  allTeamMembers: Yup.boolean().nullable(),
  responsiblePerson: Yup.string()
    .max(200, 'Must be 200 characters or less')
    .nullable(),
  documentStatus: Yup.number().min(0).max(15).nullable(),
  documentType: Yup.number().min(0).max(17).nullable(),
  stateCode: Yup.string().max(2, 'Must be 2 characters or less').nullable(),
  history: Yup.boolean().nullable(),
  pastDueOnly: Yup.boolean().nullable(),
  sortDueDateDesc: Yup.boolean().nullable(),
  sortCreatedDateDesc: Yup.boolean().nullable(),
  pageNumber: Yup.number().nullable(),
});

export interface AssociateDocumentDto {
  id: string;
  claimId: string;
}

export interface DocumentIdDto {
  id: string;
}

export interface ReassignDto {
  mhaContactId: string;
  documents: DocumentIdDto[];
}

// Document Extra
export interface DocumentExtraDto {
  suta?: string;
  fein?: string;
  claimantFirstName?: string;
  claimantLastName?: string;
  claimantJobTitle?: string;
  employerId?: string;
  ssn?: string;
  employerStateCode?: string;
  dateFirstWorked?: Date;
  dateLastWorked?: Date;
  benefitYearBeginingDate?: Date;
  benefitYearEndingDate?: Date;
  documentDate?: Date;
  wagesWeeksNeededCode?: number;
  requestforReliefofChargesAllowedInd?: boolean;
  needsDependants?: boolean;
  wagesNeededBeginDate?: Date;
  wagesNeededEndDate?: Date;
  weeklyBenefitAmount?: string;
  potentialLiability?: string;
  sidesFormat?: string;
  basePeriodStartDate?: Date;
  basePeriodEndDate?: Date;
}

//Sides
export interface UiAttachment {
  fileName: string; // string maxLength: 500minLength: 0nullable: true
  fileUpload?: File; //	string($binary) nullable: true FILE
}

export interface UiGeneral {
  state?: string;
  claimantSuffix?: string; //string maxLength: 200 minLength: 0 nullable: true
  claimantOtherLastName?: string; //string maxLength: 200 minLength: 0 nullable: true
  responseDue?: Date; //string($date - time) nullable: true
  fein?: string; //maxLength: 200 minLength: 0 nullable: true
  employmentPeriod?: number;
  typeOfClaim?: number;
  formNumber?: string; //maxLength: 200 minLength: 0 nullable: true
  claimEffectiveDate?: Date; //	string($date - time) nullable: true
  claimNumber?: string; //maxLength: 200 minLength: 0nullable: true
  uiOfficeName?: string; //maxLength: 200 minLength: 0nullable: true
  uiOfficePhone?: string; //maxLength: 200 minLength: 0 nullable: true
  uiOfficeFax?: string; //maxLength: 200 minLength: 0 nullable: true
  isClaimantWorkingAllAvailableHours?: boolean;
  claimantWorkingAllAvailableHoursComment?: string; //maxLength: 2000 minLength: 0nullable: true
  separationEffectiveDate?: Date;
  returnToWorkDate?: Date; //string($date - time) nullable: true
  separationReasonId?: number; //	SidesDocTypesinteger($int32)Enum: Array[23]
  isSeasonal?: boolean;
  isReasonableAssurance?: boolean;
  isLaborDisputeStrike?: boolean;
  isLaborDisputeLockout?: boolean;
  isMandatoryRetirement?: boolean;
  claimantSeparationReasonComment?: string; //string maxLength: 2000minLength: 0nullable: true
  employerSeparationReasonComment?: string; //maxLength: 2000 minLength: 0 nullable: true
  amendedResponseComment?: string; //string maxLength: 2000minLength: 0nullable: true
  isReadyToRespond: boolean;
  attachments: UiAttachment[];
  requestDate?: Date;
  respondedDateTime?: Date;
}

export interface UiIncident {
  id?: string;
  priorIncidentDate?: Date; //string($date - time) nullable: true
  priorIncident?: string; //string maxLength: 200 minLength: 0 nullable: true
  priorIncidentComment?: string; //string maxLength: 2000 minLength: 0 nullable: true
  isWarningIssued?: boolean;
  warningIssuedDate?: Date; //string($date - time) nullable: true
  warningComment?: string; //string maxLength: 2000 minLength: 0 nullable: true
}

export interface UiDischarge {
  dischargeReason: number; //SidesDischargeReasoninteger($int32) Enum: Array[8]
  isViolationOfCompanyPolicy?: boolean;
  isClaimantAwareOfPolicy?: boolean;
  awareOfPolicyOption: number; //SidesAwareOfPolicyOptionsinteger($int32) Enum: Array[4]
  finalIncidentDate?: Date; //tring($date - time) nullable: true
  finalIncidentComment?: string; //string maxLength: 2000 minLength: 0 nullable: true
  whoDischarged?: string; //string maxLength: 200 minLength: 0nullable: true
  whoDischargedTitle?: string; //maxLength: 200 minLength: 0 nullable: true
  additionalDischargeComment?: string; //maxLength: 2000 minLength: 0 nullable: true
  incidents: UiIncident[];
}

export interface UiVoluntaryQuit {
  quitReasonId: number; //SidesQuitReasonsinteger($int32) Enum: Array[10]
  isContinuingWorkAvailable?: boolean;
  reasonForQuitComment?: string; //maxLength: 2000 minLength: 0 nullable: true
  hiringAgreementChangeId: number; //SidesHiringAgreementChangeOptionsinteger($int32) Enum: Array[7]
  descriptionOfChangeComment?: string; // maxLength: 2000 minLength: 0 nullable: true
  didClaimantTookActionToAvoidQuitting?: boolean;
  descriptionOfActionComment?: string; //string maxLength: 2000 minLength: 0 nullable: true
}

export interface UiPay {
  id?: string;
  payTypeId?: number; //SidesPayTypesinteger($int32) Enum: Array[15]
  amountPerPeriod?: string;
  periodId?: number; //SidesPeriodOptionsinteger($int32) Enum: Array[8]
  issuedDate?: Date; //	string($date - time) nullable: true
  periodAllocatedFromDate?: Date; //string($date - time) nullable: true
  periodAllocatedToDate?: Date; //string($date - time) nullable: true
}

export interface UiPayments {
  totalEarnedWagesNeededInd?: number;
  totalWeeksWorkedNeededInd?: number;
  requestedWageFromDate?: Date; //	string($date - time) nullable: true
  requestedWageToDate?: Date; //string($date - time) nullable: true
  totalEarnedWages?: string; //string maxLength: 200 minLength: 0 nullable: true
  totalWeeksWorked?: string; //string maxLength: 200 minLength: 0 nullable: true
  wagesAfterClaimDate?: string; //string maxLength: 200 minLength: 0 nullable: true
  averageWeeklyWages?: string; //string maxLength: 200 minLength: 0 nullable: true
  hoursWorkedAfterClaimDate?: string; //string maxLength: 200 minLength: 0 nullable: true
  averageWeeklyHours?: string; //string maxLength: 200 minLength: 0 nullable: true
  payList: UiPay[];
  percentClaimantContributedToPension?: string; //string maxLength: 200 minLength: 0 nullable: true
  pensionComment?: string; //string maxLength: 2000 minLength: 0 nullable: true
}

export interface UiMc {
  requestforReliefofChargesAllowedInd?: boolean | null;
  employerReportedDependents?: string;
  employerCorrectedWageforTotalBasePeriod?: string;
  employerCorrectedWageforFirstQuarter?: string;
  employerCorrectedWageforSecondQuarter?: string;
  employerCorrectedWageforThirdQuarter?: string;
  employerCorrectedWageforFourthQuarter?: string;
}

export interface UiSidesDto {
  general: UiGeneral;
  discharge: UiDischarge;
  voluntaryQuit: UiVoluntaryQuit;
  payments: UiPayments;
  extra?: DocumentExtraDto;
  mc: UiMc;
  type: string;
}

//Actual documents dto
export interface DocumentsDto {
  id?: string;
  label?: string; //ie. Smith; David - MercyOne North Iowa on W310 - Action List
  claimId?: string; //($uuid) nullable: true
  type: number; //DocTypesinteger($int32)Enum:  Array [ 18 ]
  status: number; //DocStatusTypesinteger($int32) Enum:   Array [ 15 ]
  assignedId?: string; //($uuid)
  isComplete: boolean;
  fileName?: string; //maxLength: 500 minLength: 0    nullable: true
  sidesId?: string; //maxLength: 500 minLength: 0   nullable: true
  sidesFormat?: string; //maxLength: 500 minLength: 0   nullable: true
  notes?: string; //maxLength: 2000 minLength: 0 nullable: true
  isInternal: boolean;
  dueDate?: Date; //($date-time)   nullable: true
  employerName?: string; //maxLength: 500 minLength: 0   nullable: true
  employeeId?: string; //maxLength: 200 minLength: 0   nullable: true
  suta?: string; // maxLength: 500 minLength: 0 nullable: true
  stateCode?: string; //maxLength: 3 minLength: 0 nullable: true
  claimantSsn?: string; //maxLength: 9 minLength: 0    nullable: true
  claimantFirstName?: string;
  claimantLastName?: string;
  dateReceived?: Date;
  dateIndexed?: Date;
  uploadedBy?: string;
  fileUpload?: File;
  isSelected?: boolean;
  uiSides?: UiSidesDto;
  extra?: DocumentExtraDto;
  benefitYearEndingDate?: Date;
  benefitYearBeginningDate?: Date;
  questionnaireToken?: string;
  isQuestionnaireComplete?: boolean;
  assignedContact?: string;
  repFullName?: string;
  respondedDateTime?: Date;
}

export interface ActionsRequestDto {
  claimantFirstName?: string;
  claimantLastName?: string;
  claimantSsn?: string;
  employerName?: string;
  employerSuta?: string;
  employeeId?: string;
  responsiblePerson?: string;
  documentStatus?: number;
  documentType?: number;
  history: boolean;
  pastDueOnly: boolean;
  pageNumber: number;
  allTeamMembers?: boolean;
  stateCode?: string;
  sortDueDateDesc?: boolean;
  sortCreatedDateDesc?: boolean;
}

export interface ActionsFilterDto {
  claimantFirstName?: string;
  claimantLastName?: string;
  claimantSsn?: string;
  employerName?: string;
  employerSuta?: string;
  employeeId?: string;
  responsiblePerson?: string;
  documentStatus?: number;
  documentType?: number;
  history: boolean;
  pastDueOnly: boolean;
  stateCode?: string;
}

export interface ActionItemsDto {
  page: number;
  total: number;
  baseUrl: string;
  documents?: DocumentsDto[];
}

export interface ClaimDocumentRequestDto {
  claimId?: string;
  documents: DocumentsDto[];
}

export interface sortByModel {
  createdDate: boolean;
  dueDate: boolean;
  employer: boolean;
}

export const sortByModelInit: sortByModel = {
  dueDate: false,
  createdDate: false,
  employer: false,
};

export const ActionsFilterInit: ActionsFilterDto = {
  history: false,
  pastDueOnly: false,
};

export const DocumentsInit: DocumentsDto = {
  type: 0, //DocTypesinteger($int32)Enum:  Array [ 18 ]
  status: 0, //DocStatusTypesinteger($int32) Enum:   Array [ 15 ]
  isComplete: false,
  isInternal: false,
  dateReceived: new Date(),
  dueDate: new Date(),
  dateIndexed: new Date(),
  fileName: '',
};

export const actionsRequestInit: ActionsRequestDto = {
  claimantFirstName: '',
  claimantLastName: '',
  claimantSsn: '',
  employerName: '',
  employerSuta: '',
  employeeId: '',
  history: false,
  pastDueOnly: false,
  pageNumber: 1,
};

export const ActionItemsInit: ActionItemsDto = {
  page: 1,
  total: 1,
  baseUrl: '',
  documents: [DocumentsInit],
};

export const UiAttachmentInit: UiAttachment = {
  fileName: '',
};

export const UiGeneralInit: UiGeneral = {
  //separationReasonId: 0, //	SidesDocTypesinteger($int32)Enum: Array[23]
  isReadyToRespond: false,
  attachments: [],
};

export const UiIncidentInit: UiIncident = {
  priorIncident: '',
  priorIncidentComment: '',
  isWarningIssued: false,
  warningComment: '',
};

export const UiDischargeInit: UiDischarge = {
  dischargeReason: 0, //SidesDischargeReasoninteger($int32) Enum: Array[8]
  //isViolationOfCompanyPolicy: false,
  //isClaimantAwareOfPolicy: false,
  awareOfPolicyOption: 0, //SidesAwareOfPolicyOptionsinteger($int32) Enum: Array[4]
  incidents: [],
};

export const UiVoluntaryQuitInit: UiVoluntaryQuit = {
  quitReasonId: 0, //SidesQuitReasonsinteger($int32) Enum: Array[10]
  //isContinuingWorkAvailable: false,
  hiringAgreementChangeId: 0, //SidesHiringAgreementChangeOptionsinteger($int32) Enum: Array[7]
  //didClaimantTookActionToAvoidQuitting: false,
};

export const UiPayInit: UiPay = {
  payTypeId: 0,
  amountPerPeriod: '',
  periodId: 0,
};

export const UiPaymentsInit: UiPayments = {
  payList: [],
};

export const UiSidesInit: UiSidesDto = {
  general: UiGeneralInit,
  discharge: UiDischargeInit,
  voluntaryQuit: UiVoluntaryQuitInit,
  payments: UiPaymentsInit,
  mc: {},
  type: '',
};

export const SiInit: ISi = {
  receivingWorkersCompensationInd: 1,
  receivePension401Kind: Pension401kReceivedCodes_class.enum.Item4,
  uisSiAttachments: [],
  uisSiContactOccurrences: [],
  uisSiFailedToReportOccurrences: [],
  uisSiPension401Koccurrences: [],
  uisSiPreviousAssignmentOccurrences: [],
  uisSiPriorIncidentOccurrences: [],
  uisSiRemunerationOccurrences: [],
  uisSiWitnessInformationOccurrences: [],
};

export const McInit: IMc = {
  uisMcAttachments: [],
  uisMcEmployerCharges: [],
};

// Options
export const employmentPeriodOptions = [
  { name: '', id: 0 },
  { name: 'Last, Base Period, and Lag Quarter', id: 1 },
  { name: 'Last Employer', id: 2 },
  { name: 'Base Period Employer', id: 3 },
  { name: 'Lag Quarter Employer', id: 4 },
  { name: 'Last and Base Period Employer', id: 5 },
  { name: 'Last and Lag Quarter Employer', id: 6 },
  { name: 'Base Period and Lag Quarter Employer', id: 7 },
];

export const typeOfClaimOptions = [
  { name: 'New Initial Claim', id: 0 },
  { name: 'Additional Claim', id: 1 },
];

export const separationReasons = [
  { name: '1.  Temporary Layoff', id: 0 },
  { name: '2.  Laid Off/Lack of Work', id: 1 },
  { name: '3.  Fired/Discharged', id: 2 },
  { name: '4.  Vacation/Holiday Shutdown', id: 3 },
  { name: '5.  Asked To Resign', id: 4 },
  { name: '6.  Voluntary Quit/Separation', id: 5 },
  { name: '7.  School Employee Between Terms, Likely To Return', id: 6 },
  { name: '8.  School Employee Between Terms, Not Likely To Return', id: 7 },
  { name: '9.  Still Employed, Full Time', id: 8 },
  { name: '10. Still Employed, Part Time', id: 9 },
  { name: '11. Still Employed, Hours Reduced By Employer', id: 10 },
  { name: '12. On Call or Temporary Status', id: 11 },
  { name: '13. Leave of Absence', id: 12 },
  { name: '14. Retirement', id: 13 },
  { name: '15. Disciplinary Suspension', id: 14 },
  { name: '16. Labor Dispute', id: 15 },
  { name: '17. Professional Athlete Between Sports Season', id: 16 },
  { name: '18. Disaster Related Separation', id: 17 },
  { name: '19. Other', id: 18 },
  { name: '20. Never Employed Here', id: 19 },
  { name: '21. TPA Does Not Represent This Employer', id: 20 },
  { name: '99. Not Provided', id: 21 },
];

export const dischargeReasons = [
  { name: '', id: 0 },
  { name: 'Not Qualified', id: 1 },
  { name: 'Absenteeism/Lateness', id: 2 },
  { name: 'Failed To Follow Instructions/Policy/Contract', id: 3 },
  { name: 'Drugs and Alcohol', id: 4 },
  { name: 'Dishonesty/Theft/Violation of Law/Fighting', id: 5 },
  { name: 'Medical', id: 6 },
  { name: 'Loss of License', id: 7 },
  { name: 'Not Listed Above', id: 8 },
];

export const awareOfPolicyOptions = [
  //{ name: '', id: 0 },
  { name: 'Verbal', id: 1 },
  { name: 'Written', id: 2 },
  { name: 'Both', id: 3 },
];

export const payTypeOptions = [
  { name: '', id: 0 },
  { name: 'Severance', id: 1 },
  { name: 'Separation', id: 2 },
  { name: 'Vacation', id: 3 },
  { name: 'Holiday/Floating Holiday', id: 4 },
  { name: 'Pension', id: 5 },
  { name: 'Profit Sharing', id: 6 },
  { name: 'Bonus Pay', id: 7 },
  { name: 'Wages in Lieu of Notice', id: 8 },
  { name: 'Back Pay Award', id: 9 },
  { name: 'Residual Payments', id: 10 },
  { name: 'Commissions', id: 11 },
  { name: 'Sick Pay', id: 12 },
  { name: 'Disability Pay', id: 13 },
  { name: 'Other', id: 14 },
];

export const payPeriodOptions = [
  { name: '', id: 0 },
  { name: 'Weekly', id: 1 },
  { name: 'Biweekly', id: 2 },
  { name: 'Monthly', id: 3 },
  { name: 'Quarterly', id: 4 },
  { name: 'Lump Sum', id: 5 },
  { name: 'Daily', id: 6 },
  { name: 'Semi Monthly', id: 7 },
];

export const reasonForQuitOptions = [
  { name: '', id: 0 },
  { name: 'No reason given', id: 1 },
  { name: 'To seek or accept other employment', id: 2 },
  { name: 'Due to health reasons', id: 3 },
  { name: 'Due to job dissatisfaction', id: 4 },
  { name: 'In lieu of discharge', id: 5 },
  { name: 'Personal reasons', id: 6 },
  { name: 'Failure to report for work/abandonment', id: 7 },
  { name: 'Retirement', id: 8 },
  { name: 'Working conditions', id: 9 },
  { name: 'Not Listed Above', id: 10 },
];

export const hiringAgreementChangeOptions = [
  { name: '', id: 0 },
  { name: 'No change', id: 1 },
  { name: 'Hours changed', id: 2 },
  { name: 'Job duties changed', id: 3 },
  { name: 'Rate of pay changed', id: 4 },
  { name: 'Changed job location', id: 5 },
  { name: 'Other', id: 6 },
];
