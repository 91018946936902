import moment from 'moment';
import * as Yup from 'yup';
import {
  minDateError,
  maxDateError,
  maxError,
  maxNumError,
  notRequiredError,
  requiredError,
} from '../../common/constants/strings';
import { ISi } from '../../common/dtos/SiDto';
import { IUisSiContactOccurrence } from '../../common/dtos/UisSiContactOccurrenceDto';
import { IUisSiFailedToReportOccurrence } from '../../common/dtos/UisSiFailedToReportOccurrenceDto';
import { IUisSiPension401Koccurrence } from '../../common/dtos/UisSiPension401KoccurrenceDto';
import { IUisSiPreviousAssignmentOccurrence } from '../../common/dtos/UisSiPreviousAssignmentOccurrenceDto';
import { IUisSiPriorIncidentOccurrence } from '../../common/dtos/UisSiPriorIncidentOccurrenceDto';
import { IUisSiRemunerationOccurrence } from '../../common/dtos/UisSiRemunerationOccurrenceDto';
import { ContributionCodes_class } from '../../common/enums/ContributionCodes';
import { FrequencyCodes_class } from '../../common/enums/FrequencyCodes';
import { Pension401kCodes_class } from '../../common/enums/Pension401kCodes';
import { Pension401kReceivedCodes_class } from '../../common/enums/Pension401kReceivedCodes';
import { RemunerationTypeCodes_class } from '../../common/enums/RemunerationTypeCodes';
import { VoluntarySepReasonCodes_class } from '../../common/enums/VoluntarySepReasonCodes';
import {
  isActCommittedViolatedLaw,
  isActionTakenComments,
  isAdverseEffectOnEmployer,
  isAgreedReturnToWorkDateInd,
  isAltercationOnCompanyPropertyInd,
  isAltercationOnCompanyTimeInd,
  isAmendedResponseDescription,
  isAskedToTestInd,
  isAttachmentInd,
  isAverageNumberofHoursWorkedperWeek,
  isChangesPermanentInd,
  isChangesScheduledToLast,
  isClaimantActionsToAvoidQuitInd,
  isClaimantAdmissionInd,
  isClaimantAvoidAltercationInd,
  isClaimantContactToExplainInd,
  isClaimantNoticeInd,
  isClaimantNotifyOfMedicalIssue,
  isClaimantPaidThroughNoticeInd,
  isClaimantProvidedIntendedLastDayWork,
  isClaimantProvidedIntendedLastDayWorkUnknown,
  isClaimantReleasedToReturnToWork,
  isClaimantRequestMedicalLoa,
  isClaimantStartAltercationInd,
  isClaimantWorkOutNoticeInd,
  isClaimantWorkRestrictions,
  isClmtStrikingUnionMemberInd,
  isContinuingWorkAvailableInd,
  isCustomaryWeeklyWorkSchedule,
  isDamagesReportedBy,
  isDateAppliedForWorkersComp,
  isDateAppliedForWorkersCompKnownInd,
  isDateClaimantReleased,
  isDateDisputeBegan,
  isDateDisputeEnded,
  isDateMadeContact,
  isDateNoticeGiven,
  isDateNoticeGivenUnknown,
  isDateOfInjury,
  isDischargePolicyAwareExplanationCode,
  isDischargePolicyAwareInd,
  isDischargeReasonCode,
  isDischargeReasonComments,
  isDollarValue,
  isDutiesClaimantCouldNotPerform,
  isEffectiveDateOfChanges,
  isEffectiveSeparationDate,
  isEmployerAllocationInd,
  isEmployerDamage,
  isEmployerReportedClaimantLastDayofWork,
  isEmployerSepReasonCode,
  isEmployerSepReasonComments,
  isFinalIncidentDate,
  isFinalIncidentReason,
  isFirstDayOfBreak,
  isFirstDayOfContract,
  isFraudComments,
  isFraudReasons,
  isFullAmount401KrolledOver,
  isHaveAuthorityToOfferInd,
  isHiringAgreementChangesCode,
  isHiringAgreementChangesComments,
  isHowOfferConveyed,
  isInadequatePerfReasonInd,
  isInLieuInd,
  isJobChangeInd,
  isLaborDisputeEndedInd,
  isLaborDisputeTypeInd,
  isLastDayOfBreak,
  isLastDayOfContract,
  isLoaapprovedInd,
  isMandatoryPension,
  isMandatoryRetirementInd,
  isMedicalLoaavailable,
  isMedicalStatementProvidedInd,
  isMetPastExpections,
  isNewContractJobOfferInd,
  isNewTempAssignmentCommutingDistance,
  isNewTempAssignmentDateOfferMade,
  isNewTempAssignmentDateRefused,
  isNewTempAssignmentHours,
  isNewTempAssignmentJobDuties,
  isNewTempAssignmentRateOfPay,
  isNewTempAssignmentStartDate,
  isNewTempAssignmentWhyRefused,
  isNotWorkingAvailableHoursReason,
  isNotWorkingOnAssignment,
  isOtherWorkOfferedInd,
  isPaidDuringSuspensionInd,
  isPaidOut401KtoClaimant,
  isPercentage401KrolledOver,
  isPercentage401KrolledOverUnknown,
  isPersonOfferedReasonableAssurance,
  isPositionReturningTo,
  isPriorIncidentInd,
  isQuitUnderAdviceOfHealthcareProvider,
  isRateofPayPerPeriod,
  isReasonDissatisfied,
  isReasonForBreak,
  isReasonForFailToReport,
  isReasonForFinalAbsence,
  isReceiveNormalRateOfPayInd,
  isReceivePension401Kind,
  isRefuseToProvideInd,
  isRegularRateofPay,
  isRelationshipEndedInd,
  isRemunerationInd,
  isReplacementWorkersHiredInd,
  isReturnToWorkDate,
  isReturnToWorkDateInd,
  isReturnToWorkInd,
  isSeparationDateInd,
  isTempAgencyPolicyInd,
  isTempAssignmentClaimantContactAgencyInd,
  isTempAssignmentLastDay,
  isTempAssignmentWhoEnded,
  isTempAssignmentWhyNotCompleted,
  isTempAssignmentWorkAvailableInd,
  isTempPolicyAwarenessCode,
  isTestPerformedInd,
  isTotalEarnedWages,
  isTotalEarnedWagesNeededInd,
  isTotalWeeksWorked,
  isTotalWeeksWorkedNeededInd,
  isTypeOfWorkersCompReceiving,
  isUisSiFailedToReportOccurrences,
  isUisSiPension401Koccurrences,
  isUisSiPreviousAssignmentOccurrences,
  isUisSiPriorIncidentOccurrences,
  isUisSiRemunerationOccurrences,
  isUisSiWitnessInformationOccurrences,
  isValueAttachedToActInd,
  isViolateCompanyPolicyInd,
  isVoluntarySepReasonCode,
  isVoluntarySepReasonComments,
  isWereThereWitnessesInd,
  isWhatConditionsChanged,
  isWhatWasStolen,
  isWhoDischargedName,
  isWhoDischargedTitle,
  isWhoReducedHours,
  isWhyContractNotOffered,
  isWhyLeaveOfAbsence,
  isWhyLoadenied,
  isWhyNoticeWasntWorkedOut,
  isWhyNotPerformed,
  isWhyNotReturning,
  isWhyRequestedToTest,
  isWhyRetireComments,
  isWhyUnemployedLaborDispute,
  isWorkAvailableWithinRestrictionsInd,
  isWorkingAllAvailableHoursInd,
  isWorkingConditions,
  isWorkingConditionsChangedInd,
  isWorkingNewAssignmentInd,
  isWorkRestrictionsInd,
  opChainOfCustodyInd,
  opClaimantJobTitle,
  opClmtMemberAnotherUnionInd,
  opContinuingWorkAvailableInd,
  opContractAccepted,
  opContractEmployee1099Ind,
  opDischargeReasonComments,
  opDollarValue,
  opEmployerAccommodateRestrictionsInd,
  opEmployerCity,
  opEmployerContactName,
  opEmployerReportedClaimantFirstDayofWork,
  opEmployerReportedClaimantLastDayofWork,
  opEmployerSepReasonComments,
  opEmployerState,
  opEmployerStreetAddress,
  opEmployerZip,
  opFirstDayOfBreak,
  opFraudInd,
  opHiringAgreementChangesCode,
  opHowSettlementAffects,
  opLastDayOfBreak,
  opLocationOfLaborDispute,
  opNewContractJobOfferInd,
  opNumberOfHoursWorkedAfterClaimEffectiveDate,
  opPeriodOfTimeWorkersCompPaymentCovers,
  opReceivingWorkersCompensationInd,
  opRepContractTermDate,
  opSeasonalEmploymentInd,
  opSecondTestSplitSampleGiven,
  opSecondTestSplitSampleResults,
  opSeparationDateInd,
  opSettlementAffectClmtInd,
  opTemporaryStaffingInd,
  opTesterCompanyName,
  opTesterName,
  opTestResults,
  opUisSiContactOccurrences,
  opUnionNameAndLocalNo,
  opViolateCompanyPolicyInd,
  opVoluntarySepReasonComments,
  opWeeklyWorkersCompRate,
  opWhoPerformedTest,
  opWhyEmployerCouldNotAccommodate,
  opWhyRetireComments,
  opWorkContinueDuringBreakInd,
  opWorkersCompComments,
} from '../../utils';

/*
 * Yup Validation Schema based on
 * G:\.shortcut-targets-by-id\0B4pGxV3b_knZRlptUWtUR0xfdm8\Clients\Michigan Health and Hospital Association\Unemployment Claims Processing - 24866 25550\Design Specs\R4 - SIDES Re-write\SIDES Separation Information Standard Format.docx
 * Comments after values in mcValidationSchema reference that the value is mapped through the
 * GetSafeString() method at Orca.Application\Helpers\Mapper\UiSides\McMapProfile.cs:202
 * The method throws an error if the string doesn't match the regex pattern there.
 */

interface Options extends Yup.ValidateOptions {
  originalValue: any;
  context: ISi;
}

export const uisSiWitnessInformationOccurrencesSchema = Yup.object().shape({
  witnessName: Yup.string()
    .max(60, maxError)
    .nullable()
    .test('witnessName', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined && values.wereThereWitnessesInd === 0)
        return this.createError({ message: requiredError });
      return true;
    }),
  witnessJobTitle: Yup.string()
    .max(30, maxError)
    .nullable()
    .test('witnessJobTitle', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined && values.wereThereWitnessesInd === 0)
        return this.createError({ message: requiredError });
      return true;
    }),
});
export const uisSiPriorIncidentOccurrencesSchema = Yup.object().shape({
  priorIncidentDate: Yup.date()
    .nullable()
    .test('priorIncidentDate', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined) return this.createError({ message: requiredError });

      //Check Date
      let checkHighDate = moment(values.finalIncidentDate);
      let checkLowDate = moment(values.requestDate).add(-7, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  priorIncidentReason: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('priorIncidentReason', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined) return this.createError({ message: requiredError });
      return true;
    }),
  priorIncidentWarningInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('priorIncidentWarningInd', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined) return this.createError({ message: requiredError });
      return true;
    }),
  priorIncidentWarningDate: Yup.date()
    .nullable()
    .test('priorIncidentWarningDate', '', function (v) {
      const { priorIncidentWarningInd, priorIncidentDate } = this
        .parent as IUisSiPriorIncidentOccurrence;
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined && priorIncidentWarningInd === 0)
        return this.createError({ message: requiredError });
      if (v !== undefined && (priorIncidentWarningInd ?? 1) === 1)
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(values.requestDate);
      let checkLowDate = moment(priorIncidentDate);
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });

      return true;
    }),
  priorIncidentWarningDescription: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('priorIncidentWarningDescription', '', function (v) {
      const { priorIncidentWarningInd } = this
        .parent as IUisSiPriorIncidentOccurrence;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined && (priorIncidentWarningInd ?? 1) === 0)
        return this.createError({ message: requiredError });
      if (v !== undefined && (priorIncidentWarningInd ?? 1) === 1)
        return this.createError({ message: notRequiredError });
      return true;
    }),
});
export const uisSiFailedToReportOccurrencesSchema = Yup.object().shape({
  dateFailedToReport: Yup.date()
    .nullable()
    .test('dateFailedToReport', '', function (v) {
      const { dateFailedtoReportUnknown } = this
        .parent as IUisSiFailedToReportOccurrence;
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (
        v === undefined &&
        values.voluntarySepReasonCode ===
          VoluntarySepReasonCodes_class.enum.Item7 &&
        dateFailedtoReportUnknown !== 0
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        values.voluntarySepReasonCode !==
          VoluntarySepReasonCodes_class.enum.Item7 &&
        dateFailedtoReportUnknown === 0
      )
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment();
      let checkLowDate = moment().add(-104, 'weeks');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (v !== undefined && moment(v).diff(checkHighDate) > 0)
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  dateFailedtoReportUnknown: Yup.number()
    .max(0, maxNumError)
    .nullable()
    .test('dateFailedtoReportUnknown', '', function (v) {
      const { dateFailedToReport } = this
        .parent as IUisSiFailedToReportOccurrence;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (
        v === undefined &&
        values.voluntarySepReasonCode ===
          VoluntarySepReasonCodes_class.enum.Item7 &&
        dateFailedToReport === undefined
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        values.voluntarySepReasonCode !==
          VoluntarySepReasonCodes_class.enum.Item7 &&
        dateFailedToReport !== undefined
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
});
export const uisSiPreviousAssignmentOccurrencesSchema = Yup.object().shape({
  prevTempAssignmentStartDate: Yup.date()
    .nullable()
    .test('prevTempAssignmentStartDate', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined) return this.createError({ message: requiredError });

      //Check Date
      let checkHighDate = moment();
      let checkLowDate = moment().add(-100, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (v !== undefined && moment(v).diff(checkHighDate) > 0)
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  prevTempAssignmentEndDate: Yup.date()
    .nullable()
    .test('prevTempAssignmentEndDate', '', function (v) {
      const { prevTempAssignmentStartDate } = this
        .parent as IUisSiPreviousAssignmentOccurrence;
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined) return this.createError({ message: requiredError });

      //Check Date
      let checkHighDate = moment();
      let checkLowDate = moment(prevTempAssignmentStartDate);
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  prevTempAssignmentJobDuties: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('prevTempAssignmentJobDuties', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined) return this.createError({ message: requiredError });
      return true;
    }),
  prevTempAssignmentRateOfPay: Yup.number()
    .max(999999999999999, maxNumError)
    .nullable()
    .test('prevTempAssignmentRateOfPay', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined) return this.createError({ message: requiredError });
      return true;
    }),
  prevTempAssignmentCommutingDistance: Yup.string()
    .max(50, maxError)
    .nullable()
    .test('prevTempAssignmentCommutingDistance', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined) return this.createError({ message: requiredError });
      return true;
    }),
  prevTempAssignmentHours: Yup.string()
    .max(100, maxError)
    .nullable()
    .test('prevTempAssignmentHours', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined) return this.createError({ message: requiredError });
      return true;
    }),
});
export const uisSiRemunerationOccurrencesSchema = Yup.object().shape({
  remunerationTypeCode: Yup.number()
    .max(15, maxNumError)
    .nullable()
    .test('remunerationTypeCode', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined) return this.createError({ message: requiredError });
      return true;
    }),
  employerAllocationInd: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('employerAllocationInd', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined && isEmployerAllocationInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isEmployerAllocationInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  allocationBeginDate: Yup.date()
    .nullable()
    .test('allocationBeginDate', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const { employerAllocationInd, allocationBeginDateUnknownInd } = this
        .parent as IUisSiRemunerationOccurrence;
      if (
        v === undefined &&
        employerAllocationInd === 0 &&
        allocationBeginDateUnknownInd === undefined
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        (employerAllocationInd !== 0 ||
          allocationBeginDateUnknownInd !== undefined)
      )
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(values.requestDate).add(2, 'years');
      let checkLowDate = moment(values.claimEffectiveDate).add(-10, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  allocationBeginDateUnknownInd: Yup.number()
    .max(0, maxNumError)
    .nullable()
    .test('allocationBeginDateUnknownInd', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const { employerAllocationInd, allocationBeginDate } = this
        .parent as IUisSiRemunerationOccurrence;
      if (
        v === undefined &&
        employerAllocationInd === 0 &&
        allocationBeginDate === undefined
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        (employerAllocationInd !== 0 || allocationBeginDate !== undefined)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  allocationEndDate: Yup.date()
    .nullable()
    .test('allocationEndDate', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const {
        employerAllocationInd,
        allocationEndDateUnknownInd,
        allocationBeginDate,
      } = this.parent as IUisSiRemunerationOccurrence;
      if (
        v === undefined &&
        employerAllocationInd === 0 &&
        allocationEndDateUnknownInd === undefined
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        (employerAllocationInd !== 0 ||
          allocationEndDateUnknownInd !== undefined)
      )
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(values.requestDate).add(10, 'years');
      let checkLowDate =
        allocationBeginDate !== undefined
          ? moment(allocationBeginDate)
          : moment(values.claimEffectiveDate).add(-10, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  allocationEndDateUnknownInd: Yup.number()
    .max(0, maxNumError)
    .nullable()
    .test('allocationEndDateUnknownInd', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const { employerAllocationInd, allocationEndDate } = this
        .parent as IUisSiRemunerationOccurrence;
      if (
        v === undefined &&
        employerAllocationInd === 0 &&
        allocationEndDate === undefined
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        (employerAllocationInd !== 0 || allocationEndDate !== undefined)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  remunerationPeriodFrequencyCode: Yup.number()
    .max(8, maxNumError)
    .nullable()
    .test('remunerationPeriodFrequencyCode', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined) return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        values.allocationRequiredInd === 1 &&
        v !== FrequencyCodes_class.enum.L &&
        v !== FrequencyCodes_class.enum.Z
      )
        return this.createError({
          message: 'Must be Lump Sum or Not Able to Provide',
        });
      return true;
    }),
  remunerationAmountPerPeriod: Yup.number()
    .max(999999999999999, maxNumError)
    .nullable()
    .test('remunerationAmountPerPeriod', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const { remunerationPeriodFrequencyCode } = this
        .parent as IUisSiRemunerationOccurrence;
      let FC = FrequencyCodes_class.enum;
      if (
        v === undefined &&
        remunerationPeriodFrequencyCode !== undefined &&
        [FC.D, FC.W, FC.B, FC.S, FC.M, FC.Q, FC.L].indexOf(
          remunerationPeriodFrequencyCode
        ) !== -1
      )
        return this.createError({ message: requiredError });
      if (v !== undefined && remunerationPeriodFrequencyCode === undefined)
        return this.createError({ message: notRequiredError });
      return true;
    }),
  numberHoursRepresented: Yup.number()
    .max(999, maxNumError)
    .nullable()
    .test('numberHoursRepresented', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const { remunerationPeriodFrequencyCode, remunerationTypeCode } = this
        .parent as IUisSiRemunerationOccurrence;
      let FC = FrequencyCodes_class.enum;
      if (
        v === undefined &&
        remunerationPeriodFrequencyCode !== undefined &&
        remunerationTypeCode !== undefined &&
        [FC.D, FC.W, FC.B, FC.S, FC.M, FC.Q, FC.L].indexOf(
          remunerationPeriodFrequencyCode
        ) !== -1
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        (remunerationPeriodFrequencyCode === undefined ||
          remunerationTypeCode === undefined)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  dateRemunerationIssued: Yup.date()
    .nullable()
    .test('dateRemunerationIssued', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const { remunerationPeriodFrequencyCode, remunerationTypeCode } = this
        .parent as IUisSiRemunerationOccurrence;
      let FC = FrequencyCodes_class.enum;
      if (
        v === undefined &&
        remunerationPeriodFrequencyCode !== undefined &&
        remunerationTypeCode !== undefined &&
        [FC.D, FC.W, FC.B, FC.S, FC.M, FC.Q, FC.L].indexOf(
          remunerationPeriodFrequencyCode
        ) !== -1
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        (remunerationPeriodFrequencyCode === undefined ||
          remunerationTypeCode === undefined)
      )
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(values.requestDate).add(5, 'years');
      let checkLowDate = moment(values.claimEffectiveDate).add(-2, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  remunerationComments: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('remunerationComments', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const { remunerationTypeCode } = this
        .parent as IUisSiRemunerationOccurrence;
      if (
        v === undefined &&
        remunerationTypeCode === RemunerationTypeCodes_class.enum.Item14
      )
        return this.createError({ message: requiredError });
      return true;
    }),
});
export const uisSiPension401KoccurrencesSchema = Yup.object().shape({
  pension401Kind: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('pension401Kind', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined) return this.createError({ message: requiredError });
      return true;
    }),
  pension401KfrequencyCode: Yup.number()
    .max(8, maxNumError)
    .nullable()
    .test('pension401KfrequencyCode', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const { pension401Kind } = this.parent as IUisSiPension401Koccurrence;
      if (
        v === undefined &&
        (pension401Kind === Pension401kCodes_class.enum.Item1 ||
          values.paidOut401KtoClaimant === 0)
      )
        return this.createError({ message: requiredError });
      return true;
    }),
  pension401KamountPerPeriod: Yup.number()
    .max(999999999999999, maxNumError)
    .nullable()
    .test('pension401KamountPerPeriod', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const { pension401KfrequencyCode, pension401KamountPerPeriodUnknown } =
        this.parent as IUisSiPension401Koccurrence;
      let FC = FrequencyCodes_class.enum;
      if (
        v === undefined &&
        pension401KfrequencyCode !== undefined &&
        pension401KamountPerPeriodUnknown === undefined &&
        [FC.D, FC.W, FC.B, FC.S, FC.M, FC.Q, FC.L].indexOf(
          pension401KfrequencyCode
        ) !== -1
      )
        return this.createError({ message: requiredError });
      if (v !== undefined && pension401KfrequencyCode === undefined)
        return this.createError({ message: notRequiredError });
      return true;
    }),
  pension401KamountPerPeriodUnknown: Yup.number()
    .max(0, maxNumError)
    .nullable()
    .test('pension401KamountPerPeriodUnknown', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const { pension401KfrequencyCode, pension401KamountPerPeriod } = this
        .parent as IUisSiPension401Koccurrence;
      if (
        v === undefined &&
        pension401KfrequencyCode !== undefined &&
        pension401KamountPerPeriod === undefined
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        (pension401KfrequencyCode === undefined ||
          pension401KamountPerPeriod !== undefined)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  datePension401Kissued: Yup.date()
    .nullable()
    .test('datePension401Kissued', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const { pension401KfrequencyCode, datePension401KissuedUnknown } = this
        .parent as IUisSiPension401Koccurrence;
      let FC = FrequencyCodes_class.enum;
      if (
        v === undefined &&
        pension401KfrequencyCode !== undefined &&
        datePension401KissuedUnknown === undefined &&
        [FC.D, FC.W, FC.B, FC.S, FC.M, FC.Q, FC.L].indexOf(
          pension401KfrequencyCode
        ) !== -1
      )
        return this.createError({ message: requiredError });
      if (v !== undefined && pension401KfrequencyCode === undefined)
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(values.requestDate).add(5, 'years');
      let checkLowDate = moment(values.claimEffectiveDate).add(-2, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  datePension401KissuedUnknown: Yup.number()
    .max(0, maxNumError)
    .nullable()
    .test('datePension401KissuedUnknown', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const { pension401KfrequencyCode, datePension401Kissued } = this
        .parent as IUisSiPension401Koccurrence;
      let FC = FrequencyCodes_class.enum;
      if (
        v === undefined &&
        pension401KfrequencyCode !== undefined &&
        datePension401Kissued === undefined &&
        [FC.D, FC.W, FC.B, FC.S, FC.M, FC.Q, FC.L].indexOf(
          pension401KfrequencyCode
        ) !== -1
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !(
          pension401KfrequencyCode !== undefined ||
          datePension401Kissued !== undefined
        )
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  whoContributedPension401K: Yup.number()
    .max(4, maxNumError)
    .nullable()
    .test('whoContributedPension401K', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const { pension401KfrequencyCode } = this
        .parent as IUisSiPension401Koccurrence;
      let FC = FrequencyCodes_class.enum;
      if (
        v === undefined &&
        pension401KfrequencyCode !== undefined &&
        [FC.D, FC.W, FC.B, FC.S, FC.M, FC.Q, FC.L, FC.Z].indexOf(
          pension401KfrequencyCode
        ) !== -1
      )
        return this.createError({ message: requiredError });
      if (v !== undefined && pension401KfrequencyCode === undefined)
        return this.createError({ message: notRequiredError });
      return true;
    }),
  claimantPensionContributionPercent: Yup.number()
    .max(100, maxNumError)
    .nullable()
    .test('claimantPensionContributionPercent', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const {
        whoContributedPension401K,
        claimantPension401KcontributionPercentUnknown,
        employerPension401KcontributionPercent,
      } = this.parent as IUisSiPension401Koccurrence;
      let PC = ContributionCodes_class.enum;
      if (
        v === undefined &&
        (whoContributedPension401K === PC.C ||
          whoContributedPension401K === PC.B) &&
        claimantPension401KcontributionPercentUnknown === undefined
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        ((whoContributedPension401K !== PC.C &&
          whoContributedPension401K !== PC.B) ||
          claimantPension401KcontributionPercentUnknown !== undefined)
      )
        return this.createError({ message: notRequiredError });

      // Added BR to verifiy that combined percent is not more than 100.
      let total = (v ?? 0) + (employerPension401KcontributionPercent ?? 0);
      if (total > 100)
        return this.createError({
          message:
            'Total combined percentage of claimant and employer cannot be more than 100.',
        });
      return true;
    }),
  claimantPension401KcontributionPercentUnknown: Yup.number()
    .max(0, maxNumError)
    .nullable()
    .test('claimantPension401KcontributionPercentUnknown', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const { whoContributedPension401K, claimantPensionContributionPercent } =
        this.parent as IUisSiPension401Koccurrence;
      let PC = ContributionCodes_class.enum;
      if (
        v === undefined &&
        (whoContributedPension401K === PC.C ||
          whoContributedPension401K === PC.B) &&
        claimantPensionContributionPercent === undefined
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        ((whoContributedPension401K !== PC.C &&
          whoContributedPension401K !== PC.B) ||
          claimantPensionContributionPercent !== undefined)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  employerPension401KcontributionPercent: Yup.number()
    .max(100, maxNumError)
    .nullable()
    .test('employerPension401KcontributionPercent', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const {
        whoContributedPension401K,
        employerPension401KcontributionPercentUnknown,
        claimantPensionContributionPercent,
      } = this.parent as IUisSiPension401Koccurrence;
      let PC = ContributionCodes_class.enum;
      if (
        v === undefined &&
        (whoContributedPension401K === PC.E ||
          whoContributedPension401K === PC.B) &&
        employerPension401KcontributionPercentUnknown === undefined
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        ((whoContributedPension401K !== PC.E &&
          whoContributedPension401K !== PC.B) ||
          employerPension401KcontributionPercentUnknown !== undefined)
      )
        return this.createError({ message: notRequiredError });

      // Added BR to verifiy that combined percent is not more than 100.
      let total = (v ?? 0) + (claimantPensionContributionPercent ?? 0);
      if (total > 100)
        return this.createError({
          message:
            'Total combined percentage of claimant and employer cannot be more than 100.',
        });
      return true;
    }),
  employerPension401KcontributionPercentUnknown: Yup.number()
    .max(0, maxNumError)
    .nullable()
    .test('employerPension401KcontributionPercentUnknown', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const {
        whoContributedPension401K,
        employerPension401KcontributionPercent,
      } = this.parent as IUisSiPension401Koccurrence;
      let PC = ContributionCodes_class.enum;
      if (
        v === undefined &&
        (whoContributedPension401K === PC.E ||
          whoContributedPension401K === PC.B) &&
        employerPension401KcontributionPercent === undefined
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        ((whoContributedPension401K !== PC.E &&
          whoContributedPension401K !== PC.B) ||
          employerPension401KcontributionPercent !== undefined)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  employerLastContributionDate: Yup.date()
    .nullable()
    .test('employerLastContributionDate', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const { whoContributedPension401K, employerLastContributionDateUnknown } =
        this.parent as IUisSiPension401Koccurrence;
      let PC = ContributionCodes_class.enum;
      if (
        v === undefined &&
        (whoContributedPension401K === PC.E ||
          whoContributedPension401K === PC.B) &&
        employerLastContributionDateUnknown === undefined
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        ((whoContributedPension401K !== PC.E &&
          whoContributedPension401K !== PC.B) ||
          employerLastContributionDateUnknown !== undefined)
      )
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(values.requestDate).add(1, 'years');
      let checkLowDate = moment(values.claimEffectiveDate).add(-100, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  employerLastContributionDateUnknown: Yup.number()
    .max(0, maxNumError)
    .nullable()
    .test('employerLastContributionDateUnknown', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const { whoContributedPension401K, employerLastContributionDate } = this
        .parent as IUisSiPension401Koccurrence;
      let PC = ContributionCodes_class.enum;
      if (
        v === undefined &&
        (whoContributedPension401K === PC.E ||
          whoContributedPension401K === PC.B) &&
        employerLastContributionDate === undefined
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        ((whoContributedPension401K !== PC.E &&
          whoContributedPension401K !== PC.B) ||
          employerLastContributionDate !== undefined)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  pension401Kcomments: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('pension401Kcomments', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const { pension401Kind } = this.parent as IUisSiPension401Koccurrence;
      if (v !== undefined && pension401Kind === undefined)
        return this.createError({ message: notRequiredError });
      return true;
    }),
});
export const uisSiContactOccurrencesSchema = Yup.object().shape({
  externalContactTypeInd: Yup.number()
    .max(2, maxNumError)
    .nullable()
    .test('externalContactTypeInd', '', function (v) {
      let values: ISi = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      if (v === undefined) return this.createError({ message: requiredError });
      return true;
    }),
  externalBusinessCompanyName: Yup.string()
    .max(100, maxError)
    .nullable()
    .test('externalBusinessCompanyName', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const {
        externalBusinessContactName,
        externalBusinessEmail,
        externalBusinessPhone,
      } = this.parent as IUisSiContactOccurrence;
      if (
        v === undefined &&
        externalBusinessContactName === undefined &&
        externalBusinessEmail === undefined &&
        externalBusinessPhone === undefined
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  externalBusinessContactName: Yup.string()
    .max(60, maxError)
    .nullable()
    .test('externalBusinessContactName', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const {
        externalBusinessCompanyName,
        externalBusinessEmail,
        externalBusinessPhone,
      } = this.parent as IUisSiContactOccurrence;
      if (
        v === undefined &&
        externalBusinessCompanyName === undefined &&
        externalBusinessEmail === undefined &&
        externalBusinessPhone === undefined
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  externalBusinessEmail: Yup.string()
    .max(60, maxError)
    .nullable()
    .test('externalBusinessEmail', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const {
        externalBusinessCompanyName,
        externalBusinessContactName,
        externalBusinessPhone,
      } = this.parent as IUisSiContactOccurrence;
      if (
        v === undefined &&
        externalBusinessCompanyName === undefined &&
        externalBusinessContactName === undefined &&
        externalBusinessPhone === undefined
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  externalBusinessPhone: Yup.string()
    .max(15, maxError)
    .nullable()
    .test('externalBusinessPhone', '', function (v) {
      let values = (this.options as Options).context ?? ({} as ISi);
      if (!(values.isReadyToRespond ?? false)) return true;
      const {
        externalBusinessCompanyName,
        externalBusinessContactName,
        externalBusinessEmail,
      } = this.parent as IUisSiContactOccurrence;
      if (
        v === undefined &&
        externalBusinessCompanyName === undefined &&
        externalBusinessContactName === undefined &&
        externalBusinessEmail === undefined
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
});

export const siValidationSchema = Yup.object().shape({
  amendedResponseDescription: Yup.string()
    .max(2000, maxError)
    .nullable()
    .test('amendedResponseDescription', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isAmendedResponseDescription(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isAmendedResponseDescription(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  correctedStateEmployerAccountNbr: Yup.string().max(20, maxError).nullable(), // Uses GetSafeString
  correctedEmployerName: Yup.string().max(100, maxError).nullable(), // Uses GetSafeString
  correctedFEIN: Yup.string().max(9, maxError).nullable(), // Uses GetSafeString
  otherSSN: Yup.string().max(9, maxError).nullable(), // Uses GetSafeString
  claimantNameWorkedAsForEmployer: Yup.string().max(70, maxError).nullable(),
  fraudInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('fraudInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opFraudInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  fraudReasons: Yup.number()
    .max(6, maxNumError)
    .nullable()
    .test('fraudReasons', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isFraudReasons(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isFraudReasons(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  fraudComments: Yup.string()
    .max(100, maxError)
    .nullable()
    .test('fraudComments', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isFraudComments(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isFraudComments(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  contractEmployee1099Ind: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('contractEmployee1099Ind', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opContractEmployee1099Ind(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  seasonalEmploymentInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('seasonalEmploymentInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opSeasonalEmploymentInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  employerSepReasonCode: Yup.number()
    .max(23, maxNumError)
    .nullable()
    .test('employerSepReasonCode', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isEmployerSepReasonCode(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isEmployerSepReasonCode(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  temporaryStaffingInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('temporaryStaffingInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);

      if (v !== undefined && !opTemporaryStaffingInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  claimantJobTitle: Yup.string()
    .max(30, maxError)
    .nullable()
    .test('claimantJobTitle', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opClaimantJobTitle(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  employerReportedClaimantFirstDayofWork: Yup.date()
    .nullable()
    .test('employerReportedClaimantFirstDayofWork', '', function (v) {
      const {
        isReadyToRespond,
        requestDate,
        employerReportedClaimantLastDayofWork,
      } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opEmployerReportedClaimantFirstDayofWork(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(employerReportedClaimantLastDayofWork);
      let checkLowDate = moment(requestDate).add(-100, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  employerReportedClaimantLastDayofWork: Yup.date()
    .nullable()
    .test('employerReportedClaimantLastDayofWork', '', function (v) {
      const {
        isReadyToRespond,
        requestDate,
        employerReportedClaimantFirstDayofWork,
      } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isEmployerReportedClaimantLastDayofWork(values))
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isEmployerReportedClaimantLastDayofWork(values) &&
        !opEmployerReportedClaimantLastDayofWork(values)
      )
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment();
      let checkLowDate =
        employerReportedClaimantFirstDayofWork === undefined
          ? moment(requestDate).add(-10, 'years')
          : moment(employerReportedClaimantFirstDayofWork);

      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  separationDateInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('separationDateInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isSeparationDateInd(values))
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isSeparationDateInd(values) &&
        !opSeparationDateInd(values)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  effectiveSeparationDate: Yup.date()
    .nullable()
    .test('effectiveSeparationDate', '', function (v) {
      const {
        isReadyToRespond,
        requestDate,
        employerReportedClaimantLastDayofWork,
      } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isEffectiveSeparationDate(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isEffectiveSeparationDate(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(requestDate).add(1, 'years');
      let checkLowDate = moment(employerReportedClaimantLastDayofWork);
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  returnToWorkInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('returnToWorkInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isReturnToWorkInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isReturnToWorkInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  returnToWorkDateInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('returnToWorkDateInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isReturnToWorkDateInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isReturnToWorkDateInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  whyLeaveOfAbsence: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('whyLeaveOfAbsence', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWhyLeaveOfAbsence(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWhyLeaveOfAbsence(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  agreedReturnToWorkDateInd: Yup.number()
    .max(4, maxNumError)
    .nullable()
    .test('agreedReturnToWorkDateInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isAgreedReturnToWorkDateInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isAgreedReturnToWorkDateInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  returnToWorkDate: Yup.date()
    .nullable()
    .test('returnToWorkDate', '', function (v) {
      const {
        isReadyToRespond,
        requestDate,
        claimEffectiveDate,
        effectiveSeparationDate,
        employerReportedClaimantLastDayofWork,
      } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isReturnToWorkDate(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isReturnToWorkDate(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(requestDate).add(1, 'years');
      let checkLowDate =
        moment(claimEffectiveDate).diff(moment(effectiveSeparationDate)) < 0
          ? moment(effectiveSeparationDate).diff(
              moment(employerReportedClaimantLastDayofWork)
            ) < 0
            ? moment(employerReportedClaimantLastDayofWork)
            : moment(effectiveSeparationDate)
          : moment(claimEffectiveDate).diff(moment(effectiveSeparationDate)) < 0
          ? moment(effectiveSeparationDate)
          : moment(claimEffectiveDate);
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  whoReducedHours: Yup.number()
    .max(2, maxNumError)
    .nullable()
    .test('whoReducedHours', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWhoReducedHours(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWhoReducedHours(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  workingAllAvailableHoursInd: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('workingAllAvailableHoursInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWorkingAllAvailableHoursInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWorkingAllAvailableHoursInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  notWorkingAvailableHoursReason: Yup.string()
    .max(500, maxError)
    .nullable()
    .test('notWorkingAvailableHoursReason', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isNotWorkingAvailableHoursReason(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isNotWorkingAvailableHoursReason(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  dischargeReasonCode: Yup.number()
    .max(11, maxNumError)
    .nullable()
    .test('dischargeReasonCode', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isDischargeReasonCode(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isDischargeReasonCode(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  paidDuringSuspensionInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('paidDuringSuspensionInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isPaidDuringSuspensionInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isPaidDuringSuspensionInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  receiveNormalRateOfPayInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('receiveNormalRateOfPayInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isReceiveNormalRateOfPayInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isReceiveNormalRateOfPayInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  inadequatePerfReasonInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('inadequatePerfReasonInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isInadequatePerfReasonInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isInadequatePerfReasonInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  finalIncidentDate: Yup.date()
    .nullable()
    .test('finalIncidentDate', '', function (v) {
      const { isReadyToRespond, requestDate } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isFinalIncidentDate(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isFinalIncidentDate(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(requestDate);
      let checkLowDate = moment(requestDate).add(-7, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  finalIncidentReason: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('finalIncidentReason', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isFinalIncidentReason(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isFinalIncidentReason(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  metPastExpections: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('metPastExpections', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isMetPastExpections(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isMetPastExpections(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  reasonForFinalAbsence: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('reasonForFinalAbsence', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isReasonForFinalAbsence(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isReasonForFinalAbsence(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  askedToTestInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('askedToTestInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isAskedToTestInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isAskedToTestInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  testPerformedInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('testPerformedInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isTestPerformedInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isTestPerformedInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  whyNotPerformed: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('whyNotPerformed', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWhyNotPerformed(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWhyNotPerformed(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  whyRequestedToTest: Yup.number()
    .max(5, maxNumError)
    .nullable()
    .test('whyRequestedToTest', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWhyRequestedToTest(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWhyRequestedToTest(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  whoPerformedTest: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('whoPerformedTest', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);

      if (v !== undefined && !opWhoPerformedTest(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  testerName: Yup.string()
    .max(60, maxError)
    .nullable()
    .test('testerName', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);

      if (v !== undefined && !opTesterName(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  testerCompanyName: Yup.string()
    .max(100, maxError)
    .nullable()
    .test('testerCompanyName', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);

      if (v !== undefined && !opTesterCompanyName(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  testResults: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('testResults', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);

      if (v !== undefined && !opTestResults(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  chainOfCustodyInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('chainOfCustodyInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);

      if (v !== undefined && !opChainOfCustodyInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  secondTestSplitSampleGiven: Yup.number()
    .max(4, maxNumError)
    .nullable()
    .test('secondTestSplitSampleGiven', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);

      if (v !== undefined && !opSecondTestSplitSampleGiven(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  secondTestSplitSampleResults: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('secondTestSplitSampleResults', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);

      if (v !== undefined && !opSecondTestSplitSampleResults(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  jobChangeInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('jobChangeInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isJobChangeInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isJobChangeInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  whatConditionsChanged: Yup.string()
    .max(500, maxError)
    .nullable()
    .test('whatConditionsChanged', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWhatConditionsChanged(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWhatConditionsChanged(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  whatWasStolen: Yup.string()
    .max(500, maxError)
    .nullable()
    .test('whatWasStolen', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWhatWasStolen(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWhatWasStolen(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  actCommittedViolatedLaw: Yup.string()
    .max(500, maxError)
    .nullable()
    .test('actCommittedViolatedLaw', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isActCommittedViolatedLaw(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isActCommittedViolatedLaw(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  adverseEffectOnEmployer: Yup.string()
    .max(2000, maxError)
    .nullable()
    .test('adverseEffectOnEmployer', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isAdverseEffectOnEmployer(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isAdverseEffectOnEmployer(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  valueAttachedToActInd: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('valueAttachedToActInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isValueAttachedToActInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isValueAttachedToActInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  dollarValue: Yup.number()
    .max(999999999999999, maxNumError)
    .nullable()
    .test('dollarValue', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isDollarValue(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isDollarValue(values) && !opDollarValue(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  claimantAdmissionInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('claimantAdmissionInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isClaimantAdmissionInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isClaimantAdmissionInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  employerDamage: Yup.string()
    .max(250, maxError)
    .nullable()
    .test('employerDamage', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isEmployerDamage(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isEmployerDamage(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  damagesReportedBy: Yup.string()
    .max(30, maxError)
    .nullable()
    .test('damagesReportedBy', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isDamagesReportedBy(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isDamagesReportedBy(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  altercationOnCompanyPropertyInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('altercationOnCompanyPropertyInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isAltercationOnCompanyPropertyInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isAltercationOnCompanyPropertyInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  altercationOnCompanyTimeInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('altercationOnCompanyTimeInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isAltercationOnCompanyTimeInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isAltercationOnCompanyTimeInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  claimantStartAltercationInd: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('claimantStartAltercationInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isClaimantStartAltercationInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isClaimantStartAltercationInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  claimantAvoidAltercationInd: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('claimantAvoidAltercationInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isClaimantAvoidAltercationInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isClaimantAvoidAltercationInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  wereThereWitnessesInd: Yup.number()
    .max(4, maxNumError)
    .nullable()
    .test('wereThereWitnessesInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWereThereWitnessesInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWereThereWitnessesInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  violateCompanyPolicyInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('violateCompanyPolicyInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isViolateCompanyPolicyInd(values))
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isViolateCompanyPolicyInd(values) &&
        !opViolateCompanyPolicyInd(values)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  dischargePolicyAwareInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('dischargePolicyAwareInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isDischargePolicyAwareInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isDischargePolicyAwareInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  dischargePolicyAwareExplanationCode: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('dischargePolicyAwareExplanationCode', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isDischargePolicyAwareExplanationCode(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isDischargePolicyAwareExplanationCode(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  whoDischargedName: Yup.string()
    .max(60, maxError)
    .nullable()
    .test('whoDischargedName', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWhoDischargedName(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWhoDischargedName(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  whoDischargedTitle: Yup.string()
    .max(60, maxError)
    .nullable()
    .test('whoDischargedTitle', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWhoDischargedTitle(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWhoDischargedTitle(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  dischargeReasonComments: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('dischargeReasonComments', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isDischargeReasonComments(values))
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isDischargeReasonComments(values) &&
        !opDischargeReasonComments(values)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  priorIncidentInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('priorIncidentInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isPriorIncidentInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isPriorIncidentInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  voluntarySepReasonCode: Yup.number()
    .max(9, maxNumError)
    .nullable()
    .test('voluntarySepReasonCode', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isVoluntarySepReasonCode(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isVoluntarySepReasonCode(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  claimantNotifyOfMedicalIssue: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('claimantNotifyOfMedicalIssue', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isClaimantNotifyOfMedicalIssue(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isClaimantNotifyOfMedicalIssue(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  claimantRequestMedicalLoa: Yup.number()
    .max(2, maxNumError)
    .nullable()
    .test('claimantRequestMedicalLoa', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isClaimantRequestMedicalLoa(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isClaimantRequestMedicalLoa(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  medicalLoaavailable: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('medicalLoaavailable', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isMedicalLoaavailable(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isMedicalLoaavailable(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  loaapprovedInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('loaapprovedInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isLoaapprovedInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isLoaapprovedInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  whyLoadenied: Yup.string()
    .max(500, maxError)
    .nullable()
    .test('whyLoadenied', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWhyLoadenied(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWhyLoadenied(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  quitUnderAdviceOfHealthcareProvider: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('quitUnderAdviceOfHealthcareProvider', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isQuitUnderAdviceOfHealthcareProvider(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isQuitUnderAdviceOfHealthcareProvider(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  medicalStatementProvidedInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('medicalStatementProvidedInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isMedicalStatementProvidedInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isMedicalStatementProvidedInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  dutiesClaimantCouldNotPerform: Yup.string()
    .max(500, maxError)
    .nullable()
    .test('dutiesClaimantCouldNotPerform', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isDutiesClaimantCouldNotPerform(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isDutiesClaimantCouldNotPerform(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  workRestrictionsInd: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('workRestrictionsInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWorkRestrictionsInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWorkRestrictionsInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  claimantWorkRestrictions: Yup.string()
    .max(500, maxError)
    .nullable()
    .test('claimantWorkRestrictions', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isClaimantWorkRestrictions(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isClaimantWorkRestrictions(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  workAvailableWithinRestrictionsInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('workAvailableWithinRestrictionsInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWorkAvailableWithinRestrictionsInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWorkAvailableWithinRestrictionsInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  otherWorkOfferedInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('otherWorkOfferedInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isOtherWorkOfferedInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isOtherWorkOfferedInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  inLieuInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('inLieuInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isInLieuInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isInLieuInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  continuingWorkAvailableInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('continuingWorkAvailableInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isContinuingWorkAvailableInd(values))
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isContinuingWorkAvailableInd(values) &&
        !opContinuingWorkAvailableInd(values)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  reasonDissatisfied: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('reasonDissatisfied', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isReasonDissatisfied(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isReasonDissatisfied(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  workingConditions: Yup.string()
    .max(500, maxError)
    .nullable()
    .test('workingConditions', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWorkingConditions(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWorkingConditions(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  workingConditionsChangedInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('workingConditionsChangedInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWorkingConditionsChangedInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWorkingConditionsChangedInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  changesPermanentInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('changesPermanentInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isChangesPermanentInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isChangesPermanentInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  changesScheduledToLast: Yup.string()
    .max(60, maxError)
    .nullable()
    .test('changesScheduledToLast', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isChangesScheduledToLast(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isChangesScheduledToLast(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  mandatoryRetirementInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('mandatoryRetirementInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isMandatoryRetirementInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isMandatoryRetirementInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  whyRetireComments: Yup.string()
    .max(500, maxError)
    .nullable()
    .test('whyRetireComments', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWhyRetireComments(values))
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isWhyRetireComments(values) &&
        !opWhyRetireComments(values)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  hiringAgreementChangesCode: Yup.number()
    .max(6, maxNumError)
    .nullable()
    .test('hiringAgreementChangesCode', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isHiringAgreementChangesCode(values))
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isHiringAgreementChangesCode(values) &&
        !opHiringAgreementChangesCode(values)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  hiringAgreementChangesComments: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('hiringAgreementChangesComments', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isHiringAgreementChangesComments(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isHiringAgreementChangesComments(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  effectiveDateOfChanges: Yup.date()
    .nullable()
    .test('effectiveDateOfChanges', '', function (v) {
      const { isReadyToRespond, requestDate, claimEffectiveDate } = this
        .parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isEffectiveDateOfChanges(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isEffectiveDateOfChanges(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(claimEffectiveDate);
      let checkLowDate = moment(requestDate).add(-100, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  claimantActionsToAvoidQuitInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('claimantActionsToAvoidQuitInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isClaimantActionsToAvoidQuitInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isClaimantActionsToAvoidQuitInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  actionTakenComments: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('actionTakenComments', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isActionTakenComments(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isActionTakenComments(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  claimantContactToExplainInd: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('claimantContactToExplainInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isClaimantContactToExplainInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isClaimantContactToExplainInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  dateMadeContact: Yup.date()
    .nullable()
    .test('dateMadeContact', '', function (v) {
      const {
        isReadyToRespond,
        requestDate,
        responseDueDate,
        employerReportedClaimantFirstDayofWork,
      } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isDateMadeContact(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isDateMadeContact(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate =
        moment().diff(moment(responseDueDate)) < 0
          ? moment()
          : moment(responseDueDate);
      let checkLowDate =
        employerReportedClaimantFirstDayofWork === undefined
          ? moment(requestDate).add(-10, 'years')
          : moment(employerReportedClaimantFirstDayofWork);
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  reasonForFailToReport: Yup.string()
    .max(500, maxError)
    .nullable()
    .test('reasonForFailToReport', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isReasonForFailToReport(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isReasonForFailToReport(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  claimantNoticeInd: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('claimantNoticeInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isClaimantNoticeInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isClaimantNoticeInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  dateNoticeGiven: Yup.date()
    .nullable()
    .test('dateNoticeGiven', '', function (v) {
      const { isReadyToRespond, requestDate, claimEffectiveDate } = this
        .parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isDateNoticeGiven(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isDateNoticeGiven(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(claimEffectiveDate);
      let checkLowDate = moment(requestDate).add(-100, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  dateNoticeGivenUnknown: Yup.number()
    .max(0, maxNumError)
    .nullable()
    .test('dateNoticeGivenUnknown', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isDateNoticeGivenUnknown(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isDateNoticeGivenUnknown(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  claimantProvidedIntendedLastDayWork: Yup.date()
    .nullable()
    .test('claimantProvidedIntendedLastDayWork', '', function (v) {
      const { isReadyToRespond, requestDate, claimEffectiveDate } = this
        .parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isClaimantProvidedIntendedLastDayWork(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isClaimantProvidedIntendedLastDayWork(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(requestDate).add(1, 'years');
      let checkLowDate = moment(claimEffectiveDate).add(-10, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  claimantProvidedIntendedLastDayWorkUnknown: Yup.number()
    .max(0, maxNumError)
    .nullable()
    .test('claimantProvidedIntendedLastDayWorkUnknown', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (
        v === undefined &&
        isClaimantProvidedIntendedLastDayWorkUnknown(values)
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isClaimantProvidedIntendedLastDayWorkUnknown(values)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  claimantWorkOutNoticeInd: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('claimantWorkOutNoticeInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isClaimantWorkOutNoticeInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isClaimantWorkOutNoticeInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  whyNoticeWasntWorkedOut: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('whyNoticeWasntWorkedOut', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWhyNoticeWasntWorkedOut(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWhyNoticeWasntWorkedOut(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  claimantPaidThroughNoticeInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('claimantPaidThroughNoticeInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isClaimantPaidThroughNoticeInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isClaimantPaidThroughNoticeInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  voluntarySepReasonComments: Yup.string()
    .max(2000, maxError)
    .nullable()
    .test('voluntarySepReasonComments', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isVoluntarySepReasonComments(values))
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isVoluntarySepReasonComments(values) &&
        !opVoluntarySepReasonComments(values)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  whyNotReturning: Yup.number()
    .max(11, maxNumError)
    .nullable()
    .test('whyNotReturning', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWhyNotReturning(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWhyNotReturning(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  firstDayOfContract: Yup.date()
    .nullable()
    .test('firstDayOfContract', '', function (v) {
      const { isReadyToRespond, requestDate, claimEffectiveDate } = this
        .parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isFirstDayOfContract(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isFirstDayOfContract(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(requestDate).add(1, 'years');
      let checkLowDate = moment(claimEffectiveDate).add(-10, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  lastDayOfContract: Yup.date()
    .nullable()
    .test('lastDayOfContract', '', function (v) {
      const { isReadyToRespond, requestDate, firstDayOfContract } = this
        .parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isLastDayOfContract(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isLastDayOfContract(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(requestDate).add(10, 'years');
      let checkLowDate = moment(firstDayOfContract);
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  reasonForBreak: Yup.number()
    .max(2, maxNumError)
    .nullable()
    .test('reasonForBreak', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isReasonForBreak(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isReasonForBreak(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  firstDayOfBreak: Yup.date()
    .nullable()
    .test('firstDayOfContract', '', function (v) {
      const { isReadyToRespond, requestDate, claimEffectiveDate } = this
        .parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isFirstDayOfBreak(values))
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isFirstDayOfBreak(values) &&
        !opFirstDayOfBreak(values)
      )
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(requestDate).add(10, 'years');
      let checkLowDate = moment(claimEffectiveDate).add(-10, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  lastDayOfBreak: Yup.date()
    .nullable()
    .test('lastDayOfBreak', '', function (v) {
      const { isReadyToRespond, requestDate, firstDayOfBreak } = this
        .parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isLastDayOfBreak(values))
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isLastDayOfBreak(values) &&
        !opLastDayOfBreak(values)
      )
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(requestDate).add(10, 'years');
      let checkLowDate = moment(firstDayOfBreak);
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  workContinueDuringBreakInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('workContinueDuringBreakInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opWorkContinueDuringBreakInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  positionReturningTo: Yup.string()
    .max(30, maxError)
    .nullable()
    .test('positionReturningTo', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isPositionReturningTo(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isPositionReturningTo(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  newContractJobOfferInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('newContractJobOfferInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isNewContractJobOfferInd(values))
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isNewContractJobOfferInd(values) &&
        !opNewContractJobOfferInd(values)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  personOfferedReasonableAssurance: Yup.string()
    .max(60, maxError)
    .nullable()
    .test('personOfferedReasonableAssurance', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isPersonOfferedReasonableAssurance(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isPersonOfferedReasonableAssurance(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  haveAuthorityToOfferInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('haveAuthorityToOfferInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isHaveAuthorityToOfferInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isHaveAuthorityToOfferInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  howOfferConveyed: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('howOfferConveyed', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isHowOfferConveyed(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isHowOfferConveyed(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  whyContractNotOffered: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('whyContractNotOffered', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWhyContractNotOffered(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWhyContractNotOffered(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  contractAccepted: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('contractAccepted', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opContractAccepted(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  laborDisputeTypeInd: Yup.number()
    .max(2, maxNumError)
    .nullable()
    .test('laborDisputeTypeInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isLaborDisputeTypeInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isLaborDisputeTypeInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  locationOfLaborDispute: Yup.string()
    .max(250, maxError)
    .nullable()
    .test('locationOfLaborDispute', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opLocationOfLaborDispute(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  unionNameAndLocalNo: Yup.string()
    .max(100, maxError)
    .nullable()
    .test('unionNameAndLocalNo', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opUnionNameAndLocalNo(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  dateDisputeBegan: Yup.date()
    .nullable()
    .test('dateDisputeBegan', '', function (v) {
      const { isReadyToRespond, requestDate } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isDateDisputeBegan(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isDateDisputeBegan(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment();
      let checkLowDate = moment(requestDate).add(-10, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (v !== undefined && moment(v).diff(checkHighDate) > 0)
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  laborDisputeEndedInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('laborDisputeEndedInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isLaborDisputeEndedInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isLaborDisputeEndedInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  dateDisputeEnded: Yup.date()
    .nullable()
    .test('dateDisputeEnded', '', function (v) {
      const { isReadyToRespond, requestDate, dateDisputeBegan } = this
        .parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isDateDisputeEnded(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isDateDisputeEnded(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment(requestDate).add(1, 'years');
      let checkLowDate = moment(dateDisputeBegan);
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  replacementWorkersHiredInd: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('replacementWorkersHiredInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isReplacementWorkersHiredInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isReplacementWorkersHiredInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  clmtStrikingUnionMemberInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('clmtStrikingUnionMemberInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isClmtStrikingUnionMemberInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isClmtStrikingUnionMemberInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  clmtMemberAnotherUnionInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('clmtMemberAnotherUnionInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opClmtMemberAnotherUnionInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  whyUnemployedLaborDispute: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('whyUnemployedLaborDispute', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWhyUnemployedLaborDispute(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWhyUnemployedLaborDispute(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  settlementAffectClmtInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('settlementAffectClmtInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opSettlementAffectClmtInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  howSettlementAffects: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('howSettlementAffects', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opHowSettlementAffects(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  relationshipEndedInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('relationshipEndedInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isRelationshipEndedInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isRelationshipEndedInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  workingNewAssignmentInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('workingNewAssignmentInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isWorkingNewAssignmentInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isWorkingNewAssignmentInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  notWorkingOnAssignment: Yup.number()
    .max(4, maxNumError)
    .nullable()
    .test('notWorkingOnAssignment', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isNotWorkingOnAssignment(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isNotWorkingOnAssignment(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  tempAgencyPolicyInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('tempAgencyPolicyInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isTempAgencyPolicyInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isTempAgencyPolicyInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  tempPolicyAwarenessCode: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('tempPolicyAwarenessCode', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isTempPolicyAwarenessCode(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isTempPolicyAwarenessCode(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  tempAssignmentLastDay: Yup.date()
    .nullable()
    .test('tempAssignmentLastDay', '', function (v) {
      const { isReadyToRespond, requestDate } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isTempAssignmentLastDay(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isTempAssignmentLastDay(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment();
      let checkLowDate = moment(requestDate).add(-100, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (v !== undefined && moment(v).diff(checkHighDate) > 0)
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  newTempAssignmentDateOfferMade: Yup.date()
    .nullable()
    .test('newTempAssignmentDateOfferMade', '', function (v) {
      const { isReadyToRespond, requestDate } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isNewTempAssignmentDateOfferMade(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isNewTempAssignmentDateOfferMade(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment();
      let checkLowDate = moment(requestDate).add(-100, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (v !== undefined && moment(v).diff(checkHighDate) > 0)
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  newTempAssignmentStartDate: Yup.date()
    .nullable()
    .test('newTempAssignmentStartDate', '', function (v) {
      const { isReadyToRespond, newTempAssignmentDateOfferMade } = this
        .parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isNewTempAssignmentStartDate(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isNewTempAssignmentStartDate(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment();
      let checkLowDate = moment(newTempAssignmentDateOfferMade);
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  newTempAssignmentJobDuties: Yup.string()
    .max(500, maxError)
    .nullable()
    .test('newTempAssignmentJobDuties', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isNewTempAssignmentJobDuties(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isNewTempAssignmentJobDuties(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  newTempAssignmentRateOfPay: Yup.number()
    .max(999999999999999, maxNumError)
    .nullable()
    .test('newTempAssignmentRateOfPay', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isNewTempAssignmentRateOfPay(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isNewTempAssignmentRateOfPay(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  newTempAssignmentCommutingDistance: Yup.string()
    .max(50, maxError)
    .nullable()
    .test('newTempAssignmentCommutingDistance', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isNewTempAssignmentCommutingDistance(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isNewTempAssignmentCommutingDistance(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  newTempAssignmentHours: Yup.string()
    .max(100, maxError)
    .nullable()
    .test('newTempAssignmentHours', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isNewTempAssignmentHours(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isNewTempAssignmentHours(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  newTempAssignmentWhyRefused: Yup.string()
    .max(500, maxError)
    .nullable()
    .test('newTempAssignmentWhyRefused', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isNewTempAssignmentWhyRefused(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isNewTempAssignmentWhyRefused(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  newTempAssignmentDateRefused: Yup.date()
    .nullable()
    .test('newTempAssignmentDateRefused', '', function (v) {
      const { isReadyToRespond, newTempAssignmentDateOfferMade } = this
        .parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isNewTempAssignmentDateRefused(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isNewTempAssignmentDateRefused(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment();
      let checkLowDate = moment(newTempAssignmentDateOfferMade);
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  tempAssignmentWhoEnded: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('tempAssignmentWhoEnded', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isTempAssignmentWhoEnded(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isTempAssignmentWhoEnded(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  tempAssignmentWhyNotCompleted: Yup.string()
    .max(1000, maxError)
    .nullable()
    .test('tempAssignmentWhyNotCompleted', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isTempAssignmentWhyNotCompleted(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isTempAssignmentWhyNotCompleted(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  tempAssignmentClaimantContactAgencyInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('tempAssignmentClaimantContactAgencyInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isTempAssignmentClaimantContactAgencyInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isTempAssignmentClaimantContactAgencyInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  tempAssignmentWorkAvailableInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('tempAssignmentWorkAvailableInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isTempAssignmentWorkAvailableInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isTempAssignmentWorkAvailableInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  employerSepReasonComments: Yup.string()
    .max(2000, maxError)
    .nullable()
    .test('employerSepReasonComments', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isEmployerSepReasonComments(values))
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isEmployerSepReasonComments(values) &&
        !opEmployerSepReasonComments(values)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  refuseToProvideInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('refuseToProvideInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isRefuseToProvideInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isRefuseToProvideInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  employerContactName: Yup.string()
    .max(60, maxError)
    .nullable()
    .test('employerContactName', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opEmployerContactName(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  employerStreetAddress: Yup.string()
    .max(35, maxError)
    .nullable()
    .test('employerStreetAddress', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opEmployerStreetAddress(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  employerCity: Yup.string()
    .max(19, maxError)
    .nullable()
    .test('employerCity', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opEmployerCity(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  employerState: Yup.string()
    .max(3, maxError)
    .nullable()
    .test('employerState', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opEmployerState(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  employerZip: Yup.string()
    .max(10, maxError)
    .nullable()
    .test('employerState', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opEmployerZip(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  repContractTermDate: Yup.date()
    .nullable()
    .test('repContractTermDate', '', function (v) {
      const { isReadyToRespond, requestDate } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opRepContractTermDate(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment();
      let checkLowDate = moment(requestDate).add(-100, 'days');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  totalEarnedWagesNeededInd: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('totalEarnedWagesNeededInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isTotalEarnedWagesNeededInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isTotalEarnedWagesNeededInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  totalEarnedWages: Yup.number()
    .max(999999999999999, maxNumError)
    .nullable()
    .test('totalEarnedWages', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isTotalEarnedWages(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isTotalEarnedWages(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  totalWeeksWorkedNeededInd: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('totalWeeksWorkedNeededInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isTotalWeeksWorkedNeededInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isTotalWeeksWorkedNeededInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  totalWeeksWorked: Yup.number()
    .max(99, maxNumError)
    .nullable()
    .test('totalWeeksWorked', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isTotalWeeksWorked(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isTotalWeeksWorked(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  WagesEarnedAfterClaimEffectiveDate: Yup.number()
    .max(999999999999999, maxNumError)
    .nullable()
    .test('totalEarnedWages', '', function (v) {
      return true;
    }),
  numberOfHoursWorkedAfterClaimEffectiveDate: Yup.number()
    .max(99999, maxNumError)
    .nullable()
    .test('numberOfHoursWorkedAfterClaimEffectiveDate', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (
        v !== undefined &&
        !opNumberOfHoursWorkedAfterClaimEffectiveDate(values)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  regularRateofPay: Yup.number()
    .max(999999999999999, maxNumError)
    .nullable()
    .test('regularRateofPay', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isRegularRateofPay(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isRegularRateofPay(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  rateofPayPerPeriod: Yup.number()
    .max(5, maxNumError)
    .nullable()
    .test('rateofPayPerPeriod', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isRateofPayPerPeriod(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isRateofPayPerPeriod(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  averageNumberofHoursWorkedperWeek: Yup.number()
    .max(99, maxNumError)
    .nullable()
    .test('averageNumberofHoursWorkedperWeek', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isAverageNumberofHoursWorkedperWeek(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isAverageNumberofHoursWorkedperWeek(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  customaryWeeklyWorkSchedule: Yup.string()
    .max(100, maxError)
    .nullable()
    .test('customaryWeeklyWorkSchedule', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isCustomaryWeeklyWorkSchedule(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isCustomaryWeeklyWorkSchedule(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  remunerationInd: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('remunerationInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isRemunerationInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isRemunerationInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  receivingWorkersCompensationInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('receivingWorkersCompensationInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opReceivingWorkersCompensationInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  dateOfInjury: Yup.date()
    .nullable()
    .test('dateOfInjury', '', function (v) {
      const { isReadyToRespond, requestDate } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isDateOfInjury(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isDateOfInjury(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment();
      let checkLowDate = moment(requestDate).add(-100, 'years');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  dateAppliedForWorkersCompKnownInd: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('dateAppliedForWorkersCompKnownInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isDateAppliedForWorkersCompKnownInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isDateAppliedForWorkersCompKnownInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  dateAppliedForWorkersComp: Yup.date()
    .nullable()
    .test('dateAppliedForWorkersComp', '', function (v) {
      const { isReadyToRespond, requestDate } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isDateAppliedForWorkersComp(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isDateAppliedForWorkersComp(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment();
      let checkLowDate = moment(requestDate).add(-100, 'days');
      if (
        v === undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  typeOfWorkersCompReceiving: Yup.number()
    .max(6, maxNumError)
    .nullable()
    .test('typeOfWorkersCompReceiving', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isTypeOfWorkersCompReceiving(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isTypeOfWorkersCompReceiving(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  weeklyWorkersCompRate: Yup.number()
    .max(999999999999999, maxNumError)
    .nullable()
    .test('weeklyWorkersCompRate', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opWeeklyWorkersCompRate(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  periodOfTimeWorkersCompPaymentCovers: Yup.string()
    .max(250, maxError)
    .nullable()
    .test('periodOfTimeWorkersCompPaymentCovers', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opPeriodOfTimeWorkersCompPaymentCovers(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  claimantReleasedToReturnToWork: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('claimantReleasedToReturnToWork', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isClaimantReleasedToReturnToWork(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isClaimantReleasedToReturnToWork(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  dateClaimantReleased: Yup.date()
    .nullable()
    .test('dateClaimantReleased', '', function (v) {
      const { isReadyToRespond, requestDate } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isDateClaimantReleased(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isDateClaimantReleased(values))
        return this.createError({ message: notRequiredError });

      //Check Date
      let checkHighDate = moment();
      let checkLowDate = moment(requestDate).add(-100, 'days');
      if (
        v !== undefined &&
        moment(v).diff(moment(checkLowDate).add(-36, 'hours')) < 0
      )
        return this.createError({ message: minDateError(checkLowDate) });
      if (
        v !== undefined &&
        moment(v).diff(moment(checkHighDate).add(36, 'hours')) > 0
      )
        return this.createError({ message: maxDateError(checkHighDate) });
      return true;
    }),
  employerAccommodateRestrictionsInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('employerAccommodateRestrictionsInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opEmployerAccommodateRestrictionsInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  whyEmployerCouldNotAccommodate: Yup.string()
    .max(500, maxError)
    .nullable()
    .test('whyEmployerCouldNotAccommodate', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opWhyEmployerCouldNotAccommodate(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  workersCompComments: Yup.string()
    .max(500, maxError)
    .nullable()
    .test('workersCompComments', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v !== undefined && !opWorkersCompComments(values))
        return this.createError({ message: notRequiredError });
      return true;
    }), // Uses GetSafeString
  receivePension401Kind: Yup.number()
    .max(6, maxNumError)
    .nullable()
    .test('receivePension401Kind', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isReceivePension401Kind(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isReceivePension401Kind(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  mandatoryPension: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('mandatoryPension', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isMandatoryPension(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isMandatoryPension(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  fullAmount401KrolledOver: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('fullAmount401KrolledOver', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isFullAmount401KrolledOver(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isFullAmount401KrolledOver(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  percentage401KrolledOver: Yup.number()
    .max(99.99, maxNumError)
    .nullable()
    .test('percentage401KrolledOver', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isPercentage401KrolledOver(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isPercentage401KrolledOver(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  percentage401KrolledOverUnknown: Yup.number()
    .max(0, maxNumError)
    .nullable()
    .test('percentage401KrolledOverUnknown', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isPercentage401KrolledOverUnknown(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isPercentage401KrolledOverUnknown(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  paidOut401KtoClaimant: Yup.number()
    .max(3, maxNumError)
    .nullable()
    .test('paidOut401KtoClaimant', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isPaidOut401KtoClaimant(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isPaidOut401KtoClaimant(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),
  attachmentInd: Yup.number()
    .max(1, maxNumError)
    .nullable()
    .test('attachmentInd', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (v === undefined && isAttachmentInd(values))
        return this.createError({ message: requiredError });
      if (v !== undefined && !isAttachmentInd(values))
        return this.createError({ message: notRequiredError });
      return true;
    }),

  uisSiWitnessInformationOccurrences: Yup.array()
    .of(uisSiWitnessInformationOccurrencesSchema)
    .nullable()
    .max(5)
    .test('uisSiWitnessInformationOccurrences', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (
        (v === undefined || v.length === 0) &&
        isUisSiWitnessInformationOccurrences(values)
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isUisSiWitnessInformationOccurrences(values) &&
        v.length !== 0
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  uisSiPriorIncidentOccurrences: Yup.array()
    .of(uisSiPriorIncidentOccurrencesSchema)
    .nullable()
    .max(5)
    .test('uisSiPriorIncidentOccurrences', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (
        (v === undefined || v.length === 0) &&
        isUisSiPriorIncidentOccurrences(values)
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        v.length !== 0 &&
        !isUisSiPriorIncidentOccurrences(values)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  uisSiFailedToReportOccurrences: Yup.array()
    .of(uisSiFailedToReportOccurrencesSchema)
    .nullable()
    .max(5)
    .test('uisSiFailedToReportOccurrences', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (
        (v === undefined || v.length === 0) &&
        isUisSiFailedToReportOccurrences(values) &&
        v.length === 0
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isUisSiFailedToReportOccurrences(values) &&
        v.length !== 0
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  uisSiPreviousAssignmentOccurrences: Yup.array()
    .of(uisSiPreviousAssignmentOccurrencesSchema)
    .nullable()
    .max(5)
    .test('uisSiPreviousAssignmentOccurrences', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (
        (v === undefined || v.length === 0) &&
        isUisSiPreviousAssignmentOccurrences(values) &&
        v.length === 0
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isUisSiPreviousAssignmentOccurrences(values) &&
        v.length !== 0
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  uisSiRemunerationOccurrences: Yup.array()
    .of(uisSiRemunerationOccurrencesSchema)
    .nullable()
    .max(5)
    .test('uisSiRemunerationOccurrences', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (
        (v === undefined || v.length === 0) &&
        isUisSiRemunerationOccurrences(values) &&
        v.length === 0
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isUisSiRemunerationOccurrences(values) &&
        v.length !== 0
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),
  uisSiPension401Koccurrences: Yup.array()
    .of(uisSiPension401KoccurrencesSchema)
    .nullable()
    .max(2, 'There should not be more than 2 occurrences')
    .test('uisSiPension401Koccurrences', '', function (v) {
      const { isReadyToRespond, receivePension401Kind } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (
        (v === undefined || v.length === 0) &&
        isUisSiPension401Koccurrences(values) &&
        v.length === 0
      )
        return this.createError({ message: requiredError });
      if (
        v !== undefined &&
        !isUisSiPension401Koccurrences(values) &&
        v.length !== 0
      )
        return this.createError({ message: notRequiredError }); //Pension401kCodes_class

      if (
        v !== undefined &&
        receivePension401Kind !== Pension401kReceivedCodes_class.enum.Item3 &&
        v.length === 2
      )
        return this.createError({
          message: 'Only 1 Pension / 401K occurence required.',
        });

      if (
        v !== undefined &&
        receivePension401Kind === Pension401kReceivedCodes_class.enum.Item3 &&
        v.length === 1
      )
        return this.createError({
          message: '2 Pension / 401K occurence required.',
        });

      if (
        v !== undefined &&
        receivePension401Kind === Pension401kReceivedCodes_class.enum.Item3 &&
        v.length === 2 &&
        v[0].pension401Kind === v[1].pension401Kind
      )
        return this.createError({
          message: 'Must only have 1 Pension and 1 401K occurence.',
        });
      return true;
    }),
  uisSiContactOccurrences: Yup.array()
    .of(uisSiContactOccurrencesSchema)
    .nullable()
    .max(5)
    .test('uisSiContactOccurrences', '', function (v) {
      const { isReadyToRespond } = this.parent as ISi;
      if (!isReadyToRespond) return true;
      let values = (this.options as Options).context ?? ({} as ISi);
      if (
        v !== undefined &&
        v.length !== 0 &&
        !opUisSiContactOccurrences(values)
      )
        return this.createError({ message: notRequiredError });
      return true;
    }),

  attachments: Yup.array().of(
    Yup.object().shape({
      fileName: Yup.string()
        .max(500, 'Must be 500 characters or less')
        .nullable(),
      fileUpload: Yup.mixed().nullable(),
    })
  ),
  requestDate: Yup.date().nullable(),
});
