import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { resetPasswordActions } from './resetPassword.actions';
import { resetPasswordInit } from './resetPassword.dtos';

const resetPasswordReducer = combineReducers({
  resetPasswordRequest: createReducer(resetPasswordInit)
    .handleAction(
      [
        resetPasswordActions.request,
      ], (state, action) => action.payload),

  resetPasswordSuccess: createReducer(false as boolean)
    .handleAction(
      [
        resetPasswordActions.success,
      ], (state, action) => true),

  resetPasswordError: createReducer('')
    .handleAction(
      [
        resetPasswordActions.failure,
      ], (state, action) => action.payload)
});

export default resetPasswordReducer;