import React from 'react';
import { FormikHandlers, FormikHelpers, } from 'formik';

// material ui
import { Grid, FormControlLabel } from '@material-ui/core';
import { ClientQuestionnaireDto, } from '../../dto';
import { MHARadio } from '../../../../common/styles/styles';
import { radioButtonBooleanOptions } from '../../constants/options';


interface SeasonalEmploymentComponentProps extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>["setFieldValue"];
  setShouldBlockNavigation: (o: boolean) => void;
}

const SeasonalEmploymentComponent: React.FC<SeasonalEmploymentComponentProps> = (
  {
    values,
    handleBlur,
    setFieldValue,
    setShouldBlockNavigation
  }) => {

  return (
    <React.Fragment>
      <Grid item xs={12}>Do you have seasonal status with the State Agency?</Grid>
      <Grid container item xs={12} direction="row">
        {radioButtonBooleanOptions.map((o, index) => (
          <Grid item xs={3} key={o.name + index}>
            <FormControlLabel
              id="seasonalStatus"
              name="seasonalStatus"
              onChange={(e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{}>, checked: boolean) => {
                setFieldValue('seasonalStatus', radioButtonBooleanOptions.find(x => x.name === (e.target as HTMLInputElement).value)?.status ?? 1);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              checked={o.status === values.seasonalStatus}
              value={o.name}
              control={<MHARadio
                size="small" />} label={o.name} data-cy="clientQuestionnaire_seasonalStatus" />
          </Grid>
        ))}
      </Grid>
      <br />

    </React.Fragment>
  )
}

export default SeasonalEmploymentComponent;
