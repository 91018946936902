import React from 'react';
import { Formik } from 'formik';
import {
  Dialog, DialogTitle, Grid, DialogActions,
} from '@material-ui/core';
import { DeleteConfirmationLabel, WideDialogContent } from '../../../claims/claims.styles';
import { ButtonGrid, StyledCancelButton, StyledSubmitButton } from '../../../../common/styles/styles';
import { HealthSystemDto } from '../healthSystems.dtos';


interface Props {
  open: boolean;
  onCancel: () => void;
  handleAgree: (dto: (HealthSystemDto | undefined)) => void;
}

const HealthGroupWarningDialog: React.FC<Props> = (
  {
    open,
    onCancel,
    handleAgree
  }) => {
  const cancelDialog = () => onCancel();

  return <Formik
    initialValues={{ deleteConfirmation: '' }}
    validateOnChange={false}
    validateOnBlur={false}
    onSubmit={(values, { setSubmitting, resetForm }) => {
      setTimeout(() => {
        setSubmitting(false);
        resetForm();
      }, 700);
      if (values.deleteConfirmation === "")
        handleAgree(undefined);

      cancelDialog();
    }}
    enableReinitialize={true}
  >
    {(props) => {
      const {
        isSubmitting,
        handleSubmit,
      } = props;
      return (
        <Dialog
          open={open}
          data-cy="healthGroupWarningDialog_dialog"
        >
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <DialogTitle style={{ marginLeft: 4 }}></DialogTitle>
              <DeleteConfirmationLabel style={{ marginLeft: 30 }}>
                No groups exist for this health system. Are you sure you want to save?
              </DeleteConfirmationLabel>
              <WideDialogContent>
                <form onSubmit={handleSubmit}>
                  <DialogActions>
                    <ButtonGrid container>
                      <StyledSubmitButton
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                        data-cy="delete_dialog_submitButton"
                      >Save
                      </StyledSubmitButton>

                      <StyledCancelButton
                        data-cy="delete_dialog_cancelButton"
                        color="secondary"
                        onClick={cancelDialog}
                      >Cancel
                      </StyledCancelButton>
                    </ButtonGrid>
                  </DialogActions>
                </form>
              </WideDialogContent>
            </Grid>
          </Grid>
        </Dialog>
      );
    }}
  </Formik>
}

export default HealthGroupWarningDialog;
