

export interface ProfileDto {

}

export const profileInit: ProfileDto = {};

export interface GoverningDistrictDto {
  governingDistrictId: number,
  countryId: number,
  name: string,
  code: string
}

export interface CountryDto {
  countryId: number,
  name: string,
  alpha2: string,
  alpha3: string,
  governingDistrictName: string,
  governingDistricts: GoverningDistrictDto[],
}

export const countryInit: ProfileDto = {};

