 
/* Autogenerated by Typewriter do NOT edit
   Dtos.tst is the generating template */


export interface IUisSiRemunerationOccurrence  { 
    
    id?: number;
    remunerationTypeCode?: number;
    employerAllocationInd?: number;
    allocationBeginDate?: Date;
    allocationBeginDateUnknownInd?: number;
    allocationEndDate?: Date;
    allocationEndDateUnknownInd?: number;
    remunerationPeriodFrequencyCode?: number;
    remunerationAmountPerPeriod?: number;
    numberHoursRepresented?: string;
    dateRemunerationIssued?: Date;
    remunerationComments?: string; 
} 

export class IUisSiRemunerationOccurrence_PropertyAttributes {
    static readonly id_Attributes = {
      required: { value: true }, name: "Id", 
      };
       static readonly remunerationTypeCode_Attributes = {
      required: { value: false }, name: "RemunerationTypeCode", 
      };
       static readonly employerAllocationInd_Attributes = {
      required: { value: false }, name: "EmployerAllocationInd", 
      };
       static readonly allocationBeginDate_Attributes = {
      required: { value: false }, name: "AllocationBeginDate", 
      };
       static readonly allocationBeginDateUnknownInd_Attributes = {
      required: { value: false }, name: "AllocationBeginDateUnknownInd", 
      };
       static readonly allocationEndDate_Attributes = {
      required: { value: false }, name: "AllocationEndDate", 
      };
       static readonly allocationEndDateUnknownInd_Attributes = {
      required: { value: false }, name: "AllocationEndDateUnknownInd", 
      };
       static readonly remunerationPeriodFrequencyCode_Attributes = {
      required: { value: false }, name: "RemunerationPeriodFrequencyCode", 
      };
       static readonly remunerationAmountPerPeriod_Attributes = {
      required: { value: false }, name: "RemunerationAmountPerPeriod", 
      };
       static readonly numberHoursRepresented_Attributes = {
      stringLength: { maxLength: 3, }, required: { value: false }, name: "NumberHoursRepresented", 
      };
       static readonly dateRemunerationIssued_Attributes = {
      required: { value: false }, name: "DateRemunerationIssued", 
      };
       static readonly remunerationComments_Attributes = {
      stringLength: { maxLength: 1000, }, required: { value: false }, name: "RemunerationComments", 
      };
      
}

