import { EmployerSepReasonCodes_class } from "../common/enums/EmployerSepReasonCodes";
import { FraudTypeCodes_class } from "../common/enums/FraudTypeCodes";
import { VoluntarySepReasonCodes_class } from "../common/enums/VoluntarySepReasonCodes";
import { DischargeReasonCodes_class } from "../common/enums/DischargeReasonCodes";
import { IMc } from "../common/dtos/McDto";
import { IndicatorTypesWithD_class } from "../common/enums/IndicatorTypesWithD";
import { IndicatorTypes_class } from "../common/enums/IndicatorTypes";

/*
 * A function for each IMc prop that is conditionally or optionally required from the client. 
 * based on 
 * SIDES Monetary and Potential Charges Standard Format - 7-26-23.pdf
 * */
const SC = EmployerSepReasonCodes_class.enum;
const DC = DischargeReasonCodes_class.enum;
const VC = VoluntarySepReasonCodes_class.enum;

export const isAmendedResponseDescription = (v: IMc): boolean => v.respondedDateTime !== undefined;
export const isFraudReasons = (v: IMc): boolean => v.fraudInd === 0;
export const isFraudComments = (v: IMc): boolean => v.fraudReasons === FraudTypeCodes_class.enum.Item5;
export const isEmployerSeparationReasonCode = (v: IMc): boolean => {
  if (v.employerSepReasonAnsweredInd == IndicatorTypes_class.enum.N && v.relationshipEndedInd != IndicatorTypes_class.enum.N) return true;
  if (v.employerSepQuestionShownInd == IndicatorTypesWithD_class.enum.D && v.relationshipEndedInd != IndicatorTypes_class.enum.N) return true;
  return false;
}


export const isEmployerSepReasonAnsweredInd = (v: IMc): boolean => {
  if (v.employerSeparationReasonCode == SC.Item20) return false;
  if (v.employerSeparationReasonCode == SC.Item21) return false;
  if (v.employerSeparationReasonCode == SC.Item23) return false;
  if (v.employerSepQuestionShownInd == IndicatorTypesWithD_class.enum.D) return false;
  if (v.employerSepQuestionShownInd == IndicatorTypesWithD_class.enum.Y) return true;
  return false;
}

export const isRelationshipEndedInd = (v: IMc): boolean => v.temporaryStaffingInd === 0;
export const isWorkingNewAssignmentInd = (v: IMc): boolean => v.relationshipEndedInd === 1;
export const isNotWorkingOnAssignment = (v: IMc): boolean => v.workingNewAssignmentInd === 1;

export const isDischargeReasonCode = (v: IMc): boolean => {
  if (v.voluntarySepReasonCode == VC.Item5) return true;
  if (v.employerSeparationReasonCode === SC.Item3 || v.employerSeparationReasonCode === SC.Item5) return true;
  return false;
}

export const isEmployerSepReasonComments = (v: IMc): boolean => {
  if (v.requestReliefofChargesInd == IndicatorTypes_class.enum.Y) return true;
  return false;
}

export const isLastDayWorked = (v: IMc): boolean => {
  if (v.employerSeparationReasonCode == null) return false;
  if ([SC.Item9, SC.Item10, SC.Item11, SC.Item19, SC.Item20, SC.Item21, SC.Item23, SC.Item99].findIndex(f => f === v.employerSeparationReasonCode) === -1) return true;
  return false;
}

export const isAdditionalSeparationAckInd = (v: IMc): boolean =>
  v.additionalSeparationAckInd !== undefined && [SC.Item20, SC.Item21, SC.Item23].indexOf(v.employerSeparationReasonCode ?? -1) === -1;
export const isAttachmentInd = (v: IMc): boolean => [SC.Item20, SC.Item21, SC.Item23].indexOf(v.employerSeparationReasonCode ?? -1) === -1;

export const isRequestReliefofChargesInd = (v: IMc): boolean => {
  if (
    v.requestforReliefofChargesAllowedInd == IndicatorTypes_class.enum.Y
    && [SC.Item20, SC.Item21, SC.Item23].findIndex(f => f === v.employerSeparationReasonCode) === -1) return true;
  return false;
}

export const isRefuseToProvideInd = (v: IMc): boolean => v.employerSeparationReasonCode == SC.Item99;
export const isVoluntarySepReasonCode = (v: IMc): boolean => v.employerSeparationReasonCode === SC.Item6;


/* Attachment */
// Handled automatically

/* Preparer */
// Handled automatically