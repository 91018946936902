import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Dialog, Grid, TextField, DialogActions
} from '@material-ui/core';
import { getType } from 'typesafe-actions';
import { deleteAppellateEventActions, deleteEligibilityEventActions } from '../actions';
import { requiredError } from '../../../common/constants/strings';
import { StyledCardContent, ButtonGrid, StyledSubmitButton, StyledCancelButton } from '../../../common/styles/styles';
import { DeleteDialogTitle, DeleteConfirmationLabel, WideDialogContent, DeleteLabel } from '../claims.styles';


interface Props {
  eventId: string,
  isAppellate: boolean,
  open: boolean,
  onCancel: () => void,
  isClientUser: boolean,
  isReadOnly: boolean,
}

const DeleteEventDialog: React.FC<Props> = ({
  eventId,
  isAppellate,
  open,
  onCancel,
  isClientUser,
  isReadOnly
}) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (eventId.length === 0) {
      onCancel();
    }
  }, [eventId]);


  return <Formik
    initialValues={{ deleteConfirmation: '' }}
    validateOnChange={false}
    validateOnBlur={false}
    validationSchema={
      Yup.object({
        deleteConfirmation: Yup.string().max(30, "Must be 30 characters or less").required(requiredError),
      })
    }
    onSubmit={(values, { setSubmitting, resetForm }) => {
      setTimeout(() => {
        setSubmitting(false);
        resetForm();
      }, 700);

      if (values.deleteConfirmation.length !== 0 && values.deleteConfirmation.toLowerCase() === 'delete') {
        dispatch({
          type: getType(isAppellate ? deleteAppellateEventActions.request : deleteEligibilityEventActions.request) ,
          payload: eventId
        });

        onCancel();
      }
    }}
    enableReinitialize={true}
  >
    {(props) => {
      const {
        values,
        errors,
        isSubmitting,
        handleBlur,
        handleChange,
        handleSubmit,
      } = props;
      return (
        <Dialog
          open={open}
          data-cy="delete_dialog"
        >
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <DeleteDialogTitle>Delete</DeleteDialogTitle>
              <DeleteConfirmationLabel>Are you sure you want to delete?</DeleteConfirmationLabel>
              <WideDialogContent>
                <StyledCardContent>

                  <Grid item xs={12}>
                    <DeleteLabel>Type DELETE to confirm</DeleteLabel>
                    <TextField
                      id="deleteConfirmation"
                      name="deleteConfirmation"
                      onChange={handleChange}
                      value={values.deleteConfirmation}
                      fullWidth
                      data-cy="delete_dialog_confirmation"
                      onBlur={handleBlur}
                      error={Boolean(errors.deleteConfirmation)}
                      helperText={errors.deleteConfirmation}>
                    </TextField>

                  </Grid>

                </StyledCardContent>

                <DialogActions>
                  <ButtonGrid container>
                    <StyledSubmitButton
                      type="button"
                      color="primary"
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting || isClientUser || isReadOnly}
                      data-cy="delete_dialog_submitButton"
                    >
                      Delete
                  </StyledSubmitButton>

                    <StyledCancelButton
                      data-cy="delete_dialog_cancelButton"
                      color="secondary"
                      onClick={onCancel}
                    >
                      Cancel
                  </StyledCancelButton>
                  </ButtonGrid>
                </DialogActions>
              </WideDialogContent>             
            </Grid>
          </Grid>
        </Dialog>
      );
    }}
  </Formik>
}

export default DeleteEventDialog;
