import React, { useState } from "react";
import { Prompt, useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { Formik, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import { getType } from "typesafe-actions";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { Link as RouterLink } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  DialogActions,
  InputLabel,
  FormControlLabel,
  Paper,
  Button,
  Typography,
  Checkbox,
  Link,
  FormControl,
  FormHelperText,
  Tooltip,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddBoxRoundedIcon from "@material-ui/icons/AddBoxRounded";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import RefreshIcon from "@material-ui/icons/Refresh";
import Label from "../../../common/components/label";
import MoneyField from "../../../common/components/numberFields";
import SsnTextField from "../../../common/components/ssnTextField";
import { rootSelector } from "../../../common/selectors/selectors";
import {
  reportedWageTypes,
  paymentErrorTypes,
} from "../../../common/enums/paymentEnums";
import {
  getBatchPaymentActions,
  createBatchPaymentActions,
  updateBatchPaymentActions,
  resolvePaymentDiscrepancyActions,
  getMiWamDataActions,
} from "../actions";
import { BatchPaymentsDto, paymentDtoInit, MiWamDto } from "../dtos";
import { CompanyDto } from "../../../features/company/dtos";
import { getCompaniesActions } from "../../company/actions";
import { ClaimDto, ClaimInit } from "../../claims/claims.dtos";
import { createClaimActions } from "../../claims/actions";
import {
  IndentGrid,
  MHARadio,
  TMGrid1,
  PaddedTextField,
  BMGrid,
  RemoveIcon,
  ButtonGrid,
  PostButton,
  TableCard,
  ListCardContent,
  StyledSubmitButton,
  StyledCancelButton,
  Icon,
} from "../../../common/styles/styles";
import { requiredError } from "../../../common/constants/strings";
import {
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueGetterParams,
} from "@material-ui/data-grid";
import moment from "moment";
import { Sort } from "@material-ui/icons";

interface AddBatchPaymentDialogProps {
  open: boolean;
  onCancel: () => void;
  isReadOnly: boolean;
  batchId: string;
}

const AddBatchPaymentDialog: React.FC<AddBatchPaymentDialogProps> = ({
  open,
  onCancel,
  isReadOnly,
  batchId,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const baseUrl = process.env.REACT_APP_API_URL ?? "";
  const downloadDocumentUrl = baseUrl + "download/documents/";

  const systemCompaniesList = rootSelector(
    (state) => state.companies.companiesList
  );
  const systemBatchData = rootSelector(
    (state) => state.batchPayments.miWamData
  );
  const systemBatch = rootSelector((state) => state.batchPayments.batch);
  const systemClaim = rootSelector((state) => state.claims.claim);

  const [dto, setDto] = React.useState<BatchPaymentsDto>(
    {} as BatchPaymentsDto
  );
  const [batchData, setBatchData] = React.useState<MiWamDto[]>([]);
  const [pdfScale, setPdfScale] = React.useState(0.4);
  const [numPages, setNumPages] = React.useState(0);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [companies, setCompanies] = React.useState<CompanyDto[]>([]);
  const [shouldBlockNavigation, setShouldBlockNavigation] =
    React.useState(false);
  const [fromPost, setFromPost] = React.useState<boolean>(false);
  const [checkClaim, setCheckClaim] = React.useState<boolean>(false);
  const [sortListOfChargesBy, setSortListOfChargesBy] = useState<
    "organic" | "ssn"
  >("organic");

  React.useEffect(() => {
    dispatch({ type: getType(getCompaniesActions.request) });
    if (batchId !== undefined && batchId.length !== 0) {
      dispatch({
        type: getType(getBatchPaymentActions.request),
        payload: batchId,
      });
    }
  }, [dispatch, batchId]);

  React.useEffect(
    () =>
      setCompanies(
        (
          JSON.parse(JSON.stringify(systemCompaniesList)) as CompanyDto[]
        ).filter((f) => f.name !== undefined && f.name.length !== 0)
      ),
    [systemCompaniesList]
  );
  React.useEffect(
    () => setBatchData(JSON.parse(JSON.stringify(systemBatchData))),
    [systemBatchData]
  );

  React.useEffect(() => {
    if (
      !!dto.employerId &&
      companies.length > 0 &&
      dto.batchPaymentTypeId > 0
    ) {
      let company = companies.find((f) => f.companyId === dto.employerId);
      if (!!company)
        dispatch({
          type: getType(getMiWamDataActions.request),
          payload: { employerId: +company.suta, fileName: dto.fileName },
        });
    }
  }, [dto, companies]);

  React.useEffect(() => {
    let batch: BatchPaymentsDto = JSON.parse(JSON.stringify(systemBatch));
    if (batch.charges !== undefined && batch.charges.length === 0) {
      batch.charges.push({
        chargeAmount: "",
        ssn: "",
      });
    }
    setDto(batch);
  }, [systemBatch]);

  React.useEffect(() => {
    if (checkClaim) {
      setShouldBlockNavigation(false);
      dispatch({
        type: getType(updateBatchPaymentActions.request),
        payload: dto,
      });
      dispatch({
        type: getType(getBatchPaymentActions.request),
        payload: dto.id,
      });
      setCheckClaim(false);
    }
  }, [systemClaim, dto, checkClaim]);

  const dataColumns: GridColDef[] = [
    {
      field: "employerId",
      headerName: "Employer Id",
      description: "Employer Id",
      width: 120,
    },
    {
      field: "claimantSsn",
      headerName: "Claimant Ssn",
      description: "Claimant Ssn",
      width: 120,
    },
    {
      field: "processedDate1",
      headerName: "Processed Date",
      description: "Processed Date",
      width: 120,
      valueGetter: (params: GridValueGetterParams) =>
        `${
          ((params.getValue(params.id, "processedDate") || "") as string).split(
            "T"
          )[0]
        }`,
      //valueGetter: (params: GridValueGetterParams) => moment(`${params.getValue(params.id, 'processedDate')}`).format('MM/DD/yyyy'),
    },
    {
      field: "weekEndingDate1",
      headerName: "Week Ending Date",
      description: "Week Ending Date",
      width: 120,
      valueGetter: (params: GridValueGetterParams) =>
        `${
          (
            (params.getValue(params.id, "weekEndingDate") || "") as string
          ).split("T")[0]
        }`,
      //valueGetter: (params: GridValueGetterParams) => moment(`${params.getValue(params.id, 'weekEndingDate')}`).format('MM/DD/yyyy'),
    },
    {
      field: "adjustmentType",
      headerName: "Adjustment Type",
      description: "Adjustment Type",
      width: 100,
    },
    {
      field: "earnedIncome",
      headerName: "Earned Income",
      description: "Earned Income",
      width: 100,
    },
    {
      field: "amount",
      headerName: "Amount",
      description: "Amount",
      width: 100,
    },
    {
      field: "claimantLastName",
      headerName: "Claimant Last Name",
      description: "Claimant Last Name",
      width: 120,
    },
    {
      field: "claimantFirstInitial",
      headerName: "Claimant First Initial",
      description: "Claimant First Initial",
      width: 100,
    },
  ];

  const handleZoomIn = () => {
    let newScale = pdfScale >= 2 ? 2 : pdfScale + 0.2;
    setPdfScale(newScale);
  };

  const handleZoomOut = () => {
    let newScale = pdfScale <= 0.2 ? 0.2 : pdfScale - 0.2;
    setPdfScale(newScale);
  };

  const handleCreateClaim = (batch: BatchPaymentsDto, paymentId: string) => {
    let payment = batch.charges.find((charge) => charge.id === paymentId);
    let claimantNameParts =
      payment === undefined
        ? ["", ""]
        : payment?.claimant?.split(" ") ?? ["", ""];
    let newClaim: ClaimDto = { ...ClaimInit };
    newClaim.employerId = batch.employerId;
    newClaim.claimantSsn = payment === undefined ? "" : payment.ssn ?? "";
    newClaim.claimantFirstName =
      claimantNameParts[0] === undefined ? "" : claimantNameParts[0];
    newClaim.claimantLastName =
      claimantNameParts[1] === undefined ? "" : claimantNameParts[1];
    newClaim.claimantPaymentList = [];
    newClaim.notes = [];

    batch.needsUpdate = true;
    dispatch({ type: getType(getBatchPaymentActions.success), payload: batch });
    dispatch({ type: getType(createClaimActions.request), payload: newClaim });
    setCheckClaim(true);
  };

  const handleResolveError = (
    isValid: boolean,
    batch: BatchPaymentsDto,
    paymentDiscrepancyId: string
  ) => {
    batch.needsUpdate = true;
    dispatch({ type: getType(getBatchPaymentActions.success), payload: batch });
    dispatch({
      type: getType(resolvePaymentDiscrepancyActions.request),
      payload: { id: paymentDiscrepancyId, isValid: isValid },
    });
  };

  const cancelDialog = () => onCancel(); //history.push(window.location.pathname);

  function onDocumentLoadSuccess({ numPages }: any) {
    setPageNumber(1);
    setNumPages(numPages);
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer className="MuiDataGrid-toolbarContainer">
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <Formik
      initialValues={dto}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={Yup.object().shape({
        statementDate: Yup.date().required(requiredError),
        reportedWageTypeId: Yup.number()
          .min(1, "Please select reporting type.")
          .max(3)
          .required(requiredError),
        employerId: Yup.string().required(requiredError),
        periodCoveredFromDate: Yup.date().nullable(),
        periodCoveredToDate: Yup.date().nullable(),
        dueDateTime: Yup.date().nullable(),
        charges: Yup.array().of(
          Yup.object().shape({
            ssn: Yup.string().required(requiredError),
            chargeAmount: Yup.string().required(requiredError),
            chargeDate: Yup.date().required(requiredError),
            //.notOneOf([undefined, null], 'Please enter the charge date.').required(requiredError),
            reportedWages: Yup.string().required(requiredError),
          })
        ),
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setTimeout(() => {
          setSubmitting(false);
          resetForm();
          setShouldBlockNavigation(false);
        }, 700);

        let tempDto = { ...values };
        tempDto.needsUpdate = true;
        dispatch({
          type: getType(getBatchPaymentActions.success),
          payload: tempDto,
        });

        if (fromPost) tempDto.isComplete = false;

        dispatch({
          type: getType(
            values.id === undefined || values.id.length === 0
              ? createBatchPaymentActions.request
              : updateBatchPaymentActions.request
          ),
          payload: tempDto,
        });

        if (!fromPost) {
          onCancel();
        }
        setFromPost(false);
      }}
      enableReinitialize={true}
    >
      {(props) => {
        const {
          values,
          errors,
          isSubmitting,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          handleReset,
        } = props;
        return (
          <form onSubmit={handleSubmit}>
            <FieldArray
              name="charges"
              render={(arrayHelpers) => (
                <Dialog
                  open={open}
                  data-cy="eligibility_event_dialog"
                  fullWidth
                  maxWidth="xl"
                >
                  <DialogContent>
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={5}>
                          {values.fileName !== undefined &&
                          values.fileName.length === 0 ? (
                            <Paper
                              style={{
                                maxHeight: 800,
                                width: "90%",
                                maxWidth: window.parent.innerWidth - 252,
                                backgroundColor: "lightgray",
                                overflow: "auto",
                              }}
                              elevation={3}
                            >
                              <Grid container justifyContent="center">
                                <span
                                  style={{
                                    marginTop: 84,
                                    fontSize: 32,
                                    fontWeight: 400,
                                  }}
                                >
                                  PDF VIEWER
                                </span>
                              </Grid>
                            </Paper>
                          ) : (
                            <>
                              {values.batchPaymentTypeId === 0 ? (
                                <Grid item xs={12}>
                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent="center"
                                    hidden={numPages === 0}
                                  >
                                    <Button
                                      title="Reset Zoom"
                                      color="secondary"
                                      onClick={() => setPdfScale(0.4)}
                                      data-cy="claim_pdf_zoomIn"
                                    >
                                      <RefreshIcon />
                                    </Button>
                                  </Grid>

                                  <Grid
                                    container
                                    item
                                    xs={12}
                                    justifyContent="center"
                                    hidden={numPages === 0}
                                  >
                                    <Button
                                      title="Zoom in pdf"
                                      color="secondary"
                                      onClick={handleZoomOut}
                                      data-cy="claim_pdf_zoomOut"
                                    >
                                      <ZoomOutIcon />
                                    </Button>
                                    <Pagination
                                      page={pageNumber}
                                      count={numPages}
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      onChange={(e, p) => {
                                        setPageNumber(p);
                                      }}
                                    />
                                    <Button
                                      title="Zoom in pdf"
                                      color="secondary"
                                      onClick={handleZoomIn}
                                      data-cy="claim_pdf_zoomIn"
                                    >
                                      <ZoomInIcon />
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Paper
                                      style={{
                                        maxHeight: 570,
                                        maxWidth: 480,
                                        overflow: "auto",
                                      }}
                                      elevation={3}
                                    >
                                      <Document
                                        file={
                                          values.fileName !== undefined &&
                                          (values.fileName ?? "").length !== 0
                                            ? downloadDocumentUrl +
                                                encodeURIComponent(
                                                  values.fileName
                                                ) ?? ""
                                            : null
                                        }
                                        onLoadSuccess={onDocumentLoadSuccess}
                                      >
                                        <Page
                                          pageNumber={pageNumber}
                                          width={900}
                                          scale={pdfScale}
                                        />
                                      </Document>
                                    </Paper>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Grid
                                  item
                                  xs={12}
                                  style={{
                                    height: `${
                                      batchData.length > 20 ? "80" : "70"
                                    }vh`,
                                  }}
                                >
                                  <DataGrid
                                    rows={batchData}
                                    columns={dataColumns}
                                    pageSize={25}
                                    density="compact"
                                    components={{
                                      Toolbar: CustomToolbar,
                                    }}
                                  />
                                </Grid>
                              )}
                            </>
                          )}
                        </Grid>

                        <Grid item xs={7}>
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                              display="block"
                            >
                              Add Batch Payment
                            </Typography>
                            <br />
                          </Grid>
                          <Grid item xs={12}>
                            <InputLabel>Charge Statement</InputLabel>
                          </Grid>

                          <IndentGrid container>
                            <Grid item xs={12}>
                              <Label>Reporting Type</Label>
                            </Grid>
                            {[...reportedWageTypes]
                              .filter((wageType) => wageType.id !== 0)
                              .map((wageType, index) => (
                                <Grid item xs={4} key={wageType.name + index}>
                                  <FormControlLabel
                                    key={"FormControl" + wageType.name + index}
                                    id="reportedWageTypeId"
                                    name="reportedWageTypeId"
                                    onChange={(
                                      e:
                                        | React.ChangeEvent<HTMLInputElement>
                                        | React.ChangeEvent<{}>,
                                      checked: boolean
                                    ) => {
                                      setFieldValue(
                                        "reportedWageTypeId",
                                        +(e.target as HTMLInputElement).value
                                      );
                                    }}
                                    onBlur={(e) => {
                                      handleBlur(e);
                                      setShouldBlockNavigation(true);
                                    }}
                                    checked={
                                      wageType.id === values.reportedWageTypeId
                                    }
                                    value={wageType.id}
                                    control={
                                      <MHARadio
                                        disabled={isReadOnly}
                                        disableRipple
                                        size="small"
                                      />
                                    }
                                    label={wageType.name}
                                    data-cy="batchPayments_reportedWageTypeId"
                                  />
                                </Grid>
                              ))}
                            <FormControl
                              fullWidth
                              error={Boolean(errors.reportedWageTypeId)}
                            >
                              <FormHelperText id="reportedWageTypeId_helper_text">
                                {errors.reportedWageTypeId &&
                                  errors.reportedWageTypeId}
                              </FormHelperText>
                            </FormControl>
                          </IndentGrid>

                          <IndentGrid container spacing={2}>
                            <Grid item sm={12} md={4}>
                              <KeyboardDatePicker
                                margin="none"
                                id="statementDate"
                                name="statementDate"
                                disabled={isReadOnly}
                                label="Statement Date"
                                format="MM/DD/yyyy"
                                fullWidth
                                InputLabelProps={{ required: true }}
                                value={
                                  values.statementDate
                                    ? moment.utc(values.statementDate)
                                    : null
                                }
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setShouldBlockNavigation(true);
                                }}
                                onChange={(date: MaterialUiPickersDate) => {
                                  setFieldValue("statementDate", date);
                                }}
                                error={errors.statementDate !== undefined}
                                helperText={
                                  errors.statementDate && errors.statementDate
                                }
                                data-cy="batchPayments_statementDate"
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </Grid>

                            <TMGrid1 item sm={12} md={4}>
                              <Autocomplete
                                id="employerId"
                                options={companies}
                                getOptionLabel={(option) => option.suta ?? ""}
                                autoComplete
                                disabled={isReadOnly}
                                includeInputInList
                                value={
                                  companies.find(
                                    (company) =>
                                      company.companyId === values.employerId
                                  ) || null
                                }
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setShouldBlockNavigation(true);
                                }}
                                onChange={(_e: any, v: CompanyDto | null) => {
                                  setFieldValue(
                                    "employerId",
                                    v?.companyId ?? ""
                                  );
                                }}
                                renderInput={(params) => (
                                  <PaddedTextField
                                    {...params}
                                    id="employerId"
                                    size="small"
                                    margin="none"
                                    value={values.employerId || ""}
                                    label="SUTA"
                                    InputLabelProps={{ required: true }}
                                    disabled={isReadOnly}
                                    onBlur={(e) => {
                                      handleBlur(e);
                                      setShouldBlockNavigation(true);
                                    }}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "employerId",
                                        companies.find(
                                          (company) =>
                                            company.suta === e.target.value ??
                                            ""
                                        )?.companyId ?? ""
                                      );
                                    }}
                                    error={Boolean(errors.employerId)}
                                    helperText={errors.employerId}
                                    data-cy="batchPayments_suta"
                                    fullWidth
                                  />
                                )}
                              />
                            </TMGrid1>

                            <TMGrid1 item sm={12} md={4}>
                              <Autocomplete
                                id="employerName"
                                options={companies}
                                getOptionLabel={(option) => option.name}
                                value={
                                  companies.find(
                                    (company) =>
                                      company.companyId === values.employerId
                                  ) || null
                                }
                                includeInputInList
                                disabled={isReadOnly}
                                autoComplete
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setShouldBlockNavigation(true);
                                }}
                                onChange={(_e: any, v: CompanyDto | null) => {
                                  setFieldValue(
                                    "employerId",
                                    v?.companyId ?? ""
                                  );
                                }}
                                renderInput={(params) => (
                                  <PaddedTextField
                                    {...params}
                                    id="employerName"
                                    size="small"
                                    margin="none"
                                    disabled={isReadOnly}
                                    value={values.employerId || ""}
                                    label="Employer"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "employerId",
                                        companies.find(
                                          (company) =>
                                            company.suta === e.target.value ??
                                            ""
                                        )?.companyId ?? ""
                                      );
                                    }}
                                    onBlur={(e) => {
                                      handleBlur(e);
                                      setShouldBlockNavigation(true);
                                    }}
                                    error={Boolean(errors.employerId)}
                                    helperText={errors.employerId}
                                    data-cy="batchPayments_employer"
                                    fullWidth
                                  />
                                )}
                              />
                            </TMGrid1>

                            <Grid item xs={4}>
                              <KeyboardDatePicker
                                margin="none"
                                id="periodCoveredFromDate"
                                name="periodCoveredFromDate"
                                disabled={isReadOnly}
                                label="Period Converted From"
                                format="MM/DD/yyyy"
                                fullWidth
                                value={
                                  values.periodCoveredFromDate
                                    ? moment.utc(values.periodCoveredFromDate)
                                    : null
                                }
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setShouldBlockNavigation(true);
                                }}
                                onChange={(date: MaterialUiPickersDate) => {
                                  setFieldValue("periodCoveredFromDate", date);
                                }}
                                data-cy="batchPayments_periodCoveredFromDate"
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>

                            <Grid item xs={4}>
                              <KeyboardDatePicker
                                margin="none"
                                id="periodCoveredToDate"
                                name="periodCoveredToDate"
                                disabled={isReadOnly}
                                label="Period Converted To"
                                format="MM/DD/yyyy"
                                fullWidth
                                value={
                                  values.periodCoveredToDate
                                    ? moment.utc(values.periodCoveredToDate)
                                    : null
                                }
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setShouldBlockNavigation(true);
                                }}
                                onChange={(date: MaterialUiPickersDate) => {
                                  setFieldValue("periodCoveredToDate", date);
                                }}
                                data-cy="batchPayments_periodCoveredToDate"
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>

                            <Grid item xs={4}>
                              <KeyboardDatePicker
                                margin="none"
                                name="dueDateTime"
                                disabled={isReadOnly}
                                label="Due Date"
                                emptyLabel="Not on File"
                                format="MM/DD/yyyy"
                                fullWidth
                                value={
                                  values.dueDateTime
                                    ? moment.utc(values.dueDateTime)
                                    : null
                                }
                                onBlur={(e) => {
                                  handleBlur(e);
                                  setShouldBlockNavigation(true);
                                }}
                                onChange={(date: MaterialUiPickersDate) => {
                                  setFieldValue("dueDateTime", date);
                                }}
                                data-cy="batchPayments_dueDateTime"
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </Grid>
                          </IndentGrid>

                          <IndentGrid container spacing={2}></IndentGrid>

                          <BMGrid
                            item
                            xs={12}
                            container
                            spacing={2}
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <InputLabel>List of Charges</InputLabel>
                            </Grid>

                            <Grid item>
                              <Tooltip title="Sort charges by SSN then by Week Ending Date">
                                <Button
                                  color="primary"
                                  disableElevation
                                  onClick={() => {
                                    setFieldValue(
                                      "charges",
                                      [...values.charges]
                                        .sort((a, b) => {
                                          const isBadSsn = (
                                            value: string
                                          ): boolean => {
                                            return (
                                              !value ||
                                              value.trim().length === 0 ||
                                              value.includes("_")
                                            );
                                          };

                                          if (
                                            (!isBadSsn(a.ssn) &&
                                              isBadSsn(b.ssn)) ||
                                            (isBadSsn(a.ssn) && isBadSsn(b.ssn))
                                          ) {
                                            return -1;
                                          } else if (
                                            isBadSsn(a.ssn) &&
                                            !isBadSsn(b.ssn)
                                          ) {
                                            return 1;
                                          }

                                          return (
                                            a.ssn.localeCompare(b.ssn) ||
                                            new Date(
                                              a.chargeDate ?? ""
                                            ).getTime() -
                                              new Date(
                                                b.chargeDate ?? ""
                                              ).getTime()
                                          );
                                        })
                                        .map((charge) => charge)
                                    );
                                  }}
                                  variant={
                                    /*sortListOfChargesBy === "ssn"
                                      ? "contained"
                                      :*/ "outlined"
                                  }
                                >
                                  SORT BY SSN
                                </Button>
                              </Tooltip>
                            </Grid>
                          </BMGrid>

                          <IndentGrid container spacing={2}>
                            {values.charges &&
                              values.charges.map((charge, index) => (
                                <React.Fragment key={charge.id}>
                                  <TMGrid1 item xs={6} sm={2}>
                                    <TextField
                                      id={`charges[${index}].ssn`}
                                      name={`charges[${index}].ssn`}
                                      size="small"
                                      margin="none"
                                      value={charge.ssn || ""}
                                      label={"SSN"}
                                      disabled={isReadOnly}
                                      InputLabelProps={{ required: true }}
                                      onChange={(e) => {
                                        handleChange(e);
                                        setShouldBlockNavigation(true);
                                      }}
                                      onBlur={(e) => {
                                        handleBlur(e);
                                        setShouldBlockNavigation(true);
                                      }}
                                      error={Boolean(
                                        getIn(errors, `charges[${index}].ssn`)
                                      )}
                                      helperText={
                                        getIn(
                                          errors,
                                          `charges[${index}].ssn`
                                        ) &&
                                        getIn(errors, `charges[${index}].ssn`)
                                      }
                                      data-cy="batchPayments_ssn"
                                      fullWidth
                                      InputProps={{
                                        inputComponent: SsnTextField as any,
                                      }}
                                    />
                                  </TMGrid1>

                                  <Grid
                                    item
                                    xs={6}
                                    sm={3}
                                    key={"chargeDate_" + index}
                                  >
                                    <KeyboardDatePicker
                                      margin="none"
                                      label="Week Ending Date"
                                      id={`charges[${index}].chargeDate`}
                                      name={`charges[${index}].chargeDate`}
                                      format="MM/DD/yyyy"
                                      disabled={isReadOnly}
                                      value={
                                        charge.chargeDate
                                          ? moment.utc(charge.chargeDate)
                                          : null
                                      }
                                      fullWidth
                                      InputLabelProps={{
                                        required: true,
                                        shrink: true,
                                      }}
                                      onBlur={(e) => {
                                        handleBlur(e);
                                        setShouldBlockNavigation(true);
                                      }}
                                      onChange={(date: MaterialUiPickersDate) =>
                                        setFieldValue(
                                          `charges[${index}].chargeDate`,
                                          date
                                        )
                                      }
                                      KeyboardButtonProps={{
                                        "aria-label": "date received",
                                      }}
                                      data-cy="batchPayments_chargeDate"
                                      error={Boolean(
                                        getIn(
                                          errors,
                                          `charges[${index}].chargeDate`
                                        )
                                      )}
                                      helperText={Boolean(
                                        getIn(
                                          errors,
                                          `charges[${index}].chargeDate`
                                        ) &&
                                          getIn(
                                            errors,
                                            `charges[${index}].chargeDate`
                                          )
                                      )}
                                    />
                                  </Grid>

                                  <TMGrid1
                                    item
                                    xs={6}
                                    sm={3}
                                    key={"chargeAmount_" + index}
                                  >
                                    <TextField
                                      id={`charges[${index}].chargeAmount`}
                                      size="small"
                                      disabled={isReadOnly}
                                      margin="none"
                                      label="Charge Amount"
                                      value={charge.chargeAmount || ""}
                                      name={`charges[${index}].chargeAmount`}
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      onBlur={(e) => {
                                        handleBlur(e);
                                        setShouldBlockNavigation(true);
                                      }}
                                      error={Boolean(
                                        getIn(
                                          errors,
                                          `charges[${index}].chargeAmount`
                                        )
                                      )}
                                      data-cy="batchPayments_chargeAmount"
                                      helperText={
                                        Boolean(
                                          getIn(
                                            errors,
                                            `charges[${index}].chargeAmount`
                                          )
                                        ) &&
                                        getIn(
                                          errors,
                                          `charges[${index}].chargeAmount`
                                        )
                                      }
                                      fullWidth
                                      InputLabelProps={{ required: true }}
                                      InputProps={{
                                        inputComponent: MoneyField as any,
                                      }}
                                    />
                                  </TMGrid1>
                                  <TMGrid1
                                    item
                                    xs={6}
                                    sm={3}
                                    key={"reportedWages_" + index}
                                  >
                                    <TextField
                                      id={`charges[${index}].reportedWages`}
                                      size="small"
                                      disabled={isReadOnly}
                                      margin="none"
                                      label="Wages Reported"
                                      value={charge.reportedWages || ""}
                                      name={`charges[${index}].reportedWages`}
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      onBlur={(e) => {
                                        handleBlur(e);
                                        setShouldBlockNavigation(true);
                                      }}
                                      error={Boolean(
                                        getIn(
                                          errors,
                                          `charges[${index}].reportedWages`
                                        )
                                      )}
                                      data-cy="batchPayments_reportedWages"
                                      helperText={
                                        Boolean(
                                          getIn(
                                            errors,
                                            `charges[${index}].reportedWages`
                                          )
                                        ) &&
                                        getIn(
                                          errors,
                                          `charges[${index}].reportedWages`
                                        )
                                      }
                                      fullWidth
                                      InputLabelProps={{ required: true }}
                                      InputProps={{
                                        inputComponent: MoneyField as any,
                                      }}
                                    />
                                  </TMGrid1>

                                  <Grid
                                    container
                                    item
                                    xs={6}
                                    sm={1}
                                    key={"addRemovePayment_" + index}
                                    alignContent="center"
                                    justifyContent="flex-start"
                                  >
                                    {((charge.id === undefined ||
                                      charge.id.length === 0) &&
                                      values.charges.length <= 1) ||
                                    index === values.charges.length - 1 ? (
                                      <Icon
                                        title="Add Charge"
                                        color="secondary"
                                        onClick={() => {
                                          arrayHelpers.push(
                                            JSON.parse(
                                              JSON.stringify(paymentDtoInit)
                                            )
                                          );
                                        }}
                                        data-cy="batchPayments_addChargeButton"
                                      >
                                        <AddBoxRoundedIcon />
                                      </Icon>
                                    ) : index < values.charges.length - 1 ? (
                                      <RemoveIcon
                                        title="Remove Charge"
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                        data-cy="batchPayments_removeChargeButton"
                                      >
                                        <IndeterminateCheckBoxIcon />
                                      </RemoveIcon>
                                    ) : null}
                                  </Grid>
                                </React.Fragment>
                              ))}
                          </IndentGrid>

                          <Grid container>
                            <ButtonGrid container>
                              <PostButton
                                type="button"
                                color="primary"
                                islargebutton={true}
                                onClick={() => {
                                  setFromPost(true);
                                  handleSubmit();
                                }}
                                disabled={isSubmitting || isReadOnly}
                                data-cy="batchPayments_submitPost"
                              >
                                POST
                              </PostButton>
                            </ButtonGrid>
                          </Grid>

                          <BMGrid
                            item
                            xs={12}
                            hidden={
                              !values.charges?.some(
                                (charge) =>
                                  charge.discrepancy !== undefined &&
                                  charge.discrepancy.error !== 0
                              )
                            }
                          >
                            <InputLabel>Discrepancies</InputLabel>
                          </BMGrid>
                          <Grid container spacing={2}>
                            {values.charges?.some(
                              (charge) =>
                                charge.discrepancy !== undefined &&
                                charge.discrepancy.error !== 0
                            ) &&
                              values.charges
                                .filter(
                                  (charge) =>
                                    charge.discrepancy !== undefined &&
                                    charge.discrepancy.error !== 0
                                )
                                .filter(
                                  (charge, i, s) =>
                                    i ===
                                    s.findIndex(
                                      (fi) =>
                                        fi.ssn === charge.ssn &&
                                        fi.discrepancy?.error ===
                                          charge.discrepancy?.error
                                    )
                                )
                                .map((charge, index) => (
                                  <Grid
                                    item
                                    xs={11}
                                    key={"discrepancyCard_" + index}
                                  >
                                    <TableCard raised>
                                      <ListCardContent>
                                        <Grid container item xs={12}>
                                          <Grid item xs={4}>
                                            <Typography
                                              variant="body2"
                                              color="textPrimary"
                                            >
                                              Claimant
                                            </Typography>
                                            <Link
                                              component={RouterLink}
                                              to={
                                                charge.claimId === undefined
                                                  ? `/claims`
                                                  : `/claim/${charge.claimId}`
                                              }
                                              variant="subtitle1"
                                              color="secondary"
                                            >
                                              <Typography
                                                variant="body2"
                                                color="secondary"
                                              >
                                                {charge.claimant ===
                                                  undefined ||
                                                charge.claimant.length <= 1
                                                  ? "Not on file"
                                                  : charge.claimant}
                                              </Typography>
                                            </Link>
                                          </Grid>

                                          <Grid item xs={3}>
                                            <Typography
                                              variant="body2"
                                              color="textPrimary"
                                            >
                                              SSN
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              color="secondary"
                                            >
                                              {charge.ssn ?? ""}
                                            </Typography>
                                          </Grid>

                                          <Grid item xs={3}>
                                            <Typography
                                              variant="body2"
                                              color="textPrimary"
                                            >
                                              Error
                                            </Typography>
                                            <Typography
                                              variant="body2"
                                              color="secondary"
                                            >
                                              {paymentErrorTypes.find(
                                                (f) =>
                                                  f.id ===
                                                  (charge.discrepancy?.error ??
                                                    0)
                                              )?.name ?? ""}
                                            </Typography>
                                          </Grid>

                                          {charge.discrepancy?.error === 3 && (
                                            <Grid item xs={2}>
                                              <PostButton
                                                type="button"
                                                color="primary"
                                                onClick={() =>
                                                  handleCreateClaim(
                                                    values,
                                                    charge.id ?? ""
                                                  )
                                                }
                                                disabled={
                                                  isSubmitting || isReadOnly
                                                }
                                                data-cy="changeMe"
                                              >
                                                CREATE
                                              </PostButton>
                                            </Grid>
                                          )}

                                          {charge.discrepancy?.error !== 3 && (
                                            <Grid item xs={2}>
                                              <PostButton
                                                type="button"
                                                color="primary"
                                                onClick={() =>
                                                  handleResolveError(
                                                    true,
                                                    values,
                                                    charge.discrepancy?.id ?? ""
                                                  )
                                                }
                                                disabled={
                                                  isSubmitting || isReadOnly
                                                }
                                                data-cy="changeMe"
                                              >
                                                Valid Charge
                                              </PostButton>

                                              <PostButton
                                                type="button"
                                                orangecolor={true}
                                                onClick={() =>
                                                  handleResolveError(
                                                    false,
                                                    values,
                                                    charge.discrepancy?.id ?? ""
                                                  )
                                                }
                                                disabled={
                                                  isSubmitting || isReadOnly
                                                }
                                                data-cy="changeMe"
                                              >
                                                Protest
                                              </PostButton>
                                            </Grid>
                                          )}
                                        </Grid>
                                      </ListCardContent>
                                    </TableCard>
                                  </Grid>
                                ))}
                          </Grid>

                          <IndentGrid>
                            <Grid item xs={12}>
                              <br />
                              <FormControl
                                error={
                                  !values.charges?.some(
                                    (charge) => charge.id !== undefined
                                  ) ||
                                  (values.isComplete === true &&
                                    values.charges?.some(
                                      (charge) =>
                                        charge.discrepancy !== undefined &&
                                        charge.discrepancy.error !== 0
                                    ))
                                }
                              >
                                <>
                                  <FormControlLabel
                                    id="isComplete"
                                    name="isComplete"
                                    onChange={handleChange}
                                    onBlur={(e) => {
                                      handleBlur(e);
                                      setShouldBlockNavigation(true);
                                    }}
                                    value={values.isComplete || false}
                                    checked={values.isComplete || false}
                                    disabled={isReadOnly}
                                    control={
                                      <Checkbox
                                        size="small"
                                        style={{ marginLeft: 5 }}
                                        data-cy="batchPayments_isCompleteCheckbox"
                                      />
                                    }
                                    label={
                                      <Typography variant="subtitle2">
                                        Complete
                                      </Typography>
                                    }
                                  />
                                  {!values.charges?.some(
                                    (charge) => charge.id !== undefined
                                  ) ||
                                    (values.isComplete === true &&
                                      values.charges?.some(
                                        (charge) =>
                                          charge.discrepancy !== undefined &&
                                          charge.discrepancy.error !== 0
                                      ) && (
                                        <FormHelperText id="is_complete_helper_text">
                                          Please process all Discrepanies before
                                          this is complete.
                                        </FormHelperText>
                                      ))}
                                </>
                              </FormControl>
                            </Grid>
                          </IndentGrid>
                        </Grid>
                      </Grid>

                      <DialogActions>
                        <ButtonGrid container>
                          <StyledSubmitButton
                            type="button"
                            color="primary"
                            onClick={() => {
                              if (
                                !(
                                  values.isComplete === true &&
                                  values.charges?.some(
                                    (charge) =>
                                      charge.discrepancy !== undefined &&
                                      charge.discrepancy.error !== 0
                                  )
                                ) &&
                                values.charges?.some(
                                  (charge) => charge.id !== undefined
                                )
                              )
                                handleSubmit();
                            }}
                            disabled={
                              isSubmitting ||
                              isReadOnly ||
                              (values.isComplete === true &&
                                values.charges?.some(
                                  (charge) =>
                                    charge.discrepancy !== undefined &&
                                    charge.discrepancy.error !== 0
                                )) ||
                              values.charges?.some(
                                (charge) => charge.id === undefined
                              )
                            }
                            data-cy="batchPayments_submitButton"
                          >
                            Save
                          </StyledSubmitButton>

                          <StyledCancelButton
                            data-cy="batchPayments_cancelButton"
                            color="secondary"
                            onClick={() => {
                              handleReset();
                              cancelDialog();
                            }}
                          >
                            {shouldBlockNavigation ? "Cancel" : "Back"}
                          </StyledCancelButton>
                        </ButtonGrid>
                      </DialogActions>
                    </form>
                  </DialogContent>
                  <Prompt
                    when={shouldBlockNavigation}
                    message="You have unsaved edits, are you sure you want to leave?"
                  />
                </Dialog>
              )}
            />
          </form>
        );
      }}
    </Formik>
  );
};

export default AddBatchPaymentDialog;
