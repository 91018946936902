import styled from 'styled-components';

// material ui
import { Container, DialogTitle, DialogContent, CardContent } from '@material-ui/core';


// Styled resetPassword components ....
export const ChangePasswordContainer = styled(Container).attrs({ component: 'main'})`
    margin-top: 50px;
`;
export const H3 = styled.h3`
    margin-bottom: 0;
`;

export const StyledDialogTitle = styled(DialogTitle)`
    padding: 0 16px;
`;

export const StyledDialogContent = styled(DialogContent)`
    padding: 0 8px;
`;

export const StyledCardContent = styled(CardContent)`
    padding: 0 8px;
`;