import { call, put, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { changePasswordApi } from './changePassword.api';
import { changePasswordActions } from './changePassword.actions';
import { logoutActions } from '../login/login.actions';
import { tenantId } from '../../../common/constants/strings';


function* handleChangePassword(dto: ReturnType<typeof changePasswordActions.request>) {

  try {

    const response: boolean = yield call(changePasswordApi, dto.payload);
    if (response) {
      yield put({ type: getType(changePasswordActions.success), payload: true });
      yield put({ type: getType(logoutActions.request) });
      setTimeout(() => { window.location.href = `/${localStorage.getItem(tenantId) ?? 'mha'}/login`; }, 1500);
    }
    else {
      
      put({ type: getType(changePasswordActions.failure), payload: "Change Password Failed" });
    }

  }
  catch (error) {
    put({ type: getType(changePasswordActions.failure), payload: "Change Password Failed" });
  }

}

export function* watchChangePasswordRequest() {
  try {
    yield takeLatest(getType(changePasswordActions.request), handleChangePassword);
  }
  catch (err) {
  }
}