import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { getType } from 'typesafe-actions';
import { Grid, Card, Typography, MenuItem, Box } from '@material-ui/core';
import { Prompt } from 'react-router';
import moment from 'moment';
import { showNotification } from '../../../common/actionCreators/dispatchNotification';
import MoneyField from '../../../common/components/numberFields';
import { rootSelector } from '../../../common/selectors/selectors';
import * as Styles from '../../../common/styles/styles';
import * as Style from '../styles';

import { updateCompanyActions, createCompanyActions } from '../actions';
import { companyInit, EmployerChargeInit } from '../dtos';
import UnemploymentChargesCard from './unemploymentCard';
import { isReadOnly } from '../../../common/dtos/auth';
import { requiredError } from '../../../common/constants/strings';
import { FeatureTypeEnum } from '../../../common/enums/featureTypeEnum';

const UnemploymentCharges: React.FC = () => {
  const dispatch = useDispatch();

  const systemCompany = rootSelector((state) => state.companies.company);

  const [dto, setDto] = React.useState(companyInit);
  const [shouldBlockNavigation, setShouldBlockNavigation] =
    React.useState(false);
  const [formDto, setFormDto] = React.useState({ year: 0, charge: undefined });

  const year = new Date().getFullYear();

  const dateRanges = [
    { id: 1, date: year },
    { id: 2, date: year - 1 },
    { id: 3, date: year - 2 },
  ];

  React.useEffect(() => {
    setDto(systemCompany);
    setShouldBlockNavigation(false);
  }, [systemCompany]);

  return (
    <>
      <Box p={1}>
        <Grid
          container
          data-cy='employer_unemploymentCharges_container'
          spacing={2}
        >
          <Formik
            initialValues={EmployerChargeInit}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={Yup.object().shape({
              year: Yup.number().required(requiredError),
              charge: Yup.string()
                .required(requiredError)
                .max(15, 'Must be 15 characters or less'),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                setSubmitting(false);
              }, 750);

              if (
                dto.companyId === undefined ||
                dto.companyId.length === 0 ||
                dto.name.length === 0 ||
                dto.fein === undefined ||
                dto.fein.length === 0 ||
                dto.suta === undefined ||
                dto.suta.length === 0
              ) {
                showNotification(
                  'Please save employer details before attempting to add to unemployment agency charges.'
                );
              } else {
                let dtoToSave = dto;
                dtoToSave.emails = dtoToSave.emails.filter(
                  (e) => e.emailAddress.length !== 0
                );
                dtoToSave.phones = dtoToSave.phones.filter(
                  (p) => p.phoneNumber.length !== 0
                );
                dtoToSave.notes = dtoToSave.notes.filter(
                  (n) => n.entry.length !== 0
                );
                dtoToSave.addresses = dtoToSave.addresses.filter(
                  (a) =>
                    a.address1 !== undefined &&
                    a.address1!.length !== 0 &&
                    a.city !== undefined &&
                    a.city!.length !== 0
                );
                dtoToSave.businessUnitList = dtoToSave.businessUnitList.filter(
                  (b) => b.name.length !== 0
                );
                dtoToSave.employeeCountList =
                  dtoToSave.employeeCountList.filter((e) => e.year !== 0);
                dtoToSave.employerChargeList =
                  dtoToSave.employerChargeList.filter(
                    (e) =>
                      e.year !== 0 &&
                      e.charge !== null &&
                      e.charge !== undefined &&
                      e.charge !== ''
                  );

                dtoToSave.employerChargeList.push(values);

                dispatch({
                  type: dtoToSave.companyId
                    ? getType(updateCompanyActions.request)
                    : getType(createCompanyActions.request),
                  payload: dtoToSave,
                });

                resetForm();
              }
            }}
            enableReinitialize={true}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleSubmit,
                handleBlur,
                isSubmitting,
              } = props;
              return (
                <Grid item xs={12}>
                  <Card>
                    <Styles.CardContent1>
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={1}
                            hidden={isReadOnly(FeatureTypeEnum.Employers)}
                          >
                            <Style.UAYearSelect
                              id='year'
                              size='small'
                              margin='none'
                              InputLabelProps={{ required: true }}
                              value={values.year || ''}
                              label='Select Year'
                              name='year'
                              onBlur={handleBlur}
                              error={Boolean(errors.year)}
                              helperText={
                                errors.year && touched.year && errors.year
                              }
                              select
                              fullWidth
                              onChange={(e) => {
                                handleChange(e);
                                setShouldBlockNavigation(true);
                              }}
                              data-cy='employer_unemployment_yearInput'
                              SelectProps={{
                                MenuProps: { disableScrollLock: true },
                              }}
                            >
                              {dateRanges
                                .filter((item) => item.date !== 0)
                                .map((option, index) => (
                                  <MenuItem
                                    key={option.date.toString() + index}
                                    value={option.date}
                                  >
                                    {option.date}
                                  </MenuItem>
                                ))}
                            </Style.UAYearSelect>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            hidden={isReadOnly(FeatureTypeEnum.Employers)}
                          >
                            <Styles.TextNumberField
                              id='charge'
                              size='small'
                              margin='none'
                              InputLabelProps={{ required: true }}
                              value={values.charge || ''}
                              label='Charge Amount'
                              name='charge'
                              onBlur={handleBlur}
                              error={Boolean(errors.charge)}
                              helperText={
                                errors.charge && touched.charge && errors.charge
                              }
                              InputProps={{ inputComponent: MoneyField as any }}
                              onChange={(e) => {
                                handleChange(e);
                                setShouldBlockNavigation(true);
                              }}
                              data-cy='employer_employeecharge_chargeInput'
                            />
                          </Grid>

                          <Grid
                            item
                            xs={1}
                            hidden={isReadOnly(FeatureTypeEnum.Employers)}
                          >
                            <Style.AddButton
                              type='submit'
                              data-cy='employer_unemployment_charge_addButton'
                              variant='contained'
                              color='primary'
                              disabled={
                                isSubmitting ||
                                isReadOnly(FeatureTypeEnum.Employers)
                              }
                            >
                              Add
                            </Style.AddButton>
                          </Grid>
                        </Grid>
                      </form>
                    </Styles.CardContent1>
                  </Card>
                </Grid>
              );
            }}
          </Formik>

          {dto.employerChargeList &&
          dto.employerChargeList.filter((e) => e.year !== 0).length > 0 ? (
            dto.employerChargeList
              .filter((e) => e.year !== 0)
              .sort((a, b) => {
                return a.year === b.year ? 0 : a.year! < b.year! ? 1 : -1;
              })
              .map((o, index) => (
                <UnemploymentChargesCard
                  EmployerCharge={o}
                  index={index}
                  key={'employer_unemploymentCharges_card_' + index}
                />
              ))
          ) : (
            <Grid item xs={12}>
              <Card>
                <Styles.CardContent1>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Typography variant='subtitle1' color='primary'>
                        No Records at this time.
                      </Typography>
                    </Grid>
                  </Grid>
                </Styles.CardContent1>
              </Card>
              <br />
            </Grid>
          )}
          <Prompt
            when={shouldBlockNavigation}
            message='You have unsaved edits, are you sure you want to leave?'
          />
        </Grid>
      </Box>
    </>
  );
};

export default UnemploymentCharges;
