
export interface TodoInteractionDto {
    id?: string, //($uuid) nullable: true
    followUpActionId: number,	//ActionTypesinteger($int32)
    lastActionId: number,	//ActionTypesinteger($int32) Enum: Array[5]
    assignedUserId?: string, //uuid
    followUpDate: Date,	//string($date-time)
    followUpNote?: string,	//string nullable: true
    lastActionNote?: string,	//string nullable: true
    createdDate: Date,	//string($date-time)
    createdBy?: string,	//string nullable: true
    isProspect: boolean,
    convertToSalesOpportunity: boolean,
    isTarget: boolean,
    salesStageId: number,	//SalesStageTypesinteger($int32) Enum:Array [ 11 ]
    projectedSaleAmount?: string, //maxLength: 200, minLength: 0, nullable: true
    outlookTypeId: number,	//OutlookTypesinteger($int32) Enum: Array [ 3 ]
    soldDate: Date,
    saleAmount?: string,
    companyName?: string, //nullable: true
    contactName?: string, //nullable: true
    contactEmail?: string, //nullable: true
    contactPhone?: string //nullable: true

};

export const toDoInteractionInit: TodoInteractionDto = {
    //id: '', //($uuid) nullable: true
    followUpActionId: 0,	//ActionTypesinteger($int32)
    lastActionId: 0,	//ActionTypesinteger($int32) Enum: Array[5]
    followUpDate: new Date(),	//string($date-time)
    //  assignedUserId: '',
    createdDate: new Date(),	//string($date-time)
    isProspect: true,
    convertToSalesOpportunity: true,
    isTarget: true,
    salesStageId: 0,	//SalesStageTypesinteger($int32) Enum:Array [ 11 ]
    outlookTypeId: 1,	//OutlookTypesinteger($int32) Enum: Array [ 3 ]
    soldDate: new Date(),
};




