


import { ISelectOption } from "../dtos/SelectOptionDto";
enum TestRequestedReasonCodes {
        Item1 = 0,
        Item2 = 1,
        Item3 = 2,
        Item4 = 3,
        Item5 = 4,
         
}

export class TestRequestedReasonCodes_class {

public static enum = TestRequestedReasonCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['Item1']: 0,
    ['Item2']: 1,
    ['Item3']: 2,
    ['Item4']: 3,
    ['Item5']: 4,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "1- New Hire",
    [1]: "2- Post Accident",
    [2]: "3- Behavior",
    [3]: "4- Random",
    [4]: "5- Employee Assistance",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "1- New Hire"},
  {value: 1, text: "2- Post Accident"},
  {value: 2, text: "3- Behavior"},
  {value: 3, text: "4- Random"},
  {value: 4, text: "5- Employee Assistance"},
  ]
}

