import React from 'react';
import {
  FormikHandlers,
  FormikErrors,
  FormikHelpers,
  FieldArray,
  FieldArrayRenderProps,
} from 'formik';
import { useDispatch } from 'react-redux';
import { getType } from 'typesafe-actions';
// material ui
import {
  Grid,
  CardContent,
  InputAdornment,
  Typography,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import Dropzone from 'react-dropzone';
import SsnTextField from '../../../../common/components/ssnTextField';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { CompanyDto } from '../../../../features/company/dtos';
import { rootSelector } from '../../../../common/selectors/selectors';
import { getCompaniesActions } from '../../../company/actions';
import { getGoverningDistrictsActions } from '../../../account/profile/profile.actions';
import {
  CheckboxLabel,
  ChooseFileCard,
  NavCard,
  NavOptionsGrid,
  SmallFileText,
} from '../../documents.styles';
import {
  RemoveIcon,
  DashedBox,
  Emphasize,
} from '../../../../common/styles/styles';
import { ISi, ISi_PropertyAttributes } from '../../../../common/dtos/SiDto';
import { StateAbrCodes_class } from '../../../../common/enums/StateAbrCodes';
import { ClaimTypeCodes_class } from '../../../../common/enums/ClaimTypeCodes';
import { EmployerTypeCodes_class } from '../../../../common/enums/EmployerTypeCodes';
import SiTextField from '../siFieldComponents/SiTextField';
import SiDateField from '../siFieldComponents/SiDateField';
import SiSelectOptions from '../siFieldComponents/SiSelectOptions';
import { FraudTypeCodes_class } from '../../../../common/enums/FraudTypeCodes';
import IndicatorTypeYField from '../siFieldComponents/IndicatorTypeYField';
import SiTextAreaField from '../siFieldComponents/SiTextAreaField';
import { IUisSiAttachment } from '../../../../common/dtos/UisSiAttachmentDto';
import IndicatorTypeField from '../siFieldComponents/IndicatorTypeField';
import { isAttachmentInd } from '../../../../utils';

interface Props extends React.HTMLProps<React.ReactNode> {
  values: ISi;
  errors: FormikErrors<ISi>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  setFieldValue: FormikHelpers<ISi>['setFieldValue'];
  isReadOnly: boolean;
}

const SiDetails: React.FC<Props> = ({
  values,
  errors,
  handleChange,
  isReadOnly,
  handleBlur,
  setFieldValue,
}) => {
  const dispatch = useDispatch();

  const systemCompaniesList = rootSelector(
    (state) => state.companies.companiesList
  );

  const [companies, setCompanies] = React.useState<CompanyDto[]>([]);
  const [isMasked, setIsMasked] = React.useState<boolean>(false);
  const [amendedResponse, setAmendedResponse] = React.useState<boolean>(false);

  const handleDrop = (
    acceptedFiles: File[],
    arrayHelpers: FieldArrayRenderProps
  ) => {
    let fileList = [] as string[];
    acceptedFiles.forEach((f) => {
      fileList.push(f.name);
      let newDoc: IUisSiAttachment = {
        fileName: f.name,
        fileUpload: f,
      };
      arrayHelpers.push(newDoc);
    });
  };

  React.useEffect(() => {
    dispatch({ type: getType(getCompaniesActions.request) });
    dispatch({ type: getType(getGoverningDistrictsActions.request) });
  }, [dispatch]);

  React.useEffect(() => {
    setCompanies(systemCompaniesList);
  }, [systemCompaniesList]);

  React.useEffect(() => {
    if (!isAttachmentInd(values) && values.attachmentInd !== undefined)
      setFieldValue('attachmentInd', 1);
    if (values.respondedDateTime !== undefined) setAmendedResponse(true);
  }, [values]);

  const handleSSNMaskToggle = () => {
    setIsMasked(!isMasked);
  };

  const handleGetMaskedSSNValue = (): string => {
    let v = values.otherSsn ?? '';
    if (v.replace('-', '').length > 3) {
      return '***-**-' + v.replace('-', '').slice(-4);
    } else {
      return '***-**-';
    }
  };

  return (
    <FieldArray
      name={`uisSiAttachments`}
      render={(arrayHelpers) => (
        <Grid container spacing={2} data-cy='sidesResponse_details_container'>
          <NavOptionsGrid item xs={6}>
            <NavCard>
              <CardContent>
                <Grid container spacing={2}>
                  <SiTextField
                    label={'First Name'}
                    name={`claimantFirstName`}
                    value={values.claimantFirstName}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={5}
                    max={
                      ISi_PropertyAttributes.claimantFirstName_Attributes
                        .stringLength.maxLength
                    }
                  />
                  <SiTextField
                    label={'MI'}
                    name={`claimantMiddleInitial`}
                    value={values.claimantMiddleInitial}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={2}
                    max={
                      ISi_PropertyAttributes.claimantMiddleInitial_Attributes
                        .stringLength.maxLength
                    }
                  />
                  <SiTextField
                    label={'Last Name'}
                    name={`claimantLastName`}
                    value={values.claimantLastName}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={5}
                    max={
                      ISi_PropertyAttributes.claimantLastName_Attributes
                        .stringLength.maxLength
                    }
                  />
                  <SiTextField
                    label={'Suffix'}
                    name={`claimantSuffix`}
                    value={values.claimantSuffix}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={2}
                    max={
                      ISi_PropertyAttributes.claimantSuffix_Attributes
                        .stringLength.maxLength
                    }
                  />
                  <SiTextField
                    label={'Other Last Name'}
                    name={`otherLastName`}
                    value={values.otherLastName}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={10}
                    max={
                      ISi_PropertyAttributes.otherLastName_Attributes
                        .stringLength.maxLength
                    }
                  />

                  <SiTextField
                    label={'SSN'}
                    name={`otherSsn`}
                    value={
                      !isMasked ? values.otherSsn : handleGetMaskedSSNValue()
                    }
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={
                      ISi_PropertyAttributes.otherLastName_Attributes
                        .stringLength.maxLength
                    }
                    inputlabelprops={{ required: true }}
                    inputprops={{
                      endAdornment: (
                        <InputAdornment
                          position='end'
                          role='viewPasswordToggle'
                          onClick={(e) => {
                            handleSSNMaskToggle();
                          }}
                        >
                          {isMasked ? (
                            <IconButton
                              tabIndex={-1}
                              aria-label='View SSN'
                              title='View SSN'
                            >
                              <VisibilityIcon color='primary' />
                            </IconButton>
                          ) : (
                            <IconButton title='Hide SSN' aria-label='Hide SSN'>
                              <VisibilityOffIcon color='primary' />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                      inputComponent: SsnTextField as any,
                    }}
                  />

                  <SiSelectOptions
                    label={'State'}
                    name={`requestingStateAbbreviation`}
                    value={values.requestingStateAbbreviation}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={4}
                    options={StateAbrCodes_class.enumAsSelectOptions}
                  />

                  <SiDateField
                    label={'Response Due'}
                    name={`responseDueDate`}
                    value={values.responseDueDate}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={4}
                    inputlabelprops={{ shrink: true }}
                  />
                  <SiDateField
                    label={'BYB'}
                    name={`benefitYearBeginDate`}
                    value={values.benefitYearBeginDate}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={4}
                    inputlabelprops={{ shrink: true }}
                  />

                  <SiSelectOptions
                    label={'Employer'}
                    name={`correctedStateEmployerAccountNbr`}
                    optionName={`name`}
                    optionValueName={`suta`}
                    value={values.correctedStateEmployerAccountNbr} //correctedEmployerName
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={6}
                    options={companies}
                  />
                  <SiSelectOptions
                    label={'SUTA'}
                    name={`correctedStateEmployerAccountNbr`}
                    optionName={`suta`}
                    optionValueName={`suta`}
                    value={values.correctedStateEmployerAccountNbr}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={6}
                    options={companies}
                  />

                  <SiTextField
                    label={'FEIN'}
                    name={`correctedFein`}
                    value={values.correctedFein}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={6}
                    max={
                      ISi_PropertyAttributes.correctedFein_Attributes
                        .stringLength.maxLength
                    }
                  />

                  <SiSelectOptions
                    label={'Employment Period'}
                    name={`typeofEmployerCode`}
                    value={values.typeofEmployerCode}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={6}
                    options={EmployerTypeCodes_class.enumAsSelectOptions}
                  />
                  <SiSelectOptions
                    label={'Type of Claim'}
                    name={`typeofClaimCode`}
                    value={values.typeofClaimCode}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={6}
                    options={ClaimTypeCodes_class.enumAsSelectOptions}
                  />

                  <SiTextField
                    label={'Form Number'}
                    name={`formNumber`}
                    value={values.formNumber}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={6}
                    max={
                      ISi_PropertyAttributes.formNumber_Attributes.stringLength
                        .maxLength
                    }
                  />

                  <SiDateField
                    label={'Claim Effective Date'}
                    name={`claimEffectiveDate`}
                    value={values.claimEffectiveDate}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={6}
                    inputlabelprops={{ shrink: true }}
                  />

                  <SiTextField
                    label={'Claim Number'}
                    name={`claimNumber`}
                    value={values.claimNumber}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={6}
                    max={
                      ISi_PropertyAttributes.claimNumber_Attributes.stringLength
                        .maxLength
                    }
                  />
                  <SiTextField
                    label={'UI Office Name'}
                    name={`requestUiofficeName`}
                    value={values.requestUiofficeName}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={4}
                    max={
                      ISi_PropertyAttributes.requestUiofficeName_Attributes
                        .stringLength.maxLength
                    }
                  />
                  <SiTextField
                    label={'UI Office Phone'}
                    name={`requestUiofficePhone`}
                    value={values.requestUiofficePhone}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={4}
                    max={
                      ISi_PropertyAttributes.requestUiofficePhone_Attributes
                        .stringLength.maxLength
                    }
                  />
                  <SiTextField
                    label={'UI Office Fax'}
                    name={`requestUiofficeFax`}
                    value={values.requestUiofficeFax}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={4}
                    max={
                      ISi_PropertyAttributes.requestUiofficeFax_Attributes
                        .stringLength.maxLength
                    }
                  />
                  <SiTextField
                    label={'UI Office Email Address'}
                    name={`requestUiofficeEmailAddress`}
                    value={values.requestUiofficeEmailAddress}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    size={8}
                    max={
                      ISi_PropertyAttributes
                        .requestUiofficeEmailAddress_Attributes.stringLength
                        .maxLength
                    }
                  />

                  <IndicatorTypeYField
                    label={'Fraudulent Claim'}
                    name={`fraudInd`}
                    value={values.fraudInd}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                  />
                  <SiSelectOptions
                    label={'Reason employer is indicating a potential fraud'}
                    name={`fraudReasons`}
                    value={values.fraudReasons}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    options={FraudTypeCodes_class.enumAsSelectOptions}
                  />

                  <SiTextAreaField
                    label={'Please provide details:'}
                    name={`fraudComments`}
                    value={values.fraudComments}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    isReadOnly={isReadOnly}
                    max={
                      ISi_PropertyAttributes.fraudComments_Attributes
                        .stringLength.maxLength
                    }
                  />
                </Grid>
              </CardContent>
            </NavCard>
          </NavOptionsGrid>
          <NavOptionsGrid item xs={6}>
            <NavCard>
              <CardContent>
                <Grid container direction='row'>
                  {isAttachmentInd(values) && (
                    <IndicatorTypeField
                      label={
                        'Do you have any attachments which support your responses?'
                      }
                      name={`attachmentInd`}
                      value={values.attachmentInd}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      isReadOnly={isReadOnly}
                    />
                  )}

                  <Grid item xs={12}>
                    <ChooseFileCard>
                      <CardContent>
                        <Grid hidden={isReadOnly} item xs={12}>
                          <Dropzone
                            aria-label='Supporting document upload'
                            accept='.rtf,.pdf,.txt,.tiff,.tif,.csv'
                            onDrop={(e) => {
                              handleDrop(e, arrayHelpers);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <DashedBox
                                container
                                justifyContent='center'
                                {...getRootProps({ className: 'dropzone' })}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  container
                                  justifyContent='center'
                                >
                                  <CloudUpload fontSize='large' />
                                </Grid>
                                <input
                                  id='fileUpload'
                                  name='fileUpload'
                                  aria-label='File input area'
                                  {...getInputProps()}
                                />
                                <Typography variant='h6' display='block'>
                                  {' '}
                                  Drag and drop or <Emphasize>browse</Emphasize>
                                </Typography>
                              </DashedBox>
                            )}
                          </Dropzone>
                        </Grid>
                        <br></br>
                        {values.uisSiAttachments &&
                          values.uisSiAttachments.map((o, index) => (
                            <Grid
                              container
                              justifyContent='flex-start'
                              key={'attachment_' + index}
                            >
                              <Grid item xs={1}>
                                <RemoveIcon
                                  title='Remove File'
                                  onClick={() => {
                                    arrayHelpers.remove(index);
                                  }}
                                  data-cy='changeMe'
                                >
                                  <IndeterminateCheckBoxIcon />
                                </RemoveIcon>
                              </Grid>
                              <Grid item xs={5}>
                                <SmallFileText>{o.fileName}</SmallFileText>
                              </Grid>
                            </Grid>
                          ))}
                      </CardContent>
                    </ChooseFileCard>
                  </Grid>

                  {values.isReadyToRespond === true &&
                    values.attachmentInd === 0 &&
                    values.uisSiAttachments &&
                    values.uisSiAttachments.length === 0 && (
                      <Typography variant='body2' color='error'>
                        Must have at least 1 attachment.
                      </Typography>
                    )}

                  {values.isReadyToRespond === true &&
                    values.attachmentInd !== 0 &&
                    values.uisSiAttachments &&
                    values.uisSiAttachments.length !== 0 && (
                      <Typography variant='body2' color='error'>
                        This is not required. Please remove attachments
                      </Typography>
                    )}
                </Grid>
              </CardContent>
            </NavCard>
            <br></br>
            <Grid container item xs={12}>
              <NavCard>
                <CardContent>
                  <Grid item xs={12}>
                    <CheckboxLabel
                      checked={amendedResponse}
                      value={amendedResponse}
                      onChange={() => setAmendedResponse(!amendedResponse)}
                      control={
                        <Checkbox
                          id='amendedResponse'
                          name='amendedResponse'
                          disabled={isReadOnly || true}
                          size='medium'
                        />
                      }
                      disabled={isReadOnly || true}
                      label='Amended Response (Describe Below)'
                      data-cy='sidesResponse_amendedResponseCheckbox'
                    />
                  </Grid>
                  <NavCard>
                    <CardContent>
                      <SiTextAreaField
                        label={''}
                        name={`amendedResponseDescription`}
                        value={values.amendedResponseDescription}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        isReadOnly={isReadOnly || !amendedResponse}
                        max={
                          ISi_PropertyAttributes
                            .amendedResponseDescription_Attributes.stringLength
                            .maxLength
                        }
                      />
                    </CardContent>
                  </NavCard>
                  <Grid item xs={12}>
                    <CheckboxLabel
                      checked={values.isReadyToRespond || false}
                      value={values.isReadyToRespond || false}
                      onChange={handleChange}
                      control={
                        <Checkbox
                          name={`isReadyToRespond`}
                          disabled={isReadOnly}
                          size='medium'
                        />
                      }
                      label='Ready to Respond'
                      data-cy='sidesResponse_isReadyToRespond'
                    />
                  </Grid>
                </CardContent>
              </NavCard>
            </Grid>
          </NavOptionsGrid>
        </Grid>
      )}
    />
  );
};

export default SiDetails;
