import React from 'react';

import MenuNavItem, { MenuNavItemProps } from './menuNavItem';

export interface MenuNavItemsProps {
  items: MenuNavItemProps[]
  isNested?: boolean
  isCollapsed?: boolean
}

const MenuNavItems: React.FC<MenuNavItemsProps> = (props: MenuNavItemsProps) => {
  const { items = [], isCollapsed = false, isNested = false } = props;

  return (
    <>
      {items.map((item, index) => (
        <MenuNavItem
          {...item}
          isCollapsed={isCollapsed}
          isNested={isNested}
          key={index}
        />
      ))}
    </>
  )
}

export default MenuNavItems;
