import { createAsyncAction } from 'typesafe-actions';
import { CompanyCustomerSettingsDto } from './customers.dtos';

export const getCompanyCustomerSettingsActions = createAsyncAction(
  'GET_COMPANY_CUSTOMER_SETTINGS_REQUEST',
  'GET_COMPANY_CUSTOMER_SETTINGS_SUCCESS',
  'GET_COMPANY_CUSTOMER_SETTINGS_ERROR',
)<CompanyCustomerSettingsDto[], CompanyCustomerSettingsDto[], string>();

export const updateCompanyCustomerSettingsActions = createAsyncAction(
  'UPDATE_COMPANY_CUSTOMER_SETTINGS_REQUEST',
  'UPDATE_COMPANY_CUSTOMER_SETTINGS_SUCCESS',
  'UPDATE_COMPANY_CUSTOMER_SETTINGS_ERROR',
)<CompanyCustomerSettingsDto[], CompanyCustomerSettingsDto[], string>(); 