import { combineReducers } from 'redux';
import { createReducer, getType } from 'typesafe-actions';

// KK I added missing actions and new refresh actions
import {
  getContactsActions, getContactActions, contactListShouldRefresh, contactShouldRefresh, contactImportHistoryShouldRefresh,
  getContactBulkImportHistoryActions, sendPasswordResetActions,
} from './contacts.actions';

import { ContactDto, contactInit } from '../contacts/contacts.dtos';
import { BulkImportDto } from '../../common/dtos/bulkImportDto';

const contactsReducer = combineReducers({
  contactsList: createReducer([] as ContactDto[])
    .handleAction([getContactsActions.success,], (state, action) => action.payload),

  getContactRequest: createReducer('')
    .handleAction([getContactActions.request,], (state, action) => action.payload),

  contact: createReducer(contactInit)
    .handleAction([getContactActions.success,], (state, action) => action.payload),

  refreshContact: createReducer('')
    .handleType(getType(contactShouldRefresh), (state, action) => action.payload),

  refreshContactList: createReducer(true)
    .handleType(getType(contactListShouldRefresh), (state, action) => action.payload),

  refreshContactImportHistory: createReducer(true)
    .handleType(getType(contactImportHistoryShouldRefresh), (state, action) => action.payload),

  bulkImportHistory: createReducer([] as BulkImportDto[])
    .handleAction([ getContactBulkImportHistoryActions.success, ], (state, action) => action.payload),

  sendPasswordReset: createReducer('')
    .handleAction([ sendPasswordResetActions.request, ], (state, action) => action.payload),

  sendPasswordResetResult: createReducer(false)
    .handleAction([ sendPasswordResetActions.success, ], (state, action) => action.payload),
});


export default contactsReducer;