import React from 'react';
import {
  Dialog, DialogTitle, Grid, DialogActions, DialogContent, Typography,
} from '@material-ui/core';
import { ButtonGrid, StyledSubmitButton } from '../../../common/styles/styles';


interface Props {
  open: boolean,
  onCancel: () => void,
}

const ClientReportNoticeDialog: React.FC<Props> = (props: Props) => {
  const { open, onCancel } = props;
 
  return <Dialog
    open={open}
    data-cy="isComplete_dialog"
    fullWidth={true}
    maxWidth="sm"
  >
    <DialogTitle>Benchmark and Comparison Report - By Request Only</DialogTitle>
    <DialogContent>
      <Grid container alignContent="center" justifyContent="center" spacing={2}>

        <Grid item xs={10}>
          <Typography variant="subtitle2">
            Benchmark and Comparison Report is available by request only. Please contact your assigned claims representative to request your report.
          </Typography>
        </Grid>

      </Grid>

    </DialogContent>


    <DialogActions>
      <ButtonGrid container>
        <StyledSubmitButton
          onClick={() => onCancel()}
          color="primary"
          size="large"
        >
          OK
        </StyledSubmitButton>
      </ButtonGrid>
    </DialogActions>
  </Dialog>
}

export default ClientReportNoticeDialog;