import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, getIn } from 'formik';
import * as Yup from 'yup';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { getType } from 'typesafe-actions';
// material ui
import {
  Grid,
  Box,
  Typography,
  InputAdornment,
  Card,
  CardContent,
  TextField,
  Button,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import DescriptionIcon from '@material-ui/icons/Description';
import {
  DocumentsDto,
  DocumentsInit,
  actionsRequestInit,
} from './document.dtos';
import moment from 'moment';
import { getDocumentsActions, updateAssignContactActions } from './actions';
import { rootSelector } from '../../common/selectors/selectors';
import {
  TableCard,
  ListCardContent,
  LabelLink,
  Checkbox1,
  SmallLabelLink,
  CardTextField,
  ToolLabel,
} from '../../common/styles/styles';
import {
  PaddedSearchfield,
  DueSoonButton,
  DueTodayButton,
  OverdueButton,
  SortGrid,
  ImportExportIconBlue,
  ImportExportIconNotSelected,
  ImportExportIconRed,
  ReassignButton,
} from './documents.styles';
import QuestionnaireDialog from '../questionnaire/questionnaireDialog';
import {
  openReachOutEmailTemplateDialog,
  toggleEmailTemplateDialog,
} from '../../common/actions/emailTemplate.actions';
import { ClaimDto, ClaimInit } from '../claims/claims.dtos';
import { getClaimActions } from '../claims/actions';
import { devToolsEnhancer } from 'redux-devtools-extension';

const ClientActionsList: React.FC = () => {
  const dispatch = useDispatch();
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  //const systemTotalDocuments = rootSelector((state) => state.documents.totalDocuments);
  const systemDocuments = rootSelector(
    (state) => state.documents.documentsList
  );
  const baseUrl = rootSelector((state) => state.documents.baseUrl);

  //const [totalDocuments, setTotalDocuments] = React.useState<number>(0);
  const [documentsList, setDocumentsList] = React.useState<DocumentsDto[]>([]);
  const [documentsSearchOptions, setDocumentsSearchOptions] = React.useState<
    string[]
  >([]);
  const [claimEditDialogOpen, setClaimEditDialogOpen] =
    React.useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] =
    React.useState<DocumentsDto>(DocumentsInit);
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [sortParameter, setSortParameter] = React.useState<string>('');

  React.useEffect(() => {
    dispatch({
      type: getType(getDocumentsActions.request),
      payload: actionsRequestInit,
    });
  }, [dispatch]);

  React.useEffect(() => {
    let searchOptions: string[] = [];
    let list: DocumentsDto[] = JSON.parse(JSON.stringify(systemDocuments));
    if (list.length !== 0 && list !== undefined) {
      list.forEach((item: DocumentsDto) => {
        if (item.employeeId !== undefined) {
          searchOptions.push(item.employeeId!);
        }
        if (item.label !== undefined) {
          searchOptions.push(item.label!);
        }
        if (item.claimantSsn !== undefined && item.claimantSsn.length !== 0) {
          searchOptions.push(item.claimantSsn);
        }
        if (
          item.suta !== undefined &&
          item.suta.length !== 0 &&
          item.suta !== ''
        ) {
          searchOptions.push(item.suta);
        }
      });
    }
    setDocumentsSearchOptions(
      Array.from(new Set(searchOptions))
        .sort((a, b) => a.localeCompare(b))
        .filter((s) => s !== undefined && s !== '' && s !== ' ')
    );

    setDocumentsList(
      list.sort((a, b) => {
        return moment(a.dueDate).diff(moment(b.dueDate));
      })
    );
  }, [systemDocuments]);

  //React.useEffect(() => {
  //  setTotalDocuments(systemTotalDocuments);
  //}, [systemTotalDocuments]);

  //React.useEffect(() => {
  //  let searchOptions: string[] = [];
  //  if (documentsList.length !== 0 && documentsList !== undefined) {
  //    documentsList.forEach((item: DocumentsDto) => {
  //      if (item.label !== undefined) {
  //        searchOptions.push(item.label!);
  //      }
  //      if (item.claimantSsn !== undefined && item.claimantSsn.length !== 0) {
  //        searchOptions.push(item.claimantSsn);
  //      }
  //      if (
  //        item.suta !== undefined &&
  //        item.suta.length !== 0 &&
  //        item.suta !== ''
  //      ) {
  //        searchOptions.push(item.suta);
  //      }
  //    });
  //  }
  //  setDocumentsSearchOptions(
  //    Array.from(new Set(searchOptions))
  //      .sort((a, b) => a.localeCompare(b))
  //      .filter((s) => s !== undefined && s !== '' && s !== ' ')
  //  );
  //}, [documentsList]);

  const handleCancelClaimeEditDialog = () => {
    setClaimEditDialogOpen(false);
  };

  const handleExport = (list: DocumentsDto[]) => {
    let fileName = `actionList-${moment().format('MM/DD/yyyy')}`;
    let csvData = list.map((m) => {
      return {
        'First name': m.claimantFirstName,
        'Last name': m.claimantLastName,
        SSN: m.claimantSsn ?? '',
        'Employer name': m.employerName ?? '',
        'Employee ID': m.employeeId ?? '',
        SUTA: m.suta ?? '',
        'State code': m.stateCode ?? '',
        'Due date': moment(m.dueDate).format('MM/DD/yyyy') || '',
        'Secure link': !!m.questionnaireToken
          ? `${baseUrl}questionnaire/${m.questionnaireToken}`
          : '',
      };
    });
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleForwardEmail = (item: DocumentsDto) => {
    //setClaim
    let claim: ClaimDto = { ...ClaimInit };
    claim.id = item.claimId;
    claim.documents = [{ ...item }];

    dispatch({ type: getType(getClaimActions.success), payload: claim });
    // Open Email
    dispatch({ type: getType(openReachOutEmailTemplateDialog), payload: true });
    dispatch({ type: getType(toggleEmailTemplateDialog), payload: true });
  };

  const handleClaimEditDialogOpen = (document: DocumentsDto) => {
    setSelectedDocument(document);
    setClaimEditDialogOpen(true);
  };

  const handleSortDocumentsList = (
    parameter: string,
    search: string | undefined = undefined
  ) => {
    let query = search === undefined ? searchQuery : search;
    let newDocs = JSON.parse(JSON.stringify(systemDocuments)) as DocumentsDto[];

    newDocs = newDocs.filter(
      (item: DocumentsDto) =>
        (item.employeeId !== undefined &&
          item.employeeId.toLowerCase().includes(query.toLowerCase())) ||
        (item.label !== undefined &&
          item.label.toLowerCase().includes(query.toLowerCase())) ||
        (item.claimantSsn !== undefined &&
          item.claimantSsn!.toLowerCase().includes(query.toLowerCase())) ||
        (item.suta !== undefined &&
          item.suta!.toLowerCase().includes(query.toLowerCase()))
    );

    switch (parameter) {
      case 'DueDateAscending': {
        setSortParameter('DueDateAscending');
        newDocs = newDocs.sort((a, b) => {
          return moment(b.dueDate).diff(moment(a.dueDate));
        });
        break;
      }

      case 'LastNameAscending': {
        setSortParameter('LastNameAscending');
        newDocs = newDocs.sort((a, b) =>
          b.claimantLastName!.localeCompare(a.claimantLastName!)
        );
        break;
      }
      case 'LastNameDescending': {
        setSortParameter('LastNameDescending');
        newDocs = newDocs.sort((a, b) =>
          a.claimantLastName!.localeCompare(b.claimantLastName!)
        );
        break;
      }
      case 'EmployerAscending': {
        setSortParameter('EmployerAscending');
        newDocs = newDocs.sort((a, b) =>
          b.employerName!.localeCompare(a.employerName!)
        );
        break;
      }
      case 'EmployerDescending': {
        setSortParameter('EmployerDescending');
        newDocs = newDocs.sort((a, b) =>
          a.employerName!.localeCompare(b.employerName!)
        );
        break;
      }
      case 'DueDateDescending':
      default: {
        setSortParameter('DueDateDescending');
        newDocs = newDocs.sort((a, b) => {
          return moment(a.dueDate).diff(moment(b.dueDate));
        });
        break;
      }
    }
    setDocumentsList(newDocs);
  };

  return (
    <>
      {claimEditDialogOpen &&
        selectedDocument.questionnaireToken &&
        selectedDocument.questionnaireToken.length !== 0 &&
        !selectedDocument.isQuestionnaireComplete && (
          <QuestionnaireDialog
            open={claimEditDialogOpen}
            onCancel={handleCancelClaimeEditDialog}
            token={selectedDocument.questionnaireToken}
          />
        )}

      <Formik
        initialValues={{ list: documentsList }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={Yup.object({
          list: Yup.array().of(
            Yup.object().shape({
              assigned: Yup.string()
                .min(50, 'Must be 50 characters or less')
                .nullable(),
            })
          ),
        })}
        onSubmit={(values) => {
          dispatch({
            type: getType(updateAssignContactActions.request),
            payload: values.list,
          });
        }}
        enableReinitialize={true}
      >
        {(F) => {
          return (
            <Box p={2}>
              <Grid container data-cy='contact-list-Container'>
                <Grid item xs={12}>
                  <Typography variant='subtitle1' display='inline'>
                    To-Do List
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    color='textSecondary'
                    display='inline'
                  >
                    {' '}
                    ({documentsList.length})
                  </Typography>
                </Grid>
                <br />
                <br />
                <Formik
                  initialValues={{ search: '' }}
                  validateOnChange={false}
                  validateOnBlur={false}
                  validationSchema={Yup.object({
                    search: Yup.string().max(
                      200,
                      'Must be 200 characters or less'
                    ),
                  })}
                  onSubmit={(values) => {
                    setSearchQuery(values.search);
                    handleSortDocumentsList('', values.search);
                  }}
                  enableReinitialize={true}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                    } = props;
                    return (
                      <>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={4}>
                            <form
                              onSubmit={() => {
                                handleSubmit();
                              }}
                            >
                              <Autocomplete
                                id='autoSearch'
                                options={documentsSearchOptions}
                                autoComplete
                                includeInputInList
                                freeSolo
                                clearOnBlur
                                selectOnFocus
                                //inputValue={values.search || ''}
                                value={values.search || ''}
                                onChange={(_e: any, v: string | null) => {
                                  setFieldValue('search', v ?? '');
                                  //handleSubmit();
                                }}
                                renderInput={(params) => (
                                  <PaddedSearchfield
                                    {...params}
                                    id='search'
                                    size='small'
                                    margin='none'
                                    value={values.search}
                                    label='Search'
                                    name='search'
                                    onChange={handleChange}
                                    error={Boolean(errors.search)}
                                    helperText={
                                      errors.search &&
                                      touched.search &&
                                      errors.search
                                    }
                                    data-cy='contactsListSearch'
                                    onKeyDown={(e) => {
                                      if (e.keyCode === 13) {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        handleSubmit();
                                      }
                                    }}
                                    InputProps={{
                                      ...params.InputProps,
                                      startAdornment: (
                                        <InputAdornment
                                          position='start'
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleSubmit();
                                          }}
                                        >
                                          <IconButton
                                            edge='start'
                                            title='Search'
                                            aria-label='Actions Search Icon'
                                            color='secondary'
                                            data-cy='actionItems_search'
                                            type='submit'
                                          >
                                            <SearchIcon />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            </form>
                          </Grid>
                          <SortGrid
                            container
                            item
                            md={12}
                            sm={12}
                            xs={12}
                            lg={6}
                            spacing={3}
                          >
                            <Grid item xs={3}>
                              {sortParameter === 'DueDateDescending' ? (
                                <Checkbox1
                                  onChange={() =>
                                    handleSortDocumentsList('DueDateAscending')
                                  }
                                  aria-label='Due Date Sort Toggle'
                                  name='sortByDueDateAscending'
                                  id='sortByDueDateAscending'
                                  value={false}
                                  checked={
                                    sortParameter === 'DueDateDescending'
                                  }
                                  control={
                                    <Checkbox
                                      checkedIcon={<ImportExportIconBlue />}
                                      icon={<ImportExportIconNotSelected />}
                                      size='small'
                                      data-cy='claims_ActionList_pastDueCheckbox'
                                    />
                                  }
                                  label={
                                    <Typography variant='subtitle2'>
                                      Due Date
                                    </Typography>
                                  }
                                />
                              ) : (
                                <Checkbox1
                                  onChange={() =>
                                    handleSortDocumentsList('DueDateDescending')
                                  }
                                  aria-label='Due Date Sort Toggle'
                                  name='sortByDueDateDescending'
                                  id='sortByDueDateDescending'
                                  value={false}
                                  checked={sortParameter === 'DueDateAscending'}
                                  control={
                                    <Checkbox
                                      checkedIcon={<ImportExportIconRed />}
                                      icon={<ImportExportIconNotSelected />}
                                      size='small'
                                      data-cy='claims_ActionList_pastDueCheckbox'
                                    />
                                  }
                                  label={
                                    <Typography variant='subtitle2'>
                                      Due Date
                                    </Typography>
                                  }
                                />
                              )}
                            </Grid>
                            <Grid item xs={3}>
                              {sortParameter === 'LastNameDescending' ? (
                                <Checkbox1
                                  onChange={() =>
                                    handleSortDocumentsList('LastNameAscending')
                                  }
                                  aria-label='Date Created Sort Toggle'
                                  name='sortByLastNameAscending'
                                  id='sortByLastNameAscending'
                                  value={''}
                                  checked={
                                    sortParameter === 'LastNameDescending'
                                  }
                                  control={
                                    <Checkbox
                                      checkedIcon={<ImportExportIconBlue />}
                                      icon={<ImportExportIconNotSelected />}
                                      size='small'
                                      data-cy='claims_ActionList_pastDueCheckbox'
                                    />
                                  }
                                  label={
                                    <Typography variant='subtitle2'>
                                      Last Name
                                    </Typography>
                                  }
                                />
                              ) : (
                                <Checkbox1
                                  onChange={() =>
                                    handleSortDocumentsList(
                                      'LastNameDescending'
                                    )
                                  }
                                  aria-label='Date CreatedSort Toggle'
                                  name='sortByLastNameDescending'
                                  id='sortByLastNameDescending'
                                  value={''}
                                  checked={
                                    sortParameter === 'LastNameAscending'
                                  }
                                  control={
                                    <Checkbox
                                      checkedIcon={<ImportExportIconRed />}
                                      icon={<ImportExportIconNotSelected />}
                                      size='small'
                                      data-cy='claims_ActionList_pastDueCheckbox'
                                    />
                                  }
                                  label={
                                    <Typography variant='subtitle2'>
                                      Last Name
                                    </Typography>
                                  }
                                />
                              )}
                            </Grid>
                            <Grid item xs={3}>
                              {sortParameter === 'EmployerDescending' ? (
                                <Checkbox1
                                  onChange={() =>
                                    handleSortDocumentsList('EmployerAscending')
                                  }
                                  aria-label='Date Created Sort Toggle'
                                  name='sortByEmployerAscending'
                                  id='sortByEmployerAscending'
                                  value={''}
                                  checked={
                                    sortParameter === 'EmployerDescending'
                                  }
                                  control={
                                    <Checkbox
                                      checkedIcon={<ImportExportIconBlue />}
                                      icon={<ImportExportIconNotSelected />}
                                      size='small'
                                      data-cy='claims_ActionList_pastDueCheckbox'
                                    />
                                  }
                                  label={
                                    <Typography variant='subtitle2'>
                                      Employer
                                    </Typography>
                                  }
                                />
                              ) : (
                                <Checkbox1
                                  onChange={() =>
                                    handleSortDocumentsList(
                                      'EmployerDescending'
                                    )
                                  }
                                  aria-label='Date CreatedSort Toggle'
                                  name='sortByEmployerDescending'
                                  id='sortByEmployerDescending'
                                  value={''}
                                  checked={
                                    sortParameter === 'EmployerAscending'
                                  }
                                  control={
                                    <Checkbox
                                      checkedIcon={<ImportExportIconRed />}
                                      icon={<ImportExportIconNotSelected />}
                                      size='small'
                                      data-cy='claims_ActionList_pastDueCheckbox'
                                    />
                                  }
                                  label={
                                    <Typography variant='subtitle2'>
                                      Employer
                                    </Typography>
                                  }
                                />
                              )}
                            </Grid>
                            <Grid item xs={3}>
                              <ToolLabel
                                component={Button}
                                onClick={() => handleExport(F.values.list)}
                                data-cy={`o-list-card-export-link`}
                                color='secondary'
                              >
                                <Typography variant='subtitle2'>
                                  EXPORT
                                </Typography>
                              </ToolLabel>
                            </Grid>
                          </SortGrid>
                          <Grid item xs={2}>
                            <ReassignButton
                              title='SAVE ASSIGNMENTS'
                              color='secondary'
                              onClick={() => F.handleSubmit()}
                              data-cy='actionitems-update-assignments'
                              //variant={selectedDocuments.length > 0 ? "outlined" : "text"}
                            >
                              <Typography color='primary' variant='subtitle1'>
                                SAVE ASSIGNMENTS
                              </Typography>
                            </ReassignButton>
                          </Grid>
                        </Grid>

                        <br></br>
                        <Grid container spacing={2}>
                          {F.values.list &&
                            F.values.list.map((o, index) => (
                              <Grid
                                item
                                xs={12}
                                key={'documentsList-item-' + index}
                              >
                                <TableCard raised>
                                  <ListCardContent>
                                    <Grid container spacing={2}>
                                      <div
                                        hidden
                                        data-cy={`o-list-card-id-${o.label}`}
                                      ></div>
                                      <Grid item xs={3}>
                                        <LabelLink
                                          component={Button}
                                          onClick={() =>
                                            handleClaimEditDialogOpen(o)
                                          }
                                          data-cy={`o-list-card-link-${index}`}
                                          color='secondary'
                                        >
                                          {o.label && o.label.length !== 0
                                            ? `${o.label}    ${o.claimantSsn}`
                                            : '-NEW MAIL-'}
                                          &nbsp;&nbsp;
                                          {o.questionnaireToken &&
                                            o.questionnaireToken.length !== 0 &&
                                            !o.isQuestionnaireComplete && (
                                              <DescriptionIcon />
                                            )}
                                        </LabelLink>
                                      </Grid>
                                      <Grid item xs={7}>
                                        {o.questionnaireToken &&
                                          o.questionnaireToken.length !== 0 &&
                                          !o.isQuestionnaireComplete && (
                                            <SmallLabelLink
                                              component={Button}
                                              onClick={() =>
                                                handleForwardEmail(o)
                                              }
                                              data-cy={`o-list-card-link-${index}`}
                                              color='secondary'
                                            >
                                              FORWARD
                                            </SmallLabelLink>
                                          )}
                                      </Grid>
                                      <Grid
                                        container
                                        alignItems='flex-start'
                                        item
                                        xs={2}
                                        justifyContent='flex-end'
                                      >
                                        {Math.ceil(
                                          -moment()
                                            .utc()
                                            .diff(o.dueDate, 'hours') / 24
                                        ) > 0 ? (
                                          <DueSoonButton
                                            type='button'
                                            variant='contained'
                                            data-cy='actionItems_dueSoonButton'
                                            color='primary'
                                          >
                                            Due Soon
                                          </DueSoonButton>
                                        ) : Math.ceil(
                                            -moment()
                                              .utc()
                                              .diff(o.dueDate, 'hours') / 24
                                          ) === 0 ? (
                                          <DueTodayButton
                                            type='button'
                                            variant='contained'
                                            data-cy='actionItems_dueTodayButton'
                                            color='primary'
                                          >
                                            Due Today
                                          </DueTodayButton>
                                        ) : Math.ceil(
                                            -moment()
                                              .utc()
                                              .diff(o.dueDate, 'hours') / 24
                                          ) < 0 ? (
                                          <OverdueButton
                                            type='button'
                                            variant='contained'
                                            data-cy='actionItems_overdueButton_'
                                            color='primary'
                                          >
                                            Overdue
                                          </OverdueButton>
                                        ) : null}
                                      </Grid>

                                      <Grid item xs={5} lg={2}>
                                        <Typography
                                          variant='body2'
                                          color='textPrimary'
                                        >
                                          Claim Start Date
                                        </Typography>
                                        <Typography
                                          variant='body2'
                                          color='secondary'
                                        >
                                          {o.benefitYearBeginningDate ===
                                          undefined
                                            ? ''
                                            : moment(
                                                o.benefitYearBeginningDate
                                              ).format('MM/DD/yyyy')}
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={5} lg={2}>
                                        <Typography
                                          variant='body2'
                                          color='textPrimary'
                                        >
                                          Employer Name
                                        </Typography>
                                        <Typography
                                          variant='body2'
                                          color='secondary'
                                        >
                                          {o.employerName === undefined ||
                                          o.employerName.replace(' ', '')
                                            .length === 0
                                            ? 'None on File'
                                            : o.employerName || ''}
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={5} lg={1}>
                                        <Typography
                                          variant='body2'
                                          color='textPrimary'
                                        >
                                          SUTA
                                        </Typography>
                                        <Typography
                                          variant='body2'
                                          color='secondary'
                                        >
                                          {o.suta || ''}
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={5} lg={1}>
                                        <Typography
                                          variant='body2'
                                          color='textPrimary'
                                        >
                                          Employee ID
                                        </Typography>
                                        <Typography
                                          variant='body2'
                                          color='secondary'
                                        >
                                          {o.employeeId || ''}
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={5} lg={1}>
                                        <Typography
                                          variant='body2'
                                          color='textPrimary'
                                        >
                                          State Code
                                        </Typography>
                                        <Typography
                                          variant='body2'
                                          color='secondary'
                                        >
                                          {o.stateCode || ''}
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={5} lg={2}>
                                        <Typography
                                          variant='body2'
                                          color='textPrimary'
                                        >
                                          Assigned
                                        </Typography>
                                        <CardTextField
                                          value={
                                            F.values.list[index]
                                              .assignedContact || ''
                                          }
                                          name={`list[${index}].assignedContact`}
                                          onChange={F.handleChange}
                                          onBlur={F.handleBlur}
                                          error={
                                            Boolean(
                                              getIn(
                                                errors,
                                                `list[${index}].assignedContact`
                                              )
                                            ) &&
                                            getIn(
                                              F.touched,
                                              `list[${index}].assignedContact`
                                            )
                                          }
                                          helperText={
                                            getIn(
                                              errors,
                                              `list[${index}].assignedContact`
                                            ) &&
                                            getIn(
                                              F.touched,
                                              `list[${index}].assignedContact`
                                            ) &&
                                            getIn(
                                              errors,
                                              `list[${index}].assignedContact`
                                            )
                                          }
                                          data-cy={`client-actionlist-{index}-assignedContact`}
                                        />
                                      </Grid>

                                      <Grid item xs={5} lg={2}>
                                        <Typography
                                          variant='body2'
                                          color='textPrimary'
                                        >
                                          Claims Rep
                                        </Typography>
                                        <Typography
                                          variant='body2'
                                          color='secondary'
                                        >
                                          {o.repFullName || ''}
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={2}>
                                        <Typography
                                          variant='body2'
                                          color='textPrimary'
                                        >
                                          Due Date
                                        </Typography>
                                        <Typography
                                          variant='body2'
                                          color='secondary'
                                        >
                                          {moment(o.dueDate).format(
                                            'MM/DD/yyyy'
                                          ) || ''}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </ListCardContent>
                                </TableCard>
                              </Grid>
                            ))}
                        </Grid>

                        {F.values.list && F.values.list.length === 0 && (
                          <Grid item xs={12}>
                            <Card>
                              <CardContent>
                                <Grid
                                  container
                                  justifyContent='center'
                                  alignItems='center'
                                >
                                  <Typography
                                    variant='subtitle1'
                                    color='secondary'
                                  >
                                    No records found
                                    {searchQuery.length === 0
                                      ? '.'
                                      : ' matching ' + searchQuery}
                                  </Typography>
                                </Grid>
                              </CardContent>
                            </Card>
                            <br />
                          </Grid>
                        )}
                      </>
                    );
                  }}
                </Formik>
              </Grid>
            </Box>
          );
        }}
      </Formik>
    </>
  );
};

export default ClientActionsList;
