


import { ISelectOption } from "../dtos/SelectOptionDto";
enum Pension401kCodes {
        Item1 = 0,
        Item2 = 1,
         
}

export class Pension401kCodes_class {

public static enum = Pension401kCodes;

  public static readonly enumByText : { [key : string ]: number} = {
    ['Item1']: 0,
    ['Item2']: 1,
    
  };
  public static readonly enumByNumber : { [key : number ]: string} = {
    [0]: "1- Pension",
    [1]: "2- 401K",
    
  };

  public static readonly enumAsSelectOptions:Array<ISelectOption<number>> = [
  {value: 0, text: "1- Pension"},
  {value: 1, text: "2- 401K"},
  ]
}

