import { MenuItem } from "@material-ui/core";
import React from "react";
import { CompanyHsDto } from "../features/company/dtos";

/**
 * Formats a SSN - with or without a mask.
 * parameter. 
 * @param ssn: string
 */
export const formatStaticSSN = (
  ssn: string | undefined
): string => {
  if (!!!ssn) return '';
  if (ssn.includes('*') || ssn.includes('-')) return ssn;

  switch (ssn.length) {
    case 1: return `   -  -${ssn}`;
    case 2: return `   -  -${ssn}`;
    case 3: return `   -  -${ssn}`;
    case 4: return `   -  -${ssn}`;
    case 5: return `   - ${ssn.slice(0, 1)}-${ssn.slice(-4)}`;
    case 6: return `   -${ssn.slice(0, 2)}-${ssn.slice(-4)}`;
    case 7: return `  ${ssn.slice(0, 1)}-${ssn.slice(1, 3)}-${ssn.slice(-4)}`;
    case 8: return ` ${ssn.slice(0, 2)}-${ssn.slice(2, 4)}-${ssn.slice(-4)}`;
    case 9: return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(-4)}`;
    case 0:
    default: return '';
  }
};