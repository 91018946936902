/**
 * Returns a query string of all object key-value pairs where value is explicitly
 * **not** `null` *and* **not** `undefined`.
 *
 * i.e. `?{key}={value}`
 */
export const URIQuery = ({ ...parameters }): string => {
  let query = '';

  // Do not pass `null` or `undefined` values in the query parameters
  const keys = Object.keys(parameters).filter(
    (key) => parameters[key] !== null && parameters[key] !== undefined
  );

  if (keys.length > 0) {
    // keys[0] is only ever used on this line to begin the query string (which must
    // start with '?') and is removed with keys.shift().
    query = '?' + keys[0] + '=' + parameters[keys.shift()!];

    keys.forEach((key) => (query = query + '&' + key + '=' + parameters[key]));
  }

  return query;
};
