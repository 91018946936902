import { ISi } from '../../common/dtos/SiDto';
import { createAsyncAction, createAction } from 'typesafe-actions';
import { DocumentsDto, ReassignDto, ActionsRequestDto, AssociateDocumentDto } from './document.dtos';
import { IMc } from '../../common/dtos/McDto';

export const getDocumentActions = createAsyncAction(
  'GET_DOCUMENT_REQUEST',
  'GET_DOCUMENT_SUCCESS',
  'GET_DOCUMENT_ERROR'
)<string, DocumentsDto, string>();

export const getDocumentsActions = createAsyncAction(
  'GET_DOCUMENTS_REQUEST',
  'GET_DOCUMENTS_SUCCESS',
  'GET_DOCUMENTS_ERROR'
)<ActionsRequestDto, DocumentsDto[], string>();


export const reassignDocumentsActions = createAsyncAction(
  'REASSIGN_DOCUMENT_REQUEST',
  'REASSIGN_DOCUMENT_SUCCESS',
  'REASSIGN_DOCUMENT_ERROR',
)<ReassignDto, undefined, string>();

export const updateAssignContactActions = createAsyncAction(
  'UPDATE_ASSIGNED_CONTACT_REQUEST',
  'UPDATE_ASSIGNED_CONTACT_SUCCESS',
  'UPDATE_ASSIGNED_CONTACT_ERROR',
)<DocumentsDto[], undefined, string>();

export const deleteDocumentsActions = createAsyncAction(
  'DELETE_DOCUMENT_REQUEST',
  'DELETE_DOCUMENT_SUCCESS',
  'DELETE_DOCUMENT_ERROR',
)<string, string, string>();


export const getDocumentPageNumber = createAsyncAction(
  'GET_DOCUMENT_PAGE_NUMBER_REQUEST',
  'GET_DOCUMENT_PAGE_NUMBER_SUCCESS',
  'GET_DOCUMENT_PAGE_NUMBER_ERROR',
)<undefined, number, string>();

export const getDocumentPageTotal = createAsyncAction(
  'GET_DOCUMENT_PAGE_TOTAL_REQUEST',
  'GET_DOCUMENT_PAGE_TOTAL_SUCCESS',
  'GET_DOCUMENT_PAGE_TOTAL_ERROR',
)<undefined, number, string>();

export const documentAssociateActions = createAsyncAction(
  'DOCUMENT_ASSOCIATE_REQUEST',
  'DOCUMENT_ASSOCIATE_SUCCESS',
  'DOCUMENT_ASSOCIATE_ERROR',
)<AssociateDocumentDto, undefined, string>();

export const documentDeassociateActions = createAsyncAction(
    'DOCUMENT_DEASSOCIATE_REQUEST',
    'DOCUMENT_DEASSOCIATE_SUCCESS',
    'DOCUMENT_DEASSOCIATE_ERROR',
)<AssociateDocumentDto, undefined, string>();

export const siteBaseUrlAction = createAction('')<string>();

export const getUisSiActions = createAsyncAction(
  'GET_UISSI_REQUEST',
  'GET_UISSI_SUCCESS',
  'GET_UISSI_ERROR'
)<string, ISi, string>();

export const updateUisSiActions = createAsyncAction(
  'UPDATE_UISSI_REQUEST',
  'UPDATE_UISSI_SUCCESS',
  'UPDATE_UISSI_ERROR'
)<ISi, ISi, string>();

export const getUisMcActions = createAsyncAction(
  'GET_UISMC_REQUEST',
  'GET_UISMC_SUCCESS',
  'GET_UISMC_ERROR'
)<string, IMc, string>();

export const updateUisMcActions = createAsyncAction(
  'UPDATE_UISMC_REQUEST',
  'UPDATE_UISMC_SUCCESS',
  'UPDATE_UISMC_ERROR'
)<IMc, IMc, string>();

