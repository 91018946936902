//Questionnaire Options


//Did the claimant receive separation pay,
//other than regular wages earned(for
//example: PTO payout, severance pay,
//    notice pay, bonus pay, etc.)?
export const claimantSeparationPayOptions = [
  //{ name: 'Please select an option', id: 0 },
  { name: 'Accrued PTO', id: 1 },
  { name: 'Bonus Pay', id: 2 },
  { name: 'Pension/Retirement Pay', id: 3 },
  { name: 'Settlement Pay', id: 4 },
  { name: 'Severance Pay', id: 5 },
  { name: 'Sick Pay', id: 6 },
  { name: 'Vacation Pay', id: 7 },
  { name: 'Wages in Lieu of Notice', id: 8 },
  { name: 'Other', id: 9 },
];

//What is the frequency of pay?
export const frequencyOfPayOptions = [
  //{ name: 'Please select an option', id: 0 },
  { name: 'Weekly', id: 1 },
  { name: 'Bi-Weekly', id: 2 },
  { name: 'Semi-Monthly', id: 3 },
  { name: 'Monthly', id: 4 },
  { name: 'Quarterly', id: 5 },
  { name: 'Lump Sum', id: 6 },
];

//What is the reason for the claimant ? s
//employment separation(if still employed, or
//there has been no separation ? please
//select ? other ?)?
export const initialSeparationReasonOptions = [
  //{ name: 'Please select an option', id: 0 },
  { name: 'Resignation', id: 1 },
  { name: 'Discharge', id: 2 },
  { name: 'Lack of Work', id: 3 },
  { name: 'Never Employed by Employer', id: 4 },
  { name: 'Other', id: 5 },
];


//What was the reason the employee gave
//for resigning ?
export const resignationReasonOptions = [
    //{ name: 'Please select an option', id: 0 },
    { name: 'No Reason Provided', id: 1 },
    { name: 'To Seek or Accept Other Employment', id: 2 },
    { name: 'Due to Health Reasons', id: 3 },
    { name: 'Job Dissatisfaction', id: 4 },
    { name: 'Resignation In Lieu of Discharge', id: 5 },
    { name: 'Personal Reasons', id: 6 },
    { name: 'Failure to Report For Work/Job Abandoment', id: 7 },
    { name: 'Retirement', id: 8 },
    { name: 'Working Conditions', id: 9 },
    { name: 'Failure to Return from Leave of Absence', id: 10 },
    { name: 'Other', id: 11 },
];


//What is the reason for the claimant ? s
//employment separation(if still employed, or
//there has been no separation ? please
//select ? other ?)? 
export const failureToReturnOptions = [
    //{ name: 'Please select an option', id: 0 },
    { name: 'Medical', id: 1 },
    { name: 'Personal', id: 2 },
    //{ name: 'Educational', id: 3 },
    //{ name: 'Military', id: 4 },
    { name: 'Workers Compensation', id: 5 },
    { name: 'Other', id: 6 },
];


//What was the reason for the discharge?
export const dischargeReasonOptions = [
  //{ name: 'Please select an option', id: 0 },
  { name: 'Not Qualified', id: 1 },
  { name: 'Job Performance', id: 2 },
  { name: 'Neglect of Duties', id: 3 },
  { name: 'Insubordination', id: 4 },
  { name: 'Absenteeism/Excessive Tardiness', id: 5 },
  { name: 'Drugs and/or Alcohol', id: 6 },
  { name: 'Theft', id: 7 },
  { name: 'Medical Reasons', id: 8 },
  { name: 'Loss of License or Requirement of Job', id: 9 },
  { name: 'Violation of Policy/Rules', id: 10 },
  { name: 'Other', id: 11 },
];

//How was the claimant notified of
//their discharge from
//employment ?
export const dischargeNotificationOptions = [
  //{ name: 'Please select an option', id: 0 },
  { name: 'Phone', id: 1 },
  { name: 'In-Person', id: 2 },
  { name: 'Email', id: 3 },
  { name: 'Letter', id: 4 },
  { name: 'Other', id: 5 },
];



//Was this separation a permanent or
//temporary layoff ?
export const lackOfWorkReasonOptions = [
    //{ name: 'Please select an option', id: 0 },
    { name: 'Permanent Layoff', id: 2 },
    { name: 'Temporary Layoff', id: 1 },
];


//I - You indicated Other, please choose the
//option that best fits the situation

export const otherSeparationReasonOptions = [
  //{ name: 'Please select an option', id: 0 },
  { name: 'School Employee on Semester / Holiday Break', id: 1 },
  { name: 'Still Employed Full-Time', id: 2 },
  { name: 'Still Employed Part-Time', id: 3 },
  { name: 'Still Employed On-Call', id: 4 },
  { name: 'Still Employed but Hours Recently Reduced', id: 5 },
  { name: 'Seasonal / Temporary Employment', id: 6 },
  { name: 'Leave of Absence', id: 7 },
  { name: 'Disciplinary Suspension', id: 8 },
  { name: 'Labor Dispute (strike or lock-out)', id: 9 },
  { name: 'Disaster-Related Separation', id: 10 },
  //{ name: 'Never Employed by Employer', id: 11 },
];


//J
//Who initiated the reduction in hours ?
export const whoInitiatedHoursReductionOptions = [
  //{ name: 'Please select an option', id: 0 },
  { name: 'Employer', id: 1 },
  { name: 'Claimant', id: 2 },
];



//K
//What type of leave was granted?
export const typeOfLeaveGrantedOptions = [
  //{ name: 'Please select an option', id: 0 },
  { name: 'Medical', id: 1 },
  { name: 'Personal', id: 2 },
  { name: 'Educational', id: 3 },
  { name: 'Military', id: 4 },
  { name: 'Workers Compensation', id: 5 },
  { name: 'Other', id: 6 },
];

export const indicatorOptions = [
  { name: 'No', id: 1 },
  { name: 'Yes', id: 2 },
  { name: 'Not Warranted', id: 0 },
];

export const lossOfLicenseIndicatorOptions = [
    { name: 'No', id: 1 },
    { name: 'Yes', id: 2 },
    { name: 'Not Asked', id: 0 },
];

export const radioButtonBooleanOptions = [
  { name: 'No', status: false },
  { name: 'Yes', status: true },
];
