import { AxiosError } from 'axios';
import { ApiError } from './apiResponse';
import apiClient from './apiClient';
import { showNotification } from '../actionCreators/dispatchNotification';

// endpoints
const timeZoneUrl = '/time-zones'

export const getTimeZonesApi = async () => {
  return await apiClient().get(`${timeZoneUrl}`)
    .then((res) => {
      return res.data.result;
    })
    .catch((err) => {
      if (err && err.response) {
        const axiosError = err as AxiosError<ApiError>;
        if (axiosError.response) {
          showNotification(axiosError.response.data.error.message, 'error');
        }
      }
      else {
        showNotification('An error occurred while retrieving contacts.', 'error');
      }
      throw err;
    });
};
