import React from 'react';
import { FormikHandlers, FormikHelpers, FormikErrors, getIn } from 'formik';

// material ui
import { Grid, TextField, Card, MenuItem, CardHeader } from '@material-ui/core';

import { ClientQuestionnaireDto } from '../dto';
import { CardContent3 } from '../../../common/styles/styles';
import MoneyField from '../../../common/components/numberFields';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { jobStatusOptions } from '../../../common/constants/options';
import moment from 'moment';

interface Props extends React.HTMLProps<React.ReactNode> {
  values: ClientQuestionnaireDto;
  errors: FormikErrors<ClientQuestionnaireDto>;
  handleChange: FormikHandlers['handleChange'];
  handleBlur: FormikHandlers['handleBlur'];
  setFieldValue: FormikHelpers<ClientQuestionnaireDto>['setFieldValue'];
  setShouldBlockNavigation: (o: boolean) => void;
}

const ClaimProfileCard: React.FC<Props> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setShouldBlockNavigation,
}) => {
  return (
    <Card>
      <CardHeader
        title='Claim Profile'
        titleTypographyProps={{ variant: 'h6' }}
      ></CardHeader>
      <CardContent3>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              id='claimantName'
              size='small'
              margin='none'
              value={`${values.parentClaim?.claimantFirstName ?? ''} ${
                values.parentClaim?.claimantLastName ?? ''
              }`}
              label='Claimant Name'
              name='claimantName'
              InputProps={{ readOnly: true }}
              data-cy='clientQuestionnaire_claimantName'
              aria-label='Claimant Name'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id='claimantSsn'
              size='small'
              margin='none'
              value={`${values.parentClaim?.claimantSsn ?? ''}`}
              label='SSN'
              name='claimantSsn'
              InputProps={{ readOnly: true }}
              data-cy='clientQuestionnaire_claimantSsn'
              aria-label='Claimant SSN'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id='employerName'
              size='small'
              margin='none'
              value={`${values.parentClaim?.employerName ?? ''}`}
              label='Employer Name'
              name='employerName'
              InputProps={{ readOnly: true }}
              data-cy='clientQuestionnaire_employerName'
              aria-label='Employer Name'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id='businessUnit'
              size='small'
              margin='none'
              value={values.parentClaim?.selectedBusinessUnit || ''}
              label='Business Unit'
              name='businessUnit'
              InputProps={{ readOnly: true }}
              data-cy='clientQuestionnaire_businessUnit'
              aria-label='Job Title'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id='benefitYearBeginningDate'
              name='benefitYearBeginningDate'
              label='Benefit Year Beginning'
              InputProps={{ readOnly: true }}
              value={
                moment(values.parentClaim?.benefitYearBeginningDate).format(
                  'MM/DD/yyyy'
                ) || null
              }
              data-cy='clientQuestionnaire_benefitYearBeginningDate'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id='benefitYearEndingDate'
              name='benefitYearEndingDate'
              label='Benefit Year Ending'
              InputProps={{ readOnly: true }}
              value={
                moment(values.parentClaim?.benefitYearEndingDate).format(
                  'MM/DD/yyyy'
                ) || null
              }
              data-cy='clientQuestionnaire_benefitYearEndingDate'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id='potentialLiability'
              size='small'
              margin='none'
              value={values.parentClaim?.potentialLiabilityAmount || ''}
              label='Potential Liability'
              name='potentialLiability'
              fullWidth
              InputProps={{ inputComponent: MoneyField as any, readOnly: true }}
              data-cy='clientQuestionnaire_potentialLiability'
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id='employeeId'
              size='small'
              margin='none'
              value={values.employeeId || ''}
              label='Employee ID'
              name='employeeId'
              onChange={(e) => {
                handleChange(e);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              error={Boolean(getIn(errors, 'employeeId'))}
              helperText={
                Boolean(getIn(errors, 'employeeId')) &&
                getIn(errors, 'employeeId')
              }
              data-cy='clientQuestionnaire_employeeId'
              aria-label='Employee ID'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <KeyboardDatePicker
              margin='none'
              id='hireDate'
              name='hireDateTime'
              label='Hire Date'
              format='MM/DD/yyyy'
              value={values.hireDateTime || null}
              data-cy='clientQuestionnaire_hireDate'
              fullWidth
              onChange={(date: MaterialUiPickersDate) => {
                setFieldValue('hireDateTime', date);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              error={Boolean(errors.hireDateTime)}
              helperText={errors.hireDateTime && errors.hireDateTime}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <KeyboardDatePicker
              margin='none'
              id='lastDayWorked'
              name='lastDayWorkedDate'
              label='Last Day Worked'
              format='MM/DD/yyyy'
              value={values.lastDayWorkedDate || null}
              data-cy='clientQuestionnaire-lastDayWorkedDate'
              fullWidth
              onChange={(date: MaterialUiPickersDate) => {
                setFieldValue('lastDayWorkedDate', date);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <KeyboardDatePicker
              margin='none'
              id='separationDate'
              name='separationDate'
              label='Separation Date'
              format='MM/DD/yyyy'
              value={values.separationDate || null}
              data-cy='clientQuestionnaire_separationDate'
              fullWidth
              onChange={(date: MaterialUiPickersDate) => {
                setFieldValue('separationDate', date);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id='jobTitle'
              size='small'
              margin='none'
              value={values.claimantJobTitle || ''}
              label='Job Title'
              name='claimantJobTitle'
              onChange={(e) => {
                handleChange(e);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              error={Boolean(getIn(errors, 'claimantJobTitle'))}
              helperText={
                Boolean(getIn(errors, 'claimantJobTitle')) &&
                getIn(errors, 'claimantJobTitle')
              }
              data-cy='clientQuestionnaire_jobTitle'
              aria-label='Job Title'
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id='claimantJobStatusTypeId'
              size='small'
              margin='none'
              select
              value={values.claimantJobStatusTypeId || 0}
              label='Most Recent Job Status'
              name='claimantJobStatusTypeId'
              onChange={(e) => {
                handleChange(e);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              error={Boolean(getIn(errors, 'claimantJobStatusId'))}
              helperText={
                Boolean(getIn(errors, 'claimantJobStatusId')) &&
                getIn(errors, 'claimantJobStatusId')
              }
              data-cy='clientQuestionnaire_jobStatus'
              aria-label='Most Recent Job Status'
              fullWidth
              SelectProps={{ MenuProps: { disableScrollLock: true } }}
            >
              {jobStatusOptions
                .filter((item) => item.name !== '' && item.id !== 4)
                .map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id='lastRateOfPay'
              size='small'
              margin='none'
              value={values.lastRateOfPay || ''}
              label='Last Rate of Pay'
              name='lastRateOfPay'
              fullWidth
              onChange={(e) => {
                handleChange(e);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              error={Boolean(errors.lastRateOfPay)}
              helperText={errors.lastRateOfPay && errors.lastRateOfPay}
              InputProps={{ inputComponent: MoneyField as any }}
              data-cy='clientQuestionnaire_lastRateOfPay'
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              id='hrContact'
              size='small'
              margin='none'
              value={values.hrContact || ''}
              label='HR/Colleague Relation Contact'
              name='hrContact'
              onChange={(e) => {
                handleChange(e);
                setShouldBlockNavigation(true);
              }}
              onBlur={handleBlur}
              error={Boolean(errors.hrContact)}
              helperText={Boolean(errors.hrContact) && errors.hrContact}
              data-cy='clientQuestionnaire_hrContact'
              aria-label='HR/Colleague Relation Contact'
              fullWidth
            />
          </Grid>
        </Grid>

        <br />
        <br />
      </CardContent3>
    </Card>
  );
};

export default ClaimProfileCard;
