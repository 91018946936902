export interface UpdatePasswordDto {
  username: string;
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const updatePasswordInit: UpdatePasswordDto = {
  username: '',
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};
